import React from 'react'
import { Grid } from '@material-ui/core'
import Input from 'components/input'
import styles from 'styles/invoice.module.css'

const InvoiceInput = ({name, labelText, setFunction, size, error}) => {
    return(
    <Grid item xs={size}>
						<div className={styles.inp} style={{position: "relative"}}>
							<label htmlFor={`${name}`}>{`${labelText}`}</label>
							<Input 
								className={styles.inputText}
								value={name} 
								handleChange={setFunction} 
								name={`${name}`}
								error={error} 
							/>
						</div>
					</Grid>
                )
}

export default InvoiceInput;