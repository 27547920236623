import React, { useState, useEffect } from "react";
import Input from "./input";
import stylesMod from "../styles/newOrEdit.module.css";
import { X, Plus } from "react-feather";
import {
  Button,
  DialogActions,
  Grid,
  Switch,
  FormControlLabel,
  Tooltip,
} from "@material-ui/core";
import { DBurl } from "../appConfig";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import BTNstyles from "../styles/button.module.css";
import { XCircle, PlusCircle } from "react-feather";
import IconButton from "@material-ui/core/IconButton";
import { styled } from "@material-ui/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddDepartment(props) {
  const [name, setName] = useState(props.department?.name || null);
  const [shortname, setShortname] = useState(
    props.department?.shortname || null
  );
  const [nip, setNip] = useState(props.department?.nip || null);
  const [street, setStreet] = useState(props.department?.street || null);
  const [street_no, setStreetNo] = useState(
    props.department?.street_no || null
  );
  const [apartment_no, setApartment_no] = useState(
    props.department?.apartment_no || null
  );
  const [city, setCity] = useState(props.department?.city || null);
  const [zipcode, setZipcode] = useState(props.department?.zipcode || null);
  const [country, setCountry] = useState(props.department?.country || null);
  const [comments, setComments] = useState(props.department?.comments || null);
  const [customer, setCustomer] = useState(null);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [paymentDeadline, setPaymentDeadline] = useState(null);
  const [defaultIssuePlace, setDefaultIssuePlace] = useState();
  const [wrongFormat, setWrongFormat] = useState(false);
  const [format, setFormat] = useState({
    label: "N/MM/YYYY",
    value: "n/mm/yyyy",
  });
  const [special, setSpecial] = useState();

  const [receiverOther, setReveiverOther] = useState(
    props.department?.recipient_other_than_buyer || false
  );
  const [receivers, setReceivers] = useState([]);
  const [otherReceiver, setOtherReceiver] = useState(
    receivers.find(
      (rec) => rec.id === props?.department?.recipient_other_than_buyer_id
    )
  );

  const [defaultExchangeRateDate, setDefaultExchangeRateDate] = useState();
  const [selectedCurrencies, setSelectedCurrencies] = useState(
    props?.bank_accounts?.map((acc) => acc.currency) || []
  );
  const [selectedCustomerDepartment, setSelectedCustomerDepartment] =
    useState();
  const [customerDepartmentOptions, setCustomerDepartmentOptions] = useState(
    []
  );
  const [
    customerDepartmentBankAccountOptions,
    setCustomerDepartmentBankAccountOptios,
  ] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState(
    [
      {
        label: props?.bank_accounts?.map((acc) => acc.account_name),
        value: props?.bank_accounts?.map((acc) => acc.account_name),
      },
    ] || []
  );
  const [error, setError] = useState(props.error);

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  const currencies = [
    { label: "PLN", value: "PLN" },
    { label: "EURO", value: "EUR" },
    { label: "CHF", value: "CHF" },
    { label: "GBP", value: "GBP" },
    { label: "CZK", value: "CZK" },
    { label: "UAH", value: "UAH" },
  ];

  const {
    fetchAllAccounts,
    newClientID,
    handleSaveDep,
    bank_accounts,
    department,
    newDep,
    allAccounts,
    propkey,
  } = props;

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#129865",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#129865",
    },
    "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
      backgroundColor: "#129865",
    },
  }));

  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const addAccount = (index) => {
    const tempAccounts = {
      account_name: "",
      bank_name: "",
      bank_account_no: "",
      bank_vat_account_no: "",
      swift_bic_no: "",
      currency: "",
      is_default: false,
      id_department: department.id,
      saveThisAcc: true,
      justCreated: true,
      createdWithoutDep: newDep,
      accIndex: index,
    };
    props.allAccounts &&
      props.setBank_accounts([...props.allAccounts, tempAccounts]);
  };

  const deleteDep = async (ids) => {
    props.loading(true);
    const response = await fetch(`${DBurl}/setDepartments`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.token,
      },
      body: JSON.stringify({
        mode: "delete",
        data: { ids },
      }),
    });
    props.loading(false);
  };

  const deleteAcc = async (id) => {
    props.loading(true);
    const response = await fetch(`${DBurl}/setDepartmentsBankAccounts`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.token,
      },
      body: JSON.stringify({
        mode: "delete",
        data: { ids: [id] },
      }),
    });
    props.loading(false);
  };
  const removeAccount = (i) => {
    let tempAccounts = [...props.bank_accounts].filter(
      (_e, index) => index !== i
    );
    props.setBank_accounts([...tempAccounts]);
  };

  const handleRemoveSaved = (data, i) => {
    setOpenModalDelete(true);
    setDeleteData({ ...data, index: i });
  };

  const handleAccount = (data, name, i) => {
    const tempAccount = [...props.bank_accounts];
    tempAccount[i][name] = data;
  };

  const validateFormat = (newFormat) => {
    const values = ["n", "mm", "yyyy", "s"];
    for (const value of values) {
      if (!newFormat.includes(value)) {
        setWrongFormat(true);
        return;
      }
    }
    setWrongFormat(false);
  };

  const deadlineOptions = [
    { value: 1, label: "1 dzień" },
    { value: 3, label: "3 dni" },
    { value: 7, label: "7 dni" },
    { value: 14, label: "14 dni" },
    { value: 30, label: "30 dni" },
    { value: 45, label: "45 dni" },
    { value: 60, label: "60 dni" },
    { value: 90, label: "90 dni" },
  ];

  const formatOptions = [
    { value: "n/mm/yyyy", label: "N/MM/YYYY" },
    { value: "s/n/mm/yyyy", label: "S/N/MM/YYYY" },
    { value: "n/s/mm/yyyy", label: "N/S/MM/YYYY" },
    { value: "n/mm/s/yyyy", label: "N/MM/S/YYYY" },
    { value: "n/mm/yyyy/s", label: "N/MM/YYYY/S" },
  ];

  const defaultExchangeRateDateOptions = [
    { value: "1", label: "Zawsze ręcznie" },
    { value: "2", label: "Z pierwszego załadunku" },
    { value: "3", label: "Z ostatniego rozładunku" },
  ];

  useEffect(() => {
    props.bank_accounts &&
      setSelectedCurrencies(
        props.bank_accounts.map((acc) => {
          return { label: acc.currency, value: acc.currency };
        })
      );
  }, [props.bank_accounts]);

  useEffect(() => {
    props.department.default_customer_department &&
      setSelectedCustomerDepartment(
        customerDepartmentOptions.find(
          (dep) => dep.id === props.department.default_customer_department
        )
      );
  }, [customerDepartmentOptions, props.department.default_customer_department]);

  useEffect(() => {
    bank_accounts &&
      setSelectedAccounts(
        bank_accounts.map((acc) => {
          return { ...acc, label: acc.account_name, value: acc.account_name };
        })
      );
  }, [JSON.stringify(bank_accounts)]);

  const fetchDepartment = async () => {
    const mode =
      props.newDep || !props.department.id
        ? "insert"
        : props.edit
        ? "update"
        : "insert";
    const response = await fetch(
      `${DBurl}${
        props.addContractor ? "/setDepartments" : "/setCustomersDepartments"
      }`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.token,
        },
        body: JSON.stringify({
          mode,
          data: {
            name,
            shortname,
            id_parent: props.parent_id || customer?.value,
            nip,
            street,
            city,
            zipcode,
            country,
            id: props.department.id || null,
            street_no,
            apartment_no,
            comments,
            default_payment_deadline: paymentDeadline?.value,
            default_place_of_issue: defaultIssuePlace,
            default_invoices_special_tag_definition: special,
            default_invoices_name_definition: format?.value,
            recipient_other_than_buyer: receiverOther,
            recipient_other_than_buyer_id: otherReceiver?.id,
            from_when_exchange_rate: defaultExchangeRateDate?.value,
            default_customer_department: selectedCustomerDepartment?.id,
          },
        }),
      }
    );

    const json = await response.json();

    if (json.success) {
      // setName("");
      // setShortname("");
      // setNip("");
      // setStreet("");
      // setStreetNo("");
      // setApartment_no("");
      // setCity("");
      // setZipcode("");
      // setCountry("");
      // setComments("");
      // setPaymentDeadline("");
      // setDefaultIssuePlace("");
      if (props.customer) {
        props.isSuccess();
      }
      props.setOpen(false);
      if (mode === "insert") {
        const newID = json.data.new_id;
        return newID;
      }
    }
  };

  const handleSave = () => {
    if (
      !customer ||
      !name ||
      !shortname ||
      !nip ||
      !country ||
      !city ||
      !country ||
      !street
    ) {
      setError(true);
      props.setDialog({
        isOpen: true,
        type: "error",
        content: "Uzupełnij wymagane pola",
      });
    } else {
      setError(false);
      fetchDepartment();
    }
  };

  const getCustomersDepartments = async function () {
    const response = await fetch(`${DBurl}/getCustomersDepartments/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setCustomerDepartmentOptions(
        json.data.map((dep) => {
          return {
            ...dep,
            label: dep.name,
            value: dep.id,
          };
        })
      );
      return json;
    } else {
      props.setDialog({
        isOpen: true,
        type: "error",
        content: `Nie udało się pobrać odziałów klientów / ${json.responseDescription} // ${json.response}`,
      });
    }
  };

  const getDepartmentAccounts = async () => {
    const response = await fetch(
      `${DBurl}/getCustomersDepartmentsAccounts?customers_departament_id=${selectedCustomerDepartment.id}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.token,
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setCustomerDepartmentBankAccountOptios(
        json.data.map((acc) => {
          return { ...acc, label: acc.account_name, value: acc.account_name };
        })
      );
    }
  };

  useEffect(() => {
    getCustomersDepartments();
  }, []);

  useEffect(() => {
    selectedCustomerDepartment && getDepartmentAccounts();
  }, [selectedCustomerDepartment]);

  const getCustomers = async () => {
    const response = await fetch(`${DBurl}/getCustomers`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.token,
      },
    });
    const json = await response.json();
    if (json.success) {
      const result = json.data.map((e) => {
        return { label: e.shortname, value: e.id };
      });
      await setCustomerOptions(result);
    }
  };

  const fetchReceivers = async () => {
    const response = await fetch(`${DBurl}/getContractors`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.token,
      },
    });
    const json = await response.json();

    if (json.success) {
      setReceivers(
        json.data.map((e) => {
          return { ...e, label: e.name, value: e.id };
        })
      );
    }
  };

  useEffect(() => {
    getCustomers();
    fetchReceivers();
  }, []);

  const fetchDepAsync = async () => {
    const new1D = await fetchDepartment();
    handleSaveDep(new1D, department.id || props.propkey, newDep);
  };
  useEffect(() => {
    if (props.saveDep) {
      fetchDepAsync();
    }
  }, [props.saveDep]);

  useEffect(() => {
    setName(props.department?.name);
    setShortname(props.department?.shortname);
    setComments(props.department?.comments);
    setNip(props.department?.nip);
    setCustomer({ value: props.department?.id_parent });
    setStreet(props.department?.street || props.department?.address?.street);
    setCity(props.department?.city || props.department?.address?.city);
    setZipcode(props.department?.zipcode || props.department?.address?.zipcode);
    setStreetNo(
      props.department?.street_no || props.department?.address?.street_no
    );
    setApartment_no(
      props.department?.apartment_no || props.department?.address?.apartment_no
    );
    setCountry(props.department?.country || props.department?.address?.country);
    setDefaultIssuePlace(props.department?.default_place_of_issue);
    setPaymentDeadline(
      deadlineOptions.find(
        (option) =>
          option.value === parseInt(props.department?.default_payment_deadline)
      )
    );
    setCustomer(
      customerOptions.find(
        (option) => option.value === props.department?.id_parent
      )
    );
    setSpecial(props.department?.default_invoices_special_tag_definition);
    setFormat(
      props.department.default_invoices_name_definition
        ? {
            value: `${props.department.default_invoices_name_definition}`,
            label: `${props.department.default_invoices_name_definition}`,
          }
        : { label: "N/MM/YYYY", value: "n/mm/yyyy" }
    );
    setOtherReceiver({
      ...receivers?.find(
        (rec) => rec.id === props?.department?.recipient_other_than_buyer_id
      ),
    });
    setDefaultExchangeRateDate(
      defaultExchangeRateDateOptions.find(
        (opt) => opt.value === props.department?.from_when_exchange_rate
      )
    );
  }, [JSON.stringify(props.department), customerOptions, receivers]);

  const onBlurFunc = () => {
    if (props.addContractor) {
      const tempDepartment = [...props.allDepartments];
      tempDepartment[props.propkey] = {
        name: name,
        shortname: shortname,
        // parent_id: props.parent_id || customer.value
        id: props.department.id,
        id_parent: props.parent_id || customer?.value,
        nip: nip,
        street: street,
        city: city,
        zipcode: zipcode,
        country: country,
        // district: 'Mazowieckie',
        street_no: street_no,
        apartment_no: apartment_no,
        comments: comments,
        paymentDeadline: paymentDeadline?.value,
        newDep,
      };
      props.setDepartment(tempDepartment);
    }
  };

  const depID = props.department?.id;

  return (
    <>
      {props.customer && (
        <Grid
          item
          xs={2}
          className={`${stylesMod.WOHead} ${stylesMod.secondClass}`}
        >
          <div className={stylesMod.inp}>
            <label>Wybierz Operatora oddziału*</label>
            <Select
              value={customer}
              className={`${stylesMod.select} ${
                error && !customer && stylesMod.error
              }`}
              options={customerOptions}
              name="customer"
              placeholder="Wybierz..."
              onChange={(e) => {
                setCustomer(e);
              }}
              styles={{
                control: (base, state) => ({
                  ...base,
                  border: "1px solid #142f42",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #142f42",
                  },
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused && "#142f42",
                  color: state.isFocused && "#fff",
                }),
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
              menuPortalTarget={document.body}
            />
          </div>
        </Grid>
      )}
      <Grid
        item
        xs={3}
        className={`${stylesMod.WOHead} ${stylesMod.secondClass}`}
      >
        <div className={stylesMod.inp}>
          <label>Pełna nazwa oddziału*</label>
          <Input
            onBlur={onBlurFunc}
            value={name}
            handleChange={(e) => {
              setName(e.target.value);
            }}
            name="name"
            disabled={props.isDisabled ? true : false}
            error={!name && error}
          />
        </div>
      </Grid>
      <Grid
        item
        xs={2}
        className={`${stylesMod.WOHead} ${stylesMod.secondClass}`}
      >
        <div className={stylesMod.inp}>
          <label>Skrócona nazwa*</label>
          <Input
            onBlur={onBlurFunc}
            value={shortname}
            handleChange={(e) => {
              setShortname(e.target.value);
            }}
            name="shortname"
            disabled={props.isDisabled ? true : false}
            error={!shortname && error}
          />
        </div>
      </Grid>
      <Grid
        item
        xs={2}
        className={`${stylesMod.WOHead} ${stylesMod.secondClass}`}
      >
        <div className={stylesMod.inp}>
          <label>NIP*</label>
          <Input
            onBlur={onBlurFunc}
            value={nip}
            handleChange={(e) => {
              setNip(e.target.value);
            }}
            name="nip"
            disabled={props.isDisabled ? true : false}
            type="number"
            error={!nip && error}
          />
        </div>
      </Grid>

      {props.addContractor ? (
        <Grid
          item
          xs={2}
          className={`${stylesMod.WOHead} ${stylesMod.secondClass}`}
        >
          <div className={stylesMod.inp}>
            <label htmlFor={"seller"}>Domyślny termin zapłaty</label>
            <Select
              value={paymentDeadline}
              className={`${stylesMod.select} ${
                error && !paymentDeadline && stylesMod.error
              }`}
              options={deadlineOptions}
              onChange={(e) => {
                setPaymentDeadline(e);
              }}
              name="deadline"
              placeholder="Wybierz..."
              getOptionValue={(option) => `${option.label}`}
              styles={{
                control: (base, state) => ({
                  ...base,
                  innerHeight: "150px",
                  outerHeight: "150px",
                  border: "1px solid #142f42",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #142f42",
                  },
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused && "#142f42",
                  color: state.isFocused && "#fff",
                }),
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
            />
          </div>
        </Grid>
      ) : (
        <></>
      )}
      <Grid
        item
        xs={2}
        className={`${stylesMod.WOHead} ${stylesMod.secondClass}`}
      >
        <div className={stylesMod.inp}>
          <label>Kraj*</label>
          <Input
            onBlur={onBlurFunc}
            value={country}
            handleChange={(e) => {
              setCountry(e.target.value);
            }}
            name="country"
            disabled={props.isDisabled ? true : false}
            error={!country && error}
          />
        </div>
      </Grid>
      <Grid
        item
        xs={2}
        className={`${stylesMod.WOHead} ${stylesMod.secondClass}`}
      >
        <div className={stylesMod.inp}>
          <label>Ulica*</label>
          <Input
            onBlur={onBlurFunc}
            value={street}
            error={error && !street}
            handleChange={(e) => {
              setStreet(e.target.value);
            }}
            name="street"
            disabled={props.isDisabled ? true : false}
          />
        </div>
      </Grid>
      <Grid
        item
        xs={1}
        className={`${stylesMod.WOHead} ${stylesMod.secondClass}`}
      >
        <div className={stylesMod.inp}>
          <label>Numer*</label>
          <Input
            onBlur={onBlurFunc}
            value={street_no}
            error={error && !street_no}
            handleChange={(e) => {
              setStreetNo(e.target.value);
            }}
            name="street_no"
            type="text"
            disabled={props.isDisabled ? true : false}
          />
        </div>
      </Grid>
      <Grid
        item
        xs={1}
        className={`${stylesMod.WOHead} ${stylesMod.secondClass}`}
      >
        <div className={stylesMod.inp}>
          <label>Lokal</label>
          <Input
            onBlur={onBlurFunc}
            value={apartment_no}
            handleChange={(e) => {
              setApartment_no(e.target.value);
            }}
            name="apartment_no"
            type="text"
            disabled={props.isDisabled ? true : false}
          />
        </div>
      </Grid>
      <Grid
        item
        xs={2}
        className={`${stylesMod.WOHead} ${stylesMod.secondClass}`}
      >
        <div className={stylesMod.inp}>
          <label>Kod pocztowy*</label>
          <Input
            onBlur={onBlurFunc}
            value={zipcode}
            handleChange={(e) => {
              setZipcode(e.target.value);
            }}
            name="zipcode"
            type="text"
            disabled={props.isDisabled ? true : false}
            error={!zipcode && error}
          />
        </div>
      </Grid>
      <Grid
        item
        xs={2}
        className={`${stylesMod.WOHead} ${stylesMod.secondClass}`}
      >
        <div className={stylesMod.inp}>
          <label>Miasto*</label>
          <Input
            onBlur={onBlurFunc}
            value={city}
            handleChange={(e) => {
              setCity(e.target.value);
            }}
            name="city"
            disabled={props.isDisabled ? true : false}
            error={!city && error}
          />
        </div>
      </Grid>
      <Grid
        item
        xs={3}
        className={`${stylesMod.WOHead} ${stylesMod.secondClass}`}
      >
        <div className={stylesMod.inp}>
          <label>Komentarz</label>
          <Input
            onBlur={onBlurFunc}
            value={comments}
            handleChange={(e) => {
              setComments(e.target.value);
            }}
            name="comments"
            disabled={props.isDisabled ? true : false}
          />
        </div>
      </Grid>
      {props.addContractor && (
        <Grid
          item
          xs={2}
          className={`${stylesMod.WOHead} ${stylesMod.secondClass}`}
        >
          <div className={stylesMod.inp}>
            <label>Domyślna data kursu wymiany waluty</label>
            <Select
              value={defaultExchangeRateDate}
              className={`${stylesMod.select} ${error && stylesMod.error}`}
              options={defaultExchangeRateDateOptions}
              onChange={(e) => {
                setDefaultExchangeRateDate(e);
              }}
              name="exchangeRateDate"
              getOptionValue={(option) => `${option.label}`}
              styles={{
                control: (base, state) => ({
                  ...base,
                  innerHeight: "150px",
                  outerHeight: "150px",
                  border: "1px solid #142f42",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #142f42",
                  },
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused && "#142f42",
                  color: state.isFocused && "#fff",
                }),
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
            />
          </div>
        </Grid>
      )}
      {props.addContractor && (
        <div className={stylesMod.checkParAlt}>
          <input
            type="checkbox"
            className={stylesMod.check}
            checked={receiverOther}
            onChange={(e) => {
              setReveiverOther(e.target.checked);
            }}
            name="receiverOther"
          />
          <label>Odbiorca faktury jest inny niż nabywca.</label>
        </div>
      )}
      {props.addContractor && (
        <Grid item xs={12}>
          <div>
            <label>Domyślne konto do płatności:</label>
            {!props?.bank_accounts.length && (
              <IconButton
                className={stylesMod.btnIcon}
                onClick={() => {
                  addAccount(props.propkey);
                }}
                value={0}
              >
                <PlusCircle className={stylesMod.add} />
              </IconButton>
            )}
          </div>
          <Grid item xs={12}>
            {props?.bank_accounts.length > 0 && (
              <Grid item xs={2}>
                <div className={stylesMod.inp}>
                  <label>Wybierz przypisany oddział operatora:</label>
                  <Select
                    value={selectedCustomerDepartment}
                    className={`${stylesMod.select}`}
                    options={customerDepartmentOptions}
                    onChange={(e) => {
                      setSelectedCustomerDepartment(e);
                    }}
                    menuPortalTarget={document.body}
                    name="currency"
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        border: "1px solid #142f42",
                        boxShadow: "none",
                        "&:hover": {
                          border: "1px solid #142f42",
                        },
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused && "#142f42",
                        color: state.isFocused && "#fff",
                      }),
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </div>
              </Grid>
            )}
            {props?.bank_accounts?.map((acc, i) => {
              {
                return (
                  <div className={stylesMod.bankAccountsContainer}>
                    <Grid item xs={2}>
                      <div className={stylesMod.inp}>
                        <label>Nazwa konta:</label>
                        <CreatableSelect
                          value={selectedAccounts[i]}
                          className={`${stylesMod.select}`}
                          options={customerDepartmentBankAccountOptions}
                          onChange={(e) => {
                            props.handleSetNewBankAccData(
                              "account_name",
                              depID || props.propkey,
                              i,
                              e.value,
                              depID,
                              newDep
                            );
                            props.handleSetNewBankAccData(
                              "bank_name",
                              depID || props.propkey,
                              i,
                              e.bank_name,
                              depID,
                              newDep
                            );
                            props.handleSetNewBankAccData(
                              "bank_account_no",
                              depID || props.propkey,
                              i,
                              e.bank_account_no,
                              depID,
                              newDep
                            );
                            props.handleSetNewBankAccData(
                              "bank_vat_account_no",
                              depID || props.propkey,
                              i,
                              e.bank_vat_account_no,
                              depID,
                              newDep
                            );
                            props.handleSetNewBankAccData(
                              "swift_bic_no",
                              depID || props.propkey,
                              i,
                              e.swift_bic_no,
                              depID,
                              newDep
                            );
                            props.handleSetNewBankAccData(
                              "currency",
                              depID || props.propkey,
                              i,
                              e.currency,
                              depID,
                              newDep
                            );
                            selectedAccounts.push(e);
                            selectedCurrencies.push(e.currency);
                          }}
                          menuPortalTarget={document.body}
                          name="account_name"
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              border: "1px solid #142f42",
                              boxShadow: "none",
                              "&:hover": {
                                border: "1px solid #142f42",
                              },
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isFocused && "#142f42",
                              color: state.isFocused && "#fff",
                            }),
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div className={stylesMod.inp}>
                        <label>Nazwa banku</label>
                        <Input
                          value={acc.bank_name}
                          handleChange={(e) => {
                            props.handleSetNewBankAccData(
                              e.target.name,
                              depID || props.propkey,
                              i,
                              e.target.value,
                              depID,
                              newDep
                            );
                          }}
                          name="bank_name"
                          error={!acc.bank_name && error}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div className={stylesMod.inp}>
                        <label>Numer konta</label>
                        <Input
                          value={acc.bank_account_no}
                          handleChange={(e) => {
                            props.handleSetNewBankAccData(
                              e.target.name,
                              depID || props.propkey,
                              i,
                              e.target.value,
                              depID,
                              newDep
                            );
                          }}
                          name="bank_account_no"
                          error={!acc.bank_account_no && error}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div className={stylesMod.inp}>
                        <label>Numer konta VAT</label>
                        <Input
                          value={acc.bank_vat_account_no}
                          handleChange={(e) => {
                            props.handleSetNewBankAccData(
                              e.target.name,
                              depID || props.propkey,
                              i,
                              e.target.value,
                              depID,
                              newDep
                            );
                          }}
                          name="bank_vat_account_no"
                          disabled={props.isDisabled ? true : false}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div className={stylesMod.inp}>
                        <label>Numer SWIFT/BIC</label>
                        <Input
                          value={acc.swift_bic_no}
                          handleChange={(e) => {
                            props.handleSetNewBankAccData(
                              e.target.name,
                              depID || props.propkey,
                              i,
                              e.target.value,
                              depID,
                              newDep
                            );
                          }}
                          name="swift_bic_no"
                          disabled={props.isDisabled ? true : false}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div className={stylesMod.inp}>
                        <label>Domyślna waluta konta:</label>
                        <Select
                          value={selectedCurrencies[i]}
                          className={`${stylesMod.select}`}
                          options={currencies}
                          onChange={(e) => {
                            selectedCurrencies.push(e);
                            props.handleSetNewBankAccData(
                              "currency",
                              depID || props.propkey,
                              i,
                              e.value,
                              depID,
                              newDep
                            );
                          }}
                          menuPortalTarget={document.body}
                          name="currency"
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              border: "1px solid #142f42",
                              boxShadow: "none",
                              "&:hover": {
                                border: "1px solid #142f42",
                              },
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isFocused && "#142f42",
                              color: state.isFocused && "#fff",
                            }),
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                        />
                      </div>
                      {/* </div> */}
                    </Grid>
                    <Grid item xs={1} className={stylesMod.buttonContainer}>
                      {
                        <IconButton
                          className={stylesMod.btnIcon}
                          value={i}
                          onClick={() => {
                            acc.old
                              ? handleRemoveSaved(acc, i)
                              : removeAccount(i);
                          }}
                        >
                          <XCircle className={stylesMod.remove} />
                        </IconButton>
                      }
                      <IconButton
                        className={stylesMod.btnIcon}
                        onClick={() => {
                          addAccount(props.propkey);
                        }}
                        value={i}
                      >
                        <PlusCircle className={stylesMod.add} />
                      </IconButton>
                      <Tooltip title={"Maksymalnie dwa per oddział."}>
                        <FormControlLabel
                          className={stylesMod.smallerFont}
                          control={
                            <GreenSwitch
                              name={"is_default"}
                              checked={acc.is_default}
                              onChange={(e) =>
                                props.handleSetNewBankAccData(
                                  e.target.name,
                                  depID || props.propkey,
                                  i,
                                  e.target.checked,
                                  depID
                                )
                              }
                              disabled={() =>
                                bank_accounts.filter((acc) => acc.is_default)
                                  .length > 2
                              }
                            />
                          }
                          label="Domyślne"
                        />
                      </Tooltip>
                    </Grid>
                  </div>
                );
              }
            })}
          </Grid>
        </Grid>
      )}
      {props.customer ? (
        <Grid
          item
          xs={3}
          className={`${stylesMod.WOHead} ${stylesMod.secondClass}`}
        >
          <div className={stylesMod.inp}>
            <label>Format numeracji faktur:</label>
            <Select
              value={format}
              className={`${stylesMod.select}`}
              options={formatOptions}
              onChange={(e) => {
                setFormat(e);
              }}
              name="deadline"
              getOptionValue={(option) => `${option.label}`}
              styles={{
                control: (base, state) => ({
                  ...base,
                  innerHeight: "150px",
                  outerHeight: "150px",
                  border: "1px solid #142f42",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #142f42",
                  },
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused && "#142f42",
                  color: state.isFocused && "#fff",
                }),
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
              menuPortalTarget={document.body}
            />
          </div>
        </Grid>
      ) : (
        <></>
      )}
      {props.customer ? (
        <Grid
          item
          xs={3}
          className={`${stylesMod.WOHead} ${stylesMod.secondClass}`}
        >
          <div className={stylesMod.inp}>
            <label>Zdefiniuj specjalny tag:</label>
            <Input
              value={special}
              handleChange={(e) => {
                validateFormat(e.target.value);
                setSpecial(e.target.value);
              }}
              name="special"
              type="text"
              disabled={props.isDisabled ? true : false}
            />
          </div>
        </Grid>
      ) : (
        <></>
      )}
      {props.customer ? (
        <Grid
          item
          xs={3}
          className={`${stylesMod.WOHead} ${stylesMod.secondClass}`}
        >
          <div className={stylesMod.inp}>
            <label>Domyślne miejsce wystawienia:</label>
            <Input
              value={defaultIssuePlace}
              handleChange={(e) => {
                setDefaultIssuePlace(e.target.value);
              }}
              name="defaultIssuePlace"
              disabled={props.isDisabled ? true : false}
            />
          </div>
        </Grid>
      ) : (
        <></>
      )}

      {props.addContractor && receiverOther && (
        <Grid
          container
          spacing={2}
          className={stylesMod.receiverOtherContainer}
        >
          <Grid item xs={3}>
            <div className={stylesMod.inpAlt}>
              <label>Odbiorca:</label>
              <Select
                value={otherReceiver}
                className={`${stylesMod.select} ${error && stylesMod.error}`}
                options={receivers.filter(
                  (rec) => rec.id !== props.department.id_parent
                )}
                onChange={(e) => {
                  setOtherReceiver(e);
                }}
                name="receiver"
                getOptionValue={(option) => `${option.label}`}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    innerHeight: "150px",
                    outerHeight: "150px",
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused && "#142f42",
                    color: state.isFocused && "#fff",
                  }),
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                menuPortalTarget={document.body}
              />
            </div>
          </Grid>
          <Grid item xs={9}></Grid>
          <Grid item xs={2}>
            <div className={stylesMod.inp}>
              <label>Kraj</label>
              <Input
                value={otherReceiver?.address?.country}
                name="country"
                disabled={true}
                error={!country && error}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={stylesMod.inp}>
              <label>Miasto</label>
              <Input
                value={otherReceiver?.address?.city}
                name="city"
                disabled={true}
                error={!city && error}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={stylesMod.inp}>
              <label>Ulica</label>
              <Input
                value={otherReceiver?.address?.street}
                name="street"
                disabled={true}
                error={!street && error}
              />
            </div>
          </Grid>
          <Grid item xs={1}>
            <div className={stylesMod.inp}>
              <label>Numer</label>
              <Input
                value={otherReceiver?.address?.street_no}
                name="country"
                disabled={true}
                error={!country && error}
              />
            </div>
          </Grid>
          <Grid item xs={1}>
            <div className={stylesMod.inp}>
              <label>Lokal</label>
              <Input
                value={otherReceiver?.address?.apartment_no}
                name="country"
                disabled={true}
                error={!country && error}
              />
            </div>
          </Grid>
          <Grid item xs={1}>
            <div className={stylesMod.inp}>
              <label>Kod pocztowy</label>
              <Input
                value={otherReceiver?.address?.zipcode}
                name="country"
                disabled={true}
                error={!country && error}
              />
            </div>
          </Grid>
        </Grid>
      )}

      {props.addContractor && (
        <Grid item xs={12} className={!props.isLast ? stylesMod.notLast : null}>
          <div className={stylesMod.outBtnRow}>
            <Button
              variant="outlined"
              color="primary"
              className={stylesMod.outBtn}
              onClick={(e) => {
                props.addDepartmentFunc();
              }}
            >
              Dodaj oddział <Plus />
            </Button>
            {props.showDelete ? (
              <Button
                variant="outlined"
                color="primary"
                className={stylesMod.outBtn}
                onClick={(e) => {
                  props.department.id
                    ? handleRemoveSaved(props.department)
                    : props.removeDepartmentFunc();
                }}
              >
                Usuń oddział <X />
              </Button>
            ) : null}
          </div>
        </Grid>
      )}

      {props.customer && (
        <Grid item xs={12}>
          <DialogActions>
            <Button
              variant="contained"
              size="large"
              className={`${BTNstyles.btn} ${BTNstyles.err}`}
              onClick={() => {
                props.setOpen(false);
              }}
            >
              Anuluj
            </Button>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={BTNstyles.btn}
              onClick={handleSave}
            >
              {props.add ? "Dodaj" : "Zapisz"}
            </Button>
          </DialogActions>
        </Grid>
      )}

      <Dialog
        open={openModalDelete}
        TransitionComponent={Transition}
        onClose={() => {
          setOpenModalDelete(false);
          setDeleteData();
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          <div>
            Czy napewno chcesz usunąć{" "}
            {deleteData?.id_department ? "konto" : "oddział"} ?
          </div>
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              setOpenModalDelete(false);
              setDeleteData();
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div>
              Czy napewno chcesz usunąć{" "}
              {deleteData?.id_department
                ? `konto ${deleteData?.account_name}`
                : `oddział ${deleteData?.name}`}{" "}
              ?
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={BTNstyles.btn}
            onClick={(e) => {
              setOpenModalDelete(false);
              setDeleteData();
            }}
          >
            Anuluj
          </Button>
          <Button
            variant="contained"
            size="large"
            className={`${BTNstyles.btn} ${BTNstyles.err}`}
            onClick={(e) => {
              if (deleteData?.id_department) {
                deleteAcc(deleteData.id);
                removeAccount(deleteData.index);
                setOpenModalDelete(false);
              }
              if (!deleteData?.id_department) {
                deleteDep([deleteData.id]);
                props.removeDepartmentFunc();
                setOpenModalDelete(false);
              }
            }}
            color="primary"
          >
            Usuń <X className={BTNstyles.downBtnIco} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
