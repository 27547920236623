import React from "react"
import stylesMod from 'styles/newOrEdit.module.css'
import './styles.scss'
import Select from 'react-select'
import { SelectOptionType } from ".."

export type AddFleetCostsContainerSelectProps = {
    value: SelectOptionType;
    label: string;
    onChangeHandler: ({ value, label }: SelectOptionType) => void
    options: SelectOptionType[]
}

const AddFleetCostsContainerSelect = ({ value, onChangeHandler, label, options }: AddFleetCostsContainerSelectProps) => {

    return (<div className="add-costs-container__item">
        <div className={stylesMod.inp}>
            <label>{`${label}:`}</label>
            <Select
                value={value}
                className={`${stylesMod.select}`}
                options={options}
                onChange={e => {
                    onChangeHandler({ label: e?.label || "", value: e?.value || "" })
                }}
                menuPortalTarget={document.body}
                name={label}
                styles={{
                    control: (base, state) => ({
                        ...base,
                        border: '1px solid #142f42',
                        boxShadow: 'none',
                        '&:hover': {
                            border: '1px solid #142f42',
                        }
                    }),
                    option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused ? "#142f42" : undefined,
                        color: state.isFocused ? "#fff" : undefined,
                    }),
                    menuPortal: base => ({ ...base, zIndex: 9999 })

                }}
            />
        </div>
    </div>)
}


export default AddFleetCostsContainerSelect;

