import React, { useEffect, useRef, useState } from "react";
import H from "@here/maps-api-for-javascript";
import styles from "./ShowPointOnMap.module.css";

const ShowPointOnMap = (props) => {
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const [position, setPosition] = useState(null);
  let markerPosition = {
    lat: Number(props?.traceData?.address?.latitude) || 52.23187165,
    lng: Number(props?.traceData?.address?.longitude) || 21.0067064,
  };
  let markerAddress = props?.traceData?.address?.address_display_name || "";

  useEffect(() => {
    let map;
    let behavior;
    let ui;
    let marker;

    if (
      props?.traceData?.address?.latitude &&
      props?.traceData?.address?.longitude
    ) {
      setPosition({
        lat: Number(props.traceData.address.latitude),
        lng: Number(props.traceData.address.longitude),
      });
    }

    const startDragging = (event) => {
      behavior.disable();
    };

    const drag = (event) => {
      const pointer = event.currentPointer;
      const markerPosition = map.screenToGeo(
        pointer.viewportX,
        pointer.viewportY
      );
      marker.setGeometry(markerPosition);
    };

    const stopDragging = async (event) => {
      behavior.enable();
      const tmpPosition = marker.getGeometry();
      setPosition(tmpPosition);
    };

    const initializeMap = async () => {
      const platform = new H.service.Platform({
        apikey: process.env.REACT_APP_HERE_API_KEY,
      });

      const defaultLayers = platform.createDefaultLayers();
      map = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
        center: markerPosition,
        zoom: props?.traceData?.address?.latitude !== "" ? 15 : 10,
      });
      behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
      ui = H.ui.UI.createDefault(map, defaultLayers, "pl-PL");

      let svgIcon = `<svg fill='#ed3c22' viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg"><path d="M192 0C85.97 0 0 85.97 0 192c0 77.41 26.97 99.03 172.3 309.7c9.531 13.77 29.91 13.77 39.44 0C357 291 384 269.4 384 192C384 85.97 298 0 192 0zM192 271.1c-44.13 0-80-35.88-80-80S147.9 111.1 192 111.1s80 35.88 80 80S236.1 271.1 192 271.1z"/></svg>`;
      var pngIcon = new H.map.Icon(svgIcon, {
        size: { w: 36, h: 50 },
        anchor: { x: 16, y: 32 },
      });

      marker = new H.map.Marker(markerPosition, {
        volatility: true,
        icon: pngIcon,
      });

      const addressParts = [
        props.traceData.address.apartment_no &&
          `${props.traceData.address.apartment_no},`,
        props.traceData.address.street_no &&
          `${props.traceData.address.street_no},`,
        props.traceData.address.street && `${props.traceData.address.street},`,
        props.traceData.address.city && `${props.traceData.address.city},`,
        props.traceData.address.district &&
          `${props.traceData.address.district},`,
        props.traceData.address.zipcode &&
          `${props.traceData.address.zipcode},`,
        props.traceData.address.country && `${props.traceData.address.country}`,
      ];

      const address_display_name = addressParts.filter(Boolean).join(" ");

      const updatedAddress = {
        ...props.traceData.address,
        address_display_name: address_display_name,
      };

      if (props.type !== "warehouse") {
        props.handleTrace(
          {
            target: {
              name: "address",
              value: updatedAddress,
            },
          },
          props.propKey
        );
      }

      markerRef.current = marker;

      marker.addEventListener("dragstart", startDragging);
      marker.addEventListener("drag", drag);
      marker.addEventListener("dragend", stopDragging);

      marker.draggable = true;
      map.addObject(marker);
    };

    initializeMap();

    return () => {
      if (markerRef.current) {
        markerRef.current.removeEventListener("dragstart", startDragging);
        markerRef.current.removeEventListener("drag", drag);
        markerRef.current.removeEventListener("dragend", stopDragging);
      }

      if (map) {
        map.dispose();
      }

      if (behavior) {
        behavior.dispose();
      }

      if (ui) {
        ui.dispose();
      }
    };
  }, []);

  useEffect(() => {
    if (props.type !== "warehouse" && props.newTraceAddress) {
      const updatedAddress = {
        ...props.traceData.address,
        latitude: String(position.lat),
        longitude: String(position.lng),
      };
      props.handleTrace(
        {
          target: {
            name: "address",
            value: updatedAddress,
          },
        },
        props.propKey
      );
    }

    return () => props.type !== "warehouse" && props.setNewTraceAddress(false);
  }, [props.newTraceAddress]);

  useEffect(() => {
    if (props.type === "warehouse" && position?.lng && position?.lat) {
      props.setTmpData((prev) => ({
        ...prev,
        address: {
          ...prev,
          longitude: String(position.lng),
          latitude: String(position.lat),
        },
      }));
    }
  }, [position]);

  return (
    <div className={styles.mapBox}>
      <div className={styles.label}>Wybrana lokalizacja:</div>
      <div className={styles.address}>{markerAddress}</div>
      <div ref={mapRef} style={{ width: "100%", height: "100%" }} />
    </div>
  );
};

export default ShowPointOnMap;
