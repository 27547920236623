import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

const storeFCMToken = async (token) => {
  try {
    localStorage.setItem("FCMToken", token);
  } catch (e) {
    console.log("oh no", e);
  }
};

export default async function getFCMToken() {
  let token = await getToken(messaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_VAPIDKEY,
  })
    .then()
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });

  if (token) {
    storeFCMToken(token);
  }
}
