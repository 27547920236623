import React, { Dispatch, SetStateAction, forwardRef } from "react"
import Input from 'components/input'
import stylesMod from 'styles/newOrEdit.module.css'
import './styles.scss'
import DatePicker from "react-datepicker";
import plLocale from 'date-fns/locale/pl';
import moment from 'moment';
import { Calendar } from "react-feather";



export type AddFleetCostsContainerDatePickerPropsType = {
    value: Date | undefined;
    label: string | undefined;
    onChangeHandler: (Dispatch<SetStateAction<number>> | Dispatch<SetStateAction<string>> | Dispatch<SetStateAction<undefined>>) | ((date: Date) => void)
    showMonthYearPicker?: boolean
    format?: "MM-yyyy" | "dd-MM-yyyy";
    disabled?: boolean;
}

const CustomInput = forwardRef((props: any, ref: any) => {
    return (
        <div className={`${'add-costs-container__datepicker'} ${stylesMod.dataPicker}`}>
            <label onClick={props.onClick} ref={ref}>
                {props.value || props.placeholder}
            </label>
            <Calendar onClick={props.onClick} />
        </div>
    );
});

const AddFleetCostsContainerDatePicker = ({ value, onChangeHandler, label, showMonthYearPicker, format = 'MM-yyyy', disabled }: AddFleetCostsContainerDatePickerPropsType) => {

    return (<div className={stylesMod.datePicker}>
        <label>{label}</label>
        <div className=".add-costs-container__date"><DatePicker
            disabled={disabled}
            dateFormat={format}
            showMonthYearPicker={showMonthYearPicker}
            startDate={new Date()}
            selected={value || new Date()}
            onChange={(e: any) => {
                let newD = moment(e).valueOf()
                let newDS: any = moment(newD).format(format)
                newDS = new Date(e)
                onChangeHandler(newDS)
            }}
            locale={plLocale}
            name="date"
            customInput={<CustomInput error={false} />}
        /></div>
    </div>)
}


export default AddFleetCostsContainerDatePicker;