import React, { useState, useEffect } from "react";
import Title from "../../components/title";
import { DBurl } from "../../appConfig";
import {
  Check,
  ArrowRight,
  Info,
  X,
  Save,
  RefreshCw,
  File,
} from "react-feather";
import styles from "../table.module.css";
import BTNstyles from "../../styles/button.module.css";
import Pagination from "../../components/pagination";
import Button from "@material-ui/core/Button";
import NewOrder from "./newOrder/newOrder";
import NewOrderSped from "./newOrderSped";
import stylesMod from "../../styles/newOrEdit.module.css";
import {
  Table,
  TableBody,
  TableCell,
  IconButton,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Slide,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import OrderListTableRow from "./OrderListTableRow";
import ForwardingOrderTableRow from "./forwardingOrdersTableRow";
import OrderPerPageButton from "./OrderPerPageButton";
import { perPageButtons } from "./utilities/constants";
import Input from "../../components/input";
import InvoiceAddNew from "../account/AddInvoice/InvoiceAddNew";
import IcoBtn from "../../components/buttons/icoBtn";
import RowsPerPage from "../../components/RowsPerPage";
import moment from "moment";
import OrderFiles from "./orderFiles";
import { useMutation, useQuery } from "react-query";
import {
  getOrders,
  getForwardingOrders,
  getOrdersTemplates,
  getRefuelingFromOrders,
  getCostsFromOrders,
  deleteOrders,
  getHereMapsRouting,
} from "api/endpoints";
import TmsLoading from "components/TmsLoading";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] === a[orderBy]) return 0;
  return b[orderBy] < a[orderBy] ? -1 : 1;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (array?.length > 0) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
}

const headCells = [
  {
    id: "order_no",
    numeric: true,
    disablePadding: false,
    label: "nr Zlecenia",
  },
  {
    id: "client_order_no",
    numeric: true,
    disablePadding: false,
    label: "Nr zlecenia (klient)",
  },
  { id: "ts", numeric: true, disablePadding: false, label: "Data" },
  {
    id: "contractor.shortname",
    numeric: true,
    disablePadding: false,
    label: "Klient",
  },
  {
    id: "loading",
    numeric: true,
    disablePadding: false,
    label: "Miejsce załadunku",
  },
  {
    id: "unloading",
    numeric: true,
    disablePadding: false,
    label: "Miejsce rozładunku",
  },
  { id: "goods", numeric: true, disablePadding: false, label: "Towar" },
  { id: "ref", numeric: true, disablePadding: false, label: "Nr ref." },
  {
    id: "order_price",
    numeric: true,
    disablePadding: false,
    label: "Przychód",
  },
  { id: "cost", numeric: true, disablePadding: false, label: "Koszt" },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
    noSort: true,
  },
  {
    id: "tags",
    numeric: true,
    disablePadding: false,
    label: "Tagi",
    noSort: true,
  },
  {
    id: "buttons",
    numeric: true,
    disablePadding: false,
    label: "",
    width: "210px",
  },
];

const forwardingHeadCells = [
  {
    id: "order_no",
    numeric: true,
    disablePadding: false,
    label: "nr Zlecenia",
  },
  {
    id: "client_order_no",
    numeric: true,
    disablePadding: false,
    label: "Nr zlecenia (klient)",
  },
  { id: "ts", numeric: true, disablePadding: false, label: "Data" },
  {
    id: "contractor.shortname",
    numeric: true,
    disablePadding: false,
    label: "Klient",
  },
  {
    id: "carrier",
    numeric: true,
    disablePadding: false,
    label: "Przewoźnik",
  },
  {
    id: "loading",
    numeric: true,
    disablePadding: false,
    label: "Miejsce załadunku",
  },
  {
    id: "unloading",
    numeric: true,
    disablePadding: false,
    label: "Miejsce rozładunku",
  },
  { id: "goods", numeric: true, disablePadding: false, label: "Towar" },
  { id: "ref", numeric: true, disablePadding: false, label: "Nr ref." },
  { id: "rate", numeric: true, disablePadding: false, label: "Stawka frachtu" },
  {
    id: "cost",
    numeric: true,
    disablePadding: false,
    label: "Koszt przewoźnika",
  },
  {
    id: "profit",
    numeric: true,
    disablePadding: false,
    label: "Zysk",
    noSort: true,
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
    noSort: true,
  },
  {
    id: "tags",
    numeric: true,
    disablePadding: false,
    label: "Tagi",
    noSort: true,
  },
  {
    id: "buttons",
    numeric: true,
    disablePadding: false,
    label: "",
    width: "210px",
  },
];

function EnhancedTableHead(props) {
  const {
    styles,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={styles.tabHead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ width: `${headCell.width || "auto"}` }}
          >
            {!headCell.noSort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={styles.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableHeadForward(props) {
  const {
    styles,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={styles.tabHead}>
      <TableRow>
        {forwardingHeadCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ width: `${headCell.width || "auto"}` }}
          >
            {!headCell.noSort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={styles.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function useInput({ type, size }) {
  const [value, setValue] = useState("");
  const input = (
    <TableCell className={styles.inputCell}>
      <Input
        value={value}
        handleChange={(e) => {
          setValue(e.target.value);
        }}
        type={type}
        search={true}
        className={size}
      />
    </TableCell>
  );
  return [value, input];
}

export default function OrderList(props) {
  const [table, setTable] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("short");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [searchOrder, setSearchOrder] = useInput({ type: "text" });
  const [searchClient, setSearchClient] = useInput({ type: "text" });
  const [searchCargoName, setSearchCargoName] = useInput({ type: "text" });
  const [searchRef, setSearchRef] = useInput({ type: "text" });
  const [searchClientOrdNum, setSearchClientOrdNum] = useInput({
    type: "text",
  });
  const [searchCarrier, setSearchCarrier] = useInput({ type: "text" });
  const [searchStatus, setSearchStatus] = useInput({ type: "text" });
  const [searchTags, setSearchTags] = useInput({ type: "text" });
  const [searchDate, setSearchDate] = useInput({ type: "text" });
  const [deleteData, setDeleteData] = React.useState([]);
  const [templateOn, setTemplateOn] = React.useState(false);

  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [openModalCostFuel, setOpenModalCostFuel] = React.useState(false);
  const [openModalFilesList, setOpenModalFilesList] = React.useState(false);
  const [openModalOrderFiles, setOpenModalOrderFiles] = React.useState(false);
  const [orderData, setOrderData] = useState(props.state.orderData);
  const [orderDataID, setOrderDataID] = useState();

  const [rowsPerPageModalTab, setRowsPerPageModalTab] = React.useState(10);
  const [pageModalTab, setPageModalTab] = React.useState(0);
  const paginateModalTab = (pageNumber) => setPageModalTab(pageNumber - 1);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");
  const [reload, setReload] = useState(false);

  const [refuelingFromOrders, setRefuelingFromOrders] = useState([]);
  const [costFromOrders, setCostFromOrders] = useState([]);
  const [tollCosts, setTollCosts] = useState([]);
  const [tollCostsSum, setTollCostsSum] = useState([]);
  const [exchangeRatesList, setExchangeRatesList] = useState([]);

  const [refuelingFromOrdersAll, setRefuelingFromOrdersAll] = useState([]);
  const [costFromOrdersAll, setCostFromOrdersAll] = useState([]);

  const [selectedOrder, setSelectedOrder] = useState();
  const [driverFilesList, setDriverFilesList] = useState([]);
  const [euroRate, setEuroRate] = useState(null);

  // zlecenia spedycyjne
  const [showForwardingOrders, setShowForwardingOrders] = useState(
    props.state.showForwardingOrders || false
  );
  const [forwardingOrders, setForwardingOrders] = useState([]);

  const iso = require("iso-3166-1");

  const savedTabId = props.tabID;

  const saveTab = () => {
    const tempTab = { ...props.tabs[savedTabId] };
    tempTab.showForwardingOrders = showForwardingOrders;
    tempTab.orderData = orderData;
    return tempTab;
  };

  useEffect(() => {
    props.setTabs(() => {
      const tempTab = [...props.tabs];
      tempTab[props.tabID] = saveTab();
      return tempTab;
    });
  }, [showForwardingOrders, orderData]);

  const {
    data: ordersData,
    refetch: refetchOrders,
    isLoading: ordersLoading,
  } = useQuery(["getOrders"], getOrders, {
    onError: (error) => {
      props.setDialog({
        isOpen: true,
        type: "error",
        content: `Nie udało się pobrać zleceń / ${error.message}`,
      });
      setReload(false);
    },
  });

  useEffect(() => {
    if (ordersData?.length > 0) {
      const tempData = [...ordersData];
      tempData.forEach((e) => {
        e.reservation = e.status === 4 ? true : false;
      });
      setTable(tempData);
      setReload(false);
    }
  }, [ordersData]);

  const {
    refetch: refetchForwardingOrders,
    isFetching: forwardingOrdersFetching,
  } = useQuery(["getForwardingOrders"], getForwardingOrders, {
    onSuccess: (data) => {
      const tempData = [...data];
      tempData.forEach((e) => {
        e.reservation = e.status === 4 ? true : false;
        e.status = setStatus(e.status);
      });
      setForwardingOrders(tempData);
      table.length && props.loading(false);
      setReload(false);
    },
    onError: (error) => {
      props.setDialog({
        isOpen: true,
        type: "error",
        content: `Nie można pobrać zleceń / ${error.message}`,
      });
      setReload(false);
    },
  });

  const { isFetching: ordersTemplatesFetching } = useQuery(
    ["getOrdersTemplates"],
    getOrdersTemplates,
    {
      onSuccess: (data) => {
        const tempData = [...data];
        tempData.forEach((e) => {
          e.status = setStatus(5);
        });
        setTemplates(tempData);
        table.length && props.loading(false);
      },
      onError: (error) => {
        props.setDialog({
          isOpen: true,
          type: "error",
          content: `Nie można pobrać szablonów zleceń / ${error.message}`,
        });
        setReload(false);
      },
    }
  );

  const {
    refetch: refetchRefuelingFromOrders,
    isFetching: refuelingFromOrdersFetching,
  } = useQuery(["getRefuelingFromOrders"], getRefuelingFromOrders, {
    onSuccess: (data) => {
      if (data?.length > 0) {
        let tempRefueling = data.filter((o) => {
          return o.id_order === selectedOrder;
        });
        setRefuelingFromOrdersAll(data);
        setRefuelingFromOrders(tempRefueling);
      }
      table.length && props.loading(false);
    },
    onError: (error) => {
      props.setDialog({
        isOpen: true,
        type: "error",
        content: `Nie można pobrać zleceń / ${error.message}`,
      });
      setReload(false);
    },
  });

  const {
    refetch: refetchCostsFromOrders,
    isFetching: costsFromOrdersFetching,
  } = useQuery(["getCostsFromOrders"], getCostsFromOrders, {
    onSuccess: (data) => {
      if (data?.length > 0) {
        let tempCost = data.filter((o) => {
          return o.id_order === selectedOrder;
        });
        setCostFromOrdersAll(data);
        setCostFromOrders(tempCost);
      }
      table.length && props.loading(false);
    },
    onError: (error) => {
      props.setDialog({
        isOpen: true,
        type: "error",
        content: `Nie można pobrać kosztów zleceń / ${error.message}`,
      });
      setReload(false);
      props.loading(false);
    },
  });

  const getExchangeRateForCode = async (code) => {
    let data = moment().format("YYYY-MM-DD");
    const response = await fetch(
      `${DBurl}/getExchangeRateForCode?code=${code}&date=${data}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
      }
    );
    const json = await response.json();

    if (json.success) {
      setEuroRate(Number(json?.data[0]?.value));
    }
  };

  const { refetch: fetchHereMapsRouting } = useQuery(
    ["getHeremapsRouting"],
    () => getHereMapsRouting({ routing_id: null, order_id: selectedOrder }),
    {
      enabled: false,
      onSuccess: (data) => {
        if (data.response?.routes?.length > 0) {
          for (var i = 0; i < data.response?.routes[0]?.sections.length; i++) {
            let temp_tolls = [];

            if (data.response.routes[0].sections[i].tolls) {
              for (const toll of data.response.routes[0].sections[i].tolls) {
                temp_tolls.push(toll);
              }
            }

            if (i === data.response?.routes[0]?.sections.length) {
              table.length && props.loading(false);
            }

            setTollCosts(temp_tolls);
          }
        } else {
          return false;
        }
      },
      onError: (error) => {
        props.setDialog({
          isOpen: true,
          type: "error",
          content: `Nie można pobrać routingu z Here Maps / ${error.message}`,
        });
      },
    }
  );

  const getExchangeRates = async () => {
    const response = await fetch(`${DBurl}/getExchangeRates`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setExchangeRatesList(json.data);
    }
  };

  function setStatus(status) {
    switch (status) {
      case 0:
        return (
          <span className={styles.status}>
            <Check className={styles.ok} /> Zakończone
          </span>
        );
      case 1:
        return (
          <span className={styles.status}>
            <ArrowRight className={styles.alert} /> W trakcie
          </span>
        );
      case 2:
        return (
          <span className={styles.status}>
            <Info className={styles.alert} /> Wymaga uwagi
          </span>
        );
      case 3:
        return (
          <span className={styles.status}>
            <X className={styles.err} /> Anulowane
          </span>
        );
      case 4:
        return (
          <span className={styles.status}>
            <Save className={styles.alert} /> Rezerwacja
          </span>
        );
      case 5:
        return (
          <span className={styles.status}>
            <Save className={styles.alert} /> Szablon
          </span>
        );
      default:
        return (
          <span className={styles.status}>
            <X className={styles.err} /> Błąd statusu
          </span>
        );
    }
  }

  const cleanFetch = () => {
    setRefuelingFromOrders([]);
    setCostFromOrders([]);
    setSelectedOrder();
    setDriverFilesList([]);
    setTollCosts([]);
    setTollCostsSum([]);
  };

  const finishOrderManually = async (id) => {
    setReload(true);
    const response = await fetch(
      `${DBurl}/finishOrderManually?id_order=${id}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
      }
    );
    setReload(false);
    getOrders();
  };

  const finishForwardingOrderManually = async (id) => {
    setReload(true);
    const response = await fetch(`${DBurl}/finishForwardingOrderManually`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "insert",
        data: {
          id_order: id,
        },
      }),
    });
    setReload(false);
  };

  const { mutate: delOrders } = useMutation("deleteOrders", deleteOrders, {
    onSuccess: () => {
      props.setDialog({
        isOpen: true,
        type: "success",
        content: `Zlecenie zostało usunięte`,
      });
      refetchOrders();
      refetchForwardingOrders();
    },
    onError: (error) => {
      props.setDialog({
        isOpen: true,
        type: "error",
        content: `Nie udało się usunąć zlecenia / ${error.message}`,
      });
    },
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const search = (tbl) => {
    return tbl?.filter((row) => {
      const tags =
        row.tags.length > 0
          ? row.tags.map((tag) => tag?.name.toLowerCase()).join(", ")
          : [];
      const client = row.contractor.shortname
        ? row?.contractor?.shortname?.toLowerCase()
        : "b.d.";
      return (
        row.order_no
          ?.toString()
          .toLowerCase()
          .includes(searchOrder?.toLowerCase()) &&
        client?.includes(searchClient?.toLowerCase()) &&
        row.status
          ?.toString()
          .toLowerCase()
          .includes(searchStatus.toLowerCase()) &&
        (!searchTags || tags.includes(searchTags.toLowerCase())) &&
        handleRefs(row).some((str) => str.includes(searchRef)) &&
        row?.customer_order_number
          ?.toLowerCase()
          .includes(searchClientOrdNum.toLowerCase()) &&
        (!searchCarrier ||
          row?.carrier?.shortname
            .toLowerCase()
            .includes(searchCarrier.toLowerCase())) &&
        row?.ts?.toLowerCase().includes(searchDate.toLowerCase())
      );
    });
  };

  const handleSelectAllClick = (event) => {
    if (table?.length > 0) {
      if (event.target.checked) {
        const newSelecteds = table.map((n) => n.id);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    } else {
      props.setDialog({
        isOpen: true,
        type: "warning",
        content: "Brak danych do zaznaczenia",
      });
    }
  };

  const handleClick = (_event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const paginate = (pageNumber) => setPage(pageNumber - 1);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const openNewOrder = (row, type) => {
    const tempData = {
      active: true,
      componentName: "NewOrder",
      component: NewOrder,
      permission_id: 7,
      tabName: type || "Nowe zlecenie",
      isEdit: type === "Edycja zlecenia" ? true : false,
      isCopy: type === "Kopia zlecenia" ? true : false,
      isTemplate: type === "Edycja szablonu" ? true : false,
      tabID: null,
      fetchOrderId: row.id,
      order_type_S: false,
      id_invoice: row.id_invoice,
    };

    props.addTab("newOrder", tempData);
  };

  const openNewForwardingOrder = (row, type) => {
    const tempData = {
      permission_id: 7,
      tabName: type || "Nowe zlecenie spedycyjne",
      isEdit: type === "Nowe zlecenie spedycyjne" ? false : true,
      tabID: null,
      component: NewOrderSped,
      active: true,
      fetchOrderId: row.id,
      order_type_S: false,
    };

    props.addTab("newOrderSped", tempData);
  };

  const addInvoice = (order, forCarrier) => {
    const tempData = {
      permission_id: 14,
      tabName: "Wystaw fakturę",
      tabID: null,
      component: InvoiceAddNew,
      active: true,
      orderData: order,
      forCarrier: forCarrier,
      invoiceFromOrder: true,
      typeInput: { label: "Faktura VAT", value: 1 },
    };
    props.addTab("InvoiceAddNew", tempData);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, table.length - page * rowsPerPage);

  const costSumFuel = (o) =>
    o.reduce((sum, item) => sum + parseInt(item.cost_of_fuel), 0);
  const costSumCosts = (o) =>
    o.reduce((sum, item) => sum + parseInt(item.cost_pln), 0);

  const importAll = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  };

  const images = importAll(
    require.context("../../img/flags", false, /\.(png|gif|svg)$/)
  );

  const renderFlag = (code) => {
    return code.toLowerCase() + ".gif";
  };

  const exchangeToPLN = (value, code) => {
    const temp_ex_list = exchangeRatesList;
    let temp_exchange = temp_ex_list.filter((o) => {
      return o.code === code;
    });
    if (temp_exchange[0] !== undefined) {
      return (Number(temp_exchange[0].value) * value).toFixed(2);
    } else {
      if (code === "RUB") {
        return (value * 0.063).toFixed(2);
      }
    }
  };

  const printOrdersPDF = (id) => {
    const url = `${DBurl}/printOrdersPDF?id_order=${id}&output=I`;
    window.open(url, "_blank");
  };

  const printForwardingOrdersPDF = (id, recipient) => {
    const url = `${DBurl}/printOrdersPDF?id_order=${id}&type=forwarding_order&for=${recipient}`;
    window.open(url, "_blank");
  };

  useEffect(() => {
    getExchangeRateForCode("EUR");
    getExchangeRates();
  }, []);

  useEffect(() => {
    openModalCostFuel && refetchRefuelingFromOrders();
    openModalCostFuel && refetchCostsFromOrders();
    openModalCostFuel && selectedOrder && fetchHereMapsRouting();
  }, [openModalCostFuel]);

  useEffect(() => {
    let sum = 0;

    if (tollCosts.length > 0) {
      for (let i = 0; tollCosts.length > i; i++) {
        if (tollCosts[i].fares[0].price.currency === "PLN") {
          sum += tollCosts[i].fares[0].price.value;
        } else if (tollCosts[i].fares[0].price.currency === "RUB") {
          sum += tollCosts[i].fares[0].price.value * 0.063;
        } else {
          let temp_to_pln = exchangeToPLN(
            tollCosts[i].fares[0].price.value,
            tollCosts[i].fares[0].price.currency
          );
          sum += Number(temp_to_pln);
        }
      }
    }
    setTollCostsSum(sum);
    return () => setTollCostsSum();
  }, [tollCosts]);

  useEffect(() => {
    setOrderData(
      !showForwardingOrders
        ? table.find((order) => order.id === orderDataID)
        : forwardingOrders.find((order) => order.id === orderDataID)
    );
  }, [orderDataID, reload, table]);

  const handleRefs = (row) => {
    const locationsRefs = row?.locations
      ?.map((loc) => loc.reference_no)
      .filter((rec) => rec !== null);
    const goodsRefs = row?.locations
      ?.map((loc) => loc?.goods?.map((g) => g.reference_no))
      ?.flat()
      .filter((rec) => rec !== null);
    return locationsRefs.length ? locationsRefs : goodsRefs;
  };

  const isLoading =
    ordersLoading ||
    forwardingOrdersFetching ||
    ordersTemplatesFetching ||
    refuelingFromOrdersFetching ||
    costsFromOrdersFetching;

  return (
    <>
      <Paper className={styles.paper}>
        <Title
          title={props.state.tabName}
          btnBox
          btnAdd
          addFunc={(e) => {
            !showForwardingOrders
              ? props.addTab("newOrder")
              : props.addTab("newOrderSped");
          }}
          btnUni={
            !showForwardingOrders && {
              txt: templateOn ? "Przełącz na zlecenia" : "Przelącz na szablony",
              ico: <RefreshCw className={BTNstyles.downBtnIco} />,
              Func: () => setTemplateOn(!templateOn),
            }
          }
          showVar={!templateOn}
          showVarFunc={() => {
            setShowForwardingOrders(
              (prevShowForwardingOrders) => !prevShowForwardingOrders
            );
          }}
          showVarText={
            showForwardingOrders
              ? "Pokaż zlecenia"
              : "Pokaż zlecenia spedycyjne"
          }
        />
      </Paper>
      <TableContainer className={styles.stickyTable}>
        <Table
          stickyHeader={true}
          className={styles.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
          size={"small"}
        >
          {!showForwardingOrders ? (
            <EnhancedTableHead
              styles={styles}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={templateOn ? templates.length : table.length}
            />
          ) : (
            <EnhancedTableHeadForward
              styles={styles}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={templateOn ? templates.length : table.length}
            />
          )}
          <TableBody className={styles.tabBody}>
            {!showForwardingOrders ? (
              <TableRow>
                {setSearchOrder}
                {setSearchClientOrdNum}
                {setSearchDate}
                {setSearchClient}
                <TableCell size="medium"></TableCell>
                <TableCell size="small"></TableCell>
                {setSearchCargoName}
                {setSearchRef}
                <TableCell></TableCell>
                <TableCell></TableCell>
                {setSearchStatus}
                {setSearchTags}
                <TableCell></TableCell>
              </TableRow>
            ) : (
              <TableRow>
                {setSearchOrder}
                {setSearchClientOrdNum}
                {setSearchDate}
                {setSearchClient}
                {setSearchCarrier}
                <TableCell size="medium"></TableCell>
                <TableCell size="small"></TableCell>
                {setSearchCargoName}
                {setSearchRef}
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                {setSearchTags}
                <TableCell></TableCell>
              </TableRow>
            )}

            {stableSort(
              search(
                templateOn
                  ? templates
                  : !showForwardingOrders
                  ? table
                  : forwardingOrders
              ),
              getComparator(order, orderBy)
            )
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row, index) => {
                const allFiles = row.files?.map((file) => file.name);
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return !showForwardingOrders ? (
                  <OrderListTableRow
                    key={row.id}
                    row={row}
                    finishOrderManually={finishOrderManually}
                    handleClick={handleClick}
                    isItemSelected={isItemSelected}
                    labelId={labelId}
                    openNewOrder={openNewOrder}
                    templateOn={templateOn}
                    setDeleteData={setDeleteData}
                    setOpenModalDelete={setOpenModalDelete}
                    setOpenModalCostFuel={setOpenModalCostFuel}
                    addInvoice={addInvoice}
                    setSelectedOrder={setSelectedOrder}
                    refuelingFromOrders={refuelingFromOrdersAll}
                    costFromOrders={costFromOrdersAll}
                    tollCosts={tollCosts}
                    printOrdersPDF={printOrdersPDF}
                    openModalOrderFiles={openModalOrderFiles}
                    setOpenModalOrderFiles={setOpenModalOrderFiles}
                    setOrderData={setOrderData}
                    handleRefs={handleRefs}
                  />
                ) : (
                  <ForwardingOrderTableRow
                    key={row.id}
                    row={row}
                    finishOrderManually={finishForwardingOrderManually}
                    handleClick={handleClick}
                    isItemSelected={isItemSelected}
                    labelId={labelId}
                    openNewOrder={openNewForwardingOrder}
                    templateOn={templateOn}
                    setDeleteData={setDeleteData}
                    setOpenModalDelete={setOpenModalDelete}
                    setOpenModalCostFuel={setOpenModalCostFuel}
                    addInvoice={addInvoice}
                    setSelectedOrder={setSelectedOrder}
                    refuelingFromOrders={refuelingFromOrdersAll}
                    costFromOrders={costFromOrdersAll}
                    tollCosts={tollCosts}
                    printOrdersPDF={printForwardingOrdersPDF}
                    openModalOrderFiles={openModalOrderFiles}
                    setOpenModalOrderFiles={setOpenModalOrderFiles}
                    setOrderData={setOrderData}
                    exchangeRatesList={exchangeRatesList}
                    handleRefs={handleRefs}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <div className={styles.tableBottom}>
        <div>
          {perPageButtons.map(({ value, minNumber }) => (
            <OrderPerPageButton
              key={value}
              tableLength={table.length}
              minNumber={minNumber}
              value={value}
              templateOn={templateOn}
              templatesLength={templates.length}
              setRowsPerPage={setRowsPerPage}
              rowsPerPage={rowsPerPage}
            />
          ))}
        </div>
        <div>
          <Pagination
            postsPerPage={rowsPerPage}
            totalPosts={templateOn ? templates.length : table.length}
            paginate={paginate}
            page={page}
          />
        </div>
      </div>

      <OrderFiles
        token={props.user.csrf_token}
        orderData={orderData}
        openModalOrderFiles={openModalOrderFiles}
        setOpenModalOrderFiles={setOpenModalOrderFiles}
        setRefresh={setReload}
        setOrderDataID={setOrderDataID}
        refresh={reload}
      />

      <Dialog
        open={openModalDelete}
        TransitionComponent={Transition}
        onClose={() => {
          setDeleteData([]);
          setOpenModalDelete(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Czy napewno chcesz usunąć ?{" "}
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              setDeleteData([]);
              setOpenModalDelete(false);
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {deleteData.length > 0 ? (
            <DialogContentText id="alert-dialog-slide-description">
              Czy napewno chcesz usunąć zlecenia:{" "}
              {deleteData.map((e) => {
                const tempOrder = !showForwardingOrders
                  ? table.find((el) => el.id === e)
                  : forwardingOrders.find((el) => el.id === e);
                return (
                  <>
                    <br />
                    Zlecenie nr {tempOrder ? tempOrder.order_no : null} od{" "}
                    {tempOrder ? tempOrder?.contractor?.name : null}{" "}
                  </>
                );
              })}
            </DialogContentText>
          ) : (
            <DialogContentText id="alert-dialog-slide-description">
              Czy napewno chcesz usunąć zlecenie:{" "}
              {selected.map((e) => {
                const tempOrder = !showForwardingOrders
                  ? table.find((el) => el.id === e)
                  : forwardingOrders.find((el) => el.id === e);
                return (
                  <>
                    <br />
                    Zlecenie nr {tempOrder ? tempOrder.order_no : null} od{" "}
                    {tempOrder ? tempOrder?.contractor?.name : null}{" "}
                  </>
                );
              })}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={BTNstyles.btn}
            onClick={(e) => {
              setOpenModalDelete(false);
            }}
          >
            Anuluj
          </Button>
          <Button
            variant="contained"
            size="large"
            className={`${BTNstyles.btn} ${BTNstyles.err}`}
            onClick={() => {
              delOrders(deleteData.length > 0 ? deleteData : selected);
              setSelected([]);
              setOpenModalDelete(false);
              setDeleteData([]);
            }}
            color="primary"
          >
            Usuń <X className={BTNstyles.downBtnIco} />
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openModalCostFuel}
        fullWidth={true}
        maxWidth={"md"}
        TransitionComponent={Transition}
        onClose={() => {
          setOpenModalCostFuel(false);
          cleanFetch();
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Koszty zlecenia
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              setOpenModalCostFuel(false);
              cleanFetch();
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {refuelingFromOrders?.length > 0 && (
              <Grid className={styles.tableBoxInColumnA_full} item xs={12}>
                <h5>Tankowania:</h5>
                <TableContainer>
                  <Table
                    className={styles.modalTable}
                    aria-labelledby="tableTitle"
                  >
                    <TableHead className={styles.tabHead}>
                      <TableRow>
                        <TableCell className={styles.cell_modalTable}>
                          Data
                        </TableCell>
                        <TableCell
                          className={styles.cell_modalTable}
                          align="right"
                        >
                          Pojazd
                        </TableCell>
                        <TableCell
                          className={styles.cell_modalTable}
                          align="right"
                        >
                          Paliwo
                        </TableCell>
                        <TableCell
                          className={styles.cell_modalTable}
                          align="right"
                        >
                          Cena paliwa
                        </TableCell>
                        <TableCell
                          className={styles.cell_modalTable}
                          align="right"
                        >
                          Ilość
                        </TableCell>
                        <TableCell
                          className={styles.cell_modalTable}
                          align="right"
                        >
                          Metoda płatności
                        </TableCell>
                        <TableCell
                          className={styles.cell_modalTable}
                          align="right"
                        >
                          Koszt
                        </TableCell>
                        <TableCell
                          className={styles.cell_modalTable}
                          align="right"
                        ></TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody className={styles.tabBody}>
                      {stableSort(
                        refuelingFromOrders,
                        getComparator(order, orderBy)
                      )
                        ?.slice(
                          pageModalTab * rowsPerPageModalTab,
                          pageModalTab * rowsPerPageModalTab +
                            rowsPerPageModalTab
                        )
                        ?.map((row, index) => {
                          return (
                            <TableRow role="checkbox" tabIndex={-1} key={index}>
                              <TableCell className={styles.cell_modalTable}>
                                {row.ts_refueling || "-"}
                              </TableCell>
                              <TableCell
                                className={styles.cell_modalTable}
                                align="right"
                              >
                                {row.car_name || "-"}
                              </TableCell>
                              <TableCell
                                className={styles.cell_modalTable}
                                align="right"
                              >
                                {row.fuel_type || "-"}
                              </TableCell>
                              <TableCell
                                className={styles.cell_modalTable}
                                align="right"
                              >
                                {Number.parseFloat(row.price_of_fuel).toFixed(
                                  2
                                ) || "-"}
                              </TableCell>
                              <TableCell
                                className={styles.cell_modalTable}
                                align="right"
                              >
                                {row.amount_of_fuel || "-"}
                              </TableCell>
                              <TableCell
                                className={styles.cell_modalTable}
                                align="right"
                              >
                                {row.payment_method || "-"}
                              </TableCell>
                              <TableCell
                                className={styles.cell_modalTable}
                                align="right"
                              >
                                {Number.parseFloat(row.cost_of_fuel).toFixed(
                                  2
                                ) || "-"}
                              </TableCell>
                              <TableCell
                                className={styles.cell_modalTable}
                                align="right"
                              >
                                <div
                                  className={styles.buttonBox}
                                  style={
                                    row.files.length > 0
                                      ? { opacity: 1 }
                                      : { opacity: 0.1 }
                                  }
                                >
                                  <IcoBtn
                                    icon="File"
                                    onClick={() => {
                                      if (row.files.length > 0) {
                                        setOpenModalFilesList(true);
                                        setDriverFilesList(row.files);
                                      } else {
                                        props.setDialog({
                                          isOpen: true,
                                          type: "error",
                                          content: `Brak plików do wyświetlenia.`,
                                        });
                                      }
                                    }}
                                  />
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className={styles.tableSummriseRow}>
                  <div>Podsumowanie</div>
                  <div>
                    <b style={{ paddingLeft: "5px" }}>
                      {costSumFuel(refuelingFromOrders).toFixed(2)} PLN
                    </b>
                  </div>
                </div>
                {refuelingFromOrders.length >= 10 && (
                  <div className={`${styles.tableBottom} modalTabPagin`}>
                    <RowsPerPage
                      rowsPerPage={rowsPerPageModalTab}
                      setRowsPerPage={setRowsPerPageModalTab}
                      setPage={setPageModalTab}
                      tableLength={refuelingFromOrders?.length}
                      minRows={10}
                    />
                    <div>
                      <Pagination
                        postsPerPage={rowsPerPageModalTab}
                        totalPosts={refuelingFromOrders?.length}
                        paginate={paginateModalTab}
                        page={pageModalTab}
                      />
                    </div>
                  </div>
                )}
              </Grid>
            )}
            {costFromOrders?.length > 0 && (
              <Grid className={styles.tableBoxInColumnB_full} item xs={12}>
                <h5>Zakupy:</h5>
                <TableContainer>
                  <Table
                    className={styles.modalTable}
                    aria-labelledby="tableTitle"
                  >
                    <TableHead className={styles.tabHead}>
                      <TableRow>
                        <TableCell className={styles.cell_modalTable}>
                          Data
                        </TableCell>
                        <TableCell
                          className={styles.cell_modalTable}
                          align="right"
                        >
                          Typ
                        </TableCell>
                        <TableCell
                          className={styles.cell_modalTable}
                          align="right"
                        >
                          Opis
                        </TableCell>
                        <TableCell
                          className={styles.cell_modalTable}
                          align="right"
                        >
                          Wystawione przez
                        </TableCell>
                        <TableCell
                          className={styles.cell_modalTable}
                          align="right"
                        >
                          Koszt
                        </TableCell>
                        <TableCell
                          className={styles.cell_modalTable}
                          align="right"
                        ></TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody className={styles.tabBody}>
                      {stableSort(costFromOrders, getComparator(order, orderBy))
                        ?.slice(
                          pageModalTab * rowsPerPageModalTab,
                          pageModalTab * rowsPerPageModalTab +
                            rowsPerPageModalTab
                        )
                        .map((row, index) => {
                          return (
                            <TableRow role="checkbox" tabIndex={-1} key={index}>
                              <TableCell className={styles.cell_modalTable}>
                                {moment(row.ts_cost).format(
                                  "YYYY-MM-DD HH:mm:SS"
                                ) || "-"}
                              </TableCell>
                              <TableCell
                                className={styles.cell_modalTable}
                                align="right"
                              >
                                {row.type || "-"}
                              </TableCell>
                              <TableCell
                                className={styles.cell_modalTable}
                                align="right"
                              >
                                {row.description || "-"}
                              </TableCell>
                              <TableCell
                                className={styles.cell_modalTable}
                                align="right"
                              >
                                {row.cost_from || "-"}
                              </TableCell>
                              <TableCell
                                className={styles.cell_modalTable}
                                align="right"
                              >
                                {Number.parseFloat(row.cost_pln).toFixed(2) ||
                                  "-"}
                              </TableCell>
                              <TableCell
                                className={styles.cell_modalTable}
                                align="right"
                              >
                                <div
                                  className={styles.buttonBox}
                                  style={
                                    row.files.length > 0
                                      ? { opacity: 1 }
                                      : { opacity: 0.1 }
                                  }
                                >
                                  <IcoBtn
                                    icon="File"
                                    onClick={() => {
                                      if (row.files.length > 0) {
                                        setOpenModalFilesList(true);
                                        setDriverFilesList(row.files);
                                      } else {
                                        props.setDialog({
                                          isOpen: true,
                                          type: "error",
                                          content: `Brak plików do wyświetlenia.`,
                                        });
                                      }
                                    }}
                                  />
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className={styles.tableSummriseRow}>
                  <div>Podsumowanie</div>
                  <div>
                    <small>
                      {(costSumCosts(costFromOrders) / euroRate).toFixed(2)} EUR
                    </small>{" "}
                    /
                    <b style={{ paddingLeft: "5px" }}>
                      {costSumCosts(costFromOrders).toFixed(2)} PLN
                    </b>
                  </div>
                </div>
                {costFromOrders?.length >= 10 && (
                  <div className={`${styles.tableBottom} modalTabPagin`}>
                    <RowsPerPage
                      rowsPerPage={rowsPerPageModalTab}
                      setRowsPerPage={setRowsPerPageModalTab}
                      setPage={setPageModalTab}
                      tableLength={costFromOrders?.length}
                      minRows={10}
                    />
                    <div>
                      <Pagination
                        postsPerPage={rowsPerPageModalTab}
                        totalPosts={costFromOrders?.length}
                        paginate={paginateModalTab}
                        page={pageModalTab}
                      />
                    </div>
                  </div>
                )}
              </Grid>
            )}
            {tollCosts?.length > 0 && (
              <Grid className={styles.tableBoxInColumnB_full} item xs={12}>
                <h5>Koszty drogowe:</h5>
                <TableContainer>
                  <Table
                    className={styles.modalTable}
                    aria-labelledby="tableTitle"
                  >
                    <TableHead className={styles.tabHead}>
                      <TableRow>
                        <TableCell
                          className={styles.cell_modalTable}
                          align="left"
                        >
                          System
                        </TableCell>
                        <TableCell
                          className={styles.cell_modalTable}
                          align="right"
                        >
                          Koszt
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody className={styles.tabBody}>
                      {stableSort(tollCosts, getComparator(order, orderBy))
                        .slice(
                          pageModalTab * rowsPerPageModalTab,
                          pageModalTab * rowsPerPageModalTab +
                            rowsPerPageModalTab
                        )
                        .map((row, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell
                                className={styles.cell_modalTable}
                                align="left"
                              >
                                {
                                  <img
                                    src={
                                      images[
                                        `${renderFlag(
                                          iso.whereAlpha3(row.countryCode)
                                            .alpha2
                                        )}`
                                      ]
                                    }
                                    alt={row.countryCode}
                                    style={{ marginRight: "5px" }}
                                  ></img>
                                }
                                {row.tollSystem}
                              </TableCell>
                              <TableCell
                                className={styles.cell_modalTable}
                                align="right"
                              >
                                <div className={styles.flexRowMain}>
                                  <div className={styles.flexRow}>
                                    {row.fares[0].price.currency !== "PLN" &&
                                      row.fares[0].price.value.toFixed(2)}
                                    <small>
                                      {row.fares[0].price.currency !== "PLN" &&
                                        row.fares[0].price.currency}
                                    </small>
                                  </div>

                                  <div
                                    className={styles.flexRow}
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {row.fares[0].price.currency !== "PLN"
                                      ? exchangeToPLN(
                                          row.fares[0].price.value,
                                          row.fares[0].price.currency
                                        )
                                      : row.fares[0].price.value.toFixed(2)}
                                    <small>{"PLN"}</small>
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className={styles.tableSummriseRow}>
                  <div>Podsumowanie</div>
                  <div>
                    <small>{(tollCostsSum / euroRate).toFixed(2)} EUR</small> /
                    <b style={{ paddingLeft: "5px" }}>
                      {tollCostsSum.toFixed(2)} PLN
                    </b>
                  </div>
                </div>
                {tollCosts.length > 10 && (
                  <div className={`${styles.tableBottom} modalTabPagin`}>
                    <RowsPerPage
                      rowsPerPage={rowsPerPageModalTab}
                      setRowsPerPage={setRowsPerPageModalTab}
                      setPage={setPageModalTab}
                      tableLength={tollCosts?.length}
                      minRows={tollCosts.length}
                    />
                    <div>
                      <Pagination
                        postsPerPage={rowsPerPageModalTab}
                        totalPosts={tollCosts?.length}
                        paginate={paginateModalTab}
                        page={pageModalTab}
                      />
                    </div>
                  </div>
                )}
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={BTNstyles.btn}
            onClick={(e) => {
              setOpenModalCostFuel(false);
              cleanFetch();
            }}
          >
            Zamknij
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openModalFilesList}
        fullWidth={true}
        maxWidth={"md"}
        TransitionComponent={Transition}
        onClose={() => {
          setOpenModalFilesList(false);
          setDriverFilesList([]);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Lista plików
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              setOpenModalFilesList(false);
              setDriverFilesList([]);
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table
              className={styles.modalTable}
              aria-labelledby="tableTitle"
              size="small"
            >
              <TableHead className={styles.tabHead}>
                <TableRow>
                  <TableCell className={styles.cell_modalTable}>
                    Nazwa pliku
                  </TableCell>
                  <TableCell className={styles.cell_modalTable}>Data</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={styles.tabBody}>
                {driverFilesList &&
                  driverFilesList.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          className={styles.cell_modalTable}
                          align="left"
                        >
                          {row.name}{" "}
                        </TableCell>
                        <TableCell
                          className={styles.cell_modalTable}
                          align="left"
                        >
                          {row.ts}{" "}
                        </TableCell>
                        <TableCell
                          className={styles.cell_modalTable}
                          align="right"
                        >
                          <div className={styles.buttonBox}>
                            <IcoBtn
                              tooltip="Zobacz plik"
                              value={row}
                              onClick={() => {
                                window.open(
                                  `${DBurl}/getFile?filename=${row.name}`,
                                  "_blank"
                                );
                              }}
                            >
                              <File tooltip="Zobacz plik" />
                            </IcoBtn>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={BTNstyles.btn}
            onClick={(e) => {
              setOpenModalFilesList(false);
              setDriverFilesList([]);
            }}
          >
            Zamknij
          </Button>
        </DialogActions>
      </Dialog>
      {isLoading && <TmsLoading status="Pobieranie zleceń" />}
    </>
  );
}
