import React from 'react'
import Button from '@material-ui/core/Button';

export default function RowPerPage({ rowsPerPage, setRowsPerPage, setPage, tableLength, minRows }) {
  let minRowsDefault = minRows || 10
  return (
    <div>
      <Button
        size="small"
        variant={rowsPerPage <= minRowsDefault ? 'contained' : "outlined"}
        color={rowsPerPage <= minRowsDefault ? 'secondary' : "primary"}
        value="10"
        state={rowsPerPage <= minRowsDefault ? 'on' : 'off'}
        onClick={() => {
          setRowsPerPage(minRowsDefault)
          setPage(0)
        }}>{minRowsDefault}
      </Button>
      {tableLength > minRowsDefault ?
        <Button
          size="small"
          variant={rowsPerPage > minRowsDefault && rowsPerPage <= 20 ? 'contained' : "outlined"}
          color={rowsPerPage > minRowsDefault && rowsPerPage <= 20 ? 'secondary' : "primary"}
          value="20"
          state={rowsPerPage > minRowsDefault && rowsPerPage <= 20 ? 'on' : 'off'}
          onClick={() => {
            setRowsPerPage(tableLength > 20 ? 20 : tableLength)
            setPage(0)
          }}>{tableLength > 20 ? 20 : tableLength}
        </Button> : null}
      {tableLength > 20 ?
        <Button
          size="small"
          variant={rowsPerPage > 20 && rowsPerPage <= 40 ? 'contained' : "outlined"}
          color={rowsPerPage > 20 && rowsPerPage <= 40 ? 'secondary' : "primary"}
          value="40"
          state={rowsPerPage > 20 && rowsPerPage <= 40 ? 'on' : 'off'}
          onClick={() => {
            setRowsPerPage(tableLength > 40 ? 40 : tableLength)
            setPage(0)
          }}>{tableLength > 40 ? 40 : tableLength}
        </Button> : null}
      {tableLength > 40 ?
        <Button
          size="small"
          variant={rowsPerPage > 40 && rowsPerPage <= 80 ? 'contained' : "outlined"}
          color={rowsPerPage > 40 && rowsPerPage <= 80 ? 'secondary' : "primary"}
          state={rowsPerPage > 40 && rowsPerPage <= 80 ? 'on' : 'off'}
          value="80"
          onClick={() => {
            setRowsPerPage(tableLength > 80 ? 80 : tableLength)
            setPage(0)
          }}>{tableLength > 80 ? 80 : tableLength}
        </Button> : null}
      {tableLength > 80 ?
        <Button
          size="small"
          variant={rowsPerPage > 80 ? 'contained' : "outlined"}
          color={rowsPerPage > 80 ? 'secondary' : "primary"}
          state={rowsPerPage > 80 ? 'on' : 'off'}
          value="120"
          onClick={() => {
            setRowsPerPage(tableLength > 120 ? 120 : tableLength)
            setPage(0)
          }}>{tableLength > 120 ? 120 : tableLength}</Button> : null}
    </div>
  )
}
