import React from 'react'
import Grid from '@material-ui/core/Grid';
import Input from '../../components/input'
import styles from '../../styles/newOrEdit.module.css'
import { XCircle, PlusCircle } from 'react-feather';
import IconButton from '@material-ui/core/IconButton';
import { AppName, ContactEmail } from 'appConfig';

export default function Contact(props) {
	const error = props.error
	return (
		<>
			<Grid item xs={2} className={`${styles.WOHead} ${styles.secondClass}`}>
				<div className={styles.inp}>
					{props.propKey === 0 ? <label>Imię</label> : null}
					<Input 
						value={props.contacts.firstname} 
						handleChange={(e) => { props.handleContact (e.target.value, e.target.name, props.propKey, props.warehouseKey) }} 
						name="firstname" 
						disabled={props.isDisabled ? true : false} 
						// error={!props.contacts.firstname && error}
						/>
				</div>
			</Grid>
			<Grid item xs={2} className={`${styles.WOHead} ${styles.secondClass}`}>
				<div className={styles.inp}>
					{props.propKey === 0 ? <label>Nazwisko</label> : null}
					<Input 
						value={props.contacts.surname} 
						handleChange={(e) => { props.handleContact (e.target.value,e.target.name, props.propKey, props.warehouseKey) }}
						name="surname" 
						disabled={props.isDisabled ? true : false} 
						// error={!props.contacts.surname && error}
					/>
				</div>
			</Grid>
			<Grid item xs={2} className={`${styles.WOHead} ${styles.secondClass}`}>
				<div className={styles.inp}>
					{props.propKey === 0 ? <label>Stanowisko</label> : null}
					<Input 
						value={props.contacts.role} 
						handleChange={(e) => { props.handleContact (e.target.value,e.target.name, props.propKey, props.warehouseKey) }}
						name="role" 
						disabled={props.isDisabled ? true : false}
					/>
				</div>
			</Grid>
			<Grid item xs={1} className={`${styles.WOHead} ${styles.secondClass}`}>
				<div className={styles.inp}>
					{props.propKey === 0 ? <label>Nr. tel.</label> : null}
					<Input 
						value={props.contacts.phone} 
						handleChange={(e) => { props.handleContact (e.target.value,e.target.name, props.propKey, props.warehouseKey) }}
						name="phone" 
						disabled={props.isDisabled ? true : false}
						// error={!(props.contacts.phone || props.contacts.email) && error}
					/>
				</div>
			</Grid>
			<Grid item xs={2} className={`${styles.WOHead} ${styles.secondClass}`}>
				<div className={styles.inp}>
					{props.propKey === 0 ? <label>Email</label> : null}
					<Input 
						value={props.contacts.email} 
						handleChange={(e) => { props.handleContact (e.target.value,e.target.name, props.propKey, props.warehouseKey) }}
						name="email" email mailFunc={e => {
							window.open(`mailto:${props.contacts.email}?subject=Zapytanie z ${AppName}TMS`, 'emailWindow')
						}} 
						disabled={props.isDisabled ? true : false}
						// error={!(props.contacts.phone || props.contacts.email) && error}
					/>
				</div>
			</Grid>
			<Grid item xs={3} className={`${styles.WOHead} ${styles.iconsAddRemove} ${styles.secondClass}`}>
				<IconButton className={styles.btnIcon} value={props.propKey} onClick={() => { props.removeContact(props.propKey, props.warehouseKey) }}>
					<XCircle className={styles.remove} />
				</IconButton>
				<IconButton className={styles.btnIcon} onClick={() => {props.addContact(props.warehouseKey)}} value={props.propKey}  >
					<PlusCircle className={styles.add} />
				</IconButton>
			</Grid>
		</>
	)
}
