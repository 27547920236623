import styles from './tabBar.module.css'
import { X } from 'react-feather';
import './tabBar.module.css'

import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


function a11yProps(index) {
	return {
		id: `scrollable-auto-tab-${index}`,
		'aria-controls': `scrollable-auto-tabpanel-${index}`,
	};
}

export default function TabBar(props) {

	let tab;
	if (props.tabs.length > 0) {
		tab = props.tabs?.map((el, index) => {
			var active;
			if (el.active) { active = styles.active }
			return <Tab className={`${styles.tab} ${active}`}
				key={index}
				label={el.tabName}
				{...a11yProps(index)}
				onClick={() => { props.changeTab(index) }}
				icon={<X size={14} 
					onClick={() => {
					props.removeTab(index)
				}} />
			}/>
		});
	} else {
		tab = []
	}

	// useEffect(() => {

	// },[props.value])

	// useEffect(() => {
	// 	props.tabs.forEach((e, i) => {
	// 		if (e.active) { setValue(i) }
	// 	});
	// }, [])

	return (

		<div>
			<AppBar position="static" color="default">

				<Tabs
					value={props.tabIdx}
					className={styles.tabbar}
					// onChange={handleChange}
					indicatorColor="primary"
					// TabIndicatorProps={{
					// 	style: {
					// 		display: "none",
					// 	},
					//   }}
					textColor="primary"
					variant="scrollable"
					scrollButtons="auto"
					aria-label="scrollable auto tabs example"
				>
					{tab}
				</Tabs>
			</AppBar>

		</div>
	);
}