import React, { useState, useEffect } from "react";
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Snackbar,
} from "@material-ui/core";
import { DBurl } from "../appConfig";
import stylesMod from "../styles/newOrEdit.module.css";
import BTNstyles from "../styles/button.module.css";
import CiclesRepStyles from "./ciclesStyles.module.css";
import Grid from "@material-ui/core/Grid";
import { styled } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import { Add } from "@material-ui/icons";
import { X } from "react-feather";
import Alert from "@material-ui/lab/Alert";
import { Switch, FormControlLabel } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#129865",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#129865",
  },
  "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
    backgroundColor: "#129865",
  },
}));

export default function CiclicIsEnabledDialog(props) {
  const [checkEnabledReport, setCheckEnabledReport] = useState(
    props.data?.is_enabled || ""
  );

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");

  const clear = () => {
    setCheckEnabledReport(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDialog(false);
  };

  async function enableCycleReports(data) {
    await fetch(`${DBurl}/enableCycleReports`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "update",
        data: {
          ...data,
        },
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setOpenDialog(true);
          setDialogContent(
            `Raport cykliczny został ${
              checkEnabledReport ? "włączony" : "wyłączony"
            }`
          );
          setDialogColor(`${checkEnabledReport ? "success" : "warning"}`);
        } else {
          setOpenDialog(true);
          setDialogContent(
            `${data.responseDescription} // ${DataTransferItemList.response}  `
          );
          setDialogColor("error");
        }
      });
  }

  useEffect(() => {
    if (props.data?.id) {
      setCheckEnabledReport(props.data.is_enabled);
    }
  }, [props.data?.id_driver]);

  return (
    <>
      <Dialog
        open={props.openModalIsEnabledCiclic}
        TransitionComponent={Transition}
        onClose={() => {
          props.setOpenModalIsEnabledCiclic(false);

          clear();
          props.refresh();
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Włącz/wyłącz raport cykliczny
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              props.setOpenModalIsEnabledCiclic(false);
              clear();
              props.refresh();
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {props.data?.id_driver && (
            <div className={`${CiclesRepStyles.title}`}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className={stylesMod.inp}>
                    <label>Kierowca</label>
                    <h3>
                      {props.selectedDriverFullName ||
                        props.data?.driver_name ||
                        ""}
                    </h3>
                  </div>
                </Grid>
              </Grid>
            </div>
          )}

          <div className={`${CiclesRepStyles.title}`}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className={stylesMod.inp}>
                  <FormControlLabel
                    className={stylesMod.smallerFont}
                    control={
                      <GreenSwitch
                        checked={checkEnabledReport}
                        color="primary"
                        onChange={(e) => {
                          setCheckEnabledReport(e.target.checked);
                        }}
                      />
                    }
                    label={
                      checkEnabledReport === false ? "wyłączony" : "włączony"
                    }
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={BTNstyles.btn}
            onClick={(e) => {
              props.setOpenModalIsEnabledCiclic(false);
              clear();
              props.refresh();
            }}
          >
            Anuluj
          </Button>
          <Button
            variant="contained"
            size="large"
            className={`${BTNstyles.btn} ${BTNstyles.grn}`}
            onClick={() => {
              const data = {
                id: props.data.id,
                is_enabled: checkEnabledReport,
              };
              props.setOpenModalIsEnabledCiclic(false);
              enableCycleReports(data).then(() => props.refresh());
            }}
            color="primary"
          >
            Dodaj <Add className={BTNstyles.downBtnIco} />
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={dialogColor}>
          {dialogContent}
        </Alert>
      </Snackbar>
    </>
  );
}
