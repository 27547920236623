import React, { useState, useEffect } from 'react'
import stylesMod from '../../styles/newOrEdit.module.css'
import Input from '../../components/input'
import Select from 'react-select'
import { DBurl } from '../../appConfig'
import BTNstyles from '../../styles/button.module.css'
import { Add } from '@material-ui/icons';
import { X } from 'react-feather';
import ShortList from '../../components/ShortList'

import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, Slide, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Paper } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CarsToUserModal(props) {
  const [vehicles, setVehicles] = useState([])
  const [checked, setChecked] = useState([])
  const [wasGet, setWasGet] = useState(false)

  const getCars = async function () {
    const response = await fetch(`${DBurl}/getCarsToLinkUser/`, {
      method: "GET",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token
      },
    })
    const json = await response.json()
    if (json.success) {
      const tempCarNames = json.data.map((e, i) => {
        return {
          ...e,
          title: e.name
        }
      })
      setVehicles(tempCarNames)
    } else {
      props.setOpenSnack(true)
      props.setSnackContent(`${json.responseDescription} // ${json.response}  `)
      props.setSnackColor("error")
    }
  }

  useEffect(() => {
    getCars()
  }, [])

  useEffect(() => {
    !!props.data?.id && getCarsToUsers(props.user.csrf_token, props.data?.id)
  }, [props.data?.id])


  const getCarsToUsers = async function (token,id) {
    const response = await fetch(`${DBurl}/getCarsToUsers?id_user=${id}`, {
      method: "GET",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      },
    })
    const json = await response.json()
    if (json.success) {
      setChecked(json.data.map(e=>e.id))
      checked.length>0 && setWasGet(true)
    } else {
      props.setOpenSnack(true)
      props.setSnackContent(`${json.responseDescription} // ${json.response}  `)
      props.setSnackColor("error")
    }
  }

  const setCarsToUser = async (id, token, data, type) => {

    const response = await fetch(`${DBurl}/setCarsToUsers`, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      },
      body: JSON.stringify({
        mode: type,
        data: {
          id_user: id,
          car_ids: data
        }
      })
    });
    const json = await response.json()
    if (json.success) {
      props.setOpenSnack(true)
      props.setSnackContent(`Zapisane poprawnie`)
      props.setSnackColor("success")
      props.setOpen(false)
    } else {
      props.setOpenSnack(true)
      props.setSnackContent(`${json.responseDescription} // ${json.response}  `)
      props.setSnackColor("error")
    }

  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    props.setOpen(false)
  }


  return (
    <Dialog
      maxWidth='md'
      fullWidth={true}
      open={props.open}
      // className={stylesMod.noOverflowGrand}
      TransitionComponent={Transition}
      onClose={() => {
        props.setOpen(false)
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title" className={stylesMod.dialogHead}>
        Przypisz pojazdy
        <IconButton
          aria-label="close"
          className={stylesMod.closeButton}
          onClick={() => {
            props.setOpen(false)
          }}
        >
          <X />
        </IconButton>
      </DialogTitle >
      <DialogContent className={stylesMod.noOverflow}>
        <Grid container spacing={2} >
          <Grid item xs={12}>
            <p>
              Uprawnienia użytkownikas: {props.data?.firstname} {props.data?.surname}
            </p>
          </Grid>
        </Grid>
        <ShortList
          user={props.user}
          data={vehicles}
          title={'Nazwa'}
          TSDdata={checked}
          setTSDopen={props.setOpen}
          setTSDdata={setChecked}
          propSelected
          car
          multiCheck
          noTitle
        />

      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="large" color="primary" className={`${BTNstyles.btn} ${BTNstyles.err}`} onClick={() => {
          // clear()
          props.setOpen(false)
        }}>
          Anuluj
			</Button>
        <Button
          variant="contained"
          size="large"
          className={`${BTNstyles.btn} ${BTNstyles.grn}`}
          onClick={(e) => {
            setCarsToUser(props.data.id, props.user.csrf_token, checked, wasGet ? "update": "insert")
          }}
          color="primary">
          Zapisz <Add className={BTNstyles.downBtnIco} />
        </Button>
      </DialogActions>

    </Dialog >
  )
}
