import React, { useState, useRef } from "react";
import { useMutation } from "react-query";
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { X, UploadCloud } from "react-feather";
import stylesMod from "styles/newOrEdit.module.css";
import BTNstyles from "styles/button.module.css";
import { uploadFile } from "api/endpoints";
import ImportTable from "./ImportTable";
import { TmsDialogState } from "types";

type Props = {
  importModal: { isOpen: boolean };
  setImportModal: (arg: { isOpen: boolean }) => void;
  refetchFuelCards: () => void;
  setDialog: (dialog: TmsDialogState) => void;
};

const ImportModal = ({
  importModal,
  setImportModal,
  refetchFuelCards,
  setDialog,
}: Props) => {
  const [filename, setFileName] = useState();
  const [btnSend, setBtnSend] = useState(false);
  const [files, setFiles] = useState<any>([]);

  const fileInput = useRef(null);

  const { mutate: upload } = useMutation("uploadFile", uploadFile, {
    onSuccess: (response) => {
      if (response) {
        setFileName(response.filename);
        setBtnSend(true);
      }
    },
    onError: () => {
      setDialog({
        isOpen: true,
        type: "error",
        content: `Nie udało się wysłać pliku.`,
      });
    },
  });

  const fetchFile = (files: any) => {
    const formData = new FormData();
    const file = files[0][0];
    formData.append("file", file, file.name);

    upload(formData);
  };

  const handleFileSendApi = () => {
    files.length > 0 && fetchFile(files);
  };

  const handleClose = () => {
    setImportModal({ isOpen: false });
    setFileName(undefined);
    setBtnSend(false);
    setFiles([]);
  };

  return (
    <Dialog
      open={importModal.isOpen}
      onClose={() => {
        setImportModal({ isOpen: false });
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth={"lg"}
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        className={stylesMod.dialogHead}
      >
        Importuj plik kart paliwowych
        <IconButton
          aria-label="close"
          className={stylesMod.closeButton}
          onClick={() => {
            setImportModal({ isOpen: false });
          }}
        >
          <X />
        </IconButton>
      </DialogTitle>
      {filename ? (
        <ImportTable
          filename={filename}
          refetchFuelCards={refetchFuelCards}
          setDialog={setDialog}
          handleClose={handleClose}
        />
      ) : (
        <>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid className={"col2"} container spacing={2}>
                  <Grid item xs={btnSend ? 11 : 12}>
                    <input
                      accept=".xlsx, .xls, .csv"
                      style={{ display: "none" }}
                      id="raised-button-file"
                      type="file"
                      ref={fileInput}
                      onChange={(e) => {
                        setFiles([...files, e.target.files]);
                        setBtnSend(true);
                      }}
                    />
                    <label htmlFor="raised-button-file">
                      <Button
                        variant="outlined"
                        color="primary"
                        component="div"
                        className="fileBtn"
                      >
                        {files.length > 0
                          ? files[0][0]?.name
                          : "Wybierz pliki z komputera (.xlsx, .xls, .csv)..."}

                        <Button
                          onClick={() => {
                            setBtnSend(false);
                          }}
                          style={
                            files.length > 0
                              ? { display: "flex" }
                              : { display: "none" }
                          }
                        >
                          <X />
                        </Button>
                      </Button>
                    </label>
                  </Grid>
                  {btnSend && (
                    <Grid item xs={1}>
                      <Button
                        onClick={handleFileSendApi}
                        color="secondary"
                        className={`${BTNstyles.btn} ${BTNstyles.grn}`}
                        style={
                          btnSend === true
                            ? {
                                display: "inline-flex",
                                margin: 0,
                                width: "100%",
                                maxHeight: "36px",
                              }
                            : {
                                display: "none",
                                margin: 0,
                                width: "100%",
                                maxHeight: "36px",
                              }
                        }
                      >
                        Wyślij plik
                        <UploadCloud
                          style={{ marginLeft: "10px", width: "18px" }}
                        />
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ padding: "8px 13px" }}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={BTNstyles.btn}
              onClick={handleClose}
            >
              Anuluj
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default ImportModal;
