import axios from "axios";
import { DBurl } from "appConfig";

const api = axios.create({
  baseURL: DBurl,
  withCredentials: true,
});

api.interceptors.request.use(
  function (config, error) {
    const token =
      sessionStorage?.getItem("user") &&
      JSON.parse(sessionStorage?.getItem("user") || "").csrf_token;

    if (token) {
      config.headers["X-CSRF-Token"] = token;
    }

    return config;
  },

  function (error) {
    return Promise.reject(error);
  }
);

export default api;
