import React, { useState, useEffect } from "react";
import Input from "components/input";
import styles from "styles/invoice.module.css";
import { TableCell, TableRow } from "@material-ui/core";
import IcoBtn from "components/buttons/icoBtn";
import Select from "react-select";
import { DBurl } from "../../../appConfig";

const ServicesTableRow = ({
  handleDeleteService,
  service,
  token,
  orders,
  handleChangeVat,
  handleChangeServices,
  servicesOptions,
  buyer,
  documentType,
  typeInputValue,
  disabled = false,
  isCorrection,
  error,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");
  const [vatOptions, setVatOptions] = useState([]);
  const [vatRate, setVatRate] = useState(0);
  const [orderNum, setOrderNum] = useState(0);
  const [serviceName, setServiceName] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [netPrice, setNetPrice] = useState(0);
  const [netAmount, setNetAmount] = useState(0);
  const [vatAmount, setVatAmount] = useState(0);
  const [grossAmount, setGrossAmount] = useState(0);
  const [noteText, setNoteText] = useState(service?.name || "");
  const [noteAmount, setNoteAmount] = useState(
    parseFloat(service?.value_brutto) || 0
  );

  useEffect(() => {
    if (service?.id) {
      const { id_order } = service;
      const order = orders.find((ord) => ord.id === id_order);
      setOrderNum(order);
      setServiceName({ ...service, label: service?.name });
      setQuantity(service?.quantity);
      setNetPrice(service?.price_netto);
      setVatRate(service?.vat);
      if (service?.vat === 0 || service?.vat) {
        handleChangeVat(service?.vat, "remove");
        handleChangeVat(service?.vat, "add");
      }
    }
  }, [service, orders?.length]);

  const getNetAmount = () => {
    setNetAmount(
      (netPrice * quantity)?.toFixed(2) !== "NaN"
        ? (netPrice * quantity)?.toFixed(2)
        : 0
    );
    netPrice * quantity > 0 &&
      handleChangeServices(service.id, "value_netto", netPrice * quantity);
  };

  const getVatAmount = () => {
    if (isNaN(vatRate)) {
      setVatAmount(0);
      return;
    }
    setVatAmount(
      ((netAmount * vatRate) / 100)?.toFixed(2) !== "NaN"
        ? ((netAmount * vatRate) / 100)?.toFixed(2)
        : 0
    );
  };

  const getGrossAmount = () => {
    setGrossAmount(
      (Number(netAmount) + Number(vatAmount))?.toFixed(2) !== "NaN"
        ? (Number(netAmount) + Number(vatAmount))?.toFixed(2)
        : 0
    );
    parseFloat(netAmount) + parseFloat(vatAmount) > 0 &&
      handleChangeServices(
        service.id,
        "value_brutto",
        parseFloat(netAmount) + parseFloat(vatAmount)
      );
  };

  useEffect(() => {
    getNetAmount();
    getVatAmount();
    getGrossAmount();
  }, [netPrice, quantity, netAmount, vatRate, vatAmount]);

  const select_styles = {
    control: (base, state) => ({
      ...base,
      border: "1px solid #142f42",
      boxShadow: "none",
      width: "100%",
      "&:hover": {
        border: "1px solid #142f42",
      },
      width: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused && "#142f42",
      color: state.isFocused && "#fff",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 10 }),
  };

  const getVat = async () => {
    const response = await fetch(`${DBurl}/getVatRates/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setVatOptions(
        json.data.map((e) => {
          return {
            value: e.id,
            label: e.rate ? String(e.rate) : "0",
            rate: String(e.rate),
            id: e.id,
            selName: "vat",
          };
        })
      );
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
    }
  };

  useEffect(() => {
    getVat();
  }, []);

  const NP = { value: "NP", label: "NP", rate: "NP", id: "NP", selName: "vat" };

  const VatSelector = () => {
    return (
      <Select
        value={{
          label: vatRate?.rate || vatRate,
          value: vatRate?.rate || vatRate,
        }}
        menuPortalTarget={document.body}
        options={vatOptions.concat(NP)}
        onChange={(e) => {
          setVatRate((prevVat) => {
            prevVat && handleChangeVat(prevVat, "remove");
            handleChangeVat(e.rate, "add");
            handleChangeServices(service.id, "vat", e.rate);
            return e.rate;
          });
        }}
        name="vat_rate"
        placeholder=""
        getOptionValue={(option) => `${option.rate}`}
        styles={select_styles}
        className={
          error && !vatRate ? `${styles.selectError}` : `${styles.select}`
        }
      />
    );
  };

  return (
    <>
      {documentType?.value === 1 && (
        <TableRow>
          <TableCell align="left">
            <Select
              isDisabled={isCorrection}
              value={orderNum}
              menuPortalTarget={document.body}
              options={orders.filter((order) => order.id_invoice === null)}
              // options={orders.filter(order => order.status === 0 && order.id_invoice === null)}
              onChange={(e) => {
                handleChangeServices(service.id, "id_order", e.id);
                setOrderNum(e);
              }}
              name="orderNum"
              placeholder="Wybierz..."
              getOptionValue={(order) => `${order.order_no}`}
              styles={select_styles}
              className={
                error && !orderNum
                  ? `${styles.selectError}`
                  : `${styles.select}`
              }
            />
          </TableCell>
          <TableCell align="left">
            <Select
              isDisabled={disabled}
              value={serviceName}
              menuPortalTarget={document.body}
              options={servicesOptions}
              onChange={(e) => {
                handleChangeServices(service.id, "name", e.label);
                setServiceName(e);
              }}
              name="services"
              placeholder="Wybierz..."
              getOptionValue={(option) => `${option.label}`}
              styles={select_styles}
              className={
                error && serviceName.label === undefined
                  ? `${styles.selectError}`
                  : `${styles.select}`
              }
            />
          </TableCell>
          <TableCell align="right">
            <Input
              type="number"
              min="0"
              disabled={disabled}
              value={quantity}
              handleChange={(e) => {
                setQuantity(e.target.value >= 0 ? e.target.value : 0);
                handleChangeServices(
                  service.id,
                  "quantity",
                  parseInt(e.target.value >= 0 ? e.target.value : 0)
                );
              }}
              name={"quantity"}
              error={error && !quantity}
            />
          </TableCell>
          <TableCell align="right">
            <Input
              disabled={disabled}
              value={netPrice}
              handleChange={(e) => {
                setNetPrice(e.target.value);
                handleChangeServices(
                  service.id,
                  "price_netto",
                  parseInt(e.target.value)
                );
              }}
              name={"price_netto"}
              error={error && !netPrice}
            />
          </TableCell>
          <TableCell align="right">
            <VatSelector />
          </TableCell>
          <TableCell align="right">
            <Input value={netAmount} disabled />
          </TableCell>
          <TableCell align="right">
            <Input value={vatAmount} disabled />
          </TableCell>
          <TableCell align="right">
            <Input value={grossAmount} disabled />
          </TableCell>
          {!isCorrection && (
            <TableCell align="right">
              <div className={styles.buttonBox}>
                <IcoBtn
                  icon="X"
                  tooltip="Usuń"
                  className="btn-delete"
                  onClick={() => {
                    (vatRate?.rate || vatRate > -1) &&
                      handleChangeVat(vatRate?.rate || vatRate, "remove");
                    handleDeleteService(service.id);
                    handleChangeServices(service.id, "removeAllRow", null);
                  }}
                />
              </div>
            </TableCell>
          )}
        </TableRow>
      )}

      {documentType?.value === undefined ? (
        <TableRow>
          <TableCell align="left" className={styles.statusLong}>
            <Select
              isDisabled={isCorrection}
              value={orderNum}
              menuPortalTarget={document.body}
              options={orders.filter((order) => order.id_invoice === null)}
              // options={orders.filter(order => order.status === 0 && order.id_invoice === null)}
              onChange={(e) => {
                handleChangeServices(service.id, "id_order", e.id);
                setOrderNum(e);
              }}
              name="orderNum"
              placeholder="Wybierz..."
              getOptionValue={(order) => `${order.order_no}`}
              styles={select_styles}
              className={
                error && !orderNum
                  ? `${styles.selectError}`
                  : `${styles.select}`
              }
            />
          </TableCell>
          <TableCell align="left" className={styles.statusLong}>
            <Select
              value={serviceName}
              menuPortalTarget={document.body}
              options={servicesOptions}
              onChange={(e) => {
                handleChangeServices(service.id, "name", e.label);
                setServiceName(e);
              }}
              name="services"
              placeholder="Wybierz..."
              getOptionValue={(option) => `${option.label}`}
              styles={select_styles}
              className={
                error && serviceName.label === undefined
                  ? `${styles.selectError}`
                  : `${styles.select}`
              }
            />
          </TableCell>
          <TableCell align="right" className={styles.statusShort}>
            <Input
              value={quantity}
              handleChange={(e) => {
                setQuantity(e.target.value);
                handleChangeServices(
                  service.id,
                  "quantity",
                  parseInt(e.target.value)
                );
              }}
              name={"quantity"}
              error={error && !quantity}
            />
          </TableCell>
          <TableCell align="right" className={styles.statusShort}>
            <Input
              value={netPrice}
              handleChange={(e) => {
                setNetPrice(e.target.value);
                handleChangeServices(
                  service.id,
                  "price_netto",
                  parseInt(e.target.value)
                );
              }}
              name={"price_netto"}
              error={error && !netPrice}
            />
          </TableCell>
          <TableCell align="right" className={styles.statusShort}>
            <VatSelector />
          </TableCell>
          <TableCell align="right" className={styles.statusShort}>
            <Input value={netAmount} disabled />
          </TableCell>
          <TableCell align="right" className={styles.statusShort}>
            <Input
              value={quantity && netPrice ? vatAmount.toString() : ""}
              disabled
            />
          </TableCell>
          <TableCell align="right" className={styles.statusShort}>
            <Input value={grossAmount} disabled />
          </TableCell>
          {!isCorrection && (
            <TableCell align="right">
              <div className={styles.buttonBox}>
                <IcoBtn
                  icon="X"
                  tooltip="Usuń"
                  className="btn-delete"
                  onClick={() => {
                    (vatRate?.rate || vatRate > -1) &&
                      handleChangeVat(vatRate?.rate || vatRate, "remove");
                    handleDeleteService(service.id);
                    handleChangeServices(service.id, "removeAllRow", null);
                  }}
                />
              </div>
            </TableCell>
          )}
        </TableRow>
      ) : (
        <></>
      )}

      {documentType?.value === 4 && (
        <TableRow>
          <TableCell align="left">
            <Input
              value={noteText}
              handleChange={(e) => {
                setNoteText(e.target.value);
                handleChangeServices(service.id, "name", e.target.value);
              }}
              name={"name"}
              error={error && !noteText}
            />
          </TableCell>
          <TableCell align="left">
            <Input
              type={"number"}
              value={noteAmount}
              handleChange={(e) => {
                setNoteAmount(e.target.value);
                handleChangeServices(
                  service.id,
                  "value_brutto",
                  e.target.value
                );
              }}
              error={error && !noteAmount}
            />
          </TableCell>
          <TableCell align="right">
            <div className={styles.buttonBox}>
              <IcoBtn
                icon="X"
                tooltip="Usuń"
                className="btn-delete"
                onClick={() => {
                  (vatRate?.rate || vatRate > -1) &&
                    handleChangeVat(vatRate?.rate || vatRate, "remove");
                  handleDeleteService(service.id);
                  handleChangeServices(service.id, "removeAllRow", null);
                }}
              />
            </div>
          </TableCell>
        </TableRow>
      )}

      {documentType?.value === 5 && (
        <TableRow>
          <TableCell align="left">
            <Input
              value={noteText}
              handleChange={(e) => {
                setNoteText(e.target.value);
                handleChangeServices(service.id, "name", e.target.value);
              }}
              name={"name"}
              error={error && !noteText}
            />
          </TableCell>
          <TableCell align="left">
            <Input
              type={"number"}
              value={noteAmount}
              handleChange={(e) => {
                setNoteAmount(e.target.value);
                handleChangeServices(
                  service.id,
                  "value_brutto",
                  e.target.value
                );
              }}
              error={error && !noteAmount}
            />
          </TableCell>
          <TableCell align="right">
            <div className={styles.buttonBox}>
              <IcoBtn
                icon="X"
                tooltip="Usuń"
                className="btn-delete"
                onClick={() => {
                  (vatRate?.rate || vatRate > -1) &&
                    handleChangeVat(vatRate?.rate || vatRate, "remove");
                  handleDeleteService(service.id);
                  handleChangeServices(service.id, "removeAllRow", null);
                }}
              />
            </div>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default ServicesTableRow;
