import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { DBurl } from "appConfig";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import moment from "moment";
import { getDriversCostsReports } from "api/endpoints";
import TmsLoading from "components/TmsLoading";
import { perPageButtons } from "containers/order/utilities/constants";
import OrderPerPageButton from "containers/order/OrderPerPageButton";
import Pagination from "components/pagination";
import IcoBtn from "components/buttons/icoBtn";
import SettlementConfirmModal from "./SettlementConfirmModal";
import { setDriversCostsReports } from "api/endpoints";

import styles from "containers/table.module.css";
import "./styles.sass";

const Settlements = ({
  handleNewSettlement,
  handleEditSettlement,
  setDialog,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [page, setPage] = useState(0);
  const [confirmModal, setConfirmModal] = useState({ isOpen: false });

  const paginate = (pageNumber) => setPage(pageNumber - 1);

  const parseDate = (date) => {
    let newD = moment(date).valueOf();
    let year = moment(newD).format("yyyy");
    let month = moment(newD).format("MM");

    return `${year} - ${month}`;
  };

  const {
    data: raports,
    isLoading,
    refetch: refetchRaports,
  } = useQuery(["getDriversCostsReports"], () => getDriversCostsReports());

  const handleDownloadReportXLS = (date) => {
    let newD = moment(date).valueOf();
    let year = moment(newD).format("yyyy");
    let month = moment(newD).format("MM");

    const fileUrl = `${DBurl}/getDriversCostsReportXLS?year=${year}&month=${month}`;

    window.open(fileUrl, "_blank");
  };

  const { mutate: updateRaport } = useMutation(
    "setDriversCostsReports",
    setDriversCostsReports,
    {
      onSuccess: () => {
        setDialog({
          isOpen: true,
          type: "success",
          content: "Raport został potwierdzony",
        });
        setConfirmModal({ isOpen: false });
        refetchRaports();
      },
    }
  );

  const handleConfirm = () => {
    updateRaport({ id: confirmModal.id, is_confirmed: true });
  };

  const generateTableCells = () => {
    return Array.from({ length: 10 }, (_, index) => <TableCell key={index} />);
  };

  if (isLoading) {
    return <TmsLoading />;
  }

  return (
    <>
      <div className="drivers-settlement">
        <div className="drivers-settlement-header">
          <div className="drivers-settlement-header--left">
            <h1 className="drivers-settlement-header__title">
              Rozliczenia kierowców
            </h1>
            <div className="drivers-settlement-header__buttons"></div>
          </div>
          <div className="drivers-settlement-header--right">
            <button
              className="drivers-settlement-header__button"
              onClick={handleNewSettlement}
            >
              Nowe rozliczenie
            </button>
          </div>
        </div>
        <TableContainer className="drivers-settlement-table">
          <Table stickyHeader>
            <TableHead className="drivers-settlement-table__header">
              <TableRow>
                <TableCell
                  className="drivers-settlement-header__cell"
                  size="medium"
                  padding="normal"
                >
                  <label>Rozliczenie za okres</label>
                </TableCell>
                <TableCell
                  className="drivers-settlement-header__cell"
                  size="medium"
                  padding="normal"
                >
                  <label>Status rozliczenia</label>
                </TableCell>
                {generateTableCells()}
                <TableCell
                  size="medium"
                  padding="normal"
                  align="right"
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {raports
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell className="drivers-settlement-header__cell">
                      {parseDate(item.issue_date)}
                    </TableCell>
                    <TableCell className="drivers-settlement-header__cell">
                      {item.is_confirmed ? "Potwierdzone" : "Nie potwierdzone"}
                    </TableCell>
                    {generateTableCells()}
                    <TableCell align="right">
                      <div className="routes-table__options">
                        <IcoBtn
                          icon="Download"
                          tooltip="Pobierz rozliczenie"
                          onClick={() => {
                            handleDownloadReportXLS(item.issue_date);
                          }}
                        />
                        {!item.is_confirmed && (
                          <>
                            <IcoBtn
                              icon="Edit"
                              tooltip="Edytuj"
                              onClick={() => {
                                handleEditSettlement(item.issue_date, item.id);
                              }}
                            />
                            <IcoBtn
                              icon="Check"
                              tooltip="Potwierdź"
                              className="drivers-settlement-table__button-edit"
                              onClick={() => {
                                setConfirmModal({
                                  isOpen: true,
                                  date: parseDate(item.issue_date),
                                  id: item.id,
                                });
                              }}
                            />
                          </>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <div
            className={styles.tableBottom}
            style={{
              width: "calc(100vw - 200px)",
              position: "fixed",
              bottom: 0,
              zIndex: 999,
            }}
          >
            <div>
              {perPageButtons?.map(({ value, minNumber }) => (
                <OrderPerPageButton
                  key={value}
                  tableLength={raports ? raports?.length : 0}
                  minNumber={minNumber}
                  value={value}
                  templatesLength={0}
                  setRowsPerPage={setRowsPerPage}
                  rowsPerPage={rowsPerPage}
                />
              ))}
            </div>
            <div>
              <Pagination
                postsPerPage={rowsPerPage}
                totalPosts={raports?.length}
                paginate={paginate}
                page={page}
              />
            </div>
          </div>
        </TableContainer>
      </div>
      <SettlementConfirmModal
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
        handleConfirm={handleConfirm}
      />
    </>
  );
};

export default Settlements;
