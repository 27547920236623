import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import Select from "react-select";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import stylesMod from "../../styles/newOrEdit.module.css";
import Input from "../../components/input";
import { DBurl } from "../../appConfig";
import BTNstyles from "../../styles/button.module.css";
import { Add } from "@material-ui/icons";
import { X } from "react-feather";
import { IconButton } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CostAdd(props) {
  const [error, setError] = useState(false);
  const [order, setOrder] = useState(props.data?.order || "");
  const [name, setName] = useState(props.data?.name || "");
  const [type, setType] = useState(props.data?.type || "");
  const typeOptions = [
    { label: "Opłaty drogowe", value: "Opłaty drogowe" },
    { label: "Parking", value: "Parking" },
    { label: "Prom", value: "Prom" },
    { label: "Myjnia", value: "Myjnia" },
    { label: "Serwis", value: "Serwis" },
    { label: "Nocleg", value: "Nocleg" },
    { label: "Własne", value: "Własne" },
  ];
  const [cost, setCost] = useState(props.data?.cost);
  const [currency, setCurrency] = useState(props.data?.currency || "");
  const currencyOption = [
    { label: "PLN", value: "PLN" },
    { label: "EUR", value: "EUR" },
    { label: "CHF", value: "CHF" },
    { label: "FUNT", value: "FUNT" },
    { label: "RUBEL", value: "RUBEL" },
    { label: "HRYWNA", value: "HRYWNA" },
  ];
  const [description, setDescription] = useState(props.data?.description || "");

  useEffect(() => {
    if (props.data?.name) {
      setOrder({
        label: props.data.order.name,
        value: props.data.order.id,
        ...props.data.order,
      });
      setName(props.data.name);
      setType({ label: props.data.type, value: props.data.type });
      setCost(props.data.cost);
      setCurrency({ label: props.data.currency, value: props.data.currency });
      setDescription(props.data.description);
    }
  }, [props.data.name]);

  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    control: (base, state) => ({
      ...base,
      border: "1px solid #142f42",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #142f42",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused && "#142f42",
      color: state.isFocused && "#fff",
      fontSize: "11px",
    }),
  };

  return (
    <Dialog
      maxWidth="lg"
      open={props.open}
      TransitionComponent={Transition}
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        className={stylesMod.dialogHead}
      >
        Dodaj koszt{" "}
        <IconButton
          aria-label="close"
          className={stylesMod.closeButton}
          onClick={() => {
            props.setOpen(false);
          }}
        >
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent className={stylesMod.noOverflow}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <div className={stylesMod.inp}>
              <h6>Dodaj koszt</h6>
              <label>Dotyczy zlecenia</label>
              <Select
                value={order}
                className={`${stylesMod.select} ${
                  error && !order.value && stylesMod.error
                }`}
                options={props.orderOptions}
                onChange={(e) => {
                  setOrder(e);
                }}
                name="order"
                menuPortalTarget={document.body}
                placeholder="Wybierz..."
                styles={selectStyles}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={`${stylesMod.inp}`}>
              <label>Nazwa</label>
              <Input
                value={name}
                error={!name && error}
                handleChange={(e) => {
                  setName(e.target.value);
                }}
                name="name"
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={`${stylesMod.inp}`}>
              <label>Typ</label>
              <Select
                value={type}
                className={`${stylesMod.select} ${
                  error && !type.value && stylesMod.error
                }`}
                options={typeOptions}
                onChange={(e) => {
                  setType(e);
                }}
                name="type"
                menuPortalTarget={document.body}
                placeholder="Wybierz..."
                styles={selectStyles}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={`${stylesMod.inp} `}>
              <label>Kwota</label>
              <Input
                value={cost}
                error={!cost && error}
                handleChange={(e) => {
                  setCost(e.target.value);
                }}
                name="cost"
                type="number"
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={`${stylesMod.inp}`}>
              <label>Waluta</label>
              <Select
                value={currency}
                className={`${stylesMod.select} ${
                  error && !currency.value && stylesMod.error
                }`}
                options={currencyOption}
                onChange={(e) => {
                  setCurrency(e);
                }}
                name="currency"
                menuPortalTarget={document.body}
                placeholder="Wybierz..."
                styles={selectStyles}
              />
            </div>
          </Grid>
          <Grid item xs={5}>
            <div className={stylesMod.inp}>
              <label>Opis</label>
              <Input
                value={description}
                handleChange={(e) => {
                  setDescription(e.target.value);
                }}
                name="description"
              />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="large"
          color="primary"
          className={`${BTNstyles.btn} ${BTNstyles.err}`}
          onClick={(e) => {
            setOrder("");
            setName("");
            setType("");
            setCost("");
            setCurrency("");
            setDescription("");
            props.setOpen(false);
          }}
        >
          Anuluj
        </Button>
        <Button
          variant="contained"
          size="large"
          className={`${BTNstyles.btn} ${BTNstyles.grn}`}
          onClick={(e) => {
            if (!!order?.id && !!cost && !!name && !!currency) {
              const fetchUrl = `${DBurl}/setCostForOrder/`;
              const data = {
                id: props.data.id || null,
                cost: Number(cost),
                id_order: order.value,
                currency: currency.label, // waluta (PLN, EUR, CHF, FUNT, KORONA, RUBEL, HRYWNA)
                type: type.label, // typ (Opłaty drogowe, Parking, Prom, Myjnia, Serwis, Nocleg, Własne)
                name: name,
                description: description || null,
              };
              fetch(fetchUrl, {
                method: "post",
                credentials: "include",
                headers: {
                  "Content-Type": "application/json",
                  "X-CSRF-Token": props.user.csrf_token,
                },
                body: JSON.stringify({
                  mode: props.edit ? "update" : "insert",
                  data: data,
                }),
              })
                .then((res) => res.json())
                .then((data) => {
                  if (data.success) {
                    props.setDialog({
                      isOpen: true,
                      type: "success",
                      content: props.edit
                        ? "Zapisano zmiany"
                        : "Koszt został dodany",
                    });

                    props.refresh();
                    props.setOpen(false);
                  }
                });
            } else {
              props.setDialog({
                isOpen: true,
                type: "error",
                content: "Uzupełnij wymagane pola",
              });
              setError(true);
            }
          }}
          color="primary"
        >
          {props.edit ? "Zapisz" : "Dodaj"}{" "}
          <Add className={BTNstyles.downBtnIco} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
