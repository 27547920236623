import { DBurl } from "appConfig";
import { generateTraces, newOrderDefaultValues } from "./newOrder.data";

export const parseAndUpdateCargo = (locations, cargo, updateQuantity) => {
  const parseLoadAndUnload = (load, unload, updateQuantity) => {
    const result = [];

    const cargoMap = {};

    load.forEach((item) => {
      const id = item.id;

      if (!cargoMap[id]) {
        cargoMap[id] = {
          id,
          load: [],
          unload: [],
          statusLoad: false,
          statusUnload: false,
          quantityLoad: 0,
          quantityUnload: 0,
        };
      }

      cargoMap[id].load.push(item);

      const sumLoad = cargoMap[id].load.reduce(
        (sum, item) => sum + item.quantity,
        0
      );

      if (sumLoad > 0) {
        cargoMap[id].quantityLoad = sumLoad;
        cargoMap[id].statusLoad = true;

        if (updateQuantity) {
          cargoMap[id].quantity = sumLoad;
          cargoMap[id].maxQuantity = sumLoad;
        }
      }
    });

    unload.forEach((item) => {
      const id = item.id;

      if (!cargoMap[id]) {
        cargoMap[id] = {
          id,
          load: [],
          unload: [],
          statusLoad: false,
          statusUnload: false,
          quantityLoad: 0,
          quantityUnload: 0,
        };
      }

      cargoMap[id].unload.push(item);

      const sumUnload = cargoMap[id].unload.reduce(
        (sum, item) => sum + item.quantity,
        0
      );

      if (sumUnload > 0) {
        cargoMap[id].quantityUnload = sumUnload;
        cargoMap[id].statusUnload = true;
      }
    });

    for (const id in cargoMap) {
      result.push(cargoMap[id]);
    }

    return result;
  };

  const mergeCargoData = (oldCargo, newCargo) => {
    const mergedCargo = oldCargo?.map((item) => {
      const matchingItem = newCargo.find((newItem) => newItem.id === item.id);

      if (matchingItem) {
        return { ...item, ...matchingItem };
      }

      return item;
    });

    return mergedCargo;
  };

  let load = [];
  let unload = [];

  locations.forEach((location) => {
    if (location.type === "loading" || location.type === "unloading") {
      if (location.cargo.length > 0) {
        location.cargo.forEach((cargo) => {
          if (location.type === "loading") {
            load.push({
              id: cargo.id,
              traceId: location.id,
              quantity: Number(cargo.quantity),
            });
          }

          if (location.type === "unloading") {
            unload.push({
              id: cargo.id,
              traceId: location.id,
              quantity: Number(cargo.quantity),
            });
          }
        });
      }
    }
  });

  if (load?.length > 0 || unload?.length > 0) {
    const newQuantity = parseLoadAndUnload(load, unload, updateQuantity);

    if (newQuantity?.length > 0 && cargo?.length > 0) {
      const newCargo = mergeCargoData(cargo, newQuantity);

      return newCargo;
    }
  }
};

export const checkError = (values, user_s) => {
  if (values.isTemplate) {
    return { isValid: true };
  }

  if (user_s === true) {
    if (
      !!values.order_no &&
      !!values.order_title &&
      !!values.order_text &&
      !!values.order_start &&
      !!values.order_stop &&
      !!values.transport.set
    ) {
      return { isValid: false, message: "Wypełnij wymagane pola" };
    } else {
      return { isValid: true };
    }
  } else {
    const checkCargo = () => {
      let result = { isValid: true };
      values.cargo.forEach((cargo) => {
        if (values.checkedGoods) {
          if (!cargo.name) {
            result = { isValid: false, message: "Towar nie został wybrany" };
          }

          if (!values.disable_cargo_quantity_check) {
            if (!cargo.statusLoad) {
              result = {
                isValid: false,
                message: "Towar nie został załadowany",
              };
            }

            if (!cargo.statusUnload) {
              result = {
                isValid: false,
                message: "Towar nie został wyładowany",
              };
            }

            if (!cargo.maxQuantity || cargo.maxQuantity.length === 0) {
              result = { isValid: false, message: "Wypełnij ilość towaru" };
            }

            if (cargo.quantityUnload > cargo.maxQuantity) {
              result = {
                isValid: false,
                message: `Zbyt mała ilość towaru w ładunku aby zrealizować rozładunek`,
              };
            }

            if (cargo.quantityLoad > cargo.maxQuantity) {
              result = {
                isValid: false,
                message: `Zbyt mała ilość towaru w ładunku aby zrealizować załadunek`,
              };
            }

            if (cargo.quantityUnload < cargo.maxQuantity) {
              result = {
                isValid: false,
                message: `W rozładunku jest za mała ilość towaru aby wyładować cały towar z ładunku`,
              };
            }

            if (cargo.quantityLoad < cargo.maxQuantity) {
              result = {
                isValid: false,
                message: `W załadunku jest za mała ilość towaru aby załadować cały towar z ładunku`,
              };
            }

            if (cargo.quantityLoad < cargo.quantityUnload) {
              result = {
                isValid: false,
                message: `Za mało załadowanego towaru, rozładunek wynosi ${cargo.quantityUnload} ${cargo.unit.label}`,
              };
            }

            if (cargo.quantityLoad > cargo.quantityUnload) {
              result = {
                isValid: false,
                message: `Za mało wyładowanego towaru, załadunek wynosi ${cargo.quantityLoad} ${cargo.unit.label}`,
              };
            }
          }
        }
      });
      return result;
    };

    const checkTraceCargo = (data) => {
      let result = true;
      data.forEach((cargo) => {
        if (values.checkedGoods === true) {
          if (!cargo.NumRef || !cargo.name) {
            result = false;
          }
        }
      });
      return result;
    };

    const checkTrace = () => {
      let result = { isValid: true };

      values.trace.forEach((location) => {
        if (location.isBlocked) {
          return result;
        }

        if (location.type === "loading" || location.type === "unloading") {
          if (!location.date) {
            result = {
              isValid: false,
              message: "Wypełnij daty w punktach trasy",
            };
          }

          if (!checkTraceCargo(location.cargo)) {
            result = {
              isValid: false,
              message:
                "Wypełnij wymagane pola towaru w punktach załadunku i rozładunku",
            };
          }

          if (location.manual_adress === true) {
            if (!location?.address?.latitude || !location?.address?.longitude) {
              result = {
                isValid: false,
                message: "Niepoprawne adresy w punktach trasy",
              };
            }

            if (!location?.address) {
              result = {
                isValid: false,
                message: "Uzupełnij adresy w punktach trasy",
              };
            }
          } else {
            if (!location.warehouse || !location.warehouse.address) {
              result = {
                isValid: false,
                message: "Niepoprawne adresy magazynów w punktach trasy",
              };
            }
          }
        } else {
          if (!location.address) {
            result = {
              isValid: false,
              message: "Niepoprawne adresy w punktach trasy",
            };
          }
        }
      });

      return result;
    };

    let result = { isValid: true };

    if (!values.order_no) {
      result = { isValid: false, message: "Brak numeru zlecenia" };
    }

    if (!values.contractor.value) {
      result = { isValid: false, message: "Wybierz klienta" };
    }

    if (!values.transport.set || !values.transport.vehicle) {
      result = { isValid: false, message: "Wybierz zestaw transportowy" };
    }

    if (!checkCargo().isValid) {
      result = checkCargo();
    }

    if (!checkTrace().isValid) {
      result = checkTrace();
    }

    if (values?.driverNote?.enabled) {
      if (values.driverNote?.note?.note?.length < 1) {
        result = { isValid: false, message: "Wypełnij notatkę dla kierowcy" };
      }
    }

    return result;
  }
};

export const checkTraceError = (locations) => {
  let is_error = false;
  locations.map((location) => {
    if (!location.warehouse) {
      if (location.type === "unloading") {
        if (location.address === undefined) {
          is_error = true;
        }
        if (location.address?.latitude === null) {
          is_error = true;
        }
        if (location.address?.longitude === null) {
          is_error = true;
        }
      } else if (location.type === "loading") {
        if (location.address === undefined) {
          is_error = true;
        }
        if (location.address?.latitude === null) {
          is_error = true;
        }
        if (location.address?.longitude === null) {
          is_error = true;
        }
      }
    } else {
      if (location.type === "unloading") {
        if (location.warehouse?.address === undefined) {
          is_error = true;
        }
        if (location.warehouse?.address?.latitude === null) {
          is_error = true;
        }
        if (location.warehouse?.address?.longitude === null) {
          is_error = true;
        }
      } else if (location.type === "loading") {
        if (location.warehouse?.address === undefined) {
          is_error = true;
        }
        if (location.warehouse?.address?.latitude === null) {
          is_error = true;
        }
        if (location.warehouse?.address?.longitude === null) {
          is_error = true;
        }
      }
    }

    if (location.type !== "unloading" && location.type !== "loading") {
      if (location.address === undefined) {
        is_error = true;
      }
    }
  });
  return is_error;
};

const changeDateFormat = (date) => {
  if (date !== null) {
    const arrayDate = date.split("-");
    let time = arrayDate[2].split(" ")[1];
    time = time.substring(0, 5);
    arrayDate[2] = arrayDate[2].substring(0, 2);
    return `${arrayDate[0]}-${arrayDate[1]}-${arrayDate[2]}T${time}`;
  }
};

const checkTraceCargo = (data, checkedGoods, isCopy) => {
  let tempCargo = [];

  data.forEach((cargo) => {
    if (checkedGoods || cargo.id_good) {
      tempCargo.push({
        name: cargo.name,
        id: cargo.id_good,
        quantity: isCopy ? null : cargo.goods_location_quantity,
        quantityInput: isCopy ? null : Number(cargo.goods_location_quantity),
        unit: cargo.unit ? { label: cargo.unit, value: cargo.unit } : null,
        palletExchange: cargo.is_pallet_exchange,
        currency: cargo.goods_currency
          ? { label: cargo.goods_currency, value: cargo.goods_currency }
          : null,
        ldmSize: cargo.ldm_size,
        ADR: cargo.is_adr,
        ADRClass: cargo.adr_class,
        NumRef: isCopy ? null : cargo.reference_no,
        is_pallet_exchange: cargo.palletExchange,
        details: cargo.details,
      });
    }
  });
  if (tempCargo.length === 0) {
    tempCargo.push({
      name: "",
      quantity: "",
      unit: "",
      price: "",
      currency: "",
      statusLoad: true,
      statusUnload: false,
      details: "",
    });
  }
  return tempCargo;
};

export const getOrder = async ({
  id,
  userToken,
  order_type_S,
  checkedGoods,
  updateValues,
  setDateFrom_temp,
  setDateTo_temp,
  setFiles,
  isEdit,
  isCopy,
  isTemplate,
}) => {
  const orderFull = async () => {
    const response = await fetch(`${DBurl}/getOrders?id=${id}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": userToken,
      },
    });

    isCopy = isTemplate ? isTemplate : isCopy;

    const json = await response.json();
    if (json.success) {
      const order = { ...json.data[0] };

      let tmpSelectedFilesArr = {
        files_car: [],
        files_driver1: [],
        files_driver2: [],
        files_trailer: [],
      };

      for (let file of order.files) {
        if (file.kind === "car") {
          tmpSelectedFilesArr.files_car.push(file.ref_file_id);
        }
        if (file.kind === "driver1") {
          tmpSelectedFilesArr.files_driver1.push(file.ref_file_id);
        }
        if (file.kind === "driver2") {
          tmpSelectedFilesArr.files_driver2.push(file.ref_file_id);
        }
        if (file.kind === "trailer") {
          tmpSelectedFilesArr.files_trailer.push(file.ref_file_id);
        }
      }

      let tempCargo = [];
      let load = [];
      let unload = [];

      order.locations.map((location) => {
        location.goods.forEach((cargo) => {
          if (cargo.goods_location_quantity) {
            if (location.type === "loading") {
              load.push({
                id: `trace-${location.index_order}`,
                quantity: Number(cargo.goods_location_quantity),
              });
            }

            if (location.type === "unloading") {
              unload.push({
                id: `trace-${location.index_order}`,
                quantity: Number(cargo.goods_location_quantity),
              });
            }
          }
        });
      });

      order.locations.map((location) => {
        location.goods.map((cargo) => {
          let found = false;
          for (let i = 0; i < tempCargo.length; i++) {
            if (tempCargo[i].name == cargo.name) {
              found = true;
              break;
            }
          }
          if (!found) {
            tempCargo.push({
              name: cargo.name,
              label: cargo.name,
              value: cargo.id_good,
              id: cargo.id_good,
              details: cargo.details,
              unit:
                cargo.unit === undefined
                  ? null
                  : {
                      label: cargo.unit,
                      value: cargo.unit,
                    },
              price: cargo.goods_price,
              currency:
                cargo.goods_currency === undefined
                  ? null
                  : {
                      label: cargo.goods_currency,
                      value: cargo.goods_currency,
                    },
              palletExchange: cargo.is_pallet_exchange,
              ldmSize: cargo.ldm_size,
              ADRClass: cargo.adr_class,
              ADR: cargo.is_adr,
              NumRef: isCopy ? null : cargo.reference_no,
            });
          }
        });
      });

      let isLocationDriverStatus = false;

      const findDriverStatus = (locationId) => {
        const driverStatus = order.driver_status?.filter(
          (status) => status.id_location === locationId
        );

        if (driverStatus.length > 0) isLocationDriverStatus = true;

        return { isStatus: driverStatus.length > 0, status: driverStatus[0] };
      };

      let tempTrace = [];

      order.locations.forEach((location) => {
        if (location.type === "loading" || location.type === "unloading") {
          if (location.id_warehouse === null) {
            tempTrace.push({
              id: `trace-${location.index_order}`,
              id_location: location.id,
              reference_no: isCopy ? "ref_1" : location.reference_no,
              isDriverStatus: isCopy
                ? false
                : findDriverStatus(location.id).isStatus,
              driverStatus: isCopy
                ? null
                : findDriverStatus(location.id).status,
              isBlocked: isCopy
                ? false
                : findDriverStatus(location.id).isStatus,
              type: location.type,
              date: isCopy ? null : changeDateFormat(location.ts),
              comment: location.comment,
              border_crossing: {
                label: location.address_display_name,
                value: location.id_border_crossing,
              },
              address: {
                city: location.city,
                country: location.country,
                country_code: location.country_code,
                district: location.district,
                latitude: location.latitude,
                longitude: location.longitude,
                street: location.street,
                street_no: location.street_no,
                apartment_no: location.apartment_no,
                firm_name: location.firm_name,
                zipcode: location.zipcode,
                address_display_name: location.address_display_name,
              },
              cargo: checkTraceCargo(location.goods, checkedGoods, isCopy),
              manual_adress: true,
            });
          } else {
            tempTrace.push({
              id: `trace-${location.index_order}`,
              id_location: location.id,
              isDriverStatus: isCopy
                ? false
                : findDriverStatus(location.id).isStatus,
              driverStatus: isCopy
                ? null
                : findDriverStatus(location.id).status,
              isBlocked: isCopy
                ? false
                : findDriverStatus(location.id).isStatus,
              reference_no: location.reference_no,
              type: location.type,
              date: isCopy ? null : changeDateFormat(location.ts),
              comment: location.comment,
              warehouse: { id: location.id_warehouse },
              cargo: checkTraceCargo(location.goods, checkedGoods, isCopy),
            });
          }
        } else {
          tempTrace.push({
            id: `trace-${location.index_order}`,
            id_location: location.id,
            type: location.type,
            subtype: location.subtype,
            date: isCopy ? null : changeDateFormat(location.ts),
            comment: location.comment,
            border_crossing: {
              label: location.address_display_name,
              value: location.id_border_crossing,
            },
            address: {
              city: location.city,
              country: location.country,
              country_code: location.country_code,
              district: location.district,
              latitude: location.latitude,
              longitude: location.longitude,
              street: location.street,
              street_no: location.street_no,
              zipcode: location.zipcode,
              address_display_name: location.address_display_name,
            },
          });
        }
      });

      const updatedCargo = parseAndUpdateCargo(
        tempTrace,
        tempCargo,
        isCopy ? false : true
      );

      updateValues({
        ...newOrderDefaultValues,
        id: order.id,
        order_no: order.order_no,
        isEdit: isTemplate ? true : isEdit,
        isCopy,
        isTemplate,
        isLocationDriverStatus,
        customer_order_number: isCopy ? null : order.customer_order_number,
        tsId: isCopy ? null : order.transport_set.id_transport_set,
        contractor: order.contractor
          ? {
              label: order.contractor.name,
              name: order.contractor.name,
              value: order.id_contractor,
            }
          : { label: "", name: "", value: "" },
        price: isCopy ? null : order.order_price,
        currency: {
          value: order.order_currency,
          label: order.order_currency,
        },
        cost: order.cost,
        driver_status: isCopy ? null : order.driver_status,
        cargo:
          updatedCargo?.length > 0 ? updatedCargo : newOrderDefaultValues.cargo,
        is_required_weight: order.is_required_weight,
        checkedGoods: tempCargo.length > 0 && tempCargo[0].id ? true : false,
        kilometers: {
          ...newOrderDefaultValues.kilometers,
          access: isCopy ? 1 : order.access_kilometers,
          cargo: order.cargo_kilometers,
          sum: isCopy ? null : order.sum_kilometers,
        },
        transport: isCopy
          ? newOrderDefaultValues.transport
          : {
              ...newOrderDefaultValues.transport,
              advance:
                order.advance_payments.length > 0
                  ? order.advance_payments.map((e) => {
                      return {
                        value: e.value,
                        currency: { label: e.currency, value: e.currency },
                      };
                    })
                  : [{ value: 0, currency: { label: "PLN", value: "PLN" } }],
            },
        trace: tempTrace,
        selected_tags: order.tags.map((o) => {
          return o.id;
        }),
        disable_cargo_quantity_check:
          !order.goods_quantity_verification_required,
        driverNote: order.order_note
          ? {
              enabled: true,
              note: {
                note: order.order_note,
              },
            }
          : null,
      });
      order.files && setFiles(order.files);
    }
  };

  const orderS = async () => {
    const response = await fetch(`${DBurl}/getOrdersS?id=${id}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": userToken,
      },
    });
    const json = await response.json();
    if (json.success) {
      const order = { ...json.data[0] };
      updateValues({
        ...newOrderDefaultValues,
        order_no: order.order_no,
        order_title: order.order_name,
        order_text: order.order_description,
        tsId: order.transport_set.id_transport_set,
        isEdit: true,
      });
      setDateFrom_temp(
        order.order_start ? order.order_start.replace(/ /g, "T") : ""
      );
      setDateTo_temp(
        order.order_stop ? order.order_stop.replace(/ /g, "T") : ""
      );
    }
  };

  if (order_type_S === false) {
    await orderFull();
  } else if (order_type_S === true) {
    await orderS();
  }
};

export const resetValues = (updateValues, setFiles) => {
  const newValues = { ...newOrderDefaultValues, trace: generateTraces() };

  updateValues({
    ...newValues,
    favoriteRoute: null,
  });
  setFiles([]);
};

export const checkTransportSet = async (
  values,
  transportOptions,
  user,
  updateValue,
  setDialog,
  setOpenModalCreateTransportSet
) => {
  const getURL = () => {
    if (values.transport.trailer.value && !values.transport.driverTwo.value)
      return `${DBurl}/checkTransportSets?id_car=${values.transport.car.value}&id_driver1=${values.transport.driver.value}&id_trailer=${values.transport.trailer.value}`;
    if (values.transport.trailer.value && values.transport.driverTwo.value)
      return `${DBurl}/checkTransportSets?id_car=${values.transport.car.value}&id_driver1=${values.transport.driver.value}&id_driver2=${values.transport.driverTwo.value}&id_trailer=${values.transport.trailer.value}`;
    if (!values.transport.trailer.value && !values.transport.driverTwo.value)
      return `${DBurl}/checkTransportSets?id_car=${values.transport.car.value}&id_driver1=${values.transport.driver.value}`;
    if (!values.transport.trailer.value && values.transport.driverTwo.value)
      return `${DBurl}/checkTransportSets?id_car=${values.transport.car.value}&id_driver1=${values.transport.driver.value}&id_driver2=${values.transport.driverTwo.value}`;
    if (
      !values.transport.trailer.value &&
      !values.transport.driverTwo.value &&
      !values.transport.driver
    )
      return `${DBurl}/checkTransportSets?id_car=0&id_driver1=0`;
    if (
      !values.transport.trailer.value &&
      !values.transport.driverTwo.value &&
      !values.transport.driver.value &&
      !values.transport.car.value
    )
      return `${DBurl}/checkTransportSets?id_car=0&id_driver1=0`;
  };
  const response = await fetch(getURL(), {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": user.csrf_token,
    },
  });
  const json = await response.json();
  if (json?.data?.length > 0) {
    updateValue(
      "transport.set",
      transportOptions.find((set) => set.id === json.data[0].id)
    );
    setDialog({
      isOpen: true,
      type: "success",
      content: "Zestaw transportowy sprawdzony pomyślnie.",
    });
  }
  if (json?.data?.length === 0) {
    setOpenModalCreateTransportSet(true);
    setDialog({
      isOpen: true,
      type: "error",
      content: "Zestaw transportowy nie istnieje.",
    });
  }
};
