import React, { useState, useEffect } from "react";
import Input from "components/input";
import Title from "components/title";
import styles from "../../table.module.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
  Slide,
} from "@material-ui/core";
import IcoBtn from "components/buttons/icoBtn";
import Select from "react-select";
import ServicesTableRow from "./invoiceAddNewServicesTableRow";
import ServicesTableRowDisabled from "./tableRowDisabled";
import stylesMod from "styles/invoice.module.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ServicesTable = (props) => {
  const headCells = [
    {
      id: "orderNumber",
      numeric: false,
      disablePadding: true,
      label: "Numer zlecenia (Numer zlecenia klienta)",
    },
    {
      id: "serviceType",
      numeric: false,
      disablePadding: false,
      label: "Rodzaj usługi / nazwa towaru",
    },
    {
      id: "quantity",
      numeric: true,
      disablePadding: false,
      label: "Ilość",
      class: styles.statusShort,
    },
    {
      id: "netPrice",
      numeric: true,
      disablePadding: false,
      label: "Cena netto",
      class: styles.statusShort,
    },
    {
      id: "tax",
      numeric: true,
      disablePadding: false,
      label: "Stawka VAT",
      class: styles.statusShort,
    },
    {
      id: "netValue",
      numeric: true,
      disablePadding: false,
      label: "Kwota netto",
      class: styles.statusShort,
    },
    {
      id: "taxValue",
      numeric: true,
      disablePadding: false,
      label: "Kwota VAT",
      class: styles.statusShort,
    },
    {
      id: "grossValue",
      numeric: true,
      disablePadding: false,
      label: "Kwota brutto",
      class: styles.statusShort,
    },
  ];

  function EnhancedTableHead(props) {
    const {
      styles,
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead className={styles.tabHead}>
        {documentType?.value === undefined && (
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={headCell.class}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  style={{ fontSize: "12px" }}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={styles.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell className={styles.buttonBoxParentLg} />
          </TableRow>
        )}
        {documentType?.value === 1 && (
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={headCell.class}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  style={{ fontSize: "12px" }}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={styles.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell className={styles.buttonBoxParentLg} />
          </TableRow>
        )}
        {documentType?.value === 4 && (
          <TableRow>
            <TableCell align={"left"} padding={"default"}>
              <TableSortLabel direction={"asc"} style={{ fontSize: "14px" }}>
                Treść
              </TableSortLabel>
            </TableCell>
            <TableCell
              align={"left"}
              padding={"default"}
              className={stylesMod.noteAmount}
            >
              <TableSortLabel direction={"asc"} style={{ fontSize: "14px" }}>
                Kwota
              </TableSortLabel>
            </TableCell>
            <TableCell className={styles.buttonBoxParentLg} />
          </TableRow>
        )}
        {documentType?.value === 5 && (
          <TableRow>
            <TableCell align={"left"} padding={"default"}>
              <TableSortLabel direction={"asc"} style={{ fontSize: "14px" }}>
                Treść
              </TableSortLabel>
            </TableCell>
            <TableCell
              align={"left"}
              padding={"default"}
              className={stylesMod.noteAmount}
            >
              <TableSortLabel direction={"asc"} style={{ fontSize: "14px" }}>
                Kwota
              </TableSortLabel>
            </TableCell>
            <TableCell className={styles.buttonBoxParentLg} />
          </TableRow>
        )}
      </TableHead>
    );
  }

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("car");
  const [selected, setSelected] = useState([]);
  const [table, setTable] = useState([
    {
      invoice_no: "",
      orders: [],
      contractor: {},
      data_services: "",
    },
  ]);

  const {
    orders,
    handleChangeCurrency,
    handleChangeVat,
    handleChangeServices,
    servicesOptions,
    selectedCurrency,
    propServ,
    buyer,
    documentType,
    typeInputValue,
    disabled = false,
    isCorrection,
  } = props;
  const initialSerices = {
    id: 1,
    numerZlecenia: "",
    rodzajUslugi: "",
    ilosc: 0,
  };
  const [services, setServices] = useState([initialSerices]);
  const getNewID = (allServices) =>
    allServices.length > 0 ? allServices.reverse()[0].id + 1 : 1;

  const handleNewService = () => {
    const newService = { ...initialSerices, id: getNewID(services) };
    setServices((prevServices) => [...prevServices, newService]);
  };

  const handleDeleteService = (index) => {
    const newServices = services.filter((service) => service.id !== index);
    setServices(newServices);
  };

  useEffect(() => {
    if (propServ.length > 0) {
      setServices(propServ);
    }
  }, [propServ.length]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = table.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const currencies = [
    { label: "PLN", value: "PLN" },
    { label: "EURO", value: "EUR" },
    { label: "CHF", value: "CHF" },
    { label: "FUNT", value: "GBP" },
    { label: "KORONA", value: "CZK" },
    { label: "HRYWNA", value: "UAH" },
  ];

  return (
    <>
      <div className={styles.root}>
        <Paper className={styles.paper}>
          <Title
            title={disabled ? "Było" : isCorrection ? "Powinno być" : "Usługi"}
            btnBox
            btnAdd={!isCorrection}
            selectedCurrency={selectedCurrency}
            addFunc={handleNewService}
            selectCurrencyFunc={(e) => handleChangeCurrency(e.value)}
            selectCurrency={disabled ? null : currencies}
          />
          <TableContainer className={stylesMod.table}>
            <Table
              className={styles.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                styles={styles}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={table.length}
              />
              <TableBody className={styles.tabBody}>
                {services.map((service) => (
                  <ServicesTableRow
                    disabled={disabled}
                    key={service.id}
                    isCorrection={isCorrection}
                    documentType={documentType}
                    handleDeleteService={handleDeleteService}
                    handleChangeVat={handleChangeVat}
                    handleChangeServices={handleChangeServices}
                    service={service}
                    buyer={buyer}
                    typeInputValue={typeInputValue}
                    servicesOptions={servicesOptions}
                    orders={orders}
                    token={props.props.user.csrf_token}
                    error={props.error}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </>
  );
};

export default ServicesTable;
