import { logout } from "api/endpoints";
import { AxiosError } from "axios";
import TmsDialog from "components/TmsDialog";
import React, { createContext, ReactNode, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { TmsDialogState, User } from "types";
import api from "api/axios";

interface UserContextData {
  user: User;
  setUser: (user: User) => void;
  isLoggedIn: boolean;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  logoutApp: any;
}

export const UserContext = createContext<UserContextData>(
  {} as UserContextData
);

export const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const userSession = sessionStorage?.getItem("user");
  const loggedSession = sessionStorage?.getItem("isLoggedIn");
  const [user, setUser] = useState<User>(
    userSession && JSON.parse(userSession)
  );
  const [isLoggedIn, setIsLoggedIn] = useState(
    (loggedSession && JSON.parse(loggedSession)) || false
  );
  const [dialog, setDialog] = useState<TmsDialogState>({ isOpen: false });
  const queryClient = useQueryClient();

  const clearCache = () => {
    sessionStorage.clear();
    setIsLoggedIn(false);
    queryClient.clear();
  };

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 403) {
        clearCache();
        setDialog({
          isOpen: true,
          type: "info",
          content: "Sesja wygasła. Zaloguj się ponownie.",
        });
      }
      return Promise.reject(error);
    }
  );

  const { mutate: logoutApp } = useMutation<User | AxiosError>(
    "logout",
    logout,
    {
      onSettled: () => {
        clearCache();
        setDialog({
          isOpen: true,
          type: "success",
          content: "Wylogowano",
        });
      },
    }
  );

  return (
    <>
      <UserContext.Provider
        value={{
          user,
          setUser,
          isLoggedIn,
          setIsLoggedIn,
          logoutApp,
        }}
      >
        {children}
      </UserContext.Provider>
      <TmsDialog dialog={dialog} setDialog={setDialog} />
    </>
  );
};
