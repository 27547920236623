import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import { Snackbar, TextField, Button } from '@material-ui/core';
import styles from './login/login.module.css';
import { DBurl, ContactEmail, AppName } from '../appConfig'
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Login(props) {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");
  const [openPassModal, setOpenPassModal] = useState(false)
  const [mailForgot, setMailForgot] = useState("");

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenDialog(false)
  }

  const handleSubmit = async () => {
    const response = await fetch(`${DBurl}/setRecoverPassword`, {
      method: "POST",
      // credentials: 'include',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: mailForgot
      })
    })
    const json = await response.json()
    console.log(json)
    if (json.data?.emailFound) {
      setOpenDialog(true)
      setDialogContent("Na podany mail wysłaliśmy link do resetowania hasła")
      setDialogColor("success")
      setOpenPassModal(false)
    } else {
      setOpenDialog(true)
      setDialogContent("Podany mail nie jest przyporządkowany do żadnego konto, sprawdź czy mail jest poprawny")
      setDialogColor("error")
    }
  }

  return (
    <>
      <div className={styles.loginPage}>
        <div className={styles.loginLink}>
          <span> Nie masz konta? <a href={`mailto:${ContactEmail}`}>Napisz do nas</a></span>
        </div>
        <div className={styles.login}>
          <h2>{AppName} TMS</h2>
          <h5>Wpisz nowe hasło</h5>


          <form noValidate onSubmit={handleSubmit}>
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Nowe hasło"
              type="password"
              id="password"
              value={password}
              className={styles.input}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              name="repeatPassword"
              label="Powtórz hasło"
              type="password"
              id="repeatPassword"
              value={repeatPassword}
              className={styles.input}
              onChange={(e) => setRepeatPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              className={styles.logBtn}
              disabled={!(password.length > 7 && repeatPassword.length > 7) || !(password === repeatPassword)}
            >
              Zapisz hasło
            </Button>
          </form>
        </div>

        <div className={styles.loginLink}>
          <NavLink to="/login">Polityka prywatności</NavLink>
        </div>
      </div>
      <Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={dialogColor}>
          {dialogContent}
        </Alert>
      </Snackbar>
    </>
  )
}
