import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import stylesMod from '../../styles/newOrEdit.module.css'
import Input from '../../components/input'
import { IconButton } from '@material-ui/core';
import { DBurl } from '../../appConfig'
import BTNstyles from '../../styles/button.module.css'
import { X, Lock } from 'react-feather'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PasswordChange(props) {

  const [password, setPassword] = useState("")
  const [error, setError] = useState(false)


  const clear = () => {
    setPassword("")
  }

  const setNewPasswordAdmin = async (id) => {
    const response = await fetch(`${DBurl}/setNewPasswordAdmin`, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token
      },
      body: JSON.stringify({
        mode: 'insert',
        data: {
          id_user: id,
          new_password: password
        }
      })
    });

    const json = await response.json();
    if (json.success) {
      props.setOpenSnack(true)
      props.setSnackColor('success')
      props.setSnackContent("Hasło zostało zmienione")
      const killUsersToken = await fetch(`${DBurl}/destroyUserSessions?id_user=${id}`, {
        method: "GET",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token
        }
      });
      const killUsersTokenJson = await killUsersToken.json()
      if (killUsersTokenJson.success) {
        console.log(`Tokeny użytkownika o id: ${id} zostały wyłączone`)
      }

      clear()
      props.setOpen(false)

    }


  }

  return (
    <Dialog
      maxWidth='xs'
      fullWidth={true}
      open={props.open}
      // className={stylesMod.noOverflowGrand}
      TransitionComponent={Transition}
      onClose={() => {
        props.setOpen(false)
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title" className={stylesMod.dialogHead}>Zmień hasło<IconButton aria-label="close" className={stylesMod.closeButton} onClick={() => {
        props.setOpen(false)
      }}>
        <X />
      </IconButton>
      </DialogTitle >
      <DialogContent className={stylesMod.noOverflow}>
      <Grid container spacing={2} >
          <Grid item xs={12}>
            <p className={stylesMod.center}>Zmień hasło użytkownikowi: {props.name}</p>
          </Grid>

        </Grid>
        <Grid container spacing={2} className={stylesMod.centerWithM} >
          <Grid item xs={8}>
            <div className={`${stylesMod.inp}`}>
              <label>Nowe hasło</label>
              <Input value={password}
                error={!password && error}
                handleChange={e => {
                  setPassword(e.target.value)
                }} name="password" 
                type="password"/>
            </div>
          </Grid>

        </Grid>
        <Grid container spacing={2} className={stylesMod.mt20} >
          <Grid item xs={12}>
            <p className={`${stylesMod.warning} ${stylesMod.center}`}>Zmiana hasła wyloguje użytkownika z obecnej sesji</p>
          </Grid>

        </Grid>

      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="large" color="primary" className={`${BTNstyles.btn} ${BTNstyles.err}`} onClick={() => {
          clear()
          props.setOpen(false)

        }}>
          Anuluj
			</Button>
        <Button variant="contained" size="large" className={`${BTNstyles.btn} ${BTNstyles.grn}`} onClick={(e) => {

          if (!!password) {
            setNewPasswordAdmin(props.userId)
          } else {
            setError(true)
            props.setOpenSnack(true)
            props.setSnackColor('error')
            props.setSnackContent("Wpisz nowe hasło")
          }
        }}
          color="primary">
          Zmień hasło <Lock className={BTNstyles.downBtnIco} />
        </Button>
      </DialogActions>

    </Dialog >
  )
}
