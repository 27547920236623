import React, { useState, useMemo } from "react";
import { DBurl } from "appConfig";
import IcoBtn from "components/buttons/icoBtn";
import { TableCell, TableRow, IconButton } from "@material-ui/core";
import { ArrowLeftCircle, ArrowRightCircle } from "react-feather";
import { FileBtn } from "components/buttons";
import { Check, ArrowRight, Info, X, Save } from "react-feather";

import styles from "containers/table.module.css";

const OrderListTableRow = ({
  row,
  isItemSelected,
  labelId,
  openNewOrder,
  templateOn,
  setDeleteData,
  setOpenModalDelete,
  addInvoice,
  setOpenModalCostFuel,
  setSelectedOrder,
  finishOrderManually,
  printOrdersPDF,
  setOpenModalOrderFiles,
  setOrderData,
  handleRefs,
}) => {
  const {
    tags,
    ts,
    contractor,
    locations,
    order_price,
    cost,
    order_currency,
    status,
    id,
    reservation,
    order_no,
    id_invoice,
    customer_order_number,
  } = row;
  const allTags = tags.map((tag) => (tag?.name ? tag.name : ""));
  const [tagsToShow, setTagsToShow] = useState(allTags.slice(0, 1));
  const [showAllTags, setShowAllTags] = useState(false);

  const toggleShowAllTags = (e, newShowAllTags) => {
    e.preventDefault();
    e.stopPropagation();
    setShowAllTags(newShowAllTags);
    setTagsToShow(newShowAllTags ? [...allTags] : allTags.slice(0, 1));
  };

  const dateString = (type, row) => {
    let tl = row?.locations.find((e) => e.type === type);
    let streetAndApart = tl?.apartment_no
      ? `${tl.street_no}/${tl.apartment_no}`
      : tl?.street_no
      ? tl.street_no
      : "";
    return `${tl?.street ? tl.street : ""} ${
      !!streetAndApart ? streetAndApart : ""
    }, ${tl?.zipcode ? tl.zipcode : ""} ${tl?.city ? tl.city : ""}, ${
      tl?.district ? tl.district : ""
    }`;
  };

  const renderToggleSymbol = () => {
    if (allTags.length >= 2)
      return showAllTags ? (
        <IconButton className={styles.mediumIcons}>
          <ArrowLeftCircle color={"orange"} />
        </IconButton>
      ) : (
        <IconButton className={styles.mediumIcons}>
          <ArrowRightCircle color={"green"} />
        </IconButton>
      );
    return <></>;
  };

  const renderTags = (tags) => {
    return (
      <div className={styles.tagGrid}>
        <span> {tags.join(", ")} </span>
        <span onClick={(e) => toggleShowAllTags(e, !showAllTags)}>
          {renderToggleSymbol()}
        </span>
      </div>
    );
  };

  const printPDF = (id) => {
    const url = `${DBurl}/printInvoice2PDF?id_invoice=${id}&lang=PL`;
    window.open(url, "_blank");
  };

  const handleRowClass = () => {
    if (reservation) return styles.reservation;
    if (id_invoice) return styles.invoicedOrder;
    return "";
  };

  const edit = useMemo(() => {
    if (templateOn) {
      return {
        icon: "Edit",
        tooltip: "Edytuj szablon",
        onClick: "Edycja szablonu",
      };
    }

    if (id_invoice) {
      return {
        icon: "Search",
        tooltip: "Podgląd",
        onClick: "Podgląd zlecenia",
      };
    } else {
      return {
        icon: "Edit",
        tooltip: "Edytuj zlecenie",
        onClick: "Edycja zlecenia",
      };
    }
  }, [id_invoice, templateOn]);

  function setStatus(status) {
    switch (status) {
      case 0:
        return (
          <span className={styles.status}>
            <Check className={styles.ok} /> Zakończone
          </span>
        );
      case 1:
        return (
          <span className={styles.status}>
            <ArrowRight className={styles.alert} /> W trakcie
          </span>
        );
      case 2:
        return (
          <span className={styles.status}>
            <Info className={styles.alert} /> Wymaga uwagi
          </span>
        );
      case 3:
        return (
          <span className={styles.status}>
            <X className={styles.err} /> Anulowane
          </span>
        );
      case 4:
        return (
          <span className={styles.status}>
            <Save className={styles.alert} /> Rezerwacja
          </span>
        );
      case 5:
        return (
          <span className={styles.status}>
            <Save className={styles.alert} /> Szablon
          </span>
        );
      default:
        return (
          <span className={styles.status}>
            <X className={styles.err} /> Błąd statusu
          </span>
        );
    }
  }

  return (
    <TableRow
      hover={!id_invoice}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={id}
      selected={isItemSelected}
      className={handleRowClass()}
    >
      <TableCell component="th" id={labelId} align="right" scope="row">
        {order_no}
      </TableCell>
      <TableCell component="th" id={labelId} align="right" scope="row">
        {customer_order_number}
      </TableCell>
      <TableCell align="right">{ts}</TableCell>
      <TableCell align="right">
        {contractor.shortname ? contractor.shortname : "b.d."}
      </TableCell>
      <TableCell align="right">{dateString("loading", row)}</TableCell>
      <TableCell align="right">{dateString("unloading", row)}</TableCell>
      <TableCell align="right">
        {locations.find((e) => e.type === "loading")?.goods[0]?.name}
      </TableCell>
      <TableCell align="right">
        {Array.from(new Set(handleRefs(row).map((ref) => ref))).join(", ")}
      </TableCell>
      <TableCell align="right">
        {order_price} {order_currency}
      </TableCell>
      <TableCell align="right">
        {cost} {order_currency}
      </TableCell>
      <TableCell align="right">{setStatus(status)}</TableCell>
      <TableCell align="right">{renderTags(tagsToShow)}</TableCell>
      <TableCell align="right">
        <div className={styles.buttonBox_orderList}>
          <div>
            {row?.driver_status?.length > 0 && status !== 0 && (
              <IcoBtn
                icon="Pause"
                tooltip={"Zakończ ręcznie"}
                value={row}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  finishOrderManually(row.id);
                }}
              />
            )}
          </div>
          <div>
            <IcoBtn
              icon="DollarSign"
              tooltip="Tankowania, koszty drogowe"
              fontSize="small"
              handleChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpenModalCostFuel(true);
                setSelectedOrder(row.id);
              }}
            />
          </div>
          <div>
            <FileBtn
              tooltip="PDF"
              handleChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                printOrdersPDF(row.id);
              }}
            />
          </div>
          <div>
            <IcoBtn
              icon="FileText"
              tooltip="Pliki"
              fontSize="small"
              handleChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOrderData(row);
                setOpenModalOrderFiles(true);
              }}
            />
          </div>
          <div>
            {!id_invoice ? (
              <FileBtn
                tooltip="Wystaw fakturę"
                color={"var(--cor-col)"}
                handleChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  addInvoice(row, false);
                }}
              />
            ) : (
              <IcoBtn
                icon="Download"
                tooltip="Pobierz fakturę"
                fontSize="small"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  printPDF(id_invoice);
                }}
              />
            )}
          </div>
          <div>
            {row.contractor.shortname && (
              <IcoBtn
                icon="Copy"
                tooltip={templateOn ? "Użyj szablonu" : "Kopiuj"}
                value={row}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  openNewOrder(row, "Kopia zlecenia");
                }}
              />
            )}
          </div>
          <div>
            {row.contractor.shortname && (
              <IcoBtn
                icon={edit.icon}
                tooltip={edit.tooltip}
                value={row}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  openNewOrder(row, edit.onClick);
                }}
              />
            )}
          </div>
          {status === 0 && (
            <div>
              <IcoBtn
                icon="X"
                tooltip="Usuń"
                className="btn-delete"
                value={row}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setDeleteData([id]);
                  setOpenModalDelete(true);
                }}
              />
            </div>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default OrderListTableRow;
