import React, { useState, forwardRef } from 'react';
import styles from '../table.module.css'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Slide, Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import fuelCardsStyles from '../../styles/fuelCards.module.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import plLocale from 'date-fns/locale/pl';
import { Calendar } from 'react-feather';
import FuelPricesRow from "./FuelPricesRow"


const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const CustomInput = forwardRef((props, ref) => {
    return (
        <div className={`${'datapicker_new'} ${styles.dataPicker}` }>        
            <label onClick={props.onClick} ref={ref}>         
            {props.value || props.placeholder} 
            </label>
            <Calendar onClick={props.onClick} />
        </div>
    );
});

const headCells = [
	{ id: 'station', numeric: true, disablePadding: false, label: 'Stacja paliw', class: fuelCardsStyles.headCell },
	{ id: 'E92', numeric: true, disablePadding: false, label: 'E92', align: 'center' },
	{ id: 'E95', numeric: true, disablePadding: false, label: 'E95', align: 'center' },
	{ id: 'E97', numeric: true, disablePadding: false, label: 'E97', align: 'center' },
	{ id: 'E100', numeric: true, disablePadding: false, label: 'Diesel', align: 'center' },
	{ id: 'LPG', numeric: true, disablePadding: false, label: 'LPG', align: 'center' },
	{ id: 'AdBlue', numeric: true, disablePadding: false, label: 'AdBlue', align: 'center', }
];

const FuelCardsFuelPrices = (props, favStations) => {

    const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('car');
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [table, setTable] = useState([])

	const [openDialog, setOpenDialog] = useState(false)
	const [dialogContent, setDialogContent] = useState("")
	const [dialogColor, setDialogColor] = useState("error")
    const [date, setDate] = useState(new Date())


	function EnhancedTableHead(props) {
		const { styles, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

		return (
			<TableHead className={styles.tabHead}>
				<TableRow>
					{headCells.map((headCell) => {
						return (
								<TableCell
									key={headCell.id}
									align={headCell.align ? `${headCell.align}` : "left"}
									padding={headCell.disablePadding ? 'none' : 'default'}
									sortDirection={orderBy === headCell.id ? order : false}
									className={headCell.class}
								>
									{headCell.label}
								</TableCell>
						)
					})}
				</TableRow>
			</TableHead>
		);
	}

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenDialog(false)
	}


	const isSelected = (name) => selected.indexOf(name) !== -1;


    return (
        <div className={fuelCardsStyles.fuelPrices}>
                <div className={fuelCardsStyles.headerPrices}>
                    <div>Ceny paliw</div>
                    {/* <div className={fuelCardsStyles.date}>
                        <DatePicker 
							dateFormat="dd.MM.yyyy"
							selected={date} 
							onChange={(e) => {
	
								let newD = moment(e).valueOf()
								let newDS = moment(newD).format('yyyy-MM-DD')
								newDS = new Date(e)
                                setDate={newDS}
							}} 
							locale={plLocale}
							name="issueDate"
							customInput={<CustomInput error={false}/>}
							placeholderText={"dd.mm.rrrr"} 
						/>
                        </div> */}
                    </div>
                <TableContainer className={styles.stickyDoubleTableAlt}>
                <Table
                        className={fuelCardsStyles.fuelPricesTable}
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            styles={styles}
                            order={order}
                            orderBy={orderBy}
                            rowCount={table.length}
                        />
                        <TableBody className={styles.tabBody}>
                            {props.favStations && props.favStations
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
										<FuelPricesRow row={row} props={props.props} date={date} key={row.id}/>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={styles.tableBottom} >
                </div>
			<Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
				<Alert onClose={handleClose} severity={dialogColor}>
					{dialogContent}
				</Alert>
			</Snackbar>
        </div>
    )
}

export default FuelCardsFuelPrices;