import React, { useState } from "react";
import { AxiosError } from "axios";
import Select, { ValueType } from "react-select";
import { X, Plus } from "react-feather";
import { CompactPicker } from "react-color";
import { useMutation, useQuery } from "react-query";
import { Button, Grid } from "@material-ui/core";
import stylesMod from "styles/newOrEdit.module.css";
import BTNstyles from "styles/button.module.css";
import Input from "components/input";
import TmsTitleSmall from "components/TmsTitleSmall";
import TmsDialog from "components/TmsDialog";
import { getTags, setTags } from "api/endpoints";
import { NewTagResponse, Tag, TmsDialogState, TagSelectOption } from "types";

const colors = [
  "#33691e",
  "#2e7d32",
  "#43a047",
  "#588f27",
  "#45bf55",
  "#bedb39",
  "#00305a",
  "#004b8d",
  "#1510f0",
  "#04bfbf",
  "#35478c",
  "#0288d1",
  "#440505",
  "#4c1b1b",
  "#d50000",
  "#ff2d00",
  "#f44336",
  "#d23600",
  "#fa5b0f",
  "#d95100",
  "#fd7400",
  "#f2b705",
  "#fa9600",
  "#0f8299",
  "#36175e",
  "#4c1273",
  "#4a148c",
  "#512da8",
  "#7b1fa2",
  "#9c27b0",
  "#2a2c2b",
  "#374140",
  "#546e7a",
  "#292a2b",
  "#1c1d21",
  "#44464a",
];

type Props = {
  selected_tags: Array<number>;
  updateValue: (name: string, value: any) => void;
  isPreview?: boolean;
};

const Tags = ({ selected_tags, updateValue, isPreview }: Props) => {
  const [colorPicker, setColorPicker] = useState<string>("");
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);
  const [tags_list, setTags_list] = useState<TagSelectOption[]>([]);
  const [add_tag, setAdd_tag] = useState<string>("");
  const [dialog, setDialog] = useState<TmsDialogState>({ isOpen: false });

  const styles = {
    control: (base: any, state: any) => ({
      ...base,
      border: "1px solid #142f42",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #142f42",
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused && "#142f42",
      color: state.isFocused && "#fff",
    }),
  };

  // sprawdzanie dubli na liscie wybranych tagów
  let selected_tags_duble: Array<number> = [];
  selected_tags.forEach(function (item) {
    if (selected_tags_duble.indexOf(item) < 0) {
      selected_tags_duble.push(item);
    }
  });

  const handleColorClick = () => {
    setDisplayColorPicker(true);
  };

  const handleColorClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChangeComplete = (e: any) => {
    setColorPicker(e.hex);
  };

  const colorContrast = (rgb: any) => {
    rgb = rgb.split(/\(([^)]+)\)/)[1].replace(/ /g, "");
    var r = parseInt(rgb.split(",")[0], 10),
      g = parseInt(rgb.split(",")[1], 10),
      b = parseInt(rgb.split(",")[2], 10),
      a;

    if (rgb.split(",")[3] !== null) {
      a = parseInt(rgb.split(",")[3], 10);
    }

    let contrast =
      (Math.round(r * 299) + Math.round(g * 587) + Math.round(b * 114)) / 1000;
    return contrast >= 170 ? "black" : "white";
  };

  const { refetch: fetchTags } = useQuery<Tag[], AxiosError>(
    "getTags",
    getTags,
    {
      onSuccess: (tags: Tag[]) => {
        setTags_list(
          tags?.map((tag: Tag) => ({
            value: tag.id,
            label: tag.name,
            color: tag.color,
          }))
        );
      },
      onError: (error) => {
        setDialog({ isOpen: true, type: "error", content: error.message });
      },
    }
  );

  const { mutate: addTag } = useMutation("addTag", setTags, {
    onSuccess: (response: NewTagResponse, newTag: Tag) => {
      if (response.new_id) {
        setDialog({
          isOpen: true,
          type: "success",
          content: `Nowy tag "${newTag.name}" został dodany do bazy tagów`,
        });
        updateValue("selected_tags", [...selected_tags, response?.new_id]);
        setAdd_tag("");
        setColorPicker("");
        fetchTags();
      }
      if (newTag.return === "duplicate") {
        updateValue("selected_tags", [...selected_tags, response.duplicate_id]);
        setAdd_tag("");
        setColorPicker("");
        fetchTags();
      }
    },
    onError: (error: AxiosError) => {
      setDialog({
        isOpen: true,
        type: "error",
        content: error.message,
      });
    },
  });

  const handleTagsChange = (
    selectedOptions: ValueType<TagSelectOption, true>
  ) => {
    if (selectedOptions) {
      updateValue(
        "selected_tags",
        selectedOptions.map((option) => option.value)
      );
    }
  };

  const handleTagAdd = (newTag: string) => {
    if (newTag !== "") {
      addTag({ id: 0, name: newTag, color: colorPicker });
    } else {
      setDialog({ isOpen: true, type: "error", content: "Proszę wpisać Tag" });
    }
  };

  const removeTag = (tagId: number) => {
    const newTempTags = selected_tags.filter((selected_tag) => {
      return selected_tag !== tagId;
    });
    updateValue("selected_tags", newTempTags);
  };

  const CustomLabel = (props: TagSelectOption) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      {props.label}
      <span
        className={BTNstyles.selectedColor_option}
        style={{
          backgroundColor: props.color,
          borderColor: "#aaaaaa",
        }}
      ></span>
    </div>
  );

  return (
    <>
      <Grid item xs={12} className={stylesMod.labels}>
        <Grid container spacing={2}>
          <TmsTitleSmall title="Tagi" />
          <Grid container spacing={2}>
            {selected_tags_duble &&
              tags_list &&
              selected_tags_duble !== null && (
                <Grid item xs={12}>
                  {selected_tags_duble.map((tagId: number, index: number) => {
                    let name = tags_list.filter((x) => {
                      return x.value === tagId;
                    });

                    let colorTmp = name.length > 0 ? name[0].color : "#ffffff";
                    let colorBorderTmp =
                      name.length > 0 ? name[0].color : "#ffffff";

                    if (colorTmp === "#ffffff") {
                      colorBorderTmp = "#dfdfdf";
                    }

                    let colorTmpToRBG = colorTmp?.substring(1);
                    let aRgbHex = colorTmpToRBG?.match(/.{1,2}/g);

                    if (aRgbHex) {
                      let aRgb = [
                        parseInt(aRgbHex[0], 16),
                        parseInt(aRgbHex[1], 16),
                        parseInt(aRgbHex[2], 16),
                      ];

                      let colorText = colorContrast(
                        String(`rgb(${aRgb[0]},${aRgb[1]},${aRgb[2]})`)
                      );

                      if (
                        tags_list.length > 0 &&
                        name[0]?.label !== undefined
                      ) {
                        return (
                          <div
                            key={index}
                            className={stylesMod.tagItem}
                            style={{
                              position: "relative",
                              color: colorText,
                              border: `2px solid ${colorBorderTmp}`,
                              backgroundColor: `rgb(${aRgb[0]} ${aRgb[1]} ${aRgb[2]} / 90%)`,
                            }}
                          >
                            {name.length > 0 && name[0].label}
                            <Button
                              disabled={isPreview}
                              color="primary"
                              component="div"
                              onClick={(e) => removeTag(tagId)}
                              className={`${stylesMod.BtnInInput_x}`}
                            >
                              <X
                                className={stylesMod.tagOut}
                                style={{ color: colorText }}
                              />
                            </Button>
                          </div>
                        );
                      }
                    }
                  })}
                </Grid>
              )}
          </Grid>
          {!isPreview && (
            <>
              <Grid item xs={6}>
                <div className={`${stylesMod.inp} multi_selector`}>
                  <label>Wybierz tag</label>
                  <Select
                    isMulti
                    value={tags_list?.filter((obj: any) =>
                      selected_tags?.includes(obj.value)
                    )}
                    className={`${stylesMod.select}`}
                    options={tags_list || []}
                    onChange={handleTagsChange}
                    name="tags_list"
                    placeholder="Wybierz tag..."
                    // @ts-ignore
                    getOptionLabel={CustomLabel}
                    getOptionValue={(option) => `${option.label}`}
                    styles={styles}
                    isDisabled={isPreview}
                  />
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className={stylesMod.inp} style={{ position: "relative" }}>
                  <label>Dodaj tag</label>
                  <Input
                    className={stylesMod.inputText}
                    value={add_tag}
                    handleChange={(e: any) => setAdd_tag(e.target.value)}
                    name="Dodaj tag"
                    placeholder="Dodaj nowy..."
                    disabled={isPreview}
                  />
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className={stylesMod.inp} style={{ position: "relative" }}>
                  <button
                    disabled={isPreview}
                    onClick={handleColorClick}
                    style={{
                      backgroundColor: colorPicker,
                      border: `2px solid ${colorPicker || "rgb(21, 47, 66)"}`,
                      fontSize: "12px",
                    }}
                    className={BTNstyles.BTNColor}
                  >
                    {colorPicker !== ""
                      ? "Zmień kolor taga"
                      : "Wybierz kolor taga"}
                  </button>
                  {displayColorPicker ? (
                    <div
                      style={{
                        position: "absolute",
                        zIndex: "99999",
                        top: "60px",
                        left: "2px",
                      }}
                    >
                      <div
                        style={{
                          position: "fixed",
                          top: "0px",
                          right: "0px",
                          bottom: "0px",
                          left: "0px",
                        }}
                        onClick={handleColorClose}
                      />
                      <CompactPicker
                        color={colorPicker}
                        colors={colors}
                        onChangeComplete={handleChangeComplete}
                      />
                    </div>
                  ) : null}
                </div>
              </Grid>
              <Grid item md={1}>
                <Button
                  color="primary"
                  style={{ marginTop: 3 }}
                  className={`${BTNstyles.primary} ${BTNstyles.addTag}`}
                  component="button"
                  variant="contained"
                  disabled={
                    add_tag !== "" && colorPicker !== ""
                      ? false
                      : true || isPreview
                  }
                  onClick={() => handleTagAdd(add_tag)}
                >
                  <Plus className={stylesMod.clientAdd} />
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <TmsDialog dialog={dialog} setDialog={setDialog} />
    </>
  );
};

export default Tags;
