import React, { Component } from 'react';
import styles from '../fleet/carsList.module.css';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Popper from '@material-ui/core/Popper';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MapIcon from '@material-ui/icons/Map';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { Search } from 'react-feather';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { styled } from '@material-ui/core/styles';
import IconFuel from '../../components/buttons/IconFuel';

const SearchBar = styled(TextField)({
    '& input + fieldset': {
      borderColor: 'rgb(20 47 66 / 34%)',
      borderWidth: 1,
      padding: 0,
      height: 30,
      fontSize: 11,
      marginTop: '5px'
    },
    '& input + fieldset > legend': {
        display: 'none'
      },
    '& input': {
        padding: 0,
        height: 30,
        fontSize: 12,
        background: '#fff',
      },
});


export class CarsList extends Component {

    /**
    * constructor()
    *
    * Funkcja 
    *
    */
    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            anchorEl2: null,
            popup: null,
            order: null,
            row: null,
            eta_waypoints: null,
            refuelings: null,
            placement: 'bottom',
            poperShow: 'carParams',
            id_order_selected: null,
            searchValue: '',
            filteredList: [],
        }


    }

    /**
    * componentDidUpdate(prevProps)
    *
    * Funkcja 
    *
    */
    componentDidUpdate(prevProps, prevState) {

        if(this.state.filteredList.length === 0 && this.props.tableRows.length !== 0){
            this.setState({
                filteredList: this.props.tableRows
            });
        }

        if (prevProps.eta_waypoints !== this.props.eta_waypoints) {
            this.setState({
                eta_waypoints: this.props.eta_waypoints
            });
        }

    }

    

    /**
     * handleClick()
     *
     * Funkcja 
     *
     */
    handleClick = (event, row) => {


        // console.log(row);
        this.setState({
            anchorEl: this.state.anchorEl ? null : event.currentTarget,
            popup: row.popupData,
            placement: 'bottom',
            poperShow: 'carParams'
        });
    };

    /**
     * handleSearchValue()
     *
     * Funkcja 
     *
     */
     handleSearchValue = (event) => {

        this.setState({
            searchValue: event.target.value
        });
        this.setState({
            filteredList: this.props.tableRows.filter((item)  => {
                let temp_list = item.name.toLowerCase();
                return temp_list.indexOf((event.target.value).toLowerCase()) > -1; 
            })
        });
    };



     onChange(field, value) {
        // parent class change handler is always called with field name and value
        this.setState({[field]: value});
    }



    /**
     * handleClickShowOrder()
     *
     * Funkcja 
     *
     */
    handleClickShowOrder = (event, row) => {
        // console.log(row);
        // console.log(this.props.eta);


        console.log('row---->>>>>',row)

        let id_order = row.activeOrderData.id_order;

        this.setState({
            anchorEl: this.state.anchorEl ? null : event.currentTarget,
            order: row.activeOrderData,
            row: row,
            eta_waypoints: null,
            refuelings: row.refuelingData,
            placement: 'left',
            poperShow: 'orderParams',
            id_order_selected: id_order
        });
        this.props.setSelectedOrder(row.activeOrderData)

        this.props.onClickShowOrderCallback((this.state.anchorEl ? false : true), row);
        this.props.onClickSelectOrderCallback(id_order);
        this.props.hiddenOneDayRoute(false)
    }


    /**
     * handleClickShowOrderPopover()
     *
     * Funkcja 
     *
     */
    handleClickShowOrderPopover = (event, row) => {
        // console.log(row);
        this.setState({
            anchorEl2: event.currentTarget,
            //order: row.activeOrderData,
            placement: 'left',
            //poperShow: 'orderParams'
        });
    }


    handleClose = () => {
        this.setState({
            anchorEl2: null
        });
    };


    /**
     * onClickShowRefuelings()
     *
     * Funkcja 
     *
     */
    onClickShowRefuelings = (e, refuelings) => {
        // console.log("refuelings:");
        // console.log(refuelings);

        let markers = [];

        refuelings.forEach((loc) => {
            markers.push({
                lat: parseFloat(loc.refueling_position.lat),
                lng: parseFloat(loc.refueling_position.lng),
                ts: loc.ts_refueling,
                type: 'refuel',
                subtype: '',
                amount_of_fuel: loc.amount_of_fuel,
                fuel_type: loc.fuel_type,
                price_of_fuel: loc.price_of_fuel,
                cost_of_fuel: loc.cost_of_fuel,
                full_refueling: loc.full_refueling,
                is_aggregate: loc.is_aggregate,
                payment_method: loc.payment_method
            });
        });

        this.props.addMarkersRefuelingsCallback(markers);
    }

    /**
     * onClickSetOrderRouteProgress(e, order, row)
     *
     * Funkcja 
     *
     */
    onClickSetOrderRouteProgress = (e, order, row) => {
        // console.log(order);
        // console.log(row);

        //row.id_device = null; // TEMP - no telemetry !!!!!

        if (row.id_device !== null) {
            this.props.showOrderRouteProgressCallback(row.id_device, order.ts_activity_start);
        }
        else {
            this.props.showOrderRouteProgressNoTelemetryCallback(row.active_order_last_status);
        }

    }

    /**
     * onClickSetOrderRoute()
     *
     * Funkcja 
     *
     */
    onClickSetOrderRoute = (e, locations, path_encoded, id_order) => {
        // console.log("locations:");
        // console.log(locations);

        let waypoints = [];
        let markers = [];

        if (locations?.length >= 2 && locations[0].lattitude !== null && locations[0].longitude !== null && locations[locations.length - 1].lattitude !== null && locations[locations.length - 1].longitude !== null) {

            // Destination
            let destination = {
                lat: parseFloat(locations[locations.length - 1].lattitude),
                lng: parseFloat(locations[locations.length - 1].longitude)
            }

            // Origin
            let origin = {
                lat: parseFloat(locations[0].lattitude),
                lng: parseFloat(locations[0].longitude)
            }

            // Waypoints
            if (locations.length > 2) {
                let waypoints_loc = locations.slice(1, -1);

                waypoints_loc.forEach((loc, index) => {

                    waypoints.push({
                        location: {
                            lat: parseFloat(loc.lattitude),
                            lng: parseFloat(loc.longitude)
                        }
                    });

                });

            }

            this.props.doRouteCallback(destination, origin, waypoints, null, 'polyline', path_encoded, id_order, locations, true);

            locations.forEach((loc) => {

                let eta_status = null;
                let eta_distans = null;
                let eta_time = null;
                let eta_etime = null;

                if (this.state.eta_waypoints !== null) {
                    eta_status = (this.state.eta_waypoints[loc.index_order + 1].realtimeStatus.status) === 'visited' ? 'Zrealizowane' : 'W trakcie';
                    eta_distans = (this.state.eta_waypoints[loc.index_order + 1].realtimeStatus.distanceRemaining) > 0 ? this.state.eta_waypoints[loc.index_order + 1].realtimeStatus.distanceRemaining / 1000 + ' km' : 0 + ' km';
                    eta_time = this.toHHMMSS(this.state.eta_waypoints[loc.index_order + 1].realtimeStatus.timeRemaining);
                    eta_etime = this.convertDateToLocalFormat(this.state.eta_waypoints[loc.index_order + 1].realtimeStatus.estimatedTimeArrival);
                }


                markers.push({
                    lat: parseFloat(loc.lattitude),
                    lng: parseFloat(loc.longitude),
                    ts: loc.ts,
                    address_display_name: loc.address_display_name,
                    type: loc.type,
                    subtype: loc.subtype,
                    access_via_transport_docs: loc.access_via_transport_docs,
                    comment: loc.comment,
                    eta_status: eta_status,
                    eta_distans: eta_distans,
                    eta_time: eta_time,
                    eta_etime: eta_etime
                });
            });

            this.props.addMarkersCallback(markers);

        }
        else {
            console.log('Wrong or null coordinates');
        }
    }

    /**
     * onClickHideOrder()
     *
     * Funkcja 
     *
     */
    onClickHideOrder = () => {

        this.setState({
            anchorEl: null
        });

        this.props.onClickHideOrderCallback();

    }

    /**
     * convertDateToLocalFormat(date)
     *
     * Funkcja 
     *
     */
    convertDateToLocalFormat = (date) => {

        let m = new Date(date);

        let year = m.getFullYear();
        let month = ((m.getMonth() + 1) > 9) ? (m.getMonth() + 1) : '0' + (m.getMonth() + 1);
        let day = (m.getDate() + 1 > 9) ? m.getDate() : '0' + m.getDate();

        let hours = (m.getHours() + 1 > 9) ? m.getHours() : '0' + m.getHours();
        let minutes = (m.getMinutes() + 1 > 9) ? m.getMinutes() : '0' + m.getMinutes();
        let seconds = (m.getSeconds() + 1 > 9) ? m.getSeconds() : '0' + m.getSeconds();

        var dateLocalString = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;

        return dateLocalString;
    }

    /**
    * toHHMMSS()
    *
    * Funkcja 
    *
    */
    toHHMMSS = (sec) => {
        var sec_num = parseInt(sec, 10); // don't forget the second param
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);
        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        if (seconds < 10) {
            seconds = "0" + seconds;
        }
        var time = hours + ':' + minutes + ':' + seconds;

        return time;
    }

    /**
     * render()
     *
     * Funkcja 
     *
     */
    render() {

        const openPopper = Boolean(this.state.anchorEl);
        const idPopper = openPopper ? 'simple-popover' : undefined;
        // const openPopover = Boolean(this.state.anchorEl2);
        // const idPopover = openPopover ? 'simple-popover' : undefined;
       

        return (
            <div className={styles.tableCarList}>
                <Paper className={styles.root}>
                    <TableContainer className={styles.container}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Numer rejestracyjny</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell align="right">Numer zlecenia aktywnego</TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={4} className={styles.searchBar}>
                                        <SearchBar
                                            type="text" 
                                            name="cars_search" 
                                            variant="outlined"
                                            fullWidth 
                                            placeholder={'Wyszukaj pojazd..'} 
                                            value={this.state.searchValue} 
                                            onChange={this.handleSearchValue}
                                            style={{background:'#fff'}}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position='start'>
                                                        <Search style={{padding: '0', width: '20px', marginRight: '10px', marginLeft: '-2px', background: '#fff'}}/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.filteredList.map((row) => {

                                    let fuelNum = row.popupData ? parseInt(row.popupData.fuel) : '-'

                                    return (
                                        
                                        <TableRow hover key={row.id} selected={row.id === this.props.selectedTableRowId ? true : false} >
                                            <TableCell className={styles.btn} onClick={() => this.props.onClickTableRowCallback(row)} style={{wordBreak: 'breakAll'}}>
                                                {row.nr_rejestracyjny}
                                            </TableCell>
                                            <TableCell align="right" style={{padding: '5px 0 0 0', minWidth: '40px'}}>
                                                {row.is_telematics &&
                                                    <Tooltip title="Parametry pojazdu" TransitionComponent={Fade} placement="top"><InfoIcon fontSize="small" className={styles.iconBtn} onClick={(e) => this.handleClick(e, row)} /></Tooltip>}
                                                {row.is_telematics &&
                                                    <Tooltip title="Wyznacz trasę od pojazdu" TransitionComponent={Fade}><GpsFixedIcon fontSize="small" className={styles.iconBtn} onClick={() => this.props.onClickTableSetTheRouteCallback(row)} /></Tooltip>}
                                            </TableCell>
                                            <TableCell style={{padding: 0}}>
                                                <div className={styles.fuelBox} >
                                                    <div>
                                                        {row.popupData ? 
                                                        <div className={styles.fuelVals} style={fuelNum < 20 ? {color: '#f38400'} : {color: '#707070'}}>
                                                            {row.popupData.fuel}
                                                            <div className={styles.fuleProcent} style={{background: `linear-gradient(0deg, rgba(255,146,0,1) 0%, rgba(255,146,0,1) ${row.fuel_percent}%, rgba(255,146,0,0) ${row.fuel_percent+1}%)`}}></div>
                                                        </div> 
                                                        : '-'}
                                                    </div>
                                                    {this.props.e100 && <div>
                                                        <Tooltip title="Stacje E100" TransitionComponent={Fade}>
                                                            <button className={row.fuel_percent <= 20 ? styles.btnToFuelWarning : styles.btnToFuel} onClick={e => {
                                                                this.props.onClickShowSearchFuel()
                                                                this.props.findNearestE100(row)
                                                                if(row.activeOrderData.exists === true && row.activeOrderData.order_locations[0].lattitude !== null){
                                                                    this.onClickSetOrderRoute(e, row.activeOrderData.order_locations, row.activeOrderData.path_encoded, row.activeOrderData.id_order)
                                                                }
                                                                
                                                                    // this.props.onClickTableRowCallback(row)
                                                            }}>
                                                                <IconFuel width={17} height={17}/>
                                                            </button>
                                                        </Tooltip>
                                                    </div>}
                                                </div>
                                            </TableCell>
                                            <TableCell align="right">{(row.activeOrderData.exists) ? <><div>{row.activeOrderData.order_no}</div><Tooltip title="Podgląd zlecenia" TransitionComponent={Fade}><InfoIcon fontSize="small" className={styles.iconBtnOrder} onClick={(e) => this.handleClickShowOrder(e, row)} /></Tooltip></> : '-'}</TableCell>
                                            {/* <TableCell align="right">{(row.activeOrderData.exists) ? <><div>{row.activeOrderData.order_no}</div></> : '-'}</TableCell> */}
                                        </TableRow>
                            
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                <Popper
                    id={idPopper}
                    open={openPopper}
                    anchorEl={this.state.anchorEl}
                    // disablePortal={true}
                    placement={'right'}
                    className={this.state.poperShow === 'orderParams' ? styles.mapListPover : (this.state.poperShow === 'carParams' ? styles.mapListMiniPover : '')}
                    // positionFixed={true}
                    modifiers={{
                        // flip: {
                        //     enabled: true,
                        // },
                        preventOverflow: {
                            enabled: false,
                            // boundariesElement: 'scrollParent',
                        }
                    }}
                >

                    {this.state.poperShow === 'carParams' &&
                        <div className={styles.poperBg}>
                            {this.state.popup !== null &&
                                <table width='100%'>
                                    <tbody>

                                        {this.state.popup.ts_local && <tr><td className={styles.popupLabel}>Czas ramki:</td><td>{this.state.popup.ts_local}</td></tr>}
                                        {this.state.popup.tacho_driver1 && <tr><td className={styles.popupLabel}>Kierowca:</td><td>{this.state.popup.tacho_driver1}</td></tr>}
                                        {this.state.popup.tacho_driver1 && <tr><td className={styles.popupLabel}></td><td>{this.state.popup.tacho_drive} | {this.state.popup.tacho_work} | {this.state.popup.tacho_break} | {this.state.popup.tacho_rest}</td></tr>}
                                        {this.state.popup.fuel && <tr><td className={styles.popupLabel}>Poziom paliwa:</td><td>{this.state.popup.fuel}</td></tr>}
                                        {this.state.popup.totaldistance && <tr><td className={styles.popupLabel}>Przebieg [CAN]:</td><td>{this.state.popup.totaldistance} km</td></tr>}
                                        {this.state.popup.mileagegps && <tr><td className={styles.popupLabel}>Przebieg [GPS]:</td><td>{this.state.popup.mileagegps} km</td></tr>}
                                        {this.state.popup.enginespeed && <tr><td className={styles.popupLabel}>Obroty silnika:</td><td>{this.state.popup.enginespeed} RPM</td></tr>}

                                    </tbody>
                                </table>
                            }

                        </div>
                    }

                    {/* {this.state.poperShow === 'orderParams' &&
                        <div className={styles.poperBg}>
                            {this.state.order !== null &&
                                <>
                                    <h1>{this.state.order.order_no}</h1>
                                    <table width='100%'>
                                        <tbody>
                                            {this.state.order.order_locations.map((location, index) => (
                                                <tr key={index}>
                                                    <td>{location.index_order + 1}</td><td>{location.ts}</td><td><Tooltip title="Pokaż punkt" TransitionComponent={Fade}><GpsFixedIcon fontSize="small" className={styles.iconBtn} onClick={() => this.props.onClickTableShowPOIOrderCallback(location)} /></Tooltip></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <button onClick={(e) => this.onClickSetOrderRoute(e, this.state.order.order_locations, this.state.order.path_encoded, this.state.order.id_order)}>Trasa zlecenia</button>
                                </>
                            }
                        </div>
                    } */}

                    {this.state.poperShow === 'orderParams' &&
                        <div className={`${styles.poperBgOrderSummary}`}>
                            {this.state.order !== null &&
                                <>
                                    <div className={styles.orderNo}>Numer zlecenia: {this.state.order.order_no}<CloseIcon className={styles.btnCloseRoute} onClick={this.onClickHideOrder} /></div>

                                    <Button 
                                        startIcon={<MapIcon />} 
                                        variant="contained" 
                                        color="primary" 
                                        className={styles.btnShowRoute} 
                                        onClick={(e) => {
                                            this.onClickSetOrderRoute(e, this.state.order.order_locations, this.state.order.path_encoded, this.state.order.id_order)
                                            this.props.hiddenOneDayRoute(false)
                                        }}
                                    >
                                        Trasa zlecenia 
                                        <Tooltip title="Wyśrodkuj" TransitionComponent={Fade}>
                                            <GpsFixedIcon 
                                                fontSize="small" 
                                                className={styles.iconBtnShowCenterRoute} 
                                                onClick={(event) => {
                                                    this.props.onClickCenterOrderRouteCallback(event, this.state.order.order_locations)
                                                }} 
                                            />
                                        </Tooltip>
                                    </Button>
                                    <Button startIcon={<TrendingUpIcon />} variant="contained" color="primary" className={styles.btnShowRoute} onClick={(e) => {
                                        this.onClickSetOrderRouteProgress(e, this.state.order, this.state.row)
                                        this.props.hiddenOneDayRoute(true)}}>Postęp realizacji <Tooltip title="Wyśrodkuj" TransitionComponent={Fade}><GpsFixedIcon fontSize="small" className={styles.iconBtnShowCenterRoute} onClick={(event) => this.props.onClickCenterOrderProgressRouteCallback(event)} /></Tooltip>
                                    </Button>

                                    {this.state.order.order_locations.map((location, index) => {
                                        let type = '';
                                        switch (location.type) {
                                            case 'loading':
                                                type = 'Załadunek';
                                                break;
                                            case 'unloading':
                                                type = 'Rozładunek';
                                                break;
                                            case 'stop':
                                                type = 'Punkt';
                                                break;
                                        }

                                        let subtype = '';
                                        if (location.subtype !== null) {
                                            switch (location.subtype) {
                                                case 'parking':
                                                    subtype = '[Parking]';
                                                    break;
                                                case 'ferry':
                                                    subtype = '[Prom]';
                                                    break;
                                                case 'start':
                                                    subtype = '[Początek trasy]';
                                                    break;
                                                case 'end':
                                                    subtype = '[Koniec trasy]';
                                                    break;
                                                case 'border':
                                                    subtype = '[Przejście graniczne]';
                                                    break;
                                                case 'point':
                                                    subtype = '[Punkt kontrolny]';
                                                    break;
                                            }
                                        }


                                        let eTime = null;
                                        if (this.state.eta_waypoints !== null) {
                                            if (this.state.eta_waypoints[location.index_order + 1] !== undefined) {
                                                eTime = this.convertDateToLocalFormat(this.state.eta_waypoints[location.index_order + 1].realtimeStatus.estimatedTimeArrival);
                                            }
                                        }
                                        else {
                                            eTime = null;
                                        }

                                        let locTime = location.ts;

                                        let eTime_ms = (this.state.eta_waypoints) ? Date.parse(eTime) : null;
                                        let locTime_ms = Date.parse(location.ts);

                                        let oneHour_ms = 3600000;
                                        let color;

                                        // Czas przekroczony
                                        if (eTime_ms > locTime_ms) {
                                            color = styles.time_red;
                                        }
                                        else {
                                            // Czas do planu
                                            color = styles.time_green;
                                            let time_diff = locTime_ms - eTime_ms;
                                            // Czas poniej 1h
                                            if (time_diff <= oneHour_ms) {
                                                color = styles.time_yellow;
                                            }
                                        }

                                        let eta_status_visited = false;
                                        if (this.state.eta_waypoints !== null) {
                                            if (this.state.eta_waypoints[location.index_order + 1] !== undefined) {
                                                eta_status_visited = (this.state.eta_waypoints && this.state.eta_waypoints[location.index_order + 1].realtimeStatus.status === 'visited') ? true : false;
                                            }
                                        }
                                        else {
                                            eta_status_visited = false;
                                        }

                                        if (eta_status_visited) {
                                            eTime = 'Zrealizowane';
                                            color = styles.time_green;
                                        }

                                        return <Accordion key={index}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Tooltip title="Pokaż punkt" TransitionComponent={Fade}><GpsFixedIcon fontSize="small" className={styles.iconBtnShowPoint} onClick={(event) => this.props.onClickTableShowPOIOrderCallback(event, location)} /></Tooltip>
                                                <p className={styles.header}>
                                                    <span className={styles.headerBold}>{location.index_order + 1} | {type} {subtype}</span><br />
                                                    {location.ts} <br />
                                                    {this.state.eta_waypoints &&
                                                        this.state.eta_waypoints[location.index_order + 1]?.realtimeStatus.distanceRemaining !== undefined &&
                                                        <span className={color}>{eTime} <b>ETA</b></span>}
                                                </p>
                                            </AccordionSummary>
                                            <AccordionDetails className={styles.accordion_details}>
                                                {this.state.eta_waypoints &&
                                                    !eta_status_visited &&
                                                    this.state.eta_waypoints[location.index_order + 1] !== undefined &&
                                                    this.state.eta_waypoints[location.index_order + 1]?.realtimeStatus.distanceRemaining !== undefined &&
                                                    <p className={styles.address}>
                                                        ETA: (Status: {(this.state.eta_waypoints[location.index_order + 1]?.realtimeStatus.status) === 'visited' ? 'Zrealizowane' : 'W trakcie'})<br />
                                                        ETA: (Dystans: {(this.state.eta_waypoints[location.index_order + 1]?.realtimeStatus.distanceRemaining) > 0 ? this.state.eta_waypoints[location.index_order + 1]?.realtimeStatus.distanceRemaining / 1000 : 0} km)<br />
                                                        ETA: (Czas: {this.toHHMMSS(this.state.eta_waypoints[location.index_order + 1]?.realtimeStatus.timeRemaining)})</p>
                                                }
                                                <br />
                                                <p className={styles.address}>{location.address_display_name}</p>
                                            </AccordionDetails>
                                        </Accordion>

                                    })}

                                    {this.state.refuelings !== null && <Button startIcon={<LocalGasStationIcon />} variant="contained" color="primary" className={styles.btnShowRoute} onClick={(e) => this.onClickShowRefuelings(e, this.state.refuelings)}>Ilość tankowań: {this.state.refuelings.length}</Button>}

                                </>
                            }
                        </div>
                    }

                </Popper>

                {/* <Popover
                    id={idPopover}
                    open={openPopover}
                    anchorEl={this.state.anchorEl2}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Typography>
                        TEST
                    </Typography>
                </Popover> */}
            </div>
        )
    }

}