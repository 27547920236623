import React, { useState, useEffect } from 'react'
import chatStyles from '../../styles/chat.module.css'
import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import IcoBtn from '../../components/buttons/icoBtn';
import { File } from 'react-feather';
import { DBurl } from '../../appConfig'
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core'
import stylesMod from '../../styles/newOrEdit.module.css'
import styles from '../table.module.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { TableHead } from '@material-ui/core'
import { Snackbar, Grid, Checkbox, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import BTNstyles from 'styles/button.module.css'
import { all } from 'iso-3166-1';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


const ReportList = ({token, data, open, setOpen, what, setWhat, selected, setSelected, loading}) => {

    const [list, setList] = useState([])

    const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = list.map((n) => n.id);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};


    const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		setSelected(newSelected);
	};

    const getContractors = async function () {
        loading(true)
		const response = await fetch(`${DBurl}/getContractors/`, {
			method: "GET",
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": token
			},
		})
		const json = await response.json()
		if (json.success) {
			setList(json.data)
            loading(false)
		} else {

		}
	}

    const fetchDrivers = async function () {
        loading(true)
        const response = await fetch(`${DBurl}/getDriversNoSlaves/`, {
          method: "GET",
          credentials: 'include',
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": token
          },
        })
        const json = await response.json()
        if (json.success) {
          let tempData = json.data.map(v => ({ ...v, name: `${v.firstname} ${v.surname}` }))
          setList(tempData)
          loading(false)
          return json
        } else {

        }
      }

      const fetchCars = async function () {
        loading(true)
        const response = await fetch(`${DBurl}/getCars/`, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": token,
          },
        });
        const json = await response.json();
        if (json.success) {
          setList(json.data)
          loading(false)
        } else {

        }

      };

    const handleRenderTitle = () => {
        if (what === 'contractors') return "Wybierz klienta/klientów"
        if (what === 'drivers') return "Wybierz kierowce/kierowców"
        if (what === 'cars') return "Wybierz samochód/samochody"
    }
    const isSelected = (name) => selected?.indexOf(name) !== -1;

    useEffect(()=>{
        if (what === 'contractors' && open === true) getContractors()
        if (what === 'drivers'  && open === true) fetchDrivers()
        if (what === 'cars'  && open === true) fetchCars()
    },[open])

    useEffect(()=>{
        setSelected([])
    },[what])
    
    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                onClose={() => {
                    setOpen(false)
                    setList([])
                }}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
            <DialogTitle id="alert-dialog-slide-title" className={stylesMod.dialogHead}>
                {handleRenderTitle()}
            </DialogTitle >

            <DialogContent className={chatStyles.tableBox}>  
            <TableContainer className={chatStyles.tableList}>
                <Table
                stickyHeader
                size="small"
                aria-label="enhanced table"
                >

                <TableHead className={styles.tabHead}>
                    <TableRow>
                    <TableCell className={styles.checkbox}>
                        <Checkbox
                            indeterminate={selected.length > 0 && selected.length < list.length}
                            checked={list.length > 0 && selected.length === list.length}
                            onChange={handleSelectAllClick}
                            inputProps={{ 'aria-labell': 'select all id' }}
                        />
				    </TableCell>
                    <TableCell align="left">Zaznacz wszystko</TableCell>
                    <TableCell align="left"></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody className={styles.tabBody}>
                {list.map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${row.id}`; 
                        const isItemSelected = isSelected(row.id);
                        return (
                            <TableRow
                                hover
                                onClick={(event) => handleClick(event, row.id)}
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.id}
                                selected={isItemSelected}
                            >
                            <TableCell className={styles.checkbox} >
                                <Checkbox
                                    checked={isItemSelected}
                                    color="primary"
                                    inputProps={{ 'aria-labellledby': labelId }}
                                />
                            </TableCell>
                            <TableCell align="left">{row.name} </TableCell>
                            <TableCell align="right" >
                            <div className={styles.buttonBox}>
                            </div>
                            </TableCell>
                        </TableRow>
                        )
                    })}

                </TableBody>
                </Table>
            </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button 
                    variant="contained" 
                    size="large" 
                    color="primary" 
                    className={BTNstyles.btn} 
                    onClick={(e) => setOpen(false)}
                    >
                    Wybierz
                </Button>
            </DialogActions>
            </Dialog>
            </>
    )}

export default ReportList;