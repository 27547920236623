import React, { useState, useEffect, useRef } from "react";
import Input from "../../components/input";
import BTNstyles from "../../styles/button.module.css";
import stylesMod from "../../styles/newOrEdit.module.css";
import { Check, X } from "react-feather";
import { DBurl } from "../../appConfig";
import {
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide
} from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const EmailRecipientModal = ({ open, setOpen, loading, token, data, setData, setOpenDialog, setDialogContent, setDialogColor }) => {

    const [emailRecipient, setEmailRecipient] = useState("")

    const validateEmail = (email) => {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    const sendInvoiceByEmail = async () => {
        loading(true);
        await fetch(`${DBurl}/sendInvoiceByEmail`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": token,
            },
            body: JSON.stringify({
                data: {
                    id_invoice: data.id,
                    recipient_email: emailRecipient
                }
            })
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setOpenDialog(true);
                    setDialogContent("Faktura wysłana.");
                    setDialogColor("success");
                    loading(false);
                } else {
                    setOpenDialog(true);
                    setDialogContent(
                        `${data.responseDescription} // ${DataTransferItemList.response}  `
                    );
                    setDialogColor("error");
                    loading(false);
                }
            });
    }

    useEffect(() => {
        if (data.contractor !== "No data") setEmailRecipient(data?.contractor?.contact[0]?.email)
    }, [data])

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                onClose={() => {
                    setOpen(false);
                    setData([]);
                }}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle
                    id="alert-dialog-slide-title"
                    className={stylesMod.dialogHead}
                >
                    Potwierdź adres email odbiorcy
                    <IconButton
                        aria-label="close"
                        className={stylesMod.closeButton}
                        onClick={() => {
                            setOpen(false);
                            setData([]);
                        }}
                    >
                        <X />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <label>Adres email</label>
                        <Input
                            value={emailRecipient}
                            handleChange={(e) => setEmailRecipient(e.target.value)}
                            name="emailRecipient"
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        className={BTNstyles.btn}
                        onClick={(e) => {
                            setOpen(false);
                        }}
                    >
                        Anuluj
                    </Button>
                    <Button
                        variant="contained"
                        size="large"
                        className={`${BTNstyles.btn} ${BTNstyles.grn}`}
                        onClick={(e) => {
                            if (validateEmail(emailRecipient)) {
                                sendInvoiceByEmail()
                                setOpen(false);
                                setData([]);
                            }
                            else {
                                setOpenDialog(true);
                                setDialogContent("Wpisany adres email jest niepoprawny.");
                                setDialogColor("error");
                            }
                        }}
                        color="primary"
                    >
                        Wyślij <Check className={BTNstyles.downBtnIco} />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default EmailRecipientModal;