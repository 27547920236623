import React, { useState, useEffect } from 'react';
import {TableCell, TableRow} from '@material-ui/core'
import { DBurl } from '../../appConfig'
import fuelCardsStyles from '../../styles/fuelCards.module.css'

const FuelPricesRow = ({row, props, date}) => {

    const [fuels, setFuels] = useState([]);
    const [loaded, setLoaded] = useState(false);
    
    const fetchPrices = async (station) => {
        props.loading(true)
        const response = await fetch(`${DBurl}/getE100FuelPrices?station=${station}`, {
            method: "GET",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": props.user.csrf_token
            }	
        });
        const json = await response.json()
        if (json.success) {
            const data = json.data
            props.loading(false)
            setFuels(data)
            setLoaded(true)
        }
    }
    useEffect(() => {
        fetchPrices(row.station)
    },[])


    const getFuelPrice = (identifiers, fuels) => {
        const specificFuel = fuels.find(fuel => {
            for (const identifier of identifiers) {
                if (fuel.service_nt_title.toLocaleLowerCase().includes(identifier.toLocaleLowerCase())) {
                    return true
                }
            }
            return false
        })
        return specificFuel?.price_client && specificFuel?.currency_client ? `${parseFloat(specificFuel.price_client).toFixed(2)} ${specificFuel.currency_client}` : "-";
    }

    return (   
        <>
           {loaded && <TableRow
                hover
                tabIndex={-1}
                className={fuelCardsStyles.pricesLine}
                >
                <TableCell className={fuelCardsStyles.headCell} align="left">{row.address}</TableCell>
                <TableCell align="center">{getFuelPrice(["92"], fuels)}</TableCell>
                <TableCell align="center">{getFuelPrice(["95"], fuels)}</TableCell>
                <TableCell align="center">{getFuelPrice(["97"], fuels)}</TableCell>
                <TableCell align="center">{getFuelPrice(["Diesel"], fuels)}</TableCell>
                <TableCell align="center">{getFuelPrice(["LPG", "Liquefied hydrocarbon"], fuels)}</TableCell>
                <TableCell align="center">{getFuelPrice(["AdBlue"], fuels)}</TableCell>
            </TableRow>}
        </>                                     
    )
}

export default FuelPricesRow;