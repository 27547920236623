import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import stylesMod from "styles/newOrEdit.module.css";
import styles from "./newWarehouse.module.css";
import Input from "components/input";
import Select from "react-select";
import { DBurl } from "appConfig";
import BTNstyles from "styles/button.module.css";
import { Add } from "@material-ui/icons";
import Contact from "./Contact";
import { AddressSearch } from "components/addressSearch";
import { IconButton } from "@material-ui/core";
import ShowPointOnMap from "../order/ShowPointOnMap";
import { X, Download, MapPin } from "react-feather";
import TmsDialog from "components/TmsDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewWarehouse(props) {
  const [client, setClient] = useState(props.data?.client || null);
  const [name, setName] = useState(props.data?.name || null);
  const [clientOption, setClientOption] = useState(null);
  const [address, setAddress] = useState(
    props.data?.address || {
      country: null,
      city: null,
      street: null,
      street_no: null,
      apartment_no: null,
      zipcode: null,
      longitude: null,
      latitude: null,
      country_code: null,
      district: null,
    }
  );
  const [contacts, setContact] = useState(
    props.data?.contacts || [
      {
        fullName: "",
        firstname: "",
        surname: "",
        role: "",
        phone: "",
        email: "",
        primary_contact: true,
      },
    ]
  );
  const [openModalShowPoint, setOpenModalShowPoint] = useState(false);
  const [tmpData, setTmpData] = useState([]);
  const [error, setError] = useState(false);

  const checkError = () => {
    if (
      !name ||
      !address.country ||
      !address.city ||
      !address.street ||
      !address.street_no ||
      !address.zipcode
    ) {
      return true;
    }
    return false;
  };

  const handleAddressChange = (val, name) => {
    const tempAddress = { ...address };
    tempAddress[name] = val;
    setAddress(tempAddress);
  };

  const handleContactChange = (val, name, i) => {
    const tempContact = [...contacts];
    tempContact[i][name] = val;
    setContact(tempContact);
  };

  const addContact = () => {
    const tempContact = [
      ...contacts,
      {
        fullname: "",
        firstname: "",
        surname: "",
        role: "",
        phone: "",
        mail: "",
      },
    ];
    setContact(tempContact);
  };

  const removeContact = (i) => {
    let tempContact = [...contacts];
    if (tempContact.length !== 1) {
      tempContact.splice(i, 1);
    } else {
      tempContact = [
        {
          fullname: "",
          firstname: "",
          surname: "",
          role: "",
          phone: "",
          mail: "",
        },
      ];
    }
    setContact(tempContact);
  };

  const contactModule = contacts.map((e, i) => {
    return (
      <Contact
        contacts={e}
        key={i}
        propKey={i}
        addContact={addContact}
        removeContact={removeContact}
        handleContact={handleContactChange}
      />
    );
  });

  useEffect(() => {
    const getContractors = async function () {
      const response = await fetch(`${DBurl}/getContractors/`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
      });
      const json = await response.json();
      if (json.success) {
        let tempClientOption = [];
        json.data.forEach((el) => {
          tempClientOption = [
            ...tempClientOption,
            { value: el.id, label: el.name, name: "saved" },
          ];
        });
        setClientOption(tempClientOption);
        if (props.data?.id_contractor) {
          let clientTemp = tempClientOption.find(
            (e) => e.value === props.data?.id_contractor
          );
          setClient(clientTemp);
        }
      } else {
        props.setDialog({
          isOpen: true,
          type: "error",
          content: `${json.responseDescription} // ${json.response}`,
        });
      }
    };
    getContractors();
  }, []);

  useEffect(() => {
    const data = {
      address: {
        street: address.street || null,
        city: address.city || null,
        zipcode: address.zipcode || null,
        country: address.country || null,
        district: null,
        street_no: address.street_no || null,
        apartment_no: address.apartment_no || null,
        longitude: address.longitude || null,
        latitude: address.latitude || null,
      },
    };

    setTmpData(data);
  }, [address]);

  const handleSave = () => {
    const fetchUrl = `${DBurl}/setWarehouses`;

    const contactTemp = [...contacts];
    contactTemp.forEach((e, i) => {
      i === 0 ? (e.primary_contact = true) : (e.primary_contact = false);
    });

    const data = {
      id_contractor: client?.value,
      name: name,
      shortname: name,
      street: address.street,
      city: address.city,
      zipcode: address.zipcode,
      country: address.country,
      district: null,
      street_no: address.street_no,
      apartment_no: address.apartment_no,
      longitude: address.longitude,
      latitude: address.latitude,
      contacts: contactTemp,
      id: props.edit ? props.data?.id : null,
      address_display_name: null,
    };

    if (checkError()) {
      setError(true);
      props.setDialog({
        isOpen: true,
        type: "error",
        content: "Wypełnij wymagane pola",
      });
    } else {
      setError(false);
      fetch(fetchUrl, {
        method: "post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
        body: JSON.stringify({
          mode: props.edit ? "update" : "insert",
          data: data,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            props.fetchWarehouse();
            props.setDialog({
              isOpen: true,
              type: "success",
              content: props.edit ? "Zapisano zmiany" : "Magazyn został dodany",
            });
            props.setOpen(false);
          }
        });
    }
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="xl"
        style={{ height: "100%" }}
        open={openModalShowPoint}
        onClose={() => {
          setOpenModalShowPoint(false);
        }}
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Podgląd trasy
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              setOpenModalShowPoint(false);
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ShowPointOnMap
            csrf_token={props.user.csrf_token}
            traceData={tmpData}
            setTmpData={setTmpData}
            type={"warehouse"}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={`${BTNstyles.btn} ${BTNstyles.err}`}
            onClick={(e) => {
              setOpenModalShowPoint(false);
            }}
          >
            Anuluj <X className={BTNstyles.downBtnIco} />
          </Button>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={BTNstyles.btn}
            onClick={(e) => {
              setOpenModalShowPoint(false);
              setAddress((prev) => ({
                ...prev,
                longitude: tmpData.address.longitude,
                latitude: tmpData.address.latitude,
              }));
              props.setDialog({
                isOpen: true,
                type: "success",
                content: "Dodano nowe współrzędne.",
              });
            }}
          >
            Zapisz punkt <Download className={BTNstyles.downBtnIco} />
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth="lg"
        open={props.open}
        TransitionComponent={Transition}
        onClose={() => {
          props.setOpen(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          {props.edit ? "Edytuj magazyn" : "Dodaj magazyn"}
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              props.setOpen(false);
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent className={stylesMod.noOverflow}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div className={stylesMod.inp}>
                <h6>Dane Klienta</h6>
                <label>Klient</label>
                <Select
                  value={client}
                  className={stylesMod.select}
                  options={clientOption}
                  onChange={(e) => {
                    setClient(e);
                  }}
                  name="client"
                  placeholder="Wybierz..."
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      border: "1px solid #142f42",
                      boxShadow: "none",
                      "&:hover": {
                        border: "1px solid #142f42",
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                    }),
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={12}>
              <div className={styles.flexRow}>
                <div>
                  <div className={stylesMod.inp}>
                    <label>Nazwa magazynu*</label>
                    <Input
                      value={name}
                      handleChange={(e) => {
                        setName(e.target.value);
                      }}
                      name="name"
                      error={error && !name}
                    />
                  </div>
                </div>

                <div>
                  <div className={stylesMod.inp}>
                    <AddressSearch
                      token={props.user.csrf_token}
                      setAddress={setAddress}
                      error={error}
                    />
                  </div>
                </div>

                <div>
                  <div className={stylesMod.inp} style={{ maxWidth: "110px" }}>
                    <label>Kraj*</label>
                    <Input
                      value={address.country}
                      handleChange={(e) => {
                        handleAddressChange(e.target.value, e.target.name);
                      }}
                      name="country"
                      error={error && !address.country}
                    />
                  </div>
                </div>

                <div>
                  <div className={stylesMod.inp} style={{ maxWidth: "100px" }}>
                    <label>Kod pocztowy*</label>
                    <Input
                      value={address.zipcode}
                      handleChange={(e) => {
                        handleAddressChange(e.target.value, e.target.name);
                      }}
                      name="zipcode"
                      error={error && !address.zipcode}
                    />
                  </div>
                </div>

                <div>
                  <div className={stylesMod.inp} style={{ maxWidth: "130px" }}>
                    <label>Miasto*</label>
                    <Input
                      value={address.city}
                      handleChange={(e) => {
                        handleAddressChange(e.target.value, e.target.name);
                      }}
                      name="city"
                      error={error && !address.city}
                    />
                  </div>
                </div>

                <div>
                  <div className={stylesMod.inp}>
                    <label>Ulica*</label>
                    <Input
                      value={address.street}
                      handleChange={(e) => {
                        handleAddressChange(e.target.value, e.target.name);
                      }}
                      name="street"
                      error={error && !address.street}
                    />
                  </div>
                </div>

                <div>
                  <div className={stylesMod.inp} style={{ maxWidth: "70px" }}>
                    <label>Nr*</label>
                    <Input
                      value={address.street_no}
                      handleChange={(e) => {
                        handleAddressChange(e.target.value, e.target.name);
                      }}
                      name="street_no"
                      error={error && !address.street_no}
                    />
                  </div>
                </div>

                <div>
                  <div className={stylesMod.inp} style={{ maxWidth: "70px" }}>
                    <label>Lokal</label>
                    <Input
                      value={address.apartment_no}
                      handleChange={(e) => {
                        handleAddressChange(e.target.value, e.target.name);
                      }}
                      name="apartment_no"
                    />
                  </div>
                </div>

                <div>
                  {tmpData?.address?.latitude &&
                    tmpData?.address?.longitude && (
                      <div
                        className={stylesMod.inp}
                        style={{ minWidth: "165px" }}
                      >
                        <label></label>
                        <Button
                          className={`${BTNstyles.btnMin} ${BTNstyles.blue}`}
                          onClick={(e) => {
                            setOpenModalShowPoint(true);
                          }}
                        >
                          Koryguj współrzędne
                          <MapPin
                            style={{ minWidth: "16px", maxWidth: "16px" }}
                          />
                        </Button>
                      </div>
                    )}
                </div>
              </div>
            </Grid>
            {contactModule}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={`${BTNstyles.btn} ${BTNstyles.err}`}
            onClick={(e) => {
              props.setOpen(false);
            }}
          >
            Anuluj
          </Button>
          <Button
            variant="contained"
            size="large"
            className={`${BTNstyles.btn} ${BTNstyles.grn}`}
            onClick={handleSave}
            color="primary"
          >
            {props.edit ? "Zapisz" : "Dodaj"}{" "}
            <Add className={BTNstyles.downBtnIco} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
