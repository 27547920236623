import React, { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import { X } from "react-feather";
import Input from "components/input";
import { DBurl } from "appConfig";
import stylesMod from "styles/newOrEdit.module.css";
import BTNstyles from "styles/button.module.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateTransportSet = ({
  token,
  car,
  trailer,
  driver,
  driverTwo,
  open,
  setOpen,
  setNewTransportSetID,
  setDialog,
}) => {
  const [name, setName] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    if (name.length < 1) setError(true);
    else setError(false);
  }, [name]);

  const postTransportSet = async () => {
    const response = await fetch(`${DBurl}/setTransportSets/`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify({
        mode: "insert",
        data: {
          label: name,
          id_car: car.value,
          id_trailer: trailer.value || null,
          id_driver1: driver.value,
          id_driver2: driverTwo.value || null,
          is_double_cast_of_drivers: driverTwo.value ? true : false,
          axle_netto: null,
          is_global: false,
        },
      }),
    });
    const json = await response.json();
    if (json.success) {
      setNewTransportSetID(json?.data?.new_id);
      setDialog({
        isOpen: true,
        type: "success",
        content: "Zestaw transportowy zapisany pomyślnie.",
      });
    } else {
      setDialog({
        isOpen: true,
        type: "error",
        content: "Błąd przy zapisie zestawu transportowego.",
      });
    }
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Utwórz zestaw transportowy
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              setOpen(false);
              setName("");
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div>
                Wskazany zestaw transportowy nie istnieje. Utwórz zestaw aby
                kontynuować.
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={stylesMod.inp}>
                <label>Nazwa:</label>
                <Input
                  value={name}
                  handleChange={(e) => setName(e.target.value)}
                  name="transportSetName"
                  error={error && name.length < 1}
                />
              </div>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <div className={stylesMod.inp}>
                <label>Pojazd:</label>
                <Input value={car?.label} name="car" disabled />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={stylesMod.inp}>
                <label>Naczepa:</label>
                <Input value={trailer?.label} name="trailer" disabled />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={stylesMod.inp}>
                <label>Kierowca 1:</label>
                <Input value={driver?.label} name="driver1" disabled />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={stylesMod.inp}>
                <label>Kierowca 2:</label>
                <Input value={driverTwo?.label} name="driver2" disabled />
              </div>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={BTNstyles.btn}
            onClick={(e) => {
              setOpen(false);
              setName("");
            }}
          >
            Anuluj
          </Button>
          <Button
            variant="contained"
            size="large"
            className={`${BTNstyles.btn} ${BTNstyles.grn}`}
            onClick={() => {
              if (!error) {
                postTransportSet();
                setOpen(false);
                setName("");
              } else {
                setDialog({
                  isOpen: true,
                  type: "error",
                  content: "Podaj nazwę nowego zestawu transportowego.",
                });
              }
            }}
            color="primary"
          >
            Zapisz
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateTransportSet;
