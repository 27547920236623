import { useEffect } from "react";
import { DBurl } from "../../../appConfig";

export const useEditInvoice = (
  invoiceIdFromProps,
  token,
  allSetStates,
  departments,
  clients,
  typeOptions,
  isCorrection,
  exchangeRate,
  tags_list,
  selected_tags
) => {
  const {
    setInvoiceNum,
    setIssuePlace,
    setIssueDate,
    setSaleServiceDate,
    setDocumentType,
    setSeller,
    setCompanyName,
    setTaxNum,
    setStreet,
    setCity,
    setCountry,
    setBankAccName,
    setBank,
    setAccNum,
    setTaxAccNum,
    setSwiftAccNum,
    setEori,
    setDbo,
    setBuyer,
    setBuyerCompanyName,
    setBuyerTaxNum,
    setBuyerStreet,
    setBuyerCity,
    setBuyerCountry,
    setBuyerEori,
    setBuyerDbo,
    setPersonToIssue,
    setPersonToPickUp,
    setSelectedCurrency,
    setServices,
    setPaymentDeadline,
    setPaymentDeadlineDay,
    setDescription,
    setAdvancePayment,
    setTotalGross,
    setPaymentOption,
    setBankAccName2,
    setBank2,
    setAccNum2,
    setTaxAccNum2,
    setSwiftAccNum2,
    setBankAccName3,
    setBank3,
    setAccNum3,
    setTaxAccNum3,
    setSwiftAccNum3,
    setSecondAccount,
    setThirdAccount,
    setNumberFromProps,
    setLinkedInvNum,
    setAccCurrency,
    setAcc2Currency,
    setAcc3Currency,
    setReceiver,
    setInvReceiver,
    setReceiverCompanyName,
    setReceiverTaxNum,
    setReceiverStreet,
    setReceiverCity,
    setReceiverCountry,
    setExchangeRateDate,
    setSelected_tags,
    setBuyerBankAccName,
    setBuyerBank,
    setBuyerAccNum,
    setBuyerTaxAccNum,
    setBuyerSwiftAccNum,
    setBuyerAccountCurreny,
    setBuyerSecondAccount,
    setBuyerBankAccName2,
    setBuyerBank2,
    setBuyerAccNum2,
    setBuyerTaxAccNum2,
    setBuyerSwiftAccNum2,
    setBuyerAccountCurreny2
  } = allSetStates;

  useEffect(() => {
  }, [JSON.stringify(tags_list), JSON.stringify(selected_tags)])

  const getDealer = (id) => departments.find((dep) => dep?.value === id);
  const getClient = (id) => clients.find((cli) => cli?.value === id);
  const getDocumentType = (id) =>
    typeOptions.find((typeOption) => parseInt(typeOption.kind) === id);
  const getDeadlineDays = (issueDate, deadLineDate) => {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date(deadLineDate);
    const secondDate = new Date(issueDate);

    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

    return diffDays;
  };

  const getInvoice = async () => {
    const response = await fetch(
      `${DBurl}/getInvoices/?id=${invoiceIdFromProps}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token,
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      const data = json.data[0];
      const seller = getDealer(data?.dealer?.id);
      const buyer = getClient(data?.contractor.id);
      const issueDate = new Date(data?.date_of_issue.substring(10, 0));
      const deadlineDate = new Date(data?.payment_deadline.substring(10, 0));
      const numberOfDays = getDeadlineDays(deadlineDate, issueDate);
      const allTags = data.tags.map(tag => tag.id_tag)
      setSelected_tags(allTags)
      if (data?.type === 1 && data?.kind === null)
        setDocumentType({ label: "Faktura VAT", value: 1 });
      if (data?.type === 1 && data?.kind === "1")
        setDocumentType({ label: "Faktura VAT EU", value: 1 });
      if (data?.type === 1 && data?.kind === "2")
        setDocumentType({
          label: "Faktura VAT (mechanizm podzielonej płatności)",
          value: 1,
        });
      if (data?.type === 4 && data?.kind === null)
        setDocumentType({ label: "Nota obciążeniowa", value: 4 });
      if (data?.type === 5 && data?.kind === null)
        setDocumentType({ label: "Nota uznaniowa", value: 5 });
      if (data.type === 1 && !isCorrection) setInvoiceNum(data?.invoice_no);
      if (data.type === 4 && !isCorrection) setInvoiceNum(data?.debit_note_no);
      if (data.type === 5 && !isCorrection) setInvoiceNum(data?.credit_note_no);
      if (data.type === 4 || data.type === 5 || isCorrection)
        setLinkedInvNum(data.invoice_no);
      setNumberFromProps(data?.invoice_no);
      setIssuePlace(data?.place_of_issue);
      setIssueDate(issueDate);
      setSaleServiceDate(
        !isCorrection
          ? new Date(data?.date_service.substring(10, 0))
          : new Date()
      );
      data?.date_of_exchange_rate
        ? setExchangeRateDate(
          new Date(data?.date_of_exchange_rate.substring(10, 0))
        )
        : setExchangeRateDate(new Date(data?.date_of_issue.substring(10, 0)));
      setSeller({ label: seller?.label, value: seller?.value });
      setCompanyName(seller?.name);
      setStreet(
        `${seller?.street ? seller?.street : ""} ${seller?.street_no ? seller?.street_no : ""
        } ${seller?.apartment_no !== " " && seller?.apartment_no ? "/" : ""} ${seller?.apartment_no ? seller?.apartment_no : ""
        }`
      );
      setTaxNum(seller?.nip);
      setCountry(seller?.country);
      setCity(`${seller?.zipcode} ${seller?.city}`);
      setBankAccName({
        label: data?.dealer_account_name_1,
        value: null,
        acc_name: data?.dealer_account_name_1,
      });
      setBank(data?.dealer_bank_name_1);
      setAccNum(data?.dealer_bank_account_no_1);
      setTaxAccNum(data?.dealer_bank_vat_account_no_1);
      setSwiftAccNum(data?.dealer_swift_bic_no_1);
      setEori(data?.dealer_eori);
      setDbo(data?.dealer_bdo);
      setBuyer(buyer);
      setBuyerCompanyName(buyer?.label);
      setBuyerTaxNum(buyer?.nip);
      setBuyerStreet(
        `${buyer?.street ? buyer?.street : ""} ${buyer?.street_no ? buyer?.street_no : ""
        } ${buyer?.apartment_no !== " " && buyer?.apartment_no ? "/" : ""} ${buyer?.apartment_no ? buyer?.apartment_no : ""
        }`
      );
      setBuyerCity(buyer?.city);
      setBuyerCountry(buyer?.country);
      setBuyerEori(data?.contractor_eori);
      setBuyerDbo(data?.contractor_bdo);
      setBuyerBankAccName(data?.contractor_account_name_1);
      setBuyerBank(data?.contractor_bank_name_1);
      setBuyerAccNum(data?.contractor_bank_account_no_1);
      setBuyerTaxAccNum(data?.contractor_bank_vat_account_no_1);
      setBuyerSwiftAccNum(data?.contractor_swift_bic_no_1);
      if (
        data?.contractor_account_name_2 !== null &&
        data?.contractor_account_name_2 !== undefined &&
        data?.contractor_account_name_2 !== ""
      ) {
        setBuyerSecondAccount(true);
        setBuyerBankAccName2(data?.contractor_account_name_2);
        setBuyerBank2(data?.contractor_bank_name_2);
        setBuyerAccNum2(data?.contractor_bank_account_no_2);
        setBuyerTaxAccNum2(data?.contractor_bank_vat_account_no_2);
        setBuyerSwiftAccNum2(data?.contractor_swift_bic_no_2);
        setBuyerAccountCurreny2(data?.contractor_account_currency_2);
      }
      setPersonToIssue(data?.person_to_issue);
      setPersonToPickUp(data?.person_to_pick_up);
      setSelectedCurrency(data?.currency);
      setServices(data?.services);
      setPaymentDeadline({
        label: numberOfDays === 1 ? "1 dzień" : `${numberOfDays} dni`,
        value: numberOfDays,
      });
      setDescription(data?.description);
      setAdvancePayment(data?.advance);
      setTotalGross(data?.person_to_issue);
      setPaymentOption({
        label: data?.payment_method,
        value: data?.payment_method,
      });
      setAccCurrency(data?.dealer_account_currency_1);
      if (
        data?.dealer_account_name_2 !== null &&
        data?.dealer_account_name_2 !== undefined &&
        data?.dealer_account_name_2 !== ""
      ) {
        setSecondAccount(true);
        setBankAccName2({
          label: data?.dealer_account_name_2,
          value: data?.dealer_account_name_2,
          acc_name: data?.dealer_account_name_2,
        });
        setBank2(data?.dealer_bank_name_2);
        setAccNum2(data?.dealer_bank_account_no_2);
        setTaxAccNum2(data?.dealer_bank_vat_account_no_2);
        setSwiftAccNum2(data?.dealer_swift_bic_no_2);
        setAcc2Currency(data?.dealer_account_currency_2);
      }
      if (
        data?.dealer_account_name_3 !== null &&
        data?.dealer_account_name_3 !== "" &&
        data?.dealer_account_name_3 !== undefined
      ) {
        setThirdAccount(true);
        setBankAccName3({
          label: data?.dealer_account_name_3,
          value: data?.dealer_account_name_3,
          acc_name: data?.dealer_account_name_3,
        });
        setBank3(data?.dealer_bank_name_3);
        setAccNum3(data?.dealer_bank_account_no_3);
        setTaxAccNum3(data?.dealer_bank_vat_account_no_3);
        setSwiftAccNum3(data?.dealer_swift_bic_no_3);
        setAcc3Currency(data?.dealer_account_currency_3);
      }
      setReceiver(data?.is_recipient);
      if (data?.is_recipient) {
        setInvReceiver({
          label: data?.contractor2?.shortname,
          value: data?.contractor2?.id,
        });
        setReceiverCompanyName(data?.contractor2?.name);
        setReceiverTaxNum(data?.contractor2?.nip);
        setReceiverStreet(
          `${data?.contractor2?.address?.street
            ? data?.contractor2?.address?.street
            : ""
          } ${data?.contractor2?.address?.street_no
            ? data?.contractor2?.address?.street_no
            : ""
          } ${data?.contractor2?.address?.apartment_no !== " " &&
            data?.contractor2?.address?.apartment_no
            ? "/"
            : ""
          } ${data?.contractor2?.address?.apartment_no
            ? data?.contractor2?.address?.apartment_no
            : ""
          }`
        );
        setReceiverCity(
          `${data?.contractor2?.address?.zipcode} ${data?.contractor2?.address?.city}`
        );
        setReceiverCountry(data?.contractor2?.address?.country);
      }
    }
  };

  useEffect(() => {
    invoiceIdFromProps && departments && clients && getInvoice();
  }, [invoiceIdFromProps, JSON.stringify(departments), JSON.stringify(clients), JSON.stringify(tags_list)]);
};
