import React, { useState, useContext, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";

import TmsSelect from "components/TmsSelect";
import TmsDialog from "components/TmsDialog";
import TmsLoading from "components/TmsLoading";
import IcoBtn from "components/buttons/icoBtn";
import { perPageButtons } from "containers/order/utilities/constants";
import OrderPerPageButton from "containers/order/OrderPerPageButton";
import Pagination from "components/pagination";
import RouteEditModal from "./RouteEditModal";
import TmsDeleteModal from "components/TmsDeleteModal";

import { countries } from "data/countries";
import { UserContext } from "contexts/userContext";
import {
  getContractors,
  getRoutesFavourites,
  getExchangeRates,
  deleteRoutesFavourites,
} from "api/endpoints";
import {
  Contractor,
  ExchangeRate,
  FavouriteRoute,
  HereMapsToll,
  SelectOptionState,
  TmsDialogState,
} from "types";
import {
  EditRouteModal,
  DeleteRouteModal,
  ParsedFavouriteRoute,
} from "./FavouriteRoutes.types";

import styles from "containers/table.module.css";
import "./styles.sass";

const FavouriteRoutes = () => {
  const [contractorsOptions, setContractorsOptions] = useState<
    SelectOptionState[]
  >([]);
  const [contractorSelect, setContractorSelect] =
    useState<SelectOptionState>(null);
  const [editModal, setEditModal] = useState<EditRouteModal>({ isOpen: false });
  const [deleteModal, setDeleteModal] = useState<DeleteRouteModal>({
    isOpen: false,
  });
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [dialog, setDialog] = useState<TmsDialogState>({ isOpen: false });
  const [page, setPage] = useState(0);

  const { user } = useContext(UserContext);

  const paginate = (pageNumber: number) => setPage(pageNumber - 1);

  useQuery<Contractor[], AxiosError>("contractors", getContractors, {
    onSuccess: (contractors) => {
      if (contractors) {
        setContractorsOptions(
          contractors.map((contractor) => ({
            value: contractor.id.toString(),
            label: contractor.shortname,
          }))
        );
      } else {
        setDialog({
          isOpen: true,
          type: "error",
          content: "Nie można pobrać kontrahentów",
        });
      }
    },
  });

  const {
    data: favoritesRoutes,
    isLoading: favoritesRoutesLoading,
    refetch: refetchFavoritesRoutes,
  } = useQuery<FavouriteRoute[], AxiosError>(
    ["getRoutesFavourites", contractorSelect?.value],
    () =>
      getRoutesFavourites({
        filter_id_firm: user.id_firm,
        filter_id_contractor: contractorSelect?.value,
      }),
    {
      onError: (error) => {
        setDialog({ isOpen: true, type: "error", content: error.message });
      },
    }
  );

  const { data: exchangeRates } = useQuery<ExchangeRate[]>(
    "exchangeRates",
    getExchangeRates
  );

  const { mutate: deleteRoute } = useMutation(
    "deleteRoutesFavourites",
    deleteRoutesFavourites,
    {
      onSuccess: () => {
        refetchFavoritesRoutes();
        setDialog({
          isOpen: true,
          type: "success",
          content: `Trasa "${deleteModal.name}" została usunięta.`,
        });
      },
      onError: (error: AxiosError) => {
        setDialog({
          isOpen: true,
          type: "error",
          content: `Nie udało się usunąć trasy / ${error.message}`,
        });
      },
    }
  );

  const handleDelete = () => {
    if (deleteModal.id) {
      deleteRoute({
        ids: [deleteModal.id],
      });
    }
  };

  const parsedRoutes = useMemo((): ParsedFavouriteRoute[] | undefined => {
    const onlyWithAddress = favoritesRoutes?.filter(
      (route) =>
        route?.locations?.[0]?.warehouse !== undefined ||
        route?.locations?.[0]?.address !== undefined
    );

    if (onlyWithAddress) {
      return onlyWithAddress.map((route) => ({
        addressFrom:
          route.locations[0]?.warehouse?.address || route.locations[0]?.address,
        addressTo:
          route.locations[route.locations.length - 1]?.warehouse?.address ||
          route.locations[route.locations.length - 1]?.address,
        ...route,
      }));
    }
  }, [favoritesRoutes]);

  const getCountryCode = (countryName: string) => {
    const country = countries.filter(
      (country) => country.label === countryName
    )[0];

    return country.code.toLowerCase();
  };

  const calculateCosts = (favoriteRoute: FavouriteRoute) => {
    const result = favoriteRoute.route.response;

    let temp_tolls_arr = [];
    let allTolls: HereMapsToll[] | any = [];

    for (var i = 0; i < result.routes[0].sections.length; i++) {
      if (result.routes[0].sections[i].tolls) {
        temp_tolls_arr.push(result.routes[0].sections[i].tolls);
      } else if (result.routes[0].sections[i].type === "transit") {
        if (result.routes[0].sections[i].transport.mode === "ferry") {
          temp_tolls_arr.push(result.routes[0].sections[i].transport);
        }
      }
    }

    for (let t = 0; t < temp_tolls_arr.length; t++) {
      allTolls = allTolls.concat(temp_tolls_arr[t]);
    }

    const exchangeToPLN = (
      value: number,
      code: string,
      arr: ExchangeRate[]
    ) => {
      if (arr) {
        if (arr.length > 0) {
          let tmpExData = arr.find((o: ExchangeRate) => {
            return o.code === code;
          });
          if (tmpExData) {
            let mpExValue =
              tmpExData && typeof tmpExData.value === "string"
                ? Number(tmpExData.value)
                : tmpExData.value;
            let val =
              tmpExData && typeof tmpExData.value === "string"
                ? Number(value)
                : value;
            let result = Number(mpExValue) * val;
            return Number.isNaN(result) ? 0 : result.toFixed(2);
          } else {
            return "-";
          }
        }
      }
    };

    let temp_tolls = allTolls;
    let sum = 0;

    if (exchangeRates) {
      if (temp_tolls.length > 0) {
        for (const val of temp_tolls) {
          if (val?.mode !== "ferry") {
            if (val?.fares[0].price.currency === "PLN") {
              sum += Number(val.fares[0].price.value) || 0;
            } else if (val?.fares[0].price.currency === "RUB") {
              sum += (Number(val.fares[0].price.value) || 0) * 0.063;
            } else {
              let temp_to_pln = exchangeToPLN(
                val.fares[0].price.value,
                val.fares[0].price.currency,
                exchangeRates
              );
              let parsed_value = Number(temp_to_pln);
              if (!Number.isNaN(parsed_value)) {
                sum += parsed_value || 0;
              }
            }
          }
        }
      }
    }

    if (sum > 0) {
      return (
        <div>
          {sum.toFixed(2)} <small> PLN</small>
        </div>
      );
    }
  };

  const openEditModal = (favoriteRoute: FavouriteRoute) => {
    favoriteRoute.route.id_order = null;

    setEditModal({
      isOpen: true,
      route: { ...favoriteRoute, trace: favoriteRoute.locations },
    });
  };

  return (
    <>
      <div className="routes-header">
        <div className="routes-header--left">
          <h1 className="routes-header__title">Trasy</h1>
          <div className="routes-header__buttons"></div>
        </div>
        <div className="routes-header--right">
          <div className="routes-header__filter">
            <label>Kontrahent:</label>
            <TmsSelect
              className="routes-header__select"
              options={contractorsOptions}
              name="filter"
              value={contractorSelect}
              onChange={(option) => {
                setContractorSelect(option);
              }}
              isClearable
            />
          </div>
        </div>
      </div>
      <TableContainer className="routes-table">
        <Table stickyHeader>
          <TableHead className="routes-table__header">
            <TableRow>
              <TableCell size="medium" padding="normal">
                <label className="routes-table__cell">Nazwa trasy</label>
              </TableCell>
              <TableCell size="medium" padding="normal">
                <label className="routes-table__cell">Poczatek trasy</label>
              </TableCell>
              <TableCell size="medium" padding="normal">
                <label className="routes-table__cell">Koniec trasy</label>
              </TableCell>
              <TableCell size="medium" padding="normal" align="right">
                <label className="routes-table__cell">Koszty drogowe</label>
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell
                size="medium"
                padding="normal"
                align="right"
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {parsedRoutes
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((favoriteRoute) => (
                <TableRow key={favoriteRoute.id}>
                  <TableCell>{favoriteRoute.name}</TableCell>
                  <TableCell>
                    {favoriteRoute.addressFrom.country && (
                      <div className="routes-table__route">
                        <img
                          src={`img/flags/${getCountryCode(
                            favoriteRoute.addressFrom.country
                          )}.gif`}
                        />
                        <div>
                          {favoriteRoute.addressFrom.city},{" "}
                          {favoriteRoute.addressFrom.country}
                        </div>
                      </div>
                    )}
                  </TableCell>
                  <TableCell>
                    {favoriteRoute.addressTo.country && (
                      <div className="routes-table__route">
                        <img
                          src={`img/flags/${getCountryCode(
                            favoriteRoute.addressTo.country
                          )}.gif`}
                        />
                        <div>
                          {favoriteRoute.addressTo.city},{" "}
                          {favoriteRoute.addressTo.country}
                        </div>
                      </div>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {calculateCosts(favoriteRoute)}
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell align="right">
                    <div className="routes-table__options">
                      <IcoBtn
                        icon="Edit"
                        tooltip="Edytuj"
                        onClick={() => {
                          openEditModal(favoriteRoute);
                        }}
                      />
                      <IcoBtn
                        icon="Trash"
                        tooltip="Usuń"
                        className="btn-delete"
                        onClick={() => {
                          setDeleteModal({
                            isOpen: true,
                            id: favoriteRoute.id,
                            name: favoriteRoute.name,
                          });
                        }}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <div
          className={styles.tableBottom}
          style={{ width: "calc(100vw - 200px)", position: "fixed", bottom: 0 }}
        >
          <div>
            {perPageButtons.map(({ value, minNumber }) => (
              <OrderPerPageButton
                key={value}
                tableLength={favoritesRoutes ? favoritesRoutes.length : 0}
                minNumber={minNumber}
                value={value}
                templatesLength={0}
                setRowsPerPage={setRowsPerPage}
                rowsPerPage={rowsPerPage}
              />
            ))}
          </div>
          <div>
            <Pagination
              postsPerPage={rowsPerPage}
              totalPosts={favoritesRoutes?.length}
              paginate={paginate}
              page={page}
            />
          </div>
        </div>
      </TableContainer>

      {editModal.isOpen && (
        <RouteEditModal
          editModal={editModal}
          setEditModal={setEditModal}
          refetchFavoritesRoutes={refetchFavoritesRoutes}
          setDialog={setDialog}
        />
      )}
      {deleteModal.isOpen && (
        <TmsDeleteModal
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          deleteFunction={handleDelete}
        />
      )}
      <TmsDialog dialog={dialog} setDialog={setDialog} />
      {favoritesRoutesLoading && <TmsLoading status="Pobieranie tras" />}
    </>
  );
};

export default FavouriteRoutes;
