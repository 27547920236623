import React, { useState, useEffect, useRef, useMemo } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { components } from "react-select";
import { useQuery } from "react-query";
import { useFormik } from "formik";
import { Plus } from "react-feather";
import { IconButton, Grid } from "@material-ui/core";
import { DBurl } from "appConfig";
import Title from "components/title";
import Input from "components/input";
import TmsLoading from "components/TmsLoading";
import RatesPerKmModal from "components/RatesPerKmModal";
import NewWarehouse from "containers/client/NewWarehouse";
import styles from "containers/table.module.css";
import AddProfile from "containers/settings/addProfile";
import CreateTransportSet from "./TransportSet/CreateTransportSet";
import NewOrderInput from "./newOrderInput";
import BasicInformation from "./BasicInformation";
import TransportSet from "./TransportSet";
import Cargo from "./Cargo/Cargo";
import Locations from "./Locations";
import OrderCosts from "./OrderCosts";
import ModalProfilQuestion from "./ModalProfilQuestion";
import Footer from "./Footer";

import {
  getTransportSets,
  getWarehouses,
  getRatesPerKm,
  getOrderName,
} from "api/endpoints";
import {
  checkError,
  checkTraceError,
  getOrder,
  resetValues,
  checkTransportSet,
} from "./newOrder.functions";
import { newOrderDefaultValues, currencyOption } from "./newOrder.data";

import stylesVersion from "styles/version_s.module.css";
import stylesMod from "styles/newOrEdit.module.css";

const NewOrder = ({
  addTab,
  setTabs,
  state,
  tabID,
  tabs,
  tabCloseAndChangeTo,
  setDialog,
  user,
  propKey,
}) => {
  const [toCostValues, setToCostValues] = useState(false);
  const [isShowRequiredFields, setIsShowRequiredFields] = useState(false);
  const [openAddModalProfile, setOpenAddModalProfile] = useState(false);
  const [openAddModalProfileData, setOpenAddModalProfileData] = useState({});
  const [editProfile, setEditProfile] = useState(false);
  const [openModalProfilQuestion, setOpenModalProfilQuestion] = useState(false);
  const [openModalShowRoute, setOpenModalShowRoute] = useState(false);
  const [warehousesOptions, setWarehousesOptions] = useState([]);
  const [openModalNewWarehouse, setOpenModalNewWarehouse] = useState(false);
  const [openModalRatesPerKm, setOpenModalRatesPerKm] = useState(false);
  const [openModalCreateTransportSet, setOpenModalCreateTransportSet] =
    useState(false);
  const [user_s, setUser_s] = useState(
    user.tms_version_s === true ? user.tms_version_s : false
  );
  const [mainRouteChange, setMainRouteChange] = useState(false);
  const [newTransportSetID, setNewTransportSetID] = useState(undefined);
  const [newTransportSetFromEdit, setNewTransportSetFromEdit] = useState(null);
  const [error, setError] = useState(false);
  const [transportOptions, setTransportOptions] = useState([]);
  const [clearHooksState, setClearHooksState] = useState(false);
  const [files, setFiles] = useState(state.files || []);
  const isLoading = useRef(0);

  const newOrder = useFormik({
    initialValues: state.values || { ...newOrderDefaultValues },
  });

  const values = newOrder.values;

  let isPreview =
    state.id_invoice || values.isLoading || state.previewComponent;

  if (state.isCopy) {
    isPreview = false;
  }

  const updateValue = (name, value) => {
    newOrder.setFieldValue(name, value);
  };

  const updateValues = newOrder.setValues;

  const [order_start_temp, setDateFrom_temp] = useState(
    state?.order_start?.replace(/ /g, "T") || ""
  );
  const [order_stop_temp, setDateTo_temp] = useState(
    state?.order_stop?.replace(/ /g, "T") || ""
  );
  const [order_start, setDateFrom] = useInput(
    { type: "datetime-local" },
    "Od",
    order_start_temp
  );
  const [order_stop, setDateTo] = useInput(
    { type: "datetime-local" },
    "Do",
    order_stop_temp
  );

  const { refetch: refetchRatesPerKm } = useQuery(
    ["ratesPerKm", values.contractor.value],
    () => getRatesPerKm(values.contractor.value),
    {
      enabled: Boolean(values.contractor.value),
      onSuccess: (ratesPerKm) => {
        if (ratesPerKm?.length > 0) {
          updateValue("ratesPerKm", ratesPerKm[0]);
        } else {
          updateValue("ratesPerKm", { rate_netto: 0 });
        }
      },
      onError: (error) => {
        setDialog({
          isOpen: true,
          type: "error",
          content: `Błąd pobierania stawki za kilometr: ${error.message}`,
        });
      },
    }
  );

  const { refetch: getSets } = useQuery(
    ["transportSets", newTransportSetID],
    getTransportSets,
    {
      onSuccess: (transportSets) => {
        setTransportOptions(
          transportSets?.map((transportSet) => ({
            ...transportSet,
            value: transportSet.id,
          }))
        );
      },
      onError: (error) => {
        setDialog({ isOpen: true, type: "error", content: error.message });
      },
    }
  );

  const updateCarProfile = (carProfile) => {
    updateValue("transport.set.car_profile", carProfile);
  };

  const { refetch: refetchWarehouses } = useQuery(
    ["warehouses", values.contractor.value],
    () => getWarehouses(values.contractor.value),
    {
      enabled: Boolean(values.contractor),
      onSuccess: (warehouses) => {
        if (warehouses) {
          setWarehousesOptions(
            warehouses?.map((warehouse) => ({
              ...warehouse,
              value: warehouse.id,
              label: warehouse.name,
            }))
          );
        }
      },
      onError: (error) => {
        setDialog({ isOpen: true, type: "error", content: error.message });
      },
    }
  );

  const enableGenerateOrderNumber = useMemo(() => {
    if (isPreview || state.isEdit) {
      return false;
    }

    return true;
  }, [isPreview, state.isEdit]);

  const { refetch: refetchGetOrderName } = useQuery(
    ["getOrderName"],
    getOrderName,
    {
      onSuccess: (response) => {
        if (response?.order_name) {
          updateValue("order_no", response?.order_name);
        } else {
          setDialog({
            isOpen: true,
            type: "error",
            content: "Nie mogę wygenerować nowego numeru zlecenia",
          });
        }
      },
      onError: (error) => {
        setDialog({ isOpen: true, type: "error", content: error.message });
      },
      enabled: enableGenerateOrderNumber,
    }
  );

  const savedTabId = tabID;

  const saveTab = () => {
    const tempTab = { ...tabs[savedTabId] };
    tempTab.files = files;
    tempTab.values = values;

    return tempTab;
  };

  useEffect(() => {
    const tempTab = [...tabs];
    tempTab[tabID] = saveTab();

    setTabs(tempTab);
  }, [values, files]);

  const resetForm = () => {
    resetValues(updateValues, setFiles);
    refetchGetOrderName();
  };

  const settingTransportSet = (data) => {
    const newTransportSet = newTransportSetFromEdit
      ? { ...newTransportSetFromEdit }
      : { ...data };

    if (newTransportSet) {
      updateValues({
        ...values,
        transport: {
          ...values.transport,
          set: newTransportSet,
          vehicle: newTransportSet.car_name,
          driver: {
            label: newTransportSet?.driver1_name,
            value: newTransportSet?.id_driver1,
          },
          driverTwo: {
            label: newTransportSet?.driver2_name,
            value: newTransportSet?.id_driver2,
          },
          car: {
            label: newTransportSet?.car_name,
            value: newTransportSet?.id_car,
          },
          trailer: {
            label: newTransportSet?.trailer_name,
            value: newTransportSet?.id_trailer,
          },
          isDedicated: newTransportSet.isDedicated,
          dedicated: {
            company: newTransportSet.dedicatedTo,
            timeStart: newTransportSet.dedicatedTimeStart,
            timeEnd: newTransportSet.dedicatedTimeEnd,
          },
          semiTrailer: !user_s && newTransportSet.trailer_name,
        },
      });
    }
  };

  useEffect(() => {
    const newTransportSet = transportOptions?.find(
      (el) => el?.id == newTransportSetID
    );
    if (newTransportSet) {
      setNewTransportSetFromEdit(newTransportSet);
      settingTransportSet(newTransportSet);

      updateValues({
        ...values,
        transport: {
          ...values.transport,
          set: newTransportSet,
          trailer: {
            label: newTransportSet?.trailer_name,
            value: newTransportSet?.id_trailer,
          },
          driver: {
            label: newTransportSet?.driver1_name,
            value: newTransportSet?.id_driver1,
          },
          driverTwo: {
            label: newTransportSet?.driver2_name,
            value: newTransportSet?.id_driver2,
          },
        },
      });
    }
  }, [transportOptions?.length]);

  useEffect(() => {
    if (state.order_type_S === true) {
      setUser_s(true);
    }
  }, [state.order_type_S]);

  useEffect(() => {
    if (state.previewComponent) {
      updateValue("previewComponent", true);
    }
  }, [state]);

  const ValueContainer = ({ children, selectProps, ...props }) => (
    <components.ValueContainer {...props}>
      <div className={stylesMod.clientAddParent}>
        <IconButton
          className={stylesMod.btnIcon}
          onClick={() => {
            selectProps.selectProps(
              selectProps.link ? selectProps.link : "AddClient",
              false,
              selectProps.saveTab()
            );
          }}
        >
          <Plus className={stylesMod.clientAdd} />
        </IconButton>
      </div>
      {children}
    </components.ValueContainer>
  );

  function useInput({ type }, placeholder, initialValue) {
    const [value, setValue] = useState(() => initialValue);
    const input = (
      <Input
        value={value}
        handleChange={(e) => {
          setValue(e.target.value);
        }}
        type={type}
        placeholder={placeholder}
        className={styles.searchInputSmall}
        error={error && value.length < 1}
        search={type === "text" ? true : false}
      />
    );

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return [value, input];
  }

  const triggerMainRouteChange = () => {
    setMainRouteChange(true);
  };

  const checkTsId = (id, array) => {
    if (id && array.length > 0) {
      const set = array.find((e) => e.id == id);
      settingTransportSet(set);
    }
  };

  useEffect(() => {
    if (!values.transport?.set?.id) {
      if (transportOptions?.length > 0 && values.tsId) {
        checkTsId(values.tsId, transportOptions);
      }
    }
  }, [values.transport?.set?.id, transportOptions, values?.tsId]);

  const loadOrder = async () => {
    updateValue("isFetching", true);
    await getOrder({
      id: state.fetchOrderId,
      userToken: user.csrf_token,
      order_type_S: state.order_type_S,
      checkedGoods: values.checkedGoods,
      updateValues,
      setDateFrom_temp,
      setDateTo_temp,
      setFiles,
      isEdit: state.isEdit,
      isCopy: state.isCopy,
      isTemplate: state.isTemplate,
    });
    if (state.isCopy) {
      await refetchGetOrderName();
    }
    updateValue("isFetching", false);
  };

  useEffect(() => {
    if (state.fetchOrderId) {
      loadOrder();
    }
  }, [state.fetchOrderId]);

  useEffect(() => {
    if (
      !!Number(values.kilometers.sum) &&
      !!Number(values.ratesPerKm.rate_netto)
    ) {
      updateValue(
        "cost",
        (
          Number(values.kilometers.sum) * Number(values.ratesPerKm.rate_netto)
        ).toFixed(2)
      );
    }
  }, [values.kilometers.sum, values.ratesPerKm.rate_netto]);

  useEffect(() => {
    if (Number(values.kilometers.cargo) < 0) {
      updateValue("kilometers.cargo", 0);
    } else if (Number(values.kilometers.access) < 0) {
      updateValue("kilometers.access", 0);
    } else if (
      Number(values.kilometers.access) < 0 ||
      values.kilometers.cargo === undefined
    ) {
      updateValue("kilometers.sum", 0 + Number(values.kilometers.access));
    } else {
      updateValue(
        "kilometers.sum",
        Number(values.kilometers.cargo) + Number(values.kilometers.access)
      );
    }
  }, [values.kilometers.access, values.kilometers.cargo, values.trace]);

  useEffect(() => {
    if (clearHooksState === true) {
      setClearHooksState(false);
    }
  }, [clearHooksState]);

  const checkTransportSetFunction = () =>
    checkTransportSet(
      values,
      transportOptions,
      user,
      updateValue,
      setDialog,
      setOpenModalCreateTransportSet
    );

  if (values.isFetching) {
    return (
      <>
        <Title title={state.tabName} />
        <TmsLoading status="Pobieranie danych" />
      </>
    );
  }

  return (
    <>
      <Title title={state.tabName} />
      <Grid container spacing={5} className={stylesMod.cont}>
        <BasicInformation
          values={values}
          updateValue={updateValue}
          updateValues={updateValues}
          addTab={addTab}
          error={error}
          setDialog={setDialog}
          user_s={user_s}
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          toCostValues={toCostValues}
          setToCostValues={setToCostValues}
          ValueContainer={ValueContainer}
          saveTab={saveTab}
          isShowRequiredFields={isShowRequiredFields}
          setIsShowRequiredFields={setIsShowRequiredFields}
          currencyOption={currencyOption}
          setOpenModalRatesPerKm={setOpenModalRatesPerKm}
          transport_set={values.transport.set}
          isLoading={isLoading}
          isPreview={isPreview}
          getOrderName={refetchGetOrderName}
        />
        <TransportSet
          values={values}
          transport={values.transport}
          updateValue={updateValue}
          addTab={addTab}
          propKey={propKey}
          transportOptions={transportOptions}
          settingTransportSet={settingTransportSet}
          error={error}
          ValueContainer={ValueContainer}
          isShowRequiredFields={isShowRequiredFields}
          saveTab={saveTab}
          toCostValues={toCostValues}
          user_s={user_s}
          currencyOption={currencyOption}
          stylesVersion={stylesVersion}
          Tooltip={Tooltip}
          NewOrderInput={NewOrderInput}
          user={user}
          files={files}
          setFiles={setFiles}
          checkTransportSet={checkTransportSetFunction}
          isPreview={values?.isLocationDriverStatus || isPreview}
        />
      </Grid>
      {!state.previewComponent && (
        <Cargo
          cargo={values.cargo}
          driverNote={values.driverNote}
          updateValue={updateValue}
          checkedGoods={values.checkedGoods}
          error={error}
          setDialog={setDialog}
          addTab={addTab}
          saveTab={saveTab}
          user_s={user_s}
          is_required_weight={values.is_required_weight}
          id_invoice={null}
          disable_cargo_quantity_check={values.disable_cargo_quantity_check}
          isPreview={isPreview}
        />
      )}
      <Locations
        values={values}
        updateValue={updateValue}
        user={user}
        addTab={addTab}
        user_s={user_s}
        checkError={() => checkError(values, user_s)}
        setError={setError}
        setMainRouteChange={setMainRouteChange}
        setOpenModalShowRoute={setOpenModalShowRoute}
        setOpenModalProfilQuestion={setOpenModalProfilQuestion}
        setOpenAddModalProfileData={setOpenAddModalProfileData}
        clearHooksState={clearHooksState}
        isShowRequiredFields={isShowRequiredFields}
        toCostValues={toCostValues}
        saveTab={saveTab}
        warehousesOptions={warehousesOptions}
        error={error}
        setOpenModalNewWarehouse={setOpenModalNewWarehouse}
        triggerMainRouteChange={triggerMainRouteChange}
        checkTraceError={() => checkTraceError(values.trace)}
        previewComponent={state.previewComponent}
        isPreview={isPreview}
      />
      <OrderCosts
        values={values}
        updateValue={updateValue}
        updateValues={updateValues}
        addTab={addTab}
        error={error}
        user_s={user_s}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        toCostValues={toCostValues}
        setToCostValues={setToCostValues}
        ValueContainer={ValueContainer}
        saveTab={saveTab}
        isShowRequiredFields={isShowRequiredFields}
        setIsShowRequiredFields={setIsShowRequiredFields}
        currencyOption={currencyOption}
        setOpenModalRatesPerKm={setOpenModalRatesPerKm}
        transport_set={values.transport.set}
        isLoading={isLoading}
        isPreview={isPreview}
      />
      <AddProfile
        open={openAddModalProfile}
        setOpen={setOpenAddModalProfile}
        data={openAddModalProfileData}
        user={user}
        editProfile={editProfile}
        setEditProfile={setEditProfile}
        refresh={getSets}
        setOpenSnack={setDialog}
        newOrder={true}
        setDialog={setDialog}
        newOrderProfileUpdate={true}
        updateCarProfile={updateCarProfile}
        isPreview={isPreview}
      />
      {!values.isLoading && !state.previewComponent && (
        <Footer
          values={values}
          updateValue={updateValue}
          transport_set={values.transport.set}
          user_s={user_s}
          checkError={() => checkError(values, user_s)}
          isEdit={values.isEdit}
          checkTraceError={() => checkTraceError(values.trace)}
          setDialog={setDialog}
          openModalShowRoute={openModalShowRoute}
          setOpenModalShowRoute={setOpenModalShowRoute}
          setOpenModalProfilQuestion={setOpenModalProfilQuestion}
          setOpenAddModalProfileData={setOpenAddModalProfileData}
          setClearHooksState={setClearHooksState}
          setError={setError}
          mainRouteChange={mainRouteChange}
          user={user}
          checkTransportSet={checkTransportSetFunction}
          state={state}
          setMainRouteChange={setMainRouteChange}
          order_start={order_start}
          order_stop={order_stop}
          files={files}
          resetForm={resetForm}
          loadOrder={loadOrder}
          tabCloseAndChangeTo={tabCloseAndChangeTo}
          refetchGetOrderName={refetchGetOrderName}
          isPreview={isPreview}
        />
      )}
      <ModalProfilQuestion
        openModalProfilQuestion={openModalProfilQuestion}
        setOpenModalProfilQuestion={setOpenModalProfilQuestion}
        transport_set={values.transport.set}
        setOpenAddModalProfile={setOpenAddModalProfile}
      />
      <NewWarehouse
        open={openModalNewWarehouse}
        setOpen={setOpenModalNewWarehouse}
        edit={false}
        user={user}
        fetchWarehouse={() => {
          refetchWarehouses();
        }}
      />
      <RatesPerKmModal
        open={openModalRatesPerKm}
        setOpen={setOpenModalRatesPerKm}
        setDialog={setDialog}
        contractor={values.contractor}
        token={user.csrf_token}
        refresh={() => refetchRatesPerKm()}
        ratesPerKm={values.ratesPerKm}
      />
      <CreateTransportSet
        token={user.csrf_token}
        car={values.transport.car}
        trailer={values.transport.trailer}
        driver={values.transport.driver}
        driverTwo={values.transport.driverTwo}
        open={openModalCreateTransportSet}
        setOpen={setOpenModalCreateTransportSet}
        setNewTransportSetID={setNewTransportSetID}
        setDialog={setDialog}
      />
      {values.isLoading && <TmsLoading status="Zapisywanie danych" />}
    </>
  );
};

export default NewOrder;
