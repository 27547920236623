import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '../../components/input'
import Title from '../../components/title'
import styles from '../table.module.css'
import RowsPerPage from '../../components/RowsPerPage'
import Pagination from '../../components/pagination'
import Bar from '../../components/Bar'
import Status from '../../components/StatusDriver'
import IcoBtn from '../../components/buttons/icoBtn';
// import NewOrder from '../order/newOrder'
import TachoIcon from '../../components/tachoIcons'
import Tooltip from '@material-ui/core/Tooltip';
import OrderDialog from '../order/orderDialog'
import IconButton from '@material-ui/core/IconButton';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import SelectorsDialog from '../../components/selectorsDialog'
import { DBurl } from '../../appConfig'
import Timer from '../../components/Timer'

function importAll(r) {
	let images = {};
	r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
	return images;
}

const images = importAll(require.context('../../img/flags', false, /\.(png|gif|svg)$/));

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array?.map((el, index) => [el, index]);
	if (stabilizedThis) {
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}

}

const headCells = [
	{ id: 'name', numeric: false, disablePadding: true, label: 'Pojazd' },
	{ id: 'actualOrder', numeric: true, disablePadding: false, label: 'Zlecenie' },
	{ id: 'progress', numeric: true, disablePadding: false, label: '% realizacji zlecenia' },
	{ id: 'status', numeric: false, disablePadding: false, label: 'Status' },
	{ id: 'driver', numeric: true, disablePadding: false, label: 'Kierowca' },
	{ id: 'selector', numeric: true, disablePadding: false, label: 'Selektory' }, ,
	{ id: 'kmToTarget', numeric: true, disablePadding: false, label: 'Km do celu' },
	{ id: 'position', numeric: true, disablePadding: false, label: 'Pozycja' }
];

function EnhancedTableHead(props) {
	const { styles, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, setSearchVehicle, setSearchOrder, setSearchDriver } = props;

	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<>
			<TableHead className={styles.tabHead}>
				<TableRow>
					<TableCell className={styles.checkbox}>
						<Checkbox
							indeterminate={numSelected > 0 && numSelected < rowCount}
							checked={rowCount > 0 && numSelected === rowCount}
							onChange={onSelectAllClick}
							inputProps={{ 'aria-label': 'select all id' }}
						/>
					</TableCell>
					{headCells.map((headCell) => (
						headCell.id === "selector" ? (
							<TableCell
								key={headCell.id}
								align={headCell.numeric ? 'right' : 'left'}
								padding={headCell.disablePadding ? 'none' : 'default'}
								sortDirection={orderBy === headCell.id ? order : false}
							>
								{headCell.label}
							</TableCell>
						) : (
							<TableCell
								key={headCell.id}
								align={headCell.numeric ? 'right' : 'left'}
								padding={headCell.disablePadding ? 'none' : 'default'}
								sortDirection={orderBy === headCell.id ? order : false}
							>
								<TableSortLabel
									active={orderBy === headCell.id}
									direction={orderBy === headCell.id ? order : 'asc'}
									onClick={createSortHandler(headCell.id)}
									style={headCell.id === 'selector' ? { paddingRight: "10px" } : null}
								>
									{headCell.label}
									{orderBy === headCell.id ? (

										<span className={styles.visuallyHidden}>
											{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
										</span>
									) : null}
								</TableSortLabel>
							</TableCell>
						)))}
					{/* <TableCell className={styles.buttonBoxParent} /> */}
				</TableRow>
			</TableHead>
			<TableHead className={styles.tabHead}>
				<TableRow className={styles.secondStickyRow}>
					<TableCell></TableCell>
					{setSearchVehicle}
					{setSearchOrder}
					<TableCell></TableCell>
					<TableCell></TableCell>
					{setSearchDriver}
					<TableCell></TableCell>
					<TableCell></TableCell>
					<TableCell></TableCell>
					{/* <TableCell></TableCell> */}
				</TableRow>
			</TableHead>
		</>
	);
}

EnhancedTableHead.propTypes = {
	styles: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

function useInput({ type }) {
	const [value, setValue] = useState("")
	const input = <TableCell ><Input value={value} handleChange={e => {
		setValue(e.target.value)
	}
	} type={type}
		search={true} /></TableCell>;
	return [value, input];
}

export default function EnhancedTable(props) {
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('name');
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(15);
	const [table, setTable] = useState([]);
	const [searchVehicle, setSearchVehicle] = useInput({ type: "text" });
	const [searchOrder, setSearchOrder] = useInput({ type: "text" });
	const [searchDriver, setSearchDriver] = useInput({ type: "text" });
	const [openOrder, setOpenOrder] = useState(false);
	const [orderData, setOrderData] = useState({});
	const [rowData, setRowData] = useState('');
	const [fullscreenOn, setFullscreenOn] = useState(false);
	const [openSelectorsModal, setOpenSelectorsModal] = useState(false);
	const [openSelectorsData, setOpenSelectorsData] = useState({})
	const [openOrderStatus, setOpenOrderStatus] = useState(false);
	const [orderStatusData, setOrderStatusData] = useState({});

	async function getStateOfTheFleetS() {
		props.loading(true)
		await fetch(`${DBurl}/getStateOfTheFleetS/`, {
			method: "GET",
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": props.user.csrf_token
			}
		}).then(res => res.json())
			.then(
				data => {
					setTable(data.data || [])
					props.loading(false)
				}
			)
	}

	useEffect(() => {
		getStateOfTheFleetS()
	}, [])


	const fullscreenFunc = useFullScreenHandle();

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';

		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	}

	const search = (tbl) => {

		return tbl.filter((row) => {
			const tempOrder_np = row.active_order.order_no || "";
			const tempDriver = row.driver || ""
			return row.name.toString().toLowerCase().indexOf(searchVehicle.toLowerCase()) > -1 &&
				tempOrder_np?.toString().toLowerCase().indexOf(searchOrder.toLowerCase()) > -1 &&
				tempDriver.toString().toLowerCase().indexOf(searchDriver.toLowerCase()) > -1
		})
	}

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = table?.map((n) => n.id);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		setSelected(newSelected);
	};

	const paginate = pageNumber => setPage(pageNumber - 1);
	const isSelected = (name) => selected.indexOf(name) !== -1;

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, table?.length - page * rowsPerPage);

	const getOpenSelectorsData = row => {

		// TODO fetch po id po dane obecne i historyczne do selektorów danego kierowcy/auta
		// Przygotować JSONa z przykładowymi danymi i zaimportować Tu

		const yesterdayData = {
			drivingTime: row.tacho_drive_yesterday,
			workTime: row.tacho_work_yesterday,
			stopTime: row.tacho_break_yesterday,
			stopEngineOnTime: row.tacho_rest_yesterday
		}

		const todayData = {
			drivingTime: row.tacho_drive_today,
			workTime: row.tacho_work_today,
			stopTime: row.tacho_break_today,
			stopEngineOnTime: row.tacho_rest_today
		}

		// Funkcja która przyjmuje dwa czasy jako stringi "12:34" i dodaje je do siebie zwracając stringa w tym samym formacie

		// function addingTime(firstTime, secondTime) {
		// 	const firstTable = firstTime.split(":")
		// 	const secondTable = secondTime.split(":")
		// 	let resultMinutes = Number(firstTable[1]) + Number(secondTable[1])
		// 	let resultHours = Number(firstTable[0]) + Number(secondTable[0])
		// 	if (resultMinutes > 59) {
		// 		resultMinutes -= 60
		// 		resultHours += 1
		// 	}
		// 	if (resultMinutes < 10) { resultMinutes = `0${resultMinutes}` }
		// 	if (resultHours < 10) { resultHours = `0${resultHours}` }

		// 	return `${resultHours}:${resultMinutes}`
		// }

		const summaryData = {
			drivingTime: row.tacho_drive_sum,
			workTime: row.tacho_work_sum,
			stopTime: row.tacho_break_sum,
			stopEngineOnTime: row.tacho_rest_sum
		}

		return {
			yesterday: yesterdayData,
			today: todayData,
			summary: summaryData
		}
	}

	const exportToXLS = () => {
		const url = `${DBurl}/printStateOfTheFleetXLS`;
		window.open(url, '_blank');
	}


	return (
		<div className={styles.root}>
			<FullScreen handle={fullscreenFunc}>
				<Paper className={styles.paper}>
					<Title title={props.state.tabName}
						btnBox
						btnExport
						btnAdd
						addFunc={() => props.addTab('newOrder')}
						btnAddTxt="Dodaj zlecenie"
						btnFullscreen
						exportFunc={() => { exportToXLS() }}
						fullscreenOn={fullscreenOn}
						setFullscreenOn={setFullscreenOn}
						fullscreenFunc={() => {
							fullscreenOn ? fullscreenFunc.exit() : fullscreenFunc.enter()
							setFullscreenOn(!fullscreenFunc.active)
						}}
						cornersNew
						refresh={<Timer refreshTable={getStateOfTheFleetS} table={table}/>}
					// }

					/>
					<TableContainer className={styles.stickyTable}>
						<Table
							// style={{ tableLayout: "auto" }}
							stickyHeader
							className={styles.table}
							aria-labelledby="tableTitle"
							size="small"
							aria-label="enhanced table"
						>

							<EnhancedTableHead
								styles={styles}
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onSelectAllClick={handleSelectAllClick}
								onRequestSort={handleRequestSort}
								rowCount={table?.length}

								setSearchVehicle={setSearchVehicle}
								setSearchOrder={setSearchOrder}
								setSearchDriver={setSearchDriver}
							/>
							<TableBody className={styles.tabBody}>

								{stableSort(search(table), getComparator(order, orderBy))
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => {

										const isItemSelected = isSelected(row.id);
										const labelId = `enhanced-table-checkbox-${index}`;

										const active_order_last_status = (row.active_order_last_status !== null) ? row.active_order_last_status[0].id_status : null;

										return (
											<TableRow
												hover
												onClick={(event) => handleClick(event, row.id)}
												role="checkbox"
												aria-checked={isItemSelected}
												tabIndex={-1}
												key={row.id}
												selected={isItemSelected}
											>
												<TableCell className={styles.checkbox}>
													<Checkbox
														checked={isItemSelected}
														color="primary"
														inputProps={{ 'aria-labelledby': labelId }}

													/>
												</TableCell>
												<TableCell align="left">{row.name}</TableCell>
												<TableCell align="right">
													<div className={styles.tableCenter}>
														<span className={styles.mr10}>{row.active_order.exists ? row.active_order.order_no : "Brak aktywnego zlecenia"}</span>
														{row.active_order.exists && <IcoBtn icon="Search" tooltip="Podgląd" value={row} onClick={(e) => {
															setOpenOrder(true)
															setRowData(row)
															setOrderData(row.active_order)
														}} />}

														{row.active_order.exists && <IcoBtn icon="Info" tooltip="Historia statusów" value={row} onClick={(e) => {
															setOpenOrderStatus(true)
															setRowData(row)
															setOrderStatusData(row.active_order)
														}} />}
													</div>
												</TableCell>


												<TableCell align="left">
													{row.progress_to_destination !== null ?
														<Bar
															value={row.progress_to_destination !== null ? row.progress_to_destination.percentage_progress : 0} color={
																active_order_last_status === 1 ||
																	active_order_last_status === 4 ||
																	active_order_last_status === 5 ||
																	active_order_last_status === 7 ||
																	active_order_last_status === 9 ||
																	active_order_last_status === 13 ||
																	active_order_last_status === 12
																	? "green" :
																	(active_order_last_status === 2 ||
																		active_order_last_status === 3 ||
																		active_order_last_status === 6 ||
																		active_order_last_status === 8
																		? "yellow" : "red"
																	)} /> :
														"Brak danych"}

												</TableCell>
												<TableCell align="left" className={styles.status_order}>
													<Status num={active_order_last_status} />
												</TableCell>
												<TableCell align="right">
													<div className={styles.tableCenter}>
														<span className={styles.mr10}>{row.driver?.name}</span>
														{row.driver?.allow_mobile && <IcoBtn icon="Chat" tooltip="Komunikator" value={row} onClick={(e) => {
															props.addTab("Communication", row.driver.id)
														}} />}
													</div>

												</TableCell>
												<TableCell align="right" >
													<div className={styles.tachoiconsParent}>
														<div>
															<Tooltip disableHoverListener={false} title="Jazda">
																<IconButton className={styles.smallIcons} onClick={() => {
																	setOpenSelectorsModal(true)
																	setOpenSelectorsData(getOpenSelectorsData(row))
																}}>
																	<TachoIcon icon="IcoDrive" />
																</IconButton>
															</Tooltip>
															{row.tacho_drive_today}

														</div>
														<div>
															<Tooltip disableHoverListener={false} title="Odpoczynek">
																<IconButton className={styles.smallIcons}
																	onClick={() => {
																		setOpenSelectorsModal(true)
																		setOpenSelectorsData(getOpenSelectorsData(row))
																	}}>
																	<TachoIcon icon="IcoStop" />
																</IconButton>
															</Tooltip>
															{row.tacho_break_today}
														</div>
														<div>
															<Tooltip disableHoverListener={false} title="Dyspozycyjność">
																<IconButton className={styles.smallIcons}
																	onClick={() => {
																		setOpenSelectorsModal(true)
																		setOpenSelectorsData(getOpenSelectorsData(row))
																	}}>
																	<TachoIcon icon="IcoAvailability" />
																</IconButton>
															</Tooltip>
															{row.tacho_rest_today}
														</div>
														<div>
															<Tooltip disableHoverListener={false} title="Praca">
																<IconButton className={styles.smallIcons}
																	onClick={() => {
																		setOpenSelectorsModal(true)
																		setOpenSelectorsData(getOpenSelectorsData(row))
																	}}>
																	<TachoIcon icon="IcoWork" />
																</IconButton>
															</Tooltip>
															{row.tacho_work_today}
														</div>
													</div>
												</TableCell>
												<TableCell align="right">{row.progress_to_destination !== null ? `${row.progress_to_destination.km_to_destination} km` : "brak zlecenia"}</TableCell>
												<TableCell align="right">
													<div className={styles.tableCenter}>
														{/* TODO: dopasować flagi w zależności od współrzędnych  */}

														<span className={styles.positionFlag}>														<img src={images[`${row.position_address?.address?.country_code}.gif`]} />{row.position ? `${row.position?.lat}, ${row.position?.lng}` : "brak danych"}</span>

														{row.position && <IcoBtn icon="Pin" tooltip="Wskaż na mapie" value={row} onClick={(e) => {
															props.addTab("Map", row.position)
														}} />}
													</div>

												</TableCell>

											</TableRow>
										);
									})}
								{emptyRows > 0 && (
									<TableRow style={{ height: 33 * emptyRows }}>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<div className={styles.tableBottom} >
						<RowsPerPage
							rowsPerPage={rowsPerPage}
							setRowsPerPage={setRowsPerPage}
							setPage={setPage}
							tableLength={table.length}
						/>
						<div>
							<Pagination
								postsPerPage={rowsPerPage}
								totalPosts={search(table).length}
								paginate={paginate}
								page={page}
							/>
						</div>
					</div>
					{openOrder && <OrderDialog
						data={orderData}
						open={openOrder}
						driverDate={rowData.driver}
						setOpen={setOpenOrder}
						mainData={rowData}
						addTab={props.addTab}
						user={props.user}
						tms_version_s={props.user.tms_version_s}
						order_type_S={true}
					/>}

					{openOrderStatus && <OrderDialog
						orderStatus={true}
						data={orderStatusData}
						open={openOrderStatus}
						setOpen={setOpenOrderStatus}
						mainData={rowData}
						addTab={props.addTab}
						user={props.user}
					/>}

					{openSelectorsModal && <SelectorsDialog
						data={openSelectorsData}
						open={openSelectorsModal}
						setOpen={setOpenSelectorsModal}
						mainData={rowData}
						addTab={props.addTab} />}
				</Paper>
			</FullScreen>
		</div>
	)
}