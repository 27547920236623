import React, { Component } from 'react'

export default class Payments extends Component {
	// constructor(props) {
	// 	super(props);
	// }

	render() {
		return (
			<div>
				Payments - Płatności
			</div>
		)
	}
}
