import { DBurl } from "appConfig";

export const addFleetCostsApiCall = async <T,>(url: string, token: string, data: T, handleSuccess: () => void, handleFailure :() => void) => {

    const response = await fetch(`${DBurl}/${url}`, {
        method: "POST",
        credentials: 'include',
        headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": token
        },
        body: JSON.stringify({
            mode: "insert",
            data
        })
    });

    const json = await response.json()

    if (json.success) {
        handleSuccess()

    }else {
        handleFailure()
    }
}

export const confirmFleetCostsReport = async <T,>(token: string, data: T, handleSuccess: () => void, handleFailure :() => void) => {

    const response = await fetch(`${DBurl}/setFleetCostsReports`, {
        method: "POST",
        credentials: 'include',
        headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": token
        },
        body: JSON.stringify({
            mode: "insert",
            data
        })
    });

    const json = await response.json()

    if (json.success) {
        handleSuccess()

    }else {
        handleFailure()
    }
}
