import React, { Component } from 'react'
import { LogIn } from 'react-feather';
import styles from './btnStyles.module.css'
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

export default class LogInBtn extends Component {

	render() {
		return (
			<Tooltip title={this.props.tooltip} disableHoverListener={!this.props.tooltip}>
				<IconButton onClick={this.props.handleChange} value={this.props.value} className={`${styles.btn} ${styles.primary}`} >
					<LogIn />
				</IconButton>
			</Tooltip>
		)
	}
}
