import React, { useState, useEffect } from "react";
import Input from "../../components/input";
import { FileBtn } from "../../components/buttons";
import Title from "../../components/title";
import BTNstyles from "../../styles/button.module.css";
import styles from "../table.module.css";
import stylesMod from "../../styles/newOrEdit.module.css";
import { X } from "react-feather";
import RowsPerPage from "../../components/RowsPerPage";
import Pagination from "../../components/pagination";
import { DBurl } from "../../appConfig";
import Grid from "@material-ui/core/Grid";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  IconButton,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Slide,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Add } from "@material-ui/icons";
import IcoBtn from "../../components/buttons/icoBtn";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CiclicDialog from "../../components/CiclicDialog";
import Select from "react-select";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (array?.length > 0) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
}

const headCells = [
  {
    id: "invoiceNr",
    numeric: false,
    disablePadding: true,
    label: "Nr faktury",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Typ",
    class: styles.statusShort,
  },
  { id: "order", numeric: true, disablePadding: false, label: "Zlecenie" },
  { id: "company", numeric: true, disablePadding: false, label: "Klient" },
  {
    id: "saleDate",
    numeric: true,
    disablePadding: false,
    label: "Data Sprzedaży",
  },
  {
    id: "paidDate",
    numeric: true,
    disablePadding: false,
    label: "Data wystawienia",
  },
  { id: "netto", numeric: true, disablePadding: false, label: "Wartość netto" },
  {
    id: "value_brutto",
    numeric: true,
    disablePadding: false,
    label: "Wartość brutto",
  },
  {
    id: "validDate",
    numeric: true,
    disablePadding: false,
    label: "Termin płatności",
  },
  {
    id: "madeBy",
    numeric: true,
    disablePadding: false,
    label: "Wystawiona przez",
  },
  // { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Wyślij ponaglenie",
  },
];

function EnhancedTableHead(props) {
  const {
    styles,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={styles.tabHead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={headCell.class}
            style={{ padding: "10px 20px" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={styles.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function useInput({ type }) {
  const [value, setValue] = useState("");
  const input = (
    <TableCell>
      <Input
        value={value}
        handleChange={(e) => {
          setValue(e.target.value);
        }}
        type={type}
        search={true}
      />
    </TableCell>
  );
  return [value, input];
}

// let  templateListOptions = []

export default function Vindication(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("car");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [table, setTable] = useState([
    {
      invoice_no: "",
      orders: [],
      contractor: {},
      data_services: "",
    },
  ]);
  const [searchInvoiceNr, setSearchInvoiceNr] = useInput({ type: "text" });
  const [searchOrder, setSearchOrder] = useInput({ type: "text" });
  const [searchType, setSearchType] = useInput({ type: "text" });
  const [searchClient, setSearchClient] = useInput({ type: "text" });
  const [searchMadeBy, setSearchMadeBy] = useInput({ type: "text" });
  const [openModalTemplate, setOpenModalTemplate] = React.useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");
  const [openModalCiclic, setOpenModalCiclic] = React.useState(false);
  const [templateList, setTemplateList] = useState();
  const [openModalTemplateList, setOpenModalTemplateList] = useState(false);
  const [openModalSelectTemplate, setOpenModalSelectTemplate] = useState(false);
  const [ifEdit, setIfEdit] = useState(false);
  const [templateListOptions, setTemplateListOptions] = useState([]);
  const [currentTemplate, setCurrentTemplate] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [selectedInvoiceRow, setSelectedInvoiceRow] = useState();

  let invoice_Mail_adress = "info@mail.com";
  let invoice_Mail_subtitle = "Ponaglenie";

  const modules = {
    toolbar: false,
  };

  const fetchInvoiceReminderTemplates = async () => {
    props.loading(true);
    const response = await fetch(`${DBurl}/getInvoiceReminderTemplates`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setTemplateList(json.data);
      setTemplateListOptions(
        json.data.map((o, i) => {
          return { value: i, id: o.id, label: o.name, content: o.content };
        })
      );

      props.loading(false);
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  const fetchInvoices = async (token) => {
    props.loading(true);
    const response = await fetch(`${DBurl}/getInvoices`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
    });
    const json = await response.json();
    if (json.success) {
      if (json?.data?.length > 0) {
        let vindication_list = json.data.filter((o) => {
          let paid = Number(o.payment) + Number(o.advance);
          let value = o.value_brutto || getBrutto(o.services);
          const result =
            o.type === 4
              ? 4
              : Number(paid) === Number(value)
              ? 0
              : new Date(o.deadline) > new Date()
              ? 1
              : 2;
          return result === 2;
        });
        setTable(vindication_list);
      }

      props.loading(false);
    }
  };

  const getType = (type) => {
    switch (type) {
      case 1:
        return "Faktura VAT";
      case 2:
        return "Proforma";
      case 3:
        return "Korekta";
      case 4:
        return "Szablon";
      default:
        return "";
    }
  };

  const search = (tbl) => {
    return tbl.filter((row) => {
      return (
        row.invoice_no
          ?.toString()
          .toLowerCase()
          .indexOf(searchInvoiceNr.toLowerCase()) > -1 &&
        getType(row.type).toLowerCase().indexOf(searchType.toLowerCase()) >
          -1 &&
        row.contractor?.shortname
          ?.toString()
          .toLowerCase()
          .indexOf(searchClient.toLowerCase()) > -1 &&
        row.signature
          ?.toString()
          .toLowerCase()
          .indexOf(searchMadeBy.toLowerCase()) > -1
      );
    });
  };

  const paginate = (pageNumber) => setPage(pageNumber - 1);
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const getNetto = (arr, corArr) => {
    if (!!corArr) {
      const tempArr = [];
      arr.forEach((el, i) => {
        const correctionElement = corArr.find((el2) => el2.id === el.id);
        !!correctionElement
          ? tempArr.push(correctionElement)
          : tempArr.push(el);
      });
      return tempArr.reduce(
        (a, b) => Number(a) + (Number(b["value_brutto"]) || 0),
        0
      );
    } else {
      var result = 0;
      arr.forEach((e) => (result += Number(e.value_netto)));
      return result;
    }
  };

  const getBrutto = (arr) => {
    var result = 0;
    arr.forEach((e) => (result += Number(e.value_brutto)));
    return result;
  };

  function tempToMail(mail, iUser, iDate, iNo, iServices, iAmount, iCurrency) {
    let temp = mail.content;

    temp = temp.replaceAll("<p><br></p>", "<br>");
    temp = temp.replaceAll("<p>", "");
    temp = temp.replaceAll("</p>", "<br>");
    temp = temp.replaceAll("<br>", "\n");
    temp = temp.replaceAll("<span>", "");
    temp = temp.replaceAll("</span>", "");
    temp = temp.replaceAll('<span style="color: rgb(122, 122, 122);">', "");
    temp = temp.replaceAll('<strong style="color: rgb(102, 102, 102);">', "");
    temp = temp.replaceAll("<strong>", "");
    temp = temp.replaceAll("</strong>", "");

    temp = temp.replaceAll("[dłużnik]", iUser);
    temp = temp.replaceAll("[data]", iDate);
    temp = temp.replaceAll("[numer_faktury]", iNo);
    temp = temp.replaceAll("[usługa]", () => {
      if (iServices.length > 0) {
        let servicesList = iServices.map((o) => {
          return o.name.toLowerCase();
        });
        return String(servicesList).replaceAll(",", ", ");
      } else {
        return iServices;
      }
    });
    temp = temp.replaceAll("[kwota]", iAmount);
    temp = temp.replaceAll("[waluta]", iCurrency ? iCurrency : "PLN");

    return temp;
  }

  const cyclicRaport = async () => {
    setOpenModalCiclic(true);
  };

  const saveTemplate = async (data) => {
    props.loading(true);

    let temp = data.content;
    temp = temp.replaceAll("<p><br></p>", "<br>");
    temp = temp.replaceAll("<p>", "");
    temp = temp.replaceAll("</p>", "<br>");
    temp = temp.replaceAll('<span style="color: rgb(122, 122, 122);">', "");
    temp = temp.replaceAll("<span>", "");
    temp = temp.replaceAll("</span>", "");
    temp = temp.replaceAll('<strong style="color: rgb(102, 102, 102);">', "");
    temp = temp.replaceAll("<strong>", "");
    temp = temp.replaceAll("</strong>", "");

    const response = await fetch(`${DBurl}/setInvoiceReminderTemplates`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "update",
        data: {
          id: data.id,
          name: data.name,
          content: temp,
        },
      }),
    });
    const json = await response.json();
    if (json.success) {
      fetchInvoiceReminderTemplates();
      setOpenDialog(true);
      setDialogContent(`Definicja zaktualizowana poprawnie.`);
      setDialogColor("success");

      props.loading(false);
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  const addNewTemplate = async (data) => {
    props.loading(true);

    let temp = data.content;
    temp = temp.replaceAll("<p><br></p>", "<br>");
    temp = temp.replaceAll("<p>", "");
    temp = temp.replaceAll("</p>", "<br>");
    temp = temp.replaceAll('<span style="color: rgb(122, 122, 122);">', "");
    temp = temp.replaceAll("<span>", "");
    temp = temp.replaceAll("</span>", "");
    temp = temp.replaceAll('<strong style="color: rgb(102, 102, 102);">', "");
    temp = temp.replaceAll("<strong>", "");
    temp = temp.replaceAll("</strong>", "");

    const response = await fetch(`${DBurl}/setInvoiceReminderTemplates`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "insert",
        data: {
          name: data.name,
          content: temp,
        },
      }),
    });
    const json = await response.json();
    if (json.success) {
      fetchInvoiceReminderTemplates();
      setOpenDialog(true);
      setDialogContent(`Nowy szablon dodany poprawnie.`);
      setDialogColor("success");

      props.loading(false);
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  const delTemplate = async (id) => {
    props.loading(true);

    const response = await fetch(`${DBurl}/setInvoiceReminderTemplates`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "delete",
        data: {
          ids: [id],
        },
      }),
    });
    const json = await response.json();
    if (json.success) {
      fetchInvoiceReminderTemplates();
      setOpenDialog(true);
      setDialogContent(`Wybrany szablon został usunięty.`);
      setDialogColor("success");

      props.loading(false);
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const printPDF = (id) => {
    const url = `${DBurl}/printInvoice2PDF?token=${props.user.csrf_token}&id_invoice=${id}`;
    window.open(url, "_blank");
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDialog(false);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = table.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  useEffect(() => {
    if (openModalTemplate === true) {
      setState({ ...state, right: true });
    } else {
      setState({ ...state, right: false });
    }
  }, [openModalTemplate]);

  useEffect(() => {
    fetchInvoices(props.user.csrf_token);
  }, []);

  useEffect(() => {
    if (openModalTemplateList === true) {
      fetchInvoiceReminderTemplates();
    }
  }, [openModalTemplateList]);

  return (
    <>
      <div className={styles.root}>
        <Paper className={styles.paper}>
          <Title
            title={props.state.tabName}
            btnBox
            btnCyclic
            cyclicFunc={() => {
              cyclicRaport();
            }}
            rightSide
            vindicationTemplate
            modal={setOpenModalTemplateList}
          />
          <TableContainer className={styles.stickyTable}>
            <Table
              className={styles.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                styles={styles}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={table.length}
              />
              <TableBody className={styles.tabBody}>
                <TableRow>
                  {setSearchInvoiceNr}
                  {setSearchType}
                  {setSearchOrder}
                  {setSearchClient}
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  {setSearchMadeBy}
                  <TableCell></TableCell>
                </TableRow>
                {stableSort(search(table), getComparator(order, orderBy))
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        // selected={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
													<Checkbox
														checked={isItemSelected}
														color="primary"
														inputProps={{ 'aria-labelledby': labelId }}
													/>
												</TableCell> */}
                        <TableCell
                          align="left"
                          style={{ padding: "10px 20px" }}
                        >
                          {row.correction_no || row.invoice_no}
                        </TableCell>
                        <TableCell align="left">{getType(row.type)}</TableCell>
                        <TableCell align="right">
                          {row.orders?.[0]?.order_no}
                        </TableCell>
                        <TableCell align="right">
                          {row.contractor?.shortname}
                        </TableCell>
                        <TableCell align="right">
                          {row.date_service?.substring(11, 0)}
                        </TableCell>
                        <TableCell align="right">
                          {row.date_of_correction?.substring(11, 0) ||
                            row.date_of_issue?.substring(11, 0)}
                        </TableCell>
                        <TableCell align="right">
                          {getNetto(row.services, row.correction_services)}{" "}
                          {row.currency}
                        </TableCell>
                        <TableCell align="right">
                          {!!row.value_brutto
                            ? row.value_brutto
                            : getBrutto(row.services)}{" "}
                          {row.currency}
                        </TableCell>
                        <TableCell align="right">
                          {row.payment_deadline?.substring(11, 0)}
                        </TableCell>
                        <TableCell align="right">{row.signature}</TableCell>
                        <TableCell align="right">
                          <div className={styles.buttonBox}>
                            <IcoBtn
                              icon="Mail"
                              tooltip="Wyślij ponaglenie"
                              value={row}
                              onClick={(e) => {
                                // let valToMAil = tempToMail(mailTempValue, row.contractor.name, row.payment_deadline, row.invoice_no, row.services, row.value_brutto, row.currency)
                                // let location = '<a href="#">Pobierz fakture</a>'
                                // invoice_Mail_subtitle = row.invoice_no ? "Ponaglenie za fakturę o numerze: " + row.invoice_no : "Ponaglenie"

                                // let link = `mailto:${invoice_Mail_adress}?&subject=${encodeURIComponent(invoice_Mail_subtitle)}&attachedfile="https://dream.ai/logo.svg"&body=${encodeURIComponent(valToMAil)}`;

                                // window.open(link)
                                setSelectedInvoiceRow(row);
                                fetchInvoiceReminderTemplates();
                                setOpenModalSelectTemplate(true);
                              }}
                            />
                            <FileBtn
                              tooltip="PDF"
                              handleChange={(e) => {
                                printPDF(row.id);
                              }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={styles.tableBottom}>
            <RowsPerPage
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              setPage={setPage}
              tableLength={table?.length}
            />

            <div>
              <Pagination
                postsPerPage={rowsPerPage}
                totalPosts={search(table)?.length}
                paginate={paginate}
                page={page}
              />
            </div>
          </div>
        </Paper>

        <CiclicDialog
          {...props}
          reportType={"invoices"}
          openModalCiclic={openModalCiclic}
          setOpenModalCiclic={setOpenModalCiclic}
        />

        <Dialog
          open={openModalTemplate}
          TransitionComponent={Transition}
          onClose={() => {
            setOpenModalTemplate(false);
            setCurrentTemplate();
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          fullWidth={true}
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            className={stylesMod.dialogHead}
          >
            Definicja ponaglenia
            <IconButton
              aria-label="close"
              className={stylesMod.closeButton}
              onClick={() => {
                setOpenModalTemplate(false);
                setCurrentTemplate();
              }}
            >
              <X />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div className="modalLegend">
              <h2 style={{ fontSize: "22px" }}>Legenda zmiennych:</h2>
              <hr />
              <div style={{ lineHeight: "3" }}>
                <b>[dłużnik]</b> - imie i nazwisko dłużnika, <br />
                <b>[data]</b> - data wystawienia faktury, <br />
                <b>[numer_faktury]</b> - numer faktury, <br />
                <b>[usługa]</b> - przedmiot faltury, <br />
                <b>[kwota]</b> - kwota do spłaty, <br />
                <b>[waluta]</b> - waluta <br />
              </div>
            </div>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className={`${stylesMod.inp}`}>
                    <label>Nazwa szablonu ponaglenia</label>
                    <Input
                      value={currentTemplate ? currentTemplate.name : ""}
                      handleChange={(e) =>
                        setCurrentTemplate({
                          ...currentTemplate,
                          name: e.target.value,
                        })
                      }
                      name="template_name"
                      type="text"
                      placeholder="Wpisz nazwe szablonu"
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <ReactQuill
                    theme="snow"
                    value={currentTemplate ? currentTemplate.content : ""}
                    onChange={(e) => {
                      let temp = e;
                      // temp = temp.replaceAll(/<[^>]*>?/gm,'')
                      setCurrentTemplate({ ...currentTemplate, content: temp });
                    }}
                    modules={modules}
                  />
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={BTNstyles.btn}
              onClick={(e) => {
                setOpenModalTemplate(false);
                setCurrentTemplate();
              }}
            >
              Anuluj
            </Button>
            <Button
              variant="contained"
              size="large"
              className={`${BTNstyles.btn} ${BTNstyles.btnGreen}`}
              color="primary"
              onClick={(e) => {
                setOpenModalTemplate(false);
                ifEdit
                  ? saveTemplate(currentTemplate)
                  : addNewTemplate(currentTemplate);
              }}
            >
              Zapisz
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openModalTemplateList}
          TransitionComponent={Transition}
          onClose={() => {
            setOpenModalTemplateList(false);
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          fullWidth={true}
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            className={stylesMod.dialogHead}
          >
            Lista szablonów
            <IconButton
              aria-label="close"
              className={stylesMod.closeButton}
              onClick={() => {
                setOpenModalTemplateList(false);
              }}
            >
              <X />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  component={"div"}
                  style={{ padding: "0 8px" }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    className={`${BTNstyles.btnGreen}`}
                    style={{ fontSize: "10px", float: "right" }}
                    color="primary"
                    onClick={(e) => {
                      toggleDrawer("right", true);
                      setOpenModalTemplate(true);
                      setCurrentTemplate();
                      setIfEdit(false);
                    }}
                  >
                    Dodaj nowy szablon{" "}
                    <Add className={`${BTNstyles.downBtnIco}`} />
                  </Button>
                </Grid>
                <Grid item component={"div"} xs={12}>
                  <TableContainer>
                    <Table
                      className={styles.modalTable}
                      aria-labelledby="tableTitle"
                      size="small"
                    >
                      <TableHead className={styles.tabHead}>
                        <TableRow>
                          <TableCell>Nazwa szablonu</TableCell>
                          <TableCell>Treść</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {templateList !== undefined &&
                          templateList.map((o, i) => {
                            return (
                              <TableRow tabIndex={-1} key={i}>
                                <TableCell style={{ padding: "8px 6px" }}>
                                  {o.name}
                                </TableCell>
                                <TableCell style={{ padding: "8px 6px" }}>
                                  {o.content.substring(0, 30) + "..."}
                                </TableCell>
                                <TableCell
                                  style={{ padding: "8px 0px" }}
                                  align="right"
                                >
                                  <div className={styles.buttonBox}>
                                    <IcoBtn
                                      icon="Edit"
                                      tooltip="Zmień treść ponaglenia"
                                      value={o}
                                      onClick={(e) => {
                                        setIfEdit(true);
                                        setCurrentTemplate({
                                          id: o.id,
                                          name: o.name,
                                          content: o.content,
                                        });
                                        setOpenModalTemplate(true);
                                      }}
                                    />

                                    <IcoBtn
                                      icon="X"
                                      tooltip="Usuń"
                                      className="btn-delete"
                                      value={o}
                                      onClick={(e) => {
                                        delTemplate(o.id);
                                      }}
                                    />
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <Button variant="contained" size="large" color="primary" className={BTNstyles.btn} onClick={(e) => {
							setOpenModalTemplateList(false)
						}}>
							Anuluj
						</Button> */}
            <Button
              variant="contained"
              size="large"
              className={`${BTNstyles.btn}`}
              color="primary"
              onClick={(e) => {
                setOpenModalTemplateList(false);
              }}
            >
              Zamknij
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openModalSelectTemplate}
          TransitionComponent={Transition}
          onClose={() => {
            setOpenModalSelectTemplate(false);
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            className={stylesMod.dialogHead}
          >
            Wybierz szablon
            <IconButton
              aria-label="close"
              className={stylesMod.closeButton}
              onClick={() => {
                setOpenModalSelectTemplate(false);
              }}
            >
              <X />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div className={stylesMod.inp}>
                <label>Wybierz szablon</label>
                <Select
                  menuPortalTarget={document.body}
                  value={templateListOptions.find(
                    ({ value }) => value === String(selectedTemplate)
                  )}
                  className={`${stylesMod.select}`}
                  options={templateListOptions}
                  onChange={(e) => {
                    setSelectedTemplate(e);
                  }}
                  name="contractor"
                  placeholder="Wybierz szablon..."
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    control: (base, state) => ({
                      ...base,
                      border: "1px solid #142f42",
                      boxShadow: "none",
                      "&:hover": {
                        border: "1px solid #142f42",
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                    }),
                  }}
                />
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={BTNstyles.btn}
              onClick={(e) => {
                setOpenModalSelectTemplate(false);
              }}
            >
              Anuluj
            </Button>
            <Button
              variant="contained"
              size="large"
              className={`${BTNstyles.btn} ${BTNstyles.btnGreen}`}
              color="primary"
              onClick={(e) => {
                setOpenModalSelectTemplate(false);

                if (selectedTemplate && selectedInvoiceRow) {
                  let valToMAil = tempToMail(
                    selectedTemplate,
                    selectedInvoiceRow.contractor.name,
                    selectedInvoiceRow.payment_deadline,
                    selectedInvoiceRow.invoice_no,
                    selectedInvoiceRow.services,
                    selectedInvoiceRow.value_brutto,
                    selectedInvoiceRow.currency
                  );
                  let location = '<a href="#">Pobierz fakture</a>';
                  invoice_Mail_subtitle = selectedInvoiceRow.invoice_no
                    ? "Ponaglenie za fakturę o numerze: " +
                      selectedInvoiceRow.invoice_no
                    : "Ponaglenie";

                  let link = `mailto:${invoice_Mail_adress}?&subject=${encodeURIComponent(
                    invoice_Mail_subtitle
                  )}&attachedfile="https://dream.ai/logo.svg"&body=${encodeURIComponent(
                    valToMAil
                  )}`;

                  window.open(link);
                }

                // selectedTemplate
              }}
            >
              Wyślij
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={dialogColor}>
          {dialogContent}
        </Alert>
      </Snackbar>
    </>
  );
}
