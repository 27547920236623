import React, { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  DialogActions,
  Button,
  DialogContentText,
} from "@material-ui/core";
import Select from "react-select";
import Input from "./input";
import { DBurl } from "../appConfig";
import { X, ArrowRight } from "react-feather";
import BTNstyles from "../styles/button.module.css";
import stylesMod from "../styles/newOrEdit.module.css";
import IconButton from "@material-ui/core/IconButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function AddServices(props) {
  const [name, setName] = useState(props.data?.name || "");
  const [price_netto, setPrice_netto] = useState(props.data?.price_netto || "");
  const [quantity, setQuantity] = useState(props.data?.quantity || "");
  const [value_netto, setValue_netto] = useState(props.data?.value_netto || "");
  const [vat, setVat] = useState(props.data?.vat || "");
  const [value_brutto, setValue_brutto] = useState(
    props.data?.value_brutto || ""
  );

  useEffect(() => {
    if (props.data?.id) {
      setName(props.data?.name);
      setPrice_netto(props.data?.price_netto);
      setQuantity(props.data?.quantity);
      setValue_netto(props.data?.value_netto);
      setVat({ label: props.data?.vat, value: props.data?.vat });
      setValue_brutto(props.data?.value_brutto);
    } else {
      setName("");
      setPrice_netto("");
      setQuantity("");
      setValue_netto("");
      setVat("");
      setValue_brutto("");
    }
  }, [props.data]);

  const setServices = async (type) => {
    let data =
      type === "insert"
        ? {
            name: name,
            price_netto: price_netto,
            quantity: quantity,
            value_netto: value_netto,
            vat: vat.value,
            value_brutto: value_brutto,
          }
        : {
            name: name,
            price_netto: price_netto,
            quantity: quantity,
            value_netto: value_netto,
            vat: vat.value,
            value_brutto: value_brutto,
            id: props.data?.id,
          };

    const response = await fetch(`${DBurl}/setServices/`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.token,
      },
      body: JSON.stringify({
        mode: type,
        data: data,
      }),
    });
    const json = await response.json();
    if (json.success) {
      props.refresh();

      props.setSnack(true);
      props.setSnackContent("Usługa zapisana");
      props.setSnackColor("success");
      props.setOpen(false);
    } else {
      props.setSnack(true);
      props.setSnackContent("Błąd przy tworzeniu/edycji usługi VAT");
      props.setSnackColor("error");
    }
  };

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      onClose={() => {
        props.setOpen(false);
      }}
      maxWidth="lg"
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        className={stylesMod.dialogHead}
      >
        Wpisz dane usługi:{" "}
        <IconButton
          aria-label="close"
          className={stylesMod.closeButton}
          onClick={() => {
            props.setOpen(false);
          }}
        >
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            {/* <div className={stylesMod.inp}> */}
            <label>Nazwa</label>
            <Input
              value={name}
              handleChange={(e) => {
                setName(e.target.value);
              }}
              name="name"
              type="text"
            />
            {/* </div> */}
          </Grid>

          <Grid item xs={2}>
            <label>Cena netto</label>
            <Input
              value={price_netto}
              handleChange={(e) => {
                setPrice_netto(Number(e.target.value));
                setValue_netto(Number(e.target.value) * Number(quantity));
                setValue_brutto(
                  Number(e.target.value) *
                    Number(quantity) *
                    (Number(vat.value) * 0.01 + 1)
                );
              }}
              name="price_netto"
              type="number"
            />
          </Grid>
          <Grid item xs={1}>
            <label>Ilość</label>
            <Input
              value={quantity}
              handleChange={(e) => {
                setQuantity(Number(e.target.value));
                setValue_netto(e.target.value * price_netto);
                setValue_brutto(
                  e.target.value *
                    price_netto *
                    (Number(vat.value) * 0.01 + 1).toFixed(2)
                );
              }}
              name="quantity"
              type="number"
            />
          </Grid>
          <Grid item xs={2}>
            <label>Wartość netto</label>
            <Input
              value={value_netto}
              name="value_netto"
              type="number"
              disabled
            />
          </Grid>
          <Grid item xs={2}>
            <label>VAT</label>
            <Select
              value={vat}
              className={stylesMod.select}
              options={props.vatOptions}
              onChange={(e) => {
                setVat(e);
                setValue_brutto(
                  value_netto * (Number(e?.value) * 0.01 + 1).toFixed(2)
                );
              }}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 2000 }) }}
              name="vat"
              placeholder="Wybierz..."
              style={{
                control: (base, state) => ({
                  ...base,
                  border: "1px solid #142f42",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #142f42",
                  },
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused && "#142f42",
                  color: state.isFocused && "#fff",
                }),
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <label>Wartość brutto</label>
            <Input
              value={value_brutto}
              name="value_brutto"
              type="text"
              disabled
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="large"
          disabled={!name}
          onClick={(e) => setServices(props.type)}
        >
          Zapisz <ArrowRight className={`${BTNstyles.downBtnIco}`} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function RemoveServices(props) {
  const deleteFunc = async (id) => {
    const response = await fetch(`${DBurl}/setServices/`, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.token,
      },
      body: JSON.stringify({
        mode: "delete",
        data: {
          ids: [id],
        },
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          props.setSnack(true);
          props.setSnackContent("usługa usunięta");
          props.setSnackColor("success");
          props.refresh();
          props.setOpen(false);
        } else {
          props.setSnack(true);
          props.setSnackContent("Błąd podczas usuwania");
          props.setSnackColor("error");
        }
      });
  };
  return (
    <>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        onClose={() => {
          props.setOpen(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Czy napewno chcesz usunąć usługę
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              props.setOpen(false);
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Czy napewno chcesz usunąć usługę <br />
            <p>{props.data?.name} ?</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={BTNstyles.btn}
            onClick={(e) => {
              props.setOpen(false);
            }}
          >
            Anuluj
          </Button>
          <Button
            variant="contained"
            size="large"
            className={`${BTNstyles.btn} ${BTNstyles.err}`}
            onClick={(e) => {
              deleteFunc(props.data?.id);
              props.setOpen(false);
            }}
            color="primary"
          >
            Usuń <X className={BTNstyles.downBtnIco} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
