import React, { FC, ReactNode } from "react";
import {
    Button, IconButton, Slide, Dialog,
    DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core'
import { X } from 'react-feather';
import Input from 'components/input'
import stylesMod from 'styles/newOrEdit.module.css'
import BTNstyles from 'styles/button.module.css'
import { MODALS_NAMES_TYPE, headCells } from "..";

export type AddFleetCostsGenericModalPropsType = {
    open: boolean;
    children: ReactNode;
    handleClose: () => void;
    handleConfirm: () => void;
    modalID?: MODALS_NAMES_TYPE;
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddFleetCostsGenericModal: FC<AddFleetCostsGenericModalPropsType> = ({ open, children, handleClose, modalID, handleConfirm }) => {

    const title = headCells.find(item => item.id === modalID)?.title

    return (
        <Dialog
            maxWidth='md'
            fullWidth
            open={open}
            // @ts-ignore
            TransitionComponent={Transition}
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >

            <DialogTitle id="alert-dialog-slide-title" className={stylesMod.dialogHead}>{title}
                <IconButton aria-label="close" className={stylesMod.closeButton} onClick={handleClose}>
                    <X />
                </IconButton>
            </DialogTitle >
            <DialogContent>
                <div className="add-costs-container">
                    {children}
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" size="large" color="primary" className={BTNstyles.btn} onClick={handleClose}>
                    Anuluj
                </Button>
                <Button variant="contained" size="large" className={`${BTNstyles.btn} ${BTNstyles.grn}`} onClick={() => {
                    handleConfirm()
                }}
                    color="primary">
                    Dodaj
                </Button>
            </DialogActions>

        </Dialog>

    )
}

export default AddFleetCostsGenericModal;