import React, { useState, useEffect, forwardRef, useRef } from "react";
import Title from "components/title";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import plLocale from "date-fns/locale/pl";
import moment from "moment";
import { Calendar, X, FileText } from "react-feather";
import styles from "styles/invoice.module.css";
import BTNstyles from "styles/button.module.css";
import stylesMod from "styles/newOrEdit.module.css";
import InvoiceInput from "./invoiceAddInputField";
import {
  TableContainer,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import ServicesTable from "./invoiceAddNewServicesTable";
import SummaryTable from "./summaryTable";
import { useNewInvoice } from "./useNewInvoice.hook";
import { useEditInvoice } from "./useEditInvoice.hook";
import {
  getNewInvoiceNumber,
  getInvNumberFromProps,
} from "./AddInvoice.helpers";
import { DBurl } from "../../../appConfig";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import ClipLoader from "react-spinners/ClipLoader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const override = {
  width: "20px",
  height: "20px",
  zIndex: "99999",
};

const formatDate = (date = new Date()) => {
  var day = date.getDate();
  var month = date.getMonth() + 1;
  var year = date.getFullYear();
  const result = `${year}-${month}-${day}`;
  return result;
};

export default function InvoiceAddNew(props) {
  const [openSnack, setOpenSnack] = useState(false);
  const [snackContent, setSnackContent] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);
  const [dialogColor, setDialogColor] = useState("error");

  const [invoiceNum, setInvoiceNum] = useState(props.state.invoiceNum || null);
  const [issuePlace, setIssuePlace] = useState(props.state.issuePlace || null);
  const [issueDate, setIssueDate] = useState(new Date());
  const [saleServiceDate, setSaleServiceDate] = useState(new Date());
  const [documentType, setDocumentType] = useState(
    props.state.documentType || null
  );
  const [seller, setSeller] = useState(
    props.state.seller || [{ label: "", value: null }]
  );
  const [companyName, setCompanyName] = useState(
    props.state.companyName || null
  );
  const [taxNum, setTaxNum] = useState(props.state.taxNum || null);
  const [street, setStreet] = useState(props.state.street || null);
  const [city, setCity] = useState(props.state.city || null);
  const [country, setCountry] = useState(props.state.country || null);
  const [email, setEmail] = useState(props.state.email || null);
  const [phoneNum, setPhoneNum] = useState(props.state.phoneNum || null);
  const [website, setWebsite] = useState(props.state.website || null);
  const [krs, setKrs] = useState(props.state.krs || null);
  const [bankAccName, setBankAccName] = useState(
    props.state.bankAccName || null
  );
  const [bank, setBank] = useState(props.state.bank || null);
  const [accNum, setAccNum] = useState(props.state.accNum || null);
  const [taxAccNum, setTaxAccNum] = useState(props.state.taxAccNum || null);
  const [swiftAccNum, setSwiftAccNum] = useState(
    props.state.swiftAccNum || null
  );
  const [accCurrency, setAccCurrency] = useState(
    props.state.accCurrency || null
  );
  const [bankAccName2, setBankAccName2] = useState(
    props.state.bankAccName2 || null
  );
  const [bank2, setBank2] = useState(props.state.bank2 || null);
  const [accNum2, setAccNum2] = useState(props.state.accNum2 || null);
  const [taxAccNum2, setTaxAccNum2] = useState(props.state.taxAccNum2 || null);
  const [swiftAccNum2, setSwiftAccNum2] = useState(
    props.state.swiftAccNum2 || null
  );
  const [acc2Currency, setAcc2Currency] = useState(
    props.state.acc2Currency || null
  );
  const [bankAccName3, setBankAccName3] = useState(
    props.state.bankAccName3 || null
  );
  const [bank3, setBank3] = useState(props.state.bank3 || null);
  const [accNum3, setAccNum3] = useState(props.state.accNum3 || null);
  const [taxAccNum3, setTaxAccNum3] = useState(props.state.taxAccNum3 || null);
  const [swiftAccNum3, setSwiftAccNum3] = useState(
    props.state.swiftAccNum3 || null
  );
  const [acc3Currency, setAcc3Currency] = useState(
    props.state.acc3Currency || null
  );
  const [eori, setEori] = useState(props.state.eori || null);
  const [dbo, setDbo] = useState(props.state.dbo || null);

  const [buyer, setBuyer] = useState(
    props.state.buyer || { label: "", value: null }
  );
  const [buyerCompanyName, setBuyerCompanyName] = useState(
    props.state.buyerCompanyName || null
  );
  const [buyerTaxNum, setBuyerTaxNum] = useState(
    props.state.buyerTaxNum || null
  );
  const [buyerStreet, setBuyerStreet] = useState(
    props.state.buyerStreet || null
  );
  const [buyerCity, setBuyerCity] = useState(props.state.buyerCity || null);
  const [buyerCountry, setBuyerCountry] = useState(
    props.state.buyerCountry || null
  );
  const [buyerEmail, setBuyerEmail] = useState(props.state.buyerEmail || null);
  const [buyerPhoneNum, setBuyerPhoneNum] = useState(
    props.state.buyerPhoneNum || null
  );
  const [buyerWebsite, setBuyerWebsite] = useState(
    props.state.buyerWebsite || null
  );
  const [buyerKrs, setBuyerKrs] = useState(props.state.buyerKrs || null);
  const [buyerBankAccName, setBuyerBankAccName] = useState(
    props.state.buyerBankAccName || null
  );
  const [buyerBank, setBuyerBank] = useState(props.state.buyerBank || null);
  const [buyerAccNum, setBuyerAccNum] = useState(
    props.state.buyerAccNum || null
  );
  const [buyerTaxAccNum, setBuyerTaxAccNum] = useState(
    props.state.buyerTaxAccNum || null
  );
  const [buyerSwiftAccNum, setBuyerSwiftAccNum] = useState(
    props.state.buyerSwiftAccNum || null
  );
  const [buyerAccountCurrency, setBuyerAccountCurreny] = useState();
  const [buyerSecondAccount, setBuyerSecondAccount] = useState(false);
  const [buyerBankAccName2, setBuyerBankAccName2] = useState(
    props.state.buyerBankAccName2 || null
  );
  const [buyerBank2, setBuyerBank2] = useState(props.state.buyerBank2 || null);
  const [buyerAccNum2, setBuyerAccNum2] = useState(
    props.state.buyerAccNum2 || null
  );
  const [buyerTaxAccNum2, setBuyerTaxAccNum2] = useState(
    props.state.buyerTaxAccNum2 || null
  );
  const [buyerSwiftAccNum2, setBuyerSwiftAccNum2] = useState(
    props.state.buyerSwiftAccNum2 || null
  );
  const [buyerAccountCurrency2, setBuyerAccountCurreny2] = useState();
  const [buyerAccountOptions, setBuyerAccountOptions] = useState([]);
  const [buyerEori, setBuyerEori] = useState(props.state.buyerEori || null);
  const [buyerDbo, setBuyerDbo] = useState(props.state.buyerDbo || null);

  const [personToIssue, setPersonToIssue] = useState(
    `${props?.user?.first_name} ${props?.user?.last_name}`
  );
  const [personToPickUp, setPersonToPickUp] = useState(
    props.state.personToPickUp || null
  );
  const [stopAuto, setStopAuto] = useState(props.state.stopAuto || false);
  const [selectedCurrency, setSelectedCurrency] = useState(
    props.state.selectedCurrency || null
  );
  const [vat, setVat] = useState(props.state.vat || []);
  const [services, setServices] = useState(props.state.services || []);
  const [paymentDeadline, setPaymentDeadline] = useState(
    props.state.paymentDeadline || { value: 30, label: "30 dni" }
  );
  const [paymentDeadlineDay, setPaymentDeadlineDay] = useState(
    props.state.paymentDeadlineDay || null
  );
  const [description, setDescription] = useState(
    props.state.description || null
  );
  const [advancePayment, setAdvancePayment] = useState(
    props.state.advancePayment || 0
  );
  const [totalGross, setTotalGross] = useState(props.state.totalGross || 0);
  const [paymentOption, setPaymentOption] = useState(
    props.state.paymentOption || { value: "Przelew", label: "Przelew" }
  );
  const [secondAccount, setSecondAccount] = useState(
    props.state.secondAccount || false
  );
  const [thirdAccount, setThirdAccount] = useState(
    props.state.thirdAccount || false
  );
  const [numberFromProps, setNumberFromProps] = useState(
    props.state.numberFromProps || null
  );
  const [linkedInvNum, setLinkedInvNum] = useState(
    props.state.linkedInvNum || null
  );
  const [debitNoteNum, setDebitNoteNum] = useState(
    props.state.debitNoteNum || null
  );
  const [creditNoteNum, setCreditNoteNum] = useState(
    props.state.creditNoteNum || null
  );
  const [noteInvId, setNoteInvId] = useState(props.state.noteInvId || []);
  const [correctionTitle, setCorrectionTitle] = useState(
    props.state.correctionTitle || null
  );

  const isCorrection = useRef(props?.state?.typeInput?.value === 3);
  const [invFormat, setInvFormat] = useState(props.state.invFormat || null);
  const [special, setSpecial] = useState(props.state.special || null);
  const [prot, setProt] = useState(props.state.prot || false);
  const [printData, setPrintData] = useState(props.state.printData || true);

  const [openDialogSummary, setOpenDialogSummary] = useState(false);
  const [new_id, setNew_id] = useState();
  const [error, setError] = useState(false);

  const [receiver, setReceiver] = useState(props.state.receiver || false);

  const [invReceiver, setInvReceiver] = useState(
    props.state.invReceiver || { label: "", value: null }
  );
  const [receiverCompanyName, setReceiverCompanyName] = useState(
    props.state.receiverCompanyName || null
  );
  const [receiverTaxNum, setReceiverTaxNum] = useState(
    props.state.receiverTaxNum || null
  );
  const [receiverStreet, setReceiverStreet] = useState(
    props.state.receiverStreet || null
  );
  const [receiverCity, setReceiverCity] = useState(
    props.state.receiverCity || null
  );
  const [receiverCountry, setReceiverCountry] = useState(
    props.state.receiverCountry || null
  );

  const [exchangeRateDate, setExchangeRateDate] = useState(new Date());

  ///tagi
  const [selected_tags, setSelected_tags] = useState(props.state.tags || []);

  const handleTagsChange = (e) => {
    setSelected_tags(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  var selected_tags_duble = [];
  selected_tags.forEach(function (item) {
    if (selected_tags_duble.indexOf(item) < 0) {
      selected_tags_duble.push(item);
    }
  });

  const colorContrast = (rgb) => {
    rgb = rgb.split(/\(([^)]+)\)/)[1].replace(/ /g, "");
    var r = parseInt(rgb.split(",")[0], 10),
      g = parseInt(rgb.split(",")[1], 10),
      b = parseInt(rgb.split(",")[2], 10),
      a;

    if (rgb.split(",")[3] !== null) {
      a = parseInt(rgb.split(",")[3], 10);
    }

    var contrast =
      (Math.round(r * 299) + Math.round(g * 587) + Math.round(b * 114)) / 1000;
    return contrast >= 170 ? "black" : "white";
  };

  const removeTag = (i, e) => {
    const newTempTags = selected_tags.filter((o) => {
      return o !== e;
    });
    setSelected_tags(() => newTempTags);
  };

  const checkServicesError = () => {
    if (documentType?.value === 1) {
      let result = false;
      services.forEach((s) => {
        if (
          !s.name ||
          !s.id_order ||
          !s.price_netto ||
          !s.quantity ||
          (s.vat !== 0 && !s.vat)
        ) {
          result = true;
        }
      });
      if (services?.length === 0) {
        result = true;
      }
      return result;
    }
    return false;
  };

  const checkError = () => {
    if (
      !buyerCompanyName ||
      !buyerTaxNum ||
      !buyerStreet ||
      !buyerCity ||
      !buyerCountry ||
      !invoiceNum ||
      documentType?.length === 0 ||
      services?.length === 0 ||
      !companyName ||
      !taxNum ||
      !street ||
      !city ||
      !country ||
      !accNum ||
      !buyer.value ||
      !seller.value
    ) {
      return true;
    }
    if (
      (documentType?.value === 4 && !linkedInvNum) ||
      (documentType?.value === 5 && !linkedInvNum)
    )
      return true;
    if (isCorrection.current && !correctionTitle) return true;
    else return false;
  };

  useEffect(() => {
    checkError();
    checkServicesError();
  }, [
    buyerCompanyName,
    buyerTaxNum,
    buyerStreet,
    buyerCity,
    buyerCountry,
    invoiceNum,
    documentType,
    services,
    companyName,
    taxNum,
    street,
    city,
    country,
    accNum,
  ]);

  const {
    invoices,
    departments,
    accounts,
    clients,
    orders,
    exchangeRate,
    servicesOptions,
    invoiceOK,
    clientDepartments,
    sufix,
    tags_list,
    buyerOptions,
  } = useNewInvoice(
    props.user,
    seller?.value,
    buyer,
    selectedCurrency,
    invoiceNum,
    debitNoteNum,
    creditNoteNum,
    documentType,
    linkedInvNum,
    isCorrection.current,
    invFormat,
    special,
    saleServiceDate,
    exchangeRateDate
  );
  const selectedBuyerDepartment = clientDepartments?.find(
    (dep) => dep.id_parent === buyer?.id_contractor
  );

  const deadlineOptions = [
    { value: 1, label: "1 dzień" },
    { value: 3, label: "3 dni" },
    { value: 7, label: "7 dni" },
    { value: 14, label: "14 dni" },
    { value: 30, label: "30 dni" },
    { value: 45, label: "45 dni" },
    { value: 60, label: "60 dni" },
    { value: 90, label: "90 dni" },
  ];

  const defaultDepartment = departments.find((dep) => dep.default === true);

  const handleDefaultDep = () => {
    if (defaultDepartment !== undefined && !invoiceIdFromProps) {
      setSeller(defaultDepartment);
      setCompanyName(defaultDepartment.name);
      setStreet(
        `${defaultDepartment.street ? defaultDepartment.street : ""} ${
          defaultDepartment.street_no ? defaultDepartment.street_no : ""
        } ${
          defaultDepartment.apartment_no !== " " &&
          defaultDepartment.apartment_no
            ? "/"
            : ""
        } ${
          defaultDepartment.apartment_no ? defaultDepartment.apartment_no : ""
        }`
      );
      setTaxNum(defaultDepartment.nip);
      setCountry(defaultDepartment.country);
      setCity(`${defaultDepartment.zipcode} ${defaultDepartment.city}`);
      setIssuePlace(defaultDepartment.issuePlace);
      setInvFormat(defaultDepartment.invFormat);
      setSpecial(defaultDepartment.special);
    }
    return;
  };

  const handleDeadline = () => {
    if (selectedBuyerDepartment !== undefined) {
      setPaymentDeadline(
        deadlineOptions.find(
          (option) =>
            option.value ===
            parseInt(selectedBuyerDepartment.default_payment_deadline)
        )
      );
    }
  };

  useEffect(() => {
    handleDeadline();
  }, [selectedBuyerDepartment]);

  useEffect(() => {
    handleDefaultDep();
  }, [defaultDepartment]);

  const handleValueChange = () => {
    if (documentType?.value === 4) {
      setDebitNoteNum(linkedInvNum);
      setCreditNoteNum("");
    }
    if (documentType?.value === 5) {
      setCreditNoteNum(linkedInvNum);
      setDebitNoteNum("");
    }
    if (documentType?.value === 1) {
      setCreditNoteNum("");
      setDebitNoteNum("");
    }
  };

  useEffect(() => {
    handleValueChange();
  }, [linkedInvNum, documentType]);

  const handleAddSecondAccount = () => {
    setSecondAccount(true);
  };

  const handleDeleteSecondAccount = () => {
    setSecondAccount(false);
    setBankAccName2("");
    setBank2("");
    setAccNum2("");
    setTaxAccNum2("");
    setSwiftAccNum2("");
    setAcc2Currency("");
  };

  const handleAddThirdAccount = () => {
    setThirdAccount(true);
  };

  const handleDeleteThirdAccount = () => {
    setThirdAccount(false);
    setBankAccName3("");
    setBank3("");
    setAccNum3("");
    setTaxAccNum3("");
    setSwiftAccNum3("");
    setAcc3Currency("");
  };

  const handleAddSecondBuyerAccount = () => {
    setBuyerSecondAccount(true);
  };

  const handleDeleteSecondBuyerAccount = () => {
    setBuyerSecondAccount(false);
    setBuyerBankAccName2("");
    setBuyerBank2("");
    setBuyerAccNum2("");
    setBuyerTaxAccNum2("");
    setBuyerSwiftAccNum2("");
  };

  const handleNewTotalGross = (newValue) => setTotalGross(newValue);

  const handleChangeServices = (id, name, value) => {
    const index = services.findIndex((serv) => serv.id === id);
    if (name === "removeAllRow") {
      setServices((prevServices) =>
        prevServices.filter((srv) => srv.id !== id)
      );
      return;
    }
    if (index === -1) {
      const newEntry = { [name]: value };
      setServices((prevServices) => [...prevServices, { id, ...newEntry }]);
    }
    if (index !== -1) {
      const newServices = [...services];
      newServices[index][name] = value;
      setServices([...newServices]);
    }
  };

  const handleNewServiceWithAllData = (data) => {
    setServices((prev) => [...prev, { ...data }]);
  };

  const handleChangeVat = (newVat, action) => {
    if (action === "add") {
      setVat((prevVat) => [...prevVat, newVat]);
    }
    if (action === "remove") {
      setVat((prevVat) => {
        const index = prevVat.lastIndexOf(newVat);
        if (index === -1) return prevVat;
        return prevVat.filter((_singleVat, i) => i !== index);
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDialog(false);
  };

  const handleChangeCurrency = (newCurrency) =>
    setSelectedCurrency(newCurrency);

  const typeOptions = [
    { value: 1, label: "Faktura VAT", kind: null },
    { value: 1, label: "Faktura VAT EU", kind: 1 },
    {
      value: 1,
      label: "Faktura VAT (mechanizm podzielonej płatności)",
      kind: 2,
    },
    { value: 4, label: "Nota obciążeniowa", kind: null },
    { value: 5, label: "Nota uznaniowa", kind: null },
  ];

  const paymentOptions = [
    { value: "Przelew", label: "Przelew" },
    { value: "Ukryj na wydruku", label: "Ukryj na fakturze" },
    { value: "Zgodnie z wpłatą", label: "Zgodnie z umową" },
    { value: "Przelew dwuwalutowy", label: "Przelew dwuwalutowy" },
  ];

  const allSetStates = {
    setInvoiceNum,
    setIssuePlace,
    setIssueDate,
    setSaleServiceDate,
    setDocumentType,
    setSeller,
    setCompanyName,
    setTaxNum,
    setStreet,
    setCity,
    setCountry,
    setBankAccName,
    setBank,
    setAccNum,
    setTaxAccNum,
    setSwiftAccNum,
    setEori,
    setDbo,
    setBuyer,
    setBuyerCompanyName,
    setBuyerTaxNum,
    setBuyerStreet,
    setBuyerCity,
    setBuyerCountry,
    setBuyerEori,
    setBuyerDbo,
    setPersonToIssue,
    setPersonToPickUp,
    setSelectedCurrency,
    setServices,
    setPaymentDeadline,
    setPaymentDeadlineDay,
    setDescription,
    setAdvancePayment,
    setTotalGross,
    setPaymentOption,
    setBankAccName3,
    setBank3,
    setAccNum3,
    setTaxAccNum3,
    setSwiftAccNum3,
    setBankAccName2,
    setBank2,
    setAccNum2,
    setTaxAccNum2,
    setSwiftAccNum2,
    setSecondAccount,
    setThirdAccount,
    setNumberFromProps,
    setLinkedInvNum,
    setAccCurrency,
    setAcc2Currency,
    setAcc3Currency,
    setReceiver,
    setInvReceiver,
    setReceiverCompanyName,
    setReceiverTaxNum,
    setReceiverStreet,
    setReceiverCity,
    setReceiverCountry,
    setExchangeRateDate,
    setSelected_tags,
    setBuyerBankAccName,
    setBuyerBank,
    setBuyerAccNum,
    setBuyerTaxAccNum,
    setBuyerSwiftAccNum,
    setBuyerAccountCurreny,
    setBuyerSecondAccount,
    setBuyerBankAccName2,
    setBuyerBank2,
    setBuyerAccNum2,
    setBuyerTaxAccNum2,
    setBuyerSwiftAccNum2,
    setBuyerAccountCurreny2,
  };
  const invoiceIdFromProps = getInvNumberFromProps(props);

  useEditInvoice(
    invoiceIdFromProps,
    props.user.csrf_token,
    allSetStates,
    departments,
    buyerOptions,
    typeOptions,
    isCorrection.current,
    exchangeRate,
    tags_list,
    selected_tags
  );

  useEffect(() => {
    setPaymentDeadlineDay(addDays(issueDate, 30));
  }, []);

  const select_styles = {
    control: (base, state) => ({
      ...base,
      border: "1px solid #142f42",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #142f42",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused && "#142f42",
      color: state.isFocused && "#fff",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 10 }),
  };

  const clear = () => {
    setInvoiceNum(null);
    setIssuePlace(null);
    setIssueDate(new Date());
    setSaleServiceDate(new Date());
    setDocumentType(null);
    setSeller([{ label: null, value: null }]);
    setCompanyName(null);
    setTaxNum(null);
    setStreet(null);
    setCity(null);
    setCountry(null);
    setEmail(null);
    setPhoneNum(null);
    setWebsite(null);
    setKrs(null);
    setBankAccName(null);
    setBank(null);
    setAccNum(null);
    setTaxAccNum(null);
    setSwiftAccNum(null);
    setEori(null);
    setDbo(null);
    setBuyer([{ label: "", value: null }]);
    setBuyerCompanyName(null);
    setBuyerTaxNum(null);
    setBuyerStreet(null);
    setBuyerCity(null);
    setBuyerCountry(null);
    setBuyerEmail(null);
    setBuyerPhoneNum(null);
    setBuyerWebsite(null);
    setBuyerKrs(null);
    setBuyerBankAccName(null);
    setBuyerBank(null);
    setBuyerAccNum(null);
    setBuyerTaxAccNum(null);
    setBuyerSwiftAccNum(null);
    setBuyerEori(null);
    setBuyerDbo(null);
    setPersonToIssue(null);
    setPersonToPickUp(null);
    setServices([]);
    setPaymentDeadline({ value: 30, label: "30 dni" });
    setDescription(null);
    setAdvancePayment(0);
    setPaymentOption(null);
    setSecondAccount(false);
    setThirdAccount(false);
    setBankAccName2(null);
    setBank2(null);
    setAccNum2(null);
    setTaxAccNum2(null);
    setSwiftAccNum2(null);
    setBankAccName3(null);
    setBank3(null);
    setAccNum3(null);
    setTaxAccNum3(null);
    setSwiftAccNum3(null);
    setLinkedInvNum(null);
    setDebitNoteNum(null);
    setCreditNoteNum(null);
    setCorrectionTitle(null);
    setAccCurrency(null);
    setAcc2Currency(null);
    setAcc3Currency(null);
    setNew_id(null);
    setSelected_tags([]);
    setBuyerBankAccName(null);
    setBuyerBank(null);
    setBuyerAccNum(null);
    setBuyerTaxAccNum(null);
    setBuyerSwiftAccNum(null);
    setBuyerAccountCurreny(null);
    setBuyerSecondAccount(false);
    setBuyerBankAccName2(null);
    setBuyerBank2(null);
    setBuyerAccNum2(null);
    setBuyerTaxAccNum2(null);
    setBuyerSwiftAccNum2(null);
    setBuyerAccountCurreny2(null);
    setServices([]);
  };

  const handlePostError = (text) => {
    setDialogColor("error");
    setOpenDialog(true);
    setDialogContent(text);
  };

  const checkIsPostOK = () => {
    if (!numberFromProps && invoiceOK?.available_name) {
      return true;
    }
    if (numberFromProps && invoiceNum && numberFromProps === invoiceNum) {
      return true;
    }
    if (
      numberFromProps &&
      linkedInvNum &&
      numberFromProps === linkedInvNum &&
      !isCorrection.current
    ) {
      return true;
    }
    return invoiceOK?.available_name;
  };

  const handleOrders = () => {
    if (documentType?.value === 1) return services.map((serv) => serv.id_order);
    if (documentType?.value === undefined)
      return services.map((serv) => serv.id_order);
    if (documentType?.value === 4) return noteInvId;
    if (documentType?.value === 5) return noteInvId;
  };
  const handleValueBrutto = () => {
    if (documentType?.value === 1) return totalGross.toFixed(2);
    if (documentType?.value === undefined) return totalGross.toFixed(2);
    if (documentType?.value === 4)
      return services
        .map((serv) => parseFloat(serv.value_brutto))
        .reduce((a, b) => a + b, 0);
    if (documentType?.value === 5)
      return services
        .map((serv) => parseFloat(serv.value_brutto))
        .reduce((a, b) => a + b, 0);
  };
  const checkDebit = () => {
    if (documentType?.value === 1) return null;
    if (documentType?.value === 4) return invoiceNum;
    if (documentType?.value === undefined) return null;
  };
  const checkCredit = () => {
    if (documentType?.value === 1) return null;
    if (documentType?.value === 5) return invoiceNum;
    if (documentType?.value === undefined) return null;
  };
  const handleInvNum = () => {
    if (documentType?.value === 1) return invoiceNum;
    if (documentType?.value === undefined) return invoiceNum;
    if (documentType?.value === 4) return debitNoteNum;
    if (documentType?.value === 5) return creditNoteNum;
  };
  const handleMode = () => {
    if (isCorrection.current) return "insert";
    if (!isCorrection.current && !invoiceIdFromProps) return "insert";
    return "update";
  };

  const handleServices = () => {
    services.map((serv) => (serv.id_service_reference = serv.id));
    return [...services];
  };

  const removeNaN = (data) => {
    for (let i = 0; i < data?.length; i++) {
      if (typeof data[i].value_brutto === "string") {
        data[i].value_brutto = data[i].value_brutto.replace("NaN", "");
      } else if (
        typeof data[i].value_brutto === "number" &&
        isNaN(data[i].value_brutto)
      ) {
        data[i].value_brutto = 0;
      }
    }
    return data;
  };

  const fetchClientDepartmentsBankAccounts = async (id) => {
    props.loading(true);
    const response = await fetch(`${DBurl}/getDepartmentsBankAccounts`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();

    if (json.success) {
      const newAccounts = json.data
        .filter((acc) => acc.id_department === id)
        .map((acc) => {
          return {
            label: acc?.account_name,
            value: acc?.id,
            acc_no: acc?.bank_account_no,
            bank_name: acc?.bank_name,
            vat_no: acc?.bank_vat_account_no,
            swift_no: acc.swift_bic_no,
            acc_name: acc?.account_name,
            currency: acc?.currency,
          };
        });
      setBuyerAccountOptions([...newAccounts]);
      if (
        json.data
          .filter((acc) => acc.id_department === id)
          .filter((acc) => acc.is_default)[0]
      ) {
        setBankAccName({
          label: json.data
            .filter((acc) => acc.id_department === id)
            .filter((acc) => acc.is_default)[0].account_name,
          value: json.data
            .filter((acc) => acc.id_department === id)
            .filter((acc) => acc.is_default)[0].id,
        });
        setBank(
          json.data
            .filter((acc) => acc.id_department === id)
            .filter((acc) => acc.is_default)[0].bank_name
        );
        setAccNum(
          json.data
            .filter((acc) => acc.id_department === id)
            .filter((acc) => acc.is_default)[0].bank_account_no
        );
        setTaxAccNum(
          json.data
            .filter((acc) => acc.id_department === id)
            .filter((acc) => acc.is_default)[0].bank_vat_account_no
        );
        setSwiftAccNum(
          json.data
            .filter((acc) => acc.id_department === id)
            .filter((acc) => acc.is_default)[0].swift_bic_no
        );
      }
      if (
        json.data
          .filter((acc) => acc.id_department === id)
          .filter((acc) => acc.is_default)[1]
      ) {
        setSecondAccount(true);
        setBankAccName2({
          label: json.data
            .filter((acc) => acc.id_department === id)
            .filter((acc) => acc.is_default)[1].account_name,
          value: json.data
            .filter((acc) => acc.id_department === id)
            .filter((acc) => acc.is_default)[1].id,
        });
        setBank2(
          json.data
            .filter((acc) => acc.id_department === id)
            .filter((acc) => acc.is_default)[1].bank_name
        );
        setAccNum2(
          json.data
            .filter((acc) => acc.id_department === id)
            .filter((acc) => acc.is_default)[1].bank_account_no
        );
        setTaxAccNum2(
          json.data
            .filter((acc) => acc.id_department === id)
            .filter((acc) => acc.is_default)[1].bank_vat_account_no
        );
        setSwiftAccNum2(
          json.data
            .filter((acc) => acc.id_department === id)
            .filter((acc) => acc.is_default)[1].swift_bic_no
        );
      }
      props.loading(false);
    }
  };

  useEffect(() => {
    buyer?.value && fetchClientDepartmentsBankAccounts(buyer?.value);
  }, [buyer]);

  const checkEUVAT = async (buyerTaxNum) => {
    const numNoChars = buyerTaxNum.replace(/[^a-zA-Z0-9]/g, "");
    props.loading(true);
    const countryCode = buyerTaxNum.match(/^\d/)
      ? "PL"
      : numNoChars.slice(0, 2).toUpperCase();
    let numberCode = numNoChars.match(/^\d/) ? numNoChars : numNoChars.slice(2);
    const response = await fetch(
      `${DBurl}/euVatApi?nip=${numberCode.replace(
        /\s/g,
        ""
      )}&country=${countryCode}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setBuyerTaxNum(buyerTaxNum.replace(/[^a-zA-Z0-9]/g, ""));
      if (
        json.data ===
        "Member State service unavailable. Please re-submit your request later."
      ) {
        props.loading(false);
        setOpenDialog(true);
        setDialogColor("warning");
        setDialogContent("Brak możliwości sprawdzenia numeru NIP");
        postInvoice();
        return;
      }
      if (json.data.status === true) {
        props.loading(false);
        setOpenDialog(true);
        setDialogContent(`NIP poprawny.`);
        setDialogColor("success");
        postInvoice();
        return;
      } else {
        props.loading(false);
        setOpenDialog(true);
        setDialogContent(`NIP nieaktywny`);
        setDialogColor("error");
        return;
      }
    } else {
      props.loading(false);
      setOpenDialog(true);
      setDialogContent(
        `Wystąpił nieoczekiwany błąd. Skontaktuj się z administratorem.`
      );
      setDialogColor("error");
      return;
    }
  };

  const postManyTags = async (id_invoice) => {
    for (const tag of selected_tags) {
      postTagsForInvoice(id_invoice, tag);
    }
  };
  const postTagsForInvoice = async (id_invoice, tag) => {
    const response = await fetch(`${DBurl}/setInvoiceTagAssignments`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: handleMode(),
        data: {
          id_invoice,
          id_tag: tag,
        },
      }),
    });
    const json = await response.json();
    if (json.success) {
    }
  };

  const postInvoice = async () => {
    props.loading(true);
    if (checkError() || checkServicesError()) {
      setError(true);
      handlePostError(
        "Upewnij się, że wszystkie wymagane pola zostały wypełnione."
      );
      props.loading(false);
      return;
    }
    if (!checkIsPostOK()) {
      handlePostError("Numer faktury jest już zajęty");
      props.loading(false);
      return;
    }
    for (let i = 0; i < services?.length; i++) {
      services[i].value_brutto =
        Number(services[i].value_netto) +
        Number(services[i].value_netto) * (Number(services[i].vat) / 100);
    }
    setError(false);
    const servs = !isCorrection.current
      ? { services: services }
      : { correction_services: services };
    const response = await fetch(`${DBurl}/setInvoices`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: handleMode(),
        data: !isCorrection.current
          ? {
              id: invoiceIdFromProps ? invoiceIdFromProps : null,
              id_dealer: seller?.value,
              id_contractor: buyer?.id_contractor, // ID kontrahenta
              id_contractor_department: buyer?.value, // ID odziału kontrahenta
              is_recipient: receiver,
              id_contractor2: invReceiver?.value || null,
              type: !isCorrection.current ? documentType?.value : 3, // Typ faktury. 1 - Faktura, 2 - Proforma, 3 - Korekta, 4 - Nota obciążeniowa, 5 - Nota uznaniowa
              date_of_issue: formatDate(issueDate), // Data wystawienia
              date_service: formatDate(saleServiceDate), // Data sprzedaży / wykonania usługi
              invoice_no: handleInvNum(), // Numer faktury
              place_of_issue: issuePlace, // Miejsce wystawienia
              description: description, // Opis faktury
              payment_method: paymentOption.value, // Rodzaj płatności
              payment_deadline: paymentDeadlineDay, // Termin płatności
              advance: null, // Zaliczka
              orders: [...new Set(handleOrders())], // Dotyczy zleceń
              value_brutto: handleValueBrutto(), // Wartość brutto
              payment: advancePayment, // Zapłacone
              ts_payment: issueDate, // Data ostatniej wpłaty
              dealer_account_name_1: bankAccName?.acc_name, // Konta oddziału operatora MAX 3 zestawy
              dealer_bank_name_1: bank,
              dealer_bank_account_no_1: accNum,
              dealer_bank_vat_account_no_1: taxAccNum,
              dealer_swift_bic_no_1: swiftAccNum,
              dealer_account_currency_1: accCurrency,
              dealer_account_name_2: bankAccName2?.acc_name || null,
              dealer_bank_name_2: bank2 || null,
              dealer_bank_account_no_2: accNum2 || null,
              dealer_bank_vat_account_no_2: taxAccNum2 || null,
              dealer_swift_bic_no_2: swiftAccNum2 || null,
              dealer_account_currency_2: acc2Currency || null,
              dealer_account_name_3: bankAccName3?.acc_name || null,
              dealer_bank_name_3: bank3 || null,
              dealer_bank_account_no_3: accNum3 || null,
              dealer_bank_vat_account_no_3: taxAccNum3 || null,
              dealer_swift_bic_no_3: swiftAccNum3 || null,
              dealer_account_currency_3: acc3Currency || null,
              currency:
                exchangeRate[0] === "No data" || exchangeRate?.length === 0
                  ? "PLN"
                  : exchangeRate[0]?.code, // Waluta
              exchange_rate: exchangeRate[0]?.value, // Jeżeli currency != PLN (faktura wielowalutowa) to podejmy wartość kursu
              exchange_no: exchangeRate[0]?.no, // Jeżeli currency != PLN (faktura wielowalutowa) to podejmy numer notowania
              exchange_date: exchangeRate[0]?.date, // Jeżeli currency != PLN (faktura wielowalutowa) to podejmy datę notowania
              person_to_pick_up: personToPickUp, // Osoba upoważniona do odbioru dokumentu
              person_to_issue: personToIssue, // Osoba upoważniona do wystawienia dokumentu
              kind: documentType?.kind, // 1 - Faktura VAT EU, 2 - Mechanizm podzielnej płatności, null - brak rodzaju
              debit_note_no: checkDebit(), // Jezeli type = 4 (nota obciążeniowa) to podajemy numer noty obciążeniowej
              credit_note_no: checkCredit(), // Jezeli type = 5 (nota uznaniowa) to podajemy numer noty uznaniowej
              dealer_dbo: dbo,
              dealer_eori: eori,
              contractor_dbo: buyerDbo,
              contractor_eori: buyerEori,
              signature: "",
              services: removeNaN(services),
              is_gmp_fsa: prot,
              print_order_data: printData,
              for_preview: true,
              date_of_exchange_rate: exchangeRateDate,
              contractor_account_name_1: buyerBankAccName.label, // Konta kontrahenta MAX 2 zestawy
              contractor_bank_name_1: buyerBank,
              contractor_bank_account_no_1: buyerAccNum,
              contractor_bank_vat_account_no_1: buyerTaxAccNum,
              contractor_swift_bic_no_1: buyerSwiftAccNum,
              // contractor_account_currency_1: 'EUR',

              contractor_account_name_2: buyerBankAccName2.label, // Konta kontrahenta MAX 2 zestawy
              contractor_bank_name_2: buyerBank2,
              contractor_bank_account_no_2: buyerAccNum2,
              contractor_bank_vat_account_no_2: buyerTaxAccNum2,
              contractor_swift_bic_no_2: buyerSwiftAccNum2,
            }
          : {
              id_dealer: seller?.value,
              id_contractor: buyer?.id_contractor,
              id_contractor_department: buyer?.value,
              type: 3, // korekta
              date_of_issue: formatDate(issueDate),
              date_of_correction: formatDate(saleServiceDate), // data korekty
              date_service: formatDate(issueDate),
              invoice_no: linkedInvNum,
              correction_no: invoiceNum, // numer korekty
              correction_invoice_id: invoiceIdFromProps, // id faktury do której wystawiono korektę
              correction_title: correctionTitle, // korekta tytułem
              place_of_issue: issuePlace,
              description: description,
              currency:
                exchangeRate[0] === "No data" || exchangeRate?.length === 0
                  ? "PLN"
                  : exchangeRate[0]?.code,
              exchange_rate: exchangeRate[0]?.value, // Jeżeli currency != PLN (faktura wielowalutowa) to podejmy wartość kursu
              exchange_no: exchangeRate[0]?.no, // Jeżeli currency != PLN (faktura wielowalutowa) to podejmy numer notowania
              exchange_date: exchangeRate[0]?.date, // Jeżeli currency != PLN (faktura wielowalutowa) to podejmy datę notowania
              payment_method: paymentOption.value,
              payment_deadline: paymentDeadlineDay,
              advance: null,
              // correction_services: services.map(serv => serv.id_service_reference=serv.id),
              correction_services: removeNaN(handleServices()),
              orders: [...new Set(handleOrders())],
              value_brutto: handleValueBrutto(),
              payment: advancePayment,
              ts_payment: issueDate,
              dealer_account_name_1: bankAccName?.acc_name, // Konta oddziału operatora MAX 3 zestawy
              dealer_bank_name_1: bank,
              dealer_bank_account_no_1: accNum,
              dealer_bank_vat_account_no_1: taxAccNum,
              dealer_swift_bic_no_1: swiftAccNum,
              dealer_account_currency_1: accCurrency,
              dealer_account_name_2: bankAccName2?.acc_name || null,
              dealer_bank_name_2: bank2 || null,
              dealer_bank_account_no_2: accNum2 || null,
              dealer_bank_vat_account_no_2: taxAccNum2 || null,
              dealer_swift_bic_no_2: swiftAccNum2 || null,
              dealer_account_currency_2: acc2Currency || null,
              dealer_account_name_3: bankAccName3?.acc_name || null,
              dealer_bank_name_3: bank3 || null,
              dealer_bank_account_no_3: accNum3 || null,
              dealer_bank_vat_account_no_3: taxAccNum3 || null,
              dealer_swift_bic_no_3: swiftAccNum3 || null,
              dealer_account_currency_3: acc3Currency,
              person_to_pick_up: personToPickUp, // Osoba upoważniona do odbioru dokumentu
              person_to_issue: personToIssue, // Osoba upoważniona do wystawienia dokumentu
              is_gmp_fsa: prot,
              print_order_data: printData,
              is_recipient: receiver,
              id_contractor2: invReceiver?.value || null,
              date_of_exchange_rate: exchangeRateDate,
              contractor_account_name_1: buyerBankAccName, // Konta kontrahenta MAX 2 zestawy
              contractor_bank_name_1: buyerBank,
              contractor_bank_account_no_1: buyerAccNum,
              contractor_bank_vat_account_no_1: buyerTaxAccNum,
              contractor_swift_bic_no_1: buyerSwiftAccNum,
              contractor_account_name_2: buyerBankAccName2, // Konta kontrahenta MAX 2 zestawy
              contractor_bank_name_2: buyerBank2,
              contractor_bank_account_no_2: buyerAccNum2,
              contractor_bank_vat_account_no_2: buyerTaxAccNum2,
              contractor_swift_bic_no_2: buyerSwiftAccNum2,
            },
      }),
    });
    const json = await response.json();
    if (json.success) {
      let tmp_new_id = json.data.new_id;

      setOpenDialogSummary(true);
      setNew_id(
        invoiceIdFromProps && !isCorrection.current
          ? invoiceIdFromProps
          : tmp_new_id
      );
      selected_tags?.length && postManyTags(tmp_new_id);
      props.loading(false);
      return json;
    } else {
      handlePostError(
        "UPS! Coś poszło nie tak. Upewnij się, że formularz jest poprawnie wypełniony."
      );
      props.loading(false);
    }
  };

  const checkCorrection = () => {
    if (invoiceIdFromProps && isCorrection.current) return true;
    if (invoiceIdFromProps && !isCorrection.current) return false;
    if (!invoiceIdFromProps && !isCorrection.current) return true;
  };
  useEffect(() => {
    if (!stopAuto && checkCorrection()) {
      setInvoiceNum(
        getNewInvoiceNumber(
          invoices,
          issueDate,
          saleServiceDate,
          documentType,
          invFormat,
          special,
          isCorrection.current,
          sufix
        )
      );
    }
  }, [invoices?.length, issueDate, saleServiceDate, documentType]);

  const CustomInput = forwardRef((props, ref) => {
    return (
      <div className={`${"datapicker_new"} ${styles.dataPicker}`}>
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <Calendar onClick={props.onClick} />
      </div>
    );
  });

  const addDays = (date = new Date(), days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    var day = result.getDate();
    var month = result.getMonth() + 1;
    var year = result.getFullYear();
    const deadlineDate = `${year}-${month}-${day}`;
    return deadlineDate;
  };

  const fetchDeleteInvoice = async (id) => {
    if (invoiceIdFromProps && !isCorrection.current) {
      setOpenDialog(true);
      setDialogContent("Edycja faktury została anulowana.");
      setDialogColor("success");
      setOpenDialogSummary(false);
      return;
    }

    props.loading(true);
    const response = await fetch(`${DBurl}/deleteInvoice?id_invoice=${id}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });

    const json = await response.json();
    if (json.success) {
      setOpenDialog(true);
      setDialogContent("Faktura została anulowana.");
      setDialogColor("success");
      setOpenDialogSummary(false);
      props.loading(false);
    } else {
      setOpenDialog(true);
      setDialogContent("Błąd przy próbie anulowania faktury..");
      setDialogColor("error");

      setOpenDialogSummary(false);
      props.loading(false);
    }
  };

  const fetchSaveInvoice = async (id) => {
    props.loading(true);
    const response = await fetch(`${DBurl}/saveInvoice?id_invoice=${id}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });

    const json = await response.json();
    if (json.success) {
      setOpenDialog(true);
      setDialogContent("Faktura została zapisana.");
      setDialogColor("success");

      clear();
      setOpenDialogSummary(false);
      props.loading(false);
    } else {
      setOpenSnack(true);
      setDialogContent("Błąd przy próbie zapisu faktury..");
      setDialogColor("error");

      setOpenDialogSummary(false);
      props.loading(false);
    }
  };

  const printPDF = (id) => {
    const url = `${DBurl}/printInvoice2PDF?id_invoice=${id}&output=I`;
    window.open(url, "_blank");
  };

  const order = props.tabs.find((tab) => tab.orderData);

  const lastUnloading = order?.orderData?.locations
    ?.filter((loc) => loc.type === "unloading")
    .slice(-1)[0];

  const handleServicesFromOrder = () => {
    const data = {
      id: 1,
      id_order: order?.orderData?.id,
      name: "Usługa transportowa",
      quantity: 1,
      price_netto: order?.orderData?.order_price
        ? order?.orderData?.order_price
        : 1,
      vat: 23,
    };
    handleNewServiceWithAllData(data);
  };

  const handleInvoiceFromOrder = () => {
    if (order) {
      if (order.forCarrier) {
        setBuyer({
          label: order?.orderData?.carrier?.shortname,
          value: order?.orderData?.carrier?.id,
          id_contractor: order?.orderData?.contractor?.id,
        });
        setBuyerCompanyName(order?.orderData?.carrier?.name);
        setBuyerStreet(
          `${
            order?.orderData?.carrier?.address?.street
              ? order?.orderData?.carrier?.street
              : ""
          } ${
            order?.orderData?.carrier?.street_no
              ? order?.orderData?.carrier?.street_no
              : ""
          } ${
            order?.orderData?.carrier?.apartment_no !== " " &&
            order?.orderData?.carrier?.apartment_no
              ? "/"
              : ""
          } ${
            order?.orderData?.carrier?.apartment_no
              ? order?.orderData?.carrier?.apartment_no
              : ""
          }`
        );
        setBuyerTaxNum(order?.orderData?.carrier?.nip);
        setBuyerCountry(order?.orderData?.carrier?.country);
        setBuyerCity(
          `${
            order?.orderData?.carrier?.zipcode !== undefined
              ? order?.orderData?.carrier?.zipcode
              : ""
          } ${
            order?.orderData?.carrier?.city !== undefined
              ? order?.orderData?.carrier?.city
              : ""
          }`
        );
        setSelectedCurrency(order?.orderData?.order_currency || "PLN");
        handleServicesFromOrder();
        setSaleServiceDate(new Date(lastUnloading?.ts));
      } else {
        setBuyer({
          label: order?.orderData?.contractor?.shortname,
          value: order?.orderData?.contractor?.id,
          id_contractor: order?.orderData?.contractor?.id,
        });
        setBuyerCompanyName(order?.orderData?.contractor?.name);
        setBuyerStreet(
          `${
            order?.orderData?.contractor?.address?.street
              ? order?.orderData?.contractor?.address?.street
              : ""
          } ${
            order?.orderData?.contractor?.address?.street_no
              ? order?.orderData?.contractor?.address?.street_no
              : ""
          } ${
            order?.orderData?.contractor?.address?.apartment_no !== " " &&
            order?.orderData?.contractor?.address?.apartment_no
              ? "/"
              : ""
          } ${
            order?.orderData?.contractor?.address?.apartment_no
              ? order?.orderData?.contractor?.address?.apartment_no
              : ""
          }`
        );
        setBuyerTaxNum(order?.orderData?.contractor?.nip);
        setBuyerCountry(order?.orderData?.contractor?.address?.country);
        setBuyerCity(
          `${
            order?.orderData?.contractor?.address?.zipcode
              ? order?.orderData?.contractor?.address?.zipcode
              : ""
          } ${
            order?.orderData?.contractor?.address?.city
              ? order?.orderData?.contractor?.address?.city
              : ""
          }`
        );
        setSelectedCurrency(order?.orderData?.order_currency || "PLN");
        handleServicesFromOrder();
        setSaleServiceDate(new Date(lastUnloading?.ts));
      }
    }
  };

  const handleReceiver = () => {
    !receiver ? setReceiver(true) : setReceiver(false);
    setInvReceiver({ label: "", value: null });
    setReceiverCompanyName("");
    setReceiverTaxNum("");
    setReceiverStreet("");
    setReceiverCity("");
    setReceiverCountry("");
  };
  // Zapisywanie danych
  const savedTabId = props.tabID;
  const saveTab = () => {
    const tempTab = { ...props.tabs[savedTabId] };

    tempTab.buyerCompanyName = buyerCompanyName;
    tempTab.invoiceNum = invoiceNum;
    tempTab.issuePlace = issuePlace;
    tempTab.issueDate = issueDate;
    tempTab.saleServiceDate = saleServiceDate;
    tempTab.documentType = documentType;
    tempTab.seller = seller;
    tempTab.companyName = companyName;
    tempTab.taxNum = taxNum;
    tempTab.street = street;
    tempTab.city = city;
    tempTab.country = country;
    tempTab.email = email;
    tempTab.phoneNum = phoneNum;
    tempTab.website = website;
    tempTab.krs = krs;
    tempTab.bankAccName = bankAccName;
    tempTab.bank = bank;
    tempTab.accNum = accNum;
    tempTab.taxAccNum = taxAccNum;
    tempTab.swiftAccNum = swiftAccNum;
    tempTab.accCurrency = accCurrency;
    tempTab.bankAccName2 = bankAccName2;
    tempTab.bank2 = bank2;
    tempTab.accNum2 = accNum2;
    tempTab.taxAccNum2 = taxAccNum2;
    tempTab.swiftAccNum2 = swiftAccNum2;
    tempTab.acc2Currency = acc2Currency;
    tempTab.bankAccName3 = bankAccName3;
    tempTab.bank3 = bank3;
    tempTab.accNum3 = accNum3;
    tempTab.taxAccNum3 = taxAccNum3;
    tempTab.swiftAccNum3 = swiftAccNum3;
    tempTab.acc3Currency = acc3Currency;
    tempTab.eori = eori;
    tempTab.dbo = dbo;
    tempTab.buyer = buyer;
    tempTab.buyerCompanyName = buyerCompanyName;
    tempTab.buyerTaxNum = buyerTaxNum;
    tempTab.buyerStreet = buyerStreet;
    tempTab.buyerCity = buyerCity;
    tempTab.buyerCountry = buyerCountry;
    tempTab.buyerEmail = buyerEmail;
    tempTab.buyerPhoneNum = buyerPhoneNum;
    tempTab.buyerWebsite = buyerWebsite;
    tempTab.buyerKrs = buyerKrs;
    tempTab.buyerBankAccName = buyerBankAccName;
    tempTab.buyerBank = buyerBank;
    tempTab.buyerAccNum = buyerAccNum;
    tempTab.buyerTaxAccNum = buyerTaxAccNum;
    tempTab.buyerSwiftAccNum = buyerSwiftAccNum;
    tempTab.buyerEori = buyerEori;
    tempTab.buyerDbo = buyerDbo;
    tempTab.personToIssue = personToIssue;
    tempTab.personToPickUp = personToPickUp;
    tempTab.stopAuto = stopAuto;
    tempTab.selectedCurrency = selectedCurrency;
    tempTab.vat = vat;
    tempTab.services = services;
    tempTab.paymentDeadline = paymentDeadline;
    tempTab.paymentDeadlineDay = paymentDeadlineDay;
    tempTab.description = description;
    tempTab.advancePayment = advancePayment;
    tempTab.totalGross = totalGross;
    tempTab.paymentOption = paymentOption;
    tempTab.secondAccount = secondAccount;
    tempTab.thirdAccount = thirdAccount;
    tempTab.numberFromProps = numberFromProps;
    tempTab.linkedInvNum = linkedInvNum;
    tempTab.debitNoteNum = debitNoteNum;
    tempTab.creditNoteNum = creditNoteNum;
    tempTab.noteInvId = noteInvId;
    tempTab.correctionTitle = correctionTitle;
    tempTab.invFormat = invFormat;
    tempTab.special = special;
    tempTab.prot = prot;
    tempTab.printData = printData;
    tempTab.new_id = new_id;
    tempTab.receiver = receiver;
    tempTab.invReceiver = invReceiver;
    tempTab.receiverCompanyName = receiverCompanyName;
    tempTab.receiverTaxNum = receiverTaxNum;
    tempTab.receiverStreet = receiverStreet;
    tempTab.receiverCity = receiverCity;
    tempTab.receiverCountry = receiverCountry;
    tempTab.date_of_exchange_rate = exchangeRateDate;
    tempTab.selected_tags = selected_tags;

    return tempTab;
  };

  useEffect(() => {
    props.setTabs(() => {
      const tempTab = [...props.tabs];
      tempTab[props.tabID] = saveTab();
      return tempTab;
    });
  }, [
    buyerCompanyName,
    invoiceNum,
    issuePlace,
    issueDate,
    saleServiceDate,
    documentType,
    seller,
    companyName,
    taxNum,
    street,
    city,
    country,
    email,
    phoneNum,
    website,
    krs,
    bankAccName,
    bank,
    accNum,
    taxAccNum,
    swiftAccNum,
    accCurrency,
    bankAccName2,
    bank2,
    accNum2,
    taxAccNum2,
    swiftAccNum2,
    acc2Currency,
    bankAccName3,
    bank3,
    accNum3,
    taxAccNum3,
    swiftAccNum3,
    acc3Currency,
    eori,
    dbo,
    buyer,
    buyerCompanyName,
    buyerTaxNum,
    buyerStreet,
    buyerCity,
    buyerCountry,
    buyerEmail,
    buyerPhoneNum,
    buyerWebsite,
    buyerKrs,
    buyerBankAccName,
    buyerBank,
    buyerAccNum,
    buyerTaxAccNum,
    buyerSwiftAccNum,
    buyerEori,
    buyerDbo,
    personToIssue,
    personToPickUp,
    stopAuto,
    selectedCurrency,
    vat,
    services,
    paymentDeadline,
    paymentDeadlineDay,
    description,
    advancePayment,
    totalGross,
    paymentOption,
    secondAccount,
    thirdAccount,
    numberFromProps,
    linkedInvNum,
    debitNoteNum,
    creditNoteNum,
    noteInvId,
    correctionTitle,
    invFormat,
    special,
    prot,
    printData,
    new_id,
    receiver,
    invReceiver,
    receiverCompanyName,
    receiverTaxNum,
    receiverStreet,
    receiverCity,
    receiverCountry,
    selected_tags,
  ]);

  const isWeekendDay = (date) => {
    const day = date.getDay();
    return day === 0 || day === 6;
  };

  useEffect(() => {
    handleInvoiceFromOrder();
  }, [order?.invoiceFromOrder === true]);

  const iframeRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;
      iframe.onload = () => {
        setIsLoading(false);
      };
    }
  }, [new_id]);

  const recipientOther = (e) => {
    setInvReceiver(e);
    setReceiverCompanyName(e?.name ? e?.name : "");
    setReceiverStreet(
      `${e?.street ? e?.street : ""} ${e?.street_no ? e?.street_no : ""} ${
        e?.apartment_no !== " " && e?.apartment_no ? "/" : ""
      } ${e?.apartment_no ? e?.apartment_no : ""}`
    );
    setReceiverTaxNum(e?.nip ? e?.nip : "");
    setReceiverCountry(e?.country ? e?.country : "");
    setReceiverCity(e?.zipcode && e?.city ? `${e?.zipcode} ${e?.city}` : "");
  };

  useEffect(() => {
    setReceiver(selectedBuyerDepartment?.recipient_other_than_buyer);
    selectedBuyerDepartment &&
      recipientOther(
        clients
          .filter((dep) => dep.value !== buyer?.id_contractor)
          .find(
            (opt) =>
              opt.value ===
              selectedBuyerDepartment?.recipient_other_than_buyer_id
          )
      );
  }, [selectedBuyerDepartment]);

  useEffect(() => {
    if (orders?.length > 0) {
      const allOrders = orders.filter((obj) =>
        services.map((srv) => srv.id_order).includes(obj.id)
      );
      const allLocations = allOrders.map((ord) => ord.locations).flat();
      const firstLoading = allLocations
        .filter((loc) => loc.type === "loading")
        .sort((a, b) => new Date(a.ts) - new Date(b.ts))[0]?.ts;
      const lastUnloading = allLocations
        .filter((loc) => loc.type === "unloading")
        .sort((a, b) => new Date(b.ts) - new Date(a.ts))[0]?.ts;
      if (order && order.forCarrier) {
        setExchangeRateDate(new Date(order.orderData.fo_exchange_date));
      } else if (
        selectedBuyerDepartment?.from_when_exchange_rate === "2" &&
        services[0]?.id_order &&
        firstLoading
      ) {
        setExchangeRateDate(new Date(firstLoading));
      } else if (
        selectedBuyerDepartment?.from_when_exchange_rate === "3" &&
        services[0]?.id_order &&
        lastUnloading
      ) {
        setExchangeRateDate(new Date(lastUnloading));
      }
    }
  }, [selectedBuyerDepartment, services, JSON.stringify(orders)]);

  return (
    <div>
      <Title title={props.state.tabName} />
      <Grid className={styles.mainContent} container item xs={12}>
        <Grid className={styles.topContent} container item xs={12}>
          <InvoiceInput
            size={4}
            name={invoiceNum}
            labelText={"Nr faktury"}
            setFunction={(e) => {
              setStopAuto(true);
              setInvoiceNum(e.target.value);
            }}
            error={error && !invoiceNum}
          />
          <Grid item xs={4}>
            <div className={`${styles.inp} multi_selector`}>
              <label htmlFor={"doc_type"}>Wybierz rodzaj dokumentu</label>
              <Select
                value={documentType}
                className={
                  error && !documentType
                    ? `${styles.selectError}`
                    : `${styles.select}`
                }
                options={typeOptions}
                onChange={(e) => {
                  setDocumentType(e);
                }}
                name="doc_type"
                placeholder="Wybierz..."
                getOptionValue={(option) => `${option?.label}`}
                styles={select_styles}
                menuPortalTarget={document.body}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={styles.datePicker}>
              <label htmlFor={"issueDate"}>Data wystawienia</label>
              <div className={styles.date}>
                <DatePicker
                  dateFormat="dd.MM.yyyy"
                  selected={issueDate}
                  onChange={(e) => {
                    let newD = moment(e).valueOf();
                    let newDS = moment(newD).format("yyyy-MM-DD");
                    newDS = new Date(e);
                    setIssueDate(newDS);
                  }}
                  locale={plLocale}
                  name="issueDate"
                  customInput={<CustomInput error={false} />}
                  placeholderText={"dd.mm.rrrr"}
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid className={styles.topContent} container item xs={12}>
          <Grid item xs={4}>
            {documentType?.value === 4 ||
            documentType?.value === 5 ||
            props?.state?.typeInput?.value === 3 ? (
              <div className={`${styles.inp} multi_selector`}>
                <label htmlFor={"numb"}>Dotyczy dokumentu</label>
                <Select
                  value={
                    linkedInvNum?.label
                      ? linkedInvNum?.label
                      : { label: linkedInvNum, value: linkedInvNum }
                  }
                  className={
                    error && !linkedInvNum
                      ? `${styles.selectError}`
                      : `${styles.select}`
                  }
                  options={invoices.map((inv) => ({
                    label: inv.invoice_no,
                    value: inv.invoice_no,
                    id: inv.id,
                  }))}
                  onChange={(e) => {
                    setLinkedInvNum(e?.label);
                    setNoteInvId([e.id]);
                  }}
                  name="numb"
                  placeholder="Dotyczy dokumentu"
                  getOptionValue={(option) => `${option.invoice_no}`}
                  styles={select_styles}
                  menuPortalTarget={document.body}
                />
              </div>
            ) : (
              <></>
            )}
          </Grid>
          <InvoiceInput
            size={4}
            name={issuePlace}
            labelText={"Miejsce wystawienia"}
            setFunction={(e) => setIssuePlace(e.target.value)}
          />
          <Grid item xs={2}>
            <div className={styles.datePicker}>
              <label htmlFor={"saleServiceDate"}>
                {!isCorrection.current
                  ? "Data sprzedaży"
                  : "Data wystawienia korekty"}
              </label>
              <div className={styles.date}>
                <DatePicker
                  dateFormat="dd.MM.yyyy"
                  selected={saleServiceDate}
                  onChange={(e) => {
                    let newD = moment(e).valueOf();
                    let newDS = moment(newD).format("yyyy-MM-DD");
                    newDS = new Date(e);
                    setSaleServiceDate(newDS);
                  }}
                  locale={plLocale}
                  name="saleServiceDate"
                  customInput={<CustomInput error={false} />}
                  placeholderText={"dd.mm.rrrr"}
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid className={styles.topContent} container item xs={12}>
          <Grid item xs={4}>
            <div className={`${styles.inp} multi_selector`}>
              <label htmlFor={"tags_list"}>Wybierz tag</label>
              <Select
                isMulti
                value={tags_list.filter((obj) =>
                  selected_tags.includes(obj.value)
                )}
                className={`${styles.select}`}
                options={tags_list || []}
                onChange={handleTagsChange}
                name="tags_list"
                placeholder="Wybierz tag..."
                getOptionLabel={(e) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {e?.label}
                    <span
                      className={BTNstyles.selectedColor_option}
                      style={{
                        backgroundColor: e.color,
                        borderColor: "#aaaaaa",
                      }}
                    ></span>
                  </div>
                )}
                getOptionValue={(option) => `${option?.label}`}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused && "#142f42",
                    color: state.isFocused && "#fff",
                  }),
                }}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            {selected_tags_duble &&
              tags_list &&
              selected_tags_duble !== null &&
              selected_tags_duble.map((o, i) => {
                let name = tags_list.filter((x) => {
                  return x.value === o;
                });

                let colorTmp = name?.length > 0 ? name[0].color : "#ffffff";
                let colorBorderTmp =
                  name?.length > 0 ? name[0].color : "#ffffff";

                if (colorTmp === "#ffffff") {
                  colorBorderTmp = "#dfdfdf";
                }

                let colorTmpToRBG = colorTmp.substring(1);
                let aRgbHex = colorTmpToRBG.match(/.{1,2}/g);
                let aRgb = [
                  parseInt(aRgbHex[0], 16),
                  parseInt(aRgbHex[1], 16),
                  parseInt(aRgbHex[2], 16),
                ];

                let colorText = colorContrast(
                  String(`rgb(${aRgb[0]},${aRgb[1]},${aRgb[2]})`)
                );

                if (tags_list?.length > 0 && name[0]?.label !== undefined) {
                  return (
                    <div
                      key={i}
                      className={stylesMod.tagItem}
                      style={{
                        position: "relative",
                        color: colorText,
                        border: `2px solid ${colorBorderTmp}`,
                        backgroundColor: `rgb(${aRgb[0]} ${aRgb[1]} ${aRgb[2]} / 90%)`,
                      }}
                    >
                      {name?.length > 0 && name[0]?.label}
                      <Button
                        color="primary"
                        component="div"
                        onClick={(e) => removeTag(i, o)}
                        className={`${stylesMod.BtnInInput_x}`}
                      >
                        <X
                          className={stylesMod.tagOut}
                          style={{ color: colorText }}
                        />
                      </Button>
                    </div>
                  );
                }
              })}
          </Grid>
          <Grid item xs={2}>
            <div className={styles.datePicker}>
              <label htmlFor={"saleServiceDate"}>
                Data kursu wymiany waluty
              </label>
              <div className={styles.date}>
                <DatePicker
                  dateFormat="dd.MM.yyyy"
                  maxDate={new Date()}
                  filterDate={(date) => !isWeekendDay(date)}
                  selected={exchangeRateDate}
                  onChange={(date) => {
                    if (date && !isWeekendDay(date)) {
                      setExchangeRateDate(date);
                    }
                  }}
                  locale={plLocale}
                  name="saleServiceDate"
                  customInput={<CustomInput error={false} />}
                  placeholderText="dd.mm.rrrr"
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid className={styles.midContent} container item xs={12}>
          <Grid className={styles.midContentLeft} container item xs={6}>
            <Grid item xs={12}>
              <div className={`${styles.inp} multi_selector`}>
                <label htmlFor={"seller"}>Wybierz sprzedawcę</label>
                <Select
                  value={seller}
                  className={
                    error && !seller?.value
                      ? `${styles.selectError}`
                      : `${styles.select}`
                  }
                  options={departments}
                  onChange={(e) => {
                    setSeller(e);
                    setCompanyName(e.name);
                    setStreet(
                      `${e.street ? e.street : ""} ${
                        e.street_no ? e.street_no : ""
                      } ${
                        e.apartment_no !== " " && e.apartment_no ? "/" : ""
                      } ${e.apartment_no ? e.apartment_no : ""}`
                    );
                    setTaxNum(e.nip);
                    setCountry(e.country);
                    setCity(`${e.zipcode} ${e.city}`);
                    setIssuePlace(e.issuePlace);
                    setPaymentDeadline(
                      deadlineOptions.find(
                        (option) => option.value === parseInt(e.deadline)
                      )
                    );
                    setInvFormat(e.invFormat);
                    setSpecial(e.special);
                  }}
                  name="seller"
                  placeholder="Wybierz sprzedawcę..."
                  getOptionValue={(option) => `${option?.label}`}
                  styles={select_styles}
                  menuPortalTarget={document.body}
                  error={error && !seller?.value}
                />
              </div>
            </Grid>
            <InvoiceInput
              size={12}
              name={companyName}
              labelText={"Nazwa firmy"}
              setFunction={(e) => setCompanyName(e.target.value)}
              error={error && !companyName}
            />
            <InvoiceInput
              size={12}
              name={taxNum}
              labelText={"NIP"}
              setFunction={(e) => setTaxNum(e.target.value)}
              error={error && !taxNum}
            />
            <InvoiceInput
              size={12}
              name={street}
              labelText={"Ulica"}
              setFunction={(e) => setStreet(e.target.value)}
              error={error && !street}
            />
            <InvoiceInput
              size={12}
              name={city}
              labelText={"Kod pocztowy i miejscowość"}
              setFunction={(e) => setCity(e.target.value)}
              error={error && !city}
            />
            <InvoiceInput
              size={12}
              name={country}
              labelText={"Kraj"}
              setFunction={(e) => setCountry(e.target.value)}
              error={error && !country}
            />
            <InvoiceInput
              size={12}
              name={email}
              labelText={"Email"}
              setFunction={(e) => setEmail(e.target.value)}
            />
            <InvoiceInput
              size={12}
              name={phoneNum}
              labelText={"Numer telefonu"}
              setFunction={(e) => setPhoneNum(e.target.value)}
            />
            <InvoiceInput
              size={12}
              name={website}
              labelText={"WWW"}
              setFunction={(e) => setWebsite(e.target.value)}
            />
            <InvoiceInput
              size={12}
              name={krs}
              labelText={"KRS"}
              setFunction={(e) => setKrs(e.target.value)}
            />
            <InvoiceInput
              size={12}
              name={eori}
              labelText={"EORI"}
              setFunction={(e) => setEori(e.target.value)}
            />
            <InvoiceInput
              size={12}
              name={dbo}
              labelText={"DBO"}
              setFunction={(e) => setDbo(e.target.value)}
            />
            <Grid item xs={12}>
              <div className={`${styles.inp} multi_selector`}>
                <label htmlFor={"account"}>Nazwa konta</label>
                <CreatableSelect
                  value={bankAccName}
                  className={`${styles.select}`}
                  options={
                    buyerAccountOptions[0]?.label
                      ? buyerAccountOptions
                      : accounts.filter(
                          (acc) =>
                            acc?.label !== bankAccName2?.label &&
                            acc?.label !== bankAccName3?.label
                        )
                  }
                  onChange={(e) => {
                    setBankAccName(e);
                    setAccNum(e.acc_no);
                    setTaxAccNum(e.vat_no);
                    setSwiftAccNum(e.swift_no);
                    setBank(e.bank_name);
                    setAccCurrency(e.currency);
                  }}
                  name="account"
                  placeholder=""
                  getOptionValue={(option) => `${option?.label}`}
                  styles={select_styles}
                  menuPortalTarget={document.body}
                />
              </div>
            </Grid>
            <InvoiceInput
              size={12}
              name={bank}
              labelText={"Nazwa banku"}
              setFunction={(e) => setBank(e.target.value)}
            />
            <InvoiceInput
              size={12}
              name={accNum}
              labelText={"Numer konta"}
              setFunction={(e) => setAccNum(e.target.value)}
              error={error && !accNum}
            />
            <InvoiceInput
              size={12}
              name={taxAccNum}
              labelText={"Numer konta VAT"}
              setFunction={(e) => setTaxAccNum(e.target.value)}
            />
            <InvoiceInput
              size={12}
              name={swiftAccNum}
              labelText={"Numer konta SWIFT/BIC"}
              setFunction={(e) => setSwiftAccNum(e.target.value)}
            />
            <Grid container item xs={12}>
              <Grid item xs={10}></Grid>
              {!secondAccount && (
                <Grid
                  item
                  xs={2}
                  className={styles.addAccount}
                  onClick={() => handleAddSecondAccount()}
                >
                  + Dodaj kolejne konto
                </Grid>
              )}
              {secondAccount && !thirdAccount && (
                <Grid
                  item
                  xs={2}
                  className={styles.addAccount}
                  onClick={() => handleDeleteSecondAccount()}
                >
                  Schowaj
                </Grid>
              )}
            </Grid>
            {secondAccount && (
              <>
                <Grid item xs={12}>
                  <div className={`${styles.inp} multi_selector`}>
                    <label htmlFor={"account"}>Nazwa konta</label>
                    <CreatableSelect
                      value={bankAccName2}
                      className={`${styles.select}`}
                      options={
                        buyerAccountOptions
                          ? buyerAccountOptions
                          : accounts.filter(
                              (acc) =>
                                acc?.label !== bankAccName?.label &&
                                acc?.label !== bankAccName3?.label
                            )
                      }
                      onChange={(e) => {
                        setBankAccName2(e);
                        setAccNum2(e.acc_no);
                        setTaxAccNum2(e.vat_no);
                        setSwiftAccNum2(e.swift_no);
                        setBank2(e.bank_name);
                        setAcc2Currency(e.currency);
                      }}
                      name="seller"
                      placeholder=""
                      getOptionValue={(option) => `${option?.label}`}
                      styles={select_styles}
                      menuPortalTarget={document.body}
                    />
                  </div>
                </Grid>
                <InvoiceInput
                  size={12}
                  name={bank2}
                  labelText={"Nazwa banku"}
                  setFunction={(e) => setBank2(e.target.value)}
                />
                <InvoiceInput
                  size={12}
                  name={accNum2}
                  labelText={"Numer konta"}
                  setFunction={(e) => setAccNum2(e.target.value)}
                />
                <InvoiceInput
                  size={12}
                  name={taxAccNum2}
                  labelText={"Numer konta VAT"}
                  setFunction={(e) => setTaxAccNum2(e.target.value)}
                />
                <InvoiceInput
                  size={12}
                  name={swiftAccNum2}
                  labelText={"Numer konta SWIFT/BIC"}
                  setFunction={(e) => setSwiftAccNum2(e.target.value)}
                />
                <Grid container item xs={12}>
                  <Grid item xs={8}></Grid>
                  {!thirdAccount && (
                    <Grid
                      item
                      xs={4}
                      className={styles.addAccount}
                      onClick={() => handleAddThirdAccount()}
                    >
                      + Dodaj kolejne konto
                    </Grid>
                  )}
                  {thirdAccount && (
                    <Grid
                      item
                      xs={4}
                      className={styles.addAccount}
                      onClick={() => handleDeleteThirdAccount()}
                    >
                      Schowaj
                    </Grid>
                  )}
                </Grid>
              </>
            )}
            {thirdAccount && (
              <>
                <Grid item xs={12}>
                  <div className={`${styles.inp} multi_selector`}>
                    <label htmlFor={"account"}>Nazwa konta</label>
                    <CreatableSelect
                      value={bankAccName3}
                      className={`${styles.select}`}
                      options={
                        buyerAccountOptions
                          ? buyerAccountOptions
                          : accounts.filter(
                              (acc) =>
                                acc?.label !== bankAccName?.label &&
                                acc?.label !== bankAccName2?.label
                            )
                      }
                      onChange={(e) => {
                        setBankAccName3(e);
                        setAccNum3(e.acc_no);
                        setTaxAccNum3(e.vat_no);
                        setSwiftAccNum3(e.swift_no);
                        setBank3(e.bank_name);
                        setAcc3Currency(e.currency);
                      }}
                      name="seller"
                      placeholder=""
                      getOptionValue={(option) => `${option?.label}`}
                      styles={select_styles}
                      menuPortalTarget={document.body}
                    />
                  </div>
                </Grid>
                <InvoiceInput
                  size={12}
                  name={bank3}
                  labelText={"Nazwa banku"}
                  setFunction={(e) => setBank3(e.target.value)}
                />
                <InvoiceInput
                  size={12}
                  name={accNum3}
                  labelText={"Numer konta"}
                  setFunction={(e) => setAccNum3(e.target.value)}
                />
                <InvoiceInput
                  size={12}
                  name={taxAccNum3}
                  labelText={"Numer konta VAT"}
                  setFunction={(e) => setTaxAccNum3(e.target.value)}
                />
                <InvoiceInput
                  size={12}
                  name={swiftAccNum3}
                  labelText={"Numer konta SWIFT/BIC"}
                  setFunction={(e) => setSwiftAccNum3(e.target.value)}
                />
                <Grid container item xs={12}>
                  <Grid item xs={8}></Grid>
                  {thirdAccount && !secondAccount && (
                    <Grid
                      item
                      xs={4}
                      className={styles.addAccount}
                      onClick={() => handleAddThirdAccount()}
                    >
                      Schowaj
                    </Grid>
                  )}
                </Grid>
              </>
            )}
            {!receiver && buyerSecondAccount && !secondAccount && (
              <>
                <Grid item xs={12} className={styles.emptyRow}>
                  {" "}
                </Grid>
                <Grid item xs={12} className={styles.emptyRow}>
                  {" "}
                </Grid>
                <Grid item xs={12} className={styles.emptyRow}>
                  {" "}
                </Grid>
                <Grid item xs={12} className={styles.emptyRow}>
                  {" "}
                </Grid>
                <Grid item xs={12} className={styles.emptyRow}>
                  {" "}
                </Grid>
                <Grid item xs={12} className={styles.emptyRow}>
                  {" "}
                </Grid>
              </>
            )}
            {receiver && !secondAccount && (
              <>
                <Grid item xs={12} className={styles.emptyRow}>
                  {" "}
                </Grid>
                <Grid item xs={12} className={styles.emptyRow}>
                  {" "}
                </Grid>
                <Grid item xs={12} className={styles.emptyRow}>
                  {" "}
                </Grid>
                <Grid item xs={12} className={styles.emptyRow}>
                  {" "}
                </Grid>
                <Grid item xs={12} className={styles.emptyRow}>
                  {" "}
                </Grid>
                <Grid item xs={12} className={styles.emptyRow}>
                  {" "}
                </Grid>
              </>
            )}
            {!thirdAccount && receiver && secondAccount && (
              <>
                <Grid item xs={12} className={styles.emptyRowAlt}>
                  {" "}
                </Grid>
              </>
            )}
          </Grid>
          <Grid className={styles.midContentRight} container item xs={6}>
            <Grid item xs={12}>
              <div className={`${styles.inp} multi_selector`}>
                <label htmlFor={"buyer"}>Wybierz nabywcę</label>
                <Select
                  value={buyer}
                  className={
                    error && !buyer.value
                      ? `${styles.selectError}`
                      : `${styles.select}`
                  }
                  options={buyerOptions}
                  onChange={(e) => {
                    setBuyer(e);
                    setBuyerCompanyName(e.name);
                    setBuyerStreet(
                      `${e.street ? e.street : ""} ${
                        e.street_no ? e.street_no : ""
                      } ${
                        e.apartment_no !== " " && e.apartment_no ? "/" : ""
                      } ${e.apartment_no ? e.apartment_no : ""}`
                    );
                    setBuyerTaxNum(e.nip);
                    setBuyerCountry(e.country);
                    setBuyerCity(
                      `${e.zipcode ? e.zipcode : ""} ${e.city ? e.city : ""}`
                    );
                    if (e.country !== "Polska") {
                      setPaymentOption({
                        label: "Przelew dwuwalutowy",
                        value: "Przelew dwuwalutowy",
                      });
                    }
                  }}
                  name="buyer"
                  placeholder="Wybierz nabywcę..."
                  getOptionValue={(option) => `${option?.label}`}
                  styles={select_styles}
                  menuPortalTarget={document.body}
                  error={error && !buyer.value}
                />
              </div>
            </Grid>
            <InvoiceInput
              size={12}
              name={buyerCompanyName}
              labelText={"Nazwa firmy"}
              setFunction={(e) => setBuyerCompanyName(e.target.value)}
              error={error && !buyerCompanyName}
            />
            <InvoiceInput
              size={12}
              name={buyerTaxNum}
              labelText={"NIP"}
              setFunction={(e) => setBuyerTaxNum(e.target.value)}
              error={error && !buyerTaxNum}
            />
            <InvoiceInput
              size={12}
              name={buyerStreet}
              labelText={"Ulica"}
              setFunction={(e) => setBuyerStreet(e.target.value)}
              error={error && !buyerStreet}
            />
            <InvoiceInput
              size={12}
              name={buyerCity}
              labelText={"Kod pocztowy i miejscowość"}
              setFunction={(e) => setBuyerCity(e.target.value)}
              error={error && !buyerCity}
            />
            <InvoiceInput
              size={12}
              name={buyerCountry}
              labelText={"Kraj"}
              setFunction={(e) => setBuyerCountry(e.target.value)}
              error={error && !buyerCountry}
            />
            <InvoiceInput
              size={12}
              name={buyerEmail}
              labelText={"Email"}
              setFunction={(e) => setBuyerEmail(e.target.value)}
            />
            <InvoiceInput
              size={12}
              name={buyerPhoneNum}
              labelText={"Numer telefonu"}
              setFunction={(e) => setBuyerPhoneNum(e.target.value)}
            />
            <InvoiceInput
              size={12}
              name={buyerWebsite}
              labelText={"WWW"}
              setFunction={(e) => setBuyerWebsite(e.target.value)}
            />
            <InvoiceInput
              size={12}
              name={buyerKrs}
              labelText={"KRS"}
              setFunction={(e) => setBuyerKrs(e.target.value)}
            />
            <InvoiceInput
              size={12}
              name={buyerEori}
              labelText={"EORI"}
              setFunction={(e) => setBuyerEori(e.target.value)}
            />
            <InvoiceInput
              size={12}
              name={buyerDbo}
              labelText={"DBO"}
              setFunction={(e) => setBuyerDbo(e.target.value)}
            />
            <Grid item xs={12}>
              <InvoiceInput
                size={12}
                name={buyerBankAccName}
                labelText={"Nazwa konta"}
                setFunction={(e) => setBuyerBankAccName(e.target.value)}
              />
            </Grid>
            <InvoiceInput
              size={12}
              name={buyerBank}
              labelText={"Nazwa banku"}
              setFunction={(e) => setBuyerBank(e.target.value)}
            />
            <InvoiceInput
              size={12}
              name={buyerAccNum}
              labelText={"Numer konta"}
              setFunction={(e) => setBuyerAccNum(e.target.value)}
            />
            <InvoiceInput
              size={12}
              name={buyerSwiftAccNum}
              labelText={"Numer konta SWIFT/BIC"}
              setFunction={(e) => setBuyerSwiftAccNum(e.target.value)}
            />
            <InvoiceInput
              size={12}
              name={buyerTaxAccNum}
              labelText={"Numer konta VAT"}
              setFunction={(e) => setBuyerTaxAccNum(e.target.value)}
            />
            <Grid container item xs={12}>
              <Grid item xs={8}></Grid>
              <Grid item xs={4} className={styles.addReceiver}>
                <div className={styles.addReceiverAndAcc}>
                  <div>
                    <input
                      className={styles.receiverBox}
                      type="checkbox"
                      checked={receiver}
                      value={receiver}
                      onChange={() => handleReceiver()}
                    ></input>
                    <span className={styles.receierBoxText}>
                      Odbiorca jest inny niż nabywca
                    </span>
                  </div>
                  {!buyerSecondAccount && (
                    <Grid
                      item
                      xs={12}
                      className={styles.addAccount}
                      onClick={() => handleAddSecondBuyerAccount()}
                    >
                      + Dodaj kolejne konto
                    </Grid>
                  )}
                  {buyerSecondAccount && (
                    <Grid
                      item
                      xs={12}
                      className={styles.addAccount}
                      onClick={() => handleDeleteSecondBuyerAccount()}
                    >
                      Schowaj
                    </Grid>
                  )}
                </div>
              </Grid>
            </Grid>
            {buyerSecondAccount ? (
              <>
                <Grid item xs={12}>
                  <InvoiceInput
                    size={12}
                    name={buyerBankAccName2}
                    labelText={"Nazwa konta"}
                    setFunction={(e) => setBuyerBankAccName2(e.target.value)}
                  />
                </Grid>
                <InvoiceInput
                  size={12}
                  name={buyerBank2}
                  labelText={"Nazwa banku"}
                  setFunction={(e) => setBuyerBank2(e.target.value)}
                />
                <InvoiceInput
                  size={12}
                  name={buyerAccNum2}
                  labelText={"Numer konta"}
                  setFunction={(e) => setBuyerAccNum2(e.target.value)}
                />
                <InvoiceInput
                  size={12}
                  name={buyerSwiftAccNum2}
                  labelText={"Numer konta SWIFT/BIC"}
                  setFunction={(e) => setBuyerSwiftAccNum2(e.target.value)}
                />
                <InvoiceInput
                  size={12}
                  name={buyerTaxAccNum2}
                  labelText={"Numer konta VAT"}
                  setFunction={(e) => setBuyerTaxAccNum2(e.target.value)}
                />
                {!thirdAccount && (
                  <Grid container item xs={12}>
                    <Grid item xs={12} className={styles.emptySmall}>
                      <div></div>
                    </Grid>
                  </Grid>
                )}
              </>
            ) : (
              <></>
            )}
            {!receiver && secondAccount && (
              <>
                <Grid item xs={12} className={styles.emptyRow}>
                  {" "}
                </Grid>
                <Grid item xs={12} className={styles.emptyRow}>
                  {" "}
                </Grid>
                <Grid item xs={12} className={styles.emptyRow}>
                  {" "}
                </Grid>
                <Grid item xs={12} className={styles.emptyRow}>
                  {" "}
                </Grid>
                <Grid item xs={12} className={styles.emptyRow}>
                  {" "}
                </Grid>
                <Grid item xs={12} className={styles.emptyRow}>
                  {" "}
                </Grid>
              </>
            )}
            {thirdAccount && !receiver && (
              <>
                <Grid item xs={12} className={styles.emptyRow}>
                  {" "}
                </Grid>
                <Grid item xs={12} className={styles.emptyRow}>
                  {" "}
                </Grid>
                <Grid item xs={12} className={styles.emptyRow}>
                  {" "}
                </Grid>
                <Grid item xs={12} className={styles.emptyRow}>
                  {" "}
                </Grid>
                <Grid item xs={12} className={styles.emptyRow}>
                  {" "}
                </Grid>
                <Grid item xs={12} className={styles.emptyRowAlt2}>
                  {" "}
                </Grid>
                {!secondAccount && (
                  <Grid container item xs={12}>
                    <Grid item xs={12} className={styles.emptySmall}>
                      <div></div>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
            {!secondAccount && receiver ? (
              <>
                <Grid item xs={12}>
                  <div className={`${styles.inp} multi_selector`}>
                    <label htmlFor={"seller"}>Wybierz odbiorcę</label>
                    <CreatableSelect
                      value={invReceiver}
                      className={`${styles.select}`}
                      options={clients.filter(
                        (dep) => dep.value !== buyer?.id_contractor
                      )}
                      onChange={(e) => {
                        setInvReceiver(e);
                        setReceiverCompanyName(e.name);
                        setReceiverStreet(
                          `${e.street ? e.street : ""} ${
                            e.street_no ? e.street_no : ""
                          } ${
                            e.apartment_no !== " " && e.apartment_no ? "/" : ""
                          } ${e.apartment_no ? e.apartment_no : ""}`
                        );
                        setReceiverTaxNum(e.nip);
                        setReceiverCountry(e.country);
                        setReceiverCity(
                          e.zipcode && e.city ? `${e.zipcode} ${e.city}` : ""
                        );
                      }}
                      name="seller"
                      placeholder="Wybierz sprzedawcę..."
                      getOptionValue={(option) => `${option?.label}`}
                      styles={select_styles}
                      menuPortalTarget={document.body}
                    />
                  </div>
                </Grid>
                <InvoiceInput
                  size={12}
                  name={receiverCompanyName}
                  labelText={"Nazwa firmy"}
                  setFunction={(e) => setReceiverCompanyName(e.target.value)}
                  error={error && !receiverCompanyName}
                />
                <InvoiceInput
                  size={12}
                  name={receiverTaxNum}
                  labelText={"NIP"}
                  setFunction={(e) => setReceiverTaxNum(e.target.value)}
                  error={error && !receiverTaxNum}
                />
                <InvoiceInput
                  size={12}
                  name={receiverStreet}
                  labelText={"Ulica"}
                  setFunction={(e) => setReceiverStreet(e.target.value)}
                  error={error && !receiverStreet}
                />
                <InvoiceInput
                  size={12}
                  name={receiverCity}
                  labelText={"Kod pocztowy i miejscowość"}
                  setFunction={(e) => setReceiverCity(e.target.value)}
                  error={error && !receiverCity}
                />
                <InvoiceInput
                  size={12}
                  name={receiverCountry}
                  labelText={"Kraj"}
                  setFunction={(e) => setReceiverCountry(e.target.value)}
                  error={error && !receiverCountry}
                />
              </>
            ) : (
              <></>
            )}
            {secondAccount && receiver ? (
              <>
                <Grid item xs={12}>
                  <div className={`${styles.inp} multi_selector`}>
                    <label htmlFor={"seller"}>Wybierz odbiorcę</label>
                    <CreatableSelect
                      value={invReceiver}
                      className={`${styles.select}`}
                      options={clients.filter(
                        (dep) => dep.value !== buyer?.id_contractor
                      )}
                      onChange={(e) => {
                        setInvReceiver(e);
                        setReceiverCompanyName(e.name);
                        setReceiverStreet(
                          `${e.street ? e.street : ""} ${
                            e.street_no ? e.street_no : ""
                          } ${
                            e.apartment_no !== " " && e.apartment_no ? "/" : ""
                          } ${e.apartment_no ? e.apartment_no : ""}`
                        );
                        setReceiverTaxNum(e.nip);
                        setReceiverCountry(e.country);
                        setReceiverCity(
                          e.zipcode && e.city ? `${e.zipcode} ${e.city}` : ""
                        );
                      }}
                      name="seller"
                      placeholder="Wybierz sprzedawcę..."
                      getOptionValue={(option) => `${option?.label}`}
                      styles={select_styles}
                      menuPortalTarget={document.body}
                    />
                  </div>
                </Grid>
                <InvoiceInput
                  size={12}
                  name={receiverCompanyName}
                  labelText={"Nazwa firmy"}
                  setFunction={(e) => setReceiverCompanyName(e.target.value)}
                  error={error && !receiverCompanyName}
                />
                <InvoiceInput
                  size={12}
                  name={receiverTaxNum}
                  labelText={"NIP"}
                  setFunction={(e) => setReceiverTaxNum(e.target.value)}
                  error={error && !receiverTaxNum}
                />
                <InvoiceInput
                  size={12}
                  name={receiverStreet}
                  labelText={"Ulica"}
                  setFunction={(e) => setReceiverStreet(e.target.value)}
                  error={error && !receiverStreet}
                />
                <InvoiceInput
                  size={12}
                  name={receiverCity}
                  labelText={"Kod pocztowy i miejscowość"}
                  setFunction={(e) => setReceiverCity(e.target.value)}
                  error={error && !receiverCity}
                />
                <InvoiceInput
                  size={12}
                  name={receiverCountry}
                  labelText={"Kraj"}
                  setFunction={(e) => setReceiverCountry(e.target.value)}
                  error={error && !receiverCountry}
                />
                {thirdAccount && (
                  <Grid container>
                    <Grid item xs={12} className={styles.emptyRow}>
                      {" "}
                    </Grid>
                    <Grid item xs={12} className={styles.emptyRow}>
                      {" "}
                    </Grid>
                    <Grid item xs={12} className={styles.emptyRow}>
                      {" "}
                    </Grid>
                    <Grid item xs={12} className={styles.emptyRow}>
                      {" "}
                    </Grid>
                    <Grid item xs={12} className={styles.emptyRow}>
                      {" "}
                    </Grid>
                    <Grid item xs={12} className={styles.emptyRowAlt}>
                      {" "}
                    </Grid>
                  </Grid>
                )}
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid
            className={styles.services}
            container
            item
            xs={12}
            style={{ margin: "30px 20px" }}
          >
            <TableContainer>
              {isCorrection.current && (
                <ServicesTable
                  handleChangeServices={() => {
                    return undefined;
                  }}
                  props={props}
                  isCorrection={isCorrection.current}
                  selectedCurrency={selectedCurrency}
                  propServ={services}
                  buyer={buyer}
                  typeInputValue={props?.state?.typeInput?.value}
                  documentType={documentType}
                  handleChangeCurrency={() => {
                    return undefined;
                  }}
                  handleChangeVat={() => {
                    return undefined;
                  }}
                  servicesOptions={servicesOptions}
                  orders={!buyer ? [] : orders}
                  disabled={true}
                  error={error}
                />
              )}
              <ServicesTable
                handleChangeServices={handleChangeServices}
                props={props}
                selectedCurrency={selectedCurrency}
                propServ={services}
                buyer={buyer}
                isCorrection={isCorrection.current}
                documentType={documentType}
                handleChangeCurrency={handleChangeCurrency}
                handleChangeVat={handleChangeVat}
                servicesOptions={servicesOptions}
                orders={!buyer ? [] : orders}
                error={error}
              />
            </TableContainer>
          </Grid>
          <Grid container item xs={12} style={{ margin: "30px 20px" }}>
            <Grid item xs={6}>
              <Grid item xs={11}>
                {isCorrection.current && (
                  <InvoiceInput
                    size={12}
                    name={correctionTitle}
                    labelText={"Przyczyna korekty"}
                    setFunction={(e) => setCorrectionTitle(e.target.value)}
                    error={error && !correctionTitle}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <TableContainer className={styles.summary}>
                {
                  <SummaryTable
                    props={props}
                    documentType={documentType}
                    handleNewTotalGross={handleNewTotalGross}
                    services={services}
                    exchangeRate={exchangeRate}
                    selectedCurrency={selectedCurrency}
                    vat={[...new Set(vat)]}
                  />
                }
              </TableContainer>
            </Grid>
          </Grid>

          <Grid container item xs={12} style={{ margin: "30px 20px" }}>
            <Grid container item xs={6}>
              {" "}
            </Grid>
            <Grid container item xs={6}>
              <TableContainer className={styles.summary}>
                <Grid item xs={12} className={styles.mB10}>
                  <div className={`${styles.inp} multi_selector`}>
                    <label htmlFor={"seller"}>Sposób zapłaty</label>
                    <Select
                      value={paymentOption}
                      className={`${styles.select}`}
                      options={paymentOptions}
                      onChange={(e) => setPaymentOption(e)}
                      name="paymentOption"
                      placeholder="Wybierz sposób płatności..."
                      getOptionValue={(option) => `${option?.label}`}
                      styles={select_styles}
                      menuPortalTarget={document.body}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} className={styles.mB10}>
                  <div className={`${styles.inp} multi_selector`}>
                    <label htmlFor={"seller"}>Termin zapłaty:</label>
                    <Select
                      value={paymentDeadline}
                      className={`${styles.select}`}
                      options={deadlineOptions}
                      defaultValue={{ value: 30, label: "30 dni" }}
                      onChange={(e) => {
                        setPaymentDeadline(e);
                        setPaymentDeadlineDay(
                          addDays(
                            issueDate,
                            e.value !== undefined ? e.value : 30
                          )
                        );
                      }}
                      name="deadline"
                      placeholder="Wybierz termin płatności..."
                      getOptionValue={(option) => `${option?.label}`}
                      styles={select_styles}
                      menuPortalTarget={document.body}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} className={styles.mB10}>
                  <InvoiceInput
                    size={12}
                    name={description}
                    labelText={"Opis dokumentu"}
                    setFunction={(e) => setDescription(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} className={styles.mB10}>
                  <InvoiceInput
                    size={12}
                    name={advancePayment}
                    labelText={"Zapłacono z góry"}
                    setFunction={(e) => setAdvancePayment(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} className={styles.mB10}>
                  <InvoiceInput
                    size={12}
                    name={personToPickUp}
                    labelText={"Osoba upoważniona do odbioru"}
                    setFunction={(e) => setPersonToPickUp(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} className={styles.mB10}>
                  <InvoiceInput
                    size={12}
                    name={personToIssue}
                    labelText={"Osoba upoważniona do wystawienia"}
                    setFunction={(e) => setPersonToIssue(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={printData}
                        onChange={() =>
                          setPrintData((prevPrintData) => !prevPrintData)
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Dane zlecenia na fakturze"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={prot}
                        onChange={() => setProt((prevProt) => !prevProt)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Drukuj na fakturze 'Zabezpieczone GMP + FSA'"
                  />
                </Grid>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={openDialogSummary}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Zatwierdź fakturę
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              setOpenDialogSummary(false);
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <small style={{ display: "flex", paddingBottom: "5px" }}>
            Zobacz fakturę przed potwierdzeniem, przyciskiem poniżej:
          </small>

          <div className={`loading ${isLoading ? "" : "hide"}`}>
            <ClipLoader
              color={"#ed3c22"}
              loading={isLoading}
              height={3}
              width={"100%"}
              cssOverride={override}
            />
            Ładowanie podglądu faktury..
          </div>
          <iframe
            ref={iframeRef}
            width={"100%"}
            height={"600px"}
            src={`${DBurl}/printInvoice2PDF?id_invoice=${new_id}&output=I#toolbar=0`}
            title="FV"
            className={`iframe ${isLoading ? "hide" : ""}`}
          />

          <Button
            onClick={(e) => printPDF(new_id)}
            className={stylesMod.pdfView}
          >
            <span>
              <FileText />
            </span>
            <span>Podgląd faktury PDF</span>
          </Button>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={BTNstyles.btn}
            onClick={(e) => fetchDeleteInvoice(new_id)}
          >
            Anuluj
          </Button>
          <Button
            variant="contained"
            size="large"
            className={`${BTNstyles.btn} ${BTNstyles.btnGreen}`}
            color="primary"
            onClick={(e) => fetchSaveInvoice(new_id)}
          >
            Zatwierdź i zapisz fakture
          </Button>
        </DialogActions>
      </Dialog>

      <DialogActions>
        <Button
          variant="contained"
          size="large"
          color="primary"
          className={BTNstyles.btn}
          onClick={() => clear()}
        >
          Anuluj
        </Button>
        <Button
          variant="contained"
          size="large"
          className={`${BTNstyles.btn} ${BTNstyles.grn}`}
          onClick={() => {
            if (checkError() || checkServicesError()) {
              setError(true);
              handlePostError(
                "Upewnij się, że wszystkie wymagane pola zostały wypełnione."
              );
              return;
            }
            if (!checkIsPostOK()) {
              handlePostError("Numer faktury jest już zajęty");
              return;
            }
            checkEUVAT(buyerTaxNum);
          }}
        >
          Zapisz
        </Button>
      </DialogActions>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={(e, r) => {
          if (r === "clickaway") {
            return;
          }
          setOpenSnack(false);
        }}
      >
        <Alert
          onClose={(e, r) => {
            if (r === "clickaway") {
              return;
            }
            setOpenSnack(false);
          }}
          severity="success"
        >
          {snackContent}
        </Alert>
      </Snackbar>
      <Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={dialogColor}>
          {dialogContent}
        </Alert>
      </Snackbar>
    </div>
  );
}
