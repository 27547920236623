import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import styles from "../../styles/documents.module.css";
import stylesMod from "../../styles/newOrEdit.module.css";
import Input from "../../components/input";
import { DBurl } from "../../appConfig";
import BTNstyles from "../../styles/button.module.css";
import { Add } from "@material-ui/icons";
import { X } from "react-feather";
import { IconButton } from "@material-ui/core";
import moment from "moment";
import { Switch, FormControlLabel } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { nationOptions } from "containers/documents/nationOptions";
import Select from "react-select";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#129865",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#129865",
  },
  "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
    backgroundColor: "#129865",
  },
}));

const select_styles = {
  control: (base, state) => ({
    ...base,
    border: "1px solid #142f42",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #142f42",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused && "#142f42",
    color: state.isFocused && "#fff",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 99999999 }),
};

export default function AddDriver(props) {
  const [firstname, setFirstname] = useState(props.data.firstname || "");
  const [surname, setSurname] = useState(props.data.surname || "");
  const [email, setEmail] = useState(props.data.email || "");
  const [phoneNr, setPhoneNr] = useState(props.data.phone || "");
  const [currentMedicalExam, setCurrentMedicalExam] = useState(
    props.data.current_medical_exam || ""
  );
  const [nextMedicalExam, setNextMedicalExam] = useState(
    props.data.next_medical_exam || ""
  );
  const [expiryDriversCard, setExpiryDriversCard] = useState(
    props.data.expiry_drivers_card || ""
  );
  const [comment, setComment] = useState(props.data.comment || "");
  const [nationality, setNationality] = useState(null);
  const [category, setCategory] = useState(
    props.data.driving_license_categories || {
      B: false,
      BE: false,
      C: false,
      CE: false,
      D: false,
      DE: false,
    }
  );
  const [permission, setPermission] = useState(
    props.data.drivers_permissions || {
      ADR: false,
      GABARYT: false,
    }
  );

  const [ekd, setEkd] = useState(props.data.ekd);
  const [active, setActive] = useState(props.data.active);
  const [ekdCustomer, SetEkdCustomer] = useState(props.data.ekd);
  const [error, setError] = useState(false);

  const getCustomers = async () => {
    const response = await fetch(`${DBurl}/getCustomers`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      if (json.data[0]?.ekd) {
        SetEkdCustomer(json.data[0].ekd);
      }
    }
  };

  useEffect(() => {
    getCustomers();
    setEkd(props.data.ekd);
    setActive(props.data.active);
    if (props.data.nation) {
      setNationality({
        value: `${props.data.nation}`,
        label: `${props.data.nation}`,
      });
    } else {
      setNationality(null);
    }
    setFirstname(props.data.firstname);
    setSurname(props.data.surname);
    setEmail(props.data.email);
    setPhoneNr(props.data.phone);
    setCurrentMedicalExam(
      props.data?.current_medical_exam?.slice().substring(0, 10)
    );
    setNextMedicalExam(props.data?.next_medical_exam?.slice().substring(0, 10));
    setExpiryDriversCard(
      props.data?.expiry_drivers_card?.slice().substring(0, 10)
    );
    setComment(props.data.comment);
    setCategory({
      B: props.data.driving_license_categories?.includes("B"),
      BE: props.data.driving_license_categories?.includes("BE"),
      C: props.data.driving_license_categories?.includes("C"),
      CE: props.data.driving_license_categories?.includes("CE"),
      D: props.data.driving_license_categories?.includes("D"),
      DE: props.data.driving_license_categories?.includes("DE"),
    });
    setPermission({
      ADR: props.data.drivers_permissions?.includes("ADR"),
      GABARYT: props.data.drivers_permissions?.includes("GABARYT"),
    });
  }, []);

  const handleCheckbox = (name, value) => {
    if (name === "ADR" || name === "GABARYT") {
      let tempPermission = { ...permission };
      tempPermission[name] = value;
      setPermission(tempPermission);
    } else {
      let tempCategory = { ...category };

      tempCategory[name] = value;

      setCategory(tempCategory);
    }
  };

  const setDriver = () => {
    const fetchUrl = `${DBurl}/setDrivers/`;
    const tempPermission = Object.keys(permission).filter(
      (key) => permission[key]
    );
    const tempCategory = Object.keys(category).filter((key) => category[key]);
    const data = {
      active: active,
      comment: comment,
      current_medical_exam: currentMedicalExam,
      driverid: props.driverId || null,
      drivers_permissions: tempPermission,
      driving_license_categories: tempCategory,
      email: email,
      expiry_drivers_card: expiryDriversCard,
      firstname: firstname,
      next_medical_exam: nextMedicalExam,
      phone_number: phoneNr,
      surname: surname,
      id: props.data.id || null,
      allow_mobile: props.data.allow_mobile || false,
      registered: moment(Date.now()).format("YYYY-MM-DD"),
      ekd: ekd
        ? ekd
        : props.user.adm !== null && props.user.adm !== 0
        ? ekd
        : false,
      nation: nationality.value,
    };
    fetch(fetchUrl, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: props.edit ? "update" : "insert",
        data: data,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.data.return === "duplicate") {
          props.setDialog({
            isOpen: true,
            type: "error",
            content: `Konto istnieje w systemie. Prosimy o wybranie innego loginu.`,
          });
          return;
        } else {
          if (data.success && data.data.return !== "duplicate") {
            props.setDialog({
              isOpen: true,
              type: "success",
              content: props.edit ? "Zapisano zmiany" : "Kierowca dodany",
            });
            props.fetchDrivers();
            props.setOpen(false);
          } else {
            props.setDialog({
              isOpen: true,
              type: "error",
              content: `Nie udało się przeprowadzić operacji: ${data.responseDescription}`,
            });
          }
        }
      });
  };

  const handleSave = () => {
    if (!firstname || !surname || !nationality) {
      setError(true);
      props.setDialog({
        isOpen: true,
        type: "error",
        content: "Wypełnij wymagane pola",
      });
    } else {
      setError(false);
      setDriver();
    }
  };

  return (
    <Dialog
      maxWidth="lg"
      open={props.open}
      TransitionComponent={Transition}
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        className={stylesMod.dialogHead}
      >
        {props.edit === true ? "Edytuj kierowcę" : "Dodaj kierowcę"}
        <IconButton
          aria-label="close"
          className={stylesMod.closeButton}
          onClick={() => {
            props.setOpen(false);
          }}
        >
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <div className={stylesMod.inp}>
              <label>Imię</label>
              <Input
                value={firstname}
                error={error && !firstname}
                handleChange={(e) => {
                  setFirstname(e.target.value);
                }}
                name="firstname"
                disabled={!!props.data.deviceid && !!firstname}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={stylesMod.inp}>
              <label>Nazwisko</label>
              <Input
                value={surname}
                error={error && !surname}
                handleChange={(e) => {
                  setSurname(e.target.value);
                }}
                name="surname"
                disabled={!!props.data.deviceid && !!surname}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={stylesMod.inp}>
              <label>Email</label>
              <Input
                value={email}
                handleChange={(e) => {
                  setEmail(e.target.value);
                }}
                name="email"
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={stylesMod.inp}>
              <label>Nr telefonu</label>
              <Input
                value={phoneNr}
                handleChange={(e) => {
                  setPhoneNr(e.target.value);
                }}
                name="phone_number"
                disabled={!!props.data.deviceid && !!phoneNr}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={stylesMod.inp}>
              <label>Ostatnie badanie</label>
              <Input
                value={currentMedicalExam}
                type="date"
                handleChange={(e) => {
                  setCurrentMedicalExam(e.target.value);
                }}
                name="current_medical_exam"
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={stylesMod.inp}>
              <label>Następne badanie</label>
              <Input
                value={nextMedicalExam}
                type="date"
                handleChange={(e) => {
                  setNextMedicalExam(e.target.value);
                }}
                name="next_medical_exam"
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={`${stylesMod.inp} ${stylesMod.start}`}>
              <label>Termin karty kierowcy</label>
              <Input
                value={expiryDriversCard}
                type="date"
                handleChange={(e) => {
                  setExpiryDriversCard(e.target.value);
                }}
                name="expiry_drivers_card"
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={`${stylesMod.checkboxList} ${stylesMod.checkPar}`}>
              <label>Kategorie:</label>
              <div className={stylesMod.checkboxRow}>
                <input
                  type="checkbox"
                  className={stylesMod.check}
                  checked={category.B}
                  onChange={(e) => {
                    handleCheckbox(e.target.name, e.target.checked);
                  }}
                  name="B"
                />
                <label>B</label>
                <input
                  type="checkbox"
                  className={stylesMod.check}
                  checked={category.BE}
                  onChange={(e) => {
                    handleCheckbox(e.target.name, e.target.checked);
                  }}
                  name="BE"
                />
                <label>B+E</label>
                <input
                  type="checkbox"
                  className={stylesMod.check}
                  checked={category.C}
                  onChange={(e) => {
                    handleCheckbox(e.target.name, e.target.checked);
                  }}
                  name="C"
                />
                <label>C</label>
                <input
                  type="checkbox"
                  className={stylesMod.check}
                  checked={category.CE}
                  onChange={(e) => {
                    handleCheckbox(e.target.name, e.target.checked);
                  }}
                  name="CE"
                />
                <label>C+E</label>
                <input
                  type="checkbox"
                  className={stylesMod.check}
                  checked={category.D}
                  onChange={(e) => {
                    handleCheckbox(e.target.name, e.target.checked);
                  }}
                  name="D"
                />
                <label>D</label>
                <input
                  type="checkbox"
                  className={stylesMod.check}
                  checked={category.DE}
                  onChange={(e) => {
                    handleCheckbox(e.target.name, e.target.checked);
                  }}
                  name="DE"
                />
                <label>D+E</label>
              </div>
              <div className={stylesMod.checkboxRow}>
                <input
                  type="checkbox"
                  className={stylesMod.check}
                  checked={permission.ADR}
                  onChange={(e) => {
                    handleCheckbox(e.target.name, e.target.checked);
                  }}
                  name="ADR"
                />
                <label>ADR</label>
                <input
                  type="checkbox"
                  className={stylesMod.check}
                  checked={permission.GABARYT}
                  onChange={(e) => {
                    handleCheckbox(e.target.name, e.target.checked);
                  }}
                  name="GABARYT"
                />
                <label>Gabaryt</label>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={`${stylesMod.inp} ${stylesMod.start}`}>
              <label>Komentarz</label>
              <Input
                value={comment}
                handleChange={(e) => {
                  setComment(e.target.value);
                }}
                name="comment"
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={`${stylesMod.inp} ${stylesMod.start}`}>
              <label htmlFor={"numb"}>Narodowość</label>
              <Select
                value={nationality}
                className={`${stylesMod.select} ${
                  error && !nationality && stylesMod.error
                }`}
                options={nationOptions}
                onChange={(e) => {
                  setNationality(e);
                }}
                name="numb"
                placeholder="Wybierz..."
                getOptionValue={(option) => `${option.label}`}
                styles={select_styles}
                menuPortalTarget={document.body}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            {ekdCustomer === true ? (
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={ekd}
                    onChange={(e) => setEkd(e.target.checked)}
                  />
                }
                label="EKD"
              />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12}>
            {props.user.adm !== null ? (
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={active === 1 ? true : false}
                    onChange={(e) =>
                      setActive(e.target.checked === true ? 1 : 0)
                    }
                  />
                }
                label={active === 1 ? "Aktywny" : "Nieaktywny"}
              />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="large"
          color="primary"
          className={`${BTNstyles.btn} ${BTNstyles.err}`}
          onClick={(e) => {
            props.setOpen(false);
          }}
        >
          Zamknij
        </Button>
        <Button
          variant="contained"
          size="large"
          className={`${BTNstyles.btn} ${BTNstyles.grn}`}
          onClick={handleSave}
          color="primary"
        >
          {props.edit ? "Zapisz" : "Dodaj"}{" "}
          <Add className={BTNstyles.downBtnIco} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
