import React from 'react'

export 	const handleRenderAction = (row) => {


    if (row.what === "ADD_DEF") return "Dodanie kolumny"
    if (row.what === "EDIT_DEF") return "Edycja kolumny"
    if (row.what === "DEL_DEF") return "Usunięcie kolumny"
    if (row.what === "ADD_DEF_VAL" && row.target_values.startsWith('www')) return "Dodanie pliku"
    if (row.what === "ADD_DEF_VAL") return "Dodanie danych"
    if (row.what === "EDIT_DEF_VAL") return "Edycja danych"
    if (row.what === "DEL_DEF_VAL") return "Usunięcie danych"
    if (row.what === "EDIT_CAR_DOC_VAL") return "Edycja danych stałych"
    if (row.what === "EDIT_TRAILER_DOC_VAL") return "Edycja danych stałych"
    if (row.what === "EDIT_DRIVER_DOC_VAL") return "Edycja danych stałych"
    if (row.what === "EDIT_FIRM_DOC_VAL") return "Edycja danych stałych"
    if (row.what === "ADD_CAR_DOC_FILE") return "Dodanie pliku"
    if (row.what === "ADD_TRAILER_DOC_FILE") return "Dodanie pliku"
    if (row.what === "ADD_DRIVER_DOC_FILE") return "Dodanie pliku"
    if (row.what === "ADD_FIRM_DOC_FILE") return "Dodanie pliku"
    if (row.what === "DEL_CAR_DOC_FILE") return "Usunięcie pliku"
    if (row.what === "DEL_TRAILER_DOC_FILE") return "Usunięcie pliku"
    if (row.what === "DEL_DRIVER_DOC_FILE") return "Usunięcie pliku"
    if (row.what === "DEL_FIRM_DOC_FILE") return "Usunięcie pliku"
    if (row.what === "ADD_DEF_VAL_FROM_IMPORT") return "Dodanie danych z pliku"
    if (row.what === "EDIT_DEF_VAL_FROM_IMPORT") return "Edycja danych z pliku"
    if (row.what === "EDIT_CARS_DOC_VAL_FROM_IMPORT") return "Edycja danych z pliku"
    if (row.what === "EDIT_TRAILER_DOC_VAL_FROM_IMPORT") return "Edycja danych z pliku"
    if (row.what === "EDIT_DRIVER_DOC_VAL_FROM_IMPORT") return "Edycja danych z pliku"
    if (row.what === "EDIT_FIRM_DOC_VAL_FROM_IMPORT") return "Edycja danych z pliku"

}

export const handleRenderColumn = (row) => {

    if (row.what === "EDIT_CAR_DOC_VAL" || row.what === 'EDIT_CARS_DOC_VAL_FROM_IMPORT') return [<div><b>Termin</b> badania technicznego</div>,
                                                <div><b>Data</b> ważności ubezpieczenia</div>,
                                                <div><b>Kalibracja</b> tachografu</div>,
                                                <div><b>Umowa</b> użyczenia pojazdu z leasingu</div>,]
    if (row.what === "EDIT_TRAILER_DOC_VAL" || row.what === 'EDIT_TRAILER_DOC_VAL_FROM_IMPORT') return [
                                                <div><b>Termin</b> badania technicznego</div>,
                                                <div><b>Data</b> ważności ubezpieczenia</div>,
                                                <div><b>Data</b> ważności certyfikatu S</div>,
                                                <div><b>Data</b> Ważności Certyfikatu zamknięcia celnego</div>,
                                                <div><b>Data</b> Ważności certyfikatu ATP</div>]
    if (row.what === "EDIT_DRIVER_DOC_VAL" || row.what === 'EDIT_DRIVER_DOC_VAL_FROM_IMPORT') return [
                                                <div><b>Świadectwo</b> kwalifikacji</div>,
                                                <div><b>Formularz</b> A1</div>,
                                                <div><b>Umowa</b> o pracę</div>,
                                                <div><b>Wiza</b></div>,
                                                <div><b>Oświadczenie</b> o powieżeniu pracy</div>,
                                                <div>Zgłoszenia IMI <b>Niemcy</b></div>,
                                                <div>Zgłoszenia IMI <b>Francja</b></div>,
                                                <div>Zgłoszenia IMI <b>Hiszpania</b></div>,
                                                <div>Zgłoszenia IMI <b>Włochy</b></div>,
                                                <div>Zgłoszenia IMI <b>Czechy</b></div>,
                                                <div>Zgłoszenia IMI <b>Austria</b></div>,
                                                <div>Zgłoszenia IMI <b>Niderlandy</b></div>,
                                                <div>Zgłoszenia IMI <b>Belgia</b></div>,
                                                <div><b>Narodowość</b></div>
                                            ]
    if (row.what === "EDIT_FIRM_DOC_VAL" || row.what === 'EDIT_FIRM_DOC_VAL_FROM_IMPORT') return [<div><b>Certyfikat</b> kompetencji zawodowych</div>,
                                                <div><b>Licencja</b> na transport krajowy</div>,
                                                <div><b>Licencja</b> na transport międzynarodowy</div>]
    else return []

}

export const handleRenderCategory = (row) => {
    if (row.category === "car" || row.what.includes("CAR")) return "Pojazdy"
    if (row.category === "driver" || row.what.includes("DRIVER")) return "Kierowcy"
    if (row.category === "trailer" || row.what.includes("TRAILER")) return "Naczepy"
    if (row.category === "firm" || row.what.includes("FIRM")) return "Firma"
}

export const handleRenderValues = (row) => {
    const baseValues = row.target_values.split(",").map(value => <div>{value ? value : 'brak danych'}</div>)

    if (row.what === "ADD_DEF_VAL" && row.target_values.startsWith('www')) return `${row.target_values.split("---")[1]}`
    if (row.target_values.startsWith('www')) return `${row.target_values.split("---")[1]}`
    if (row.what === "DEL_DEF") return "-----"
    else return baseValues

}