import React, { useState, useRef, useEffect } from "react";
import { DBurl } from "../appConfig";
import styles from "./addressSearch.module.css";
import stylesVersion from "../styles/version_s.module.css";
import Input from "components/input";

export function AddressSearch(props) {
  const timerRef = useRef(null);
  const [value, setValue] = useState(props.value || "");
  const [options, setOptions] = useState([]);

  const fetchData = async (term) => {
    const response = await fetch(`${DBurl}/search?term=${term}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.token,
      },
    });

    const json = await response.json();
    if (json.success) {
      if (json.data) {
        const result = await json.data.map((e) => {
          return {
            label: e.display_name,
            value: e.place_id,
            ...e,
          };
        });
        await setOptions(result);
      }
    }
  };

  const fetchLonLat = async (lon, lat) => {
    const response = await fetch(`${DBurl}/reverse?lon=${lon}&lat=${lat}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.token,
      },
    });
    const json = await response.json();
    if (json.success) {
      const result = {
        address_display_name: json.data.display_name,
        country: json.data.address.country,
        city: json.data.address.city,
        street: json.data.address.road,
        street_no: json.data.address.house_number,
        zipcode: json.data.address.postcode,
        longitude: json.data.lon,
        latitude: json.data.lat,
        country_code: json.data.address.country_code,
        district: json.data.address.state,
      };
      props.setAddress(result);
    }
  };

  useEffect(() => {
    if (props.newValue) {
      setValue(props.newValue.address_display_name);
    }
    return () => setValue();
  }, [props.newValue]);

  return (
    <>
      <div className={styles.box}>
        <label className={`${props.user_s ? stylesVersion.onlyfull : ""}`}>
          Adres*
        </label>
        <Input
          className={`${styles.input} ${props.dense && styles.dense}  ${
            props.user_s ? stylesVersion.onlyfull : ""
          }`}
          disabled={props.user_s || props.isDisabled || props.isPreview}
          autoComplete="off"
          name="option"
          value={value}
          placeholder={"Wpisz adres.."}
          handleChange={(e) => {
            setValue(e.target.value);
            clearTimeout(timerRef.current);
            let temp = e.target.value;
            timerRef.current = setTimeout(() => {
              fetchData(temp);
            }, 300);
          }}
          list="data"
          error={props.error}
        />
        {options.length > 0 ? (
          <div className={`${styles.list} ${props.dense && styles.dense}`}>
            {options.map((e, i) => (
              <div
                className={styles.element}
                value={e.value}
                key={i}
                onClick={(ev) => {
                  setValue(e.label);
                  fetchLonLat(e.lon, e.lat);
                  setOptions([]);
                }}
              >
                {e.label}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </>
  );
}
