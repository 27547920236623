import React, { Component } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import styles from './map.module.css';
import stylesMod from '../../styles/newOrEdit.module.css'
import { Button, Grid } from '@material-ui/core';
import { X } from 'react-feather';
import Select from 'react-select'
import BTNstyles from '../../styles/button.module.css'


export class MyMarkerE100 extends Component{

	constructor(props) {
		super(props);

		this.state = {
            selectedFuelCard: null,
            nearestVehicles: []
		}

	}

	findNearestVehicle = (latA, lgnA, latB, lgnB) => {
        const earthRadius = 6371; // promień Ziemi w km
        const degLat = this.degToRad(latB - latA);
        const degLng = this.degToRad(lgnB - lgnA);
        const a =
          Math.sin(degLat / 2) * Math.sin(degLat / 2) +
          Math.cos(this.degToRad(latA)) * Math.cos(this.degToRad(latB)) *
          Math.sin(degLng / 2) * Math.sin(degLng / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = earthRadius * c;
      
        return distance;
	}
    degToRad = (deg) => {
        return deg * (Math.PI / 180);
    }
    arrowIconRight = () => {
        return (
            <svg stroke="currentColor" fill="currentColor" strokeWidth="1" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <path d="M192 128l128 128-128 128z"></path>
            </svg>
        )
    }

    render = () => {


        const fuleCardList = this.props.fuelCardList.map((o,i)=>{
            return {value: o.id_fuel_card, label: o.id_fuel_card}
        }) || []

        console.log("->",this.props.type_e100)

        const content = (
            <div className={styles.scrollFixGMAPE100}>
            <Grid className={styles.modalE100Header} container>
                <Grid item xs={11}>
                    <Grid container>
                        <Grid item>
                            <img className={styles.modalE100Logo} src={require(`../../img/icons/e100_${this.props.type_e100}.png`)} alt="icon"></img>
                        </Grid>
                        <Grid className={styles.modalE100Name} item>
                            {this.props.name}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={1}>
                    <button className={styles.modalE100CloseButton} onClick={(e) => this.props.callbackOnClose(this.props.id, this.props.index, e)}>
                        <X/>
                    </button>
                </Grid>
            </Grid>
            
            <Grid className={styles.modalE100Body} container>
                <Grid item xs={12}>
                    <h3>Ceny paliw (Polski złoty):</h3>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        {this.props.fuel.map((o,i)=>{

                            let bgColor = '#fff';

                            switch(o.type){
                                case 1: 
                                    bgColor = "#e7eec0"
                                    break
                                case 2: 
                                    bgColor = "#e8e8e8"
                                    break     
                                case 3: 
                                    bgColor = "#dce3ea"
                                    break        
                                default:    
                                    bgColor = "#dce3ea"
                                    break
                            }

                            return (
                                <Grid key={i} item style={{maxWidth: 100 / this.props.fuel.length + '%', width: '100%', textAlign: 'center', padding: '0 4px'}}>
                                    <Grid container>
                                        <Grid item xs={12} className={styles.fuelName}>{o.name}</Grid>
                                        <Grid item xs={12}>
                                            <div className={styles.circlePrice} style={{width: '100%', height: 0, paddingBottom: '100%', position: 'relative'}}>
                                                <p style={{background: bgColor}}>{o.price}</p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>

                <span className={styles.hr}></span>

                <Grid item xs={12}>
                    <h3>Ostatnie transakcje:</h3>
                </Grid>
                <Grid item xs={12}>
                    {this.props.last_transaction.map((o,i)=>{
                        if(i < 3) {

                            let bgColor = '#fff';

                            switch(o.type){
                                case 1: 
                                    bgColor = "#e7eec0"
                                    break
                                case 2: 
                                    bgColor = "#e8e8e8"
                                    break     
                                case 3: 
                                    bgColor = "#dce3ea"
                                    break        
                                default:    
                                    bgColor = "#dce3ea"
                                    break
                            }

                            return (
                                <Grid key={i} item xs={12} className={styles.gridRow}>
                                    <Grid container>
                                        <Grid item xs={8} className={styles.left}>{o.fuel_card_number}</Grid>
                                        <Grid item xs={2} className={styles.right}>
                                            <span className={styles.fuelNameLastTransaction} style={{background: bgColor}}>{o.name}</span>
                                        </Grid>
                                        <Grid item xs={2} className={styles.right}>{o.quantity} L</Grid>
                                    </Grid>
                                </Grid>
                            )
                        }
                    })}
                </Grid>

                <span className={styles.hr}></span>

                <Grid item xs={12}>
                    <h3>Limit dla karty paliwowej (w litrach):</h3>
                </Grid>
                <Grid item xs={12}>
                    <Select
                        menuPortalTarget={document.body}
                        value={fuleCardList && fuleCardList.find( ({ value }) => value === String(this.state.selectedFuelCard))}
                        className={stylesMod.select}
                        options={fuleCardList}
                        onChange={(e) => {
                            this.setState({
                                selectedFuelCard: e
                            });
                        }}
                        name="Car"
                        placeholder="Wybierz..."
                        styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                            control: (base, state) => ({
                                ...base,
                                border: '1px solid #142f42',
                                marginTop: '6px',
                                marginBottom: '10px',
                                boxShadow: 'none',
                                '&:hover': {
                                    border: '1px solid #142f42',
                                }
                            }),
                            option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isFocused && "#142f42",
                                color: state.isFocused && "#fff",
                                fontSize: '11px'
                            })

                        }}
                    />
                </Grid> 
                <Grid item xs={12}>
                    <Grid container>
                        {this.props.limits.map((o,i)=>{

                            let bgColor = '#fff';

                            switch(o.type){
                                case 1: 
                                    bgColor = "#e7eec0"
                                    break
                                case 2: 
                                    bgColor = "#e8e8e8"
                                    break     
                                case 3: 
                                    bgColor = "#dce3ea"
                                    break        
                                default:    
                                    bgColor = "#dce3ea"
                                    break
                            }

                            return (
                                <Grid key={i} item style={{maxWidth: 100 / this.props.fuel.length + '%', width: '100%', textAlign: 'center', padding: '0 4px'}}>
                                    <Grid container>
                                        <Grid item xs={12} className={styles.fuelName}>{o.name}</Grid>
                                        <Grid item xs={12}>
                                            <div className={styles.circlePrice} style={{width: '100%', height: 0, paddingBottom: '100%', position: 'relative'}}>
                                                <p style={{background: bgColor}}>{o.limit}</p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>

                <span className={styles.hr}></span>

                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        size="small"
                        className={`${styles.btnSearch}`}
                        onClick={(e) => {
                            let tempTable = []
                            for(let i = 0; this.props.carList.length > i; i++){
                                if(this.props.carList[i].position !== null){

                                    const newItem = {
                                        name: this.props.carList[i].name,
                                        id:  this.props.carList[i].id,
                                        distance: this.findNearestVehicle(this.props.carList[i].position.lat, this.props.carList[i].position.lng, this.props.position.lat, this.props.position.lng).toFixed(1)
                                    }

                                    tempTable.push(newItem)

                                    tempTable.sort((a, b) => a.distance - b.distance);

                                }
                            }
                            this.setState({
                                nearestVehicles: tempTable
                            });



                        }}>
                        Znajdź najbliższe pojazdy
                    </Button>
                </Grid>
                <Grid className={styles.distanceList} item xs={12}>
                    {this.state.nearestVehicles.map((o,i)=>{
                        if(i < 3){
                            return (
                                <Grid key={i} container className={styles.listRow}>
                                    <Grid item xs={2} className={styles.right}><b>{o.distance}</b> km</Grid>
                                    <Grid item xs={2} className={styles.center}>
                                        {this.arrowIconRight()}
                                    </Grid>
                                    <Grid item xs={8} className={styles.left}>{o.name}</Grid>
                                </Grid>
                            )
                        }

                    })}
                </Grid>

            </Grid>

        </div>
        )

        return (
            <div>
                <Marker 
                    key = {this.props.id}
                    position = {this.props.position} 
                    title = {this.props.className}
                    icon = {this.props.icon}
                    clusterer = {this.props.clusterer}
                    onLoad = {(e) => this.props.callbackOnLoadMarker(e)}
                    onClick = {(e) => this.props.callbackOnClickMarker(this.props.id, this.props.index, e)} 
                />
                <InfoWindow 
                    key = {this.props.id+1}
                    name = {this.props.type_e100}
                    position = {this.props.position} 
                    onLoad = {(e) => this.props.callbackOnLoadInfoWindow(e)}
                    onDomReady = {(e) => this.props.callbackOnDomReadyInfoWindow(e)}
                    >
                    <div className={this.props.type_e100}>
                        {content}
                    </div>
                </InfoWindow>
            </div>
        )}

}


