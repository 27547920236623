import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import {
  DBurl,
  AppName,
  ContactPhone,
  ContactGuard,
  ContactEmail2,
} from "appConfig";
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListItemIcon,
  Button,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import {
  Users,
  Edit2,
  Truck,
  BarChart2,
  MessageSquare,
  Settings,
  Clock,
  File,
  DollarSign,
} from "react-feather";
import ChangeLog from "components/ChangeLog";
import accountingIcon from "@iconify/icons-map/accounting";
// import Logo from "img/logo.svg";
import { versions } from "versions";
import prom_style from "components/promotion.module.css";
import { withStyles } from "@material-ui/core/styles";
import styles from "./sidebar.module.css";

const Logo = `/brand/${process.env.REACT_APP_NAME}/img/logo.svg`;

const useStyles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 200,
  },
});

const Sidebar = ({
  classes,
  tabs,
  addTab,
  changeTab,
  isPromotionUser,
  permissions_ids,
  adm_p,
  user_s,
  setOpenVideo,
  e100,
}) => {
  const [state, setState] = useState({
    open0: false,
    open1: false,
    open2: false,
    open3: false,
    open4: false,
    open5: false,
    open6: false,
    open7: false,
    open8: false,
    open9: false,
  });
  const [isChangeLogOpen, setIsChangeLogOpen] = useState(false);

  const handleClick = (open) => {
    setState({
      [open]: state[open] ? false : true,
    });
  };

  const itemClass = {
    open0: state.open0 ? styles.itemOpen : null,
    open1: state.open1 ? styles.itemOpen : null,
    open2: state.open2 ? styles.itemOpen : null,
    open3: state.open3 ? styles.itemOpen : null,
    open4: state.open4 ? styles.itemOpen : null,
    open5: state.open5 ? styles.itemOpen : null,
    open6: state.open6 ? styles.itemOpen : null,
    open7: state.open7 ? styles.itemOpen : null,
    open8: state.open8 ? styles.itemOpen : null,
    open9: state.open9 ? styles.itemOpen : null,
  };

  const clickActions = (name, added) => {
    if (tabs.find((p) => p.componentName === name) === undefined) {
      if (added) {
        addTab(added);
      } else {
        addTab(name);
      }
    } else {
      let tab = tabs.filter((o) => o.componentName === name);
      let tabId = tab[0].tabID;
      changeTab(tabId);
    }
  };

  const checkForBeta = (url) => {
    const index = url.indexOf("://");
    if (index !== -1) {
      const checkBeta = url.substring(index + 3, index + 7);
      return checkBeta;
    }
    return false;
  };

  const openChangeLog = () => {
    if (checkForBeta(DBurl) === "beta") {
      setIsChangeLogOpen(true);
    }
  };

  const isAdmin = useMemo(() => {
    if (
      adm_p === 999 ||
      adm_p === 9 ||
      (adm_p === 1 && user_s === false) ||
      (adm_p === null && user_s === false)
    ) {
      return true;
    }

    return false;
  }, [adm_p, user_s]);

  const isAdminAndUser_s = useMemo(() => {
    if (
      adm_p === 999 ||
      adm_p === 9 ||
      (adm_p === 1 && user_s === true) ||
      (adm_p === null && user_s === true)
    ) {
      return true;
    }

    return false;
  }, [adm_p, user_s]);

  const menu = [
    {
      label: "Baza klientów",
      icon: <Users />,
      collapse: state.open1,
      className: itemClass.open1,
      open: "open1",
      disabled:
        permissions_ids?.includes(2) ||
        permissions_ids?.includes(3) ||
        permissions_ids?.includes(4) ||
        permissions_ids?.includes(5),
      submenu: [
        {
          action: "ClientList",
          url: "/client-list",
          label: "Lista klientów",
          disabled: !permissions_ids?.includes(2),
        },
        {
          action: "AddClient",
          url: "/client-add",
          label: "Dodaj klienta",
          disabled: !permissions_ids?.includes(3),
        },
        {
          action: "Warehouse",
          url: "/warehouse",
          label: "Magazyny",
          disabled: !permissions_ids?.includes(4),
        },
        {
          action: "Goods",
          url: "/goods",
          label: "Towary",
          disabled: !permissions_ids?.includes(5),
        },
      ],
    },
    {
      label: "Zlecenia",
      icon: <Edit2 />,
      collapse: state.open2,
      className: itemClass.open2,
      open: "open2",
      disabled:
        permissions_ids?.includes(7) ||
        permissions_ids?.includes(6) ||
        permissions_ids?.includes(8),
      submenu: [
        {
          action: "newOrder",
          url: "/order-add",
          label: "Stwórz zlecenie",
          disabled: !permissions_ids?.includes(7),
        },
        {
          action: "newOrderSped",
          url: "/order-sped-add",
          label: "Zlecenie spedycyjne",
          disabled: !permissions_ids?.includes(7),
        },
        {
          action: "OrderList",
          url: "/order-list",
          label: "Lista zleceń",
          disabled: !permissions_ids?.includes(6),
          condition: isAdmin,
        },
        {
          action: "Tags",
          url: "/tags-list",
          label: "Tagi",
          disabled: !permissions_ids?.includes(6),
          condition: isAdmin,
        },
        {
          action: "DriverNotes",
          url: "/driver-notes",
          label: "Notatki dla kierowców",
          disabled: !permissions_ids?.includes(6),
          condition: isAdmin,
        },
        {
          action: "OrderList_S",
          url: "/order-list-s",
          label: "Lista zleceń",
          disabled: !permissions_ids?.includes(6),
          condition: isAdminAndUser_s,
          sign_s: true,
        },
        {
          action: "TimeTable",
          url: "/timetable",
          label: "Harmonogram",
          disabled: !permissions_ids?.includes(8),
          condition: isAdmin,
        },
        {
          action: "TimeTable_S",
          url: "/timetable_S",
          label: "Harmonogram",
          disabled: !permissions_ids?.includes(8),
          condition: isAdminAndUser_s,
          sign_s: true,
        },
        {
          action: "OrderActions",
          url: "/order-actions",
          label: "Przepinka/Przesiadka",
          disabled: !permissions_ids?.includes(6),
          condition: isAdmin,
        },
        {
          action: "BorderCrossings",
          url: "/border-crossings",
          label: "Przejścia graniczne",
          disabled: !permissions_ids?.includes(6),
          condition: isAdmin,
        },
        {
          action: "FavouriteRoutes",
          url: "/favourite-routes",
          label: "Trasy",
          disabled: !permissions_ids?.includes(6),
          condition: isAdmin,
        },
      ],
    },
    {
      label: "Flota",
      icon: <Truck />,
      collapse: state.open3,
      className: itemClass.open3,
      open: "open3",
      disabled:
        permissions_ids?.includes(10) ||
        permissions_ids?.includes(11) ||
        permissions_ids?.includes(9) ||
        permissions_ids?.includes(12) ||
        permissions_ids?.includes(13),
      submenu: [
        {
          action: "Map",
          url: "/map",
          label: "Mapa",
          disabled: !permissions_ids?.includes(9),
        },
        {
          action: "TransportSet",
          url: "/transportSet",
          label: "Zestaw Transportowy",
          disabled: !permissions_ids?.includes(12),
          condition: isAdmin,
        },
        {
          action: "Fleet",
          action2: "FleetState",
          url: "/fleet-state",
          label: "Stan floty",
          disabled: !permissions_ids?.includes(11),
          condition: user_s === false || user_s === null,
        },
        {
          action: "Fleet_S",
          action2: "FleetState_S",
          url: "/fleet-state-s",
          label: "Stan floty",
          disabled: !permissions_ids?.includes(11),
          condition: isAdminAndUser_s,
          sign_s: true,
        },
        {
          action: "Exploitation",
          url: "/exploitation",
          label: "Eksploatacja",
          disabled: !permissions_ids?.includes(10),
        },
        {
          action: "WorkTime",
          url: "/work-time",
          label: "Czas pracy",
          disabled: !permissions_ids?.includes(13),
        },
        {
          action: "FuelCards",
          url: "/fuelCards",
          label: "Karty paliwowe",
          disabled: !permissions_ids?.includes(13),
          condition: e100,
        },
        {
          action: "NoOrderStatusHistory",
          url: "/NoOrderStatusHistory",
          label: "Historia statusów bez zleceń",
          disabled: !permissions_ids?.includes(13),
        },
      ],
    },
    {
      label: "Księgowość",
      icon: <Icon icon={accountingIcon} />,
      collapse: state.open4,
      className: itemClass.open4,
      open: "open4",
      disabled:
        permissions_ids?.includes(14) ||
        permissions_ids?.includes(15) ||
        permissions_ids?.includes(16) ||
        permissions_ids?.includes(17) ||
        permissions_ids?.includes(18) ||
        permissions_ids?.includes(19) ||
        permissions_ids?.includes(20),
      submenu: [
        {
          action: "InvoiceAddNew",
          url: "/invoice-add",
          label: "Wystaw fakturę",
          disabled: !permissions_ids?.includes(14),
        },
        {
          action: "ExchangeRates",
          url: "/exchange-rates",
          label: "Tabela kursów walut",
          disabled: false,
        },
        {
          action: "InvoiceList",
          url: "/invoice-list",
          label: "Lista faktur",
          disabled: !permissions_ids?.includes(15),
        },
        {
          action: "Vindication",
          url: "/vindication",
          label: "Windykacja",
          disabled: !permissions_ids?.includes(15),
        },
        {
          action: "InvoiceAdd_S",
          url: "/invoice-add-s",
          label: "Wystaw fakturę",
          disabled: !permissions_ids?.includes(14),
          condition: isAdminAndUser_s,
          sign_s: true,
        },
        {
          action: "InvoiceList_S",
          url: "/invoice-list-s",
          label: "Lista faktur",
          disabled: !permissions_ids?.includes(15),
          condition: isAdminAndUser_s,
          sign_s: true,
        },
        {
          action: "Contractors",
          url: "/contractors",
          label: "Kontrahenci",
          disabled: !permissions_ids?.includes(16),
        },
        {
          action: "CostsList",
          url: "/cost-list",
          label: "Koszty",
          disabled: !permissions_ids?.includes(17),
        },
        {
          action: "PurchaseList",
          url: "/purchase-list",
          label: "Zakupy",
          disabled: !permissions_ids?.includes(18),
        },
        {
          action: "Departments",
          url: "/departments",
          label: "Oddziały",
          disabled: !permissions_ids?.includes(19),
        },
        {
          action: "AccountSettings",
          url: "/account_settings",
          label: "Ustawienia księgowe",
          disabled: !permissions_ids?.includes(20),
        },
        {
          action: "InvoiceTags",
          url: "/invoice_tags",
          label: "Tagi do faktur",
          disabled: !permissions_ids?.includes(20),
        },
      ],
    },
    {
      label: "Dokumenty",
      icon: <File />,
      collapse: state.open8,
      className: itemClass.open8,
      open: "open8",
      disabled: permissions_ids?.includes(21),
      submenu: [
        {
          action: "VehiclesDocs",
          url: "/VehiclesDocs",
          label: "Pojazdy",
          disabled: !permissions_ids?.includes(10),
          condition: isAdmin,
        },
        {
          action: "DriversDocs",
          url: "/DriversDocs",
          label: "Kierowcy",
          disabled: !permissions_ids?.includes(10),
          condition: isAdmin,
        },
        {
          action: "TrailersDocs",
          url: "/TrailersDocs",
          label: "Naczepy",
          disabled: !permissions_ids?.includes(10),
        },
        {
          action: "CostsList",
          url: "/cost-list",
          label: "Koszty",
          disabled: !permissions_ids?.includes(10),
        },
        {
          action: "CompanyDocs",
          url: "/CompanyDocs",
          label: "Firma",
          disabled: !permissions_ids?.includes(10),
        },
        {
          action: "DeadlinesDocs",
          url: "/DeadlinesDocs",
          label: "Terminy",
          disabled: !permissions_ids?.includes(10),
        },
        {
          action: "ChangesHistory",
          url: "/ChangesHistory",
          label: "Historia zmian",
          disabled: !permissions_ids?.includes(10),
          condition: adm_p >= 1,
        },
      ],
    },
    {
      label: "Raporty",
      icon: <BarChart2 />,
      collapse: state.open5,
      className: itemClass.open5,
      open: "open5",
      disabled: permissions_ids?.includes(21),
      submenu: [
        {
          action: "LoadingUnloading_S",
          url: "/LoadingUnloading_S",
          label: "Załadunek/Rozładunek",
          disabled: !permissions_ids?.includes(10),
          condition: isAdminAndUser_s,
          sign_s: true,
        },
        {
          action: "ForeignEpisodes",
          url: "/ForeignEpisodes",
          label: "Odcinki zagraniczne",
          disabled: !permissions_ids?.includes(10),
        },
        {
          action: "CiclesReports",
          url: "/CiclesReports",
          label: "Raporty cykliczne",
          disabled: !permissions_ids?.includes(10),
        },
        {
          action: "RaportEKD",
          url: "/RaportEKD",
          label: "Raport EKD",
          disabled: !permissions_ids?.includes(10),
        },
        {
          action: "FinishedOrders",
          url: "/OrdersRaport",
          label: "Raport realizacji zleceń",
          disabled: !permissions_ids?.includes(10),
        },
        {
          action: "DriversActivity",
          url: "/DriversActivityRaport",
          label: "Raport aktywności kierowców",
          disabled: !permissions_ids?.includes(10),
        },
      ],
    },
    {
      label: "Koszty",
      icon: <DollarSign />,
      collapse: state.open9,
      className: itemClass.open9,
      open: "open9",
      disabled: permissions_ids?.includes(21),
      submenu: [
        {
          action: "CostsImport",
          url: "/costs-import",
          label: "Karty Paliwowe",
          disabled: !permissions_ids?.includes(2),
        },
        {
          action: "DriversSettlement",
          url: "/drivers-settlement",
          label: "Rozliczenia kierowców",
          disabled: !permissions_ids?.includes(2),
        },
        {
          action: "FleetCosts",
          url: "/fleet-costs",
          label: "Koszty utrzymania floty",
          disabled: !permissions_ids?.includes(2),
        },
        {
          action: "FleetCostsReport",
          url: "/fleet-costs-report",
          label: "Raport kosztów utrzymania floty",
          disabled: !permissions_ids?.includes(2),
        },
        {
          action: "FleetCostsReportsConfirmed",
          url: "/fleet-costs-reports-confirmed",
          label: "Koszty floty - raporty zatwierdzone",
          disabled: !permissions_ids?.includes(2),
        },
      ],
    },
    {
      label: "Komunikacja",
      icon: <MessageSquare />,
      className: itemClass.open6,
      open: "open6",
      disabled: permissions_ids?.includes(22),
      action: "Communication",
      url: "/communication",
    },
    {
      label: "Konfiguracja",
      icon: <Settings />,
      className: itemClass.open7,
      collapse: state.open7,
      open: "open7",
      disabled:
        permissions_ids?.includes(23) ||
        permissions_ids?.includes(24) ||
        permissions_ids?.includes(25) ||
        permissions_ids?.includes(26) ||
        permissions_ids?.includes(27) ||
        permissions_ids?.includes(28),
      submenu: [
        {
          action: "Drivers",
          url: "/drivers",
          label: "Kierowcy",
          disabled: !permissions_ids?.includes(23),
        },
        {
          action: "Vehicles",
          url: "/vehicles",
          label: "Pojazdy",
          disabled: !permissions_ids?.includes(24),
        },
        {
          action: "Trailers",
          url: "/trailers",
          label: "Naczepy",
          disabled: !permissions_ids?.includes(25),
          condition: user_s === false || user_s === null,
        },
        {
          action: "UsersList",
          url: "/UsersList",
          label: "Użytkownicy",
          disabled: !permissions_ids?.includes(26),
        },
        {
          action: "CompanyList",
          url: "/companyList",
          label: "Firmy",
          disabled: !permissions_ids?.includes(27),
        },
        {
          action: "clientList",
          action2: "OparatorsList",
          url: "/clientList",
          label: "Operatorzy",
          disabled: !permissions_ids?.includes(28),
        },
        {
          action: "clientListSA",
          action2: "OparatorsListSA",
          url: "/clientListSA",
          label: "Operatorzy SA",
          disabled: !permissions_ids?.includes(28),
          condition: adm_p === 999,
        },
      ],
    },
  ];

  return (
    <>
      <div className={styles.parent}>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={`${classes.root} slidebar`}
        >
          <h2 className={styles.logo}>
            <img src={Logo} alt={`${AppName} TMS`} />
          </h2>
          <div
            id="slide-box"
            className={
              isPromotionUser?.active ? styles.navBox_with_promo : styles.navBox
            }
          >
            {menu.map((item, index) => (
              <div key={index}>
                <ListItem
                  button
                  onClick={() => {
                    item.action
                      ? clickActions(item.action)
                      : handleClick(item.open);
                  }}
                  className={item.className}
                  disabled={!item.disabled}
                  to={item.url && item.url}
                >
                  <ListItemIcon className={styles.icon}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText className={styles.text} primary={item.label} />
                  {!item.action ? (
                    item.collapse ? (
                      <ExpandLess className={styles.whiteFill} />
                    ) : (
                      <ExpandMore className={styles.whiteFill} />
                    )
                  ) : null}
                </ListItem>
                <Collapse
                  in={item.collapse}
                  timeout="auto"
                  unmountOnExit
                  className={styles.callapse}
                >
                  {item.submenu && (
                    <List component="div" disablePadding>
                      {item.submenu.map((subItem, index) => {
                        if (
                          subItem.condition !== undefined
                            ? subItem.condition
                            : true
                        )
                          return (
                            <ListItem
                              button
                              key={index}
                              className={styles.nestedText}
                              onClick={() => {
                                clickActions(subItem.action, subItem.action2);
                              }}
                              component={Link}
                              to={subItem.url}
                              disabled={subItem.disabled}
                            >
                              <ListItemText primary={subItem.label} />
                              {subItem.sign_s && (
                                <div className="sign_s">S</div>
                              )}
                            </ListItem>
                          );
                      })}
                    </List>
                  )}
                </Collapse>
              </div>
            ))}
          </div>
          <div
            className={
              isPromotionUser.active
                ? styles.version_with_promo
                : styles.version
            }
          >
            <div className={prom_style.end_time}>
              <div>
                {AppName === "Logisat" && (
                  <Button onClick={(e) => setOpenVideo(true)}>
                    Zobacz Wideoinstrukcję TMS
                  </Button>
                )}
                <div>Kontakt do Opiekuna:</div>
                <div
                  className={prom_style.value}
                  style={{ marginBottom: "5px" }}
                >
                  <a href={`tel:${ContactPhone}`}>{ContactPhone} </a>
                  <a href={`mailto:${ContactEmail2}`}>{ContactEmail2} </a>
                </div>
                {isPromotionUser?.active && (
                  <div className={prom_style.dataEnd}>
                    <div style={{ display: "flex" }}>
                      <Clock />
                    </div>
                    <div>
                      <div>Konto ważne do:</div>
                      <div className={prom_style.value}>
                        {isPromotionUser?.end}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div onClick={openChangeLog}>v{versions[0].version}</div>
          </div>
        </List>
      </div>
      <ChangeLog
        isChangeLogOpen={isChangeLogOpen}
        setIsChangeLogOpen={setIsChangeLogOpen}
      />
    </>
  );
};

export default withStyles(useStyles)(Sidebar);
