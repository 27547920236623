import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Input from "../../components/input";
import Title from "../../components/title";
import SubTitle from "../../components/subTitle";
import styles from "../table.module.css";
import RowsPerPage from "../../components/RowsPerPage";
import Pagination from "../../components/pagination";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Snackbar,
  Checkbox,
  Button,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { DBurl } from "../../appConfig";
import IcoBtn from "../../components/buttons/icoBtn";
import moment from "moment";
import CiclicDialog from "../../components/CiclicDialog";
import { File } from "react-feather";
import BTN from "../../components/buttons/btnStyles.module.css";

function descendingComparator(a, b, orderBy) {
  if (orderBy === "refueling length") {
    return ((!!b.refuelings && !b.refuelings[0].brak && b.refuelings?.length) ||
      0) <
      ((!!a.refuelings && !a.refuelings[0].brak && a.refuelings?.length) || 0)
      ? -1
      : ((!!b.refuelings && !b.refuelings[0].brak && b.refuelings?.length) ||
          0) >
        ((!!a.refuelings && !a.refuelings[0].brak && a.refuelings?.length) || 0)
      ? 1
      : 0;
  }
  if (orderBy === "refueling add") {
    return (!!b.refuelings &&
      !b.refuelings[0].brak &&
      countFuel(b.refuelings)) ||
      0 <
        (!!a.refuelings && !a.refuelings[0].brak && countFuel(a.refuelings)) ||
      0
      ? -1
      : (!!b.refuelings && !b.refuelings[0].brak && countFuel(b.refuelings)) ||
        0 >
          (!!a.refuelings &&
            !a.refuelings[0].brak &&
            countFuel(a.refuelings)) ||
        0
      ? 1
      : (!!b.refuelings && !b.refuelings[0].brak && countFuel(b.refuelings)) ||
        0 ===
          (!!a.refuelings && !a.refuelings[0].brak && countFuel(a.refuelings)
            ? 0
            : -1);
  }

  let sortB;
  let sortA;
  if (!!b && typeof b[orderBy] === "string") {
    sortB = b[orderBy].toLowerCase();
  } else {
    sortB = b;
  }
  if (!!a && typeof a[orderBy] === "string") {
    sortA = a[orderBy].toLowerCase();
  } else {
    sortA = a;
  }
  if (sortB < sortA) {
    return -1;
  }
  if (sortB > sortA) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const countFuel = (rows) => {
  let result = 0;
  rows && rows.forEach((row) => (result += row.fuel));
  return result;
};

const headCells = [
  { id: "Lp", alignRight: false, disablePadding: false, label: "Lp." },
  {
    id: "fromData",
    alignRight: false,
    disablePadding: false,
    label: "Data od",
  },
  { id: "toData", alignRight: false, disablePadding: false, label: "Data do" },
  { alignRight: false, disablePadding: false, label: "" },
];

function EnhancedTableHead(props) {
  const { styles } = props;

  return (
    <>
      <TableHead className={styles.tabHead}>
        <TableRow>
          <TableCell
            align={"left"}
            padding={"default"}
            className={styles.headCellSmall}
            style={{ padding: "10px 20px", fontSize: "11px", width: "100px" }}
          >
            {"Lp."}
          </TableCell>
          <TableCell
            align={"left"}
            padding={"default"}
            className={styles.headCellSmall}
            style={{ padding: "10px 20px", fontSize: "11px", width: "300px" }}
          >
            {"Data od"}
          </TableCell>
          <TableCell
            align={"left"}
            padding={"default"}
            className={styles.headCellSmall}
            style={{ padding: "10px 20px", fontSize: "11px", width: "300px" }}
          >
            {"Data do"}
          </TableCell>
          <TableCell
            align={"left"}
            padding={"default"}
            className={styles.headCellSmall}
            style={{ padding: "10px 20px", fontSize: "11px" }}
          >
            {""}
          </TableCell>
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  styles: PropTypes.object.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function useInput({ type }, placeholder) {
  const [value, setValue] = useState("");
  const input = (
    <Input
      value={value}
      handleChange={(e) => {
        setValue(e.target.value);
      }}
      type={type}
      placeholder={placeholder}
      className={styles.searchInputSmall}
      search={type === "text" ? true : false}
    />
  );
  return [value, input];
}

const options = [];

export default function RaportEKD(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [table, setTable] = useState([]);
  const [table2, setTable2] = useState([]);
  const [fullTable, setFullTable] = useState([]);
  const [searchDriver, setSearchDriver] = useInput(
    { type: "text" },
    "Szukaj kierowcy"
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");

  const [driverList, setDriverList] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [selectedDriverFullName, setSelectedDriverFullName] = useState();
  const [selectedDriverBusinessId, setSelectedDriverBusinessId] = useState();

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [openModalCiclic, setOpenModalCiclic] = React.useState(false);
  const [error, setError] = useState(false);
  const [openBD, setOpenBD] = React.useState(true);

  const [protocol, setProtocol] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDialog(false);
  };

  async function getEKD(from, to, driver) {
    props.loading(true);
    await fetch(
      `${DBurl}/getEKDReport?idDriver=${driver}&dateFrom=${from}&dateTo=${to}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          if (data.data.length <= 0) {
            setOpenDialog(true);
            setDialogContent(`Brak danych dla wybranych parametrów...`);
            setDialogColor("error");
            setTable(
              data.data.map((el) => {
                return { ...el, id: el.id_ekd };
              }) || []
            );
            props.loading(false);
          } else {
            setTable(data.data || []);
            props.loading(false);
            return data;
          }
        } else {
          setOpenDialog(true);
          setDialogContent(`Wystąpił błąd - skontaktuj się z administratorem.`);
          setDialogColor("error");
          setTable(data.data || []);
          props.loading(false);
        }
      });
  }

  async function getEkdWithProtocol() {
    const startDate = moment(fromDate).format("YYYY-MM-DD");
    const endDate = moment(toDate).endOf("month").format("YYYY-MM-DD");
    props.loading(true);

    await fetch(
      `${DBurl}/logbooks?filter_id_driver=${selectedDriver}&filter_date_from=${startDate}&filter_date_to=${endDate}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          if (data?.data?.length <= 0) {
            setOpenDialog(true);
            setDialogContent(`Brak danych dla wybranych parametrów...`);
            setDialogColor("error");
            setTable2(data?.data || []);
            props.loading(false);
          } else {
            setTable2(
              data?.data?.map((el) => {
                return { ...el, id_ekd: el.id };
              }) || []
            );
            props.loading(false);
            return data;
          }
        } else {
          setOpenDialog(true);
          setDialogContent(`Wystąpił błąd - skontaktuj się z administratorem.`);
          setDialogColor("error");
          setTable2(data?.data?.data || []);
          props.loading(false);
        }
      });
  }

  async function getDrivers() {
    props.loading(true);
    await fetch(`${DBurl}/getDriversNoSlaves`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          const drivers = data.data;
          if (options.length === 0) {
            for (const i in drivers) {
              options.push({
                label:
                  (drivers[i].firstname === null ? "" : drivers[i].firstname) +
                  " " +
                  (drivers[i].surname === null ? "" : drivers[i].surname) +
                  " [" +
                  drivers[i].driverid +
                  "] ",
                value: drivers[i].id,
              });
            }
            options.sort((b, a) =>
              a.label.localeCompare(b.label, "es", { sensitivity: "base" })
            );
            setDriverList(data.data);
          } else {
            // console.log("lista pełna...")
          }
          props.loading(false);
          return data;
        } else {
          props.loading(false);
          setOpenDialog(true);
          setDialogContent(
            `${data.responseDescription} // ${DataTransferItemList.response}  `
          );
          setDialogColor("error");
        }
      });
  }

  useEffect(() => {
    getDrivers();
  }, []);

  useEffect(() => {
    setFullTable(
      table2
        .concat(table)
        .sort((a, b) => new Date(b.ts_start) - new Date(a.ts_start))
    );
  }, [table, table2]);

  const removeDuplicates = (table) => {
    let idMap = new Map();
    table.forEach((item) => {
      idMap.set(item.id_ekd, item);
    });
    return Array.from(idMap.values());
  };

  const search = (tbl) => {
    if (tbl?.length > 0) {
      return tbl.filter((row) => {
        return !protocol
          ? row?.id_ekd
              .toString()
              .toLowerCase()
              .indexOf(searchDriver.toLowerCase()) > -1
          : row?.id
              .toString()
              .toLowerCase()
              .indexOf(searchDriver.toLowerCase()) > -1;
      });
    }
  };

  const paginate = (pageNumber) => setPage(pageNumber - 1);

  const generateRaport = async () => {
    const startDate = moment(fromDate).format("YYYY-MM-DD hh:mm:ss");
    const endDate = moment(toDate).endOf("month").format("YYYY-MM-DD hh:mm:ss");

    getEKD(startDate, endDate, selectedDriver);

    var selectedRow = driverList.filter((obj) => {
      return obj.id === selectedDriver;
    });

    if (selectedRow.length > 0) {
      setSelectedRow(selectedRow);
      setSelectedDriverFullName(
        `${selectedRow[0].firstname} ${selectedRow[0].surname}`
      );
      setSelectedDriverBusinessId(selectedRow[0].driverid);
    }
  };

  const printPDF = (id) => {
    const url = `${DBurl}/printEKDReportPDF?idEkd=${id}`;
    window.open(url, "_blank");
  };

  const printProtocolPDF = (id) => {
    const url = `${DBurl}/printLogbookStartEntryToPDF?id=${id}`;
    window.open(url, "_blank");
  };

  const checkForm = () => {
    if (!selectedDriver || !fromDate || !toDate) return false;
    return true;
  };

  const handleError = () => {
    setOpenDialog(true);
    setDialogColor("error");
    setDialogContent("Proszę wypełnić formularz.");
  };

  useEffect(() => {
    if (driverList.length > 0) {
      setOpenBD(false);
    }
  }, [driverList]);

  const generateBoth = () => {
    generateRaport();
    getEkdWithProtocol();
  };

  return (
    <>
      <div className={styles.root}>
        <Paper className={styles.paper}>
          <Title
            title={props.state.tabName}
            btnBox
            btnGenerate
            // generateFunc={() => { checkForm() ? !protocol ? generateRaport() : getEkdWithProtocol() : handleError() }}
            generateFunc={() => {
              checkForm() ? generateBoth() : handleError();
            }}
            cornersNew
            datapicker_from={setFromDate}
            datapicker_to={setToDate}
            fromDate={fromDate}
            toDate={toDate}
            selectDriverFunc={(e) => {
              setSelectedDriver(e.value);
              return e;
            }}
            // checkbox
            // checked={protocol}
            // handleCheckbox={() => setProtocol(!protocol)}
            // checkboxText={"Wygeneruj EKD z protokołem wyjazdowym"}
            selectDriver={options}
          />
          <SubTitle
            title={props.state.tabName}
            subTitleBox
            driverNameBox={selectedDriverFullName}
            driverWorkNumber={selectedDriverBusinessId}
          />
          <TableContainer className={styles.stickyTable}>
            <Table
              stickyHeader
              className={styles.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                styles={styles}
                rowCount={removeDuplicates(fullTable)?.length}
              />
              <TableBody className={styles.tabBody}>
                {stableSort(
                  removeDuplicates(fullTable),
                  getComparator(order, orderBy)
                )
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    return (
                      <TableRow hover tabIndex={-1} key={index}>
                        <TableCell
                          align="left"
                          style={{ padding: "10px 20px" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ padding: "10px 20px" }}
                        >
                          {row.ts_start?.slice(0, 19)}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ padding: "10px 20px" }}
                        >
                          {row.ts_end}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={styles.buttonBoxParent}
                        >
                          <div className={styles.buttonBoxContainer}>
                            {row.ts_end && (
                              <div className={styles.buttonBox}>
                                <Button
                                  className={BTN.BtnLabel}
                                  tooltip="Pobierz PDF"
                                  value={row}
                                  onClick={(e) => {
                                    printPDF(row.id_ekd);
                                  }}
                                >
                                  <File /> Pobierz pełen raport
                                </Button>
                              </div>
                            )}
                            <div className={styles.buttonBox}>
                              <Button
                                className={BTN.BtnLabel}
                                tooltip="Pobierz PDF"
                                value={row}
                                onClick={(e) => {
                                  printProtocolPDF(row.id_ekd);
                                }}
                              >
                                <File /> Pobierz protokół wyjazdowy
                              </Button>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={styles.tableBottom}>
            <RowsPerPage
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              setPage={setPage}
              tableLength={removeDuplicates(fullTable)?.length}
            />
            <div>
              <Pagination
                postsPerPage={rowsPerPage}
                totalPosts={removeDuplicates(fullTable)?.length}
                paginate={paginate}
                page={page}
              />
            </div>
          </div>

          <CiclicDialog
            {...props}
            selectedDriver={selectedDriver}
            selectedDriverFullName={selectedDriverFullName}
            openModalCiclic={openModalCiclic}
            setOpenModalCiclic={setOpenModalCiclic}
          />

          <Snackbar
            open={openDialog}
            autoHideDuration={4000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity={dialogColor}>
              {dialogContent}
            </Alert>
          </Snackbar>
        </Paper>
      </div>
    </>
  );
}
