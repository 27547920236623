import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Button, IconButton, Grid } from "@material-ui/core";
import { UploadCloud, XCircle, DownloadCloud, Search } from "react-feather";
import AdvanceItem from "components/AdvanceItem";
import { DBurl } from "appConfig";
import stylesMod from "styles/newOrEdit.module.css";
import { useQuery } from "react-query";
import { getDrivers, getCars, getTrailers } from "api/endpoints";
import TmsDialog from "components/TmsDialog";
import FilesTms from "./FilesTms";

const TransportSet = ({
  values,
  transport,
  updateValue,
  transportOptions,
  settingTransportSet,
  error,
  ValueContainer,
  addTab,
  propKey,
  isShowRequiredFields,
  saveTab,
  toCostValues,
  user_s,
  currencyOption,
  stylesVersion,
  Tooltip,
  NewOrderInput,
  files,
  setFiles,
  checkTransportSet,
  isPreview,
}) => {
  const [driversOptions, setDriversOptions] = useState([
    { label: "", value: null },
  ]);
  const [vehiclesOptions, setVehiclesOptions] = useState([
    { label: "", value: null },
  ]);
  const [trailerOptions, setTrailerOptions] = useState([
    { label: "", value: null },
  ]);
  const [selectedFilesArr, setSelectedFilesArr] = useState({
    files_car: [],
    files_driver1: [],
    files_driver2: [],
    files_trailer: [],
  });
  const [tempFiles, setTempFiles] = useState([]);
  const [dialog, setDialog] = useState({ isOpen: false });

  const updateTransportValue = (name, value) => {
    updateValue(`transport.${name}`, value);
  };

  useQuery("drivers", getDrivers, {
    onSuccess: (drivers) => {
      setDriversOptions(
        drivers?.map((driver) => ({
          ...driver,
          label: `${driver.firstname} ${driver.surname}`,
          value: driver.id,
        }))
      );
    },
    onError: (error) => {
      setDialog({ isOpen: true, type: "error", content: error?.message });
    },
  });

  useQuery("cars", getCars, {
    onSuccess: (cars) => {
      setVehiclesOptions(
        cars?.map((car) => ({ label: car.name, value: car.id }))
      );
    },
    onError: (error) => {
      setDialog({ isOpen: true, type: "error", content: error.message });
    },
  });

  useQuery("trailers", getTrailers, {
    onSuccess: (trailers) => {
      setTrailerOptions(
        trailers?.map((trailer) => ({ label: trailer.name, value: trailer.id }))
      );
    },
    onError: (error) => {
      setDialog({ isOpen: true, type: "error", content: error.message });
    },
  });

  const selectStyles = {
    control: (base, state) => ({
      ...base,
      border: "1px solid #142f42",
      backgroundColor:
        isShowRequiredFields &&
        !toCostValues &&
        !transport.set &&
        "#e1f3d3 !important",
      borderColor:
        isShowRequiredFields && !toCostValues && "#82cd47 !important",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #142f42",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused && "#142f42",
      color: state.isFocused && "#fff",
    }),
  };

  useEffect(() => {
    const newFiles = files.map((file) => {
      const newName = { user_filename: "" };
      for (const key in file) {
        newName[key] = file[key];
      }
      return newName;
    });
    setTempFiles(newFiles);
  }, [files.length]);

  const updateFileNames = (newName, index) => {
    const newFiles = [...tempFiles];
    newFiles[index].user_filename = `${newName}`;
    setTempFiles(newFiles);
    const arr = [...files];
    arr[index].user_filename = `${newName}`;
    setFiles(arr);
  };

  return (
    <>
      <Grid item xs={4}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <div className={stylesMod.inp}>
              <label>Zestaw transportowy*</label>
              <Select
                value={transport?.set}
                options={transportOptions}
                className={`${stylesMod.select} ${
                  transport?.error && stylesMod.error
                } ${error && !transport?.set && stylesMod.error}`}
                onChange={(e) => {
                  settingTransportSet(e);
                  updateTransportValue("error", false);
                  updateTransportValue("car", {
                    label: e.car_name,
                    value: e.id_car,
                  });
                  updateTransportValue("driver", {
                    label: e.driver1_name,
                    value: e.id_driver1,
                  });
                  e.id_trailer !== null
                    ? updateTransportValue("trailer", {
                        label: e.trailer_name,
                        value: e.id_trailer,
                      })
                    : updateTransportValue("trailer", { label: "", value: "" });
                  e.is_double_cast_of_drivers
                    ? updateTransportValue("driverTwo", {
                        label: e.driver2_name,
                        value: e.id_driver2,
                      })
                    : updateTransportValue("driverTwo", {
                        label: "",
                        value: "",
                      });
                }}
                name="transport_set"
                components={{ ValueContainer }}
                selectProps={addTab}
                link="TransportSet"
                saveTab={saveTab}
                placeholder="Wybierz..."
                styles={selectStyles}
                isDisabled={isPreview}
              />
            </div>
          </Grid>
          <Grid item xs={5}>
            <div className={stylesMod.inp}>
              <label>Pojazd</label>
              <Select
                value={transport?.car}
                options={vehiclesOptions}
                className={`${stylesMod.select}`}
                onChange={(e) => {
                  updateTransportValue("car", e);
                  updateTransportValue("set", null);
                }}
                name="car"
                saveTab={saveTab}
                placeholder="Wybierz..."
                styles={selectStyles}
                isDisabled={isPreview}
              />
            </div>
          </Grid>
          <Grid item xs={5}>
            <div className={stylesMod.inp}>
              <label>Naczepa</label>
              <Select
                value={transport?.trailer}
                options={trailerOptions}
                className={`${stylesMod.select}`}
                onChange={(e) => {
                  updateTransportValue("trailer", e);
                  updateTransportValue("set", null);
                }}
                name="trailer"
                saveTab={saveTab}
                placeholder="Wybierz..."
                styles={selectStyles}
                isDisabled={isPreview}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <div className={stylesMod.inp}>
              <label>Kierowca 1</label>
              <Select
                value={transport?.driver}
                options={driversOptions?.filter(
                  (driv) => driv.id !== transport?.driverTwo?.value
                )}
                className={`${stylesMod.select}`}
                onChange={(e) => {
                  updateTransportValue("driver", {
                    label: `${e.firstname} ${e.surname}`,
                    value: e.id,
                  });
                  updateTransportValue("set", null);
                }}
                name="driver"
                saveTab={saveTab}
                placeholder=""
                styles={selectStyles}
                isDisabled={isPreview}
              />
            </div>
          </Grid>
          <Grid item xs={5}>
            <div className={stylesMod.inp}>
              <label>Kierowca 2</label>
              <Select
                value={transport?.driverTwo}
                options={driversOptions?.filter(
                  (driv) => driv.id !== transport.driver?.value
                )}
                className={`${stylesMod.select}`}
                onChange={(e) => {
                  updateTransportValue("driverTwo", {
                    label: `${e.firstname} ${e.surname}`,
                    value: e.id,
                  });
                  updateTransportValue("set", null);
                }}
                name="driverTwo"
                saveTab={saveTab}
                placeholder=""
                styles={selectStyles}
                isDisabled={isPreview}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {(Array.isArray(transport.advance) ? transport.advance : [null]).map(
            (item, index) => (
              <AdvanceItem
                user_s={user_s}
                data={item}
                key={index}
                propkey={index}
                addAdvance={() => {
                  updateTransportValue("advance", [
                    ...transport?.advance,
                    { value: 0, currency: "" },
                  ]);
                }}
                removeAdvance={(indexToRemove) => {
                  updateTransportValue(
                    "advance",
                    transport?.advance?.filter(
                      (_, index) => index !== indexToRemove
                    )
                  );
                }}
                setCurrency={(value, index) => {
                  updateTransportValue(`advance[${index}].currency`, value);
                }}
                setAdvance={(value, index) => {
                  updateTransportValue(`advance[${index}].value`, value);
                }}
                currencyOption={currencyOption}
                isPreview={isPreview}
              />
            )
          )}
          <Grid item xs={12}>
            <Button
              disabled={user_s || isPreview}
              variant="outlined"
              color="primary"
              className={`${stylesMod.outBtn} ${
                user_s ? stylesVersion.onlyfull : ""
              }`}
              onClick={() => {
                checkTransportSet();
              }}
            >
              Sprawdź zestaw transportowy
              <Search />
            </Button>
          </Grid>

          <Grid item xs={10} className={stylesMod.gridButtonParent}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <input
                  disabled={user_s || isPreview}
                  accept="*"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  multiple
                  type="file"
                  onChange={(e) => {
                    const selectedFiles = Array.from(e.target.files);
                    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
                    e.target.value = null; // Clear the selected files from the input
                  }}
                />
                <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
                  <label htmlFor="raised-button-file">
                    <Button
                      disabled={user_s || isPreview}
                      variant="outlined"
                      color="primary"
                      component="span"
                      className={`${stylesMod.outBtn} ${stylesMod.outBtnRed} ${
                        user_s ? stylesVersion.onlyfull : ""
                      }`}
                    >
                      Podepnij dokumenty
                      <UploadCloud />
                    </Button>
                  </label>
                </Tooltip>
              </Grid>
              <FilesTms
                transport={transport}
                updateTransportValue={updateTransportValue}
                user_s={user_s}
                selectedFilesArr={selectedFilesArr}
                setSelectedFilesArr={setSelectedFilesArr}
                files={files}
                setFiles={setFiles}
                isPreview={isPreview}
              />
            </Grid>
          </Grid>
          {files.length > 0 && (
            <Grid item xs={12}>
              <div className={stylesMod.filesList}>
                {files.map((e, i) => {
                  if (e) {
                    return (
                      <>
                        <p className={stylesMod.filesItem}>
                          <NewOrderInput
                            key={e.user_filename & i}
                            index={i}
                            file={e}
                            handleChange={updateFileNames}
                            isDisabled={isPreview}
                          />

                          <div className={stylesMod.filesIconBox}>
                            {!!e.id &&
                              (e.type === "image/jpeg" ||
                                e.type === "image/png" ||
                                e.type === "application/pdf" ||
                                e.type === "application/vnd.ms-excel" ||
                                e.type ===
                                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                e.type === "text/csv") && (
                                <IconButton
                                  className={stylesMod.btnIcon2}
                                  onClick={() => {
                                    window.open(
                                      `${DBurl}/getFile?filename=${e.name}`,
                                      "_blank"
                                    );
                                  }}
                                  value={propKey}
                                >
                                  <DownloadCloud className={stylesMod.add} />
                                </IconButton>
                              )}
                            <IconButton
                              isDisabled={isPreview}
                              className={stylesMod.btnIcon2}
                              onClick={() => {
                                const tempFiles = [...files];
                                tempFiles.splice(i, 1);
                                setFiles(tempFiles);

                                if (e.kind === "car") {
                                  const index =
                                    selectedFilesArr.files_car.indexOf(
                                      e.ref_file_id
                                    );
                                  if (index !== -1) {
                                    selectedFilesArr.files_car.splice(index, 1);
                                  }
                                }
                                if (e.kind === "driver1") {
                                  const index =
                                    selectedFilesArr.files_driver1.indexOf(
                                      e.ref_file_id
                                    );
                                  if (index !== -1) {
                                    selectedFilesArr.files_driver1.splice(
                                      index,
                                      1
                                    );
                                  }
                                }
                                if (e.kind === "driver2") {
                                  const index =
                                    selectedFilesArr.files_driver2.indexOf(
                                      e.ref_file_id
                                    );
                                  if (index !== -1) {
                                    selectedFilesArr.files_driver2.splice(
                                      index,
                                      1
                                    );
                                  }
                                }
                                if (e.kind === "trailer") {
                                  const index =
                                    selectedFilesArr.files_trailer.indexOf(
                                      e.ref_file_id
                                    );
                                  if (index !== -1) {
                                    selectedFilesArr.files_trailer.splice(
                                      index,
                                      1
                                    );
                                  }
                                }
                              }}
                              value={propKey}
                            >
                              <XCircle className={stylesMod.remove} />
                            </IconButton>
                          </div>
                        </p>
                      </>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </Grid>
          )}
        </Grid>
      </Grid>
      <TmsDialog dialog={dialog} setDialog={setDialog} />
    </>
  );
};

export default TransportSet;
