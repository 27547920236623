


export const headCells = [
    { id: 'car', numeric: true, disablePadding: false, label: 'Pojazd', title: 'title', },
    { id: 'leasing', numeric: true, disablePadding: true, label: 'Leasing', title: 'Podaj informacje na temat leasingu pojazdu.', },
    { id: 'insurance', numeric: true, disablePadding: true, label: 'Ubezpieczenie', title: 'Podaj informacje na temat ubezpieczenia pojazdu.', },
    { id: 'service', numeric: true, disablePadding: true, label: 'Obsługa serwisowa', title: 'Podaj informacje na temat kosztów serwisu pojazdu.', },
    { id: 'parts', numeric: true, disablePadding: true, label: 'Części', title: 'Podaj informacje na temat kosztów części.', },
    { id: 'equipment', numeric: true, disablePadding: true, label: 'Wyposażenie', title: 'Podaj informacje na temat kosztów wyposażenia.', },
    { id: 'sell', numeric: true, disablePadding: true, label: 'Sprzedaż auta', title: 'Podaj informacje na temat przychodów ze sprzedaży pojazdu.', },
    { id: 'tires', numeric: true, disablePadding: true, label: 'Opony', title: 'Podaj informacje na temat kosztów opon.', },
    { id: 'taxes', numeric: true, disablePadding: true, label: 'Podatki i opłaty', title: 'Podaj informacje na temat podatków.', },
    { id: 'office', numeric: true, disablePadding: true, label: 'Biuro', title: 'Podaj informacje o kosztach prowadzenia biura.', },
    { id: 'empolyees', numeric: true, disablePadding: true, label: 'Pracownicy', title: 'Podaj informacje o kosztach związanych z pracownikami.', },
    { id: 'other', numeric: true, disablePadding: true, label: 'Opłaty inne', title: 'Podaj informacje na temat innych kosztów.', },
];

export const MODALS = {
    LEASING: "leasing",
    INSURANCE: "insurance",
    SERVICE: "service",
    PARTS: "parts",
    EQUIPMENT: "equipment",
    SELL: "sell",
    TIRES: "tires",
    TAXES: "taxes",
    OFFICE: "office",
    EMPLOYEES: "empolyees",
    OTHER: "other",
}

const currentYear = new Date().getFullYear()

export const MONTHS = [
    { label: "Styczeń", value: 1 },
    { label: "Luty", value: 2 },
    { label: "Marzec", value: 3 },
    { label: "Kwiecień", value: 4 },
    { label: "Maj", value: 5 },
    { label: "Czerwiec", value: 6 },
    { label: "Lipiec", value: 7 },
    { label: "Sierpień", value: 8 },
    { label: "Wrzesień", value: 9 },
    { label: "Październik", value: 10 },
    { label: "Listopad", value: 11 },
    { label: "Grudzień", value: 12 },
]

export const LEASING_OPTIONS = [
    { label: "Stawka miesięczna", value: 2 },
    { label: "Stawka roczna", value: 3 },
    { label: "Wpłata własna", value: 4 },
    { label: "Wykup", value: 5 },
]

export const CURRENCY_OPTIONS = [
    { label: "PLN", value: "PLN" },
    { label: "EUR", value: "EUR" },
    { label: "GBP", value: "GBP" },
    { label: "USD", value: "USD" },
    { label: "CHF   ", value: "CHF " },
]