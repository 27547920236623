import React, { useState, useEffect, useContext } from "react";
import { DBurl } from "./appConfig";
import { BrowserRouter } from "react-router-dom";
import Login from "./containers/login/login";
import MainView from "./containers/tabs/MainView";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Theme from "./styles/theme.json";
import RecoveryPassword from "./containers/RecoverPassword";
import ClipLoader from "react-spinners/ClipLoader";
import Promotion from "./components/Promotion";
import styles from "./app.module.css";
import { AppName } from "./appConfig";
import TmsDialog from "components/TmsDialog";
import { UserContext } from "contexts/userContext";

import "./styles/main.css";

const theme = createTheme(Theme);

export default function App() {
  const [isRecoveryCodeOk, setIsRecoveryCodeOk] = useState(false);
  const isRecoverPassword = document.URL.includes("recover_password");
  const [loading, setLoading] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [color, setColor] = useState("#ed3c22");
  const [isPromotion, setPromotion] = useState(false);
  const [dataFromURL, setDataFromURL] = useState();
  const [dialog, setDialog] = useState({ isOpen: false });

  const { user, setUser, isLoggedIn, setIsLoggedIn } = useContext(UserContext);

  const override = {
    width: "50px",
    height: "50px",
  };

  const checkRecoveryPassword = async (url) => {
    const response = await fetch(`${DBurl}/checkUrlRecoverPassword`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        url: url,
      }),
    });
    const json = await response.json();
    setIsRecoveryCodeOk(json.data.correctUrl);
  };

  useEffect(() => {
    if (isRecoverPassword) {
      const recoveryCode =
        document.URL.split("/")[document.URL.split("/").length - 1];
      checkRecoveryPassword(recoveryCode);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const url_new = document.URL;
    const searchParams = new URLSearchParams(new URL(url_new).search);

    const name = searchParams.get("name");
    const nip = searchParams.get("nip");
    const street = searchParams.get("street");
    const street_no = searchParams.get("street_no");
    const city = searchParams.get("city");
    const postal = searchParams.get("postal");
    const phone = searchParams.get("phone");
    const trader_name = searchParams.get("trader_name");
    const trader_email = searchParams.get("trader_email");

    const data = {
      name: name,
      nip: nip,
      street: street,
      street_no: street_no,
      city: city,
      postal: postal,
      phone: phone,
      trader_name: trader_name,
      trader_email: trader_email,
    };

    const fillup = () => {
      setPromotion(true);
      setDataFromURL(data);
    };

    const cleanup = () => {
      setPromotion(false);
      setDataFromURL();
    };

    const alert = () => {
      setDialog({
        isOpen: true,
        type: "warning",
        content: "Przepraszamy, nie można utworzyć konta promocyjnego.",
      });
    };

    if (url_new.includes("promotion_account")) {
      data.name !== undefined && data.nip !== undefined
        ? data.name !== "" && data.nip !== ""
          ? fillup()
          : alert()
        : cleanup();
    }
  }, []);

  useEffect(() => {
    document.title = AppName;
  }, []);

  const mainView = isLoggedIn ? (
    <MainView
      user={user}
      setLoading={setLoading}
      isLoading={loading}
      isLoggedIn={isLoggedIn}
      setIsLoggedIn={setIsLoggedIn}
      setDialog={setDialog}
    />
  ) : isRecoveryCodeOk ? (
    <RecoveryPassword />
  ) : isPromotion ? (
    <Promotion
      setPromotion={setPromotion}
      dataFromURL={dataFromURL}
      setIsLoggedIn={setIsLoggedIn}
      user={user}
      setUser={setUser}
    />
  ) : (
    <Login
      title={`Login`}
      user={user}
      setUser={setUser}
      setIsLoggedIn={setIsLoggedIn}
    />
  );

  const LoadingSpinner = () => (
    <div
      className={styles.loader}
      style={loading ? { display: "flex" } : { display: "none" }}
    >
      <ClipLoader
        color={color}
        loading={loading}
        width={"100%"}
        speedMultiplier={1}
        cssOverride={override}
      />
      <p>Ładowanie...</p>
    </div>
  );

  let urls = {
    url: window.location.origin,
    apiUrl: DBurl,
  };

  let isBeta = false;

  let checkBeta = (url) => {
    let index = url.indexOf("://");
    return url.substring(index + 3, index + 7);
  };

  if (urls.apiUrl && urls.apiUrl.indexOf("://")) {
    isBeta = checkBeta(urls.apiUrl) ? true : false;
  } else {
    isBeta = checkBeta(urls.url) ? true : false;
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <LoadingSpinner />
          {isBeta === true ? (
            <div className={isLoggedIn ? "betaTopBar" : "betaTopBarLogOut"}>
              BETA
            </div>
          ) : (
            ""
          )}
          <div className="mainSet">{mainView}</div>
        </BrowserRouter>
      </ThemeProvider>
      <TmsDialog setDialog={setDialog} dialog={dialog} />
    </>
  );
}
