import React, { useState, useEffect, useMemo } from "react";
import styles from "./goodsEdit.module.css";
import Input from "./input";
import Title from "./title";
import Button from "@material-ui/core/Button";
import { Plus } from "react-feather";
import Select from "react-select";

export default function GoodsEdit(props) {
  const [id, setId] = useState(undefined);
  const [name, setName] = useState("");
  const [unit, setUnit] = useState("");
  const [details, setDetails] = useState("");

  useEffect(() => {
    setName(props.name);
    const unitObj = props.unitOption.find((e) => props.unit == e.name);
    setUnit({ ...unitObj, value: unitObj?.id, label: unitObj?.name });
    setDetails(props.details);
    setId(props.id);
  }, [props.name, props.unit, props.details, props.id]);

  const options = useMemo(() => {
    if (props?.unitOption?.length > 0) {
      return props.unitOption.map((e) => ({ value: e.id, label: e.name }));
    }

    return [];
  }, [props?.unitOption]);

  const handleSave = () => {
    if (props.title === "Edytuj") {
      props.edit({ id, name, details, unit: unit.label });
    } else {
      if (unit.label === undefined || name === "") {
        props.setDialog({
          isOpen: true,
          type: "error",
          content: "Wprowadź nazwę oraz wybierz jednostkę",
        });
      }

      if (name !== "" && unit.label !== undefined) {
        props.add({ name, details, unit: unit.label });
        setName("");
        setUnit("");
        setDetails("");
      }
    }
  };

  return (
    <div>
      <Title title={props.title} />
      <div className={styles.inputBox}>
        <div className={styles.inp}>
          <label>Nazwa towaru</label>
          <Input
            value={name}
            handleChange={(e) => {
              setName(e.target.value);
            }}
            name="name"
          />
        </div>

        <div className={styles.inp}>
          <label>Jednostka</label>
          <Select
            value={unit}
            className={styles.select}
            options={options}
            name="unit"
            placeholder="Wybierz..."
            styles={{
              control: (base, state) => ({
                ...base,
                border: "1px solid #142f42",
                boxShadow: "none",
                "&:hover": {
                  border: "1px solid #142f42",
                },
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused && "#142f42",
                color: state.isFocused && "#fff",
              }),
            }}
            onChange={(e) => {
              setUnit(e);
            }}
          />
        </div>

        <div className={styles.inp}>
          <label>Opis</label>
          <textarea
            rows="4"
            className={styles.textarea}
            value={details}
            onChange={(e) => {
              setDetails(e.target.value);
            }}
            name="details"
          />
        </div>
        <Button
          variant="outlined"
          color="primary"
          className={styles.outBtn}
          onClick={handleSave}
        >
          {props.btn} <Plus />
        </Button>
      </div>
    </div>
  );
}
