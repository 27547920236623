import React, { useState, useEffect } from "react";
import Input from "../../components/input";
import styles from "../../styles/newOrEdit.module.css";
import Grid from "@material-ui/core/Grid";
import Contact from "./Contact";
import { X, Plus } from "react-feather";
import Button from "@material-ui/core/Button";
import stylesMod from "../../styles/newOrEdit.module.css";
import { AddressSearch } from "../../components/addressSearch";

export default function AddWarehouse(props) {
  const [checkedCont, setCheckedCont] = React.useState(false);
  const contactModule = props.contacts?.map((e, i) => {
    return (
      <Contact
        contacts={e}
        key={i}
        warehouseKey={props.propKey}
        propKey={i}
        isDisabled={props.isDisabled ? true : false}
        addContact={props.warehouseAddContact}
        removeContact={props.warehouseRemoveContact}
        handleContact={props.warehouseHandleContact}
      />
    );
  });

  React.useEffect(() => {
    if (checkedCont) {
      props.setCheckedContact();
    }
  }, [checkedCont]);

  return (
    <>
      <Grid item xs={2}>
        <div className={stylesMod.inp}>
          <h6>Nazwa</h6>
          <label>Nazwa magazynu</label>
          <Input
            disabled={props.isDisabled}
            value={props.name}
            handleChange={(e) => {
              props.handleChange(e.target.value, e.target.name, props.propKey);
            }}
            name="name"
            error={props.error}
          />
        </div>
      </Grid>
      <Grid item xs={3}>
        <div className={stylesMod.inp}>
          <h6>Adres</h6>
          <AddressSearch
            token={props.csrf_token}
            isDisabled={props.isDisabled}
            setAddress={(e) => {
              props.handleChange(e.country, "country", props.propKey);
              props.handleChange(e.city, "city", props.propKey);
              props.handleChange(e.street, "street", props.propKey);
              props.handleChange(e.street_no, "street_no", props.propKey);
              props.handleChange(e.apartment_no, "apartment_no", props.propKey);
              props.handleChange(e.zipcode, "zipcode", props.propKey);
              props.handleChange(e.latitude, "latitude", props.propKey);
              props.handleChange(e.longitude, "longitude", props.propKey);
            }}
          />
        </div>
      </Grid>
      <Grid item xs={1} className={`${styles.WOHead} ${styles.secondClass}`}>
        <div className={styles.inp}>
          <label>Kraj</label>
          <Input
            value={props.country}
            handleChange={(e) => {
              props.handleChange(e.target.value, e.target.name, props.propKey);
            }}
            name="country"
            disabled
            error={!props.country && props.error}
          />
        </div>
      </Grid>
      <Grid item xs={1} className={`${styles.WOHead} ${styles.secondClass}`}>
        <div className={styles.inp}>
          <label>Miasto</label>
          <Input
            value={props.city}
            handleChange={(e) => {
              props.handleChange(e.target.value, e.target.name, props.propKey);
            }}
            name="city"
            disabled
            error={!props.city && props.error}
          />
        </div>
      </Grid>
      <Grid item xs={2} className={`${styles.WOHead} ${styles.secondClass}`}>
        <div className={styles.inp}>
          <label>Ulica</label>
          <Input
            value={props.street}
            onChange={(e) => {
              props.handleChange(e.target.value, e.target.name, props.propKey);
            }}
            name="street"
            disabled
            // error={!props.street && props.error}
          />
        </div>
      </Grid>
      <Grid item xs={1} className={`${styles.WOHead} ${styles.secondClass}`}>
        <div className={styles.inp}>
          <label>Numer</label>
          <Input
            value={props.street_no}
            handleChange={(e) => {
              props.handleChange(e.target.value, e.target.name, props.propKey);
            }}
            name="street_no"
            disabled
          />
        </div>
      </Grid>
      <Grid item xs={1} className={`${styles.WOHead} ${styles.secondClass}`}>
        <div className={styles.inp}>
          <label>Lokal</label>
          <Input
            value={props.apartment_no}
            handleChange={(e) => {
              props.handleChange(e.target.value, e.target.name, props.propKey);
            }}
            name="apartment_no"
            disabled
          />
        </div>
      </Grid>
      <Grid item xs={1} className={`${styles.WOHead} ${styles.secondClass}`}>
        <div className={styles.inp}>
          <label>Kod pocztowy</label>
          <Input
            value={props.zipcode}
            handleChange={(e) => {
              props.handleChange(e.target.value, e.target.name, props.propKey);
            }}
            name="zipcode"
            disabled
            error={!props.zipcode && props.error}
          />
        </div>
      </Grid>
      <Grid
        item
        xs={5}
        className={`${styles.WOHead} ${styles.secondClass}`}
      ></Grid>
      <Grid item xs={12} className={`${styles.checkBoxes} ${styles.pb0}`}>
        <h6>Osoba do kontaktu</h6>
        <div className={styles.checkPar}>
          <input
            type="checkbox"
            className={styles.check}
            checked={checkedCont}
            onChange={(e) => {
              if (e.target.checked) {
                props.handleChange(
                  e.target.value,
                  e.target.name,
                  props.propKey,
                  "contacts"
                );
              }
              setCheckedCont(e.target.checked);
            }}
            name="checkWarehouse"
            disabled={props.isDisabled ? true : false}
          />
          <label>Taka sama jak w firmie</label>
        </div>
      </Grid>
      {contactModule}
      <Grid item xs={12} className={!props.isLast ? styles.notLast : null}>
        <div className={styles.outBtnRow}>
          <Button
            variant="outlined"
            color="primary"
            className={styles.outBtn}
            onClick={(e) => {
              props.addWarehouseFunc();
            }}
          >
            Dodaj magazyn <Plus />
          </Button>
          {props.showDeleteWarehouse ? (
            <Button
              variant="outlined"
              color="primary"
              className={styles.outBtn}
              onClick={(e) => {
                props.removeWarehouseFunc(props.key);
              }}
            >
              Usuń magazyn <X />
            </Button>
          ) : null}
        </div>
      </Grid>
    </>
  );
}
