import React, { useState, useEffect, forwardRef } from 'react'
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import stylesMod from '../../styles/addAccount.module.css'
import { DBurl } from '../../appConfig'
import BTNstyles from '../../styles/button.module.css'
import styles from '../table.module.css';
import Input from '../../components/input'
import Select from 'react-select'
import { useRef } from 'react';
import { IMaskInput, IMaskMixin } from 'react-imask';
import styles2 from '../../components/input.module.css'
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from 'react-feather';

const EditAccount = ({ setOpen, token, setLoading, accData}) => {

    const [accName, setAccName] = useState(accData?.account_name)
    const [bankName, setBankName] = useState(accData?.bank_name)
    const [accNum, setAccNum] = useState(accData?.bank_account_no)
    const [taxAccNum, setTaxAccNum] = useState(accData?.bank_vat_account_no)
    const [swiftNum, setSwiftNum] = useState(accData?.swift_bic_no)
    const [defaultCurrency, setDefaultCurrency] = useState(accData?.currency)
    const currencies = [
        { label: "PLN", value: "PLN" },
		{ label: "EURO", value: "EUR" },
		{ label: "CHF", value: "CHF" },
		{ label: "FUNT", value: "GBP" },
		{ label: "KORONA", value: "CZK" },
		{ label: "HRYWNA", value: "UAH" }
	]
    const handleIBAN = () => {
        if (selectedCurrency.value === "PLN") return "PL"
        if (selectedCurrency.value === "CHF") return "CH"
        if (selectedCurrency.value === "GBP") return "GB"
        if (selectedCurrency.value === "CZK") return "CZ"
        if (selectedCurrency.value === "UAH") return "UA"
        if (selectedCurrency.value === "EUR") return "XX"
        if (!selectedCurrency) return ""
    }
    const tooltipText = <div>Numer konta podany w formacie< br />XX00 0000 0000 0000 0000 0000 0000< br />gdzie "XX" oznacza kod IBAN, np. PL.</div>
    const vatTooltipText = <div>Numer konta podany w formacie< br />00 0000 0000 0000 0000 0000 0000</div>
    const [selectedCurrency, setSelectedCurrency] = useState(currencies.find(curr => curr.value === accData?.currency))
    const handleEditAccDetails = async() => {  
        setLoading(true)  
        const response = await fetch(`${DBurl}/setCustomersDepartmentsAccounts`, {
        method: "POST",
        credentials: 'include',
        headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
        },
        body: JSON.stringify({
        mode: 'update',
        data: {
            id: accData.id,
            account_name: accName,
            bank_name: bankName,
            bank_account_no: accNum,
            bank_vat_account_no: taxAccNum,
            swift_bic_no: swiftNum,
            currency: defaultCurrency
        }
        })
        });
    setLoading(false)
    }


    const ref = useRef(null);
    const inputRef = useRef(null);

    // useEffect(()=>{
    //     setAccNum(handleIBAN())
    // },[selectedCurrency])

    return (
        <div className={stylesMod.addAccount}>
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <div className={styles.inp}>
                        <label>Nazwa konta:</label>
                        <Input value={accName} handleChange={(e) => { setAccName(e.target.value) }} name="accName" type="text" />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={styles.inp}>
                        <label>Nazwa banku:</label>
                        <Input value={bankName} handleChange={(e) => { setBankName(e.target.value) }} name="bankName" type="text" />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={styles.inp}>
                        <label>Domyślna waluta konta:</label>
                        <Select 
                            value={selectedCurrency}
                            className={`${stylesMod.select}`}
                            options={currencies}
                            onChange={e => { 
                                setSelectedCurrency(e)
                                setDefaultCurrency(e.value)
                            }}
                            menuPortalTarget={document.body}
                            name="currency"
                            styles={{
                                control: (base, state) => ({
                                    ...base,
                                    border: '1px solid #142f42',
                                    boxShadow: 'none',
                                    '&:hover': {
                                        border: '1px solid #142f42',
                                    }
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isFocused && "#142f42",
                                    color: state.isFocused && "#fff",
                                }),
                                menuPortal: base => ({ ...base, zIndex: 9999 })

                            }}
                        />
                        </div>
                    </Grid>
                <Grid item xs={12} className={`${stylesMod.WOHead} ${stylesMod.secondClass}`}>
                <div className={styles.inp}>
                <label>
                        Numer konta:
                        <Tooltip className={styles.infoTooltip} title={tooltipText}><Info /></Tooltip>
                        </label>
                        <IMaskInput
                            className={styles2.input}
                            // radix=" "
                            value={accNum}
                            unmask={false} // true|false|'typed'
                            ref={ref}
                            mask='aa00 0000 0000 0000 0000 0000 0000'
                            inputRef={inputRef}
                            onAccept={
                                (value, mask) => setAccNum(value)
                            }
                            // placeholder='__ ____ ____ ____ ____'
                        />
                    </div>
				</Grid>
                <Grid item xs={12} className={`${stylesMod.WOHead} ${stylesMod.secondClass}`}>
                <div className={styles.inp}>
                        <label>
                            Numer konta VAT:
                            <Tooltip className={styles.infoTooltip} title={vatTooltipText}><Info /></Tooltip>
                        </label>
                        <IMaskInput
                            className={styles2.input}
                            radix="-"
                            value={taxAccNum}
                            unmask={false} // true|false|'typed'
                            ref={ref}
                            mask='00 0000 0000 0000 0000 0000 0000'
                            // maxLength = '20'
                            inputRef={inputRef}
                            onAccept={
                                (value, mask) => setTaxAccNum(value)
                            }
                            // placeholder='__-____-____-____-____'
                        />
                    </div>
				</Grid>
                <Grid item xs={12}>
                    <div className={styles.inp}>
                        <label>Numer konta SWIFT/BIC:</label>
                        <Input value={swiftNum} handleChange={(e) => { setSwiftNum(e.target.value) }} name="swiftNum" type="text" />
                    </div>
                </Grid>
                {/* <Grid item xs={12} className={`${stylesMod.WOHead} ${stylesMod.secondClass}`}>
                <div className={styles.inp}>
                        <label>Numer konta SWIFT/BIC:</label>
                        <IMaskInput
                            className={styles2.input}
                            radix="-"
                            value={swiftNum}
                            unmask={false} // true|false|'typed'
                            ref={ref}
                            mask='** 0000 0000 0000 0000'
                            maxLength = '22'
                            inputRef={inputRef}
                            onAccept={
                                (value, mask) => setSwiftNum(value)
                            }
                            placeholder='__-____-____-____-____'
                        />
                    </div>
				</Grid> */}
            </Grid>
            <DialogActions>
                <Button variant="contained" size="large" className={`${BTNstyles.btn} ${BTNstyles.grn}`} onClick={() => {
                    handleEditAccDetails()
                    setOpen(false)
                    }}>
                    Zapisz
                </Button>
                <Button variant="contained" size="large" color="primary" className={BTNstyles.btn} onClick={() => setOpen(false)}>
                    Anuluj
                </Button>
            </DialogActions>
        </div>
    )
}

export default EditAccount;