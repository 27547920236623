import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Input from '../../components/input'
import Title from '../../components/title'
import SubTitle from '../../components/subTitle'
import styles from '../table.module.css'
import RowsPerPage from '../../components/RowsPerPage'
import Pagination from '../../components/pagination'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Paper, Snackbar, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Slide, IconButton } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { DBurl } from '../../appConfig'
import IcoBtn from '../../components/buttons/icoBtn';
import CircularProgress from '@material-ui/core/CircularProgress';
import CiclicDialog from '../../components/CiclicDialog'
import CiclicIsEnabledDialog from '../../components/CiclicIsEnabledDialog'
import Button from '@material-ui/core/Button';
import { X } from 'react-feather';
import stylesMod from '../../styles/newOrEdit.module.css'
import BTNstyles from '../../styles/button.module.css'
import DeadlinesCiclic from '../documents/deadlinesCiclic'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function descendingComparator(a, b, orderBy) {
	if (orderBy === "refueling length") {

		return (!!b.refuelings && !b.refuelings[0].brak && b.refuelings?.length || 0) < (!!a.refuelings && !a.refuelings[0].brak && a.refuelings?.length || 0) ?
			-1 :
			((!!b.refuelings && !b.refuelings[0].brak && b.refuelings?.length || 0) > (!!a.refuelings && !a.refuelings[0].brak && a.refuelings?.length || 0) ?
				1 : 0)

	}
	if (orderBy === "refueling add") {
		return (!!b.refuelings && !b.refuelings[0].brak && countFuel(b.refuelings)) || 0 < (!!a.refuelings && !a.refuelings[0].brak && countFuel(a.refuelings)) || 0 ?
			-1 :
			((!!b.refuelings && !b.refuelings[0].brak && countFuel(b.refuelings)) || 0 > (!!a.refuelings && !a.refuelings[0].brak && countFuel(a.refuelings)) || 0 ? 1 :
				((!!b.refuelings && !b.refuelings[0].brak && countFuel(b.refuelings)) || 0 === (!!a.refuelings && !a.refuelings[0].brak && countFuel(a.refuelings) ? 0 : -1)))
	}


	let sortB;
	let sortA;
	if (!!b && typeof b[orderBy] === 'string') {
		sortB = b[orderBy].toLowerCase()
	} else {
		sortB = b
	}
	if (!!a && typeof a[orderBy] === 'string') {
		sortA = a[orderBy].toLowerCase()
	} else {
		sortA = a
	}
	if (sortB < sortA) {
		return -1;
	}
	if (sortB > sortA) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);

	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const countFuel = (rows) => {
	let result = 0
	rows && rows.forEach(row => result += row.fuel)
	return result
}

const headCells = [
	{ id: 'report_type', alignRight: false, disablePadding: false, label: 'Raport' },
	{ id: 'file_type', alignRight: false, disablePadding: true, label: 'Typ' },
	{ id: 'id_driver', alignRight: false, disablePadding: true, label: 'Kierowca' },
	{ id: 'emails', alignRight: false, disablePadding: true, label: 'Adresy e-mail' },
	{ id: 'is_enabled', alignRight: false, disablePadding: true, label: 'Status' },
	{ id: 'akcje', alignRight: true, disablePadding: false, label: 'Ustawienia', width: '150px' },
];

function EnhancedTableHead(props) {
	const { styles} = props;

	return (
		<>
			<TableHead className={styles.tabHead}>
				<TableRow>
					{headCells.map((headCell) => (
						<TableCell
							key={headCell.id}
							align={headCell.alignRight ? 'right' : 'left'}
							padding={headCell.disablePadding ? 'none' : 'default'}
							className={styles.headCellSmall}
							style={{padding:'10px 20px'}}
						>
							{headCell.label}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
		</>
	);
}

EnhancedTableHead.propTypes = {
	styles: PropTypes.object.isRequired,
	rowCount: PropTypes.number.isRequired,
};

function useInput({ type }, placeholder) {
	const [value, setValue] = useState("")
	const input = <Input value={value} handleChange={e => {
		setValue(e.target.value)
	}
	} type={type}
		placeholder={placeholder}
		className={styles.searchInputSmall}
		search={type === "text" ? true : false} />;
	return [value, input];
}

const options = [];


export default function CiclesReports(props) {
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('name');
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(15);
	const [table, setTable] = useState([]);
	const [searchDriver, setSearchDriver] = useInput({ type: "text" }, "Szukaj kierowcy");
	const [dateFrom, setDateFrom] = useInput({ type: "datetime-local" }, "Od");
	const [dateTo, setDateTo] = useInput({ type: "datetime-local" }, "Do");

	const [openDialog, setOpenDialog] = useState(false)
	const [dialogContent, setDialogContent] = useState("")
	const [dialogColor, setDialogColor] = useState("error")

	const [driverList, setDriverList] = useState([]);

	const [selectedRow, setSelectedRow] = useState();

	const [openModalCiclic, setOpenModalCiclic] = React.useState(false);
	const [openModalDocsCiclic, setOpenModalDocsCiclic] = React.useState(false);
	const [openModalIsEnabledCiclic, setOpenModalIsEnabledCiclic] = React.useState(false);
	const [deleteData, setDeleteData] = useState([])
	const [openModalDelete, setOpenModalDelete] = useState(false)

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenDialog(false)
	}

	async function getCiclesReports() {
		props.loading(true)
		await fetch(`${DBurl}/getCycleReports`, {
			method: "GET",
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": props.user.csrf_token
			}
		}).then(res => res.json())
		.then(
			data => {
				if (data.success) {
					if(data.data.length <= 0){
						setOpenDialog(true)
						setDialogContent(`Brak danych dla wybranych parametrów...`)
						setDialogColor("error")
						setTable(data.data || [])
						props.loading(false)
					}else{
						setTable(data.data || [])
						props.loading(false)
						return data
					}
				} else {
					setOpenDialog(true)
					setDialogContent(`Brak danych, proszę wybrać właściwe parametry..`)
					setDialogColor("error")
					setTable(data.data || [])
					props.loading(false)
				}
			}
		)
	}

	const delDriver = async function (arrayOfIds) {
		props.loading(true)
		const response = await fetch(`${DBurl}/setCycleReports/`, {
			method: "post",
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": props.user.csrf_token
			},
			body: JSON.stringify({
				mode: 'delete',
				data: {
					ids: arrayOfIds
				}
			})
		})
		const json = await response.json()
	
		if (json.success) {
			setOpenDialog(true)
			setDialogContent(`Raport został usunięty z bazy `)
			setDialogColor("success")
			getCiclesReports()
			props.loading(false)
			return json
		} else {
			setOpenDialog(true)
			setDialogContent(`${json.responseDescription} // ${json.response}  `)
			setDialogColor("error")
			props.loading(false)
		}
	
;
			
	
	
	}

	const paginate = pageNumber => setPage(pageNumber - 1);


	useEffect(() => {
		getCiclesReports()
	}, [])

	return (
		<>
			<div className={styles.root}>

				<Paper className={styles.paper}>
					<Title title={props.state.tabName}
						btnBox
						cornersNew
					/>
					<TableContainer className={styles.stickyTable}>
						<Table
							stickyHeader
							className={styles.table}
							aria-labelledby="tableTitle"
							size="small"
							aria-label="enhanced table"
						>

							<colgroup>
								<col width="20%" />
								<col width="5%" />
								<col width="20%" />
								<col width="45%" />
								<col width="10%" />
								<col width="10%" />
							</colgroup>

							<EnhancedTableHead
								styles={styles}
								rowCount={table.length}
							/>
							<TableBody className={styles.tabBody}>
							{stableSort(table, getComparator(order, orderBy))
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => {	
										switch(row.report_type){
											case "REPORT_LOAD_UNLOAD":
												row.report_type = "Załadunki/Rozładunki"
												break;
											case "REPORT_LOAD_UNLOAD_S":
												row.report_type = "Załadunki/Rozładunki S"	
												break;
											case "REPORT_FOREIGN_EPISODES":
												row.report_type = "Odcinki zagraniczne"
												break;
											case "REPORT_LATE_INVOICES":
												row.report_type = "Windykacja - ponaglenie"
												break;	
											case "REPORT_DOC_DEADLINES_CARS":
												row.report_type = "Dokumenty - pojazdy"
												break;	
											case "REPORT_DOC_DEADLINES_TRAILERS":
												row.report_type = "Dokumenty - naczepy"
												break;	
											case "REPORT_DOC_DEADLINES_DRIVERS":
												row.report_type = "Dokumenty - kierowcy"
												break;	
											case "REPORT_DOC_DEADLINES_FIRM":
												row.report_type = "Dokumenty - firma"
												break;	
											default:
												break;	
										}

										return (
											<TableRow hover tabIndex={-1} key={index}>

												<TableCell width="40%" style={{padding:'10px 20px'}}>{row.report_type}</TableCell>
												<TableCell align="left">{row.file_type}</TableCell>
												<TableCell align="left">{row.driver_name}</TableCell>
												<TableCell align="left">
													{row.emails.length > 1 ? Object.values(row.emails).join(', ') : row.emails}
												</TableCell>
												<TableCell align="left">
													<div style={row.is_enabled === false ? {color: 'red'} : {}}>
														{row.is_enabled === true ? 'aktywny' : 'nieaktywny'}
													</div>
												</TableCell>
												<TableCell align="right" className={styles.buttonBoxParent}>
													<div className={styles.buttonBox}>
														{<IcoBtn icon="Lock" tooltip={row.is_enabled === true ? 'dezaktywuj' : 'aktywuj'} value={row} onClick={(e) => {
															setSelectedRow(row)
															setOpenModalIsEnabledCiclic(true)
														}} 
														className={row.is_enabled === true ? styles.mob_active_on : styles.mob_active_off}/>}
														<IcoBtn icon="Edit" tooltip="Edytuj" value={row} onClick={(e) => {
															if (row.report_type == 'Załadunki/Rozładunki' ||
																row.report_type == 'Załadunki/Rozładunki S' ||
																row.report_type == 'Odcinki zagraniczne' ||
																row.report_type == 'Windykacja - ponaglenie'){
																setSelectedRow(row)
																setOpenModalCiclic(true)
															} else {
																setSelectedRow(row)
																setOpenModalDocsCiclic(true)
															}
														}} />
														<IcoBtn icon="X" tooltip="Usuń" className="btn-delete" value={row} onClick={(e) => {
															setDeleteData([row.id])
															setSelected(row);
															setOpenModalDelete(true)
					
														}} />
													</div>
												</TableCell>
											</TableRow>
										);
									})}
							</TableBody>
						</Table>
					</TableContainer>
					<div className={styles.tableBottom} >
						<RowsPerPage
							rowsPerPage={rowsPerPage}
							setRowsPerPage={setRowsPerPage}
							setPage={setPage}
							tableLength={table.length}
						/>
						<div>
							<Pagination
								postsPerPage={rowsPerPage}
								totalPosts={table.length}
								paginate={paginate}
								page={page}
							/>
						</div>
					</div>


					<CiclicDialog
						{...props}
						openModalCiclic={openModalCiclic} 
						setOpenModalCiclic={setOpenModalCiclic}
						data={selectedRow}
						refresh={getCiclesReports}
						isEdit={true}
					/>

					<DeadlinesCiclic
						{...props}
						openModalCiclic={openModalDocsCiclic} 
						setOpenModalCiclic={setOpenModalDocsCiclic}
						data={selectedRow}
						refresh={getCiclesReports}
						isEdit={true}
					/>

					<CiclicIsEnabledDialog
						{...props}
						openModalIsEnabledCiclic={openModalIsEnabledCiclic} 
						setOpenModalIsEnabledCiclic={setOpenModalIsEnabledCiclic}
						data={selectedRow}
						refresh={getCiclesReports}
						isEdit={true}
					/>

					<Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
						<Alert onClose={handleClose} severity={dialogColor}>
							{dialogContent}
						</Alert>
					</Snackbar>



					<Dialog
						open={openModalDelete}
						TransitionComponent={Transition}
						onClose={() => {
							setDeleteData([])
							setOpenModalDelete(false)
						}}
						aria-labelledby="alert-dialog-slide-title"
						aria-describedby="alert-dialog-slide-description"
					>
						<DialogTitle id="alert-dialog-slide-title" className={stylesMod.dialogHead}>Usuń raport cykliczny?			
						<IconButton aria-label="close" className={stylesMod.closeButton} onClick={() => {
							setDeleteData([])
							setOpenModalDelete(false)
						}}>
							<X />
						</IconButton>
						</DialogTitle >
						<DialogContent>Czy napewno chcesz usunąć raport cykliczny?</DialogContent>
						<DialogActions>
							<Button variant="contained" size="large" color="primary" className={BTNstyles.btn} onClick={(e) => {
								setOpenModalDelete(false)
							}}>
								Anuluj
							</Button>
							<Button variant="contained" size="large" className={`${BTNstyles.btn} ${BTNstyles.err}`} onClick={() => {
								delDriver(deleteData)
								setSelected([])
								setOpenModalDelete(false)
								setDeleteData([])
							}}
								color="primary">
								Usuń <X className={BTNstyles.downBtnIco} />
							</Button>
						</DialogActions>
					</Dialog>

				</Paper>
				</div>
		</>

	)
}
