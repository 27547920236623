import React, { useState, useEffect } from "react";
import Title from "../../components/title";
import { DBurl } from "../../appConfig";
import { X, Plus, Edit2 } from "react-feather";
import styles from "../table.module.css";
import BTNstyles from "../../styles/button.module.css";
import Pagination from "../../components/pagination";
import Button from "@material-ui/core/Button";
import stylesMod from "../../styles/newOrEdit.module.css";
import {
  Table,
  TableBody,
  TableCell,
  IconButton,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
  Slide,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import OrderPerPageButton from "./OrderPerPageButton";
import { perPageButtons } from "./utilities/constants";
import Input from "../../components/input";
import IcoBtn from "../../components/buttons/icoBtn";
import { CompactPicker } from "react-color";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] === a[orderBy]) return 0;
  return b[orderBy] < a[orderBy] ? -1 : 1;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Tagi",
    width: "300px",
  },
  {
    id: "color",
    numeric: false,
    disablePadding: false,
    label: "Kolor",
    width: "300px",
  },
  {
    id: "buttons",
    numeric: true,
    disablePadding: false,
    label: "",
    noSort: true,
  },
];

function EnhancedTableHead(props) {
  const {
    styles,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={styles.tabHead}>
      <TableRow>
        <TableCell className={styles.checkbox}>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all id" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ width: `${headCell.width || "auto"}` }}
          >
            {!headCell.noSort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={styles.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function useInput({ type, size }) {
  const [value, setValue] = useState("");
  const input = (
    <TableCell className={styles.inputCell}>
      <Input
        value={value}
        handleChange={(e) => {
          setValue(e.target.value);
        }}
        type={type}
        search={true}
        className={size}
      />
    </TableCell>
  );
  return [value, input];
}

export default function Tags(props) {
  const [table, setTable] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("short");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [searchName, setSearchName] = useInput({ type: "text" });
  const [searchColor, setSearchColor] = useInput({ type: "text" });
  const [deleteData, setDeleteData] = React.useState([]);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [editData, setEditData] = useState([]);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [colorPicker, setColorPicker] = useState("");
  const [colorText, setColorText] = useState("");
  const [error, setError] = useState(false);

  const popover = {
    position: "absolute",
    left: "22%",
    top: "-32px",
    // zIndex: "99999",
  };
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };
  const colors = [
    "#33691e",
    "#2e7d32",
    "#43a047",
    "#588f27",
    "#45bf55",
    "#bedb39",
    "#00305a",
    "#004b8d",
    "#1510f0",
    "#04bfbf",
    "#35478c",
    "#0288d1",
    "#440505",
    "#4c1b1b",
    "#d50000",
    "#ff2d00",
    "#f44336",
    "#d23600",
    "#fa5b0f",
    "#d95100",
    "#fd7400",
    "#f2b705",
    "#fa9600",
    "#0f8299",
    "#36175e",
    "#4c1273",
    "#4a148c",
    "#512da8",
    "#7b1fa2",
    "#9c27b0",
    "#2a2c2b",
    "#374140",
    "#546e7a",
    "#292a2b",
    "#1c1d21",
    "#44464a",
  ];

  //-------------------

  const getTags = async function () {
    props.loading(true);
    const response = await fetch(`${DBurl}/getTags/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      const tempData = [...json.data];
      setTable(tempData);
      props.loading(false);
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  const fetchSetTags = async function (id, name, color, ids, type) {
    props.loading(true);

    let data =
      type === "update"
        ? {
            id: id,
            name: name,
            color: color,
          }
        : type === "insert"
        ? {
            name: name,
            color: color,
          }
        : {
            ids: ids,
          };

    const response = await fetch(`${DBurl}/setTags/`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: type,
        data: data,
      }),
    });
    const json = await response.json();
    if (json.success) {
      getTags();
      if (json.data.return === "duplicate") {
        setOpenDialog(true);
        setDialogContent(
          `Tag o nazwie: <b>${json.data.duplicate_name}</b> istnieje już w bazie tagów.`
        );
        setDialogColor("warning");
      } else {
        setOpenDialog(true);
        setDialogContent(
          `${
            type === "update"
              ? "Tag uaktualniony dodany."
              : type === "insert"
              ? "Tag został dodany"
              : ids.length > 1
              ? "Tagi zostały usunięte"
              : "Tag został dodany"
          }`
        );
        setDialogColor("success");
      }
      setEditData([]);
      props.loading(false);
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  //-------------------

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDialog(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = table.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleColorClick = (e) => {
    setDisplayColorPicker(true);
  };

  const handleColorClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChangeComplete = (e) => {
    setColorPicker(e.hex);
    setEditData({ ...editData, color: e.hex });
  };

  const search = (tbl) => {
    return tbl.filter((row) => {
      return (
        String(row.name).toLowerCase().indexOf(searchName.toLowerCase()) > -1 &&
        String(row.color).toLowerCase().indexOf(searchColor.toLowerCase()) > -1
      );
    });
  };

  const paginate = (pageNumber) => setPage(pageNumber - 1);

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const openEdit = (row) => {
    const tempData = {
      id: row ? row.id : null,
      name: row ? row.name : null,
      color: row ? row.color : null,
    };
    row && setColorPicker(row.color);
    setEditData(tempData);
    setOpenModalEdit(true);
  };

  const colorContrast = (rgb) => {
    rgb = rgb.split(/\(([^)]+)\)/)[1].replace(/ /g, "");
    var r = parseInt(rgb.split(",")[0], 10),
      g = parseInt(rgb.split(",")[1], 10),
      b = parseInt(rgb.split(",")[2], 10),
      a;

    if (rgb.split(",")[3] !== null) {
      a = parseInt(rgb.split(",")[3], 10);
    }

    var contrast =
      (Math.round(r * 299) + Math.round(g * 587) + Math.round(b * 114)) / 1000;
    return contrast >= 170 ? "black" : "white";
  };

  const checkErrors = (obj) => {
    const hasError =
      obj.name === undefined ||
      obj.name === null ||
      obj.name === "" ||
      obj.color === undefined ||
      obj.color === null ||
      obj.color === "";
    setError(hasError);
    return hasError;
  };

  const cleanEdit = () => {
    setOpenModalEdit(false);
    setEditData([]);
    setEditData([]);
    setColorPicker("");
    setColorText("");
    setError(false);
  };

  //-------------------

  useEffect(() => {
    if (colorPicker) {
      let colorTmpToRBG = colorPicker.substring(1);
      let aRgbHex = colorTmpToRBG.match(/.{1,2}/g);
      let aRgb = [
        parseInt(aRgbHex[0], 16),
        parseInt(aRgbHex[1], 16),
        parseInt(aRgbHex[2], 16),
      ];

      let tmp = colorContrast(String(`rgb(${aRgb[0]},${aRgb[1]},${aRgb[2]})`));

      setColorText(tmp);
    }
  }, [colorPicker]);

  useEffect(() => {
    getTags();
  }, []);

  useEffect(() => {
    if (openModalEdit) {
      checkErrors(editData);
    }
  }, [editData, openModalEdit]);

  return (
    <>
      <Paper className={styles.paper}>
        <Title
          title={props.state.tabName}
          btnBox
          btnAdd
          addFunc={(e) => openEdit()}
          btnDelete={selected.length > 0 ? true : "disabled"}
          deleteFunc={(e) => setOpenModalDelete(true)}
        />
      </Paper>
      <TableContainer className={styles.stickyTable}>
        <Table
          stickyHeader={true}
          className={styles.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
          size={"small"}
        >
          <EnhancedTableHead
            styles={styles}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={table.length}
          />
          <TableBody className={styles.tabBody}>
            <TableRow>
              <TableCell></TableCell>
              {setSearchName}
              {setSearchColor}
              <TableCell></TableCell>
            </TableRow>

            {table.length > 0 &&
              stableSort(search(table), getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell className={styles.checkbox}>
                        <Checkbox
                          checked={isItemSelected}
                          color="primary"
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">
                        <div
                          style={{ display: "flex", cursor: "pointer" }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setOpenModalEdit(true);
                            openEdit(row);
                          }}
                        >
                          <div
                            className={stylesMod.tagItem}
                            style={{
                              position: "relative",
                              backgroundColor: row.color,
                              minHeight: "18px",
                              boxShadow: "0px 1px 6px 0px rgb(0 0 0 / 10%)",
                              cursor: "pointer",
                            }}
                          ></div>
                          {row.color}
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "5px",
                          }}
                        >
                          <div>
                            <IcoBtn
                              icon="Edit"
                              tooltip="Edytuj"
                              value={row}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setOpenModalEdit(true);
                                openEdit(row);
                              }}
                            />
                          </div>
                          <div>
                            <IcoBtn
                              icon="X"
                              tooltip="Usuń"
                              className="btn-delete"
                              value={row}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setDeleteData([row.id]);
                                setOpenModalDelete(true);
                              }}
                            />
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>

      <div className={styles.tableBottom}>
        <div>
          {perPageButtons.map(({ value, minNumber }) => (
            <OrderPerPageButton
              key={value}
              tableLength={table.length}
              minNumber={minNumber}
              value={value}
              setRowsPerPage={setRowsPerPage}
              rowsPerPage={rowsPerPage}
            />
          ))}
        </div>
        <div>
          <Pagination
            postsPerPage={rowsPerPage}
            totalPosts={table.length}
            paginate={paginate}
            page={page}
          />
        </div>
      </div>

      <Dialog
        open={openModalEdit}
        maxWidth="md"
        fullWidth={true}
        TransitionComponent={Transition}
        onClose={() => {
          cleanEdit();
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          {editData.id ? "Edycja" : "Dodaj nowy Tag"}
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              cleanEdit();
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ minHeight: "115px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {editData.id ? "Zmień" : "Dodaj"} nazwe i kolor taga:
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className={stylesMod.inp} style={{ position: "relative" }}>
                <label>Nazwa taga</label>
                <Input
                  className={`${stylesMod.inputText} ${
                    editData.name === "" ? stylesMod.error : ""
                  }`}
                  value={editData.name}
                  handleChange={(e) => {
                    setEditData({ ...editData, name: e.target.value });
                  }}
                  name="Dodaj tag"
                  placeholder="Dodaj nowy..."
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={stylesMod.inp} style={{ position: "relative" }}>
                <button
                  onClick={handleColorClick}
                  style={{
                    backgroundColor: colorPicker,
                    boxShadow: "0px 1px 6px 0px rgb(0 0 0 / 10%)",
                    border: `2px solid ${colorPicker || "rgb(21, 47, 66)"}`,
                  }}
                  className={BTNstyles.BTNColor}
                >
                  <span style={{ color: colorText }}>
                    {colorPicker !== ""
                      ? "Zmień kolor taga"
                      : "Wybierz kolor taga"}
                  </span>
                </button>
                {displayColorPicker ? (
                  <div style={popover}>
                    <div style={cover} onClick={handleColorClose} />
                    <CompactPicker
                      disableAlpha={true}
                      color={colorPicker}
                      colors={colors}
                      onChange={handleColorClose}
                      onChangeComplete={handleChangeComplete}
                      placement="topCenter"
                    />
                  </div>
                ) : null}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}></Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={BTNstyles.btn}
            onClick={(e) => {
              cleanEdit();
            }}
          >
            Anuluj
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={error}
            size="large"
            className={`${BTNstyles.btn} ${styles.green}`}
            onClick={() => {
              editData.id === null
                ? fetchSetTags(
                    editData.id,
                    editData.name,
                    editData.color,
                    [],
                    "insert"
                  )
                : fetchSetTags(
                    editData.id,
                    editData.name,
                    editData.color,
                    [],
                    "update"
                  );
              setOpenModalEdit(false);
            }}
          >
            {editData.id === null ? "Dodaj" : "Zapisz"}{" "}
            {editData.id === null ? (
              <Plus className={BTNstyles.downBtnIco} />
            ) : (
              <Edit2 className={BTNstyles.downBtnIco} />
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openModalDelete}
        TransitionComponent={Transition}
        onClose={() => {
          setDeleteData([]);
          setOpenModalDelete(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Usuń {deleteData.length > 0 ? "tag" : "tagi"}{" "}
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              setDeleteData([]);
              setOpenModalDelete(false);
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {deleteData.length > 0 ? (
            <DialogContentText id="alert-dialog-slide-description">
              Czy napewno chcesz usunąć tag:
              <div className={styles.colorBox}>
                {deleteData.map((e) => {
                  const tempList = table.find((el) => el.id === e);
                  return (
                    <>
                      <div className={styles.colorRow}>
                        <div
                          className={stylesMod.tagItem}
                          style={{
                            position: "relative",
                            backgroundColor: tempList.color,
                            minHeight: "18px",
                            boxShadow: "0px 1px 6px 0px rgb(0 0 0 / 10%)",
                          }}
                        ></div>
                        {tempList ? tempList.name : null}
                      </div>
                    </>
                  );
                })}
              </div>
            </DialogContentText>
          ) : (
            <DialogContentText id="alert-dialog-slide-description">
              Czy napewno chcesz usunąć tagi:
              <div className={styles.colorBox}>
                {selected.map((e) => {
                  const tempList = table.find((el) => el.id === e);
                  return (
                    <>
                      <div className={styles.colorRow}>
                        <div
                          className={stylesMod.tagItem}
                          style={{
                            position: "relative",
                            backgroundColor: tempList.color,
                            minHeight: "18px",
                            boxShadow: "0px 1px 6px 0px rgb(0 0 0 / 10%)",
                          }}
                        ></div>
                        {tempList ? tempList.name : null}
                      </div>
                    </>
                  );
                })}
              </div>
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={BTNstyles.btn}
            onClick={(e) => {
              setOpenModalDelete(false);
            }}
          >
            Anuluj
          </Button>
          <Button
            variant="contained"
            size="large"
            className={`${BTNstyles.btn} ${BTNstyles.err}`}
            onClick={() => {
              setOpenModalDelete(false);
              fetchSetTags(
                null,
                null,
                null,
                deleteData.length > 0 ? deleteData : selected,
                "delete"
              );
              setSelected([]);
              setDeleteData([]);
            }}
            color="primary"
          >
            Usuń <X className={BTNstyles.downBtnIco} />
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={dialogColor}>
          {dialogContent}
        </Alert>
      </Snackbar>
    </>
  );
}
