import React from 'react'

export default function E100(props) {
	const icon = (() => {
		return (
			<svg width={24} height={24.61} viewBox="0 0 111 82" fill='none' xmlns="http://www.w3.org/2000/svg">
				<path d="M71.0368 21.1113C62.0856 21.1113 54.5334 27.3649 52.3114 35.8393H89.756C87.5403 27.3714 79.9818 21.1113 71.0368 21.1113ZM71.0368 0C70.2455 0 68.1818 0 67.9286 0H11.0178C8.29573 0 5.85851 2.091 5.71291 4.87684C5.56098 7.851 7.86526 10.3122 10.733 10.3122H29.5471C35.0862 10.3122 38.6059 16.3968 35.9471 21.3841L35.9028 21.462C34.909 23.3257 33.0035 24.4881 30.9334 24.4881H19.0005C16.2784 24.4881 13.8412 26.5791 13.6956 29.365C13.5437 32.3391 15.848 34.8003 18.7157 34.8003H25.2424C28.4646 34.8003 31.0727 37.4822 31.0727 40.7876V41.0019C31.0727 42.2162 31.1233 43.4176 31.2246 44.6059C31.4272 46.9697 29.5787 48.9827 27.2681 48.9827C24.546 48.9827 22.1088 51.0737 21.9632 53.8596C21.8112 56.8337 24.1155 59.2949 26.9832 59.2949H27.8315C32.0666 59.2949 36.0738 61.3404 38.5426 64.8666C46.4557 76.1853 59.8446 83.2051 74.7402 81.8284C93.4403 80.101 108.684 64.8211 110.748 45.6774C113.413 20.936 94.6051 0 71.0368 0ZM95.4407 46.1514H52.305C55.1284 56.9311 66.5802 64.1198 78.5574 59.4118C80.8047 58.5286 82.7102 56.9896 84.4827 55.3142C86.6034 53.3076 89.9776 53.5219 91.826 55.8986C93.4656 58.0091 93.1175 61.1326 91.1993 62.9834C85.7172 68.2758 78.5638 71.1915 71.0242 71.1915C54.2611 71.1915 40.7266 56.7493 41.6319 39.3524C42.4232 24.1245 54.5777 11.6564 69.4226 10.8446C86.3755 9.91603 100.461 23.7998 100.461 40.9954C100.467 43.8461 98.2198 46.1514 95.4407 46.1514ZM5.90282 24.6764C2.42741 24.0011 -0.566898 27.0727 0.0914688 30.6377C0.458635 32.6184 2.03492 34.2418 3.97203 34.6184C7.44745 35.2938 10.4418 32.2222 9.78339 28.6571C9.41622 26.67 7.83361 25.0466 5.90282 24.6764Z" fill={props.fill}></path>
			</svg>
		)
	})()
	return icon
}
