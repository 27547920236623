import React, { useState, useEffect, useMemo, useRef } from "react";
import Select from "react-select";
import { useQuery } from "react-query";
import {
  MapPin,
  Move,
  XCircle,
  ArrowUpCircle,
  Copy,
  ArrowDownCircle,
} from "react-feather";
import { Grid } from "@material-ui/core/";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DatePicker from "react-datepicker";
import plLocale from "date-fns/locale/pl";
import moment from "moment";

import { AddressSearch } from "components/addressSearch";

import { countries } from "data/countries";
import { getBorderCrossings, getBorderCrossingsFavorites } from "api/endpoints";
import {
  filterCrossings,
  translateCountries,
} from "components/BorderCrossings/utils";
import { parseSelectOptionsWithoutDuplicates } from "components/TmsSelect/utils";

import styles from "../trace.module.css";
import stylesVersion from "styles/version_s.module.css";
import "react-datepicker/dist/react-datepicker.css";

const TraceStop = ({
  data,
  trace,
  updateValue,
  handleTrace,
  propKey,
  error,
  icon,
  setType,
  user_s,
  type,
  newDate,
  cargoIndex,
  handleTraceNew,
  CustomInput,
  copyTrace,
  moveTrace,
  removeTrace,
  user,
  setDialog,
  getMinDate,
  getMaxDate,
  getMinTime,
  getMaxTime,
  handleTypeChange,
  typeOptions,
  setLabelForSubtype,
  isPreview,
}) => {
  const [stopIcon, setStopIcon] = React.useState(
    <MapPin className={styles.mainIco} />
  );
  const [borderCrossingsOptions, setBorderCrossingsOptions] = useState([]);
  const [origin, setOrigin] = useState();
  const [destination, setDestination] = useState();

  const countriesBorders = useMemo(() => {
    if (data.subtype === "borderCrossing") {
      const tracePrev = trace[propKey - 1];
      const traceNext = trace[propKey + 1];

      if (tracePrev && traceNext) {
        const countryFrom =
          tracePrev?.warehouse?.address?.country || tracePrev?.address?.country;
        const countryTo =
          traceNext?.warehouse?.address?.country || traceNext?.address?.country;

        if (countryFrom && countryTo) {
          const translateCountry = (polName) => {
            const foundCountry = countries.find(
              (country) => country.label === polName
            );
            return foundCountry ? foundCountry.eng_name : polName;
          };

          if (countryFrom?.length > 0 && countryTo?.length > 0) {
            return {
              origin: translateCountry(countryFrom),
              destination: translateCountry(countryTo),
            };
          }

          return null;
        }

        return null;
      }

      return null;
    }
  }, [data, trace]);

  const showBorderCrossing = useMemo(() => {
    if (origin && destination) {
      return true;
    }
    return false;
  }, [origin, destination]);

  const translateCountryToEng = (polName) => {
    const foundCountry = countries.find((country) => country.label === polName);
    return foundCountry ? foundCountry.eng_name : polName;
  };

  const engOrigin = translateCountryToEng(origin?.value);
  const engDestination = translateCountryToEng(destination?.value);

  useQuery(
    [
      "borderCrossingsTrace",
      engOrigin,
      engDestination,
      trace,
      showBorderCrossing,
    ],
    () => getBorderCrossings(engOrigin, engDestination),
    {
      enabled: data.subtype === "borderCrossing" && showBorderCrossing,
      onSuccess: (borderCrossings) => {
        if (borderCrossings?.length > 0) {
          setBorderCrossingsOptions(
            borderCrossings?.map((borderCrossing) => ({
              ...borderCrossing,
              label: borderCrossing.name,
              value: borderCrossing.id,
            }))
          );
        }
      },
    }
  );

  const { data: borderCrossings, isLoading: borderCrossingsLoading } = useQuery(
    ["borderCrossings"],
    () => getBorderCrossings(),
    { enabled: data.subtype === "borderCrossing" }
  );

  const filtredCrossings =
    borderCrossings &&
    filterCrossings({
      crossings: translateCountries(borderCrossings),
      origin,
      destination,
    });

  const crossingFromSelectOptions =
    filtredCrossings &&
    parseSelectOptionsWithoutDuplicates({
      data: filtredCrossings,
      label: "origin",
      value: "origin",
      image: "origin_iso2",
    });

  const crossingToSelectOptions =
    filtredCrossings &&
    parseSelectOptionsWithoutDuplicates({
      data: filtredCrossings,
      label: "destination",
      value: "destination",
      image: "destination_iso2",
    });

  const { data: favoritesBorderCrossings } = useQuery(
    ["favoritesBorderCrossingsTrace"],
    () => getBorderCrossingsFavorites(),
    {
      enabled: data.subtype === "borderCrossing",
      onError: (error) => {
        setDialog({ isOpen: true, type: "error", content: error.message });
      },
    }
  );

  const updateBorderCrossing = (borderCrossing) => {
    updateValue(`trace[${propKey}].border_crossing`, {
      ...borderCrossing,
      label: borderCrossing.name,
      value: borderCrossing.id,
    });
    updateValue(`trace[${propKey}].address.latitude`, borderCrossing.latitude);
    updateValue(
      `trace[${propKey}].address.longitude`,
      borderCrossing.longitude
    );
    updateValue(
      `trace[${propKey}].address.country_code`,
      borderCrossing.origin_iso2
    );
    updateValue(`trace[${propKey}].address.city`, borderCrossing.name);
    updateValue(
      `trace[${propKey}].address.address_display_name`,
      borderCrossing.name
    );
  };

  const updateToFavoriteCrossing = () => {
    if (favoritesBorderCrossings) {
      const favoriteCrossing = filterCrossings({
        crossings: favoritesBorderCrossings?.map(
          (item) => item.border_crossing
        ),
        origin: { value: countriesBorders?.origin },
        destination: { value: countriesBorders?.destination },
      })[0];

      if (favoriteCrossing) {
        updateBorderCrossing(favoriteCrossing);
      }
    }
  };

  const handleClearFunction = () => {
    updateValue(`trace[${propKey}].border_crossing`, {});
  };

  useEffect(() => {
    const translateCountryToPL = (engName) => {
      const foundCountry = countries.find(
        (country) => country.eng_name === engName
      );
      return foundCountry ? foundCountry.label : engName;
    };

    if (countriesBorders?.origin && countriesBorders?.destination) {
      const originPL = translateCountryToPL(countriesBorders?.origin);
      const destinationPL = translateCountryToPL(countriesBorders?.destination);

      setOrigin({
        label: originPL,
        value: originPL,
      });
      setDestination({
        label: destinationPL,
        value: destinationPL,
      });
    }
  }, [countriesBorders?.origin, countriesBorders?.destination]);

  useEffect(() => {
    if (data.subtype === "borderCrossing") {
      if (!data.border_crossing) {
        updateToFavoriteCrossing();
      }
    }
  }, [trace, favoritesBorderCrossings]);

  useEffect(() => {
    if (data.subtype === "borderCrossing") {
      if (!data.border_crossing?.id) {
        updateValue(
          `trace[${propKey}].border_crossing.id`,
          data.border_crossing?.value
        );
        return;
      }

      if (
        countriesBorders?.origin !== data.border_crossing?.origin ||
        countriesBorders?.destination !== data.border_crossing?.destination
      ) {
        updateToFavoriteCrossing();
      }
    }
  }, [countriesBorders?.origin, countriesBorders?.destination]);

  const showAddress = useMemo(() => {
    if (data.subtype === "borderCrossing") {
      if (data.manual_adress) {
        return true;
      }
      return false;
    }
    return true;
  }, [data, countriesBorders]);

  const showAddressCheckbox = useMemo(() => {
    if (data.subtype === "borderCrossing") {
      if (Boolean(countriesBorders)) {
        return true;
      }
      return false;
    }
    return false;
  }, [data, countriesBorders]);

  useEffect(() => {
    if (!!data?.subtype) {
      handleTrace(
        {
          target: {
            name: "subtype",
            value: data?.subtype,
          },
        },
        propKey
      );
      setStopIcon(icon(data?.subtype));
      setType(setLabelForSubtype(data?.subtype));
    }
  }, [data?.subtype]);

  const filterTime = () => {
    if (!newDate) {
      return false;
    }

    return true;
  };

  return (
    <form
      style={{ backgroundColor: data.isBlocked ? "#d7f5d7" : "" }}
      className={`${styles.traceBox} ${styles.sm}`}
    >
      <div className={styles.moveBox}></div>
      <Move className={styles.moveIcon} />
      <div className={`${user_s ? stylesVersion.onlyfull : ""}`}>
        {stopIcon}
      </div>
      <Grid container spacing={2} className={styles.tBox}>
        <Grid item xs={2}>
          <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
            <div className={styles.inp}>
              <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                Typ
              </label>
              <Select
                isDisabled={user_s || isPreview}
                className={`select ${user_s ? stylesVersion.onlyfull : ""}`}
                value={type}
                options={typeOptions}
                classNamePrefix="selectPrefix"
                onChange={(option) => {
                  handleTypeChange(option);
                }}
                name="subtype"
                placeholder="Wybierz typ"
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused && "#142f42",
                    color: state.isFocused && "#fff",
                  }),
                }}
              />
            </div>
          </Tooltip>
        </Grid>

        {!showAddress && (
          <>
            <Grid item xs={2}>
              <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
                <div className={styles.inp}>
                  <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                    Z:
                  </label>
                  <Select
                    isDisabled={user_s || isPreview}
                    className={`select ${user_s ? stylesVersion.onlyfull : ""}`}
                    value={origin}
                    options={crossingFromSelectOptions}
                    classNamePrefix="selectPrefix"
                    onChange={(option) => {
                      if (option === null || option === undefined) {
                        handleClearFunction();
                      }
                      setOrigin(option);
                    }}
                    isClearable
                    name="borderCrossing"
                    placeholder="Wybierz kraj"
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        border: "1px solid #142f42",
                        boxShadow: "none",
                        "&:hover": {
                          border: "1px solid #142f42",
                        },
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused && "#142f42",
                        color: state.isFocused && "#fff",
                      }),
                    }}
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={2}>
              <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
                <div className={styles.inp}>
                  <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                    Do:
                  </label>
                  <Select
                    isDisabled={user_s || isPreview}
                    className={`select ${user_s ? stylesVersion.onlyfull : ""}`}
                    value={destination}
                    options={crossingToSelectOptions}
                    classNamePrefix="selectPrefix"
                    onChange={(option) => {
                      if (option === null || option === undefined) {
                        handleClearFunction();
                      }
                      setDestination(option);
                    }}
                    isClearable
                    name="borderCrossing"
                    placeholder="Wybierz kraj"
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        border: "1px solid #142f42",
                        boxShadow: "none",
                        "&:hover": {
                          border: "1px solid #142f42",
                        },
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused && "#142f42",
                        color: state.isFocused && "#fff",
                      }),
                    }}
                  />
                </div>
              </Tooltip>
            </Grid>
            {showBorderCrossing && (
              <Grid item xs={3}>
                <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
                  <div className={styles.inp}>
                    <label
                      className={`${user_s ? stylesVersion.onlyfull : ""}`}
                    >
                      Przejście graniczne
                    </label>
                    <Select
                      isDisabled={user_s || isPreview}
                      className={`select ${
                        user_s ? stylesVersion.onlyfull : ""
                      }`}
                      value={data.border_crossing}
                      options={borderCrossingsOptions}
                      classNamePrefix="selectPrefix"
                      onChange={(borderCrossing) => {
                        updateBorderCrossing(borderCrossing);
                      }}
                      name="borderCrossing"
                      placeholder="Wybierz przejście"
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          border: "1px solid #142f42",
                          boxShadow: "none",
                          "&:hover": {
                            border: "1px solid #142f42",
                          },
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused && "#142f42",
                          color: state.isFocused && "#fff",
                        }),
                      }}
                    />
                  </div>
                </Tooltip>
              </Grid>
            )}
          </>
        )}
        {showAddress && (
          <Grid item xs={3}>
            <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
              <div className={styles.inp}>
                <AddressSearch
                  user_s={user_s}
                  token={user.csrf_token}
                  setAddress={(e) =>
                    handleTrace(e, propKey, false, false, true)
                  }
                  dense
                  value={
                    (data?.address && data?.address?.address_display_name) ||
                    null
                  }
                  error={error}
                  isPreview={isPreview}
                />
              </div>
            </Tooltip>
          </Grid>
        )}
        {showAddressCheckbox && (
          <Grid item xs={2}>
            <div className={styles.manualAdress}>
              <input
                type="checkbox"
                disabled={user_s || isPreview}
                className={`${styles.check} ${
                  user_s ? stylesVersion.onlyfull : ""
                }`}
                onChange={(e) => {
                  if (propKey === 0) {
                    if (e.target.checked) {
                      updateValue("kilometers", {});
                      updateValue("cost", "");
                    }
                  }
                  handleTrace(e, propKey, cargoIndex, true);
                }}
                name="manual_adress"
                checked={data?.manual_adress || false}
              />
              <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                Ustaw adres ręcznie
              </label>
            </div>
          </Grid>
        )}
        <Grid item xs={2}>
          <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
            <div className={styles.inp}>
              <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                Data
              </label>
              <DatePicker
                disabled={user_s || isPreview}
                dateFormat="dd.MM.yyyy HH:mm"
                selected={newDate}
                timeCaption="Czas"
                minDate={getMinDate(propKey)}
                maxDate={getMaxDate(propKey)}
                minTime={getMinTime(newDate, propKey)}
                maxTime={getMaxTime(newDate, propKey)}
                filterTime={filterTime}
                onChange={(e) => {
                  let newD = moment(e).valueOf();
                  let newDS = moment(newD).format("yyyy-MM-DDTHH:mm");
                  handleTraceNew(newDS, propKey, "date");
                }}
                showTimeSelect
                timeIntervals={10}
                locale={plLocale}
                name="date"
                customInput={<CustomInput error={false} />}
                placeholderText={"dd.mm.rrrr gg:mm"}
              />
            </div>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
            <div className={styles.inp}>
              <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                {type.value === "refueling"
                  ? "Numer karty paliwowej"
                  : "Komentarz"}
              </label>
              <input
                className={`${styles.input} ${
                  user_s ? stylesVersion.onlyfull : ""
                }`}
                disabled={user_s || isPreview}
                value={data?.comment || ""}
                onChange={(e) => {
                  handleTrace(e, propKey);
                }}
                name="comment"
              />
            </div>
          </Tooltip>
        </Grid>
      </Grid>
      <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
        <div className={styles.corner}>
          <IconButton
            disabled={user_s || isPreview}
            className={`${styles.btnIcon} ${
              user_s ? stylesVersion.onlyfull : ""
            }`}
            value={propKey || ""}
            onClick={() => {
              copyTrace(propKey);
            }}
          >
            <Copy className={styles.primary} size={18} />
          </IconButton>
          <IconButton
            disabled={user_s || isPreview}
            className={`${styles.btnIcon} ${
              user_s ? stylesVersion.onlyfull : ""
            }`}
            value={propKey || ""}
            onClick={() => {
              removeTrace(propKey);
            }}
          >
            <XCircle className={styles.remove} size={18} />
          </IconButton>
        </div>
      </Tooltip>
    </form>
  );
};

export default TraceStop;
