import React, { useState, useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { XCircle } from "react-feather";
import ClipLoader from "react-spinners/ClipLoader";
import { IconButton } from "@material-ui/core";
import TmsSelect from "components/TmsSelect";
import TmsLoadingSpinner from "components/TmsLoadingSpinner/TmsLoadingSpinner";

import ResetModal from "./ResetModal";

import { generateTraces } from "containers/order/newOrder/newOrder.data";
import { getRoutesFavourites, getContractors } from "api/endpoints";
import { UserContext } from "contexts/userContext";
import { newOrderDefaultValues } from "containers/order/newOrder/newOrder.data";

import "./styles.sass";

const SelectFavoriteRoutes = ({
  values,
  contractorId,
  updateValue,
  setDialog,
  isPreview,
}) => {
  const [favoriteRoutesOptions, setFavoritesRoutesOption] = useState([]);
  const [resetModal, setResetModal] = useState({ isOpen: false });
  const { user } = useContext(UserContext);
  const { data: contractors } = useQuery("contractors", getContractors);

  const { isLoading, isFetching } = useQuery(
    ["getRoutesFavourites", contractorId],
    () =>
      getRoutesFavourites({
        filter_id_firm: user.id_firm,
        filter_id_contractor: contractorId,
      }),
    {
      onSuccess: (favoriteRoutes) => {
        if (favoriteRoutes) {
          setFavoritesRoutesOption(
            favoriteRoutes.map((route) => ({
              label: route?.name,
              value: route?.id,
              ...route,
            }))
          );
        } else {
          setDialog({
            isOpen: true,
            type: "error",
            content: "Nie mozna pobrać domyślnych tras",
          });
        }
      },
    }
  );

  const updateContractor = (contractorId) => {
    const contractor = contractors?.filter(
      (contractor) => contractor.id === contractorId
    )[0];

    if (contractor) {
      updateValue("contractor", {
        label: contractor?.name,
        value: contractor?.id,
      });
    } else {
      setDialog({
        isOpen: true,
        type: "error",
        content: "Klient wybranej trasy domyślnej nie istnieje.",
      });
    }
  };

  const updateRoute = (option) => {
    updateContractor(option.id_contractor);

    option.locations.forEach((location) => {
      delete location.date;
      if (location.cargo) location.cargo = newOrderDefaultValues.cargo;
    });

    updateValue("trace", option.locations);
    updateValue("route_id", option.route.id);
    updateValue("favoriteRoute", { label: option?.name, value: option?.id });
    updateValue("newRoute", true);
  };

  const handleSelect = (option) => {
    if (option.locations?.length > 0) {
      updateRoute(option);
    } else {
      setDialog({
        isOpen: true,
        type: "error",
        content: "Nie można załadować trasy domyślnej.",
      });
    }
  };

  const handleReset = () => {
    setResetModal({ isOpen: false });
    updateValue("trace", generateTraces());
    updateValue("favoriteRoute", {});
    updateValue("route_id", null);
    updateValue("newRoute", false);
  };

  const openResetModal = () => {
    setResetModal({ isOpen: true, handleReset });
  };

  useEffect(() => {
    if (values.updateFavouriteRoute) {
      setFavoritesRoutesOption([
        ...favoriteRoutesOptions,
        values.newFavoriteRoute,
      ]);
      updateValue("favoriteRoute", values.newFavoriteRoute);
      updateValue("newRoute", true);
      updateValue("newFavoriteRoute", undefined);
      updateValue("updateFavouriteRoute", false);
    }
  }, [values.updateFavouriteRoute]);

  if (isLoading || isFetching) {
    return <TmsLoadingSpinner />;
  }

  return (
    <>
      <div className="select-routes">
        <label className="select-routes__label">Wybierz trase</label>
        <TmsSelect
          options={favoriteRoutesOptions}
          isDisabled={isLoading || isPreview}
          name="favorite-routes"
          className="select"
          value={values.favoriteRoute}
          onChange={(option) => {
            option && handleSelect(option);
          }}
          noOptionsMessage={"Brak dostępnych tras dla wybranego klienta"}
        />
        {values.favoriteRoute &&
          Object.keys(values.favoriteRoute).length > 0 && (
            <IconButton
              className="select-routes__reset-button"
              onClick={openResetModal}
            >
              <XCircle className="select-routes__reset-icon" size={18} />
            </IconButton>
          )}
      </div>
      <ResetModal resetModal={resetModal} setResetModal={setResetModal} />
    </>
  );
};

export default SelectFavoriteRoutes;
