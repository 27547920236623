import React from 'react'
import mui from 'material-ui';
import {ReactComponent as Sleep} from 'material-ui-community-icons/icons/sleep';
import {ReactComponent as TrafficLight} from 'material-ui-community-icons/icons/traffic-light';
import {ReactComponent as Ferry} from 'material-ui-community-icons/icons/ferry';
import {ReactComponent as Alert} from 'material-ui-community-icons/icons/alert';
import { X, Clock, AlertTriangle } from 'react-feather';
import { ReactComponent as WorkIcon } from '../img/tacho/tacho_Jazda.svg'
import { ReactComponent as RestIcon } from '../img/tacho/Ikony_Rest.svg'
import { ReactComponent as ImportIcon } from '../img/tacho/Ikony_Import.svg'
import { ReactComponent as EksportIcon } from '../img/tacho/Ikony_eksport.svg'
import { LuForklift } from "react-icons/lu";
import { GiTruck } from "react-icons/gi";
import styles from './status.module.css'


export default function StatusDriver(props) {

  const police = <svg viewBox="0 0 24 24" style={{color: '#0a45b5'}}><path fill="currentColor" d="M11 0V3H13V0H11M7.88 1.46L6.46 2.87L8.59 5L10 3.58L7.88 1.46M16.12 1.46L14 3.58L15.41 5L17.54 2.88L16.12 1.46M12 5A2 2 0 0 0 10 7V8H6.5C5.84 8 5.28 8.42 5.08 9L3 15V23A1 1 0 0 0 4 24H5A1 1 0 0 0 6 23V22H18V23A1 1 0 0 0 19 24H20A1 1 0 0 0 21 23V15L18.92 9C18.72 8.42 18.16 8 17.5 8H14V7A2 2 0 0 0 12 5M6.5 9.5H17.5L19 14H5L6.5 9.5M6.5 16A1.5 1.5 0 0 1 8 17.5A1.5 1.5 0 0 1 6.5 19A1.5 1.5 0 0 1 5 17.5A1.5 1.5 0 0 1 6.5 16M17.5 16A1.5 1.5 0 0 1 19 17.5A1.5 1.5 0 0 1 17.5 19A1.5 1.5 0 0 1 16 17.5A1.5 1.5 0 0 1 17.5 16Z"></path></svg>
  const { num } = props

  if (typeof num !== 'number') {
    // console.log('StatusDriver function must get a number')
  }
  const result = (number) => {
    switch (number) {
      case 1: {
        return {
          icon: <WorkIcon className={styles.ico}/>,
          name: 'Jazda',
          id: 1
        }
      }
      case 2: {
        return {
          icon: <RestIcon className={styles.ico}/>,
          name: 'Odpoczynek',
          id: 2
        }
      }
      case 3: {
        return {
          icon: <RestIcon className={styles.ico}/>,
          name: 'Długi odpoczynek',
          id: 3
        }
      }
      case 4: {
        return {
          icon: <WorkIcon className={styles.ico} />,
          name: 'Dojazd',
          id: 4
        }
      }
      case 5: {
        return {
          icon: <WorkIcon className={styles.ico} />,
          name: 'Podjęcie zlecenia',
          id: 5
        }
      }
      case 6: {
        return {
          icon: <Clock className={styles.ico}
          />,
          name: 'Oczekiwanie na załadunek',
          id: 6
        }
      }

      case 7: {
        return {
          icon: <ImportIcon />,
          name: 'Załadunek',
          id: 7
        }
      }
      case 8: {
        return {
          icon: <Clock />,
          name: 'Oczekiwanie na rozładunek',
          id: 8
        }
      }
      case 9: {
        return {
          icon: <EksportIcon />,
          name: 'Rozładunek',
          id: 9
        }
      }
      case 10: {
        return {
          icon: <TrafficLight />,
          name: 'Korek',
          id: 10
        }
      }
      case 11: {
        return {
          icon: <AlertTriangle />,
          name: 'Awaria pojazdu',
          id: 11
        }
      }
      case 12: {
        return {
          icon: <Ferry />,
          name: 'Prom',
          id: 12
        }
      }
      case 13: {
        return {
          icon: <WorkIcon />,
          name: 'Zakończenie zlecenia',
          id: 13
        }
      }
      case 19: {
        return {
          icon: police,
          name: 'Kontrola drogowa',
          id: 19
        }
      }
      case 20: {
        return {
          icon: <LuForklift />,
          name: 'Wymiana palet',
          id: 20
        }
      }
      case 21: {
        return {
          icon: <LuForklift />,
          name: 'Wymiana palet',
          id: 21
        }
      }
      case 22: {
        return {
          icon: <LuForklift />,
          name: 'Wymiana palet',
          id: 22
        }
      }
      case 23: {
        return {
          icon: <GiTruck />,
          name: 'Zmiana zestawu',
          id: 23
        }
      }
      case 24: {
        return {
          icon: <></>,
          name: 'Odprawa celna',
          id: 24
        }
      }
      default: {
        return {
          icon: <X className={`${styles.red} ${styles.ico}`}/>,
          name: 'Brak danych',
          // id: 1
        }
      }
    }

  }


  return (
    <>
      <div className={styles.statusParent}>
        {result(num).icon}  <span className={styles.text}>
          {result(num).name} 
        </span> 
      </div>
    </>
  )



}
