import React, { useState, useEffect, useMemo } from "react";
import Select, { components } from "react-select";
import moment from "moment";
import {
  MapPin,
  Move,
  XCircle,
  ArrowUpCircle,
  Copy,
  ArrowDownCircle,
  Plus,
  Upload,
  Download,
} from "react-feather";
import DatePicker from "react-datepicker";
import { Button, Grid } from "@material-ui/core/";
import plLocale from "date-fns/locale/pl";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { AddressSearch } from "components/addressSearch";
import CargoTrace from "./CargoTrace";
import { countries } from "data/countries";

import stylesMod from "styles/newOrEdit.module.css";
import stylesVersion from "styles/version_s.module.css";
import styles from "../trace.module.css";
import "react-datepicker/dist/react-datepicker.css";

const TraceWarehouse = ({
  data,
  trace,
  values,
  user,
  updateValue,
  index,
  cargoIndex,
  handleTrace,
  isShowRequiredFields,
  toCostValues,
  user_s,
  icon,
  newDate,
  itemType,
  addTraceCargo,
  copyTrace,
  removeTrace,
  removeTraceCargo,
  goodsOptions,
  addTab,
  saveTab,
  setOpenModalNewWarehouse,
  checkedGoods,
  setOpenModalShowPoint,
  setTraceIndex,
  setTraceData,
  dialog,
  setDialog,
  handleTraceNew,
  propKey,
  CustomInput,
  warehousesOptions,
  clearHooksState,
  error,
  editFavouritesRoutes,
  getMinDate,
  getMaxDate,
  getMinTime,
  getMaxTime,
  type,
  typeOptions,
  handleTypeChange,
  isPreview,
}) => {
  const [warehouse, setWarehouse] = useState();
  const [apartment_no, setApartment_no] = useState(data?.address?.apartment_no);
  const [firm_name, setFirmName] = useState(data?.address?.firm_name);
  const [dataState, setDataState] = useState({});

  useEffect(() => {
    setDataState(data);
  }, [data]);

  useEffect(() => {
    if (clearHooksState === true) {
      setWarehouse(data?.warehouse);
    }
  }, [clearHooksState]);

  useEffect(() => {
    if (data.warehouse === undefined) {
      setWarehouse(undefined);
    }
  }, [data?.warehouse]);

  useEffect(() => {
    if (data?.warehouse && data?.warehouse?.id) {
      setWarehouse(data?.warehouse);
    }
  }, [data?.warehouse, warehouse]);

  useEffect(() => {
    if (data?.manual_adress === true) {
      if (data?.warehouse) {
        data.warehouse_temp = data?.warehouse;
        delete data?.warehouse;
      }
    }

    if (data?.manual_adress === false) {
      if (data?.address !== undefined) {
        delete data?.address;
      }
      if (data?.warehouse_temp) {
        data.warehouse = data?.warehouse_temp;
        delete data?.warehouse_temp;
      }
    }
  }, [data?.manual_adress]);

  useEffect(() => {
    if (data?.address?.apartment_no) {
      setApartment_no(data?.address?.apartment_no);
    }
  }, [data?.address]);

  useEffect(() => {
    if (data?.address?.firm_name) {
      setFirmName(data?.address?.firm_name);
    }
  }, [data?.firm_name]);

  useEffect(() => {
    if (data?.type === "loading" || data?.type === "unloading") {
      if (warehouse !== undefined && warehousesOptions.length) {
        const wareHouseDetail = warehousesOptions.find(
          (e) => e.id === warehouse?.id
        );
        if (!!wareHouseDetail) {
          handleTrace(
            {
              target: {
                name: "warehouse",
                value: wareHouseDetail,
              },
            },
            propKey
          );
        }
        setWarehouse(wareHouseDetail);
      }
    }
  }, [data?.type, warehouse, warehousesOptions]);

  const ValueContainer = ({ children, selectProps, ...props }) => {
    return (
      <components.ValueContainer {...props} className={styles.ValueContainer}>
        {!editFavouritesRoutes && (
          <div className={styles.clientAddParent}>
            <IconButton
              disabled={user_s}
              className={`${styles.btnTraceIcon} ${
                user_s ? stylesVersion.onlyfull : ""
              }`}
              onClick={() => {
                selectProps.selectProps(
                  selectProps.warehouses ? true : "Goods",
                  false,
                  selectProps.saveTab()
                );
              }}
            >
              <Plus className={styles.clientAdd} />
            </IconButton>
          </div>
        )}
        {children}
      </components.ValueContainer>
    );
  };

  const goodsOptionsWithoutSelected = useMemo(
    () =>
      goodsOptions?.filter(
        (good) => !data.cargo?.some((cargo) => cargo.id === good.id)
      ),
    [data?.cargo, goodsOptions]
  );

  const deleteSeconds = (date) => {
    const dotIndex = date.indexOf(".");

    if (dotIndex !== -1) {
      return date.substring(0, dotIndex);
    }

    return date;
  };

  const tileColor = useMemo(() => {
    if (data.type === "loading") {
      return "#129865";
    }

    if (data.type === "unloading") {
      return "#ed3c22";
    }
  }, [data.type]);

  const filterTime = () => {
    if (!newDate) {
      return false;
    }

    return true;
  };

  const getCountryCode = (countryName) => {
    const country = countries.filter(
      (country) => country.label === countryName
    )[0];

    return country.code.toLowerCase();
  };

  const getCountryFlag = useMemo(() => {
    let countryCode = null;

    if (data?.manual_adress && data?.address?.country) {
      countryCode = getCountryCode(data.address.country);
    }

    if (!data?.manual_adress && data?.warehouse?.address?.country) {
      countryCode = getCountryCode(data?.warehouse?.address?.country);
    }

    if (!countryCode) {
      return null;
    }

    return (
      <div
        style={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
          marginLeft: "12px",
        }}
      >
        <img style={{ width: "19px" }} src={`img/flags/${countryCode}.gif`} />
        <label>{countryCode.toUpperCase()}</label>
      </div>
    );
  }, [data?.address, data?.warehouse, data?.manual_adress]);

  return (
    <>
      <form
        style={{
          borderColor: tileColor,
          backgroundColor: data.isBlocked ? "#d7f5d7" : "",
        }}
        className={styles.traceBox}
      >
        <div
          style={{
            backgroundColor: tileColor,
          }}
          className={styles.moveBox}
        ></div>
        <Move className={styles.moveIcon} />
        <Grid container spacing={2} className={styles.tBox}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <div className={styles.newRow}>
                  <div>
                    {getCountryFlag}
                    {icon(itemType)}
                  </div>
                  <div>
                    <Grid container spacing={2}>
                      {data.isDriverStatus && (
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={2}>
                              <div className={stylesMod.inp}>
                                <label>
                                  {deleteSeconds(
                                    data.driverStatus.ts_status_start
                                  )}
                                </label>
                                <span style={{ fontSize: 14, fontWeight: 500 }}>
                                  Zrealizowano
                                </span>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={2}>
                            <Tooltip
                              title={
                                user_s ? "Dostępne tylko w wersji pełnej" : ""
                              }
                            >
                              <div className={styles.inp}>
                                <label
                                  className={`${
                                    user_s ? stylesVersion.onlyfull : ""
                                  }`}
                                >
                                  Typ
                                </label>
                                <Select
                                  isDisabled={user_s || isPreview}
                                  className={`select ${
                                    user_s ? stylesVersion.onlyfull : ""
                                  }`}
                                  value={type}
                                  options={typeOptions}
                                  classNamePrefix="selectPrefix"
                                  onChange={(option) => {
                                    handleTypeChange(option);
                                  }}
                                  name="subtype"
                                  placeholder="Wybierz typ"
                                  styles={{
                                    control: (base, state) => ({
                                      ...base,
                                      border: "1px solid #142f42",
                                      boxShadow: "none",
                                      "&:hover": {
                                        border: "1px solid #142f42",
                                      },
                                    }),
                                    option: (provided, state) => ({
                                      ...provided,
                                      backgroundColor:
                                        state.isFocused && "#142f42",
                                      color: state.isFocused && "#fff",
                                    }),
                                  }}
                                />
                              </div>
                            </Tooltip>
                          </Grid>
                          {!editFavouritesRoutes && (
                            <Grid item xs={2}>
                              <Tooltip
                                title={
                                  user_s ? "Dostępne tylko w wersji pełnej" : ""
                                }
                              >
                                <div className={styles.inp}>
                                  <label
                                    className={`${
                                      user_s ? stylesVersion.onlyfull : ""
                                    }`}
                                  >
                                    Data{" "}
                                    {data.type === "loading"
                                      ? "załadunku*"
                                      : "rozładunku*"}
                                  </label>
                                  <DatePicker
                                    disabled={user_s || isPreview}
                                    dateFormat="dd.MM.yyyy HH:mm"
                                    minDate={getMinDate(index)}
                                    maxDate={getMaxDate(index)}
                                    minTime={getMinTime(newDate, index)}
                                    maxTime={getMaxTime(newDate, index)}
                                    timeCaption="Czas"
                                    filterTime={filterTime}
                                    selected={newDate}
                                    onChange={(date) => {
                                      let newD = moment(date).valueOf();
                                      let newDS =
                                        moment(newD).format("yyyy-MM-DDTHH:mm");
                                      handleTraceNew(newDS, propKey, "date");
                                    }}
                                    style={{
                                      backgroundColor: "red !important",
                                    }}
                                    showTimeSelect
                                    timeIntervals={10}
                                    locale={plLocale}
                                    name="date"
                                    placeholderText={"dd.mm.rrrr gg:mm"}
                                    customInput={
                                      <CustomInput
                                        error={user_s ? false : true}
                                        isShowRequiredFields={
                                          isShowRequiredFields
                                        }
                                        traceIndex={index}
                                      />
                                    }
                                  />
                                </div>
                              </Tooltip>
                            </Grid>
                          )}

                          <Grid item xs={2}>
                            <div className={styles.manualAdress}>
                              <input
                                type="checkbox"
                                disabled={user_s || isPreview}
                                className={`${styles.check} ${
                                  user_s ? stylesVersion.onlyfull : ""
                                }`}
                                onChange={(e) => {
                                  if (propKey === 0) {
                                    if (e.target.checked) {
                                      updateValue("kilometers", {});
                                      updateValue("cost", "");
                                    }
                                  }
                                  handleTrace(e, propKey, cargoIndex, true);
                                }}
                                name="manual_adress"
                                checked={data?.manual_adress || false}
                              />
                              <label
                                className={`${
                                  user_s ? stylesVersion.onlyfull : ""
                                }`}
                              >
                                Ustaw adres ręcznie
                              </label>
                            </div>
                          </Grid>
                          <Grid item xs={2}>
                            <Tooltip
                              title={
                                user_s ? "Dostępne tylko w wersji pełnej" : ""
                              }
                            >
                              <div className={styles.manualAdress}>
                                <input
                                  disabled={user_s || isPreview}
                                  type="checkbox"
                                  className={`${styles.check} ${
                                    user_s ? stylesVersion.onlyfull : ""
                                  }`}
                                  value={
                                    data?.access_via_transport_docs || false
                                  }
                                  onChange={(e) => {
                                    handleTrace(e, propKey, undefined, true);
                                  }}
                                  name="access_via_transport_docs"
                                  checked={
                                    data?.access_via_transport_docs || false
                                  }
                                />
                                <label
                                  className={`${styles.longLabel} ${
                                    user_s ? stylesVersion.onlyfull : ""
                                  }`}
                                >
                                  Dojazd wg dokumentów przewozu
                                </label>
                              </div>
                            </Tooltip>
                          </Grid>

                          <Grid item xs={2}>
                            <Tooltip
                              title={
                                user_s ? "Dostępne tylko w wersji pełnej" : ""
                              }
                            >
                              <div className={styles.inp}>
                                <label
                                  className={`${
                                    user_s ? stylesVersion.onlyfull : ""
                                  }`}
                                >
                                  Komentarz
                                </label>
                                <input
                                  disabled={user_s || isPreview}
                                  value={dataState?.comment || ""}
                                  className={`${styles.input} ${
                                    user_s ? stylesVersion.onlyfull : ""
                                  }`}
                                  onChange={(e) => {
                                    setDataState({
                                      ...dataState,
                                      comment: e.target.value,
                                    });
                                  }}
                                  onBlur={() =>
                                    updateValue(
                                      `trace[${index}].comment`,
                                      dataState.comment
                                    )
                                  }
                                  name="comment"
                                />
                              </div>
                            </Tooltip>
                          </Grid>

                          <Grid item xs={1}>
                            <Tooltip
                              title={
                                user_s ? "Dostępne tylko w wersji pełnej" : ""
                              }
                            >
                              <div className={styles.inp}>
                                <label
                                  className={`${
                                    user_s ? stylesVersion.onlyfull : ""
                                  }`}
                                >
                                  Numer ref
                                </label>
                                <input
                                  disabled={user_s || isPreview || checkedGoods}
                                  value={dataState?.reference_no || ""}
                                  className={`${styles.input} ${
                                    user_s ? stylesVersion.onlyfull : ""
                                  }`}
                                  onChange={(e) => {
                                    setDataState({
                                      ...dataState,
                                      reference_no: e.target.value,
                                    });
                                  }}
                                  onBlur={() =>
                                    updateValue(
                                      `trace[${index}].reference_no`,
                                      dataState.reference_no
                                    )
                                  }
                                  name="reference_no"
                                />
                              </div>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>

                      {data?.manual_adress !== undefined &&
                      data?.manual_adress === true ? (
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={2}>
                              <div className={styles.inp}>
                                <label>Nazwa firmy</label>
                                <input
                                  type="text"
                                  value={data?.address?.firm_name || ""}
                                  onChange={(e) => {
                                    setFirmName(e.target.value);
                                    const newFirmName = trace.map((o, i) => {
                                      if (i === propKey) {
                                        if (o.manual_adress === true) {
                                          if (o.address === undefined) {
                                            o.address = {};
                                            o.address.firm_name =
                                              e.target.value;
                                          } else {
                                            o.address.firm_name =
                                              e.target.value;
                                          }
                                        }
                                      }
                                      return o;
                                    });

                                    updateValue("trace", newFirmName);
                                  }}
                                  className={styles.input}
                                  name="firm_name"
                                  disabled={isPreview}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={2}>
                              <Tooltip
                                title={
                                  user_s ? "Dostępne tylko w wersji pełnej" : ""
                                }
                              >
                                <div className={styles.inp}>
                                  <AddressSearch
                                    user_s={user_s}
                                    token={user.csrf_token}
                                    setAddress={(e) => {
                                      updateValue("kilometers", {});
                                      updateValue("cost", "");
                                      setApartment_no();

                                      handleTrace(
                                        e,
                                        propKey,
                                        false,
                                        false,
                                        true
                                      );
                                      if (firm_name !== "") {
                                        const newFirmName = trace.map(
                                          (o, i) => {
                                            if (i === propKey) {
                                              if (o.manual_adress === true) {
                                                o.address.firm_name = firm_name;
                                              }
                                            }
                                            return o;
                                          }
                                        );

                                        updateValue("trace", newFirmName);
                                      }
                                      if (apartment_no !== "") {
                                        const newFirmName = trace.map(
                                          (o, i) => {
                                            if (i === propKey) {
                                              if (o.manual_adress === true) {
                                                o.address.apartment_no =
                                                  apartment_no;
                                              }
                                            }
                                            return o;
                                          }
                                        );

                                        updateValue("trace", newFirmName);
                                      }
                                    }}
                                    dense
                                    value={data?.address?.address_display_name}
                                    error={error}
                                    isPreview={isPreview}
                                  />
                                </div>
                              </Tooltip>
                            </Grid>

                            <Grid item xs={1}>
                              <div className={styles.inp}>
                                <label>Kraj</label>
                                <input
                                  type="text"
                                  value={data?.address?.country || ""}
                                  className={styles.input}
                                  onChange={(e) => {
                                    const tmp = trace.map((o, i) => {
                                      if (i === propKey) {
                                        if (o.manual_adress === true) {
                                          if (o.address === undefined) {
                                            o.address = {};
                                            o.address.country = e.target.value;
                                          } else {
                                            o.address.country = e.target.value;
                                          }
                                        }
                                      }
                                      return o;
                                    });

                                    updateValue("trace", tmp);
                                  }}
                                  name="country"
                                  disabled={isPreview}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={1}>
                              <div className={styles.inp}>
                                <label>Miasto</label>
                                <input
                                  type="text"
                                  value={data?.address?.city || ""}
                                  className={styles.input}
                                  onChange={(e) => {
                                    const tmp = trace.map((o, i) => {
                                      if (i === propKey) {
                                        if (o.manual_adress === true) {
                                          if (o.address === undefined) {
                                            o.address = {};
                                            o.address.city = e.target.value;
                                          } else {
                                            o.address.city = e.target.value;
                                          }
                                        }
                                      }
                                      return o;
                                    });

                                    updateValue("trace", tmp);
                                  }}
                                  name="city"
                                  disabled={isPreview}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={1}>
                              <div className={styles.inp}>
                                <label>Kod pocztowy</label>
                                <input
                                  type="text"
                                  value={data?.address?.zipcode || ""}
                                  className={styles.input}
                                  onChange={(e) => {
                                    const tmp = trace.map((o, i) => {
                                      if (i === propKey) {
                                        if (o.manual_adress === true) {
                                          if (o.address === undefined) {
                                            o.address = {};
                                            o.address.zipcode = e.target.value;
                                          } else {
                                            o.address.zipcode = e.target.value;
                                          }
                                        }
                                      }
                                      return o;
                                    });

                                    updateValue("trace", tmp);
                                  }}
                                  name="zipcode"
                                  disabled={isPreview}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={2}>
                              <div className={styles.inp}>
                                <label>Ulica</label>
                                <input
                                  type="text"
                                  value={data?.address?.street || ""}
                                  className={styles.input}
                                  onChange={(e) => {
                                    const tmp = trace.map((o, i) => {
                                      if (i === propKey) {
                                        if (o.manual_adress === true) {
                                          if (o.address === undefined) {
                                            o.address = {};
                                            o.address.street = e.target.value;
                                          } else {
                                            o.address.street = e.target.value;
                                          }
                                        }
                                      }
                                      return o;
                                    });

                                    updateValue("trace", tmp);
                                  }}
                                  name="street"
                                  disabled={isPreview}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={1} style={{ maxWidth: "70px" }}>
                              <div className={styles.inp}>
                                <label>Numer</label>
                                <input
                                  type="text"
                                  value={data?.address?.street_no || ""}
                                  className={styles.input}
                                  name="street_no"
                                  onChange={(e) => {
                                    const newStreetNo = trace.map((o, i) => {
                                      if (i === propKey) {
                                        o.address.street_no = e.target.value;
                                      }
                                      return o;
                                    });

                                    updateValue("trace", newStreetNo);
                                  }}
                                  disabled={isPreview}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={1} style={{ maxWidth: "70px" }}>
                              <div className={styles.inp}>
                                <label>Lokal</label>
                                <input
                                  type="text"
                                  value={data?.address?.apartment_no || ""}
                                  onChange={(e) => {
                                    setApartment_no(e.target.value);
                                    const newApartmentNo = trace.map((o, i) => {
                                      if (i === propKey) {
                                        if (o.manual_adress === true) {
                                          if (o.address === undefined) {
                                            o.address = {};
                                            o.address.apartment_no =
                                              e.target.value;
                                          } else {
                                            o.address.apartment_no =
                                              e.target.value;
                                          }
                                        }
                                      }
                                      return o;
                                    });
                                    updateValue("trace", newApartmentNo);
                                  }}
                                  className={styles.input}
                                  name="apartment_no"
                                  disabled={isPreview}
                                />
                              </div>
                            </Grid>

                            {data?.address?.address_display_name !==
                              undefined && (
                              <Grid item xs={"auto"}>
                                <div className={styles.inp}>
                                  <Tooltip title={"Koryguj współrzędne punktu"}>
                                    <Button
                                      className={styles.traceBtn}
                                      disabled={isPreview}
                                      onClick={(e) => {
                                        setTraceIndex(index);
                                        setTraceData(data);
                                        setOpenModalShowPoint(true);
                                      }}
                                    >
                                      <MapPin
                                        style={{
                                          minWidth: "16px",
                                          maxWidth: "16px",
                                        }}
                                      />
                                      Koryguj współrzędne
                                    </Button>
                                  </Tooltip>
                                </div>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <Tooltip
                                title={
                                  user_s ? "Dostępne tylko w wersji pełnej" : ""
                                }
                              >
                                <div className={styles.inp}>
                                  <label
                                    className={`${
                                      user_s ? stylesVersion.onlyfull : ""
                                    }`}
                                  >
                                    Magazyn*
                                  </label>
                                  <Select
                                    isDisabled={
                                      user_s ||
                                      data?.manual_adress === true ||
                                      isPreview ||
                                      !values?.contractor?.value
                                    }
                                    value={warehouse || null}
                                    className={`select ${
                                      user_s ? stylesVersion.onlyfull : ""
                                    }`}
                                    classNamePrefix={
                                      !data?.manual_adress &&
                                      error &&
                                      !warehouse?.address
                                        ? `error selectPrefix`
                                        : "selectPrefix"
                                    }
                                    options={warehousesOptions}
                                    name="warehouse"
                                    isOptionDisabled={(option) =>
                                      option.isdisabled
                                    }
                                    warehouses
                                    placeholder="Wybierz..."
                                    components={{ ValueContainer }}
                                    selectProps={setOpenModalNewWarehouse}
                                    saveTab={saveTab}
                                    styles={{
                                      control: (base, state) => ({
                                        ...base,
                                        border: "1px solid #142f42",
                                        backgroundColor:
                                          index === 0 &&
                                          isShowRequiredFields &&
                                          !toCostValues &&
                                          warehouse?.address === undefined
                                            ? "#e1f3d3 !important"
                                            : state.isDisabled === true
                                            ? "#eee"
                                            : "#fff",
                                        borderColor:
                                          index === 0 &&
                                          isShowRequiredFields &&
                                          !toCostValues &&
                                          "#82cd47 !important",
                                        boxShadow: "none",
                                        "&:hover": {
                                          border: "1px solid #142f42",
                                        },
                                      }),
                                      option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor:
                                          state.isFocused && "#142f42",
                                        color: state.isFocused && "#fff",
                                      }),
                                    }}
                                    onChange={(warehouse) => {
                                      setWarehouse(warehouse);
                                      handleTrace(
                                        {
                                          target: {
                                            name: "warehouse",
                                            value: warehouse,
                                          },
                                        },
                                        propKey
                                      );
                                    }}
                                  />
                                </div>
                              </Tooltip>
                            </Grid>
                            {warehouse?.address?.country && (
                              <Grid
                                item
                                xs={6}
                                className={styles.traceTxtParent}
                              >
                                <Tooltip
                                  title={
                                    user_s
                                      ? "Dostępne tylko w wersji pełnej"
                                      : ""
                                  }
                                >
                                  <p
                                    className={`${styles.traceTxt} ${styles.flexRow}`}
                                  >
                                    <strong
                                      className={`${
                                        user_s ? stylesVersion.onlyfull : ""
                                      }`}
                                    >
                                      Adres:{" "}
                                    </strong>
                                    <span>
                                      {warehouse?.address?.country || ""}
                                      {warehouse?.address?.country && ", "}
                                      {warehouse?.address?.city || ""}
                                      {warehouse?.address?.city && ", "}
                                      {warehouse?.address?.street || ""}{" "}
                                      {warehouse?.address?.street_no || ""}
                                    </span>
                                    <IconButton
                                      disabled={user_s || isPreview}
                                      className={`${styles.btnCopyTxt} ${
                                        user_s ? stylesVersion.onlyfull : ""
                                      }`}
                                      onClick={() => {
                                        const el =
                                          document.createElement("textarea");
                                        el.value = `${
                                          warehouse?.address?.country || ""
                                        }, ${warehouse?.address?.city || ""}, ${
                                          warehouse?.address?.street || ""
                                        } ${
                                          warehouse?.address?.street_no || ""
                                        }`;
                                        document.body.appendChild(el);
                                        el.select();
                                        document.execCommand("copy");
                                        document.body.removeChild(el);
                                        setDialog({
                                          isOpen: false,
                                          type: "success",
                                          content: "Skopiowano adres",
                                        });
                                      }}
                                      size="small"
                                    >
                                      <Copy
                                        disabled={user_s}
                                        className={styles.primary}
                                      />
                                    </IconButton>
                                  </p>
                                </Tooltip>

                                <div className={styles.traceTxt}>
                                  <div>
                                    <strong
                                      className={`${
                                        user_s ? stylesVersion.onlyfull : ""
                                      }`}
                                    >
                                      {"Kontakt: "}
                                    </strong>
                                    {warehouse?.contacts?.[0]?.fullname
                                      ?.length > 1
                                      ? warehouse?.contacts?.[0]?.fullname
                                      : "Brak"}
                                  </div>
                                  {warehouse?.contacts?.[0]?.phone && (
                                    <div>
                                      <strong
                                        className={`${
                                          user_s ? stylesVersion.onlyfull : ""
                                        }`}
                                      >
                                        {"Tel: "}
                                      </strong>
                                      {warehouse?.contacts?.[0]?.phone || ""}
                                    </div>
                                  )}
                                  {warehouse?.contacts?.[0]?.email && (
                                    <div>
                                      <strong
                                        className={`${
                                          user_s ? stylesVersion.onlyfull : ""
                                        }`}
                                      >
                                        {"Email: "}
                                      </strong>
                                      {warehouse?.contacts?.[0]?.email || ""}
                                    </div>
                                  )}
                                </div>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>

          {!editFavouritesRoutes && (
            <>
              <Grid item xs={12} style={{ padding: 0 }}>
                <hr
                  style={{
                    width: "97%",
                    borderTop: "0",
                    borderColor: "#e5e5e5",
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item className={styles.dense} xs={3}>
                    <div className={`${styles.inp} ${styles.withIcon}`}>
                      <label
                        className={`${user_s ? stylesVersion.onlyfull : ""}`}
                      >
                        Nazwa towaru
                      </label>
                    </div>
                  </Grid>
                  <Grid item className={styles.dense} xs={1}>
                    <div className={styles.inp}>
                      <div style={{ display: "flex", gap: "3px" }}>
                        <label
                          className={`${user_s ? stylesVersion.onlyfull : ""}`}
                        >
                          Ilość
                        </label>
                        {data.type === "loading" ? (
                          <Download
                            disabled={user_s || checkedGoods === false}
                            className={`${
                              user_s || checkedGoods === false
                                ? stylesVersion.onlyfull
                                : ""
                            }`}
                            style={{
                              margin: 0,
                              stroke: "#129865",
                              width: "16px",
                            }}
                            size={18}
                          />
                        ) : (
                          <Upload
                            disabled={user_s || checkedGoods === false}
                            className={`${
                              user_s || checkedGoods === false
                                ? stylesVersion.onlyfull
                                : ""
                            }`}
                            style={{
                              margin: 0,
                              stroke: "#ed3c22",
                              width: "16px",
                            }}
                            size={18}
                          />
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Grid item className={styles.dense} xs={1}>
                    <div className={styles.inp}>
                      <label
                        className={`${user_s ? stylesVersion.onlyfull : ""}`}
                      >
                        Jednostka
                      </label>
                    </div>
                  </Grid>
                  <Grid item className={styles.dense} xs={2}>
                    <div className={styles.inp}>
                      <label
                        className={`${user_s ? stylesVersion.onlyfull : ""}`}
                      >
                        Opis Towaru
                      </label>
                    </div>
                  </Grid>
                  <Grid item className={styles.dense} xs={1}>
                    <div className={styles.inp}>
                      <label
                        className={`${user_s ? stylesVersion.onlyfull : ""}`}
                      >
                        Rozmiar LDM
                      </label>
                    </div>
                  </Grid>
                  <Grid item className={styles.dense} xs={1}>
                    <div className={styles.inp}>
                      <label
                        className={`${user_s ? stylesVersion.onlyfull : ""}`}
                      >
                        Klasa ADR
                      </label>
                    </div>
                  </Grid>
                  <Grid item className={styles.dense} xs={1}>
                    <div className={styles.inp}>
                      <label
                        className={`${user_s ? stylesVersion.onlyfull : ""}`}
                      >
                        Numer ref
                      </label>
                    </div>
                  </Grid>
                  <Grid item className={styles.dense} xs={2}>
                    <div style={{ display: "flex", gap: 20 }}>
                      <div className={styles.inp}>
                        <label
                          className={`${user_s ? stylesVersion.onlyfull : ""}`}
                        >
                          ADR
                        </label>
                      </div>
                      <div className={styles.inp}>
                        <label
                          className={`${user_s ? stylesVersion.onlyfull : ""}`}
                        >
                          Wymiana palet
                        </label>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    className={`${styles.btnCargoBox} ${styles.dense} `}
                  ></Grid>
                  {data?.cargo &&
                    data?.cargo.map((e, i) => {
                      return (
                        <CargoTrace
                          updateValue={updateValue}
                          trace={data}
                          user_s={user_s}
                          addTab={addTab}
                          saveTab={saveTab}
                          traceIndex={propKey}
                          cargo={e}
                          goodsOptions={goodsOptions}
                          goodsOptionsWithoutSelected={
                            goodsOptionsWithoutSelected
                          }
                          handleTrace={handleTrace}
                          addTraceCargo={addTraceCargo}
                          removeTraceCargo={removeTraceCargo}
                          key={i}
                          cargoIndex={i}
                          cargoLength={data?.cargo.length}
                          error={error}
                          checkedGoods={checkedGoods}
                          dialog={dialog}
                          setDialog={setDialog}
                          isPreview={isPreview}
                        />
                      );
                    })}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>

        <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={styles.corner}>
            <IconButton
              disabled={user_s || isPreview}
              className={`${styles.btnIcon} ${
                user_s ? stylesVersion.onlyfull : ""
              }`}
              value={propKey}
              onClick={() => {
                copyTrace(propKey);
              }}
            >
              <Copy className={styles.primary} size={18} />
            </IconButton>
            <IconButton
              disabled={user_s || isPreview}
              className={`${styles.btnIcon} ${
                user_s ? stylesVersion.onlyfull : ""
              }`}
              value={propKey}
              onClick={() => {
                removeTrace(propKey, data.id);
              }}
            >
              <XCircle className={styles.remove} size={18} />
            </IconButton>
          </div>
        </Tooltip>
      </form>
    </>
  );
};

export default TraceWarehouse;
