import React, { useEffect, useState } from "react";
import BTNstyles from "styles/button.module.css";
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import { X, ArrowRight, Upload, Plus } from "react-feather";
import Tooltip from "@material-ui/core/Tooltip";
import stylesMod from "styles/newOrEdit.module.css";
import ModalShowRoute from "../ModalShowRoute";
import { countryPLToCode } from "components/flags/countryToCode";
import moment from "moment";
import { DBurl } from "appConfig";
import { capitalize } from "../newOrder.helpers";
import ModalDedicated from "../ModalDedicated";
import ModalCarUsed from "../ModalCarUsed";

const Footer = ({
  values,
  updateValue,
  transport_set,
  user_s,
  checkError,
  setError,
  isEdit,
  checkTraceError,
  setDialog,
  openModalShowRoute,
  setOpenModalShowRoute,
  setOpenModalProfilQuestion,
  setOpenAddModalProfileData,
  setClearHooksState,
  mainRouteChange,
  user,
  checkTransportSet,
  state,
  setMainRouteChange,
  order_start,
  order_stop,
  files,
  resetForm,
  tabCloseAndChangeTo,
  refetchGetOrderName,
  isPreview,
}) => {
  const [openModalCancel, setOpenModalCancel] = useState(false);
  const [saveRoute, setSaveRoute] = useState(false);
  const [dedicatedModal, setDedicatedModal] = useState(false);
  const [carUsedModal, setCarUsedModal] = useState(false);
  const [saveRouteAndSaveOrder, setSaveRouteAndSaveOrder] = useState(false);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const fetchFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", await file, file.name);
      const response = await fetch(`${DBurl}/uploadFile`, {
        method: "POST",
        credentials: "include",
        headers: {
          "X-CSRF-Token": user.csrf_token,
        },
        body: formData,
      });
      const json = await response.json();
      if (json.success) {
        return {
          name: json.data?.filename,
          msg: file.name,
          user_filename: file.user_filename,
        };
      }
    } catch (err) {
      return {};
    }
  };

  const fetchSetHeremapsRoutingUPDATE = async (id_order, id_routing) => {
    if (id_order != null && id_routing != null) {
      updateValue("isLoading", true);
      const response = await fetch(`${DBurl}/setHeremapsRouting`, {
        method: "post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": user.csrf_token,
        },
        body: JSON.stringify({
          mode: "update",
          data: {
            id_order: id_order,
            id_routing: id_routing,
          },
        }),
      });
      setSaveRoute(false);
      const json = await response.json();
      if (json.success) {
        updateValue("isLoading", false);
      } else {
        updateValue("isLoading", false);
      }
    } else {
      updateValue("isLoading", false);
    }
  };

  const fetchOrderWithFiles = async (type, data) => {
    updateValue("isLoading", true);
    const response = await fetch(`${DBurl}/setOrders`, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": user.csrf_token,
      },
      body: JSON.stringify({
        mode: type,
        data: data,
      }),
    });
    const json = await response.json();
    if (json.success) {
      if (data.id_routing != null) {
        if (type == "insert") {
          fetchSetHeremapsRoutingUPDATE(json.data.new_id, data.id_routing);
        }
      }

      updateValue("isLoading", false);

      setDialog({
        isOpen: true,
        type: "success",
        content: data.is_template
          ? type == "insert"
            ? "Nowy szablon utworzony"
            : "Edycja szablonu zapisana pomyślnie"
          : type == "insert"
          ? "Nowe zlecenie utworzone"
          : "Edycja zlecenia zapisana pomyślnie",
      });

      tabCloseAndChangeTo("OrderList");
    } else {
      updateValue("isLoading", false);
      if (
        json.responseDescription ===
        "Provided order number already exists in database."
      ) {
        refetchGetOrderName();
        setDialog({
          isOpen: true,
          type: "error",
          content:
            "Numer zlecenia jest już zajęty. Wygenerowano nowy numer zlecenia",
        });
      } else {
        setDialog({
          isOpen: true,
          type: "error",
          content: `${json.responseDescription} // ${json.response}`,
        });
      }
    }
  };

  const fetchOrder = async (type, data) => {
    const tempData = { ...data };

    tempData.orders_files = tempData.orders_files.filter((e) => !!e.id && e);

    if (data.orders_files.length > 0) {
      for (const file of data.orders_files) {
        if (!file.id && !file.ref_file_id) {
          const tempFile = await fetchFile(file);
          tempData.orders_files = [...tempData.orders_files, tempFile];
        }
        if (!file.id && file.ref_file_id) {
          tempData.orders_files = [...tempData.orders_files, file];
        }
      }
      fetchOrderWithFiles(type, tempData);
    } else {
      fetchOrderWithFiles(type, data);
    }
  };

  const makeOrder = ({
    type,
    is_reservation,
    is_edit,
    is_template,
    id_routing = null,
  }) => {
    const errorResult = checkError();

    const dataForm = (date) => {
      const newDate = moment(date).format("yyyy-MM-DD HH:mm:ss");
      return newDate;
    };

    if (values.transport.set || values.transport.set !== undefined) {
      checkTransportSet();
    }

    if (user_s === true) {
      if (!errorResult.isValid) {
        setDialog({
          isOpen: true,
          type: "error",
          content: errorResult.message,
        });
        setError(true);
      } else {
        setError(false);
        const data = {
          id: is_edit ? state.fetchOrderId : null,
          id_contractor: values.contractor.value,
          id_car: values.transport.set.id_car,
          id_transport_set: values.transport.set.id,
          id_trailer: values.transport.set.id_trailer,
          id_driver1: values.transport.set.id_driver1,
          id_driver2: values.transport.set.id_driver2,
          is_dedicated_car: values.transport.set.is_dedicated,
          is_double_cast_of_drivers:
            values.transport.set.is_double_cast_of_drivers,
          order_no: values.order_no,
          order_price: values.price,
          order_currency: values.currency.value,
          orders_advance_payments: !!values.advance
            ? values.advance.map((e) => ({
                value: e.value,
                currency: e.currency.value,
              }))
            : [{}],
          access_kilometers: values.kilometers.access,
          cargo_kilometers: values.kilometers.cargo,
          sum_kilometers: values.kilometers.sum,
          cost: values.cost ? values.cost : null,
          orders_files: files ? files : [],
          status: is_reservation ? 4 : 1,
          is_edited_transport_set: false,
          advance_payment: null, //???
          orders_locations: null,
          order_name: values.order_name,
          order_description: values.order_text,
          order_start: values.order_start ? dataForm(order_start) : null,
          order_stop: values.order_stop ? dataForm(order_stop) : null,
          is_required_weight: values.is_required_weight,
        };
        fetchOrder(type, data);
      }
    } else {
      setError(false);
      if (values.transport.isDedicated) {
        setDedicatedModal(true);
      } else if (state.isCurrentlyUsed) {
        setCarUsedModal(true);
      } else {
        const order_locations = values.trace.map((location, i) => {
          const tmpCargo =
            !location.isBlocked && values.checkedGoods && location.cargo
              ? location.cargo.map((cargo) => {
                  return {
                    id_good: values.checkedGoods === false ? 0 : cargo.id,
                    goods_order_quantity:
                      cargo.quantity === "" ? null : cargo.quantity,
                    goods_location_quantity:
                      cargo.quantity === "" ? null : cargo.quantity,
                    goods_price: cargo.price === "" ? null : cargo.price,
                    goods_currency: cargo.currency.label,
                    is_pallet_exchange: cargo.palletExchange,
                    ldm_size: !!cargo.ldmSize ? cargo.ldmSize : null,
                    is_adr: cargo.ADR,
                    adr_class: !!cargo.ADRClass ? cargo.ADRClass : null,
                    reference_no: cargo.NumRef,
                    details: cargo.details,
                  };
                })
              : null;

          const address =
            (location.type === "loading" || location.type === "unloading") &&
            location.manual_adress !== undefined &&
            location.manual_adress === true &&
            location.address !== undefined
              ? {
                  address_display_name: location.address.address_display_name,
                  country: location.address.country,
                  country_code: countryPLToCode(
                    capitalize(location.address.country)
                  ),
                  street: location.address.street,
                  city: location.address.city,
                  street_no: location.address.street_no,
                  apartment_no: location.address.apartment_no,
                  firm_name: location.address.firm_name,
                  longitude: location.address.longitude,
                  latitude: location.address.latitude,
                  district: location.address.district,
                  zipcode: location.address.zipcode,
                  id_warehouse: null,
                }
              : (location.type === "loading" ||
                  location.type === "unloading") &&
                (location.manual_adress === undefined ||
                  location.manual_adress === false)
              ? {
                  country:
                    location.warehouse &&
                    location.warehouse.address &&
                    location.warehouse.address.country,
                  country_code: countryPLToCode(
                    capitalize(
                      location.warehouse &&
                        location.warehouse.address &&
                        location.warehouse.address.country
                    )
                  ),
                  street:
                    location.warehouse &&
                    location.warehouse.address &&
                    location.warehouse.address.street,
                  city:
                    location.warehouse &&
                    location.warehouse.address &&
                    location.warehouse.address.city,
                  street_no:
                    location.warehouse &&
                    location.warehouse.address &&
                    location.warehouse.address.street_no,
                  address_code:
                    location.warehouse &&
                    location.warehouse.address &&
                    location.warehouse.address.address_code,
                  apartment_no:
                    location.warehouse &&
                    location.warehouse.address &&
                    location.warehouse.address.apartment_no,
                  longitude:
                    location.warehouse &&
                    location.warehouse.address &&
                    location.warehouse.address.longitude,
                  latitude:
                    location.warehouse &&
                    location.warehouse.address &&
                    location.warehouse.address.latitude,
                  district:
                    location.warehouse &&
                    location.warehouse.address &&
                    location.warehouse.address.district,
                  zipcode:
                    location.warehouse &&
                    location.warehouse.address &&
                    location.warehouse.address.zip_code,
                  id_warehouse: location.warehouse && location.warehouse.id,
                }
              : {
                  ...location.address,
                };

          return {
            id: location.id_location,
            type: location.type,
            subtype: location.subtype,
            reference_no: values.checkedGoods ? null : location.reference_no,
            index_order: i,
            id_border_crossing: location.border_crossing
              ? location.border_crossing.value
              : null,
            access_via_transport_docs:
              location.access_via_transport_docs || false,
            comment: location.comment ? location.comment : "",
            location_ts: location.date
              ? location.date
              : location.date === ""
              ? null
              : location.date,
            locations_goods: tmpCargo,
            ...address,
          };
        });

        const data = {
          id: is_edit ? state.fetchOrderId : null,
          id_contractor: values.contractor.value,
          order_without_goods: !values.checkedGoods,
          id_car: values.transport.set.id_car,
          id_transport_set: values.transport.set.id,
          id_trailer: values.transport.set.id_trailer,
          id_driver1: values.transport.set.id_driver1,
          id_driver2: values.transport.set.id_driver2,
          is_dedicated_car: values.transport.set.is_dedicated,
          is_double_cast_of_drivers:
            values.transport.set.is_double_cast_of_drivers,
          is_reservation: values.is_reservation,
          order_no: values.order_no,
          order_price: values.price,
          order_currency: values.currency.value,
          is_active: true,
          orders_advance_payments: !!values.transport.advance
            ? values.transport.advance.map((e) => {
                return { value: e.value, currency: e.currency.value };
              })
            : [],
          access_kilometers: values.kilometers.access,
          cargo_kilometers: values.kilometers.cargo,
          sum_kilometers: values.kilometers.sum,
          cost: values.cost ? values.cost : null,
          orders_files: files ? files : [],
          orders_locations: order_locations,
          status: is_reservation ? 4 : 1,
          template_name: is_template ? values.order_no : null,
          is_template: !!is_template,
          is_edited_transport_set: false,
          order_title: values.order_title ? values.order_title : null,
          order_text: values.order_text,
          id_routing: values.route_id || id_routing,
          orders_tags: values.selected_tags,
          customer_order_number: values.customer_order_number || "",
          is_required_weight: values.is_required_weight,
          goods_quantity_verification_required:
            !values.disable_cargo_quantity_check,
          order_note: values?.driverNote?.enabled
            ? values.driverNote.note.note
            : null,
        };
        setSaveRouteAndSaveOrder(false);
        fetchOrder(type, data);
      }
    }
  };

  const handleSave = () => {
    const errorResult = checkError();

    if (!user_s) {
      if (!errorResult.isValid) {
        setError(true);
        setDialog({
          isOpen: true,
          type: "error",
          content: errorResult.message,
        });
      } else {
        if (
          transport_set !== undefined &&
          transport_set.car_profile != undefined &&
          transport_set.car_profile !== null
        ) {
          if (checkTraceError()) {
            setDialog({
              isOpen: true,
              type: "error",
              content:
                "Niepoprawne punkty zlecenia! Aby wyznaczyć trasę prosimy o zdefiniowanie poprawnych punktów zlecenia.",
            });
          }
          if (
            transport_set.car_profile.ntruckweighttotal !== null &&
            transport_set.car_profile.ntruckweightaxle !== null &&
            transport_set.car_profile.ntruckheight !== null &&
            transport_set.car_profile.ntruckwidth !== null &&
            transport_set.car_profile.ntrucklenght !== null &&
            !checkTraceError() &&
            transport_set.car_profile.h_type !== null &&
            transport_set.car_profile.h_axle_count !== null &&
            transport_set.car_profile.h_trailer_count !== null &&
            transport_set.car_profile.h_tunel_category !== null &&
            transport_set.car_profile.h_shipped_hazardous_goods !== null
          ) {
            setOpenModalShowRoute(true);
            setSaveRouteAndSaveOrder(true);
          }
        }
        // Brak profilu - sam zapis zlecenia
        else {
          if (transport_set?.label !== undefined) {
            setOpenModalProfilQuestion(true);
            setOpenAddModalProfileData(transport_set);
          } else {
            updateValue("transport.error", true);
            if (transport_set) {
              console.log("transport_set", transport_set);
              checkTransportSet();
            }
            setDialog({
              isOpen: true,
              type: "error",
              content: "Wybierz zestaw transportowy.",
            });
          }
        }
      }
    } else {
      makeOrder({
        type: isEdit ? "update" : "insert",
        is_reservation: false,
        is_edit: isEdit,
        is_template: values.isTemplate,
      });
    }
  };

  const handleTemplateSave = () => {
    if (values.isTemplate) {
      makeOrder({
        type: "update",
        is_reservation: true,
        is_edit: true,
        is_template: true,
      });
    } else {
      makeOrder({
        type: "insert",
        is_reservation: true,
        is_edit: false,
        is_template: true,
      });
    }
  };

  return (
    <>
      <div className={BTNstyles.btnRow} style={{ paddingBottom: "5px" }}>
        {!isPreview && (
          <Button
            disabled={isPreview}
            variant="contained"
            size="large"
            className={`${BTNstyles.btn} ${BTNstyles.err}`}
            onClick={() => {
              setOpenModalCancel(true);
            }}
          >
            Anuluj <X className={BTNstyles.downBtnIco} />
          </Button>
        )}
        <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div>
            <Button
              disabled={user_s ? true : false}
              variant="contained"
              color="primary"
              size="large"
              className={BTNstyles.btn}
              onClick={handleTemplateSave}
            >
              Utwórz szablon
              <Upload className={BTNstyles.downBtnIco} />
            </Button>
          </div>
        </Tooltip>
        {!isPreview && (
          <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
            <div>
              <Button
                tooltip={"asdasdadsswdad"}
                disabled={user_s ? true : false || isPreview}
                variant="contained"
                color="primary"
                size="large"
                className={BTNstyles.btn}
                onClick={() => {
                  makeOrder({
                    type: isEdit ? "update" : "insert",
                    is_reservation: true,
                    is_edit: isEdit,
                    is_template: false,
                  });
                }}
              >
                Utwórz rezerwacje
                <Upload className={BTNstyles.downBtnIco} />
              </Button>
            </div>
          </Tooltip>
        )}
        {!isPreview && (
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={BTNstyles.btn}
            onClick={values.isTemplate ? handleTemplateSave : handleSave}
            disabled={isPreview}
          >
            {values.isTemplate
              ? "Zapisz szablon"
              : isEdit
              ? "Zapisz zmiany"
              : "Utwórz zlecenie"}{" "}
            <ArrowRight className={BTNstyles.downBtnIco} />
          </Button>
        )}
      </div>
      <Dialog
        open={openModalCancel}
        onClose={() => {
          setOpenModalCancel(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Czy napewno chcesz anulować?
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              setOpenModalCancel(false);
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Czy napewno chcesz anulować?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={BTNstyles.btn}
            onClick={(e) => {
              setOpenModalCancel(false);
            }}
          >
            Anuluj
          </Button>
          <Button
            variant="contained"
            size="large"
            className={`${BTNstyles.btn} ${BTNstyles.err}`}
            onClick={(e) => {
              setOpenModalCancel(false);
              resetForm();
              setClearHooksState(true);
            }}
            color="primary"
          >
            Usuń <X className={BTNstyles.downBtnIco} />
          </Button>
        </DialogActions>
      </Dialog>
      <ModalShowRoute
        values={values}
        updateValue={updateValue}
        openModalShowRoute={openModalShowRoute}
        setOpenModalShowRoute={setOpenModalShowRoute}
        setSaveRoute={setSaveRoute}
        setMainRouteChange={setMainRouteChange}
        setDialog={setDialog}
        isEdit={values.isEdit}
        makeOrder={makeOrder}
        saveRouteAndSaveOrder={saveRouteAndSaveOrder}
        user_s={user_s}
        saveRoute={saveRoute}
        mainRouteChange={mainRouteChange}
        fetchOrderId={state.fetchOrderId}
        showSaveFavouriteRoute
        edit_id_routing={values.route_id}
        setSaveRouteAndSaveOrder={setSaveRouteAndSaveOrder}
        isPreview={isPreview}
      />
      <ModalDedicated
        dedicatedModal={dedicatedModal}
        setDedicatedModal={setDedicatedModal}
        dedicated={values.transport.dedicated}
        setDialog={setDialog}
        Transition={Transition}
      />
      <ModalCarUsed
        carUsedModal={carUsedModal}
        setCarUsedModal={setCarUsedModal}
        setDialog={setDialog}
        Transition={Transition}
      />
    </>
  );
};

export default Footer;
