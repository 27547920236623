import React, { useState, useMemo } from "react";
import Settlements from "./Settlements";
import DriversCosts from "./DriversCosts";
import TmsDialog from "components/TmsDialog";

const DriversSettlement = () => {
  const [view, setView] = useState("settlements");
  const [isEdit, setIsEdit] = useState(false);
  const [editDate, setEditDate] = useState();
  const [editId, setEditId] = useState();
  const [dialog, setDialog] = useState({ isOpen: false });

  const handleEditSettlement = (date, id) => {
    setEditDate(date);
    setIsEdit(true);
    setEditId(id);
    setView("driversCosts");
  };

  const handleNewSettlement = () => {
    setIsEdit(false);
    setView("driversCosts");
  };

  const handleChangeView = (newView) => {
    setView(newView);
  };

  const content = useMemo(() => {
    if (view === "settlements") {
      return (
        <Settlements
          handleNewSettlement={handleNewSettlement}
          handleEditSettlement={handleEditSettlement}
          setDialog={setDialog}
        />
      );
    }

    if (view === "driversCosts") {
      return (
        <DriversCosts
          handleChangeView={handleChangeView}
          isEdit={isEdit}
          editDate={editDate}
          editId={editId}
          setDialog={setDialog}
        />
      );
    }
  }, [view]);

  return (
    <>
      {content}
      <TmsDialog dialog={dialog} setDialog={setDialog} />
    </>
  );
};

export default DriversSettlement;
