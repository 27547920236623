import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { TextField, Button, Modal, Box } from "@material-ui/core";
import styles from "./login.module.css";
import { DBurl, AppName, ContactEmail } from "../../appConfig";
import TmsDialog from "components/TmsDialog";
import getFCMToken from "../../firebase";
import { UserContext } from "contexts/userContext";
import { versions } from "versions";
import { useMutation } from "react-query";
import { authUser, resetUser } from "api/endpoints";

export default function Login(props) {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [openPassModal, setOpenPassModal] = useState(false);
  const [mailForgot, setMailForgot] = useState("");
  const [dialog, setDialog] = useState({ isOpen: false });

  const Logo = `/brand/${process.env.REACT_APP_NAME}/img/logo.svg`;

  useEffect(() => {
    getFCMToken();
  }, []);

  const sendTokenToBackend = async (csrf_token) => {
    const push_token = localStorage.getItem("FCMToken");
    await fetch(`${DBurl}/setTokenPushWWW`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf_token,
      },
      body: JSON.stringify({
        data: { push_token: push_token },
      }),
    });
  };

  const notificationSupportAlert = () => {
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission();
    }
  };

  useEffect(() => {
    notificationSupportAlert();
  }, []);

  const { setUser: setUserContext } = useContext(UserContext);

  const { mutate: checkUser } = useMutation("authUser", authUser, {
    onSuccess: (data) => {
      const user = data[0];

      setUserContext(user);
      sessionStorage.setItem("user", JSON.stringify(user));
      sessionStorage.setItem("isLoggedIn", true);
      props.setIsLoggedIn(true);
      props.setUser(user);
      localStorage.setItem("csrf_token", user.csrf_token);
      sendTokenToBackend(user.csrf_token);
    },
    onError: (error) => {
      if (error.response.status === 401) {
        setDialog({
          isOpen: true,
          type: "error",
          content: "Brak dostępu - Błędny login lub hasło",
        });
      } else {
        setDialog({ isOpen: true, type: "error", content: error.message });
      }
    },
  });

  const { mutate: resetPassword } = useMutation("resetUser", resetUser, {
    onSuccess: (data) => {
      if (data?.emailFound) {
        setDialog({
          isOpen: true,
          type: "success",
          content: "Na podany mail wysłaliśmy link do resetowania hasła",
        });
        setOpenPassModal(false);
      } else {
        setDialog({
          isOpen: true,
          type: "error",
          content:
            "Podany mail nie jest przyporządkowany do żadnego konta, sprawdź czy mail jest poprawny",
        });
      }
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!login.length) {
      setDialog({
        isOpen: true,
        type: "warning",
        content: "Wpisz login",
      });
    } else if (!password.length) {
      setDialog({
        isOpen: true,
        type: "warning",
        content: "Wpisz hasło",
      });
    } else {
      checkUser({ login, password });
    }
  };

  return (
    <>
      <div className={styles.loginPage}>
        <div className={styles.loginLink}>
          <span>
            {" "}
            Nie masz konta? <a href={`mailto:${ContactEmail}`}>Napisz do nas</a>
          </span>
        </div>
        <div className={`${styles.login} login-box`}>
          <h2>
            <img src={Logo} alt={`${AppName} TMS`} style={{ width: "225px" }} />
            <div
              style={{
                textAlign: "center",
                fontSize: "12px",
              }}
            >
              v{versions[0].version}
            </div>
          </h2>
          <h5>Logowanie do TMS</h5>
          <form noValidate onSubmit={handleSubmit}>
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="login"
              label="Login"
              name="login"
              autoComplete="Login"
              autoFocus
              value={login}
              className={styles.input}
              onChange={(e) => setLogin(e.target.value)}
            />
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Hasło"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              className={styles.input}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              className={styles.logBtn}
            >
              Zaloguj
            </Button>
            <p
              className={styles.forgot}
              onClick={() => {
                setOpenPassModal(true);
              }}
            >
              Zapomniałeś hasła?
            </p>
          </form>
        </div>
        <div className={styles.loginLink}>
          <NavLink to="/login">Polityka prywatności</NavLink>
        </div>
      </div>
      <Modal
        open={openPassModal}
        onClose={() => setOpenPassModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.box}>
          <h5>Zapomniałeś hasła?</h5>
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            name="mailForgot"
            label="Podaj swój e-mail"
            type="text"
            value={mailForgot}
            onChange={(e) => setMailForgot(e.target.value)}
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            disabled={mailForgot.length < 5}
            className={styles.logBtn}
            onClick={() => {
              resetPassword(mailForgot);
            }}
          >
            Wyślij
          </Button>
        </Box>
      </Modal>
      <TmsDialog dialog={dialog} setDialog={setDialog} />
    </>
  );
}
