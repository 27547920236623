import React, { useEffect, useState } from "react";

export default function Timer(props) {
  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (seconds === 0) {
      setSeconds(60);
      props.refreshTable();
    }
  }, [seconds === 0]);

  return (
    <div className={"timer-refresh"}>
      <div style={{ fontSize: "12px", fontWeight: "normal" }}>
        odświeżenie za:
      </div>
      <div>{seconds} s.</div>
    </div>
  );
}
