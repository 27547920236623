import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Slide,
  IconButton,
} from "@material-ui/core";
import Input from "../../components/input";
import Title from "../../components/title";
import BTNstyles from "../../styles/button.module.css";
import { DBurl } from "../../appConfig";
import styles from "../table.module.css";
import stylesMod from "../../styles/newOrEdit.module.css";
import { X } from "react-feather";
import RowsPerPage from "../../components/RowsPerPage";
import Pagination from "../../components/pagination";
import IcoBtn from "../../components/buttons/icoBtn";
import AddClient from "../client/AddClient";
import RatesPerKmModal from "../../components/RatesPerKmModal";
import Alert from "@material-ui/lab/Alert";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  if (orderBy === "district") {
    return order === "desc"
      ? (a, b) => descendingComparator(a.address, b.address, orderBy)
      : (a, b) => -descendingComparator(a.address, b.address, orderBy);
  } else {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
}

function stableSort(array, comparator) {
  if (array?.length > 0) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
}

const headCells = [
  {
    id: "short",
    numeric: false,
    disablePadding: true,
    label: "Nazwa skrócona",
  },
  { id: "name", numeric: true, disablePadding: false, label: "Nazwa" },
  { id: "NIP", numeric: true, disablePadding: false, label: "NIP" },
  { id: "IDEUVAT", numeric: true, disablePadding: false, label: "Id. EU VAT" },

  { id: "balance", numeric: true, disablePadding: false, label: "Do zapłaty" },
  {
    id: "ratePerKm",
    numeric: true,
    disablePadding: false,
    label: "Stawka za km",
  },
  { id: "city", numeric: true, disablePadding: false, label: "Miasto" },
  { id: "street", numeric: true, disablePadding: false, label: "Ulica" },
  // { id: 'rating', numeric: true, disablePadding: false, label: 'Ocena' },
  {
    id: "contacts",
    numeric: true,
    disablePadding: false,
    label: "Osoba do kontaktu",
  },
];

function EnhancedTableHead(props) {
  const {
    styles,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={styles.tabHead}>
      <TableRow>
        {/* <TableCell className={styles.checkbox} /> */}
        <TableCell className={styles.checkbox} />
        <TableCell className={styles.checkbox}>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all id" }}
          />
        </TableCell>
        {headCells.map((headCell, i) => {
          let cellWidth = headCell.id === "name" ? "15%" : "auto";
          return (
            <TableCell
              key={i}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "default"}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{ width: cellWidth }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={styles.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
        <TableCell className={styles.buttonBoxParent} />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  styles: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function useInput({ type }) {
  const [value, setValue] = useState("");
  const input = (
    <TableCell>
      <Input
        value={value}
        handleChange={(e) => {
          setValue(e.target.value);
        }}
        type={type}
        search={true}
      />
    </TableCell>
  );
  return [value, input];
}

export default function Contractors(props) {
  const [open, setOpen] = useState(null);
  const [departmentsList, setDepartmentsList] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("short");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [table, setTable] = useState([]);
  const [ratesPerKm, setRatesPerKm] = useState([]);
  const [contractor, setContractor] = useState({});
  const [ratePerKm, setRatePerKm] = useState(null);
  const [id_ratesPerKm, set_id_ratesPerKm] = useState();
  const [searchShort, setSearchShort] = useInput({ type: "text" });
  const [searchName, setSearchName] = useInput({ type: "text" });
  const [searchNIP, setSearchNIP] = useInput({ type: "text" });
  const [searchIDEUVAT, setSearchIDEUVAT] = useInput({ type: "text" });
  const [searchCity, setSearchCity] = useInput({ type: "text" });
  const [searchStreet, setSearchStreet] = useInput({ type: "text" });
  const [searchBalance, setSearchBalance] = useInput({ type: "number" });
  const [searchAddressContact, setSearchAddressContact] = useInput({
    type: "text",
  });
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [modalContent, setModalContent] = React.useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");

  const [openModalRatesPerKm, setOpenModalRatesPerKm] = useState(false);

  const getContractors = async function () {
    props.loading(true);
    const response = await fetch(`${DBurl}/getContractors/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setTable(json.data);
      props.loading(false);
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  const getRatesPerKm = async function () {
    props.loading(true);
    const response = await fetch(`${DBurl}/getRatesPerKm/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setRatesPerKm(json.data);
      props.loading(false);
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  const getDepartments = async function () {
    props.loading(true);
    const response = await fetch(`${DBurl}/getDepartments`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setDepartmentsList(json.data);
      props.loading(false);
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  const delContractors = async function (arrayOfIds) {
    props.loading(true);
    const response = await fetch(`${DBurl}/setContractors/`, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "delete",
        data: {
          ids: arrayOfIds,
        },
      }),
    });
    const json = await response.json();
    if (json.success) {
      setOpenDialog(true);
      setDialogContent(`Dane zostały usunięte `);
      setDialogColor("success");
      getContractors();
      props.loading(false);
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  useEffect(() => {
    getContractors();
    getRatesPerKm();
    getDepartments();
    // stableSort(search(table), getComparator(order, orderBy))
    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  }, []);

  // useEffect(()=> {
  // 	getRatesPerKm()
  // },[table[0]])

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDialog(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const search = (tbl) => {
    return tbl.filter((row) => {
      return (
        String(row.shortname).toLowerCase().indexOf(searchShort.toLowerCase()) >
          -1 &&
        String(row.name).toLowerCase().indexOf(searchName.toLowerCase()) > -1 &&
        String(row.nip).toLowerCase().indexOf(searchNIP.toLowerCase()) > -1 &&
        String(row.ideuvat).toLowerCase().indexOf(searchIDEUVAT.toLowerCase()) >
          -1 &&
        // String(row.rating).toLowerCase().indexOf(searchRatesPerKm.toLowerCase()) > -1 &&
        String(row.balance).toLowerCase().indexOf(searchBalance.toLowerCase()) >
          -1 &&
        String(row.address?.city)
          .toLowerCase()
          .indexOf(searchCity.toLowerCase()) > -1 &&
        String(row.address?.street)
          .toLowerCase()
          .indexOf(searchStreet.toLowerCase()) > -1 &&
        String(row.contacts?.[0]?.fullname)
          .toLowerCase()
          .indexOf(searchAddressContact.toLowerCase()) > -1
      );
    });
  };

  const openAddClient = (row, type) => {
    let tempData = {
      permission_id: 3,
      tabName: type || "Dodaj klienta",
      tabID: null,
      component: AddClient,
      active: true,
      ...row,
    };
    props.addTab("AddClient", tempData);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = table.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const assignRates = (id, type) => {
    // type :
    // 1 - zwraca stawkę (number)
    // 2 - zwraca boolean
    // 3 - zwraca cały obiekt

    if (ratesPerKm.length > 0) {
      const result = ratesPerKm.find((e) => e.contractor[0].id === id);
      if (type === 1) {
        return !!result
          ? `${Number(result.rate_netto).toFixed(2)} ${
              result.currency ? result.currency : ""
            }`
          : "Brak stawki";
      } else if (type === 2) {
        return !!result;
      } else if (type === 3) {
        return result;
      }
    } else {
      return "brak stawek";
    }
  };

  const assignDepartment = (id) => {
    return departmentsList.filter((e) => e.id_parent === id);
  };

  const paginate = (pageNumber) => setPage(pageNumber - 1);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, table.length - page * rowsPerPage);

  return (
    <div className={styles.root}>
      <Paper className={styles.paper}>
        <Title
          title={props.state.tabName}
          btnBox
          btnAdd
          addFunc={(e) => {
            props.addTab("AddClient");
          }}
          btnDelete={selected.length > 0 ? true : "disabled"}
          deleteFunc={(e) => {
            setOpenModalDelete(true);
            const selectedArray = table.filter((e) => {
              if (selected.includes(e.id)) {
                return e;
              } else {
                return null;
              }
            });
            setModalContent(selectedArray);
          }}
        />
        <TableContainer className={styles.stickyTable}>
          <Table
            className={styles.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              styles={styles}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={table.length}
            />
            <TableBody className={styles.tabBody}>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                {setSearchShort}
                {setSearchName}
                {setSearchNIP}
                {setSearchIDEUVAT}
                {setSearchBalance}
                <TableCell></TableCell>
                {setSearchCity}
                {setSearchStreet}
                {/* {setSearchRatesPerKm} */}
                {setSearchAddressContact}
                <TableCell></TableCell>
              </TableRow>
              {stableSort(search(table), getComparator(order, orderBy))
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <>
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        selected={isItemSelected}
                      >
                        <TableCell>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                              open === row.id ? setOpen(null) : setOpen(row.id);
                            }}
                          >
                            {open === row.id ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell className={styles.checkbox}>
                          <Checkbox
                            checked={isItemSelected}
                            color="primary"
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.shortname}
                        </TableCell>
                        <TableCell align="right">{row.name}</TableCell>
                        <TableCell align="right">{row.nip}</TableCell>
                        <TableCell align="right">{row.ideuvat}</TableCell>
                        <TableCell align="right">
                          <div className={styles.tableCenter}>
                            <span className={styles.mr10}>{row.balance}</span>{" "}
                            {!!row.balance ? (
                              <IcoBtn
                                icon="Plus"
                                tooltip="Dodaj płatność"
                                value={row}
                                onClick={(e) => {
                                  props.addTab("Payments", row);
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.tableCenter}>
                            <span className={styles.mr10}>
                              {assignRates(row.id, 1)}
                            </span>
                            {assignRates(row.id, 2) ? (
                              <IcoBtn
                                icon="Edit"
                                tooltip="Edytuj stawkę"
                                value={row}
                                onClick={(e) => {
                                  setContractor(row);
                                  set_id_ratesPerKm(assignRates(row.id, 3).id);
                                  setRatePerKm(assignRates(row.id, 3));
                                  setOpenModalRatesPerKm(true);
                                }}
                              />
                            ) : (
                              <IcoBtn
                                icon="Plus"
                                tooltip="dodaj stawkę"
                                value={row}
                                onClick={(e) => {
                                  setContractor(row);
                                  set_id_ratesPerKm(null);
                                  setRatePerKm("");
                                  setOpenModalRatesPerKm(true);
                                }}
                              />
                            )}
                          </div>
                        </TableCell>
                        <TableCell align="right">{row.address?.city}</TableCell>
                        <TableCell align="right">
                          {row.address?.street} {row.address?.street_no}
                        </TableCell>
                        {/* <TableCell align="right">{row.rating}</TableCell> */}
                        <TableCell align="right">
                          <div className={styles.tableCenter}>
                            <span className={styles.mr10}>
                              {row.contacts?.[0]?.fullname}
                            </span>
                            <IcoBtn
                              icon="Info"
                              tooltip="Informacje"
                              value={row}
                            />
                          </div>
                        </TableCell>
                        <TableCell
                          align="right"
                          className={styles.buttonBoxParent}
                        >
                          <div className={styles.buttonBox}>
                            <IcoBtn
                              icon="LogIn"
                              tooltip="Eksportuj"
                              value={row}
                              onClick={(e) => {
                                alert(
                                  `TODO: Funkcja eksportu:  ${JSON.stringify(
                                    row,
                                    null,
                                    4
                                  )}`
                                );
                              }}
                            />
                            <IcoBtn
                              icon="Edit"
                              tooltip="Edytuj"
                              value={row}
                              onClick={(e) => {
                                openAddClient(row, "Edytuj konto klienta");
                              }}
                            />
                            <IcoBtn
                              icon="X"
                              className="btn-delete"
                              tooltip="Usuń"
                              value={row}
                              onClick={(e) => {
                                setOpenModalDelete(true);
                                setModalContent([row]);
                              }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={2}
                        />
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={10}
                        >
                          <Collapse
                            in={open === row.id}
                            timeout="auto"
                            unmountOnExit
                          >
                            {/* <Box margin={1}> */}
                            <Table
                              size="small"
                              className={styles.collapseTable}
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Oddział</TableCell>
                                  <TableCell>Nazwa Skrócona</TableCell>
                                  <TableCell align="right">NIP</TableCell>
                                  <TableCell align="right">Kraj</TableCell>
                                  <TableCell align="right">Miasto</TableCell>
                                  <TableCell align="right">Ulica</TableCell>
                                  <TableCell align="right">
                                    Kod-pocztowy
                                  </TableCell>
                                  <TableCell align="right">Komentarz</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {assignDepartment(row.id).map((e) => {
                                  return (
                                    <>
                                      <TableRow key={e.id}>
                                        <TableCell component="th" scope="row">
                                          {e.name}
                                        </TableCell>
                                        <TableCell>{e.shortname}</TableCell>
                                        <TableCell align="right">
                                          {e.nip}
                                        </TableCell>
                                        <TableCell align="right">
                                          {e.address?.country}
                                        </TableCell>
                                        <TableCell align="right">
                                          {e.address?.city}
                                        </TableCell>
                                        <TableCell align="right">
                                          {e.address?.street}{" "}
                                          {e.address?.street_no}
                                          {e.apartment_no
                                            ? `/${e.address?.apartment_no}`
                                            : ""}
                                        </TableCell>
                                        <TableCell align="right">
                                          {e.address?.zipcode}
                                        </TableCell>
                                        <TableCell align="right">
                                          {e.comments}
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
          open={openModalDelete}
          TransitionComponent={Transition}
          onClose={() => {
            setOpenModalDelete(false);
            setModalContent([]);
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            className={stylesMod.dialogHead}
          >
            Czy napewno chcesz usunąć {modalContent ? modalContent.name : null}{" "}
            <IconButton
              aria-label="close"
              className={stylesMod.closeButton}
              onClick={() => {
                setOpenModalDelete(false);
                setModalContent([]);
              }}
            >
              <X />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Czy napewno chcesz usunąć organizacje
              {modalContent.map((e) => {
                return (
                  <>
                    <br /> {e.name}
                  </>
                );
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={BTNstyles.btn}
              onClick={(e) => {
                setOpenModalDelete(false);
                setModalContent([]);
              }}
            >
              Anuluj
            </Button>
            <Button
              variant="contained"
              size="large"
              className={`${BTNstyles.btn} ${BTNstyles.err}`}
              onClick={(e) => {
                setTable(
                  table.filter((e) => {
                    if (!selected.includes(e.id)) {
                      return e;
                    } else {
                      return null;
                    }
                  })
                );
                setModalContent([]);
                setOpenModalDelete(false);

                //TODO dodać fetch usuwający clienta z bazy
              }}
              color="primary"
            >
              Usuń <X className={BTNstyles.downBtnIco} />
            </Button>
          </DialogActions>
        </Dialog>
        <div className={styles.tableBottom}>
          <RowsPerPage
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setPage={setPage}
            tableLength={table?.length}
          />
          <div>
            <Pagination
              postsPerPage={rowsPerPage}
              totalPosts={search(table)?.length}
              paginate={paginate}
              page={page}
            />
          </div>
        </div>
      </Paper>
      <RatesPerKmModal
        open={openModalRatesPerKm}
        setOpen={setOpenModalRatesPerKm}
        setSnack={setOpenDialog}
        setSnackContent={setDialogContent}
        setSnackColor={setDialogColor}
        contractor={contractor}
        token={props.user.csrf_token}
        refresh={() => {
          getRatesPerKm();
          getContractors();
        }}
        ratesPerKm={ratePerKm}
        id_ratesPerKm={id_ratesPerKm}
        setDialog={props.setDialog}
      />

      <Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={dialogColor}>
          {dialogContent}
        </Alert>
      </Snackbar>
    </div>
  );
}
