import React from 'react'
import { X } from 'react-feather';
import styles from './btnStyles.module.css'
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

export default function DisabledDeleteBtn (props) {
	return (
		<Tooltip title={props.tooltip}  disableHoverListener={!props.tooltip}>
			<IconButton onClick={props.handleChange} value={props.value} className={`${styles.btn} ${styles.errDisabled}`}>
				< X />
			</IconButton>
		</Tooltip>
	)
}
