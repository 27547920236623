export const countries = [
  {
    id: 1,
    label: "Azerbejdżan",
    code: "AZ",
    eng_name: "Azerbaijan",
    capital: "Baku",
    zoom: 6,
  },
  {
    id: 2,
    label: "Węgry",
    code: "HU",
    eng_name: "Hungary",
    capital: "Budapest",
    zoom: 6,
  },
  {
    id: 3,
    label: "Słowenia",
    code: "SI",
    eng_name: "Slovenia",
    capital: "Ljubljana",
    zoom: 7,
  },
  {
    id: 4,
    label: "Białoruś",
    code: "BY",
    eng_name: "Belarus",
    capital: "Minsk",
    zoom: 6,
  },
  {
    id: 5,
    label: "Finlandia",
    code: "FI",
    eng_name: "Finland",
    capital: "Helsinki",
    zoom: 5,
  },
  {
    id: 6,
    label: "Wielka Brytania",
    code: "GB",
    eng_name: "United Kingdom",
    capital: "London",
    zoom: 6,
  },
  {
    id: 7,
    label: "Irlandia",
    code: "IE",
    eng_name: "Ireland",
    capital: "Dublin",
    zoom: 7,
  },
  {
    id: 8,
    label: "Włochy",
    code: "IT",
    eng_name: "Italy",
    capital: "Rome",
    zoom: 6,
  },
  {
    id: 9,
    label: "Kazachstan",
    code: "KZ",
    eng_name: "Kazakhstan",
    capital: "Nur-Sultan",
    zoom: 6,
  },
  {
    id: 10,
    label: "Mołdawia",
    code: "MD",
    eng_name: "Moldova",
    capital: "Chisinau",
    zoom: 6,
  },
  {
    id: 11,
    label: "Portugalia",
    code: "PT",
    eng_name: "Portugal",
    capital: "Lisbon",
    zoom: 6,
  },
  {
    id: 12,
    label: "Rumunia",
    code: "RO",
    eng_name: "Romania",
    capital: "Bucharest",
    zoom: 6,
  },
  {
    id: 13,
    label: "Tadżykistan",
    code: "TJ",
    eng_name: "Tajikistan",
    capital: "Dushanbe",
    zoom: 5,
  },
  {
    id: 14,
    label: "Turcja",
    code: "TR",
    eng_name: "Turkey",
    capital: "Ankara",
    zoom: 5,
  },
  {
    id: 15,
    label: "Uzbekistan",
    code: "UZ",
    eng_name: "Uzbekistan",
    capital: "Tashkent",
    zoom: 5,
  },
  {
    id: 16,
    label: "Armenia",
    code: "AM",
    eng_name: "Armenia",
    capital: "Yerevan",
    zoom: 7,
  },
  {
    id: 17,
    label: "Rosja",
    code: "RU",
    eng_name: "Russia",
    capital: "Moscow",
    zoom: 4,
  },
  {
    id: 18,
    label: "Niemcy",
    code: "DE",
    eng_name: "Germany",
    capital: "Berlin",
    zoom: 5,
  },
  {
    id: 19,
    label: "Francja",
    code: "FR",
    eng_name: "France",
    capital: "Paris",
    zoom: 5,
  },
  {
    id: 20,
    label: "Gruzja",
    code: "GE",
    eng_name: "Georgia",
    capital: "Tbilisi",
    zoom: 6,
  },
  {
    id: 21,
    label: "Austria",
    code: "AT",
    eng_name: "Austria",
    capital: "Vienna",
    zoom: 6,
  },
  {
    id: 22,
    label: "Belgia",
    code: "BE",
    eng_name: "Belgium",
    capital: "Brussels",
    zoom: 7,
  },
  {
    id: 23,
    label: "Bułgaria",
    code: "BG",
    eng_name: "Bulgaria",
    capital: "Sofia",
    zoom: 6,
  },
  {
    id: 24,
    label: "Czechy",
    code: "CZ",
    eng_name: "Czech Republic",
    capital: "Prague",
    zoom: 6,
  },
  {
    id: 25,
    label: "Dania",
    code: "DK",
    eng_name: "Denmark",
    capital: "Copenhagen",
    zoom: 7,
  },
  {
    id: 26,
    label: "Estonia",
    code: "EE",
    eng_name: "Estonia",
    capital: "Tallinn",
    zoom: 7,
  },
  {
    id: 27,
    label: "Hiszpania",
    code: "ES",
    eng_name: "Spain",
    capital: "Madrid",
    zoom: 5,
  },
  {
    id: 28,
    label: "Litwa",
    code: "LT",
    eng_name: "Lithuania",
    capital: "Vilnius",
    zoom: 6,
  },
  {
    id: 29,
    label: "Luksemburg",
    code: "LU",
    eng_name: "Luxembourg",
    capital: "Luxembourg City",
    zoom: 7,
  },
  {
    id: 30,
    label: "Łotwa",
    code: "LV",
    eng_name: "Latvia",
    capital: "Riga",
    zoom: 7,
  },
  {
    id: 31,
    label: "Holandia",
    code: "NL",
    eng_name: "Netherlands",
    capital: "Amsterdam",
    zoom: 7,
  },
  {
    id: 32,
    label: "Norwegia",
    code: "NO",
    eng_name: "Norway",
    capital: "Oslo",
    zoom: 5,
  },
  {
    id: 33,
    label: "Polska",
    code: "PL",
    eng_name: "Poland",
    capital: "Warsaw",
    zoom: 8,
  },
  {
    id: 34,
    label: "Szwecja",
    code: "SE",
    eng_name: "Sweden",
    capital: "Stockholm",
    zoom: 5,
  },
  {
    id: 35,
    label: "Słowacja",
    code: "SK",
    eng_name: "Slovakia",
    capital: "Bratislava",
    zoom: 7,
  },
  {
    id: 36,
    label: "Ukraina",
    code: "UA",
    eng_name: "Ukraine",
    capital: "Kyiv",
    zoom: 5,
  },
  {
    id: 37,
    label: "Albania",
    code: "AL",
    eng_name: "Albania",
    capital: "Tirana",
    zoom: 7,
  },
  {
    id: 38,
    label: "Andora",
    code: "AD",
    eng_name: "Andorra",
    capital: "Andorra la Vella",
    zoom: 9,
  },
  {
    id: 39,
    label: "Bośnia i Hercegowina",
    code: "BA",
    eng_name: "Bosnia and Herzegovina",
    capital: "Sarajevo",
    zoom: 7,
  },
  {
    id: 40,
    label: "Chorwacja",
    code: "HR",
    eng_name: "Croatia",
    capital: "Zagreb",
    zoom: 7,
  },
  {
    id: 41,
    label: "Cypr",
    code: "CY",
    eng_name: "Cyprus",
    capital: "Nicosia",
    zoom: 8,
  },
  {
    id: 42,
    label: "Islandia",
    code: "IS",
    eng_name: "Iceland",
    capital: "Reykjavik",
    zoom: 5,
  },
  {
    id: 43,
    label: "Kosowo",
    code: "XK",
    eng_name: "Kosovo",
    capital: "Pristina",
    zoom: 7,
  },
  {
    id: 44,
    label: "Macedonia Północna",
    code: "MK",
    eng_name: "North Macedonia",
    capital: "Skopje",
    zoom: 7,
  },
  {
    id: 45,
    label: "Malta",
    code: "MT",
    eng_name: "Malta",
    capital: "Valletta",
    zoom: 8,
  },
  {
    id: 46,
    label: "Monako",
    code: "MC",
    eng_name: "Monaco",
    capital: "Monaco",
    zoom: 9,
  },
  {
    id: 47,
    label: "Czarnogóra",
    code: "ME",
    eng_name: "Montenegro",
    capital: "Podgorica",
    zoom: 7,
  },
  {
    id: 48,
    label: "San Marino",
    code: "SM",
    eng_name: "San Marino",
    capital: "San Marino",
    zoom: 9,
  },
  {
    id: 49,
    label: "Serbia",
    code: "RS",
    eng_name: "Serbia",
    capital: "Belgrade",
    zoom: 7,
  },
  {
    id: 50,
    label: "Słowacja",
    code: "SK",
    eng_name: "Slovakia",
    capital: "Bratislava",
    zoom: 7,
  },
  {
    id: 51,
    label: "Słowenia",
    code: "SI",
    eng_name: "Slovenia",
    capital: "Ljubljana",
    zoom: 7,
  },
  {
    id: 52,
    label: "Watykan",
    code: "VA",
    eng_name: "Vatican City",
    capital: "Vatican City",
    zoom: 9,
  },
  {
    id: 53,
    label: "Grecja",
    code: "GR",
    eng_name: "Greece",
    capital: "Athens",
    zoom: 6,
  },
  {
    id: 54,
    label: "Szwajcaria",
    code: "CH",
    eng_name: "Switzerland",
    capital: "Bern",
    zoom: 7,
  },
];
