import { SelectOption } from "types";

type Params = {
  data: Array<any>;
  label: string;
  value: string;
  image?: string;
};

export const parseSelectOptions = ({
  data,
  label,
  value,
  image,
}: Params): SelectOption[] =>
  data.map((item: any) => ({
    label: item[label],
    value: item[value],
    image: item[image || ""],
  }));

export const parseSelectOptionsWithoutDuplicates = ({
  data,
  label,
  value,
  image,
}: Params): SelectOption[] => {
  const options = parseSelectOptions({ data, label, value, image });

  const optionsWithoutDuplicates = options.filter(
    (obj: any, index: number) =>
      options.findIndex((item: any) => item.value === obj.value) === index
  );

  return optionsWithoutDuplicates;
};
