import React from 'react'
import styles from './subtitle.module.css'

export default function subTitle(props) {
	
	const tableTitle = [
		'Zlecenia aktywne - wybierz zlecenie do przepinki',
		'Zlecenia aktywne - wybierz zlecenie do przesiadki',
		'Zlecenia aktywne - wybierz zlecenie do przeładunku'
	]

	let newTitle = ''

	switch(props.title){
		case 'Przepinka':
			newTitle = tableTitle[0]
			break
		case 'Przesiadka':
			newTitle = tableTitle[1]
			break
		case 'Przeładunek':
			newTitle = tableTitle[2]
			break
		default:
			newTitle = 'Zlecenia aktywne'		
			break
	}
	

	return (
		<div className={styles.subtitle}>
		
				{props.subTitleBox &&
					<>
						{props.driverNameBox &&
							<div>
								<div className={styles.labelValueRow}>
									<label>Kierowca</label>
									<label className={styles.value}>{props.driverNameBox}</label>
								</div>
							</div>
						}
						
						{props.driverWorkNumber &&
							<div>
								<div className={styles.labelValueRow}>
									<label>Numer służbowy</label>
									<label className={styles.value}>{props.driverWorkNumber}</label>
								</div>
							</div>
						}
					</>
				}

				{props.TableTitleActive &&
					<div>
						<div className={styles.labelValueRow}>
							<label>{newTitle}</label>
						</div>
					</div>
				}

				{props.TableTitleWaiting &&
					<div>
						<div className={styles.labelValueRow}>
							<label>Zlecenia oczekujące</label>
						</div>
					</div>
				}
		
		</div>
	)
}



