import React, { useState, useEffect } from "react";
import chatStyles from "../../styles/chat.module.css";
import { List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import IcoBtn from "../../components/buttons/icoBtn";
import { File, UploadCloud } from "react-feather";
import { DBurl } from "../../appConfig";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import stylesMod from "../../styles/newOrEdit.module.css";
import styles from "../table.module.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { TableHead } from "@material-ui/core";
import { Snackbar, Grid, Checkbox, Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import BTNstyles from "styles/button.module.css";
import { all } from "iso-3166-1";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrderFiles = ({
  token,
  orderData,
  openModalOrderFiles,
  setOpenModalOrderFiles,
  setRefresh,
  setOrderDataID,
  refresh,
}) => {
  const [selected, setSelected] = useState([]);
  const [selectedWWW, setSelectedWWW] = useState([]);
  const [editName, setEditName] = useState([]);
  const [newName, setNewName] = useState([]);
  const [files, setFiles] = useState([]);

  const fetchFile = async (file) => {
    try {
      const formData = new FormData();
      await formData.append("file", await file, file.name);
      const response = await fetch(`${DBurl}/uploadFile`, {
        method: "POST",
        credentials: "include",
        headers: {
          "X-CSRF-Token": token,
        },
        body: formData,
      });
      const json = await response.json();
      if (json.success) {
        return await {
          name: json.data?.filename,
          msg: file.name,
          user_filename: file.user_filename,
        };
      }
    } catch (err) {
      return {};
    }
  };

  const fetchOrderWithFiles = async (type, data) => {
    const newData = { ...data, id_driver2: null };
    const response = await fetch(`${DBurl}/setOrdersFiles`, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify({
        mode: type,
        data: data,
      }),
    });
    const json = await response.json();
    if (json.success) {
      setFiles([]);
      setRefresh(true);
    }
  };

  const fetchOrder = async (type, data) => {
    const tempData = { ...data };

    tempData.order_files = tempData.order_files.filter((e) => !!e.id && e);

    if (data.order_files.length > 0) {
      for (const file of data.order_files) {
        if (!file.id && !file.ref_file_id) {
          const tempFile = await fetchFile(file);
          tempData.order_files = [...tempData.order_files, tempFile];
        }
        if (!file.id && file.ref_file_id) {
          tempData.order_files = [...tempData.order_files, file];
        }
      }
      fetchOrderWithFiles(type, tempData);
    } else {
      fetchOrderWithFiles(type, data);
    }
  };
  const makeOrder = (
    type,
    is_reservation,
    is_edit,
    is_template,
    id_routing = null
  ) => {
    const data = {
      id_order: orderData.id,
      order_files: files,
    };
    fetchOrder(type, data);
  };

  const allSelected = selected.concat(selectedWWW);
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = orderData.files
        .filter((file) => file.from_mobile)
        .map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleSelectAllWWWClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = orderData.files
        .filter((file) => !file.from_mobile)
        .map((n) => n.name);
      setSelectedWWW(newSelecteds);
      return;
    }
    setSelectedWWW([]);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const handleClickWWW = (event, name) => {
    const selectedIndex = selectedWWW.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedWWW, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedWWW.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selectedWWW.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelectedWWW(newSelected);
  };
  const isSelected = (name) => selected?.indexOf(name) !== -1;
  const isSelectedWWW = (name) => selectedWWW?.indexOf(name) !== -1;
  const [disabled, setDisabled] = useState(true);

  const handleDisabled = () => {
    if (allSelected.length === 0) {
      setDisabled(true);
    }
    if (allSelected.length > 0) {
      setDisabled(false);
    }
  };
  const handleDownload = () => {
    for (const name of allSelected) {
      const nameForDownload = displayName(
        orderData.files.find((file) => file.name === name)
      );
      fetch(`${DBurl}/getFile?filename=${name}`)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
          link.download = nameForDownload;
          link.href = URL.createObjectURL(blob);
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
        });
    }
  };

  const displayName = (row) => {
    if (row.user_filename) return row.user_filename;
    if (
      !row.user_filename &&
      row.msg !== "" &&
      row.msg !== undefined &&
      !row.from_mobile
    )
      return row.msg;
    if (!row.user_filename && row.from_mobile) return row.name;
    if (!row.user_filename && row.col_name) return row.col_name;
    if (!row.user_filename && !row.msg && !row.col_name) return row.name;
  };
  const handlePostNewName = async (name, id) => {
    const file = orderData.files.find((order) => order.id === id);
    const response = await fetch(`${DBurl}/setNewFileName`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify({
        mode: "update",
        data: {
          id: id,
          user_filename: name[id] ? name[id] : displayName(file),
        },
      }),
    });
    const json = await response.json();

    if (json.success) {
      setRefresh(true);
    }
  };

  useEffect(() => {
    handleDisabled();
  }, [allSelected]);

  useEffect(() => {
    setOrderDataID(orderData?.id);
  }, [JSON.stringify(orderData), refresh]);

  return (
    <>
      <Dialog
        open={openModalOrderFiles}
        TransitionComponent={Transition}
        onClose={() => {
          setOpenModalOrderFiles(false);
          setSelected([]);
          setSelectedWWW([]);
          setNewName([]);
          setEditName([]);
          setFiles([]);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {!orderData?.is_forwarding_order && (
          <DialogTitle
            id="alert-dialog-slide-title"
            className={stylesMod.dialogHead}
          >
            Lista plików kierowcy
          </DialogTitle>
        )}

        {!orderData?.is_forwarding_order && (
          <div>
            {orderData &&
            orderData.files.filter((file) => file.from_mobile).length > 0 ? (
              <DialogContent className={chatStyles.tableBox}>
                <TableContainer className={chatStyles.tableList}>
                  <Table stickyHeader size="small" aria-label="enhanced table">
                    <TableHead className={styles.tabHead}>
                      <TableRow>
                        <TableCell className={styles.checkbox}>
                          <Checkbox
                            indeterminate={
                              selected.length > 0 &&
                              selected.length <
                                orderData?.files.filter(
                                  (file) => file.from_mobile
                                ).length
                            }
                            checked={
                              orderData?.files.filter(
                                (file) => file.from_mobile
                              ).length > 0 &&
                              selected.length ===
                                orderData?.files.filter(
                                  (file) => file.from_mobile
                                ).length
                            }
                            onChange={handleSelectAllClick}
                            inputProps={{ "aria-labell": "select all id" }}
                          />
                        </TableCell>
                        <TableCell align="left">Nazwa pliku</TableCell>
                        <TableCell align="left"></TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody className={styles.tabBody}>
                      {orderData &&
                        orderData.files
                          .filter((file) => file.from_mobile)
                          .sort((a, b) => a.id - b.id)
                          .map((row, index) => {
                            const labelId = `enhanced-table-checkbox-${row.id}`;
                            const isItemSelected = isSelected(row.name);
                            return (
                              <TableRow
                                hover
                                onClick={(event) =>
                                  handleClick(event, row.name)
                                }
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.id}
                                selected={isItemSelected}
                              >
                                <TableCell style={{ padding: "0" }}>
                                  <Checkbox
                                    checked={isItemSelected}
                                    color="primary"
                                    inputProps={{ "aria-labelledby": labelId }}
                                  />
                                </TableCell>
                                <TableCell align="left">
                                  {editName.includes(row.id) ? (
                                    <input
                                      className={stylesMod.newOrderInput}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                      }}
                                      value={
                                        newName[row.id] || displayName(row)
                                      }
                                      onChange={(e) =>
                                        setNewName((prevValues) => ({
                                          ...prevValues,
                                          [row.id]: e.target.value,
                                        }))
                                      }
                                    />
                                  ) : (
                                    displayName(row)
                                  )}
                                </TableCell>
                                <TableCell align="right">
                                  <div className={styles.buttonBox}>
                                    {editName.includes(row.id) ? (
                                      <IcoBtn
                                        className={stylesMod.checkIco}
                                        icon="Check"
                                        tooltip={"Zapisz"}
                                        value={row}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          setEditName(
                                            editName.filter(
                                              (name) => name !== row.id
                                            )
                                          );
                                          handlePostNewName(newName, row.id);
                                        }}
                                      />
                                    ) : (
                                      <IcoBtn
                                        className={stylesMod.editIco}
                                        icon="Edit"
                                        tooltip={"Zmień nazwę"}
                                        value={row}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          setEditName([...editName, row.id]);
                                        }}
                                      />
                                    )}
                                    <IcoBtn
                                      className={chatStyles.listBtn}
                                      tooltip="Zobacz plik"
                                      value={row}
                                      onClick={() => {
                                        window.open(
                                          `${DBurl}/getFile?filename=${row.name}`,
                                          "_blank"
                                        );
                                      }}
                                    >
                                      <File tooltip="Zobacz plik" />
                                    </IcoBtn>
                                    <IcoBtn
                                      icon="Download"
                                      tooltip="Pobierz"
                                      className={chatStyles.listBtn}
                                      onClick={() => {
                                        fetch(
                                          `${DBurl}/getFile?filename=${row.name}`
                                        )
                                          .then((response) => response.blob())
                                          .then((blob) => {
                                            const link =
                                              document.createElement("a");
                                            link.download = displayName(row);
                                            link.href =
                                              URL.createObjectURL(blob);
                                            document.body.appendChild(link);
                                            link.click();
                                            URL.revokeObjectURL(link.href);
                                          });
                                      }}
                                    />
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </DialogContent>
            ) : (
              <div className={stylesMod.noFiles}>
                Brak plików do wyświetlenia.
              </div>
            )}
          </div>
        )}
        <DialogActions></DialogActions>
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Lista plików podpiętych do zlecenia
        </DialogTitle>
        {orderData &&
        [...orderData.files, ...files].filter((file) => !file.from_mobile)
          .length > 0 ? (
          <DialogContent className={chatStyles.tableBox}>
            <TableContainer className={chatStyles.tableList}>
              <Table stickyHeader size="small" aria-label="enhanced table">
                <TableHead className={styles.tabHead}>
                  <TableRow>
                    <TableCell className={styles.checkbox}>
                      <Checkbox
                        indeterminate={
                          selectedWWW.length > 0 &&
                          selectedWWW.length <
                            orderData?.files.filter((file) => !file.from_mobile)
                              .length
                        }
                        checked={
                          orderData?.files.filter((file) => !file.from_mobile)
                            .length > 0 &&
                          selectedWWW.length ===
                            orderData?.files.filter((file) => !file.from_mobile)
                              .length
                        }
                        onChange={handleSelectAllWWWClick}
                        inputProps={{ "aria-label": "select all WWW id" }}
                      />
                    </TableCell>
                    <TableCell align="left">Nazwa pliku</TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody className={styles.tabBody}>
                  {orderData &&
                    [...orderData.files, ...Array.from(files)]
                      .filter((file) => !file.from_mobile)
                      .sort((a, b) => a.id - b.id)
                      .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${row.id}`;
                        const isItemSelected = isSelectedWWW(row.name);
                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              !row.uploadedRecently &&
                              handleClickWWW(event, row.name)
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                          >
                            <TableCell className={styles.checkbox}>
                              {row.hasOwnProperty("from_mobile") && (
                                <Checkbox
                                  checked={isItemSelected}
                                  color="primary"
                                  inputProps={{ "aria-labellledby": labelId }}
                                />
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {editName.includes(row.id) ? (
                                <input
                                  className={stylesMod.newOrderInput}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }}
                                  value={newName[row.id] || displayName(row)}
                                  onChange={(e) =>
                                    setNewName((prevValues) => ({
                                      ...prevValues,
                                      [row.id]: e.target.value,
                                    }))
                                  }
                                />
                              ) : (
                                displayName(row)
                              )}
                            </TableCell>
                            <TableCell align="right">
                              {row.hasOwnProperty("from_mobile") && (
                                <div className={styles.buttonBox}>
                                  {editName.includes(row.id) ? (
                                    <IcoBtn
                                      className={stylesMod.checkIco}
                                      icon="Check"
                                      tooltip={"Zapisz"}
                                      value={row}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setEditName(
                                          editName.filter(
                                            (name) => name !== row.id
                                          )
                                        );
                                        handlePostNewName(newName, row.id);
                                      }}
                                    />
                                  ) : (
                                    <IcoBtn
                                      className={stylesMod.editIco}
                                      icon="Edit"
                                      tooltip={"Zmień nazwę"}
                                      value={row}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setEditName([...editName, row.id]);
                                      }}
                                    />
                                  )}
                                  <IcoBtn
                                    className={chatStyles.listBtn}
                                    tooltip="Zobacz plik"
                                    value={row}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      window.open(
                                        `${DBurl}/getFile?filename=${row.name}`,
                                        "_blank"
                                      );
                                    }}
                                  >
                                    <File tooltip="Zobacz plik" />
                                  </IcoBtn>
                                  <IcoBtn
                                    icon="Download"
                                    tooltip="Pobierz"
                                    className={chatStyles.listBtn}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      fetch(
                                        `${DBurl}/getFile?filename=${row.name}`
                                      )
                                        .then((response) => response.blob())
                                        .then((blob) => {
                                          const link =
                                            document.createElement("a");
                                          link.download = displayName(row);
                                          link.href = URL.createObjectURL(blob);
                                          document.body.appendChild(link);
                                          link.click();
                                          URL.revokeObjectURL(link.href);
                                        });
                                    }}
                                  />
                                </div>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        ) : (
          <div className={stylesMod.noFiles}>Brak plików do wyświetlenia.</div>
        )}
        <DialogActions>
          {files.length === 0 ? (
            <div>
              <input
                accept="*"
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={(e) => {
                  const selectedFiles = Array.from(e.target.files);
                  setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
                  e.target.value = null; // Clear the selected files from the input
                }}
              />
              <label htmlFor="raised-button-file">
                <Button
                  // disabled={user_s}
                  variant="contained"
                  size="large"
                  color="secondary"
                  component="span"
                  className={`${BTNstyles.btn} ${
                    files.length === 0 ? stylesMod.uploadBtn : stylesMod.saveBtn
                  }`}
                >
                  {files.length === 0 ? "Załaduj pliki" : "Zapisz"}
                </Button>
              </label>
            </div>
          ) : (
            <Button
              // disabled={user_s}
              onClick={(e) => {
                files.length > 0 && makeOrder("update", false, true, false);
              }}
              variant="contained"
              size="large"
              color="secondary"
              component="span"
              className={`${BTNstyles.btn} ${
                files.length === 0 ? stylesMod.uploadBtn : stylesMod.saveBtn
              }`}
            >
              Zapisz
            </Button>
          )}
          <Button
            variant="contained"
            size="large"
            color="primary"
            disabled={disabled}
            className={BTNstyles.btn}
            onClick={(e) => {
              handleDownload();
            }}
          >
            Pobierz zaznaczone
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrderFiles;
