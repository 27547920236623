/**
 * File with logic and structure for handling the "New client" tab.
 *
 * @author     Maciej Łukasiak feat. Adam Jachymek (fixes) <maciej.lukasiak@albatross.com.pl>
 * @copyright  (C) 2021 Albatross System Sp. z o.o.
 */

import React, { useState, useEffect } from "react";
import Title from "../../components/title";
import Input from "../../components/input";
import stylesMod from "../../styles/newOrEdit.module.css";
import BTNstyles from "../../styles/button.module.css";
import { Check, X } from "react-feather";
import Button from "@material-ui/core/Button";
import AddWarehouse from "./AddWarehouse";
import Grid from "@material-ui/core/Grid";
import Contact from "./Contact";
import { DBurl } from "../../appConfig";
import AddDepartment from "../../components/AddDepartment";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { AddressSearch } from "../../components/addressSearch";
import CarrierCar from "./CarrierCar";
import CarrierTrailer from "./CarrierTrailer";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddClient(props) {
  ///konta
  const initialBankAcc = {
    account_name: "",
    bank_name: "",
    bank_account_no: "",
    bank_vat_account_no: "",
    swift_bic_no: "",
    currency: "",
    is_default: false,
  };
  const [bank_accounts, setBank_accounts] = useState([initialBankAcc]);
  const [bank_accountsBE, setBank_accountsBE] = useState([initialBankAcc]);
  const [refetchAccounts, setRefetchAccounts] = useState(false);
  const handleSetNewBankAccData = (
    field,
    setNO,
    fieldNO,
    value,
    newCreatedDep,
    newDep
  ) => {
    setBank_accounts((prev) => {
      const setToChange = prev?.filter(
        (acc) =>
          acc?.id_department === setNO ||
          (setNO === 0 && acc?.id_department === undefined)
      );
      const setRest = prev?.filter(
        (acc) => acc?.id_department !== setNO && !acc?.justCreated
      );

      let toChange;

      if (newDep !== undefined) {
        toChange =
          newCreatedDep === undefined && setToChange.length
            ? { ...setToChange[fieldNO] }
            : { ...initialBankAcc };
      } else {
        toChange = { ...setToChange[fieldNO] } || { ...initialBankAcc };
      }

      if (field === "is_default") {
        const countNOK =
          bank_accounts.filter(
            (acc) => acc.is_default && acc.id_department === setNO
          ).length === 2;

        if (countNOK && !value) {
          return [...setRest, ...setToChange];
        }

        if (!countNOK) {
          toChange[field] = value;
        }
      } else {
        toChange[field] = value;
      }

      const setToChangeRest = setToChange?.filter(
        (_, index) => index !== fieldNO
      );
      toChange.id_department = setNO;
      toChange.departmentStatus = {
        status: newCreatedDep === null ? "new" : "old",
        number: setNO,
      };
      toChange.saveThisAcc = true;
      toChange.justCreated = false;
      toChange.accIndex = setNO;
      toChange.createdWithoutDep = newDep;

      setToChangeRest.splice(fieldNO, 0, toChange);
      const result = [...setRest, ...setToChangeRest];
      return result;
    });
  };

  const fetchDepartmentAccounts = async (
    {
      account_name,
      bank_name,
      bank_account_no,
      bank_vat_account_no,
      swift_bic_no,
      currency,
      is_default,
      id: alreadySavedId,
      id_department,
    },
    mode,
    id_dep
  ) => {
    if (mode === "update") {
    }
    const response = await fetch(`${DBurl}${"/setDepartmentsBankAccounts"}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode,
        data:
          mode === "insert"
            ? {
                id_department: id_dep || id_department,
                account_name,
                bank_name,
                bank_account_no,
                bank_vat_account_no,
                swift_bic_no,
                currency,
                is_default,
              }
            : {
                id: alreadySavedId,
                id_department: id_dep,
                account_name,
                bank_name,
                bank_account_no,
                bank_vat_account_no,
                swift_bic_no,
                currency,
                is_default,
              },
      }),
    });
    const json = await response.json();
    if (json.success) {
      setOpenDialog(true);
      setDialogColor("success");
      setDialogContent("Dane zapisane.");
    }
  };

  const getDepartmentsBankAccounts = async () => {
    props.loading(true);
    const response = await fetch(`${DBurl}/getDepartmentsBankAccounts`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    setBank_accounts(
      [...json.data].map((a) => {
        return { ...a, old: true };
      })
    );
    refetchAccounts &&
      setBank_accounts(
        [...json.data].map((a) => {
          return { ...a, old: true, newDep: true };
        })
      );
    setBank_accountsBE([...json.data]);
    props.loading(false);
    setRefetchAccounts(false);
    return json.data;
  };

  useEffect(() => {
    getDepartmentsBankAccounts();
  }, [refetchAccounts === true]);

  const fetchAllAccounts = async (id_dep, tempID, newDep) => {
    for (const bank_account of bank_accounts) {
      const mode =
        bank_account.hasOwnProperty("id") && bank_accountsBE.length
          ? "update"
          : "insert";

      const { id_department, saveThisAcc, createdWithoutDep, is_default } =
        bank_account;

      if (saveThisAcc) {
        if (createdWithoutDep) {
          id_department === tempID &&
            fetchDepartmentAccounts(bank_account, mode, id_dep);
        }
        if (!createdWithoutDep) {
          if (newDep !== undefined) {
            id_department === id_dep &&
              fetchDepartmentAccounts(bank_account, mode, id_dep);
          }

          if (newDep === undefined) {
            id_department === tempID &&
              fetchDepartmentAccounts(bank_account, mode, tempID);
          }

          if (is_default && newDep) {
            id_department === tempID &&
              fetchDepartmentAccounts(bank_account, mode, id_dep);
          }
        }
      }
    }
    setBank_accounts((prev) =>
      prev.map((acc) => {
        return {
          ...acc,
          saveThisAcc: false,
        };
      })
    );
  };

  const handleSaveDep = (newDepID, tempID, newDep) => {
    fetchAllAccounts(newDepID, tempID, newDep);
  };

  useEffect(() => {
    getDepartmentsBankAccounts();
  }, []);

  const fetchBoth = async () => {
    const id_dep = await fetchContractor(
      {
        name: name,
        shortname: shortname,
        nip: NIP,
        street: street ? street : null,
        city: city,
        zipcode: zipcode,
        country: country,
        street_no: street_no,
        apartment_no: apartment_no,
        timocomid: timocom,
        transeuid: transEU,
        ideuvat: EUVAT,
        comments: comments,
        contacts: contacts,
        rating: null,
        balance: null,
        district: null,
        id: props.state.id || null,
        warehouses: Warehouses,
        department: Department,
        latitude: latitude,
        longitude: longitude,
      },
      props.user.csrf_token
    );
  };

  const [name, setName] = useState(props.state.name || "");
  const [shortname, setShort] = useState(props.state.shortname || "");
  const [country, setCountry] = useState(
    props.state.country || props.state.address?.country || ""
  );
  const [city, setCity] = useState(
    props.state.city || props.state.address?.city || ""
  );
  const [street, setStreet] = useState(
    props.state.street || props.state.address?.street || ""
  );
  const [street_no, setStreetNr] = useState(
    props.state.street_no || props.state.address?.street_no || ""
  );
  const [apartment_no, setApartment_no] = useState(
    props.state.apartment_no || props.state.address?.apartment_no || ""
  );
  const [zipcode, setZipCode] = useState(
    props.state.zipcode || props.state.address?.zipcode || ""
  );
  const [latitude, setLatitude] = useState(
    props.state.latitude || props.state.address?.latitude || ""
  );
  const [longitude, setLongitude] = useState(
    props.state.longitude || props.state.address?.longitude || ""
  );
  const [manualCorrection, setManualCorrection] = useState(false);
  const [contacts, setContacts] = useState(
    props.state.contacts || [
      {
        firstname: "",
        surname: "",
        role: "",
        phone: "",
        email: "",
        primary_contact: true,
      },
    ]
  );
  const [comments, setComments] = useState(props.state.comments || "");
  const [saveDep, setSaveDep] = useState(false);
  const [NIP, setNIP] = useState(props.state.NIP || props.state.nip || "");
  const [EUVAT, setEUVAT] = useState(
    props.state.EUVAT || props.state.ideuvat || ""
  );
  const [timocom, setTimocom] = useState(
    props.state.timocom || props.state.timocomid || ""
  );
  const [transEU, setTransEU] = useState(
    props.state.transEU || props.state.transeuid || ""
  );
  const [checkWarehouse, setCheckWarehouse] = useState(
    props.state.checkWarehouse || false
  );
  const [checkAddress, setCheckAddress] = useState(
    props.state.checkAddress || false
  );

  const [contractor_id, setContractor_id] = useState(null);
  const [error, setError] = useState(false);
  const [Warehouses, setWarehouses] = useState(
    props.state.Warehouses || [
      {
        country: "",
        city: "",
        street: "",
        street_no: "",
        apartment_no: "",
        zipcode: "",
        latitude: null,
        longitude: null,
        contacts: [
          {
            firstname: "",
            surname: "",
            role: "",
            phone: "",
            email: "",
            primary_contact: true,
          },
        ],
        checkedAddress: false,
      },
    ]
  );

  const [checkDepartmentAddress, setCheckDepartmentAddress] = useState(
    props.state.checkDepartmentAddress || false
  );
  const [Department, setDepartment] = useState(
    props.state.Department || [
      {
        name: "",
        shortname: "",
        id_parent: null,
        nip: "",
        street: "",
        city: "",
        zipcode: "",
        country: "",
        district: "",
        street_no: "",
        apartment_no: "",
        comments: "",
        newDep: true,
      },
    ]
  );
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");
  const [hideWarehouse, setHideWarehouse] = useState(false);
  const [hideDepartment, setHideDepartment] = useState(false);
  const [hideData, setHideData] = useState(false);
  const [isCarrier, setIsCarrier] = useState(props.state.isCarrier || false);
  const [carrierCars, setCarrierCars] = useState(
    props.state.carrierCars || [
      {
        model: "",
        brand: "",
        license_plate: "",
        comment: "",
      },
    ]
  );
  const [carrierTrailers, setCarrierTrailers] = useState(
    props.state.carrierTrailers || [
      {
        model: "",
        brand: "",
        license_plate: "",
        comment: "",
      },
    ]
  );

  const [address_display_name, setAddress_display_name] = useState(
    props.state.address_display_name || ""
  );

  const [newClientID, setNewClientID] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDialog(false);
  };

  const checkError = () => {
    if (name && shortname && country && city && zipcode && NIP) {
      let noError = true;

      if (!isCarrier && checkWarehouse) {
        Warehouses.forEach((e, i) => {
          if (!e.name || !e.country || !e.city || !e.zipcode) {
            noError = false;
          }
        });
      }
      !isCarrier &&
        Department.forEach((e, i) => {
          if (
            !e.name ||
            !e.shortname ||
            !e.nip ||
            !e.country ||
            !e.city ||
            !e.zipcode
          ) {
            noError = false;
          }
        });
      !isCarrier &&
        bank_accounts.forEach((e, i) => {
          if (!e.account_name || !e.bank_account_no || !e.bank_name) {
            noError = false;
          }
        });
      if (!noError) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (props.state.edit && props?.state?.Warehouses[0]?.id) {
      setCheckWarehouse(true);
    }
    if (props.state.edit && !props?.state?.Warehouses[0]?.id) {
      setCheckWarehouse(false);
    }
  }, []);

  const clearData = () => {
    setOpenModalDelete(false);
    setName("");
    setShort("");
    setCountry("");
    setApartment_no("");
    setCity("");
    setLatitude("");
    setLongitude("");
    setStreet("");
    setStreetNr("");
    setZipCode("");
    setContacts([
      {
        firstname: "",
        surname: "",
        role: "",
        phone: "",
        email: "",
        primary_contact: true,
      },
    ]);
    setComments("");
    setNIP("");
    setEUVAT("");
    setTimocom("");
    setTransEU("");
    setCheckWarehouse(false);
    setCheckAddress(false);
    setWarehouses([
      {
        country: "",
        city: "",
        street: "",
        street_no: "",
        apartment_no: "",
        zipcode: "",
        contacts: [
          {
            firstname: "",
            surname: "",
            role: "",
            phone: "",
            email: "",
            primary_contact: true,
          },
        ],
        checkedAddress: false,
      },
    ]);
    setIsCarrier(false);
    setCarrierCars([
      {
        model: "",
        brand: "",
        license_plate: "",
        comment: "",
      },
    ]);
    setCarrierTrailers([
      {
        model: "",
        brand: "",
        license_plate: "",
        comment: "",
      },
    ]);
    setDepartment([
      {
        name: "",
        shortname: "",
        id_parent: null,
        nip: "",
        street: "",
        city: "",
        zipcode: "",
        country: "",
        district: "",
        street_no: "",
        apartment_no: "",
        comments: "",
        newDep: true,
      },
    ]);
    // setBank_accounts([initialBankAcc])
    setRefetchAccounts(true);
    setAddress_display_name("");
  };

  const updateWarehouse = (data, name, i, sub, subi) => {
    const tempWarehouses = [...Warehouses];
    if (sub && subi) {
      tempWarehouses[i][sub][subi][name] = data;
    } else if (sub) {
      tempWarehouses[i][sub] = contacts;
    } else {
      tempWarehouses[i][name] = data;
      setCheckAddress(false);
    }

    setWarehouses(tempWarehouses);
  };

  const warehouseAddContact = (warehouseKey) => {
    const tempWarehouses = [...Warehouses];
    if (warehouseKey > -1) {
      tempWarehouses[warehouseKey].contacts = [
        ...Warehouses[warehouseKey].contacts,
        {
          firstname: "",
          surname: "",
          role: "",
          phone: "",
          email: "",
          primary_contact: false,
        },
      ];
      setWarehouses(tempWarehouses);
    }
  };

  const warehouseRemoveContact = (contactKey, warehouseKey) => {
    const tempWarehouses = [...Warehouses];
    if (warehouseKey > -1) {
      tempWarehouses[warehouseKey].contacts = [
        ...Warehouses[warehouseKey].contacts,
      ];
      if (tempWarehouses[warehouseKey].contacts.length !== 1) {
        tempWarehouses[warehouseKey].contacts.splice(contactKey, 1);
      } else {
        tempWarehouses[warehouseKey].contacts = [
          {
            firstname: "",
            surname: "",
            role: "",
            phone: "",
            email: "",
            primary_contact: true,
          },
        ];
      }
      setWarehouses(tempWarehouses);
    }
  };

  const warehouseHandleContact = (data, name, contactKey, warehouseKey) => {
    const tempWarehouses = [...Warehouses];
    if (warehouseKey > -1) {
      tempWarehouses[warehouseKey].contacts = [
        ...Warehouses[warehouseKey].contacts,
      ];
      tempWarehouses[warehouseKey].contacts[contactKey][name] = data;
    }
    // tempContact[i][name] = data
    setWarehouses(tempWarehouses);
  };

  const handleContact = (data, name, i) => {
    const tempContact = [...contacts];
    tempContact[i][name] = data;
    setContacts(tempContact);
  };

  const addContact = () => {
    const tempContact = [
      ...contacts,
      {
        firstname: "",
        surname: "",
        role: "",
        phone: "",
        email: "",
        primary_contact: false,
      },
    ];
    setContacts(tempContact);
  };

  const removeContact = (i) => {
    let tempContact = [...contacts];
    if (tempContact.length !== 1) {
      tempContact.splice(i, 1);
    } else {
      tempContact = [
        {
          firstname: "",
          surname: "",
          role: "",
          phone: "",
          email: "",
          primary_contact: true,
        },
      ];
    }
    setContacts(tempContact);
  };

  const handleCar = (data, name, i) => {
    const tempCar = [...carrierCars];
    tempCar[i][name] = data;
    setCarrierCars(tempCar);
  };

  const addCar = () => {
    const tempCar = [
      ...carrierCars,
      {
        model: "",
        brand: "",
        license_plate: "",
        comment: "",
      },
    ];
    setCarrierCars(tempCar);
  };

  const removeCar = (i) => {
    let tempCars = [...carrierCars];
    if (tempCars.length !== 1) {
      tempCars.splice(i, 1);
    } else {
      tempCars = [
        {
          model: "",
          brand: "",
          license_plate: "",
          comment: "",
        },
      ];
    }
    setCarrierCars(tempCars);
  };

  const handleTrailer = (data, name, i) => {
    const tempTrailer = [...carrierTrailers];
    tempTrailer[i][name] = data;
    setCarrierTrailers(tempTrailer);
  };

  const addTrailer = () => {
    const tempTrailer = [
      ...carrierTrailers,
      {
        model: "",
        brand: "",
        license_plate: "",
        comment: "",
      },
    ];
    setCarrierTrailers(tempTrailer);
  };

  const removeTrailer = (i) => {
    let tempTrailers = [...carrierTrailers];
    if (tempTrailers.length !== 1) {
      tempTrailers.splice(i, 1);
    } else {
      tempTrailers = [
        {
          model: "",
          brand: "",
          license_plate: "",
          comment: "",
        },
      ];
    }
    setCarrierTrailers(tempTrailers);
  };

  const contactModule = contacts.map((e, i) => {
    return (
      <Contact
        contacts={e}
        key={i}
        propKey={i}
        addContact={addContact}
        removeContact={removeContact}
        handleContact={handleContact}
        error={error}
      />
    );
  });

  const carrierCarModule = carrierCars.map((e, i) => {
    return (
      <CarrierCar
        carrierCars={e}
        propKey={i}
        key={i}
        addCar={addCar}
        removeCar={removeCar}
        handleCar={handleCar}
        error={error}
      />
    );
  });

  const carrierTrailerModule = carrierTrailers.map((e, i) => {
    return (
      <CarrierTrailer
        carrierTrailers={e}
        key={i}
        propKey={i}
        addTrailer={addTrailer}
        removeTrailer={removeTrailer}
        handleTrailer={handleTrailer}
        error={error}
      />
    );
  });

  const addWarehouseFunc = () => {
    const tempWarehouses = [
      ...Warehouses,
      {
        country: "",
        city: "",
        street: "",
        street_no: "",
        apartment_no: "",
        zipcode: "",
        latitude: null,
        longitude: null,
        contacts: [
          {
            firstname: "",
            surname: "",
            role: "",
            phone: "",
            email: "",
            primary_contact: true,
          },
        ],
      },
    ];
    setWarehouses(tempWarehouses);
  };

  const removeWarehouseFunc = (i) => {
    var tempWarehouses = [...Warehouses];

    if (tempWarehouses.length !== 1) {
      tempWarehouses.splice(i, 1);
    } else {
      tempWarehouses = [
        {
          country: "",
          city: "",
          street: "",
          street_no: "",
          apartment_no: "",
          zipcode: "",
          latitude: null,
          longitude: null,
          contacts: [
            {
              firstname: "",
              surname: "",
              role: "",
              phone: "",
              email: "",
              primary_contact: true,
            },
          ],
          checkedAddress: false,
          primary_contact: true,
        },
      ];
    }

    setWarehouses(tempWarehouses);
  };

  const isSuccess = () => {
    props.setDialog({
      isOpen: true,
      type: "success",
      content: props.state.edit ? "Klient zapisany" : "Klient dodany",
    });
    props.tabCloseAndChangeTo("ClientList");
  };

  const fetchContractor = async (data, token) => {
    props.loading(true);
    if (data.longitude !== "" && data.latitude !== "") {
      const response = await fetch(`${DBurl}/setContractors`, {
        method: "post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token,
        },
        body: JSON.stringify({
          mode: props.state.edit ? "update" : "insert",
          data: data,
        }),
      });

      const json = await response.json();

      if (json.success) {
        if (json.data.return === "duplicate") {
          setOpenDialog(true);
          setDialogContent(
            `Podany numer NIP figuruje już w bazie danych pod nazwą "${json.data.duplicate_name}"`
          );
          setDialogColor("error");
          props.loading(false);
          return json.data.id_contractor;
        } else {
          setContractor_id(json.data.id_contractor);
          var warehouseAddingCheck = true;

          if (checkWarehouse) {
            for await (const [i, e] of data.warehouses.entries()) {
              let tempWarehouse = {
                id_contractor: json.data.id_contractor || props.state.id,
                name: i > 0 ? `${e.name}-${i + 1}` : e.name,
                shortname: e.name,
                district: null,
                longitude: e.longitude,
                latitude: e.latitude,
                address_display_name: null,
                id: e.id,
                ...e,
              };

              if (
                tempWarehouse.longitude !== "" ||
                tempWarehouse.latitude !== ""
              ) {
                const response2 = await fetch(`${DBurl}/setWarehouses/`, {
                  method: "post",
                  credentials: "include",
                  headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-Token": token,
                  },
                  body: JSON.stringify({
                    mode: tempWarehouse.id ? "update" : "insert",
                    data: tempWarehouse,
                  }),
                });

                const json2 = await response2.json();
                if (!json2.success) {
                  warehouseAddingCheck = false;
                }
              } else {
                setOpenDialog(true);
                setDialogContent(`Niepoprawna lokalizacja magazynu`);
                setDialogColor("error");
              }
            }
            if (warehouseAddingCheck) {
              if (data.warehouses.length > 1) {
                setDialogContent(`Klient i magazyny zapisane`);
              } else {
                setDialogContent(`Klient i magazyn zapisany`);
              }
              setDialogColor("success");
              isSuccess();
            } else {
              setDialogColor("error");
              setDialogContent(`Błąd podczas zapisywania magazynu`);
            }
          } else {
            isSuccess();
          }

          setSaveDep(true);

          props.loading(false);
          return json.data.id_contractor;
        }
      } else {
        setOpenDialog(true);
        setDialogContent(`${json.responseDescription} // ${json.response}  `);
        setDialogColor("error");
        props.loading(false);
      }
    } else {
      setOpenDialog(true);
      setDialogContent(`Niepoprawna lokalizacja klienta`);
      setDialogColor("error");
      props.loading(false);
    }
  };

  const fetchCarrier = async () => {
    const response = await fetch(`${DBurl}/setCarriers`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: props.state.edit ? "update" : "insert",
        data: {
          id: props.state.id || null,
          id_firm: props.user.id_firm,
          id_customer: props.user.customer_id,
          name: name,
          shortname: shortname,
          nip: NIP,
          street: street ? street : null,
          city: city,
          zipcode: zipcode,
          country: country,
          district: "",
          street_no: street_no,
          apartment_no: apartment_no ? apartment_no.toString() : "",
          rating: "",
          balance: 1,
          timocomid: timocom ? timocom : "",
          transeuid: transEU ? transEU : "",
          ideuvat: EUVAT ? EUVAT : "",
          comments: comments,
          longitude: longitude,
          latitude: latitude,
          recipient_other_than_buyer: false,
          cars: carrierCars,
          trailers: carrierTrailers,
          contacts: contacts,
        },
      }),
    });
    const json = await response.json();
    if (json.success) {
      isSuccess();
    } else {
      props.setDialog({
        isOpen: true,
        type: "error",
        content: `${json.responseDescription} // ${json.response}`,
      });
    }
  };

  const savedTabId = props.tabID;
  const saveTab = () => {
    const tempTab = { ...props.tabs[savedTabId] };
    tempTab.name = name;
    tempTab.shortname = shortname;
    tempTab.country = country;
    tempTab.street = street;
    tempTab.street_no = street_no;
    tempTab.apartment_no = apartment_no;
    tempTab.zipcode = zipcode;
    tempTab.contacts = contacts;
    tempTab.comments = comments;
    tempTab.timocom = timocom;
    tempTab.NIP = NIP;
    tempTab.EUVAT = EUVAT;
    tempTab.transEU = transEU;
    tempTab.Warehouses = Warehouses;
    tempTab.Department = Department;
    tempTab.carrierCars = carrierCars;
    tempTab.carrierTrailers = carrierTrailers;
    tempTab.isCarrier = isCarrier;
    tempTab.address_display_name = address_display_name;
    tempTab.city = city;

    return tempTab;
  };

  useEffect(() => {
    props.setTabs(() => {
      const tempTab = [...props.tabs];
      tempTab[props.tabID] = saveTab();
      return tempTab;
    });
  }, [
    name,
    shortname,
    country,
    street,
    street_no,
    apartment_no,
    zipcode,
    contacts,
    comments,
    timocom,
    NIP,
    EUVAT,
    transEU,
    Warehouses,
    Department,
    isCarrier,
    carrierCars,
    carrierTrailers,
    address_display_name,
    city,
  ]);

  useEffect(() => {
    if (props.state.edit && props.state.cars?.length > 0)
      setCarrierCars(props.state.cars);
    if (props.state.edit && props.state.trailers?.length > 0)
      setCarrierTrailers(props.state.trailers);
  }, [props.state.edit === true]);

  const warehouseModule = (
    <>
      <Title
        title="Magazyny"
        hide={{ is: hideWarehouse, set: setHideWarehouse }}
        btnBox
      />
      <Grid
        container
        spacing={2}
        className={`${stylesMod.cont2} ${hideWarehouse && stylesMod.hide}`}
      >
        <Grid item xs={12} className={stylesMod.checkBoxes}>
          <h6>Adres</h6>
          <div className={stylesMod.checkPar}>
            <input
              type="checkbox"
              className={stylesMod.check}
              checked={checkWarehouse}
              onChange={(e) => {
                setCheckWarehouse(!!e.target.checked);
              }}
              name="checkWarehouse"
            />
            <label>Klient posiada magazyn</label>
          </div>
          <div className={stylesMod.checkPar}>
            <input
              type="checkbox"
              className={stylesMod.check}
              checked={checkAddress}
              onChange={(e) => {
                const tempWarehouses = [...Warehouses];
                tempWarehouses[0].country = country;
                tempWarehouses[0].city = city;
                tempWarehouses[0].street = street || null;
                tempWarehouses[0].street_no = street_no;
                tempWarehouses[0].apartment_no = apartment_no;
                tempWarehouses[0].zipcode = zipcode;
                tempWarehouses[0].latitude = latitude;
                tempWarehouses[0].longitude = longitude;
                setWarehouses(tempWarehouses);
                setCheckAddress(e.target.checked);
              }}
              name="checkAddress"
              disabled={!checkWarehouse}
            />
            <label>Taki sam adres jak klienta</label>
          </div>
        </Grid>

        {Warehouses.map((e, i) => {
          return (
            <AddWarehouse
              name={e.name}
              country={e.country}
              city={e.city}
              street={e.street}
              street_no={e.street_no}
              apartment_no={e.apartment_no}
              zipcode={e.zipcode}
              setCheckedContact={() => {
                const tempWarehouses = [...Warehouses];
                tempWarehouses[i].contacts = [...contacts];
                setWarehouses(tempWarehouses);
              }}
              checkedContact={e.checkedContact}
              contacts={e.contacts}
              handleChange={updateWarehouse}
              isDisabled={!checkWarehouse}
              warehouseAddContact={warehouseAddContact}
              warehouseRemoveContact={warehouseRemoveContact}
              warehouseHandleContact={warehouseHandleContact}
              propKey={i}
              key={i}
              addWarehouseFunc={addWarehouseFunc}
              removeWarehouseFunc={removeWarehouseFunc}
              showDeleteWarehouse={Warehouses.length > 1}
              isLast={i === Warehouses.length - 1}
              error={checkWarehouse && error}
              csrf_token={props.user.csrf_token}
            />
          );
        })}
      </Grid>
    </>
  );

  const departmenteModule = (
    <>
      <Title
        title="Oddziały"
        hide={{ is: hideDepartment, set: setHideDepartment }}
        btnBox
      />
      <Grid
        container
        spacing={2}
        className={`${stylesMod.cont2} ${hideDepartment && stylesMod.hide}`}
      >
        <Grid item xs={12} className={stylesMod.checkBoxes}>
          <h6>Adres</h6>
          <div className={stylesMod.checkPar}>
            <input
              type="checkbox"
              className={stylesMod.check}
              checked={checkDepartmentAddress}
              onChange={(e) => {
                if (!checkDepartmentAddress) {
                  const tempDepartment = [...Department];
                  tempDepartment[0].nip = NIP;
                  tempDepartment[0].country = country;
                  tempDepartment[0].comments = comments;
                  tempDepartment[0].city = city;
                  tempDepartment[0].street = street || null;
                  tempDepartment[0].street_no = street_no;
                  tempDepartment[0].apartment_no = apartment_no;
                  tempDepartment[0].zipcode = zipcode;
                  tempDepartment[0].name = name;
                  tempDepartment[0].shortname = shortname;
                  setDepartment(tempDepartment);
                }

                setCheckDepartmentAddress(!checkDepartmentAddress);
              }}
              name="checkDepartmentAddress"
            />
            <label>Takie same jak dane klienta</label>
          </div>
        </Grid>

        {Department.sort((a, b) => a.id - b.id).map((e, i) => {
          return (
            <AddDepartment
              handleSaveDep={handleSaveDep}
              newClientID={newClientID}
              fetchAllAccounts={fetchAllAccounts}
              addContractor
              department={e}
              allDepartments={Department}
              setOpen={() => {}}
              key={i}
              edit={props.state.edit}
              propkey={i}
              setDepartment={setDepartment}
              parent_id={
                contractor_id ||
                (Array.isArray(props?.state?.Department)
                  ? props?.state?.id
                  : contractor_id)
              }
              token={props.user.csrf_token}
              saveDep={saveDep}
              newDep={e?.newDep}
              loading={props.loading}
              addDepartmentFunc={() => {
                setDepartment([
                  ...Department,
                  {
                    name: "",
                    shortname: "",
                    id_parent: null,
                    nip: "",
                    street: "",
                    city: "",
                    zipcode: "",
                    country: "",
                    district: "",
                    street_no: "",
                    apartment_no: "",
                    comments: "",
                    newDep: true,
                  },
                ]);
              }}
              removeDepartmentFunc={() => {
                if (Department.length > 1) {
                  const updatedDepartments = [...Department]; // Create a shallow copy of the array
                  updatedDepartments.splice(i, 1); // Remove the department at index 'i'
                  setDepartment(updatedDepartments); // Update the state with the new array
                } else {
                  setDepartment({
                    name: "",
                    shortname: "",
                    id_parent: null,
                    nip: "",
                    street: "",
                    city: "",
                    zipcode: "",
                    country: "",
                    district: "",
                    street_no: "",
                    apartment_no: "",
                    comments: "",
                  });
                }
              }}
              error={error}
              showDelete={Department.length > 1}
              setBank_accounts={setBank_accounts}
              bank_accounts={bank_accounts.filter((acc) => {
                const { id_department, justCreated, saveThisAcc, accIndex } =
                  acc;
                if (e.id === undefined) {
                  return (justCreated || saveThisAcc) && accIndex === i;
                }
                if (e.id !== undefined) {
                  return id_department === e.id;
                }
                return false;
              })}
              allAccounts={bank_accounts}
              handleSetNewBankAccData={handleSetNewBankAccData}
              isSuccess={isSuccess}
            />
          );
        })}
      </Grid>
    </>
  );

  const getDataByNipOrEuvat = (code) => {
    let temp;
    let result;
    if (typeof code === "string") {
      temp = code
        .replaceAll("-", "")
        .replaceAll(" ", "")
        .replaceAll(".", "")
        .replaceAll(";", "")
        .replaceAll(",", "");
      result = temp;
      setNIP(result);
    } else {
      result = code;
    }
    if (Number.isNaN(Number(result[0]))) {
      checkEUVAT(result);
    } else {
      getDataByNip(result);
    }
  };

  const getDataByNip = async (nip) => {
    if (`${nip}`.length !== 10) {
      setOpenDialog(true);
      setDialogContent(`Niepoprawny NIP`);
      setDialogColor("error");
    } else {
      const response = await fetch(`${DBurl}/gusApi?nip=${nip}`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
      });
      const json = await response.json();
      if (json.success) {
        setName(json.data.name);
        if (
          json.data.city === "" ||
          json.data.zipCode === "" ||
          json.data.street === "" ||
          json.data.propertyNumber === ""
        ) {
          setOpenDialog(true);
          setDialogContent(
            `NIP poprawny, brak danych adresowych. Proszę uzupełnić adres ręcznie.`
          );
          setDialogColor("success");
          setCity(json.data.city);
          setStreet(json.data.street);
          setStreetNr(json.data.propertyNumber);
          setZipCode(json.data.zipCode);
          setApartment_no(json.data.apartmentNumber);
          setCountry("");
        } else {
          !!json.data.city && setCountry("Polska");
          !!json.data.city && setCity(json.data.city);
          !!json.data.street && setStreet(json.data.street);
          !!json.data.propertyNumber && setStreetNr(json.data.propertyNumber);
          !!json.data.zipCode && setZipCode(json.data.zipCode);
          !!json.data.apartmentNumber &&
            setApartment_no(json.data.apartmentNumber);

          let temp_str =
            json.data.city +
            "," +
            json.data.street +
            " " +
            json.data.propertyNumber +
            "," +
            json.data.apartmentNumber +
            "," +
            json.data.zipCode;
          searchByStr(temp_str);

          setOpenDialog(true);
          setDialogContent(`NIP poprawny, uzupełniono dane`);
          setDialogColor("success");
          setAddress_display_name("");
        }
      }
      if (json.data.status !== "ok") {
        setOpenDialog(true);
        setDialogContent(`Niepoprawne dane`);
        setDialogColor("error");
      }
    }
  };

  const getCountryByCode = (code) => {
    switch (code) {
      case "AT":
        return "Austria";
      case "BE":
        return "Belgia";
      case "BG":
        return "Bułgaria";
      case "CY":
        return "Cypr";
      case "CZ":
        return "Czechy";
      case "DE":
        return "Niemcy";
      case "DK":
        return "Dania";
      case "EE":
        return "Estonia";
      case "EL":
        return "Grecja";
      case "ES":
        return "Hiszpania";
      case "FI":
        return "Finlandia";
      case "FR":
        return "Francja";
      case "HU":
        return "Węgry";
      case "IE":
        return "Irlandia";
      case "IT":
        return "Włochy";
      case "LT":
        return "Litwa";
      case "LU":
        return "Luksemburg";
      case "LV":
        return "Łotwa";
      case "MT":
        return "Malta";
      case "NL":
        return "Holandia";
      case "PL":
        return "Polska";
      case "PT":
        return "Portugalia";
      case "RO":
        return "Rumunia";
      case "SE":
        return "Szwecja";
      case "SK":
        return "Słowacja";
      case "XI":
        return "Irlandia Pólnocna";
    }
  };
  const searchByStr = async (str) => {
    const response = await fetch(`${DBurl}/search?term=${str}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success && json.data?.length > 0) {
      setLatitude(json.data[0].lat);
      setLongitude(json.data[0].lon);
    } else if (json.success && json.data?.length === 0) {
      setOpenDialog(true);
      setDialogContent(`Nie znaleziono danych adresowych.`);
      setDialogColor("warning");
      setCity("");
      setStreet("");
      setStreetNr("");
      setZipCode("");
      setApartment_no("");
      setCountry("");
    }
  };

  const searchByLonLan = async (lon, lat) => {
    const response = await fetch(`${DBurl}/reverse?lon=${lon}&lat=${lat}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      !!json.data.address.city && setCity(json.data.address.city);
      !!json.data.address.street && setStreet(json.data.address.street);
      !!json.data.address.road && setStreet(json.data.address.road);
      !!json.data.address.propertyNumber &&
        setStreetNr(json.data.address.propertyNumber);
      !!json.data.address.house_number &&
        setStreetNr(json.data.address.house_number);
      !!json.data.address.postcode && setZipCode(json.data.address.postcode);
      !!json.data.address.apartmentNumber &&
        setApartment_no(json.data.address.apartmentNumber);
      setOpenDialog(true);
      setDialogContent(`Uzupełenione dane - Sprawdź poprawność`);
      setDialogColor("success");
    }
  };

  const checkEUVAT = async (euvatNum) => {
    const countryCode = euvatNum.slice(0, 2).toUpperCase();
    let numberCode = euvatNum.slice(2);
    const response = await fetch(
      `${DBurl}/euVatApi?nip=${numberCode.replace(
        /\s/g,
        ""
      )}&country=${countryCode}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setName(json.data.name);
      setCountry(getCountryByCode(countryCode));
      searchByStr(json.data.address);
      setOpenDialog(true);
      setDialogContent(`NIP poprawny, uzupełniono dane`);
      setDialogColor("success");
      setAddress_display_name("");
    } else {
      setOpenDialog(true);
      setDialogContent(`Niepoprawne dane`);
      setDialogColor("error");
    }
  };

  return (
    <>
      <Title
        title={props.state.tabName}
        hide={{ is: hideData, set: setHideData }}
        btnBox
      />
      <Grid
        container
        spacing={5}
        className={`${stylesMod.cont} ${hideData && stylesMod.hide}`}
      >
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <div className={stylesMod.inp}>
                <div className={stylesMod.clientCarrierContainer}>
                  <h6>Dane kontrahenta</h6>
                  <div className={stylesMod.checkPar}>
                    <input
                      type="checkbox"
                      className={stylesMod.check}
                      checked={isCarrier}
                      onChange={(e) => {
                        setIsCarrier(!!e.target.checked);
                      }}
                      name="isCarrier"
                    />
                    <label>Przewoźnik</label>
                  </div>
                </div>
                <label>Nazwa</label>
                <Input
                  value={name}
                  handleChange={(e) => {
                    setName(e.target.value);
                  }}
                  name="name"
                  error={!name && error}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={stylesMod.inp}>
                <label>Nazwa skrócona</label>
                <Input
                  value={shortname}
                  handleChange={(e) => {
                    setShort(e.target.value);
                  }}
                  name="shortname"
                  error={!shortname && error}
                />
              </div>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={2} className={stylesMod.blParent}>
              <div className={stylesMod.bl}></div>
              <div className={stylesMod.inp}>
                <label>
                  NIP <span>- format: 1234567890 </span>{" "}
                  {NIP.length !== 10 && error && (
                    <span className={stylesMod.error}>
                      {" "}
                      - Nieprawidłowy NIP{" "}
                    </span>
                  )}
                </label>
                <Input
                  value={NIP}
                  handleChange={(e) => {
                    setNIP(e.target.value);
                  }}
                  name="NIP"
                  search
                  serchFunc={(e) => {
                    getDataByNipOrEuvat(NIP);
                  }}
                  error={NIP.length !== 10 && error}
                  type="tel"
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={stylesMod.inp}>
                <label>Identyfikator EU VAT</label>
                <Input
                  value={EUVAT}
                  handleChange={(e) => {
                    setEUVAT(e.target.value);
                  }}
                  name="EUVAT"
                  search
                  serchFunc={() => {
                    getDataByNipOrEuvat(EUVAT);
                  }}
                />
              </div>
            </Grid>

            <Grid item xs={3}>
              <div className={stylesMod.inp}>
                <h6>Adres</h6>
                <AddressSearch
                  token={props.user.csrf_token}
                  error={error && !address_display_name}
                  setAddress={(e) => {
                    setAddress_display_name(e.address_display_name);
                    setCountry(e.country);
                    setCity(e.city, "city", props.propKey);
                    setStreet(e.street, "street", props.propKey);
                    setStreetNr(e.street_no, "street_no", props.propKey);
                    setApartment_no(
                      e.apartment_no,
                      "apartment_no",
                      props.propKey
                    );
                    setZipCode(e.zipcode, "zipcode", props.propKey);
                    setLatitude(e.latitude, "latitude", props.propKey);
                    setLongitude(e.longitude, "longitude", props.propKey);
                  }}
                  newValue={{ address_display_name }}
                />
              </div>
            </Grid>

            <Grid item xs={1}>
              <div className={`${stylesMod.inp} ${stylesMod.sm}`}>
                <div className={stylesMod.addressCorrection}>
                  <h6>Adres</h6>
                  {latitude && longitude && (
                    <div
                      className={stylesMod.addressCorrectionCheckboxContainer}
                    >
                      <input
                        type="checkbox"
                        className={stylesMod.check}
                        checked={manualCorrection}
                        onChange={(e) => {
                          setManualCorrection(!!e.target.checked);
                        }}
                        name="manualCorrection"
                      />
                      <label>Popraw adres ręcznie</label>
                    </div>
                  )}
                </div>
                <label>Kraj</label>
                <Input
                  value={country}
                  handleChange={(e) => {
                    setCountry(e.target.value);
                  }}
                  name="country"
                  error={!country && error}
                  disabled={!manualCorrection}
                />
              </div>
            </Grid>
            <Grid item xs={1}>
              <div className={stylesMod.inp}>
                <label>Miasto</label>
                <Input
                  value={city}
                  handleChange={(e) => {
                    setCity(e.target.value);
                  }}
                  name="city"
                  error={!city && error}
                  disabled={!manualCorrection}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={stylesMod.inp}>
                <label>Ulica</label>
                <Input
                  value={street}
                  handleChange={(e) => {
                    setStreet(e.target.value);
                  }}
                  name="street"
                  // error={!street && error}
                  disabled={!manualCorrection}
                />
              </div>
            </Grid>
            <Grid item xs={1}>
              <div className={stylesMod.inp}>
                <label>Numer</label>
                <Input
                  value={street_no}
                  handleChange={(e) => {
                    setStreetNr(e.target.value);
                  }}
                  name="street_no"
                  // disabled
                />
              </div>
            </Grid>
            <Grid item xs={1}>
              <div className={stylesMod.inp}>
                <label>Lokal</label>
                <Input
                  value={apartment_no}
                  handleChange={(e) => {
                    setApartment_no(e.target.value);
                  }}
                  name="apartment_no"
                />
              </div>
            </Grid>
            <Grid item xs={1}>
              <div className={stylesMod.inp}>
                <label>Kod pocztowy</label>
                <Input
                  value={zipcode}
                  handleChange={(e) => {
                    setZipCode(e.target.value);
                  }}
                  name="ZipCode"
                  error={!zipcode && error}
                  disabled={!manualCorrection}
                />
              </div>
            </Grid>

            <Grid item xs={1} />

            <Grid item xs={2} className={stylesMod.blParent}>
              <div className={stylesMod.bl}></div>
              <div className={stylesMod.inp}>
                <label>Identyfikator Timocom</label>
                <Input
                  value={timocom}
                  handleChange={(e) => {
                    setTimocom(e.target.value);
                  }}
                  name="timocom"
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={stylesMod.inp}>
                <label>Identyfikator Trans.eu</label>
                <Input
                  value={transEU}
                  handleChange={(e) => {
                    setTransEU(e.target.value);
                  }}
                  name="transEU"
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              className={`${stylesMod.checkBoxes} ${stylesMod.pb0}`}
            >
              <h6>Osoba do kontaktu</h6>
            </Grid>

            {contactModule}

            <Grid item xs={5}>
              <div className={`${stylesMod.textareaPrn} ${stylesMod.inp}`}>
                <h6>Uwagi</h6>
                <label>Uwagi</label>
                <textarea
                  rows="4"
                  className={stylesMod.textarea}
                  value={comments}
                  onChange={(e) => {
                    setComments(e.target.value);
                  }}
                  name="comments"
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!isCarrier ? (
        warehouseModule
      ) : (
        <div>
          <Title title="Pojazdy" btnBox />
          {carrierCarModule}
        </div>
      )}

      {!isCarrier ? (
        departmenteModule
      ) : (
        <div>
          <Title title="Naczepy" btnBox />
          {carrierTrailerModule}
        </div>
      )}

      <div className={BTNstyles.btnRow}>
        <Button
          variant="contained"
          size="large"
          className={`${BTNstyles.btn} ${BTNstyles.err}`}
          onClick={() => setOpenModalDelete(true)}
        >
          Anuluj <X className={BTNstyles.downBtnIco} />
        </Button>

        <Dialog
          open={openModalDelete}
          TransitionComponent={Transition}
          onClose={() => {
            setOpenModalDelete(false);
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            className={stylesMod.dialogHead}
          >
            Czy napewno chcesz anulować{" "}
            <IconButton
              aria-label="close"
              className={stylesMod.closeButton}
              onClick={() => {
                setOpenModalDelete(false);
              }}
            >
              <X />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Czy napewno chcesz wyczyścić wszystkie pola?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={BTNstyles.btn}
              onClick={(e) => {
                setOpenModalDelete(false);
              }}
            >
              Anuluj
            </Button>
            <Button
              variant="contained"
              size="large"
              className={`${BTNstyles.btn} ${BTNstyles.err}`}
              onClick={(e) => {
                clearData();
              }}
              color="primary"
            >
              Usuń <X className={BTNstyles.downBtnIco} />
            </Button>
          </DialogActions>
        </Dialog>

        <Button
          variant="contained"
          color="primary"
          size="large"
          className={`${BTNstyles.btn} ${BTNstyles.grn}`}
          onClick={() => {
            if (checkError()) {
              setError(false);
              !isCarrier ? fetchBoth() : fetchCarrier();
            } else {
              setError(true);
              setOpenDialog(true);
              setDialogContent("Uzupełnij wymagane pola");
              setDialogColor("error");
            }
          }}
        >
          {props.state.edit ? "Zapisz" : "Dodaj"}{" "}
          <Check className={BTNstyles.downBtnIco} />
        </Button>
      </div>
      <Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={dialogColor}>
          {dialogContent}
        </Alert>
      </Snackbar>
    </>
  );
}
