export const generateTraces = () => {
  const randomId = Math.floor(Math.random() * 1000);

  return [
    {
      id: "trace" + randomId,
      reference_no: "ref_1",
      number: randomId,
      type: "loading",
      date: null,
      time: null,
      manual_adress: false,
      comment: null,
      isBlocked: false,
      border_crossing: {},
      address: undefined,
      access_via_transport_docs: false,
      cargo: [
        {
          id: 1,
          name: null,
          quantity: null,
          unit: {},
          palletExchange: false,
          ldmSize: null,
          details: null,
          ADR: false,
          ADRClass: null,
          NumRef: null,
        },
      ],
      warehouse: undefined,
    },
    {
      id: "trace" + (randomId + 1),
      reference_no: "ref_1",
      number: randomId + 1,
      type: "unloading",
      date: null,
      time: null,
      manual_adress: false,
      comment: null,
      isBlocked: false,
      border_crossing: {},
      address: undefined,
      access_via_transport_docs: false,
      cargo: [
        {
          id: 1,
          name: null,
          quantity: null,
          unit: {},
          palletExchange: false,
          ldmSize: null,
          details: null,
          ADR: false,
          ADRClass: null,
          NumRef: null,
        },
      ],
      warehouse: undefined,
    },
  ];
};

export const newOrderDefaultValues = {
  order_no: "",
  show_order_no_refresh_button: false,
  customer_order_number: "",
  order_title: "",
  order_start: {},
  order_stop: {},
  order_text: "",
  tsId: null,
  contractor: { label: "" },
  price: null,
  currency: { label: "PLN", value: "PLN" },
  checkedGoods: false,
  kilometers: {
    access: 1,
    cargo: 0,
    sum: 0,
  },
  isEdit: false,
  isCopy: false,
  isLoading: false,
  isFetching: false,
  ratesPerKm: { rate_netto: 0 },
  cost: 0,
  selected_tags: [],
  cargo: [
    {
      name: null,
      maxQuantity: null,
      unit: null,
      price: null,
      currency: { label: "PLN", value: "PLN" },
      statusLoad: false,
      statusUnload: false,
      details: null,
      NumRef: "ref_1",
    },
  ],
  is_required_weight: false,
  transport: {
    set: null,
    car: { label: "", value: "" },
    trailer: { label: "", value: "" },
    driver: null,
    driverTwo: null,
    isDedicated: false,
    dedicated: null,
    vehicle: null,
    semiTrailer: null,
    advance: [{ value: 0, currency: { label: "PLN", value: "PLN" } }],
    files: [],
    error: false,
  },
  trace: generateTraces(),
  route_id: null,
  newRoute: false,
  favoriteRoute: null,
  newFavoriteRoute: null,
  disable_cargo_quantity_check: false,
  driverNote: undefined,
};

export const currencyOption = [
  { label: "PLN", value: "PLN" },
  { label: "EURO", value: "EUR" },
  { label: "CHF", value: "CHF" },
  { label: "FUNT", value: "GBP" },
  { label: "KORONA", value: "CZK" },
  { label: "RUBEL", value: "RUB" },
  { label: "HRYWNA", value: "UAH" },
];
