import React, { useState, useEffect, forwardRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import stylesMod from "../../styles/newOrEdit.module.css";
import styles from "../table.module.css";
import Button from "@material-ui/core/Button";
import NewOrder from "./newOrder";
import { X, Calendar } from "react-feather";
import IconButton from "@material-ui/core/IconButton";
import Select from "react-select";
import { DBurl } from "../../appConfig";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { TableHead } from "@material-ui/core";
import moment from "moment";
import {
  toggleShowAllTags,
  getAllTags,
  getBarColor,
} from "../helpers/table.helpers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import plLocale from "date-fns/locale/pl";
import vacationStyles from "../../styles/vacation.module.css";
import BTNstyles from "../../styles/button.module.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VacationDialog = (props) => {
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState({
    label: "",
    value: "",
  });
  const [driverDays, setDriverDays] = useState(undefined);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [orders, setOrders] = useState();
  const [driverDates, setDriverDates] = useState([]);
  const [error, setError] = useState();
  const [datesWithIds, setDatesWithIds] = useState();
  const [conflictOrders, setConflictOrders] = useState();
  const [conflictOrdersNames, setConflictOrdersNames] = useState(undefined);

  const select_styles = {
    control: (base, state) => ({
      ...base,
      border: "1px solid #142f42",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #142f42",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused && "#142f42",
      color: state.isFocused && "#fff",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 99999 }),
  };

  const CustomInput = forwardRef((props, ref) => {
    return (
      <div className={`${"datapicker_new"} ${styles.dataPicker}`}>
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <Calendar onClick={props.onClick} />
      </div>
    );
  });

  const getDrivers = async function () {
    props.props.loading(true);
    const response = await fetch(`${DBurl}/getDrivers/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      let tempData = json.data.map((e) => {
        return {
          ...e,
          label: `${e.firstname} ${e.surname}`,
          value: e.id,
        };
      });
      setDrivers(tempData);
      props.props.loading(false);
      return json;
    }
  };

  const getOrders = async function () {
    const response = await fetch(`${DBurl}/getOrders/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      const tempData = [...json.data];
      setOrders(tempData);
      return json;
    }
  };

  const setDriverHolidays = async function () {
    props.props.loading(true);
    const response = await fetch(`${DBurl}/setDriversHolidays`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "insert",
        data: {
          id_driver: selectedDriver.id,
          ts_start: startDate,
          ts_end: endDate,
        },
      }),
    });
    const json = await response.json();
    if (json.success) {
      props.setSnack(true);
      props.setSnackContent("Urlop zapisany pomyślnie.");
      props.setSnackColor("success");
      props.setOpen(false);
      props.setReload(true);
      props.props.loading(false);
    }
  };

  const getOrdersForSelectedDriver = (id) => {
    return orders.filter(
      (order) =>
        order.transport_set.id_driver1 === id ||
        order.transport_set.id_driver2 === id
    );
  };

  useEffect(() => {
    setDrivers(props.drivers);
    // getDrivers()
    getOrders();
  }, []);

  const reduceWorkset = (workSet) => {
    return workSet
      .map((set) => set.locations)
      .map((element) =>
        element.reduce((total, { ts }, index, arr) => {
          if (index === 0 || index === arr.length - 1) {
            return [...total, ts];
          }
          return [...total];
        }, [])
      );
  };

  const reduceWorksetMapIds = (workSet) => {
    const locationsAndId = workSet.map((set) => {
      return { locations: set.locations, id: set.id };
    });
    const allDatesAndId = locationsAndId.map((element) => {
      const id = element.id;
      const dates = element.locations.reduce((total, { ts }, index, arr) => {
        if (index === 0 || index === arr.length - 1) {
          return [...total, ts];
        }
        return [...total];
      }, []);
      return { id, dates };
    });
    return allDatesAndId;
  };

  const dateAppender = (sDate, eDate) => {
    let allDatesSet = [];
    let newStartDate = new Date(sDate);
    while (newStartDate <= eDate) {
      allDatesSet = [...allDatesSet, new Date(newStartDate)];
      newStartDate.setDate(newStartDate.getDate() + 1);
    }
    allDatesSet.push(eDate);
    return allDatesSet;
  };

  const appendDatesBetweenTwo = (dateSets) => {
    let allDates = [];
    for (const dateSet of dateSets) {
      const startDate = new Date(dateSet[0]);
      const endDate = new Date(dateSet[1]);
      allDates = [...allDates, ...dateAppender(startDate, endDate)];
    }
    return allDates.sort((a, b) => a - b);
  };

  const appendDatesBetweenTwoWithId = (dateSet) => {
    let allDates = [];
    const startDate = new Date(dateSet[0]);
    const endDate = new Date(dateSet[1]);
    allDates = [...allDates, ...dateAppender(startDate, endDate)];
    return allDates.sort((a, b) => a - b);
  };

  const sanitizeDateset = (dateSet) => {
    return [
      ...new Set(
        dateSet.map(
          (date) =>
            `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
        )
      ),
    ];
  };

  const handleDriversDates = (workSet) => {
    const dateSets = reduceWorkset(workSet);
    const dateSetsWithMappedIds = reduceWorksetMapIds(workSet).map(
      (element) => {
        return {
          id: element.id,
          dates: appendDatesBetweenTwoWithId(element.dates),
        };
      }
    );
    const allDates = appendDatesBetweenTwo(dateSets);
    const sanitizedWithId = dateSetsWithMappedIds.map((ele) => {
      return { id: ele.id, dates: sanitizeDateset(ele.dates) };
    });
    setDatesWithIds(sanitizedWithId);
    return sanitizeDateset(allDates);
  };

  const handleChangeDates = (sDate, eDate) => {
    if (!sDate || !eDate) return;
    const daysBetween = sanitizeDateset(dateAppender(sDate, eDate));
    const isConflict = daysBetween.some((element) =>
      driverDates.includes(element)
    );
    // if (isConflict) {
    const conflictId = datesWithIds.filter((ele) =>
      ele.dates.some((e) => daysBetween.includes(e))
    );
    const conflictOrder = conflictId.map((confl) =>
      orders.filter((order) => order.id === confl.id)
    );
    setConflictOrders(conflictOrder);
    // }
    setError(isConflict);
  };

  useEffect(() => {
    if (error) {
      const orderNos = [];
      conflictOrders.forEach((conflict) =>
        conflict.forEach((c) => orderNos.push(c.order_no))
      );
      setConflictOrdersNames(orderNos);
    }
  }, [error]);
  useEffect(() => {
    handleChangeDates(startDate, endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    setStartDate();
    setEndDate();
  }, [selectedDriver.id]);

  return (
    <Dialog
      maxWidth="sm"
      open={props.open}
      TransitionComponent={Transition}
      fullWidth
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        className={stylesMod.dialogHead}
      >
        Zaplanuj urlop
        <IconButton
          aria-label="close"
          className={stylesMod.closeButton}
          onClick={() => {
            props.setOpen(false);
          }}
        >
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent id="alert-dialog-slide-description">
        <Grid container>
          <Grid item xs={12}>
            <div className={`${styles.inp} multi_selector`}>
              <h1 htmlFor={"doc_type"}>Wybierz kierowcę</h1>
              <Select
                value={selectedDriver}
                options={drivers}
                onChange={(e) => {
                  const allDates = handleDriversDates(
                    getOrdersForSelectedDriver(e.id)
                  );
                  setDriverDates(allDates);
                  setSelectedDriver(e);
                }}
                name="doc_type"
                placeholder="Wybierz..."
                getOptionValue={(option) => `${option.label}`}
                styles={select_styles}
                menuPortalTarget={document.body}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container xs={12} className={vacationStyles.datePickers}>
          <Grid item xs={4}>
            <div className={styles.datePicker}>
              <label htmlFor={"issueDate"}>Data rozpoczęcia</label>
              <div className={styles.date}>
                <DatePicker
                  dateFormat="dd.MM.yyyy"
                  minDate={new Date()}
                  readOnly={!selectedDriver.id}
                  selected={startDate}
                  onChange={(e) => {
                    let newD = moment(e).valueOf();
                    let newDS = moment(newD).format("yyyy-MM-DD");
                    newDS = new Date(e >= new Date() ? e : new Date());
                    if (endDate && newDS > endDate) {
                      setEndDate(newDS);
                    }
                    setStartDate(newDS);
                  }}
                  locale={plLocale}
                  name="startDate"
                  customInput={<CustomInput error={false} />}
                  placeholderText={"dd.mm.rrrr"}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <div className={styles.datePicker}>
              <label htmlFor={"issueDate"}>Data zakończenia</label>
              <div className={styles.date}>
                <DatePicker
                  dateFormat="dd.MM.yyyy"
                  readOnly={!startDate}
                  minDate={startDate}
                  selected={endDate}
                  onChange={(e) => {
                    let newD = moment(e).valueOf();
                    let newDS = moment(newD).format("yyyy-MM-DD");
                    newDS = new Date(e >= startDate ? e : startDate);
                    setEndDate(newDS);
                    // const x = handleChangeDates(startDate, newDS)
                  }}
                  locale={plLocale}
                  name="endDate"
                  customInput={<CustomInput error={false} />}
                  placeholderText={"dd.mm.rrrr"}
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} className={vacationStyles.high}>
          <Grid item xs={12}>
            {error && conflictOrdersNames && (
              <div className={vacationStyles.error}>
                {`We wskazanym terminie wybrany kierowca posiada zaplanowane zlecenie ${conflictOrdersNames[0]}. Edytuj obsadę tego zlecenia aby kontynuować.`}
              </div>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="large"
          color="primary"
          className={BTNstyles.btn}
          onClick={(e) => {
            props.setOpen(false);
            // setName('')
          }}
        >
          Anuluj
        </Button>
        <Button
          variant="contained"
          size="large"
          className={`${BTNstyles.btn} ${BTNstyles.grn}`}
          onClick={() => {
            if (!error) {
              setDriverHolidays();
            }
          }}
          color="primary"
        >
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VacationDialog;
