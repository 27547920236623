import React, { useState, useEffect, useRef } from "react";
import Title from "../../components/title";
import { DBurl } from "../../appConfig";
import { X, Check, UploadCloud } from "react-feather";
import styles from "../table.module.css";
import stylesMod from "../../styles/newOrEdit.module.css";
import BTNstyles from "../../styles/button.module.css";
import RowsPerPage from "../../components/RowsPerPage";
import Pagination from "../../components/pagination";
import Button from "@material-ui/core/Button";
import { ExportBtn } from "../../components/buttons";
import Grid from "@material-ui/core/Grid";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
  Slide,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import PurchaseAdd from "./PurchaseAdd";
import Input from "../../components/input";
import IcoBtn from "../../components/buttons/icoBtn";
import Select from "react-select";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "car", right: true, disablePadding: false, label: "Pojazd" },
  { id: "name", right: true, disablePadding: false, label: "Nazwa" },
  { id: "type", right: true, disablePadding: false, label: "Typ" },
  { id: "cost", right: true, disablePadding: false, label: "Kwota" },
  { id: "description", right: true, disablePadding: false, label: "Opis" },
  {
    id: "department",
    right: true,
    disablePadding: false,
    label: "Departament",
  },
];

function EnhancedTableHead(props) {
  const {
    styles,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={styles.tabHead}>
      <TableRow>
        <TableCell className={styles.checkbox}>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all id" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.right ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ width: `${headCell.width || "auto"}` }}
          >
            {!headCell.noSort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={styles.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

function useInput({ type, size }) {
  const [value, setValue] = useState("");
  const input = (
    <TableCell className={styles.inputCell}>
      <Input
        value={value}
        handleChange={(e) => {
          setValue(e.target.value);
        }}
        type={type}
        search={true}
        className={size}
      />
    </TableCell>
  );
  return [value, input];
}

export default function PurchaseList(props) {
  const [table, setTable] = useState([]);
  const [ordersData, setOrdersData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("short");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [pageModalTab, setPageModalTab] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [rowsPerPageModalTab, setRowsPerPageModalTab] = React.useState(10);
  const [searchCar, setSearchCar] = useInput({ type: "text" });
  const [searchName, setSearchName] = useInput({ type: "text" });
  const [searchType, setSearchType] = useInput({ type: "text" });
  const [searchDescription, setSearchDescription] = useInput({ type: "text" });
  const [searchDepartment, setSearchDepartment] = useInput({ type: "text" });
  const [carsOptions, setCarsOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [deleteData, setDeleteData] = React.useState([]);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [openModalImport, setOpenModalImport] = React.useState(false);
  const [openPurchaseAddModal, setOpenPurchaseAddModal] = useState(false);
  const [dataPurchaseAddModal, setDataPurchaseAddModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");
  const [files, setFiles] = useState([]);
  const [btnSend, setBtnSend] = useState(false);
  const [importColCar, setImportColCar] = useState();
  const [importColName, setImportColName] = useState();
  const [importColType, setImportColType] = useState();
  const [importColCurrent, setImportColCurrent] = useState();
  const [importColPrice, setImportColPrice] = useState();
  const [importColDiscription, setImportColDiscription] = useState();
  const [importColCarData, setImportColCarData] = useState();
  const [importColNameData, setImportColNameData] = useState();
  const [importColTypeData, setImportColTypeData] = useState();
  const [importColCurrentData, setImportColCurrentData] = useState();
  const [importColPriceData, setImportColPriceData] = useState();
  const [importColDiscriptionData, setImportColDiscriptionData] = useState();
  const [importColDepartmentsData, setImportColDepartmentsData] = useState();
  const [tableData, setTableData] = useState([]);
  const [importColDepartment, setImportColDepartment] = useState();
  const [parsedDataData, setParsedDataData] = useState();
  const [parsedDataHeaders, setParsedDataHeaders] = useState();
  const [error, setError] = useState(false);
  const [allSelectDepartments, setAllSelectDepartments] = useState(false);
  const [isSend, setIsSend] = useState(false);

  const [importedParsedData, setImportedParsedData] = useState();
  const [ifParsed, setIfParsed] = useState(false);
  const [carList, setCarList] = useState();
  const [carListWithID, setCarListWithID] = useState();

  const fileInput = useRef(null);

  function handleClear() {
    fileInput.current.value = "";
  }

  const modalTabStyles = {
    border: "1px solid #aaaaaa",
    borderRadius: "4px",
    margin: "10px 4px 0 4px",
  };

  const cleanStates = () => {
    setAllSelectDepartments(false);
    setError(false);
    setCarList();
    setCarListWithID();
    setIfParsed(false);
    setParsedDataHeaders();
    setParsedDataData();
    setImportColCar();
    setImportColName();
    setImportColType();
    setImportColCurrent();
    setImportColPrice();
    setImportColDiscription();
    setImportColCarData();
    setImportColNameData();
    setImportColTypeData();
    setImportColCurrentData();
    setImportColPriceData();
    setImportColDiscriptionData();
    setImportColDepartmentsData();
    setDepartmentOptions();
    setImportColDepartment();
    setFiles([]);
    setBtnSend(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDialog(false);
  };

  const getCostsOthers = async function () {
    props.loading(true);
    const response = await fetch(`${DBurl}/getCostsOthers/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setTable(json.data);
      props.loading(false);
    }
  };

  const getCar = async function () {
    props.loading(true);
    const response = await fetch(`${DBurl}/getCars/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setCarsOptions(
        json.data.map((e) => {
          return { ...e, label: e.name, value: e.id };
        })
      );
      props.loading(false);
    }
  };

  const getDepartments = async function () {
    props.loading(true);
    const response = await fetch(`${DBurl}/getCustomersDepartments/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      // const tempOptions = json.data.map(e => { return { value: e.id, label: e.shortname } })
      // setDepartmentOptions([{ label: client.label, value: false }, ...tempOptions])
      props.loading(false);
      setDepartmentOptions(
        json.data.map((e) => {
          return {
            label: e.shortname,
            value: e.id,
            ...e,
          };
        })
      );

      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  const exportToXLS = (id) => {
    const url = `${DBurl}/printCostsOthersXLS?token=${props.user.csrf_token}&ids=${id}`;
    window.open(url, "_blank");
  };

  const delCosts = async function (arrayOfIds) {
    props.loading(true);
    const response = await fetch(`${DBurl}/setCostOther/`, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "delete",
        data: {
          ids: arrayOfIds,
        },
      }),
    });
    const json = await response.json();

    if (json.success) {
      setOpenDialog(true);
      setDialogContent(`Dane zostały usunięte `);
      setDialogColor("success");
      getCostsOthers();
      props.loading(false);
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const search = (tbl) => {
    // return tbl
    return tbl?.filter((row) => {
      return row.car?.name
        ? row.car?.name?.toString().toLowerCase().indexOf(searchCar) > -1
        : (searchCar ? false : true) &&
            row.type?.toString().toLowerCase().indexOf(searchType) > -1 &&
            (row.description
              ? row.description
                  ?.toString()
                  .toLowerCase()
                  .indexOf(searchDescription.toLowerCase()) > -1
              : searchDescription
              ? false
              : true) &&
            row.name
              ?.toString()
              .toLowerCase()
              .indexOf(searchName.toLowerCase()) > -1 &&
            (row.customer_department?.shortname
              ? row.customer_department?.shortname
                  ?.toString()
                  .toLowerCase()
                  .indexOf(searchDepartment.toLowerCase()) > -1
              : searchDepartment
              ? false
              : true);
    });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = table.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const paginate = (pageNumber) => setPage(pageNumber - 1);
  const paginateModalTab = (pageNumber) => setPageModalTab(pageNumber - 1);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, table.length - page * rowsPerPage);

  const importPurchaseFile = () => {
    setOpenModalImport(true);
  };

  const fetchFile = async (file) => {
    props.loading(true);
    setIsSend(true);
    try {
      const formData = new FormData();
      await formData.append("file", await file[0][0], file[0][0].name);
      const response = await fetch(`${DBurl}/uploadFile`, {
        method: "POST",
        credentials: "include",
        headers: {
          "X-CSRF-Token": props.user.csrf_token,
        },
        body: formData,
      });
      const json = await response.json();
      if (json.success) {
        props.loading(false);
        setIsSend(false);

        setOpenDialog(true);
        setDialogContent(`Plik wysłany prawidłowo.`);
        setDialogColor("success");

        fetchGetDataFromFile(json.data?.filename);
        setIfParsed(true);
        return await {
          name: json.data?.filename,
          msg: file.name,
        };
      }
    } catch (err) {
      props.loading(false);
      setIsSend(false);

      setOpenDialog(true);
      setDialogContent(`Błąd przesyłania pliku.`);
      setDialogColor("error");
      // console.log(err)
      return {};
    }
  };

  const fetchGetDataFromFile = async (filename) => {
    props.loading(true);
    const response = await fetch(
      `${DBurl}/getDataFromFile?filename=${filename}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setOpenDialog(true);
      setDialogContent(`Plik sparsowany poprawnie.`);
      setDialogColor("success");

      getDepartments();
      setImportedParsedData(json.data[0]);
      setParsedDataHeaders(() => {
        return json.data[0][0].map((o, i) => {
          if (o && o.length > 0) {
            return { value: i, label: o.charAt(0).toUpperCase() + o.slice(1) };
          } else {
            return {};
          }
        });
      });

      let dates = json.data[0].slice(1);
      setParsedDataData(dates);

      props.loading(false);
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  const fetchSetCosts = async (costs) => {
    props.loading(true);
    const response = await fetch(`${DBurl}/setCosts`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "insert",
        data: {
          costs: costs,
        },
      }),
    });
    const json = await response.json();
    if (json.success) {
      setOpenDialog(true);
      setDialogContent(
        `Zaimportowano ${json.data.imported_items} pozycji. ${
          json.data.duplicate_items > 0
            ? `Pominięto ${json.data.duplicate_items} zdublowanych pozycji`
            : ""
        }`
      );
      setDialogColor("success");

      getCostsOthers();
      setOpenModalImport(false);
      cleanStates();

      props.loading(false);
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  const colDataIndex = (selected) => {
    let obj = parsedDataHeaders.find((x) => x.label === selected?.label);
    let index = parsedDataHeaders.indexOf(obj);
    return index;
  };

  const getCarIds = async function (carList) {
    props.loading(true);
    const response = await fetch(`${DBurl}/getCarIds?cars=${carList}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setCarListWithID(json.data);
      setImportColCarData(json.data);
      props.loading(false);
    } else {
      props.loading(false);
    }
  };

  const handleFileSendApi = () => {
    files.length > 0 && fetchFile(files);
  };

  useEffect(() => {
    getCostsOthers();
    getDepartments();
    getCar();
  }, []);

  let tempCarColData = [];
  let tempNameColData = [];
  let tempTypeColData = [];
  let tempCurrentColData = [];
  let tempPriceColData = [];
  let tempDiscriptionDataColData = [];
  let tempDepartmentsDataColData = [];

  useEffect(() => {
    if (parsedDataHeaders) {
      if (importColCar) {
        for (let i = 0; parsedDataData.length > i; i++) {
          tempCarColData.push({
            value: i,
            car: parsedDataData[i][colDataIndex(importColCar)],
          });
        }

        let tempCarList = tempCarColData.map((o) => {
          return o.car;
        });
        let tempCarListString = String(tempCarList);

        getCarIds(tempCarListString);
      }

      tempCarColData.length && setImportColCarData(tempCarColData);
    }
  }, [importColCar]);

  useEffect(() => {
    if (parsedDataHeaders) {
      if (importColName) {
        for (let i = 0; parsedDataData.length > i; i++) {
          tempNameColData.push({
            value: i,
            name: parsedDataData[i][colDataIndex(importColName)],
          });
        }
      }
      tempNameColData.length && setImportColNameData(tempNameColData);
    }
  }, [importColName]);

  useEffect(() => {
    if (parsedDataHeaders) {
      if (importColType) {
        for (let i = 0; parsedDataData.length > i; i++) {
          tempTypeColData.push({
            value: i,
            type: parsedDataData[i][colDataIndex(importColType)],
          });
        }
      }

      tempTypeColData.length && setImportColTypeData(tempTypeColData);
    }
  }, [importColType]);

  useEffect(() => {
    if (parsedDataHeaders) {
      if (importColCurrent) {
        for (let i = 0; parsedDataData.length > i; i++) {
          tempCurrentColData.push({
            value: i,
            current: parsedDataData[i][colDataIndex(importColCurrent)],
          });
        }
      }

      tempCurrentColData.length && setImportColCurrentData(tempCurrentColData);
    }
  }, [importColCurrent]);

  useEffect(() => {
    if (parsedDataHeaders) {
      if (importColPrice) {
        for (let i = 0; parsedDataData.length > i; i++) {
          tempPriceColData.push({
            value: i,
            price: parsedDataData[i][colDataIndex(importColPrice)],
          });
        }
      }

      tempPriceColData.length && setImportColPriceData(tempPriceColData);
    }
  }, [importColPrice]);

  useEffect(() => {
    if (parsedDataHeaders) {
      if (importColDiscription) {
        for (let i = 0; parsedDataData.length > i; i++) {
          tempDiscriptionDataColData.push({
            value: i,
            discription: parsedDataData[i][colDataIndex(importColDiscription)],
          });
        }
      }

      tempDiscriptionDataColData.length &&
        setImportColDiscriptionData(tempDiscriptionDataColData);
    }
  }, [importColDiscription]);

  useEffect(() => {
    if (parsedDataHeaders) {
      if (importColDepartment) {
        for (let i = 0; parsedDataData.length > i; i++) {
          // console.log('importColDepartment', i, importColDepartment)
          tempDepartmentsDataColData.push({
            value: i,
            department: importColDepartment.label,
          });
        }
      }

      tempDepartmentsDataColData.length &&
        setImportColDepartmentsData(tempDepartmentsDataColData);
    }
  }, [importColDepartment]);

  useEffect(() => {
    // console.log(">>>>>>")
    if (tableData && tableData.length) {
      for (let i = 0; tableData.length > i; i++) {
        if (tableData[i].departament === "") {
          setAllSelectDepartments(true);
          break;
        } else {
          setAllSelectDepartments(false);
        }
      }
    }
  }, [tableData]);

  useEffect(() => {
    if (parsedDataData) {
      let tempRow = [];
      for (let i = 0; parsedDataData.length > i; i++) {
        tempRow.push({
          id: i,
          car:
            importColCarData?.length > 0
              ? {
                  car_name: importColCarData[i].car,
                  id: importColCarData[i].id,
                }
              : "",
          name: importColNameData?.length > 0 ? importColNameData[i].name : "",
          type: importColTypeData?.length > 0 ? importColTypeData[i].type : "",
          current:
            importColCurrentData?.length > 0
              ? importColCurrentData[i].current
              : "",
          price:
            importColPriceData?.length > 0 ? importColPriceData[i].price : "",
          discription:
            importColDiscriptionData?.length > 0
              ? importColDiscriptionData[i].discription
              : "",
          departament:
            importColDepartmentsData?.length > 0
              ? importColDepartmentsData[i].department
              : "",
        });
      }

      setTableData(tempRow);
    }
  }, [parsedDataData]);

  useEffect(() => {
    const updatedItems = [...tableData];
    if (parsedDataData) {
      for (let i = 0; parsedDataData.length > i; i++) {
        updatedItems[i] = {
          ...updatedItems[i],
          type: importColTypeData[i].type,
        };
      }
    }
    setTableData(updatedItems);
  }, [importColTypeData]);

  useEffect(() => {
    const updatedItems = [...tableData];
    if (parsedDataData) {
      for (let i = 0; parsedDataData.length > i; i++) {
        updatedItems[i] = {
          ...updatedItems[i],
          price: importColPriceData[i].price,
        };
      }
    }
    setTableData(updatedItems);
  }, [importColPriceData]);

  useEffect(() => {
    const updatedItems = [...tableData];
    if (parsedDataData) {
      for (let i = 0; parsedDataData.length > i; i++) {
        updatedItems[i] = {
          ...updatedItems[i],
          name: importColNameData[i].name,
        };
      }
    }
    setTableData(updatedItems);
  }, [importColNameData]);

  useEffect(() => {
    const updatedItems = [...tableData];
    if (parsedDataData) {
      for (let i = 0; parsedDataData.length > i; i++) {
        updatedItems[i] = {
          ...updatedItems[i],
          discription: importColDiscriptionData[i].discription,
        };
      }
    }
    setTableData(updatedItems);
  }, [importColDiscriptionData]);

  useEffect(() => {
    const updatedItems = [...tableData];
    if (parsedDataData) {
      for (let i = 0; parsedDataData.length > i; i++) {
        updatedItems[i] = {
          ...updatedItems[i],
          current: importColCurrentData[i].current || "PLN",
        };
      }
    }
    setTableData(updatedItems);
  }, [importColCurrentData]);

  useEffect(() => {
    const updatedItems = [...tableData];
    if (parsedDataData) {
      for (let i = 0; parsedDataData.length > i; i++) {
        updatedItems[i] = {
          ...updatedItems[i],
          car: {
            car_name: importColCarData[i].car,
            id: importColCarData[i].id,
          },
        };
      }
    }
    setTableData(updatedItems);
  }, [importColCarData]);

  useEffect(() => {
    const updatedItems = [...tableData];
    if (parsedDataData) {
      for (let i = 0; parsedDataData.length > i; i++) {
        updatedItems[i] = {
          ...updatedItems[i],
          departament: importColDepartmentsData[i].department,
        };
      }
    }
    setTableData(updatedItems);
  }, [importColDepartmentsData]);

  return (
    <>
      <Paper className={styles.paper}>
        <Title
          title={props.state.tabName}
          btnBox
          btnAdd
          btnExport
          btnImport
          importFunc={(e) => {
            importPurchaseFile();
          }}
          exportFunc={(e) => {
            exportToXLS(selected);
          }}
          addFunc={(e) => {
            setDataPurchaseAddModal(false);
            setEdit(false);
            setOpenPurchaseAddModal(true);
          }}
          btnDelete={selected.length > 0 ? true : "disabled"}
          deleteFunc={(e) => setOpenModalDelete(true)}
        />
      </Paper>
      <TableContainer className={styles.stickyTable}>
        <Table
          stickyHeader={true}
          className={styles.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
          size={"small"}
        >
          <EnhancedTableHead
            styles={styles}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={table.length}
          />
          <TableBody className={styles.tabBody}>
            <TableRow>
              <TableCell></TableCell>
              {setSearchCar}
              {setSearchName}
              {setSearchType}
              <TableCell></TableCell>
              {setSearchDescription}
              {setSearchDepartment}
              <TableCell></TableCell>
            </TableRow>

            {stableSort(search(table), getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    className={row.reservation ? styles.reservation : ""}
                  >
                    <TableCell className={styles.checkbox}>
                      <Checkbox
                        checked={isItemSelected}
                        color="primary"
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </TableCell>

                    <TableCell
                      component="th"
                      id={labelId}
                      align="right"
                      scope="row"
                    >
                      {row.car?.name}
                    </TableCell>
                    <TableCell align="right">{row.name}</TableCell>
                    <TableCell align="right">{row.type} </TableCell>
                    <TableCell align="right">{`${row.cost} ${row.currency}`}</TableCell>
                    <TableCell align="right">{row.description}</TableCell>
                    <TableCell align="right">
                      {row.customer_department?.shortname}
                    </TableCell>
                    <TableCell align="right">
                      <div className={styles.buttonBox}>
                        <ExportBtn
                          tooltip="Eksport do XLS"
                          handleChange={(e) => {
                            exportToXLS(row.id);
                          }}
                        />

                        <IcoBtn
                          icon="Edit"
                          tooltip="edytuj stawkę"
                          value={row}
                          onClick={(e) => {
                            setDataPurchaseAddModal(row);
                            setEdit(true);
                            setOpenPurchaseAddModal(true);
                          }}
                        />
                        <IcoBtn
                          icon="X"
                          tooltip="Usuń"
                          className="btn-delete"
                          value={row}
                          onClick={(e) => {
                            setDeleteData([row.id]);
                            setOpenModalDelete(true);
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 33 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <div className={styles.tableBottom}>
        <RowsPerPage
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          tableLength={table.length}
        />
        <div>
          <Pagination
            postsPerPage={rowsPerPage}
            totalPosts={table.length}
            paginate={paginate}
            page={page}
          />
        </div>
      </div>

      <Dialog
        open={openModalImport}
        TransitionComponent={Transition}
        onClose={() => {
          setOpenModalImport(false);
          cleanStates();
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth={"lg"}
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Importuj plik zakupów
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              setOpenModalImport(false);
              cleanStates();
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={
            parsedDataData ? { minHeight: "300px" } : { minHeight: "auto" }
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid className={"col2"} container spacing={2}>
                <Grid item xs={btnSend && !ifParsed ? 11 : 12}>
                  <input
                    accept=".xlsx, .xls, .csv"
                    style={{ display: "none" }}
                    id="raised-button-file"
                    // multiple
                    type="file"
                    ref={fileInput}
                    onChange={(e) => {
                      setFiles([...files, e.target.files]);
                      setBtnSend(true);
                    }}
                  />
                  <label htmlFor="raised-button-file">
                    <Button
                      variant="outlined"
                      color="primary"
                      component="div"
                      className="fileBtn"
                    >
                      {files.length > 0
                        ? files[0][0]?.name
                        : "Wybierz pliki z komputera (.xlsx, .xls, .csv)..."}

                      <Button
                        onClick={() => {
                          setBtnSend(false);
                          cleanStates();
                          handleClear();
                        }}
                        style={
                          files.length > 0
                            ? { display: "flex" }
                            : { display: "none" }
                        }
                      >
                        <X />
                      </Button>
                    </Button>
                  </label>
                </Grid>
                {btnSend && !ifParsed ? (
                  <Grid item xs={1}>
                    <Button
                      onClick={handleFileSendApi}
                      color="secondary"
                      className={`${BTNstyles.btn} ${BTNstyles.grn}`}
                      style={
                        btnSend === true && !ifParsed
                          ? {
                              display: "inline-flex",
                              margin: 0,
                              width: "100%",
                              maxHeight: "36px",
                            }
                          : {
                              display: "none",
                              margin: 0,
                              width: "100%",
                              maxHeight: "36px",
                            }
                      }
                    >
                      Wyślij plik
                      <UploadCloud
                        style={{ marginLeft: "10px", width: "18px" }}
                      />
                    </Button>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            {parsedDataData ? (
              <Grid className={"col7"} item xs={12}>
                <Grid container spacing={1}>
                  <Grid style={modalTabStyles} item component={"div"} xs={12}>
                    <TableContainer>
                      <Table
                        stickyHeader={true}
                        className={styles.table}
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                        size={"small"}
                      >
                        <TableHead className={styles.tabHead}>
                          <TableRow>
                            <TableCell className={"tabcell"}>
                              Pojazd
                              <Select
                                menuPortalTarget={document.body}
                                value={
                                  parsedDataHeaders &&
                                  parsedDataHeaders.find(
                                    ({ value }) =>
                                      value === String(importColCar)
                                  )
                                }
                                className={`${stylesMod.select} ${
                                  error && !importColCar && stylesMod.error
                                }`}
                                options={parsedDataHeaders}
                                onChange={(e) => {
                                  setImportColCar(e);
                                }}
                                name="Car"
                                placeholder="Wybierz..."
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                  control: (base, state) => ({
                                    ...base,
                                    border: "1px solid #142f42",
                                    marginTop: "6px",
                                    boxShadow: "none",
                                    "&:hover": {
                                      border: "1px solid #142f42",
                                    },
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor:
                                      state.isFocused && "#142f42",
                                    color: state.isFocused && "#fff",
                                    fontSize: "11px",
                                  }),
                                }}
                              />
                            </TableCell>
                            <TableCell className={"tabcell"}>
                              Nazwa
                              <Select
                                menuPortalTarget={document.body}
                                value={
                                  parsedDataHeaders &&
                                  parsedDataHeaders.find(
                                    ({ value }) =>
                                      value === String(importColName)
                                  )
                                }
                                className={`${stylesMod.select} ${
                                  error && !importColName && stylesMod.error
                                }`}
                                options={parsedDataHeaders}
                                onChange={(e) => {
                                  setImportColName(e);
                                }}
                                name="Name"
                                placeholder="Wybierz..."
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                  control: (base, state) => ({
                                    ...base,
                                    border: "1px solid #142f42",
                                    marginTop: "6px",
                                    boxShadow: "none",
                                    "&:hover": {
                                      border: "1px solid #142f42",
                                    },
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor:
                                      state.isFocused && "#142f42",
                                    color: state.isFocused && "#fff",
                                    fontSize: "11px",
                                  }),
                                }}
                              />
                            </TableCell>
                            <TableCell className={"tabcell"}>
                              Typ
                              <Select
                                menuPortalTarget={document.body}
                                value={
                                  parsedDataHeaders &&
                                  parsedDataHeaders.find(
                                    ({ value }) =>
                                      value === String(importColType)
                                  )
                                }
                                className={`${stylesMod.select} ${
                                  error && !importColType && stylesMod.error
                                }`}
                                options={parsedDataHeaders}
                                onChange={(e) => {
                                  setImportColType(e);
                                }}
                                name="Type"
                                placeholder="Wybierz..."
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                  control: (base, state) => ({
                                    ...base,
                                    border: "1px solid #142f42",
                                    marginTop: "6px",
                                    boxShadow: "none",
                                    "&:hover": {
                                      border: "1px solid #142f42",
                                    },
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor:
                                      state.isFocused && "#142f42",
                                    color: state.isFocused && "#fff",
                                    fontSize: "11px",
                                  }),
                                }}
                              />
                            </TableCell>
                            <TableCell className={"tabcell"}>
                              Waluta
                              <Select
                                menuPortalTarget={document.body}
                                value={
                                  parsedDataHeaders &&
                                  parsedDataHeaders.find(
                                    ({ value }) =>
                                      value === String(importColCurrent)
                                  )
                                }
                                className={`${stylesMod.select}`}
                                options={parsedDataHeaders}
                                onChange={(e) => {
                                  setImportColCurrent(e);
                                }}
                                name="Current"
                                placeholder="Wybierz..."
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                  control: (base, state) => ({
                                    ...base,
                                    border: "1px solid #142f42",
                                    marginTop: "6px",
                                    boxShadow: "none",
                                    "&:hover": {
                                      border: "1px solid #142f42",
                                    },
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor:
                                      state.isFocused && "#142f42",
                                    color: state.isFocused && "#fff",
                                    fontSize: "11px",
                                  }),
                                }}
                              />
                            </TableCell>
                            <TableCell className={"tabcell"}>
                              Kwota
                              <Select
                                menuPortalTarget={document.body}
                                value={
                                  parsedDataHeaders &&
                                  parsedDataHeaders.find(
                                    ({ value }) =>
                                      value === String(importColPrice)
                                  )
                                }
                                className={`${stylesMod.select} ${
                                  error && !importColPrice && stylesMod.error
                                }`}
                                options={parsedDataHeaders}
                                onChange={(e) => {
                                  setImportColPrice(e);
                                }}
                                name="Price"
                                placeholder="Wybierz..."
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                  control: (base, state) => ({
                                    ...base,
                                    border: "1px solid #142f42",
                                    marginTop: "6px",
                                    boxShadow: "none",
                                    "&:hover": {
                                      border: "1px solid #142f42",
                                    },
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor:
                                      state.isFocused && "#142f42",
                                    color: state.isFocused && "#fff",
                                    fontSize: "11px",
                                  }),
                                }}
                              />
                            </TableCell>
                            <TableCell className={"tabcell"}>
                              Opis
                              <Select
                                menuPortalTarget={document.body}
                                value={
                                  parsedDataHeaders &&
                                  parsedDataHeaders.find(
                                    ({ value }) =>
                                      value === String(importColDiscription)
                                  )
                                }
                                className={`${stylesMod.select}`}
                                options={parsedDataHeaders}
                                onChange={(e) => {
                                  setImportColDiscription(e);
                                }}
                                name="Discription"
                                placeholder="Wybierz..."
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                  control: (base, state) => ({
                                    ...base,
                                    border: "1px solid #142f42",
                                    marginTop: "6px",
                                    boxShadow: "none",
                                    "&:hover": {
                                      border: "1px solid #142f42",
                                    },
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor:
                                      state.isFocused && "#142f42",
                                    color: state.isFocused && "#fff",
                                    fontSize: "11px",
                                  }),
                                }}
                              />
                            </TableCell>
                            <TableCell className={"tabcell"}>
                              Departament
                              <Select
                                menuPortalTarget={document.body}
                                value={
                                  departmentOptions &&
                                  departmentOptions.find(
                                    ({ value }) =>
                                      value === String(importColDepartment)
                                  )
                                }
                                className={`${stylesMod.select} ${
                                  error &&
                                  !importColDepartment &&
                                  allSelectDepartments &&
                                  stylesMod.error
                                }`}
                                options={departmentOptions}
                                onChange={(e) => {
                                  setImportColDepartment(e);
                                }}
                                name="contractor"
                                // placeholder={!importColDepartment && allSelectDepartments ? "Wybierz..." : "Wybrane"}
                                placeholder={
                                  importColDepartment || !allSelectDepartments
                                    ? "Wybrane"
                                    : "Wybierz..."
                                }
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                  control: (base, state) => ({
                                    ...base,
                                    border: "1px solid #142f42",
                                    marginTop: "6px",
                                    boxShadow: "none",
                                    "&:hover": {
                                      border: "1px solid #142f42",
                                    },
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor:
                                      state.isFocused && "#142f42",
                                    color: state.isFocused && "#fff",
                                    fontSize: "11px",
                                  }),
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {stableSort(
                            search(tableData),
                            getComparator(order, orderBy)
                          )
                            .slice(
                              pageModalTab * rowsPerPageModalTab,
                              pageModalTab * rowsPerPageModalTab +
                                rowsPerPageModalTab
                            )
                            .map((row, index) => {
                              return (
                                <TableRow
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={index}
                                  className={
                                    row.car.id === null ? styles.err : ""
                                  }
                                >
                                  <TableCell className={"tabcell"}>
                                    {row.car.car_name || "-"}
                                  </TableCell>
                                  <TableCell className={"tabcell"}>
                                    {row.name || "-"}
                                  </TableCell>
                                  <TableCell className={"tabcell"}>
                                    {row.type || "-"}
                                  </TableCell>
                                  <TableCell className={"tabcell"}>
                                    {row.current || "-"}
                                  </TableCell>
                                  <TableCell className={"tabcell"}>
                                    {row.price || "-"}
                                  </TableCell>
                                  <TableCell className={"tabcell"}>
                                    {row.discription || "-"}
                                  </TableCell>
                                  <TableCell
                                    className={"tabcell"}
                                    style={{ padding: "0 8px" }}
                                  >
                                    {
                                      <Select
                                        menuPortalTarget={document.body}
                                        value={
                                          departmentOptions &&
                                          departmentOptions.find(
                                            ({ label }) => {
                                              return (
                                                label ===
                                                String(row.departament)
                                              );
                                            }
                                          )
                                        }
                                        className={`${stylesMod.select} selectInTableCell`}
                                        options={departmentOptions}
                                        onChange={(e) => {
                                          let value = e.label;
                                          const newItems = tableData.map(
                                            (item) => {
                                              if (item.id === index) {
                                                return {
                                                  ...item,
                                                  departament: value,
                                                };
                                              }
                                              return item;
                                            }
                                          );
                                          setTableData(newItems);
                                          return e;
                                        }}
                                        name="departament"
                                        placeholder="Wybierz..."
                                        styles={{
                                          menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                          }),
                                          control: (base, state) => ({
                                            ...base,
                                            border: "1px solid #142f42",
                                            boxShadow: "none",
                                            "&:hover": {
                                              border: "1px solid #142f42",
                                            },
                                          }),
                                          option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor:
                                              state.isFocused && "#142f42",
                                            color: state.isFocused && "#fff",
                                          }),
                                        }}
                                      />
                                    }
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <div className={`${styles.tableBottom} modalTabPagin`}>
                      <RowsPerPage
                        rowsPerPage={rowsPerPageModalTab}
                        setRowsPerPage={setRowsPerPageModalTab}
                        setPage={setPageModalTab}
                        tableLength={tableData.length}
                      />
                      <div>
                        <Pagination
                          postsPerPage={rowsPerPageModalTab}
                          totalPosts={tableData.length}
                          paginate={paginateModalTab}
                          page={pageModalTab}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: "8px 13px" }}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={BTNstyles.btn}
            onClick={(e) => {
              setOpenModalImport(false);
              cleanStates();
            }}
          >
            Anuluj
          </Button>
          <Button
            variant="contained"
            size="large"
            style={ifParsed ? { display: "flex" } : { display: "none" }}
            className={`${BTNstyles.btn} ${BTNstyles.grn}`}
            onClick={(e) => {
              // for (let i = 0; tableData.length > i; i++){
              // 	if(tableData[i].departament !== ''){
              // 		setAllSelectDepartments(true)
              // 		break;
              // 	}
              // }

              // console.log('out>>>',tableData)
              // console.table('val>',importColCar,importColName,importColType,importColPrice,importColDepartment,allSelectDepartments, importColCar && importColName && importColType && importColPrice && importColDepartment && allSelectDepartments)

              if (
                importColCar &&
                importColName &&
                importColType &&
                importColPrice &&
                (importColDepartment || !allSelectDepartments)
              ) {
                setError(false);

                let tempDataToImport = tableData.map((o) => {
                  return {
                    id_customer_department: departmentOptions.filter((x) => {
                      return x.label === o.departament;
                    })[0].id,
                    cost: o.price,
                    id_car: o.car.id,
                    currency: o.current,
                    type: o.type,
                    name: o.name,
                    description: o.discription,
                  };
                });

                let tempDataToImportNotNull = tempDataToImport.filter((x) => {
                  return x.id_car !== null;
                });

                fetchSetCosts(tempDataToImportNotNull);
              } else {
                setError(true);
                setOpenDialog(true);
                setDialogContent(`Proszę wybrać wymagane kolumny.`);
                setDialogColor("error");
              }
            }}
            color="primary"
          >
            Importuj <Check className={BTNstyles.downBtnIco} />
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openModalDelete}
        TransitionComponent={Transition}
        onClose={() => {
          setDeleteData([]);
          setOpenModalDelete(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Czy napewno chcesz usunąć ?{" "}
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              setDeleteData([]);
              setOpenModalDelete(false);
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {deleteData.length > 0 ? (
            <DialogContentText id="alert-dialog-slide-description">
              Czy napewno chcesz usunąć zakupy:{" "}
              {deleteData.map((e) => {
                const costOrder = table.find((el) => el.id === e);
                return (
                  <>
                    <br />
                    Zakup {costOrder ? costOrder.name : null} od{" "}
                    {costOrder ? costOrder.customer_department.name : null}{" "}
                  </>
                );
              })}
            </DialogContentText>
          ) : (
            <DialogContentText id="alert-dialog-slide-description">
              Czy napewno chcesz usunąć zakupy:{" "}
              {selected.map((e) => {
                const costOrder = table.find((el) => el.id === e);
                return (
                  <>
                    <br />
                    Zakup{costOrder ? costOrder.name : null} od{" "}
                    {costOrder ? costOrder.customer_department.name : null}{" "}
                  </>
                );
              })}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={BTNstyles.btn}
            onClick={(e) => {
              setOpenModalDelete(false);
            }}
          >
            Anuluj
          </Button>
          <Button
            variant="contained"
            size="large"
            className={`${BTNstyles.btn} ${BTNstyles.err}`}
            onClick={() => {
              delCosts(deleteData.length > 0 ? deleteData : selected);
              setSelected([]);
              setOpenModalDelete(false);
              setDeleteData([]);
            }}
            color="primary"
          >
            Usuń <X className={BTNstyles.downBtnIco} />
          </Button>
        </DialogActions>
      </Dialog>

      <PurchaseAdd
        open={openPurchaseAddModal}
        setOpen={setOpenPurchaseAddModal}
        data={dataPurchaseAddModal}
        carsOptions={carsOptions}
        departmentOptions={departmentOptions}
        getCosts={getCostsOthers}
        edit={edit}
        setOpenSnack={setOpenDialog}
        setSnackContent={setDialogContent}
        setSnackColor={setDialogColor}
        user={props.user}
      />

      <Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={dialogColor}>
          {dialogContent}
        </Alert>
      </Snackbar>
    </>
  );
}
