import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { X } from "react-feather";
import Tooltip from "@material-ui/core/Tooltip";
import DriverNotes from "components/DriverNotes/DriverNotes";
import TmsTitleSmall from "components/TmsTitleSmall";

import stylesMod from "styles/newOrEdit.module.css";
import stylesVersion from "styles/version_s.module.css";
import "./styles.sass";

const DriverNotesBox = ({
  showNotes,
  setShowNotes,
  user_s,
  isPreview,
  updateValue,
  valuesDriverNote,
  setDialog,
}) => {
  const [note, setNote] = useState({ name: "", note: "" });
  const [createTemplate, setCreateTemplate] = useState();
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);

  const handleCloseModal = () => {
    setIsTemplateModalOpen(false);
  };

  useEffect(() => {
    if (valuesDriverNote?.note) {
      setNote(valuesDriverNote.note);
    } else {
      setNote({ name: "", note: "" });
    }
  }, [valuesDriverNote?.note]);

  useEffect(() => {
    if (valuesDriverNote?.enabled) {
      setShowNotes(valuesDriverNote.enabled);
    }
  }, [valuesDriverNote?.enabled]);

  useEffect(() => {
    if (showNotes) {
      updateValue("driverNote.enabled", true);
    } else {
      updateValue("driverNote.enabled", false);
    }
  }, [showNotes]);

  const updateOnBlur = () => {
    updateValue("driverNote.note", note);
  };

  const handleUpdateFromTemplate = (note) => {
    updateValue("driverNote.note", note);
  };

  const handleCreateTemplate = () => {
    setCreateTemplate({ id: 0, name: "", note: note.note, new: true });
    setIsTemplateModalOpen(true);
  };

  const useStyles = makeStyles({
    root: {
      padding: 0,
    },
  });

  const classes = useStyles();

  if (!showNotes) {
    return null;
  }

  return (
    <>
      <Grid container spacing={2} className="driver-note-box">
        <TmsTitleSmall title="Notatka dla kierowcy" />
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
                <div className={stylesMod.inp}>
                  <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                    Notatka
                  </label>

                  <textarea
                    value={note.note}
                    rows={5}
                    onChange={(e) => setNote({ ...note, note: e.target.value })}
                    onBlur={updateOnBlur}
                  />
                  <div className="driver-note-box__template-wrapper">
                    {note?.note?.length > 1 && (
                      <Button
                        style={{ padding: "9px", marginRight: "10px" }}
                        disabled={user_s || isPreview}
                        variant="outlined"
                        color="primary"
                        className={stylesMod.outBtn}
                        onClick={handleCreateTemplate}
                      >
                        Utwórz jako szablon
                      </Button>
                    )}
                    <Button
                      style={{ padding: "9px" }}
                      disabled={user_s || isPreview}
                      variant="contained"
                      color="primary"
                      className={stylesMod.outBtn}
                      onClick={() => {
                        setCreateTemplate(null);
                        setIsTemplateModalOpen(true);
                      }}
                    >
                      Szablony notatek
                    </Button>
                  </div>
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        maxWidth="xl"
        fullWidth
        open={isTemplateModalOpen}
        onClose={handleCloseModal}
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Szablony notatek
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={handleCloseModal}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.root}>
          {isTemplateModalOpen && (
            <DriverNotes
              modalMode={true}
              handleUpdateFromTemplate={handleUpdateFromTemplate}
              setIsTemplateModalOpen={setIsTemplateModalOpen}
              createTemplate={createTemplate}
              setCreateTemplate={setCreateTemplate}
              setDialog={setDialog}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DriverNotesBox;
