import React, { useState, useEffect } from "react";
import chatStyles from "../../styles/chat.module.css";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from "@material-ui/core";
import IcoBtn from "../../components/buttons/icoBtn";
import { File } from "react-feather";
import { DBurl } from "../../appConfig";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import stylesMod from "../../styles/newOrEdit.module.css";
import styles from "../table.module.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { TableHead } from "@material-ui/core";
import { Snackbar, Grid } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DriversListCommunication({
  communitorUsers,
  setChat,
  chat,
  setDriver,
  props,
  setDialog,
}) {
  const [driver_id, setDriver_id] = useState();
  const [driverFilesList, setDriverFilesList] = useState([]);
  const [driverNoOrderFilesList, setDriverNoOrderFilesList] = useState([]);
  const [openModaFiles, setOpenModalFiles] = useState(false);

  const getFilesForDriver = async (driver_id) => {
    const response = await fetch(
      `${DBurl}/getFilesForDriver?id_driver=${driver_id}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setDriverFilesList(json.data);
    }
  };

  const getFilesForDriverNoOrder = async (driver_id) => {
    const response = await fetch(
      `${DBurl}/getNoOrderFiles?filter_id_driver=${driver_id}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setDriverNoOrderFilesList(json.data);
    }
  };

  useEffect(() => {
    if (driver_id !== undefined) {
      getFilesForDriver(driver_id);
      getFilesForDriverNoOrder(driver_id);
    }
  }, [openModaFiles === true]);

  const joinedTables = driverFilesList.concat(driverNoOrderFilesList);

  return (
    <>
      <div className={chatStyles.side}>
        <List>
          {communitorUsers?.map((user, i) => (
            <ListItem
              onClick={() => {
                setChat(user.id);
                setDriver(`${user.firstname} ${user.surname}`);
              }}
              style={{
                backgroundColor: chat === user.id ? "#b4ffbe" : "",
                cursor: "pointer",
              }}
              key={i}
            >
              <ListItemIcon>
                <div
                  className={
                    user.active ? chatStyles.dotGreen : chatStyles.dotRed
                  }
                />
              </ListItemIcon>

              <ListItemText
                className={chatStyles.text}
                primary={`${user.firstname} ${user.surname}`}
              />

              <div
                style={user.num_files > 0 ? { opacity: 1 } : { opacity: 0.1 }}
              >
                <IcoBtn
                  icon="Download"
                  className={chatStyles.listBtn}
                  tooltip={
                    user.num_files > 0 ? "Pobierz ostatni plik" : "Brak plików"
                  }
                  onClick={async () => {
                    if (user.num_files > 0) {
                      const response = await fetch(
                        `${DBurl}/getFilesForDriver?id_driver=${user.id}`,
                        {
                          method: "GET",
                          credentials: "include",
                          headers: {
                            "Content-Type": "application/json",
                            "X-CSRF-Token": props.user.csrf_token,
                          },
                        }
                      );
                      const json = await response.json();
                      if (json.success) {
                        if (json.data.length > 0) {
                          window.open(
                            `${DBurl}/getFile?filename=${json.data[0].filename}`,
                            "_blank"
                          );
                        } else {
                          setDialog({
                            isOpen: true,
                            type: "error",
                            content: "Brak plików do wyświetlenia",
                          });
                          props.loading(false);
                        }
                      }
                    }
                  }}
                />
              </div>

              <div
                style={user.num_files > 0 ? { opacity: 1 } : { opacity: 0.1 }}
              >
                <IcoBtn
                  icon="File"
                  className={chatStyles.listBtn}
                  tooltip={user.num_files > 0 ? "Lista plików" : "Brak plików"}
                  onClick={() => {
                    if (user.num_files > 0) {
                      setDriver_id(user.id);
                      setOpenModalFiles(true);
                    }
                  }}
                />
              </div>
            </ListItem>
          ))}
        </List>
      </div>

      <Dialog
        open={openModaFiles}
        TransitionComponent={Transition}
        onClose={() => {
          setOpenModalFiles(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {driverFilesList && driverFilesList.length > 0 ? (
          <>
            <DialogTitle
              id="alert-dialog-slide-title"
              className={stylesMod.dialogHead}
            >
              Lista plików
            </DialogTitle>

            <DialogContent className={chatStyles.tableBox}>
              <TableContainer className={chatStyles.tableList}>
                <Table stickyHeader size="small" aria-label="enhanced table">
                  <TableHead className={styles.tabHead}>
                    <TableRow>
                      <TableCell align="left">Nazwa pliku</TableCell>
                      <TableCell align="left">Numer zlecenia</TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody className={styles.tabBody}>
                    {driverFilesList &&
                      joinedTables
                        .sort((a, b) => new Date(b.ts) - new Date(a.ts))
                        .map((row, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell align="left">
                                {row.filename}{" "}
                              </TableCell>
                              <TableCell align="left">
                                {row.order_no ? row.order_no : "Brak zlecenia"}{" "}
                              </TableCell>
                              <TableCell align="right">
                                <div className={styles.buttonBox}>
                                  <IcoBtn
                                    className={chatStyles.listBtn}
                                    tooltip="Zobacz plik"
                                    value={row}
                                    onClick={() => {
                                      window.open(
                                        `${DBurl}/getFile?filename=${row.filename}`,
                                        "_blank"
                                      );
                                    }}
                                  >
                                    <File tooltip="Zobacz plik" />
                                  </IcoBtn>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions></DialogActions>
          </>
        ) : (
          <h2 className={styles.noFiles}></h2>
        )}
      </Dialog>
    </>
  );
}
