import React, { useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import DriversListCommunication from "./DriversListCommunication";
import Chat from "./Chat";
import ChatInput from "./ChatInput";
import { Snackbar, Grid } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { DBurl } from "../../appConfig";
import chatStyles from "../../styles/chat.module.css";
import Input from "../../components/input";
import TableCell from "@material-ui/core/TableCell";
import TmsDialog from "components/TmsDialog";
import { getCommunicatorUsers } from "api/endpoints";
import TmsLoading from "components/TmsLoading";

function useInput({ type }) {
  const [value, setValue] = useState("");
  const input = (
    <TableCell>
      <Input
        value={value}
        handleChange={(e) => {
          setValue(e.target.value);
        }}
        type={type}
        search={true}
      />
    </TableCell>
  );
  return [value, input];
}

export default function Communication(props) {
  const [chatID, setChatID] = useState(null);
  const [messages, setMessages] = useState([]);
  const [driver, setDriver] = useState("");
  const [stamp, setStamp] = useState(true);
  const [dialog, setDialog] = useState({ isOpen: false });
  const [searchDriver, setSearchDriver] = useInput({ type: "text" });
  const [showLoading, setShowLoading] = useState(false);

  var st;
  const startST = () => {
    st = setTimeout(() => {
      setStamp(!stamp);
    }, 10000);
  };

  useEffect(() => {
    clearTimeout(st);
    startST();
  }, []);

  useEffect(() => {
    if (chatID !== null) {
      getMessages();
    }
    clearTimeout(st);
    startST();
  }, [stamp]);

  const { data: allCommunicatorUsers, refetch: refetchDrivers } = useQuery(
    "getCommunicatorUsers",
    getCommunicatorUsers
  );

  const communitorUsers = useMemo(
    () => allCommunicatorUsers?.filter((user) => user.allow_mobile === true),
    [allCommunicatorUsers]
  );

  useEffect(() => {
    if (!chatID) {
      setChatID(communitorUsers?.[0]?.id);
    }
  }, [chatID, communitorUsers]);

  const getMessages = async () => {
    const response = await fetch(
      `${DBurl}/getMessagesFromDriver?id_driver=${chatID}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      refetchDrivers();
      if (!!json.data) {
        setMessages(json.data.reverse());
      } else {
        setMessages([]);
      }
      return json;
    } else {
      setDialog({
        isOpen: true,
        type: "error",
        content: `${json.responseDescription} // ${json.response}  `,
      });
    }
  };

  useEffect(() => {
    if (!!chatID) {
      getMessages();
    }
  }, [chatID]);

  const search = (tbl) => {
    return tbl.filter((row) => {
      return (
        String(row.firstname)
          .toLowerCase()
          .includes(searchDriver.toLowerCase()) ||
        String(row.surname).toLowerCase().includes(searchDriver.toLowerCase())
      );
    });
  };

  return (
    <>
      <Grid container spacing={3} className={chatStyles.parent}>
        <Grid item xs={2} className={chatStyles.noPad}>
          {setSearchDriver}
          <DriversListCommunication
            communitorUsers={communitorUsers}
            setChat={setChatID}
            chat={chatID}
            setDriver={setDriver}
            props={props}
            setDialog={setDialog}
          />
        </Grid>
        <Grid item xs={10} className={chatStyles.noPad}>
          <Chat messages={messages} driver={driver} />
          <ChatInput
            chatID={chatID}
            token={props.user.csrf_token}
            refresh={getMessages}
            messages={messages}
            refetchDrivers={refetchDrivers}
            setDialog={setDialog}
            setShowLoading={setShowLoading}
          />
        </Grid>
      </Grid>
      <TmsDialog dialog={dialog} setDialog={setDialog} />
      {showLoading && <TmsLoading status="Wysyłanie wiadomości" />}
    </>
  );
}
