import React from "react";
import stylesMod from "styles/newOrEdit.module.css";
import BTNstyles from "styles/button.module.css";
import { X } from "react-feather";
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

const DialModal = ({
  open,
  title,
  btn1Class,
  TransitionComponent,
  onClose,
  text,
  closeClick,
  btn1Click,
  btn1Text,
  btn1Ico,
}) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={TransitionComponent}
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        className={stylesMod.dialogHead}
      >
        {title}
        <IconButton
          aria-label="close"
          className={stylesMod.closeButton}
          onClick={onClose}
        >
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="large"
          color="primary"
          className={BTNstyles.btn}
          onClick={closeClick}
        >
          Anuluj
        </Button>
        <Button
          variant="contained"
          size="large"
          className={btn1Class}
          onClick={btn1Click}
          color="primary"
        >
          {btn1Text} {btn1Ico}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialModal;
