import React, { Component } from "react";
import { DBurl } from "../../appConfig";
import ReactDragListView from "react-drag-listview";
import { MyAutocomplete } from "./MyAutocomplete";
import styles from "../fleet/route.module.css";

import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import AdjustIcon from "@material-ui/icons/Adjust";

export class Route extends Component {
  /**
   * constructor()
   *
   * Funkcja
   *
   */
  constructor(props) {
    super(props);

    const dataAC = [];
    for (let i = 1, len = 3; i < len; i++) {
      dataAC.push({
        title: `rows${i}`,
        text: "",
        place: null,
      });
    }

    this.state = {
      myAutocompleteItem: this.props.routeACdata,
      optionsAC: [],
      myAutocopleteTimeout: null,
    };

    if (this.props.initDoRoute) {
      this.doRoute();
    }
  }

  /**
   * componentDidUpdate(prevProps)
   *
   * Funkcja
   *
   */
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.myAutocompleteItem !== this.props.routeACdata &&
      this.props.updateRoute
    ) {
      this.setState({
        myAutocompleteItem: this.props.routeACdata,
      });
      this.props.setUpdateRouteCallback(false);
      this.doRoute();
    }
  }

  /**
   * onChangeMyAutocomplete(event, title)
   *
   * Funkcja
   *
   */
  onChangeMyAutocomplete = async (event, title) => {
    let newInputValue = event.currentTarget.value;

    this.state.myAutocompleteItem.forEach((item, index) => {
      if (item.title === title) {
        this.setState((state) => ({
          myAutocompleteItem: [
            ...state.myAutocompleteItem.slice(0, index),
            {
              ...state.myAutocompleteItem[index],
              title: title,
              text: newInputValue,
              place: null,
            },
            ...state.myAutocompleteItem.slice(index + 1),
          ],
        }));
      }
    });

    if (newInputValue !== "" && newInputValue.length > 1) {
      // Debounce
      return await this.debounce(newInputValue);
    }
  };

  /**
   * debounce()
   *
   * Funkcja
   *
   */
  debounce = (newInputValue) => {
    const that = this;

    // test for coordinates
    const latLonRegexp =
      /^[ \t\r\n]*([0-9]+[.,][0-9]+)[^0-9]{1,3}([0-9]+[.,][0-9]+)[ \t\r\n]*$/;
    // if (latLonRegexp.test(newInputValue)) {
    //     console.log('Współrzędne');
    // }
    // else {
    //     console.log('Fraza');
    // }

    var promise = new Promise(function (resolve, reject) {
      if (that.state.myAutocopleteTimeout) {
        clearTimeout(that.state.myAutocopleteTimeout);
      }
      let timeout = setTimeout(function () {
        resolve(that.search(newInputValue));
      }, 1000);
      that.setState({ myAutocopleteTimeout: timeout });
    });

    return promise;
  };

  /**
   * search(term)
   *
   * Funkcja
   *
   */
  search = async (term) => {
    const response = await fetch(`${DBurl}/search?term=${term}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.props.csrf_token,
      },
    });

    this.setState({
      optionsAC: [],
    });

    if (response.status === 200) {
      const json = await response.json();

      if (json.success && json.data !== null) {
        json.data.forEach((item, index) => {
          let label = item.display_name;

          let place = {
            location: { lat: parseFloat(item.lat), lng: parseFloat(item.lon) },
          };

          this.setState({
            optionsAC: [
              ...this.state.optionsAC,
              {
                label: label,
                id: index,
                place: place,
              },
            ],
          });
        });

        return this.state.optionsAC;
      }
    }
  };

  /**
   * onClickMyAutocomplete(selected, title)
   *
   * Funkcja
   *
   */
  onClickMyAutocomplete = (selected, title) => {
    //console.log(selected);

    this.state.myAutocompleteItem.forEach((item, index) => {
      if (item.title === title) {
        this.setState((state) => ({
          myAutocompleteItem: [
            ...state.myAutocompleteItem.slice(0, index),
            {
              ...state.myAutocompleteItem[index],
              title: title,
              text: selected.label,
              place: selected.place,
            },
            ...state.myAutocompleteItem.slice(index + 1),
          ],
        }));
      }
    });

    this.doRoute();
  };

  /**
   * onClickAddNewPoint()
   *
   * Funkcja
   *
   */
  onClickAddNewPoint = () => {
    // let i = this.state.myAutocompleteItem.length + 1;
    // this.setState({
    //     myAutocompleteItem: [
    //         ...this.state.myAutocompleteItem,
    //         {
    //             title: `rows${i}`,
    //             text: '',
    //             place: null
    //         }
    //     ]
    // });

    let myAutocompleteItemCopy = this.state.myAutocompleteItem;
    myAutocompleteItemCopy.push({
      title: `rows${this.state.myAutocompleteItem.length + 1}`,
      text: "",
      place: null,
    });
    this.setState({
      myAutocompleteItem: myAutocompleteItemCopy,
    });
  };

  /**
   * doRoute()
   *
   * Funkcja
   *
   */
  doRoute = () => {
    // Check number of points
    const routePoints = [];
    let waypoints = [];
    setTimeout(() => {
      this.state.myAutocompleteItem.forEach((item, index) => {
        if (item.place !== null) {
          routePoints.push(item.place);
        }
      });
      if (routePoints.length >= 2) {
        let destination = {
          lat: routePoints[routePoints.length - 1].location.lat,
          lng: routePoints[routePoints.length - 1].location.lng,
        };

        let origin = {
          lat: routePoints[0].location.lat,
          lng: routePoints[0].location.lng,
        };

        // Waypoints
        if (routePoints.length > 2) {
          waypoints = routePoints.slice(1, -1);
        }

        this.props.doRouteCallback(
          destination,
          origin,
          waypoints,
          this.state.myAutocompleteItem,
          "directions",
          null,
          null,
          null,
          false
        );
      }
    }, 100);
  };

  /**
   * onDragEnd(fromIndex, toIndex)
   *
   * Funkcja
   *
   */
  onDragEnd = (fromIndex, toIndex) => {
    const data = [...this.state.myAutocompleteItem];
    const item = data.splice(fromIndex, 1)[0];
    data.splice(toIndex, 0, item);
    this.setState({ myAutocompleteItem: data });

    this.doRoute();
  };

  /**
   * onDeletePoint(index)
   *
   * Funkcja
   *
   */
  onDeletePoint = (index) => {
    const data = [...this.state.myAutocompleteItem];
    if (data.length > 2) {
      data.splice(index, 1);
      this.setState({ myAutocompleteItem: data });

      this.doRoute();
    }

    //console.log(index, data);
  };

  /**
   * onClosePlaner()
   *
   * Funkcja
   *
   */
  onClosePlaner = () => {
    this.props.onCloseRoutePlanerCallback();
  };

  /**
   * render()
   *
   * Funkcja
   *
   */
  render = () => {
    return (
      <div className={styles.routePlaner}>
        <ReactDragListView
          nodeSelector="li"
          handleSelector="div"
          onDragEnd={this.onDragEnd}
        >
          <ul className={styles.ulPoints}>
            {this.state.myAutocompleteItem.map((item, index) => {
              return (
                <li className={styles.liPoints} key={index}>
                  <div className={styles.dragPoints}>
                    <AdjustIcon fontSize="small" />
                  </div>
                  <div className={styles.inputPoints}>
                    <MyAutocomplete
                      inputClass={styles.inputClass}
                      inputValue={item.text}
                      title={item.title}
                      onChange={this.onChangeMyAutocomplete}
                      onClick={this.onClickMyAutocomplete}
                      placeholder="Wybierz punkt docelowy..."
                    />
                  </div>
                  <div
                    className={styles.deletePoints}
                    onClick={() => this.onDeletePoint(index)}
                  >
                    <CloseIcon fontSize="small" />
                  </div>
                </li>
              );
            })}
          </ul>
        </ReactDragListView>
        <div className={styles.summaryContainer}>
          <div className={styles.addNewPoint} onClick={this.onClickAddNewPoint}>
            <AddIcon fontSize="small" />
          </div>
          <div className={styles.routeSummary}>
            {this.props.routeDistance !== "" && (
              <span>
                Dystans: <b>{this.props.routeDistance} km</b> | Czas przejazdu:{" "}
                <b>{this.props.routeDuration}</b>
              </span>
            )}
          </div>
          <div className={styles.closePoints} onClick={this.onClosePlaner}>
            <CloseIcon fontSize="small" />
          </div>
        </div>
      </div>
    );
  };
}
