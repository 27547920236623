import React, { useState } from "react";
import { DBurl } from "../appConfig";
import Grid from "@material-ui/core/Grid";
import styles from "./uploadFile.module.css";
import { UploadCloud, X } from "react-feather";

const FileUpload = (props) => {
  const [preview, setPreview] = useState(props.data.logo_base64 || null);
  const [croppedPreview, setCroppedPreview] = useState(
    props.data.logo_base64 || null
  );
  const MAX_WIDTH = props.width;
  const MAX_HEIGHT = props.height;
  const FRAME_SIZE = 1;

  // console.log(props.data.logo_base64)

  const fetchFile = async (fileConv) => {
    const base64Data = fileConv;
    const byteCharacters = atob(base64Data.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray]);
    const file = new File([blob], "fv_logo.jpg");
    const formData = new FormData();
    formData.append("file", file, file.name);
    const response = await fetch(`${DBurl}/uploadFile`, {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: formData,
    });
    const json = await response.json();
    if (json.success) {
      props.setFileLogo(json.data?.filename);
    }
  };

  const handleChange = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const image = new Image();
      image.onload = () => {
        if (image.width > MAX_WIDTH || image.height > MAX_HEIGHT) {
          const canvas = document.createElement("canvas");
          const scale = Math.min(
            MAX_WIDTH / image.width,
            MAX_HEIGHT / image.height
          );
          canvas.width = MAX_WIDTH;
          canvas.height = MAX_HEIGHT;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(
            image,
            (image.width - MAX_WIDTH / scale) / 2,
            (image.height - MAX_HEIGHT / scale) / 2,
            MAX_WIDTH / scale,
            MAX_HEIGHT / scale,
            0,
            0,
            MAX_WIDTH,
            MAX_HEIGHT
          );
          setCroppedPreview(canvas.toDataURL());
          fetchFile(canvas.toDataURL());
        } else {
          setCroppedPreview(reader.result);
        }
        setPreview(reader.result);
      };
      image.src = reader.result;
    };
    reader.readAsDataURL(file);
  };

  const handleClean = async (e) => {
    setPreview();
    setCroppedPreview();
  };

  //   console.log('croppedPreview',croppedPreview)

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        // style={{maxWidth: MAX_WIDTH + "px"}}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {preview ? (
            <div
              style={{
                width: MAX_WIDTH + 2 * FRAME_SIZE,
                height: MAX_HEIGHT + 2 * FRAME_SIZE,
                border: `1px dashed #aaaaaa`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#fbfbfb",
                borderRadius: "5px",
                position: "relative",
              }}
            >
              <img
                src={croppedPreview}
                alt="Preview"
                style={{
                  width: MAX_WIDTH,
                  height: MAX_HEIGHT,
                }}
              />

              <div className={styles.uploadBtnBoxIcon}>
                <div className={styles.upload}>
                  <label id="upload-label">
                    <UploadCloud />
                  </label>
                  <input
                    type="file"
                    name="upload-file"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className={styles.uploadBtnBoxIconClean}>
                <button onClick={(e) => handleClean()}>
                  <X />
                </button>
              </div>
            </div>
          ) : (
            <div
              style={{
                width: MAX_WIDTH + 2 * FRAME_SIZE,
                height: MAX_HEIGHT + 2 * FRAME_SIZE,
                border: `1px dashed #aaaaaa`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#fbfbfb",
                borderRadius: "5px",
              }}
            >
              <div className={styles.uploadBtnBox}>
                <div className={styles.upload}>
                  <label id="upload-label">
                    <UploadCloud />
                    Wybierz plik
                  </label>
                  <input
                    type="file"
                    name="upload-file"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default FileUpload;
