import React, { useState } from "react";
import { DBurl } from "appConfig";
import { X, ArrowRight } from "react-feather";
import { Button, Grid, Drawer } from "@material-ui/core";
import Title from "./title";
import Input from "./input";

import BTNstyles from "styles/button.module.css";
import stylesMod from "styles/newOrEdit.module.css";

export default function DriverDrawer(props) {
  const [error, setError] = useState(false);

  const fetchTransportSet = async () => {
    const response = await fetch(`${DBurl}/setTransportSets/`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "insert",
        data: {
          label: props.data.label,
          id_transport_set: props.data.transportSet_id,
          id_car: props.data.car_id,
          id_trailer: props.data.trailer_id,
          id_driver1: props.data.driver1_id,
          id_driver2: props.data.driver2_id,
          is_double_cast_of_drivers: props.data.driver2_id ? true : false,
          axle_netto: null,
          is_global: false,
        },
      }),
    });
    const json = await response.json();
    if (json.success) {
      props.fetchGets();
      props.setDialog({
        isOpen: true,
        type: "success",
        content: "Zestaw pomyślnie stworzony",
      });
      props.clearCreateForm();
      props.setOpen(false);
    }
  };

  const handleCreate = () => {
    if (!props.data.label || !props.data.car_name || !props.data.driver1_name) {
      setError(true);
      props.setDialog({
        isOpen: true,
        type: "warning",
        content: "Proszę uzupełnić wymagane pola",
      });
    } else {
      setError(false);
      fetchTransportSet();
    }
  };

  return (
    <>
      <Drawer variant="persistent" anchor="bottom" open={props.open}>
        <Title title={props.data.edit ? "Edytuj zestaw" : "Dodaj Zestaw"} />
        <div className={stylesMod.innerDrawer}>
          <Grid container spacing={2}>
            <Grid item xs={1}>
              <div className={stylesMod.inp}>
                <label>Nazwa</label>
                <Input
                  value={props.data.label}
                  error={error && !props.data.label}
                  handleChange={(e) => {
                    props.setData({
                      ...props.data,
                      label: e.target.value,
                    });
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={stylesMod.inp}>
                <label>Pojazd</label>
                <Input
                  value={props.data.car_name}
                  disabled
                  error={error && !props.data.car_name}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={stylesMod.inp}>
                <label>Naczepa</label>
                <Input value={props.data.trailer_name} disabled />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={stylesMod.inp}>
                <label>Pierwszy kierowca</label>
                <Input
                  value={props.data.driver1_name}
                  disabled
                  error={error && !props.data.driver1_name}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={stylesMod.inp}>
                <label>Drugi kierowca</label>
                <Input value={props.data.driver2_name} disabled />
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={stylesMod.buttonContDrawer}>
                <Button
                  variant="contained"
                  size="medium"
                  className={`${BTNstyles.btnDrawer} ${BTNstyles.err}`}
                  styles={{ height: "auto" }}
                  onClick={() => {
                    props.setOpen(false);
                  }}
                >
                  {" "}
                  Zamknij
                  <X className={BTNstyles.downBtnIco} />
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  className={BTNstyles.btnDrawer}
                  onClick={handleCreate}
                >
                  {props.data.edit ? "Zapisz zmiany" : "Utwórz zestaw"}{" "}
                  <ArrowRight className={BTNstyles.downBtnIco} />
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Drawer>
    </>
  );
}
