import React, { useState, useEffect, useRef } from "react";
import Input from "../../components/input";
import { EditBtn, FileBtn, Edit2Btn } from "../../components/buttons";
import Title from "../../components/title";
import BTNstyles from "../../styles/button.module.css";
import styles from "../table.module.css";
import stylesMod from "../../styles/newOrEdit.module.css";
import { X, Disc, Target, File, Check, UploadCloud } from "react-feather";
import RowsPerPage from "../../components/RowsPerPage";
import Pagination from "../../components/pagination";
import { DBurl } from "../../appConfig";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  IconButton,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
  Slide,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitl,
  DialogTitle,
  Tooltip,
  Grid,
  Collapse,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import StatusInvoice from "../../components/StatusInvoice";
import InvoiceAddNew from "./AddInvoice/InvoiceAddNew";
import IcoBtn from "../../components/buttons/icoBtn";
import Select from "react-select";
import EmailRecipientModal from "./emailRecipientModal";

const options = [{ label: "Pokaż wszystkie", value: null }];
const optionsFvLang = [
  { label: "Polski", value: "PL" },
  { label: "Angielski", value: "EN" },
  { label: "Niemiecki", value: "DE" },
  { label: "Hiszpański", value: "ES" },
  { label: "Czeski", value: "CZ" },
  { label: "Szwedzki", value: "SE" },
  { label: "Słowacki", value: "SK" },
  { label: "Francuski", value: "FR" },
  { label: "Ukraiński", value: "UA" },
  { label: "Włoski", value: "IT" },
  { label: "Duński", value: "DK" },
  { label: "Turecki", value: "TR" },
  { label: "Rosyjski", value: "RU" },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const select_styles = {
  control: (base, state) => ({
    ...base,
    border: "1px solid #142f42",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #142f42",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused && "#142f42",
    color: state.isFocused && "#fff",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 10 }),
};

const headCells = [
  {
    id: "invoiceNr",
    numeric: false,
    disablePadding: true,
    label: "Nr faktury",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Typ",
    class: styles.statusShort,
  },
  { id: "order", numeric: true, disablePadding: false, label: "Zlecenie" },
  { id: "company", numeric: true, disablePadding: false, label: "Klient" },
  {
    id: "saleDate",
    numeric: true,
    disablePadding: false,
    label: "Data Sprzedaży",
    class: styles.centered,
  },
  {
    id: "paidDate",
    numeric: true,
    disablePadding: false,
    label: "Data wystawienia",
    class: styles.centered,
  },
  {
    id: "netto",
    numeric: true,
    disablePadding: false,
    label: "Wartość netto",
    class: styles.centered,
  },
  {
    id: "value_brutto",
    numeric: true,
    disablePadding: false,
    label: "Wartość brutto",
    class: styles.centered,
  },
  {
    id: "validDate",
    numeric: true,
    disablePadding: false,
    label: "Termin płatności",
    class: styles.centered,
  },
  {
    id: "progress",
    numeric: true,
    disablePadding: false,
    label: "Kwota opłacona",
    class: styles.paid,
  },
  // { id: 'madeBy', numeric: true, disablePadding: false, label: 'Wystawiona przez', class: styles.centered },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
  { id: "tas", numeric: false, disablePadding: false, label: "Tagi" },
  {
    id: "wpłata",
    numeric: false,
    disablePadding: false,
    label: "Wpłata",
    class: styles.payment,
  },
];

function EnhancedTableHead(props) {
  const {
    styles,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={styles.tabHead}>
      <TableRow>
        <TableCell className={styles.checkbox}>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all id" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={headCell.class}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={styles.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {/* <TableCell className={styles.buttonBoxParentLg} /> */}
      </TableRow>
    </TableHead>
  );
}

function useInput({ type }) {
  const [value, setValue] = useState("");
  const input = (
    <TableCell>
      <Input
        value={value}
        handleChange={(e) => {
          setValue(e.target.value);
        }}
        type={type}
        search={true}
      />
    </TableCell>
  );
  return [value, input];
}

export default function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("car");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [table, setTable] = useState([
    {
      invoice_no: "",
      orders: [],
      contractor: {},
      data_services: "",
    },
  ]);
  const [deletedTable, setDeletedTable] = useState([]);
  const [activeTable, setActiveTable] = useState([]);
  const [fullTable, setFullTable] = useState([]);
  const [sortedTable, setSortedTable] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);

  const [searchInvoiceNr, setSearchInvoiceNr] = useInput({ type: "text" });
  const [searchOrder, setSearchOrder] = useInput({ type: "text" });
  const [searchType, setSearchType] = useInput({ type: "text" });
  const [searchClient, setSearchClient] = useInput({ type: "text" });
  const [searchMadeBy, setSearchMadeBy] = useInput({ type: "text" });
  const [searchTags, setSearchTags] = useInput({ type: "text" });

  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [modalContent, setModalContent] = React.useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedFvLanguage, setSelectedFvLanguage] = useState(null);
  const [departmentsList, setDepartmentsList] = useState();
  const [fullPayment, setFullPayment] = useState(false);
  const [partPayment, setPartPayment] = useState(false);
  const [openFullPaymentModal, setOpenFullPaymentModal] = useState(false);
  const [openPartPaymentModal, setOpenPartPaymentModal] = useState(false);
  const [partPaymentValue, setPartPaymentValue] = useState("");
  const [reload, setReload] = useState(false);

  const [openModalImport, setOpenModalImport] = useState();
  const [btnSend, setBtnSend] = useState(false);
  const [files, setFiles] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [parsedDataData, setParsedDataData] = useState();
  const fileInput = useRef(null);
  const [ifParsed, setIfParsed] = useState(false);
  const [rowsPerPageModalTab, setRowsPerPageModalTab] = React.useState(10);
  const [pageModalTab, setPageModalTab] = React.useState(0);
  const [currencyFilter, setCurrencyFilter] = useState();
  const [showCurrPrice, setShowCurrPrice] = useState(false);
  const [exchangeRates, setExchangeRates] = useState();

  const [tags_list, setTags_list] = useState([]);
  const [emailRecipient, setEmailRecipient] = useState();
  const [openModalEmailRecipient, setOpenModalEmailRecipient] = useState(false);
  const [emailRecipientModalData, setEmailRecipientModalData] = useState([]);

  const [openCollapse, setOpenCollapse] = useState(null);
  const handleShowTags = (row) => {
    return String(
      row.tags
        ?.map((tag) => tags_list.find((t) => t.id === tag.id_tag)?.name)
        .flat()
    );
  };

  const currencyFilterOptions = [
    { label: "Wszystkie", value: "" },
    { label: "PLN", value: "pln" },
    { label: "EUR", value: "eur" },
    { label: "Inne", value: "other" },
  ];

  const paginateModalTab = (pageNumber) => setPageModalTab(pageNumber - 1);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDialog(false);
  };

  const fetchInvoices = async (token) => {
    props.loading(true);
    if (selectedDepartment === null) {
      const response = await fetch(`${DBurl}/getInvoices`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token,
        },
      });
      const json = await response.json();
      if (json.success) {
        if (json?.data?.length > 0) {
          setTable(json.data);
          setDeletedTable(json.data.filter((inv) => inv.is_deleted));
          setActiveTable(json.data.filter((inv) => !inv.is_deleted));
        }

        props.loading(false);
      }
    }
    if (selectedDepartment !== null) {
      const response = await fetch(
        `${DBurl}/getInvoices?id_customer_departament=${selectedDepartment}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": token,
          },
        }
      );
      const json = await response.json();
      if (json.success) {
        setTable(json.data);
        setDeletedTable(json.data.filter((inv) => inv.is_deleted));
        setActiveTable(json.data.filter((inv) => !inv.is_deleted));
        props.loading(false);
      }
    }
  };

  async function getDepartments() {
    // props.loading(true);
    await fetch(`${DBurl}/getCustomersDepartments`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          const departments = data.data;
          if (options.length === 1) {
            for (let i in departments) {
              options.push({
                label:
                  departments[i].shortname === null
                    ? ""
                    : departments[i].shortname,
                value: departments[i].id,
              });
            }
            // options.sort((b, a) => a.label.localeCompare(b.label, 'es', {sensitivity: 'base'}))
            setDepartmentsList(data.data);
          }
          // props.loading(false);
          return data;
        } else {
          // props.loading(false);
          setOpenDialog(true);
          setDialogContent(
            `${data.responseDescription} // ${DataTransferItemList.response}  `
          );
          setDialogColor("error");
        }
      });
  }

  const sendInvoiceByEmail = async (id) => {
    props.loading(true);
    await fetch(`${DBurl}/sendInvoiceByEmail`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        data: {
          id_invoice: id,
        },
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setOpenDialog(true);
          setDialogContent("Faktura wysłana.");
          setDialogColor("success");
          props.loading(false);
        } else {
          setOpenDialog(true);
          setDialogContent(
            `${data.responseDescription} // ${DataTransferItemList.response}  `
          );
          setDialogColor("error");
          props.loading(false);
        }
      });
  };

  const delInvoices = async function (arrayOfIds) {
    props.loading(true);
    const response = await fetch(`${DBurl}/setInvoices/`, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "delete",
        data: {
          ids: arrayOfIds,
        },
      }),
    });
    const json = await response.json();

    if (json.success) {
      setOpenDialog(true);
      setDialogContent(`Dane zostały usunięte `);
      setDialogColor("success");
      fetchInvoices(props.user.csrf_token);
      setSelected([]);
      props.loading(true);
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(true);
    }
  };

  const handleInvoicePayment = async (id_invoice, value) => {
    setReload(true);
    const response = await fetch(`${DBurl}/setInvoicePaid`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "update",
        data: {
          id_invoice: id_invoice,
          value: value,
        },
      }),
    });
    setReload(false);
    setPartPaymentValue("");
  };

  const getTags = async function () {
    // props.loading(true);
    const response = await fetch(`${DBurl}/getInvoiceTags/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      const tempData = [...json.data];
      setTags_list(tempData);
      // props.loading(false);
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      // props.loading(false);
    }
  };

  useEffect(() => {
    fetchInvoices(props.user.csrf_token);
    getDepartments();
  }, [selectedDepartment, reload === true]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getType = (type) => {
    switch (type) {
      case 1:
        return "Faktura VAT";
      case 2:
        return "Proforma";
      case 3:
        return "Korekta";
      case 4:
        return "Nota obciążeniowa";
      case 5:
        return "Nota uznaniowa";
      default:
        return "";
    }
  };

  const handleType = (row) => {
    if (row.type === 1) return row.invoice_no;
    if (row.type === 3)
      return `${row.correction_no} ${
        sortedTable?.find((i) => i.id === row?.correction_invoice_id)
          ?.invoice_no
          ? `(${
              sortedTable?.find((i) => i.id === row?.correction_invoice_id)
                ?.invoice_no
            })`
          : ""
      }`;
    if (row.type === 4) return row.debit_note_no;
    if (row.type === 5) return row.credit_note_no;
  };

  const search = (tbl) => {
    const ret = tbl.filter((row) => {
      return (
        handleShowTags(row).toLowerCase().includes(searchTags.toLowerCase()) &&
        handleType(row)
          ?.toLowerCase()
          .includes(searchInvoiceNr.toLowerCase()) &&
        getType(row.type).toLowerCase().indexOf(searchType.toLowerCase()) >
          -1 &&
        row.orders?.[0]?.order_no
          ?.toLowerCase()
          .includes(searchOrder.toLowerCase())
      );
    });

    return ret;
  };

  const correctedInvoicesIDs =
    table?.length > 0
      ? table
          ?.filter((inv) => inv.correction_invoice_id)
          .map((inv) => inv.correction_invoice_id)
      : [];

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = table.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const paginate = (pageNumber) => setPage(pageNumber - 1);
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const getNetto = (arr, corArr) => {
    if (!!corArr) {
      const tempArr = [];
      arr.forEach((el, i) => {
        const correctionElement = corArr.find(
          (el2) => el2.id_service_reference === el.id
        );
        !!correctionElement
          ? tempArr.push(correctionElement)
          : tempArr.push(el);
      });
      return tempArr.reduce(
        (a, b) => Number(a) + (Number(b["value_netto"]) || 0),
        0
      );
    } else {
      var result = 0;
      arr.forEach((e) => (result += Number(e.value_netto)));
      return result;
    }
  };

  const getBrutto = (arr) => {
    var result = 0;
    arr.forEach((e) => (result += Number(e.value_brutto)));
    return result;
  };

  const printPDF = (id, lang) => {
    const url = `${DBurl}/printInvoice2PDF?id_invoice=${id}&lang=${
      lang === null ? "PL" : lang
    }`;
    window.open(url, "_blank");
  };

  const editInvoice = (id, type) => {
    const tempData = {
      permission_id: 14,
      tabName: getType(type) || "Edytuj fakturę",
      tabID: null,
      component: InvoiceAddNew,
      active: true,
      fetchInvoiceId: id,
      typeInput: { label: getType(type), value: type },
    };
    props.addTab("InvoiceAddNew", tempData);
  };

  const fetchRates = async () => {
    // props.loading(true);
    const response = await fetch(`${DBurl}/getExchangeRates`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setExchangeRates(json.data);
      // props.loading(false);
    }
  };

  useEffect(() => {
    fetchRates();
    getTags();
  }, []);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, table.length - page * rowsPerPage);

  // do search i kolejnosci

  const checkInvoices = (value, filterValue) => {
    if (filterValue.length === 0) return true;
    if (!value) return false;
    return value.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase());
  };

  useEffect(() => {
    const allFilters = "".concat(
      searchInvoiceNr,
      searchType,
      searchOrder,
      searchClient
    );
    if (allFilters.length === 0) {
      setSortedTable(
        fullTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      );
      setIsFiltered(false);
      return;
    }

    setIsFiltered(true);
    const filtered = fullTable.filter(
      (inv) =>
        checkInvoices(inv.invoice_no, searchInvoiceNr) &&
        checkInvoices(getType(inv.type), searchType) &&
        checkInvoices(inv.orders?.[0]?.order_no, searchOrder) &&
        checkInvoices(String(inv.contractor?.shortname), searchClient)
    );

    setSortedTable(
      filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    );
  }, [
    searchInvoiceNr,
    searchType,
    searchOrder,
    searchClient,
    page,
    rowsPerPage,
  ]);

  useEffect(() => {
    // const sorted =  stableSort(search(activeTable), getComparator(order, orderBy))
    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    // const sorted = isFiltered ? [...fullTable] : [...activeTable].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    const sorted = [...activeTable];
    deletedTable.sort(
      (a, b) => new Date(a.date_of_issue) - new Date(b.date_of_issue)
    );

    for (const tableItem of deletedTable) {
      if (tableItem.debit_note_no) {
        const debitIndex = sorted.findIndex(
          (inv) => inv.debit_note_no === tableItem.debit_note_no
        );
        if (debitIndex !== -1) {
          sorted.splice(debitIndex + 1, 0, tableItem);
          continue;
        }
      }

      if (tableItem.credit_note_no) {
        const creditIndex = sorted.findIndex(
          (inv) => inv.credit_note_no === tableItem.credit_note_no
        );
        if (creditIndex !== -1) {
          sorted.splice(creditIndex + 1, 0, tableItem);
          continue;
        }
      }

      if (tableItem.correction_no) {
        const correctionIndex = sorted.findIndex(
          (inv) => inv.correction_no === tableItem.correction_no
        );
        if (correctionIndex !== -1) {
          sorted.splice(correctionIndex + 1, 0, tableItem);
          continue;
        }
      }

      const invIndex = sorted.findIndex(
        (inv) =>
          inv.invoice_no === tableItem.invoice_no &&
          !inv.credit_note_no &&
          !inv.debit_note_no
      );
      if (invIndex !== -1) {
        sorted.splice(invIndex + 1, 0, tableItem);
        continue;
      }
      sorted.push(tableItem);
    }
    // const invNoCorrection = sorted.filter(item => !item?.correction_invoice_id);
    // const invCorrection = sorted.filter(item => item?.correction_invoice_id);
    // const correctNoInv = []

    // for (const inv of invCorrection) {
    //   const goodPosition = invNoCorrection.findLastIndex(item => inv.correction_invoice_id === item.id);
    //   if (goodPosition !== -1) {
    //     invNoCorrection.splice(goodPosition + 1, 0, inv)
    //     continue
    //   }

    //   if (goodPosition === -1) {
    //     correctNoInv.push(inv);
    //   }
    // }

    // const allInvoices = [...invNoCorrection, ...correctNoInv]

    // setFullTable(allInvoices);
    // setSortedTable(
    //   allInvoices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    // );
    setFullTable(sorted);
    setSortedTable(
      sorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    );
  }, [activeTable]);

  function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  }
  const images = importAll(
    require.context("../../img/flags", false, /\.(png|gif|svg)$/)
  );
  const iso = require("iso-3166-1");

  const renderFlag = (code) => {
    return code.toLowerCase() + ".gif";
  };

  const filterCurrencies = (table, filter) => {
    if (filter?.value === "other")
      return table.filter(
        (inv) => inv.currency !== "PLN" && inv.currency !== "EUR"
      );
    return filter?.value
      ? table.filter((inv) => inv.currency === filter.label)
      : table;
  };

  const handleShowCurrPrices = (filter) => {
    filter?.value && filter.value !== "PLN"
      ? setShowCurrPrice(true)
      : setShowCurrPrice(false);
  };

  useEffect(() => {
    handleShowCurrPrices(currencyFilter);
  }, [currencyFilter]);

  // Import z pliku faktur opłaconych

  const modalTabStyles = {
    border: "1px solid #aaaaaa",
    borderRadius: "4px",
    margin: "10px 4px 0 4px",
  };

  const cleanStates = () => {
    setBtnSend(false);
    setFiles([]);
    setTableData([]);
    setParsedDataData();
    fileInput.current.value = "";
    setIfParsed(false);
  };

  const fetchPaymentsDataFromFile = async (token, data) => {
    props.loading(true);
    const response = await fetch(`${DBurl}/setPaymentsDataFromFile`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify({
        mode: "update",
        data: {
          payments: data,
        },
      }),
    });
    const json = await response.json();
    if (json.success) {
      setOpenDialog(true);
      setDialogContent(
        `Lista faktur została pomyślnie przesłana. Przesłane faktury od tej chwili będą oznaczone jako opłacone.`
      );
      setDialogColor("success");

      fetchInvoices(props.user.csrf_token);
      setOpenModalImport(false);
      cleanStates();

      props.loading(false);
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");

      setOpenModalImport(false);

      props.loading(false);
    }
  };

  const fetchGetDataFromFile = async (filename) => {
    props.loading(true);
    const response = await fetch(
      `${DBurl}/getPaymentsDataFromFile?filename=${filename}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      if (json.data.length > 0) {
        setOpenDialog(true);
        setDialogContent(`Plik sparsowany poprawnie.`);
        setDialogColor("success");
      } else {
        setOpenDialog(true);
        setDialogContent(`Pobrane numery faktur nie istnieją w bazie danych..`);
        setDialogColor("warning");
      }

      setParsedDataData(json.data);

      props.loading(false);
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  const fetchFile = async (file) => {
    props.loading(true);
    try {
      const formData = new FormData();
      await formData.append("file", await file[0][0], file[0][0].name);
      const response = await fetch(`${DBurl}/uploadFile`, {
        method: "POST",
        credentials: "include",
        headers: {
          "X-CSRF-Token": props.user.csrf_token,
        },
        body: formData,
      });
      const json = await response.json();
      if (json.success) {
        props.loading(false);

        setOpenDialog(true);
        setDialogContent(`Plik wysłany prawidłowo.`);
        setDialogColor("success");

        setIfParsed(true);

        fetchGetDataFromFile(json.data?.filename);
        return await {
          name: json.data?.filename,
          // msg: file.name,
        };
      }
    } catch (err) {
      props.loading(false);

      setIfParsed(false);
      setOpenDialog(true);
      setDialogContent(`Błąd przesyłania pliku.`);
      setDialogColor("error");
      return {};
    }
  };

  const importFvFile = () => {
    setOpenModalImport(true);
  };

  const handleFileSendApi = () => {
    files.length > 0 && fetchFile(files);
  };

  useEffect(() => {
    if (parsedDataData) {
      let tempRow = [];
      for (let i = 0; parsedDataData.length > i; i++) {
        tempRow.push({
          invoice_no: parsedDataData[i].invoice_no,
          ts_payment: parsedDataData[i].ts_payment,
          value_brutto: Number(
            parsedDataData[i].value_brutto.replace(",", ".")
          ),
          advance: Number(parsedDataData[i].advance.replace(",", ".")),
          payment: Number(parsedDataData[i].payment.replace(",", ".")),
        });
      }

      setTableData(tempRow);
    }
  }, [parsedDataData]);

  const allCorrections = fullTable.filter((inv) => inv.correction_invoice_id);

  const correctionsForRow = (row) => {
    return allCorrections.filter(
      (correction) => correction.correction_invoice_id === row.id
    );
  };

  const getNettoCorrection = (row) => {
    if (!row || !row.correction_services || !row.services) {
      return null;
    }
    let diffSum = 0;
    row.correction_services.forEach((correctionService) => {
      const correspondingService = row.services.find(
        (service) => service.id === correctionService.id_service_reference
      );
      if (correspondingService) {
        const roznica =
          correctionService.value_netto - correspondingService.value_netto;
        diffSum += roznica;
      }
    });
    return diffSum;
  };

  const getBruttoCorrection = (row) => {
    if (!row || !row.correction_services || !row.services) {
      console.error("Niepoprawny format obiektu.");
      return null;
    }
    let diffSumBrutto = 0;
    row.correction_services.forEach((correctionService) => {
      const correspondingService = row.services.find(
        (service) => service.id === correctionService.id_service_reference
      );
      if (correspondingService) {
        const roznicaBrutto =
          correctionService.value_brutto - correspondingService.value_brutto;
        diffSumBrutto += roznicaBrutto;
      }
    });
    return diffSumBrutto;
  };

  return (
    <>
      <div className={styles.root}>
        <Paper className={styles.paper}>
          <Title
            title={props.state.tabName}
            btnBox
            btnAdd
            addFunc={(e) => {
              props.addTab("InvoiceAddNew");
            }}
            btnDelete={selected.length > 0 ? true : "disabled"}
            deleteFunc={(e) => {
              setModalContent(table.filter((e) => selected.includes(e.id)));

              ///to do dokończyć delete

              setOpenModalDelete(true);
            }}
            selectDepartmentFunc={(e) => {
              setSelectedDepartment(e.value);
              return e;
            }}
            selectDepartment={options}
            selectFvLangFunc={(e) => {
              setSelectedFvLanguage(e.value);
              return e;
            }}
            selectFvLang={optionsFvLang}
            btnImportFv
            importFuncFv={(e) => {
              importFvFile();
            }}
          />
          <TableContainer className={styles.stickyTable}>
            <Table
              className={styles.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                styles={styles}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={table.length}
              />
              <TableBody className={styles.tabBody}>
                <TableRow>
                  <TableCell></TableCell>
                  {setSearchInvoiceNr}
                  {setSearchType}
                  {setSearchOrder}
                  {setSearchClient}
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <div className={`${styles.inp} multi_selector`}>
                      {/* <label htmlFor={"doc_type"}>Wybierz rodzaj dokumentu</label> */}
                      <Select
                        value={currencyFilter}
                        className={`${styles.select}`}
                        options={currencyFilterOptions}
                        onChange={(e) => {
                          setCurrencyFilter(e);
                        }}
                        name="currency"
                        placeholder="Waluta"
                        getOptionValue={(option) => `${option.label}`}
                        styles={select_styles}
                        menuPortalTarget={document.body}
                      />
                    </div>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  {/* {setSearchMadeBy} */}
                  <TableCell></TableCell>
                  {setSearchTags}
                  <TableCell></TableCell>
                </TableRow>
                {search(filterCurrencies(sortedTable, currencyFilter))
                  .filter((inv) => !inv.correction_invoice_id)
                  .map((row, index) => {
                    // {stableSort(search(table), getComparator(order, orderBy))
                    // 	.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    // 	.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const rowCurrency = exchangeRates
                      ? exchangeRates.find((rate) => rate.code === row.currency)
                      : [];
                    return (
                      <>
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          style={{
                            backgroundColor: row.is_deleted
                              ? "rgba(4, 4, 4, 0.08)"
                              : "inherit",
                          }}
                        >
                          <TableCell
                            style={{
                              padding: "0",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              flexDirection: "column",
                              height: "75px",
                            }}
                          >
                            <Checkbox
                              checked={isItemSelected}
                              color="primary"
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                            {allCorrections
                              .map((c) => c.correction_invoice_id)
                              .includes(row.id) && (
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  openCollapse === row.id
                                    ? setOpenCollapse(null)
                                    : setOpenCollapse(row.id);
                                }}
                              >
                                {openCollapse === row.id ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            )}
                          </TableCell>
                          <TableCell align="left">{handleType(row)}</TableCell>
                          <TableCell align="left">
                            {getType(row.type)}
                          </TableCell>
                          <TableCell align="right">
                            {`${row.orders?.[0]?.order_no}`}
                          </TableCell>
                          <TableCell align="right">
                            {row.contractor?.shortname}
                          </TableCell>
                          <TableCell align="right">
                            {row.date_service?.substring(11, 0)}
                          </TableCell>
                          <TableCell align="right">
                            {row.date_of_correction?.substring(11, 0) ||
                              row.date_of_issue?.substring(11, 0)}
                          </TableCell>
                          <TableCell align="right">
                            {getNetto(row.services, row.correction_services)}{" "}
                            {/* {sumValueNetto(row.correction_services ? row.correction_services : row.services)}{" "} */}
                            {row.currency}
                          </TableCell>
                          <TableCell align="right">
                            {!!row.value_brutto
                              ? row.value_brutto
                              : getBrutto(row.services)}{" "}
                            {row.currency}
                            {showCurrPrice && row.currency !== "PLN" && (
                              <div>
                                (
                                {!!row.value_brutto
                                  ? parseFloat(
                                      Number(row.value_brutto) *
                                        Number(rowCurrency?.value)
                                    ).toFixed(2)
                                  : parseFloat(
                                      getBrutto(row.services) *
                                        Number(rowCurrency?.value)
                                    ).toFixed(2)}{" "}
                                PLN)
                              </div>
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {row.payment_deadline?.substring(11, 0)}
                          </TableCell>
                          <TableCell align="right">
                            {row.payment === null ? "0" : row.payment}/
                            {!!row.value_brutto
                              ? row.value_brutto
                              : getBrutto(row.services)}{" "}
                            {row.currency}
                          </TableCell>
                          {/* <TableCell align="right">{row.signature}</TableCell> */}
                          <TableCell align="left">
                            {!correctedInvoicesIDs.includes(row.id) ? (
                              <StatusInvoice
                                type={row.type}
                                deadline={row.payment_deadline}
                                paid={Number(row.payment) + Number(row.advance)}
                                value={
                                  row.value_brutto || getBrutto(row.services)
                                }
                              />
                            ) : (
                              "Skorygowana"
                            )}
                          </TableCell>
                          <TableCell>{handleShowTags(row)}</TableCell>
                          <TableCell align="left">
                            <div className={styles.buttonBoxAlt}>
                              <div style={{ display: "flex", gap: "3px" }}>
                                {!row.is_deleted && (
                                  <Button
                                    variant="contained"
                                    disabled={
                                      row.value_brutto !== null
                                        ? row.payment === row.value_brutto
                                        : parseFloat(row.payment) ===
                                          getBrutto(row.services)
                                    }
                                    size="small"
                                    color="primary"
                                    className={BTNstyles.Payment}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setModalContent([row]);
                                      setPartPayment(true);
                                      setOpenPartPaymentModal(true);
                                    }}
                                  >
                                    <div className={BTNstyles.PaymentContent}>
                                      <Disc className={BTNstyles.PaymentIcon} />{" "}
                                      Część
                                    </div>
                                  </Button>
                                )}
                                {!row.is_deleted && (
                                  <Button
                                    variant="contained"
                                    disabled={row.payment > 0}
                                    size="small"
                                    color="primary"
                                    className={BTNstyles.Payment}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setModalContent([row]);
                                      setFullPayment(true);
                                      setOpenFullPaymentModal(true);
                                    }}
                                  >
                                    <div className={BTNstyles.PaymentContent}>
                                      <Target
                                        className={BTNstyles.PaymentIcon}
                                      />{" "}
                                      Całość
                                    </div>
                                  </Button>
                                )}
                              </div>
                              <div style={{ display: "flex", gap: "3px" }}>
                                <IcoBtn
                                  icon="Mail"
                                  tooltip="Wyślij fakturę mailem"
                                  value={row}
                                  onClick={() => {
                                    setOpenModalEmailRecipient(true);
                                    setEmailRecipientModalData(row);
                                  }}
                                />
                                {!row.is_deleted && row.type !== 3 && (
                                  <>
                                    {row.type !== 4 && row.type !== 5 && (
                                      <Edit2Btn
                                        tooltip="Wystaw Korekte"
                                        handleChange={(e) => {
                                          editInvoice(row.id, 3);
                                        }}
                                      />
                                    )}
                                    {!row.is_deleted && (
                                      <EditBtn
                                        tooltip="Edytuj"
                                        handleChange={(e) => {
                                          editInvoice(row.id, row.type);
                                        }}
                                      />
                                    )}
                                  </>
                                )}
                                <Tooltip title="PDF">
                                  <Button
                                    className={`${BTNstyles.IconPlusLabel} ${
                                      selectedFvLanguage !== null
                                        ? BTNstyles.IconWithLabel
                                        : ""
                                    }`}
                                    onClick={(e) => {
                                      printPDF(row.id, selectedFvLanguage);
                                    }}
                                  >
                                    <File />
                                    {selectedFvLanguage !== null && (
                                      <img
                                        src={
                                          images[
                                            `${renderFlag(
                                              selectedFvLanguage === "EN"
                                                ? "GB"
                                                : selectedFvLanguage
                                            )}`
                                          ]
                                        }
                                        alt={selectedFvLanguage}
                                      ></img>
                                    )}
                                    {/* {selectedFvLanguage !== null && <label>{selectedFvLanguage}</label>} */}
                                  </Button>
                                </Tooltip>
                                {/* <FileBtn tooltip="PDF" handleChange={e => {
																printPDF(row.id, selectedFvLanguage)
															}}/> */}
                                <IcoBtn
                                  icon="X"
                                  tooltip="Usuń"
                                  className="btn-delete"
                                  value={row}
                                  onClick={(e) => {
                                    setModalContent([row]);
                                    setOpenModalDelete(true);
                                  }}
                                />
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                        {allCorrections
                          .map((c) => c.correction_invoice_id)
                          .includes(row.id) && (
                          <>
                            {openCollapse === row.id && (
                              <TableRow>
                                <TableCell
                                  style={{ paddingBottom: 0, paddingTop: 0 }}
                                  colSpan={1}
                                />
                                <TableCell
                                  style={{ paddingBottom: 0, paddingTop: 0 }}
                                  colSpan={13}
                                >
                                  <Collapse
                                    in={openCollapse === row.id}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    {/* <Box margin={1}> */}
                                    <Table
                                      size="small"
                                      className={styles.collapseTable}
                                    >
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Numer korekty</TableCell>
                                          <TableCell>
                                            Faktura korygowana
                                          </TableCell>
                                          <TableCell>Zlecenie</TableCell>
                                          <TableCell>Data sprzedaży</TableCell>
                                          <TableCell>
                                            Data wystawienia
                                          </TableCell>
                                          <TableCell>Wartość netto</TableCell>
                                          <TableCell>Wartość brutto</TableCell>
                                          <TableCell>
                                            Termin płatności
                                          </TableCell>
                                          <TableCell>Kwota opłacona</TableCell>
                                          <TableCell>Status</TableCell>
                                          <TableCell>Tagi</TableCell>
                                          <TableCell></TableCell>
                                          <TableCell></TableCell>
                                          <TableCell></TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {correctionsForRow(row)
                                          .sort(
                                            (a, b) =>
                                              a.is_deleted - b.is_deleted
                                          )
                                          .map((e) => {
                                            return (
                                              <>
                                                <TableRow
                                                  key={e.id}
                                                  style={{
                                                    backgroundColor:
                                                      e.is_deleted
                                                        ? "rgba(4, 4, 4, 0.08)"
                                                        : "inherit",
                                                  }}
                                                >
                                                  <TableCell
                                                    component="th"
                                                    scope="e"
                                                  >
                                                    {e.correction_no}
                                                  </TableCell>
                                                  <TableCell>
                                                    {e.invoice_no}
                                                  </TableCell>
                                                  <TableCell>{`${e.orders?.[0]?.order_no}`}</TableCell>
                                                  <TableCell>
                                                    {e.date_service?.substring(
                                                      11,
                                                      0
                                                    )}
                                                  </TableCell>
                                                  <TableCell>
                                                    {e.date_of_correction?.substring(
                                                      11,
                                                      0
                                                    ) ||
                                                      e.date_of_issue?.substring(
                                                        11,
                                                        0
                                                      )}
                                                  </TableCell>
                                                  <TableCell>{`${getNettoCorrection(
                                                    e
                                                  )} ${e.currency}`}</TableCell>
                                                  <TableCell>{`${getBruttoCorrection(
                                                    e
                                                  ).toFixed(2)} ${
                                                    e.currency
                                                  }`}</TableCell>
                                                  <TableCell>
                                                    {e.payment_deadline?.substring(
                                                      11,
                                                      0
                                                    )}
                                                  </TableCell>
                                                  <TableCell>
                                                    {e.payment === null
                                                      ? "0"
                                                      : e.payment}
                                                    /
                                                    {getBruttoCorrection(
                                                      e
                                                    ).toFixed(2)}
                                                    {e.currency}
                                                  </TableCell>
                                                  <TableCell>
                                                    {!correctedInvoicesIDs.includes(
                                                      e.id
                                                    ) ? (
                                                      <StatusInvoice
                                                        type={e.type}
                                                        deadline={
                                                          e.payment_deadline
                                                        }
                                                        paid={
                                                          Number(e.payment) +
                                                          Number(e.advance)
                                                        }
                                                        value={
                                                          getBruttoCorrection(
                                                            e
                                                          ) ||
                                                          getBrutto(e.services)
                                                        }
                                                      />
                                                    ) : (
                                                      "Skorygowana"
                                                    )}
                                                  </TableCell>
                                                  <TableCell>
                                                    {handleShowTags(e)}
                                                  </TableCell>
                                                  <TableCell></TableCell>
                                                  <TableCell></TableCell>
                                                  <TableCell>
                                                    <div
                                                      className={
                                                        styles.buttonBoxAlt
                                                      }
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          gap: "3px",
                                                        }}
                                                      >
                                                        {!e.is_deleted && (
                                                          <Button
                                                            variant="contained"
                                                            disabled={
                                                              getBruttoCorrection(
                                                                e
                                                              ).toFixed(2) !==
                                                              null
                                                                ? e.payment ===
                                                                  getBruttoCorrection(
                                                                    e
                                                                  ).toFixed(2)
                                                                : parseFloat(
                                                                    e.payment
                                                                  ) ===
                                                                  getBrutto(
                                                                    e.services
                                                                  )
                                                            }
                                                            size="small"
                                                            color="primary"
                                                            className={
                                                              BTNstyles.Payment
                                                            }
                                                            onClick={() => {
                                                              setModalContent([
                                                                e,
                                                              ]);
                                                              setPartPayment(
                                                                true
                                                              );
                                                              setOpenPartPaymentModal(
                                                                true
                                                              );
                                                            }}
                                                          >
                                                            <div
                                                              className={
                                                                BTNstyles.PaymentContent
                                                              }
                                                            >
                                                              <Disc
                                                                className={
                                                                  BTNstyles.PaymentIcon
                                                                }
                                                              />{" "}
                                                              Część
                                                            </div>
                                                          </Button>
                                                        )}
                                                        {!e.is_deleted && (
                                                          <Button
                                                            variant="contained"
                                                            disabled={
                                                              e.payment > 0
                                                            }
                                                            size="small"
                                                            color="primary"
                                                            className={
                                                              BTNstyles.Payment
                                                            }
                                                            onClick={() => {
                                                              setModalContent([
                                                                e,
                                                              ]);
                                                              setFullPayment(
                                                                true
                                                              );
                                                              setOpenFullPaymentModal(
                                                                true
                                                              );
                                                            }}
                                                          >
                                                            <div
                                                              className={
                                                                BTNstyles.PaymentContent
                                                              }
                                                            >
                                                              <Target
                                                                className={
                                                                  BTNstyles.PaymentIcon
                                                                }
                                                              />{" "}
                                                              Całość
                                                            </div>
                                                          </Button>
                                                        )}
                                                      </div>
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          gap: "3px",
                                                        }}
                                                      >
                                                        <IcoBtn
                                                          icon="Mail"
                                                          tooltip="Wyślij fakturę mailem"
                                                          value={row}
                                                          onClick={() => {
                                                            setOpenModalEmailRecipient(
                                                              true
                                                            );
                                                            setEmailRecipientModalData(
                                                              row
                                                            );
                                                          }}
                                                        />
                                                        <Tooltip title="PDF">
                                                          <Button
                                                            className={`${
                                                              BTNstyles.IconPlusLabel
                                                            } ${
                                                              selectedFvLanguage !==
                                                              null
                                                                ? BTNstyles.IconWithLabel
                                                                : ""
                                                            }`}
                                                            onClick={() => {
                                                              printPDF(
                                                                e.id,
                                                                selectedFvLanguage
                                                              );
                                                            }}
                                                          >
                                                            <File />
                                                            {selectedFvLanguage !==
                                                              null && (
                                                              <img
                                                                src={
                                                                  images[
                                                                    `${renderFlag(
                                                                      selectedFvLanguage ===
                                                                        "EN"
                                                                        ? "GB"
                                                                        : selectedFvLanguage
                                                                    )}`
                                                                  ]
                                                                }
                                                                alt={
                                                                  selectedFvLanguage
                                                                }
                                                              ></img>
                                                            )}
                                                            {/* {selectedFvLanguage !== null && <label>{selectedFvLanguage}</label>} */}
                                                          </Button>
                                                        </Tooltip>
                                                        {/* <FileBtn tooltip="PDF" handleChange={e => {
																printPDF(e.id, selectedFvLanguage)
															}}/> */}
                                                        <IcoBtn
                                                          icon="X"
                                                          tooltip="Usuń"
                                                          className="btn-delete"
                                                          value={e}
                                                          onClick={() => {
                                                            setModalContent([
                                                              e,
                                                            ]);
                                                            setOpenModalDelete(
                                                              true
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  </TableCell>
                                                </TableRow>
                                              </>
                                            );
                                          })}
                                      </TableBody>
                                    </Table>
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            )}
                          </>
                        )}
                      </>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 33 * emptyRows }}>
                    <TableCell colSpan={13} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={styles.tableBottom}>
            <RowsPerPage
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              setPage={setPage}
              tableLength={fullTable.length}
            />

            <div>
              <Pagination
                postsPerPage={rowsPerPage}
                totalPosts={search(fullTable).length}
                paginate={paginate}
                page={page}
              />
            </div>
          </div>
        </Paper>

        <Dialog
          open={openModalDelete}
          TransitionComponent={Transition}
          onClose={() => {
            setOpenModalDelete(false);
            setModalContent([]);
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            className={stylesMod.dialogHead}
          >
            Czy napewno chcesz usunąć {modalContent ? modalContent.name : null}{" "}
            <IconButton
              aria-label="close"
              className={stylesMod.closeButton}
              onClick={() => {
                setOpenModalDelete(false);
                setModalContent([]);
              }}
            >
              <X />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Czy napewno chcesz usunąć Faktury
              {modalContent.map((e) => {
                return (
                  <>
                    <br /> {e.invoice_no}
                  </>
                );
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={BTNstyles.btn}
              onClick={(e) => {
                setOpenModalDelete(false);
                setModalContent([]);
              }}
            >
              Anuluj
            </Button>
            <Button
              variant="contained"
              size="large"
              className={`${BTNstyles.btn} ${BTNstyles.err}`}
              onClick={(e) => {
                delInvoices(modalContent.map((e) => e.id));
                setModalContent([]);
                setOpenModalDelete(false);
              }}
              color="primary"
            >
              Usuń <X className={BTNstyles.downBtnIco} />
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openFullPaymentModal}
          TransitionComponent={Transition}
          onClose={() => {
            setOpenFullPaymentModal(false);
            setModalContent([]);
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            className={stylesMod.dialogHead}
          >
            Całościowa opłata faktury
            <IconButton
              aria-label="close"
              className={stylesMod.closeButton}
              onClick={() => {
                setOpenFullPaymentModal(false);
                setModalContent([]);
              }}
            >
              <X />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Faktura opłacona w całości
              {modalContent.map((e) => {
                return (
                  <>
                    <br /> Numer faktury: {e.invoice_no}
                    <br /> Kwota:{" "}
                    {!!modalContent[0].value_brutto
                      ? modalContent[0].value_brutto + modalContent[0].currency
                      : getBruttoCorrection(modalContent[0].services) +
                        modalContent[0].currency}
                  </>
                );
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={BTNstyles.btn}
              onClick={(e) => {
                setOpenFullPaymentModal(false);
                setModalContent([]);
              }}
            >
              Anuluj
            </Button>
            <Button
              variant="contained"
              size="large"
              className={`${BTNstyles.btn} ${BTNstyles.grn}`}
              onClick={(e) => {
                handleInvoicePayment(
                  modalContent[0]?.id,
                  !!modalContent[0]?.value_brutto
                    ? modalContent[0].value_brutto
                    : getBruttoCorrection(modalContent[0].services)
                );
                setModalContent([]);
                setOpenFullPaymentModal(false);
              }}
              color="primary"
            >
              Zapłać
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openPartPaymentModal}
          TransitionComponent={Transition}
          onClose={() => {
            setOpenPartPaymentModal(false);
            setModalContent([]);
            setPartPaymentValue("");
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            className={stylesMod.dialogHead}
          >
            Częściowa opłata faktury
            <IconButton
              aria-label="close"
              className={stylesMod.closeButton}
              onClick={() => {
                setOpenPartPaymentModal(false);
                setModalContent([]);
              }}
            >
              <X />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {modalContent.map((e) => {
                return (
                  <>
                    <br />
                    Numer faktury: {e.invoice_no}
                    <br />
                    Wartość faktury:{" "}
                    {!!modalContent[0].value_brutto
                      ? modalContent[0].value_brutto + modalContent[0].currency
                      : getBruttoCorrection(modalContent[0].services) +
                        modalContent[0].currency}
                    <br />
                    Kwota opłacona:{" "}
                    <div className={styles.paymentCont}>
                      <Input
                        value={partPaymentValue}
                        max={
                          modalContent[0].value_brutto
                            ? modalContent[0].value_brutto
                            : getBruttoCorrection(modalContent[0].services)
                        }
                        className={styles.paymentVal}
                        handleChange={(e) => {
                          setPartPaymentValue(
                            e.target.value >= 0 ? e.target.value : 0
                          );
                        }}
                        type="number"
                      />
                      <Input
                        disabled
                        value={modalContent[0].currency}
                        className={styles.paymentCurr}
                      />
                    </div>
                  </>
                );
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={BTNstyles.btn}
              onClick={(e) => {
                setOpenPartPaymentModal(false);
                setModalContent([]);
                setPartPaymentValue("");
              }}
            >
              Anuluj
            </Button>
            <Button
              variant="contained"
              size="large"
              className={`${BTNstyles.btn} ${BTNstyles.grn}`}
              onClick={(e) => {
                handleInvoicePayment(modalContent[0]?.id, partPaymentValue);
                setModalContent([]);
                setOpenPartPaymentModal(false);
              }}
              color="primary"
            >
              Zapłać
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openModalImport}
          TransitionComponent={Transition}
          onClose={() => {
            setOpenModalImport(false);
            cleanStates();
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          maxWidth={"lg"}
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            className={stylesMod.dialogHead}
          >
            Importuj plik z listą opłaconych faktur
            <IconButton
              aria-label="close"
              className={stylesMod.closeButton}
              onClick={() => {
                setOpenModalImport(false);
                cleanStates();
              }}
            >
              <X />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid className={"col2"} container spacing={2}>
                  <Grid item xs={files.length > 0 ? 11 : 12}>
                    <input
                      accept=".xlsx, .xls, .csv"
                      style={{ display: "none" }}
                      id="raised-button-file"
                      // multiple
                      type="file"
                      ref={fileInput}
                      onChange={(e) => {
                        setFiles([e.target.files]);
                        setBtnSend(true);
                      }}
                    />
                    <label htmlFor="raised-button-file">
                      <Button
                        variant="outlined"
                        color="primary"
                        component="div"
                        className="fileBtn"
                      >
                        {files.length > 0
                          ? files[0][0]?.name
                          : "Wybierz pliki z komputera (.xlsx, .xls, .csv)..."}

                        <Button
                          onClick={() => {
                            setBtnSend(false);
                            cleanStates();
                          }}
                          style={
                            files.length > 0
                              ? { display: "flex" }
                              : { display: "none" }
                          }
                        >
                          <X />
                        </Button>
                      </Button>
                    </label>
                  </Grid>
                  {files.length > 0 ? (
                    <Grid item xs={1}>
                      <Button
                        onClick={handleFileSendApi}
                        color="secondary"
                        className={`${BTNstyles.btn} ${BTNstyles.grn}`}
                        style={
                          files.length > 0
                            ? {
                                display: "inline-flex",
                                margin: 0,
                                width: "100%",
                                maxHeight: "36px",
                              }
                            : {
                                display: "none",
                                margin: 0,
                                width: "100%",
                                maxHeight: "36px",
                              }
                        }
                      >
                        Wyślij plik
                        <UploadCloud
                          style={{ marginLeft: "10px", width: "18px" }}
                        />
                      </Button>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
              {parsedDataData && tableData.length > 0 ? (
                <Grid className={"col7"} item xs={12}>
                  <Grid container spacing={1}>
                    <Grid style={modalTabStyles} item component={"div"} xs={12}>
                      <TableContainer>
                        <Table
                          stickyHeader={true}
                          className={styles.table}
                          aria-labelledby="tableTitle"
                          aria-label="enhanced table"
                          size={"small"}
                        >
                          <TableHead className={styles.tabHead}>
                            <TableRow>
                              <TableCell className={"tabcell"}>
                                Numer faktury
                              </TableCell>
                              <TableCell className={"tabcell"}>
                                Data opłacenia faktury
                              </TableCell>
                              <TableCell className={"tabcell"}>
                                Kwota brutto
                              </TableCell>
                              <TableCell className={"tabcell"}>
                                Zaliczka
                              </TableCell>
                              <TableCell className={"tabcell"}>
                                Płatność
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {stableSort(
                              tableData,
                              getComparator(order, orderBy)
                            )
                              .slice(
                                pageModalTab * rowsPerPageModalTab,
                                pageModalTab * rowsPerPageModalTab +
                                  rowsPerPageModalTab
                              )
                              .map((row, index) => {
                                return (
                                  <TableRow
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={index}
                                  >
                                    <TableCell className={"tabcell"}>
                                      {row.invoice_no || "-"}
                                    </TableCell>
                                    <TableCell className={"tabcell"}>
                                      {row.ts_payment || "-"}
                                    </TableCell>
                                    <TableCell className={"tabcell"}>
                                      {row.value_brutto.toFixed(2)}
                                    </TableCell>
                                    <TableCell className={"tabcell"}>
                                      {row.advance.toFixed(2)}
                                    </TableCell>
                                    <TableCell
                                      className={`tabcell ${
                                        row.payment + row.advance ===
                                        row.value_brutto
                                          ? styles.summarise_col_ok
                                          : styles.summarise_col_err
                                      } `}
                                    >
                                      <div>
                                        {row.payment + row.advance ===
                                        row.value_brutto ? (
                                          <Check color="green" />
                                        ) : (
                                          <X color="red" />
                                        )}
                                        {row.payment.toFixed(2)}
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <div className={`${styles.tableBottom} modalTabPagin`}>
                        <RowsPerPage
                          rowsPerPage={rowsPerPageModalTab}
                          setRowsPerPage={setRowsPerPageModalTab}
                          setPage={setPageModalTab}
                          tableLength={tableData.length}
                          minRows={10}
                        />
                        <div>
                          <Pagination
                            postsPerPage={rowsPerPageModalTab}
                            totalPosts={tableData.length}
                            paginate={paginateModalTab}
                            page={pageModalTab}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </DialogContent>

          <DialogActions style={{ padding: "8px 13px" }}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={BTNstyles.btn}
              onClick={(e) => {
                setOpenModalImport(false);
                cleanStates();
              }}
            >
              Anuluj
            </Button>
            <Button
              variant="contained"
              size="large"
              style={
                ifParsed && tableData.length > 0
                  ? { display: "flex" }
                  : { display: "none" }
              }
              className={`${BTNstyles.btn} ${BTNstyles.grn}`}
              onClick={(e) => {
                fetchPaymentsDataFromFile(props.user.csrf_token, tableData);
              }}
              color="primary"
            >
              Importuj <Check className={BTNstyles.downBtnIco} />
            </Button>
          </DialogActions>
        </Dialog>
        <EmailRecipientModal
          open={openModalEmailRecipient}
          setOpen={setOpenModalEmailRecipient}
          loading={props.loading}
          token={props.user.csrf_token}
          data={emailRecipientModalData}
          setData={setEmailRecipientModalData}
          setOpenDialog={setOpenDialog}
          setDialogContent={setDialogContent}
          setDialogColor={setDialogColor}
        />
      </div>
      <Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={dialogColor}>
          {dialogContent}
        </Alert>
      </Snackbar>
    </>
  );
}
