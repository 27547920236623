import React, { useContext, useEffect, useState } from "react";
import { Modal, Box } from "@material-ui/core";
import { useMutation, useQuery } from "react-query";
import { ValueType } from "react-select";
import { Edit2 } from "react-feather";

import TmsSelect from "components/TmsSelect";
import TmsLoading from "components/TmsLoading";
import {
  parseSelectOptions,
  parseSelectOptionsWithoutDuplicates,
} from "components/TmsSelect/utils";

import {
  getBorderCrossings,
  postBorderCrossingsFavorites,
} from "api/endpoints";
import { UserContext } from "contexts/userContext";
import { filterCrossings, translateCountries } from "../utils";
import { BorderCrossing, SelectOption, SelectOptionState } from "types";

import "./styles.sass";

type Props = {
  isOpen: boolean;
  setOpen: (arg: boolean) => void;
  showDialogAndRefetch: (type: "success" | "error", content: string) => void;
  headerOrigin: SelectOptionState;
  headerDestination: SelectOptionState;
  favoritesCrossings?: BorderCrossing[];
  setHeaderOrigin: (origin: SelectOptionState) => void;
  setHeaderDestination: (origin: SelectOptionState) => void;
};

const CrossingModal = ({
  isOpen,
  setOpen,
  showDialogAndRefetch,
  headerOrigin,
  headerDestination,
  favoritesCrossings,
  setHeaderOrigin,
  setHeaderDestination,
}: Props) => {
  const [origin, setOrigin] = useState<SelectOptionState>(headerOrigin);
  const [destination, setDestination] =
    useState<SelectOptionState>(headerDestination);
  const [newFavoriteCrossing, setNewFavoriteCrossing] =
    useState<SelectOptionState>();

  const { user } = useContext(UserContext);

  const { data: borderCrossings, isLoading: borderCrossingsLoading } = useQuery<
    BorderCrossing[]
  >(["borderCrossings"], () => getBorderCrossings());

  const clearFavoriteCrossingSelect = () => setNewFavoriteCrossing(null);

  const handleClose = () => {
    setOpen(false);
    clearFavoriteCrossingSelect();
  };

  useEffect(() => {
    setOrigin(headerOrigin);
    setDestination(headerDestination);
  }, [headerOrigin, headerDestination, isOpen]);

  useEffect(() => {
    clearFavoriteCrossingSelect();
  }, [origin, destination]);

  const { mutate: postNewFavoriteCrossing } = useMutation(
    postBorderCrossingsFavorites,
    {
      onSuccess: () => {
        showDialogAndRefetch(
          "success",
          "Domyślne przejście graniczne zostało zaaktualizowane"
        );
        handleClose();
      },
      onError: (error: any) => {
        showDialogAndRefetch("error", error?.message);
      },
    }
  );

  const currentFavoriteCrossing =
    favoritesCrossings &&
    filterCrossings({ crossings: favoritesCrossings, origin, destination })[0];

  const filtredCrossings =
    borderCrossings &&
    filterCrossings({
      crossings: translateCountries(borderCrossings),
      origin,
      destination,
    });

  const favoriteCrossingSelectOptions =
    filtredCrossings &&
    parseSelectOptions({
      data: filtredCrossings.filter(
        (crossing) => crossing.id !== currentFavoriteCrossing?.id
      ),
      label: "name",
      value: "id",
    });

  const crossingFromSelectOptions =
    filtredCrossings &&
    parseSelectOptionsWithoutDuplicates({
      data: filtredCrossings,
      label: "origin",
      value: "origin",
      image: "origin_iso2",
    });

  const crossingToSelectOptions =
    filtredCrossings &&
    parseSelectOptionsWithoutDuplicates({
      data: filtredCrossings,
      label: "destination",
      value: "destination",
      image: "destination_iso2",
    });

  const handleSave = () => {
    const data = {
      id_border_crossing: Number(newFavoriteCrossing?.value),
      id_firm: user.id_firm,
      id_customer: user.customer_id,
      id_user: user.id,
    };

    if (!currentFavoriteCrossing) {
      postNewFavoriteCrossing({
        mode: "insert",
        data,
      });
    } else {
      postNewFavoriteCrossing({
        mode: "update",
        data: { ...data, id: currentFavoriteCrossing.id },
      });
    }
  };

  if (borderCrossingsLoading) return <TmsLoading />;

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box className="crossings-modal">
        <label>Ustaw przejście domyślne</label>
        <div className="crossings-modal__selects">
          <div className="crossings-header__filter">
            <label>Z:</label>
            <TmsSelect
              className="crossings-header__select"
              options={crossingFromSelectOptions}
              name="filter"
              onChange={(option: ValueType<SelectOption, false>) => {
                setOrigin(option);
                setHeaderOrigin(option);
              }}
              value={origin}
              image={{ path: "img/flags", extension: "gif" }}
            />
          </div>
          <div className="crossings-header__filter">
            <label>Do:</label>
            <TmsSelect
              className="crossings-header__select"
              options={crossingToSelectOptions}
              name="filter"
              onChange={(option: ValueType<SelectOption, false>) => {
                setDestination(option);
                setHeaderDestination(option);
              }}
              value={destination}
              image={{ path: "img/flags", extension: "gif" }}
            />
          </div>
        </div>
        {origin && destination && (
          <div>
            {currentFavoriteCrossing && (
              <div className="crossings-modal__favorite-crossing">
                <label>Domyślne przejście graniczne:</label>
                <p className="crossings-modal__favorite-crossing-name">
                  {currentFavoriteCrossing?.name}
                </p>
              </div>
            )}
            <div className="crossings-modal__change">
              <label>Ustaw domyślne przejście graniczne na:</label>
              <TmsSelect
                className="crossings-header__select"
                options={favoriteCrossingSelectOptions}
                name="filter"
                onChange={(option: ValueType<SelectOption, false>) =>
                  setNewFavoriteCrossing(option)
                }
                value={newFavoriteCrossing}
              />
            </div>
          </div>
        )}
        <div className="crossings-modal__buttons">
          <button
            className="crossings-modal__button"
            onClick={() => handleClose()}
          >
            Anuluj
          </button>
          <button
            disabled={newFavoriteCrossing === null}
            onClick={() => handleSave()}
            className="crossings-modal__button --save"
          >
            Zapisz <Edit2 className="crossings-modal__save-icon" />
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default CrossingModal;
