import React, { useState, useEffect, forwardRef } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  TableContainer,
  TableSortLabel,
  Checkbox,
  Paper,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Slide,
  Grid,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import Input from "../../components/input";
import { DBurl } from "../../appConfig";
import Title from "../../components/title";
import BTNstyles from "../../styles/button.module.css";
import styles from "../table.module.css";
import { X, Slash, Check } from "react-feather";
import RowsPerPage from "../../components/RowsPerPage";
import Pagination from "../../components/pagination";
import AddDepartment from "../../components/AddDepartment";
import DepartmentsAccList from "./DepartmentsAccList";
import IcoBtn from "../../components/buttons/icoBtn";
import stylesMod from "../../styles/newOrEdit.module.css";
import { useMutation, useQuery } from "react-query";
import {
  getCustomersDepartments,
  deleteCustomersDepartments,
} from "api/endpoints";
import TmsLoading from "components/TmsLoading";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  if (orderBy === "city") {
    return order === "desc"
      ? (a, b) => descendingComparator(a.address, b.address, orderBy)
      : (a, b) => -descendingComparator(a.address, b.address, orderBy);
  } else {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
}

function stableSort(array, comparator) {
  if (array?.length > 0) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
}

const headCells = [
  {
    id: "shortname",
    right: true,
    disablePadding: false,
    label: "Nazwa skrócona",
  },
  { id: "name", right: true, disablePadding: false, label: "Nazwa" },
  { id: "nip", right: true, disablePadding: false, label: "NIP" },
  { id: "city", right: true, disablePadding: false, label: "Miasto" },
  { id: "street", right: true, disablePadding: false, label: "Ulica" },
  { id: "comments", right: true, disablePadding: false, label: "Komentarz" },
  {
    id: "default",
    right: false,
    disablePadding: false,
    label: "Domyślny",
    class: styles.test,
  },
];

function EnhancedTableHead(props) {
  const {
    styles,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    setSearchName,
    setSearchNIP,
    setSearchAddressStreet,
    setSearchShortname,
    setSearchBankAccount,
    setSearchComment,
    setSearchAddressCity,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead className={styles.tabHead}>
        <TableRow>
          <TableCell className={styles.checkbox}>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all id" }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.right ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "default"}
              sortDirection={orderBy === headCell.id ? order : false}
              className={headCell.class}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={styles.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell className={styles.buttonBoxParent} />
        </TableRow>
      </TableHead>
      <TableHead className={styles.tabHead}>
        <TableRow className={styles.secondStickyRow}>
          <TableCell></TableCell>
          {setSearchShortname}
          {setSearchName}
          {setSearchNIP}
          {setSearchAddressCity}
          {setSearchAddressStreet}
          {/* {setSearchBankAccount} */}
          {setSearchComment}
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  styles: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function useInput({ type }) {
  const [value, setValue] = useState("");
  const input = (
    <TableCell>
      <Input
        value={value}
        handleChange={(e) => {
          setValue(e.target.value);
        }}
        type={type}
        search={true}
      />
    </TableCell>
  );
  return [value, input];
}

export default function EnhancedTable(props) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("shortname");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(16);
  const [table, setTable] = useState([]);
  const [searchShortname, setSearchShortname] = useInput({ type: "text" });
  const [searchName, setSearchName] = useInput({ type: "text" });
  const [searchNIP, setSearchNIP] = useInput({ type: "text" });
  const [searchAddressCity, setSearchAddressCity] = useInput({ type: "text" });
  const [searchAddressStreet, setSearchAddressStreet] = useInput({
    type: "text",
  });
  const [searchBankAccount, setSearchBankAccount] = useInput({ type: "text" });
  const [searchComment, setSearchComment] = useInput({ type: "text" });
  const [dataDepartment, setDataDepartment] = useState([]);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [modalContent, setModalContent] = useState([]);
  const [openModalAddDepartment, setOpenModalAddDepartment] = useState(false);
  const [openModalDepartmentAccounts, setOpenModalDepartmentAccounts] =
    useState(false);
  const [reload, setReload] = useState(false);

  const { refetch: refetchCustomersDepartments, isLoading } = useQuery(
    "getCustomersDepartments",
    getCustomersDepartments,
    {
      onSuccess: (data) => {
        setTable(data);
      },
      onError: (error) => {
        props.setDialog({
          isOpen: true,
          type: "error",
          content: `Nie można pobrać odziałów / ${error.message}`,
        });
      },
    }
  );

  const { mutate: deleteCustomersDepartment } = useMutation(
    "deleteCustomersDepartments",
    deleteCustomersDepartments,
    {
      onSuccess: () => {
        refetchCustomersDepartments();
        props.setDialog({
          isOpen: true,
          type: "success",
          content: "Oddział został usunięty",
        });
      },
      onError: (error) => {
        props.setDialog({
          isOpen: true,
          type: "error",
          content: `Nie udało się usunąć oddziału / ${error.message}`,
        });
      },
    }
  );

  const setDefaultDepartment = async (row) => {
    setReload(true);
    const response = await fetch(`${DBurl}/setDefaultCustomersDepartments`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "update",
        data: {
          id: parseInt(row?.id), // ID oddziału operatora
        },
      }),
    });
    setReload(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const search = (tbl) => {
    if (tbl?.length > 0) {
      return tbl.filter((row) => {
        return (
          String(row.shortname)
            .toLowerCase()
            .indexOf(searchShortname.toLowerCase()) > -1 &&
          String(row.name).toLowerCase().indexOf(searchName.toLowerCase()) >
            -1 &&
          String(row.nip).toLowerCase().indexOf(searchNIP.toLowerCase()) > -1 &&
          String(row.address?.city)
            .toLowerCase()
            .indexOf(searchAddressCity.toLowerCase()) > -1 &&
          String(row.address?.street)
            .toLowerCase()
            .indexOf(searchAddressStreet.toLowerCase()) > -1 &&
          String(row.bank_account_no)
            .toLowerCase()
            .indexOf(searchBankAccount.toLowerCase()) > -1 &&
          String(row.comments)
            .toLowerCase()
            .indexOf(searchComment.toLowerCase()) > -1
        );
      });
    }
  };

  const handleSelectAllClick = (event) => {
    if (table?.length > 0) {
      if (event.target.checked) {
        const newSelecteds = table.map((n) => n.id);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    } else {
      props.setDialog({
        isOpen: true,
        type: "warning",
        content: "Brak danych do zaznaczenia",
      });
    }
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const paginate = (pageNumber) => setPage(pageNumber - 1);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const deleteFunc = (e) => {
    setOpenModalDelete(true);
    const selectedArray = table.filter((e) => {
      if (selected.includes(e.id)) {
        return e;
      } else {
        return null;
      }
    });
    setModalContent(selectedArray);
  };
  const addFunc = () => {
    setDataDepartment(false);
    setOpenModalAddDepartment(true);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, (table && table.length) - page * rowsPerPage);

  const snackDepartment = () => {
    props.setDialog({
      isOpen: true,
      type: "success",
      content: "Oddział dodany/zapisany",
    });
    refetchCustomersDepartments();
  };

  return (
    <>
      <div className={styles.root}>
        <Paper className={styles.paper}>
          <Title
            title={props.state.tabName}
            btnBox={props.user.adm >= 1}
            btnAdd
            btnDelete={selected.length > 0 ? "ok" : "disabled"}
            deleteFunc={deleteFunc}
            addFunc={addFunc}
          />
          <TableContainer className={styles.stickyTable}>
            <Table
              className={styles.table}
              stickyHeader
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                styles={styles}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={table.length}
                setSearchShortname={setSearchShortname}
                setSearchName={setSearchName}
                setSearchNIP={setSearchNIP}
                setSearchAddressStreet={setSearchAddressStreet}
                setSearchBankAccount={setSearchBankAccount}
                setSearchComment={setSearchComment}
                setSearchAddressCity={setSearchAddressCity}
              />
              <TableBody className={styles.tabBody}>
                {stableSort(search(table), getComparator(order, orderBy))
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell className={styles.checkbox}>
                          <Checkbox
                            checked={isItemSelected}
                            color="primary"
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          align="right"
                        >
                          {row.shortname}
                        </TableCell>
                        <TableCell align="right">{row.name}</TableCell>
                        <TableCell align="right">{row.nip}</TableCell>
                        <TableCell align="right">{row.address?.city}</TableCell>
                        <TableCell align="right">
                          {row.address?.street}{" "}
                          {row.address?.street_no ? row.address.street_no : ""}{" "}
                          {row.address?.apartment_no
                            ? `/${row.address.apartment_no}`
                            : ""}
                        </TableCell>
                        <TableCell align="right">{row.comments}</TableCell>
                        <TableCell align="right">
                          {row.is_default ? (
                            <Tooltip title="Domyślny oddział">
                              <div className={BTNstyles.container}>
                                <Check className={BTNstyles.checkIcon} />
                              </div>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Ustaw jako domyślny">
                              <div className={BTNstyles.container}>
                                <Slash
                                  className={BTNstyles.slashIcon}
                                  onClick={(e) => {
                                    setDefaultDepartment(row);
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }}
                                />
                              </div>
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={styles.buttonBoxParent}
                        >
                          <div className={styles.buttonBox}>
                            <IcoBtn
                              icon="DollarSign"
                              tooltip="Dodaj konto"
                              value={row}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setDataDepartment(row);
                                setOpenModalDepartmentAccounts(true);
                              }}
                            />
                            <IcoBtn
                              icon="Edit"
                              tooltip="Edytuj"
                              value={row}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setDataDepartment(row);
                                setOpenModalAddDepartment(true);
                              }}
                            />
                            <IcoBtn
                              icon="X"
                              tooltip="Usuń"
                              className="btn-delete"
                              value={row}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setOpenModalDelete(true);
                                setModalContent([row]);
                              }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 33 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Dialog
            open={openModalDelete}
            TransitionComponent={Transition}
            onClose={() => {
              setOpenModalDelete(false);
              setModalContent([]);
            }}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle
              id="alert-dialog-slide-title"
              className={stylesMod.dialogHead}
            >
              Czy napewno chcesz usunąć{" "}
              {modalContent ? modalContent.name : null}{" "}
              <IconButton
                aria-label="close"
                className={stylesMod.closeButton}
                onClick={() => {
                  setOpenModalDelete(false);
                  setModalContent([]);
                }}
              >
                <X />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Czy napewno chcesz usunąć organizacje
                {modalContent.map((e) => {
                  return (
                    <>
                      <br /> {e.name}
                    </>
                  );
                })}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                size="large"
                color="primary"
                className={BTNstyles.btn}
                onClick={(e) => {
                  setOpenModalDelete(false);
                  setModalContent([]);
                }}
              >
                Anuluj
              </Button>
              <Button
                variant="contained"
                size="large"
                className={`${BTNstyles.btn} ${BTNstyles.err}`}
                onClick={(e) => {
                  let delArray = modalContent.map((e) => e.id);
                  deleteCustomersDepartment(delArray);
                  setModalContent([]);
                  setOpenModalDelete(false);
                }}
                color="primary"
              >
                Usuń <X className={BTNstyles.downBtnIco} />
              </Button>
            </DialogActions>
          </Dialog>

          {openModalAddDepartment && (
            <Dialog
              open={openModalAddDepartment}
              TransitionComponent={Transition}
              maxWidth="lg"
              onClose={() => {
                setOpenModalAddDepartment(false);
              }}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle
                id="alert-dialog-slide-title"
                className={stylesMod.dialogHead}
              >
                {!dataDepartment ? "Dodaj oddział" : "Edytuj oddział"}{" "}
                <IconButton
                  aria-label="close"
                  className={stylesMod.closeButton}
                  onClick={() => {
                    setOpenModalAddDepartment(false);
                  }}
                >
                  <X />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <AddDepartment
                    department={dataDepartment}
                    setOpen={setOpenModalAddDepartment}
                    parent_id={props.user.customer_id}
                    token={props.user.csrf_token}
                    add={!dataDepartment}
                    edit={!!dataDepartment}
                    customer
                    isSuccess={snackDepartment}
                    setDialog={props.setDialog}
                  />
                </Grid>
              </DialogContent>
            </Dialog>
          )}

          <Dialog
            open={openModalDepartmentAccounts}
            TransitionComponent={Transition}
            maxWidth="lg"
            onClose={() => {
              setOpenModalDepartmentAccounts(false);
            }}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle
              id="alert-dialog-slide-title"
              className={stylesMod.dialogHead}
            >
              {"Lista powiązanych kont"}

              <IconButton
                aria-label="close"
                className={stylesMod.closeButton}
                onClick={() => {
                  setOpenModalDepartmentAccounts(false);
                }}
              >
                <X />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <DepartmentsAccList
                  department={dataDepartment}
                  setOpen={setOpenModalDepartmentAccounts}
                  token={props.user.csrf_token}
                />
              </Grid>
            </DialogContent>
          </Dialog>

          <div className={styles.tableBottom}>
            <RowsPerPage
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              setPage={setPage}
              tableLength={table?.length}
            />
            <div>
              <Pagination
                postsPerPage={rowsPerPage}
                totalPosts={search(table)?.length}
                paginate={paginate}
                page={page}
              />
            </div>
          </div>
        </Paper>
      </div>
      {isLoading && <TmsLoading status="Pobieranie odziałów" />}
    </>
  );
}
