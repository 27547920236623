import React, { useState, useEffect, useRef } from "react";
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
} from "react-calendar-timeline/lib";
import "../../components/timetable.css";
import moment from "moment";
import { DBurl } from "../../appConfig";
import Title from "../../components/title";
import BTNstyles from "../../styles/button.module.css";
import { Search } from "react-feather";
import Button from "@material-ui/core/Button";
import { Add } from "@material-ui/icons";
import Pagination from "../../components/pagination";
import styles from "../../styles/timeTable.module.css";
import ItemsOnPage from "../../components/ItemsOnPage";
import OrderDialog from "./orderDialog";
import VacationDialog from "./vacationDialog";
import EditHoliday from "./editHoliday";
import "../../styles/flag.css";
import vacationStyles from "../../styles/vacation.module.css";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import OrderPreviewModal from "components/OrderPreviewModal";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function TimeTable(props) {
  // dane kierowców musze być przed danymi pobranymi z bazy - bez nich nie działa przesuwanie tablicyw prawo/lewo (cały czas cofa date) - nie wyjaśnione dlaczego
  const [vehicles, setVehicles] = useState([
    { id: 1, title: "Wczytuję" },
    { id: 2, title: "dane" },
  ]);
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchTitleValue, setSearchTitleValue] = useState("");
  // const [defaultTimeStart, setDefaultTimeStart] = useState(moment().add(0, 'hour'))
  // const [defaultTimeEnd, setDefaultTimeEnd] = useState(moment().add(14, 'days'))
  const [table, setTable] = useState([]);

  const defaultTimeStart = moment().add(0, "hour");
  const defaultTimeEnd = moment().add(14, "days");
  const [openOrder, setOpenOrder] = useState(false);
  const [orderData, setOrderData] = useState({});

  const [openSnack, setOpenSnack] = useState(false);
  const [snackContent, setSnackContent] = useState("");
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [modalDeleteData, setModalDeleteData] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");

  const [drivers, setDrivers] = useState([]);
  const [showDrivers, setShowDrivers] = useState(false);
  const [planVacation, setPlanVacation] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const [reload, setReload] = useState(false);
  const [editHoliday, setEditHoliday] = useState(false);
  const [driverToEdit, setDriverToEdit] = useState();
  const [holidayToEdit, setHolidayToEdit] = useState();
  const [previewOrderModal, setPreviewOrderModal] = useState({ isOpen: false });

  const isLoading = useRef(0);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const inputRef = useRef();

  const getCars = async function () {
    const response = await fetch(`${DBurl}/getCars/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      if (json.data.length > 0) {
        const tempCarNames = json.data.map((e, i) => {
          return {
            ...e,
            title: e.name,
          };
        });
        setVehicles(tempCarNames);
        setReload(false);
        isLoading.current -= 1;
      }
    }
  };

  const getOrders = async function () {
    const response = await fetch(`${DBurl}/getOrders/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();

    if (json.success) {
      if (json.data.length > 0) {
        const tempData = json.data.map((e, i) => {
          const start = moment(e.locations[0] && e.locations[0].ts);
          const end = moment(
            e.locations[e.locations.length - 1] &&
              e.locations[e.locations.length - 1].ts
          );
          return {
            ...e,
            start_time: moment(e.locations[0] && e.locations[0].ts),
            end_time: moment(
              e.locations[e.locations.length - 1] &&
                e.locations[e.locations.length - 1].ts
            ),
            group: showDrivers
              ? e.transport_set.id_driver1
              : e.transport_set.id_car,
            title: e.order_no,
            itemProps: {
              urlop: false,
            },
          };
        });
        const secondDriverData = json.data
          .filter((data) => data.transport_set.id_driver2 !== "")
          .map((e, i) => {
            const start = moment(e.locations[0] && e.locations[0].ts);
            const end = moment(
              e.locations[e.locations.length - 1] &&
                e.locations[e.locations.length - 1].ts
            );
            return {
              ...e,
              id: `${e.id}---double`,

              start_time: moment(e.locations[0] && e.locations[0].ts),
              end_time: moment(
                e.locations[e.locations.length - 1] &&
                  e.locations[e.locations.length - 1].ts
              ),
              group: e.transport_set.id_driver2,
              title: e.order_no,
              itemProps: {
                urlop: false,
              },
            };
          });
        setOrders(secondDriverData.concat(tempData));
      }
      setReload(false);
      isLoading.current -= 1;
    } else {
      isLoading.current -= 1;
    }
  };

  const getDrivers = async function () {
    const response = await fetch(`${DBurl}/getDrivers/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      let tempData = json.data.map((e) => {
        const title = e.is_master_driver
          ? `${e.firstname} ${e.surname}`
          : `${e.firstname} ${e.surname} ${e.driverid && `[${e.driverid}]`}`;
        return {
          ...e,
          title: title,
          label: `${e.firstname} ${e.surname}`,
          value: e.id,
        };
      });
      const driversWithHolidays = json.data.filter(
        (driver) => driver.drivers_holidays.length > 0
      );
      const driversHolidays = driversWithHolidays
        .map((driver) =>
          driver.drivers_holidays.map((holiday) => {
            const start_time = new Date(holiday.ts_start);
            const end_time = new Date(holiday.ts_end);
            start_time.setHours(0);
            start_time.setMinutes(0);
            start_time.setSeconds(1);
            end_time.setHours(23);
            end_time.setMinutes(59);
            end_time.setSeconds(59);

            return {
              ...holiday,
              group: driver.id,
              start_time: start_time,
              end_time: end_time,
              title: "Urlop",
              itemProps: {
                urlop: true,
              },
            };
          })
        )
        .flat();
      setDrivers(tempData.filter((driver) => driver.is_slave === false));
      setHolidays(driversHolidays);
      setReload(false);
      isLoading.current -= 1;
      return json;
    } else {
      isLoading.current -= 1;
    }
  };
  const indexOfLastPost = page * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;

  useEffect(() => {
    getCars();
    getOrders();
    getDrivers();
  }, [showDrivers, reload]);

  useEffect(() => {
    setTable(showDrivers ? drivers : vehicles);
  }, [vehicles, indexOfFirstPost, indexOfLastPost, drivers]);

  const paginate = (pageNumber) => setPage(pageNumber);

  function search(rows) {
    if (rows) {
      return rows.filter((row) => {
        if (row.title) {
          return (
            row.title.toString().toLowerCase().indexOf(searchTitleValue) > -1
          );
        } else {
          return null;
        }
      });
    } else {
      return null;
    }
  }

  const colorContrast = (rgb) => {
    rgb = rgb.split(/\(([^)]+)\)/)[1].replace(/ /g, "");
    var r = parseInt(rgb.split(",")[0], 10),
      g = parseInt(rgb.split(",")[1], 10),
      b = parseInt(rgb.split(",")[2], 10),
      a;

    if (rgb.split(",")[3] !== null) {
      a = parseInt(rgb.split(",")[3], 10);
    }
    var contrast =
      (Math.round(r * 299) + Math.round(g * 587) + Math.round(b * 114)) / 1000;
    return contrast >= 170 ? "black" : "white";
  };

  const itemRenderer = ({
    item,
    timelineContext,
    itemContext,
    getItemProps,
    getResizeProps,
  }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();

    const setStatusColor = (status) => {
      switch (status) {
        case 0:
          // Zakończone
          return { background: "#109010", font: "#fff" };
        case 1:
          // W trakcie
          return { background: "#ffff00", font: "#000" };
        case 2:
          // Wymaga uwagi
          return { background: "#ff0000", font: "#fff" };
        case 3:
          // Anulowane
          return { background: "#ff0000", font: "#fff" };
        case 4:
          // Rezerwacja
          return { background: "#000", font: "#fff" };
        case 5:
          // Szablon
          return { background: "#000", font: "#fff" };
        default:
          // Błąd
          return { background: "#ff0000", font: "#fff" };
      }
    };

    const { locations } = item;

    const itemBackgroundColor = setStatusColor(item.status).background;
    const itemColor = setStatusColor(item.status).font;

    if (locations && locations.length > 1) {
      const parts = [];
      let isDriverStatus = false;

      locations.forEach((location) => {
        const driverStatus = item.driver_status?.filter(
          (status) => status.id_location === location?.id
        );

        if (driverStatus.length > 0) {
          isDriverStatus = true;
        }

        const locationColor = () => {
          if (isDriverStatus) {
            return driverStatus.length > 0
              ? { background: "#109010", font: "#fff" }
              : { background: "#ff0000", font: "#fff" };
          }

          return { background: "#ffff00", font: "#000" };
        };

        const setLabelForSubtype = (val) => {
          switch (val) {
            case "parking":
              return { value: "parking", label: "Parking" };
            case "ferry":
              return { value: "ferry", label: "Prom" };
            case "start":
              return { value: "start", label: "Początek trasy" };
            case "end":
              return { value: "end", label: "Koniec trasy" };
            case "borderCrossing":
              return { value: "borderCrossing", label: "Przejście graniczne" };
            case "point":
              return { value: "point", label: "Punkt kontrolny" };
            case "refueling":
              return { value: "refueling", label: "Tankowanie" };
            case "loading":
              return { value: "loading", label: "Załadunek" };
            case "unloading":
              return { value: "unloading", label: "Rozładunek" };
            default:
              return { value: "point", label: "Punkt kontrolny" };
          }
        };

        const locationName = location.subtype
          ? setLabelForSubtype(location.subtype).label
          : location.type === "loading"
          ? "Załadunek"
          : "Rozładunek";

        parts.push(
          <div
            key={`${item.id}`}
            style={{
              backgroundColor: locationColor().background,
              color: locationColor().font,
              width: "100%",
              borderRight: "1px solid black",
              borderTop: "1px solid black",
              paddingRight: 5,
              paddingLeft: 5,
              fontSize: 11,
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            {locationName}
          </div>
        );
      });

      return (
        <div
          {...getItemProps({
            style: {
              backgroundColor: itemBackgroundColor,
              color: itemColor,
              borderRadius: 5,
              display: "flex",
              flexDirection: "column",
              height: itemContext.dimensions.height,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
          })}
          onMouseDown={() => {
            const order = orders.find((order) => order.id === item.id);
            setOrderData(order);
            setPreviewOrderModal({ isOpen: true, orderId: order.id });
          }}
        >
          {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
          <div style={{ textAlign: "center", margin: "0 auto", fontSize: 11 }}>
            {itemContext.title}
          </div>
          <div style={{ display: "flex" }}>{parts}</div>
          {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
        </div>
      );
    }

    return (
      <div
        {...getItemProps({
          style: {
            backgroundColor: itemBackgroundColor,
            color: itemColor,
            color: "#fff",
            borderRadius: 5,
          },
        })}
      >
        {item.title}
      </div>
    );

    // let baseColor = item?.tags?.length > 0 ? item?.tags[0]?.color : "#2196f3";

    // let colorTmpToRBG = baseColor.substring(1);

    // let aRgbHex = colorTmpToRBG.match(/.{1,2}/g);

    // let aRgb = [
    //   parseInt(aRgbHex[0], 16),
    //   parseInt(aRgbHex[1], 16),
    //   parseInt(aRgbHex[2], 16),
    // ];

    // let rbgColor = `rgb(${aRgb[0]} ${aRgb[1]} ${aRgb[2]} / 90%)`;

    // const backgroundColor = itemContext.selected
    //   ? rbgColor
    //     ? "orange"
    //     : "orange"
    //   : rbgColor;

    // const borderColor =
    //   baseColor !== "#ffffff"
    //     ? itemContext.selected
    //       ? rbgColor
    //         ? "orange"
    //         : "orange"
    //       : rbgColor
    //     : itemContext.selected
    //     ? "#c8c8c8"
    //       ? "orange"
    //       : baseColor
    //     : "#c8c8c8";

    // const color = colorContrast(
    //   String(`rgb(${aRgb[0]},${aRgb[1]},${aRgb[2]})`)
    // );

    // return (
    //   <>
    //     {item.itemProps.urlop ? (
    //       <div
    //         {...getItemProps({
    //           style: {
    //             backgroundColor: "#32CD32",
    //             color,
    //             borderRadius: 5,
    //           },
    //         })}
    //       >
    //         {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

    //         <div
    //           className={vacationStyles.stripes}
    //           style={{
    //             height: itemContext.dimensions.height,
    //             overflow: "hidden",
    //             paddingLeft: 3,
    //             textOverflow: "ellipsis",
    //             whiteSpace: "nowrap",
    //           }}
    //         >
    //           {itemContext.title}
    //         </div>

    //         {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
    //       </div>
    //     ) : (
    //       <div
    //         {...getItemProps({
    //           style: {
    //             backgroundColor,
    //             color,
    //             borderColor,
    //             borderStyle: "solid",
    //             borderWidth: 1,
    //             borderRadius: 4,
    //           },
    //         })}
    //       >
    //         {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

    //         <div
    //           style={{
    //             height: itemContext.dimensions.height,
    //             overflow: "hidden",
    //             paddingLeft: 3,
    //             textOverflow: "ellipsis",
    //             whiteSpace: "nowrap",
    //           }}
    //         >
    //           {itemContext.title}
    //         </div>

    //         {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
    //       </div>
    //     )}
    //   </>
    // );
  };

  useEffect(() => {
    isLoading.current = 2;
    props.loading(true);
  }, []);
  useEffect(() => {
    if (isLoading.current === 0) {
      props.loading(false);
    }
  }, [isLoading.current]);

  return (
    <>
      {showDrivers ? (
        <Title
          title={props.state.tabName}
          btnBox
          switch
          plan
          planFunc={() => setPlanVacation(true)}
          text={showDrivers ? "Kierowca/Pojazd" : "Pojazd/Kierowca"}
          switchFunc={() => {
            setShowDrivers((prevSetShowDrivers) => !prevSetShowDrivers);
          }}
        />
      ) : (
        <Title
          title={props.state.tabName}
          btnBox
          switch={drivers.length > 0 && orders.length > 0}
          text={showDrivers ? "Kierowca/Pojazd" : "Pojazd/Kierowca"}
          switchFunc={() => {
            setShowDrivers((prevSetShowDrivers) => !prevSetShowDrivers);
          }}
        />
      )}
      <Timeline
        groups={
          showDrivers
            ? drivers.length > 0
              ? search(drivers).slice(indexOfFirstPost, indexOfLastPost)
              : [{ id: 0, title: "Brak danych" }]
            : vehicles.length > 0
            ? search(vehicles).slice(indexOfFirstPost, indexOfLastPost)
            : [{ id: 0, title: "Brak danych" }]
        }
        items={showDrivers ? holidays.concat(orders) : orders}
        defaultTimeStart={defaultTimeStart}
        defaultTimeEnd={defaultTimeEnd}
        stackItems
        lineHeight={55}
        minZoom={7 * 24 * 60 * 60 * 1000}
        maxZoom={2 * 365.24 * 24 * 60 * 60 * 1000}
        canMove={false}
        canResize={false}
        sidebarWidth={showDrivers ? 350 : 220}
        itemRenderer={itemRenderer}
        timeSteps={{
          second: 60,
          minute: 60,
          hour: 24,
          day: 1,
          month: 1,
          year: 1,
        }}
        // onItemSelect={(i, a) => {
        //   if (a.target.textContent !== "Urlop") {
        //     const order = orders.find((e) => e.id === i);
        //     setOrderData(order);
        //     setPreviewOrderModal({ isOpen: true, orderId: order.id });
        //   }
        //   if (a.target.textContent === "Urlop") {
        //     const driverToEdit = drivers.find((driver) =>
        //       driver.drivers_holidays.some((holiday) => holiday.id === i)
        //     );
        //     const holidayToEdit = driverToEdit.drivers_holidays.find(
        //       (holiday) => holiday.id === i
        //     );
        //     setEditHoliday(true);
        //     setDriverToEdit({
        //       ...driverToEdit,
        //       label: `${driverToEdit.firstname} ${driverToEdit.surname}`,
        //       value: driverToEdit.id,
        //     });
        //     setHolidayToEdit(holidayToEdit);
        //   }
        // }}
      >
        <TimelineHeaders>
          {/* <div className="rctLeftHeader"> */}
          <SidebarHeader>
            {({ getRootProps }) => {
              return (
                <div
                  className={showDrivers ? "rctLeftHeaderAlt" : "rctLeftHeader"}
                >
                  <div {...getRootProps()} className="rctLeftLabel">
                    {showDrivers ? "Kierowca" : "Pojazd"}
                  </div>
                  <div className="rctSearchParent">
                    <input
                      type="text"
                      search
                      className="rctSearch"
                      value={searchTitleValue}
                      onChange={(e) => {
                        setSearchTitleValue(e.target.value.toLowerCase());
                        setTimeout(() => {
                          inputRef.current.focus();
                        }, 50);
                      }}
                      ref={inputRef}
                    />
                    <Search className="rctSearchIcon" />
                  </div>
                </div>
              );
            }}
          </SidebarHeader>
          <DateHeader labelFormat="YYYY.MM" />
          <DateHeader labelFormat="DD" />
        </TimelineHeaders>
      </Timeline>
      <div className={styles.tableBottom}>
        <ItemsOnPage
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          table={table}
        />
        <div>
          <Pagination
            postsPerPage={rowsPerPage}
            totalPosts={search(table).length}
            paginate={paginate}
          />
          {openOrder && (
            <OrderDialog
              data={orderData}
              open={openOrder}
              order_type_S={false}
              setOpen={setOpenOrder}
              addTab={props.addTab}
              user={props.user}
            />
          )}
          {previewOrderModal && (
            <OrderPreviewModal
              previewOrderModal={previewOrderModal}
              setPreviewOrderModal={setPreviewOrderModal}
              orderId={orderData.id}
              orderTitle={orderData.title}
              addTab={props.addTab}
              user={props.user}
            />
          )}
          {planVacation && (
            <VacationDialog
              // data={orderData}
              drivers={drivers}
              props={props}
              setSnack={setOpenSnack}
              setSnackContent={setSnackContent}
              setSnackColor={setDialogColor}
              reload={reload}
              setReload={setReload}
              open={planVacation}
              order_type_S={false}
              setOpen={setPlanVacation}
              addTab={props.addTab}
              user={props.user}
              isLoading={isLoading.current}
            />
          )}
          {editHoliday && (
            <EditHoliday
              data={orderData}
              drivers={drivers}
              props={props}
              setSnack={setOpenSnack}
              setSnackContent={setSnackContent}
              setSnackColor={setDialogColor}
              reload={reload}
              setReload={setReload}
              open={editHoliday}
              setOpen={setEditHoliday}
              addTab={props.addTab}
              user={props.user}
              isLoading={isLoading.current}
              driverToEdit={driverToEdit}
              holidayToEdit={holidayToEdit}
            />
          )}
        </div>
      </div>
      <div className={BTNstyles.btnRow}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={BTNstyles.btn}
          onClick={(e) => {
            props.addTab("newOrder");
          }}
        >
          Dodaj <Add className={BTNstyles.downBtnIco} />
        </Button>
      </div>

      <Snackbar open={openSnack} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={dialogColor}>
          {snackContent}
        </Alert>
      </Snackbar>
    </>
  );
}
