import React from 'react'
import styles from './StatusInvoice.module.css'

export default function StatusInvoice(props) {
	const [text, setText] = React.useState(<div className={styles.green}>Sprawdzam status...</div>)
	const value = 
	React.useEffect(()=>{
		const result = (Number(props.paid) === Number(props.value)? 0 : (
			new Date(props.deadline) > new Date() ? 1 : 2)
		)

		switch (result) {


			case 0: {
				setText(<div className={styles.green}>Opłacona</div>)
				break;
			}
			case 1: {
				setText(<div className={styles.yellow}>Do zapłaty</div>)
				break;
			}
			case 2: {
				setText(<div className={styles.red}>Po terminie</div>)
				break;
			}
			default :{
				console.log(`Błąd - brak statusu lub zły status - ${props.value}`)
				setText("Błąd statusu")
			}
		}
	},[props.value, props.paid,props.deadline])



	return (
		<>
		{text}
		</>
	)
}

