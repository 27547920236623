import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Input from "../../components/input";

import { DBurl, AppName } from "../../appConfig";
import Title from "../../components/title";
import styles from "../table.module.css";
import stylesMod from "../../styles/newOrEdit.module.css";
import BTNstyles from "../../styles/button.module.css";
import Button from "@material-ui/core/Button";

import RowsPerPage from "../../components/RowsPerPage";
import Pagination from "../../components/pagination";
import { X } from "react-feather";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Slide,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Grid,
} from "@material-ui/core";
import AddDriver from "./addDriver";
import MergeDriver from "./mergeDriver";
import SetMobileApp from "./SetMobileApp";

import IcoBtn from "../../components/buttons/icoBtn";
import { QRCodeSVG } from "qrcode.react";
import { useMutation, useQuery } from "react-query";
import { deleteDrivers, getDrivers } from "api/endpoints";
import TmsLoading from "components/TmsLoading";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (array?.length > 0) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
}

const headCells = [
  { id: "id", right: false, disablePadding: true, label: "LP." },
  {
    id: "name",
    right: false,
    disablePadding: true,
    label: "Imię i nazwisko",
    class: styles.nameBox,
  },
  { id: "email", right: true, disablePadding: false, label: "Email" },
  {
    id: "phone",
    right: true,
    disablePadding: false,
    label: "Nr tel.",
    class: styles.p10Box,
  },
  {
    id: "currentMedicalExam",
    right: true,
    disablePadding: false,
    label: "Ostatnie badanie",
  },
  {
    id: "nextMedicalExam",
    right: true,
    disablePadding: false,
    label: "Następne badanie",
  },
  {
    id: "expiryDriversCard",
    right: true,
    disablePadding: false,
    label: "Termin k. kierowcy",
  },
  { id: "categories", right: true, disablePadding: false, label: "Kategorie" },
  {
    id: "registered",
    right: true,
    disablePadding: false,
    label: "Zarejestrowany",
  },
  {
    id: "comment",
    right: true,
    disablePadding: false,
    label: "Komentarz",
    class: styles.qBox,
  },
];

function EnhancedTableHead(props) {
  const { styles, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={styles.tabHead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.right ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={headCell.class}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={styles.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell className={styles.buttonBoxParent} />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  styles: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function useInput({ type }) {
  const [value, setValue] = useState("");
  const input = (
    <TableCell>
      <Input
        value={value}
        handleChange={(e) => {
          setValue(e.target.value);
        }}
        type={type}
        search={true}
      />
    </TableCell>
  );
  return [value, input];
}

export default function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("short");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [table, setTable] = useState([]);
  const [searchName, setSearchName] = useInput({ type: "text" });
  const [searchEmail, setSearchEmail] = useInput({ type: "text" });
  const [searchComment, setSearchComment] = useInput({ type: "text" });
  const [searchCategories, setSearchCategories] = useInput({ type: "text" });
  const [driverData, setDriverData] = useState({});
  const [edit, setEdit] = useState(false);
  const [merge, setMerge] = useState(false);

  const [openSnack, setOpenSnack] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");

  const [deleteData, setDeleteData] = useState([]);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openMergeModal, setOpenMergeModal] = useState(false);
  const [openQRModal, setOpenQRModal] = useState(false);

  const [openSetMobileModal, setOpenSetMobileModal] = useState(false);
  const [openSetMobileData, setOpenSetMobileData] = useState({});
  const [slaveDrivers, setSlaveDrivers] = useState([]);
  const [notSlaveDrivers, setNotSlaveDrivers] = useState([]);
  const handleAddToSlaveDrivers = (newDriver) =>
    setSlaveDrivers((prevDrivers) => [...prevDrivers, newDriver]);
  const handleAddToNotSlaveDrivers = (newDriver) =>
    setNotSlaveDrivers((prevDrivers) => [...prevDrivers, newDriver]);
  const handleDeleteSlaveDrivers = (newDriver) =>
    setSlaveDrivers((prevDrivers) =>
      prevDrivers.filter((driver) => driver !== newDriver)
    );
  const handleDeleteNotSlaveDrivers = (newDriver) =>
    setNotSlaveDrivers((prevDrivers) =>
      prevDrivers.filter((driver) => driver !== newDriver)
    );
  const slaveFunctions = {
    handleAddToSlaveDrivers,
    handleAddToNotSlaveDrivers,
    handleDeleteSlaveDrivers,
    handleDeleteNotSlaveDrivers,
  };

  const renderAddButton = (id, is_slave) => {
    if (notSlaveDrivers.includes(id)) return true;
    if (slaveDrivers.includes(id) || is_slave) return false;
    return true;
  };

  const { refetch: refetchDrivers, isLoading: isDriversLoading } = useQuery(
    "getDrivers",
    getDrivers,
    {
      onSuccess: (data) => {
        const tempData = data.map((driver) => ({
          ...driver,
          name: `${driver.firstname} ${driver.surname} ${
            driver.driverid ? "[" + driver.driverid + "]" : ""
          }`,
        }));
        setTable(tempData);
      },
      onError: (error) => {
        props.setDialog({
          isOpen: true,
          type: "error",
          content: `Nie udało się pobrać kierowców / ${error.message}`,
        });
      },
    }
  );

  const { mutate: deleteDriver } = useMutation("deleteDrivers", deleteDrivers, {
    onSuccess: (data) => {
      props.setDialog({
        isOpen: true,
        type: "success",
        content: "Kierowca został usunięty",
      });
      refetchDrivers();
    },
    onError: (error) => {
      props.setDialog({
        isOpen: true,
        type: "error",
        content: `Nie udało się usunąć kierowcy / ${error.message}`,
      });
    },
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const search = (tbl) => {
    if (tbl?.length > 0) {
      return tbl.filter((row) => {
        return (
          String(row.name).toLowerCase().indexOf(searchName.toLowerCase()) >
            -1 &&
          String(row.email).toLowerCase().indexOf(searchEmail.toLowerCase()) >
            -1 &&
          String(row.comment)
            .toLowerCase()
            .indexOf(searchComment.toLowerCase()) > -1 &&
          String(
            checkCategories(
              row.driving_license_categories,
              row.drivers_permissions
            )
          )
            .toLowerCase()
            .indexOf(searchCategories.toLowerCase()) > -1
        );
      });
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = table.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const paginate = (pageNumber) => setPage(pageNumber - 1);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, table.length - page * rowsPerPage);

  const checkCategories = (cat, perm) => {
    const string = `${cat.toString()} ${perm.toString()}`;
    return string;
  };

  const moveToBottom = (array) => {
    const isSlave = array.filter((obj) => obj.is_slave);
    const noSlave = array.filter((obj) => !obj.is_slave);
    return [...noSlave, ...isSlave];
  };

  return (
    <>
      <div className={styles.rootFlex}>
        <div className={styles.tableBox}>
          <Paper className={styles.paper}>
            <Title
              title={props.state.tabName}
              btnBox
              btnAdd
              addFunc={() => {
                setEdit(false);
                setDriverData({});
                setOpenAddModal(true);
              }}
              btnMobileApp
              mobileAppFunc={setOpenQRModal}
            />
            <TableContainer className={styles.stickyTable}>
              <Table
                className={styles.table}
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  styles={styles}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={table.length}
                />

                <TableBody className={styles.tabBody}>
                  <TableRow>
                    <TableCell></TableCell>
                    {setSearchName}
                    {setSearchEmail}
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    {setSearchCategories}
                    <TableCell></TableCell>
                    {setSearchComment}
                    <TableCell></TableCell>
                  </TableRow>
                  {stableSort(
                    search(moveToBottom(table)),
                    getComparator(order, orderBy)
                  )
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          onClick={(event) => handleClick(event, row.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          className={row.is_slave ? styles.isSlaveDriver : ""}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.name}
                          </TableCell>
                          <TableCell align="right">{row.email}</TableCell>
                          <TableCell align="right">{row.phone}</TableCell>
                          <TableCell align="right">
                            {row.current_medical_exam}
                          </TableCell>
                          <TableCell align="right">
                            {row.next_medical_exam}
                          </TableCell>
                          <TableCell align="right">
                            {row.expiry_drivers_card}
                          </TableCell>
                          <TableCell align="right">
                            {checkCategories(
                              row.driving_license_categories,
                              row.drivers_permissions
                            )}
                          </TableCell>
                          <TableCell align="right">{row.registered}</TableCell>
                          <TableCell
                            align="right"
                            className={styles.commentBox}
                          >
                            {row.comment}
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.buttonBoxAlt}>
                              <div
                                className={styles.buttonBoxAltGrid}
                                style={
                                  props.user.adm !== null &&
                                  props.user.adm !== 0
                                    ? { gridTemplateColumns: "1fr 1fr 1fr 1fr" }
                                    : { gridTemplateColumns: "1fr" }
                                }
                              >
                                {props.user.adm !== null &&
                                  props.user.adm !== 0 && (
                                    <IcoBtn
                                      icon="Phone"
                                      tooltip={
                                        row.allow_mobile === false
                                          ? "Załóż konto"
                                          : "Edytuj Konto"
                                      }
                                      value={row}
                                      onClick={(e) => {
                                        setOpenSetMobileModal(true);
                                        setOpenSetMobileData(row);
                                      }}
                                      className={
                                        row.allow_mobile === false
                                          ? styles.mob_active_off
                                          : styles.mob_active_on
                                      }
                                    />
                                  )}

                                <IcoBtn
                                  icon="Edit"
                                  tooltip="Edytuj"
                                  value={row}
                                  className={
                                    row.active === 0
                                      ? styles.mob_active_off
                                      : ""
                                  }
                                  onClick={(e) => {
                                    setDriverData(row);
                                    setEdit(true);
                                    setOpenAddModal(true);
                                  }}
                                />

                                {props.user.adm !== null &&
                                  props.user.adm !== 0 && (
                                    <IcoBtn
                                      icon="X"
                                      tooltip="Usuń"
                                      value={row}
                                      className={`${
                                        row.driver_num_in_transport_sets > 0 &&
                                        styles.hidden
                                      } btn-delete`}
                                      onClick={(e) => {
                                        setDeleteData([row.id]);
                                        setOpenModalDelete(true);
                                      }}
                                    />
                                  )}

                                {props.user.adm !== null &&
                                  props.user.adm !== 0 &&
                                  renderAddButton(row.id, row.is_slave) && (
                                    <IcoBtn
                                      icon="Plus"
                                      tooltip="Połącz"
                                      value={row}
                                      onClick={(e) => {
                                        setDriverData(row);
                                        setMerge(true);
                                        setOpenMergeModal(true);
                                      }}
                                    />
                                  )}
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div className={styles.tableBottom}>
              <RowsPerPage
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setPage={setPage}
                tableLength={table?.length}
              />
              <div>
                <Pagination
                  page={page}
                  postsPerPage={rowsPerPage}
                  totalPosts={search(table)?.length}
                  paginate={paginate}
                />
              </div>
            </div>
          </Paper>
        </div>
        {openSetMobileModal && (
          <SetMobileApp
            open={openSetMobileModal}
            setOpen={setOpenSetMobileModal}
            data={openSetMobileData}
            user={props.user}
            fetchDrivers={refetchDrivers}
            table={table}
            setTable={setTable}
            setDialog={props.setDialog}
          />
        )}
        {openAddModal && (
          <AddDriver
            open={openAddModal}
            setOpen={setOpenAddModal}
            data={driverData}
            user={props.user}
            fetchDrivers={refetchDrivers}
            setOpenSnack={setOpenDialog}
            setSnackContent={setDialogContent}
            setSnackColor={setDialogColor}
            edit={edit}
            setDialog={props.setDialog}
          />
        )}
        {openMergeModal && (
          <MergeDriver
            open={openMergeModal}
            setOpen={setOpenMergeModal}
            data={driverData}
            slaveFunctions={slaveFunctions}
            user={props.user}
            fetchDrivers={refetchDrivers}
            setOpenSnack={setOpenDialog}
            setSnackContent={setDialogContent}
            setSnackColor={setDialogColor}
            merge={merge}
            setDialog={props.setDialog}
          />
        )}
        <Dialog
          open={openModalDelete}
          TransitionComponent={Transition}
          onClose={() => {
            setDeleteData([]);
            setOpenModalDelete(false);
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            className={stylesMod.dialogHead}
          >
            Czy napewno chcesz usunąć użytkownika?{" "}
            <IconButton
              aria-label="close"
              className={stylesMod.closeButton}
              onClick={() => {
                setDeleteData([]);
                setOpenModalDelete(false);
              }}
            >
              <X />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Czy napewno chcesz usunąć użytkownika:
              <br />{" "}
              {deleteData.length &&
                table.find((e) => e.id === deleteData[0]).name}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={BTNstyles.btn}
              onClick={(e) => {
                setOpenModalDelete(false);
              }}
            >
              Anuluj
            </Button>
            <Button
              variant="contained"
              size="large"
              className={`${BTNstyles.btn} ${BTNstyles.err}`}
              onClick={() => {
                deleteDriver(deleteData);
                setSelected([]);
                setOpenModalDelete(false);
                setDeleteData([]);
              }}
              color="primary"
            >
              Usuń <X className={BTNstyles.downBtnIco} />
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openQRModal}
          TransitionComponent={Transition}
          fullWidth={true}
          maxWidth={"xs"}
          onClose={() => {
            setOpenQRModal(false);
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            className={stylesMod.dialogHead}
          >
            {AppName} TMS Mobile{" "}
            <IconButton
              aria-label="close"
              className={stylesMod.closeButton}
              onClick={() => {
                setOpenQRModal(false);
              }}
            >
              <X />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Zeskanuj QR CODE i pobierz bezpłatną aplikację {AppName} TMS
              Mobile dla kierowcy
            </DialogContentText>
            <Grid container>
              <Grid item xs={12}>
                <div className={styles.qrcode}>
                  <QRCodeSVG size={280} value={`${DBurl}/getAppMobile/`} />
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={BTNstyles.btn}
              onClick={(e) => {
                setOpenQRModal(false);
              }}
            >
              Zamknij
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {isDriversLoading && <TmsLoading status="Pobieranie kierowców" />}
    </>
  );
}
