import React, { useState, useEffect } from "react";
import Title from "components/title";
import styles from "../../table.module.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Slide,
  Grid,
} from "@material-ui/core";
import stylesMod from "styles/invoice.module.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SummaryTable = ({
  exchangeRate,
  selectedCurrency,
  vat,
  services,
  handleNewTotalGross,
  documentType,
}) => {
  const [totalValues, setTotalValues] = useState({
    total: 0,
    totalGross: 0,
    totalVat: 0,
  });

  const getFilteredServices = (v) => services.filter((serv) => serv.vat === v);
  const getNetvalue = (filteredServices) =>
    filteredServices.reduce((total, current) => {
      return total + current.quantity * current.price_netto;
    }, 0);

  const getAllValues = () => {
    return services.reduce(
      (all, current) => {
        const { vat, price_netto, quantity } = current;
        const correctVat = vat === "NP" ? 0 : vat;
        const total = price_netto * quantity;
        const totalVat = (total * correctVat) / 100;
        const totalGross = total + totalVat;
        const newObj = {
          total: total + all.total,
          totalGross: totalGross + all.totalGross,
          totalVat: totalVat + all.totalVat,
        };
        return newObj;
      },
      { total: 0, totalGross: 0, totalVat: 0 }
    );
  };

  const getWartoscNetto = () => {
    if (totalValues.total === 0) return "";
    const netTotal = (exchangeRate[0]?.value * totalValues.total).toFixed(2);
    if (isNaN(totalValues.total) || isNaN(netTotal)) return "";
    return `${totalValues.total} ${selectedCurrency} x ${exchangeRate[0]?.value} PLN/${selectedCurrency} = ${netTotal} PLN`;
  };
  const getVat = () => {
    if (totalValues.totalVat === 0) return "";
    const netTotal = (exchangeRate[0]?.value * totalValues.totalVat).toFixed(2);
    if (isNaN(totalValues.totalVat) || isNaN(netTotal)) return "";
    return `${totalValues.totalVat} ${selectedCurrency} x ${exchangeRate[0]?.value} PLN/${selectedCurrency} = ${netTotal} PLN`;
  };
  const getVWartoscBrutto = () => {
    if (totalValues.totalGross === 0) return "";
    const netTotal = (exchangeRate[0]?.value * totalValues.totalGross).toFixed(
      2
    );
    if (isNaN(totalValues.totalGross) || isNaN(netTotal)) return "";
    return `${totalValues.totalGross} ${selectedCurrency} x ${exchangeRate[0]?.value} PLN/${selectedCurrency} = ${netTotal} PLN`;
  };

  useEffect(() => {
    const allValues = getAllValues();
    handleNewTotalGross(allValues.totalGross);
    setTotalValues(allValues);
  }, [services, vat]);

  return (
    <>
      {documentType?.value === undefined && (
        <div className={styles.root}>
          <Paper className={styles.paper}>
            <Title title={"Razem:"} />
            <TableContainer className={stylesMod.table}>
              <Table
                className={stylesMod.summaryTable}
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
              >
                <TableCell className={stylesMod.cell}></TableCell>
                <TableCell className={stylesMod.cell}>Wartość netto</TableCell>
                <TableCell className={stylesMod.cell}>Stawka VAT</TableCell>
                <TableCell className={stylesMod.cell}>Kwota VAT</TableCell>
                <TableCell className={stylesMod.cell}>Wartość brutto</TableCell>
                <TableBody className={styles.tabBody}>
                  <TableRow className={stylesMod.summaryRow}>
                    <TableCell className={stylesMod.cell}>Razem:</TableCell>
                    <TableCell className={stylesMod.cell}>
                      {isNaN(totalValues.total) ? "" : totalValues.total}
                    </TableCell>
                    <TableCell className={stylesMod.cell}></TableCell>
                    <TableCell className={stylesMod.cell}>
                      {isNaN(totalValues.totalVat)
                        ? ""
                        : Number(totalValues.totalVat).toFixed(2)}
                    </TableCell>
                    <TableCell className={stylesMod.cell}>
                      {isNaN(totalValues.totalGross)
                        ? ""
                        : Number(totalValues.totalGross).toFixed(2)}
                    </TableCell>
                  </TableRow>
                  {vat &&
                    vat.map((v) => {
                      const newV = isNaN(v) ? 0 : v;
                      const filtered = getFilteredServices(v);
                      const total = getNetvalue(filtered);
                      const totalVat = (total * newV) / 100;
                      const totalGross = total + totalVat;
                      return (
                        <TableRow>
                          <TableCell className={stylesMod.cell}>
                            W tym:
                          </TableCell>
                          <TableCell className={stylesMod.cell}>
                            {isNaN(total) ? "" : total}
                          </TableCell>
                          <TableCell className={stylesMod.cell}>
                            {isNaN(v) && v !== "NP"
                              ? ""
                              : v === "NP"
                              ? "NP"
                              : v}
                          </TableCell>
                          <TableCell className={stylesMod.cell}>
                            {isNaN(totalVat) ? "" : Number(totalVat).toFixed(2)}
                          </TableCell>
                          <TableCell className={stylesMod.cell}>
                            {isNaN(totalGross)
                              ? ""
                              : Number(totalGross).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {selectedCurrency && selectedCurrency !== "PLN" ? (
            <div>
              <Grid container xs={12}>
                <Grid className={stylesMod.cell} item xs={3}>
                  Wartość netto:
                </Grid>
                <Grid className={stylesMod.cellAlt} item xs={9}>
                  {getWartoscNetto()}
                </Grid>
              </Grid>
              <Grid container xs={12}>
                <Grid className={stylesMod.cell} item xs={3}>
                  Kwota VAT
                </Grid>
                <Grid className={stylesMod.cellAlt} item xs={9}>
                  {getVat()}
                </Grid>
              </Grid>
              <Grid container xs={12}>
                <Grid className={stylesMod.cell} item xs={3}>
                  Wartość brutto:
                </Grid>
                <Grid className={stylesMod.cellAlt} item xs={9}>
                  {getVWartoscBrutto()}
                </Grid>
              </Grid>
              <Grid container xs={12}>
                <Grid className={stylesMod.cell} item xs={3}></Grid>
                <Grid className={stylesMod.cellAlt} item xs={9}>
                  {`${exchangeRate[0]?.value} PLN/${exchangeRate[0]?.code} wg tabeli kursów średnich NBP nr ${exchangeRate[0]?.no} z dnia ${exchangeRate[0]?.date}`}
                </Grid>
              </Grid>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
      {documentType?.value === 1 && (
        <div className={styles.root}>
          <Paper className={styles.paper}>
            <Title title={"Razem:"} />
            <TableContainer className={stylesMod.table}>
              <Table
                className={stylesMod.summaryTable}
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
              >
                <TableCell className={stylesMod.cell}></TableCell>
                <TableCell className={stylesMod.cell}>Wartość netto</TableCell>
                <TableCell className={stylesMod.cell}>Stawka VAT</TableCell>
                <TableCell className={stylesMod.cell}>Kwota VAT</TableCell>
                <TableCell className={stylesMod.cell}>Wartość brutto</TableCell>
                <TableBody className={styles.tabBody}>
                  <TableRow className={stylesMod.summaryRow}>
                    <TableCell className={stylesMod.cell}>Razem:</TableCell>
                    <TableCell className={stylesMod.cell}>
                      {isNaN(totalValues.total) ? "" : totalValues.total}
                    </TableCell>
                    <TableCell className={stylesMod.cell}></TableCell>
                    <TableCell className={stylesMod.cell}>
                      {isNaN(totalValues.totalVat)
                        ? ""
                        : Number(totalValues.totalVat).toFixed(2)}
                    </TableCell>
                    <TableCell className={stylesMod.cell}>
                      {isNaN(totalValues.totalGross)
                        ? ""
                        : Number(totalValues.totalGross).toFixed(2)}
                    </TableCell>
                  </TableRow>
                  {vat &&
                    vat.map((v) => {
                      const newV = isNaN(v) ? 0 : v;
                      const filtered = getFilteredServices(v);
                      const total = getNetvalue(filtered);
                      const totalVat = (total * newV) / 100;
                      const totalGross = total + totalVat;
                      return (
                        <TableRow>
                          <TableCell className={stylesMod.cell}>
                            W tym:
                          </TableCell>
                          <TableCell className={stylesMod.cell}>
                            {isNaN(total) ? "" : total}
                          </TableCell>
                          <TableCell className={stylesMod.cell}>
                            {isNaN(v) && v !== "NP"
                              ? ""
                              : v === "NP"
                              ? "NP"
                              : v}
                          </TableCell>
                          <TableCell className={stylesMod.cell}>
                            {isNaN(totalVat) ? "" : Number(totalVat).toFixed(2)}
                          </TableCell>
                          <TableCell className={stylesMod.cell}>
                            {isNaN(totalGross)
                              ? ""
                              : Number(totalGross).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {selectedCurrency && selectedCurrency !== "PLN" ? (
            <div>
              <Grid container xs={12}>
                <Grid className={stylesMod.cell} item xs={3}>
                  Wartość netto:
                </Grid>
                <Grid className={stylesMod.cellAlt} item xs={9}>
                  {getWartoscNetto()}
                </Grid>
              </Grid>
              <Grid container xs={12}>
                <Grid className={stylesMod.cell} item xs={3}>
                  Kwota VAT
                </Grid>
                <Grid className={stylesMod.cellAlt} item xs={9}>
                  {getVat()}
                </Grid>
              </Grid>
              <Grid container xs={12}>
                <Grid className={stylesMod.cell} item xs={3}>
                  Wartość brutto:
                </Grid>
                <Grid className={stylesMod.cellAlt} item xs={9}>
                  {getVWartoscBrutto()}
                </Grid>
              </Grid>
              <Grid container xs={12}>
                <Grid className={stylesMod.cell} item xs={3}></Grid>
                <Grid className={stylesMod.cellAlt} item xs={9}>
                  {`${exchangeRate[0]?.value} PLN/${exchangeRate[0]?.code} wg tabeli kursów średnich NBP nr ${exchangeRate[0]?.no} z dnia ${exchangeRate[0]?.date}`}
                </Grid>
              </Grid>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};

export default SummaryTable;
