import React from "react";
import Select, { components } from "react-select";
import Input from "./input";
import stylesMod from "../styles/newOrEdit.module.css";
import { XCircle, PlusCircle, Download, Upload, Plus } from "react-feather";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import stylesVersion from "../styles/version_s.module.css";
import Tooltip from "@material-ui/core/Tooltip";

export default function AdvanceItem(props) {
  return (
    <>
      <Grid item xs={4}>
        <Tooltip title={props.user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={`${stylesMod.inp}`}>
            {props.propkey === 0 && (
              <label
                className={`${props.user_s ? stylesVersion.onlyfull : ""}`}
              >
                Zaliczka
              </label>
            )}
            <Input
              className={`${props.user_s ? stylesVersion.onlyfull : ""}`}
              disabled={props.user_s || props.isPreview}
              value={props.data.value}
              handleChange={(e) =>
                props.setAdvance(e.target.value, props.propkey)
              }
              name="saved"
              type="number"
            />
          </div>
        </Tooltip>
      </Grid>
      <Grid item xs={4}>
        <Tooltip title={props.user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={`${stylesMod.inp}`}>
            {props.propkey === 0 && (
              <label
                className={`${props.user_s ? stylesVersion.onlyfull : ""}`}
              >
                Waluta
              </label>
            )}
            <Select
              value={props.data.currency}
              isDisabled={props.user_s || props.isPreview}
              className={`${stylesMod.select} ${
                props.user_s ? stylesVersion.onlyfull : ""
              }`}
              options={props.currencyOption}
              onChange={(e) => {
                props.setCurrency(e, props.propkey);
              }}
              name="currency"
              placeholder="Wybierz..."
              styles={{
                control: (base, state) => ({
                  ...base,
                  border: "1px solid #142f42",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #142f42",
                  },
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused && "#142f42",
                  color: state.isFocused && "#fff",
                }),
              }}
            />
          </div>
        </Tooltip>
      </Grid>
      <Grid item xs={2}>
        <Tooltip title={props.user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={stylesMod.iconsBox2}>
            <IconButton
              disabled={props.user_s || props.propkey === 0 || props.isPreview}
              className={`${stylesMod.btnIcon} ${
                props.user_s || props.propkey === 0
                  ? stylesVersion.onlyfull
                  : ""
              }`}
              value={props.propkey}
              onClick={() => {
                props.removeAdvance(props.propkey);
              }}
            >
              <XCircle className={stylesMod.remove} />
            </IconButton>
            <IconButton
              disabled={props.user_s || props.isPreview}
              className={`${stylesMod.btnIcon} ${
                props.user_s ? stylesVersion.onlyfull : ""
              }`}
              onClick={props.addAdvance}
              value={props.propkey}
            >
              <PlusCircle className={stylesMod.add} />
            </IconButton>
          </div>
        </Tooltip>
      </Grid>
    </>
  );
}
