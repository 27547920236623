import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Slide,
  Grid,
} from "@material-ui/core";
import { X } from "react-feather";
import stylesMod from "styles/newOrEdit.module.css";
import BTNstyles from "styles/button.module.css";
import { TransitionProps } from "@material-ui/core/transitions";

const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

type Props = {
  openDialogClientDetails: boolean;
  setOpenDialogClientDetails: (openDialogClientDetails: boolean) => void;
  contractor: {
    name: string;
    nip: string;
    ideuvat: string;
    address: {
      apartment_no: string;
      city: string;
      country: string;
      district: string;
      street: string;
      street_no: string;
      zipcode: string;
    };
  };
};

const ContractorDetailsModal = ({
  openDialogClientDetails,
  setOpenDialogClientDetails,
  contractor,
}: Props) => {
  const showApartmentNo = () => {
    if (contractor?.address?.apartment_no === "") return;
    if (contractor?.address?.apartment_no === " ") return;
    if (contractor?.address?.apartment_no === null) return;
    if (contractor?.address?.apartment_no === undefined) return;
    else return `/${contractor?.address?.apartment_no}`;
  };

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={openDialogClientDetails}
        TransitionComponent={Transition}
        onClose={() => {
          setOpenDialogClientDetails(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Szczegóły klienta
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              setOpenDialogClientDetails(false);
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={3}>
              <b>Nazwa klienta:</b>
            </Grid>
            <Grid item xs={8}>
              {contractor.name ? contractor.name : "Brak danych"}
            </Grid>
            <Grid item xs={3}>
              <b>NIP:</b>
            </Grid>
            <Grid item xs={8}>
              {contractor.nip ? contractor.nip : "Brak danych"}
            </Grid>
            <Grid item xs={3}>
              <b>EUVAT:</b>
            </Grid>
            <Grid item xs={8}>
              {contractor.ideuvat ? contractor.ideuvat : "Brak danych"}
            </Grid>
            <Grid item xs={3}>
              <b>Adres:</b>
            </Grid>
            <Grid item xs={8}>
              {contractor?.address?.street} {contractor?.address?.street_no}
              {showApartmentNo()}
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={8}>
              {contractor?.address?.zipcode} {contractor?.address?.city}
            </Grid>
            <Grid item xs={3}>
              <b>Kraj:</b>
            </Grid>
            <Grid item xs={8}>
              {contractor?.address?.country}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            className={`${BTNstyles.btn} ${BTNstyles.grn}`}
            onClick={() => {
              setOpenDialogClientDetails(false);
            }}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ContractorDetailsModal;
