import React from "react";
import { Button } from "@material-ui/core";

type Props = {
  minNumber: number;
  value: number;
  tableLength: number;
  templateOn?: boolean;
  templatesLength: number;
  setRowsPerPage: (rows: number) => void;
  rowsPerPage: number;
};

const OrderPerPageButton = ({
  minNumber,
  value,
  tableLength,
  templateOn,
  templatesLength,
  setRowsPerPage,
  rowsPerPage,
}: Props) => {
  const dataLength = templateOn ? templatesLength : tableLength;
  const displayValue = dataLength > value ? value : dataLength;

  if (!(dataLength >= minNumber)) return <></>;

  return (
    <Button
      size={"small"}
      variant={"outlined"}
      value={value}
      onClick={() => {
        setRowsPerPage(displayValue);
      }}
    >
      {displayValue}
    </Button>
  );
};

export default OrderPerPageButton;
