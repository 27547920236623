import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import stylesMod from "../styles/newOrEdit.module.css";
import Input from "./input";
import { XCircle, PlusCircle, Download, Upload, Plus } from "react-feather";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import stylesVersion from "../styles/version_s.module.css";
import Tooltip from "@material-ui/core/Tooltip";

export default function Cargo(props) {
  const error = props.error;
  const [selectedValue, setSelectedValue] = useState(
    props.cargo.name ? props.cargo : ""
  );
  const [unit, setUnit] = useState(props.cargo.unit ? props.unit : "");
  const [selectedCurrency, setSelectedCurrency] = useState(
    props.cargo.name
      ? {
          label: props.cargo.currency,
          name: props.cargo.name,
        }
      : ""
  );
  const currencyOption = [
    { label: "PLN", value: "PLN" },
    { label: "EURO", value: "EUR" },
    { label: "CHF", value: "CHF" },
    { label: "FUNT", value: "GBP" },
    { label: "KORONA", value: "CZK" },
    { label: "RUBEL", value: "RUB" },
    { label: "HRYWNA", value: "UAH" },
  ];

  const ValueContainer = ({
    children,
    selectProps,
    secSelectProps,
    ...props
  }) => {
    return (
      <components.ValueContainer {...props}>
        <div className={stylesMod.clientAddParent}>
          <IconButton
            className={stylesMod.btnIcon}
            onClick={async () => {
              selectProps.selectProps.addtab(
                "Goods",
                false,
                selectProps.selectProps.saveTab()
              );
            }}
          >
            <Plus className={stylesMod.clientAdd} />
          </IconButton>
        </div>
        {children}
      </components.ValueContainer>
    );
  };

  useEffect(() => {
    if ((props.cargo.id, props.goodsOptions)) {
      const goodie =
        props.goodsOptions.find((e) => e.id === props.cargo.name) ||
        props.goodsOptions.find((e) => e.id === props.cargo.id);
      props.handleSelect(goodie, props.propKey, "name");
      setSelectedValue(goodie);
    }
  }, [props.cargo.name, props.cargo.id, props.goodsOptions]);

  useEffect(() => {
    if (props.cargo.currency) {
      const currencyItem = currencyOption.find(
        (e) => e.value == props.cargo.currency
      );
      props.handleSelect(currencyItem, props.propKey, "currency");
      setSelectedCurrency(currencyItem);
    }
  }, [props.cargo.currency]);

  useEffect(() => {
    if (props.unitOption.length) {
      setUnit(props.unitOption.find((e) => e.label === props.cargo.unit));
    }
  }, [props.cargo.unit, props.unitOption]);

  return (
    <>
      <Grid item xs={3}>
        <Tooltip title={props.user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={stylesMod.inp}>
            <Select
              isDisabled={props.user_s || props.checkedGoods === false}
              value={selectedValue}
              className={`${stylesMod.select} ${
                error && !selectedValue && stylesMod.error
              } ${
                props.user_s || props.checkedGoods === false
                  ? stylesVersion.onlyfull
                  : ""
              }`}
              options={props.goodsOptions}
              name="name"
              placeholder="Wybierz..."
              components={{ ValueContainer }}
              selectProps={{ addtab: props.addTab, saveTab: props.saveTab }}
              onChange={(e) => {
                props.handleSelect(e, props.propKey, "name");
                props.handleCargo(
                  { name: "unit", value: e.unit },
                  props.propKey
                );
                setSelectedValue(e);
                props.checkStatus();
              }}
              styles={{
                control: (base, state) => ({
                  ...base,
                  border: "1px solid #142f42",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #142f42",
                  },
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused && "#142f42",
                  color: state.isFocused && "#fff",
                }),
              }}
            />
          </div>
        </Tooltip>
      </Grid>
      <Grid item xs={1}>
        <Tooltip title={props.user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={stylesMod.inp}>
            <Input
              className={`${stylesMod.select} ${
                error && !selectedValue && stylesMod.error
              } ${
                props.user_s || props.checkedGoods === false
                  ? stylesVersion.onlyfull
                  : ""
              }`}
              disabled={props.user_s || props.checkedGoods === false}
              value={props.cargo.quantity}
              handleChange={(e) => {
                props.handleCargo(e, props.propKey);
                props.checkStatus();
              }}
              name="quantity"
              error={
                error &&
                (props.cargo.quantity === null ||
                  props.cargo.quantity === undefined ||
                  props.cargo.quantity === "" ||
                  props.statusError)
              }
              type="number"
            />
          </div>
        </Tooltip>
      </Grid>
      <Grid item xs={2}>
        <Tooltip title={props.user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={stylesMod.inp}>
            <Select
              isDisabled={props.user_s || props.checkedGoods === false}
              value={unit}
              className={`${stylesMod.select} ${
                error && !selectedValue && props.checkedGoods && stylesMod.error
              } ${props.user_s ? stylesVersion.onlyfull : ""}`}
              // className={`${stylesMod.select} ${
              //   props.user_s || props.checkedGoods === false
              //     ? stylesVersion.onlyfull
              //     : ""
              // }`}
              options={props.unitOption}
              name="unit"
              placeholder="Wybierz..."
              components={{ ValueContainer }}
              selectProps={{ addtab: props.addTab, saveTab: props.saveTab }}
              onChange={(e) => {
                props.handleSelect({ value: e.label }, props.propKey, "unit");
                setUnit(e);
                // props.handleCargo({ name: "unit", value: e.unit }, props.propKey)
                // setSelectedValue(e)
                props.checkStatus();
              }}
              styles={{
                control: (base, state) => ({
                  ...base,
                  border: "1px solid #142f42",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #142f42",
                  },
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused && "#142f42",
                  color: state.isFocused && "#fff",
                }),
              }}
            />
          </div>
        </Tooltip>
      </Grid>
      <Grid item xs={2}>
        <Tooltip title={props.user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={stylesMod.inp}>
            <Input
              className={`${
                props.user_s || props.checkedGoods === false
                  ? stylesVersion.onlyfull
                  : ""
              }`}
              disabled={props.user_s || props.checkedGoods === false}
              value={props.cargo.price}
              handleChange={(e) => props.handleCargo(e, props.propKey)}
              name="price"
              type="number"
              error={
                error &&
                (props.cargo.price === null ||
                  props.cargo.price === undefined ||
                  props.cargo.price === "")
              }
            />
          </div>
        </Tooltip>
      </Grid>
      <Grid item xs={2}>
        <Tooltip title={props.user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={stylesMod.inp}>
            <Select
              isDisabled={props.user_s || props.checkedGoods === false}
              className={`${stylesMod.select} ${
                error && !selectedValue && props.checkedGoods && stylesMod.error
              } ${props.user_s ? stylesVersion.onlyfull : ""}`}
              options={currencyOption}
              onChange={(e) => {
                props.handleSelect(e, props.propKey, "currency");
                setSelectedCurrency(e);
              }}
              name="currency"
              placeholder="Wybierz..."
              value={selectedCurrency}
              styles={{
                control: (base, state) => ({
                  ...base,
                  border: "1px solid #142f42",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #142f42",
                  },
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused && "#142f42",
                  color: state.isFocused && "#fff",
                }),
              }}
            />
          </div>
        </Tooltip>
      </Grid>
      <Grid item xs={2} className={stylesMod.flexC}>
        <Tooltip title={props.user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={stylesMod.iconsBox}>
            <IconButton
              disabled={props.user_s || props.checkedGoods === false}
              className={`${stylesMod.btnIcon} ${
                props.user_s || props.checkedGoods === false
                  ? stylesVersion.onlyfull
                  : ""
              }`}
              value={props.propKey}
              onClick={() => {
                props.removeCargo(props.propKey);
              }}
            >
              <XCircle className={stylesMod.remove} size={18} />
            </IconButton>
            <IconButton
              disabled={props.user_s || props.checkedGoods === false}
              className={`${stylesMod.btnIcon} ${
                props.user_s || props.checkedGoods === false
                  ? stylesVersion.onlyfull
                  : ""
              }`}
              onClick={props.addCargo}
              value={props.propKey}
            >
              <PlusCircle className={stylesMod.add} size={18} />
            </IconButton>
            <div className={stylesMod.iconsCol}>
              <div className={stylesMod.iconsRow}>
                <Download
                  disabled={props.user_s || props.checkedGoods === false}
                  className={`${
                    props.cargo.statusLoad ? stylesMod.done : stylesMod.undone
                  }  ${
                    props.user_s || props.checkedGoods === false
                      ? stylesVersion.onlyfull
                      : ""
                  }`}
                  size={18}
                />
                <Upload
                  disabled={props.user_s || props.checkedGoods === false}
                  className={`${
                    props.cargo.statusUnload ? stylesMod.done : stylesMod.undone
                  }  ${
                    props.user_s || props.checkedGoods === false
                      ? stylesVersion.onlyfull
                      : ""
                  }`}
                  size={18}
                />
              </div>
            </div>
          </div>
        </Tooltip>
      </Grid>
    </>
  );
}
