import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import stylesMod from "../styles/newOrEdit.module.css";
import Input from "./input";
import { IconButton } from "@material-ui/core";
import { DBurl } from "../appConfig";
import BTNstyles from "../styles/button.module.css";
import { X, Lock } from "react-feather";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PasswordChange(props) {
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState(false);

  const clear = () => {
    setPassword("");
    setNewPassword("");
  };

  const setNewPasswordUser = async (id) => {
    const response = await fetch(`${DBurl}/setNewPasswordUser`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "insert",
        data: {
          old_password: password,
          new_password: newPassword,
        },
      }),
    });

    const json = await response.json();
    if (json.success) {
      if (json.data.status) {
        props.setDialog({
          isOpen: true,
          type: "success",
          content: "Hasło zostało zmienione",
        });
        const killUsersToken = await fetch(
          `${DBurl}/destroyUserSessions?id_user=${id}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": props.user.csrf_token,
            },
          }
        );
        const killUsersTokenJson = await killUsersToken.json();
        if (killUsersTokenJson.success) {
          console.log(`Tokeny użytkownika o id: ${id} zostały wyłączone`);
        }

        clear();
        props.logout();
        props.setOpen(false);
      } else {
        props.setDialog({
          isOpen: true,
          type: "error",
          content: "Niepoprawne hasło",
        });
      }
    }
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth={true}
      open={props.open}
      TransitionComponent={Transition}
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        className={stylesMod.dialogHead}
      >
        Zmień hasło
        <IconButton
          aria-label="close"
          className={stylesMod.closeButton}
          onClick={() => {
            props.setOpen(false);
          }}
        >
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent className={stylesMod.noOverflow}>
        <Grid container spacing={2}></Grid>
        <Grid container spacing={2} className={stylesMod.centerWithM}>
          <Grid item xs={8}>
            <div className={`${stylesMod.inp}`}>
              <label>Stare hasło</label>
              <Input
                value={password}
                error={!password && error}
                handleChange={(e) => {
                  setPassword(e.target.value);
                }}
                name="password"
                type="password"
              />
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2} className={stylesMod.centerWithM}>
          <Grid item xs={8}>
            <div className={`${stylesMod.inp}`}>
              <label>Nowe hasło</label>
              <Input
                value={newPassword}
                error={!newPassword && error}
                handleChange={(e) => {
                  setNewPassword(e.target.value);
                }}
                name="newPassword"
                type="password"
              />
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2} className={stylesMod.centerWithM}>
          <Grid item xs={8}>
            <div className={`${stylesMod.inp}`}>
              <label>Powtórz hasło</label>
              <Input
                value={repeatPassword}
                error={!repeatPassword && error}
                handleChange={(e) => {
                  setRepeatPassword(e.target.value);
                }}
                name="repeatPassword"
                type="password"
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={stylesMod.mt20}>
          <Grid item xs={12}>
            <p className={`${stylesMod.warning} ${stylesMod.center}`}>
              Po zmianie hasła zostaniesz wylogowany
            </p>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="large"
          color="primary"
          className={`${BTNstyles.btn} ${BTNstyles.err}`}
          onClick={() => {
            clear();
            props.setOpen(false);
          }}
        >
          Anuluj
        </Button>
        <Button
          variant="contained"
          size="large"
          className={`${BTNstyles.btn} ${BTNstyles.grn}`}
          onClick={(e) => {
            if (newPassword === repeatPassword) {
              if (!!password && !!newPassword) {
                setNewPasswordUser(props.user.id);
              } else {
                setError(true);
                props.setDialog({
                  isOpen: true,
                  type: "error",
                  content: "Uzupełnij pola",
                });
              }
            } else {
              props.setDialog({
                isOpen: true,
                type: "error",
                content: "Nowa hasła nie są identyczne",
              });
            }
          }}
          color="primary"
        >
          Zmień hasło <Lock className={BTNstyles.downBtnIco} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
