import React from "react";
import styles from "./bar.module.css";

export default function Bar(props) {
  const [width, setWidth] = React.useState("0%");

  React.useEffect(() => {
    setTimeout(() => {
      setWidth(`${props.value}%`);
    }, 200);
  });

  const progressClass =
    props.color === "green"
      ? `${styles.progress} ${styles.green}`
      : props.color === "red"
      ? `${styles.progress} ${styles.red}`
      : `${styles.progress} ${styles.yellow}`;
  const barClass =
    props.color === "green"
      ? `${styles.bar} ${styles.green}`
      : props.color === "red"
      ? `${styles.bar} ${styles.red}`
      : `${styles.bar} ${styles.yellow}`;
  return (
    <div className={progressClass}>
      <div className={barClass} style={{ width: width }}>
        {props.value}%
      </div>
    </div>
  );
}
