import React, { useState, useEffect } from "react";
import Select from "react-select";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import stylesMod from "../../styles/newOrEdit.module.css";
import { DBurl } from "../../appConfig";
import BTNstyles from "../../styles/button.module.css";
import { Add, Edit } from "@material-ui/icons";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { X } from "react-feather";
import { IconButton } from "@material-ui/core";

import { Switch, FormControlLabel } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import styles from "./profile.module.css";
import InputNum from "../../components/input_num";

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#129865",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#129865",
  },
  "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
    backgroundColor: "#129865",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddDriver(props) {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");
  const [error, setError] = useState(false);

  const [bsoundenabled, setBsoundenabled] = useState(); //1, // Flag enabling/disabling sounds
  const [boperaterighthanded, setBoperaterighthanded] = useState(); //1, // Flag effecting Navigation appearance | 1 - Righthanded appearance, 0 - Lefthanded appearance
  const [nvolumemin, setNvolumemin] = useState(); //-----7, // Represents minimum sound volume (0-10)
  const [nvolumemax, setNvolumemax] = useState(); //--------10, // Represents maximum sound volume (0-10)
  const [distanceunit, setDistanceunit] = useState(); //-------1, // Flag determining distance units | 0 - Miles(yards), 1 - Kilometers, 2 - Miles(feet)
  const [clockformat, setClockformat] = useState(); //-------0, // Flag determining clock format | 0 - Europe (14:00), 1 - US (2:00), 2 - UK (2:00 PM)
  const [gpsunits, setGpsunits] = useState(); //0, // Flag determining GPSUnits format | 0 - Degrees (0.0°), 1 - Degrees; minutes (0°0.0'), 2 - Degrees; minutes; seconds (0°0'00")
  const [keyboardtype, setKeyboardtype] = useState(); //0, SELECT // Flag determining keyboard type | 1 - ABCD, 2 - QWERTY, 3 - AZERTY, 4 - DVORAK
  const [navoidtollroads, setNavoidtollroads] = useState(); //3, SELECT // Flag determining whether Navigation avoids toll roads when computing route | 1 - Ask to avoid toll roads, 2 - Always avoid toll roads, 3 - Never avoid toll roads
  const [bavoiduturns, setBavoiduturns] = useState(); //0, SELECT // Flag determining whether Navigation avoids U-turns when computing route | 1 - Ask to avoid U-turns, 2 - Always avoid U-turns, 3 - Never avoid U-turns
  const [nplanningsettings, setNplanningsettings] = useState(); //1, SELECT // Flag determining what kind of route Navigation computes | 1 - Navigation computes fastest route, 2 - Navigation computes shortest route, 3 - Navigation computes economical route
  const [nplanningsettingslimitedspeed, setNplanningsettingslimitedspeed] =
    useState(); //0, INPUT NUMBER // Ograniczenie prędkości pojazdu
  const [bavoidferries, setBavoidferries] = useState(); //0, SELECT // Flag determining whether Navigation avoids ferries when computing route | 1 - Ask to avoid ferries, 2 - Always avoid ferries, 3 - Never avoid ferries
  const [bdisablemainmenu, setBdisablemainmenu] = useState(); //0, CHECKBOX // Flag enabling/disabling main menu | 0 - main menu enabled, 1 - main menu disabled
  const [bdisablerecompute, setBdisablerecompute] = useState(); //0, CHECKBOX // Flag enabling/disabling recomputing route when deviating from the route.
  const [netamaximumspeed, setNetamaximumspeed] = useState(); //0, INPUT NUMBER // Estimate time arrival maximum speed
  const [netapercentagechange, setNetapercentagechange] = useState(); //0, INPUT NUMBER // Estimate time arrival percentage change
  const [bradarswarnon, setBradarswarnon] = useState(); //1, CHECKBOX // Enable speed camera warning 1 = enable; 0 = disable; -1 = get value.
  const [bradarsvisible, setBradarsvisible] = useState(); //1, CHECKBOX // Radars visible 1 = visible; 0 = invisible; -1 = get value.
  const [nradardistance, setNradardistance] = useState(); //10, INPUT NUMBER // Radars warning distance (range 1..10); -1 = get value; real distance = distance * 100 (m / yd)
  const [nradardistanceincity, setNradardistanceincity] = useState(); //3, INPUT NUMBER// Radars warning distance in city (range 1..10); -1 = get value; real distance = distance * 100 (m / yd)
  const [nskin, setNskin] = useState(); //2, SELECT // Skin mode 0 = DAY; 1 = NIGHT; 2 = AUTOSKIN.
  const [ntimezone, setNtimezone] = useState(); //60, INPUT NUMBER // Time zone in minutes.
  const [nspeedexceedincity, setNspeedexceedincity] = useState(); //10, INPUT NUMBER // Speed warning is triggered when the maximum allowed speed in city is exceeded by this value.
  const [nspeedexceed, setNspeedexceed] = useState(); //15, INPUT NUMBER // Speed warning is triggered when the maximum allowed speed outside city is exceeded by this value.
  const [nview, setNview] = useState(); //0, SELECT// View mode 0 = 3D; 1 = 2D; 2 = 2D North up.
  const [nsignpostdirection, setNsignpostdirection] = useState(); //0, SELECT// Displayed signposts: 0=disabled; 1=Preffered direction; 2=All directions; -1=get value.
  const [nsignpostsize, setNsignpostsize] = useState(); //0, SELECT// Displayed signposts size: 0=single line; 1=simplified; 2=full; -1=get value.
  const [bsnaptoeveryroad, setBsnaptoeveryroad] = useState(); //0, CHECKBOX// Allows position snapping to forbidden roads.
  const [bmaxspeedwarn, setBmaxspeedwarn] = useState(); //1, SELECT// Enable acoustic speed exceeding warning 1 = enable; 0 = disable; -1 = get value.
  const [bttsenabled, setBttsenabled] = useState(); //1, CHECKBOX// Selected sound is TTS.
  const [nvisiblepointreachdistance, setNvisiblepointreachdistance] =
    useState(); //0 CHECKBOX,
  const [ninvisiblepointreachdistance, setNinvisiblepointreachdistance] =
    useState(); //0 CHECKBOX,
  const [ballowclosedroads, setBallowclosedroads] = useState(); //0 CHECKBOX,
  const [btruckinmap, setBtruckinmap] = useState(); //1, CHECKBOX// Flag determining whether a truck attributes are contained in map
  const [busetruckatt, setBusetruckatt] = useState(); //1, CHECKBOX// Use truck attributes
  const [ntruckmaxspeed, setNtruckmaxspeed] = useState(); //90, INPUT NUMBER // Truck max speed [km/h].
  const [ntruckweighttotal, setNtruckweighttotal] = useState(); //10000, INPUT NUMBER // Gross vehicle weight [kg].
  const [ntruckweightaxle, setNtruckweightaxle] = useState(); //5000, INPUT NUMBER // Weight per axle [kg].
  const [ntrucktandemweight, setNtrucktandemweight] = useState(); //0, INPUT NUMBER // Tandem axle weight [kg].
  const [ntrucktridemweight, setNtrucktridemweight] = useState(); //0, INPUT NUMBER // Tridem axle weight [kg].
  const [ntruckotherweight, setNtruckotherweight] = useState(); //0, INPUT NUMBER // Other weight [kg].
  const [ntruckunladenweight, setNtruckunladenweight] = useState(); //0, INPUT NUMBER // Unladen vehicle weight [kg].
  const [ntrucklenght, setNtrucklenght] = useState(); //10000, INPUT NUMBER // Total vehicle length [mm].
  const [ntruckaxlelength, setNtruckaxlelength] = useState(); //0, INPUT NUMBER // Extreme axle length [mm].
  const [ntrailerlength, setNtrailerlength] = useState(); //0, INPUT NUMBER // Trailer Length [mm].
  const [ntractorlength, setNtractorlength] = useState(); //0, INPUT NUMBER // Tractor Length [mm].
  const [nkingpinlastaxle, setNkingpinlastaxle] = useState(); //0, INPUT NUMBER // Kingpin to last axle [mm].
  const [nkingpinlasttandem, setNkingpinlasttandem] = useState(); //0, INPUT NUMBER // Kingpin to middle of last tandem [mm].
  const [nkingpinendtrailer, setNkingpinendtrailer] = useState(); //0, INPUT NUMBER // Kingpin to end of trailer (last trailer if applicable) [mm].
  const [ntruckotherlength, setNtruckotherlength] = useState(); //0, INPUT NUMBER // Other length [mm].
  const [ntruckwidth, setNtruckwidth] = useState(); //2450, INPUT NUMBER // Vehicle width [mm].
  const [ntruckheight, setNtruckheight] = useState(); //3000, INPUT NUMBER // Posted height [mm].
  const [nloadrestrictions, setNloadrestrictions] = useState(); //0, CHECKBOX// HAZMAT / Load restrictions.
  const [ballowitineraryedit, setBallowitineraryedit] = useState(); //1 CHECKBOX // Flag determining whether the user will be allowed to edit itinerary manually.
  const [carsWithProfiles, setCarsWithProfiles] = useState();
  const [carsWithProfiles_selected_id, setCarsWithProfiles_selected_id] =
    useState("");

  const [h_shipped_hazardous_goods_arr, setH_shipped_hazardous_goods_arr] =
    useState();
  const [h_shipped_hazardous_goods, setH_shipped_hazardous_goods] = useState(
    []
  );

  const [h_type, setH_type] = useState(); //h_type: 'tractor', // Typ pojazdu dwie opcje -> "straight": a truck on a single frame with a permanently attached cargo area "tractor": a towing vehicle that can pull one or more semi-trailers (aka semi-truck)
  const [h_axle_count, setH_axle_count] = useState(); // Defines total number of axles in the vehicle
  const [h_trailer_count, setH_trailer_count] = useState(); // Number of trailers attached to the vehicle

  const [explosive, setExplosive] = useState();
  const [gas, setGas] = useState();
  const [flammable, setFlammable] = useState();
  const [combustible, setCombustible] = useState();
  const [organic, setOrganic] = useState();
  const [poison, setPoison] = useState();
  const [radioActive, setRadioactive] = useState();
  const [corrosive, setCorrosive] = useState();
  const [poisonousInhalation, setPoisonousInhalation] = useState();
  const [harmfulToWater, setHarmfulToWater] = useState();
  const [other, setOther] = useState();
  const [h_tunel_category, setH_tunel_category] = useState();
  const [gigaliner_trucks, setGigaliner_trucks] = useState();
  const [btrafficenabled, setBtrafficenabled] = useState();

  const [defaultProfil, setDefaultProfil] = useState();

  const [customersTemp, setCustomersTemp] = useState();
  const [customersID, setCustomersID] = useState();
  const [ifTraffic_enabled, setIfTraffic_enabled] = useState();

  const boperaterighthanded_o = [
    { label: "Wygląd leworęczny", value: 0 },
    { label: "Wygląd praworęczny", value: 1 },
  ];
  const distanceunit_o = [
    { label: "Mile (jardy)", value: 0 },
    { label: "Kilometry", value: 1 },
    { label: "Mile (stopy)", value: 2 },
  ];
  const clockformat_o = [
    { label: "Europe (14:00)", value: 0 },
    { label: "US (2:00)", value: 1 },
    { label: "UK (2:00 PM)", value: 2 },
  ];
  const gpsunits_o = [
    { label: "Stopnie (0.0°)", value: 0 },
    { label: "Stopnie; minuty (0°0,0)", value: 1 },
    { label: "Stopnie; minuty; sekundy (0°0'00)", value: 2 },
  ];
  const keyboardtype_o = [
    { label: "ABCD", value: 1 },
    { label: "QWERTY", value: 2 },
    { label: "AZERTY", value: 3 },
    { label: "DVORAK", value: 4 },
  ];
  const navoidtollroads_o = [
    { label: "Pytaj o unikanie dróg płatnych", value: 1 },
    { label: "Zawsze unikaj dróg płatnych", value: 2 },
    { label: "Nigdy nie unikaj dróg płatnych", value: 3 },
  ];
  const bavoiduturns_o = [
    { label: "Pytaj o unikanie zawracania", value: 1 },
    { label: "Zawsze unikaj zawracania", value: 2 },
    { label: "Nigdy nie unikaj zawracania", value: 3 },
  ];
  const nplanningsettings_o = [
    { label: "Nawigacja oblicza najszybszą trasę", value: 1 },
    { label: "Nawigacja oblicza najkrótszą trasę", value: 2 },
    { label: "Nawigacja oblicza trasę ekonomiczną", value: 3 },
  ];
  const bavoidferries_o = [
    { label: "Poproś o unikanie promów", value: 1 },
    { label: "Zawsze unikaj promów", value: 2 },
    { label: "Nigdy nie unikaj promów", value: 3 },
  ];
  const nskin_o = [
    { label: "Dzień", value: 0 },
    { label: "Noc", value: 1 },
    { label: "Auto", value: 2 },
  ];
  const nview_o = [
    { label: "3D", value: 0 },
    { label: "2D", value: 1 },
    { label: "2D północ u góry", value: 2 },
  ];
  const nsignpostdirection_o = [
    { label: "Wyłączone", value: 0 },
    { label: "Preferowany kierunek", value: 1 },
    { label: "Wszystkie kierunki", value: 2 },
  ];
  const nsignpostsize_o = [
    { label: "Pojedyncza linia", value: 0 },
    { label: "Uproszczony", value: 1 },
    { label: "Pełny", value: 2 },
  ];
  const h_type_o = [
    { label: "Pojazd ciężarowy", value: 0, data: "straight" },
    { label: "Ciągnik", value: 1, data: "tractor" },
  ];
  const h_tunel_category_o = [
    { label: "B", value: 0 },
    { label: "C", value: 1 },
    { label: "D", value: 2 },
    { label: "E", value: 3 },
  ];

  var carsWithProfiles_selected = [];

  const minMax = (v, min, max) => {
    const minVal = min;
    const maxVal = max;
    const value = Math.max(minVal, Math.min(maxVal, v));
    return value;
  };
  var carsprofile_o = carsWithProfiles?.map(function (x, index) {
    return { value: index, id: x.id, label: x.name };
  });

  const fetchCarsWithProfiles = async function () {
    const response = await fetch(`${DBurl}/getCarsWithProfiles/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setCarsWithProfiles(json.data);
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
    }
  };

  const fetchCarsWithProfilesDefault = async function () {
    const response = await fetch(`${DBurl}/getCarsTabletsProfiles?id_car=0`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setDefaultProfil(json.data);
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
    }
  };

  const getCustomers = async () => {
    const response = await fetch(`${DBurl}/getCustomers`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setCustomersTemp(json.data);
    }
  };

  if (carsWithProfiles) {
    carsWithProfiles_selected = carsWithProfiles.filter((obj) => {
      return obj.id === carsWithProfiles_selected_id;
    });
  }

  const cleanValues = () => {
    props.setEditProfile(false);
    setDefaultProfil();
    setBsoundenabled();
    setBoperaterighthanded();
    setNvolumemin();
    setNvolumemax();
    setDistanceunit();
    setClockformat();
    setGpsunits();
    setKeyboardtype();
    setNavoidtollroads();
    setBavoiduturns();
    setNplanningsettings();
    setNplanningsettingslimitedspeed();
    setBavoidferries();
    setBdisablemainmenu();
    setBdisablerecompute();
    setNetamaximumspeed();
    setNetapercentagechange();
    setBradarswarnon();
    setBradarsvisible();
    setNradardistance();
    setNradardistanceincity();
    setNskin();
    setNtimezone();
    setNspeedexceedincity();
    setNspeedexceed();
    setNview();
    setNsignpostdirection();
    setNsignpostsize();
    setBsnaptoeveryroad();
    setBmaxspeedwarn();
    setBttsenabled();
    setNvisiblepointreachdistance();
    setNinvisiblepointreachdistance();
    setBallowclosedroads();
    setBtruckinmap();
    setBusetruckatt();
    setNtruckmaxspeed();
    setNtruckweighttotal();
    setNtruckweightaxle();
    setNtrucktandemweight();
    setNtrucktridemweight();
    setNtruckotherweight();
    setNtruckunladenweight();
    setNtrucklenght();
    setNtruckaxlelength();
    setNtrailerlength();
    setNtractorlength();
    setNkingpinlastaxle();
    setNkingpinlasttandem();
    setNkingpinendtrailer();
    setNtruckotherlength();
    setNtruckwidth();
    setNtruckheight();
    setNloadrestrictions();
    setBallowitineraryedit();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDialog(false);
  };

  const tunelCategory = (data) =>
    data === undefined
      ? ""
      : data === 0
      ? h_tunel_category_o[0].label
      : data === 1
      ? h_tunel_category_o[1].label
      : data === 2
      ? h_tunel_category_o[2].label
      : data === 3
      ? h_tunel_category_o[3].label
      : "";

  useEffect(() => {
    fetchCarsWithProfiles();
    getCustomers();
    return () => setCarsWithProfiles();
  }, []);

  useEffect(() => {
    setH_shipped_hazardous_goods_arr(
      props.data.car_profile?.h_shipped_hazardous_goods_arr
    );
    setH_shipped_hazardous_goods(
      props.data.car_profile?.h_shipped_hazardous_goods_arr || []
    );
    setBsoundenabled(props.data.car_profile?.bsoundenabled);
    setBoperaterighthanded(props.data.car_profile?.boperaterighthanded);
    setNvolumemin(props.data.car_profile?.nvolumemin);
    setNvolumemax(props.data.car_profile?.nvolumemax);
    setDistanceunit(props.data.car_profile?.distanceunit);
    setClockformat(props.data.car_profile?.clockformat);
    setGpsunits(props.data.car_profile?.gpsunits);
    setKeyboardtype(props.data.car_profile?.keyboardtype);
    setNavoidtollroads(props.data.car_profile?.navoidtollroads);
    setBavoiduturns(props.data.car_profile?.bavoiduturns);
    setNplanningsettings(props.data.car_profile?.nplanningsettings);
    setNplanningsettingslimitedspeed(
      props.data.car_profile?.nplanningsettingslimitedspeed
    );
    setBavoidferries(props.data.car_profile?.bavoidferries);
    setBdisablemainmenu(props.data.car_profile?.bdisablemainmenu);
    setBdisablerecompute(props.data.car_profile?.bdisablerecompute);
    setNetamaximumspeed(props.data.car_profile?.netamaximumspeed);
    setNetapercentagechange(props.data.car_profile?.netapercentagechange);
    setBradarswarnon(props.data.car_profile?.bradarswarnon);
    setBradarsvisible(props.data.car_profile?.bradarsvisible);
    setNradardistance(props.data.car_profile?.nradardistance);
    setNradardistanceincity(props.data.car_profile?.nradardistanceincity);
    setNskin(props.data.car_profile?.nskin);
    setNtimezone(props.data.car_profile?.ntimezone);
    setNspeedexceedincity(props.data.car_profile?.nspeedexceedincity);
    setNspeedexceed(props.data.car_profile?.nspeedexceed);
    setNview(props.data.car_profile?.nview);
    setNsignpostdirection(props.data.car_profile?.nsignpostdirection);
    setNsignpostsize(props.data.car_profile?.nsignpostsize);
    setBsnaptoeveryroad(props.data.car_profile?.bsnaptoeveryroad);
    setBmaxspeedwarn(props.data.car_profile?.bmaxspeedwarn);
    setBttsenabled(props.data.car_profile?.bttsenabled);
    setNvisiblepointreachdistance(
      props.data.car_profile?.nvisiblepointreachdistance
    );
    setNinvisiblepointreachdistance(
      props.data.car_profile?.ninvisiblepointreachdistance
    );
    setBallowclosedroads(props.data.car_profile?.ballowclosedroads);
    setBtruckinmap(props.data.car_profile?.btruckinmap);
    setBusetruckatt(props.data.car_profile?.busetruckatt);
    setNtruckmaxspeed(props.data.car_profile?.ntruckmaxspeed);
    setNtruckweighttotal(props.data.car_profile?.ntruckweighttotal);
    setNtruckweightaxle(props.data.car_profile?.ntruckweightaxle);
    setNtrucktandemweight(props.data.car_profile?.ntrucktandemweight);
    setNtrucktridemweight(props.data.car_profile?.ntrucktridemweight);
    setNtruckotherweight(props.data.car_profile?.ntruckotherweight);
    setNtruckunladenweight(props.data.car_profile?.ntruckunladenweight);
    setNtrucklenght(props.data.car_profile?.ntrucklenght);
    setNtruckaxlelength(props.data.car_profile?.ntruckaxlelength);
    setNtrailerlength(props.data.car_profile?.ntrailerlength);
    setNtractorlength(props.data.car_profile?.ntractorlength);
    setNkingpinlastaxle(props.data.car_profile?.nkingpinlastaxle);
    setNkingpinlasttandem(props.data.car_profile?.nkingpinlasttandem);
    setNkingpinendtrailer(props.data.car_profile?.nkingpinendtrailer);
    setNtruckotherlength(props.data.car_profile?.ntruckotherlength);
    setNtruckwidth(props.data.car_profile?.ntruckwidth);
    setNtruckheight(props.data.car_profile?.ntruckheight);
    setNloadrestrictions(props.data.car_profile?.nloadrestrictions);
    setBallowitineraryedit(props.data.car_profile?.ballowitineraryedit);

    setH_type(
      props.data.car_profile?.h_type === "straight"
        ? 0
        : props.data.car_profile?.h_type === "tractor"
        ? 1
        : null
    );
    setH_axle_count(props.data.car_profile?.h_axle_count);
    setH_trailer_count(props.data.car_profile?.h_trailer_count);

    setExplosive(
      props.data.car_profile?.h_shipped_hazardous_goods_arr.includes(
        "explosive"
      ) === true
        ? 1
        : 0
    );
    setGas(
      props.data.car_profile?.h_shipped_hazardous_goods_arr.includes("gas") ===
        true
        ? 1
        : 0
    );
    setFlammable(
      props.data.car_profile?.h_shipped_hazardous_goods_arr.includes(
        "flammable"
      ) === true
        ? 1
        : 0
    );
    setCombustible(
      props.data.car_profile?.h_shipped_hazardous_goods_arr.includes(
        "combustible"
      ) === true
        ? 1
        : 0
    );
    setOrganic(
      props.data.car_profile?.h_shipped_hazardous_goods_arr.includes(
        "organic"
      ) === true
        ? 1
        : 0
    );
    setPoison(
      props.data.car_profile?.h_shipped_hazardous_goods_arr.includes(
        "poison"
      ) === true
        ? 1
        : 0
    );
    setRadioactive(
      props.data.car_profile?.h_shipped_hazardous_goods_arr.includes(
        "radioactive"
      ) === true
        ? 1
        : 0
    );
    setCorrosive(
      props.data.car_profile?.h_shipped_hazardous_goods_arr.includes(
        "corrosive"
      ) === true
        ? 1
        : 0
    );
    setPoisonousInhalation(
      props.data.car_profile?.h_shipped_hazardous_goods_arr.includes(
        "poisonousInhalation"
      ) === true
        ? 1
        : 0
    );
    setHarmfulToWater(
      props.data.car_profile?.h_shipped_hazardous_goods_arr.includes(
        "harmfulToWater"
      ) === true
        ? 1
        : 0
    );
    setOther(
      props.data.car_profile?.h_shipped_hazardous_goods_arr.includes(
        "other"
      ) === true
        ? 1
        : 0
    );

    setGigaliner_trucks(props.data.car_profile?.gigaliner_trucks);
    setBtrafficenabled(props.data.car_profile?.btrafficenabled);
  }, [props.data.car_profile, props.editProfile]);

  useEffect(() => {
    if (carsWithProfiles_selected_id !== "") {
      setH_shipped_hazardous_goods(
        carsWithProfiles_selected[0].car_profile.h_shipped_hazardous_goods_arr
      );
      setH_shipped_hazardous_goods_arr(
        carsWithProfiles_selected[0].car_profile.h_shipped_hazardous_goods_arr
      );
      setBsoundenabled(carsWithProfiles_selected[0].car_profile.bsoundenabled);
      setBoperaterighthanded(
        carsWithProfiles_selected[0].car_profile.boperaterighthanded
      );
      setNvolumemin(carsWithProfiles_selected[0].car_profile.nvolumemin);
      setNvolumemax(carsWithProfiles_selected[0].car_profile.nvolumemax);
      setDistanceunit(carsWithProfiles_selected[0].car_profile.distanceunit);
      setClockformat(carsWithProfiles_selected[0].car_profile.clockformat);
      setGpsunits(carsWithProfiles_selected[0].car_profile.gpsunits);
      setKeyboardtype(carsWithProfiles_selected[0].car_profile.keyboardtype);
      setNavoidtollroads(
        carsWithProfiles_selected[0].car_profile.navoidtollroads
      );
      setBavoiduturns(carsWithProfiles_selected[0].car_profile.bavoiduturns);
      setNplanningsettings(
        carsWithProfiles_selected[0].car_profile.nplanningsettings
      );
      setNplanningsettingslimitedspeed(
        carsWithProfiles_selected[0].car_profile.nplanningsettingslimitedspeed
      );
      setBavoidferries(carsWithProfiles_selected[0].car_profile.bavoidferries);
      setBdisablemainmenu(
        carsWithProfiles_selected[0].car_profile.bdisablemainmenu
      );
      setBdisablerecompute(
        carsWithProfiles_selected[0].car_profile.bdisablerecompute
      );
      setNetamaximumspeed(
        carsWithProfiles_selected[0].car_profile.netamaximumspeed
      );
      setNetapercentagechange(
        carsWithProfiles_selected[0].car_profile.netapercentagechange
      );
      setBradarswarnon(carsWithProfiles_selected[0].car_profile.bradarswarnon);
      setBradarsvisible(
        carsWithProfiles_selected[0].car_profile.bradarsvisible
      );
      setNradardistance(
        carsWithProfiles_selected[0].car_profile.nradardistance
      );
      setNradardistanceincity(
        carsWithProfiles_selected[0].car_profile.nradardistanceincity
      );
      setNskin(carsWithProfiles_selected[0].car_profile.nskin);
      setNtimezone(carsWithProfiles_selected[0].car_profile.ntimezone);
      setNspeedexceedincity(
        carsWithProfiles_selected[0].car_profile.nspeedexceedincity
      );
      setNspeedexceed(carsWithProfiles_selected[0].car_profile.nspeedexceed);
      setNview(carsWithProfiles_selected[0].car_profile.nview);
      setNsignpostdirection(
        carsWithProfiles_selected[0].car_profile.nsignpostdirection
      );
      setNsignpostsize(carsWithProfiles_selected[0].car_profile.nsignpostsize);
      setBsnaptoeveryroad(
        carsWithProfiles_selected[0].car_profile.bsnaptoeveryroad
      );
      setBmaxspeedwarn(carsWithProfiles_selected[0].car_profile.bmaxspeedwarn);
      setBttsenabled(carsWithProfiles_selected[0].car_profile.bttsenabled);
      setNvisiblepointreachdistance(
        carsWithProfiles_selected[0].car_profile.nvisiblepointreachdistance
      );
      setNinvisiblepointreachdistance(
        carsWithProfiles_selected[0].car_profile.ninvisiblepointreachdistance
      );
      setBallowclosedroads(
        carsWithProfiles_selected[0].car_profile.ballowclosedroads
      );
      setBtruckinmap(carsWithProfiles_selected[0].car_profile.btruckinmap);
      setBusetruckatt(carsWithProfiles_selected[0].car_profile.busetruckatt);
      setNtruckmaxspeed(
        carsWithProfiles_selected[0].car_profile.ntruckmaxspeed
      );
      setNtruckweighttotal(
        carsWithProfiles_selected[0].car_profile.ntruckweighttotal
      );
      setNtruckweightaxle(
        carsWithProfiles_selected[0].car_profile.ntruckweightaxle
      );
      setNtrucktandemweight(
        carsWithProfiles_selected[0].car_profile.ntrucktandemweight
      );
      setNtrucktridemweight(
        carsWithProfiles_selected[0].car_profile.ntrucktridemweight
      );
      setNtruckotherweight(
        carsWithProfiles_selected[0].car_profile.ntruckotherweight
      );
      setNtruckunladenweight(
        carsWithProfiles_selected[0].car_profile.ntruckunladenweight
      );
      setNtrucklenght(carsWithProfiles_selected[0].car_profile.ntrucklenght);
      setNtruckaxlelength(
        carsWithProfiles_selected[0].car_profile.ntruckaxlelength
      );
      setNtrailerlength(
        carsWithProfiles_selected[0].car_profile.ntrailerlength
      );
      setNtractorlength(
        carsWithProfiles_selected[0].car_profile.ntractorlength
      );
      setNkingpinlastaxle(
        carsWithProfiles_selected[0].car_profile.nkingpinlastaxle
      );
      setNkingpinlasttandem(
        carsWithProfiles_selected[0].car_profile.nkingpinlasttandem
      );
      setNkingpinendtrailer(
        carsWithProfiles_selected[0].car_profile.nkingpinendtrailer
      );
      setNtruckotherlength(
        carsWithProfiles_selected[0].car_profile.ntruckotherlength
      );
      setNtruckwidth(carsWithProfiles_selected[0].car_profile.ntruckwidth);
      setNtruckheight(carsWithProfiles_selected[0].car_profile.ntruckheight);
      setNloadrestrictions(
        carsWithProfiles_selected[0].car_profile.nloadrestrictions
      );
      setBallowitineraryedit(
        carsWithProfiles_selected[0].car_profile.ballowitineraryedit
      );

      setH_type(
        carsWithProfiles_selected[0].car_profile.h_type === "straight"
          ? 0
          : carsWithProfiles_selected[0].car_profile.h_type === "tractor"
          ? 1
          : null
      );
      setH_axle_count(carsWithProfiles_selected[0].car_profile.h_axle_count);
      setH_trailer_count(
        carsWithProfiles_selected[0].car_profile.h_trailer_count
      );

      setExplosive(
        carsWithProfiles_selected[0].car_profile.h_shipped_hazardous_goods_arr.includes(
          "explosive"
        ) === true
          ? 1
          : 0
      );
      setGas(
        carsWithProfiles_selected[0].car_profile.h_shipped_hazardous_goods_arr.includes(
          "gas"
        ) === true
          ? 1
          : 0
      );
      setFlammable(
        carsWithProfiles_selected[0].car_profile.h_shipped_hazardous_goods_arr.includes(
          "flammable"
        ) === true
          ? 1
          : 0
      );
      setCombustible(
        carsWithProfiles_selected[0].car_profile.h_shipped_hazardous_goods_arr.includes(
          "combustible"
        ) === true
          ? 1
          : 0
      );
      setOrganic(
        carsWithProfiles_selected[0].car_profile.h_shipped_hazardous_goods_arr.includes(
          "organic"
        ) === true
          ? 1
          : 0
      );
      setPoison(
        carsWithProfiles_selected[0].car_profile.h_shipped_hazardous_goods_arr.includes(
          "poison"
        ) === true
          ? 1
          : 0
      );
      setRadioactive(
        carsWithProfiles_selected[0].car_profile.h_shipped_hazardous_goods_arr.includes(
          "radioactive"
        ) === true
          ? 1
          : 0
      );
      setCorrosive(
        carsWithProfiles_selected[0].car_profile.h_shipped_hazardous_goods_arr.includes(
          "corrosive"
        ) === true
          ? 1
          : 0
      );
      setPoisonousInhalation(
        carsWithProfiles_selected[0].car_profile.h_shipped_hazardous_goods_arr.includes(
          "poisonousInhalation"
        ) === true
          ? 1
          : 0
      );
      setHarmfulToWater(
        carsWithProfiles_selected[0].car_profile.h_shipped_hazardous_goods_arr.includes(
          "harmfulToWater"
        ) === true
          ? 1
          : 0
      );
      setOther(
        carsWithProfiles_selected[0].car_profile.h_shipped_hazardous_goods_arr.includes(
          "other"
        ) === true
          ? 1
          : 0
      );
      setGigaliner_trucks(
        carsWithProfiles_selected[0].car_profile.gigaliner_trucks
      );
      setBtrafficenabled(
        carsWithProfiles_selected[0].car_profile.btrafficenabled
      );
    }
  }, [carsWithProfiles_selected_id]);

  useEffect(() => {
    if (combustible === 1) {
      setH_tunel_category(2);
    } else if (organic === 1) {
      setH_tunel_category(2);
    } else if (poison === 1) {
      setH_tunel_category(2);
    } else if (radioActive === 1) {
      setH_tunel_category(2);
    } else if (corrosive === 1) {
      setH_tunel_category(2);
    } else if (poisonousInhalation === 1) {
      setH_tunel_category(2);
    } else if (harmfulToWater === 1) {
      setH_tunel_category(2);
    } else if (other === 1) {
      setH_tunel_category(2);
    } else if (gas === 1) {
      if (h_tunel_category !== 2) {
        setH_tunel_category(3);
      }
    } else if (flammable === 1) {
      setH_tunel_category(1);
    } else if (explosive === 1) {
      setH_tunel_category(0);
    } else {
      setH_tunel_category();
    }
  }, [
    combustible,
    organic,
    poison,
    radioActive,
    corrosive,
    poisonousInhalation,
    harmfulToWater,
    other,
    gas,
    explosive,
    flammable,
  ]);

  useEffect(() => {
    if (
      props.data.car_profile === null ||
      props.data.car_profile === undefined
    ) {
      fetchCarsWithProfilesDefault();
    }
  }, [props.data.car_profile, props.editProfile]);

  useEffect(() => {
    if (props.data.car_profile === null) {
      if (defaultProfil?.id === 0) {
        setH_shipped_hazardous_goods_arr(
          defaultProfil.h_shipped_hazardous_goods_arr
        );
        setH_shipped_hazardous_goods(
          defaultProfil.h_shipped_hazardous_goods_arr || []
        );
        setBsoundenabled(defaultProfil.bsoundenabled);
        setBoperaterighthanded(defaultProfil.boperaterighthanded);
        setNvolumemin(defaultProfil.nvolumemin);
        setNvolumemax(defaultProfil.nvolumemax);
        setDistanceunit(defaultProfil.distanceunit);
        setClockformat(defaultProfil.clockformat);
        setGpsunits(defaultProfil.gpsunits);
        setKeyboardtype(defaultProfil.keyboardtype);
        setNavoidtollroads(defaultProfil.navoidtollroads);
        setBavoiduturns(defaultProfil.bavoiduturns);
        setNplanningsettings(defaultProfil.nplanningsettings);
        setNplanningsettingslimitedspeed(
          defaultProfil.nplanningsettingslimitedspeed
        );
        setBavoidferries(defaultProfil.bavoidferries);
        setBdisablemainmenu(defaultProfil.bdisablemainmenu);
        setBdisablerecompute(defaultProfil.bdisablerecompute);
        setNetamaximumspeed(defaultProfil.netamaximumspeed);
        setNetapercentagechange(defaultProfil.netapercentagechange);
        setBradarswarnon(defaultProfil.bradarswarnon);
        setBradarsvisible(defaultProfil.bradarsvisible);
        setNradardistance(defaultProfil.nradardistance);
        setNradardistanceincity(defaultProfil.nradardistanceincity);
        setNskin(defaultProfil.nskin);
        setNtimezone(defaultProfil.ntimezone);
        setNspeedexceedincity(defaultProfil.nspeedexceedincity);
        setNspeedexceed(defaultProfil.nspeedexceed);
        setNview(defaultProfil.nview);
        setNsignpostdirection(defaultProfil.nsignpostdirection);
        setNsignpostsize(defaultProfil.nsignpostsize);
        setBsnaptoeveryroad(defaultProfil.bsnaptoeveryroad);
        setBmaxspeedwarn(defaultProfil.bmaxspeedwarn);
        setBttsenabled(defaultProfil.bttsenabled);
        setNvisiblepointreachdistance(defaultProfil.nvisiblepointreachdistance);
        setNinvisiblepointreachdistance(
          defaultProfil.ninvisiblepointreachdistance
        );
        setBallowclosedroads(defaultProfil.ballowclosedroads);
        setBtruckinmap(defaultProfil.btruckinmap);
        setBusetruckatt(defaultProfil.busetruckatt);
        setNtruckmaxspeed(defaultProfil.ntruckmaxspeed);
        setNtruckweighttotal(defaultProfil.ntruckweighttotal);
        setNtruckweightaxle(defaultProfil.ntruckweightaxle);
        setNtrucktandemweight(defaultProfil.ntrucktandemweight);
        setNtrucktridemweight(defaultProfil.ntrucktridemweight);
        setNtruckotherweight(defaultProfil.ntruckotherweight);
        setNtruckunladenweight(defaultProfil.ntruckunladenweight);
        setNtrucklenght(defaultProfil.ntrucklenght);
        setNtruckaxlelength(defaultProfil.ntruckaxlelength);
        setNtrailerlength(defaultProfil.ntrailerlength);
        setNtractorlength(defaultProfil.ntractorlength);
        setNkingpinlastaxle(defaultProfil.nkingpinlastaxle);
        setNkingpinlasttandem(defaultProfil.nkingpinlasttandem);
        setNkingpinendtrailer(defaultProfil.nkingpinendtrailer);
        setNtruckotherlength(defaultProfil.ntruckotherlength);
        setNtruckwidth(defaultProfil.ntruckwidth);
        setNtruckheight(defaultProfil.ntruckheight);
        setNloadrestrictions(defaultProfil.nloadrestrictions);
        setBallowitineraryedit(defaultProfil.ballowitineraryedit);

        setH_type(
          defaultProfil.h_type === "straight"
            ? 0
            : defaultProfil.h_type === "tractor"
            ? 1
            : null
        );
        setH_axle_count(defaultProfil.h_axle_count);
        setH_trailer_count(defaultProfil.h_trailer_count);

        setExplosive(
          defaultProfil.h_shipped_hazardous_goods_arr.includes("explosive") ===
            true
            ? 1
            : 0
        );
        setGas(
          defaultProfil.h_shipped_hazardous_goods_arr.includes("gas") === true
            ? 1
            : 0
        );
        setFlammable(
          defaultProfil.h_shipped_hazardous_goods_arr.includes("flammable") ===
            true
            ? 1
            : 0
        );
        setCombustible(
          defaultProfil.h_shipped_hazardous_goods_arr.includes(
            "combustible"
          ) === true
            ? 1
            : 0
        );
        setOrganic(
          defaultProfil.h_shipped_hazardous_goods_arr.includes("organic") ===
            true
            ? 1
            : 0
        );
        setPoison(
          defaultProfil.h_shipped_hazardous_goods_arr.includes("poison") ===
            true
            ? 1
            : 0
        );
        setRadioactive(
          defaultProfil.h_shipped_hazardous_goods_arr.includes(
            "radioactive"
          ) === true
            ? 1
            : 0
        );
        setCorrosive(
          defaultProfil.h_shipped_hazardous_goods_arr.includes("corrosive") ===
            true
            ? 1
            : 0
        );
        setPoisonousInhalation(
          defaultProfil.h_shipped_hazardous_goods_arr.includes(
            "poisonousInhalation"
          ) === true
            ? 1
            : 0
        );
        setHarmfulToWater(
          defaultProfil.h_shipped_hazardous_goods_arr.includes(
            "harmfulToWater"
          ) === true
            ? 1
            : 0
        );
        setOther(
          defaultProfil.h_shipped_hazardous_goods_arr.includes("other") === true
            ? 1
            : 0
        );

        setGigaliner_trucks(defaultProfil.gigaliner_trucks);
        setBtrafficenabled(defaultProfil.btrafficenabled);
      }
    }
  }, [defaultProfil, props.editProfile]);

  useEffect(() => {
    setCustomersID(props.data.customer && props.data.customer.id);
  }, [props.data.customer]);

  useEffect(() => {
    if (customersID) {
      let selectedCustomer = customersTemp.filter((o) => {
        return o.id === customersID;
      });
      setIfTraffic_enabled(selectedCustomer[0].traffic_enabled);
    }
  }, [customersID, customersTemp]);

  const checkError = (value) => {
    if (error & !value & (value !== 0)) {
      return true;
    }
    return false;
  };

  return (
    <Dialog
      maxWidth="lg"
      open={props.open}
      TransitionComponent={Transition}
      onClose={() => {
        props.setOpen(false);
        cleanValues();
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        className={stylesMod.dialogHead}
      >
        {props.data.car_profile === null
          ? "Dodaj profil pojazdu"
          : "Edytuj profil pojazdu"}
        <IconButton
          aria-label="close"
          className={stylesMod.closeButton}
          onClick={() => {
            props.setOpen(false);
            cleanValues();
          }}
        >
          <X />
        </IconButton>
      </DialogTitle>

      <Grid container className={styles.popupHeader}>
        <Grid item xs={12}>
          <div className={stylesMod.inp}>
            <label>Skopiuj profil z pojazdu</label>
            <Select
              className={`${stylesMod.select}`}
              options={carsprofile_o}
              name="type"
              placeholder="Wybierz profil..."
              onChange={(e) => {
                setCarsWithProfiles_selected_id(e.id);
              }}
              styles={{
                control: (base, state) => ({
                  ...base,
                  border: "1px solid #142f42",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #142f42",
                  },
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused && "red",
                    color: state.isFocused && "#fff",
                  }),
                }),
              }}
            />
          </div>
        </Grid>
      </Grid>

      <DialogContent className={stylesMod.noOverflow}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Masa na oś [kg]</label>
              <InputNum
                type="number"
                value={ntruckweightaxle === "" ? null : ntruckweightaxle}
                handleChange={(e) => {
                  setNtruckweightaxle(
                    e.target.value === "" ? null : e.target.value
                  );
                }}
                name="ntruckweightaxle"
                error={checkError(ntruckweightaxle)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Masa całkowita pojazdu [kg]</label>
              <InputNum
                type="number"
                value={ntruckweighttotal}
                handleChange={(e) => {
                  setNtruckweighttotal(
                    e.target.value === "" ? null : e.target.value
                  );
                }}
                name="ntruckweighttotal"
                error={checkError(ntruckweighttotal)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Wysłana wysokość [mm]</label>
              <InputNum
                type="number"
                value={ntruckheight}
                handleChange={(e) => {
                  setNtruckheight(
                    e.target.value === "" ? null : e.target.value
                  );
                }}
                name="ntruckheight"
                error={checkError(ntruckheight)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Szerokość pojazdu [mm]</label>
              <InputNum
                type="number"
                value={ntruckwidth}
                handleChange={(e) => {
                  setNtruckwidth(e.target.value === "" ? null : e.target.value);
                }}
                name="ntruckwidth"
                error={checkError(ntruckwidth)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Całkowita długość pojazdu [mm]</label>
              <InputNum
                type="number"
                value={ntrucklenght}
                handleChange={(e) => {
                  setNtrucklenght(
                    e.target.value === "" ? null : e.target.value
                  );
                }}
                name="ntrucklenght"
                error={checkError(ntrucklenght)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Typ pojazdu</label>
              <Select
                className={`${stylesMod.select}`}
                options={h_type_o}
                value={
                  h_type === undefined
                    ? ""
                    : h_type === 0
                    ? h_type_o[0]
                    : h_type === 1
                    ? h_type_o[1]
                    : null
                }
                name="h_type"
                placeholder="Wybierz..."
                onChange={(e) => {
                  setH_type(e.value);
                }}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                    }),
                  }),
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Liczba osi w pojeździe</label>
              <InputNum
                type="number"
                value={h_axle_count}
                handleChange={(e) => {
                  setH_axle_count(e.target.value ? e.target.value : null);
                }}
                name="h_axle_count"
                error={checkError(h_axle_count)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Liczba przyczep przyczepionych do pojazdu</label>
              <InputNum
                type="number"
                value={h_trailer_count}
                handleChange={(e) => {
                  setH_trailer_count(
                    e.target.value === "" ? null : e.target.value
                  );
                }}
                name="h_trailer_count"
                error={checkError(h_trailer_count)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <div className={styles.seperator}></div>
            </div>
          </Grid>

          <h3 style={{ marginLeft: "8px" }}>Towary niebezpieczne</h3>

          <Grid item xs={12}>
            <div className={`${stylesMod.inp}`}>
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={explosive === 1 ? true : false}
                    color="primary"
                    onChange={(e) => {
                      setExplosive(e.target.checked === true ? 1 : 0);
                      if (e.target.checked === true) {
                        setH_shipped_hazardous_goods([
                          ...h_shipped_hazardous_goods,
                          "explosive",
                        ]);
                      } else {
                        setH_shipped_hazardous_goods(
                          h_shipped_hazardous_goods.filter(
                            (item) => item !== "explosive"
                          )
                        );
                      }
                    }}
                  />
                }
                label="Materiał wybuchowy"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={`${stylesMod.inp}`}>
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={gas === 1 ? true : false}
                    color="primary"
                    onChange={(e) => {
                      setGas(e.target.checked === true ? 1 : 0);
                      if (e.target.checked === true) {
                        setH_shipped_hazardous_goods([
                          ...h_shipped_hazardous_goods,
                          "gas",
                        ]);
                      } else {
                        setH_shipped_hazardous_goods(
                          h_shipped_hazardous_goods.filter(
                            (item) => item !== "gas"
                          )
                        );
                      }
                    }}
                  />
                }
                label="Gas"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={`${stylesMod.inp}`}>
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={flammable === 1 ? true : false}
                    color="primary"
                    onChange={(e) => {
                      setFlammable(e.target.checked === true ? 1 : 0);
                      if (e.target.checked === true) {
                        setH_shipped_hazardous_goods([
                          ...h_shipped_hazardous_goods,
                          "flammable",
                        ]);
                      } else {
                        setH_shipped_hazardous_goods(
                          h_shipped_hazardous_goods.filter(
                            (item) => item !== "flammable"
                          )
                        );
                      }
                    }}
                  />
                }
                label="Materiały łatwo palne"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={`${stylesMod.inp}`}>
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={combustible === 1 ? true : false}
                    color="primary"
                    onChange={(e) => {
                      setCombustible(e.target.checked === true ? 1 : 0);
                      if (e.target.checked === true) {
                        setH_shipped_hazardous_goods([
                          ...h_shipped_hazardous_goods,
                          "combustible",
                        ]);
                      } else {
                        setH_shipped_hazardous_goods(
                          h_shipped_hazardous_goods.filter(
                            (item) => item !== "combustible"
                          )
                        );
                      }
                    }}
                  />
                }
                label="Materiały palne"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={`${stylesMod.inp}`}>
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={organic === 1 ? true : false}
                    color="primary"
                    onChange={(e) => {
                      setOrganic(e.target.checked === true ? 1 : 0);
                      if (e.target.checked === true) {
                        setH_shipped_hazardous_goods([
                          ...h_shipped_hazardous_goods,
                          "organic",
                        ]);
                      } else {
                        setH_shipped_hazardous_goods(
                          h_shipped_hazardous_goods.filter(
                            (item) => item !== "organic"
                          )
                        );
                      }
                    }}
                  />
                }
                label="Materiały organiczna"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={`${stylesMod.inp}`}>
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={poison === 1 ? true : false}
                    color="primary"
                    onChange={(e) => {
                      setPoison(e.target.checked === true ? 1 : 0);
                      if (e.target.checked === true) {
                        setH_shipped_hazardous_goods([
                          ...h_shipped_hazardous_goods,
                          "poison",
                        ]);
                      } else {
                        setH_shipped_hazardous_goods(
                          h_shipped_hazardous_goods.filter(
                            (item) => item !== "poison"
                          )
                        );
                      }
                    }}
                  />
                }
                label="Materiały trujące"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={`${stylesMod.inp}`}>
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={radioActive === 1 ? true : false}
                    color="primary"
                    onChange={(e) => {
                      setRadioactive(e.target.checked === true ? 1 : 0);
                      if (e.target.checked === true) {
                        setH_shipped_hazardous_goods([
                          ...h_shipped_hazardous_goods,
                          "radioactive",
                        ]);
                      } else {
                        setH_shipped_hazardous_goods(
                          h_shipped_hazardous_goods.filter(
                            (item) => item !== "radioactive"
                          )
                        );
                      }
                    }}
                  />
                }
                label="Materiały radioaktywne"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={`${stylesMod.inp}`}>
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={corrosive === 1 ? true : false}
                    color="primary"
                    onChange={(e) => {
                      setCorrosive(e.target.checked === true ? 1 : 0);
                      if (e.target.checked === true) {
                        setH_shipped_hazardous_goods([
                          ...h_shipped_hazardous_goods,
                          "corrosive",
                        ]);
                      } else {
                        setH_shipped_hazardous_goods(
                          h_shipped_hazardous_goods.filter(
                            (item) => item !== "corrosive"
                          )
                        );
                      }
                    }}
                  />
                }
                label="Materiały żrące"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={`${stylesMod.inp}`}>
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={poisonousInhalation === 1 ? true : false}
                    color="primary"
                    onChange={(e) => {
                      setPoisonousInhalation(e.target.checked === true ? 1 : 0);
                      if (e.target.checked === true) {
                        setH_shipped_hazardous_goods([
                          ...h_shipped_hazardous_goods,
                          "poisonousInhalation",
                        ]);
                      } else {
                        setH_shipped_hazardous_goods(
                          h_shipped_hazardous_goods.filter(
                            (item) => item !== "poisonousInhalation"
                          )
                        );
                      }
                    }}
                  />
                }
                label="Materiały trujące przy wdychaniu"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={`${stylesMod.inp}`}>
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={harmfulToWater === 1 ? true : false}
                    color="primary"
                    onChange={(e) => {
                      setHarmfulToWater(e.target.checked === true ? 1 : 0);
                      if (e.target.checked === true) {
                        setH_shipped_hazardous_goods([
                          ...h_shipped_hazardous_goods,
                          "harmfulToWater",
                        ]);
                      } else {
                        setH_shipped_hazardous_goods(
                          h_shipped_hazardous_goods.filter(
                            (item) => item !== "harmfulToWater"
                          )
                        );
                      }
                    }}
                  />
                }
                label="Materiały szkodliwe dla wody"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={`${stylesMod.inp}`}>
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={other === 1 ? true : false}
                    color="primary"
                    onChange={(e) => {
                      setOther(e.target.checked === true ? 1 : 0);
                      if (e.target.checked === true) {
                        setH_shipped_hazardous_goods([
                          ...h_shipped_hazardous_goods,
                          "other",
                        ]);
                      } else {
                        setH_shipped_hazardous_goods(
                          h_shipped_hazardous_goods.filter(
                            (item) => item !== "other"
                          )
                        );
                      }
                    }}
                  />
                }
                label="Inne rodzaje materiałów niebezpiecznych"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>
                Określa kategorię tunelu używaną do ograniczenia transportu
              </label>
              <Select
                className={`${stylesMod.select}`}
                options={h_tunel_category_o}
                value={
                  h_tunel_category === undefined
                    ? null
                    : h_tunel_category === 0
                    ? h_tunel_category_o[0]
                    : h_tunel_category === 1
                    ? h_tunel_category_o[1]
                    : h_tunel_category === 2
                    ? h_tunel_category_o[2]
                    : h_tunel_category === 3
                    ? h_tunel_category_o[3]
                    : null
                }
                name="h_tunel_category"
                placeholder="Wybierz..."
                onChange={(e) => {
                  setH_tunel_category(e.value);
                }}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                    }),
                  }),
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <div className={styles.seperator}></div>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Wygląd nawigacji</label>
              <Select
                className={`${stylesMod.select}`}
                options={boperaterighthanded_o}
                value={
                  boperaterighthanded === undefined
                    ? ""
                    : boperaterighthanded === 0
                    ? boperaterighthanded_o[0]
                    : boperaterighthanded === 1
                    ? boperaterighthanded_o[1]
                    : ""
                }
                name="boperaterighthanded"
                placeholder="Wybierz..."
                onChange={(e) => {
                  setBoperaterighthanded(e.value === "" ? null : e.value);
                }}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                    }),
                  }),
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Minimalna głośność dźwięku (0-10)</label>
              <InputNum
                type="number"
                min="0"
                max="10"
                value={minMax(nvolumemin, 0, 10)}
                handleChange={(e) => {
                  setNvolumemin(e.target.value === "" ? null : e.target.value);
                }}
                name="nvolumemin"
                error={checkError(nvolumemin)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Maksymalna głośność dźwięku (0-10)</label>
              <InputNum
                type="number"
                min="0"
                max="10"
                value={minMax(nvolumemax, 0, 10)}
                handleChange={(e) => {
                  setNvolumemax(e.target.value === "" ? null : e.target.value);
                }}
                name="nvolumemax"
                error={checkError(nvolumemax)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Jednostki odległości</label>
              <Select
                className={`${stylesMod.select}`}
                options={distanceunit_o}
                value={
                  distanceunit === undefined
                    ? ""
                    : distanceunit === 0
                    ? distanceunit_o[0]
                    : distanceunit === 1
                    ? distanceunit_o[1]
                    : distanceunit === 2
                    ? distanceunit_o[2]
                    : ""
                }
                name="distanceunit"
                placeholder="Wybierz..."
                onChange={(e) => {
                  setDistanceunit(e.value === "" ? null : e.value);
                }}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                    }),
                  }),
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Format zegara</label>
              <Select
                className={`${stylesMod.select}`}
                options={clockformat_o}
                value={
                  clockformat === undefined
                    ? ""
                    : clockformat === 0
                    ? clockformat_o[0]
                    : clockformat === 1
                    ? clockformat_o[1]
                    : clockformat === 2
                    ? clockformat_o[2]
                    : ""
                }
                name="clockformat"
                placeholder="Wybierz..."
                onChange={(e) => {
                  setClockformat(e.value === "" ? null : e.value);
                }}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                    }),
                  }),
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Format GPSUnits</label>
              <Select
                className={`${stylesMod.select}`}
                options={gpsunits_o}
                value={
                  gpsunits === undefined
                    ? ""
                    : gpsunits === 0
                    ? gpsunits_o[0]
                    : gpsunits === 1
                    ? gpsunits_o[1]
                    : gpsunits === 2
                    ? gpsunits_o[2]
                    : ""
                }
                name="clockformat"
                placeholder="Wybierz..."
                onChange={(e) => {
                  setGpsunits(e.value === "" ? null : e.value);
                }}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                    }),
                  }),
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Typ klawiatury</label>
              <Select
                className={`${stylesMod.select}`}
                options={keyboardtype_o}
                value={
                  keyboardtype === undefined
                    ? ""
                    : keyboardtype === 1
                    ? keyboardtype_o[0]
                    : keyboardtype === 2
                    ? keyboardtype_o[1]
                    : keyboardtype === 3
                    ? keyboardtype_o[2]
                    : keyboardtype === 4
                    ? keyboardtype_o[3]
                    : ""
                }
                name="keyboardtype"
                placeholder="Wybierz..."
                onChange={(e) => {
                  setKeyboardtype(e.value === "" ? null : e.value);
                }}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                    }),
                  }),
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Omijaj drogi płatne podczas obliczania trasy</label>
              <Select
                className={`${stylesMod.select}`}
                options={navoidtollroads_o}
                value={
                  navoidtollroads === undefined
                    ? ""
                    : navoidtollroads === 1
                    ? navoidtollroads_o[0]
                    : navoidtollroads === 2
                    ? navoidtollroads_o[1]
                    : navoidtollroads === 3
                    ? navoidtollroads_o[2]
                    : ""
                }
                name="navoidtollroads"
                placeholder="Wybierz..."
                onChange={(e) => {
                  setNavoidtollroads(e.value === "" ? null : e.value);
                }}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                    }),
                  }),
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Unikaj zawracania podczas obliczania trasy</label>
              <Select
                className={`${stylesMod.select}`}
                options={bavoiduturns_o}
                value={
                  bavoiduturns === undefined
                    ? ""
                    : bavoiduturns === 1
                    ? bavoiduturns_o[0]
                    : bavoiduturns === 2
                    ? bavoiduturns_o[1]
                    : bavoiduturns === 3
                    ? bavoiduturns_o[2]
                    : ""
                }
                name="bavoiduturns"
                placeholder="Wybierz..."
                onChange={(e) => {
                  setBavoiduturns(e.value === "" ? null : e.value);
                }}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                    }),
                  }),
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Rodzaje tras</label>
              <Select
                className={`${stylesMod.select}`}
                options={nplanningsettings_o}
                value={
                  nplanningsettings === undefined
                    ? ""
                    : nplanningsettings === 1
                    ? nplanningsettings_o[0]
                    : nplanningsettings === 2
                    ? nplanningsettings_o[1]
                    : nplanningsettings === 3
                    ? nplanningsettings_o[2]
                    : ""
                }
                name="nplanningsettings"
                placeholder="Wybierz..."
                onChange={(e) => {
                  setNplanningsettings(e.value === "" ? null : e.value);
                }}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                    }),
                  }),
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Ograniczenie prędkości pojazdu</label>
              <InputNum
                type="number"
                value={nplanningsettingslimitedspeed}
                handleChange={(e) => {
                  setNplanningsettingslimitedspeed(
                    e.target.value === "" ? null : e.target.value
                  );
                }}
                name="nplanningsettingslimitedspeed"
                error={checkError(nplanningsettingslimitedspeed)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Omijaj promy podczas obliczania trasy</label>
              <Select
                className={`${stylesMod.select}`}
                options={bavoidferries_o}
                value={
                  bavoidferries === undefined
                    ? ""
                    : bavoidferries === 1
                    ? bavoidferries_o[0]
                    : bavoidferries === 2
                    ? bavoidferries_o[1]
                    : bavoidferries === 3
                    ? bavoidferries_o[2]
                    : ""
                }
                name="bavoidferries"
                placeholder="Wybierz..."
                onChange={(e) => {
                  setBavoidferries(e.value === "" ? null : e.value);
                }}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                    }),
                  }),
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Szacowany czas przybycia przy maksymalnej predkości</label>
              <InputNum
                type="number"
                value={netamaximumspeed}
                handleChange={(e) => {
                  setNetamaximumspeed(
                    e.target.value === "" ? null : e.target.value
                  );
                }}
                name="netamaximumspeed"
                error={checkError(netamaximumspeed)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Oszacuj zmianę procentową czasu przybycia</label>
              <InputNum
                type="number"
                value={netapercentagechange}
                handleChange={(e) => {
                  setNetapercentagechange(
                    e.target.value === "" ? null : e.target.value
                  );
                }}
                name="netapercentagechange"
                error={checkError(netapercentagechange)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Odległość ostrzegania radarów (zakres 1..10)</label>
              <InputNum
                type="number"
                min="1"
                max="10"
                value={minMax(nradardistance, 1, 10)}
                handleChange={(e) => {
                  setNradardistance(e.value === "" ? null : e.target.value);
                }}
                name="nradardistance"
                error={checkError(nradardistance)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>
                Odległość ostrzegania radarów w mieście (zakres 1..10)
              </label>
              <InputNum
                type="number"
                min="1"
                max="10"
                value={minMax(nradardistanceincity, 1, 10)}
                handleChange={(e) => {
                  setNradardistanceincity(
                    e.target.value === "" ? null : e.target.value
                  );
                }}
                name="nradardistanceincity"
                error={checkError(nradardistanceincity)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Skóra</label>
              <Select
                className={`${stylesMod.select}`}
                options={nskin_o}
                value={nskin === undefined ? "" : nskin_o[nskin]}
                name="nskin"
                placeholder="Wybierz..."
                onChange={(e) => {
                  setNskin(e.value === "" ? null : e.value);
                }}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                    }),
                  }),
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Strefa czasowa w minutach</label>
              <InputNum
                type="number"
                value={ntimezone}
                handleChange={(e) => {
                  setNtimezone(e.target.value === "" ? null : e.target.value);
                }}
                name="ntimezone"
                error={checkError(ntimezone)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>
                Ostrzeżenie o przekroczeniu prędkości, gdy maksymalna dozwolona
                prędkość w mieście zostanie przekroczona o podaną wartość.
              </label>
              <InputNum
                type="number"
                value={nspeedexceedincity}
                handleChange={(e) => {
                  setNspeedexceedincity(
                    e.target.value === "" ? null : e.target.value
                  );
                }}
                name="nspeedexceedincity"
                error={checkError(nspeedexceedincity)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>
                Ostrzeżenie o przekroczeniu prędkości, gdy maksymalna dozwolona
                prędkość poza miastem zostanie przekroczona o podaną wartość.
              </label>
              <InputNum
                type="number"
                value={nspeedexceed}
                handleChange={(e) => {
                  setNspeedexceed(
                    e.target.value === "" ? null : e.target.value
                  );
                }}
                name="nspeedexceed"
                error={checkError(nspeedexceed)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Tryb podglądu</label>
              <Select
                className={`${stylesMod.select}`}
                options={nview_o}
                value={
                  nview === undefined
                    ? ""
                    : nview === 0
                    ? nview_o[0]
                    : nview === 1
                    ? nview_o[1]
                    : nview === 2
                    ? nview_o[2]
                    : ""
                }
                name="nview"
                placeholder="Wybierz..."
                onChange={(e) => {
                  setNview(e.value === "" ? null : e.value);
                }}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                    }),
                  }),
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Wyświetlane drogowskazy</label>
              <Select
                className={`${stylesMod.select}`}
                options={nsignpostdirection_o}
                value={
                  nsignpostdirection === undefined
                    ? ""
                    : nsignpostdirection === 0
                    ? nsignpostdirection_o[0]
                    : nsignpostdirection === 1
                    ? nsignpostdirection_o[1]
                    : nsignpostdirection === 2
                    ? nsignpostdirection_o[2]
                    : ""
                }
                name="nsignpostdirection"
                placeholder="Wybierz..."
                onChange={(e) => {
                  setNsignpostdirection(e.value === "" ? null : e.value);
                }}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                    }),
                  }),
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Rozmiar wyświetlanych drogowskazów</label>
              <Select
                className={`${stylesMod.select}`}
                options={nsignpostsize_o}
                value={
                  nsignpostsize === undefined
                    ? ""
                    : nsignpostsize === 0
                    ? nsignpostsize_o[0]
                    : nsignpostsize === 1
                    ? nsignpostsize_o[1]
                    : nsignpostsize === 2
                    ? nsignpostsize_o[2]
                    : ""
                }
                name="nsignpostsize"
                placeholder="Wybierz..."
                onChange={(e) => {
                  setNsignpostsize(e.value === "" ? null : e.value);
                }}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                    }),
                  }),
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Maksymalna prędkość pojazdu [km/h]</label>
              <InputNum
                type="number"
                value={ntruckmaxspeed}
                handleChange={(e) => {
                  setNtruckmaxspeed(
                    e.target.value === "" ? null : e.target.value
                  );
                }}
                name="ntruckmaxspeed"
                error={checkError(ntruckmaxspeed)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Nacisk na oś tandemową [kg].</label>
              <InputNum
                type="number"
                value={ntrucktandemweight}
                handleChange={(e) => {
                  setNtrucktandemweight(
                    e.target.value === "" ? null : e.target.value
                  );
                }}
                name="ntrucktandemweight"
                error={checkError(ntrucktandemweight)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Nacisk na oś Tridem [kg]</label>
              <InputNum
                type="number"
                value={ntrucktridemweight}
                handleChange={(e) => {
                  setNtrucktridemweight(
                    e.target.value === "" ? null : e.target.value
                  );
                }}
                name="ntrucktridemweight"
                error={checkError(ntrucktridemweight)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Inna waga [kg]</label>
              <InputNum
                type="number"
                value={ntruckotherweight}
                handleChange={(e) => {
                  setNtruckotherweight(
                    e.target.value === "" ? null : e.target.value
                  );
                }}
                name="ntruckotherweight"
                error={checkError(ntruckotherweight)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Masa pojazdu bez ładunku [kg]</label>
              <InputNum
                type="number"
                value={ntruckunladenweight}
                handleChange={(e) => {
                  setNtruckunladenweight(
                    e.target.value === "" ? null : e.target.value
                  );
                }}
                name="ntruckunladenweight"
                error={checkError(ntruckunladenweight)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Ekstremalna długość osi [mm]</label>
              <InputNum
                type="number"
                value={ntruckaxlelength}
                handleChange={(e) => {
                  setNtruckaxlelength(
                    e.target.value === "" ? null : e.target.value
                  );
                }}
                name="ntruckaxlelength"
                error={checkError(ntruckaxlelength)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Długość przyczepy [mm]</label>
              <InputNum
                type="number"
                value={ntrailerlength}
                handleChange={(e) => {
                  setNtrailerlength(
                    e.target.value === "" ? null : e.target.value
                  );
                }}
                name="ntrailerlength"
                error={checkError(ntrailerlength)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Długość ciągnika [mm]</label>
              <InputNum
                type="number"
                value={ntractorlength}
                handleChange={(e) => {
                  setNtractorlength(
                    e.target.value === "" ? null : e.target.value
                  );
                }}
                name="ntractorlength"
                error={checkError(ntractorlength)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Kingpin do ostatniej osi [mm]</label>
              <InputNum
                type="number"
                value={nkingpinlastaxle}
                handleChange={(e) => {
                  setNkingpinlastaxle(
                    e.target.value === "" ? null : e.target.value
                  );
                }}
                name="nkingpinlastaxle"
                error={checkError(nkingpinlastaxle)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Kingpin do połowy ostatniego tandemu [mm]</label>
              <InputNum
                type="number"
                value={nkingpinlasttandem}
                handleChange={(e) => {
                  setNkingpinlasttandem(
                    e.target.value === "" ? null : e.target.value
                  );
                }}
                name="nkingpinlasttandem"
                error={checkError(nkingpinlasttandem)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>
                Kingpin do końca przyczepy (ostatnia przyczepa, jeśli dotyczy)
                [mm]
              </label>
              <InputNum
                type="number"
                value={nkingpinendtrailer}
                handleChange={(e) => {
                  setNkingpinendtrailer(
                    e.target.value === "" ? null : e.target.value
                  );
                }}
                name="nkingpinendtrailer"
                error={checkError(nkingpinendtrailer)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Inna długość [mm]</label>
              <InputNum
                type="number"
                value={ntruckotherlength}
                handleChange={(e) => {
                  setNtruckotherlength(
                    e.target.value === "" ? null : e.target.value
                  );
                }}
                name="ntruckotherlength"
                error={checkError(ntruckotherlength)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <label>Ograniczenia obciążenia</label>
              <InputNum
                type="number"
                value={nloadrestrictions}
                handleChange={(e) => {
                  setNloadrestrictions(
                    e.target.value === "" ? null : e.target.value
                  );
                }}
                name="nloadrestrictions"
                error={checkError(nloadrestrictions)}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={`${stylesMod.inp}`}>
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={ballowitineraryedit === 1 ? true : false}
                    color="primary"
                    onChange={(e) => {
                      setBallowitineraryedit(e.target.checked === true ? 1 : 0);
                    }}
                  />
                }
                label="Czy użytkownik będzie mógł ręcznie edytować plan podróży?"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={`${stylesMod.inp}`}>
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={bdisablemainmenu === 1 ? true : false}
                    color="primary"
                    onChange={(e) => {
                      setBdisablemainmenu(e.target.checked === true ? 1 : 0);
                    }}
                  />
                }
                label="Menu główne"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={`${stylesMod.inp}`}>
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={bdisablerecompute === 1 ? true : false}
                    color="primary"
                    onChange={(e) => {
                      setBdisablerecompute(e.target.checked === true ? 1 : 0);
                    }}
                  />
                }
                label="Ponownego obliczania trasy przy zjeżdżaniu z trasy"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={`${stylesMod.inp}`}>
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={bradarswarnon === 1 ? true : false}
                    color="primary"
                    onChange={(e) => {
                      setBradarswarnon(e.target.checked === true ? 1 : 0);
                    }}
                  />
                }
                label="Ostrzeżenie o fotoradarach"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={`${stylesMod.inp}`}>
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={bradarsvisible === 1 ? true : false}
                    color="primary"
                    onChange={(e) => {
                      setBradarsvisible(e.target.checked === true ? 1 : 0);
                    }}
                  />
                }
                label="Radary"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={`${stylesMod.inp}`}>
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={bsnaptoeveryroad === 1 ? true : false}
                    color="primary"
                    onChange={(e) => {
                      setBsnaptoeveryroad(e.target.checked === true ? 1 : 0);
                    }}
                  />
                }
                label="Umożliwia przyciąganie pozycji do zakazanych dróg"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={`${stylesMod.inp}`}>
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={bmaxspeedwarn === 1 ? true : false}
                    color="primary"
                    onChange={(e) => {
                      setBmaxspeedwarn(e.target.checked === true ? 1 : 0);
                    }}
                  />
                }
                label="Ostrzeżenie o przekroczeniu prędkości akustycznej"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={`${stylesMod.inp}`}>
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={bsoundenabled === 1 ? true : false}
                    color="primary"
                    onChange={(e) => {
                      setBsoundenabled(e.target.checked === true ? 1 : 0);
                    }}
                  />
                }
                label="Dzwięk"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={`${stylesMod.inp}`}>
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={bttsenabled === 1 ? true : false}
                    color="primary"
                    onChange={(e) => {
                      setBttsenabled(e.target.checked === true ? 1 : 0);
                    }}
                  />
                }
                label="Dźwięk TTS"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={`${stylesMod.inp}`}>
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={nvisiblepointreachdistance === 1 ? true : false}
                    color="primary"
                    onChange={(e) => {
                      setNvisiblepointreachdistance(
                        e.target.checked === true ? 1 : 0
                      );
                    }}
                  />
                }
                label="Odległość zasięgu widocznego punktu"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={`${stylesMod.inp}`}>
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={ninvisiblepointreachdistance === 1 ? true : false}
                    color="primary"
                    onChange={(e) => {
                      setNinvisiblepointreachdistance(
                        e.target.checked === true ? 1 : 0
                      );
                    }}
                  />
                }
                label="Niewidoczna odległość zasięgu punktu"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={`${stylesMod.inp}`}>
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={ballowclosedroads === 1 ? true : false}
                    color="primary"
                    onChange={(e) => {
                      setBallowclosedroads(e.target.checked === true ? 1 : 0);
                    }}
                  />
                }
                label="Zezwól na zamknięte drogi"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={`${stylesMod.inp}`}>
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={btruckinmap === 1 ? true : false}
                    color="primary"
                    onChange={(e) => {
                      setBtruckinmap(e.target.checked === true ? 1 : 0);
                    }}
                  />
                }
                label="Czy atrybuty ciężarówki są zawarte na mapie"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={`${stylesMod.inp}`}>
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={busetruckatt === 1 ? true : false}
                    color="primary"
                    onChange={(e) => {
                      setBusetruckatt(e.target.checked === true ? 1 : 0);
                    }}
                  />
                }
                label="Użyj atrybutów ciężarówki"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={`${stylesMod.inp}`}>
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={gigaliner_trucks === true ? true : false}
                    color="primary"
                    onChange={(e) => {
                      setGigaliner_trucks(
                        e.target.checked === true ? true : false
                      );
                    }}
                  />
                }
                label="Gigaliner"
              />
            </div>
          </Grid>

          {ifTraffic_enabled !== undefined && ifTraffic_enabled === true ? (
            <Grid item xs={12}>
              <div className={`${stylesMod.inp}`}>
                <FormControlLabel
                  className={stylesMod.smallerFont}
                  control={
                    <GreenSwitch
                      checked={btrafficenabled === true ? true : false}
                      color="primary"
                      onChange={(e) => {
                        setBtrafficenabled(
                          e.target.checked === true ? true : false
                        );
                      }}
                    />
                  }
                  label="Natężenie ruchu"
                />
              </div>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </DialogContent>

      <DialogActions className={stylesMod.stickyButtons}>
        <Button
          variant="contained"
          size="large"
          color="primary"
          className={`${BTNstyles.btn} ${BTNstyles.err}`}
          onClick={(e) => {
            props.setOpen(false);
            cleanValues();
          }}
        >
          Zamknij
        </Button>

        <Button
          variant="contained"
          size="large"
          className={`${BTNstyles.btn} ${BTNstyles.grn}`}
          onClick={(e) => {
            const fetchUrl = `${DBurl}/setCarsTabletsProfiles/`;
            const toNumber = (d) =>
              typeof d === "string" ? Number(d) : d === undefined ? null : d;
            const toCheckbox = (d) => (d === null || d === undefined ? 0 : d);

            const data = {
              bsoundenabled: toCheckbox(bsoundenabled),
              boperaterighthanded:
                boperaterighthanded === undefined ? null : boperaterighthanded,
              nvolumemin: toNumber(nvolumemin),
              nvolumemax: toNumber(nvolumemax),
              distanceunit: distanceunit === undefined ? null : distanceunit,
              clockformat: clockformat === undefined ? null : clockformat,
              gpsunits: gpsunits === undefined ? null : gpsunits,
              keyboardtype: keyboardtype === undefined ? null : keyboardtype,
              navoidtollroads:
                navoidtollroads === undefined ? null : navoidtollroads,
              bavoiduturns: bavoiduturns === undefined ? null : bavoiduturns,
              nplanningsettings:
                nplanningsettings === undefined ? null : nplanningsettings,
              nplanningsettingslimitedspeed: toNumber(
                nplanningsettingslimitedspeed
              ),
              bavoidferries: bavoidferries === undefined ? null : bavoidferries,
              bdisablemainmenu: toCheckbox(bdisablemainmenu),
              bdisablerecompute: toCheckbox(bdisablerecompute),
              netamaximumspeed: toNumber(netamaximumspeed),
              netapercentagechange: toNumber(netapercentagechange),
              bradarswarnon: toCheckbox(bradarswarnon),
              bradarsvisible: toCheckbox(bradarsvisible),
              nradardistance: toNumber(nradardistance),
              nradardistanceincity: toNumber(nradardistanceincity),
              nskin: nskin === undefined ? null : nskin,
              ntimezone: toNumber(ntimezone),
              nspeedexceedincity: toNumber(nspeedexceedincity),
              nspeedexceed: toNumber(nspeedexceed),
              nview: nview === undefined ? null : nview,
              nsignpostdirection:
                nsignpostdirection === undefined ? null : nsignpostdirection,
              nsignpostsize: nsignpostsize === undefined ? null : nsignpostsize,
              bsnaptoeveryroad: toCheckbox(bsnaptoeveryroad),
              bmaxspeedwarn: toCheckbox(bmaxspeedwarn),
              bttsenabled: toCheckbox(bttsenabled),
              nvisiblepointreachdistance: toCheckbox(
                nvisiblepointreachdistance
              ),
              ninvisiblepointreachdistance: toCheckbox(
                ninvisiblepointreachdistance
              ),
              ballowclosedroads: toCheckbox(ballowclosedroads),
              btruckinmap: toCheckbox(btruckinmap),
              busetruckatt: toCheckbox(busetruckatt),
              ntruckmaxspeed: toNumber(ntruckmaxspeed),
              ntruckweighttotal: toNumber(ntruckweighttotal),
              ntruckweightaxle: toNumber(ntruckweightaxle),
              ntrucktandemweight: toNumber(ntrucktandemweight),
              ntrucktridemweight: toNumber(ntrucktridemweight),
              ntruckotherweight: toNumber(ntruckotherweight),
              ntruckunladenweight: toNumber(ntruckunladenweight),
              ntrucklenght: toNumber(ntrucklenght),
              ntruckaxlelength: toNumber(ntruckaxlelength),
              ntrailerlength: toNumber(ntrailerlength),
              ntractorlength: toNumber(ntractorlength),
              nkingpinlastaxle: toNumber(nkingpinlastaxle),
              nkingpinlasttandem: toNumber(nkingpinlasttandem),
              nkingpinendtrailer: toNumber(nkingpinendtrailer),
              ntruckotherlength: toNumber(ntruckotherlength),
              ntruckwidth: toNumber(ntruckwidth),
              ntruckheight: toNumber(ntruckheight),
              nloadrestrictions: toNumber(nloadrestrictions),
              ballowitineraryedit: toCheckbox(ballowitineraryedit),

              h_type:
                h_type === 0 ? "straight" : h_type === 1 ? "tractor" : null, //'tractor',
              h_axle_count: h_axle_count ? Number(h_axle_count) : null, //3,
              h_trailer_count: h_trailer_count ? h_trailer_count : null, //1,
              h_tunel_category: tunelCategory(h_tunel_category), //'B'
              h_shipped_hazardous_goods: h_shipped_hazardous_goods.toString(), //'explosive,gas,flammable,combustible,organic,poison,radioactive,corrosive,poisonousInhalation,harmfulToWater,other',
              gigaliner_trucks: gigaliner_trucks ? true : false, //true
              btrafficenabled: btrafficenabled ? true : false, //true

              // id: props.edit? props.data.id : null,
            };

            const ifEdit = props.data.car_profile !== null ? true : false;

            if (ifEdit) {
              data.id = props.data.car_profile.id;
            } else {
              data.id_car =
                props.newOrder === true ? props.data.id_car : props.data.id;
            }

            const validData = Object.values(data).every((value) => {
              if (value !== null) {
                return true;
              }
              return false;
            });

            if (validData === false) {
              setError(true);

              props.setDialog({
                isOpen: true,
                type: "error",
                content: "Proszę o wypełnienie wszystkich pól.",
              });
            } else {
              setError(false);
              fetch(fetchUrl, {
                method: "post",
                credentials: "include",
                headers: {
                  "Content-Type": "application/json",
                  "X-CSRF-Token": props.user.csrf_token,
                },
                body: JSON.stringify({
                  mode: ifEdit ? "update" : "insert",
                  data: data,
                }),
              })
                .then((res) => res.json())
                .then((response) => {
                  if (response.success) {
                    fetchCarsWithProfiles();
                    props.setDialog({
                      isOpen: true,
                      type: "success",
                      content: ifEdit ? "Zapisano zmiany" : "Profil dodany",
                    });
                    props.refresh();

                    if (props.newOrderProfileUpdate) {
                      props.updateCarProfile(data);
                    }

                    props.setOpen(false);
                  } else {
                    props.setDialog({
                      isOpen: true,
                      type: "error",
                      content: "Błąd zapisu profilu",
                    });
                  }
                });
            }
          }}
          color="primary"
        >
          {props.data.car_profile === null ? "Dodaj profil" : "Edytuj profil"}{" "}
          {props.data.car_profile === null ? (
            <Add className={BTNstyles.downBtnIco} />
          ) : (
            <Edit className={BTNstyles.downBtnIco} />
          )}
        </Button>
      </DialogActions>

      <Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={dialogColor}>
          {dialogContent}
        </Alert>
      </Snackbar>
    </Dialog>
  );
}
