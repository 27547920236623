const appendZero = (value) => (value.length === 1 ? `0${value}` : `${value}`);

const checkInvType = (invType, selectedType, isCorrection) => {
  if (isCorrection && invType === 3 && selectedType === 1) {
    return true;
  }
  return invType === selectedType;
};
const removePrefix = (number, prefix) => {
  if (!number) {
    return number;
  }
  if (number.includes(`${prefix} `)) {
    return number.split(`${prefix} `)[1].split("/")[0];
  }
  return number.split(`${prefix}`)[1].split("/")[0];
};

const getInvNumber = (
  { debit_note_no, credit_note_no, invoice_no, type: invType, correction_no },
  type,
  invFormat,
  prefix,
  isCorrection
) => {
  if (
    isCorrection &&
    type === 1 &&
    correction_no &&
    correction_no.startsWith("KO")
  ) {
    const correctionNumber =
      correction_no && correction_no.includes(`${prefix}/`)
        ? correction_no.replace(`${prefix}`, "")
        : correction_no.replace(`${prefix}`, "");
    return removePrefix(correctionNumber, "KO");
  }
  if (invType === 4 && type === 4) {
    const debNoteNoPrefix =
      debit_note_no && debit_note_no.includes(`${prefix}/`)
        ? debit_note_no.replace(`${prefix}/`, "")
        : debit_note_no.replace(`${prefix}`, "");
    return removePrefix(debNoteNoPrefix, "NO");
  }
  if (invType === 5 && type === 5) {
    if (!credit_note_no || credit_note_no === null) {
      return removePrefix(credit_note_no, "NU");
    }
    const credNoteNoPrefix =
      credit_note_no && credit_note_no.includes(`${prefix}/`)
        ? credit_note_no.replace(`${prefix}/`, "")
        : credit_note_no.replace(`${prefix}`, "");
    return removePrefix(credNoteNoPrefix, "NU");
  }

  const invNoPrefix =
    invoice_no && invoice_no.includes(`${prefix}/`)
      ? invoice_no.replace(`${prefix}/`, "")
      : invoice_no.replace(`${prefix}`, "");

  return parseInt(invNoPrefix.split("/")[0]);
};

const getPrefix = (documentType, isCorrection) => {
  if (documentType === 4) {
    return "NO ";
  }
  if (documentType === 5) {
    return "NU ";
  }
  if (isCorrection) {
    return "KO ";
  }
  return "";
};

export const getNewInvoiceNumber = (
  invoices,
  issueDate,
  saleServiceDate,
  documentType,
  invFormat,
  special,
  isCorrection,
  sufix
) => {
  const date = new Date(issueDate);
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  if (invoices?.length > 0) {
    const filteredInvoices = invoices
      ?.filter((inv) => {
        const issueDate = new Date(inv.date_of_issue);
        const issueMonth = issueDate.getMonth() + 1;
        const issueYear = issueDate.getFullYear();
        const { correction_no } = inv;
        if (invoices.length === 0) {
          if (!invFormat) {
            const newNumber = `${getPrefix(
              documentType.value,
              isCorrection
            )}${invoiceNumber}/${appendZero(month)}/${year}`;
            return newNumber;
          }
          let newNum = invFormat;
          newNum = newNum.replace("n", invoiceNumber);
          newNum = newNum.replace("s", invFormat.includes("s") ? special : "");
          newNum = newNum.replace("mm", appendZero(month));
          newNum = newNum.replace("yyyy", year);
          return `${isCorrection ? "KO " : ""}${getPrefix(
            documentType.value
          )}${newNum}${sufix ? `/${sufix}` : ""}`;
        }
        if (inv.is_deleted) {
          return false;
        }
        if (
          isCorrection &&
          (correction_no === undefined || correction_no === null)
        ) {
          return false;
        }
        if (
          isCorrection &&
          typeof correction_no === "string" &&
          !correction_no.includes("/20")
        ) {
          return false;
        }
        if (correction_no !== undefined) {
          if (
            isCorrection &&
            correction_no &&
            !correction_no?.startsWith("KO ")
          ) {
            return false;
          }
        }
        if (
          isNaN(
            getInvNumber(
              inv,
              documentType.value,
              invFormat,
              special,
              isCorrection
            )
          )
        ) {
          return false;
        }
        return (
          issueMonth === month &&
          issueYear === year &&
          checkInvType(inv.type, documentType.value, isCorrection)
        );
      })
      .map((inv) => {
        return getInvNumber(
          inv,
          documentType.value,
          invFormat,
          special,
          isCorrection
        );
      });

    const invoiceNumber =
      filteredInvoices?.length === 0
        ? 1
        : Math.max(...[...new Set(filteredInvoices)]) + 1 || 1;

    if (!invFormat) {
      const newNumber = `${getPrefix(
        documentType.value,
        isCorrection
      )}${invoiceNumber}/${appendZero(month)}/${year}`;
      return newNumber;
    }

    let newNum = invFormat;
    newNum = newNum.replace("n", invoiceNumber);
    newNum = newNum.replace("s", invFormat.includes("s") ? special : "");
    newNum = newNum.replace("mm", appendZero(month));
    newNum = newNum.replace("yyyy", year);

    return `${isCorrection ? "KO " : ""}${getPrefix(
      documentType.value
    )}${newNum}${sufix ? `/${sufix}` : ""}`;
  }
};

export const getInvNumberFromProps = (props) => {
  const id = props?.state?.fetchInvoiceId;
  return id;
};
