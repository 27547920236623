import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Input from "../../components/input";
import { DBurl } from "../../appConfig";
import Title from "../../components/title";
import styles from "../table.module.css";
import Button from "@material-ui/core/Button";
import BTNstyles from "../../styles/button.module.css";
import stylesMod from "../../styles/newOrEdit.module.css";
import RowsPerPage from "../../components/RowsPerPage";
import Pagination from "../../components/pagination";
import Alert from "@material-ui/lab/Alert";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Slide,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import AddVehicles from "./addVehicles";
import { X } from "react-feather";
import IcoBtn from "../../components/buttons/icoBtn";
import { getTrailers, getTrailersTypes } from "api/endpoints";
import { useQuery } from "react-query";
import TmsLoading from "components/TmsLoading";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (array?.length > 0) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
}

const headCells = [
  { id: "id", right: false, disablePadding: true, label: "LP." },
  { id: "registration", right: false, disablePadding: true, label: "Naczepa" },
  { id: "VIN", right: true, disablePadding: false, label: "VIN" },
  {
    id: "registered",
    right: true,
    disablePadding: false,
    label: "Wyprodukowany",
  },
  {
    id: "type",
    right: true,
    disablePadding: false,
    label: "Typ",
    width: "220px",
  },
  { id: "weight", right: true, disablePadding: false, label: "Masa Własna" },
  { id: "OCValidTo", right: true, disablePadding: false, label: "Termin OC" },
  { id: "ACValidTo", right: true, disablePadding: false, label: "Termin AC" },
  {
    id: "carReviewValidTo",
    right: true,
    disablePadding: false,
    label: "Przegląd ważny do:",
  },
  { id: "comment", right: true, disablePadding: false, label: "Uwagi" },
];

function EnhancedTableHead(props) {
  const { styles, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={styles.tabHead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.right ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={headCell.class}
            style={{ width: headCell.width }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={styles.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  styles: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function useInput({ type }) {
  const [value, setValue] = useState("");
  const input = (
    <TableCell>
      <Input
        value={value}
        handleChange={(e) => {
          setValue(e.target.value);
        }}
        type={type}
        search={true}
      />
    </TableCell>
  );
  return [value, input];
}

export default function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("short");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [table, setTable] = useState([]);
  const [searchVehicle, setSearchVehicle] = useInput({ type: "text" });
  const [searchVIN, setSearchVIN] = useInput({ type: "text" });
  const [searchComment, setSearchComment] = useInput({ type: "text" });
  const [openSnack, setOpenSnack] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [deleteData, setDeleteData] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openAddModalData, setOpenAddModalData] = useState({});
  const [edit, setEdit] = useState(false);
  const [trailersType, setTrailersType] = useState([]);

  const { refetch: refetchTrailers, isLoading: isTrailersLoading } = useQuery(
    "getTrailers",
    getTrailers,
    {
      onSuccess: (data) => {
        setTable(data);
      },
      onError: (error) => {
        props.setDialog({
          isOpen: true,
          type: "error",
          content: `Błąd podczas pobierania listy przyczep // ${error.message}`,
        });
      },
    }
  );

  const { isLoading: isTrailersTypeLoading } = useQuery(
    "getTrailersTypes",
    getTrailersTypes,
    {
      onSuccess: (data) => {
        setTrailersType(data);
      },
      onError: (error) => {
        props.setDialog({
          isOpen: true,
          type: "error",
          content: `Błąd podczas pobierania listy typów przyczep // ${error.message}`,
        });
      },
    }
  );

  const delVehicles = async function (arrayOfIds) {
    props.loading(true);
    const response = await fetch(`${DBurl}/setCars/`, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "delete",
        data: {
          ids: arrayOfIds,
        },
      }),
    });
    const json = await response.json();
    if (json.success) {
      props.setDialog({
        isOpen: true,
        type: "success",
        content: `Pojazd został usunięty z bazy`,
      });
      refetchTrailers();
      props.loading(false);
      return json;
    } else {
      props.setDialog({
        isOpen: true,
        type: "error",
        content: `Nie udało się usunąć // ${json.responseDescription} // ${json.response}`,
      });
      props.loading(false);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDialog(false);
  };

  const search = (tbl) => {
    if (tbl?.length > 0) {
      return tbl.filter((row) => {
        return (
          String(row.name).toLowerCase().indexOf(searchVehicle.toLowerCase()) >
            -1 &&
          String(row.vin).toLowerCase().indexOf(searchVIN.toLowerCase()) > -1 &&
          String(row.description)
            .toLowerCase()
            .indexOf(searchComment.toLowerCase()) > -1
        );
      });
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = table.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const paginate = (pageNumber) => setPage(pageNumber - 1);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const isLoading = isTrailersLoading || isTrailersTypeLoading;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, table?.length - page * rowsPerPage);

  return (
    <>
      <div className={styles.rootFlex}>
        <div className={styles.tableBox}>
          <Paper className={styles.paper}>
            <Title
              title={props.state.tabName}
              btnBox
              btnAdd
              addFunc={() => {
                setOpenAddModalData({});
                setEdit(false);
                setOpenAddModal(true);
              }}
            />
            <TableContainer className={styles.stickyTable}>
              <Table
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
                className={styles.table}
              >
                <EnhancedTableHead
                  styles={styles}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={table.length}
                />

                <TableBody className={styles.tabBody}>
                  <TableRow>
                    <TableCell></TableCell>
                    {setSearchVehicle}
                    {setSearchVIN}
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    {setSearchComment}
                    <TableCell></TableCell>
                  </TableRow>
                  {stableSort(search(table), getComparator(order, orderBy))
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          selected={isItemSelected}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.name}
                          </TableCell>
                          <TableCell align="right">{row.vin}</TableCell>
                          <TableCell align="right">
                            {row.rok_produkcji}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ minWidth: "200px" }}
                          >
                            {row.trailer_data[0]?.name}
                          </TableCell>
                          <TableCell align="right">
                            {row.axle_netto} kg
                          </TableCell>
                          <TableCell align="right">
                            {row.term_oc?.slice(0, 10)}
                          </TableCell>
                          <TableCell align="right">
                            {row.term_ac?.slice(0, 10)}
                          </TableCell>
                          <TableCell align="right">
                            {row.inspection_valid_until?.slice(0, 10)}
                          </TableCell>
                          <TableCell align="right">{row.description}</TableCell>
                          <TableCell align="right">
                            <div className={styles.buttonBox}>
                              <IcoBtn
                                icon="Edit"
                                tooltip="edytuj"
                                value={row}
                                onClick={(e) => {
                                  setOpenAddModalData(row);
                                  setEdit(true);
                                  setOpenAddModal(true);
                                }}
                              />
                              <IcoBtn
                                icon="X"
                                tooltip="Usuń"
                                value={row}
                                className={`${
                                  !!row.deviceid && styles.hidden
                                } btn-delete`}
                                onClick={(e) => {
                                  setDeleteData([row.id]);
                                  setOpenModalDelete(true);
                                }}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div className={styles.tableBottom}>
              <RowsPerPage
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setPage={setPage}
                tableLength={table?.length}
                page={page}
              />
              <div>
                <Pagination
                  postsPerPage={rowsPerPage}
                  totalPosts={search(table)?.length}
                  paginate={paginate}
                />
              </div>
            </div>
          </Paper>
        </div>

        {openAddModal && (
          <AddVehicles
            open={openAddModal}
            setOpen={setOpenAddModal}
            data={openAddModalData}
            user={props.user}
            edit={edit}
            refresh={refetchTrailers}
            trailer
            setOpenSnack={setOpenDialog}
            setSnackContent={setDialogContent}
            setSnackColor={setDialogColor}
            setDialog={props.setDialog}
            typeOptions={trailersType.map((e) => {
              return { label: e.name, value: e.id };
            })}
          />
        )}

        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={(e, r) => {
            if (r === "clickaway") {
              return;
            }
            setOpenSnack(false);
          }}
        >
          <Alert
            onClose={(e, r) => {
              if (r === "clickaway") {
                return;
              }
              setOpenSnack(false);
            }}
            severity="success"
          ></Alert>
        </Snackbar>

        <Snackbar
          open={openDialog}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={dialogColor}>
            {dialogContent}
          </Alert>
        </Snackbar>

        <Dialog
          open={openModalDelete}
          TransitionComponent={Transition}
          onClose={() => {
            setDeleteData([]);
            setOpenModalDelete(false);
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            className={stylesMod.dialogHead}
          >
            Czy napewno chcesz usunąć Pojazd?{" "}
            <IconButton
              aria-label="close"
              className={stylesMod.closeButton}
              onClick={() => {
                setDeleteData([]);
                setOpenModalDelete(false);
              }}
            >
              <X />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Czy napewno chcesz usunąć Pojazdy:{" "}
              {selected.map((e) => {
                const delOrder = table.find((el) => el.id === e);
                return (
                  <>
                    <br />
                    {delOrder.name}{" "}
                  </>
                );
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={BTNstyles.btn}
              onClick={(e) => {
                setOpenModalDelete(false);
              }}
            >
              Anuluj
            </Button>
            <Button
              variant="contained"
              size="large"
              className={`${BTNstyles.btn} ${BTNstyles.err}`}
              onClick={() => {
                delVehicles(deleteData);
                setSelected([]);
                setOpenModalDelete(false);
                setDeleteData([]);
              }}
              color="primary"
            >
              Usuń <X className={BTNstyles.downBtnIco} />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {isTrailersLoading && <TmsLoading status="Pobieranie naczep" />}
    </>
  );
}
