import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import styles from "./MainView.module.css";
import TopBar from "../../components/topbar";
import TabBar from "../../components/tabBar";
import * as Comp from "../../states";

import AddClient from "../client/AddClient";
import ClientList from "../client/ClientList";
import Goods from "../client/Goods";
import Warehouse from "../client/Warehouse";
import InvoiceAdd from "../account/InvoiceAdd";
import InvoiceAdd_S from "../account/InvoiceAdd_S";
import InvoiceList from "../account/InvoiceList";
import InvoiceList_S from "../account/InvoiceList_S";
import Contractors from "../account/Contractors";
import ExchangeRates from "../account/ExchangeRates";
import Payments from "../account/Payments";
import WorkTime from "../fleet/WorkTime";
import Fleet from "../fleet/FleetState";
import Fleet_S from "../fleet/FleetState_S";
import Map from "../fleet/Map";
import Exploitation from "../fleet/Exploitation";
import FuelCards from "containers/fleet/FuelCards";
import LoadingUnloading from "../reports/LoadingUnloading";
import LoadingUnloading_S from "../reports/LoadingUnloading_S";
import ForeignEpisodes from "../reports/ForeignEpisodes";
import RaportEKD from "../reports/RaportEKD";
import CiclesReports from "../reports/CiclesReports";
import Settings from "../configuration";
import Communication from "../communication";
import Reports from "../reports";
import NewOrder from "containers/order/newOrder/newOrder";
import NewOrderSped from "../order/newOrderSped";
import Tags from "../order/Tags";
import OrderList from "../order/OrderList";
import OrderList_S from "../order/OrderList_S";
import TimeTable from "../order/TimeTable";
import TimeTable_S from "../order/TimeTable_S";
import Drivers from "../settings/Drivers";
import Vehicles from "../settings/Vehicles";
import Trailers from "../settings/Trailer";
import TransportSet from "../fleet/TransportSet";
import Departments from "../account/Departments";
import AccountSettings from "../account/AccountSettings";
import CostsList from "../account/CostsList";
import PurchaseList from "../account/PurchaseList";
import OparatorsList from "../configuration/ClientList";
import OparatorsListSA from "../configuration/ClientListSA";
import CompanyList from "../configuration/CompanyList";
import UsersList from "../configuration/UsersList";
import OrderActions from "../order/OrderActions";
import InvoiceAddNew from "../account/AddInvoice/InvoiceAddNew";
import VehiclesDocs from "../documents/vehicles";
import DriversDocs from "../documents/drivers";
import TrailersDocs from "../documents/trailers";
import CompanyDocs from "../documents/company";
import DeadlinesDocs from "../documents/deadlines";
import FinishedOrders from "containers/reports/FinishedOrders";
import PromotionBaner from "../../components/PromotionBaner";
import PromotionStartModal from "../../components/PromotionStartModal";
import BorderCrossings from "components/BorderCrossings/BorderCrossings";
import DriversActivity from "containers/reports/DriversActivity";
import InvoiceTags from "containers/account/InvoiceTags";
import FavouriteRoutes from "components/FavouriteRoutes";
import CostsImport from "components/CostsImport/CostsImport";
import DriversSettlement from "components/DriversSettlement";
import DriverNotes from "components/DriverNotes";

export default function MainView(props) {
  const [tabs, setTabs] = useState([]);
  const [tabIdx, setTabIdx] = useState(0);
  const [openBaner, setOpenBaner] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);

  const [isPromotionUser, setPromotionUser] = useState({
    active: false,
    start: "",
    end: "",
    is_promo_account: false,
    promo_banner: false,
    video: false,
  });

  const checkPermission = (tabsArray, number, isIndex, order) => {
    var result = tabsArray.filter((e) =>
      props.user.permissions_ids.includes(e.permission_id)
    );
    if (isIndex) {
      return result.find((e) => e.active)?.tabID || 0;
    } else if (result.filter((e) => e.active).length < 1) {
      result[0].active = true;
      return result;
    } else {
      return result;
    }
  };

  const addComponent = (comp) => {
    switch (comp) {
      case "AddClient":
        return AddClient;
      case "FavouriteRoutes":
        return FavouriteRoutes;
      case "BorderCrossings":
        return BorderCrossings;
      case "ClientList":
        return ClientList;
      case "Goods":
        return Goods;
      case "Warehouse":
        return Warehouse;
      case "InvoiceAdd":
        return InvoiceAdd;
      case "InvoiceAdd_S":
        return InvoiceAdd_S;
      case "InvoiceList":
        return InvoiceList;
      case "InvoiceList_S":
        return InvoiceList_S;
      case "Contractors":
        return Contractors;
      case "Payments":
        return Payments;
      case "WorkTime":
        return WorkTime;
      case "Fleet":
        return Fleet;
      case "Fleet_S":
        return Fleet_S;
      case "Map":
        return Map;
      case "Exploitation":
        return Exploitation;
      case "LoadingUnloading":
        return LoadingUnloading;
      case "LoadingUnloading_S":
        return LoadingUnloading_S;
      case "ForeignEpisodes":
        return ForeignEpisodes;
      case "RaportEKD":
        return RaportEKD;
      case "CiclesReports":
        return CiclesReports;
      case "Settings":
        return Settings;
      case "Communication":
        return Communication;
      case "Reports":
        return Reports;
      case "NewOrder":
        return NewOrder;
      case "Tags":
        return Tags;
      case "OrderList":
        return OrderList;
      case "OrderList_S":
        return OrderList_S;
      case "TimeTable":
        return TimeTable;
      case "TimeTable_S":
        return TimeTable_S;
      case "Drivers":
        return Drivers;
      case "Vehicles":
        return Vehicles;
      case "Trailers":
        return Trailers;
      case "TransportSet":
        return TransportSet;
      case "Departments":
        return Departments;
      case "AccountSettings":
        return AccountSettings;
      case "CostsList":
        return CostsList;
      case "PurchaseList":
        return PurchaseList;
      case "OparatorsList":
        return OparatorsList;
      case "OparatorsListSA":
        return OparatorsListSA;
      case "CompanyList":
        return CompanyList;
      case "UsersList":
        return UsersList;
      case "OrderActions":
        return OrderActions;
      case "ExchangeRates":
        return ExchangeRates;
      case "InvoiceAddNew":
        return InvoiceAddNew;
      case "VehiclesDocs":
        return VehiclesDocs;
      case "DriversDocs":
        return DriversDocs;
      case "TrailersDocs":
        return TrailersDocs;
      case "CompanyDocs":
        return CompanyDocs;
      case "DeadlinesDocs":
        return DeadlinesDocs;
      case "FinishedOrders":
        return FinishedOrders;
      case "FuelCards":
        return FuelCards;
      case "NewOrderSped":
        return NewOrderSped;
      case "InvoiceTags":
        return InvoiceTags;
      case "DriversActivity":
        return DriversActivity;
      case "CostsImport":
        return CostsImport;
      case "DriversSettlement":
        return DriversSettlement;
      case "DriverNotes":
        return DriverNotes;
    }
  };

  const addTab = (name, data, savedTab) => {
    var tempTabID = tabs.length;

    switch (name) {
      case "AddClient":
        tabs.push(data || Comp.AddClientState);
        break;

      case "ClientList":
        tabs.push(Comp.ClientListState);
        break;

      case "BorderCrossings":
        tabs.push(Comp.BorderCrossingsState);
        break;

      case "FavouriteRoutes":
        tabs.push(Comp.FavouritesRoutesState);
        break;

      case "Warehouse":
        tabs.push(Comp.WarehouseState);
        break;

      case "Goods":
        tabs.push(Comp.GoodsState);
        break;

      case "Drivers":
        tabs.push(Comp.DriversState);
        break;

      case "Vehicles":
        tabs.push(Comp.VehiclesState);
        break;

      case "Trailers":
        tabs.push(Comp.TrailersState);
        break;

      case "newOrder":
        tabs.push(data || Comp.NewOrderState);
        break;

      case "newOrderSped":
        tabs.push(data || Comp.NewOrderSpedState);
        break;

      case "Tags":
        tabs.push(Comp.TagsState);
        break;

      case "OrderList":
        let OrderListExist = false;
        tabs.forEach((e, i) => {
          if (e.tabName === "Lista zleceń") {
            OrderListExist = true;
            tempTabID = i;
          }
        });
        if (!OrderListExist) {
          tabs.push(Comp.OrderListState);
        }
        break;

      case "OrderList_S":
        let OrderListExist_S = false;
        tabs.forEach((e, i) => {
          if (e.tabName === "Lista zleceń S") {
            OrderListExist_S = true;
            tempTabID = i;
          }
        });
        if (!OrderListExist_S) {
          tabs.push(Comp.OrderListState_S);
        }
        break;

      case "TimeTable":
        tabs.push(Comp.TimeTableState);
        break;

      case "TimeTable_S":
        tabs.push(Comp.TimeTableState_S);
        break;

      case "Map":
        var tempData = { ...Comp.MapState };
        tempData.positon = data;
        tabs.push(tempData);
        break;

      case "FleetState":
        tabs.push(Comp.FleetState);
        break;

      case "FleetState_S":
        tabs.push(Comp.FleetState_S);
        break;

      case "Exploitation":
        tabs.push(Comp.ExploitationState);
        break;

      case "LoadingUnloading":
        tabs.push(Comp.LoadingUnloadingState);
        break;

      case "LoadingUnloading_S":
        tabs.push(Comp.LoadingUnloading_S_State);
        break;

      case "ForeignEpisodes":
        tabs.push(Comp.ForeignEpisodesState);
        break;

      case "RaportEKD":
        tabs.push(Comp.RaportEKDState);
        break;

      case "CiclesReports":
        tabs.push(Comp.CiclesReportsState);
        break;

      case "WorkTime":
        tabs.push(Comp.WorkTimeState);
        break;

      case "InvoiceAdd":
        tabs.push(data || Comp.InvoiceAddState);
        break;

      case "InvoiceAdd_S":
        tabs.push(data || Comp.InvoiceAddState_S);
        break;

      case "InvoiceList":
        tabs.push(Comp.InvoiceListState);
        break;

      case "InvoiceList_S":
        tabs.push(Comp.InvoiceListState_S);
        break;

      case "Contractors":
        tabs.push(Comp.ContractorsState);
        break;

      case "Payments":
        tabs.push(Comp.PaymentsState);
        break;

      case "Reports":
        tabs.push(Comp.ReportsState);
        break;

      case "Communication":
        tabs.push(Comp.CommunicationState);
        break;

      case "Settings":
        tabs.push(Comp.SettingsState);
        break;

      case "TransportSet":
        tabs.push(Comp.TransportSetState);
        break;

      case "Departments":
        tabs.push(Comp.DepartmentsState);
        break;

      case "AccountSettings":
        tabs.push(Comp.AccountSettingsState);
        break;

      case "CostsList":
        tabs.push(Comp.CostsListState);
        break;

      case "PurchaseList":
        tabs.push(Comp.PurchaseListState);
        break;

      case "UsersList":
        tabs.push(Comp.UsersListState);
        break;

      case "CompanyList":
        tabs.push(Comp.CompanyListState);
        break;

      case "Vindication":
        tabs.push(Comp.VindicationState);
        break;

      case "OparatorsList":
        tabs.push(Comp.OparatorsListState);
        break;

      case "OparatorsListSA":
        tabs.push(Comp.OparatorsListStateSA);
        break;

      case "OrderActions":
        let OrderActionsExist = false;
        tabs.forEach((e, i) => {
          if (e.tabName === "Przepinka/Przesiadka") {
            OrderActionsExist = true;
            tempTabID = i;
          }
        });
        if (!OrderActionsExist) {
          tabs.push(Comp.OrderActionsState);
        }
        break;

      case "ExchangeRates":
        tabs.push(Comp.ExchangeRatesState);
        break;

      case "InvoiceAddNew":
        tabs.push(data || Comp.InvoiceAddNewState);
        break;

      case "VehiclesDocs":
        tabs.push(data || Comp.VehiclesDocsState);
        break;

      case "DriversDocs":
        tabs.push(data || Comp.DriversDocsState);
        break;

      case "TrailersDocs":
        tabs.push(data || Comp.TrailersDocsState);
        break;

      case "CompanyDocs":
        tabs.push(data || Comp.CompanyDocsState);
        break;

      case "DeadlinesDocs":
        tabs.push(data || Comp.DeadlinesDocsState);
        break;

      case "ChangesHistory":
        tabs.push(data || Comp.ChangesHistoryState);
        break;

      case "FinishedOrders":
        tabs.push(data || Comp.FinishedOrdersState);
        break;

      case "FuelCards":
        tabs.push(data || Comp.FuelCardsState);
        break;

      case "NoOrderStatusHistory":
        tabs.push(data || Comp.NoOrderStatusHistoryState);
        break;

      case "DriversActivity":
        tabs.push(data || Comp.DriversActivityState);
        break;

      case "InvoiceTags":
        tabs.push(data || Comp.InvoiceTagsState);
        break;

      case "CostsImport":
        tabs.push(Comp.CostsImportState);
        break;

      case "DriversSettlement":
        tabs.push(Comp.DriversSettlementState);
        break;

      case "FleetCosts":
        tabs.push(Comp.FleetCostsState);
        break;

      case "FleetCostsReport":
        tabs.push(Comp.FleetCostsReportState);
        break;

      case "FleetCostsReportsConfirmed":
        tabs.push(Comp.FleetCostsReportsConfirmedState);
        break;

      case "DriverNotes":
        tabs.push(Comp.DriverNotesState);
        break;

      default:
        tabs.push(Comp.MapState);
        break;
    }
    const copyTabs = [...tabs];

    if (savedTab) {
      copyTabs[savedTab.tabID] = savedTab;
    }

    copyTabs.forEach((e) => {
      e.active = false;
    });

    copyTabs[tempTabID] = {
      ...copyTabs[tempTabID],
      active: true,
      tabID: tempTabID,
    };

    setTabs(checkPermission(copyTabs));
    setTabIdx(checkPermission(copyTabs, tempTabID, true, 1));
  };

  const changeTab = (id) => {
    const copyTabs = [...tabs];
    copyTabs.forEach((e) => {
      e.active = false;
    });
    copyTabs[id] = { ...copyTabs[id], active: true };
    setTabs(checkPermission(copyTabs, false, false, 6));
    setTabIdx(checkPermission(copyTabs, id, true, 5));
  };

  const removeTab = async (id) => {
    const copyTabs = [...tabs];

    const tempfunc = async () => {
      if (id !== 0 && id !== tabs.length - 1) {
        copyTabs.splice(id, 1);
        copyTabs.forEach((e, i) => {
          e.tabID = i;
          if (i === Number(id)) {
            e.active = true;
            setTabIdx(checkPermission(copyTabs, i, true, 2));
          }
        });
      } else if (id !== 0 && Number(id) === tabs.length - 1) {
        copyTabs.splice(id, 1);
        copyTabs.forEach((e, i) => {
          e.tabID = i;
          if (i === copyTabs.length - 1) {
            e.active = true;
            setTabIdx(checkPermission(copyTabs, i, true, 3));
          }
        });
      } else if (Number(id) === 0) {
        copyTabs.splice(id, 1);
        copyTabs.forEach((e, i) => {
          e.tabID = i;
          if (i === 0) {
            e.active = true;
            setTabIdx(checkPermission(copyTabs, i, true, 4));
          }
        });
      }
    };

    await tempfunc();

    setTabs(checkPermission(copyTabs));
  };

  const tabCloseAndChangeTo = (removeId, componentName) => {
    const foundTab = tabs.find((tab) => tab.componentName === componentName);

    if (foundTab) {
      let tempTabs = [...tabs];
      tempTabs.forEach((e) => {
        e.active = false;
      });
      tempTabs[foundTab.tabID] = { ...tempTabs[foundTab.tabID], active: true };
      delete tempTabs[removeId];

      setTabs(checkPermission(tempTabs, false, false, 6));
      setTabIdx(checkPermission(tempTabs, foundTab.tabID, true, 5));
    } else {
      props.setDialog({
        isOpen: true,
        type: "error",
        content: "Nie można zamknąć karty",
      });
    }
  };

  let active;
  let activeID;
  let currentView;

  if (tabs?.length > 0) {
    tabs.forEach((tab, index) => {
      if (tab.active) {
        active = tab;
        activeID = index;
      }
    });
  }

  currentView = active?.component ? (
    <active.component
      tabID={active.tabID}
      name={active.name}
      state={tabs[activeID]}
      key={activeID}
      addTab={addTab}
      tabs={tabs}
      setTabs={setTabs}
      tabCloseAndChangeTo={(componentName) =>
        tabCloseAndChangeTo(active.tabID, componentName)
      }
      setDialog={props.setDialog}
      user={props.user}
      loading={props.setLoading}
      isLoading={props.isLoading}
    />
  ) : (
    <div>Wczytuję</div>
  );

  const addFirstTab = () => {
    if (tabs?.length === 0) {
      const LSdata = JSON.parse(sessionStorage.getItem("tabs"));
      if (LSdata?.length > 0) {
        const LSdataTemp = LSdata.map((e) => {
          return { ...e, component: addComponent(e.componentName) };
        });
        setTabs(LSdataTemp);
      } else {
        tabs.push(Comp.OrderListState);
        const copyTabs = [...tabs];
        copyTabs[0] = { ...copyTabs[0], active: true, tabID: 0 };
        setTabs(copyTabs);
      }
    }
  };

  useEffect(() => {
    addFirstTab();
  }, []);

  useEffect(() => {
    sessionStorage.setItem("tabs", JSON.stringify(tabs));
  }, [tabs]);

  useEffect(() => {
    setPromotionUser({
      active: props.user.is_promo_account,
      start: props.user.promo_account_start_from,
      end: props.user.promo_account_expires_to,
      is_promo_account: props.user.is_promo_account,
      promo_banner: true,
      video: true,
    });
  }, [props.user]);

  useEffect(() => {
    if (isPromotionUser.active === true) {
      setOpenVideo(true);
    }
  }, [isPromotionUser.active]);

  return (
    <div className={styles.mainView}>
      <Sidebar
        addTab={addTab}
        tabs={tabs}
        permissions_ids={props.user.permissions_ids}
        adm_p={props.user.adm}
        user_s={props.user.tms_version_s}
        changeTab={changeTab}
        isPromotionUser={isPromotionUser}
        setOpenVideo={setOpenVideo}
        e100={props.user.e100}
      />
      <main className={styles.view}>
        <TopBar
          user={props.user}
          tabs={tabs}
          addTab={addTab}
          changeTab={changeTab}
          removeTab={removeTab}
          setDialog={props.setDialog}
          setIsLoggedIn={props.setIsLoggedIn}
          loading={props.setLoading}
          isLoading={props.isLoading}
          isLoggedIn={props.isLoggedIn}
        />
        <TabBar
          tabs={tabs}
          changeTab={changeTab}
          removeTab={removeTab}
          tabIdx={tabIdx}
        />
        {currentView}
      </main>
      {isPromotionUser.active && (
        <PromotionBaner
          isPromotionUser={isPromotionUser}
          isLoggedIn={props.isLoggedIn}
          openBaner={openBaner}
          setOpenBaner={setOpenBaner}
          openVideo={openVideo}
        />
      )}
      {openVideo && (
        <PromotionStartModal
          isPromotionUser={isPromotionUser}
          isLoggedIn={props.isLoggedIn}
          openBaner={openBaner}
          setOpenBaner={setOpenBaner}
          openVideo={openVideo}
          setOpenVideo={setOpenVideo}
        />
      )}
    </div>
  );
}
