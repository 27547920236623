import React, { useState } from "react";
import { AxiosError } from "axios";
import { Grid } from "@material-ui/core";
import { useQuery } from "react-query";

import CargoItem from "./CargoItem";
import Title from "components/title";
import DriverNotesBox from "components/DriverNotesBox";
import {
  CargoState,
  TmsDialogState,
  Goods,
  SelectOption,
  UnitResponse,
} from "types";
import { getGoods, getUnits } from "api/endpoints";

import stylesVersion from "styles/version_s.module.css";
import stylesMod from "styles/newOrEdit.module.css";
import "./styles.sass";

type Props = {
  cargo: CargoState[];
  driverNote: any;
  updateValue: (name: string, value: any) => void;
  checkedGoods: boolean;
  error: boolean;
  setDialog: (dialog: TmsDialogState) => void;
  addTab: () => void;
  saveTab: () => void;
  user_s: boolean;
  is_required_weight: boolean;
  id_invoice: number | null;
  disable_cargo_quantity_check: boolean;
  isPreview?: boolean;
};

const Cargo = ({
  cargo,
  driverNote,
  updateValue,
  checkedGoods,
  error,
  setDialog,
  addTab,
  saveTab,
  user_s,
  is_required_weight,
  disable_cargo_quantity_check,
  isPreview,
}: Props) => {
  const [goodsOptions, setGoodsOptions] = useState<Goods[]>();
  const [unitOption, setUnitOption] = useState<SelectOption[]>();
  const [showNotes, setShowNotes] = useState(false);

  useQuery<Goods[], AxiosError>("goods", getGoods, {
    onSuccess: (response) => {
      const goodsArray = response?.map((good) => ({
        ...good,
        value: good.id,
        label: good.name,
      }));
      setGoodsOptions(goodsArray);
    },
    onError: (error) => {
      setDialog({ isOpen: true, type: "error", content: error.message });
    },
  });

  useQuery<UnitResponse[], AxiosError>("units", getUnits, {
    onSuccess: (data) => {
      setUnitOption(
        data.map((unit) => ({
          value: unit.id.toString(),
          label: unit.name,
        }))
      );
    },
    onError: (error) => {
      setDialog({ isOpen: true, type: "error", content: error.message });
    },
  });
  const addCargo = () => {
    const newCargo = {
      currency: { label: "PLN", value: "PLN" },
      name: "",
      price: "",
      quantity: 0,
      statusLoad: false,
      statusUnload: false,
      details: "",
    };

    updateValue("cargo", [...cargo, newCargo]);
  };

  const removeCargo = (indexToRemove: number) => {
    const newCargoArray = cargo.filter((_, index) => index !== indexToRemove);

    updateValue("cargo", newCargoArray);
  };

  return (
    <>
      <Title title="Ładunek" />
      <div className="order-cargo">
        <div className="order-cargo__checkboxes">
          <label
            className={`order-cargo__checkbox-label ${
              user_s ? stylesVersion.onlyfull : ""
            }`}
          >
            <input
              type="checkbox"
              className="order-cargo__checkbox"
              onChange={(e) => {
                updateValue("checkedGoods", e.target.checked);
              }}
              name="checkedGoods"
              checked={checkedGoods}
              disabled={isPreview}
            />
            Podaj szczegóły towaru
          </label>
          <label
            className={`order-cargo__checkbox-label ${
              user_s ? stylesVersion.onlyfull : ""
            }`}
          >
            <input
              type="checkbox"
              className="order-cargo__checkbox"
              onChange={(e) => {
                updateValue("is_required_weight", e.target.checked);
              }}
              name="is_required_weight"
              checked={is_required_weight}
              disabled={isPreview}
            />
            Wymaga podania wagi ładunku
          </label>
          <label
            className={`order-cargo__checkbox-label ${
              user_s ? stylesVersion.onlyfull : ""
            }`}
          >
            <input
              type="checkbox"
              className="order-cargo__checkbox"
              onChange={(e) => {
                updateValue("disable_cargo_quantity_check", e.target.checked);
              }}
              name="disable_cargo_quantity_check"
              checked={disable_cargo_quantity_check}
              disabled={isPreview}
            />
            Nie weryfikuj ilości towarów
          </label>
          <label
            className={`order-cargo__checkbox-label ${
              user_s ? stylesVersion.onlyfull : ""
            }`}
          >
            <input
              type="checkbox"
              className="order-cargo__checkbox"
              onChange={(e) => {
                setShowNotes(e.target.checked);
              }}
              name="disable_cargo_quantity_check"
              checked={showNotes}
              disabled={isPreview}
            />
            Notatka dla kierowcy
          </label>
        </div>

        <Grid item xs={12} className={stylesMod.labels}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <div className={stylesMod.inp}>
                <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                  Nazwa towaru*
                </label>
              </div>
            </Grid>
            <Grid item xs={1}>
              <div className={stylesMod.inp}>
                <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                  Ilość*
                </label>
              </div>
            </Grid>
            <Grid item xs={1}>
              <div className={stylesMod.inp}>
                <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                  Jednostka
                </label>
              </div>
            </Grid>
            <Grid item xs={1}>
              <div className={stylesMod.inp}>
                <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                  Stawka
                </label>
              </div>
            </Grid>
            <Grid item xs={1}>
              <div className={stylesMod.inp}>
                <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                  Waluta
                </label>
              </div>
            </Grid>
            <Grid item xs={1}>
              <div className={stylesMod.inp}>
                <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                  Opis Towaru
                </label>
              </div>
            </Grid>
            <Grid item xs={1}>
              <div className={stylesMod.inp}>
                <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                  Rozmiar LDM
                </label>
              </div>
            </Grid>
            <Grid item xs={1}>
              <div className={stylesMod.inp}>
                <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                  Klasa ADR
                </label>
              </div>
            </Grid>
            <Grid item xs={1}>
              <div className={stylesMod.inp}>
                <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                  Numer ref
                </label>
              </div>
            </Grid>
            <Grid item xs={1}>
              <div style={{ display: "flex", gap: 25 }}>
                <div className={stylesMod.inp}>
                  <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                    ADR
                  </label>
                </div>
                <div className={stylesMod.inp}>
                  <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                    Wym. palet
                  </label>
                </div>
              </div>
            </Grid>
            <Grid item xs={1} className={stylesMod.statusGrid}>
              <div className={stylesMod.inp}>
                <label
                  className={`${user_s ? stylesVersion.onlyfull : ""}`}
                ></label>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {cargo?.map((cargoItem: CargoState, index: number) => (
          <CargoItem
            cargoItem={cargoItem}
            updateValue={updateValue}
            addCargo={addCargo}
            removeCargo={removeCargo}
            key={index}
            index={index}
            goodsOptions={goodsOptions}
            addTab={addTab}
            saveTab={saveTab}
            error={error}
            unitOption={unitOption}
            user_s={user_s}
            checkedGoods={checkedGoods}
            cargoLength={cargo.length}
            isPreview={isPreview}
          />
        ))}
        <DriverNotesBox
          showNotes={showNotes}
          setShowNotes={setShowNotes}
          user_s={user_s}
          isPreview={isPreview}
          updateValue={updateValue}
          valuesDriverNote={driverNote}
          setDialog={setDialog}
        />
      </div>
    </>
  );
};

export default Cargo;
