import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import { DBurl } from '../../appConfig'
import BTNstyles from '../../styles/button.module.css'
import { X } from 'react-feather';
import stylesMod from '../../styles/newOrEdit.module.css'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteWarehouse(props) {

	return (
		<>
			<Dialog
				open={props.open}
				TransitionComponent={Transition}
				onClose={() => {
					props.setOpen(false)
				}}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-slide-title" className={stylesMod.dialogHead}>Czy napewno chcesz usunąć magazyn 			<IconButton aria-label="close" className={stylesMod.closeButton} onClick={() => {
					props.setOpen(false)
				}}>
					<X />
				</IconButton>
				</DialogTitle >
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Czy napewno chcesz usunąć magazyny :  <br />
						{props.data.state.map((e, i) => {
							return <p>Magazyn firmy {e.short} pod adresem {e.address.street} {e.address.street_no}, {e.address.city}</p>
						})}

					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" size="large" color="primary" className={BTNstyles.btn} onClick={(e) => {
						props.setOpen(false)
					}}>
						Anuluj
						</Button>
					<Button variant="contained" size="large" className={`${BTNstyles.btn} ${BTNstyles.err}`} onClick={(e) => {


						fetch(`${DBurl}/setWarehouses/`, {
							method: "post",
							credentials: 'include',
							headers: {
								"Content-Type": "application/json",
								"X-CSRF-Token": props.user.csrf_token
							},
							body: JSON.stringify({
								mode: 'delete',
								data: { ids: props.data.state.map(e => e.id) }
							})
						}
						).then(res => res.json())
							.then(data => {
								if (data.success) {
									props.setOpen(false)
									props.setOpenSnack(true)
									props.setSnackContent("Magazyny zostały usunięte")
									props.fetchWarehouse()
								}
							})



					}
					}
						color="primary">
						Usuń <X className={BTNstyles.downBtnIco} />
					</Button>
				</DialogActions>
			</Dialog>

		</>
	)
}
