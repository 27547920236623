import React, { useState, useEffect } from "react";
import Title from "../../components/title";
import { DBurl } from "../../appConfig";
import { X } from "react-feather";
import styles from "../table.module.css";
import stylesMod from "../../styles/newOrEdit.module.css";
import BTNstyles from "../../styles/button.module.css";
import RowsPerPage from "../../components/RowsPerPage";
import Pagination from "../../components/pagination";
import Button from "@material-ui/core/Button";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
  Slide,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import AddUser from "./AddUser";
import PasswordChange from "./PasswordChange";
import Input from "../../components/input";
import IcoBtn from "../../components/buttons/icoBtn";
import { IconButton } from "@material-ui/core";
import CarsToUserModal from "./CarsToUserModal";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (array?.length > 0) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
}

const headCells = [
  { id: "login", right: true, disablePadding: false, label: "Login" },
  { id: "firstname", right: true, disablePadding: false, label: "imię" },
  { id: "surname", right: true, disablePadding: false, label: "Nazwisko" },
  {
    id: "customer",
    right: true,
    disablePadding: false,
    label: "Nazwa Operatora",
  },
  { id: "company", right: true, disablePadding: false, label: "Nazwa firmy" },
  { id: "email", right: true, disablePadding: false, label: "Email" },
  { id: "adm", right: true, disablePadding: false, label: "Poziom uprawnień" },
];

function EnhancedTableHead(props) {
  const {
    styles,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={styles.tabHead}>
      <TableRow>
        <TableCell className={styles.checkbox}>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all id" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.right ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ width: `${headCell.width || "auto"}` }}
          >
            {!headCell.noSort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={styles.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
        <TableCell style={{ width: "8%" }} />
      </TableRow>
    </TableHead>
  );
}

function useInput({ type, size }) {
  const [value, setValue] = useState("");
  const input = (
    <TableCell className={styles.inputCell}>
      <Input
        value={value}
        handleChange={(e) => {
          setValue(e.target.value);
        }}
        type={type}
        search={true}
        className={size}
      />
    </TableCell>
  );
  return [value, input];
}

export default function UsersList(props) {
  const [table, setTable] = useState([]);

  const [firms, setFirms] = useState([]);
  const [customers, setCustomers] = useState([]);

  const [edit, setEdit] = useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("short");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [searchLogin, setSearchLogin] = useInput({ type: "text" });
  const [searchFirstname, setSearchFirstname] = useInput({ type: "text" });
  const [searchSurname, setSearchSurname] = useInput({ type: "text" });
  const [searchEmail, setSearchEmail] = useInput({ type: "text" });
  const [searchAdm, setSearchAdm] = useInput({ type: "text" });
  const [searchFirm, setSearchFirm] = useInput({ type: "text" });
  const [searchCustomer, setSearchCustomer] = useInput({ type: "text" });

  const [deleteData, setDeleteData] = React.useState([]);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);

  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [dataAddUserModal, setDataAddUserModal] = useState(false);

  const [openPasswordChange, setOpenPasswordChange] = useState(false);
  const [passwordChangeId, setPasswordChangeId] = useState(false);
  const [passwordChangeName, setPasswordChangeName] = useState("");

  const [carsToUserModalOpen, setCarsToUserModalOpen] = useState(false);
  const [carsToUserModalUser, setCarsToUserModalUser] = useState();

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");
  const [token, setToken] = useState("");
  useEffect(() => {
    setToken(props.user.csrf_token);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDialog(false);
  };

  const getUsers = async () => {
    props.loading(true);
    const response = await fetch(`${DBurl}/getUsers`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      if (json?.data?.length > 0) {
        setTable(json.data);
      }
      props.loading(false);
    }
  };

  const getCustomers = async () => {
    props.loading(true);
    const response = await fetch(`${DBurl}/getCustomers`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      if (json?.data?.length > 0) {
        setCustomers(
          json.data.map((e) => {
            return { ...e, label: e.name, value: e.id };
          })
        );
      }

      props.loading(false);
    }
  };

  const getFirm = async () => {
    props.loading(true);
    const response = await fetch(`${DBurl}/getFirm`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      if (json?.data?.length > 0) {
        setFirms(
          json.data.map((e) => {
            return { ...e, label: e.name, value: e.id };
          })
        );
      }
      props.loading(false);
    }
  };

  const handleAccountSwitch = async (row) => {
    const response = await fetch(
      `${DBurl}/loginToDifferentAccount?id_user=${row.id}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token,
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      if (json?.data?.length > 0) {
        sessionStorage.setItem("user", JSON.stringify(json.data?.[0]));
        sessionStorage.setItem("isLoggedIn", true);
        window.location.reload();
      }
    }
  };

  const checkFirm = (id) => {
    return firms.find((e) => e.id === id);
  };

  const checkCustomer = (id) => {
    return customers.find((e) => e.id === id);
  };

  const checkAdm = (num) => {
    switch (num) {
      case "1":
        return "Admin Operatora";
      case 1:
        return "Admin Operatora";
      case "9":
        return "Admin Firmy";
      case 9:
        return "Admin Firmy";
      case "999":
        return "Super Admin";
      case 999:
        return "Super Admin";
      default:
        return "Użytkownik";
    }
  };

  useEffect(() => {
    getUsers();
    getFirm();
    getCustomers();
  }, []);

  const delUsers = async function (arrayOfIds) {
    props.loading(true);
    const response = await fetch(`${DBurl}/setUsers/`, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "delete",
        data: {
          ids: arrayOfIds,
        },
      }),
    });
    const json = await response.json();

    if (json.success) {
      setOpenDialog(true);
      setDialogContent(`Dane zostały usunięte `);
      setDialogColor("success");
      getUsers();
      props.loading(false);
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const search = (tbl) => {
    if (tbl?.length > 0) {
      return tbl?.filter((row) => {
        return (
          String(row.login).toLowerCase().indexOf(searchLogin.toLowerCase()) >
            -1 &&
          String(row.firstname)
            .toLowerCase()
            .indexOf(searchFirstname.toLowerCase()) > -1 &&
          String(row.surname)
            .toLowerCase()
            .indexOf(searchSurname.toLowerCase()) > -1 &&
          String(row.email).toLowerCase().indexOf(searchEmail.toLowerCase()) >
            -1 &&
          String(row.adm).toLowerCase().indexOf(searchAdm.toLowerCase()) > -1 &&
          String(checkFirm(row.id_firm)?.name)
            .toLowerCase()
            .indexOf(searchFirm.toLowerCase()) > -1 &&
          String(checkCustomer(row.id_customer)?.name)
            .toLowerCase()
            .indexOf(searchCustomer.toLowerCase()) > -1
        );
      });
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = table.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const paginate = (pageNumber) => setPage(pageNumber - 1);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, table.length - page * rowsPerPage);

  return (
    <>
      <Paper className={styles.paper}>
        <Title
          title={props.state.tabName}
          btnBox
          btnAdd
          addFunc={(e) => {
            setDataAddUserModal(false);
            setEdit(false);
            setOpenAddUserModal(true);
          }}
          btnDelete={selected.length > 0 ? true : "disabled"}
          deleteFunc={(e) => setOpenModalDelete(true)}
        />
      </Paper>
      <TableContainer className={styles.stickyTable}>
        <Table
          stickyHeader={true}
          className={styles.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
          size={"small"}
        >
          <EnhancedTableHead
            styles={styles}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={table.length}
          />
          <TableBody className={styles.tabBody}>
            <TableRow>
              <TableCell></TableCell>
              {setSearchLogin}
              {setSearchFirstname}
              {setSearchSurname}
              {setSearchCustomer}
              {setSearchFirm}

              {setSearchEmail}
              {setSearchAdm}
              <TableCell></TableCell>
            </TableRow>

            {stableSort(search(table), getComparator(order, orderBy))
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    className={row.reservation ? styles.reservation : ""}
                  >
                    <TableCell className={styles.checkbox}>
                      <Checkbox
                        checked={isItemSelected}
                        color="primary"
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </TableCell>

                    <TableCell
                      component="th"
                      id={labelId}
                      align="right"
                      scope="row"
                    >
                      {row.login}
                    </TableCell>
                    <TableCell align="right">{row.firstname}</TableCell>
                    <TableCell align="right">{row.surname} </TableCell>

                    <TableCell align="right">
                      {checkCustomer(row.id_customer)?.name}{" "}
                    </TableCell>
                    <TableCell align="right">
                      {checkFirm(row.id_firm)?.name}{" "}
                    </TableCell>
                    <TableCell align="right">{row.email} </TableCell>
                    <TableCell align="right">{checkAdm(row.adm)}</TableCell>
                    <TableCell align="right">
                      <div className={styles.buttonBox}>
                        <IcoBtn
                          icon="Edit"
                          tooltip="Edytuj"
                          value={row}
                          onClick={(e) => {
                            setDataAddUserModal(row);
                            setEdit(true);
                            setOpenAddUserModal(true);
                          }}
                        />
                        <IcoBtn
                          icon="Car"
                          tooltip="Przypisz pojazdy"
                          value={row}
                          onClick={(e) => {
                            setCarsToUserModalOpen(true);
                            setCarsToUserModalUser(row);
                          }}
                        />
                        <IcoBtn
                          icon="Lock"
                          tooltip="Zmień hasło"
                          value={row}
                          onClick={(e) => {
                            setPasswordChangeId(row.id);
                            setPasswordChangeName(
                              `${row.firstname} ${row.surname}`
                            );
                            setEdit(true);
                            setOpenPasswordChange(true);
                          }}
                        />
                        {props.user.adm === 999 && row.id !== props.user.id && (
                          <IcoBtn
                            icon="LogIn"
                            tooltip="Przełącz konto"
                            value={row}
                            onClick={(e) => {
                              handleAccountSwitch(row);
                            }}
                          />
                        )}
                        <IcoBtn
                          icon="X"
                          tooltip="Usuń"
                          className="btn-delete"
                          value={row}
                          onClick={(e) => {
                            setDeleteData([row.id]);
                            setOpenModalDelete(true);
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 33 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <div className={styles.tableBottom}>
        <RowsPerPage
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          tableLength={table?.length}
        />
        <div>
          <Pagination
            postsPerPage={rowsPerPage}
            totalPosts={table?.length}
            paginate={paginate}
            page={page}
          />
        </div>
      </div>

      <Dialog
        open={openModalDelete}
        TransitionComponent={Transition}
        onClose={() => {
          setDeleteData([]);
          setOpenModalDelete(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Czy napewno chcesz usunąć firmę?{" "}
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              setDeleteData([]);
              setOpenModalDelete(false);
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {deleteData.length > 0 ? (
            <DialogContentText id="alert-dialog-slide-description">
              Czy napewno chcesz usunąć użytkowników:{" "}
              {deleteData.map((e) => {
                const delUser = table.find((el) => el.id === e);
                return (
                  <>
                    <br />
                    {delUser.firstname} {delUser.surname}
                  </>
                );
              })}
            </DialogContentText>
          ) : (
            <DialogContentText id="alert-dialog-slide-description">
              Czy napewno chcesz usunąć użytkownika:{" "}
              {selected.map((e) => {
                const delUser = table.find((el) => el.id === e);
                return (
                  <>
                    <br />
                    {delUser?.firstname} {delUser?.surname}
                  </>
                );
              })}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={BTNstyles.btn}
            onClick={(e) => {
              setOpenModalDelete(false);
            }}
          >
            Anuluj
          </Button>
          <Button
            variant="contained"
            size="large"
            className={`${BTNstyles.btn} ${BTNstyles.err}`}
            onClick={() => {
              delUsers(deleteData.length > 0 ? deleteData : selected);
              setSelected([]);
              setOpenModalDelete(false);
              setDeleteData([]);
            }}
            color="primary"
          >
            Usuń <X className={BTNstyles.downBtnIco} />
          </Button>
        </DialogActions>
      </Dialog>

      {openAddUserModal && (
        <AddUser
          open={openAddUserModal}
          setOpen={setOpenAddUserModal}
          data={dataAddUserModal}
          edit={edit}
          setOpenSnack={setOpenDialog}
          setSnackContent={setDialogContent}
          setSnackColor={setDialogColor}
          user={props.user}
          firms={firms}
          customers={customers}
          refresh={getUsers}
          checkFirm={checkFirm}
          checkCustomer={checkCustomer}
          checkAdm={checkAdm}
        />
      )}

      <PasswordChange
        open={openPasswordChange}
        setOpen={setOpenPasswordChange}
        userId={passwordChangeId}
        name={passwordChangeName}
        edit={edit}
        setOpenSnack={setOpenDialog}
        setSnackContent={setDialogContent}
        setSnackColor={setDialogColor}
        user={props.user}
        refresh={getUsers}
      />

      <CarsToUserModal
        open={carsToUserModalOpen}
        setOpen={setCarsToUserModalOpen}
        data={carsToUserModalUser}
        edit={edit}
        setOpenSnack={setOpenDialog}
        setSnackContent={setDialogContent}
        setSnackColor={setDialogColor}
        user={props.user}
        refresh={getUsers}
      />

      <Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={dialogColor}>
          {dialogContent}
        </Alert>
      </Snackbar>
    </>
  );
}
