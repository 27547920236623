import React, { useState } from "react";
import {
  Button,
  IconButton,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import { X } from "react-feather";
import Input from "../../components/input";
import { DBurl } from "appConfig";
import stylesMod from "../../styles/newOrEdit.module.css";
import BTNstyles from "../../styles/button.module.css";
import docsStyles from "../../styles/documents.module.css";
import "react-datepicker/dist/react-datepicker.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditColumns = ({ open, setOpen, token, user, setDialog }) => {
  const checkCustomerPassword = async () => {
    const response = await fetch(
      `${DBurl}/checkCustomerPassword?password=${customerPassword}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token,
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      if (json.data.correct_password) {
        setDialog({
          isOpen: true,
          type: "success",
          content: "Weryfikacja pomyślna",
        });
        setLoggedIn(true);
      } else {
        setDialog({
          isOpen: true,
          type: "error",
          content: "Błędne hasło. Spróbuj ponownie",
        });
      }
    }
  };

  const setCustomerEditDataPassword = async () => {
    const response = await fetch(`${DBurl}/setCustomerEditDataPassword`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify({
        mode: "update",
        data: {
          new_password: userPassword,
        },
      }),
    });
    const json = await response.json();
    if (json.success) {
      if (json.data.return === "ok") {
        setDialog({
          isOpen: true,
          type: "success",
          content: "Hasło zostało zapisane",
        });
      }
    }
  };

  const [customerPassword, setCustomerPassword] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={() => {
          setOpen(false);
          setCustomerPassword("");
          setUserPassword("");
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Podaj hasło{" "}
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              setOpen(false);
              setLoggedIn(false);
              setUserPassword("");
              setCustomerPassword("");
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {!loggedIn ? (
              <Grid item xs={12} className={docsStyles.login}>
                <div>Aby kontynuować, najpierw potwierdź swoje hasło.</div>
                <label>Hasło:</label>
                <input className={docsStyles.hiddenInput} />
                <Input
                  type={"password"}
                  value={customerPassword}
                  handleChange={(e) => {
                    setCustomerPassword(e.target.value);
                  }}
                />
              </Grid>
            ) : (
              <Grid>
                <label>Ustaw hasło:</label>
                <input className={docsStyles.hiddenInput} />
                <Input
                  type={"password"}
                  value={userPassword}
                  handleChange={(e) => {
                    setUserPassword(e.target.value);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={BTNstyles.btn}
            onClick={(e) => {
              setOpen(false);
              setLoggedIn(false);
              setUserPassword("");
              setCustomerPassword("");
            }}
          >
            Anuluj
          </Button>
          <Button
            variant="contained"
            size="large"
            className={`${BTNstyles.btn} ${BTNstyles.grn}`}
            onClick={() => {
              if (!loggedIn && customerPassword) {
                checkCustomerPassword();
              }
              if (loggedIn && userPassword) {
                setCustomerEditDataPassword();
                setUserPassword("");
                setCustomerPassword("");
                setOpen(false);
                setLoggedIn(false);
              }
            }}
            color="primary"
          >
            {!loggedIn ? "Zaloguj" : "Ustaw hasło"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditColumns;
