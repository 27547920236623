import React from "react";
import BTNstyles from "styles/button.module.css";
import { ArrowRight } from "react-feather";
import DialModal from "../DialModal";

const ModalDedicated = ({
  dedicatedModal,
  setDedicatedModal,
  dedicated,
  setDialog,
  Transition,
}) => {
  return (
    <DialModal
      open={dedicatedModal}
      TransitionComponent={Transition}
      onClose={() => {
        setDedicatedModal(false);
      }}
      title="Auto w użyciu"
      text={`Auto jest dedykowane dla firmy ${dedicated?.company} od ${dedicated?.timeStart} do ${dedicated?.timeEnd}. Czy chcesz go użyć pomimo to?`}
      closeClick={(e) => {
        setDedicatedModal(false);
      }}
      btn1Click={(e) => {
        setDedicatedModal(false);
        setDialog({
          isOpen: true,
          type: "success",
          content: "Zlecenie stworzone",
        });
      }}
      btn1Text="Tak"
      btn1Class={`${BTNstyles.btn} ${BTNstyles.grn}`}
      btn1Ico={<ArrowRight className={BTNstyles.downBtnIco} />}
    />
  );
};

export default ModalDedicated;
