
import React, { useEffect } from 'react'
import styles from '../../styles/newOrEdit.module.css'
import styles2 from '../table.module.css'
import IcoBtn from '../../components/buttons/icoBtn';

import Grid from '@material-ui/core/Grid';
import Input from '../../components/input'
import Select from 'react-select'

export default function invoiceProductsCorrection(props) {
	return (
		<>
			<Grid item xs={1} className={styles.invoiceID}>
				{props.data.id}
			</Grid>
			<Grid item xs={2}>
				<div className={`${styles.inp} ${styles.invoice} ${styles2.tar}`}>
					<Select
						value={props.data.name}
						className={styles2.selectClass}
						options={props.servicesOptions}
						onChange={props.updateService(props.propKey, true, true)}
						name="name"
						placeholder="Nazwa usługi"
						search 
						styles={{
							control: (base, state) => ({
								...base,
								border: '1px solid #142f42',
								boxShadow: 'none',
								'&:hover': {
									border: '1px solid #142f42',
								}
							}),
							option: (provided, state) => ({
								...provided,
								backgroundColor: state.isFocused && "#142f42",
								color: state.isFocused && "#fff",
							 })

						  }}/>
				</div>
			</Grid>
			<Grid item xs={2}>
				<div className={`${styles.inp} ${styles.invoice} ${styles2.tar}`}>
					<Select
						value={props.data.order}
						className={styles2.selectClass}
						options={props.ordersOptions}
						onChange={props.updateService(props.propKey, true, true)}
						name="order"
						placeholder="-"
						search 
						styles={{
							control: (base, state) => ({
								...base,
								border: '1px solid #142f42',
								boxShadow: 'none',
								'&:hover': {
									border: '1px solid #142f42',
								}
							}),
							option: (provided, state) => ({
								...provided,
								backgroundColor: state.isFocused && "#142f42",
								color: state.isFocused && "#fff",
							 })

						  }}/>
				</div>
			</Grid>
			<Grid item xs={1}>
				<Input type="number" className={`${styles2.invoiceSm} ${styles2.tar}`} value={props.data.price_netto} handleChange={props.updateService(props.propKey, false, true)} name="price_netto" />
			</Grid>
			<Grid item xs={1}>
				<Input type="number" className={`${styles2.invoiceSm} ${styles2.tar}`} value={props.data.quantity} handleChange={props.updateService(props.propKey, false, true)} name="quantity" />
			</Grid>
			<Grid item xs={1}>
				<div className={`${styles.inp} ${styles.invoice}`}>
					<Select
						value={props.data.vat}
						className={styles2.selectClass}
						options={props.vatOptions}
						onChange={props.updateService(props.propKey, true, true)
						}
						name="vat"
						placeholder="Stawka VAT"
						search
						styles={{
							control: (base, state) => ({
								...base,
								border: '1px solid #142f42',
								boxShadow: 'none',
								'&:hover': {
									border: '1px solid #142f42',
								}
							}),
							option: (provided, state) => ({
								...provided,
								backgroundColor: state.isFocused && "#142f42",
								color: state.isFocused && "#fff",
							 })

						  }} />
				</div>
			</Grid>
			<Grid item xs={1}>
				<Input type="number" className={`${styles2.invoiceSm} ${styles2.tar}`} value={props.data.value_netto} handleChange={props.updateService(props.propKey, false, true)} name="value_netto" disabled />
			</Grid>
			<Grid item xs={2}>
				<Input type="number" className={`${`${styles2.invoiceMd} ${styles2.tar}`} ${styles.tar}`} value={props.data.value_brutto} handleChange={props.updateService(props.propKey, false, true)} name="value_brutto" disabled />
			</Grid>

		</>
	)
}
