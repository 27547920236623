import React, { useState, useEffect } from "react";
import styles from "../components/promotion.module.css";
import BTNstyles from "styles/button.module.css";
import stylesMod from "../styles/newOrEdit.module.css";
import {
  DialogActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Slide,
  Grid,
  Modal,
} from "@material-ui/core";
import { X } from "react-feather";
import ReactPlayer from "react-player";
import { AppName } from "../appConfig";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PromotionStartModal(props) {
  const [promo_video, setPromo_video] = useState(
    props.isPromotionUser.video || false
  );

  const handleCloseVideo = (e) => {
    props.setOpenVideo(false);
    props.isPromotionUser.video = false;
    props.isPromotionUser.promo_banner === true &&
      setTimeout(() => {
        props.setOpenBaner(true);
      }, 1500);
  };

  useEffect(() => {
    if (props.isPromotionUser.video === true) {
      props.setOpenVideo(true);
      props.openVideo = true;
    }
  }, []);

  return (
    props.isLoggedIn && (
      <Dialog
        open={props.openVideo}
        TransitionComponent={Transition}
        onClose={() => handleCloseVideo()}
        maxWidth={false}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          {`Witaj w ${AppName} TMS`}
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => handleCloseVideo()}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ maxWidth: "656px" }}>
            <Grid item xs={12}>
              Jeżeli chcesz zapoznać się z programem obejrzyj krótkie wideo
              wprowadzające do funkcjonalności TMS.
            </Grid>
            <Grid item xs={12}>
              <ReactPlayer
                className="react-player"
                pip={true}
                url={"video/demo.mp4"}
                controls={true}
                loop={true}
                muted={false}
                playsinline={true}
                light={"img/video-pic.png"}
                // playIcon={<img src={play} alt={'play-button'}/>}
                playing
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            className={BTNstyles.btn}
            color="primary"
            onClick={() => handleCloseVideo()}
          >
            Zamknij
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
}
