import React, { useEffect, useMemo } from "react";
import { MapPin } from "react-feather";
import KeyboardTabOutlinedIcon from "@material-ui/icons/KeyboardTabOutlined";
import FlagOutlinedIcon from "@material-ui/icons/FlagOutlined";
import LocalParkingOutlinedIcon from "@material-ui/icons/LocalParkingOutlined";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import DirectionsBoatOutlinedIcon from "@material-ui/icons/DirectionsBoatOutlined";
import stylesVersion from "styles/version_s.module.css";

import { Calendar } from "react-feather";
import IconFuel from "components/buttons/IconFuel";
import TraceStop from "./TraceStop";
import TraceWarehouse from "./TraceWarehouse";

import styles from "./trace.module.css";
import "react-datepicker/dist/react-datepicker.css";
import "./trace.css";

const typeOptions = [
  { label: "Załadunek", value: "loading" },
  { label: "Rozładunek", value: "unloading" },
  { label: "Parking", value: "parking" },
  { label: "Prom", value: "ferry" },
  { label: "Początek trasy", value: "start" },
  { label: "Koniec trasy", value: "end" },
  { label: "Przejście graniczne", value: "borderCrossing" },
  { label: "Punkt kontrolny", value: "point" },
  { label: "Tankowanie", value: "refueling" },
];

const Trace = ({
  updateValue,
  values,
  clearHooksState,
  index,
  isShowRequiredFields,
  toCostValues,
  user_s,
  itemType,
  data,
  propKey,
  handleTrace,
  handleTraceNew,
  copyTrace,
  moveTrace,
  removeTrace,
  addTraceCargo,
  removeTraceCargo,
  goodsOptions,
  addTab,
  saveTab,
  warehousesOptions,
  user,
  error,
  setOpenModalNewWarehouse,
  trace,
  checkedGoods,
  setOpenModalShowPoint,
  setTraceIndex,
  setTraceData,
  cargoIndex,
  setDialog,
  editFavouritesRoutes,
  getMinDate,
  getMaxDate,
  getMinTime,
  getMaxTime,
  isPreview,
}) => {
  const [type, setType] = React.useState("");

  let newDate = data?.date ? new Date(data.date) : "";

  const updateTraceValue = (name, value) => {
    updateValue(`trace[${index}].[${name}]`, value);
  };

  isPreview = data.isBlocked || isPreview;

  const icon = (type, user_s) => {
    switch (type) {
      case "loading":
        return (
          <img
            className={`${styles.mainIco} ${
              user_s ? stylesVersion.onlyfull : ""
            }`}
            style={{ width: 80, padding: "0 10px 0 0" }}
            src="/img/icons/loading.svg"
          />
        );
      case "unloading":
        return (
          <img
            className={`${styles.mainIco} ${
              user_s ? stylesVersion.onlyfull : ""
            }`}
            style={{ width: 80, padding: "0 10px 0 0" }}
            src="/img/icons/unloading.svg"
          />
        );

      case "parking":
        return (
          <LocalParkingOutlinedIcon
            className={`${styles.matMainIco} ${
              user_s ? stylesVersion.onlyfull : ""
            }`}
          />
        );

      case "ferry":
        return (
          <DirectionsBoatOutlinedIcon
            className={`${styles.matMainIco} ${
              user_s ? stylesVersion.onlyfull : ""
            }`}
          />
        );

      case "start":
        return (
          <ArrowForwardOutlinedIcon
            className={`${styles.matMainIco} ${
              user_s ? stylesVersion.onlyfull : ""
            }`}
          />
        );

      case "end":
        return (
          <KeyboardTabOutlinedIcon
            className={`${styles.matMainIco} ${
              user_s ? stylesVersion.onlyfull : ""
            }`}
          />
        );

      case "borderCrossing":
        return (
          <FlagOutlinedIcon
            className={`${styles.matMainIco} ${
              user_s ? stylesVersion.onlyfull : ""
            }`}
          />
        );

      case "point":
        return (
          <MapPin
            className={`${styles.mainIco} ${
              user_s ? stylesVersion.onlyfull : ""
            }`}
          />
        );

      case "refueling":
        return (
          <IconFuel
            className={`${styles.mainIco} ${
              user_s ? stylesVersion.onlyfull : ""
            }`}
          />
        );

      default:
        return (
          <MapPin
            className={`${styles.mainIco} ${
              user_s ? stylesVersion.onlyfull : ""
            }`}
          />
        );
    }
  };

  const setLabelForSubtype = (val) => {
    switch (val) {
      case "parking":
        return { value: "parking", label: "Parking" };
      case "ferry":
        return { value: "ferry", label: "Prom" };
      case "start":
        return { value: "start", label: "Początek trasy" };
      case "end":
        return { value: "end", label: "Koniec trasy" };
      case "borderCrossing":
        return { value: "borderCrossing", label: "Przejście graniczne" };
      case "point":
        return { value: "point", label: "Punkt kontrolny" };
      case "refueling":
        return { value: "refueling", label: "Tankowanie" };
      case "loading":
        return { value: "loading", label: "Załadunek" };
      case "unloading":
        return { value: "unloading", label: "Rozładunek" };
      default:
        return { value: "point", label: "Punkt kontrolny" };
    }
  };

  useEffect(() => {
    setType(setLabelForSubtype(data.type));
  }, [data.type]);

  const CustomInput = React.forwardRef(
    (
      { value, onClick, traceIndex, error, isShowRequiredFields, placeholder },
      ref
    ) => {
      return (
        <div
          className={`${styles.input} ${"datapicker_new"} ${
            error === true && error && !value && styles.error
          } ${user_s ? stylesVersion.onlyfull : ""}
		  		${
            isShowRequiredFields && value !== ""
              ? "isShowRequiredFieldsVal"
              : isShowRequiredFields && traceIndex === 0
              ? "isShowRequiredFields"
              : ""
          }`}
        >
          <label onClick={onClick} ref={ref}>
            {value || placeholder}
          </label>
          <Calendar onClick={onClick} />
        </div>
      );
    }
  );

  const handleTypeChange = (option) => {
    if (option.value === "loading" || option.value === "unloading") {
      updateTraceValue("type", option.value);
      updateTraceValue("subtype", option.value);
      setType(option);
    } else {
      updateTraceValue("type", "stop");
      updateTraceValue("subtype", option.value);
      setType(setLabelForSubtype(option.value));
    }
  };

  const checkStatus = (cargoData) => {
    const cargoTemp = cargoData || [...values.cargo];
    cargoTemp.forEach((elCargo, i) => {
      cargoTemp[i].statusLoad = false;
      cargoTemp[i].statusUnload = false;
      values.trace.forEach((elTrace) => {
        if (elTrace.cargo) {
          elTrace.cargo.forEach((elCargoTrace) => {
            const elCargoName = elCargo.name
              ? String(elCargoTrace.name).toLowerCase()
              : undefined;
            const elTraceName = elCargoTrace.name
              ? String(elCargoTrace.name).toLowerCase()
              : null;
            if (elCargoName === elTraceName) {
              if (elTrace.type === "loading") {
                cargoTemp[i].statusLoad = true;
              }
              if (elTrace.type === "unloading") {
                cargoTemp[i].statusUnload = true;
              }
            }
          });
        }
      });
    });
    updateValue("cargo", cargoTemp);
  };

  if (itemType === "stop") {
    return (
      <TraceStop
        data={data}
        trace={trace}
        updateValue={updateValue}
        handleTrace={handleTrace}
        propKey={propKey}
        error={error}
        icon={icon}
        setType={setType}
        user_s={user_s}
        type={type}
        newDate={newDate}
        cargoIndex={cargoIndex}
        handleTraceNew={handleTraceNew}
        CustomInput={CustomInput}
        copyTrace={copyTrace}
        moveTrace={moveTrace}
        removeTrace={removeTrace}
        user={user}
        setDialog={setDialog}
        getMinDate={getMinDate}
        getMaxDate={getMaxDate}
        getMinTime={getMinTime}
        getMaxTime={getMaxTime}
        setLabelForSubtype={setLabelForSubtype}
        isPreview={isPreview}
        handleTypeChange={handleTypeChange}
        typeOptions={typeOptions}
      />
    );
  } else {
    return (
      <TraceWarehouse
        data={data}
        values={values}
        trace={trace}
        user={user}
        updateValue={updateValue}
        updateTraceValue={updateTraceValue}
        index={index}
        cargoIndex={cargoIndex}
        handleTrace={handleTrace}
        isShowRequiredFields={isShowRequiredFields}
        toCostValues={toCostValues}
        user_s={user_s}
        icon={icon}
        newDate={newDate}
        itemType={itemType}
        checkStatus={checkStatus}
        addTraceCargo={addTraceCargo}
        moveTrace={moveTrace}
        copyTrace={copyTrace}
        removeTrace={removeTrace}
        removeTraceCargo={removeTraceCargo}
        goodsOptions={goodsOptions}
        addTab={addTab}
        saveTab={saveTab}
        setOpenModalNewWarehouse={setOpenModalNewWarehouse}
        checkedGoods={checkedGoods}
        setOpenModalShowPoint={setOpenModalShowPoint}
        setTraceIndex={setTraceIndex}
        setTraceData={setTraceData}
        setDialog={setDialog}
        editFavouritesRoutes={editFavouritesRoutes}
        handleTraceNew={handleTraceNew}
        propKey={propKey}
        CustomInput={CustomInput}
        warehousesOptions={warehousesOptions}
        clearHooksState={clearHooksState}
        error={error}
        getMinDate={getMinDate}
        getMaxDate={getMaxDate}
        getMinTime={getMinTime}
        getMaxTime={getMaxTime}
        setLabelForSubtype={setLabelForSubtype}
        type={type}
        setType={setType}
        typeOptions={typeOptions}
        handleTypeChange={handleTypeChange}
        isPreview={isPreview}
      />
    );
  }
};

export default Trace;
