import React, { useState, useEffect } from "react";
import Input from "../../components/input";
import Title from "../../components/title";
import styles from "../table.module.css";
import { ArrowUpRight, ArrowDownRight, Minus } from "react-feather";
import RowsPerPage from "../../components/RowsPerPage";
import Pagination from "../../components/pagination";
import { DBurl } from "../../appConfig";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Tooltip,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
} from "@material-ui/core";
import PropTypes from "prop-types";

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../../img/flags", false, /\.(png|gif|svg)$/)
);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (array?.length > 0) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
}

const headCells = [
  { id: "code", right: false, disablePadding: true, label: "Waluta" },
  { id: "currency", right: true, disablePadding: false, label: "Nazwa" },
  { id: "trend", right: true, disablePadding: false, label: "Trend" },
  { id: "value", right: true, disablePadding: false, label: "Kurs" },
];

function EnhancedTableHead(props) {
  const { styles, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={styles.tabHead}>
      <TableRow>
        {headCells.map((headCell, i) => {
          let cellPadding = headCell.id === "code" && "10px";
          return (
            <TableCell
              key={i}
              align={headCell.right ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "default"}
              sortDirection={orderBy === headCell.id ? order : false}
              className={headCell.class}
              style={{ paddingLeft: cellPadding }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={styles.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  styles: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function useInput({ type }) {
  const [value, setValue] = useState("");
  const input = (
    <TableCell>
      <Input
        value={value}
        handleChange={(e) => {
          setValue(e.target.value);
        }}
        type={type}
        search={true}
      />
    </TableCell>
  );
  return [value, input];
}

export default function ExchangeRates(props) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("short");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [table, setTable] = useState([]);
  const [searchCurrency, setSearchCurrency] = useInput({ type: "text" });
  const [searchCode, setSearchCode] = useInput({ type: "text" });

  const fetchRates = async () => {
    props.loading(true);
    const response = await fetch(`${DBurl}/getExchangeRates`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setTable(json.data);
      props.loading(false);
    }
  };

  useEffect(() => {
    fetchRates();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const search = (tbl) => {
    if (tbl?.length > 0) {
      return tbl.filter((row) => {
        return (
          String(row.code).toLowerCase().indexOf(searchCode.toLowerCase()) >
            -1 &&
          String(row.currency)
            .toLowerCase()
            .indexOf(searchCurrency.toLowerCase()) > -1
        );
      });
    }
  };

  const paginate = (pageNumber) => setPage(pageNumber - 1);

  const renderSymbol = (row) => {
    if (row.trend === "UP")
      return (
        <Tooltip
          title={`Poprzedni kurs wynosił ${parseFloat(row.prev_value).toFixed(
            4
          )}`}
        >
          <ArrowUpRight className={styles.exchangeRatesTrendUp} />
        </Tooltip>
      );
    if (row.trend === "DOWN")
      return (
        <Tooltip
          title={`Poprzedni kurs wynosił ${parseFloat(row.prev_value).toFixed(
            4
          )}`}
        >
          <ArrowDownRight className={styles.exchangeRatesTrendDown} />
        </Tooltip>
      );
    if (row.trend === "-")
      return (
        <Tooltip
          title={`Poprzedni kurs wynosił ${parseFloat(row.prev_value).toFixed(
            4
          )}`}
        >
          <Minus className={styles.exchangeRatesTrendEqual} />
        </Tooltip>
      );
  };

  const renderFlag = (row) => {
    if (row.code === "HKD") return "cn.gif";
    if (row.code === "XDR") return "eu.gif";
    return row.code.slice(0, 2).toLowerCase() + ".gif";
  };

  return (
    <div className={styles.rootFlex}>
      <div className={styles.tableBox}>
        <Paper className={styles.paper}>
          <Title title={props.state.tabName} />
          <TableContainer className={styles.stickyTable}>
            <Table
              stickyHeader
              className={styles.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                styles={styles}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={table.length}
              />
              <TableBody
                className={`${styles.tabBody} ${styles.exchangeRates}`}
              >
                <TableRow>
                  {setSearchCode}
                  {setSearchCurrency}
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                {stableSort(search(table), getComparator(order, orderBy))
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={index}
                        className={styles.exchangeRatesTableRow}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          style={{ padding: "10px" }}
                        >
                          <div>
                            <img src={images[`${renderFlag(row)}`]}></img>{" "}
                            {row.code}
                          </div>
                        </TableCell>
                        <TableCell
                          className={styles.exchangeRatesCurrency}
                          align="right"
                        >
                          {row.currency}
                        </TableCell>
                        <TableCell align="right">{renderSymbol(row)}</TableCell>
                        <TableCell align="right">
                          {parseFloat(row.value).toFixed(4)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={styles.tableBottom}>
            <RowsPerPage
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              setPage={setPage}
              tableLength={table?.length}
            />
            <div>
              <Pagination
                page={page}
                postsPerPage={rowsPerPage}
                totalPosts={search(table)?.length}
                paginate={paginate}
              />
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
}
