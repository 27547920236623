import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Grid,
} from "@material-ui/core";

import Title from "components/title";
import Input from "components/input";
import ShortList from "components/ShortList";
import { DeleteBtn } from "components/buttons";
import TransportSetDrawer from "components/TransportSetDrawer";
import DisabledDeleteBtn from "components/buttons/deleteDisabled";
import TmsLoadingSpinner from "components/TmsLoadingSpinner";
import TmsDeleteModal from "components/TmsDeleteModal";
import PropTypes from "prop-types";

import {
  getTrailers,
  getCars,
  getTransportSets,
  getDriversNoSlaves,
  deleteTransportSets,
} from "api/endpoints";

import styles from "../table.module.css";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (array?.length > 0) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
}

const headCells = [
  { id: "name", right: false, disablePadding: true, label: "Nazwa zestawu" },
  { id: "vehicle", right: true, disablePadding: false, label: "Pojazd" },
  { id: "trailer", right: true, disablePadding: false, label: "Naczepa" },
  { id: "driver", right: true, disablePadding: false, label: "Kierowca" },
  {
    id: "secondDriver",
    right: true,
    disablePadding: false,
    label: "Drugi kierowca",
  },
];

const defaultTSDdata = {
  driver1_name: "",
  driver1_id: null,
  driver2_name: "",
  driver2_id: null,
  trailer_name: "",
  trailer_id: null,
  car_id: null,
  car_name: "",
  edit: false,
  transportSet_id: null,
};

function EnhancedTableHead(props) {
  const {
    styles,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={styles.tabHead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.right ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={headCell.class}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={styles.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell className={styles.buttonBoxParent} />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  styles: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function useInput({ type }) {
  const [value, setValue] = useState("");
  const input = (
    <TableCell>
      <Input
        value={value}
        handleChange={(e) => {
          setValue(e.target.value);
        }}
        type={type}
        search={true}
      />
    </TableCell>
  );
  return [value, input];
}

export default function TransportSet(props) {
  const [driversData, setDriversData] = useState([]);
  const [vehiclesData, setVehiclesData] = useState([]);
  const [trailersData, setTrailerData] = useState([]);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("short");
  const [selected, setSelected] = useState([]);
  const [table, setTable] = useState([]);
  const [searchSet, setSearchSet] = useInput({ type: "text" });
  const [searchCar, setSearchCar] = useInput({ type: "text" });
  const [searchTrailer, setSearchTrailer] = useInput({ type: "text" });
  const [searchDriver, setSearchDriver] = useInput({ type: "text" });
  const [searchSecondDriver, setSearchSecondDriver] = useInput({
    type: "text",
  });
  const [carSelected, setCarSelected] = useState([]);
  const [trailerSelected, setTrailerSelected] = useState([]);
  const [driverOneSelected, setDriverOneSelected] = useState([]);
  const [driverSecondSelected, setDriverSecondSelected] = useState([]);

  const [TSDopen, setTSDopen] = useState(false);
  const [TSDdata, setTSDdata] = useState({
    driver1_name: "",
    driver1_id: null,
    driver2_name: "",
    driver2_id: null,
    trailer_name: "",
    trailer_id: null,
    car_id: null,
    car_name: "",
    edit: false,
    transportSet_id: null,
  });
  const [deleteModal, setDeleteModal] = useState({ isOpen: false });
  const [selectedRowsWithOrders, setSelectedRowsWithOrders] = useState([]);

  const { isLoading: isDriversNoSlavesLoading } = useQuery(
    "getTrailers",
    getDriversNoSlaves,
    {
      onSuccess: (data) => {
        const tempData = data.map((driver) => ({
          ...driver,
          name: `${driver.firstname} ${driver.surname}`,
        }));
        setDriversData(tempData);
      },
      onError: (error) => {
        props.setDialog({
          isOpen: true,
          type: "error",
          content: `Nie można pobrać kierowców / ${error.message}`,
        });
      },
    }
  );

  const { isLoading: isTrailersLoading } = useQuery(
    "getTrailers",
    getTrailers,
    {
      onSuccess: (data) => {
        setTrailerData(data);
      },
      onError: (error) => {
        props.setDialog({
          isOpen: true,
          type: "error",
          content: `Nie można pobrać naczep / ${error.message}`,
        });
      },
    }
  );

  const { isLoading: isCarsLoading } = useQuery("getCars", getCars, {
    onSuccess: (data) => {
      setVehiclesData(data);
    },
    onError: (error) => {
      props.setDialog({
        isOpen: true,
        type: "error",
        content: `Nie można pobrać pojazdów / ${error.message}`,
      });
    },
  });

  const { isLoading: isTransportSetsLoading, refetch: refetchTransportSets } =
    useQuery("getTransportSets", getTransportSets, {
      onSuccess: (data) => {
        setTable(data);
      },
      onError: (error) => {
        props.setDialog({
          isOpen: true,
          type: "error",
          content: `Nie można pobrać zestawów transportowych / ${error.message}`,
        });
      },
    });

  const { mutate: deleteSets } = useMutation(
    "deleteTransportSets",
    deleteTransportSets,
    {
      onSuccess: () => {
        props.setDialog({
          isOpen: true,
          type: "success",
          content: "Zestaw transportowy usunięty",
        });
        refetchTransportSets();
      },
      onError: (error) => {
        props.setDialog({
          isOpen: true,
          type: "error",
          content: `Nie udało się usunąć zestawu transportowego / ${error.message}`,
        });
      },
    }
  );

  const clearCreateForm = () => {
    setTSDdata({ ...defaultTSDdata });
    setCarSelected([]);
    setTrailerSelected([]);
    setDriverOneSelected([]);
    setDriverSecondSelected([]);
  };

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (table?.length > 0) {
      if (event.target.checked) {
        const newSelecteds = table.map((n) => n.id);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    } else {
      props.setDialog({
        isOpen: true,
        type: "warning",
        content: "Brak danych do zaznaczenia",
      });
    }
  };

  const handleClick = (_event, name, row) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (row.linked_to_orders_num > 0) {
      const newRows = [...selectedRowsWithOrders];
      const itemToDelete = newRows.indexOf(name);
      itemToDelete !== -1
        ? newRows.splice(itemToDelete, 1)
        : newRows.push(name);
      setSelectedRowsWithOrders(newRows);
    }

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const search = (tbl) => {
    if (tbl?.length > 0) {
      return tbl.filter((row) => {
        return (
          row.label
            .toString()
            .toLowerCase()
            .includes(searchSet.toLowerCase()) &&
          (!searchCar ||
            row.car_name
              ?.toString()
              .toLowerCase()
              .includes(searchCar.toLowerCase())) &&
          (!searchTrailer ||
            row.trailer_name
              ?.toString()
              .toLowerCase()
              .includes(searchTrailer.toLowerCase())) &&
          (!searchDriver ||
            row.driver1_name
              ?.toString()
              .toLowerCase()
              .includes(searchDriver.toLowerCase())) &&
          (!searchSecondDriver ||
            row.driver2_name
              ?.toString()
              .toLowerCase()
              .includes(searchSecondDriver.toLowerCase()))
        );
      });
    }
  };

  const handleDelete = () => {
    deleteSets([deleteModal.id]);
  };

  const isSelected = (name) => selected?.indexOf(name) !== -1;

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={4} style={{ paddingRight: "4px" }}>
          <ShortList
            user={props.user}
            data={vehiclesData}
            title="Pojazdy"
            TSDdata={TSDdata}
            setTSDopen={setTSDopen}
            setTSDdata={setTSDdata}
            isLoading={isCarsLoading}
            car
            selected={carSelected}
            setSelected={setCarSelected}
            selected2={driverSecondSelected}
            setSelected2={setDriverSecondSelected}
          />
        </Grid>
        <Grid item xs={4} style={{ paddingRight: "4px" }}>
          <ShortList
            user={props.user}
            data={trailersData}
            title="Naczepy"
            TSDdata={TSDdata}
            setTSDopen={setTSDopen}
            setTSDdata={setTSDdata}
            isLoading={isTrailersLoading}
            trailer
            selected={trailerSelected}
            setSelected={setTrailerSelected}
            selected2={driverSecondSelected}
            setSelected2={setDriverSecondSelected}
          />
        </Grid>
        <Grid item xs={4}>
          <ShortList
            user={props.user}
            data={driversData}
            title="Kierowcy"
            TSDdata={TSDdata}
            setTSDopen={setTSDopen}
            setTSDdata={setTSDdata}
            isLoading={isDriversNoSlavesLoading}
            driver
            selected={driverOneSelected}
            setSelected={setDriverOneSelected}
            selected2={driverSecondSelected}
            setSelected2={setDriverSecondSelected}
          />
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Title title={props.state.tabName} btnBox />
          <TableContainer className={styles.stickyDoubleTable}>
            <Table
              stickyHeader
              className={styles.tableWOmin}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                styles={styles}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={table.length}
              />
              <TableBody className={styles.tabBody}>
                <TableRow>
                  {setSearchSet}
                  {setSearchCar}
                  {setSearchTrailer}
                  {setSearchDriver}
                  {setSearchSecondDriver}
                  <TableCell></TableCell>
                </TableRow>
                {isTransportSetsLoading ? (
                  <TmsLoadingSpinner />
                ) : (
                  stableSort(search(table), getComparator(order, orderBy))?.map(
                    (row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.id, row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.label}
                          </TableCell>
                          <TableCell align="right">{row.car_name}</TableCell>
                          <TableCell align="right">
                            {row.trailer_name}
                          </TableCell>
                          <TableCell align="right">
                            {row.driver1_name}
                          </TableCell>
                          <TableCell align="right">
                            {row.driver2_name || "-"}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={styles.buttonBoxParent}
                          >
                            <div className={styles.buttonBox}>
                              {row.linked_to_orders_num === 0 ? (
                                <DeleteBtn
                                  tooltip="Usuń"
                                  className="btn-delete"
                                  handleChange={() => {
                                    setDeleteModal({
                                      isOpen: true,
                                      name: row?.label,
                                      id: row?.id,
                                    });
                                  }}
                                />
                              ) : (
                                <DisabledDeleteBtn
                                  tooltip={`Zestaw powiązany z ${row.linked_to_orders_num} zleceniami`}
                                  handleChange={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }}
                                />
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {TSDopen && (
        <TransportSetDrawer
          open={TSDopen}
          setOpen={setTSDopen}
          data={TSDdata}
          setData={setTSDdata}
          user={props.user}
          fetchGets={refetchTransportSets}
          setDialog={props.setDialog}
          clearCreateForm={clearCreateForm}
        />
      )}
      {deleteModal.isOpen && (
        <TmsDeleteModal
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          deleteFunction={handleDelete}
        />
      )}
    </>
  );
}
