import React from "react";
import H, { geocoding } from "@here/maps-api-for-javascript";
import { ContactSupportOutlined } from "@material-ui/icons";
import { DBurl } from "../../appConfig";
import styles from "../fleet/map.module.css";
import { iso_3166_1 } from "iso-3166-1";
import {
  ChevronDown,
  ChevronUp,
  Download,
  Upload,
  MapPin,
  Circle,
} from "react-feather";
import ferryImg from "../../img/ferry.png";
import Tooltip from "@material-ui/core/Tooltip";
import IcoBtn from "components/buttons/icoBtn";
import { transparent } from "material-ui/styles/colors";
import ClipLoader from "react-spinners/ClipLoader";

export default class ShowRouteMap extends React.Component {
  /**
   * constructor(props)
   *
   * Funkcja
   *
   */
  constructor(props) {
    super(props);

    this.state = {
      result: null,
      resultFromDB: null,
      calculateRouteParams: null,
      wasEditedPolyline: false,
      saveOnce: false,
      isCollapsed: true,
      isCollapsedParam: true,
      isCollapsedPoints: true,
      newTrace: null,
      previousMarker: null,
      activeIndex: null,
      tmpCustomMarkerId: null,
      mapCursor: false,
      isModification: false,
      emissionType: null,
    };

    // the reference to the container
    this.ref = React.createRef();

    // reference to the map
    this.map = null;
    this.ui = null;
    this.mapBehavior = null;
    this.platform = null;
    this.dragRoute = [];
    this.currentRoute = 0;
    this.alternatives = 2;
    this.oldAlternative = null;
    this.zindex = 1000;
    this.currentPolyline = null;
    this.polylineLayer = null;
    this.markerLayer = null;
    this.draggableMarkerLayer = null;
    this.routeHoverMarkerLayer = null;
    this.fenceLayer = null;
    this.icon = null;
    this.waypoints = [];
    this.origin = null;
    this.destination = null;

    this.waypointsObj = [];
    this.originObj = null;
    this.destinationObj = null;

    this.sectionsPoints = null;
    this.tolls = [];
    this.exchangeRates = [];
    this.handleClick = this.handleClick.bind(this);

    this.consumptionDetails = {
      fuelConsumption: 5.5, // in liters per 100km
      fuelPrice: 1.5, // in currency per liter
      vehicle_cost: 0.1, // in currency per km
      driver_cost: 0.2, // in currency per hour
      tollCost: 0.5, // in currency per km
      avoidTolls: true,
    };
    this.tmpResult = null;
    this.markerIdMap = new Map();
    this.tollsSummary = null;
    // console.log('');
  }

  /**
   * componentDidUpdate(prevProps)
   *
   * Funkcja
   *
   */
  componentDidUpdate(prevProps, prevState) {
    // if (this.props.saveRoute) {
    //     console.log('this.props.saveRoute:', this.props.saveRoute);
    //     this.saveRoute();
    // }

    if (this.props.saveRoute && !this.state.saveOnce) {
      // console.log('this.props.saveRoute222:', this.props.saveRoute);
      // console.log('this.state.saveOnce222:', this.state.saveOnce);
      this.saveRoute();
      this.setState({
        saveOnce: true,
      });
    }

    if (this.state.result !== null) {
      let temp_cargo_kilometers = 0;
      for (let i = 0; i < this.state.result?.routes[0].sections.length; i++) {
        temp_cargo_kilometers =
          temp_cargo_kilometers +
          this.state.result?.routes[0].sections[i].summary.length / 1000;
      }
      this.props.updateCargoKilometers(temp_cargo_kilometers.toFixed(2));
    }

    // if (prevState.carsListMatrix !== this.props.carsListMatrix) {
    //     this.setState({
    //         carsListMatrix: this.props.carsListMatrix
    //     });
    // }

    // if(this.waypointsObj.length > 0){

    //     console.log("DID UP.. newTrace", this.state.newTrace)

    //     let tmpTrace = this.state.newTrace

    //     function checkIndexEqualsX(array, x) {
    //         if (array[x].index === x) {
    //             return true;
    //         } else {
    //             return false;
    //         }
    //     }

    //     for(let i = 0; this.waypointsObj.length > i; i++){

    //         if(this.waypointsObj[i].index){
    //             if(!checkIndexEqualsX(tmpTrace, this.waypointsObj[i].index)){
    //                 tmpTrace.splice(this.waypointsObj[i].index, 0, this.waypointsObj[i]);
    //             }
    //         }
    //     }

    // }

    let tmpWaypointsObjLength = this.waypointsObj.length;
    let tmpTraceWayPointsLength = this.state.newTrace.length - 2;

    if (tmpWaypointsObjLength > tmpTraceWayPointsLength) {
      const tmpTraceWayPoints = [...this.state.newTrace];
      const pierwszyObiekt = tmpTraceWayPoints.slice(0, 1);
      const ostatniObiekt = tmpTraceWayPoints.slice(-1);
      const nowaTablica = pierwszyObiekt.concat(ostatniObiekt);
      nowaTablica.splice(1, 0, ...this.waypointsObj);
      let tmpNewTrace = [...nowaTablica];
      this.setState({ newTrace: tmpNewTrace });
    }
  }

  /**
   * saveRoute()
   *
   * Funkcja
   *
   */
  saveRoute = () => {
    // console.log('SAVE ROUTE !!!');
    // console.log('this.props.transportSet:', this.props.transport_set);
    // console.log('this.originObj:', this.originObj);
    // console.log('this.destinationObj:', this.destinationObj);
    // console.log('this.waypointsObj:', this.waypointsObj);
    // console.log('order_id:', this.props.order_id);
    // console.log('order_no:', this.props.order_no);

    this.props.saveRouteCallback(
      this.state.calculateRouteParams,
      this.state.result,
      this.originObj,
      this.destinationObj,
      this.waypointsObj,
      this.props.routing_id
    );
  };

  /**
   * componentDidMount()
   *
   * Funkcja
   *
   */
  componentDidMount() {
    // Jeżeli główna trasa został zmieniona - kasujemy pamięć dla routingu
    if (this.props.mainRouteChange) {
      this.fetchSetHeremapsRoutingDelete(
        this.props.routing_id,
        this.props.order_id
      );
    }

    if (!this.map) {
      // instantiate a platform, default layers and a map as usual
      const platform = new H.service.Platform({
        apikey: process.env.REACT_APP_HERE_API_KEY,
      });

      const layers = platform.createDefaultLayers();

      const map = new H.Map(this.ref.current, layers.vector.normal.map, {
        pixelRatio: window.devicePixelRatio,
        center: { lat: 52.22792612730421, lng: 21.002079139896622 },
        zoom: 6,
      });

      // Create the default UI:
      let ui = H.ui.UI.createDefault(map, layers);

      // Enable the event system on the map instance:
      let mapEvents = new H.mapevents.MapEvents(map);

      // Instantiate the default behavior, providing the mapEvents object:
      // add behavior control
      let mapBehavior = new H.mapevents.Behavior(mapEvents);

      this.router = platform.getRoutingService(null, 8);

      let svg =
        '<svg height="20" width="20" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">' +
        '<circle cx="10" cy="10" r="9" stroke="black" stroke-width="2" fill="none" />' +
        "</svg>";

      let icon = new H.map.Icon(svg);

      let markerLayer = new H.map.Group();
      let draggableMarkerLayer = new H.map.Group();
      let polylineLayer = new H.map.Group();
      let fenceLayer = new H.map.Group();
      let routeHoverMarkerLayer = new H.map.Group();

      //this.setStartParams();

      this.platform = platform;
      this.map = map;
      this.ui = ui;
      this.mapBehavior = mapBehavior;
      this.polylineLayer = polylineLayer;
      this.markerLayer = markerLayer;
      this.draggableMarkerLayer = draggableMarkerLayer;
      this.routeHoverMarkerLayer = routeHoverMarkerLayer;
      this.fenceLayer = fenceLayer;
      this.icon = icon;

      // add the layer for markers to the map
      this.map.addObject(markerLayer);
      this.map.addObject(draggableMarkerLayer);
      this.map.addObject(polylineLayer);
      this.map.addObject(routeHoverMarkerLayer);

      // Log
      this.fetchSetLogHeremaps("MAP_TILES");

      // this.map.addEventListener('pointermove', function (event) {
      //     if (event.target instanceof H.map.Marker) {
      //         this.map.getViewPort().element.style.cursor = 'pointer';
      //     } else {
      //         this.map.getViewPort().element.style.cursor = 'auto';
      //     }
      // }, false);

      // Do Route
      this.onClickDoRoute();
      this.fetchRates();

      map.addEventListener("tap", this.checkAddressFromMap);

      if (this.props.trace) {
        this.setState({ newTrace: this.props.trace });
      }

      // if(this.waypointsObj.length > 0){

      //     let tmpTrace = this.state.newTrace

      //     function checkIndexEqualsX(array, x) {
      //         if (array[x].index === x) {
      //             return true;
      //         } else {
      //             return false;
      //         }
      //     }

      //     for(let i = 0; this.waypointsObj.length > i; i++){

      //         if(this.waypointsObj[i].index){
      //             if(!checkIndexEqualsX(tmpTrace, this.waypointsObj[i].index)){
      //                 tmpTrace.splice(this.waypointsObj[i].index, 0, this.waypointsObj[i]);
      //             }
      //         }
      //     }

      // }
    }

    if (this.state.result !== null) {
      let temp_cargo_kilometers = 0;
      for (let i = 0; i < this.state.result?.routes[0].sections.length; i++) {
        temp_cargo_kilometers =
          temp_cargo_kilometers +
          this.state.result?.routes[0].sections[i].summary.length / 1000;
      }
      this.props.updateCargoKilometers(temp_cargo_kilometers.toFixed(2));
    }
  }

  /**
   * fetchSetLogHeremaps()
   *
   * Funkcja
   *
   */
  fetchSetLogHeremaps = async (data) => {
    const response = await fetch(`${DBurl}/setLogHeremaps`, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.props.csrf_token,
      },
      body: JSON.stringify({
        mode: "insert",
        data: {
          id_car: this.props.transport_set.id_car,
          event_name: data,
        },
      }),
    });
    const json = await response.json();
    if (json.success) {
      // console.log('log ok');
    } else {
      // console.log('error');
    }
  };

  /**
   * fetchSetHeremapsRoutingDelete()
   *
   * Funkcja
   *
   */
  fetchSetHeremapsRoutingDelete = async (routing_id, order_id) => {
    // console.log('DELETE Heremap CALL: ', routing_id, order_id);
    const response = await fetch(`${DBurl}/setHeremapsRouting`, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.props.csrf_token,
      },
      body: JSON.stringify({
        mode: "delete",
        data: {
          id_order: order_id,
          id_routing: routing_id,
        },
      }),
    });
    const json = await response.json();
    if (json.success) {
      // console.log('log ok');
    } else {
      // console.log('error');
    }
  };

  /**
   * fetchSetHeremapsRouting()
   *
   * Funkcja
   *
   */
  fetchSetHeremapsRouting = async (data) => {
    // const response = await fetch(`${DBurl}/setHeremapsRouting`, {
    //     method: "post",
    //     credentials: 'include',
    //     headers: {
    //         "Content-Type": "application/json",
    //         "X-CSRF-Token": this.props.csrf_token
    //     },
    //     body: JSON.stringify({
    //         mode: 'insert',
    //         data: {
    //             id_order: this.props.order_id,
    //             request: JSON.stringify(this.state.calculateRouteParams),
    //             response: JSON.stringify(this.state.result)
    //         }
    //     })
    // })
    // const json = await response.json()
    // if (json.success) {
    //     // console.log('log ok');
    // } else {
    //     // console.log('error');
    // }
  };

  /**
   * setStartParams()
   *
   * Funkcja
   *
   */
  setStartParams = () => {
    this.waypoints = [];
    this.waypointsObj = [];
    this.originObj = null;
    this.destinationObj = null;

    // console.log('this.props.trace:', this.props.trace);

    for (let i = 0; i < this.props.trace.length; i++) {
      // console.log(this.props.trace[i].warehouse)
      // Origin
      if (i == 0) {
        if (this.props.trace[i].warehouse != undefined) {
          this.origin =
            this.props.trace[i].warehouse.address.latitude +
            "," +
            this.props.trace[i].warehouse.address.longitude;
        } else {
          this.origin =
            this.props.trace[i].address.latitude +
            "," +
            this.props.trace[i].address.longitude;
        }
        this.originObj = this.props.trace[i];
      }
      // Destination
      else if (i == this.props.trace.length - 1) {
        if (this.props.trace[i].warehouse != undefined) {
          this.destination =
            this.props.trace[i].warehouse.address.latitude +
            "," +
            this.props.trace[i].warehouse.address.longitude;
        } else {
          this.destination =
            this.props.trace[i].address.latitude +
            "," +
            this.props.trace[i].address.longitude;
        }
        this.destinationObj = this.props.trace[i];
      }
      // Waypoints
      else {
        if (this.props.trace[i].warehouse != undefined) {
          this.waypoints.push(
            this.props.trace[i].warehouse.address.latitude +
              "," +
              this.props.trace[i].warehouse.address.longitude
          );
        } else {
          this.waypoints.push(
            this.props.trace[i].address.latitude +
              "," +
              this.props.trace[i].address.longitude
          );
        }
        this.waypointsObj.push(this.props.trace[i]);
      }
    }

    // console.log('this.destination:', this.destination);

    // this.origin = '50.1120423728813,8.68340740740811';
    // this.destination = '52.5309916298853,13.3846220493377';
    // this.waypoints = [
    //     '51.04718784491061,13.74167948779116'
    // ];
  };

  /**
   * onClickParams()
   *
   * Funkcja
   *
   */
  onClickParams = () => {
    // console.log('this.props.trace: ',this.props.trace);
    this.setStartParams();
    // console.log('this.originObj: ',this.originObj);
    // console.log('this.destinationObj: ',this.destinationObj);
    // console.log('this.waypointsObj: ',this.waypointsObj);
  };

  /**
   * calculateRoute()
   *
   * Funkcja
   *
   */
  calculateRoute = async (wPoints) => {
    if (wPoints.length == 2 && (!wPoints[0] || !wPoints[1])) return;

    // var e = document.getElementById("routeOption");
    // var routeOption = e.options[e.selectedIndex].value;

    // var mode = routeOption + ";";
    // mode += document.getElementById('useCar').checked ? "car;" : "truck;"
    // mode += 'traffic:';

    // e = document.getElementById('traffic');
    // var tr = e.options[e.selectedIndex].value;

    // mode += tr + ";";
    // mode += 'tollroad:' + document.getElementById("tollroads").value + ',';
    // mode += 'motorway:' + document.getElementById("motorways").value + ',';
    // mode += 'boatFerry:' + document.getElementById("boatFerries").value + ',';
    // mode += 'railFerry:' + document.getElementById("railFerries").value + ',';
    // mode += 'tunnel:' + document.getElementById("tunnels").value + ',';
    // mode += 'dirtRoad:' + document.getElementById("dirtRoads").value;

    // trailersCount = document.getElementById('trailersCount').value;

    // var tunnelCategory = "";

    // var hazard = new Array();

    // if (document.getElementById('combustible').checked) {
    //     hazard.push("combustible");
    //     tunnelCategory = "D";
    // }
    // if (document.getElementById('organic').checked) {
    //     hazard.push("organic");
    //     tunnelCategory = "D";
    // }
    // if (document.getElementById('poison').checked) {
    //     hazard.push("poison");
    //     tunnelCategory = "D";
    // }
    // if (document.getElementById('radioActive').checked) {
    //     hazard.push("radioActive");
    //     tunnelCategory = "D";
    // }
    // if (document.getElementById('corrosive').checked) {
    //     hazard.push("corrosive");
    //     tunnelCategory = "D";
    // }
    // if (document.getElementById('poisonousInhalation').checked) {
    //     hazard.push("poisonousInhalation");
    //     tunnelCategory = "D";
    // }
    // if (document.getElementById('harmfulToWater').checked) {
    //     hazard.push("harmfulToWater");
    //     tunnelCategory = "D";
    // }
    // if (document.getElementById('other').checked) {
    //     hazard.push("other");
    //     tunnelCategory = "D";
    // }
    // if (document.getElementById('gas').checked) {
    //     hazard.push("gas");
    //     if (tunnelCategory != "D")
    //         tunnelCategory = "E";
    // }
    // if (document.getElementById('flammable').checked) {
    //     hazard.push("flammable");
    //     tunnelCategory = "C";
    // }
    // if (document.getElementById('explosive').checked) {
    //     hazard.push("explosive");
    //     tunnelCategory = "B";
    // }

    // hazard = hazard.join(",");

    // var lWeight = parseFloat(document.getElementById("limitedWeight").value);
    // var aWeight = parseFloat(document.getElementById("weightPerAxel").value);
    // var h = parseFloat(document.getElementById("height").value);
    // var w = parseFloat(document.getElementById("width").value);
    // var l = parseFloat(document.getElementById("length").value);

    // alternatives = parseFloat(document.getElementById("alt").value);
    // if (oldAlternative != alternatives) {
    //     createDragRoutes(alternatives);
    //     oldAlternative = alternatives;
    // }

    // if (isNaN(lWeight)) lWeight = 0;
    // if (isNaN(aWeight)) aWeight = 0;
    // if (isNaN(h)) h = 0;
    // if (isNaN(w)) w = 0;
    // if (isNaN(l)) l = 0;

    // var e = document.getElementById("metric");
    // var isMetric = e.options[e.selectedIndex].value;

    // if (isMetric == 1) {
    //     metricSystem = true;
    // }
    // else {
    //     metricSystem = false;
    // }

    // if (!metricSystem) {
    //     h /= 3.2808;
    //     w /= 3.2808;
    //     l /= 3.2808;
    // }

    // if (wPoints.length > 2) {
    //     alternatives = 1;
    // }

    // collection of waypoints
    // let waypoints = [
    //     '51.04718784491061,13.74167948779116'
    // ];

    // truckType
    // m_eRoutingMode=TruckSettings::RmTruck

    // trailersCount
    // m_dwTrailers=1

    // axleCount
    // m_dwTrailerAxels=1
    // m_dwVehicleAxels=3

    // shippedHazardousGoods
    // m_eLoadRestrictions=TruckSettings::GeneralHazardousMaterials

    // limitedWeight
    // m_arrDimensionalRestrictions

    // weightPerAxle
    // m_arrDimensionalRestrictions

    // height
    // m_arrDimensionalRestrictions

    // width
    // m_arrDimensionalRestrictions

    // length
    // m_arrDimensionalRestrictions

    // tunnelCategory
    // m_eLoadRestrictions=TruckSettings:: TunnelCategoryD

    //     bsoundenabled,
    //     boperaterighthanded,
    //     nvolumemin,
    //     nvolumemax,
    //     distanceunit,
    //     clockformat,
    //     gpsunits,
    //     keyboardtype,
    //     navoidtollroads,
    //     bavoiduturns,
    //     nplanningsettings,
    //     nplanningsettingslimitedspeed,
    //     bavoidferries,
    //     bdisablemainmenu,
    //     bdisablerecompute,
    //     netamaximumspeed,
    //     netapercentagechange,
    //     bradarswarnon,
    //     bradarsvisible,
    //     nradardistance,
    //     nradardistanceincity,
    //     nskin,
    //     ntimezone,
    //     nspeedexceedincity,
    //     nspeedexceed,
    //     nview,
    //     nsignpostdirection,
    //     nsignpostsize,
    //     bsnaptoeveryroad,
    //     bmaxspeedwarn,
    //     bttsenabled,
    //     nvisiblepointreachdistance,
    //     ninvisiblepointreachdistance,
    //     ballowclosedroads,
    //     btruckinmap,
    //     busetruckatt,
    //     ntruckmaxspeed,
    //     ntruckweighttotal,
    //     ntruckweightaxle,
    //     ntrucktandemweight,
    //     ntrucktridemweight,
    //     ntruckotherweight,
    //     ntruckunladenweight,
    //     ntrucklenght,
    //     ntruckaxlelength,
    //     ntrailerlength,
    //     ntractorlength,
    //     nkingpinlastaxle,
    //     nkingpinlasttandem,
    //     nkingpinendtrailer,
    //     ntruckotherlength,
    //     ntruckwidth,
    //     ntruckheight,
    //     nloadrestrictions,
    //     ballowitineraryedit

    // Origin
    let origin = null;
    if (this.originObj.warehouse != undefined) {
      origin =
        this.originObj.warehouse.address.latitude +
        "," +
        this.originObj.warehouse.address.longitude;
    } else {
      origin =
        this.originObj.address.latitude +
        "," +
        this.originObj.address.longitude;
    }

    // Destination
    let destination = null;
    if (this.destinationObj.warehouse != undefined) {
      destination =
        this.destinationObj.warehouse.address.latitude +
        "," +
        this.destinationObj.warehouse.address.longitude;
    } else {
      destination =
        this.destinationObj.address.latitude +
        "," +
        this.destinationObj.address.longitude;
    }

    const vehicles = [
      {
        vehicletype: "straightTruck",
        vehicleheight: 350,
        vehiclewidth: 240,
        vehiclelength: 2000,
        vehicleweightperaxle: 4000,
        vehiclegrossweight: 8000,
        vehicleaxlecount: 2,
        vehicletrailercount: 1,
        vehicletrucktype: "straightTruck",
        shippedhazardousgoods_explosive: 0,
        shippedhazardousgoods_gas: 0,
        shippedhazardousgoods_flammable: 0,
        shippedhazardousgoods_combustible: 0,
        shippedhazardousgoods_organic: 0,
        shippedhazardousgoods_poison: 0,
        shippedhazardousgoods_radioactive: 0,
        shippedhazardousgoods_corrosive: 0,
        shippedhazardousgoods_poisonousinhalation: 0,
        shippedhazardousgoods_harmfultowater: 0,
        shippedhazardousgoods_other: 0,
        tunnelcategory: "",
      },
      {
        vehicletype: "tractor",
        vehicleheight: 380,
        vehiclewidth: 250,
        vehiclelength: 4000,
        vehicleweightperaxle: 8000,
        vehiclegrossweight: 32000,
        vehicleaxlecount: 4,
        vehicletrailercount: 1,
        vehicletrucktype: "tractor",
        shippedhazardousgoods_explosive: 0,
        shippedhazardousgoods_gas: 0,
        shippedhazardousgoods_flammable: 0,
        shippedhazardousgoods_combustible: 0,
        shippedhazardousgoods_organic: 0,
        shippedhazardousgoods_poison: 0,
        shippedhazardousgoods_radioactive: 0,
        shippedhazardousgoods_corrosive: 0,
        shippedhazardousgoods_poisonousinhalation: 0,
        shippedhazardousgoods_harmfultowater: 0,
        shippedhazardousgoods_other: 0,
        tunnelcategory: "",
      },
    ];

    // Waypoints
    let waypoints = this.getWaypoints();

    this.dragRoute[this.currentRoute].calculateRouteParams = {
      routingMode: "short", // => nplanningsettings | przy wyborze 'transportMode': 'truck' możliwe tylko "short" ??? = The following Transport modes only support fast routingMode: bicycle, bus, pedestrian, privateBus, scooter, taxi
      transportMode: "truck", // ustawiamy na sztywno tylko dla ciężarówki ???
      "vehicle[grossWeight]":
        this.props.transport_set.car_profile.ntruckweighttotal, // => ntruckweighttotal | waga brutto - Vehicle weight including trailers and shipped goods, in kilograms.
      "vehicle[weightPerAxle]":
        this.props.transport_set.car_profile.ntruckweightaxle, // => ntruckweightaxle | Vehicle weight per axle, in kilograms.
      //'vehicle[weightPerAxleGroup]': 'single:12,tandem:16,triple:20', // Stosowane zamiennie z weightPerAxle - Weight of different axle groups like single and tandem axles, in kilograms. przykład single:12,tandem:16,triple:20 = truck with 12 tons per single axle group, 16 tons per tandem axle group and 20 tons per triple axle group
      "vehicle[height]": parseInt(
        (this.props.transport_set.car_profile.ntruckheight * 0.1).toFixed(0)
      ), // => ntruckheight | Vehicle height, in centimeters. => ntruckheight
      "vehicle[width]": parseInt(
        (this.props.transport_set.car_profile.ntruckwidth * 0.1).toFixed(0)
      ), // => ntruckwidth | Vehicle width, in centimeters.
      "vehicle[length]": parseInt(
        (this.props.transport_set.car_profile.ntrucklenght * 0.1).toFixed(0)
      ), // => ntrucklenght ? ntractorlength | Vehicle length, in centimeters.
      "vehicle[axleCount]": this.props.transport_set.car_profile.h_axle_count, // => do dodania ??? | Defines total number of axles in the vehicle.
      "vehicle[type]":
        this.props.transport_set.car_profile.h_type === "straight"
          ? "straightTruck"
          : this.props.transport_set.car_profile.h_type, // => do dodania ??? | Specifies the type of truck straight: "straightTruck" a truck on a single frame with a permanently attached cargo area tractor: "tractor" a towing vehicle that can pull one or more semi-trailers (aka semi-truck)
      "vehicle[trailerCount]":
        this.props.transport_set.car_profile.h_trailer_count, // => do dodania ??? | Number of trailers attached to the vehicle
      //'vehicle[tunnelCategory]': this.props.transport_set.car_profile.h_tunel_category, // => wynika z shippedHazardousGoods automatycznie określane | Specifies the tunnel category used to restrict transport of specific goods. Possible values: B, C, D, E
      //'vehicle[shippedHazardousGoods]': this.props.transport_set.car_profile.h_shipped_hazardous_goods, //'explosive,gas,flammable,combustible,organic,poison,radioactive,corrosive,poisonousInhalation,harmfulToWater,other', // => do dodania ??? Comma-separated list of shipped hazardous goods in the vehicle.
      lang: "pl-pl",
      // The start point of the route:
      origin: origin,
      // The end point of the route:
      destination: destination,
      // Via
      via: new H.service.Url.MultiValueQueryParameter(waypoints),
      legAttributes: waypoints,
      // Include the route shape in the response
      //'return': 'polyline,actions,instructions,summary,travelSummary,mlDuration,typicalDuration,turnByTurnActions,elevation,routeHandle,passthrough,incidents,routingZones,truckRoadTypes,tolls',
      "tolls[summaries]": "total",
      "tolls[vignettes]": "all",
      currency: "Pln",
      return: "polyline,summary,travelSummary,tolls",
      // 'spans': 'tollSystems,names,streetAttributes'
      // 'spans': 'walkAttributes,streetAttributes,carAttributes,truckAttributes,scooterAttributes,names,length,duration,routeNumbers,segmentId,segmentRef'
      //'spans': ''
    };

    if (this.props.transport_set.car_profile.emissions !== null) {
      this.dragRoute[this.currentRoute].calculateRouteParams[
        "tolls[emissionType]"
      ] = this.emissionType(
        "param",
        this.props.transport_set.car_profile.emissions
      );
    }

    // console.log('this.dragRoute', this.dragRoute)

    if (this.props.transport_set.car_profile.h_tunel_category !== "") {
      this.dragRoute[this.currentRoute].calculateRouteParams[
        "vehicle[tunnelCategory]"
      ] = this.props.transport_set.car_profile.h_tunel_category; // => wynika z shippedHazardousGoods automatycznie określane | Specifies the tunnel category used to restrict transport of specific goods. Possible values: B, C, D, E
    }
    if (this.props.transport_set.car_profile.h_shipped_hazardous_goods !== "") {
      this.dragRoute[this.currentRoute].calculateRouteParams[
        "vehicle[shippedHazardousGoods]"
      ] =
        this.props.transport_set.car_profile.h_shipped_hazardous_goods.replace(
          /^,/,
          ""
        ); //'explosive,gas,flammable,combustible,organic,poison,radioactive,corrosive,poisonousInhalation,harmfulToWater,other', // => do dodania ??? Comma-separated list of shipped hazardous goods in the vehicle.
    }

    var calculateRouteParams =
      this.dragRoute[this.currentRoute].calculateRouteParams;

    this.setState({ calculateRouteParams: calculateRouteParams });

    // Jeżeli byłe edytowana trasa to lecimy z nowym reoutingiem - jeżeli nie to standardowo
    if (!this.state.wasEditedPolyline) {
      // Jeżeli edycja zlecenia

      // console.log("newTrace",this.state.newTrace)
      // console.log("waypointsObj",this.waypointsObj)

      if (this.props.isEdit && this.state.isModification === false) {
        // let result = await this.fetchGetHeremapsRouting();
        // console.log('result123:', result);

        // Jeżeli są dane z DB
        if (
          this.state.resultFromDB !== null &&
          this.state.resultFromDB.response !== undefined
        ) {
          // Jeżeli sa dane !== null to ładujemy z db
          if (this.state.resultFromDB.response !== null) {
            this.setState({
              calculateRouteParams: this.state.resultFromDB.request,
            });

            // console.log('ROUTING Z DB');
            // console.log('Jeżeli edycja zlecenia->Jeżeli są dane z DB');
            this.onResult(this.state.resultFromDB.response);
          } else {
            // console.log('ROUTING Z HERE1');
            // console.log('Jeżeli edycja zlecenia->Jeżeli nie ma danych w DB');
            // Routing HERE
            this.router.calculateRoute(
              calculateRouteParams,
              this.onResult,
              this.onError
            );
            // Log
            this.fetchSetLogHeremaps("ROUTING");
          }
        } else {
          // console.log('ROUTING Z HERE2');
          // Routing HERE
          this.router.calculateRoute(
            calculateRouteParams,
            this.onResult,
            this.onError
          );
          // Log
          this.fetchSetLogHeremaps("ROUTING");
        }
      }
      // Jeżeli nowe zlecenie
      else {
        // console.log('ROUTING Z HERE3');
        // console.log('Jeżeli nowe zlecenie');
        // Routing HERE
        this.router.calculateRoute(
          calculateRouteParams,
          this.onResult,
          this.onError
        );
        // Log
        this.fetchSetLogHeremaps("ROUTING");
        this.setState({ isModification: false });
      }
    }
    // Trasa zmieniona - nowy routing z HERE
    else {
      // console.log('ROUTING Z HERE4');

      // console.log('Trasa zmieniona - nowy routing z HERE');
      // Routing HERE
      this.router.calculateRoute(
        calculateRouteParams,
        this.onResult,
        this.onError
      );
      // Log
      this.fetchSetLogHeremaps("ROUTING");
    }

    // console.log('ROUTING Z HERE999');
    // // Routing HERE
    // this.router.calculateRoute(calculateRouteParams, this.onResult, this.onError);
    // // Log
    // this.fetchSetLogHeremaps('ROUTING');
  };

  /**
   * fetchGetHeremapsRouting()
   *
   * Funkcja
   *
   */
  fetchGetHeremapsRouting = async () => {
    const response = await fetch(
      `${DBurl}/getHeremapsRouting?id_order=${this.props.order_id}&id_routing=${this.props.routing_id}`,
      {
        method: "get",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": this.props.csrf_token,
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      // console.log('log ok',json);
      // console.log('json.data:', json.data);
      return json.data;
    } else {
      // console.log('error');
      return false;
    }
  };

  /**
   * onResult()
   *
   * Funkcja
   *
   */
  onResult = (result) => {
    this.sectionsPoints = [];

    // console.log('onResult result:', result);
    if (!result || !result.routes || !result.routes[0]) return;

    this.setState({ result: result });

    let temp_tolls_arr = [];
    let allTolls = [];

    for (var i = 0; i < result.routes[0].sections.length; i++) {
      this.currentRoute = i;
      this.MultiplePolyline(result.routes[0].sections[i]);

      // this.setState({ tolls: result.routes[0].sections[i].tolls });

      // // Wyodrębnij całkowitą odległość i całkowity czas z odpowiedzi:
      // var totalDistance = result.routes[0].sections[i].summary.duration;
      // var totalTime = result.routes[0].sections[i].summary.duration;

      // /// Oblicz całkowite zużycie paliwa:
      // var totalFuelConsumption = totalDistance / 100 * this.consumptionDetails.fuelConsumption;

      // // Oblicz całkowity koszt paliwa:
      // var totalFuelCost = totalFuelConsumption * this.consumptionDetails.fuelPrice;

      // // Oblicz całkowity koszt pojazdu:
      // var totalVehicleCost = totalDistance * this.consumptionDetails.vehicle_cost;

      // // Oblicz całkowity koszt kierowcy:
      // var totalDriverCost = (totalTime / 3600) * this.consumptionDetails.driver_cost;

      // // Oblicz całkowity koszt:
      // var totalCost = totalFuelCost + totalVehicleCost + totalDriverCost;

      // // Oblicz całkowity koszt opłaty drogowej:
      // var totalTollCost = totalDistance * this.consumptionDetails.tollCost;

      if (result.routes[0].sections[i].tolls) {
        temp_tolls_arr.push(result.routes[0].sections[i].tolls);
        // console.log('tolls',result.routes[0].sections[i].tolls)
      } else if (result.routes[0].sections[i].type === "transit") {
        if (result.routes[0].sections[i].transport.mode === "ferry") {
          temp_tolls_arr.push(result.routes[0].sections[i].transport);
          // console.log('ferry',result.routes[0].sections[i].transport)
        }
      }

      this.sectionsPoints[i] = {
        currentRoute: this.currentRoute,
        arrival: result.routes[0].sections[i].arrival.place.location,
        departure: result.routes[0].sections[i].departure.place.location,
      };
    }

    for (let t = 0; t < temp_tolls_arr.length; t++) {
      allTolls = allTolls.concat(temp_tolls_arr[t]);
    }

    this.tolls = allTolls;
    this.setState({
      tolls: allTolls,
    });

    this.addMarkersToMap();

    // Set the map's viewport to make the whole route visible:
    this.map
      .getViewModel()
      .setLookAtData({ bounds: this.markerLayer.getBoundingBox() });
  };

  /**
   * addMarkersToMap()
   *
   * Funkcja
   *
   */
  addMarkersToMap = () => {
    let svgIcon =
      '<svg viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg"><path d="M192 0C85.97 0 0 85.97 0 192c0 77.41 26.97 99.03 172.3 309.7c9.531 13.77 29.91 13.77 39.44 0C357 291 384 269.4 384 192C384 85.97 298 0 192 0zM192 271.1c-44.13 0-80-35.88-80-80S147.9 111.1 192 111.1s80 35.88 80 80S236.1 271.1 192 271.1z"/></svg>';
    let downloadIcon =
      '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#ffffff" stroke="#ed3c22" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>';
    let uploadIcon =
      '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#ffffff" stroke="#ed3c22" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>';
    let waypointIcon =
      '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#ffffff" stroke="#ed3c22" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>';
    //let customWaypointIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#ffffff" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>';
    let customWaypointIcon =
      '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#ffffff" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg>';
    // icon
    var pngIcon = new H.map.Icon(svgIcon, { size: { w: 36, h: 50 } });
    var pngIconDownload = new H.map.Icon(downloadIcon, {
      size: { w: 40, h: 40 },
    });
    var pngIconUpload = new H.map.Icon(uploadIcon, { size: { w: 40, h: 40 } });
    var pngIconWaypoint = new H.map.Icon(waypointIcon, {
      size: { w: 40, h: 40 },
    });
    var pngIconCustomWaypoint = new H.map.Icon(customWaypointIcon, {
      size: { w: 20, h: 20 },
    });

    let iconOrigin = null;
    if (this.originObj.type == "loading") {
      iconOrigin = pngIconDownload;
    } else if (this.originObj.type == "unloading") {
      iconOrigin = pngIconUpload;
    } else if (this.originObj.type == "custom-waypoint") {
      iconOrigin = pngIconCustomWaypoint;
    } else {
      iconOrigin = pngIconWaypoint;
    }

    // Create a marker for origin:
    let startMarker = null;
    if (this.originObj.warehouse != undefined) {
      startMarker = new H.map.Marker(
        {
          lat: this.originObj.warehouse.address.latitude,
          lng: this.originObj.warehouse.address.longitude,
        },
        { icon: iconOrigin }
      );
    } else {
      startMarker = new H.map.Marker(
        {
          lat: this.originObj.address.latitude,
          lng: this.originObj.address.longitude,
        },
        { icon: iconOrigin }
      );
    }
    //startMarker.setData('<h1>origin</h1>');

    let iconDestination = null;
    if (this.destinationObj.type == "loading") {
      iconDestination = pngIconDownload;
    } else if (this.destinationObj.type == "unloading") {
      iconDestination = pngIconUpload;
    } else if (this.destinationObj.type == "custom-waypoint") {
      iconDestination = pngIconCustomWaypoint;
    } else {
      iconDestination = pngIconWaypoint;
    }

    // Create a marker for destination:
    let endMarker = null;
    if (this.destinationObj.warehouse != undefined) {
      endMarker = new H.map.Marker(
        {
          lat: this.destinationObj.warehouse.address.latitude,
          lng: this.destinationObj.warehouse.address.longitude,
        },
        { icon: iconDestination }
      );
    } else {
      endMarker = new H.map.Marker(
        {
          lat: this.destinationObj.address.latitude,
          lng: this.destinationObj.address.longitude,
        },
        { icon: iconDestination }
      );
    }
    //endMarker.setData('<h1>destination</h1>');

    this.markerLayer.addObjects([startMarker, endMarker]);

    for (let i = 0; i < this.waypointsObj.length; i++) {
      //let waypoints_coord = this.waypoints[i].split(',');

      let icon = null;
      if (this.waypointsObj[i].type == "loading") {
        icon = pngIconDownload;
      } else if (this.waypointsObj[i].type == "unloading") {
        icon = pngIconUpload;
      } else if (this.waypointsObj[i].type == "custom-waypoint") {
        icon = pngIconCustomWaypoint;
      } else {
        icon = pngIconWaypoint;
      }

      // Create a marker for waypoints:
      let waypointsMarker = null;
      if (this.waypointsObj[i].warehouse != undefined) {
        waypointsMarker = new H.map.Marker(
          {
            lat: this.waypointsObj[i].warehouse.address.latitude,
            lng: this.waypointsObj[i].warehouse.address.longitude,
          },
          { icon: icon }
        );
      } else {
        waypointsMarker = new H.map.Marker(
          {
            lat: this.waypointsObj[i].address.latitude,
            lng: this.waypointsObj[i].address.longitude,
          },
          { icon: icon }
        );
      }

      if (this.waypointsObj[i].type == "custom-waypoint") {
        waypointsMarker.draggable = true;
        waypointsMarker.volatility = true;
        waypointsMarker.addEventListener(
          "dragstart",
          this.dragstartOnWaypoint,
          false
        );
        waypointsMarker.addEventListener(
          "dragend",
          (e) => this.dragendOnWaypoint(e, i),
          false
        );
        waypointsMarker.addEventListener("drag", this.dragOnWaypoint, false);
      }

      //waypointsMarker.setData('<h1>waypoints</h1>');

      if (this.waypointsObj[i].id === null) {
        let markerId = waypointsMarker.getId();
        this.setState({ tmpCustomMarkerId: markerId });
        this.waypointsObj[i].id = markerId;
        this.waypointsObj[i].markerId = markerId;
      }

      this.markerLayer.addObjects([waypointsMarker]);
    }

    //this.markerLayer.addEventListener('tap', this.onTapEvent);
    this.map.addObjects([this.markerLayer]);
  };

  /**
   * dragstartOnWaypoint()
   *
   * Funkcja
   *
   */
  dragstartOnWaypoint = (evt) => {
    this.mapBehavior.disable();
  };

  /**
   * dragendOnWaypoint()
   *
   * Funkcja
   *
   */
  dragendOnWaypoint = (evt, index) => {
    this.mapBehavior.enable();

    let target = evt.target;
    let pointer = evt.currentPointer;

    let coord = this.map.screenToGeo(pointer.viewportX, pointer.viewportY);

    target.setGeometry(coord);

    // Update coord
    this.waypointsObj[index].address.latitude = coord.lat.toString();
    this.waypointsObj[index].address.longitude = coord.lng.toString();

    // Waypoints
    let waypoints = this.getWaypoints();

    this.dragRoute[this.currentRoute].calculateRouteParams["via"] =
      new H.service.Url.MultiValueQueryParameter(waypoints);

    this.setState({ wasEditedPolyline: true });

    this.clearAll();
    // Ilość tras = sekcji pomiędzy waypointami
    this.alternatives = this.waypointsObj.length + 1;
    this.doRoute();
  };

  /**
   * dragOnWaypoint()
   *
   * Funkcja
   *
   */
  dragOnWaypoint = (evt) => {
    let target = evt.target;
    let pointer = evt.currentPointer;

    let coord = this.map.screenToGeo(pointer.viewportX, pointer.viewportY);

    target.setGeometry(coord);
  };

  /**
   * onTapEvent()
   *
   * Funkcja
   *
   */
  onTapEvent = (evt) => {
    var bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
      content: evt.target.getData(),
    });
    this.ui.addBubble(bubble);
  };

  /**
   * onError()
   *
   * Funkcja
   *
   */
  onError = (error) => {
    // console.log(error);
  };

  /**
   * MultiplePolyline()
   *
   * Funkcja
   *
   */
  MultiplePolyline = (section) => {
    var routeInfo = {};
    var waypointMarkers = [];

    var polyline = section.polyline;
    let linestring = H.geo.LineString.fromFlexiblePolyline(polyline);

    var color = "rgba(8,37,226,1)";

    // Fix 2022.10.25 - Dla trasy Włochy - UK występuje tranzyt promem (trasa ma więcej niż jedną sekcję)
    if (this.dragRoute[this.currentRoute] == undefined) {
      this.dragRoute[this.currentRoute] = new Object();
      this.dragRoute[this.currentRoute].polyline = null;
      this.dragRoute[this.currentRoute].calculateRouteParams =
        this.dragRoute[this.currentRoute - 1].calculateRouteParams;
      this.dragRoute[this.currentRoute].routeHoverMarker =
        this.dragRoute[this.currentRoute - 1].routeHoverMarker;
    }

    // Create a polyline to display the route:
    this.dragRoute[this.currentRoute].polyline = new H.map.Polyline(
      linestring,
      {
        style: {
          // strokeColor: 'blue',
          lineWidth: 5,
          lineColor: color,
        },
      }
    );

    // Create a patterned polyline:
    // var routeArrows = new H.map.Polyline(linestring, {
    //     style: {
    //         lineWidth: 5,
    //         fillColor: 'white',
    //         strokeColor: 'rgba(255, 255, 255, 1)',
    //         lineDash: [0, 2],
    //         lineTailCap: 'arrow-tail',
    //         lineHeadCap: 'arrow-head'
    //     }
    // });

    this.dragRoute[this.currentRoute].polyline.routeNo = this.currentRoute;
    this.dragRoute[this.currentRoute].polyline.draggable = true;
    this.dragRoute[this.currentRoute].polyline.setArrows({
      color: "#F00F",
      width: 2,
      length: 3,
      frequency: 4,
    });

    this.polylineLayer.addObject(this.dragRoute[this.currentRoute].polyline);

    // Add the route polyline and the two markers to the map:
    this.map.addObjects([this.polylineLayer]);

    this.dragRoute[this.currentRoute].polyline.addEventListener(
      "pointerenter",
      this.pointerenterOnPolyline,
      false
    );
    this.dragRoute[this.currentRoute].polyline.addEventListener(
      "pointermove",
      this.pointermoveOnPolyline,
      false
    );
    this.dragRoute[this.currentRoute].polyline.addEventListener(
      "dragstart",
      this.dragstartOnPolyline,
      false
    );
    this.dragRoute[this.currentRoute].polyline.addEventListener(
      "drag",
      this.dragOnPolyline,
      false
    );
    this.dragRoute[this.currentRoute].polyline.addEventListener(
      "dragend",
      this.dragendOnPolyline,
      false
    );
  };

  /**
   * clearAll()
   *
   * Funkcja
   *
   */
  clearAll = () => {
    this.markerLayer.removeObjects(this.markerLayer.getObjects());
    this.polylineLayer.removeObjects(this.polylineLayer.getObjects());
    this.routeHoverMarkerLayer.removeObjects(
      this.routeHoverMarkerLayer.getObjects()
    );
  };

  /**
   * pointerenterOnPolyline()
   *
   * Funkcja
   *
   */
  pointerenterOnPolyline = (evt) => {
    this.currentPolyline = evt.target;
    this.currentRoute = this.currentPolyline.routeNo;
    var coord = this.map.screenToGeo(
      evt.pointers[0].viewportX,
      evt.pointers[0].viewportY + 8
    );
    this.dragRoute[this.currentPolyline.routeNo].routeHoverMarker.setGeometry(
      coord
    );
    this.dragRoute[this.currentPolyline.routeNo].routeHoverMarker.setVisibility(
      true
    );
  };

  /**
   * pointermoveOnPolyline()
   *
   * Funkcja
   *
   */
  pointermoveOnPolyline = (evt) => {
    this.currentPolyline = evt.currentTarget;
    this.currentRoute = this.currentPolyline.routeNo;
    var viewportX = evt.pointers[0].viewportX,
      viewportY = evt.pointers[0].viewportY,
      clipedPolyline = this.clipPolyline(
        this.currentPolyline,
        viewportX,
        viewportY,
        8
      ),
      coord = this.map.screenToGeo(viewportX, viewportY + 8);

    if (clipedPolyline.length == 0) {
      this.dragRoute[
        this.currentPolyline.routeNo
      ].routeHoverMarker.setVisibility(false);
      return;
    }

    this.dragRoute[this.currentPolyline.routeNo].routeHoverMarker.setGeometry(
      coord
    );
  };

  /**
   * dragstartOnPolyline()
   *
   * Funkcja
   *
   */
  dragstartOnPolyline = (evt) => {
    this.currentPolyline = evt.currentTarget;
    this.currentRoute = this.currentPolyline.routeNo;

    this.mapBehavior.disable();

    var viewportX = evt.pointers[0].viewportX,
      viewportY = evt.pointers[0].viewportY,
      pStrip = this.currentPolyline.getGeometry(),
      clipedPolyline = this.clipPolyline(
        this.currentPolyline,
        viewportX,
        viewportY,
        8
      ),
      foundIdxPolylineForWaypoint = false,
      partPolyline = false,
      eachStripPointFn = function (lat, lng, alt, idx) {
        if (foundIdxPolylineForWaypoint) return;
        if (partPolyline[0] == lat && partPolyline[1] == lng) {
          for (var iP = 2, lP = partPolyline.length; iP < lP; iP += 2) {
            var stripPoint = pStrip.extractPoint(idx + iP / 2);
            if (
              stripPoint &&
              stripPoint.lat == partPolyline[iP] &&
              stripPoint.lng == partPolyline[iP + 1]
            ) {
              foundIdxPolylineForWaypoint = idx;
            } else {
              foundIdxPolylineForWaypoint = false;
            }
          }
        }
      };
    if (clipedPolyline[0]) {
      partPolyline = clipedPolyline[0];
      pStrip.eachLatLngAlt(eachStripPointFn);
    }
    this.dragRoute[this.currentPolyline.routeNo].foundIdxPolylineForWaypoint =
      foundIdxPolylineForWaypoint;
  };

  /**
   * dragOnPolyline()
   *
   * Funkcja
   *
   */
  dragOnPolyline = (evt) => {
    this.currentPolyline = evt.currentTarget;
    this.currentRoute = this.currentPolyline.routeNo;
    var coord = this.map.screenToGeo(
        evt.pointers[0].viewportX,
        evt.pointers[0].viewportY + 8
      ),
      newWpIdx = false;

    if (
      !this.dragRoute[this.currentPolyline.routeNo].foundIdxPolylineForWaypoint
    )
      return;

    this.dragRoute[this.currentPolyline.routeNo].allWaypoitsForNewRoute = [];
  };

  /**
   * dragendOnPolyline()
   *
   * Funkcja
   *
   */
  dragendOnPolyline = async (evt) => {
    this.mapBehavior.enable();
    this.currentPolyline = evt.currentTarget;
    this.currentRoute = this.currentPolyline.routeNo;

    var coord = this.map.screenToGeo(
      evt.pointers[0].viewportX,
      evt.pointers[0].viewportY + 8
    );
    const address = await this.fetchLonLat(
      coord.lng.toString(),
      coord.lat.toString()
    );

    let customWaypoint = {
      type: "custom-waypoint",
      address: {
        latitude: coord.lat.toString(),
        longitude: coord.lng.toString(),
        city: address.city,
        country_code: address.country_code,
      },
      id: this.state.tmpCustomMarkerId || null,
      markerId: this.state.tmpCustomMarkerId || null,
    };

    this.setState({ tmpCustomMarkerId: null });
    // Waypoints
    this.modifyWaypoints(this.currentRoute, customWaypoint);
    let waypoints = this.getWaypoints();

    this.dragRoute[this.currentRoute].calculateRouteParams["via"] =
      new H.service.Url.MultiValueQueryParameter(waypoints);

    this.setState({ wasEditedPolyline: true });

    this.clearAll();
    // Ilość tras = sekcji pomiędzy waypointami
    this.alternatives = this.currentRoute + 1; //this.waypointsObj.length + 1;
    this.doRoute();
  };

  /**
   * modifyWaypoints()
   *
   * Funkcja
   *
   */
  modifyWaypoints = (currentRoute, customWaypoint) => {
    this.waypointsObj.splice(currentRoute, 0, customWaypoint);
  };

  /**
   * getWaypoints()
   *
   * Funkcja
   *
   */
  getWaypoints = () => {
    let waypoints = [];

    for (let i = 0; i < this.waypointsObj.length; i++) {
      if (this.waypointsObj[i].warehouse != undefined) {
        waypoints.push(
          this.waypointsObj[i].warehouse.address.latitude +
            "," +
            this.waypointsObj[i].warehouse.address.longitude
        );
      } else {
        waypoints.push(
          this.waypointsObj[i].address.latitude +
            "," +
            this.waypointsObj[i].address.longitude
        );
      }
    }

    return waypoints;
  };

  /**
   * removeAllRouteObjects()
   *
   * Funkcja
   *
   */
  removeAllRouteObjects = (routeInfo) => {
    try {
      this.polylineLayer.removeObject(routeInfo.polyline);
    } catch (e) {}

    for (var iW = 0, lW = routeInfo.waypointMarkers.length; iW < lW; iW++) {
      try {
        this.markerLayer.removeObject(routeInfo.waypointMarkers[iW]);
      } catch (e) {}
    }
  };

  /**
   * clipPolyline()
   *
   * Funkcja
   *
   */
  clipPolyline = (polyline, viewportX, viewportY, bboxSize) => {
    var pleft = viewportX - bboxSize,
      pright = viewportX + bboxSize,
      ptop = viewportY - bboxSize,
      pbottom = viewportY + bboxSize,
      coordLeftTop = this.map.screenToGeo(pleft, ptop),
      coordRigthBottom = this.map.screenToGeo(pright, pbottom),
      rect = new H.geo.Rect(
        coordLeftTop.lat,
        coordLeftTop.lng,
        coordRigthBottom.lat,
        coordRigthBottom.lng
      ),
      clipedPolyline = polyline.clip(rect);

    return clipedPolyline;
  };

  /**
   * createDragRoutes()
   *
   * Funkcja
   *
   */
  createDragRoutes = (alternatives) => {
    for (var i = 0; i < alternatives; i++) {
      this.dragRoute[i] = new Object();
      this.dragRoute[i].routeHoverMarker = new H.map.Marker(
        { lat: 50.126237073013314, lng: 8.627775069326162 },
        {
          icon: this.icon,
          visibility: false,
          zIndex: 1,
          volatility: true,
        }
      );
      this.dragRoute[i].routeHoverMarker.draggable = true;

      this.routeHoverMarkerLayer.addObject(this.dragRoute[i].routeHoverMarker);

      this.dragRoute[i].routeHoverMarker.addEventListener(
        "pointermove",
        this.pointermoveOnRouteHoverMarker,
        false
      );
      this.dragRoute[i].routeHoverMarker.addEventListener(
        "dragstart",
        this.dragstartOnRouteHoverMarker,
        true
      );
      this.dragRoute[i].routeHoverMarker.addEventListener(
        "drag",
        this.dragOnRouteHoverMarker,
        false
      );
      this.dragRoute[i].routeHoverMarker.addEventListener(
        "dragend",
        this.dragendOnRouteHoverMarker,
        false
      );

      this.dragRoute[i].calculateRouteParams = {};
    }
  };

  /**
   * pointermoveOnRouteHoverMarker()
   *
   * Funkcja
   *
   */
  pointermoveOnRouteHoverMarker = (evt) => {
    this.currentPolyline.dispatchEvent(evt);
    this.currentPolyline.setZIndex(this.zindex++);
  };

  /**
   * dragstartOnRouteHoverMarker()
   *
   * Funkcja
   *
   */
  dragstartOnRouteHoverMarker = (evt) => {
    this.currentPolyline.dispatchEvent(evt);
  };

  /**
   * dragOnRouteHoverMarker()
   *
   * Funkcja
   *
   */
  dragOnRouteHoverMarker = (evt) => {
    var coord = this.map.screenToGeo(
      evt.pointers[0].viewportX,
      evt.pointers[0].viewportY + 8
    );
    evt.target.setGeometry(coord);

    this.currentPolyline.dispatchEvent(evt);
  };

  /**
   * dragendOnRouteHoverMarker()
   *
   * Funkcja
   *
   */
  dragendOnRouteHoverMarker = (evt) => {
    this.currentPolyline.dispatchEvent(evt);
  };

  /**
   * showRoute()
   *
   * Funkcja
   *
   */
  showRoute = () => {
    let linestring = H.geo.LineString.fromFlexiblePolyline(this.polyline);

    // Create a polyline to display the route:
    let routeLine = new H.map.Polyline(linestring, {
      style: { strokeColor: "blue", lineWidth: 3 },
    });

    // Add the route polyline and the two markers to the map:
    this.map.addObjects([routeLine]);

    // Set the map's viewport to make the whole route visible:
    this.map
      .getViewModel()
      .setLookAtData({ bounds: routeLine.getBoundingBox() });
  };

  /**
   * onClickDoRoute()
   *
   * Funkcja
   *
   */
  onClickDoRoute = async () => {
    let result = await this.fetchGetHeremapsRouting();

    this.tmpResult = result;
    let orgin_changed = false;
    let waypoints_changed = false;
    let destination_changed = false;
    let draw_new_route = false;

    if (result.request !== undefined) {
      let savedOrigin =
        result.origin.warehouse !== undefined
          ? result.origin.warehouse.address
          : result.origin.address;
      let savedDestination =
        result.destination.warehouse !== undefined
          ? result.destination.warehouse.address
          : result.destination.address;
      let savedWaypoints_tmp = this.tmpResult.waypoints.filter((o) => {
        return o.type !== "custom-waypoint";
      });
      let savedWaypoints = savedWaypoints_tmp.map((o) => {
        if (o.warehouse) {
          return o.warehouse.address;
        } else {
          return o.address;
        }
      });

      let new_points_count = this.props.trace.length;

      let newOrgin =
        this.props.trace[0].warehouse !== undefined
          ? this.props.trace[0].warehouse.address
          : this.props.trace[0].address;
      let newDestination =
        this.props.trace[new_points_count - 1].warehouse !== undefined
          ? this.props.trace[new_points_count - 1].warehouse.address
          : this.props.trace[new_points_count - 1].address;
      let tempNewWaypoints = this.props.trace.filter((o, i) => {
        return i !== 0 && i !== new_points_count - 1;
      });
      let newWaypoints = tempNewWaypoints.map((o, i) => {
        if (o.warehouse) {
          return o.warehouse.address;
        } else {
          return o.address;
        }
      });

      newOrgin?.latitude !== savedOrigin?.latitude ||
      newOrgin?.longitude !== savedOrigin?.longitude
        ? (orgin_changed = true)
        : (orgin_changed = false);

      newDestination?.latitude !== savedDestination?.latitude ||
      newDestination?.longitude !== savedDestination?.longitude
        ? (destination_changed = true)
        : (destination_changed = false);

      for (let i = 0; i < savedWaypoints.length; i++) {
        if (
          savedWaypoints[i].latitude !== newWaypoints[i].latitude ||
          savedWaypoints[i].longitude !== newWaypoints[i].longitude
        ) {
          waypoints_changed = true;
        }
      }
    }

    if (
      orgin_changed === true ||
      waypoints_changed === true ||
      destination_changed === true ||
      this.props.mainRouteChange === true
    ) {
      draw_new_route = true;
    }

    if (result.request !== undefined && draw_new_route === false) {
      // console.log('JEST TRASA W DB');
      this.setState({ resultFromDB: result });
      this.alternatives = result.request.via.a.length + 1;

      this.originObj = result.origin;
      this.destinationObj = result.destination;
      this.waypointsObj = result.waypoints;
    } else {
      // console.log('NIE MA TRASY W DB');
      this.setStartParams();
      // Ilość tras = sekcji pomiędzy waypointami
      this.alternatives = this.waypointsObj.length + 1;
    }

    // console.log('this.alternatives:', this.alternatives);
    this.doRoute();
  };

  /**
   * doRoute()
   *
   * Funkcja
   *
   */
  doRoute = () => {
    this.createDragRoutes(this.alternatives);

    let gFrom = [50.1120423728813, 8.68340740740811];
    let gTo = [52.5309916298853, 13.3846220493377];

    this.calculateRoute([gFrom, gTo]);
  };

  /**
   * doRoute()
   *
   * Funkcja
   *
   */
  doRouteOLD = () => {
    // Create the parameters for the routing request:
    let routingParameters = {
      routingMode: "fast",
      transportMode: "car",
      // The start point of the route:
      origin: "50.1120423728813,8.68340740740811",
      // The end point of the route:
      destination: "52.5309916298853,13.3846220493377",
      // Include the route shape in the response
      return: "polyline",
    };

    // Get an instance of the routing service version 8:
    let router = this.platform.getRoutingService(null, 8);

    // Call calculateRoute() with the routing parameters,
    // the callback and an error callback function (called if a
    // communication error occurs):
    router.calculateRoute(
      routingParameters,
      this.onResultOLD,
      function (error) {
        console.log(error.message);
      }
    );

    this.createDragRoutes(this.alternatives);
  };

  /**
   * onResultOLD(result)
   *
   * Funkcja
   *
   */
  onResultOLD = (result) => {
    // ensure that at least one route was found
    if (result.routes.length) {
      result.routes[0].sections.forEach((section) => {
        // Create a linestring to use as a point source for the route line
        let linestring = H.geo.LineString.fromFlexiblePolyline(
          section.polyline
        );

        // Create a polyline to display the route:
        let routeLine = new H.map.Polyline(linestring, {
          style: { strokeColor: "blue", lineWidth: 3 },
        });

        // Create a marker for the start point:
        let startMarker = new H.map.Marker(section.departure.place.location);

        // Create a marker for the end point:
        let endMarker = new H.map.Marker(section.arrival.place.location);

        // Add the route polyline and the two markers to the map:
        this.map.addObjects([routeLine, startMarker, endMarker]);

        // Set the map's viewport to make the whole route visible:
        this.map
          .getViewModel()
          .setLookAtData({ bounds: routeLine.getBoundingBox() });
      });
    }
  };

  fetchRates = async () => {
    const response = await fetch(`${DBurl}/getExchangeRates`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.props.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      this.exchangeRates = json.data;
    }
  };

  handleClick() {
    this.setState({
      isCollapsed: !this.state.isCollapsed,
    });
  }

  handleClickParam() {
    this.setState({
      isCollapsedParam: !this.state.isCollapsedParam,
    });
  }

  handleClickPoints() {
    this.setState({
      isCollapsedPoints: !this.state.isCollapsedPoints,
    });
  }

  iconRow = (type) => {
    switch (type) {
      case "loading":
        return <Download />;
      case "unloading":
        return <Upload />;
      case "point":
        return <MapPin />;
      case "custom-waypoint":
        return <Circle />;
      default:
        return <MapPin />;
    }
  };

  findMarkerById = (markerId) => {
    return this.markerIdMap.get(markerId) || null;
  };

  addNewPosition = (index) => {
    let tmpNewTraceRow = {
      type: "custom-waypoint",
      address: {},
      prev: true,
      // index: index + 1,
    };

    this.setState({ activeIndex: index });
    const newArray = [...this.state.newTrace];
    newArray.splice(index + 1, 0, tmpNewTraceRow);
    this.setState({
      newTrace: newArray,
      mapCursor: true,
    });
  };

  editPosition = (index, obj) => {
    // if(obj.prev !== undefined && obj.markerId){
    if (obj.markerId && typeof obj.markerId === "number") {
      const markerIdEdit = obj.markerId;
      const updatedObjects = this.waypointsObj.filter((o, i) => {
        return o.index !== index;
      });

      const updatedObjectsList = this.state.newTrace.map((obj) => {
        if (obj.markerId === markerIdEdit) {
          const { address, ...updatedObj } = obj;
          return { ...updatedObj, prev: true };
        }
        return obj;
      });

      this.setState({ newTrace: updatedObjectsList });
      this.waypointsObj = updatedObjects;
    }
  };

  delPosition = (obj) => {
    const { newTrace } = this.state;

    if (obj.address.city === undefined) {
      const updatedObjectsWaypoints = newTrace.filter((o, i) => {
        return obj.id !== o.id;
      });
      this.setState({ newTrace: updatedObjectsWaypoints });
    } else {
      const updatedObjectsWaypoints = newTrace.filter((o, i) => {
        return obj.id !== o.id;
      });

      let tmp = [...updatedObjectsWaypoints];
      tmp.splice(0, 1); // Usuwa pierwszy obiekt
      tmp.splice(tmp.length - 1, 1); // Usuwa ostatni obiekt

      this.setState({ newTrace: updatedObjectsWaypoints });
      this.waypointsObj = tmp;
      this.routeToPoint();
    }
  };

  setNewRoad = () => {
    // let waypoints = this.getWaypoints();
    // console.log("result", this.state.result)
    // this.router.calculateRoute(this.state.calculateRouteParams, this.onResult, this.onError);
  };

  fetchLonLat = (lon, lat) => {
    return new Promise((resolve, reject) => {
      fetch(`${DBurl}/reverse?lon=${lon}&lat=${lat}`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": this.props.csrf_token,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.success) {
            const result = {
              address_display_name: json.data.display_name,
              country: json.data.address.country,
              city: json.data.address.city,
              street: json.data.address.road,
              street_no: json.data.address.house_number,
              zipcode: json.data.address.postcode,
              longitude: json.data.lon,
              latitude: json.data.lat,
              country_code: json.data.address.country_code,
              district: json.data.address.state,
            };
            resolve(result);
          } else {
            reject(new Error("Error retrieving address"));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  checkAddressFromMap = async (e) => {
    if (this.state.newTrace.some((o2) => o2.prev && o2.prev === true)) {
      const { lat, lng } = this.map.screenToGeo(
        e.currentPointer.viewportX,
        e.currentPointer.viewportY
      );

      const customWaypointIcon =
        '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="transparent" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg>';
      const pngIconCustomWaypoint = new H.map.Icon(customWaypointIcon, {
        size: { w: 20, h: 20 },
        anchor: { x: 11, y: 10 },
        origin: { x: 11, y: 10 },
      });

      const marker = new H.map.Marker(
        { lat, lng },
        {
          icon: pngIconCustomWaypoint,
          volatility: true,
        }
      );

      let markerId = marker.getId();

      this.markerLayer.addObject(marker);
      this.markerIdMap.set(markerId, marker);
      this.setState({ previousMarker: marker });

      const address = await this.fetchLonLat(lng, lat);

      const updatedObjects = this.state.newTrace.map((o, i) => {
        if (o.prev) {
          return {
            ...o,
            address: address,
            prev: false,
            markerId: markerId,
            added: true, // Dodanie identyfikatora markera do obiektu trasy
            id: `custom_${markerId}`,
          };
        }
        return o;
      });

      updatedObjects && this.setState({ newTrace: updatedObjects });

      let tmp = [...updatedObjects];
      tmp.splice(0, 1); // Usuwa pierwszy obiekt
      tmp.splice(tmp.length - 1, 1); // Usuwa ostatni obiekt

      this.waypointsObj = tmp;
      this.setState({
        activeIndex: null,
        mapCursor: false,
      });

      this.routeToPoint();
    }
  };

  routeToPoint = () => {
    let origin = null;
    if (this.originObj.warehouse !== undefined) {
      origin =
        this.originObj.warehouse.address.latitude +
        "," +
        this.originObj.warehouse.address.longitude;
    } else {
      origin =
        this.originObj.address.latitude +
        "," +
        this.originObj.address.longitude;
    }

    let destination = null;
    if (this.destinationObj.warehouse !== undefined) {
      destination =
        this.destinationObj.warehouse.address.latitude +
        "," +
        this.destinationObj.warehouse.address.longitude;
    } else {
      destination =
        this.destinationObj.address.latitude +
        "," +
        this.destinationObj.address.longitude;
    }

    let waypoints = this.getWaypoints();

    let calculateRouteParamsNew = {
      routingMode: "short", // => nplanningsettings | przy wyborze 'transportMode': 'truck' możliwe tylko "short" ??? = The following Transport modes only support fast routingMode: bicycle, bus, pedestrian, privateBus, scooter, taxi
      transportMode: "truck", // ustawiamy na sztywno tylko dla ciężarówki ???
      "vehicle[grossWeight]":
        this.props.transport_set.car_profile.ntruckweighttotal, // => ntruckweighttotal | waga brutto - Vehicle weight including trailers and shipped goods, in kilograms.
      "vehicle[weightPerAxle]":
        this.props.transport_set.car_profile.ntruckweightaxle, // => ntruckweightaxle | Vehicle weight per axle, in kilograms.
      "vehicle[height]":
        this.props.transport_set.car_profile.ntruckheight * 0.1, // => ntruckheight | Vehicle height, in centimeters. => ntruckheight
      "vehicle[width]": this.props.transport_set.car_profile.ntruckwidth * 0.1, // => ntruckwidth | Vehicle width, in centimeters.
      "vehicle[length]":
        this.props.transport_set.car_profile.ntrucklenght * 0.1, // => ntrucklenght ? ntractorlength | Vehicle length, in centimeters.
      "vehicle[axleCount]": this.props.transport_set.car_profile.h_axle_count, // => do dodania ??? | Defines total number of axles in the vehicle.
      "vehicle[type]":
        this.props.transport_set.car_profile.h_type === "straight"
          ? "straightTruck"
          : this.props.transport_set.car_profile.h_type, // => do dodania ??? | Specifies the type of truck straight: "straightTruck" a truck on a single frame with a permanently attached cargo area tractor: "tractor" a towing vehicle that can pull one or more semi-trailers (aka semi-truck)
      "vehicle[trailerCount]":
        this.props.transport_set.car_profile.h_trailer_count, // => do dodania ??? | Number of trailers attached to the vehicle
      lang: "pl-pl",
      origin: origin,
      destination: destination,
      // Via
      via: new H.service.Url.MultiValueQueryParameter(waypoints),
      legAttributes: waypoints,
      "tolls[summaries]": "total",
      "tolls[vignettes]": "all",
      currency: "Pln",
      return: "polyline,summary,travelSummary,tolls",
    };

    if (this.props.transport_set.car_profile.emissions !== null) {
      calculateRouteParamsNew["tolls[emissionType]"] = this.emissionType(
        "param",
        this.props.transport_set.car_profile.emissions
      );
    }

    // console.log("calculateRouteParams",calculateRouteParamsNew)

    // this.setState({ wasEditedPolyline: true });
    // this.alternatives = this.currentRoute + 1;//this.waypointsObj.length + 1;

    this.clearAll();

    this.setState({ isModification: true });

    this.calculateRoute(calculateRouteParamsNew, this.onResult, this.onError);
  };

  // groupTollsBy = (items, key) => items.reduce((result, item) => {
  //     const prop = item[key];
  //     result[prop] = (result[prop] || []).concat(item);
  //     return console.log('prop',prop);
  // }, {});

  /**
   * render()
   *
   * Funkcja
   *
   */

  exchangeToPLN = (value, code, arr) => {
    if (arr.length > 0) {
      let tmpExData = arr.find((o) => {
        return o.code === code;
      });
      if (tmpExData) {
        let mpExValue =
          tmpExData && typeof tmpExData.value === "string"
            ? Number(tmpExData.value)
            : tmpExData.value;
        let val =
          tmpExData && typeof tmpExData.value === "string"
            ? Number(value)
            : value;
        let result = mpExValue * val;
        return Number.isNaN(result) ? 0 : result.toFixed(2);
      } else {
        return "-";
      }
    }
  };

  countryCodes = (tollsArray, key) => {
    const countryArr = [];

    for (const toll of tollsArray) {
      if (key in toll) {
        countryArr.push(toll[key]);
      }
    }

    return countryArr;
  };

  tollCostsSummary = (temp_tolls) => {
    if (temp_tolls.length > 0) {
      let tempArrCountrysCurr = this.countryCodes(temp_tolls, "countryCode");
      let uniqueCountryCurrArray = [...new Set(tempArrCountrysCurr)];
      const arrPerCountry = [];
      const iso = require("iso-3166-1");
      const regionNames = new Intl.DisplayNames(["pl"], { type: "region" });

      for (let i = 0; i < uniqueCountryCurrArray.length; i++) {
        let country_sum_orgin = 0;
        let country_sum_pln = 0;
        let currency = null;

        for (let j = 0; j < temp_tolls.length; j++) {
          if (uniqueCountryCurrArray[i] === temp_tolls[j].countryCode) {
            currency = temp_tolls[j].fares[0].price.currency;
            if (
              temp_tolls[j].countryCode !== "POL" &&
              temp_tolls[j].countryCode !== "RUB"
            ) {
              let temp_price = Number(temp_tolls[j].fares[0].price.value);
              let temp_to_pln = Number(
                this.exchangeToPLN(
                  temp_tolls[j].fares[0].price.value,
                  temp_tolls[j].fares[0].price.currency,
                  this.props.exchangeRates
                )
              );
              if (temp_price && temp_to_pln) {
                country_sum_pln += temp_to_pln || 0;
                country_sum_orgin += temp_price || 0;
              }
            } else {
              if (temp_tolls[j].countryCode === "POL") {
                country_sum_orgin +=
                  Number(temp_tolls[j].fares[0].price.value) || 0;
              } else if (temp_tolls[j].countryCode === "RUB") {
                country_sum_pln +=
                  (Number(temp_tolls[j].fares[0].price.value) || 0) * 0.063;
              }
            }
          }
        }

        let tmpSummaryPrice = {
          countryCode: uniqueCountryCurrArray[i],
          countryName: regionNames.of(
            iso.whereAlpha3(uniqueCountryCurrArray[i]).alpha2
          ),
          sumPriceOrgin: country_sum_orgin,
          sumPriceToPLN: country_sum_pln,
          currency: currency,
        };

        arrPerCountry.push(tmpSummaryPrice);
      }

      // console.log("arrPerCountry..",arrPerCountry)

      return arrPerCountry;
    }
  };

  areArraysEqual = (arr1, arr2) => {
    if ((arr1, arr2)) {
      if (arr1.length !== arr2.length) {
        return false;
      }
      const arr1JSON = JSON.stringify(arr1);
      const arr2JSON = JSON.stringify(arr2);

      return arr1JSON === arr2JSON;
    } else {
      return null;
    }
  };

  emissionType = (output_type, value) => {
    switch (value) {
      case 1:
        return output_type === "view" ? "Euro 1" : "euro1";
      case 2:
        return output_type === "view" ? "Euro 2" : "euro2";
      case 3:
        return output_type === "view" ? "Euro 3" : "euro3";
      case 4:
        return output_type === "view" ? "Euro 4" : "euro4";
      case 5:
        return output_type === "view" ? "Euro 5" : "euro5";
      case 6:
        return output_type === "view" ? "Euro 6" : "euro6";
      case null:
        return output_type === "view" ? "Brak" : null;
      default:
        return null;
    }
  };

  render() {
    if (this.state.result !== null) {
      let temp_cargo_kilometers = 0;
      for (let i = 0; i < this.state.result?.routes[0].sections.length; i++) {
        temp_cargo_kilometers =
          temp_cargo_kilometers +
          this.state.result?.routes[0].sections[i].summary.length / 1000;
      }
      this.props.updateCargoKilometers(temp_cargo_kilometers.toFixed(2));
    }

    const override = {
      width: "10px",
      height: "10px",
    };

    let car_type =
      this.props.transport_set.car_profile.h_type === "tractor"
        ? "Ciągnik"
        : "Pojazd ciężarowy";
    let hazardous_goods = [];
    let hazardous_goods_arr =
      this.props.transport_set.car_profile.h_shipped_hazardous_goods.split(",");
    hazardous_goods_arr.forEach((good) => {
      switch (good) {
        case "explosive":
          hazardous_goods.push("Materiały wybuchowe");
          break;
        case "gas":
          hazardous_goods.push("Gas");
          break;
        case "flammable":
          hazardous_goods.push("Materiały palne");
          break;
        case "combustible":
          hazardous_goods.push("Materiały organiczne");
          break;
        case "poison":
          hazardous_goods.push("Materiały trujące");
          break;
        case "radioactive":
          hazardous_goods.push("Materiały radioaktywne");
          break;
        case "corrosive":
          hazardous_goods.push("Materiały żrące");
          break;
        case "poisonousInhalation":
          hazardous_goods.push("Materiały trujące przy wdychaniu");
          break;
        case "harmfulToWater":
          hazardous_goods.push("Materiały szkodliwe dla wody");
          break;
        case "other":
          hazardous_goods.push("Inne");
          break;
      }
    });

    const images = importAll(
      require.context("../../img/flags", false, /\.(png|gif|svg)$/)
    );
    const iso = require("iso-3166-1");

    function importAll(r) {
      let images = {};
      r.keys().map((item, index) => {
        images[item.replace("./", "")] = r(item);
      });
      return images;
    }

    const renderFlag = (code) => {
      return code.toLowerCase() + ".gif";
    };

    let temp_tolls = this.tolls;
    let sum = 0;

    if (temp_tolls.length > 0) {
      for (const val of temp_tolls) {
        if (val?.mode !== "ferry") {
          if (val?.fares[0].price.currency === "PLN") {
            sum += Number(val.fares[0].price.value) || 0;
          } else if (val?.fares[0].price.currency === "RUB") {
            sum += (Number(val.fares[0].price.value) || 0) * 0.063;
          } else {
            let temp_to_pln = this.exchangeToPLN(
              val.fares[0].price.value,
              val.fares[0].price.currency,
              this.props.exchangeRates
            );
            let parsed_value = Number(temp_to_pln);
            if (!Number.isNaN(parsed_value)) {
              sum += parsed_value || 0;
            }
          }
        }
      }
    }

    // if (temp_tolls.length > 0) {
    //     let tempArrCountrysCurr = this.countryCodes(temp_tolls,"countryCode")
    //     let uniqueCountryCurrArray = [...new Set(tempArrCountrysCurr)];
    //     console.log(">>> temp_tolls",temp_tolls)

    //     const arrPerCountry = []

    //     for (let i = 0; i < uniqueCountryCurrArray.length; i++) {

    //         let country_sum_orgin = 0;
    //         let country_sum_pln = 0;
    //         let currency = null

    //         for(let j = 0; j < temp_tolls.length; j++){
    //             if(uniqueCountryCurrArray[i] === temp_tolls[j].countryCode){
    //                 currency = temp_tolls[j].fares[0].price.currency
    //                 if(temp_tolls[j].countryCode !== "POL" && temp_tolls[j].countryCode !== 'RUB'){
    //                     let temp_price = parseInt(temp_tolls[j].fares[0].price.value);
    //                     let temp_to_pln =  parseInt(this.exchangeToPLN(temp_tolls[j].fares[0].price.value, temp_tolls[j].fares[0].price.currency, this.props.exchangeRates));
    //                     if (temp_price && temp_to_pln) {
    //                         country_sum_pln += temp_to_pln || 0;
    //                         country_sum_orgin += temp_price || 0;
    //                     }
    //                 }else{
    //                     if(temp_tolls[j].countryCode === "POL"){
    //                         country_sum_orgin += parseInt(temp_tolls[j].fares[0].price.value) || 0;

    //                     }else if(temp_tolls[j].countryCode === "RUB"){
    //                         country_sum_pln += (parseInt(temp_tolls[j].fares[0].price.value) || 0) * 0.063;

    //                     }
    //                 }

    //             }
    //         }
    //         let tmpSummaryPrice = {
    //             countryCode: uniqueCountryCurrArray[i],
    //             sumPriceOrgin: country_sum_orgin,
    //             sumPriceToPLN: country_sum_pln,
    //             currency: currency
    //         }

    //         arrPerCountry.push(tmpSummaryPrice)

    //     }

    //     console.log("arrPerCountry",arrPerCountry)

    // }

    return (
      <div className={styles.mapView}>
        <div
          className={
            this.state.mapCursor === true
              ? styles.mapCursorPointer
              : styles.mapCursorNormal
          }
          style={{ width: "100%", height: "100%" }}
          ref={this.ref}
        />
        <div className={styles.mapInfo}>
          {true && (
            <div className={styles.routingHereSummaryBox}>
              <div className={styles.popupHeaderLoader}>
                Koszty opłat drogowych:
                <div style={{ display: "flex", alignContent: "center" }}>
                  <ClipLoader
                    color={"#ed3c22"}
                    loading={
                      !(this.tolls?.length && this.tollCostsSummary(this.tolls))
                    }
                    speedMultiplier={1}
                    cssOverride={override}
                  />
                </div>
                <div></div>
                <button
                  className={styles.collapse}
                  onClick={() => this.handleClick()}
                >
                  {this.state.isCollapsed ? <ChevronDown /> : <ChevronUp />}
                </button>
              </div>
              <>
                <div
                  className={`${styles.popupBody} ${styles.popupBodyStyledRows}`}
                  style={
                    this.state.isCollapsed
                      ? { height: "0px", visibility: "collapse", padding: "0" }
                      : { visibility: "visible" }
                  }
                >
                  {temp_tolls.length > 0 &&
                    temp_tolls.map((o, i) => {
                      let flag =
                        o.countryCode &&
                        images[
                          `${renderFlag(iso.whereAlpha3(o.countryCode).alpha2)}`
                        ];
                      let priceDifferent =
                        o?.mode !== "ferry" &&
                        o.fares[0].price.currency !== "PLN"
                          ? o.fares[0].price.value.toFixed(2)
                          : "";
                      let priceDifferentCode =
                        o?.mode !== "ferry" &&
                        o.fares[0].price.currency !== "PLN"
                          ? o.fares[0].price.currency
                          : "";
                      let price =
                        o?.mode !== "ferry" &&
                        o.fares[0].price.currency !== "PLN"
                          ? this.exchangeToPLN(
                              o.fares[0].price.value,
                              o.fares[0].price.currency,
                              this.props.exchangeRates
                            )
                          : o?.mode !== "ferry"
                          ? o.fares[0].price.value.toFixed(2)
                          : "";

                      return (
                        <div
                          key={i}
                          className={`${styles.grid} ${
                            o?.mode === "ferry" ? styles.ferry : ""
                          }`}
                          onClick={() => console.log(o)}
                        >
                          <div>
                            {o?.mode !== "ferry" ? (
                              <img src={flag} alt={o.countryCode}></img>
                            ) : (
                              <img src={ferryImg} alt="Ferry Icon" />
                            )}
                          </div>
                          <div className={styles.tollName}>
                            {o?.mode !== "ferry"
                              ? o.tollSystem
                              : "Prom - " + o.name}
                          </div>
                          <div></div>
                          <div
                            style={{
                              display: "flex",
                              gap: "2px",
                              justifyContent: "flex-end",
                            }}
                          >
                            <small>
                              {priceDifferent} {priceDifferentCode}
                            </small>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "2px",
                              justifyContent: "flex-end",
                              fontWeight: "bold",
                            }}
                          >
                            {price > 0 ? (
                              <>
                                {price}
                                <small>PLN</small>
                              </>
                            ) : o?.mode === "ferry" ? (
                              ""
                            ) : (
                              "-"
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
                {this.tolls?.length && this.tollCostsSummary(this.tolls) ? (
                  <>
                    <div className={styles.popupSummeriseAdditional}>
                      {this.tolls?.length &&
                        this.tollCostsSummary(this.tolls).map((o, i) => {
                          let flag =
                            o.countryCode &&
                            images[
                              `${renderFlag(
                                iso.whereAlpha3(o.countryCode).alpha2
                              )}`
                            ];

                          return (
                            <div className={styles.popupSummeriseAdditionalRow}>
                              <div>
                                {o?.mode !== "ferry" ? (
                                  <img src={flag} alt={o.countryCode}></img>
                                ) : (
                                  <img src={ferryImg} alt="Ferry Icon" />
                                )}
                              </div>
                              <div>{o.countryName}</div>
                              <div></div>
                              <div style={{ textAlign: "right" }}>
                                <small>
                                  {o.currency !== "PLN" &&
                                    `${o.sumPriceOrgin.toFixed(2)} ${
                                      o.currency
                                    }`}
                                </small>
                              </div>
                              <div style={{ textAlign: "right" }}>
                                {o.currency !== "PLN"
                                  ? o.sumPriceToPLN.toFixed(2)
                                  : o.sumPriceOrgin.toFixed(2)}{" "}
                                <small>PLN</small>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    <div className={styles.popupSummerise}>
                      <div>Suma:</div>
                      <div>
                        {sum.toFixed(2)} <small>PLN</small>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            </div>
          )}

          <div className={styles.routingHereSummaryBox}>
            <div className={styles.popupHeader}>
              Parametry routingu trasy:
              <button
                className={styles.collapse}
                onClick={() => this.handleClickParam()}
              >
                {this.state.isCollapsedParam ? <ChevronDown /> : <ChevronUp />}
              </button>
            </div>
            <div
              className={styles.popupBody}
              style={
                this.state.isCollapsedParam
                  ? { height: "0px", visibility: "collapse", padding: "0" }
                  : { visibility: "visible" }
              }
            >
              <table className={styles.tableRoutingSummary}>
                <tbody>
                  <tr>
                    <td className={styles.header}>Trasa:</td>
                    <td>Najkrótsza</td>
                  </tr>
                  <tr>
                    <td className={styles.header}>Pojazd:</td>
                    <td>{this.props.transport_set.car_name}</td>
                  </tr>
                  <tr>
                    <td className={styles.header}>Typ pojazdu:</td>
                    <td>{car_type}</td>
                  </tr>
                  <tr>
                    <td className={styles.header}>Norma emisji spalin:</td>
                    <td>
                      {this.emissionType(
                        "view",
                        this.props.transport_set.car_profile.emissions
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className={styles.header}>Ilość osi pojazdu:</td>
                    <td>{this.props.transport_set.car_profile.h_axle_count}</td>
                  </tr>
                  <tr>
                    <td className={styles.header}>Ilość przyczep:</td>
                    <td>
                      {this.props.transport_set.car_profile.h_trailer_count}
                    </td>
                  </tr>
                  <tr>
                    <td className={styles.header}>Waga brutto:</td>
                    <td>
                      {this.props.transport_set.car_profile.ntruckweighttotal}{" "}
                      kg
                    </td>
                  </tr>
                  <tr>
                    <td className={styles.header}>Masa pojazdu na oś:</td>
                    <td>
                      {this.props.transport_set.car_profile.ntruckweightaxle} kg
                    </td>
                  </tr>
                  <tr>
                    <td className={styles.header}>Wysokość pojazdu:</td>
                    <td>
                      {this.props.transport_set.car_profile.ntruckheight} mm
                    </td>
                  </tr>
                  <tr>
                    <td className={styles.header}>Szerokość pojazdu:</td>
                    <td>
                      {this.props.transport_set.car_profile.ntruckwidth} mm
                    </td>
                  </tr>
                  <tr>
                    <td className={styles.header}>Długość pojazdu:</td>
                    <td>
                      {this.props.transport_set.car_profile.ntrucklenght} mm
                    </td>
                  </tr>
                  <tr>
                    <td className={styles.header}>Towary niebezpieczne:</td>
                    <td>{new String(hazardous_goods).replaceAll(",", ", ")}</td>
                  </tr>
                  <tr>
                    <td className={styles.header}>Kategoria tuneli:</td>
                    <td>
                      {this.props.transport_set.car_profile.h_tunel_category}
                    </td>
                  </tr>
                  <tr>
                    <td className={styles.header}>
                      Km ładowne (koszt zlecenia):
                    </td>
                    <td>
                      {this.state.result ? this.props.cargo_kilometers : 0} km{" "}
                      {this.props.cost !== "" &&
                      this.props.cost !== undefined &&
                      this.props.ratesPerKm >= 0
                        ? `(${this.props.round(this.props.cost)} zł)`
                        : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {true && (
            <div className={styles.routingHereSummaryBox}>
              <div className={styles.popupHeader}>
                Punkty trasy:
                <button
                  className={styles.collapse}
                  onClick={() => this.handleClickPoints()}
                >
                  {this.state.isCollapsedPoints ? (
                    <ChevronDown />
                  ) : (
                    <ChevronUp />
                  )}
                </button>
              </div>
              <>
                <div
                  className={styles.popupBody}
                  style={
                    this.state.isCollapsedPoints
                      ? { height: "0px", visibility: "collapse", padding: "0" }
                      : { visibility: "visible" }
                  }
                >
                  {this.state.newTrace !== null &&
                    this.state.newTrace.map((o, i) => {
                      let flag =
                        o.address?.country_code &&
                        images[
                          `${renderFlag(
                            o.address?.country_code?.toUpperCase()
                          )}`
                        ];

                      return (
                        <div
                          key={i}
                          className={styles.flexRow}
                          style={
                            o.type === "custom-waypoint"
                              ? {
                                  background:
                                    "linear-gradient(90deg, rgb(18 152 101 / 10%) 0%, rgb(255, 255, 255) 73%)",
                                  borderRadius: "4px",
                                }
                              : { background: "transparent" }
                          }
                        >
                          <div
                            className={styles.flexRow_Ico}
                            style={
                              o.type === "custom-waypoint"
                                ? { color: "#000000" }
                                : { color: "var(--err-col)" }
                            }
                          >
                            {this.iconRow(o.type)}
                          </div>
                          <div className={styles.flexRow_Name}>
                            {o?.address?.city ? (
                              <Tooltip title={o.address?.address_display_name}>
                                <span>
                                  {o.address.country_code && (
                                    <img
                                      src={flag}
                                      alt={o.address.country_code.toUpperCase()}
                                    ></img>
                                  )}
                                  {/* {o.address?.country_code?.toUpperCase()},  */}
                                  <span>{o.address?.city}</span>
                                </span>
                              </Tooltip>
                            ) : o.prev ? (
                              <b
                                style={{
                                  color: "var(--cor-col)",
                                  fontWeight: "600",
                                }}
                              >
                                Zaznacz punkt na mapie
                              </b>
                            ) : o.warehouse?.id !== undefined ? (
                              "[M]" + " " + o.warehouse?.address.city
                            ) : (
                              "-"
                            )}
                          </div>

                          <div className={styles.flexRow_Btns}>
                            <IcoBtn
                              disabled={
                                i + 1 === this.state.newTrace.length ||
                                this.state.newTrace.some(
                                  (o2) => o2.prev && o2.prev === true
                                )
                                  ? true
                                  : false
                              }
                              icon="Plus"
                              className={styles.iconAdd}
                              tooltip="Dodaj kolejny punkt"
                              fontSize="small"
                              handleChange={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.addNewPosition(i);
                              }}
                            />
                            <IcoBtn
                              disabled={
                                o.type === "custom-waypoint" ? false : true
                              }
                              icon="Edit"
                              className={styles.iconEdit}
                              tooltip=""
                              fontSize="small"
                              handleChange={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.editPosition(i, o);
                              }}
                            />
                            <IcoBtn
                              disabled={
                                o.type === "custom-waypoint" ? false : true
                              }
                              icon="X"
                              className={styles.iconDel}
                              tooltip=""
                              fontSize="small"
                              handleChange={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.delPosition(o);
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  {/* {this.state.newTrace.some(o => o.prev === false) && <button className={styles.showResult} onClick={e => this.setNewRoad()}>Zapisz i przelicz trase</button>}                                                                                                                                                                                                                                                                                                                                                                                                       */}
                </div>
              </>
            </div>
          )}
        </div>

        {/* <button onClick={this.showRoute}>Trasa</button> */}
        {/* <button onClick={this.onClickDoRoute}>Routing</button>
                <button onClick={this.clearAll}>Wyczyść</button>
                <button onClick={this.onClickParams}>PArams</button>
                <button onClick={this.fetchSetHeremapsRouting}>Zapisz trasę</button> */}
      </div>
    );
  }
}
