import React, {
  useState,
  useEffect,
  forwardRef,
  SyntheticEvent,
  useMemo,
} from "react";
import Title from "../../components/title";
import styles from "../table.module.css";
import RowsPerPage from "../../components/RowsPerPage";
import Pagination from "../../components/pagination";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  IconButton,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
  Slide,
  Snackbar,
  Dialog,
  Tooltip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  SortDirection,
} from "@material-ui/core";
import fuelCardsStyles from "../../styles/fuelCards.module.css";
import { getDrivers } from "api/endpoints";
import { useQuery } from "react-query";
import { CommonPropsType } from "typeDefinitions";
import { useInput } from "components/hooks";
import { commonApiRequest } from "api/endpoints";
import { PATHS, QUERY_NAMES } from "api";
import { Driver } from "types";
import { NoOrderStatusHistoryType } from "typeDefinitions";
import { statusesType } from "typeDefinitions/containers/NoOrderStatusHistory.types";
import { DBurl } from "../../appConfig";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const headCells = [
  {
    id: "driver",
    numeric: true,
    disablePadding: false,
    label: "Kierowca",
    class: fuelCardsStyles.headCell,
  },
  {
    id: "activity",
    numeric: true,
    disablePadding: false,
    label: "Nazwa czynności",
  },
  { id: "date", numeric: true, disablePadding: false, label: "Data" },
];

const HandleActivity = (id_status: number) => {
  if (id_status === 1) return "Jazda";
  if (id_status === 2) return "Odpoczynek";
  if (id_status === 3) return "Długi odpoczynek";
  if (id_status === 4) return "Dojazd";
  if (id_status === 5) return "Podjęcie zlecenia";
  if (id_status === 6) return "Oczekiwanie na załadunek";
  if (id_status === 7) return "Załadunek";
  if (id_status === 8) return "Oczekiwanie na rozładunek";
  if (id_status === 9) return "Rozładunek";
  if (id_status === 10) return "Korek";
  if (id_status === 11) return "Awaria pojazdu";
  if (id_status === 12) return "Prom";
  if (id_status === 13) return "Zakończenie zlecenia";
  if (id_status === 14) return "Przepinka";
  if (id_status === 15) return "Przesiadka";
  if (id_status === 16) return "Przeładunek";
  if (id_status === 17) return "Zjazd na bazę";
  if (id_status === 18) return "Myjnia";
  if (id_status === 19) return "Kontrola drogowa";
  if (id_status === 20) return "Wymiana palet";
  if (id_status === 21) return "Pobieranie palet";
  if (id_status === 22) return "Zrzutka palet";
  if (id_status === 23) return "Zmiana zestawu";
  if (id_status === 24) return "Odprawa celna";
};

export type NoOrderStatusStateType = {
  tabName: string;
} & Record<string, unknown>;

export type NoOrderStatusHistoryPropsType = CommonPropsType<
  NoOrderStatusStateType[]
> & {
  state: NoOrderStatusStateType;
};

export type NoOrderStatusHistoryTableType = {
  auto: any;
  card: any;
  id_driver: number;
} & Record<string, string>;

const NoOrderStatusHistory = (props: NoOrderStatusHistoryPropsType) => {
  const [order, setOrder] = useState<SortDirection>("asc");
  const [orderBy, setOrderBy] = useState<string>("car");
  const [selected, setSelected] = useState<any[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [table, setTable] = useState<NoOrderStatusHistoryType[]>([]);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [driversIDs, setDriversIDs] = useState<number[]>([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState<string | unknown>("");
  const [dialogColor, setDialogColor] = useState<string>("error");
  const [searchDriver, setSearchDriver] = useInput(
    "text",
    "Wyszukaj",
    "",
    fuelCardsStyles.search
  );
  const [searchCar, setSearchCar] = useInput(
    "text",
    "Wyszukaj",
    "",
    fuelCardsStyles.search
  );
  const [tableToShow, setTableToShow] = useState<NoOrderStatusHistoryType[]>(
    []
  );

  useEffect(() => {
    if (!searchDriver && !searchCar) {
      setTableToShow(table);
    }
    if (searchDriver || searchCar) {
      const matchingDrivers = searchDriver
        ? drivers.filter(
            (driver) =>
              driver?.firstname
                ?.toLocaleLowerCase()
                .includes(searchDriver.toLocaleLowerCase()) ||
              driver?.surname
                ?.toLocaleLowerCase()
                .includes(searchDriver.toLocaleLowerCase())
          )
        : drivers;
      const goodRows = table.filter((row) =>
        matchingDrivers.map((driver) => driver.id).includes(row.id_driver)
      );
      const goodRowsWithActivities = goodRows.filter((row) => {
        const statuses = row.statuses.map((status) =>
          HandleActivity(status.id_status)
        );
        for (const status of statuses) {
          if (
            status?.toLocaleLowerCase().includes(searchCar.toLocaleLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
      setTableToShow(goodRowsWithActivities);
    }
  }, [searchDriver, searchCar]);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, table.length - page * rowsPerPage);
  function EnhancedTableHead(props: {
    styles: Record<string, string>;
    order: SortDirection;
    orderBy: string;
  }) {
    const { styles, order, orderBy } = props;

    return (
      <TableHead className={styles.tabHead}>
        <TableRow>
          {headCells.map((headCell) => {
            return (
              <TableCell
                key={headCell.id}
                align={"left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : undefined}
                className={headCell.class}
              >
                {headCell.label}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }
  const search = <ArrType,>(
    tbl: ArrType[],
    param1: keyof ArrType,
    param2: keyof ArrType
  ): ArrType[] => {
    return tbl.filter((row) => {
      return (
        String(row[param1]).toLowerCase().indexOf(searchDriver.toLowerCase()) >
          -1 &&
        String(row[param2]).toLowerCase().indexOf(searchCar.toLowerCase()) > -1
      );
    });
  };

  const handleClose = (
    _event: SyntheticEvent<Element, Event>,
    reason: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDialog(false);
  };

  const paginate = (pageNumber: number) => setPage(pageNumber - 1);
  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // useQuery("drivers", getDrivers, {
  // 	onSuccess: (drivers) => {
  // 		console.log(drivers.filter(dr => dr.id === 220))
  // 	  setDrivers(drivers);
  // 	},
  // 	onError: (error) => {
  // 	  setOpenDialog(true)
  // 	  setDialogColor("error")
  // 	  setDialogContent(error);
  // 	},
  //   });

  // useQuery(QUERY_NAMES.NO_ORDER_STATUS_HISTORY,
  // 	() => commonApiRequest<NoOrderStatusHistoryType[]>(PATHS.NO_ORDER_STATUS_HISTORY)
  // 	,{
  // 	onSuccess: (orderStatus) => {
  // 		console.log(orderStatus)
  // 		setTable(orderStatus.filter(rec => rec.statuses[0]?.id !== undefined));
  // 		setTableToShow(orderStatus.filter(rec => rec.statuses[0]?.id !== undefined))
  // 	},
  // 	onError: (error) => {
  // 	  setOpenDialog(true)
  // 	  setDialogColor("error")
  // 	  setDialogContent(error);
  // 	},
  //   });

  const getDrivers = async () => {
    props.loading(true);
    const response = await fetch(`${DBurl}/getDriversNoSlaves`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setDrivers(json.data);
      setDriversIDs(json.data.map((driver: any) => driver.id));
      props.loading(false);
    }
  };

  const getHistory = async () => {
    props.loading(true);
    const response = await fetch(
      `${DBurl}/getOrderDriverActivities?without_order=true&with_costs=true&with_refuelings=true&with_statuses=true&filter_ids_drivers[]=${driversIDs.join(
        ","
      )}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setTable(
        json.data.filter((rec: any) => rec.statuses[0]?.id !== undefined)
      );
      setTableToShow(
        json.data.filter((rec: any) => rec.statuses[0]?.id !== undefined)
      );
      props.loading(false);
    }
  };

  useEffect(() => {
    getDrivers();
  }, []);

  useEffect(() => {
    if (driversIDs.length > 0) {
      getHistory();
    }
  }, [driversIDs]);

  return (
    <div>
      <Grid>
        <Title title={props.state.tabName} />
        <TableContainer className={styles.stickyTable}>
          <Table
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              styles={styles}
              order={order}
              orderBy={orderBy}
            />
            <TableBody className={styles.tabBody}>
              <TableRow>
                <TableCell className={`${fuelCardsStyles.headCell}`}>
                  {setSearchDriver}
                </TableCell>
                <TableCell>{setSearchCar}</TableCell>
                <TableCell></TableCell>
              </TableRow>
              {tableToShow
                .sort(
                  (a, b) =>
                    new Date(b.ts_activity_start).getTime() -
                    new Date(a.ts_activity_start).getTime()
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(`${row.id}`);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <>
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.id}
                        className={styles.test}
                      >
                        <TableCell
                          className={fuelCardsStyles.headCell}
                          align="left"
                        >
                          {
                            drivers.find(
                              (driver) => driver.id === row.id_driver
                            )?.firstname
                          }{" "}
                          {
                            drivers.find(
                              (driver) => driver.id === row.id_driver
                            )?.surname
                          }
                        </TableCell>
                        <TableCell align="left">
                          {HandleActivity(row.statuses[0].id_status)}
                        </TableCell>
                        <TableCell align="left">
                          {row.statuses[0].ts_status_start}
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 21 * emptyRows }}>
                  <TableCell colSpan={3} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={styles.tableBottom}>
          <RowsPerPage
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setPage={setPage}
            tableLength={table.length}
            minRows={10}
          />

          <div>
            <Pagination
              postsPerPage={rowsPerPage}
              totalPosts={table.length}
              paginate={paginate}
              page={page}
            />
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default NoOrderStatusHistory;
