import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useFormik } from "formik";
import { useMutation, useQuery } from "react-query";
import * as Yup from "yup";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogContent,
  DialogActions,
  Button,
  Grid,
} from "@material-ui/core";
import RowsPerPage from "components/RowsPerPage";
import Pagination from "components/pagination";
import styles from "containers/table.module.css";
import { getDataFromFile, setFuelCards } from "api/endpoints";
import stylesMod from "styles/newOrEdit.module.css";
import BTNstyles from "styles/button.module.css";

import "./styles.sass";

const columns = [
  {
    label: "ID Pojazdu",
    name: "id_car",
  },
  {
    label: "ID Karty",
    name: "id_card",
  },
  {
    label: "Czas transakacji",
    name: "transaction_time",
  },
  {
    label: "Nazwa produktu",
    name: "product_name",
  },
  {
    label: "Ilość paliwa",
    name: "fuel",
  },
  {
    label: "Dystans",
    name: "total_distance",
  },
  {
    label: "Rejestracja",
    name: "license_plate_number",
  },
  {
    label: "Cena",
    name: "unit_price",
  },
  {
    label: "Waluta",
    name: "unit_price_currency",
  },
  {
    label: "Provider karty",
    name: "card_provider",
  },
];

const defualtValues = {
  id_car: undefined,
  id_card: "",
  transaction_time: "",
  product_name: "",
  fuel: undefined,
  total_distance: undefined,
  license_plate_number: "",
  unit_price: undefined,
  unit_price_currency: "",
  is_valid: true,
  card_provider: "",
};

const errors = {
  valueRequired: "Pole wymagane",
  valueShouldBeNumber: "Pole powinno być liczbą",
  valueShouldBeString: "Pole powinno być ciągiem znaków",
};

const ImportTable = ({
  filename,
  refetchFuelCards,
  setDialog,
  handleClose,
}) => {
  const [fileLength, setFileLength] = useState();
  const [parsedDataHeaders, setParsedDataHeaders] = useState();
  const [parsedData, setParsedData] = useState();
  const [pageModalTab, setPageModalTab] = useState(0);
  const [rowsPerPageModalTab, setRowsPerPageModalTab] = useState(10);

  const validationSchema = Yup.array().of(
    Yup.object({
      id_car: Yup.number()
        .typeError(errors.valueShouldBeNumber)
        .required(errors.valueRequired),
      id_card: Yup.string()
        .typeError(errors.valueShouldBeString)
        .required(errors.valueRequired),
      transaction_time: Yup.string()
        .typeError(errors.valueShouldBeString)
        .required(errors.valueRequired),
      product_name: Yup.string()
        .typeError(errors.valueShouldBeString)
        .required(errors.valueRequired),
      fuel: Yup.number()
        .typeError(errors.valueShouldBeNumber)
        .required(errors.valueRequired),
      total_distance: Yup.number()
        .typeError(errors.valueShouldBeNumber)
        .required(errors.valueRequired),
      license_plate_number: Yup.string()
        .typeError(errors.valueShouldBeString)
        .required(errors.valueRequired),
      unit_price: Yup.number()
        .typeError(errors.valueShouldBeNumber)
        .required(errors.valueRequired),
      unit_price_currency: Yup.string()
        .typeError(errors.valueShouldBeString)
        .required(errors.valueRequired),
      card_provider: Yup.string()
        .typeError(errors.valueShouldBeString)
        .required(errors.valueRequired),
    })
  );

  const data = useFormik({
    initialValues: [],
    validationSchema,
  });

  useEffect(() => {
    if (fileLength) {
      const initialValuesArray = Array.from({ length: fileLength }, () => ({
        ...defualtValues,
      }));

      data.setValues(initialValuesArray);
    }
  }, [fileLength]);

  const parseHeaders = (data) => {
    const parsedHeaders = data[0].map((header, index) => {
      if (header && header.length > 0) {
        return {
          value: index,
          label: header.charAt(0).toUpperCase() + header.slice(1),
        };
      } else {
        return {};
      }
    });

    setParsedDataHeaders(parsedHeaders);
  };

  const parseData = (data) => {
    const table = [];

    for (let i = 0; i < data[1].length; i++) {
      table[i] = [];
    }

    data.forEach((row, index) => {
      for (let i = 0; i < row.length; i++) {
        table[i][index] = row[i];
      }
    });

    setParsedData(table);
  };

  useQuery(["getDataFromFile", filename], () => getDataFromFile({ filename }), {
    enabled: Boolean(filename),
    onSuccess: (response) => {
      if (response?.length > 0) {
        const data = response[0];
        setFileLength(data?.length - 1);
        parseHeaders(data);
        data.shift();
        parseData(data);
      } else {
        setDialog({
          isOpen: true,
          type: "error",
          content: `Nie można załadować pliku do importu danych`,
        });
      }
    },
  });

  const paginateModalTab = (pageNumber) => setPageModalTab(pageNumber - 1);

  const { mutate: uploadFuelCards } = useMutation(
    "setFuelCards",
    setFuelCards,
    {
      onSuccess: () => {
        setDialog({
          isOpen: true,
          type: "success",
          content: "Karty paliwowe zaimportowane pomyślnie",
        });
        refetchFuelCards();
        handleClose();
      },
      onError: (error) => {
        setDialog({
          isOpen: true,
          type: "error",
          content: `Nie udało się zaimportować kart paliwowych. ${error.message}`,
        });
      },
    }
  );

  const handleImport = () => {
    data.validateForm();

    if (data.isValid) {
      uploadFuelCards({ collection: data.values });
    } else {
      setDialog({
        isOpen: true,
        type: "error",
        content: "Wypełnij wymagane pola",
      });
    }
  };

  const selectStyle = {
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
    control: (base, state) => ({
      ...base,
      border: "1px solid #142f42",
      marginTop: "6px",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #142f42",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused && "#142f42",
      color: state.isFocused && "#fff",
      fontSize: "11px",
    }),
  };

  const handleReplace = (e, column) => {
    let newData = data.values;

    const newValues = parsedData[e.value];

    newData.forEach((item, index) => {
      if (index < newValues.length) {
        item[column.name] = newValues[index];
      }
    });

    data.setValues(newData);
  };

  return (
    <>
      <DialogContent>
        <Grid className={"import"} item xs={12}>
          <Grid container spacing={1}>
            <Grid className="import__table" item component={"div"} xs={12}>
              <TableContainer>
                <Table
                  stickyHeader={true}
                  className={styles.table}
                  aria-labelledby="tableTitle"
                  aria-label="enhanced table"
                  size={"small"}
                >
                  <TableHead className={styles.tabHead}>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell key={column.name} className={"tabcell"}>
                          {column.label}
                          <Select
                            menuPortalTarget={document.body}
                            className={`${stylesMod.select}`}
                            options={parsedDataHeaders}
                            onChange={(e) => {
                              handleReplace(e, column);
                            }}
                            name={column.name}
                            placeholder="Wybierz..."
                            styles={selectStyle}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.values
                      .slice(
                        pageModalTab * rowsPerPageModalTab,
                        pageModalTab * rowsPerPageModalTab + rowsPerPageModalTab
                      )
                      .map((row, index) => {
                        return (
                          <TableRow role="checkbox" tabIndex={-1} key={index}>
                            {columns.map((column) => (
                              <TableCell
                                key={column.name}
                                className={"tabcell"}
                              >
                                {row[column.name] || "-"}
                                {Object.keys(data.errors).length > 0 &&
                                  data.errors[index][column.name] && (
                                    <div className="import__error-message">
                                      {data.errors[index][column.name]}
                                    </div>
                                  )}
                              </TableCell>
                            ))}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <div
                className={`${styles.tableBottom} modalTabPagin`}
                style={{ position: "sticky", bottom: 0 }}
              >
                <RowsPerPage
                  rowsPerPage={rowsPerPageModalTab}
                  setRowsPerPage={setRowsPerPageModalTab}
                  setPage={setPageModalTab}
                  tableLength={fileLength}
                />
                <div>
                  <Pagination
                    postsPerPage={rowsPerPageModalTab}
                    totalPosts={fileLength}
                    paginate={paginateModalTab}
                    page={pageModalTab}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: "8px 13px" }}>
        <Button
          variant="contained"
          size="large"
          color="primary"
          className={BTNstyles.btn}
          onClick={handleClose}
        >
          Anuluj
        </Button>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          style={{ backgroundColor: "rgba(46, 125, 50, 1)" }}
          className={BTNstyles.btn}
          onClick={handleImport}
          disabled={!data.isValid}
        >
          Importuj
        </Button>
      </DialogActions>
    </>
  );
};

export default ImportTable;
