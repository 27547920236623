import React, { Component } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import styles from './map.module.css';

export class MyMarker extends Component{
    
    render = () =>
        <div>
        <Marker 
            key = {this.props.id}
            position = {this.props.position} 
            icon = {this.props.icon}
            clusterer = {this.props.clusterer}
            
            onLoad = {(e) => this.props.callbackOnLoadMarker(e)}
            
            onClick = {(e) => this.props.callbackOnClickMarker(this.props.id, this.props.index, e)} 
            onMouseOver = {(e) => this.props.callbackOnMouseOverMarker(this.props.id, this.props.index, e)}
            onMouseOut = {(e) => this.props.callbackOnMouseOutMarker(this.props.id, this.props.index, e)}
            
        />
        <InfoWindow 
            key = {this.props.id+1}
            position = {this.props.position} 
            
            onLoad = {(e) => this.props.callbackOnLoadInfoWindow(e)}
            onDomReady = {(e) => this.props.callbackOnDomReadyInfoWindow(e)}
        >
            <div className={styles.scrollFixGMAP}>
                <table width='100%' className={styles.tableCarPopup}>
                    <tbody>
                        <tr><td className={styles.pop_other_left}>{this.props.name}</td><td className={styles.pop_other_right}>{this.props.speed} km/h</td></tr>

                        <tr><td className={styles.pop_other_left}>Czas ramki:</td><td className={styles.pop_other_right}>{this.props.ts_local}</td></tr>
                        <tr><td className={styles.pop_other_left}>Numer rejestracyjny:</td><td className={styles.pop_other_right}> {this.props.nr_rejestracyjny}</td></tr>
                        <tr><td className={styles.pop_other_left}>Poziom paliwa:</td><td className={styles.pop_other_right}> {this.props.fuel}</td></tr>
                        <tr><td className={styles.pop_other_left}>Średnie zużycie w dniu bieżącym:</td><td className={styles.pop_other_right}> {this.props.fuel_avg}</td></tr>
                        {this.props.axleweight1 !== null && <tr><td className={styles.pop_other_left}>Nacisk na oś 1:</td><td className={styles.pop_other_right}> {this.props.axleweight1} kg</td></tr>}
                        {this.props.axleweight2 !== null && <tr><td className={styles.pop_other_left}>Nacisk na oś 2:</td><td className={styles.pop_other_right}> {this.props.axleweight2} kg</td></tr>}
                        {this.props.axleweight3 !== null && <tr><td className={styles.pop_other_left}>Nacisk na oś 3:</td><td className={styles.pop_other_right}> {this.props.axleweight3} kg</td></tr>}
                        {this.props.axleweight4 !== null && <tr><td className={styles.pop_other_left}>Nacisk na oś 4:</td><td className={styles.pop_other_right}> {this.props.axleweight4} kg</td></tr>}
                        {this.props.axle_brutto !== null && <tr><td className={styles.pop_other_left}>Masa całkowita pojazdu brutto:</td><td className={styles.pop_other_right}> {this.props.axle_brutto} kg</td></tr>}
                        {this.props.wiretemp1 !== null && this.props.wiretemp1_msg && <tr><td className={styles.pop_other_left}>{this.props.wiretemp1_msg}:</td><td className={styles.pop_other_right}> {this.props.wiretemp1} °C</td></tr>}
                        {this.props.wiretemp2 !== null && this.props.wiretemp2_msg && <tr><td className={styles.pop_other_left}>{this.props.wiretemp2_msg}:</td><td className={styles.pop_other_right}> {this.props.wiretemp2} °C</td></tr>}
                        {this.props.wiretemp3 !== null && this.props.wiretemp3_msg && <tr><td className={styles.pop_other_left}>{this.props.wiretemp3_msg}:</td><td className={styles.pop_other_right}> {this.props.wiretemp3} °C</td></tr>}
                        {this.props.wiretemp4 !== null && this.props.wiretemp4_msg && <tr><td className={styles.pop_other_left}>{this.props.wiretemp4_msg}:</td><td className={styles.pop_other_right}> {this.props.wiretemp4} °C</td></tr>}
                        {this.props.wiretemp5 !== null && this.props.wiretemp5_msg && <tr><td className={styles.pop_other_left}>{this.props.wiretemp5_msg}:</td><td className={styles.pop_other_right}> {this.props.wiretemp5} °C</td></tr>}
                        {this.props.wiretemp6 !== null && this.props.wiretemp6_msg && <tr><td className={styles.pop_other_left}>{this.props.wiretemp6_msg}:</td><td className={styles.pop_other_right}> {this.props.wiretemp6} °C</td></tr>}
                        <tr><td className={styles.pop_other_left}>Przebieg [CAN]:</td><td className={styles.pop_other_right}> {this.props.totaldistance} km</td></tr>
                        <tr><td className={styles.pop_other_left}>Przebieg [GPS]:</td><td className={styles.pop_other_right}> {this.props.mileagegps} km</td></tr>
                        {this.props.enginespeed && <tr><td className={styles.pop_other_left}>Obroty silnika:</td><td className={styles.pop_other_right}>{this.props.enginespeed} RPM</td></tr>}

                            {this.props.tacho_exists && <MyTacho
                                tacho_driver1 = {this.props.tacho_driver1}
                                tacho_driver2 = {this.props.tacho_driver2}
                                tacho_drive = {this.props.tacho_drive}
                                tacho_work = {this.props.tacho_work}
                                tacho_break = {this.props.tacho_break}
                                tacho_rest = {this.props.tacho_rest}
                            />}
                        {this.props.lastDateCarStart !== null && <tr><td className={styles.pop_other_left}>Godzina od ostatniego włączenia stacyjki:</td><td className={styles.pop_other_right}> {this.props.lastDateCarStart}</td></tr>}
                        {this.props.lastTimeCarStart !== null && <tr><td className={styles.pop_other_left}>Czas od ostatniego włączenia stacyjki:</td><td className={styles.pop_other_right}> {this.props.lastTimeCarStart}</td></tr>}
                        {this.props.lastDateCarStop !== null && <tr><td className={styles.pop_other_left}>Godzina od ostatniego wyłączenia stacyjki:</td><td className={styles.pop_other_right}> {this.props.lastDateCarStop}</td></tr>}
                        {this.props.lastTimeCarStop !== null && <tr><td className={styles.pop_other_left}>Czas od ostatniego wyłączenia stacyjki:</td><td className={styles.pop_other_right}> {this.props.lastTimeCarStop}</td></tr>}
                    </tbody>
                </table>
            </div>
        </InfoWindow>
        </div>
}

class MyTacho extends Component{
    
    render = () =>
        <>
            {this.props.tacho_driver1 && <tr><td className={styles.pop_other_left}>Kierowca:</td><td className={styles.pop_other_right}>{this.props.tacho_driver1}</td></tr>}
            {this.props.tacho_driver1 && <tr><td className={styles.pop_other_left}></td><td className={styles.pop_other_right}>{this.props.tacho_drive} | {this.props.tacho_work} | {this.props.tacho_break} | {this.props.tacho_rest}</td></tr>}
        </>
    
}

{/* <tr><td className={styles.pop_other_left}>Kierowca 1:</td><td className={styles.pop_other_right}>{this.props.tacho_driver1}</td></tr>
            {this.props.tacho_driver2 && <tr><td className={styles.pop_other_left}>Kierowca 2:</td><td className={styles.pop_other_right}>{this.props.tacho_driver2}</td></tr>}
            <tr><td className={styles.pop_other_left}>Tachograf:</td><td></td></tr>
            <tr><td className={styles.pop_other_left}>Jazda:</td><td className={styles.pop_other_right}>{this.props.tacho_drive}</td></tr>
            <tr><td className={styles.pop_other_left}>Praca:</td><td className={styles.pop_other_right}>{this.props.tacho_work}</td></tr>
            <tr><td className={styles.pop_other_left}>Przerwa:</td><td className={styles.pop_other_right}>{this.props.tacho_break}</td></tr>
            <tr><td className={styles.pop_other_left}>Odpoczynek:</td><td className={styles.pop_other_right}>{this.props.tacho_rest}</td></tr> */}
