import React, { useState, useEffect, useRef } from "react";
import styles from "./hereMap.module.css";
import { X, Check } from "react-feather";
import { DBurl } from "../../appConfig";

export const DisplayMapFC = (props) => {
  const mapRef = useRef(null);
  const latRef = useRef(52.22792612730421);
  const lngRef = useRef(21.002079139896622);
  const [tlat, settlat] = useState();
  const [tlng, settlng] = useState();
  const [iconClicked, setIconClicked] = useState(false);
  const [iconType, setIconType] = useState("");
  const [tmp_obj, setTmp_obj] = useState(props.order[0].routing);

  const [recordList, setRecordList] = useState();
  const [list, setList] = useState(() => tmp_obj.points);
  const [isWaiting] = useState(props.order[0].is_waiting);
  const [isCargo_change] = useState(props.order[0].cargo_change_in_warehouse);
  const [isLeave_trailer] = useState(props.order[0].leave_trailer);

  useEffect(() => {
    props.mapSetup({ lat: tlat, lng: tlng });
  }, [tlat && tlng]);

  useEffect(() => {
    if (!mapRef.current) return;

    const H = window.H;

    let markerLayer = new H.map.Group();

    const showRoute = (polylines, map) => {
      for (let i = 0; i < polylines.length; i++) {
        let linestring = H.geo.LineString.fromFlexiblePolyline(polylines[i]);
        let routeLine = new H.map.Polyline(linestring, {
          style: { strokeColor: "blue", lineWidth: 3 },
        });

        map.addObjects([routeLine]);
      }
    };

    const addRoutngMarkersToMap = (map) => {
      let svgIcon = `<svg viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg"><path d="M192 0C85.97 0 0 85.97 0 192c0 77.41 26.97 99.03 172.3 309.7c9.531 13.77 29.91 13.77 39.44 0C357 291 384 269.4 384 192C384 85.97 298 0 192 0zM192 271.1c-44.13 0-80-35.88-80-80S147.9 111.1 192 111.1s80 35.88 80 80S236.1 271.1 192 271.1z"/></svg>`;
      let downloadIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="transparent" stroke="#ed3c22" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>`;
      let uploadIcon =
        '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="transparent" stroke="#ed3c22" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>';

      let waypointIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="transparent" stroke="${
        iconClicked === true ? "#0000ff" : "#ed3c22"
      }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>`;
      let customWaypointIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="transparent" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg>`;
      let changesIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#fff" stroke="#0000ff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>`;

      if (
        iconType === "unloading" ||
        iconType === "loading" ||
        iconType === "custom-waypoint" ||
        iconType === "stop"
      ) {
        changesIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="rgba(225, 225, 255, 0.5)" stroke="#0000ff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><g transform="translate(40, 40)"><circle cx="0" cy="0" r="39"></circle></g></svg>`;
      }

      const size = (type) => {
        if (type === "unloading" || type === "loading" || type === "stop") {
          return { w: 80, h: 80 };
        } else if (type === "custom-waypoint") {
          return { w: 60, h: 60 };
        } else {
          return { w: 40, h: 40 };
        }
      };
      const anchor = (type) => {
        if (
          type === "unloading" ||
          type === "loading" ||
          type === "stop" ||
          type === "end_road"
        ) {
          return { x: 40, y: 50 };
        } else if (type === "custom-waypoint") {
          return { x: 30, y: 40 };
        } else if (
          type === "border" ||
          type === "ferry" ||
          type === "start_road"
        ) {
          return { x: 40, y: 57 };
        } else if (type === "parking") {
          return { x: 42, y: 60 };
        } else {
          return { x: 20, y: 40 };
        }
      };

      // icon
      var pngIcon = new H.map.Icon(svgIcon, { size: { w: 36, h: 50 } });
      var pngIconDownload = new H.map.Icon(downloadIcon, {
        size: { w: 40, h: 40 },
      });
      var pngIconUpload = new H.map.Icon(uploadIcon, {
        size: { w: 40, h: 40 },
      });
      var pngIconWaypoint = new H.map.Icon(waypointIcon, {
        size: { w: 40, h: 40 },
      });
      var pngIconCustomWaypoint = new H.map.Icon(customWaypointIcon, {
        size: { w: 20, h: 20 },
      });

      var pngIconChangesIcon = new H.map.Icon(changesIcon, {
        size: size(iconType),
        anchor: anchor(iconType),
      });

      for (let i = 0; i < tmp_obj.points.length; i++) {
        //let waypoints_coord = this.waypoints[i].split(',');

        let icon = null;

        if (tmp_obj.points[i].type === "loading") {
          icon = pngIconDownload;
        } else if (tmp_obj.points[i].type === "unloading") {
          icon = pngIconUpload;
        } else if (tmp_obj.points[i].type === "custom-waypoint") {
          icon = pngIconCustomWaypoint;
        } else if (tmp_obj.points[i].type === "start") {
          icon = pngIconChangesIcon;
        } else {
          icon = pngIconWaypoint;
        }

        // console.log('tmp_obj.points[i]',tmp_obj)

        // Create a marker for waypoints:
        let waypointsMarker = null;
        if (tmp_obj.points[i] !== undefined) {
          waypointsMarker = new H.map.Marker(
            { lat: tmp_obj.points[i].lat, lng: tmp_obj.points[i].lon },
            { icon: icon }
          );
        }

        // //waypointsMarker.setData('<h1>waypoints</h1>');
        markerLayer.addObjects([waypointsMarker]);
        map
          .getViewModel()
          .setLookAtData({ bounds: markerLayer.getBoundingBox() });
      }

      map.addObjects([markerLayer]);
    };

    const platform = new H.service.Platform({
      apikey: process.env.REACT_APP_HERE_API_KEY,
    });
    const defaultLayers = platform.createDefaultLayers();
    const hMap = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
      center: { lat: 52.22792612730421, lng: 21.002079139896622 },
      zoom: 6,
      pixelRatio: window.devicePixelRatio || 1,
    });

    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(hMap));
    const ui = H.ui.UI.createDefault(hMap, defaultLayers);

    if (mapRef.current) {
      if (isCargo_change === false && isLeave_trailer === false) {
        markerLayer.addEventListener("tap", function (e) {
          onIconClicked(e);
        });
      }

      markerLayer.addEventListener(
        "pointerenter",
        function () {
          removeHandler();
        },
        true
      );

      markerLayer.addEventListener(
        "pointerleave",
        function () {
          addHandler();
        },
        true
      );

      hMap.addEventListener(
        "pointerenter",
        function () {
          addHandler();
        },
        true
      );

      hMap.addEventListener(
        "pointerleave",
        function () {
          removeHandler();
        },
        true
      );

      function removeHandler() {
        hMap.removeEventListener("tap", onMapClicked);
      }
      function addHandler() {
        hMap.addEventListener("tap", onMapClicked);
      }

      async function onAdressWritten(e) {
        if (tmp_obj.length !== 0) {
          const isFound = tmp_obj.points.some((element) => {
            if (iconClicked) {
              if (element.type === "start") {
                return true;
              }
              return false;
            }
          });

          if (isFound) {
            // console.log('isFound_2')
            await fetch(`${DBurl}/getOrdersForHandOver`, {
              method: "GET",
              credentials: "include",
              headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": props.user.csrf_token,
              },
            })
              .then((res) => res.json())
              .then((data) => {
                if (data.success) {
                  let selectedPoint3 = data.data.filter((item) => {
                    return item.id === props.order[0].id;
                  });
                  setTmp_obj(selectedPoint3[0].routing);
                }
              })
              .then(() => {
                setIconClicked(false);
              });
          }
        }

        fromForm(props.latitude, props.longitude);
      }

      async function onMapClicked(e) {
        props.setIsAdressWritten(false);
        setIconType("def");

        if (tmp_obj.length !== 0) {
          const isFound = tmp_obj.points.some((element) => {
            if (iconClicked) {
              if (element.type === "start") {
                return true;
              }
              return false;
            }
          });

          if (isFound) {
            // console.log('isFound...1')
            setIconClicked(false);
            // setMapClicked(false)

            await fetch(`${DBurl}/getOrdersForHandOver`, {
              method: "GET",
              credentials: "include",
              headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": props.user.csrf_token,
              },
            })
              .then((res) => res.json())
              .then((data) => {
                if (data.success) {
                  let selectedPoint2 = data.data.filter((item) => {
                    return item.id === props.order[0].id;
                  });
                  setTmp_obj(selectedPoint2[0].routing);
                }
              });
          }
        }

        var coord = hMap.screenToGeo(
          e.currentPointer.viewportX,
          e.currentPointer.viewportY
        );

        if ((isWaiting && isLeave_trailer) || (isWaiting && isCargo_change)) {
          setTmp_obj({
            points: [
              { lat: String(coord.lat), lon: String(coord.lng), type: "start" },
            ],
            polylines: [],
          });
        }

        props.mapSetup({ lat: coord.lat, lng: coord.lng });

        settlat(coord.lat);
        settlng(coord.lng);
      }

      function onIconClicked(e) {
        props.setIsAdressWritten(false);

        let temp_clicked_marker = new H.ui.InfoBubble(e.target.getGeometry(), {
          content: e.target.getData(),
        });
        let selectedPoint = tmp_obj.points.filter((item) => {
          return Number(temp_clicked_marker.u.lat) === Number(item.lat);
        });

        if (
          selectedPoint[0].lat !== tmp_obj.points[0].lat &&
          selectedPoint[0].lat !== tmp_obj.points[tmp_obj.points.length - 1].lat
        ) {
          latRef.current = selectedPoint[0].lat;
          lngRef.current = selectedPoint[0].lon;

          if (selectedPoint[0].type === "stop") {
            setIconType("stop");
          } else if (selectedPoint[0].type === "loading") {
            setIconType("loading");
          } else if (selectedPoint[0].type === "unloading") {
            setIconType("unloading");
          } else if (selectedPoint[0].type === "custom-waypoint") {
            setIconType("custom-waypoint");
          } else {
            setIconType("def");
          }

          settlat(latRef.current);
          settlng(lngRef.current);

          setIconClicked(true);
        }
      }

      if (props.isAdressWritten) {
        onAdressWritten();
      }

      function fromForm(lat, lon) {
        if ((isWaiting && isLeave_trailer) || (isWaiting && isCargo_change)) {
          if (props.isAdressWritten === true) {
            latRef.current = lat;
            lngRef.current = lon;

            props.mapSetup({ lat: latRef.current, lng: lngRef.current });
            settlat(latRef.current);
            settlng(lngRef.current);

            setTmp_obj({
              points: [
                {
                  lat: String(latRef.current),
                  lon: String(lngRef.current),
                  type: "start",
                },
              ],
              polylines: [],
            });

            props.setIsAdressWritten(false);
          }
        }
      }

      if (props.order[0].routing.polylines) {
        showRoute(props.order[0].routing.polylines, hMap);
      }
      if (tmp_obj.length !== 0) {
        addRoutngMarkersToMap(hMap);
      }
    }

    return () => {
      hMap.dispose();
    };
  }, [props.latitude, props.longitude, mapRef]);

  useEffect(() => {
    if (list) {
      let temp_list_index = list.findIndex((o) => {
        return o.type === "start";
      });
      let temp_list = [];

      if (temp_list_index !== -1) {
        for (let i = temp_list_index; i < list.length; i++) {
          temp_list.push(list[i]);
        }
        props.setDataPoints(temp_list);
      } else {
        props.setDataPoints([]);
      }
    }
  }, [list]);

  useEffect(() => {
    if (recordList !== undefined) {
      let temp_list_index = recordList.points.findIndex((o) => {
        return o.lat === latRef.current;
      });
      // console.log('temp_list_index',temp_list_index)
      let temp_list = [];

      if (temp_list_index !== -1) {
        for (let i = temp_list_index; i < recordList.points.length; i++) {
          temp_list.push(recordList.points[i]);
        }
        props.setDataPoints(temp_list);
      } else {
        props.setDataPoints([]);
      }
    }
  }, [recordList]);

  useEffect(() => {
    fetchOrginData();
  }, [iconClicked]);

  useEffect(() => {
    if (props.isAdressWritten === true) {
      setIconType("def");
    }
  }, [props.isAdressWritten]);

  async function fetchOrginData() {
    await fetch(`${DBurl}/getOrdersForHandOver`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          let selectedPoint2 = data.data.filter((item) => {
            return item.id === props.order[0].id;
          });
          selectedPoint2[0]?.routing &&
            setRecordList(selectedPoint2[0]?.routing);
        }
      });
  }

  const DragToReorderList = () => {
    const initialDnDState = {
      draggedFrom: null,
      draggedTo: null,
      isDragging: false,
      originalOrder: [],
      updatedOrder: [],
    };

    const [dragAndDrop, setDragAndDrop] = useState(initialDnDState);

    if (props.data !== null) {
      if (tmp_obj.length === undefined) {
        const checkChanges = (obj) => obj.type === "start";
        if (tmp_obj.points.some(checkChanges) === true) {
          tmp_obj.points = tmp_obj.points.filter(function (obj) {
            return obj.type !== "start";
          });
        }
        tmp_obj.points.unshift({
          lat: String(props.data.latitude),
          lon: String(props.data.longitude),
          type: "start",
        });
      }
    }

    const onDragStart = (event) => {
      const initialPosition = Number(event.currentTarget.dataset.position);

      setDragAndDrop({
        ...dragAndDrop,
        draggedFrom: initialPosition,
        isDragging: true,
        originalOrder: list,
      });

      event.dataTransfer.setData("text/html", "");
    };

    const onDragOver = (event) => {
      event.preventDefault();

      let newList = dragAndDrop.originalOrder;

      const draggedFrom = dragAndDrop.draggedFrom;

      const draggedTo = Number(event.currentTarget.dataset.position);

      const itemDragged = newList[draggedFrom];
      const remainingItems = newList.filter(
        (item, index) => index !== draggedFrom
      );

      newList = [
        ...remainingItems.slice(0, draggedTo),
        itemDragged,
        ...remainingItems.slice(draggedTo),
      ];

      if (draggedTo !== dragAndDrop.draggedTo) {
        setDragAndDrop({
          ...dragAndDrop,
          updatedOrder: newList,
          draggedTo: draggedTo,
        });
      }
    };

    const onDrop = (event) => {
      setList(dragAndDrop.updatedOrder);
      setDragAndDrop({
        ...dragAndDrop,
        draggedFrom: null,
        draggedTo: null,
        isDragging: false,
      });
    };

    const onDragLeave = () => {
      setDragAndDrop({
        ...dragAndDrop,
        draggedTo: null,
      });
    };

    return (
      <section className={styles.mapPointsList}>
        <h3>Lista punktów</h3>
        <ul>
          {list && list.length
            ? list.map((item, index) => {
                return (
                  <li
                    key={index}
                    border={"asdasd"}
                    data-position={index}
                    draggable={item.type === "start" ? true : false}
                    onDragStart={onDragStart}
                    onDragOver={onDragOver}
                    onDrop={onDrop}
                    onDragLeave={onDragLeave}
                    className={
                      dragAndDrop && dragAndDrop.draggedTo === Number(0)
                        ? "dropArea"
                        : ""
                    }
                    style={
                      item.type === "start"
                        ? (list.length === index + 1 && list.length !== 1) ||
                          (index === 0 && list.length !== 1)
                          ? {
                              backgroundColor: "rgb(255 139 139 / 10%)",
                              boxShadow: "0 0 0 1px rgb(128 0 18 / 29%)",
                            }
                          : {
                              backgroundColor: "rgb(0 128 0 / 10%)",
                              boxShadow: "0 0 0 1px rgb(0 128 0 / 29%)",
                            }
                        : null
                    }
                  >
                    <p>
                      <span>{index + 1}</span>
                      <span>
                        {item.type === "custom-waypoint"
                          ? "Niestandardowy punkt"
                          : item.type === "start"
                          ? "Przepinka/przesiadka"
                          : item.type === "stop"
                          ? "Stop"
                          : item.type === "loading"
                          ? "Załadunek"
                          : item.type === "unloading"
                          ? "Rozładunek"
                          : "-"}
                      </span>
                      <span>
                        {item.type === "start" ? (
                          (list.length === index + 1 && list.length !== 1) ||
                          (index === 0 && list.length !== 1) ? (
                            <X color="red" />
                          ) : (
                            <Check color="green" />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </p>
                  </li>
                );
              })
            : "Brak wyznaczonych punktów.."}
        </ul>
      </section>
    );
  };

  const ReorderList = () => {
    const initialDnDState = {
      draggedFrom: null,
      draggedTo: null,
      isDragging: false,
      originalOrder: [],
      updatedOrder: [],
    };

    const [dragAndDrop, setDragAndDrop] = useState(initialDnDState);

    if (props.data !== null) {
      if (tmp_obj.length === undefined) {
        const checkChanges = (obj) => obj.type === "start";
        if (tmp_obj.points.some(checkChanges) === true) {
          tmp_obj.points = tmp_obj.points.filter(function (obj) {
            return obj.type !== "start";
          });
        }
        tmp_obj.points.unshift({
          lat: String(props.data.latitude),
          lon: String(props.data.longitude),
          type: "start",
        });
      }
    }

    let selectedPoint = recordList.points.filter((o) => {
      return o.lat === latRef.current;
    });

    let toborder;

    const liOff = {
      opacity: ".2",
    };
    const liOn = {
      opacity: "1",
    };

    return (
      <section className={`${styles.mapPointsList} ${styles.mapRecordList}`}>
        <h3>Lista punktów</h3>
        <ul>
          {recordList && recordList.points.length
            ? recordList.points.map((item, index) => {
                if (selectedPoint[0].lat === item.lat) {
                  toborder = true;
                }

                return (
                  <li
                    key={index}
                    data-position={index}
                    draggable={true}
                    // className={styles.recordList}
                    // className={toborder === true ? "on" : "off"}
                    style={
                      selectedPoint[0].lat === item.lat
                        ? {
                            backgroundColor: "rgb(0 128 0 / 10%)",
                            boxShadow: "0 0 0 1px rgb(0 128 0 / 29%)",
                          }
                        : toborder === true
                        ? { backgroundColor: "withe", opacity: "1" }
                        : { backgroundColor: "withe", opacity: ".2" }
                    }
                  >
                    <p>
                      <span>{index + 1}</span>
                      <span>
                        {item.type === "custom-waypoint"
                          ? "Niestandardowy punkt"
                          : item.type === "start"
                          ? "Przepinka/przesiadka"
                          : item.type === "stop"
                          ? "Stop"
                          : item.type === "loading"
                          ? "Załadunek"
                          : item.type === "unloading"
                          ? "Rozładunek"
                          : "-"}
                      </span>
                      <span>
                        {selectedPoint[0].lat === item.lat ? (
                          <Check color="green" />
                        ) : (
                          ""
                        )}
                      </span>
                    </p>
                  </li>
                );
              })
            : "Brak wyznaczonych punktów.."}
        </ul>
      </section>
    );
  };

  return (
    <div
      className={
        isCargo_change === false && isLeave_trailer === false && styles.columns
      }
    >
      <div
        className="map"
        ref={mapRef}
        style={{ height: "300px", zIndex: "0" }}
      />
      <div className={styles.mapPointsModal}>
        {!iconClicked &&
          isCargo_change === false &&
          isLeave_trailer === false && <DragToReorderList />}
        {iconClicked &&
          isCargo_change === false &&
          isLeave_trailer === false && <ReorderList />}
      </div>
    </div>
  );
};
