import React, { Dispatch, SetStateAction } from "react"
import Input from 'components/input'
import stylesMod from 'styles/newOrEdit.module.css'
import './styles.scss'



export type AddFleetCostsContainerInputPropsType = {
    value: string | number;
    label: string;
    onChangeHandler: Dispatch<SetStateAction<number>> | Dispatch<SetStateAction<string>>
    type: "text" | "number";
    disabled?: boolean
}

const AddFleetCostsContainerInput = ({ value, onChangeHandler, label, type = 'text', disabled = false }: AddFleetCostsContainerInputPropsType) => {

    return (<div className="add-costs-container__item">
        <div className={stylesMod.inp}>
            <label>{label}</label>
            <Input value={value} type={type}
                name="type"
                disabled={disabled}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const inputValue = type === 'number' ? parseInt(e.target.value) : e.target.value;
                    if (typeof onChangeHandler === 'function') {
                        onChangeHandler(inputValue as never);
                    }
                }}
            />
        </div>
    </div>)
}


export default AddFleetCostsContainerInput;