import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Input from "../../components/input";
import Title from "../../components/title";
import SubTitle from "../../components/subTitle";
import styles from "../table.module.css";
import RowsPerPage from "../../components/RowsPerPage";
import Pagination from "../../components/pagination";
import {
  Table,
  TableBody,
  TableCell,
  IconButton,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Slide,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Snackbar,
  Checkbox,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { DBurl } from "../../appConfig";

import CiclicDialog from "../../components/CiclicDialog";
import CircularProgress from "@material-ui/core/CircularProgress";

function descendingComparator(a, b, orderBy) {
  if (orderBy === "refueling length") {
    return ((!!b.refuelings && !b.refuelings[0].brak && b.refuelings?.length) ||
      0) <
      ((!!a.refuelings && !a.refuelings[0].brak && a.refuelings?.length) || 0)
      ? -1
      : ((!!b.refuelings && !b.refuelings[0].brak && b.refuelings?.length) ||
          0) >
        ((!!a.refuelings && !a.refuelings[0].brak && a.refuelings?.length) || 0)
      ? 1
      : 0;
  }
  if (orderBy === "refueling add") {
    return (!!b.refuelings &&
      !b.refuelings[0].brak &&
      countFuel(b.refuelings)) ||
      0 <
        (!!a.refuelings && !a.refuelings[0].brak && countFuel(a.refuelings)) ||
      0
      ? -1
      : (!!b.refuelings && !b.refuelings[0].brak && countFuel(b.refuelings)) ||
        0 >
          (!!a.refuelings &&
            !a.refuelings[0].brak &&
            countFuel(a.refuelings)) ||
        0
      ? 1
      : (!!b.refuelings && !b.refuelings[0].brak && countFuel(b.refuelings)) ||
        0 ===
          (!!a.refuelings && !a.refuelings[0].brak && countFuel(a.refuelings)
            ? 0
            : -1);
  }

  let sortB;
  let sortA;
  if (!!b && typeof b[orderBy] === "string") {
    sortB = b[orderBy].toLowerCase();
  } else {
    sortB = b;
  }
  if (!!a && typeof a[orderBy] === "string") {
    sortA = a[orderBy].toLowerCase();
  } else {
    sortA = a;
  }
  if (sortB < sortA) {
    return -1;
  }
  if (sortB > sortA) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const countFuel = (rows) => {
  let result = 0;
  rows && rows.forEach((row) => (result += row.fuel));
  return result;
};

const headCells = [
  {
    id: "date",
    alignRight: false,
    disablePadding: false,
    label: "Data",
    disableSortBy: true,
  },
  {
    id: "time",
    alignRight: false,
    disablePadding: false,
    label: "Godzina",
    disableSortBy: true,
  },
  {
    id: "loading",
    alignRight: false,
    disablePadding: false,
    label: "Załadunek",
    disableSortBy: true,
  },
  {
    id: "loadId",
    alignRight: false,
    disablePadding: false,
    label: "id ładunek",
    disableSortBy: true,
  },
];

function EnhancedTableHead(props) {
  const { styles } = props;

  return (
    <>
      <TableHead className={styles.tabHead}>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.alignRight ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "default"}
              className={styles.headCellSmall}
              style={{ padding: "10px 20px", fontSize: "11px" }}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  styles: PropTypes.object.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function useInput({ type }, placeholder) {
  const [value, setValue] = useState("");
  const input = (
    <Input
      value={value}
      handleChange={(e) => {
        setValue(e.target.value);
      }}
      type={type}
      placeholder={placeholder}
      className={styles.searchInputSmall}
      search={type === "text" ? true : false}
    />
  );
  return [value, input];
}

const options = [];

export default function LoadingUnloading(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [table, setTable] = useState([]);
  const [searchDriver, setSearchDriver] = useInput(
    { type: "text" },
    "Szukaj kierowcy"
  );
  const [dateFrom, setDateFrom] = useInput({ type: "datetime-local" }, "Od");
  const [dateTo, setDateTo] = useInput({ type: "datetime-local" }, "Do");

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");

  const [driverList, setDriverList] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [selectedDriverFullName, setSelectedDriverFullName] = useState();
  const [selectedDriverBusinessId, setSelectedDriverBusinessId] = useState();

  const [openModalCiclic, setOpenModalCiclic] = React.useState(false);

  const [error, setError] = useState(false);
  const [openBD, setOpenBD] = React.useState(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDialog(false);
  };

  async function getUnloadingLoadRegister(from, to, driver) {
    props.loading(true);
    await fetch(
      `${DBurl}/getUnloadingLoadRegister?idDriver=${driver}&dateFrom=${from}&dateTo=${to}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          if (data.data.length <= 0) {
            setOpenDialog(true);
            setDialogContent(`Brak danych dla wybranych parametrów...`);
            setTable(data.data || []);
            setDialogColor("error");
          } else {
            setTable(data.data || []);
            return data;
          }
          //	props.loading(false)
        } else {
          setOpenDialog(true);
          setDialogContent(`Brak danych, proszę wybrać właściwe parametry..`);
          setDialogColor("error");
          setTable(data.data || []);
          props.loading(false);
        }
      });
  }

  async function getDrivers() {
    props.loading(true);
    await fetch(`${DBurl}/getDriversNoSlaves`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          const drivers = data.data;
          if (options.length === 0) {
            for (const i in drivers) {
              options.push({
                label:
                  (drivers[i].firstname === null ? "" : drivers[i].firstname) +
                  " " +
                  (drivers[i].surname === null ? "" : drivers[i].surname) +
                  " [" +
                  drivers[i].driverid +
                  "] ",
                value: drivers[i].id,
              });
            }
            options.sort((b, a) =>
              a.label.localeCompare(b.label, "es", { sensitivity: "base" })
            );
            setDriverList(data.data);
          } else {
            console.log("lista pełna...");
          }
          props.loading(false);
          return data;
        } else {
          setOpenDialog(true);
          setDialogContent(
            `${data.responseDescription} // ${DataTransferItemList.response}  `
          );
          setDialogColor("error");
          props.loading(false);
        }
      });
  }

  useEffect(() => {
    getDrivers();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const search = (tbl) => {
    return tbl.filter((row) => {
      return (
        row?.isLoading
          .toString()
          .toLowerCase()
          .indexOf(searchDriver.toLowerCase()) > -1
      );
    });
  };

  const paginate = (pageNumber) => setPage(pageNumber - 1);

  const exportToXLS = async () => {
    props.loading(true);

    const today = await new Date();
    const start =
      dateFrom.toString().replace("T", " ") ||
      `${today.getFullYear()}-${today.getMonth() + 1 < 10 ? "0" : ""}${
        today.getMonth() + 1
      }-${today.getDate() < 10 ? "0" : ""}${today.getDate()} 00:00:00`;
    const end =
      dateTo.replace("T", " ") ||
      `${today.getFullYear()}-${today.getMonth() + 1 < 10 ? "0" : ""}${
        today.getMonth() + 1
      }-${today.getDate() < 10 ? "0" : ""}${today.getDate()} 23:59:59`;
    const url = `${DBurl}/printUnloadingLoadRegisterXLS?idDriver=${selectedDriver}&dateFrom=${start}&dateTo=${end}`;

    fetch(
      `${DBurl}/printUnloadingLoadRegisterXLS?idDriver=${selectedDriver}&dateFrom=${start}&dateTo=${end}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "X-CSRF-Token": props.user.csrf_token,
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        if (blob.size > 286 || blob.type === "application/vnd.ms-excel") {
          window.open(url, "_blank");
          props.loading(false);
        } else {
          setOpenDialog(true);
          setDialogContent(`Brak danych, proszę wybrać właściwe parametry...`);
          setDialogColor("error");
          props.loading(false);
        }
      });
  };

  const generateRaport = async () => {
    const startDate = dateFrom?.replace("T", " ") + ":00";
    const endDate = dateTo?.replace("T", " ") + ":00";

    getUnloadingLoadRegister(startDate, endDate, selectedDriver);

    var selectedRow = driverList.filter((obj) => {
      return obj.id === selectedDriver;
    });
    if (selectedRow.length > 0) {
      setSelectedRow(selectedRow);
      setSelectedDriverFullName(
        `${selectedRow[0].firstname} ${selectedRow[0].surname}`
      );
      setSelectedDriverBusinessId(selectedRow[0].driverid);
    }
  };

  const cyclicRaport = async () => {
    if (selectedDriver) {
      setOpenModalCiclic(true);
      var selectedRow = driverList.filter((obj) => {
        return obj.id === selectedDriver;
      });
      if (selectedRow.length > 0) {
        setSelectedDriverFullName(
          `${selectedRow[0].firstname} ${selectedRow[0].surname}`
        );
        setSelectedDriverBusinessId(selectedRow[0].driverid);
      }
    } else {
      setError(true);
      setOpenDialog(true);
      setDialogContent("Brak wybranego kierowcy");
      setDialogColor("error");
    }
  };

  useEffect(() => {
    if (driverList.length > 0) {
      setOpenBD(false);
    }
  }, [driverList]);

  return (
    <>
      <div className={styles.root}>
        <Paper className={styles.paper}>
          <Title
            title={props.state.tabName}
            btnBox
            btnExport
            btnCyclic
            cyclicFunc={() => {
              cyclicRaport();
            }}
            exportFunc={() => {
              exportToXLS();
            }}
            btnGenerate
            generateFunc={() => {
              generateRaport();
            }}
            cornersNew
            rightCorner1={setDateFrom}
            rightCorner2={setDateTo}
            selectDriverFunc={(e) => {
              setSelectedDriver(e.value);
              return e;
            }}
            selectDriver={options}
          />

          <SubTitle
            title={props.state.tabName}
            subTitleBox
            driverNameBox={selectedDriverFullName}
            driverWorkNumber={selectedDriverBusinessId}
          />

          <TableContainer className={styles.stickyTable}>
            <Table
              stickyHeader
              className={styles.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead styles={styles} rowCount={table.length} />

              <TableBody className={styles.tabBody}>
                {stableSort(search(table), getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow hover tabIndex={-1} key={index}>
                        <TableCell
                          className={styles.cellWtDivider}
                          align="left"
                          style={{ padding: "10px 20px" }}
                        >
                          {row.date}
                        </TableCell>
                        <TableCell
                          className={styles.cellWtDivider}
                          align="left"
                          style={{ padding: "10px 20px" }}
                        >
                          {row.time}
                        </TableCell>
                        <TableCell
                          className={styles.cellWtDivider}
                          align="left"
                          style={{ padding: "10px 20px" }}
                        >
                          {row.isLoading}
                        </TableCell>
                        <TableCell
                          className={styles.cellWtDivider}
                          align="left"
                          style={{ padding: "10px 20px" }}
                        >
                          {row.reference_no}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={styles.tableBottom}>
            <RowsPerPage
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              setPage={setPage}
              tableLength={table.length}
            />
            <div>
              <Pagination
                postsPerPage={rowsPerPage}
                // totalPosts={search(table).length}
                paginate={paginate}
                page={page}
              />
            </div>
          </div>

          <CiclicDialog
            {...props}
            selectedDriver={selectedDriver}
            selectedDriverFullName={selectedDriverFullName}
            openModalCiclic={openModalCiclic}
            setOpenModalCiclic={setOpenModalCiclic}
          />

          <Snackbar
            open={openDialog}
            autoHideDuration={4000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity={dialogColor}>
              {dialogContent}
            </Alert>
          </Snackbar>
        </Paper>
      </div>
    </>
  );
}
