import React, { useState, useMemo, useContext, useEffect } from "react";
import BTNstyles from "styles/button.module.css";
import { DBurl } from "appConfig";
import { X, Upload } from "react-feather";
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useQuery } from "react-query";
import ShowRouteMapDraggable from "../ShowRouteMapDraggable";
import { getExchangeRates } from "api/endpoints";
import { UserContext } from "contexts/userContext";
import stylesMod from "styles/newOrEdit.module.css";
import { round } from "../newOrder.helpers";
import SaveFavoriteRoutes from "../Locations/FavouriteRoutes/SaveFavoriteRoutes";
import TmsLoading from "components/TmsLoading";

const ModalShowRoute = ({
  values,
  updateValue,
  openModalShowRoute,
  setOpenModalShowRoute,
  setSaveRoute,
  setMainRouteChange,
  setDialog,
  isEdit,
  makeOrder,
  saveRouteAndSaveOrder,
  user_s,
  saveRoute,
  mainRouteChange,
  fetchOrderId,
  showSaveFavouriteRoute,
  edit_id_routing,
  setSaveRouteAndSaveOrder,
  isPreview,
}) => {
  const [route_response, setRoute_response] = useState(null);
  const [id_routing, setIdRouting] = useState(null);
  const [saveOrderWithRoute, setSaveOrderWithRoute] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { data: exchangeRates } = useQuery("exchangeRates", getExchangeRates, {
    onError: (error) => {
      setDialog({ isOpen: true, type: "error", content: error?.message });
    },
  });

  const { user } = useContext(UserContext);

  const loading = (state) => {
    setIsLoading(state);
  };

  const routingId = useMemo(() => {
    if (values.newRoute) {
      return null;
    }

    if (edit_id_routing) {
      return edit_id_routing;
    }

    return values.route_id;
  }, [
    edit_id_routing,
    saveRoute,
    values.newRoute,
    id_routing,
    values.route_id,
  ]);

  const setHeremapsRouting = async (
    order_id,
    request,
    response_param,
    originObj,
    destinationObj,
    waypointsObj
  ) => {
    if (
      request != null &&
      response_param != null &&
      originObj != null &&
      destinationObj != null &&
      waypointsObj != null
    ) {
      const response = await fetch(`${DBurl}/setHeremapsRouting`, {
        method: "post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": user.csrf_token,
        },
        body: JSON.stringify({
          mode: "insert",
          data: {
            id_order: order_id,
            id_routing: routingId,
            request: JSON.stringify(request),
            response: JSON.stringify(response_param),
            origin: JSON.stringify(originObj),
            destination: JSON.stringify(destinationObj),
            waypoints: JSON.stringify(waypointsObj),
          },
        }),
      });
      setSaveRoute(false);
      const json = await response.json();
      if (json.success) {
        setIdRouting(json.data.routing_id);
        updateValue("route_id", json.data.routing_id);
        updateValue("newRoute", false);

        return new Promise((resolve) => {
          resolve(json.data.routing_id);
        });
      }
    }
  };

  const saveRouteCallback = (
    request,
    response,
    originObj,
    destinationObj,
    waypointsObj,
    routing_id
  ) => {
    setRoute_response(response);
    setIdRouting(routing_id);

    let id_order;

    if (!user_s) {
      if (isEdit) {
        id_order = fetchOrderId;
        if (values.newRoute) {
          id_order = null;
        }
      }

      return setHeremapsRouting(
        id_order,
        request,
        response,
        originObj,
        destinationObj,
        waypointsObj
      );
    }
  };

  const updateCargoKilometers = (kilometers) => {
    updateValue("kilometers.cargo", kilometers);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xl"
      style={{ height: "100%" }}
      open={openModalShowRoute}
      onClose={() => {
        setOpenModalShowRoute(false);
      }}
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        className={stylesMod.dialogHead}
      >
        Podgląd trasy
        <IconButton
          aria-label="close"
          className={stylesMod.closeButton}
          onClick={() => {
            setOpenModalShowRoute(false);
          }}
        >
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {showSaveFavouriteRoute && (
          <SaveFavoriteRoutes
            trace={values.trace}
            contractorId={values.contractor.value}
            updateValue={updateValue}
            user_s={user_s}
            route_response={route_response}
            setSaveRoute={setSaveRoute}
            id_routing={id_routing}
            setOpenModalShowRoute={setOpenModalShowRoute}
            setDialog={setDialog}
          />
        )}
        <ShowRouteMapDraggable
          trace={values.trace}
          updateValue={updateValue}
          updateCargoKilometers={updateCargoKilometers}
          transport_set={values.transport.set}
          order_no={values.order_no}
          order_id={isEdit ? fetchOrderId : null}
          routing_id={edit_id_routing ? edit_id_routing : id_routing}
          saveRoute={saveRoute}
          csrf_token={user.csrf_token}
          saveRouteCallback={saveRouteCallback}
          isEdit={isEdit}
          mainRouteChange={mainRouteChange}
          cargo_kilometers={values.kilometers.cargo || 0}
          cost={values.cost}
          ratesPerKm={values.ratesPerKm}
          round={round}
          exchangeRates={exchangeRates}
          saveOrderWithRoute={saveOrderWithRoute}
          setSaveOrderWithRoute={setSaveOrderWithRoute}
          makeOrder={makeOrder}
          isTemplate={values.isTemplate}
          loading={loading}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="large"
          color="primary"
          className={`${BTNstyles.btn} ${BTNstyles.err}`}
          onClick={(e) => {
            setOpenModalShowRoute(false);
            setSaveRoute(false);
            setMainRouteChange(false);
            setSaveRouteAndSaveOrder(false);
          }}
        >
          Anuluj <X className={BTNstyles.downBtnIco} />
        </Button>
        {!saveRouteAndSaveOrder && !isPreview && (
          <Button
            variant="contained"
            size="large"
            color="primary"
            disabled={isPreview}
            className={BTNstyles.btn}
            onClick={(e) => {
              setSaveRoute(true);
              setOpenModalShowRoute(false);
              setMainRouteChange(false);
            }}
          >
            Zapisz trasę <Upload className={BTNstyles.downBtnIco} />
          </Button>
        )}
        {saveRouteAndSaveOrder && !isPreview && (
          <Button
            variant="contained"
            size="large"
            color="primary"
            disabled={isPreview}
            className={BTNstyles.btn}
            onClick={(e) => {
              setSaveOrderWithRoute(true);
              setOpenModalShowRoute(false);
              setMainRouteChange(false);
            }}
          >
            {values.isTemplate
              ? "Zatwierdź trasę i zapisz szablon"
              : "Zatwierdź trasę i zapisz zamówienie"}
            <Upload className={BTNstyles.downBtnIco} />
          </Button>
        )}
      </DialogActions>
      {isLoading && <TmsLoading status="Ładowanie mapy" />}
    </Dialog>
  );
};

export default ModalShowRoute;
