import React from "react";
import {
  Button,
  IconButton,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import BTNstyles from "styles/button.module.css";
import stylesMod from "styles/newOrEdit.module.css";
import { X } from "react-feather";

const ModalProfilQuestion = ({
  openModalProfilQuestion,
  setOpenModalProfilQuestion,
  transport_set,
  setOpenAddModalProfile,
}) => {
  return (
    <Dialog
      open={openModalProfilQuestion}
      onClose={() => {
        setOpenModalProfilQuestion(false);
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        className={stylesMod.dialogHead}
      >
        Profil pojazdu?
        <IconButton
          aria-label="close"
          className={stylesMod.closeButton}
          onClick={() => {
            setOpenModalProfilQuestion(false);
          }}
        >
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Grid container>
            <Grid item xs={12}>
              Pojazd (
              <b style={{ color: "#129865" }}>{transport_set?.car_name}</b>) w
              wybranym zestawie transportowym nie ma zdefiniowanego "Profilu
              pojazdu", który jest wymaganego do wyznaczenia trasy.
              <br />
              <br />
            </Grid>
            <Grid item xs={12}>
              Czy chcesz teraz zdefiniować profil danego pojazdu?
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="large"
          color="primary"
          className={BTNstyles.btn}
          onClick={(e) => {
            setOpenModalProfilQuestion(false);
          }}
        >
          Nie
        </Button>
        <Button
          variant="contained"
          size="large"
          className={`${BTNstyles.btn} ${BTNstyles.grn}`}
          onClick={(e) => {
            setOpenModalProfilQuestion(false);
            setOpenAddModalProfile(true);
          }}
          color="primary"
        >
          Tak, zdefiniuj profil
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalProfilQuestion;
