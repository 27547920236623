import { Car } from "types";
import { AllMonthsDifferenceType, SelectOptionType } from ".";

export const getCarsOptions = (cars: Car[]) => cars?.map((car) => ({ label: car.nr_rejestracyjny, value: car.id }))

export const handleDatePickerLabel = (selectedType: SelectOptionType) => {
    if (selectedType?.value === 2 || selectedType?.value === 3) return "Data zakończenia naliczania"
    if (selectedType?.value === 4) return "Miesiąc"
    if (selectedType?.value === 5) return "Data wykupu"
    if (!selectedType.value) return "Data"
}

export const getNumberOfMonthDifference = (endDate: Date, optionalStartDate?: Date): AllMonthsDifferenceType[] => {
    const currentDate = optionalStartDate ? new Date(optionalStartDate) : new Date();
    const targetDate = new Date(endDate);

    const monthsAndYears: AllMonthsDifferenceType[] = [];

    while (currentDate <= targetDate) {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const firstDayOfMonth = new Date(year, month, 1);
        monthsAndYears.push({ year, month: month, date: firstDayOfMonth });
        currentDate.setMonth(currentDate.getMonth() + 1);
    }
    return monthsAndYears;
}
