import React, { useState, useEffect } from "react";
import Input from "./input";

import Title from "./title";
import styles from "../containers/table.module.css";
import IcoBtn from "./buttons/icoBtn";
import { X, Check } from "react-feather";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Checkbox,
  Tooltip,
  Zoom,
} from "@material-ui/core";

import ViewDataModal from "./ViewDataModal";
import TmsLoadingSpinner from "./TmsLoadingSpinner/TmsLoadingSpinner";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const getheadCells = (title, title_2) => {
  if (title === "Usługi") {
    return [
      { id: "name", numeric: true, disablePadding: false, label: title },
      {
        id: "price_netto",
        numeric: true,
        disablePadding: false,
        label: "Cena netto",
      },
      { id: "quantity", numeric: true, disablePadding: false, label: "Ilość" },
      {
        id: "value_netto",
        numeric: true,
        disablePadding: false,
        label: "Wartość netto",
      },
      { id: "vat", numeric: true, disablePadding: false, label: "VAT" },
      {
        id: "value_brutto",
        numeric: true,
        disablePadding: false,
        label: "Wartość brutto",
      },
    ];
  } else {
    return [
      { id: "id", numeric: true, disablePadding: false, label: "LP" },
      { id: "name", numeric: true, disablePadding: false, label: title },
      {
        id: "name_2",
        numeric: true,
        disablePadding: false,
        label: "Czy naczepa?",
      },
    ];
  }
};

function EnhancedTableHead(props) {
  const {
    styles,
    order,
    orderBy,
    onRequestSort,
    onSelectAllClick,
    numSelected,
    rowCount,
    multiCheck,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const headCells = getheadCells(props.title);

  return (
    <TableHead className={styles.tabHead}>
      <TableRow>
        <TableCell className={styles.checkbox}>
          {multiCheck && (
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all id" }}
            />
          )}
        </TableCell>
        {props.driver && <TableCell className={styles.checkbox}></TableCell>}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ width: `${headCell.width || "auto"}` }}
          >
            {!headCell.noSort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={styles.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

export default function ShortList(props) {
  const [table, setTable] = useState(props.data || []);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("short");
  // const [selected, props.setSelected] = useState([]);
  // const [selected2, props.setSelected2] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState([]);
  const [searchName, setSearchName] = useInput({ type: "text" });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = table.map((n) => n.id);
      props.setSelected(newSelecteds);
      props.setTSDdata(newSelecteds);
      return;
    }
    props.setSelected([]);
    props.setTSDdata([]);
  };

  function useInput({ type, size }) {
    const [value, setValue] = useState("");
    const input = (
      <TableCell>
        <Input
          value={value}
          handleChange={(e) => {
            setValue(e.target.value);
          }}
          type={type}
          search={true}
          className={props.driver ? "small" : size}
        />
      </TableCell>
    );
    return [value, input];
  }

  const handleClick = (event, name) => {
    if (props.car) {
      if (props.multiCheck) {
        var tempTSDdata = [...props.TSDdata];
        if (tempTSDdata.includes(name)) {
          props.setTSDdata(tempTSDdata.filter((e) => e !== name));
        } else {
          props.setTSDdata([...tempTSDdata, name]);
        }
      } else {
        props.setTSDdata({
          ...props.TSDdata,
          car_id: name === props.TSDdata.car_id ? "" : name,
          car_name:
            name === props.TSDdata.car_id
              ? ""
              : props.data.find((e) => {
                  return e.id === name;
                })?.name,
        });
      }
    } else if (props.trailer) {
      props.setTSDdata({
        ...props.TSDdata,
        trailer_id: name === props.TSDdata.trailer_id ? "" : name,
        trailer_name:
          name === props.TSDdata.trailer_id
            ? ""
            : props.data.find((e) => {
                return e.id === name;
              })?.name,
      });
    } else if (props.driver) {
      props.setTSDdata({
        ...props.TSDdata,
        driver1_id: name === props.TSDdata.driver1_id ? "" : name,
        driver1_name:
          name === props.TSDdata.driver1_id
            ? ""
            : props.data.find((e) => {
                return e.id === name;
              })?.name,
        driver2_id:
          name === props.TSDdata.driver2_id ? "" : props.TSDdata.driver2_id,
        driver2_name:
          name === props.TSDdata.driver2_id ? "" : props.TSDdata.driver2_name,
      });
    }

    if (!props.multiCheck) {
      if (name === props.selected[0]) {
        props.setSelected([]);
      } else if (name !== props.selected2[0]) {
        props.setSelected([name]);
      } else {
        props.setSelected([name]);
        props.setSelected2([]);
      }
    } else {
      if (props.selected.includes(name)) {
        props.setSelected(props.selected.filter((e) => e !== name));
      } else {
        props.setSelected([...props.selected, name]);
      }
    }
    props.setTSDopen(true);
  };

  const handleClick2 = (event, name) => {
    if (props.driver) {
      props.setTSDdata({
        ...props.TSDdata,
        driver2_id:
          name === props.TSDdata.driver1_id ||
          name === props.TSDdata.driver2_id ||
          !props.TSDdata.driver1_id
            ? ""
            : name,
        driver2_name:
          name === props.TSDdata.driver1_id ||
          name === props.TSDdata.driver2_id ||
          !props.TSDdata.driver1_id
            ? ""
            : props.data.find((e) => {
                return e.id === name;
              })?.name,
      });

      if (!props.multiCheck) {
        if (name === props.selected2[0]) {
          props.setSelected2([]);
        } else if (name !== props.selected[0]) {
          props.setSelected2([name]);
        } else {
          props.setSelected2([name]);
          props.setSelected([]);
        }
      } else {
        if (props.selected2.includes(name)) {
          props.setSelected2(props.selected2.filter((e) => e !== name));
        } else {
          props.setSelected2([...props.selected2, name]);
        }
      }
    }
  };

  const isSelected = (name) => props.selected?.indexOf(name) !== -1;
  const isSelected2 = (name) => props.selected2?.indexOf(name) !== -1;

  const search = (tbl) => {
    return tbl.filter((row) => {
      return row?.name
        ?.toString()
        .toLowerCase()
        .includes(searchName.toLowerCase());
    });
  };

  useEffect(() => {
    setTable(props.data);
  }, [props.data]);

  useEffect(() => {
    if (props.TSDdata?.length > 0) {
      props.setSelected(props.TSDdata);
    }
  }, [props.TSDdata]);

  return (
    <>
      {!!props.set ? (
        <Title
          title={props.title}
          btnBox
          btnAdd
          addFunc={() => {
            props.setFunc("insert");
          }}
        />
      ) : (
        !props.noTitle && <Title title={props.title} />
      )}
      <TableContainer className={styles.stickyDoubleTable}>
        <Table
          stickyHeader={true}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
          size={"small"}
        >
          <EnhancedTableHead
            title={props.title}
            styles={styles}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={table.length}
            driver={props.driver}
            multiCheck={props.multiCheck}
            numSelected={props.selected?.length}
          />
          <TableBody className={styles.tabBody}>
            <TableRow>
              {props.driver && <TableCell></TableCell>}
              <TableCell></TableCell>
              <TableCell></TableCell>
              {setSearchName}
              <TableCell></TableCell>
              <TableCell></TableCell>
              {props.services && (
                <>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </>
              )}
            </TableRow>

            {props.isLoading ? (
              <TmsLoadingSpinner />
            ) : (
              stableSort(search(table), getComparator(order, orderBy)).map(
                (row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const isItemSelected2 = isSelected2(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      className={row.reservation ? styles.reservation : ""}
                    >
                      <TableCell className={styles.checkbox}>
                        {!props.noCheckBox && (
                          <Tooltip
                            title={props.driver && "Pierwszy Kierowca"}
                            TransitionComponent={Zoom}
                            disableHoverListener={!props.driver}
                          >
                            <Checkbox
                              checked={isItemSelected}
                              color="primary"
                              inputProps={{ "aria-labelledby": labelId }}
                              onClick={(event) => handleClick(event, row.id)}
                            />
                          </Tooltip>
                        )}
                      </TableCell>
                      {props.driver && (
                        <TableCell className={styles.checkbox}>
                          <Tooltip
                            title="Drugi Kierowca"
                            TransitionComponent={Zoom}
                          >
                            <Checkbox
                              checked={isItemSelected2}
                              color="primary"
                              inputProps={{ "aria-labelledby": labelId }}
                              onClick={(event) => handleClick2(event, row.id)}
                            />
                          </Tooltip>
                        </TableCell>
                      )}

                      <TableCell
                        component="td"
                        id={labelId}
                        align="right"
                        scope="row"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        align="right"
                        scope="row"
                      >
                        {row.name}
                      </TableCell>
                      {props.driver && (
                        <TableCell
                          component="th"
                          id={labelId}
                          align="right"
                          scope="row"
                        >
                          {row.car_icon_id === 5 ? (
                            <Check color="green" style={{ width: "18px" }} />
                          ) : (
                            <X color="red" style={{ width: "18px" }} />
                          )}
                        </TableCell>
                      )}
                      {!!props.services && (
                        <>
                          <TableCell
                            component="td"
                            id={labelId}
                            align="right"
                            scope="row"
                          >
                            {row.price_netto}
                          </TableCell>
                          <TableCell
                            component="td"
                            id={labelId}
                            align="right"
                            scope="row"
                          >
                            {row.quantity}
                          </TableCell>
                          <TableCell
                            component="td"
                            id={labelId}
                            align="right"
                            scope="row"
                          >
                            {row.value_netto}
                          </TableCell>
                          <TableCell
                            component="td"
                            id={labelId}
                            align="right"
                            scope="row"
                          >
                            {row.vat}
                          </TableCell>
                          <TableCell
                            component="td"
                            id={labelId}
                            align="right"
                            scope="row"
                          >
                            {row.value_brutto}
                          </TableCell>
                        </>
                      )}

                      <TableCell align="right">
                        <div className={styles.buttonBox}>
                          {(props.car || props.trailer || props.driver) && (
                            <IcoBtn
                              icon="Info"
                              tooltip="Sprawdź"
                              value={row}
                              onClick={(e) => {
                                setOpenModal(true);
                                setModalContent(row);
                              }}
                            />
                          )}
                          {props.set && (
                            <>
                              <IcoBtn
                                icon="Edit"
                                tooltip="Edytuj"
                                value={row}
                                onClick={(e) => {
                                  props.setFunc("update", row.id);
                                }}
                              />
                              <IcoBtn
                                icon="X"
                                tooltip="Usuń"
                                className="btn-delete"
                                value={row}
                                onClick={(e) => {
                                  props.deleteFunc(row.id);
                                }}
                              />
                            </>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                }
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <ViewDataModal
        data={modalContent}
        open={openModal}
        title={props.title}
        setOpen={setOpenModal}
        setModalContent={setModalContent}
      />
    </>
  );
}
