import React, { useState, useEffect, useRef } from 'react';
import Input from '../../components/input'
import Title from '../../components/title'
import styles from '../table.module.css'
import stylesMod from '../../styles/documents.module.css'
import BTNstyles from '../../styles/button.module.css'
import RowsPerPage from '../../components/RowsPerPageAlt'
import Pagination from '../../components/pagination'
import { DBurl } from '../../appConfig'
import { X } from 'react-feather';
import {
	Button, Table, TableBody, TableCell, TableContainer, IconButton, TableHead, TableRow, TableSortLabel, Paper, Checkbox, Slide, Snackbar, Dialog, Tooltip,
	DialogActions, DialogContent, DialogTitle, Grid
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import IcoBtn from '../../components/buttons/icoBtn';
import fuelCardsStyles from '../../styles/fuelCards.module.css'
import FuelCardsTransactionHistory from './FuelCardsTransactionHistory';
import FuelCardsFuelPrices from './FuelCardsFuelPrices';
import Select from 'react-select'


const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const headCells = [
		{ id: 'fuelCardNum', numeric: true, disablePadding: false, label: 'Numer karty paliwowej', class: fuelCardsStyles.headCell },
		{ id: 'linkedVehicle', numeric: true, disablePadding: false, label: 'Przypisany pojazd' },
		{ id: 'limit', numeric: true, disablePadding: false, label: 'Informacja o limicie', class: fuelCardsStyles.limit, align: "center" },
		{ id: 'block', numeric: true, disablePadding: false, label: 'Blokada', align: 'center' },
	];

const FuelCards = (props) => {

    const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('car');
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [table, setTable] = useState([])

	const [openDialog, setOpenDialog] = useState(false)
	const [dialogContent, setDialogContent] = useState("")
	const [dialogColor, setDialogColor] = useState("error")
	const [searchCard, setSearchCard] = useInput({ type: "text" });
	const [searchCar, setSearchCar] = useInput({ type: "text" });

	const [openModalFavStations, setOpenModalFavStations] = useState(false)
	const [openModalLimit, setOpenModalLimit] = useState(false)
	const [openModalBlock, setOpenModalBlock] = useState(false)
	const [station, setStation] = useState()
	const [stations, setStations] = useState([])
	const [favStations, setFavStations] = useState([])
	const [favStationsToRender, setFavStationsToRender] = useState([])
	const [cardData, setCardData] = useState({})
	const [limitData, setLimitData] = useState('')
	const [blockData, setBlockData] = useState('')

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, table.length - page * rowsPerPage);

	function EnhancedTableHead(props) {
		const { styles, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

		return (
			<TableHead className={styles.tabHead}>
				<TableRow>
					{headCells.map((headCell) => {
						return (
							<TableCell
								key={headCell.id}
								align={headCell.align ? `${headCell.align}` : "left"}
								padding={headCell.disablePadding ? 'none' : 'default'}
								sortDirection={orderBy === headCell.id ? order : false}
								className={headCell.class}
							>
								{headCell.label}
							</TableCell>
						)
					})}
				</TableRow>
			</TableHead>
		);
	}

	function useInput({ type }) {
		const [searchValue, setSearchValue] = useState("")
		const input = <Input className={fuelCardsStyles.search} value={searchValue} handleChange={(e) => {
			setSearchValue(e.target.value)
		}
		} type={type}
			search={true}
			id={'search'}/>;
		return [searchValue, input];
	}

	const search = (tbl) => {
		return tbl.filter((row) => {
			return String(row.card).toLowerCase().indexOf(searchCard.toLowerCase()) > -1 &&
				String(row.auto).toLowerCase().indexOf(searchCar.toLowerCase()) > -1
		})
	}

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenDialog(false)
	}

	const fetchCards = async (token) => {
		props.loading(true)
		const response = await fetch(`${DBurl}/getE100Cards`, {
			method: "GET",
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": token
			}
		});
		const json = await response.json()
		if (json.success) {
			setTable(json.data)
			props.loading(false)
		}
	}

	const blockCard = async (token) => {
		const response = await fetch(`${DBurl}/blockE100Card?card=${blockData.card}`, {
			method: "GET",
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": token
			}
		});
	}

	const fetchStations = async (token) => {
		props.loading(true)
		const response = await fetch(`${DBurl}/getE100GasStations?external_category_ids=1,2,4,5`, {
			method: "GET",
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": token
			}
		});
		const json = await response.json()
		if (json.success) {
			setStations(json.data.map(s => {
				return {
					label: s.address,
					value: s.id,
					...s
				}
			}))
			props.loading(false)
		}
	}

	const fetchFavStations = async (token) => {
		props.loading(true)
		const response = await fetch(`${DBurl}/getFavoriteGasStations`, {
			method: "GET",
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": token
			}
		});
		const json = await response.json()
		if (json.success) {
			setFavStations(json.data)
			props.loading(false)
		}
	}

	const checkLimits = async (token, station, card) => {
		props.loading(true)
		const response = await fetch(`${DBurl}/getE100Limits?station=${station}&card=${card}`, {
			method: "GET",
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": token
			}
		});
		const json = await response.json()
		if (json.success) {
			setLimitData(json.data)
		}
		props.loading(false)
	}

	const postFavStations = async (token) => {
		props.loading(true)
		const response = await fetch(`${DBurl}/setFavoriteGasStations`, {
			method: "POST",
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": token
			},
			body: JSON.stringify({
				mode: 'insert',
				data: {
					fav_stations: idsToPost
				}
			})
		})
		fetchFavStations(token)
		props.loading(false)
	}
	

	useEffect(() => {
		fetchCards(props.user.csrf_token)
		fetchStations(props.user.csrf_token)
		fetchFavStations(props.user.csrf_token)
	}, [])


	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const renderLimitData = () => {
		if (limitData === "") return (<div className={fuelCardsStyles.noData}>
			Wybierz stację
		</div>)
		if (limitData === null || limitData === "Something went wrong" || limitData === "Station not found") return (<div className={fuelCardsStyles.noData}>
			-Brak danych do wyświetlenia-
		</div>)
		return (<div>
				{limitData.map(el => {return (
					<div className={fuelCardsStyles.limitData}>
						<h1>{el.title}</h1>
						<p1>{el.limit}</p1>
					</div>
				)})}
			</div>)
	}

	const delStation = (row) => {
		setFavStationsToRender(favStationsToRender.filter(st => st.id !== row.id))
	}

	const idsToPost = favStationsToRender.map(e => e.id)

	const paginate = pageNumber => setPage(pageNumber - 1);
	const isSelected = (name) => selected.indexOf(name) !== -1;

	useEffect(()=>{
		setFavStationsToRender(favStations)
	},[openModalFavStations])

    return (
        <div>
            <Grid>
                <Title title={props.state.tabName}
						btnBox
                        fuelStations
                        fuelStationsFunc={e => {setOpenModalFavStations(true)}}
                />
                <div className={fuelCardsStyles.header}>Lista kart paliwowych</div>
                <TableContainer className={styles.stickyDoubleTableAlt}>
                    <Table
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            styles={styles}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={table.length}
                        />
                        <TableBody className={styles.tabBody}>
							<TableRow>
								<TableCell className={`${fuelCardsStyles.headCell}`}>{setSearchCard}</TableCell>
								<TableCell>{setSearchCar}</TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
							</TableRow>
                            {search(table)
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (<>
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.id}
                                        >
                                            <TableCell className={fuelCardsStyles.headCell} align="left">{row.card}</TableCell>
                                            <TableCell align="left">{row.auto}</TableCell>
                                            <TableCell align="center">
												<button 
													className={fuelCardsStyles.checkButton}
													onClick={()=>{
														setCardData(row)
														setOpenModalLimit(true)
													}}
													>
													Sprawdź
												</button>
											</TableCell>
                                            <TableCell align="right" className={fuelCardsStyles.statusCell}>
												<div className={fuelCardsStyles.statusCell}>{row.status === "Aktywna" ? 
													<IcoBtn icon="Unlock" tooltip="Zablokuj" value={null} className={fuelCardsStyles.unlock} onClick={(e) => {
														setBlockData(row)
														setOpenModalBlock(true)
													}} /> : 
													<IcoBtn icon="Lock" tooltip={row.status} value={null} className={fuelCardsStyles.lock} onClick={(e) => {

													}} />
												}</div>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                    );
                                })}
                                {emptyRows > 0 && (
									<TableRow style={{ height: 21 * emptyRows }}>
										<TableCell colSpan={4} />
									</TableRow>
								)}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={styles.tableBottom} >
						<RowsPerPage
							rowsPerPage={rowsPerPage}
							setRowsPerPage={setRowsPerPage}
							setPage={setPage}
							tableLength={search(table).length}
						/>

						<div>
							<Pagination
								postsPerPage={rowsPerPage}
								totalPosts={search(table).length}
								paginate={paginate}
								page={page}
							/>
						</div>
                </div>
            </Grid>
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <FuelCardsTransactionHistory 
                        props={props}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FuelCardsFuelPrices 
                        props={props}
						favStations={favStations}
                    />
                </Grid>
            </Grid>
			<Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
				<Alert onClose={handleClose} severity={dialogColor}>
					{dialogContent}
				</Alert>
			</Snackbar>

			<Dialog
				maxWidth='sm'
				fullWidth
				open={openModalFavStations}
				TransitionComponent={Transition}
				onClose={() => {
				setOpenModalFavStations(false)
				setStation()
				}}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>

			<DialogTitle id="alert-dialog-slide-title" className={fuelCardsStyles.dialogHead}>Wybierz ulubione stacje paliw		
				<IconButton aria-label="close" className={fuelCardsStyles.closeButton} onClick={() => {
					setOpenModalFavStations(false)
					setStation()
				}}>
			<X />
			</IconButton>
				</DialogTitle >
				<DialogContent className={''}>
					<Grid container spacing={0}>
						<Grid item xs={10}>			
							<div className={`${styles.inp} multi_selector`}>
								<label htmlFor={"station"}>Wybierz stacje</label>
								<Select 
									value={station}
									className={`${fuelCardsStyles.select}`}
									options={stations.filter(station => {
										for (const secondStation of favStationsToRender) {
											if (station.id === secondStation.id) {
												return false
											}
										}
										return true
									})}
									onChange={e => {
										setStation(e)
									}
								}
									name="station"
									placeholder="Wybierz..."
									getOptionValue={option => `${option.label}`}  
									styles={{
										control: (base, state) => ({
											...base,
											border: '1px solid #142f42',
											boxShadow: 'none',
											'&:hover': {
												border: '1px solid #142f42',
											}
										}),
										option: (provided, state) => ({
											...provided,
											backgroundColor: state.isFocused && "#142f42",
											color: state.isFocused && "#fff"
										 }),
										 menuPortal: base => ({ ...base, zIndex: 9999 })
										}}
										menuPortalTarget={document.body}
								/>
							</div>
						</Grid>
						<Grid item xs={2} className={fuelCardsStyles.button}>
							{/* <IcoBtn 
								icon="Plus" 
								tooltip={favStationsToRender.length < 5 ? "Dodaj" : "Maksymalna ilość stacji osiągnięta. Usuń jedną z wybranych stacji aby dodać kolejną."} 
								className={favStationsToRender.length < 5 ? fuelCardsStyles.plus : fuelCardsStyles.plusDisabled} 
								onClick={(e) => {
									favStationsToRender.length < 5 ? setFavStationsToRender([...favStationsToRender, station]) : setFavStationsToRender(favStationsToRender)
									setStation({})
							}} /> */}
							<Tooltip title={favStationsToRender.length < 5 ? "Dodaj" : "Maksymalna ilość stacji osiągnięta. Usuń jedną z wybranych stacji aby dodać kolejną."}>
								<Button variant="contained" size="large" className={`${BTNstyles.btn} ${BTNstyles.grn} ${favStationsToRender.length < 5 ? fuelCardsStyles.plus : fuelCardsStyles.plusDisabled}`}
											onClick={(e) => {station && 
											favStationsToRender.length < 5 ? setFavStationsToRender([...favStationsToRender, station]) : setFavStationsToRender(favStationsToRender)
											station ? setStation('') : console.log(e)
									}}
									color="primary">
								Dodaj
							</Button>
							</Tooltip>
						</Grid>
						<Grid item xs={12}>
							<Grid className={fuelCardsStyles.favStationsContainer}>
								<TableContainer className={fuelCardsStyles.tableList}>
									<Table
									stickyHeader
									size="small"
									aria-label="enhanced table"
									>

									<TableHead className={styles.tabHead}>
										<TableRow>
											<TableCell align="left">Twoje stacje</TableCell>
											<TableCell align="left"></TableCell>
										</TableRow>
									</TableHead>

									<TableBody className={styles.tabBody}>

										{favStations && favStationsToRender.map((row, index) => {
											const labelId = `enhanced-table-checkbox-${row.id}`; 
											const isItemSelected = isSelected(row.name);
											return (
												<TableRow
													hover
													tabIndex={-1}
													key={row.id}
												>
													<TableCell align="left">{row.brand} {row.address} </TableCell>
													<TableCell align="right" >
														<div className={styles.buttonBox}>
															<IcoBtn
																icon="X"
																tooltip="Usuń"
																className={'btn-delete'}
																value={row}
																onClick={(e) => {
																	delStation(row)
																}} 
															/>
														</div>
												</TableCell>
											</TableRow>
											)
										})}

									</TableBody>
									</Table>
								</TableContainer>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" size="large" className={`${BTNstyles.btn}`} onClick={() => {
								setOpenModalFavStations(false)
								setStation()
							}}
							color="primary">
						Anuluj
					</Button>
					<Button variant="contained" size="large" className={`${BTNstyles.btn} ${BTNstyles.grn}`} onClick={() => {
								postFavStations(props.user.csrf_token)
								setOpenModalFavStations(false)
								setStation()
							}}
							color="primary">
						Zapisz
					</Button>
			</DialogActions>
		</Dialog>

			<Dialog
				maxWidth='sm'
				fullWidth
				open={openModalLimit}
				TransitionComponent={Transition}
				onClose={() => {
					setOpenModalLimit(false)
					setCardData({})
					setStation()
					setLimitData('')
					}}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>

			<DialogTitle id="alert-dialog-slide-title" className={fuelCardsStyles.dialogHead}>Sprawdź limity		
				<IconButton aria-label="close" className={fuelCardsStyles.closeButton} onClick={() => {
					setOpenModalLimit(false)
					setCardData({})
					setStation()
					setLimitData('')
				}}>
			<X />
			</IconButton>
				</DialogTitle >
				<DialogContent className={''}>
					<Grid container spacing={1}>
						<Grid item xs={10}>			
							<div className={`${styles.inp} multi_selector`}>
								<label htmlFor={"station"}>Wybierz stacje</label>
								<Select 
									value={station}
									className={`${fuelCardsStyles.select}`}
									options={stations}
									onChange={e => {
										checkLimits(props.user.csrf_token, e.station, cardData.card)
									}
								}
									name="station"
									placeholder="Wybierz..."
									getOptionValue={option => `${option.label}`}  
									styles={{
										control: (base, state) => ({
											...base,
											border: '1px solid #142f42',
											boxShadow: 'none',
											'&:hover': {
												border: '1px solid #142f42',
											}
										}),
										option: (provided, state) => ({
											...provided,
											backgroundColor: state.isFocused && "#142f42",
											color: state.isFocused && "#fff"
										 }),
										 menuPortal: base => ({ ...base, zIndex: 9999 })
										}}
										menuPortalTarget={document.body}
								/>
							</div>
						</Grid>
						<Grid item xs={12}>
							<Grid>
								<div className={fuelCardsStyles.limitsInfo}>
									{renderLimitData()}
								</div>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" size="large" className={`${BTNstyles.btn} ${BTNstyles.grn}`} onClick={() => {
								setOpenModalLimit(false)
								setCardData({})
								setStation()
								setLimitData('')
							}}
							color="primary">
						Ok
					</Button>
			</DialogActions>
		</Dialog>
			<Dialog
				maxWidth='xs'
				open={openModalBlock}
				TransitionComponent={Transition}
				onClose={() => {
					setOpenModalBlock(false)
					setBlockData('')
					}}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>

			<DialogTitle id="alert-dialog-slide-title" className={fuelCardsStyles.dialogHead}>Uwaga!		
				<IconButton aria-label="close" className={fuelCardsStyles.closeButton} onClick={() => {
					setOpenModalBlock(false)
					setBlockData('')
				}}>
			<X />
			</IconButton>
				</DialogTitle >
				<DialogContent className={''}>
					<Grid container spacing={1}>
						<div>
							Czy napewno chcesz zablokować kartę numer: {blockData.card}? <br/>
							UWAGA! Czynność ta nie może zostać cofnięta! <br/>
							Czy chcesz kontynuować?
						</div>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" size="large" className={`${BTNstyles.btn}`} onClick={() => {
								setOpenModalBlock(false)
								setBlockData('')
							}}
							color="primary">
						Anuluj
					</Button>
					<Button variant="contained" size="large" className={`${BTNstyles.btn} ${BTNstyles.err}`} onClick={() => {
								blockCard(props.user.csrf_token)
								setOpenModalLimit(false)
								setBlockData('')
							}}
							color="primary">
						Blokuj
					</Button>
			</DialogActions>
		</Dialog>
        </div>

    )
}

export default FuelCards;