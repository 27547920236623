import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "../../components/input";
import Title from "../../components/title";
import styles from "../table.module.css";
import RowsPerPage from "../../components/RowsPerPage";
import Pagination from "../../components/pagination";

// import NewOrder from '../order/newOrder'

import OrderDialog from "../order/orderDialog";

import { FullScreen, useFullScreenHandle } from "react-full-screen";
import SelectorsDialog from "../../components/selectorsDialog";
import { DBurl } from "../../appConfig";
import Timer from "../../components/Timer";
import FleetStateTableRow from "./FleetStateTableRow";

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../../img/flags", false, /\.(png|gif|svg)$/)
);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  if (stabilizedThis) {
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
}

const headCells = [
  { id: "name", numeric: false, disablePadding: true, label: "Pojazd" },
  {
    id: "actualOrder",
    numeric: true,
    disablePadding: false,
    label: "Zlecenie",
  },
  {
    id: "progress",
    numeric: true,
    disablePadding: false,
    label: "% realizacji zlecenia",
  },
  { id: "status", numeric: true, disablePadding: false, label: "Status" },
  { id: "ref", numeric: true, disablePadding: false, label: "Nr ref." },
  {
    id: "nextOrder",
    numeric: true,
    disablePadding: false,
    label: "Następne zlecenie",
  },
  { id: "driver", numeric: true, disablePadding: false, label: "Kierowca" },
  { id: "selector", numeric: true, disablePadding: false, label: "Selektory" },
  ,
  {
    id: "kmToTarget",
    numeric: true,
    disablePadding: false,
    label: "Km do celu",
  },
  { id: "position", numeric: true, disablePadding: false, label: "Pozycja" },
  { id: "tags", numeric: true, disablePadding: false, label: "Tagi" },
];

function EnhancedTableHead(props) {
  const {
    styles,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    setSearchVehicle,
    setSearchOrder,
    setSearchDriver,
    setSearchRef,
    setSearchTags,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead className={styles.tabHead}>
        <TableRow>
          <TableCell className={styles.checkbox}>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all id" }}
            />
          </TableCell>
          {headCells.map((headCell) =>
            headCell.id === "selector" ? (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {headCell.label}
              </TableCell>
            ) : (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  style={
                    headCell.id === "selector" ? { paddingRight: "10px" } : null
                  }
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={styles.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )
          )}
          {/* <TableCell className={styles.buttonBoxParent} /> */}
        </TableRow>
      </TableHead>
      <TableHead className={styles.tabHead}>
        <TableRow className={styles.secondStickyRow}>
          <TableCell></TableCell>
          {setSearchVehicle}
          {setSearchOrder}
          <TableCell></TableCell>
          <TableCell></TableCell>
          {setSearchRef}
          <TableCell></TableCell>
          {setSearchDriver}
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          {setSearchTags}
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  styles: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function useInput({ type }) {
  const [value, setValue] = useState("");
  const input = (
    <TableCell>
      <Input
        value={value}
        handleChange={(e) => {
          setValue(e.target.value);
        }}
        type={type}
        search={true}
      />
    </TableCell>
  );
  return [value, input];
}

export default function EnhancedTable(props) {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("last_ts");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [table, setTable] = useState([]);
  const [searchVehicle, setSearchVehicle] = useInput({ type: "text" });
  const [searchOrder, setSearchOrder] = useInput({ type: "text" });
  const [searchDriver, setSearchDriver] = useInput({ type: "text" });
  const [searchTags, setSearchTags] = useInput({ type: "text" });
  const [searchRef, setSearchRef] = useInput({ type: "text" });
  const [openOrder, setOpenOrder] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [openOrderStatus, setOpenOrderStatus] = useState(false);
  const [orderStatusData, setOrderStatusData] = useState({});
  const [rowData, setRowData] = useState("");
  const [fullscreenOn, setFullscreenOn] = useState(false);
  const [openSelectorsModal, setOpenSelectorsModal] = useState(false);
  const [openSelectorsData, setOpenSelectorsData] = useState({});
  const { addTab } = props;

  const getStateOfTheFleet = async () => {
    props.loading(true);
    const response = await fetch(`${DBurl}/getStateOfTheFleet`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      if (json?.data?.length > 0) {
        setTable(json.data);
      }
      props.loading(false);
    }
  };

  useEffect(() => {
    getStateOfTheFleet();
  }, []);

  const fullscreenFunc = useFullScreenHandle();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const search = (tbl) => {
    if (tbl?.length > 0) {
      return tbl.filter((row) => {
        const tempOrder_np = row.active_order.order_no || "";
        const tempDriver = row.driver || "";
        const tags =
          row?.active_order?.tags?.length > 0
            ? row.active_order.tags
                .map((tag) => tag?.name.toLowerCase())
                .join(", ")
            : [];
        return (
          row.name
            .toString()
            .toLowerCase()
            .indexOf(searchVehicle.toLowerCase()) > -1 &&
          tempOrder_np
            ?.toString()
            .toLowerCase()
            .indexOf(searchOrder.toLowerCase()) > -1 &&
          tempDriver
            .toString()
            .toLowerCase()
            .indexOf(searchDriver.toLowerCase()) > -1 &&
          (!searchTags || tags.includes(searchTags.toLowerCase())) &&
          (!searchRef ||
            handleRefs(row)?.some((str) => str.includes(searchRef)))
        );
      });
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = table?.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleRefs = (row) => {
    const locationsRefs = row?.active_order?.order_locations
      ?.map((loc) => loc.reference_no)
      .filter((rec) => rec !== null);
    const goodsRefs = row?.active_order?.order_locations
      ?.map((loc) => loc?.goods?.map((g) => g.reference_no))
      ?.flat()
      .filter((rec) => rec !== null);
    return locationsRefs !== undefined ? locationsRefs : goodsRefs;
  };

  const paginate = (pageNumber) => setPage(pageNumber - 1);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, table?.length - page * rowsPerPage);

  const getOpenSelectorsData = (row) => {
    // TODO fetch po id po dane obecne i historyczne do selektorów danego kierowcy/auta
    // Przygotować JSONa z przykładowymi danymi i zaimportować Tu

    const yesterdayData = {
      drivingTime: row.tacho_drive_yesterday,
      workTime: row.tacho_work_yesterday,
      stopTime: row.tacho_break_yesterday,
      stopEngineOnTime: row.tacho_rest_yesterday,
    };

    const todayData = {
      drivingTime: row.tacho_drive_today,
      workTime: row.tacho_work_today,
      stopTime: row.tacho_break_today,
      stopEngineOnTime: row.tacho_rest_today,
    };

    // Funkcja która przyjmuje dwa czasy jako stringi "12:34" i dodaje je do siebie zwracając stringa w tym samym formacie

    // function addingTime(firstTime, secondTime) {
    // 	const firstTable = firstTime.split(":")
    // 	const secondTable = secondTime.split(":")
    // 	let resultMinutes = Number(firstTable[1]) + Number(secondTable[1])
    // 	let resultHours = Number(firstTable[0]) + Number(secondTable[0])
    // 	if (resultMinutes > 59) {
    // 		resultMinutes -= 60
    // 		resultHours += 1
    // 	}
    // 	if (resultMinutes < 10) { resultMinutes = `0${resultMinutes}` }
    // 	if (resultHours < 10) { resultHours = `0${resultHours}` }

    // 	return `${resultHours}:${resultMinutes}`
    // }

    const summaryData = {
      drivingTime: row.tacho_drive_sum,
      workTime: row.tacho_work_sum,
      stopTime: row.tacho_break_sum,
      stopEngineOnTime: row.tacho_rest_sum,
    };

    return {
      yesterday: yesterdayData,
      today: todayData,
      summary: summaryData,
    };
  };

  const exportToXLS = () => {
    const url = `${DBurl}/printStateOfTheFleetXLS`;
    window.open(url, "_blank");
  };

  return (
    <div className={styles.root}>
      <FullScreen handle={fullscreenFunc}>
        <Paper className={styles.paper}>
          <Title
            title={props.state.tabName}
            btnBox
            btnExport
            btnAdd
            addFunc={() => props.addTab("newOrder")}
            btnAddTxt="Dodaj zlecenie"
            btnFullscreen
            exportFunc={() => {
              exportToXLS();
            }}
            fullscreenOn={fullscreenOn}
            setFullscreenOn={setFullscreenOn}
            fullscreenFunc={() => {
              fullscreenOn ? fullscreenFunc.exit() : fullscreenFunc.enter();
              setFullscreenOn(!fullscreenFunc.active);
            }}
            cornersNew
            refresh={<Timer refreshTable={getStateOfTheFleet} table={table} />}

            // }
          />
          <TableContainer className={styles.stickyTable}>
            <Table
              // style={{ tableLayout: "auto" }}
              stickyHeader
              className={styles.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                styles={styles}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={table?.length}
                setSearchVehicle={setSearchVehicle}
                setSearchOrder={setSearchOrder}
                setSearchDriver={setSearchDriver}
                setSearchRef={setSearchRef}
                setSearchTags={setSearchTags}
              />
              <TableBody className={styles.tabBody}>
                {stableSort(search(table), getComparator(order, orderBy))
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    const rowConfig = { row, images, isItemSelected, labelId };
                    const rowSetters = {
                      setOpenOrder,
                      setRowData,
                      setOrderData,
                      setOpenOrderStatus,
                      setOrderStatusData,
                      setOpenSelectorsModal,
                      setOpenSelectorsData,
                      setSearchRef,
                      setSearchTags,
                    };
                    const rowGetters = { addTab, getOpenSelectorsData };

                    return (
                      <FleetStateTableRow
                        key={row.id}
                        handleClick={handleClick}
                        rowConfig={rowConfig}
                        rowSetters={rowSetters}
                        rowGetters={rowGetters}
                        handleRefs={handleRefs}
                      />
                    );
                  })}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 33 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={styles.tableBottom}>
            <RowsPerPage
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              setPage={setPage}
              tableLength={table?.length}
            />
            <div>
              <Pagination
                postsPerPage={rowsPerPage}
                totalPosts={search(table)?.length}
                paginate={paginate}
                page={page}
              />
            </div>
          </div>
          {openOrder && (
            <OrderDialog
              data={orderData}
              open={openOrder}
              setOpen={setOpenOrder}
              mainData={rowData}
              addTab={props.addTab}
              user={props.user}
            />
          )}

          {openOrderStatus && (
            <OrderDialog
              orderStatus={true}
              data={orderStatusData}
              open={openOrderStatus}
              setOpen={setOpenOrderStatus}
              mainData={rowData}
              addTab={props.addTab}
              user={props.user}
            />
          )}

          {openSelectorsModal && (
            <SelectorsDialog
              data={openSelectorsData}
              open={openSelectorsModal}
              setOpen={setOpenSelectorsModal}
              mainData={rowData}
              addTab={props.addTab}
            />
          )}
        </Paper>
      </FullScreen>
    </div>
  );
}
