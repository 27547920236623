import React, { Component } from 'react';
import { DBurl } from '../../appConfig'
import styles from './routePro.module.css';
import mpClose from '../../img/mapPremium/mp_close.png';
import Draggable from 'react-draggable';
import ReactDragListView from 'react-drag-listview';

import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Autocomplete  from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
//import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IcoBtn from '../../components/buttons/icoBtn';

import { Plus, MapPin } from 'react-feather';

import KeyboardTabOutlinedIcon from '@material-ui/icons/KeyboardTabOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import car from '../../img/mapPremium/mp-osobowy.png'
import smalltruck from '../../img/mapPremium/mp-dost.png'
import bigtruck from '../../img/mapPremium/mp-ciezarowy.png'
import tir from '../../img/mapPremium/mp-naczepa.png'
import fast from '../../img/mapPremium/mp-szybka.png'
import short from '../../img/mapPremium/mp-krotka.png'
import eco from '../../img/mapPremium/mp-tania.png'
import gddkiaB from '../../img/mapPremium/mp-utrudnienia_b.png'
import gddkiaG from '../../img/mapPremium/mp-utrudnienia_g.png'
import platneB from '../../img/mapPremium/mp-platne_b.png'
import platneG from '../../img/mapPremium/mp-platne_g.png'
import restrictB from '../../img/mapPremium/mp-restrykcje_b.png'
import restrictG from '../../img/mapPremium/mp-restrykcje_g.png'
import limitB from '../../img/mapPremium/mp-ograniczenia_b.png'
import limitG from '../../img/mapPremium/mp-ograniczenia_g.png'
import countingB from '../../img/mapPremium/mp-zliczanie_b.png'
import countingG from '../../img/mapPremium/mp-zliczanie_g.png'


export class RoutePro extends Component{
    
    /**
    * constructor()
    *
    * Funkcja 
    *
    */
    constructor(){
        super();
        
        const data = [];
        for (let i = 1, len = 3; i < len; i++) {
          data.push({
            title: `rows${i}`,
            text: '',
            place: null
          });
        }
        
        this.state = {
            tabValue: 0,
            optionsAC: [],
            data: data,
            timeout: null,
            showLoading: false,
            
            selectCarType: 0,
            selectRouteType: 1,
            selectCurrency: 'PLN',
            selectTax: 1,
            startRouteDateTime: null,
            chbxGddkia: false,
            chbxPobOplat: false,
            chbxRestrykcjeDrogowe: false,
            chbxSpeedLimit: false,
            chbxOplatyZliczaj: false,
            chbxOptymalizujWszystkiePunkty: false,
            
            routeDistans: 0,
            routeTime: 0,
            routeCost: 0,
            routeStartTime: '',
            routeStopTime: '',
            routeStart: '',
            routeEnd: '',
            totalFuelCost: 0,
            totalTimeCost: 0,
            totalRouteCost: 0,
            logisticTollResults: [],
            additionalRoadCost: 0,
            vehicleTypeTemplate: [],
            visitTimes: [],
            viaTollParams: [],
            roadParams: [],
            roadSegments: [],
            routeItineraries: [],
            routeItinerariesTypeName: [
                'Inne zdarzenie',
                'Wyjazd z punktu',
                'Dotarcie do punktu',
                'Skręć w lewo',
                'Skręć w prawo',
                'Trzymaj się lewej strony',
                'Trzymaj się prawej strony',
                'Wjazd na autostradę',
                'Kontynuuj jazdę',
                'Zawróć',
                'Rondo',
                'Budowa drogi - opóźnienie',
                'Budowa drogi - możliwe utrudnienia',
                'Zmiana nazwy drogi',
                'Skręć w lewo i chwilę potem w lewo',
                'Skręć w lewo i chwilę potem w prawo',
                'Skręć w prawo i chwilę potem w lewo',
                'Skręć w prawo i chwilę potem w prawo',
                'Zjedź rampą',
                'Zjedź rampą na lewo',
                'Zjedź rampą na prawo',
                'Kontynuuj prosto',
                'Trzymaj się lewej strony',
                'Trzymaj się prawej strony',
                'Przekroczenie granicy',
                'Początek dnia podróży',
                'Koniec dnia podróży',
                'Bramki płatnej drogi',
                'Początek płatnej drogi',
                'Koniec płatnej drogi',
                'Początek czasu odpoczynku kierowcy',
                'Koniec czasu odpoczynku kierowcy',
                'Rozpoczęcie podróży promem',
                'Zakończenie podróży promem',
                'Konieczne uzupełnienie paliwa',
                'Początek drogi z mytem',
                'Koniec drogi z mytem',
                'Początek drogi' + ' viaTOLL',
                'Koniec drogi' + ' viaTOLL'
            ],
            currency:{
                'PLN': {
                    'value': 1,
                    'symbol': 'zł'
                },
                'EUR': {
                    'value': 1,
                    'symbol': '&euro;'
                },
                'USD': {
                    'value': 1,
                    'symbol': '$'
                },
                'GBP': {
                    'value': 1,
                    'symbol': '&pound;'
                }
            }
        }
        
        this.wrapper = React.createRef();
        
        
    }
    
    /**
    * componentDidMount()
    *
    * Funkcja 
    *
    */
    componentDidMount = () => {
        
        let defaultDate = new Date();
        let dd = defaultDate.getDate();
        let mm = defaultDate.getMonth() + 1; //January is 0!
        let yyyy = defaultDate.getFullYear();
        var h = defaultDate.getHours();
        var m = defaultDate.getMinutes();
        
        if(dd < 10)
            dd = '0' + dd;

        if(mm < 10)
            mm = '0' + mm;

        let newDate = yyyy + '-' + mm + '-' + dd + 'T' + h + ':' + m;
        this.setState({startRouteDateTime: newDate});
        
        this.loadVehicles(0);
    }
    
    /**
    * showLoading(what)
    *
    * Funkcja 
    *
    */
    showLoading = (what) => {
        this.setState({
            showLoading: what
        });
    }
    
    
    /**
    * doRoute()
    *
    * Funkcja 
    *
    */
    doRoute = () => {
        
        let routePlannerParamsOut = {
            // toll-roads are accepted while routing;
            "usePayRoads": (this.state.chbxPobOplat) ? 2 : 0,
            // viaTOLL roads are accepted while routing
            "useViaTollRoads": (this.state.chbxPobOplat) ? 2 : 0,
            //**// charged roads are accepted while routing
            //**//"useChargeRoads": RoutePRO.variable.useChargeRoads,

            // includes viaTOLL fees into route calculation summaries; defaults to false
            "includeViaTollCosts": this.state.chbxOplatyZliczaj,
            // appends viaTOLL fees to the LogisticTOLLResults table; defaults to false
            "appendViaTollCosts": this.state.chbxOplatyZliczaj,
            // includes european LogisticTOLL fees into route calculation summaries; defaults to false
            "includeLogisticTollCosts": this.state.chbxOplatyZliczaj,
            // includes MapBase stored fees into route calculation summaries; defaults to true
            "includeMapBaseCosts": this.state.chbxOplatyZliczaj,

            //"hazMatGeneralHazardousMaterials": RoutePRO.variable.hazMatGeneralHazardousMaterials,
            //"hazMatExplosiveMaterials": RoutePRO.variable.hazMatExplosiveMaterials,
            //"hazMatGoodsHarmfulToWater": RoutePRO.variable.hazMatGoodsHarmfulToWater,

            "currentCurrency": this.state.selectCurrency, // PLN | EUR
            "noTaxCosts": (this.state.selectTax === 1) ? true : false, // costs are returned without the tax
            //"avoidViaToll": RoutePRO.variable.avoidViaToll,
            "allowEnterToBlockedZone": this.state.chbxSpeedLimit
        }

        // Uwzględniaj restrykcje drogowe
        if(this.state.chbxRestrykcjeDrogowe){
            routePlannerParamsOut = {
                // toll-roads are accepted while routing;
                "usePayRoads": (this.state.chbxPobOplat) ? 2 : 0,
                // viaTOLL roads are accepted while routing
                "useViaTollRoads": (this.state.chbxPobOplat) ? 2 : 0,
                //**// charged roads are accepted while routing
                //**//"useChargeRoads": RoutePRO.variable.useChargeRoads,

                // includes viaTOLL fees into route calculation summaries; defaults to false
                "includeViaTollCosts": this.state.chbxOplatyZliczaj,
                // appends viaTOLL fees to the LogisticTOLLResults table; defaults to false
                "appendViaTollCosts": this.state.chbxOplatyZliczaj,
                // includes european LogisticTOLL fees into route calculation summaries; defaults to false
                "includeLogisticTollCosts": this.state.chbxOplatyZliczaj,
                // includes MapBase stored fees into route calculation summaries; defaults to true
                "includeMapBaseCosts": this.state.chbxOplatyZliczaj,

                //"hazMatGeneralHazardousMaterials": RoutePRO.variable.hazMatGeneralHazardousMaterials,
                //"hazMatExplosiveMaterials": RoutePRO.variable.hazMatExplosiveMaterials,
                //"hazMatGoodsHarmfulToWater": RoutePRO.variable.hazMatGoodsHarmfulToWater,

                "currentCurrency": this.state.selectCurrency, // PLN | EUR
                "noTaxCosts": (this.state.selectTax === 1) ? true : false, // costs are returned without the tax
                //"avoidViaToll": RoutePRO.variable.avoidViaToll,
                "allowEnterToBlockedZone": this.state.chbxSpeedLimit,
                //wymaga włączenia LogisticsAttributes w Subscribe providers
                allowLogisticsCancellation: true,
                hazMatExplosiveMaterials: 2,
                hazMatGeneralHazardousMaterials: 2,
                hazMatGoodsHarmfulToWater: 2,
                useMaxvehicleHeight: 2,
                useMaxVehicleLength: 2,
                useMaxVehicleWeight: 2,
                useMaxVehicleWeightPerAxle: 2,
                useMaxVehicleWidth: 2,

                //impassable roads are always omitted(even if target waypoint is set on that road)
                hardroadrestrictions: true,
                //waypoint snap points are placed on impassable roads too
                snaptoblockedlinks: false
            }
        }
        
        let routePoints = [];
        
        console.log(this.state.data);
        
        this.showLoading(true);
        
        this.state.data.map((item, index) => {
            if(item.text !== ''){
                routePoints.push({longitude: item.place.location.lng, latitude: item.place.location.lat});
            }
        });
        
        fetch('https://vrp-api-eu.emapa.pl/route', {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'ApiToken fec8f830-0332-4a40-ac65-9855177fe933'
            },
            body: JSON.stringify({
                "routePoints": routePoints,
                "routeCalculateType": this.state.selectRouteType,
                "routePlannerParams": routePlannerParamsOut,
                "roadParams": this.state.roadParams,
                "vehicleParams": this.state.vehicleTypeTemplate[this.state.selectCarType],
                "subscribeProviders": {
                    // Whether or not to use on-line difficulties supported by GDDKiA 
                    "subscriberAttributes": this.state.chbxGddkia, 
                    // Whether or not to use road restrictions - Restrykcje drogowe
                    "logisticsAttributes": this.state.chbxRestrykcjeDrogowe, 
                    // Whether or not to use toll fees in Europe of various kinds - Opłaty drogowe
                    "logisticTOLLAttributes": this.state.chbxOplatyZliczaj,
                    // Koszty dla podatku i waluty
                    "mapBaseAttributes": true
                },
            })
        }).then((response) => {
            console.log('roue calculator status code:', response.status);
            if(response.status !== 200) {
                console.log('Wystąpił problem z wczytaniem danych');
                return null;
            }
            return response.json();
        }).then((rco) => {
            
            console.log(rco);
                
            // Check operation code
            if(rco.operationStatus?.code !== 1){
                console.log('Nie znaleziono trasy');
            }
            else {
                // Draw route
                console.log('TRASA');
                
                this.showLoading(false);
                
                let dataOut = [];
                dataOut['routePoints'] = routePoints;
                dataOut['routeRepresentation'] = rco.routeRepresentation;
                this.props.drawPlaceCallback(dataOut);
                
                // Summary
                let distans = parseFloat(rco.totalRouteLength / 1000);
                let cost = parseFloat(rco.totalRouteCost);
                let time = rco.totalRouteTimeTs;
                let timeSec = rco.totalRouteTime * 86400; // Days to seconds;
                let timeOut = this.toHHMMSS(timeSec);
                timeOut = timeOut.slice(0,5);
                
                let startTime = new Date(this.state.startRouteDateTime);
                startTime = Date.parse(startTime);
                let routeStartTime = this.prepareDate(startTime);
                
                let timeRouteMilisec = parseInt(timeSec) * 1000;
                let stopTime = parseInt(timeRouteMilisec) + parseInt(startTime);
                let routeStopTime = this.prepareDate(stopTime);
                
                
                let firstIndex = 0;
                let lastIndex = this.state.data.length - 1;
                
                let routeStart = this.setStartEndRoute(this.state.data[firstIndex].place);
                let routeEnd = this.setStartEndRoute(this.state.data[lastIndex].place);
                
                // Show RouteCosts
                let segmentNum = rco.roadSegments.length;

                let fuelCost = 0;
                let vehicleHourCost = 0;
                let vehicleKilometerCost = 0;

                let roadTollCostTemp = 0;


                // Additional road cost - opłaty drogowe
                let additionalRoadCost = 0;

                for(let i = 0; i < segmentNum; i++){

                    for(let j = 0; j < 17; j++){

                        fuelCost += rco.roadSegments[i].roadResults[j].fuelCost;
                        vehicleHourCost += rco.roadSegments[i].roadResults[j].vehicleHourCost;
                        vehicleKilometerCost += rco.roadSegments[i].roadResults[j].vehicleKilometerCost;
                        
                    }

                    additionalRoadCost += rco.roadSegments[i].segmentAdditionalRouteCost;

                }

                for(let k = 0; k < 17; k++){         
                    roadTollCostTemp += rco.viaTollRoadResults[k].viaTollCost;
                }
                
                if(!this.state.chbxOplatyZliczaj){
                    additionalRoadCost = 0;
                }
                
                
                this.setState({
                    routeDistans: distans,
                    routeTime: timeOut,
                    routeCost: cost,
                    routeStartTime: routeStartTime,
                    routeStopTime: routeStopTime,
                    routeStart: routeStart,
                    routeEnd: routeEnd,
                    totalFuelCost: fuelCost,
                    totalTimeCost: vehicleHourCost,
                    totalRouteCost: vehicleKilometerCost,
                    logisticTollResults: rco.logisticTollResults,
                    additionalRoadCost: additionalRoadCost,
                    roadSegments: rco.roadSegments,
                    routeItineraries: rco.routeItineraries
                });
            }
            
        });
    }
    
    /**
    * loadVehicles(vehicleType)
    *
    * Funkcja 
    *
    */
    loadVehicles = async (vehicleType) => {
        
        const response = await fetch(`${DBurl}/routeProLoadVehicles?vehicletypeFrontEnd=` + vehicleType, {
            method: "GET",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": this.props.csrf_token
            }
        });
        const data = await response.json();
        
        if(data){
            
            let vehicleName = data.rows[0].vehiclename;
            let vehicleType = data.rows[0].vehicletype;
            let fuelCost = data.rows[0].fuelcost;
            let ignoreFuel = data.rows[0].ignorefuel;
            let vehicleAverageFuelConsumption = data.rows[0].vehicleaveragefuelconsumption;
            let vehicleWeight = data.rows[0].vehicleweight;
            let vehicleWeightPerAxle = data.rows[0].vehicleweightperaxle;
            let vehicleHeight = data.rows[0].vehicleheight;
            let vehicleWidth = data.rows[0].vehiclewidth;
            let vehicleLength = data.rows[0].vehiclelength;
            let tankCapacity = data.rows[0].tankcapacity;
            let vehicleEmission = data.rows[0].vehicleemission;
            let costPerKilometer = data.rows[0].costperkilometer;
            let costPerHour = data.rows[0].costperhour;
            let vehicleAxles = data.rows[0].vehicleaxles;
            let vehicleDpf = data.rows[0].vehicledpf;
            
           
            this.state.vehicleTypeTemplate[vehicleType] = {
                "vehicleName": vehicleName,
                "vehicleType": parseInt(vehicleType),
                "fuelCost": this.getCost(fuelCost),
                "ignoreFuel": (ignoreFuel == 't') ? true : false,
                "vehicleAverageFuelConsumption": parseFloat(vehicleAverageFuelConsumption),
                "vehicleWeight": parseFloat(vehicleWeight),
                "vehicleWeightPerAxle": parseFloat(vehicleWeightPerAxle),
                "vehicleHeight": parseFloat(vehicleHeight),
                "vehicleWidth": parseFloat(vehicleWidth),
                "vehicleLength": parseFloat(vehicleLength),
                "tankCapacity": parseFloat(tankCapacity),
                "vehicleEmission": parseInt(vehicleEmission),
                "costPerKilometer": this.getCost(costPerKilometer),
                "costPerHour": this.getCost(costPerHour),
                "vehicleAxles": parseInt(vehicleAxles),
                "vehicleDpf": (vehicleDpf == 't') ? true : false
            }
            
            
            let highway_use = data.rows[0].highway_use;
            let highway_speed = data.rows[0].highway_speed;
            let highway_combustion = data.rows[0].highway_combustion;

            let speedway_use = data.rows[0].speedway_use;
            let speedway_speed = data.rows[0].speedway_speed;
            let speedway_combustion = data.rows[0].speedway_combustion;

            let nationalroad_use = data.rows[0].nationalroad_use;
            let nationalroad_speed = data.rows[0].nationalroad_speed;
            let nationalroad_combustion = data.rows[0].nationalroad_combustion;

            let regionalroad_use = data.rows[0].regionalroad_use;
            let regionalroad_speed = data.rows[0].regionalroad_speed;
            let regionalroad_combustion = data.rows[0].regionalroad_combustion;

            let localroad_use = data.rows[0].localroad_use;
            let localroad_speed = data.rows[0].localroad_speed;
            let localroad_combustion = data.rows[0].localroad_combustion;

            let otherroad_use = data.rows[0].otherroad_use;
            let otherroad_speed = data.rows[0].otherroad_speed;
            let otherroad_combustion = data.rows[0].otherroad_combustion;

            let unpavedroad_use = data.rows[0].unpavedroad_use;
            let unpavedroad_speed = data.rows[0].unpavedroad_speed;
            let unpavedroad_combustion = data.rows[0].unpavedroad_combustion;

            let vehicularstreet_use = data.rows[0].vehicularstreet_use;
            let vehicularstreet_speed = data.rows[0].vehicularstreet_speed;
            let vehicularstreet_combustion = data.rows[0].vehicularstreet_combustion;

            let mainstreet_use = data.rows[0].mainstreet_use;
            let mainstreet_speed = data.rows[0].mainstreet_speed;
            let mainstreet_combustion = data.rows[0].mainstreet_combustion;

            let otherstreet_use = data.rows[0].otherstreet_use;
            let otherstreet_speed = data.rows[0].otherstreet_speed;
            let otherstreet_combustion = data.rows[0].otherstreet_combustion;

            let ferry_use = data.rows[0].ferry_use;
            let ferry_speed = data.rows[0].ferry_speed;
            let ferry_combustion = data.rows[0].ferry_combustion;
            
            this.state.roadParams = [
                {
                    "roadType": "Highway",
                    "use": (highway_use == 't') ? true : false,
                    "speed": parseInt(highway_speed),
                    "combustion": parseInt(highway_combustion)
                },
                {
                    "roadType": "Speedway",
                    "use": (speedway_use == 't') ? true : false,
                    "speed": parseInt(speedway_speed),
                    "combustion": parseInt(speedway_combustion)
                },
                {
                    "roadType": "NationalRoad",
                    "use": (nationalroad_use == 't') ? true : false,
                    "speed": parseInt(nationalroad_speed),
                    "combustion": parseInt(nationalroad_combustion)
                },
                {
                    "roadType": "RegionalRoad",
                    "use": (regionalroad_use == 't') ? true : false,
                    "speed": parseInt(regionalroad_speed),
                    "combustion": parseInt(regionalroad_combustion)
                },
                {
                    "roadType": "LocalRoad",
                    "use": (localroad_use == 't') ? true : false,
                    "speed": parseInt(localroad_speed),
                    "combustion": parseInt(localroad_combustion)
                },
                {
                    "roadType": "OtherRoad",
                    "use": (otherroad_use == 't') ? true : false,
                    "speed": parseInt(otherroad_speed),
                    "combustion": parseInt(otherroad_combustion)
                },
                {
                    "roadType": "UnpavedRoad",
                    "use": (unpavedroad_use == 't') ? true : false,
                    "speed": parseInt(unpavedroad_speed),
                    "combustion": parseInt(unpavedroad_combustion)
                },
                {
                    "roadType": "VehicularStreet",
                    "use": (vehicularstreet_use == 't') ? true : false,
                    "speed": parseInt(vehicularstreet_speed),
                    "combustion": parseInt(vehicularstreet_combustion)
                },
                {
                    "roadType": "MainStreet",
                    "use": (mainstreet_use == 't') ? true : false,
                    "speed": parseInt(mainstreet_speed),
                    "combustion": parseInt(mainstreet_combustion)
                },
                {
                    "roadType": "OtherStreet",
                    "use": (otherstreet_use == 't') ? true : false,
                    "speed": parseInt(otherstreet_speed),
                    "combustion": parseInt(otherstreet_combustion)
                },
                {
                    "roadType": "Ferry",
                    "use": (ferry_use == 't') ? true : false,
                    "speed": parseInt(ferry_speed),
                    "combustion": parseInt(ferry_combustion)
                }
            ];
            
            
            // Set viaTollParams 
            // Pojazdy samochodowe o dopuszczalnej masie całkowitej nieprzekraczajacej 3,5 tony
            if(vehicleType == '0' || vehicleType == '5'){

                var viaTollCostHighway = 0;//0.1;
                var viaTollCostSpeedway = 0;
                var viaTollCostNationalRoad = 0;
                var viaTollCostRegionalRoad = 0;
                var viaTollCostLocalRoad = 0;
                var viaTollCostOtherRoad = 0;
                var viaTollCostUnpavedRoad = 0;
                var viaTollCostVehicularStreet = 0;
                var viaTollCostMainStreet = 0;
                var viaTollCostOtherStreet = 0;
                var viaTollCostFerry = 0;

            }
            // Pojazdy samochodowe o dopuszczalnej masie całkowitej (2) powyżej 3,5 t i poniżej 12 t
            else if(vehicleType == '9'){

                // Klasa emisji spalin EURO 2-5
                if(parseInt(vehicleEmission) <= 2){

                    var viaTollCostHighway = 0.40;
                    var viaTollCostSpeedway = 0.40;
                    var viaTollCostNationalRoad = 0.32;
                    var viaTollCostRegionalRoad = 0.32;
                    var viaTollCostLocalRoad = 0;
                    var viaTollCostOtherRoad = 0;
                    var viaTollCostUnpavedRoad = 0;
                    var viaTollCostVehicularStreet = 0;
                    var viaTollCostMainStreet = 0;
                    var viaTollCostOtherStreet = 0;
                    var viaTollCostFerry = 0;

                }
                else if(parseInt(vehicleEmission) == 3){

                    var viaTollCostHighway = 0.35;
                    var viaTollCostSpeedway = 0.35;
                    var viaTollCostNationalRoad = 0.28;
                    var viaTollCostRegionalRoad = 0.28;
                    var viaTollCostLocalRoad = 0;
                    var viaTollCostOtherRoad = 0;
                    var viaTollCostUnpavedRoad = 0;
                    var viaTollCostVehicularStreet = 0;
                    var viaTollCostMainStreet = 0;
                    var viaTollCostOtherStreet = 0;
                    var viaTollCostFerry = 0;

                }
                else if(parseInt(vehicleEmission) == 4){

                    var viaTollCostHighway = 0.28;
                    var viaTollCostSpeedway = 0.28;
                    var viaTollCostNationalRoad = 0.22;
                    var viaTollCostRegionalRoad = 0.22;
                    var viaTollCostLocalRoad = 0;
                    var viaTollCostOtherRoad = 0;
                    var viaTollCostUnpavedRoad = 0;
                    var viaTollCostVehicularStreet = 0;
                    var viaTollCostMainStreet = 0;
                    var viaTollCostOtherStreet = 0;
                    var viaTollCostFerry = 0;

                }
                else if(parseInt(vehicleEmission) >= 5){

                    var viaTollCostHighway = 0.20;
                    var viaTollCostSpeedway = 0.20;
                    var viaTollCostNationalRoad = 0.16;
                    var viaTollCostRegionalRoad = 0.16;
                    var viaTollCostLocalRoad = 0;
                    var viaTollCostOtherRoad = 0;
                    var viaTollCostUnpavedRoad = 0;
                    var viaTollCostVehicularStreet = 0;
                    var viaTollCostMainStreet = 0;
                    var viaTollCostOtherStreet = 0;
                    var viaTollCostFerry = 0;

                }


            }
            // Pojazdy samochodowe o dopuszczalnej masie całkowitej (2) co najmniej 12 t 
            else if(vehicleType == '1'){

                // Klasa emisji spalin EURO 2-5
                if(parseInt(vehicleEmission) <= 2){

                    var viaTollCostHighway = 0.53;
                    var viaTollCostSpeedway = 0.53;
                    var viaTollCostNationalRoad = 0.42;
                    var viaTollCostRegionalRoad = 0.42;
                    var viaTollCostLocalRoad = 0;
                    var viaTollCostOtherRoad = 0;
                    var viaTollCostUnpavedRoad = 0;
                    var viaTollCostVehicularStreet = 0;
                    var viaTollCostMainStreet = 0;
                    var viaTollCostOtherStreet = 0;
                    var viaTollCostFerry = 0;

                }
                else if(parseInt(vehicleEmission) == 3){

                    var viaTollCostHighway = 0.46;
                    var viaTollCostSpeedway = 0.46;
                    var viaTollCostNationalRoad = 0.37;
                    var viaTollCostRegionalRoad = 0.37;
                    var viaTollCostLocalRoad = 0;
                    var viaTollCostOtherRoad = 0;
                    var viaTollCostUnpavedRoad = 0;
                    var viaTollCostVehicularStreet = 0;
                    var viaTollCostMainStreet = 0;
                    var viaTollCostOtherStreet = 0;
                    var viaTollCostFerry = 0;

                }
                else if(parseInt(vehicleEmission) == 4){

                    var viaTollCostHighway = 0.37;
                    var viaTollCostSpeedway = 0.37;
                    var viaTollCostNationalRoad = 0.29;
                    var viaTollCostRegionalRoad = 0.29;
                    var viaTollCostLocalRoad = 0;
                    var viaTollCostOtherRoad = 0;
                    var viaTollCostUnpavedRoad = 0;
                    var viaTollCostVehicularStreet = 0;
                    var viaTollCostMainStreet = 0;
                    var viaTollCostOtherStreet = 0;
                    var viaTollCostFerry = 0;

                }
                else if(parseInt(vehicleEmission) >= 5){

                    var viaTollCostHighway = 0.27;
                    var viaTollCostSpeedway = 0.27;
                    var viaTollCostNationalRoad = 0.21;
                    var viaTollCostRegionalRoad = 0.21;
                    var viaTollCostLocalRoad = 0;
                    var viaTollCostOtherRoad = 0;
                    var viaTollCostUnpavedRoad = 0;
                    var viaTollCostVehicularStreet = 0;
                    var viaTollCostMainStreet = 0;
                    var viaTollCostOtherStreet = 0;
                    var viaTollCostFerry = 0;

                }

            }
            
            // FIX: Skype z Pawłem z 23.03.2021
            this.state.viaTollParams = [
                {
                    "roadType": "Highway",
                    //"viaTollCost": RoutePRO.getCost(viaTollCostHighway)
                    "viaTollCost": parseFloat(viaTollCostHighway)
                },
                {
                    "roadType": "Speedway",
                    //"viaTollCost": RoutePRO.getCost(viaTollCostSpeedway)
                    "viaTollCost": parseFloat(viaTollCostSpeedway)
                },
                {
                    "roadType": "NationalRoad",
                    //"viaTollCost": RoutePRO.getCost(viaTollCostNationalRoad)
                    "viaTollCost": parseFloat(viaTollCostNationalRoad)
                },
                {
                    "roadType": "RegionalRoad",
                    //"viaTollCost": RoutePRO.getCost(viaTollCostRegionalRoad)
                    "viaTollCost": parseFloat(viaTollCostRegionalRoad)
                }, 
                {
                    "roadType": "LocalRoad",
                    //"viaTollCost": RoutePRO.getCost(viaTollCostLocalRoad)
                    "viaTollCost": parseFloat(viaTollCostLocalRoad)
                }, 
                {
                    "roadType": "OtherRoad",
                    //"viaTollCost": RoutePRO.getCost(viaTollCostOtherRoad)
                    "viaTollCost": parseFloat(viaTollCostOtherRoad)
                }, 
                {
                    "roadType": "UnpavedRoad",
                    //"viaTollCost": RoutePRO.getCost(viaTollCostUnpavedRoad)
                    "viaTollCost": parseFloat(viaTollCostUnpavedRoad)
                }, 
                {
                    "roadType": "VehicularStreet",
                    //"viaTollCost": RoutePRO.getCost(viaTollCostVehicularStreet)
                    "viaTollCost": parseFloat(viaTollCostVehicularStreet)
                }, 
                {
                    "roadType": "MainStreet",
                    //"viaTollCost": RoutePRO.getCost(viaTollCostMainStreet)
                    "viaTollCost": parseFloat(viaTollCostMainStreet)
                }, 
                {
                    "roadType": "OtherStreet",
                    //"viaTollCost": RoutePRO.getCost(viaTollCostOtherStreet)
                    "viaTollCost": parseFloat(viaTollCostOtherStreet)
                }, 
                {
                    "roadType": "Ferry",
                    //"viaTollCost": RoutePRO.getCost(viaTollCostFerry)
                    "viaTollCost": parseFloat(viaTollCostFerry)
                }
            ];
            
        }
    }
    
    /**
    * getCost(value)
    *
    * Funkcja
    *
    */
    getCost = (value) => {

        var returnCost = 0;

        if(parseFloat(value) > 0){

            // Get currency
            returnCost = this.getCurrency(value);

            // Get tax
            returnCost = this.getTax(returnCost);

        }

        return returnCost;

    }

    /**
    * getCurrency(value)
    *
    * Funkcja
    *
    */
    getCurrency = (value) => {

        var returnValue = 0;

        if(parseFloat(value) > 0){

            returnValue = parseFloat(value) / parseFloat(this.state.currency[this.state.selectCurrency].value);

        }

        return returnValue;
    }

    /**
    * getTax(value)
    *
    * Funkcja
    *
    */
    getTax = (value) => {

        var vat = 23;
        var isNetto = (this.state.selectTax == '0') ? true : false;
        var returnValue = 0;

        if(parseFloat(value) > 0){
            // Netto
            if(isNetto){
                var returnValue = value - value * vat / 100;
            }
            // Brutto
            else{
                var returnValue = value;
            }
        }

        return returnValue;

    }
    
    
    /**
    * prepareDate(milisek)
    *
    * Funkcja 
    *
    */
    prepareDate = (milisek) => {

        let datetime = new Date(milisek);


        let dd = datetime.getDate();
        let mm = datetime.getMonth() + 1; //January is 0!
        let yyyy = datetime.getFullYear();

        let h = datetime.getHours();
        if(parseInt(h) < 10) h = '0' + h;
        let m = datetime.getMinutes();
        if(parseInt(m) < 10) m = '0' + m;
        let s = datetime.getSeconds();
        if(parseInt(s) < 10) s = '0' + s;

        datetime = h + ' : ' + m + ' | ' + dd + '-' + mm + '-' + yyyy;
        
        return datetime;
    }
    
    /**
    * toHHMMSS()
    *
    * Funkcja 
    *
    */
    toHHMMSS = (sec) => {
        var sec_num = parseInt(sec, 10); // don't forget the second param
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);
        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        if (seconds < 10) {
            seconds = "0" + seconds;
        }
        var time = hours + ':' + minutes + ':' + seconds;
        
        return time;
    }
    
    /**
    * setStartEndRoute(place)
    *
    * Funkcja 
    *
    */
    setStartEndRoute = (place) => {
        let city = place.city;
        let street = place.street;
        let houseNo = place.houseNo;
        let postCode = place.postCode;
        let all = '';

        if(street != '' && houseNo != ''){
            let all = street + ' ' + houseNo + ',<br>' + postCode + ' ' + city;
        }
        else if(postCode != ''){
            let all = postCode + ' ' + city;
        }
        else{
            let all = city;
        }

        let html = city +  all;
        
        return html;
    }
    
    
    /**
    * clearRoutePro()
    *
    * Funkcja 
    *
    */
    clearRoutePro = () => {
        
        
        
    }
    
    /**
    * onChangeChbx(event)
    *
    * Funkcja 
    *
    */
    onChangeChbx = (event) => {
        this.setState({ ...this.state, [event.target.name]: event.target.checked });
    }
    
    /**
    * onChangeSelect(event)
    *
    * Funkcja 
    *
    */
    onChangeSelect = (event) => {
        this.setState({ ...this.state, [event.target.name]: event.target.value });
        
        if(event.target.name === 'selectCurrency'){
            this.getCurrencyApi(event.target.value);
        }
        
        this.loadVehicles(this.state.selectCarType);
    }
    
    /**
    * getCurrencyApi(code)
    *
    * Funkcja 
    *
    */
    getCurrencyApi = async (code) => {
        
        const response = await fetch(`${DBurl}/getCurrency?code=` + code, {
            method: "GET",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": this.props.csrf_token
            }
        });
        const data = await response.json();
        
        if(data){
            var stateCopy = Object.assign({}, this.state);
            stateCopy.currency[code] = Object.assign({}, stateCopy.currency[code]);
            stateCopy.currency[code].value = data[0].value;
            this.setState(stateCopy);
        }
        
        console.log(this.state.currency);
    }
    
    
    /**
    * onDragEnd(fromIndex, toIndex)
    *
    * Funkcja 
    *
    */
    onDragEnd = (fromIndex, toIndex) => {
        const data = [...this.state.data];
        const item = data.splice(fromIndex, 1)[0];
        data.splice(toIndex, 0, item);
        this.setState({ data });
        
        console.log(this.state.data);
        
        setTimeout(() => {
            // Add / refresh marker to map
            let dataOut = this.prepareData();
            this.props.addPointCallback(dataOut);
        }, 100);
    }
    
    /**
    * onCloseAutocomplete()
    *
    * Funkcja 
    *
    */
    onCloseAutocomplete = () => {
        this.setState({optionsAC: []});
    }
    
    /**
    * onChangeAutocomplete(event, selected)
    *
    * Funkcja 
    *
    */
    onChangeAutocomplete = (event, selected, title) => {
        console.log('SELECT OPTIONS onChangeAutocomplete');
        console.log(event);
        console.log(selected);
        console.log(title);
        console.log('A');
        
        this.setState({
            optionsAC: []
        });
        
        if(selected !== null){
            this.state.data.forEach((data, index) => {
                if(data.title === title){

                    this.setState(state => ({

                        data: [
                            ...state.data.slice(0,index), 
                            {
                                ...state.data[index],
                                text: selected.label,
                                place: selected.place
                            }, 
                            ...state.data.slice(index + 1)
                        ]

                    }));

                    

                }
            });
        }
        
        if(this.state.timeout){
            clearTimeout(this.state.timeout);
        }
        
        setTimeout(() => {
            // Add / refresh marker to map
            let dataOut = this.prepareData();
            this.props.addPointCallback(dataOut);
        }, 100);
        
        
    }
   
    
    /**
    * onClickInput(event, title)
    *
    * Funkcja 
    *
    */
    onClickInput = (event, title) => {
        
        this.setState({optionsAC: []});
        
        this.state.data.forEach((data, index) => {
            if(data.title === title){
                
                this.setState(state => ({
                        
                        data: [
                            ...state.data.slice(0,index), 
                            {
                                ...state.data[index],
                                text: '',
                                place: null
                            }, 
                            ...state.data.slice(index + 1)
                        ]
                        
                    }));
                
            }
        });
        
        
        
    }
    
    
    /**
    * onInputChangeAutocomplete(event, newInputValue, title)
    *
    * Funkcja 
    *
    */
    onInputChangeAutocomplete = (event, newInputValue, title) => {
        console.log('onInputChangeAutocomplete');
        console.log(newInputValue);
        
        console.log('B');
        
        this.setState({optionsAC: []});
        
        this.state.data.forEach((data, index) => {
            if(data.title === title){
                
                this.setState(state => ({
                        
                        data: [
                            ...state.data.slice(0,index), 
                            {
                                ...state.data[index],
                                text: newInputValue
                            }, 
                            ...state.data.slice(index + 1)
                        ]
                        
                    }));
                
            }
        });
        
        
        if(newInputValue !== '' && newInputValue.length > 1 && newInputValue.length < 20){
            
            //this.autocompleteLoadCarsToMap();
            
            this.showLoading(true);
            
            // Debounce
            if(this.state.timeout){
                clearTimeout(this.state.timeout);
            }
            let timeout = setTimeout(() => {
                this.autocompleteEMapa(newInputValue);
            }, 1000);
            this.setState({timeout: timeout});
            
            
            
        }
        
    }
    
    /**
    * autocompleteEMapa()
    *
    * Funkcja 
    *
    */
    autocompleteEMapa = async (term) => {
        
        //await sleep(3000);
        const response = await fetch('https://monitoring-api-eu.emapa.pl/search?searchphrase=' + term + '&outputLangCode=POL&excludeHighLvlAdmLayers=true&ApiToken=a2bdc868-5e3d-4c03-ae68-f3e64f18add3', {
            method: "GET",
            headers: {
                    "Content-Type": "application/json"
            }
        });
        const json = await response.json();
        
        this.showLoading(false);
        
        console.log(json);
        
        this.setState({
            optionsAC: []
        });
        

        json.forEach((item, index) => {
            
            let street = (item.street !== undefined && item.street !== '') ? item.street : '';
            let houseNo = (item.houseNo !== undefined && item.houseNo !== '') ? item.houseNo : '';
            let postCode = (item.postCode !== undefined) ? item.postCode : '';
            let city = item.city;

            if(street !== '' && houseNo !== ''){
                houseNo = ' ' + houseNo;
            }
            if(street !== '' || houseNo !== ''){
                postCode = ', ' + postCode;
            }
            if(street !== '' || houseNo !== '' || postCode !== ''){
                city = ', ' + city;
            }

            let label = street + houseNo + postCode + city + ', ' + item.admLvl0 + ', ' + item.admLvl1;
            
            let lng = item.geoLocation.longitude;
            let lat = item.geoLocation.latitude;


            let place = {
                location: { lat : parseFloat(lat), lng : parseFloat(lng) },
                name: label,
                city: city,
                street: street,
                houseNo: houseNo,
                postCode: postCode
            }
            
            this.setState({optionsAC: [
                    ...this.state.optionsAC, 
                    {
                        label: label, 
                        id: index,
                        place: place
                    }
                ]
            });
            
        });
        
    }
    
    /**
    * autocompleteLoadCarsToMap()
    *
    * Funkcja 
    *
    */
    autocompleteLoadCarsToMap = async () => {
        
        const response = await fetch(`${DBurl}/loadCarsToMap`, {
            method: "GET",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": this.props.csrf_token
            }
        });

        const json = await response.json();

        this.setState({optionsAC: []});

        json.forEach((car, index) => {
            this.setState({optionsAC: [
                    ...this.state.optionsAC, 
                    {
                        label: car.name, 
                        id: car.id
                    }
                ]
            });
        });
        
    }
    
    /**
    * onHandleChangeTab()
    *
    * Funkcja 
    *
    */
    onHandleChangeTab = (event, newValue) => {
        this.setState({tabValue: newValue});
    }

    /**
    * a11yProps(index)
    *
    * Funkcja 
    *
    */
    a11yProps = (index) => {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }
    
    /**
    * removeMiddlePoint(index)
    *
    * Funkcja 
    *
    */
    removeMiddlePoint = (index) => {
        const data = this.state.data;
        data.splice(index,1);
        
        this.setState({
            data: data
        });
        
        console.log(this.state.data);
        
        setTimeout(() => {
            // Add / refresh marker to map
            let dataOut = this.prepareData();
            this.props.addPointCallback(dataOut);
        }, 100);
    }
    
    /**
    * addMiddlePoint()
    *
    * Funkcja 
    *
    */
    addMiddlePoint = () => {
        
        const data = this.state.data;
        const newItem = {
            title: `rows${data.length+1}`,
            text: ''
        }
        data.splice(-1, 0, newItem);
        
        this.setState({
            data: data,
            optionsAC: []
        });
        
        console.log(this.state.data);
    }
    
    
    
    /**
    * drawPlace()
    *
    * Funkcja 
    *
    */
    drawPlace = () => {
        let dataOut = this.prepareData();
        this.props.drawPlaceCallback(dataOut);
    }
    
    /**
    * prepareData()
    *
    * Funkcja 
    *
    */
    prepareData = () => {
        
        let data = this.state.data;
        let dataOut = [];
        
        // Prepare
        data.map((row, index) => {
            if(row.text !== ''){
                dataOut.push(row);
            }
        });
        
        return dataOut;
    }
    
    /**
    * redrawPoints()
    *
    * Funkcja 
    *
    */
    redrawPoints = () => {
        return 
        {this.state.data.map((item, index) => 
            <li key={index}>
                <a href="#">Drag</a>{item.title} <Button onClick={() => this.removeMiddlePoint(index)}>x</Button>
            </li>
        )}
        
    }
    
    test = () => {
        return this.redrawPoints2;
    }
    
    /**
    * redrawPoints2(item, index)
    *
    * Funkcja 
    *
    */
    redrawPoints2 = () => {
        this.state.data.map((item, index) => {
            return <li>test</li>
        })
    }
    
    /**
    * render()
    *
    * Funkcja 
    *
    */
    render = () => {
        
        
        return(
            <Draggable
                nodeRef={this.wrapper}
                handle=".handleRoutePro"
                defaultPosition={{x: 500, y: 50}}
            >
                <div ref={this.wrapper}>

                    <div className={styles.mpContainer}>

                        <div className={`${styles.mpHeader} handleRoutePro`} id="routePROHeader_popup">
                            <div className={styles.mpHeaderTitle}>Planowanie PRO</div>
                            <div className={styles.mpHeaderClose} onClick={this.props.onClickCloseRoutePro} id="routePRO_closeBtn">
                                <img src={mpClose} style={{width: 10 + 'px'}} />
                            </div>
                        </div>

                        <AppBar position="static" color="default">
                            <Tabs value={this.state.tabValue} onChange={this.onHandleChangeTab} aria-label="full width tabs example" className={styles.tabs}>
                                <Tab label="Punkty trasy" {...this.a11yProps(0)} className={styles.tabLink} classes={{ selected: styles.tabSelected, root: styles.tabRoot }}/>
                                <Tab label="Konfiguracja" {...this.a11yProps(1)} className={styles.tabLink} classes={{ selected: styles.tabSelected, root: styles.tabRoot }}/>
                                <Tab label="Podsumowanie" {...this.a11yProps(2)} className={styles.tabLink} classes={{ selected: styles.tabSelected, root: styles.tabRoot }}/>
                            </Tabs>
                        </AppBar>


                        <TabPanel value={this.state.tabValue} index={0}>

                                <ReactDragListView
                                    nodeSelector="li"
                                    handleSelector="div"
                                    onDragEnd={this.onDragEnd}
                                >
                                
                                <ul>
                                {this.state.data.map((item, index) => 
                                    { return index === 0 ?
                                    <RouteListPointStart
                                        key={index}
                                        item={item}
                                        index={index}
                                        focusOnPoint={() => this.props.focusOnPointCallback(item)}
                                        optionsAC={this.state.optionsAC}
                                        onChangeAutocomplete={this.onChangeAutocomplete}
                                        onInputChangeAutocomplete={this.onInputChangeAutocomplete}
                                        onCloseAutocomplete={this.onCloseAutocomplete}
                                        onClickInput={this.onClickInput}
                                    />
                                    :
                                    index === this.state.data.length-1 ?
                                    <RouteListPointStop
                                        key={index}
                                        item={item}
                                        index={index}
                                        focusOnPoint={() => this.props.focusOnPointCallback(item)}
                                        addMiddlePoint={this.addMiddlePoint}
                                        optionsAC={this.state.optionsAC}
                                        onChangeAutocomplete={this.onChangeAutocomplete}
                                        onInputChangeAutocomplete={this.onInputChangeAutocomplete}
                                        onCloseAutocomplete={this.onCloseAutocomplete}
                                        onClickInput={this.onClickInput}
                                    />
                                    :
                                    <RouteListPointMiddle
                                        key={index}
                                        item={item}
                                        index={index}
                                        removeMiddlePoint={() => this.removeMiddlePoint(index)}
                                        focusOnPoint={() => this.props.focusOnPointCallback(item)}
                                        optionsAC={this.state.optionsAC}
                                        onChangeAutocomplete={this.onChangeAutocomplete}
                                        onInputChangeAutocomplete={this.onInputChangeAutocomplete}
                                        onCloseAutocomplete={this.onCloseAutocomplete}
                                        onClickInput={this.onClickInput}
                                    />
                                    }
                                    
                                )}
                                </ul>
                                
                              </ReactDragListView>


                        </TabPanel>

                        <TabPanel value={this.state.tabValue} index={1}>
                            
                            <FormControl variant="outlined">
                                <InputLabel id="selectCarType">Rodzaj pojazdu</InputLabel>
                                <Select
                                  labelId="selectCarType"
                                  label="Rodzaj pojazdu"
                                  className={styles.select}
                                  value={this.state.selectCarType}
                                  onChange={this.onChangeSelect}
                                  name="selectCarType"
                                >
                                    <MenuItem value={0} className={styles.option}><img src={car} width="29" alt="Osobowy" className={styles.mr10}/>Osobowy</MenuItem>
                                    <MenuItem value={5} className={styles.option}><img src={smalltruck} width="29" alt="Dostawczy" className={styles.mr10}/>Dostawczy</MenuItem>
                                    <MenuItem value={9} className={styles.option}><img src={bigtruck} width="29"  alt="Ciężarowy" className={styles.mr10}/>Ciężarowy</MenuItem>
                                    <MenuItem value={1} className={styles.option}><img src={tir} width="29" alt="TIR" className={styles.mr10}/>TIR</MenuItem>
                                </Select>
                            </FormControl>
                            
                            <FormControl variant="outlined" style={{marginTop: 20}}>
                                <InputLabel id="selectRouteType">Rodzaj trasy</InputLabel>
                                <Select
                                  labelId="selectRouteType"
                                  label="Rodzaj trasy"
                                  className={styles.select}
                                  value={this.state.selectRouteType}
                                  onChange={this.onChangeSelect}
                                  name="selectRouteType"
                                >
                                    <MenuItem value={1} className={styles.option} >
                                        <img src={fast} width="29" alt="Szybka" className={styles.mr10}/>
                                        Szybka
                                        </MenuItem>
                                    <MenuItem value={0} className={styles.option} >
                                        <img src={short} width="29" alt="Krótka" className={styles.mr10}/>
                                        Krótka
                                        </MenuItem>
                                    <MenuItem value={2} className={styles.option} >
                                        <img src={eco} width="29" alt="Ekonomiczna" className={styles.mr10}/>
                                        Ekonomiczna
                                        </MenuItem>
                                </Select>
                            </FormControl>
                            
                            <form noValidate style={{marginTop: 20}}>
                                <TextField
                                  variant="outlined"
                                  className={styles.timePicker}
                                  id="datetime-local"
                                  label="Rozpoczęcie trasy"
                                  type="datetime-local"
                                  defaultValue={this.state.startRouteDateTime}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                            </form>
                            
                            <FormControlLabel
                                className={styles.ctrlLabel}
                                control={
                                  <Checkbox
                                    checked={this.state.chbxGddkia}
                                    onChange={this.onChangeChbx}
                                    name="chbxGddkia"
                                    color="primary"
                                  />
                                }
                                label={<div className={styles.labelCheckbox} >
                                    <img src={this.state.chbxGddkia ? gddkiaB : gddkiaG } width="35" alt="Ekonomiczna" className={styles.mr10}/>uwzględniaj utrudnienia GDDKiA
                                    </div>}
                            />


                             
                            <FormControlLabel
                                className={styles.ctrlLabel}
                                control={
                                  <Checkbox
                                    checked={this.state.chbxPobOplat}
                                    onChange={this.onChangeChbx}
                                    name="chbxPobOplat"
                                    color="primary"
                                  />
                                }
                                label={<div className={styles.labelCheckbox} >
                                <img src={this.state.chbxPobOplat ? platneB : platneG } width="35" alt="Ekonomiczna" className={styles.mr10}/>omijaj drogi z poborem opłat
                                </div>}

                            />
                            
                            <FormControlLabel
                                className={styles.ctrlLabel}
                                control={
                                  <Checkbox
                                    checked={this.state.chbxRestrykcjeDrogowe}
                                    onChange={this.onChangeChbx}
                                    name="chbxRestrykcjeDrogowe"
                                    color="primary"
                                  />
                                }
                                label={<div className={styles.labelCheckbox} >
                                <img src={this.state.chbxRestrykcjeDrogowe ? restrictB : restrictG } width="35" alt="Ekonomiczna" className={styles.mr10}/>uwzględniaj restrykcje drogowe
                                </div>}
                            />
                        

                            <FormControlLabel
                                className={styles.ctrlLabel}
                                control={
                                  <Checkbox
                                    checked={this.state.chbxSpeedLimit}
                                    onChange={this.onChangeChbx}
                                    name="chbxSpeedLimit"
                                    color="primary"
                                  />
                                }
                                label={<div className={styles.labelCheckbox} >
                                <img src={this.state.chbxSpeedLimit ? limitB : limitG } width="35" alt="wymuś dojazd do punktu docelowego" className={styles.mr10}/>wymuś dojazd do punktu docelowego
                                </div>}
                            />
                            
                            <FormControlLabel
                                className={styles.ctrlLabel}
                                control={
                                  <Checkbox
                                    checked={this.state.chbxOplatyZliczaj}
                                    onChange={this.onChangeChbx}
                                    name="chbxOplatyZliczaj"
                                    color="primary"
                                  />
                                }
                                label={<div className={styles.labelCheckbox} >
                                <img src={this.state.chbxOplatyZliczaj ? countingB : countingG } width="35" alt="zliczaj opłaty drogowe" className={styles.mr10}/>zliczaj opłaty drogowe
                                </div>}
    
                            />
                            
                            <FormControlLabel
                                className={styles.ctrlLabel}
                                control={
                                  <Checkbox
                                    checked={this.state.chbxOptymalizujWszystkiePunkty}
                                    onChange={this.onChangeChbx}
                                    name="chbxOptymalizujWszystkiePunkty"
                                    color="primary"
                                  />
                                }
                                label="optymalizuj wszystkie punkty"
                            />
                            
                            <FormControl variant="outlined" className={styles.selectHalfParent}>
                                <InputLabel id="selectCurrency">Waluta</InputLabel>
                                <Select
                                  labelId="selectCurrency"
                                  label="Waluta"
                                  className={styles.selectHalf}
                                  value={this.state.selectCurrency}
                                  onChange={this.onChangeSelect}
                                  name="selectCurrency"
                                >
                                    <MenuItem value={'PLN'}>PLN</MenuItem>
                                    <MenuItem value={'EUR'}>EURO</MenuItem>
                                    <MenuItem value={'USD'}>US-DOLLAR</MenuItem>
                                    <MenuItem value={'GBP'}>GB-FUNT</MenuItem>
                                </Select>
                            </FormControl>
                            
                            <FormControl variant="outlined" className={styles.selectHalfParentLeft}>
                                <InputLabel id="selectTax">Podatek</InputLabel>
                                <Select
                                  labelId="selectTax"
                                  label="Waluta"
                                  className={styles.selectHalf}
                                  value={this.state.selectTax}
                                  onChange={this.onChangeSelect}
                                  name="selectTax"
                                >
                                    <MenuItem value={1}>Brutto</MenuItem>
                                    <MenuItem value={0}>Netto</MenuItem>
                                </Select>
                            </FormControl>
                            
                        </TabPanel>

                        <TabPanel value={this.state.tabValue} index={2} className={styles.tab3}>
                            
                            <Grid container spacing={1}>
                                <Grid item xs={6} >
                                    <div className={styles.date}>
                                        {this.state.routeStartTime || "b.d."}
                                    </div>
                                    <div className={styles.addressBox}>
                                    <ArrowForwardOutlinedIcon className={styles.fill}/>{this.state.routeStart || "b.d."}
                                    </div>
                                </Grid>
                                <Grid item xs={6} >
                                    <div className={styles.date}>
                                        {this.state.routeStopTime ||"b.d."}
                                    </div>
                                    <div className={styles.addressBox}>
                                    <KeyboardTabOutlinedIcon className={styles.fill}/>{this.state.routeEnd || "b.d."}
                                    </div>
                                </Grid>
                                <Grid item xs={4} >
                                    <h6 className={styles.center}>Dystans</h6>
                                    <div className={styles.distBox}>
                                    {this.state.routeDistans.toFixed(2)} km
                                    </div>
                                </Grid>
                                <Grid item xs={4} >
                                    <h6 className={styles.center}>Czas</h6>
                                    <div className={styles.timeBox}>
                                    {this.state.routeTime} h
                                    </div>
                                </Grid>
                                <Grid item xs={4} >
                                    <h6 className={styles.center}>Koszt</h6>
                                    <div className={styles.costBox}>
                                    {this.state.routeCost.toFixed(2)} {this.state.currency[this.state.selectCurrency].symbol}
                                    </div>
                                </Grid>         

                            </Grid>
                            
                            <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography>Raport szczegółowy</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  
                                    <RouteItineraries 
                                        roadSegments={this.state.roadSegments} 
                                        routeItineraries={this.state.routeItineraries}
                                        routeItinerariesTypeName={this.state.routeItinerariesTypeName}
                                        currency={this.state.currency}
                                        selectCurrency={this.state.selectCurrency}
                                    />
                                  
                                </AccordionDetails>
                            </Accordion>
                            
                            <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography>Raport kosztów eksploatacji</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <table>
                                        <OperationCosts
                                            totalFuelCost={this.state.totalFuelCost}
                                            totalTimeCost={this.state.totalTimeCost}
                                            totalRouteCost={this.state.totalRouteCost}
                                            currency={this.state.currency}
                                            selectCurrency={this.state.selectCurrency}
                                        />
                                    </table>
                                </AccordionDetails>
                            </Accordion>
                            
                            <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography>Raport opłat drogowych</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <table>
                                        <RoadToll
                                            logisticTollResults={this.state.logisticTollResults}
                                            additionalRoadCost={this.state.additionalRoadCost}
                                            currency={this.state.currency}
                                            selectCurrency={this.state.selectCurrency}
                                        />
                                    </table>
                                </AccordionDetails>
                            </Accordion>
                            
                            
                        </TabPanel>
                        
                        
                        <Button variant="contained" color="primary" className={styles.loadBtn} onClick={this.doRoute}>Planuj {this.state.showLoading && <CircularProgress />}</Button>

                    </div>


                </div>
            </Draggable>
        )
    }
}

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const routeproIcons = importAll(require.context('../../img/routepro_icons', false, /\.png/));




function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

function MyAutocomplete(props) {
    const { index, item, optionsAC, onChangeAutocomplete, onInputChangeAutocomplete, onCloseAutocomplete, onClickInput } = props;
    
    return (
        <Autocomplete
            freeSolo
            includeInputInList
            classes={{ option: styles.renderOption}}
            getOptionLabel={(option) => option.label}
            renderOption={(option) => (
                <React.Fragment>
                  <span><LocationOnIcon /></span>
                  <span>{option.label}</span>
                </React.Fragment>
            )}
            options={optionsAC}
            className={styles.inputRoot}      
            onClose={() => {onCloseAutocomplete()}}
            onChange={(event, newValue) => {onChangeAutocomplete(event, newValue, item.title)}}
            onInputChange={(event, newInputValue) => {onInputChangeAutocomplete(event, newInputValue, item.title)}}

            renderInput={(params) => (
                <div ref={params.InputProps.ref} className={styles.input}>
                    <input type="text" {...params.inputProps} placeholder="Wpisz adres" value={item.text}  onClick={(event) => onClickInput(event, item.title)} />
                </div>
            )}

        />
    )
}

function OperationCosts(props) {
    const { totalFuelCost, totalTimeCost, totalRouteCost, currency, selectCurrency } = props;
    
    let totalCost = parseFloat(totalFuelCost) + parseFloat(totalTimeCost) + parseFloat(totalRouteCost);
    
    return (
        <tbody>
            <tr><td>paliwa</td><td>{totalFuelCost.toFixed(2)} {currency[selectCurrency].symbol}</td></tr>
            <tr><td>godzinowy</td><td>{totalTimeCost.toFixed(2)} {currency[selectCurrency].symbol}</td></tr>
            <tr><td>kilometrażowy</td><td>{totalRouteCost.toFixed(2)} {currency[selectCurrency].symbol}</td></tr>
            <tr><td>Suma</td><td>{totalCost.toFixed(2)} {currency[selectCurrency].symbol}</td></tr>
        </tbody>        
    )
}

function RoadToll(props) {
    const { logisticTollResults, additionalRoadCost, currency, selectCurrency } = props;
    
    let rowCountry = '';
    let rowToll = '';
    let rowTollSummary = '';
    const isEntry = false;

    let tollSummary = 0;

    // roadTollCost
    let roadTollCost = 0;
    let entryType = '';
    let tollNum = logisticTollResults.length;

    for(let t = 0; t < tollNum; t++){

        roadTollCost = parseFloat(logisticTollResults[t].paymentLocalCurrency).toFixed(2);//.paymentOriginalCurrency).toFixed(2);
        entryType = logisticTollResults[t].entryType;

        tollSummary = parseFloat(tollSummary) + parseFloat(roadTollCost);
        isEntry = true;
    }

    let additionalRoadCostOut = parseFloat(additionalRoadCost);
    tollSummary = parseFloat(tollSummary) + parseFloat(additionalRoadCostOut);

    return (
        <tbody>
            <tr><td>Polska</td><td></td></tr>
            {isEntry && <tr><td>- {entryType}</td><td>{roadTollCost.toFixed(2)} {currency[selectCurrency].symbol}</td></tr>}
            <tr><td>- opłata drogowa</td><td>{additionalRoadCostOut.toFixed(2)} {currency[selectCurrency].symbol}</td></tr>
            <tr><td>Suma</td><td>{tollSummary.toFixed(2)} {currency[selectCurrency].symbol}</td></tr>
        </tbody>        
    )
    
    
}

function RouteItineraries(props) {
    const { roadSegments, routeItineraries, routeItinerariesTypeName, currency, selectCurrency } = props;
    
    let entryCost = 0;
    let entryDistance = 0;
    let entryTime = 0;
    
    
    const items = roadSegments.map((item, index) => {
        let startName = routeItineraries[item.itineraryFrom].itineraryName1;
        let stopName = routeItineraries[item.itineraryTo].itineraryName1;

        let titleSegment = startName + ' - ' + stopName;
        
        return ( <Ret key={index} title={titleSegment} roadSegments={item} routeItineraries={routeItineraries} routeItinerariesTypeName={routeItinerariesTypeName} currency={currency} selectCurrency={selectCurrency} /> )
    });

    return <React.Fragment>
        {items.map(i => i)} 
    </React.Fragment>
    
}

function Ret(props) {
    const { title, roadSegments, routeItineraries, routeItinerariesTypeName, currency, selectCurrency } = props
    
    let segmentCost = roadSegments.segmentRouteCost;
    let segmentLength = roadSegments.segmentRouteLength / 1000;
    let segmentTime = roadSegments.segmentRouteTime * 86400; // Days to seconds;
    
    
    const items = [];
    for(let i = roadSegments.itineraryFrom; i <= roadSegments.itineraryTo; i++){
        let value = routeItineraries[i];
        
        let entryCost = value.entryCost;
        let entryDistance = value.entryDistance / 1000;
        let entryTime = value.entryTime * 86400; // Days to seconds
        
        
        items.push(<tr key={i}><td><img src={routeproIcons[`${value.itineraryType}.png`]} />{routeItinerariesTypeName[value.itineraryType]}: {value.itineraryName0}, {value.itineraryName1}</td><td>{parseFloat(entryDistance).toFixed(1)} km</td><td>{toHHMMSS(entryTime)} h</td><td>{parseFloat(entryCost).toFixed(2)} {currency[selectCurrency].symbol}</td></tr>);
    }
    
    
    return (
        <Accordion style={{width: 280}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
                <table>
                    <tbody>
                        <tr><td>{title}</td><td>{parseFloat(segmentLength).toFixed(1)} km</td><td>{toHHMMSS(segmentTime)} h</td><td>{parseFloat(segmentCost).toFixed(2)}  {currency[selectCurrency].symbol}</td></tr>
                    </tbody>
                </table>
            </AccordionSummary>
            <AccordionDetails style={{overflowY: 'scroll', height: 200}}>
                <table>
                    <tbody>
                        {items.map(i => i)} 
                    </tbody>
                </table>
            </AccordionDetails>
        </Accordion>
    )
}


function RouteListPointMiddle(props) {
    const { index, item, removeMiddlePoint, focusOnPoint, optionsAC, onChangeAutocomplete, onInputChangeAutocomplete, onCloseAutocomplete, onClickInput } = props;
    
    return (
        <li key={index} className={styles.liPoints}>
            <div className={styles.dragPoints}><MapPin className={styles.stroke}/></div>
            <MyAutocomplete
                item={item}
                optionsAC={optionsAC}
                onChangeAutocomplete={onChangeAutocomplete}
                onInputChangeAutocomplete={onInputChangeAutocomplete}
                onCloseAutocomplete={onCloseAutocomplete}
                onClickInput={onClickInput}
            />
            <IcoBtn className={styles.removePoints} icon="Search" tooltip="Pokaż" onClick={focusOnPoint}/>
            <IcoBtn className={`${styles.removePoints} btn-delete`} icon="X" tooltip="Usuń"  onClick={removeMiddlePoint} />
            {/* <Button className={styles.removePoints} onClick={focusOnPoint}>o</Button>
            <Button className={styles.removePoints} onClick={removeMiddlePoint}>x</Button> */}
        </li>
    )
}


function RouteListPointStart(props) {
    const { index, item, focusOnPoint, optionsAC, onChangeAutocomplete, onInputChangeAutocomplete, onCloseAutocomplete, onClickInput } = props;
    
    return (
        <li key={index} className={styles.liPoints}>
            <div className={styles.dragPoints}><ArrowForwardOutlinedIcon className={styles.fill}/></div>
            <MyAutocomplete
                item={item}
                optionsAC={optionsAC}
                onChangeAutocomplete={onChangeAutocomplete}
                onInputChangeAutocomplete={onInputChangeAutocomplete}
                onCloseAutocomplete={onCloseAutocomplete}
                onClickInput={onClickInput}
            />
            <IcoBtn className={styles.removePoints} icon="Search" tooltip="Pokaż"  onClick={focusOnPoint} />
            <div style={{clear: 'both'}}></div>
        </li>
    )
}

function RouteListPointStop(props) {
    const { index, item, focusOnPoint, addMiddlePoint, optionsAC, onChangeAutocomplete, onInputChangeAutocomplete, onCloseAutocomplete, onClickInput } = props;
    
    return (
        <>
            <Button variant="outlined" color="primary" className={styles.addPoint} onClick={addMiddlePoint}> Dodaj punkt pośredni <Plus /></Button>
            <li key={index} className={styles.liPoints}>

                <div className={styles.dragPoints}><KeyboardTabOutlinedIcon className={styles.fill}/></div>
                <MyAutocomplete
                    item={item}
                    optionsAC={optionsAC}
                    onChangeAutocomplete={onChangeAutocomplete}
                    onInputChangeAutocomplete={onInputChangeAutocomplete}
                    onCloseAutocomplete={onCloseAutocomplete}
                    onClickInput={onClickInput}
                />
                <IcoBtn className={styles.removePoints} icon="Search" tooltip="Pokaż"  onClick={focusOnPoint} />
                <div style={{clear: 'both'}}></div>
            </li>
        </>
    )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
    >
        {value === index && (
            <Box p={3}>
                {children}
            </Box>
        )}
    </div>
  );
}

function toHHMMSS(sec) {
    var sec_num = parseInt(sec, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);
    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }
    var time = hours + ':' + minutes + ':' + seconds;

    return time;
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};



