import React, { useState, useEffect } from "react";
import { DBurl } from "../../../appConfig";

export const useNewInvoice = (
  user,
  department = null,
  buyer = undefined,
  selectedCurrency = undefined,
  invoiceNum = null,
  creditNoteNum = null,
  debitNoteNum = null,
  documentType,
  linkedInvNum = null,
  isCorrection,
  invFormat,
  special,
  saleServiceDate,
  exchangeRateDate
) => {
  const [invoices, setInvoices] = useState([]);
  const [departments, setDepartments] = useState([{ label: "", value: null }]);
  const [loaded, setLoaded] = useState(false);
  const [accounts, setAccounts] = useState([{ label: "", value: null }]);
  const [clients, setClients] = useState([{ label: "", value: null }]);
  const [orders, setOrders] = useState([]);
  const [exchangeRate, setExchangeRate] = useState([]);
  const [servicesOptions, setServicesOptions] = useState([]);
  const [invoiceOK, setInvoiceOK] = useState(false);
  const [clientDepartments, setClientDepartments] = useState();
  const [sufix, setSufix] = useState();
  const [forwardingOrders, setForwardingOrders] = useState([]);
  const [allOrders, setAllOrders] = useState();
  const [carriers, setCarriers] = useState([]);
  const [tags_list, setTags_list] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [buyerOptions, setBuyerOptions] = useState([]);

  const getInvoicesUrl = (department) => {
    return !department
      ? `${DBurl}/getInvoices`
      : `${DBurl}/getInvoices?id_customer_departament=${department}`;
  };
  const fetchInvoices = async () => {
    const response = await fetch(getInvoicesUrl(department), {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setLoaded(true);
      setInvoices(json.data);
    }
  };

  const getSufix = async () => {
    const response = await fetch(`${DBurl}/getUsers`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      const userFromBackend = json.data.find((sufix) => sufix.id === user.id);
      setSufix(userFromBackend?.sufix_invoice_name);
    }
  };

  const fetchClientDepartments = async () => {
    const response = await fetch(`${DBurl}/getDepartments`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": user.csrf_token,
      },
    });
    const json = await response.json();

    if (json.success) {
      setClientDepartments(json.data);
    }
  };

  const checkInvoiceName = async () => {
    const response = await fetch(
      `${DBurl}/checkInvoiceName?id_dealer=${department}&invoice_no=${invoiceNum}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": user.csrf_token,
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setInvoiceOK(json.data);
    }
  };

  const checkDebitNoteName = async () => {
    const response = await fetch(
      `${DBurl}/checkDebitNoteName?id_dealer=${department}&debit_note_no=${invoiceNum}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": user.csrf_token,
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setInvoiceOK(json.data);
    }
  };

  const checkCreditNoteName = async () => {
    const response = await fetch(
      `${DBurl}/checkDebitNoteName?id_dealer=${department}&debit_note_no=${invoiceNum}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": user.csrf_token,
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setInvoiceOK(json.data);
    }
  };

  const checkCorrectionName = async () => {
    const response = await fetch(
      `${DBurl}/checkCorrectionName?id_dealer=${department}&correction_no=${invoiceNum}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": user.csrf_token,
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setInvoiceOK(json.data);
    }
  };

  const getTags = async () => {
    const response = await fetch(`${DBurl}/getInvoiceTags`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setTags_list(
        json.data.map((tag) => {
          return { ...tag, label: tag.name, value: tag.id };
        })
      );
    }
  };

  useEffect(() => {
    if (documentType?.value === 1 && !isCorrection) {
      invoiceNum && department && checkInvoiceName();
    }
    if (documentType?.value === undefined && !isCorrection) {
      invoiceNum && department && checkInvoiceName();
    }
    if (documentType?.value === 1 && isCorrection) {
      invoiceNum && department && checkCorrectionName();
    }
    if (documentType?.value === 4) {
      invoiceNum && department && checkDebitNoteName();
    }
    if (documentType?.value === 5) {
      invoiceNum && department && checkCreditNoteName();
    }
  }, [invoiceNum, documentType]);

  const fetchDepartments = async () => {
    const response = await fetch(`${DBurl}/getCustomersDepartments`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": user.csrf_token,
      },
    });
    const json = await response.json();

    if (json.success) {
      const newDepartments = json.data.map((dep) => {
        return {
          label: dep?.shortname,
          value: dep.id,
          nip: dep.nip,
          apartment_no: dep.address.apartment_no,
          city: dep.address.city,
          country: dep.address.country,
          street: dep.address.street,
          street_no: dep.address.street_no,
          zipcode: dep.address.zipcode,
          name: dep.name,
          deadline: dep.default_payment_deadline,
          issuePlace: dep.default_place_of_issue,
          default: dep.is_default,
          invFormat: dep.default_invoices_name_definition,
          special: dep.default_invoices_special_tag_definition,
        };
      });
      setDepartments([...newDepartments]);
    }
  };

  const fetchAccounts = async () => {
    const response = await fetch(
      `${DBurl}/getCustomersDepartmentsAccounts?customers_departament_id=${department}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": user.csrf_token,
        },
      }
    );
    const json = await response.json();

    if (json.success) {
      const newAccounts = json.data.map((acc) => {
        return {
          label: acc?.account_name,
          value: acc?.id,
          acc_no: acc?.bank_account_no,
          bank_name: acc?.bank_name,
          vat_no: acc?.bank_vat_account_no,
          swift_no: acc.swift_bic_no,
          acc_name: acc?.account_name,
          currency: acc?.currency,
        };
      });
      setAccounts([...newAccounts]);
    }
  };

  const fetchExchangeRates = async (code, date) => {
    const response = await fetch(
      `${DBurl}/getExchangeRateForCode?code=${code}&date=${date}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": user.csrf_token,
        },
      }
    );
    const json = await response.json();

    if (json.success) {
      setExchangeRate(json.data);
    }
  };

  useEffect(() => {
    const today = new Date();
    // const date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
    const date =
      `${exchangeRateDate.getFullYear()}-${
        exchangeRateDate.getMonth() + 1
      }-${exchangeRateDate.getDate()}` >
      `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
        ? `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
        : `${exchangeRateDate.getFullYear()}-${
            exchangeRateDate.getMonth() + 1
          }-${exchangeRateDate.getDate() + 1}`;

    if (selectedCurrency) {
      fetchExchangeRates(selectedCurrency, date);
    }
  }, [JSON.stringify(selectedCurrency), JSON.stringify(exchangeRateDate)]);

  const getContractors = async function () {
    const response = await fetch(`${DBurl}/getDepartments/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      const newClients = json.data.map((client) => {
        return {
          label: client?.shortname,
          value: client?.id,
          name: client?.name,
          nip: client?.nip,
          street: client?.address?.street,
          street_no: client?.address?.street_no,
          apartment_no: client?.address?.apartment_no,
          city: client?.address?.city,
          zipcode: client?.address?.zipcode,
          country: client?.address?.country,
          recipient_other_than_buyer: client?.recipient_other_than_buyer,
          id_contractor: client?.id_parent,
        };
      });
      setClients([...newClients]);
    }
  };

  const getClients = async function () {
    const response = await fetch(`${DBurl}/getContractors/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      const newClients = json.data.map((client) => {
        return {
          label: client?.shortname,
          value: client?.id,
          name: client?.name,
          nip: client?.nip,
          street: client?.address?.street,
          street_no: client?.address?.street_no,
          apartment_no: client?.address?.apartment_no,
          city: client?.address?.city,
          zipcode: client?.address?.zipcode,
          country: client?.address?.country,
          recipient_other_than_buyer: client?.recipient_other_than_buyer,
          id_contractor: client?.id,
        };
      });
      setCustomers([...newClients]);
    }
  };

  const getCarriers = async function () {
    const response = await fetch(`${DBurl}/getCarriers/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      const tempCarriers = json.data.map((client) => {
        return {
          label: client?.shortname,
          value: client?.id,
          name: client?.name,
          nip: client?.nip,
          street: client?.address?.street,
          street_no: client?.address?.street_no,
          apartment_no: client?.address?.apartment_no,
          city: client?.address?.city,
          zipcode: client?.address?.zipcode,
          country: client?.address?.country,
          recipient_other_than_buyer: client?.recipient_other_than_buyer,
        };
      });
      setCarriers([...tempCarriers]);
    }
  };

  const getForwardingOrders = async () => {
    const response = await fetch(`${DBurl}/getForwardingOrders/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      const filtered = json.data.filter(
        (ord) => ord.status === 0 && ord.id_invoice === null
      );
      setForwardingOrders(
        json.data.map((order) => {
          return {
            ...order,
            label: order.customer_order_number
              ? `${order.order_no} (${order.customer_order_number})`
              : order.order_no,
            value: order.id,
            status: order.status,
            id_invoice: order.id_invoice,
          };
        })
      );
    }
  };

  const getOrders = async () => {
    const response = await fetch(`${DBurl}/getOrders/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      const filtered = json.data.filter(
        (ord) => ord.status === 0 && ord.id_invoice === null
      );
      setOrders(
        json.data.map((order) => {
          return {
            ...order,
            label: order.customer_order_number
              ? `${order.order_no} (${order.customer_order_number})`
              : order.order_no,
            value: order.id,
            status: order.status,
            id_invoice: order.id_invoice,
          };
        })
      );
    }
  };

  const getServices = async () => {
    const response = await fetch(`${DBurl}/getServices/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setServicesOptions(
        json.data?.map((e) => {
          return {
            label: e.name,
            value: e.id,
            selName: "name",
            ...e,
          };
        })
      );
      return json;
    }
  };

  useEffect(() => {
    if (buyer) {
      setOrders((prevOrders) =>
        prevOrders.filter((order) => order.contractor.id === buyer.value)
      );
    }
  }, [JSON.stringify(buyer)]);

  useEffect(() => {
    if (department) {
      fetchInvoices();
      fetchAccounts();
    }
  }, [JSON.stringify(department)]);

  useEffect(() => {
    setAllOrders(orders.concat(forwardingOrders));
  }, [JSON.stringify(orders), JSON.stringify(forwardingOrders)]);

  const checkEUVAT = async (euvatNum) => {
    const countryCode = euvatNum.slice(0, 2).toUpperCase();
    let numberCode = euvatNum.slice(2);
    // numberCode = (numberCode[0] === " " || numberCode[0] === "-") ? numberCode.slice(1) : numberCode;
    const response = await fetch(
      `${DBurl}/euVatApi?nip=${numberCode.replace(
        /\s/g,
        ""
      )}&country=${countryCode}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": user.csrf_token,
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      // setName(json.data.name);
      // setCountry(getCountryByCode(countryCode));
      // searchByStr(json.data.address);
      // setOpenDialog(true);
      // setDialogContent(`NIP poprawny, uzupełniono dane`);
      // setDialogColor("success");
    } else {
      // setOpenDialog(true);
      // setDialogContent(`Niepoprawne dane`);
      // setDialogColor("error");
    }
  };

  useEffect(() => {
    fetchDepartments();
    getCarriers();
    getContractors();
    getOrders();
    getForwardingOrders();
    getServices();
    fetchClientDepartments();
    getSufix();
    getClients();
  }, []);

  useEffect(() => {
    if (clients.length && customers.length)
      setBuyerOptions(customers.concat(clients));
  }, [clients, customers]);

  useEffect(() => {
    getTags();
  }, [JSON.stringify(tags_list)]);

  return {
    invoices,
    departments,
    accounts,
    clients: [...clients, ...carriers],
    orders: [...orders, ...forwardingOrders],
    exchangeRate,
    servicesOptions,
    invoiceOK,
    clientDepartments,
    sufix,
    tags_list,
    buyerOptions,
  };
};
