import React, { useState, useEffect, useRef } from 'react';
import Timeline, {
	TimelineHeaders,
	SidebarHeader,
	DateHeader
} from "react-calendar-timeline/lib";
import '../../components/timetable.css'
import moment from 'moment'
import { DBurl } from '../../appConfig'
import Title from '../../components/title'
import BTNstyles from '../../styles/button.module.css'
import { Search } from 'react-feather';
import Button from '@material-ui/core/Button';
import { Add } from '@material-ui/icons';
import Pagination from '../../components/pagination'
import styles from '../../styles/timeTable.module.css'
import ItemsOnPage from '../../components/ItemsOnPage'
import OrderDialog from './orderDialog'
import '../../styles/flag.css'

export default function TimeTable_S(props) {

	// dane kierowców musze być przed danymi pobranymi z bazy - bez nich nie działa przesuwanie tablicyw prawo/lewo (cały czas cofa date) - nie wyjaśnione dlaczego
	const [vehicles, setVehicles] = useState([{ "id": 1, "title": "Wczytuję" }, { "id": 2, "title": "dane" }])
	const [orders, setOrders] = useState([])
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(100);
	const [searchTitleValue, setSearchTitleValue] = useState("")
	// const [defaultTimeStart, setDefaultTimeStart] = useState(moment().add(0, 'hour'))
	// const [defaultTimeEnd, setDefaultTimeEnd] = useState(moment().add(14, 'days'))
	const [table, setTable] = useState([])

	const defaultTimeStart = moment().add(0, 'hour')
	const defaultTimeEnd = moment().add(14, 'days')
	const [openOrder, setOpenOrder] = useState(false)
	const [orderData, setOrderData] = useState({})

	// const [openSnack, setOpenSnack] = useState(false)
	// const [snackContent, setSnackContent] = useState("")
	// const [openModalDelete, setOpenModalDelete] = useState(false)
	// const [modalDeleteData, setModalDeleteData] = useState(false)

	// const [openDialog, setOpenDialog] = useState(false)
	// const [dialogContent, setDialogContent] = useState("")
	// const [dialogColor, setDialogColor] = useState("error")


	const inputRef = useRef()


	const getCars = async function () {
		props.loading(true)
		const response = await fetch(`${DBurl}/getCars/`, {
			method: "GET",
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": props.user.csrf_token
			},
		})
		const json = await response.json()
		if (json.success) {
			const tempCarNames = json.data.map((e, i) => {
				return {
					...e,
					title: e.name
				}
			})
			if(tempCarNames.length > 0){
				setVehicles(tempCarNames)
			}
			props.loading(false)
		}
	}

	const getOrdersS = async function () {
		props.loading(true)
		const response = await fetch(`${DBurl}/getOrdersS/`, {
			method: "GET",
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": props.user.csrf_token
			},
		})
		const json = await response.json()
		if (json.success) {
			const tempData = json.data.map((e, i) => {
				const start = moment(e.order_start)
				const end = moment(e.order_stop)
				return {
					...e,
					start_time: start,
					end_time: end,
					group: e.transport_set.id_car,
					title: e.order_no,
					itemProps: {
						className: `end-${e.order_start || "PL"} 
						start-${e.order_stop || "PL"}`
					}

				}

			})
			await setOrders(tempData)
			props.loading(false)

		} else {
			props.loading(false)
			// setOpenDialog(true)
			// setDialogContent(`${json.responseDescription} // ${json.response}  `)
			// setDialogColor("error")
		}
	}

	const indexOfLastPost = page * rowsPerPage;
	const indexOfFirstPost = indexOfLastPost - rowsPerPage;

	useEffect(() => {
		getCars()
		getOrdersS()
	}, [])

	useEffect(() => {
		setTable(vehicles)
	}, [vehicles, indexOfFirstPost, indexOfLastPost])

	const paginate = pageNumber => setPage(pageNumber);

	function search(rows) {
		if (rows) {
			return rows.filter((row) => {
				if (row.title) {
					return row.title.toString().toLowerCase().indexOf(searchTitleValue) > -1
				} else { return null }
			})
		} else {
			return null
		}

	}

	return (
		<>
			<Title title={props.state.tabName} />
			<Timeline
				groups={vehicles.length > 0 ? search(vehicles).slice(indexOfFirstPost, indexOfLastPost) : [{ "id": 0, "title": "Brak danych" }]}
				items={vehicles.length > 0 ? orders : null}
				defaultTimeStart={defaultTimeStart}
				defaultTimeEnd={defaultTimeEnd}
				lineHeight={40}
				minZoom={7 * 24 * 60 * 60 * 1000}
				maxZoom={2 * 365.24 * 24 * 60 * 60 * 1000}
				canMove={false}
				canResize={false}
				sidebarWidth={220}
				timeSteps={{
					second: 60,
					minute: 60,
					hour: 24,
					day: 1,
					month: 1,
					year: 1
				}}

				onItemSelect={(i) => {
					setOrderData(orders.find(e => e.id === i))
					setOpenOrder(true)
				}}
			>
				<TimelineHeaders >
					<SidebarHeader>
						{({ getRootProps }) => {
							return <div className="rctLeftHeader">
								<div {...getRootProps()} className="rctLeftLabel">Pojazd</div>
								<div className="rctSearchParent">
									<input type='text' search className="rctSearch" value={searchTitleValue} onChange={(e) => {
										setSearchTitleValue(e.target.value.toLowerCase())
										setTimeout(() => { inputRef.current.focus(); }, 50)
									}} ref={inputRef} />
									<Search className="rctSearchIcon" />
								</div>
							</div>;
						}}
					</SidebarHeader>
					<DateHeader
						labelFormat="YYYY.MM"
					/>
					<DateHeader

						labelFormat="DD"
					/>
				</TimelineHeaders>

			</Timeline>
			<div className={styles.tableBottom} >
				<ItemsOnPage
					setRowsPerPage={setRowsPerPage}
					setPage={setPage}
					table={table}
				/>
				<div>
					<Pagination
						postsPerPage={rowsPerPage}
						totalPosts={search(table).length}
						paginate={paginate}
					/>
					{openOrder && <OrderDialog data={orderData} open={openOrder} order_type_S={true} setOpen={setOpenOrder} addTab={props.addTab} user={props.user}/>}
				</div>
			</div>
			<div className={BTNstyles.btnRow}>
				<Button variant="contained" color="primary" size="large" className={BTNstyles.btn} onClick={(e) => { props.addTab('newOrder') }}>
					Dodaj <Add className={BTNstyles.downBtnIco} /></Button>
			</div>
		</>
	)
}

