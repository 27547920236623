import React, { useState } from "react";
import { Modal, Box } from "@material-ui/core";
import TmsDialog from "components/TmsDialog";

import "./styles.sass";

type resetModal = {
  isOpen: boolean;
  handleReset?: () => void;
};

type Props = {
  resetModal: resetModal;
  setResetModal: ({ isOpen }: resetModal) => void;
};

const ResetModal = ({ resetModal, setResetModal }: Props) => {
  const [dialog, setDialog] = useState({ isOpen: false });

  const handleClose = () => {
    setResetModal({ isOpen: false });
  };

  return (
    <>
      <Modal open={resetModal.isOpen} onClose={handleClose}>
        <Box className="reset-modal">
          <div className="reset-modal__title">
            <label>
              Czy chcesz anulować wybraną trasę domyślną i zresetować punkty
              załadunku i rozładunku?
            </label>
          </div>
          <div className="reset-modal__buttons">
            <button className="reset-modal__button" onClick={handleClose}>
              Anuluj
            </button>
            <button
              onClick={resetModal.handleReset}
              className="reset-modal__button --reset"
            >
              Zresetuj
            </button>
          </div>
        </Box>
      </Modal>
      <TmsDialog dialog={dialog} setDialog={setDialog} />
    </>
  );
};

export default ResetModal;
