import React from "react";
import {
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { X } from "react-feather";
import { versions } from "versions";

import stylesMod from "styles/newOrEdit.module.css";
import "./styles.sass";

type Props = {
  isChangeLogOpen: boolean;
  setIsChangeLogOpen: (isOpen: boolean) => void;
};

const ChangeLog = ({ isChangeLogOpen, setIsChangeLogOpen }: Props) => {
  const handleClose = () => {
    setIsChangeLogOpen(false);
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={isChangeLogOpen}
      onClose={handleClose}
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        className={stylesMod.dialogHead}
      >
        Change Log
        <IconButton
          aria-label="close"
          className={stylesMod.closeButton}
          onClick={handleClose}
        >
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="change-log">
          {versions.map((version, index) => (
            <div key={index} className="change-log__item">
              <p className="change-log__version">v{version.version}</p>
              {version.logs?.map((log, index) => (
                <div key={index} className="change-log__scope">
                  {log.scope && (
                    <p className="change-log__scope-name">{log.scope}</p>
                  )}
                  <ul className="change-log__changes">
                    {log.changes.map((change, index) => (
                      <li key={index} className="change-log__change">
                        {change}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeLog;
