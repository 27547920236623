import api from "./axios";
import { DBurl } from "appConfig";
import {
  BorderCrossing,
  Car,
  Contractor,
  Driver,
  DriverCosts,
  ExchangeRate,
  FavoriteBorderCrossing,
  FavouriteRoute,
  FuelCard,
  Goods,
  HereMapsRouting,
  Location,
  NewTagResponse,
  NoteTemplate,
  Order,
  RatesPerKm,
  Tag,
  TrailersTypes,
  TransportSet,
  UnitResponse,
  Warehouse,
} from "types";

export const getContractors = async (): Promise<Contractor[]> => {
  const response = await api.get(`${DBurl}/getContractors`);

  return response.data.data;
};

export const getBorderCrossings = async (
  origin?: string,
  destination?: string
): Promise<BorderCrossing[]> => {
  const response = await api.get(`${DBurl}/getBorderCrossings`, {
    params: { origin, destination },
  });

  return response.data.data;
};

export const getBorderCrossingsFavorites = async (
  filter_id_customer?: number
): Promise<FavoriteBorderCrossing[]> => {
  const response = await api.get(`${DBurl}/getBorderCrossingsFavourites`, {
    params: { with_border_crossing: true, filter_id_customer },
  });

  return response.data.data;
};

export const postBorderCrossingsFavorites = async (values: {
  mode: "insert" | "update";
  data: {
    id_border_crossing: number;
    id_firm: number;
    id_customer: number;
    id_user: number;
    id?: number;
  };
}) => {
  const response = await api.post(
    `${DBurl}/setBorderCrossingsFavourites`,
    values
  );

  return response.data.data;
};

export const getUnits = async (): Promise<UnitResponse[]> => {
  const response = await api.get(`${DBurl}/getUnits`);

  return response.data.data;
};

export const getGoods = async (): Promise<Goods[]> => {
  const response = await api.get(`${DBurl}/getGoods`);

  return response.data.data;
};

export const insertGoods = async (data: {
  name: string;
  details: string;
  unit: string;
}) => {
  const response = await api.post(`${DBurl}/setGoods`, {
    mode: "insert",
    data,
  });

  return response.data.data;
};

export const updateGoods = async (data: {
  id: number;
  name: string;
  details: string;
  unit: string;
}) => {
  const response = await api.post(`${DBurl}/setGoods`, {
    mode: "update",
    data,
  });

  return response.data.data;
};

export const deleteGoods = async (ids: number[]) => {
  const response = await api.post(`${DBurl}/setGoods`, {
    mode: "delete",
    data: { ids },
  });

  return response.data.data;
};

export const getCars = async (): Promise<Car[]> => {
  const response = await api.get(`${DBurl}/getCars`);

  return response.data.data;
};

export const deleteCars = async (ids: number[]) => {
  const response = await api.post(`${DBurl}/setCars`, {
    mode: "delete",
    data: { ids },
  });

  return response.data.data;
};

export const getTrailers = async (): Promise<Car[]> => {
  const response = await api.get(`${DBurl}/getTrailers`);

  return response.data.data;
};

export const getDrivers = async (): Promise<Driver[]> => {
  const response = await api.get(`${DBurl}/getDrivers`);

  return response.data.data;
};

export const getExchangeRates = async (): Promise<ExchangeRate[]> => {
  const response = await api.get(`${DBurl}/getExchangeRates`);

  return response.data.data;
};

export const getTransportSets = async (): Promise<TransportSet[]> => {
  const response = await api.get(`${DBurl}/getTransportSets`);

  return response.data.data;
};

export const getTags = async (): Promise<Tag[]> => {
  const response = await api.get(`${DBurl}/getTags`);

  return response.data.data;
};

export const setTags = async (data: {
  name: string;
  color: string;
}): Promise<NewTagResponse> => {
  const response = await api.post(`${DBurl}/setTags`, { mode: "insert", data });

  return response.data.data;
};

export const getPrevOrders = async (
  ts: string,
  id_car: string,
  id_driver: string
) => {
  const response = await api.get(`${DBurl}/getPrevOrders`, {
    params: { ts, id_car, id_driver },
  });

  return response.data.data;
};

export const getWarehouses = async (
  id_contractor?: number
): Promise<Warehouse[]> => {
  const response = await api.get(`${DBurl}/getWarehouses`, {
    params: { id_contractor },
  });

  return response.data.data;
};

export const getOrders = async (): Promise<Order[]> => {
  const response = await api.get(`${DBurl}/getOrders`);

  return response.data.data;
};

export const getForwardingOrders = async (): Promise<Order[]> => {
  const response = await api.get(`${DBurl}/getForwardingOrders`);

  return response.data.data;
};

export const getOrdersTemplates = async (): Promise<Order[]> => {
  const response = await api.get(`${DBurl}/getOrdersTemplates`);

  return response.data.data;
};

export const getRefuelingFromOrders = async (): Promise<Order[]> => {
  const response = await api.get(`${DBurl}/getRefuelingFromOrders`);

  return response.data.data;
};

export const getCostsFromOrders = async () => {
  const response = await api.get(`${DBurl}/getCostsFromOrders`);

  return response.data.data;
};

export const getTrailersTypes = async (): Promise<TrailersTypes[]> => {
  const response = await api.get(`${DBurl}/getTrailersTypes`);

  return response.data.data;
};

export const getRatesPerKm = async (
  id_contractor: number
): Promise<RatesPerKm[]> => {
  const response = await api.get(`${DBurl}/getRatesPerKm`, {
    params: { id_contractor },
  });

  return response.data.data;
};

export const commonApiRequest = async <DataType,>(
  url: string
): Promise<DataType> => {
  const response = await api.get(`${DBurl}/${url}`);

  return response.data.data;
};

export const setRoutesFavourites = async (data: {
  name: string;
  id_route: number;
  id_firm: number;
  id_contractor: number;
  id_customer: number;
  id_user: number;
  locations: Location[];
}) => {
  const response = await api.post(`${DBurl}/setRoutesFavourites`, {
    mode: "insert",
    data,
  });

  return response.data.data;
};

export const editRoutesFavourites = async (data: {
  id: number;
  name: string;
  id_route: number;
  id_firm: number;
  id_contractor: number;
  id_customer: number;
  id_user: number;
  locations: Location[];
}) => {
  const response = await api.post(`${DBurl}/setRoutesFavourites`, {
    mode: "update",
    data,
  });

  return response.data.data;
};

export const deleteRoutesFavourites = async (data: {
  ids: number[] | undefined;
}) => {
  const response = await api.post(`${DBurl}/setRoutesFavourites`, {
    mode: "delete",
    data,
  });

  return response.data.data;
};

export const getRoutesFavourites = async (params: {
  filter_id_firm?: number;
  filter_id_customer?: number;
  filter_id_contractor?: string;
}): Promise<FavouriteRoute[]> => {
  const response = await api.get(`${DBurl}/getRoutesFavourites`, {
    params: { ...params, with_route: true },
  });

  return response.data.data;
};

export const getHereMapsRouting = async (params: {
  routing_id: number;
  order_id: string | null;
}): Promise<HereMapsRouting> => {
  const response = await api.get(`${DBurl}/getHeremapsRouting`, { params });

  return response.data.data;
};

export const uploadFile = async (file: any) => {
  const response = await api.post(`${DBurl}/uploadFile`, file);

  return response.data.data;
};

export const getOrderName = async () => {
  const response = await api.get(`${DBurl}/generateOrderName`);

  return response.data.data;
};

export const getDataFromFile = async (params: { filename: any }) => {
  const response = await api.get(`${DBurl}/getDataFromFile`, { params });

  return response.data.data;
};

export const getCarIds = async (params: { carList: any }) => {
  const response = await api.get(`${DBurl}/getCar`, { params });

  return response.data.data;
};

export const setFuelCards = async (data: { collection: FuelCard[] }) => {
  const response = await api.post(`${DBurl}/setFuelCards`, {
    mode: "insert",
    data,
  });

  return response.data.data;
};

export const getFuelCards = async () => {
  const response = await api.get(`${DBurl}/getFuelCards`);

  return response.data.data;
};

export const setDriversCosts = async (data: DriverCosts) => {
  const response = await api.post(`${DBurl}/setDriversCosts`, {
    mode: data.id ? "update" : "insert",
    data,
  });

  return response.data.data;
};

export const getDriversCosts = async (params: {
  filter_year: Number;
  filter_month: Number;
  filter_ids_driver_cost_types?: Number[];
  filter_ids_drivers?: Number[];
}) => {
  const response = await api.get(`${DBurl}/getDriversCosts`, { params });

  return response.data.data;
};

export const getDriversCostsReports = async (params: {
  filter_year?: number;
  filter_non_confirmed?: boolean;
  filter_confirmed?: boolean;
}) => {
  const response = await api.get(`${DBurl}/getDriversCostsReports`, { params });

  return response.data.data;
};

export const getDriversCostsReportData = async (params: {
  year: number;
  month: number;
}) => {
  const response = await api.get(`${DBurl}/getDriversCostsReportData`, {
    params,
  });

  return response.data.data;
};

export const setDriversCostsReports = async (data: {
  issue_date: string;
  id?: number;
  is_confirmed?: boolean | null;
  is_confirmed_by?: number | null;
}) => {
  const response = await api.post(`${DBurl}/setDriversCostsReports`, {
    mode: data.id ? "update" : "insert",
    data,
  });

  return response.data.data;
};

export const getCommunicatorUsers = async () => {
  const response = await api.get(`${DBurl}/getCommunicatorUsers`);

  return response.data.data;
};

export const setNotesTemplates = async (data: {
  id?: number;
  name: string;
  note: string;
}) => {
  const response = await api.post(`${DBurl}/setNotesTemplates`, {
    mode: data.id ? "update" : "insert",
    data,
  });

  return response.data.data;
};

export const getNotesTemplates = async (): Promise<NoteTemplate[]> => {
  const response = await api.get(`${DBurl}/getNotesTemplates`);

  return response.data.data;
};

export const deleteNotesTemplates = async (data: { ids: number[] }) => {
  const response = await api.post(`${DBurl}/setNotesTemplates`, {
    mode: "delete",
    data,
  });

  return response.data.data;
};

export const authUser = async (data: { login: string; password: string }) => {
  const response = await api.post(`${DBurl}/auth/`, data);

  return response.data.data;
};

export const resetUser = async (data: { email: string }) => {
  const response = await api.post(`${DBurl}/recoverPassword/`, data);

  return response.data.data;
};

export const getWorkingTime = async () => {
  const response = await api.get(`${DBurl}/getWorkingTime`);

  return response.data.data;
};

export const getExploitation = async (params: {
  dateFrom: string;
  dateTo: string;
}) => {
  const response = await api.get(`${DBurl}/getExploitation`, {
    params,
  });

  return response.data.data;
};

export const getInvoiceTags = async () => {
  const response = await api.get(`${DBurl}/getInvoiceTags`);

  return response.data.data;
};

export const insertInvoiceTag = async (data: {
  name: string;
  color: string;
}) => {
  const response = await api.post(`${DBurl}/setInvoiceTags`, {
    mode: "insert",
    data,
  });

  return response.data.data;
};

export const updateInvoiceTag = async (data: {
  id: number;
  name: string;
  color: string;
}) => {
  const response = await api.post(`${DBurl}/setInvoiceTags`, {
    mode: "update",
    data,
  });

  return response.data.data;
};

export const deleteInvoiceTags = async (data: { ids: number[] }) => {
  const response = await api.post(`${DBurl}/setInvoiceTags`, {
    mode: "delete",
    data,
  });

  return response.data.data;
};

export const deleteDrivers = async (ids: number[]) => {
  const response = await api.post(`${DBurl}/setDrivers`, {
    mode: "delete",
    data: { ids },
  });

  return response.data.data;
};

export const getCustomersDepartments = async () => {
  const response = await api.get(`${DBurl}/getCustomersDepartments`);

  return response.data.data;
};

export const deleteCustomersDepartments = async (ids: number[]) => {
  const response = await api.post(`${DBurl}/setCustomersDepartments`, {
    mode: "delete",
    data: { ids },
  });

  return response.data.data;
};

export const logout = async () => {
  const response = await api.get(`${DBurl}/logout`);

  return response.data.data;
};

export const deleteOrders = async (ids: number[]) => {
  const response = await api.post(`${DBurl}/setOrders`, {
    mode: "delete",
    data: { ids },
  });

  return response.data.data;
};

export const getExchangeRateForCode = async (params: {
  code: string;
  data: string;
}) => {
  const response = await api.get(`${DBurl}/getExchangeRateForCode`, { params });

  return response.data.data;
};

export const getOrderDriverActivityRaportData = async (params: {
  from: string;
  to: string;
  ids_drivers: number[];
}) => {
  const response = await api.get(`${DBurl}/getOrderDriverActivityRaportData`, {
    params,
  });

  return response.data.data;
};

export const getCustomers = async () => {
  const response = await api.get(`${DBurl}/getCustomers`);

  return response.data.data;
};

export const getTraders = async () => {
  const response = await api.get(`${DBurl}/getTraders`);

  return response.data.data;
};

export const getFirm = async () => {
  const response = await api.get(`${DBurl}/getFirm`);

  return response.data.data;
};

export const deleteCustomers = async (ids: number[]) => {
  const response = await api.post(`${DBurl}/setCustomers`, {
    mode: "delete",
    data: { ids },
  });

  return response.data.data;
};

export const getDocReport = async (params: { what: string; range: number }) => {
  const response = await api.get(`${DBurl}/getDocReport`, {
    params,
  });

  return response.data.data;
};

export const deleteCostsFromOrders = async (ids: number[]) => {
  const response = await api.post(`${DBurl}/setCostForOrder`, {
    mode: "delete",
    data: { ids },
  });

  return response.data.data;
};

export const getOrderDriverActivities = async (params: {
  without_order: boolean;
  with_costs: boolean;
  with_refuelings: boolean;
  with_statuses: boolean;
}) => {
  const response = await api.get(`${DBurl}/getOrderDriverActivities`, {
    params,
  });

  return response.data.data;
};

export const getDriversNoSlaves = async () => {
  const response = await api.get(`${DBurl}/getDriversNoSlaves`);

  return response.data.data;
};

export const deleteTransportSets = async (ids: number[]) => {
  const response = await api.post(`${DBurl}/setTransportSets`, {
    mode: "delete",
    data: { ids },
  });

  return response.data.data;
};
