export const nationOptions = [
    {value: "Albańska", label: "Albańska"},
    {value: "Andorska", label: "Andorska"},
    {value: "Austriacka", label: "Austriacka"},
    {value: "Belgijska", label: "Belgijska"},
    {value: "Białoruska", label: "Białoruska"},
    {value: "Bośniacka", label: "Bośniacka"},
    {value: "Bułgarska", label: "Bułgarska"},
    {value: "Chorwacka", label: "Chorwacka"},
    {value: "Czeska", label: "Czeska"},
    {value: "Duńska", label: "Duńska"},
    {value: "Estońska", label: "Estońska"},
    {value: "Fińska", label: "Fińska"},
    {value: "Francuska", label: "Francuska"},
    {value: "Grecka", label: "Grecka"},
    {value: "Hiszpańska", label: "Hiszpańska"},
    {value: "Holenderska", label: "Holenderska"},
    {value: "Irlandzka", label: "Irlandzka"},
    {value: "Islandzka", label: "Islandzka"},
    {value: "Liechtensteińska", label: "Liechtensteińska"},
    {value: "Litewska", label: "Litewska"},
    {value: "Łotewska", label: "Łotewska"},
    {value: "Luksemburska", label: "Luksemburska"},
    {value: "Macedońska", label: "Macedońska"},
    {value: "Maltańska", label: "Maltańska"},
    {value: "Mołdawska", label: "Mołdawska"},
    {value: "Monakijska", label: "Monakijska"},
    {value: "Niemiecka", label: "Niemiecka"},
    {value: "Norweska", label: "Norweska"},
    {value: "Polska", label: "Polska"},
    {value: "Portugalska", label: "Portugalska"},
    {value: "Rosyjska", label: "Rosyjska"},
    {value: "Rumuńska", label: "Rumuńska"},
    {value: "Serbska", label: "Serbska"},
    {value: "Słowacka", label: "Słowacka"},
    {value: "Słoweńska", label: "Słoweńska"},
    {value: "Szwajcarska", label: "Szwajcarska"},
    {value: "Szwedzka", label: "Szwedzka"},
    {value: "Turecka", label: "Turecka"},
    {value: "Ukraińska", label: "Ukraińska"},
    {value: "Węgierska", label: "Węgierska"},
    {value: "Włoska", label: "Włoska"}
]