
import React, { useEffect } from 'react';
import styles from '../../styles/newOrEdit.module.css';
import styles2 from '../table.module.css';
import Checkbox from '@material-ui/core/Checkbox';
import IcoBtn from '../../components/buttons/icoBtn';

import Grid from '@material-ui/core/Grid';
import Input from '../../components/input';
import Select from 'react-select';

export default function invoiceProductsText(props) {

	const {data,propKey,correction_services,addRemoveCorrection} = props

	return (
		<>
			<Grid item xs={1} className={styles.invoiceID}>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Checkbox 
							size="small" 
							color="primary" 
							className={styles2.checkbox}
							// checked={}
							onChange={()=>addRemoveCorrection(data)}
						/>
					</Grid>
					<Grid item xs={6} className={styles2.correctionText}>
						{props.data.id}
					</Grid>
				</Grid>

			</Grid>
			<Grid item xs={2} className={styles2.correctionText}>
				<div className={`${styles.inp} ${styles.invoice}`}>
					{props.data.name.label}
				</div>
				{/* <Input className={styles.invoiceMd} value={props.data.name} handleChange={props.updateService(props.propKey)} name="name" /> */}
			</Grid>
			<Grid item xs={2} className={styles2.correctionText}>
				<div className={`${styles.inp} ${styles.invoice}`}>
					{props.data.order}
				</div>
			</Grid>
			<Grid item xs={1} className={styles2.correctionText}>
				{props.data.price_netto}
			</Grid>
			<Grid item xs={1} className={styles2.correctionText}>
				{props.data.quantity}
			</Grid>
			<Grid item xs={1} className={styles2.correctionText}>
				<div className={`${styles.inp} ${styles.invoice}`}>
					{props.data.vat.label}
				</div>
			</Grid>
			<Grid item xs={1} className={styles2.correctionText}>
				{props.data.value_netto}
			</Grid>
			<Grid item xs={2} className={styles2.correctionText}>
				{props.data.value_brutto}
			</Grid>
			<Grid item xs={1} className={styles.buttonCont}>

			</Grid>
		</>
	)
}
