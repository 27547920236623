import React, { useState, useEffect } from 'react'
import Title from '../../components/title'
import { DBurl } from '../../appConfig'
import { Check, ArrowRight, Info, X, Save, RefreshCw } from 'react-feather';
import styles from '../table.module.css'
import BTNstyles from '../../styles/button.module.css'
import Pagination from '../../components/pagination'
import Button from '@material-ui/core/Button';
import NewOrder from './newOrder'
import stylesMod from '../../styles/newOrEdit.module.css'
import { Table, TableBody, TableCell, IconButton, TableContainer, TableHead, TableRow, TableSortLabel, Paper, Checkbox, Slide, Snackbar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';

import Input from '../../components/input'
import IcoBtn from '../../components/buttons/icoBtn';

import { makeStyles } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{ id: "order_no", numeric: true, disablePadding: false, label: "nr Zlecenia", width: "100px" },
	{ id: "order_name", numeric: true, disablePadding: false, label: "Nazwa zlecenia", width: "150px" },
	{ id: "order_description", numeric: true, disablePadding: false, label: "Opis zlecenia", width: "250px" },
	{ id: "order_start", numeric: true, disablePadding: false, label: "Start", width: "100px" },
	{ id: "order_stop", numeric: true, disablePadding: false, label: "Stop", width: "100px" },
	{ id: 'status', numeric: true, disablePadding: false, label: 'Status', width: "100px", noSort: true },
	{ id: 'buttons', numeric: true, disablePadding: false, label: '', width: "100px" },
];

function EnhancedTableHead(props) {
	const { styles, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead className={styles.tabHead}>
			<TableRow>
				<TableCell className={styles.checkbox} style={{wdth:'50px'}}>
					<Checkbox
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{ 'aria-label': 'select all id' }}

					/>
				</TableCell>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? 'right' : 'left'}
						padding={headCell.disablePadding ? 'none' : 'default'}
						sortDirection={orderBy === headCell.id ? order : false}
						style={{ width: `${headCell.width || "auto"}` }}
					>
						{!headCell.noSort ? <TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={styles.visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</span>
							) : null}
						</TableSortLabel> : headCell.label}
					</TableCell>
				))}
				{/* <TableCell /> */}
			</TableRow>
		</TableHead>
	);
}

function useInput({ type, size }) {
	const [value, setValue] = useState("")
	const input = <TableCell className={styles.inputCell}><Input value={value} handleChange={e => {
		setValue(e.target.value)
	}
	} type={type}
		search={true}
		className={size} /></TableCell>;
	return [value, input];
}

const useStyles = makeStyles(theme => ({
	cell_long: {
	  width: 600,
	  minWidth: 1,
	},
	cell_short: {
		width: 100,
	},

}));


export default function OrderList_S(props) {

	const [table, setTable] = useState([])
	const [templates, setTemplates] = useState([])

	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('short');
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(15);
	const [searchOrder, setSearchOrder] = useInput({ type: "text" });
	const [searchClient, setSearchClient] = useInput({ type: "text" });
	const [searchCargoName, setSearchCargoName] = useInput({ type: "text" })
	const [searchStatus, setSearchStatus] = useInput({ type: "text" })
	const [deleteData, setDeleteData] = React.useState([])
	const [templateOn, setTemplateOn] = React.useState(false)

	const [openModalDelete, setOpenModalDelete] = React.useState(false);

	const [openDialog, setOpenDialog] = useState(false)
	const [dialogContent, setDialogContent] = useState("")
	const [dialogColor, setDialogColor] = useState("error")

	const classes = useStyles();

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenDialog(false)
	}

	function setStatus(status) {
		switch (status) {
			case 0:
				return (<span className={styles.status_s}><Check className={styles.ok} /> Zakończone</span>)
			case 1:
				return (<span className={styles.status_s}><ArrowRight className={styles.alert} /> W trakcie</span>)
			case 2:
				return (<span className={styles.status_s}><Info className={styles.alert} /> Wymaga uwagi</span>)
			case 3:
				return (<span className={styles.status_s}><X className={styles.err} /> Anulowane</span>)
			case 4:
				return (<span className={styles.status_s}><Save className={styles.alert} /> Rezerwacja</span>)
			case 5:
				return (<span className={styles.status_s}><Save className={styles.alert} /> Szablon</span>)
			default:
				console.log(`### Błąd - błędny status lub jego brak : ${status}`)
				return (<span className={styles.status_s}><X className={styles.err} /> Błąd statusu</span>)
		}
	}

	const getOrders = async function () {
		props.loading(true)
		const response = await fetch(`${DBurl}/getOrdersS`, {
			method: "GET",
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": props.user.csrf_token
			},
		})
		const json = await response.json()
		if (json.success) {
			const tempData = [...json.data]
			tempData.forEach(e => {
				e.reservation = e.status === 4 ? true : false
				e.status = setStatus(e.status)
			})
			setTable(tempData)
			props.loading(false)

			return json
		} else {
			setOpenDialog(true)
			setDialogContent(`${json.responseDescription} // ${json.response}  `)
			setDialogColor("error")
			props.loading(false)
		}
	}

	useEffect(() => {
		getOrders();
	}, [])

	const delOrders = async function (arrayOfIds) {
		props.loading(true)
		const response = await fetch(`${DBurl}/setOrders/`, {
			method: "post",
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": props.user.csrf_token
			},
			body: JSON.stringify({
				mode: 'delete',
				data: {
					ids: arrayOfIds
				}
			})
		})
		const json = await response.json()

		if (json.success) {
			setOpenDialog(true)
			setDialogContent(`Dane zostały usunięte `)
			setDialogColor("success")
			getOrders()
			props.loading(false)
			return json
		} else {
			setOpenDialog(true)
			setDialogContent(`${json.responseDescription} // ${json.response}  `)
			setDialogColor("error")
			props.loading(false)
		}
	}

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = table.map((n) => n.id);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name) => {

		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}
		setSelected(newSelected);
	};

	const paginate = pageNumber => setPage(pageNumber - 1);
	const isSelected = (name) => selected.indexOf(name) !== -1;

	const dateString = (type, row) => {
		let tl = row.locations.find(e => e.type === type)
		let streetAndApart = tl.apartment_no ? `${tl.street_no}/${tl.apartment_no}` : tl.street_no
		return `${!!tl.street ? tl.street : ""} ${!!streetAndApart ? streetAndApart : ""}, ${!!tl.zipcode ? tl.zipcode : ""} ${!!tl.city ? tl.city : ""}, ${!!tl.district ? tl.district : ""}`
	}

	const openNewOrder = (row, type) => {

		const tempData = {
			permission_id: 7,
			tabName: type || 'Nowe zlecenie',
			isEdit: type === 'Nowe zlecenie' ? false : true,
			tabID: null,
			component: NewOrder,
			active: true,
			fetchOrderId: row.id,
			order_type_S: true,
			order_start: row.order_start,
			order_stop: row.order_stop,
		}

		props.addTab('newOrder', tempData)
	}

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, table.length - page * rowsPerPage);

	const search = (tbl) => {

		return tbl.filter((row) => {

			let locLoading = row.locations.filter((e) => e.type === "loading")
			return row.order_no.toString().toLowerCase().indexOf(searchOrder) > -1 &&
				row.status.props.children[1].toString().toLowerCase().indexOf(searchStatus) > -1
		})
		
	}

	return (
		<>
			<Paper className={styles.paper}>
				<Title
					title={props.state.tabName}
					btnBox
					btnAdd
					addFunc={e => props.addTab('newOrder')}
					btnDelete={selected.length > 0 ? true : "disabled"}
					deleteFunc={e => setOpenModalDelete(true)}
				/>
			</Paper>
			
			<TableContainer className={styles.stickyTable}>
				<Table
					stickyHeader={true}
					className={styles.table}
					aria-labelledby="tableTitle"
					aria-label="enhanced table"
					size={"small"}
				>
					<EnhancedTableHead
						styles={styles}
						numSelected={selected.length}
						order={order}
						orderBy={orderBy}
						onSelectAllClick={handleSelectAllClick}
						onRequestSort={handleRequestSort}
						rowCount={templateOn ? templates.length : table.length}
					/>
					<TableBody className={styles.tabBody}>
	
						{
							stableSort(search(templateOn ? templates : table), getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => {
									const isItemSelected = isSelected(row.id);
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											onClick={(event) => handleClick(event, row.id)}
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row.id}
											selected={isItemSelected}
											className={row.reservation ? styles.reservation : ""}
										>
											<TableCell className={styles.checkbox} >
												<Checkbox
													checked={isItemSelected}
													color="primary"
													inputProps={{ 'aria-labelledby': labelId }}
												/>
											</TableCell>

											<TableCell component="th" id={labelId} align="right" scope="row" >{row.order_no}</TableCell>
											<TableCell component="th" id={labelId} align="right" scope="row" >{row.order_name}</TableCell>
											<TableCell component="th" id={labelId} align="right" scope="row" >{row.order_description}</TableCell>
											<TableCell component="th" id={labelId} align="right" scope="row" >{row.order_start}</TableCell>
											<TableCell component="th" id={labelId} align="right" scope="row" >{row.order_stop}</TableCell>
											<TableCell align="right">{row.status}</TableCell>
											<TableCell align="right" >
												<div className={styles.buttonBox}>
													<IcoBtn icon="Copy" tooltip={templateOn ? "Użyj szablonu" : "Kopiuj"} value={row} onClick={(e) => {
														openNewOrder(row, "Nowe zlecenie")
													}} />
													<IcoBtn icon="Edit" tooltip="Edytuj" value={row} onClick={(e) => {
														openNewOrder(row, "Edytuj zlecenie")
													}}
													/>
													<IcoBtn icon="X" tooltip="Usuń" className="btn-delete" value={row} onClick={(e) => {
														setDeleteData([row.id])
														setOpenModalDelete(true)

													}} />
												</div>
											</TableCell>
										</TableRow>
									);
								})}
						{emptyRows > 0 && (
							<TableRow style={{ height: 33 * emptyRows }}>
								<TableCell colSpan={6} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>


			<div className={styles.tableBottom} >
				<div>
					<Button size="small" variant="outlined" value="15" onClick={() => { setRowsPerPage(15) }}>15</Button>
					{(templateOn ? templates.length : table.length) > 15 ?
						<Button size="small" variant="outlined" value="30" onClick={() => {
							setRowsPerPage(
								(templateOn ? templates.length :
									table.length) > 30 ? 30 :
									(templateOn ? templates.length : table.length))
						}}>{(templateOn ?
							templates.length : table.length) > 30 ? 30 : (templateOn ?
								templates.length : table.length)}</Button> : null}
					{(templateOn ? templates.length : table.length) > 30 ?
						<Button size="small" variant="outlined" value="60" onClick={() => {
							setRowsPerPage((templateOn ?
								templates.length : table.length) > 60 ?
								60 : (templateOn ? templates.length : table.length))
						}}>{(templateOn ?
							templates.length : table.length) > 60 ?
							60 : (templateOn ? templates.length : table.length)}</Button> : null}
					{(templateOn ? templates.length : table.length) > 60 ?
						<Button size="small" variant="outlined" value="120" onClick={() => {
							setRowsPerPage((templateOn ? templates.length : table.length) > 120 ?
								120 : (templateOn ? templates.length : table.length))
						}}>{(templateOn ?
							templates.length : table.length) > 120 ?
							120 : (templateOn ? templates.length : table.length)}</Button> : null}
					{(templateOn ? templates.length : table.length) > 120 ?
						<Button size="small" variant="outlined" value="200" onClick={() => {
							setRowsPerPage((templateOn ?
								templates.length : table.length) > 200 ? 200 : (templateOn ?
									templates.length : table.length))
						}}>{(templateOn ? templates.length : table.length) > 200 ? 200 : (templateOn ?
							templates.length : table.length)}</Button> : null}
				</div>
				<div>
					<Pagination
						postsPerPage={rowsPerPage}
						totalPosts={templateOn ? templates.length : table.length}
						paginate={paginate}
					/>
				</div>
			</div>

			<Dialog
				open={openModalDelete}
				TransitionComponent={Transition}
				onClose={() => {
					setDeleteData([])
					setOpenModalDelete(false)
				}}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-slide-title" className={stylesMod.dialogHead}>Czy napewno chcesz usunąć ?			<IconButton aria-label="close" className={stylesMod.closeButton} onClick={() => {
					setDeleteData([])
					setOpenModalDelete(false)
				}}>
					<X />
				</IconButton>
				</DialogTitle >
				<DialogContent>
					{deleteData.length > 0 ?
						<DialogContentText id="alert-dialog-slide-description">
							Czy napewno chcesz usunąć zlecenia: {
								deleteData.map(e => {
									const tempOrder = table.find(el => el.id === e)
									return <><br />Zlecenie nr {tempOrder ? tempOrder.order_no : null}</>
								})}
						</DialogContentText> :
						<DialogContentText id="alert-dialog-slide-description">
							Czy napewno chcesz usunąć zlecenie: {
								selected.map(e => {
									const tempOrder = table.find(el => el.id === e)
									return <><br />Zlecenie nr {tempOrder ? tempOrder.order_no : null}</>
								})}
						</DialogContentText>}
				</DialogContent>
				<DialogActions>
					<Button variant="contained" size="large" color="primary" className={BTNstyles.btn} onClick={(e) => {
						setOpenModalDelete(false)
					}}>
						Anuluj
					</Button>
					<Button variant="contained" size="large" className={`${BTNstyles.btn} ${BTNstyles.err}`} onClick={() => {
						delOrders(deleteData.length > 0 ? deleteData : selected)
						setSelected([])
						setOpenModalDelete(false)
						setDeleteData([])
					}}
						color="primary">
						Usuń <X className={BTNstyles.downBtnIco} />
					</Button>
				</DialogActions>
			</Dialog>

			<Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
				<Alert onClose={handleClose} severity={dialogColor}>
					{dialogContent}
				</Alert>
			</Snackbar>

		</>
	)


}
