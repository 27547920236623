import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useQuery, useMutation } from "react-query";
import { perPageButtons } from "containers/order/utilities/constants";
import OrderPerPageButton from "containers/order/OrderPerPageButton";
import Pagination from "components/pagination";
import IcoBtn from "components/buttons/icoBtn";
import TmsLoading from "components/TmsLoading";
import {
  getNotesTemplates,
  setNotesTemplates,
  deleteNotesTemplates,
} from "api/endpoints";

import styles from "containers/table.module.css";
import "./styles.sass";

const DriverNotes = ({
  modalMode,
  handleUpdateFromTemplate,
  setIsTemplateModalOpen,
  createTemplate,
  setCreateTemplate,
  setDialog,
}) => {
  const [notes, setNotes] = useState([]);
  const [editNote, setEditNote] = useState();
  const [deleteNoteId, setDeleteNoteId] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [page, setPage] = useState(0);

  const paginate = (pageNumber) => setPage(pageNumber - 1);

  const {
    data: notesData,
    refetch: refetchNotes,
    isFetching,
    isLoading,
  } = useQuery("getNotesTemplates", getNotesTemplates, {
    onSuccess: (response) => {
      const sortedNotes =
        response?.length > 0 ? response.sort((a, b) => b.id - a.id) : [];

      setNotes(sortedNotes);

      if (createTemplate) {
        setNotes([createTemplate, ...sortedNotes]);
        setEditNote(createTemplate);
      }
    },
  });

  const { mutate: createNewTemplate } = useMutation(
    "setNotesTemplates",
    setNotesTemplates,
    {
      onSuccess: () => {
        refetchNotes();
        setDialog({
          isOpen: true,
          type: "success",
          content: "Szablon utworzony",
        });
      },
    }
  );

  const { mutate: updateTemplate } = useMutation(
    "setNotesTemplates",
    setNotesTemplates,
    {
      onSuccess: () => {
        refetchNotes();
        setDialog({
          isOpen: true,
          type: "success",
          content: "Szablon zaktualizowany",
        });
      },
    }
  );

  const { mutate: deleteTemplate } = useMutation(
    "deleteNotesTemplates",
    deleteNotesTemplates,
    {
      onSuccess: () => {
        refetchNotes();
        setDialog({
          isOpen: true,
          type: "success",
          content: "Szablon usunięty pomyślnie",
        });
      },
    }
  );

  const handleUpdateNote = () => {
    if (!editNote?.name || !editNote?.note) {
      setDialog({ isOpen: true, type: "error", content: "Wypełnij pola" });
      return;
    }

    if (editNote?.new) {
      if (createTemplate) {
        setCreateTemplate(null);
        setIsTemplateModalOpen(false);
      }
      createNewTemplate({
        name: editNote.name,
        note: editNote.note,
      });
    } else {
      updateTemplate({
        id: editNote.id,
        name: editNote.name,
        note: editNote.note,
      });
    }

    setEditNote(null);
  };

  const handleCreateNote = () => {
    const newNote = { id: 0, name: "", note: "", new: true };

    setNotes([newNote, ...notes]);
    setEditNote(newNote);
  };

  const handleX = (id) => {
    if (editNote?.new) {
      setNotes(notesData);
    }

    if (editNote?.id === id) {
      setEditNote(null);
    } else {
      setDeleteNoteId(id);
    }
  };

  const handleDeleteNote = (id) => {
    deleteTemplate({ ids: [id] });
  };

  const handleSelectTemplate = (note) => {
    handleUpdateFromTemplate(note);
    setIsTemplateModalOpen(false);
  };

  return (
    <>
      {!modalMode && (
        <div className="notes-header">
          <div className="notes-header--left">
            <h1 className="notes-header__title">Notatki dla kierowców</h1>
            <div className="notes-header__buttons">
              <button
                onClick={handleCreateNote}
                className="notes-header__button"
              >
                Dodaj notatkę
              </button>
            </div>
          </div>
          <div className="notes-header--right"></div>
        </div>
      )}
      {!isFetching || !isLoading ? (
        <>
          <TableContainer className="notes-table">
            <Table stickyHeader>
              <TableHead className="notes-table__header">
                <TableRow>
                  <TableCell size="medium" padding="normal">
                    <label className="notes-table__cell">Nazwa szablonu</label>
                  </TableCell>
                  <TableCell size="medium" padding="normal">
                    <label className="notes-table__cell">Notatka</label>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {notes
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((note) => {
                    const isEdit = editNote?.id === note.id;

                    return (
                      <TableRow key={note.id}>
                        <TableCell>
                          <textarea
                            disabled={!isEdit}
                            className={!isEdit ? "notes-table__text-area" : ""}
                            rows={1}
                            value={isEdit ? editNote.name : note.name}
                            onChange={(e) => {
                              setEditNote({
                                ...editNote,
                                name: e.target.value,
                              });
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <textarea
                            disabled={!isEdit}
                            className={!isEdit ? "notes-table__text-area" : ""}
                            rows={5}
                            value={isEdit ? editNote.note : note.note}
                            onChange={(e) => {
                              setEditNote({
                                ...editNote,
                                note: e.target.value,
                              });
                            }}
                          />
                        </TableCell>
                        <TableCell />
                        <TableCell align="right">
                          {deleteNoteId !== note.id ? (
                            <div className="notes-table__options">
                              {modalMode && !isEdit && (
                                <IcoBtn
                                  style={{ marginRight: "10px" }}
                                  icon="Check"
                                  tooltip="Wybierz szablon"
                                  onClick={() => handleSelectTemplate(note)}
                                />
                              )}
                              {isEdit ? (
                                <IcoBtn
                                  icon="Save"
                                  tooltip="Zapisz"
                                  onClick={handleUpdateNote}
                                />
                              ) : (
                                <IcoBtn
                                  icon="Edit"
                                  tooltip="Edytuj"
                                  onClick={() => {
                                    setEditNote(note);
                                  }}
                                />
                              )}
                              <IcoBtn
                                icon={isEdit ? "X" : "Trash"}
                                tooltip="Usuń"
                                onClick={() => handleX(note.id)}
                              />
                            </div>
                          ) : (
                            <div>
                              <p style={{ color: "#000" }}>Usunąć?</p>
                              <div className="notes-table__options">
                                <IcoBtn
                                  icon="Check"
                                  tooltip="Zapisz"
                                  onClick={() => handleDeleteNote(note.id)}
                                />
                                <IcoBtn
                                  icon="X"
                                  tooltip="Usuń"
                                  onClick={() => setDeleteNoteId(null)}
                                />
                              </div>
                            </div>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            className={styles.tableBottom}
            style={
              modalMode
                ? {
                    position: "absolute",
                    bottom: 0,
                  }
                : {
                    width: "calc(100vw - 200px)",
                    position: "fixed",
                    bottom: 0,
                  }
            }
          >
            <div>
              {perPageButtons.map(({ value, minNumber }) => (
                <OrderPerPageButton
                  key={value}
                  tableLength={notes ? notes.length : 0}
                  minNumber={minNumber}
                  value={value}
                  templatesLength={0}
                  setRowsPerPage={setRowsPerPage}
                  rowsPerPage={rowsPerPage}
                />
              ))}
            </div>
            <div>
              <Pagination
                postsPerPage={rowsPerPage}
                totalPosts={notes?.length}
                paginate={paginate}
                page={page}
              />
            </div>
          </div>
        </>
      ) : (
        <TmsLoading status="Pobieranie notatek" />
      )}
    </>
  );
};

export default DriverNotes;
