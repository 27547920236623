import React, { useState } from "react";
import styles from "./title.module.css";
import { Button, IconButton, TableCell, Checkbox } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import {
  X,
  Upload,
  Download,
  Maximize2,
  ChevronUp,
  Share,
  Tool,
  RefreshCw,
  ArrowLeft,
  Calendar,
  Search,
  CheckCircle,
} from "react-feather";
import BTNstyles from "../styles/button.module.css";
import Select, { components } from "react-select";
import stylesMod from "../styles/newOrEdit.module.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { BsFillFuelPumpFill } from "react-icons/bs";
import Input from "./input";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import plLocale from "date-fns/locale/pl";
import { AppName } from "appConfig";

export default function Title(props) {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const { selectedCurrency } = props;
  const CustomInputFrom = React.forwardRef((props, ref) => {
    return (
      <div className={styles.datapicker}>
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <Calendar onClick={props.onClick} />
      </div>
    );
  });
  const CustomInputTo = React.forwardRef((props, ref) => {
    return (
      <div className={styles.datapicker}>
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <Calendar onClick={props.onClick} />
      </div>
    );
  });

  return (
    <div className={styles.title}>
      {props.title}
      {props.btnBox && (
        <div className={`${BTNstyles.btnRow} ${BTNstyles.l50} ${"BTNbar"}`}>
          {props.btnClients && (
            <Button
              variant="contained"
              color="error"
              size="large"
              className={
                !props.btnClientsActive
                  ? BTNstyles.titleBtn
                  : BTNstyles.titleBtnActive
              }
              onClick={(e) => props.btnClientsFunc()}
            >
              Wybierz klienta / grupę
            </Button>
          )}

          {props.btnDrivers && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={
                !props.btnDriversActive
                  ? BTNstyles.titleBtn
                  : BTNstyles.titleBtnActive
              }
              onClick={(e) => props.btnDriversFunc()}
            >
              Wybierz kierowcę / grupę
            </Button>
          )}

          {props.btnCars && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={
                !props.btnCarsActive
                  ? BTNstyles.titleBtn
                  : BTNstyles.titleBtnActive
              }
              onClick={(e) => props.btnCarsFunc()}
            >
              Wybierz pojazd / grupę
            </Button>
          )}

          {props.btnChangeTrailerPPP && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={`${BTNstyles.titleBtn} ${
                props.activeTrailerPPP && BTNstyles.activeBtn
              }`}
              onClick={(e) => props.changeTrailerPPP()}
            >
              Przepinka
              {/* <Share className={BTNstyles.downBtnIco} /> */}
            </Button>
          )}

          {props.btnChangeDriverPPP && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={`${BTNstyles.titleBtn} ${
                props.activeDriverPPP && BTNstyles.activeBtn
              }`}
              onClick={(e) => props.changeDriverPPP()}
            >
              Przesiadka
              {/* <Share className={BTNstyles.downBtnIco} /> */}
            </Button>
          )}

          {props.btnTranshipmentPPP && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={`${BTNstyles.titleBtn} ${
                props.activeTranshipmentPPP && BTNstyles.activeBtn
              }`}
              onClick={(e) => props.transhipmentPPP()}
            >
              Przeładunek
              {/* <Share className={BTNstyles.downBtnIco} /> */}
            </Button>
          )}

          {props.btnResetPPP && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={`${BTNstyles.titleBtn} ${BTNstyles.smallBtn}`}
              disabled={!props.activeResetPPP}
              onClick={(e) => props.resetPPP()}
            >
              <RefreshCw className={BTNstyles.downBtnIco} />
            </Button>
          )}

          {props.btnConfirm && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={props.disabled}
              className={BTNstyles.titleBtn}
              onClick={(e) => props.confirmFunc()}
            >
              Zatwierdź <CheckCircle className={BTNstyles.downBtnIco} />
            </Button>
          )}

          {props.btnGenerate && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={BTNstyles.titleBtn}
              onClick={(e) => props.generateFunc()}
            >
              Generuj <Tool className={BTNstyles.downBtnIco} />
            </Button>
          )}

          {props.btnExport && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={BTNstyles.titleBtn}
              onClick={(e) => props.exportFunc()}
            >
              Eksportuj {props.exportFileType}{" "}
              <Share className={BTNstyles.downBtnIco} />
            </Button>
          )}
          {props.btnImport && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={BTNstyles.titleBtn}
              onClick={(e) => props.importFunc()}
            >
              Importuj <Download className={BTNstyles.downBtnIco} />
            </Button>
          )}
          {props.btnImportFv && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={BTNstyles.titleBtn}
              onClick={(e) => props.importFuncFv()}
            >
              Importuj <Download className={BTNstyles.downBtnIco} />
            </Button>
          )}

          {props.checkbox && (
            <div className={BTNstyles.checkboxContainer}>
              <input
                type="checkbox"
                checked={props.checked}
                onChange={() => props.handleCheckbox()}
              />
              {props.checkboxText}
            </div>
          )}
          {props.btnCyclic && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={BTNstyles.titleBtn}
              onClick={(e) => props.cyclicFunc()}
            >
              Cykliczny <RefreshCw className={BTNstyles.downBtnIco} />
            </Button>
          )}
          {props.searchInput && (
            <div className={styles.searchBox}>
              <input
                value={props.searchValue}
                onChange={(e) => {
                  props.setSearchValue(e.target.value);
                }}
                type="text"
                search={true}
                className={styles.searchInput}
                placeholder="Wyszukaj frazę..."
              />
              {!props.searchValue.length && (
                <Search className={styles.searchInputIcon} />
              )}
            </div>
          )}
          {props.btnDelete && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={BTNstyles.titleBtn}
              disabled={props.btnDelete === "disabled"}
              onClick={(e) => props.deleteFunc(e)}
            >
              Usuń{" "}
              <X
                className={`${BTNstyles.errStroke} ${BTNstyles.downBtnIco} ${BTNstyles.BTNdelete}`}
              />
            </Button>
          )}
          {props.btnAdd && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={`${BTNstyles.titleBtn}`}
              onClick={() => props.addFunc()}
            >
              {props.btnAddTxt || "Dodaj"}{" "}
              <Add className={`${BTNstyles.downBtnIco}  ${BTNstyles.BTNadd}`} />
            </Button>
          )}
          {props.showVar && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={BTNstyles.titleBtn}
              onClick={(e) => props.showVarFunc()}
            >
              {`${props.showVarText}`}
            </Button>
          )}
          {props.btnUni && (
            <Button
              variant="contained"
              color={props.btnUni.color || "primary"}
              size="large"
              className={`${BTNstyles.titleBtn}`}
              onClick={() => props.btnUni.Func()}
            >
              {props.btnUni.txt || "Dodaj"} {props.btnUni.ico}
            </Button>
          )}
          {props.switch && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={BTNstyles.titleBtn}
              onClick={(e) => props.switchFunc()}
            >
              {`${props.text}`} <RefreshCw className={BTNstyles.downBtnIco} />
            </Button>
          )}
          {props.plan && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={BTNstyles.titleBtn}
              onClick={(e) => props.planFunc()}
            >
              Zaplanuj urlop <Calendar className={BTNstyles.downBtnIco} />
            </Button>
          )}
          {props.deadlines && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={BTNstyles.titleBtn}
              onClick={(e) => props.deadlinesFunc()}
              disabled={props.showDeadlines ? true : false}
            >
              Terminy
            </Button>
          )}
          {props.documents && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={BTNstyles.titleBtn}
              onClick={(e) => props.docsFunc()}
              disabled={props.showDeadlines ? false : true}
            >
              Dokumenty
            </Button>
          )}
          {props.fuelStations && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={BTNstyles.titleBtn}
              onClick={(e) => props.fuelStationsFunc()}
            >
              Wybierz swoje stacje paliw{" "}
              <BsFillFuelPumpFill style={{ paddingLeft: 2 }} />
            </Button>
          )}
          {props.editCols && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={BTNstyles.titleBtn}
              onClick={(e) => props.editColsFunc()}
            >
              Definiuj dodatkowe kolumny
            </Button>
          )}
          {props.editDates && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={BTNstyles.titleBtn}
              onClick={(e) => props.editDatesFunc()}
            >
              {props.editDatesBtnTxt}
            </Button>
          )}
          {/* {props.btnFullscreen && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={BTNstyles.titleBtn}
              onClick={props.fullscreenFunc}
            >
              {props.fullscreenOn ? "Zmniejsz" : "Pełen ekran"}{" "}
              <Maximize2 className={BTNstyles.downBtnIco} />
            </Button>
          )} */}
          {props.PPPSettings && (
            <div className={styles.right_t0}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={`${BTNstyles.titleBtn}`}
                onClick={() => {
                  props.dialog(true);
                }}
                disabled={props.selected.length > 0 ? false : true}
              >
                Zmień ustawienia
              </Button>
            </div>
          )}

          {props.btnMobileApp && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={`${BTNstyles.titleBtn} ${BTNstyles.btnGreenWithe}`}
              onClick={() => {
                props.mobileAppFunc(true);
              }}
            >
              Pobierz bezpłatną aplikację {AppName} TMS Mobile dla kierowcy{" "}
              <Download className={`${BTNstyles.downBtnIco}`} />
            </Button>
          )}

          {props.rightSide ? (
            <div className={styles.right + " " + styles.rightSide}>
              {props.vindicationTemplate && (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={`${BTNstyles.titleBtn}`}
                  onClick={() => {
                    props.modal(true);
                  }}
                  // disabled={props.selected.length > 0 ? false : true}
                >
                  Szablon ponaglenia
                </Button>
              )}
            </div>
          ) : null}

          {props.btnDocExport && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={BTNstyles.titleBtn}
              onClick={(e) => props.btnDocExportFunc()}
            >
              Eksportuj tabele do pliku{" "}
              <Download className={`${BTNstyles.downBtnIco}`} />
            </Button>
          )}

          {props.btnDocImport && (
            <>
              <input
                accept="*"
                style={{ display: "none" }}
                id="raised-button-file"
                type={props.loggedIn ? "file" : null}
                onChange={(e) => {
                  props.fetchFileXLS([e.target.files]);
                }}
              />
              <label htmlFor="raised-button-file">
                <Button
                  variant="outlined"
                  color="primary"
                  component="div"
                  className={props.class}
                >
                  Importuj dane z pliku{" "}
                  <Upload className={`${BTNstyles.downBtnIco}`} />
                </Button>
              </label>
            </>
          )}

          {props.cornersNew ? (
            <div className={styles.right + " " + styles.newRightCorner}>
              {props.selectDriver && (
                <div className="driver_title">
                  {props.selectDriver.length > 0 ? (
                    <>
                      <div>Kierowca:</div>
                      <Select
                        value={props.e}
                        className={`${stylesMod.select}`}
                        options={props.selectDriver}
                        onChange={(e) => {
                          props.selectDriverFunc(e);
                        }}
                        name="driver"
                        placeholder="Wybierz..."
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            border: "1px solid #142f42",
                            boxShadow: "none",
                            "&:hover": {
                              border: "1px solid #142f42",
                            },
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused && "#142f42",
                            color: state.isFocused && "#fff",
                          }),
                        }}
                      />
                    </>
                  ) : (
                    <CircularProgress color="inherit" size={"10px"} />
                  )}
                </div>
              )}

              {props.refresh}

              {!!props.rightCorner1 && (
                <>
                  {" "}
                  <div>Od:</div> {props.rightCorner1}{" "}
                </>
              )}
              {!!props.rightCorner2 && (
                <>
                  {" "}
                  <div>Do:</div> {props.rightCorner2}{" "}
                </>
              )}

              {!!props.datapicker_from && (
                <>
                  {" "}
                  <div>Od:</div>{" "}
                  {
                    <DatePicker
                      dateFormat="MMMM yyyy"
                      selected={props.fromDate}
                      onChange={(e) => {
                        props.datapicker_from(e);
                      }}
                      locale={plLocale}
                      name="data_od"
                      customInput={<CustomInputFrom />}
                      placeholderText={"Wybierz datę"}
                      showMonthYearPicker
                      showFullMonthYearPicker
                    />
                  }{" "}
                </>
              )}
              {!!props.datapicker_month && (
                <>
                  {" "}
                  <div>Wybierz miesiąc:</div>{" "}
                  {
                    <DatePicker
                      dateFormat="MM-yyyy"
                      selected={props.date}
                      onChange={(e) => {
                        props.datapicker_month(e);
                      }}
                      locale={plLocale}
                      name="data_do"
                      customInput={<CustomInputTo />}
                      placeholderText={"Wybierz miesiąc"}
                      showMonthYearPicker
                    />
                  }{" "}
                </>
              )}

              {!!props.datapicker_to && (
                <>
                  {" "}
                  <div>Do:</div>{" "}
                  {
                    <DatePicker
                      dateFormat="MMMM yyyy"
                      selected={props.toDate}
                      onChange={(e) => {
                        props.datapicker_to(e);
                      }}
                      locale={plLocale}
                      name="data_do"
                      customInput={<CustomInputTo />}
                      placeholderText={"Wybierz datę"}
                      showMonthYearPicker
                      // showFullMonthYearPicker
                    />
                  }{" "}
                </>
              )}
            </div>
          ) : null}

          {props.search ? (
            <div className={styles.right}>
              {props.search}

              {!!props.rightCorner1 && (
                <>
                  {" "}
                  <div>Od:</div> {props.rightCorner1}{" "}
                </>
              )}
              {!!props.rightCorner2 && (
                <>
                  {" "}
                  <div>Do:</div> {props.rightCorner2}{" "}
                </>
              )}
            </div>
          ) : null}
        </div>
      )}
      {props.btnBox && (
        <div className={`${BTNstyles.endBtnRow}`}>
          {!!props.hide && (
            <IconButton
              className={`${BTNstyles.titleIcoBtn} ${
                props.hide?.is && BTNstyles.rotate
              }`}
              onClick={() => props.hide?.set(!props.hide?.is)}
            >
              <ChevronUp />
            </IconButton>
          )}
        </div>
      )}

      {props.selectDepartment && (
        <div className="driver_title" style={{ marginRight: "15px" }}>
          {
            <div className={styles.selectBox}>
              <div>Język tłumaczenia FV:</div>
              <Select
                value={props.e}
                className={`${stylesMod.select}`}
                options={props.selectFvLang}
                onChange={(e) => {
                  props.selectFvLangFunc(e);
                }}
                menuPortalTarget={document.body}
                name="fv_language"
                placeholder="Wybierz..."
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused && "#142f42",
                    color: state.isFocused && "#fff",
                  }),
                  menuPortal: (base) => ({ ...base, zIndex: 10 }),
                }}
              />
            </div>
          }
        </div>
      )}

      {props.selectDepartment && (
        <div className="driver_title">
          {props.selectDepartment.length > 0 ? (
            <div className={styles.selectBox}>
              <div>Oddział księgowy:</div>
              <Select
                value={props.e}
                className={`${stylesMod.select}`}
                options={props.selectDepartment}
                onChange={(e) => {
                  props.selectDepartmentFunc(e);
                }}
                menuPortalTarget={document.body}
                name="department"
                placeholder="Wybierz..."
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused && "#142f42",
                    color: state.isFocused && "#fff",
                  }),
                  menuPortal: (base) => ({ ...base, zIndex: 10 }),
                }}
              />
            </div>
          ) : (
            <CircularProgress
              color="inherit"
              className={`circularProgressMini_title`}
            />
          )}
        </div>
      )}

      {props.selectCurrency && (
        <div className="driver_title">
          {props.selectCurrency.length > 0 ? (
            <>
              <div>Wybierz walutę:</div>
              <Select
                value={
                  selectedCurrency
                    ? { label: selectedCurrency, value: selectedCurrency }
                    : props.e
                }
                className={`${stylesMod.select}`}
                options={props.selectCurrency}
                onChange={(e) => {
                  props.selectCurrencyFunc(e);
                }}
                menuPortalTarget={document.body}
                name="currency"
                placeholder="Wybierz..."
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused && "#142f42",
                    color: state.isFocused && "#fff",
                  }),
                  menuPortal: (base) => ({ ...base, zIndex: 10 }),
                }}
              />
            </>
          ) : (
            <CircularProgress
              color="inherit"
              className={`circularProgressMini_title`}
            />
          )}
        </div>
      )}

      {props.selectCategory && (
        <div className="driver_title">
          {props.selectCategory.length > 0 ? (
            <>
              <div>Kategoria:</div>
              <Select
                value={props.e}
                className={`${stylesMod.select}`}
                options={props.selectCategory}
                onChange={(e) => {
                  props.selectCategoryFunc(e);
                }}
                name="driver"
                placeholder="Wybierz..."
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused && "#142f42",
                    color: state.isFocused && "#fff",
                  }),
                }}
              />
            </>
          ) : (
            <CircularProgress
              color="inherit"
              className={`circularProgressMini_title`}
            />
          )}
        </div>
      )}

      {props.selectRange && (
        <div className="driver_title">
          {props.selectRange.length > 0 ? (
            <>
              <div>Zakres:</div>
              <Select
                value={props.e}
                className={`${stylesMod.select}`}
                options={props.selectRange}
                onChange={(e) => {
                  props.selectRangeFunc(e);
                }}
                name="driver"
                placeholder="Wybierz..."
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused && "#142f42",
                    color: state.isFocused && "#fff",
                  }),
                }}
              />
            </>
          ) : (
            <CircularProgress
              color="inherit"
              className={`circularProgressMini_title`}
            />
          )}
        </div>
      )}
    </div>
  );
}
