import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Input from "../../components/input";
import Title from "../../components/title";
import SubTitle from "../../components/subTitle";
import styles from "../table.module.css";
import RowsPerPage from "../../components/RowsPerPage";
import Pagination from "../../components/pagination";
import {
  Table,
  TableBody,
  TableCell,
  IconButton,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Slide,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Snackbar,
  Checkbox,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { DBurl } from "../../appConfig";
import CiclicDialog from "../../components/CiclicDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import IcoBtn from "../../components/buttons/icoBtn";
import { Button } from "@material-ui/core";
import BTNstyles from "../../styles/button.module.css";
import stylesMod from "../../styles/newOrEdit.module.css";
import {
  X,
  Calendar,
  Copy,
  Check,
  ArrowRight,
  Info,
  Save,
} from "react-feather";
import Grid from "@material-ui/core/Grid";
import { styled } from "@material-ui/styles";
import { Switch, FormControlLabel } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import plLocale from "date-fns/locale/pl";
import moment from "moment";
import { AddressSearch } from "../../components/addressSearch";
import Select from "react-select";
import { makeStyles } from "@material-ui/core/styles";
import { DisplayMapFC } from "./HereMap";

const useStyles = makeStyles((theme) => ({
  "@global .MuiDialog-paper": {
    maxWidth: "850px",
    height: "95%",
  },
}));

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#129865",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#129865",
  },
  "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
    backgroundColor: "#129865",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function descendingComparator(a, b, orderBy) {
  if (orderBy === "refueling length") {
    return ((!!b.refuelings && !b.refuelings[0].brak && b.refuelings?.length) ||
      0) <
      ((!!a.refuelings && !a.refuelings[0].brak && a.refuelings?.length) || 0)
      ? -1
      : ((!!b.refuelings && !b.refuelings[0].brak && b.refuelings?.length) ||
          0) >
        ((!!a.refuelings && !a.refuelings[0].brak && a.refuelings?.length) || 0)
      ? 1
      : 0;
  }
  if (orderBy === "refueling add") {
    return (!!b.refuelings &&
      !b.refuelings[0].brak &&
      countFuel(b.refuelings)) ||
      0 <
        (!!a.refuelings && !a.refuelings[0].brak && countFuel(a.refuelings)) ||
      0
      ? -1
      : (!!b.refuelings && !b.refuelings[0].brak && countFuel(b.refuelings)) ||
        0 >
          (!!a.refuelings &&
            !a.refuelings[0].brak &&
            countFuel(a.refuelings)) ||
        0
      ? 1
      : (!!b.refuelings && !b.refuelings[0].brak && countFuel(b.refuelings)) ||
        0 ===
          (!!a.refuelings && !a.refuelings[0].brak && countFuel(a.refuelings)
            ? 0
            : -1);
  }

  let sortB;
  let sortA;
  if (!!b && typeof b[orderBy] === "string") {
    sortB = b[orderBy].toLowerCase();
  } else {
    sortB = b;
  }
  if (!!a && typeof a[orderBy] === "string") {
    sortA = a[orderBy].toLowerCase();
  } else {
    sortA = a;
  }
  if (sortB < sortA) {
    return -1;
  }
  if (sortB > sortA) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (array?.length > 0) {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
}

const countFuel = (rows) => {
  let result = 0;
  rows && rows.forEach((row) => (result += row.fuel));
  return result;
};

function useInput({ type }, placeholder) {
  const [value, setValue] = useState("");
  const input = (
    <Input
      value={value}
      handleChange={(e) => {
        setValue(e.target.value);
      }}
      type={type}
      placeholder={placeholder}
      className={styles.searchInputSmall}
      search={type === "text" ? true : false}
    />
  );
  return [value, input];
}

export default function OrderActions(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [selectedOrderData, setSelectedOrderData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [searchDriver, setSearchDriver] = useInput(
    { type: "text" },
    "Szukaj kierowcy"
  );
  const [openModal, setOpenModal] = React.useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");
  const [activeTrailerPPP, setActiveTrailerPPP] = useState(false);
  const [activeDriverPPP, setActiveDriverPPP] = useState(false);
  const [activeTranshipmentPPP, setActiveTranshipmentPPP] = useState(false);
  const [activePPP, setActivePPP] = useState(false);
  const [activeHandOverOrdersTable, setActiveHandOverOrdersTable] = useState(
    []
  );
  const [waitingHandOverOrdersTable, setWaitingHandOverOrdersTable] = useState(
    []
  );
  const [isLeave_trailer, setIsLeave_trailer] = useState(false);
  const [isCargo_change_in_warehouse, setIsCargo_change_in_warehouse] =
    useState(false);
  const [is_waiting_TrailerPPP, setIs_waiting_TrailerPPP] = useState(false);
  const [dateTrailerPPP, setDateTrailerPPP] = useState();
  const [dateDriverPPP, setDateDriverPPP] = useState();
  const [dateTranshipmentPPP, setDateTranshipmentPPP] = useState();
  const [adressTrailerPPP, setAdressTrailerPPP] = useState();
  const [mapChangingAdressTrailerPPP, setMapChangingAdressTrailerPPP] =
    useState();
  const [adressDriverPPP, setAdressDriverPPP] = useState();
  const [adressranshipmentPPP, setAdressTranshipmentPPP] = useState();
  const [driversData, setDriversData] = useState();
  const [trailerData, setTrailerData] = useState();
  const [vehiclesData, setVehiclesData] = useState();
  const [transportSets, setTransportSets] = useState();
  const [driversData_options, setDriversData_options] = useState();
  const [trailerData_options, setTrailerData_options] = useState();
  const [vehiclesData_options, setVehiclesData_options] = useState();
  const [transportSets_options, setTransportSets_options] = useState();
  const [selectedDriver, setSelectedDriver] = useState();
  const [selectedCar, setSelectedCar] = useState();
  const [selectedTrailer, setSelectedTrailer] = useState();
  const [selectedTransportSet, setSelectedTransportSet] = useState();
  const [nameTransportSet, setNameTransportSet] = useState();
  const [isTransportSetSeted, setIsTransportSetSeted] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [isSelectedSet, setIsSelectedSet] = useState(false);

  const [ordersForHandOver, setOrdersForHandOver] = useState([]);
  const [ordersForHandOver2, setOrdersForHandOver2] = useState([]);
  const [listModal, setListModal] = useState(false);
  const [isAdressWritten, setIsAdressWritten] = useState(false);

  const [dataPoints, setDataPoints] = useState([]);

  const [isCopy, setIsCopy] = useState(false);

  const [newTransportSetId, setNewTransportSetId] = useState();
  const error = props.error;

  useStyles(props);

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div className={`datapicker_new bg_color`}>
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <Calendar onClick={props.onClick} />
      </div>
    );
  });

  function setStatus(status) {
    switch (status) {
      case 0:
        return (
          <span className={styles.status}>
            <Check className={styles.ok} /> Zakończone
          </span>
        );
      case 1:
        return (
          <span className={styles.status}>
            <ArrowRight className={styles.alert} /> W trakcie
          </span>
        );
      case 2:
        return (
          <span className={styles.status}>
            <Info className={styles.alert} /> Wymaga uwagi
          </span>
        );
      case 3:
        return (
          <span className={styles.status}>
            <X className={styles.err} /> Anulowane
          </span>
        );
      case 4:
        return (
          <span className={styles.status}>
            <Save className={styles.alert} /> Rezerwacja
          </span>
        );
      case 5:
        return (
          <span className={styles.status}>
            <Save className={styles.alert} /> Szablon
          </span>
        );
      default:
        // console.log(`### Błąd - błędny status lub jego brak : ${status}`)
        return (
          <span className={styles.status}>
            <X className={styles.err} /> Błąd statusu
          </span>
        );
    }
  }

  async function getOrdersForHandOver() {
    props.loading(true);

    await fetch(`${DBurl}/getOrdersForHandOver`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setOrdersForHandOver(data.data || []);
          setOrdersForHandOver2(data.data || []);
        } else {
          setOpenDialog(true);
          setDialogContent(`Problem z połęczeniem...`);
          setDialogColor("error");
          setOrdersForHandOver([]);
        }
      })
      .then(() => props.loading(false));
  }

  async function getActiveHandOverOrders() {
    props.loading(true);

    await fetch(`${DBurl}/getActiveHandOverOrders`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setActiveHandOverOrdersTable(data.data || []);
        } else {
          setOpenDialog(true);
          setDialogContent(`Problem z połęczeniem...`);
          setDialogColor("error");
          setActiveHandOverOrdersTable([]);
        }
      })
      .then(() => props.loading(false));
  }

  async function getWaitingHandOverOrders() {
    props.loading(true);

    await fetch(`${DBurl}/getWaitingHandOverOrders`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setWaitingHandOverOrdersTable(data.data || []);
        } else {
          setOpenDialog(true);
          setDialogContent(`Problem z połęczeniem...`);
          setDialogColor("error");
          setWaitingHandOverOrdersTable(data.data || []);
        }
      })
      .then(() => props.loading(false));
  }

  const fetchDrivers = async function () {
    props.loading(true);
    const response = await fetch(`${DBurl}/getDrivers/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      let tempData = json.data.map((v, i) => ({
        value: i,
        label: `${v.firstname} ${v.surname}`,
        id: `${v.id}`,
      }));
      setDriversData(json.data);
      setDriversData_options(tempData);
      props.loading(false);
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  const fetchTrailers = async function () {
    props.loading(true);
    const response = await fetch(`${DBurl}/getTrailers/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      let tempData = json.data.map((v, i) => ({
        value: i,
        label: `${v.name}`,
        id: `${v.id}`,
      }));
      setTrailerData(json.data);
      setTrailerData_options(tempData);
      props.loading(false);
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  const fetchCars = async function () {
    props.loading(true);
    const response = await fetch(`${DBurl}/getCars/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      let tempData = json.data.map((v, i) => ({
        value: i,
        label: `${v.name}`,
        id: `${v.id}`,
      }));
      setVehiclesData(json.data);
      setVehiclesData_options(tempData);
      props.loading(false);
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  const fetchGetsTransportSet = async function () {
    props.loading(true);
    const response = await fetch(`${DBurl}/getTransportSets/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      let tempData = json.data.map((v, i) => ({
        value: i,
        label: `${v.label}`,
        id: `${v.id}`,
      }));
      setTransportSets(json.data);
      setTransportSets_options(tempData);
      props.loading(false);
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  const fetchSetTransportSet = async (data) => {
    props.loading(true);

    const response = await fetch(`${DBurl}/setTransportSets/`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "insert",
        data: {
          label: data[0].label,
          id_car: data[0].id_car,
          id_trailer: data[0].id_trailer,
          id_driver1: data[0].id_driver1,
          id_driver2: data[0].id_driver2,
          is_double_cast_of_drivers: data.id_driver2 ? true : false,
          axle_netto: null,
          is_global: false,
        },
      }),
    });
    const json = await response.json();
    if (json.success) {
      setOpenDialog(true);
      setDialogContent(`Zestaw dodany prawidłowo`);
      setDialogColor("success");
      props.loading(false);

      setIsEdit(false);
      setNewTransportSetId(json.data.new_id);
      fetchGetsTransportSet();
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  const fetchSetCancelHandOverTheOrder = async (id_order) => {
    props.loading(true);
    const response = await fetch(`${DBurl}/setCancelHandOverTheOrder/`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "update",
        data: {
          id_order: id_order,
        },
      }),
    });
    const json = await response.json();
    if (json.success) {
      setOpenDialog(true);
      setDialogContent(`Zlecenie zostało anulowane.`);
      setDialogColor("success");

      setOpenModal(false);
      resetPPPModal();
      resetPPP();

      getActiveHandOverOrders();
      getWaitingHandOverOrders();
      props.loading(false);
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  const fetchSetHandOverTheOrder = async (data) => {
    console.log("data", data);
    //isCargo_change_in_warehouse

    let temp_id_transport_set;
    let temp_data;
    let temp_adress_name;
    let temp_address_lng;
    let temp_address_lat;
    let temp_is_set_changing_transport_set;
    let temp_hand_over_type;
    let temp_is_waiting;
    let temp_routing;

    if (activeTrailerPPP === true) {
      temp_hand_over_type = "0";
    } else if (activeDriverPPP === true) {
      temp_hand_over_type = "1";
    } else if (activeTranshipmentPPP === true) {
      temp_hand_over_type = "2";
    } else {
      temp_hand_over_type = data[0].hand_over_type;
    }

    if (is_waiting_TrailerPPP === true) {
      temp_id_transport_set = null;
      temp_is_set_changing_transport_set = false;
      temp_is_waiting = true;
    } else if (
      isLeave_trailer === true ||
      isCargo_change_in_warehouse === true
    ) {
      temp_id_transport_set = null;
      temp_is_set_changing_transport_set = false;
      temp_is_waiting = true;
    } else {
      if (newTransportSetId > 0) {
        temp_id_transport_set = newTransportSetId;
        if (
          is_waiting_TrailerPPP === true ||
          isLeave_trailer === true ||
          isCargo_change_in_warehouse === true
        ) {
          temp_is_set_changing_transport_set = false;
        } else {
          temp_is_set_changing_transport_set = true;
        }
      } else if (isSelectedSet) {
        temp_is_set_changing_transport_set = true;
        temp_id_transport_set = Number(selectedTransportSet.id);
      } else {
        temp_id_transport_set = data[0].transport_set.id_transport_set;
      }
      temp_is_waiting = false;
    }

    if (is_waiting_TrailerPPP === true) {
      temp_data = null;
      temp_adress_name = null;
      temp_address_lng = null;
      temp_address_lat = null;
    } else {
      temp_data = moment(dateTrailerPPP).format("YYYY-MM-DD HH:mm:ss");
      temp_adress_name = adressTrailerPPP.address_display_name;
      temp_address_lng = adressTrailerPPP.longitude;
      temp_address_lat = adressTrailerPPP.latitude;
    }

    if (is_waiting_TrailerPPP === true) {
      temp_routing = [];
    } else {
      temp_routing = dataPoints;
    }

    let data_to_send = {
      id_order: data[0].id, // ID zlecenia
      hand_over_type: temp_hand_over_type, // 0 - przepinka, 1 - przesiadka, 2 - przeładunek
      is_waiting: temp_is_waiting, // Czy oczekujące - true/false
      leave_trailer:
        isLeave_trailer === false
          ? selectedOrderData[0].leave_trailer
          : isLeave_trailer, // Dla przepinki opcja pozostaw naczepę
      cargo_change_in_warehouse:
        isCargo_change_in_warehouse === false
          ? selectedOrderData[0].cargo_change_in_warehouse
          : isCargo_change_in_warehouse, // Dla przeładunku opcja przeładunek w magazynie
      is_set_changing_transport_set: temp_is_set_changing_transport_set, // Czy został wskazany nowy zestaw trasnportowy
      changing_transport_sets: [
        {
          id_transport_set: temp_id_transport_set, // ID nowego zestawu transportowego (uprzednio musi być utworzny)
          date: temp_data, // Planowana data przekazania
          address_name: temp_adress_name, // Adres przekazania
          address_lng: temp_address_lng, // Współrzędne adresu przekazania
          address_lat: temp_address_lat, // Współrzędne adresu przekazania
          street: adressTrailerPPP?.street,
          city: adressTrailerPPP?.city,
          zipcode: adressTrailerPPP?.zipcode,
          country: adressTrailerPPP?.country,
          district: adressTrailerPPP?.district,
          street_no: adressTrailerPPP?.street_no,
          apartment_no: null,
          country_code: adressTrailerPPP?.country_code,
        },
      ],
      points: temp_routing,
    };

    props.loading(true);

    const response = await fetch(`${DBurl}/setHandOverTheOrder/`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "update",
        data: data_to_send,
      }),
    });
    const json = await response.json();
    if (json.success) {
      setOpenDialog(true);
      setDialogContent(`Zlecenie zostało zaktualizowane`);
      setDialogColor("success");

      setNewTransportSetId(json.data.new_id);
      fetchGetsTransportSet();

      setOpenModal(false);
      resetPPPModal();
      resetPPP();

      getActiveHandOverOrders();
      getWaitingHandOverOrders();
      props.loading(false);
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  const fetchLonLat = async (lon, lat) => {
    console.log("lon, lat", lon, lat);
    const response = await fetch(`${DBurl}/reverse?lon=${lon}&lat=${lat}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      const result = {
        address_display_name: json.data?.display_name,
        country: json.data.address?.country,
        country_code: json.data.address?.country_code,
        zipcode: json.data.address?.postcode,
        city: json.data.address?.city,
        city_district: json.data.address?.city_district,
        district: json.data.address?.state,
        neighbourhood: json.data.address?.neighbourhood,
        street: json.data.address?.road,
        street_no: json.data.address?.house_number,
        quarter: json.data.address?.quarter,
        suburb: json.data.address?.suburb,
        longitude: json.data?.lon,
        latitude: json.data?.lat,
      };
      setAdressTrailerPPP(result);
    }
  };

  function EnhancedTableHeadAll(props) {
    const { styles, onSelectAllClick, numSelected, rowCount } = props;
    const headCells = [
      {
        id: "order_no",
        numeric: true,
        disablePadding: false,
        label: "nr Zlecenia",
        width: "135px",
      },
      {
        id: "ts",
        numeric: true,
        disablePadding: false,
        label: "Data",
        width: "250px",
      },
      {
        id: "contractor.shortname",
        numeric: true,
        disablePadding: false,
        label: "Klient",
      },
      {
        id: "loading",
        numeric: true,
        disablePadding: false,
        label: "Miejsce załadunku",
        width: "13%",
      },
      {
        id: "unloading",
        numeric: true,
        disablePadding: false,
        label: "Miejsce rozładunku",
        width: "13%",
      },
      { id: "goods", numeric: true, disablePadding: false, label: "Towar" },

      {
        id: "order_price",
        numeric: true,
        disablePadding: false,
        label: "Przychód",
        width: "7%",
      },
      {
        id: "cost",
        numeric: true,
        disablePadding: false,
        label: "Koszt",
        width: "7%",
      },
      {
        id: "status",
        numeric: true,
        disablePadding: false,
        label: "Status",
        width: "11%",
        noSort: true,
      },
    ];

    return (
      <>
        <TableHead className={styles.tabHead}>
          <TableRow>
            {(activeTrailerPPP || activeDriverPPP || activeTranshipmentPPP) && (
              <TableCell className={styles.checkbox}>
                {/* <Checkbox
									indeterminate={numSelected > 0 && numSelected < rowCount}
									checked={rowCount > 0 && numSelected === rowCount}
									onChange={onSelectAllClick}
									inputProps={{ 'aria-label': 'select all id' }}

								/> */}
              </TableCell>
            )}

            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.alignRight ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                className={styles.headCellSmall}
                style={{
                  padding: "10px 20px",
                  fontSize: "11px",
                  width: `${headCell.width || "auto"}`,
                }}
              >
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </>
    );
  }

  function EnhancedTableHeadActive(props) {
    const { styles, onSelectAllClick, numSelected, rowCount } = props;
    const headCells = [
      {
        id: "hand_over_type",
        disablePadding: false,
        label: "Typ zmiany",
        width: "130px",
      },
      {
        id: "order_no",
        disablePadding: false,
        label: "Nr. zlecenia",
        width: "180px",
      },
      {
        id: "transport_set",
        disablePadding: false,
        label: "Pojazd, kierowca, naczepa",
        width: "300px",
      },
      {
        id: "changing_transport_sets",
        disablePadding: false,
        label: "Pojazd, kierowca, naczepa",
        width: "300px",
      },
      {
        id: "changing_transport_data",
        disablePadding: false,
        label: "Planowana data zmiany",
        width: "200px",
      },
      {
        id: "address_name",
        disablePadding: false,
        label: "Adres",
        width: "auto",
      },
      {
        id: "options",
        disablePadding: false,
        alignRight: true,
        label: "Ustawienia",
        width: "100px",
      },
    ];

    return (
      <>
        <TableHead className={styles.tabHead}>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.alignRight ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                className={styles.headCellSmall}
                style={{
                  padding: "10px 20px",
                  fontSize: "11px",
                  width: `${headCell.width || "auto"}`,
                }}
              >
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </>
    );
  }

  function EnhancedTableHeadWaiting(props) {
    const { styles } = props;
    const headCells = [
      {
        id: "hand_over_type",
        disablePadding: false,
        label: "Typ zmiany",
        width: "130px",
      },
      {
        id: "order_no",
        disablePadding: false,
        label: "Nr. zlecenia",
        width: "180px",
      },
      {
        id: "transport_set",
        disablePadding: false,
        label: "Pojazd, kierowca, naczepa",
        width: "300px",
      },
      { id: "address_name", disablePadding: false, label: "Adres" },
      {
        id: "changing_transport_data",
        disablePadding: false,
        label: "Data",
        width: "200px",
      },
      {
        id: "options",
        disablePadding: false,
        alignRight: true,
        label: "Ustawienia",
        width: "100px",
      },
    ];
    return (
      <>
        <TableHead className={styles.tabHead}>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.alignRight ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                className={styles.headCellSmall}
                style={{
                  padding: "10px 20px",
                  fontSize: "11px",
                  width: `${headCell.width || "auto"}`,
                }}
              >
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </>
    );
  }

  EnhancedTableHeadAll.propTypes = {
    styles: PropTypes.object.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  EnhancedTableHeadActive.propTypes = {
    styles: PropTypes.object.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  EnhancedTableHeadWaiting.propTypes = {
    styles: PropTypes.object.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDialog(false);
  };

  const search = (tbl) => {
    if (tbl?.length > 0) {
      return tbl.filter((row) => {
        return (
          row?.is_waiting
            .toString()
            .toLowerCase()
            .indexOf(searchDriver.toLowerCase()) > -1
        );
      });
    }
  };

  const handleClick = (event, name) => {
    let newSelected = [];

    if (selected.length === 0) {
      newSelected[0] = name;
    } else if (selected.length === 1) {
      if (selected[0] === name) {
        newSelected.length = 0;
      } else {
        newSelected[0] = name;
      }
    }

    setSelected(newSelected);

    if (newSelected) {
      setSelectedOrderData(
        ordersForHandOver.filter((o) => {
          return o.id === name;
        })
      );
    } else {
      setSelectedOrderData([]);
    }
  };

  const paginate = (pageNumber) => setPage(pageNumber - 1);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  //reset ui
  const resetPPP = () => {
    setActiveTrailerPPP(false);
    setActiveDriverPPP(false);
    setActiveTranshipmentPPP(false);
    setSelected([]);
    setSelectedOrderData([]);
    setActivePPP("");
    setAdressTrailerPPP();
  };

  //reset modala
  const resetPPPModal = () => {
    setDateTrailerPPP();
    setDateDriverPPP();
    setDateTranshipmentPPP();
    setAdressDriverPPP();
    setAdressTranshipmentPPP();

    setNameTransportSet();
    setSelectedDriver();
    setSelectedCar();
    setSelectedTrailer();
    setSelectedTransportSet();
    setIsTransportSetSeted(false);
    setIsValid(false);
    setIsNew(true);
    setIsEdit(false);
    setIsLeave_trailer(false);
    setIs_waiting_TrailerPPP(false);
    setIsCargo_change_in_warehouse(false);
    setNewTransportSetId();
    setListModal(false);
    setMapChangingAdressTrailerPPP();
    setAdressTrailerPPP();
  };

  //reset po zaznaczeniu switcha
  const resetPPPIs = () => {
    setIsEdit(false);
    setIsNew(true);
    setIsSelectedSet(false);

    setSelectedDriver();
    setSelectedCar();
    setSelectedTrailer();
    setDateTrailerPPP();
    setSelectedTransportSet();
    setNameTransportSet();
  };

  const changeTrailerPPP = () => {
    setActiveTrailerPPP(true);
    setActiveDriverPPP(false);
    setActiveTranshipmentPPP(false);
    setActivePPP("Przepinka");

    setSelected([]);
    setSelectedOrderData([]);
    getActiveHandOverOrders();
    getOrdersForHandOver();
  };

  const changeDriverPPP = () => {
    setActiveTrailerPPP(false);
    setActiveDriverPPP(true);
    setActiveTranshipmentPPP(false);
    setActivePPP("Przesiadka");

    setSelected([]);
    setSelectedOrderData([]);
    getActiveHandOverOrders();
    getOrdersForHandOver();
  };

  const transhipmentPPP = () => {
    setActiveTrailerPPP(false);
    setActiveDriverPPP(false);
    setActiveTranshipmentPPP(true);
    setActivePPP("Przeładunek");

    setSelected([]);
    setSelectedOrderData([]);
    getActiveHandOverOrders();
    getOrdersForHandOver();
  };

  const dateString = (type, row) => {
    let tl = row.locations.find((e) => e.type === type);
    let streetAndApart = tl?.apartment_no
      ? `${tl?.street_no}/${tl?.apartment_no}`
      : tl?.street_no;
    return `${!!tl?.street ? tl?.street : ""} ${
      !!streetAndApart ? streetAndApart : ""
    }, ${!!tl?.zipcode ? tl?.zipcode : ""} ${!!tl?.city ? tl?.city : ""}, ${
      !!tl?.district ? tl?.district : ""
    }`;
  };

  const current_transport_set = (data) => {
    const temp_data = data;
    return (
      <div className={`${stylesMod.inp} ${stylesMod.transportSetBox}`}>
        <DialogContentText id="alert-dialog-slide-description">
          Aktualny zestaw transportowy
        </DialogContentText>
        <div className={stylesMod.transportSetContent}>
          <div className={stylesMod.transportSetBoxItem}>
            <div>Pojazd</div>
            <div>
              <b className={stylesMod.lightBold}>
                {temp_data[0]?.transport_set.car_name}
              </b>
            </div>
          </div>
          <div className={stylesMod.transportSetBoxItem}>
            <div>Kierowca</div>
            <div>
              <b className={stylesMod.lightBold}>
                {temp_data[0]?.transport_set.driver1_name}
              </b>
            </div>
          </div>
          <div className={stylesMod.transportSetBoxItem}>
            <div>Naczepa</div>
            <div>
              <b className={stylesMod.lightBold}>
                {temp_data[0]?.transport_set.trailer_name}
              </b>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const copyTransportSet = () => {
    const temp_tab = transportSets_options.filter((o) => {
      return (
        Number(o.id) === selectedOrderData[0].transport_set.id_transport_set
      );
    });

    // console.log('temp_tab',temp_tab)

    if (temp_tab.length > 0) {
      setSelectedTransportSet(temp_tab[0]);
      setIsCopy(true);
      setIsEdit(true);
    } else {
      setOpenDialog(true);
      setDialogContent(`Wybrany zestaw transportowy nie istnieje`);
      setDialogColor("error");
    }
  };

  const addNewTransportSet = () => {
    trailerData_options !== undefined &&
      trailerData_options.filter(
        (o) => Number(o.id) === selectedOrderData[0]?.transport_set.id_trailer
      );

    let temp_trailer = selectedTrailer;
    if (activeTrailerPPP) {
      temp_trailer =
        trailerData_options !== undefined &&
        trailerData_options.filter(
          (o) => Number(o.id) === selectedOrderData[0]?.transport_set.id_trailer
        );
    }

    if (temp_trailer.length <= 0) {
      temp_trailer = null;
    }

    const data = [
      {
        label: nameTransportSet,
        id_car: selectedCar && Number(selectedCar[0].id),
        id_trailer: (temp_trailer && Number(temp_trailer[0].id)) || null,
        id_driver1: selectedDriver && Number(selectedDriver[0].id),
        id_driver2: null,
        is_double_cast_of_drivers: false,
        axle_netto: null,
        is_global: false,
      },
    ];

    fetchSetTransportSet(data);
  };

  const handleAdress = (e) => {
    setAdressTrailerPPP(e, props.propKey, false, false, true);
  };

  const valid_TransportSet = (type) => {
    let temp_selectedDriver = selectedDriver;
    let temp_selectedCar = selectedCar;
    let temp_selectedTrailer =
      activeTranshipmentPPP === true
        ? selectedTrailer
        : trailerData_options !== undefined &&
          trailerData_options.filter(
            (o) =>
              Number(o.id) === selectedOrderData[0]?.transport_set.id_trailer
          );
    let temp_nameTransportSet = nameTransportSet;

    if (
      temp_selectedDriver !== undefined &&
      temp_selectedCar !== undefined &&
      temp_selectedTrailer !== undefined &&
      temp_nameTransportSet
    ) {
      setIsValid(true);
    }
  };

  const popupContent = () => {
    let temp_naczepa;
    let temp_pojazd;

    if (activeTrailerPPP === true || activeDriverPPP === true) {
      temp_naczepa =
        trailerData_options !== undefined &&
        trailerData_options.filter((o) => {
          return (
            Number(o.id) === selectedOrderData[0]?.transport_set.id_trailer
          );
        });
    } else {
      temp_naczepa = selectedTrailer;
    }

    if (activeDriverPPP === true) {
      temp_pojazd =
        vehiclesData_options !== undefined &&
        vehiclesData_options.filter(
          (o) => Number(o.id) === selectedOrderData[0]?.transport_set.id_car
        );
    } else {
      temp_pojazd = selectedCar;
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {current_transport_set(selectedOrderData)}
        </Grid>

        <Grid item xs={12}>
          <div className={`${stylesMod.inp} ${stylesMod.flexRowLeft}`}>
            <FormControlLabel
              className={stylesMod.smallerFont}
              control={
                <GreenSwitch
                  checked={is_waiting_TrailerPPP}
                  onChange={(e) => {
                    setIs_waiting_TrailerPPP(e.target.checked);
                    setIsLeave_trailer(false);
                    setIsCargo_change_in_warehouse(false);
                    resetPPPIs();
                  }}
                />
              }
              label={
                activeTrailerPPP
                  ? "Oczekujący na przepięcie"
                  : activeDriverPPP
                  ? "Oczekujący na przesiadkę"
                  : "Oczekujący na przeładunek"
              }
            />
            {activeTrailerPPP === true && (
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={isLeave_trailer}
                    onChange={(e) => {
                      setIsLeave_trailer(e.target.checked);
                      setIs_waiting_TrailerPPP(false);
                      setIsCargo_change_in_warehouse(false);
                      resetPPPIs();
                    }}
                  />
                }
                label={"Czy pozostawić naczepe?"}
              />
            )}
            {activeTranshipmentPPP === true && (
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={isCargo_change_in_warehouse}
                    onChange={(e) => {
                      setIsCargo_change_in_warehouse(e.target.checked);
                      setIs_waiting_TrailerPPP(false);
                      setIsLeave_trailer(false);
                      resetPPPIs();
                    }}
                  />
                }
                label={"Przeładunek w magazynie?"}
              />
            )}
          </div>
        </Grid>

        {isLeave_trailer === false &&
          is_waiting_TrailerPPP === false &&
          isCargo_change_in_warehouse === false && (
            <Grid item xs={12} style={{ paddingBottom: "25px" }}>
              <div className={stylesMod.inp}>
                <DialogContentText id="alert-dialog-slide-description">
                  Dodaj nowy zestaw transportowy
                </DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    <div className={`${stylesMod.inp}`}>
                      <Select
                        value={selectedTransportSet}
                        className={`${stylesMod.select}`}
                        options={transportSets_options || []}
                        onChange={(e) => {
                          setSelectedTransportSet(e);
                          setIsEdit(false);
                        }}
                        name="transportSet"
                        placeholder="Wybierz zestaw..."
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            border: "1px solid #142f42",
                            boxShadow: "none",
                            "&:hover": {
                              border: "1px solid #142f42",
                            },
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused && "#142f42",
                            color: state.isFocused && "#fff",
                          }),
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      className={stylesMod.btnCopyTansportSet}
                      onClick={() => copyTransportSet()}
                    >
                      <Copy className={`${styles.primary}`} size={18} /> Skopiuj
                      obecny
                    </Button>
                  </Grid>

                  <Grid item xs={3}>
                    <div className={`${stylesMod.inp}`}>
                      <label>Nazwa</label>
                      <Input
                        placeholder={"Nazwa zestawu"}
                        value={nameTransportSet}
                        name="transport-set-name"
                        handleChange={(e) => {
                          setIsEdit(true);
                          setNameTransportSet(e.target.value);
                        }}
                        error={error && nameTransportSet.length < 1}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className={`${stylesMod.inp}`}>
                      <label>Wybierz kierowcę</label>
                      <Select
                        value={selectedDriver}
                        className={`${stylesMod.select}`}
                        options={driversData_options || []}
                        onChange={(e) => {
                          setIsEdit(true);
                          let temp = [];
                          temp.push(e);
                          setSelectedDriver(temp);
                        }}
                        name="driver"
                        placeholder="Wybierz kierowcę..."
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            border: "1px solid #142f42",
                            boxShadow: "none",
                            "&:hover": {
                              border: "1px solid #142f42",
                            },
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused && "#142f42",
                            color: state.isFocused && "#fff",
                          }),
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className={`${stylesMod.inp}`}>
                      <label>Wybierz pojazd</label>
                      <Select
                        isDisabled={activeDriverPPP}
                        value={temp_pojazd}
                        className={`${stylesMod.select} ${
                          activeDriverPPP && stylesMod.opacity05
                        }`}
                        options={vehiclesData_options || []}
                        onChange={(e) => {
                          setIsEdit(true);
                          let temp = [];
                          temp.push(e);
                          setSelectedCar(temp);
                        }}
                        name="vehicle"
                        placeholder="Wybierz pojazd..."
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            border: "1px solid #142f42",
                            boxShadow: "none",
                            "&:hover": {
                              border: "1px solid #142f42",
                            },
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused && "#142f42",
                            color: state.isFocused && "#fff",
                          }),
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className={`${stylesMod.inp}`}>
                      <label>Wybierz naczepę</label>
                      <Select
                        isDisabled={activeTrailerPPP || activeDriverPPP}
                        value={temp_naczepa}
                        className={`${stylesMod.select} ${
                          (activeTrailerPPP || activeDriverPPP) &&
                          stylesMod.opacity05
                        }`}
                        options={trailerData_options}
                        onChange={(e) => {
                          setIsEdit(true);
                          let temp = [];
                          temp.push(e);
                          setSelectedTrailer(temp);
                        }}
                        name="trailer"
                        placeholder="Wybierz naczepę..."
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            border: "1px solid #142f42",
                            boxShadow: "none",
                            "&:hover": {
                              border: "1px solid #142f42",
                            },
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused && "#142f42",
                            color: state.isFocused && "#fff",
                          }),
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} style={{ display: "flex", gap: "12px" }}>
                    <Button
                      style={
                        isNew === false && isEdit === false
                          ? { display: "none" }
                          : { display: "flex" }
                      }
                      disabled={!isValid}
                      variant="contained"
                      size="small"
                      className={`${BTNstyles.btnGreen} ${BTNstyles.err}`}
                      onClick={() => {
                        addNewTransportSet();
                      }}
                      color="primary"
                    >
                      {"Dodaj nowy zestaw"}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          )}
        {is_waiting_TrailerPPP === false && (
          <Grid item xs={12}>
            <div className={stylesMod.inp}>
              <Grid container spacing={2}>
                <Grid item xs={6} className={"adressSearch"}>
                  <DialogContentText id="alert-dialog-slide-description">
                    Adres
                  </DialogContentText>
                  <AddressSearch
                    token={props.user.csrf_token}
                    setAddress={(e) => {
                      setIsAdressWritten(true);
                      handleAdress(e);
                    }}
                    newValue={
                      mapChangingAdressTrailerPPP !== undefined &&
                      adressTrailerPPP
                    }
                    placeholder={"Wpisz adres"}
                    error={error}
                  />
                </Grid>
                <Grid item xs={6} className={"dateChanges"}>
                  <DialogContentText id="alert-dialog-slide-description">
                    Planowany czas
                  </DialogContentText>
                  <DatePicker
                    dateFormat="dd.MM.yyyy HH:mm"
                    selected={dateTrailerPPP}
                    onChange={(e) => {
                      let newD = moment(e).valueOf();
                      let newDS = moment(newD).format("yyyy-MM-DDTHH:mm");
                      newDS = new Date(newDS);
                      setDateTrailerPPP(newDS);
                    }}
                    showTimeSelect
                    timeIntervals={10}
                    locale={plLocale}
                    name="date"
                    customInput={<CustomInput />}
                    placeholderText={"Wybierz datę.."}
                  />
                </Grid>
                <>
                  <Grid item xs={12}>
                    <DisplayMapFC
                      mapSetup={setMapChangingAdressTrailerPPP}
                      latitude={
                        adressTrailerPPP !== undefined
                          ? adressTrailerPPP.latitude
                          : null
                      }
                      longitude={
                        adressTrailerPPP !== undefined
                          ? adressTrailerPPP.longitude
                          : null
                      }
                      data={
                        adressTrailerPPP !== undefined ? adressTrailerPPP : null
                      }
                      order={selectedOrderData}
                      fetchOrders={getOrdersForHandOver}
                      ordersList={ordersForHandOver}
                      user={props.user}
                      setIsAdressWritten={setIsAdressWritten}
                      isAdressWritten={isAdressWritten}
                      setDataPoints={setDataPoints}
                      dataPoints={setDataPoints}
                    />
                  </Grid>
                </>
              </Grid>
            </div>
          </Grid>
        )}
      </Grid>
    );
  };

  const toTimestamp = (strDate) => {
    const dt = Date.parse(strDate);
    return dt / 1000;
  };
  const rowBGColor = (row) => {
    let date = row.changing_transport_sets[0].date;
    let temp_leave_trailer = row.leave_trailer;
    let temp_cargo_change_in_warehouse = row.cargo_change_in_warehouse;
    let rowColor;

    if (
      temp_leave_trailer === true ||
      temp_cargo_change_in_warehouse === true
    ) {
      let actualDate = moment(new Date()).valueOf() / 1000;
      let actionDate = moment(date).valueOf() / 1000;
      let temp_date = actualDate - actionDate;

      if (temp_date <= 3600) {
        rowColor = { backgroundColor: "#fff5d9" };
      } else if (temp_date > 3600) {
        rowColor = { backgroundColor: "#ebb6b273" };
      } else {
        rowColor = { backgroundColor: "withe" };
      }
    } else {
      rowColor = { backgroundColor: "withe" };
    }
    return rowColor;
  };

  function round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  useEffect(() => {
    if (mapChangingAdressTrailerPPP?.lat !== undefined) {
      fetchLonLat(
        round(mapChangingAdressTrailerPPP.lng, 7),
        round(mapChangingAdressTrailerPPP.lat, 7)
      );
    }
    return () => {
      setAdressTrailerPPP();
    };
  }, [mapChangingAdressTrailerPPP]);

  useEffect(() => {
    getOrdersForHandOver();
    return () => setOrdersForHandOver([]);
  }, []);

  useEffect(() => {
    getWaitingHandOverOrders();
    return () => setWaitingHandOverOrdersTable([]);
  }, []);

  useEffect(() => {
    getActiveHandOverOrders();
    return () => setActiveHandOverOrdersTable([]);
  }, []);

  useEffect(() => {
    fetchDrivers();
    return () => setDriversData();
  }, []);

  useEffect(() => {
    fetchTrailers();
    return () => setTrailerData();
  }, []);

  useEffect(() => {
    fetchCars();
    return () => setVehiclesData();
  }, []);

  useEffect(() => {
    fetchGetsTransportSet();
    return () => setTransportSets();
  }, []);

  useEffect(() => {
    let temp_transportSet;
    if (transportSets !== undefined && selectedTransportSet !== undefined) {
      temp_transportSet = transportSets.filter((o) => {
        return o.id === Number(selectedTransportSet.id);
      });

      let temp_s_driver = driversData_options.filter((o) => {
        return Number(temp_transportSet[0].id_driver1) === Number(o.id);
      });
      setSelectedDriver(temp_s_driver);

      let temp_s_car = vehiclesData_options.filter((o) => {
        return Number(temp_transportSet[0].id_car) === Number(o.id);
      });
      setSelectedCar(temp_s_car);
      setNameTransportSet(temp_transportSet[0].label);

      let temp_s_trailer = trailerData_options.filter((o) => {
        return Number(temp_transportSet[0].id_trailer) === Number(o.id);
      });
      setSelectedTrailer(temp_s_trailer);

      if (activeTrailerPPP === true) {
        setSelectedTrailer(
          trailerData_options !== undefined &&
            trailerData_options.filter(
              (o) =>
                Number(o.id) === selectedOrderData[0]?.transport_set.id_trailer
            )
        );
      }
      if (activeDriverPPP === true) {
        setSelectedCar(
          vehiclesData_options !== undefined &&
            vehiclesData_options.filter(
              (o) => Number(o.id) === selectedOrderData[0]?.transport_set.id_car
            )
        );
        setSelectedTrailer(
          trailerData_options !== undefined &&
            trailerData_options.filter(
              (o) =>
                Number(o.id) === selectedOrderData[0]?.transport_set.id_trailer
            )
        );
      }

      setIsSelectedSet(true);
      setIsNew(false);
    }
  }, [selectedTransportSet]);

  useEffect(() => {
    valid_TransportSet();
    return () => setIsValid(false);
  }, [
    nameTransportSet,
    selectedCar,
    selectedDriver,
    selectedOrderData,
    selectedTrailer,
  ]);

  // dodanie dla zablokowanych pozycji w przepinka
  useEffect(() => {
    if (activeTrailerPPP === true) {
      setSelectedTrailer(
        trailerData_options !== undefined &&
          trailerData_options.filter(
            (o) =>
              Number(o.id) === selectedOrderData[0]?.transport_set.id_trailer
          )
      );
    }
  }, [activeTrailerPPP, selectedOrderData, trailerData_options]);

  // dodanie dla zablokowanych pozycji w przesiadka
  useEffect(() => {
    if (activeDriverPPP === true) {
      setSelectedTrailer(
        trailerData_options !== undefined &&
          trailerData_options.filter(
            (o) =>
              Number(o.id) === selectedOrderData[0]?.transport_set.id_trailer
          )
      );
      setSelectedCar(
        vehiclesData_options !== undefined &&
          vehiclesData_options.filter(
            (o) => Number(o.id) === selectedOrderData[0]?.transport_set.id_car
          )
      );
    }
  }, [
    activeDriverPPP,
    selectedOrderData,
    trailerData_options,
    vehiclesData_options,
  ]);

  return (
    <>
      <div className={styles.root}>
        <Paper className={styles.paper}>
          <Title
            title={props.state.tabName}
            btnBox
            btnChangeTrailerPPP
            changeTrailerPPP={() => {
              changeTrailerPPP();
            }}
            activeTrailerPPP={activeTrailerPPP}
            btnChangeDriverPPP
            changeDriverPPP={() => {
              changeDriverPPP();
            }}
            activeDriverPPP={activeDriverPPP}
            btnTranshipmentPPP
            transhipmentPPP={() => {
              transhipmentPPP();
            }}
            activeTranshipmentPPP={activeTranshipmentPPP}
            btnResetPPP
            resetPPP={() => {
              resetPPP();
            }}
            activeResetPPP={
              activeTrailerPPP || activeDriverPPP || activeTranshipmentPPP
                ? true
                : false
            }
            PPPSettings
            newSettingsPPP
            selected={selected}
            dialog={setOpenModal}
          />

          {(activeTrailerPPP === true ||
            activeDriverPPP === true ||
            activeTranshipmentPPP === true) &&
            listModal === false && (
              <>
                <SubTitle TableTitleActive title={activePPP} />

                <TableContainer className={styles.fullHeiht}>
                  <Table
                    stickyHeader
                    className={styles.table}
                    aria-labelledby="tableTitle"
                    size="small"
                    aria-label="enhanced table"
                  >
                    <EnhancedTableHeadAll
                      styles={styles}
                      rowCount={ordersForHandOver.length}
                    />

                    <TableBody className={styles.tabBody}>
                      {stableSort(
                        search(ordersForHandOver),
                        getComparator(order, orderBy)
                      )
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((row, index) => {
                          const isItemSelected = isSelected(row.id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              onClick={(event) => {
                                if (
                                  activeTrailerPPP ||
                                  activeDriverPPP ||
                                  activeTranshipmentPPP
                                ) {
                                  handleClick(event, row.id);
                                }
                              }}
                              role="checkbox"
                              aria-checked={
                                (activeTrailerPPP ||
                                  activeDriverPPP ||
                                  activeTranshipmentPPP) &&
                                isItemSelected
                              }
                              tabIndex={-1}
                              key={row.id}
                              selected={
                                (activeTrailerPPP ||
                                  activeDriverPPP ||
                                  activeTranshipmentPPP) &&
                                isItemSelected
                              }
                            >
                              {(activeTrailerPPP ||
                                activeDriverPPP ||
                                activeTranshipmentPPP) && (
                                <TableCell
                                  className={styles.checkbox}
                                  style={{ padding: "0 10px" }}
                                >
                                  <Checkbox
                                    checked={isItemSelected}
                                    color="primary"
                                    inputProps={{ "aria-labelledby": labelId }}
                                  />
                                </TableCell>
                              )}

                              <TableCell
                                component="th"
                                style={{ padding: "11.43px 20px" }}
                                id={labelId}
                                align="left"
                                scope="row"
                              >
                                {row.order_no}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "11.43px 20px" }}
                              >
                                {row.ts}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "11.43px 20px" }}
                              >
                                {row.contractor.shortname || ""}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "11.43px 20px" }}
                              >
                                {dateString("loading", row)}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "11.43px 20px" }}
                              >
                                {dateString("unloading", row)}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "11.43px 20px" }}
                              >
                                {
                                  row.locations.find(
                                    (e) => e.type === "loading"
                                  )?.goods[0]?.name
                                }
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "11.43px 20px" }}
                              >
                                {row.order_price} {row.order_currency}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "11.43px 20px" }}
                              >
                                {row.cost} {row.order_currency}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "11.43px 20px" }}
                              >
                                {setStatus(row.status)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>

                <div className={styles.tableBottom}>
                  <RowsPerPage
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    setPage={setPage}
                    tableLength={ordersForHandOver?.length}
                  />
                  <div>
                    <Pagination
                      postsPerPage={rowsPerPage}
                      totalPosts={search(ordersForHandOver)?.length}
                      paginate={paginate}
                      page={page}
                    />
                  </div>
                </div>
              </>
            )}

          {((activeTrailerPPP === false &&
            activeDriverPPP === false &&
            activeTranshipmentPPP === false) ||
            listModal === true) && (
            <>
              <SubTitle TableTitleActive title={activePPP} />
              <TableContainer className={styles.halfTable}>
                <Table
                  stickyHeader
                  className={styles.table}
                  aria-labelledby="tableTitle"
                  size="small"
                  aria-label="enhanced table"
                >
                  <EnhancedTableHeadActive
                    styles={styles}
                    rowCount={activeHandOverOrdersTable.length}
                  />

                  <TableBody className={styles.tabBody}>
                    {stableSort(
                      search(activeHandOverOrdersTable),
                      getComparator(order, orderBy)
                    )
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const date = row.changing_transport_sets[0].date;
                        let rowColor;

                        if (
                          row.leave_trailer === false ||
                          row.cargo_change_in_warehouse === false
                        ) {
                          let actualDate = moment(new Date()).valueOf() / 1000;
                          let actionDate = moment(date).valueOf() / 1000;
                          let temp_date = actualDate - actionDate;

                          if (temp_date <= 3600) {
                            rowColor = { backgroundColor: "#fff5d9" };
                          } else if (temp_date > 3600) {
                            rowColor = { backgroundColor: "#ebb6b273" };
                          } else {
                            rowColor = { backgroundColor: "withe" };
                          }
                        } else {
                          rowColor = { backgroundColor: "withe" };
                        }

                        return (
                          <TableRow
                            hover
                            onClick={(event) => {
                              if (
                                activeTrailerPPP ||
                                activeDriverPPP ||
                                activeTranshipmentPPP
                              ) {
                                handleClick(event, row.id);
                              }
                            }}
                            role="checkbox"
                            aria-checked={
                              (activeTrailerPPP ||
                                activeDriverPPP ||
                                activeTranshipmentPPP) &&
                              isItemSelected
                            }
                            tabIndex={-1}
                            key={index}
                            selected={
                              (activeTrailerPPP ||
                                activeDriverPPP ||
                                activeTranshipmentPPP) &&
                              isItemSelected
                            }
                            style={rowColor}
                          >
                            <TableCell
                              align="left"
                              style={{ padding: "0 20px", height: "43px" }}
                            >
                              {row.hand_over_type === "0"
                                ? "Przepinka"
                                : row.hand_over_type === "1"
                                ? "Przesiadka"
                                : row.hand_over_type === "2"
                                ? "Przeładunek"
                                : "-"}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ padding: "0 20px", height: "43px" }}
                            >
                              {row.order_no}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ padding: "0 20px", height: "43px" }}
                            >
                              {row.transport_set.car_name} |{" "}
                              {row.transport_set.driver1_name}{" "}
                              {row.transport_set.driver2_name !== "" &&
                                row.transport_set.driver2_name}{" "}
                              | {row.transport_set.trailer_name}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ padding: "0 20px", height: "43px" }}
                            >
                              {
                                row.changing_transport_sets[0].transport_set
                                  .car_name
                              }{" "}
                              |{" "}
                              {
                                row.changing_transport_sets[0].transport_set
                                  .driver1_name
                              }{" "}
                              {row.changing_transport_sets[0].transport_set
                                .driver2_name !== "" &&
                                row.changing_transport_sets[0].transport_set
                                  .driver2_name}{" "}
                              |{" "}
                              {
                                row.changing_transport_sets[0].transport_set
                                  .trailer_name
                              }
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ padding: "0 20px", height: "43px" }}
                            >
                              {row.changing_transport_sets[0].date}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ padding: "0 20px", height: "43px" }}
                            >
                              {row.changing_transport_sets[0].address_name}
                            </TableCell>

                            <TableCell
                              align="left"
                              style={{ padding: "0 20px", height: "43px" }}
                            >
                              <div className={styles.buttonBox}>
                                <IcoBtn
                                  icon="X"
                                  tooltip={"Anuluj"}
                                  value={row}
                                  onClick={(e) => {
                                    fetchSetCancelHandOverTheOrder(row.id);
                                  }}
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>

              <div className={styles.tableBottom}>
                <RowsPerPage
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  setPage={setPage}
                  tableLength={activeHandOverOrdersTable?.length}
                />
                <div>
                  <Pagination
                    postsPerPage={rowsPerPage}
                    totalPosts={search(activeHandOverOrdersTable)?.length}
                    paginate={paginate}
                    page={page}
                  />
                </div>
              </div>
            </>
          )}

          {((activeTrailerPPP === false &&
            activeDriverPPP === false &&
            activeTranshipmentPPP === false) ||
            listModal === true) && (
            <>
              <SubTitle TableTitleWaiting />

              <TableContainer className={styles.halfTable}>
                <Table
                  stickyHeader
                  className={styles.table}
                  aria-labelledby="tableTitle"
                  size="small"
                  aria-label="enhanced table"
                >
                  <EnhancedTableHeadWaiting
                    styles={styles}
                    rowCount={waitingHandOverOrdersTable.length}
                  />

                  <TableBody className={styles.tabBody}>
                    {stableSort(
                      search(waitingHandOverOrdersTable),
                      getComparator(order, orderBy)
                    )
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((row, index) => {
                        const date =
                          row.changing_transport_sets.length > 0
                            ? row.changing_transport_sets[0].date
                            : null;
                        let rowColor;

                        if (
                          row.leave_trailer === true ||
                          row.cargo_change_in_warehouse === true
                        ) {
                          let actualDate = moment(new Date()).valueOf() / 1000;
                          let actionDate =
                            date !== null
                              ? moment(date).valueOf() / 1000
                              : null;
                          let temp_date =
                            actionDate !== null
                              ? actualDate - actionDate
                              : null;

                          if (temp_date && temp_date <= 3600) {
                            rowColor = { backgroundColor: "#fff5d9" };
                          } else if (temp_date && temp_date > 3600) {
                            rowColor = { backgroundColor: "#ebb6b273" };
                          } else {
                            rowColor = { backgroundColor: "withe" };
                          }
                        } else {
                          rowColor = { backgroundColor: "withe" };
                        }

                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={index}
                            style={rowColor}
                          >
                            <TableCell
                              align="left"
                              style={{ padding: "0 20px", height: "43px" }}
                            >
                              {row.hand_over_type === "0"
                                ? "Przepinka"
                                : row.hand_over_type === "1"
                                ? "Przesiadka"
                                : row.hand_over_type === "2"
                                ? "Przeładunek"
                                : "-"}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ padding: "0 20px", height: "43px" }}
                            >
                              {row.order_no}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ padding: "0 20px", height: "43px" }}
                            >
                              {row.is_waiting === true &&
                              row.leave_trailer === false &&
                              row.cargo_change_in_warehouse === false
                                ? `${row.transport_set.car_name} |
																	${
                                    row.transport_set.driver1_name !== ""
                                      ? row.transport_set.driver1_name
                                      : row.transport_set.driver2_name !== ""
                                      ? row.transport_set.driver2_name
                                      : "-"
                                  } |
																	${row.transport_set.car_name}`
                                : row.hand_over_type === "0"
                                ? "Pozostawienie naczepy"
                                : row.hand_over_type === "2"
                                ? "Przeładunek w magazynie"
                                : "-"}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ padding: "0 20px", height: "43px" }}
                            >
                              {row.changing_transport_sets?.length > 0 &&
                              row.changing_transport_sets[0].address_name !==
                                null
                                ? row.changing_transport_sets[0].address_name
                                : "-"}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ padding: "0 20px", height: "43px" }}
                            >
                              {row.changing_transport_sets?.length > 0 &&
                              row.changing_transport_sets[0].date !== null
                                ? row.changing_transport_sets[0].date
                                : "-"}
                            </TableCell>

                            <TableCell
                              align="left"
                              style={{ padding: "0 20px", height: "43px" }}
                            >
                              <div className={styles.buttonBox}>
                                <IcoBtn
                                  icon="Tool"
                                  tooltip={"Wykonaj zamiane"}
                                  value={row}
                                  onClick={(e) => {
                                    setSelectedOrderData([row]);
                                    setListModal(true);

                                    if (row.hand_over_type === "0") {
                                      setActiveTrailerPPP(true);
                                      setActivePPP("Przepinka");
                                    } else if (row.hand_over_type === "1") {
                                      setActiveDriverPPP(true);
                                      setActivePPP("Przesiadka");
                                    } else if (row.hand_over_type === "2") {
                                      setActiveTranshipmentPPP(true);
                                      setActivePPP("Przeładunek");
                                    }

                                    setOpenModal(true);
                                  }}
                                />
                                <IcoBtn
                                  icon="X"
                                  tooltip={"Anuluj"}
                                  value={row}
                                  onClick={(e) => {
                                    fetchSetCancelHandOverTheOrder(row.id);
                                  }}
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>

              <div className={styles.tableBottom}>
                <RowsPerPage
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  setPage={setPage}
                  tableLength={waitingHandOverOrdersTable?.length}
                />
                <div>
                  <Pagination
                    postsPerPage={rowsPerPage}
                    totalPosts={search(waitingHandOverOrdersTable)?.length}
                    paginate={paginate}
                    page={page}
                  />
                </div>
              </div>
            </>
          )}

          <Dialog
            open={openModal}
            TransitionComponent={Transition}
            onClose={() => {
              setOpenModal(false);
              resetPPPModal();
              resetPPP();
            }}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            fullWidth={true}
          >
            <DialogTitle
              id="alert-dialog-slide-title"
              className={stylesMod.dialogHead}
            >
              {activePPP}
              <IconButton
                aria-label="close"
                className={stylesMod.closeButton}
                onClick={() => {
                  setOpenModal(false);
                  resetPPPModal();
                  resetPPP();
                }}
              >
                <X />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {activeTrailerPPP && popupContent("przepinka")}
              {activeDriverPPP && popupContent("przesiadka")}
              {activeTranshipmentPPP && popupContent("przeładunek")}
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                size="large"
                color="primary"
                className={BTNstyles.btn}
                onClick={(e) => {
                  setOpenModal(false);
                  resetPPPModal();
                  resetPPP();
                }}
              >
                Anuluj
              </Button>
              <Button
                variant="contained"
                size="large"
                disabled={isEdit || props.isLoading === true}
                className={`${BTNstyles.btn} ${BTNstyles.err}`}
                onClick={() => {
                  if (is_waiting_TrailerPPP === true) {
                    fetchSetHandOverTheOrder(selectedOrderData);
                  } else if (
                    isLeave_trailer === true ||
                    isCargo_change_in_warehouse === true
                  ) {
                    if (
                      dateTrailerPPP !== undefined &&
                      adressTrailerPPP !== undefined
                    ) {
                      fetchSetHandOverTheOrder(selectedOrderData);
                    } else {
                      setOpenDialog(true);
                      setDialogContent(`Wypełnij wszystkie pola`);
                      setDialogColor("error");
                    }
                  } else if (
                    isLeave_trailer === false ||
                    isCargo_change_in_warehouse === false ||
                    is_waiting_TrailerPPP === false
                  ) {
                    if (
                      selectedDriver !== undefined &&
                      selectedCar !== undefined &&
                      selectedTrailer !== undefined &&
                      dateTrailerPPP !== undefined &&
                      adressTrailerPPP !== undefined
                    ) {
                      fetchSetHandOverTheOrder(selectedOrderData);
                    } else {
                      setOpenDialog(true);
                      setDialogContent(`Wypełnij wszystkie pola`);
                      setDialogColor("error");
                    }
                  } else {
                    setOpenDialog(true);
                    setDialogContent(`Wypełnij wszystkie pola`);
                    setDialogColor("error");
                  }
                }}
                color="primary"
              >
                {props.isLoading === true ? (
                  <CircularProgress
                    color="inherit"
                    className={BTNstyles.btnCircularProgres}
                  />
                ) : (
                  ""
                )}{" "}
                Dalej
              </Button>
            </DialogActions>
          </Dialog>

          <Snackbar
            open={openDialog}
            autoHideDuration={4000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity={dialogColor}>
              {dialogContent}
            </Alert>
          </Snackbar>
        </Paper>
      </div>
    </>
  );
}
