import React, { useState } from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "../../components/input";
import { CopyBtn, DeleteBtn, EditBtn } from "../../components/buttons";
import Title from "../../components/title";
import styles from "../table.module.css";
import RowsPerPage from "../../components/RowsPerPage";
import Pagination from "../../components/pagination";
import NewWarehouse from "./NewWarehouse";
import DeleteWarehouse from "./DeleteWarehouse";
import { useQuery } from "react-query";
import { getWarehouses } from "api/endpoints";
import TmsLoading from "components/TmsLoading";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  if (
    orderBy === "country" ||
    orderBy === "city" ||
    orderBy === "street" ||
    orderBy === "street_no"
  ) {
    return order === "desc"
      ? (a, b) => descendingComparator(a.address, b.address, orderBy)
      : (a, b) => -descendingComparator(a.address, b.address, orderBy);
  } else {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "name", numeric: false, disablePadding: true, label: "Nazwa magazynu" },
  {
    id: "contractor_name",
    numeric: true,
    disablePadding: false,
    label: "Klient",
  },
  { id: "country", numeric: true, disablePadding: false, label: "Kraju" },
  { id: "city", numeric: true, disablePadding: false, label: "Miasto" },
  { id: "street", numeric: true, disablePadding: false, label: "Ulica" },
  {
    id: "street_no",
    numeric: true,
    disablePadding: false,
    label: "Nr",
    class: styles.shortNumber,
  },
  {
    id: "contacts",
    numeric: true,
    disablePadding: false,
    label: "Osoba do kontaktu",
    class: styles.longHead,
  },
  { id: "role", numeric: true, disablePadding: false, label: "Stanowisko" },
  { id: "phone", numeric: true, disablePadding: false, label: "Nr Telefonu" },
  { id: "email", numeric: true, disablePadding: false, label: "Email" },
];

function EnhancedTableHead(props) {
  const {
    styles,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={styles.tabHead}>
      <TableRow>
        <TableCell className={styles.checkbox}>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all id" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={headCell.class}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={styles.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell className={styles.buttonBoxParent} />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  styles: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function useInput({ type }) {
  const [value, setValue] = useState("");
  const input = (
    <TableCell>
      <Input
        value={value}
        handleChange={(e) => {
          setValue(e.target.value);
        }}
        type={type}
        search={true}
      />
    </TableCell>
  );
  return [value, input];
}

export default function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [table, setTable] = useState([]);
  const [searchName, setSearchName] = useInput({ type: "text" });
  const [searchContractor, setSearchContractor] = useInput({ type: "" });
  const [searchAddressCountry, setSearchAddressCountry] = useInput({
    type: "text",
  });
  const [searchAddressCity, setSearchAddressCity] = useInput({ type: "text" });
  const [searchAddressStreet, setSearchAddressStreet] = useInput({
    type: "text",
  });
  const [searchAddressStreet_no, setSearchAddressStreet_no] = useInput({
    type: "text",
  });
  const [searchContact, setSearchContact] = useInput({ type: "text" });
  const [searchContactRole, setSearchContactRole] = useInput({ type: "text" });
  const [searchContactPhone, setSearchContactPhone] = useInput({
    type: "text",
  });
  const [searchContactMail, setSearchContactMail] = useInput({ type: "text" });

  const [openModalNewWarehouse, setOpenModalNewWarehouse] = useState(false);
  const [modalNewWarehouseData, setModalNewWarehouseData] = useState({});
  const [openSnack, setOpenSnack] = useState(false);
  const [snackContent, setSnackContent] = useState("");
  const [modalNewWarehouseEdit, setModalNewWarehouseEdit] = useState(true);

  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [modalDeleteData, setModalDeleteData] = useState(false);

  const { isLoading: isWarehouseLoading, refetch: refetchWarehouses } =
    useQuery("getWarehouses", () => getWarehouses(), {
      onSuccess: (data) => {
        setTable(data);
      },
      onError: (error) => {
        props.setDialog({
          isOpen: true,
          type: "error",
          content: `Nie udało się pobrać magazynów / ${error.message}`,
        });
      },
    });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const search = (tbl) => {
    return tbl.filter((row) => {
      if (row && row.address && (row.address.city || row.address.city === "")) {
        return (
          String(row.address.city)
            .toLowerCase()
            .indexOf(searchAddressCity.toLowerCase()) > -1 &&
          String(row.address.country)
            .toLowerCase()
            .indexOf(searchAddressCountry.toLowerCase()) > -1 &&
          String(row.address.street)
            .toLowerCase()
            .indexOf(searchAddressStreet.toLowerCase()) > -1 &&
          String(row.name).toLowerCase().indexOf(searchName.toLowerCase()) >
            -1 &&
          String(row.address.street_no)
            .toLowerCase()
            .indexOf(searchAddressStreet_no.toLowerCase()) > -1 &&
          String(row.contacts[0]?.fullname)
            .toLowerCase()
            .indexOf(searchContact.toLowerCase()) > -1 &&
          String(row.contacts[0]?.role)
            .toLowerCase()
            .indexOf(searchContactRole.toLowerCase()) > -1 &&
          String(row.contacts[0]?.phone)
            .toLowerCase()
            .indexOf(searchContactPhone.toLowerCase()) > -1 &&
          String(row.contacts[0]?.email)
            .toLowerCase()
            .indexOf(searchContactMail.toLowerCase()) > -1 &&
          String(row.contractor?.shortname)
            .toLowerCase()
            .indexOf(searchContractor.toLowerCase()) > -1
        );
      } else {
        return false;
      }
    });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = table.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const paginate = (pageNumber) => setPage(pageNumber - 1);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, table.length - page * rowsPerPage);

  return (
    <div className={styles.root}>
      <Paper className={styles.paper}>
        <Title
          title={props.state.tabName}
          btnBox
          btnDelete
          btnAdd
          addFunc={() => {
            setOpenModalNewWarehouse(true);
            setModalNewWarehouseData({});
            setModalNewWarehouseEdit(false);
          }}
          deleteFunc={() => {
            const delTable = [];
            selected
              .sort((a, b) => {
                return b - a;
              })
              .forEach((val) => {
                table.forEach((el, i) => {
                  if (el.id === val) {
                    delTable.push(el);
                  }
                });
              });
            setOpenModalDelete(true);
            setModalDeleteData({
              state: delTable,
            });
          }}
        />
        <TableContainer className={styles.stickyTable}>
          <Table
            className={styles.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              styles={styles}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={table.length}
            />

            <TableBody className={styles.tabBody}>
              <TableRow>
                <TableCell></TableCell>
                {setSearchName}
                {setSearchContractor}
                {setSearchAddressCountry}
                {setSearchAddressCity}
                {setSearchAddressStreet}
                {setSearchAddressStreet_no}
                {setSearchContact}
                {setSearchContactRole}
                {setSearchContactPhone}
                {setSearchContactMail}
                <TableCell></TableCell>
              </TableRow>
              {stableSort(search(table), getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell className={styles.checkbox}>
                        <Checkbox
                          checked={isItemSelected}
                          color="primary"
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="right">
                        {row.contractor?.shortname}
                      </TableCell>
                      <TableCell align="right">{row.address.country}</TableCell>
                      <TableCell align="right">{row.address.city}</TableCell>
                      <TableCell align="right">{row.address.street}</TableCell>
                      <TableCell align="right" className={styles.shortNumber}>
                        {row.address.street_no}
                      </TableCell>
                      <TableCell align="right">
                        {row.contacts?.[0]?.fullname}
                      </TableCell>
                      <TableCell align="right">
                        {row.contacts?.[0]?.role}
                      </TableCell>
                      <TableCell align="right">
                        {row.contacts?.[0]?.phone}
                      </TableCell>
                      <TableCell align="right">
                        {row.contacts?.[0]?.email}
                      </TableCell>
                      <TableCell
                        className={styles.buttonBoxParent}
                        align="right"
                      >
                        <div className={styles.buttonBox}>
                          <CopyBtn
                            icon="Copy"
                            tooltip="Kopiuj"
                            value={row}
                            handleChange={(e) => {
                              setOpenModalNewWarehouse(true);
                              setModalNewWarehouseData(row);
                              setModalNewWarehouseEdit(false);
                            }}
                          />
                          <EditBtn
                            icon="Edit"
                            tooltip="Edytuj magazyn"
                            value={row}
                            handleChange={(e) => {
                              setOpenModalNewWarehouse(true);
                              setModalNewWarehouseData(row);
                              setModalNewWarehouseEdit(true);
                            }}
                          />
                          <DeleteBtn
                            icon="Delete"
                            tooltip="Usuń magazyn"
                            value={row}
                            handleChange={(e) => {
                              setOpenModalDelete(true);
                              setModalDeleteData({
                                state: [{ ...row }],
                                id: index,
                              });
                            }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={styles.tableBottom}>
          <RowsPerPage
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setPage={setPage}
            tableLength={table.length}
          />
          <div>
            <Pagination
              postsPerPage={rowsPerPage}
              totalPosts={search(table).length}
              paginate={paginate}
              page={page}
            />
          </div>
        </div>
        {openModalNewWarehouse ? (
          <NewWarehouse
            open={openModalNewWarehouse}
            setOpen={setOpenModalNewWarehouse}
            data={modalNewWarehouseData}
            table={table}
            edit={modalNewWarehouseEdit}
            setSnackContent={setSnackContent}
            setOpenSnack={setOpenSnack}
            user={props.user}
            fetchWarehouse={refetchWarehouses}
            setDialog={props.setDialog}
          />
        ) : null}

        {openModalDelete ? (
          <DeleteWarehouse
            open={openModalDelete}
            setOpen={setOpenModalDelete}
            data={modalDeleteData}
            setSnackContent={setSnackContent}
            setOpenSnack={setOpenSnack}
            user={props.user}
            fetchWarehouse={refetchWarehouses}
            setDialog={props.setDialog}
          />
        ) : null}
      </Paper>
      {isWarehouseLoading && <TmsLoading status="Ładowanie magazynów" />}
    </div>
  );
}
