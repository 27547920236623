import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "../../components/input";
import { DBurl } from "../../appConfig";
import Title from "../../components/title";
import BTNstyles from "../../styles/button.module.css";
import styles from "../table.module.css";
import stylesMod from "../../styles/newOrEdit.module.css";
import Button from "@material-ui/core/Button";
import { X } from "react-feather";
import RowsPerPage from "../../components/RowsPerPage";
import Pagination from "../../components/pagination";
import IcoBtn from "../../components/buttons/icoBtn";
import AddClient from "./AddClient";
import IconButton from "@material-ui/core/IconButton";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import DisabledDeleteBtn from "../../components/buttons/deleteDisabled";

import { CopyBtn, DeleteBtn, EditBtn } from "../../components/buttons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  if (orderBy === "city") {
    return order === "desc"
      ? (a, b) => descendingComparator(a.address, b.address, orderBy)
      : (a, b) => -descendingComparator(a.address, b.address, orderBy);
  } else {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "shortname",
    numeric: false,
    disablePadding: true,
    label: "Nazwa skrócona",
  },
  { id: "name", numeric: true, disablePadding: false, label: "Nazwa" },
  { id: "nip", numeric: true, disablePadding: false, label: "NIP" },
  { id: "ideuvat", numeric: true, disablePadding: false, label: "Id. EU VAT" },
  {
    id: "timocomid",
    numeric: true,
    disablePadding: false,
    label: "Timocom ID",
  },
  {
    id: "transeuid",
    numeric: true,
    disablePadding: false,
    label: "Trans.eu ID",
  },
  { id: "rating", numeric: true, disablePadding: false, label: "Ocena" },
  { id: "city", numeric: true, disablePadding: false, label: "Miasto" },
];

function EnhancedTableHead(props) {
  const {
    styles,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    setSearchShort,
    setSearchName,
    setSearchNIP,
    setSearchIDEUVAT,
    setSearchTimocomID,
    setSearchTranseuID,
    setSearchRating,
    setSearchAddressCity,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead className={styles.tabHead}>
        <TableRow>
          <TableCell className={styles.checkbox}>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all id" }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "default"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={styles.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell className={styles.buttonBoxParent} />
        </TableRow>
      </TableHead>
      <TableHead className={styles.tabHead}>
        <TableRow className={styles.secondStickyRow}>
          <TableCell></TableCell>
          {setSearchShort}
          {setSearchName}
          {setSearchNIP}
          {setSearchIDEUVAT}
          {setSearchTimocomID}
          {setSearchTranseuID}
          {setSearchRating}
          {setSearchAddressCity}
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  styles: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function useInput({ type }) {
  const [value, setValue] = useState("");
  const input = (
    <TableCell>
      <Input
        value={value}
        handleChange={(e) => {
          setValue(e.target.value);
        }}
        type={type}
        search={true}
      />
    </TableCell>
  );
  return [value, input];
}

export default function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("shortname");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(16);
  const [table, setTable] = useState([]);
  const [searchShort, setSearchShort] = useInput({ type: "text" });
  const [searchName, setSearchName] = useInput({ type: "text" });
  const [searchNIP, setSearchNIP] = useInput({ type: "text" });
  const [searchIDEUVAT, setSearchIDEUVAT] = useInput({ type: "text" });
  const [searchTimocomID, setSearchTimocomID] = useInput({ type: "text" });
  const [searchTranseuID, setSearchTranseuID] = useInput({ type: "text" });
  const [searchRating, setSearchRating] = useInput({ type: "text" });
  const [searchAddressCity, setSearchAddressCity] = useInput({ type: "text" });
  const [openSnack, setOpenSnack] = useState(false);
  const [snackContent, setSnackContent] = useState("");

  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [modalContent, setModalContent] = React.useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");

  const [showCarriers, setShowCarriers] = useState(false);
  const [carriersTable, setCarriersTable] = useState([]);

  const getContractors = async function () {
    props.loading(true);
    const response = await fetch(`${DBurl}/getContractors/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setTable(json.data);
      props.loading(false);
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  const delContractors = async function (arrayOfIds) {
    props.loading(true);
    const response = await fetch(
      `${DBurl}/${!showCarriers ? `setContractors` : `setCarriers`}/`,
      {
        method: "post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
        body: JSON.stringify({
          mode: "delete",
          data: {
            ids: arrayOfIds,
          },
        }),
      }
    );
    const json = await response.json();
    if (json.success) {
      setOpenDialog(true);
      setDialogContent(`Dane zostały usunięte `);
      setDialogColor("success");
      !showCarriers ? getContractors() : getCarriers();
      props.loading(false);
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  const getCarriers = async function () {
    props.loading(true);
    const response = await fetch(`${DBurl}/getCarriers/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setCarriersTable(json.data);
      props.loading(false);
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  useEffect(() => {
    getContractors();
    getCarriers();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDialog(false);
  };

  const search = (tbl) => {
    return tbl.filter((row) => {
      if (row) {
        return showCarriers ? String(row.shortname).toLowerCase().includes(searchShort.toLowerCase()) && 
        String(row.name).toLowerCase().includes(searchName.toLowerCase()) && 
        String(row.nip).toLowerCase().includes(searchNIP.toLowerCase()) && 
        String(row.ideuvat).toLowerCase().includes(searchIDEUVAT.toLowerCase()) && 
        String(row.rating).toLowerCase().includes(searchRating.toLowerCase()) 
        && String(row.city).toLowerCase().includes(searchAddressCity.toLowerCase()) :
        String(row.shortname).toLowerCase().includes(searchShort.toLowerCase()) && 
        String(row.name).toLowerCase().includes(searchName.toLowerCase()) && 
        String(row.nip).toLowerCase().includes(searchNIP.toLowerCase()) && 
        String(row.ideuvat).toLowerCase().includes(searchIDEUVAT.toLowerCase()) && 
        String(row.rating).toLowerCase().includes(searchRating.toLowerCase())
        && String(row.address.city).toLowerCase().includes(searchAddressCity.toLowerCase())
      }
    });
  };

  const openAddClient = async (row, type, edit) => {
    if (edit) {
      props.loading(true);
      const responseDepartment = await fetch(
        `${DBurl}/getDepartments?id_contractor=${row.id}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": props.user.csrf_token,
          },
        }
      );
      const jsonDep = await responseDepartment.json();
      if (jsonDep.success) {
        const tempData = [...jsonDep.data].map((e) => {
          return { ...e, ...e.address };
        });
        if (tempData.length > 0) {
          row.Department = tempData;
        } else {
          row.Department = [{}];
        }
        props.loading(false);

        // return json
      } else {
        setOpenDialog(true);
        setDialogContent(
          `Błąd podczas pobierania oddziału // ${jsonDep.responseDescription} // ${jsonDep.response}  `
        );
        setDialogColor("error");
        props.loading(false);
      }

      const response = await fetch(
        `${DBurl}/getWarehouses?id_contractor=${row.id}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": props.user.csrf_token,
          },
        }
      );
      const json = await response.json();
      if (json.success) {
        if (json.data.length > 0) {
          row.Warehouses = json.data.map((e) => {
            return {
              ...e.address,
              id: e.id,
              id_contractor: e.id_contractor,
              contacts: e.contacts,
              name: e.name,
            };
          });
        } else {
          row.Warehouses = [
            {
              contacts: [
                {
                  email: "",
                  firstname: "",
                  phone: "",
                  primary_contact: true,
                  role: "",
                  surname: "",
                },
              ],
            },
          ];
        }

        // row.checkWarehouse = row.Warehouses.length;
        props.loading(false);
        // return json
      } else {
        setOpenDialog(true);
        setDialogContent(
          `Błąd podczas pobierania magazynów // ${json.responseDescription} // ${json.response}  `
        );
        setDialogColor("error");
        props.loading(false);
      }
    }
    let tempData = {
      tabName: type || "Dodaj klienta",
      edit: edit || false,
      tabID: null,
      permission_id: 3,
      component: AddClient,
      active: true,
      ...row,
    };
    props.addTab("AddClient", tempData);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = table.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const paginate = (pageNumber) => setPage(pageNumber - 1);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const deleteFunc = (e) => {
    setOpenModalDelete(true);
    const selectedArray = table.filter((e) => {
      if (selected.includes(e.id)) {
        return e;
      } else {
        return null;
      }
    });
    setModalContent(selectedArray);
  };
  const addFunc = () => {
    props.addTab("AddClient");
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, (table && table.length) - page * rowsPerPage);

  return (
    <div className={styles.root}>
      <Paper className={styles.paper}>
        <Title
          title={props.state.tabName}
          btnBox
          btnAdd
          btnDelete={selected.length > 0 ? "ok" : "disabled"}
          deleteFunc={deleteFunc}
          addFunc={addFunc}
          showVar
          showVarFunc={() => {
            setShowCarriers((prevShowCarriers) => !prevShowCarriers);
          }}
          showVarText={!showCarriers ? "Pokaż przewoźników" : "Pokaż klientów"}
        />
        <TableContainer className={styles.stickyTable}>
          <Table
            className={styles.table}
            stickyHeader
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              styles={styles}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={table.length}
              setSearchShort={setSearchShort}
              setSearchName={setSearchName}
              setSearchNIP={setSearchNIP}
              setSearchIDEUVAT={setSearchIDEUVAT}
              setSearchTimocomID={setSearchTimocomID}
              setSearchTranseuID={setSearchTranseuID}
              setSearchRating={setSearchRating}
              setSearchAddressCity={setSearchAddressCity}
            />
            <TableBody className={styles.tabBody}>
              {stableSort(
                search(!showCarriers ? table : carriersTable),
                getComparator(order, orderBy)
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const carrierRow = { ...row, isCarrier: true };
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell className={styles.checkbox}>
                        <Checkbox
                          checked={isItemSelected}
                          color="primary"
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.shortname}
                      </TableCell>
                      <TableCell align="right">{row.name}</TableCell>
                      <TableCell align="right">{row.nip}</TableCell>
                      <TableCell align="right">{row.ideuvat}</TableCell>
                      <TableCell align="right">{row.timocomid}</TableCell>
                      <TableCell align="right">{row.transeuid}</TableCell>
                      <TableCell align="right">{row.rating}</TableCell>
                      <TableCell align="right">
                        {!showCarriers ? row.address.city : row.city}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={styles.buttonBoxParent}
                      >
                        <div className={styles.buttonBox}>
                          {/* <IcoBtn icon="LogIn" tooltip="Eksportuj" value={row} onClick={(e) => { alert(`TODO: Funkcja eksportu:  ${JSON.stringify(row, null, 4)}`) }} /> */}
                          {!showCarriers && (
                            <IcoBtn
                              icon="Copy"
                              tooltip="Kopiuj"
                              value={row}
                              onClick={(e) => {
                                const copyTemp = `${row.name} \nNIP: ${
                                  row.nip
                                } \nTimocom ID: ${
                                  row.timocomid
                                } \nTrans.eu ID: ${row.transeuid} \nMiasto: ${
                                  !showCarriers ? row.address?.city : row.city
                                }`;
                                navigator.clipboard.writeText(copyTemp);
                                e.preventDefault();
                                e.stopPropagation();
                                setSnackContent("Skopiowano do schowka");
                                setOpenSnack(true);
                              }}
                            />
                          )}
                          <IcoBtn
                            icon="Edit"
                            tooltip="Edytuj"
                            value={row}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              openAddClient(
                                !showCarriers ? row : carrierRow,
                                "Edytuj konto klienta",
                                true
                              );
                            }}
                          />
                          {row.linked_to_orders_num === 0 || showCarriers ? (
                            <DeleteBtn
                              tooltip="Usuń"
                              className="btn-delete"
                              handleChange={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setOpenModalDelete(true);
                                setModalContent([row]);
                              }}
                            />
                          ) : (
                            <DisabledDeleteBtn
                              tooltip={`Kontrahent powiązany z ${row.linked_to_orders_num} zleceniami`}
                              handleChange={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            />
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={(e, r) => {
            if (r === "clickaway") {
              return;
            }
            setOpenSnack(false);
          }}
        >
          <Alert
            onClose={(e, r) => {
              if (r === "clickaway") {
                return;
              }
              setOpenSnack(false);
            }}
            severity="success"
          >
            {snackContent}
          </Alert>
        </Snackbar>

        <Dialog
          open={openModalDelete}
          TransitionComponent={Transition}
          onClose={() => {
            setOpenModalDelete(false);
            setModalContent([]);
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            className={stylesMod.dialogHead}
          >
            Czy napewno chcesz usunąć {modalContent ? modalContent.name : null}{" "}
            <IconButton
              aria-label="close"
              className={stylesMod.closeButton}
              onClick={() => {
                setOpenModalDelete(false);
                setModalContent([]);
              }}
            >
              <X />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Czy napewno chcesz usunąć organizacje
              {modalContent.map((e) => {
                return (
                  <>
                    <br /> {e.name}
                  </>
                );
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={BTNstyles.btn}
              onClick={(e) => {
                setOpenModalDelete(false);
                setModalContent([]);
              }}
            >
              Anuluj
            </Button>
            <Button
              variant="contained"
              size="large"
              className={`${BTNstyles.btn} ${BTNstyles.err}`}
              onClick={(e) => {
                // setTable(table.filter((e) => { if (!selected.includes(e.id)) { return e } }))
                let delArray = modalContent.map((e) => e.id);
                delContractors(delArray);
                setModalContent([]);
                setOpenModalDelete(false);
              }}
              color="primary"
            >
              Usuń <X className={BTNstyles.downBtnIco} />
            </Button>
          </DialogActions>
        </Dialog>
        <div className={styles.tableBottom}>
          <RowsPerPage
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setPage={setPage}
            tableLength={table.length}
          />
          <div>
            <Pagination
              postsPerPage={rowsPerPage}
              totalPosts={search(table).length}
              paginate={paginate}
              page={page}
            />
          </div>
        </div>
      </Paper>
      <Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={dialogColor}>
          {dialogContent}
        </Alert>
      </Snackbar>
    </div>
  );
}
