import React from 'react'

export default function IcoFuel(props) {
	const icon = (() => {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" style={{margin: '20px'}} width="40" height="40" viewBox="0 0 229.449 229.449" fill="#142f42">
				<path d="M227.253,74.302l-35.177-35.177c-2.93-2.929-7.678-2.929-10.607,0c-2.929,2.929-2.929,7.678,0,10.606l21.999,21.999v84.452 c0,7.334-5.966,13.301-13.3,13.301c-7.334,0-13.301-5.967-13.301-13.301v-20.657c0-13.008-8.825-23.988-20.801-27.284v-45.13 V49.479c0-15.451-12.57-28.021-28.021-28.021H76.816c-15.451,0-28.021,12.57-28.021,28.021v13.633v50.811v79.07h-9.802 c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h126.874c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5h-9.801v-68.441 c3.499,2.398,5.801,6.421,5.801,10.975v20.657c0,15.605,12.695,28.301,28.301,28.301c15.605,0,28.3-12.696,28.3-28.301V86.25 c1.087,0.57,2.284,0.855,3.481,0.855c1.919,0,3.839-0.732,5.304-2.197C230.182,81.98,230.182,77.231,227.253,74.302z M63.795,70.611h77.272v35.811H63.795V70.611z M76.816,36.457h51.229c7.18,0,13.021,5.841,13.021,13.021v6.133H63.795v-6.133 C63.795,42.298,69.637,36.457,76.816,36.457z M63.795,192.992v-71.57h77.272v71.57H63.795z"/> <path d="M36.513,135.971c0-0.186-0.006-0.37-0.02-0.552l-0.001-42.432c0-3.034-1.827-5.769-4.63-6.929 c-2.802-1.161-6.028-0.519-8.174,1.626L2.196,109.175C0.79,110.582,0,112.489,0,114.479c0,1.989,0.79,3.897,2.197,5.303 l21.493,21.492c1.435,1.435,3.353,2.197,5.305,2.197c0.031,0,0.063,0,0.094-0.001C33.196,143.429,36.513,140.088,36.513,135.971z" />				
			</svg>
		)
	})()
	return icon
}
