import React from 'react'
import { IconButton } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip';
import TachoIcon from '../../components/tachoIcons'
import styles from 'containers/table.module.css';

const FleetStateSelector = ({tachoValue, title, icon, handleClick}) => {


    return (
        <div>
            <Tooltip disableHoverListener={false} title={title}>
                <IconButton className={styles.smallIcons} onClick={handleClick}>
                    <TachoIcon icon={icon} />
                </IconButton>
            </Tooltip>
            {tachoValue}
        </div>
    )
}


export default FleetStateSelector;