import AddClient from "containers/client/AddClient";
import ClientList from "containers/client/ClientList";
import Goods from "containers/client/Goods";
import Warehouse from "containers/client/Warehouse";
import InvoiceAdd from "containers/account/InvoiceAdd";
import InvoiceAddNew from "containers/account/AddInvoice/InvoiceAddNew";
import ExchangeRates from "containers/account/ExchangeRates";
import InvoiceAdd_S from "containers/account/InvoiceAdd_S";
import InvoiceList from "containers/account/InvoiceList";
import InvoiceList_S from "containers/account/InvoiceList_S";
import Contractors from "containers/account/Contractors";
import Payments from "containers/account/Payments";
import WorkTime from "containers/fleet/WorkTime";
import Fleet from "containers/fleet/FleetState";
import Fleet_S from "containers/fleet/FleetState_S";
import Map from "containers/fleet/Map";
import Exploitation from "containers/fleet/Exploitation";
import FuelCards from "containers/fleet/FuelCards";
import LoadingUnloading from "containers/reports/LoadingUnloading";
import LoadingUnloading_S from "containers/reports/LoadingUnloading_S";
import ForeignEpisodes from "containers/reports/ForeignEpisodes";
import RaportEKD from "containers/reports/RaportEKD";
import CiclesReports from "containers/reports/CiclesReports";
import Settings from "containers/configuration";
import Communication from "containers/communication";
import Reports from "containers/reports";
import NewOrder from "containers/order/newOrder/newOrder";
import NewOrderSped from "containers/order/newOrderSped";
import Tags from "containers/order/Tags";
import OrderList from "containers/order/OrderList";
import OrderList_S from "containers/order/OrderList_S";
import TimeTable from "containers/order/TimeTable";
import TimeTable_S from "containers/order/TimeTable_S";
import Drivers from "containers/settings/Drivers";
import Vehicles from "containers/settings/Vehicles";
import Trailers from "containers/settings/Trailer";
import TransportSet from "containers/fleet/TransportSet";
import Departments from "./containers/account/Departments";
import AccountSettings from "./containers/account/AccountSettings";
import CostsList from "./containers/account/CostsList";
import PurchaseList from "./containers/account/PurchaseList";
import OparatorsList from "./containers/configuration/ClientList";
import OparatorsListSA from "./containers/configuration/ClientListSA";
import CompanyList from "./containers/configuration/CompanyList";
import UsersList from "./containers/configuration/UsersList";
import OrderActions from "containers/order/OrderActions";
import Vindication from "containers/account/Vindication";
import VehiclesDocs from "containers/documents/vehicles";
import DriversDocs from "containers/documents/drivers";
import TrailersDocs from "containers/documents/trailers";
import CompanyDocs from "containers/documents/company";
import DeadlinesDocs from "containers/documents/deadlines";
import ChangesHistory from "containers/documents/changesHistory";
import FinishedOrders from "containers/reports/FinishedOrders";
import BorderCrossings from "components/BorderCrossings/BorderCrossings";
import NoOrderStatusHistory from "containers/fleet/NoOrderStatusHistory";
import DriversActivity from "containers/reports/DriversActivity";
import FavouriteRoutes from "components/FavouriteRoutes";
import InvoiceTags from "containers/account/InvoiceTags";
import CostsImport from "components/CostsImport/CostsImport";
import DriversSettlement from "components/DriversSettlement";
import FleetCosts from "containers/FleetCosts/FleetCosts";
import FleetCostsReport from "containers/FleetCosts/FleetCostsReports";
import FleetCostsReportsConfirmed from "containers/FleetCosts/FleetCostsReportsConfirmed";
import DriverNotes from "components/DriverNotes";

export const NewOrderState = {
  permission_id: 7,
  tabName: "Nowe zlecenie",
  tabID: null,
  componentName: "NewOrder",
  component: NewOrder,
};

export const VindicationState = {
  permission_id: 15,
  tabName: "Windykacja",
  tabID: null,
  componentName: "Vindication",
  component: Vindication,
};

export const TimeTableState = {
  permission_id: 8,
  tabName: "Harmonogram",
  tabID: null,
  componentName: "TimeTable",
  component: TimeTable,
};

export const TimeTableState_S = {
  permission_id: 8,
  tabName: "Harmonogram S",
  tabID: null,
  componentName: "TimeTable_S",
  component: TimeTable_S,
};

export const DriversState = {
  permission_id: 23,
  tabName: "Kierowcy",
  tabID: null,
  componentName: "Drivers",
  component: Drivers,
};

export const VehiclesState = {
  permission_id: 24,
  tabName: "Pojazdy",
  tabID: null,
  componentName: "Vehicles",
  component: Vehicles,
};

export const TrailersState = {
  permission_id: 25,
  tabName: "Naczepy",
  tabID: null,
  componentName: "Trailers",
  component: Trailers,
};

export const TransportSetState = {
  permission_id: 12,
  tabName: "Zestawy transportowe",
  tabID: null,
  componentName: "TransportSet",
  component: TransportSet,
};

export const TagsState = {
  permission_id: 6,
  tabName: "Tagi",
  tabID: null,
  componentName: "Tags",
  component: Tags,
};

export const OrderListState = {
  permission_id: 6,
  tabName: "Lista zleceń",
  tabID: null,
  componentName: "OrderList",
  component: OrderList,
};

export const OrderActionsState = {
  permission_id: 6,
  tabName: "Przepinka/Przesiadka",
  tabID: null,
  componentName: "OrderActions",
  component: OrderActions,
};

export const OrderListState_S = {
  permission_id: 6,
  tabName: "Lista zleceń S",
  tabID: null,
  componentName: "OrderList_S",
  component: OrderList_S,
};

export const AddClientState = {
  permission_id: 3,
  tabName: "Dodaj klienta",
  tabID: null,
  componentName: "AddClient",
  component: AddClient,
};

export const ClientListState = {
  permission_id: 2,
  tabName: "Lista klientów",
  tabID: null,
  componentName: "ClientList",
  component: ClientList,
};

export const GoodsState = {
  permission_id: 5,
  tabName: "Towary",
  tabID: null,
  componentName: "Goods",
  component: Goods,
};

export const WarehouseState = {
  permission_id: 4,
  tabName: "Magazyny",
  tabID: null,
  componentName: "Warehouse",
  component: Warehouse,
};

export const InvoiceAddState = {
  permission_id: 14,
  tabName: "Wystaw fakturę",
  tabID: null,
  componentName: "InvoiceAdd",
  component: InvoiceAdd,
};

export const InvoiceAddState_S = {
  permission_id: 14,
  tabName: "Wystaw fakturę S",
  tabID: null,
  componentName: "InvoiceAdd_S",
  component: InvoiceAdd_S,
};

export const InvoiceListState = {
  permission_id: 15,
  tabName: "Lista faktur",
  tabID: null,
  componentName: "InvoiceList",
  component: InvoiceList,
};

export const InvoiceListState_S = {
  permission_id: 15,
  tabName: "Lista faktur S",
  tabID: null,
  componentName: "InvoiceList_S",
  component: InvoiceList_S,
};

export const ContractorsState = {
  permission_id: 16,
  tabName: "Kontrahenci",
  tabID: null,
  componentName: "Contractors",
  component: Contractors,
};

export const PaymentsState = {
  // TODO payment permission
  permission_id: null,
  tabName: "Płatności",
  tabID: null,
  componentName: "Payments",
  component: Payments,
};

export const WorkTimeState = {
  permission_id: 13,
  tabName: "Czas pracy",
  tabID: null,
  componentName: "WorkTime",
  component: WorkTime,
};

export const FleetState = {
  permission_id: 11,
  tabName: "Stan floty",
  tabID: null,
  componentName: "Fleet",
  component: Fleet,
};

export const FleetState_S = {
  permission_id: 11,
  tabName: "Stan floty S",
  tabID: null,
  componentName: "Fleet_S",
  component: Fleet_S,
};

export const MapState = {
  permission_id: 9,
  tabName: "Mapa",
  tabID: null,
  componentName: "Map",
  component: Map,
};

export const ExploitationState = {
  permission_id: 10,
  tabName: "Eksploatacja",
  tabID: null,
  componentName: "Exploitation",
  component: Exploitation,
};

export const LoadingUnloadingState = {
  permission_id: 10,
  tabName: "Załadunek/Rozładunek",
  tabID: null,
  componentName: "LoadingUnloading",
  component: LoadingUnloading,
};

export const LoadingUnloading_S_State = {
  permission_id: 10,
  tabName: "Załadunek/Rozładunek S",
  tabID: null,
  componentName: "LoadingUnloading_S",
  component: LoadingUnloading_S,
};

export const ForeignEpisodesState = {
  permission_id: 10,
  tabName: "Odcinki zagraniczne",
  tabID: null,
  componentName: "ForeignEpisodes",
  component: ForeignEpisodes,
};

export const RaportEKDState = {
  permission_id: 10,
  tabName: "Raport EKD",
  tabID: null,
  componentName: "RaportEKD",
  component: RaportEKD,
};

export const CiclesReportsState = {
  permission_id: 10,
  tabName: "Raporty cykliczne",
  tabID: null,
  componentName: "CiclesReports",
  component: CiclesReports,
};

export const SettingsState = {
  permission_id: null,
  tabName: "Konfiguracja",
  tabID: null,
  componentName: "Settings",
  component: Settings,
};

export const CommunicationState = {
  permission_id: 22,
  tabName: "Komunikacja",
  tabID: null,
  componentName: "Communication",
  component: Communication,
};

export const ReportsState = {
  permission_id: 21,
  tabName: "Raporty",
  tabID: null,
  componentName: "Reports",
  component: Reports,
};

export const DepartmentsState = {
  permission_id: 19,
  tabName: "Oddziały",
  tabID: null,
  componentName: "Departments",
  component: Departments,
};

export const CostsListState = {
  permission_id: 17,
  tabName: "Koszty",
  tabID: null,
  componentName: "CostsList",
  component: CostsList,
};

export const PurchaseListState = {
  permission_id: 18,
  tabName: "Zakupy",
  tabID: null,
  componentName: "PurchaseList",
  component: PurchaseList,
};

export const AccountSettingsState = {
  permission_id: 20,
  tabName: "Ustawienia księgowe",
  tabID: null,
  componentName: "AccountSettings",
  component: AccountSettings,
};

export const OparatorsListState = {
  permission_id: 28,
  tabName: "Operatorzy",
  tabID: null,
  componentName: "clientList",
  component: OparatorsList,
};
export const OparatorsListStateSA = {
  permission_id: 28,
  tabName: "Operatorzy SA",
  tabID: null,
  componentName: "clientListSA",
  component: OparatorsListSA,
};

export const CompanyListState = {
  permission_id: 27,
  tabName: "Firmy",
  tabID: null,
  componentName: "CompanyList",
  component: CompanyList,
};

export const UsersListState = {
  permission_id: 26,
  tabName: "Użytkownicy",
  tabID: null,
  componentName: "UsersList",
  component: UsersList,
};

export const ExchangeRatesState = {
  permission_id: 10,
  tabName: "Kursy walut",
  tabID: null,
  componentName: "ExchangeRates",
  component: ExchangeRates,
};

export const InvoiceAddNewState = {
  permission_id: 14,
  tabName: "Dodaj fakture",
  tabID: null,
  componentName: "InvoiceAddNew",
  component: InvoiceAddNew,
};

export const VehiclesDocsState = {
  permission_id: 15,
  tabName: "Pojazdy",
  tabID: null,
  componentName: "VehiclesDocs",
  component: VehiclesDocs,
};

export const DriversDocsState = {
  permission_id: 15,
  tabName: "Kierowcy",
  tabID: null,
  componentName: "DriversDocs",
  component: DriversDocs,
};

export const TrailersDocsState = {
  permission_id: 15,
  tabName: "Naczepy",
  tabID: null,
  componentName: "TrailersDocs",
  component: TrailersDocs,
};

export const CompanyDocsState = {
  permission_id: 15,
  tabName: "Firma",
  tabID: null,
  componentName: "CompanyDocs",
  component: CompanyDocs,
};

export const DeadlinesDocsState = {
  permission_id: 15,
  tabName: "Terminy",
  tabID: null,
  componentName: "DeadlinesDocs",
  component: DeadlinesDocs,
};

export const ChangesHistoryState = {
  permission_id: 15,
  tabName: "Historia zmian",
  tabID: null,
  componentName: "ChangesHistory",
  component: ChangesHistory,
};

export const FinishedOrdersState = {
  permission_id: 15,
  tabName: "Raport realizacji zleceń",
  tabID: null,
  componentName: "FinishedOrders",
  component: FinishedOrders,
};

export const FuelCardsState = {
  permission_id: 15,
  tabName: "Karty Paliwowe",
  tabID: null,
  componentName: "FuelCards",
  component: FuelCards,
};

export const NewOrderSpedState = {
  permission_id: 15,
  tabName: "Nowe Zlecenie Spedycyjne",
  tabID: null,
  componentName: "NewOrderSped",
  component: NewOrderSped,
};

export const BorderCrossingsState = {
  permission_id: 15,
  tabName: "Przejścia graniczne",
  tabID: null,
  componentName: "BorderCrossings",
  component: BorderCrossings,
};

export const NoOrderStatusHistoryState = {
  permission_id: 15,
  tabName: "Historia statusów bez zleceń",
  tabID: null,
  componentName: "NoOrderStatusHistory",
  component: NoOrderStatusHistory,
};

export const DriversActivityState = {
  permission_id: 15,
  tabName: "Raport aktywności kierowców",
  tabID: null,
  componentName: "DriversActivity",
  component: DriversActivity,
};

export const FavouritesRoutesState = {
  permission_id: 15,
  tabName: "Trasy",
  tabID: null,
  componentName: "FavouriteRoutes",
  component: FavouriteRoutes,
};

export const InvoiceTagsState = {
  permission_id: 15,
  tabName: "Tagi do faktur",
  tabID: null,
  componentName: "InvoiceTags",
  component: InvoiceTags,
};

export const CostsImportState = {
  permission_id: 15,
  tabName: "Import kosztów",
  tabID: null,
  componentName: "CostsImport",
  component: CostsImport,
};

export const DriversSettlementState = {
  permission_id: 15,
  tabName: "Rozliczenia kierowców",
  tabID: null,
  componentName: "DriversSettlement",
  component: DriversSettlement,
};

export const FleetCostsState = {
  permission_id: 15,
  tabName: "Koszty utrzymania floty",
  tabID: null,
  componentName: "FleetCosts",
  component: FleetCosts,
};

export const FleetCostsReportState = {
  permission_id: 15,
  tabName: "Raport kosztów utrzymania floty",
  tabID: null,
  componentName: "FleetCostsReport",
  component: FleetCostsReport,
};

export const FleetCostsReportsConfirmedState = {
  permission_id: 15,
  tabName: "Raporty kosztów utrzymania floty - zatwierdzone",
  tabID: null,
  componentName: "FleetCostsReportsConfirmed",
  component: FleetCostsReportsConfirmed,
};

export const DriverNotesState = {
  permission_id: 15,
  tabName: "Notatki dla kierowców",
  tabID: null,
  componentName: "DriverNotes",
  component: DriverNotes,
};
