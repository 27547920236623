import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import styles from "./trace.module.css";
import {
  Download,
  Upload,
  MapPin,
  Move,
  XCircle,
  ArrowUpCircle,
  Copy,
  ArrowDownCircle,
  Plus,
  PlusCircle,
  Edit,
} from "react-feather";
import { Button, Grid } from "@material-ui/core/";
import KeyboardTabOutlinedIcon from "@material-ui/icons/KeyboardTabOutlined";
import FlagOutlinedIcon from "@material-ui/icons/FlagOutlined";
import LocalParkingOutlinedIcon from "@material-ui/icons/LocalParkingOutlined";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import DirectionsBoatOutlinedIcon from "@material-ui/icons/DirectionsBoatOutlined";
import IconButton from "@material-ui/core/IconButton";
import "./trace.css";
import { AddressSearch } from "../../components/addressSearch";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import stylesVersion from "../../styles/version_s.module.css";
import Tooltip from "@material-ui/core/Tooltip";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import plLocale from "date-fns/locale/pl";
import moment from "moment";
import MaskedInput from "react-maskedinput";
import { Calendar } from "react-feather";
import IconFuel from "../../components/buttons/IconFuel";
import Input from "../../components/input";

const Cargo = (props) => {
  const error = props.error;
  const [numberRef, setNumberRef] = useState();

  // console.log('props.data.NumRef',props.data.NumRef)
  // console.log('numberRef',numberRef)
  // console.log('props.data.id',props.data.id)
  // console.log('props.goodsOptions',props.goodsOptions)
  // console.log('---------------------')

  useEffect(() => {
    if (props.data.NumRef !== "" && props.data.NumRef !== undefined) {
      setNumberRef(props.data.NumRef);
    } else if (props.data.id) {
      let tempNum = props.goodsOptions.findIndex((o) => {
        return o.id === props.data.id;
      });

      tempNum = tempNum + 1;

      props.handleTrace(
        {
          target: {
            name: "NumRef",
            value: "ref_" + tempNum,
          },
        },
        props.propKey,
        props.cargoIndex
      );

      setNumberRef("ref_" + tempNum);
    }

    if (props.checkedGoods === false) {
      props.handleTrace(
        {
          target: {
            name: "NumRef",
            value: "ref_1",
          },
        },
        props.propKey,
        props.cargoIndex
      );

      setNumberRef("ref_1");
    }
  }, [props.data.NumRef, props.data.id]);

  const updateName = (data) => {
    if (data) {
      props.handleSelect(data, props.propKey, "name", props.cargoIndex);
      props.handleSelect(data, props.propKey, "id", props.cargoIndex);

      props.handleTrace(
        {
          target: {
            name: "details",
            value: data.details,
          },
        },
        props.propKey,
        props.cargoIndex
      );

      props.handleTrace(
        {
          target: {
            name: "unit",
            value: data.unit,
          },
        },
        props.propKey,
        props.cargoIndex
      );
      props.handleTrace(
        {
          target: {
            name: "quantity",
            value: data.quantity,
          },
        },
        props.propKey,
        props.cargoIndex
      );
      props.checkStatus();
    }
  };
  // const [warehouseValue ,setWarehouseValue] = useState(props)
  const ValueContainer = ({ children, selectProps, ...props }) => {
    return (
      <components.ValueContainer {...props} className={styles.ValueContainer}>
        <div className={styles.clientAddParent}>
          <IconButton
            disabled={props.user_s}
            className={`${styles.btnTraceIcon} ${
              props.user_s ? stylesVersion.onlyfull : ""
            }`}
            onClick={() => {
              selectProps.selectProps(
                selectProps.warehouses ? true : "Goods",
                false,
                selectProps.saveTab()
              );
            }}
          >
            <Plus className={styles.clientAdd} />
          </IconButton>
        </div>
        {children}
      </components.ValueContainer>
    );
  };

  return (
    <>
      {/* <Grid item xs={1} className={`${styles.btnCargoBox} ${styles.dense}`}>
			<IconButton
				className={styles.btnIcon}
				onClick={(e) => {
					props.addTraceCargo(props.propKey)
				}}
				value={props.propKey}
			>
				<PlusCircle className={styles.add} />
			</IconButton>
			<IconButton
				className={styles.btnIcon}
				onClick={() => {
					props.removeTraceCargo(props.propKey, props.cargoIndex);
				}}
				value={props.propKey}
			>
				<XCircle className={styles.remove} />
			</IconButton>
		</Grid> */}

      <Grid item className={styles.dense} xs={3}>
        <Tooltip title={props.user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={`${styles.btnCargoBox} ${styles.dense}`}>
            <IconButton
              disabled={props.user_s || props.checkedGoods === false}
              className={`${styles.btnIcon} ${
                props.user_s || props.checkedGoods === false
                  ? stylesVersion.onlyfull
                  : ""
              }`}
              onClick={(e) => {
                props.addTraceCargo(props.propKey);
              }}
              value={props.propKey}
            >
              <PlusCircle className={styles.add} />
            </IconButton>
            <IconButton
              disabled={props.user_s || props.checkedGoods === false}
              className={`${styles.btnIcon} ${
                props.user_s || props.checkedGoods === false
                  ? stylesVersion.onlyfull
                  : ""
              }`}
              onClick={() => {
                props.removeTraceCargo(props.propKey, props.cargoIndex);
              }}
              value={props.propKey}
            >
              <XCircle className={styles.remove} />
            </IconButton>
          </div>
        </Tooltip>
        <Tooltip title={props.user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={`${styles.inp} ${styles.withIcon}`}>
            <Select
              isDisabled={props.user_s || props.checkedGoods === false}
              className={`select ${
                props.user_s || props.checkedGoods === false
                  ? stylesVersion.onlyfull
                  : ""
              }`}
              classNamePrefix={
                error && !props.data.name
                  ? `error selectPrefix`
                  : "selectPrefix"
              }
              options={props.goodsOptions}
              isOptionDisabled={(option) => option.isdisabled}
              name="name"
              placeholder="Wybierz..."
              value={{ value: props.data.id, label: props.data.name }}
              components={{ ValueContainer }}
              selectProps={props.addTab}
              saveTab={props.saveTab}
              styles={{
                control: (base, state) => ({
                  ...base,
                  border: "1px solid #142f42",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #142f42",
                  },
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused && "#142f42",
                  color: state.isFocused && "#fff",
                }),
              }}
              onChange={(e) => {
                console.log("updateName", e);
                updateName(e);
              }}
            />
          </div>
        </Tooltip>
      </Grid>
      <Grid item className={styles.dense} xs={1}>
        <Tooltip title={props.user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={styles.inp}>
            <input
              disabled={props.user_s || props.checkedGoods === false}
              className={`${styles.input} ${
                props.user_s || props.checkedGoods === false
                  ? stylesVersion.onlyfull
                  : ""
              }`}
              value={props.data.quantity}
              onChange={(e) => {
                props.handleTrace(e, props.propKey, props.cargoIndex);
                props.checkStatus();
              }}
              name="quantity"
            />
          </div>
        </Tooltip>
      </Grid>
      <Grid item className={styles.dense} xs={1}>
        <Tooltip title={props.user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={styles.inp}>
            <input
              disabled={props.user_s || props.checkedGoods === false}
              className={`${styles.input} ${
                props.user_s || props.checkedGoods === false
                  ? stylesVersion.onlyfull
                  : ""
              }`}
              value={props.data.unit || ""}
              onChange={(e) => {
                props.handleTrace(e, props.propKey, props.cargoIndex);
                props.checkStatus();
              }}
              name="unit"
            />
          </div>
        </Tooltip>
      </Grid>
      <Grid item className={styles.dense} xs={1}>
        <Tooltip title={props.user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={styles.checkPar}>
            <input
              type="checkbox"
              disabled={props.user_s || props.checkedGoods === false}
              className={`${styles.check} ${
                props.user_s || props.checkedGoods === false
                  ? stylesVersion.onlyfull
                  : ""
              }`}
              value={props.data.palletExchange || false}
              onChange={(e) => {
                props.handleTrace(e, props.propKey, props.cargoIndex, true);
              }}
              name="palletExchange"
              checked={props.data.palletExchange || false}
            />
            {/* <label>Wymiana palet</label> */}
          </div>

          {/* <div className={styles.inp}>
					<input
						className={styles.input}
						value={props.data.palletExchange || ""}
						onChange={e => { props.handleTrace(e, props.propKey, props.cargoIndex) }}
						name="palletExchange" />
				</div> */}
        </Tooltip>
      </Grid>
      <Grid item className={styles.dense} xs={1}>
        <Tooltip title={props.user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={styles.inp}>
            <input
              disabled={props.user_s || props.checkedGoods === false}
              className={`${styles.input} ${
                props.user_s || props.checkedGoods === false
                  ? stylesVersion.onlyfull
                  : ""
              }`}
              value={props.data.ldmSize || ""}
              onChange={(e) => {
                if (e.target.value < 0) {
                  props.handleTrace(
                    { target: { name: "ldmSize", value: 0 } },
                    props.propKey,
                    props.cargoIndex
                  );
                } else {
                  props.handleTrace(e, props.propKey, props.cargoIndex);
                }
              }}
              type="number"
              name="ldmSize"
            />
          </div>
        </Tooltip>
      </Grid>
      <Grid item className={styles.dense} xs={1}>
        <Tooltip title={props.user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={styles.inp}>
            <input
              disabled={props.user_s || props.checkedGoods === false}
              className={`${styles.input} ${
                props.user_s || props.checkedGoods === false
                  ? stylesVersion.onlyfull
                  : ""
              }`}
              value={props.data.details || ""}
              onChange={(e) => {
                props.handleTrace(e, props.propKey, props.cargoIndex);
              }}
              name="details"
              type="textarea"
            />
          </div>
        </Tooltip>
      </Grid>
      <Grid item className={styles.dense} xs={1}>
        <Tooltip title={props.user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={styles.checkPar}>
            <input
              disabled={props.user_s || props.checkedGoods === false}
              type="checkbox"
              className={`${styles.check} ${
                props.user_s || props.checkedGoods === false
                  ? stylesVersion.onlyfull
                  : ""
              }`}
              value={props.data.ADR || false}
              onChange={(e) => {
                props.handleTrace(e, props.propKey, props.cargoIndex, true);
              }}
              name="ADR"
              checked={props.data.ADR || false}
            />
            {/* <label>ADR</label> */}
          </div>
        </Tooltip>
      </Grid>
      <Grid item className={styles.dense} xs={1}>
        <Tooltip title={props.user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={styles.inp}>
            <input
              disabled={props.user_s || props.checkedGoods === false}
              className={`${styles.input} ${
                props.user_s || props.checkedGoods === false
                  ? stylesVersion.onlyfull
                  : ""
              }`}
              value={props.data.ADRClass || ""}
              onChange={(e) => {
                props.handleTrace(e, props.propKey, props.cargoIndex);
              }}
              name="ADRClass"
              type="number"
            />
          </div>
        </Tooltip>
      </Grid>
      <Grid item className={styles.dense} xs={1}>
        <Tooltip title={props.user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={styles.inp}>
            <input
              disabled={props.user_s || props.checkedGoods === false}
              className={`${styles.input} ${
                error && !numberRef && styles.error
              } ${
                props.user_s || props.checkedGoods === false
                  ? stylesVersion.onlyfull
                  : ""
              }`}
              value={numberRef || ""}
              onChange={(e) => {
                props.handleTrace(e, props.propKey, props.cargoIndex);
              }}
              name="NumRef"
            />
          </div>
        </Tooltip>
      </Grid>
      <Grid item className={styles.dense} xs={1}></Grid>
    </>
  );
};

export default function Trace(props) {
  const [type, setType] = React.useState("");
  const [stopIcon, setStopIcon] = React.useState(
    <MapPin className={styles.mainIco} />
  );

  const typeOptions = [
    { label: "Parking", value: "parking" },
    { label: "Prom", value: "ferry" },
    { label: "Początek trasy", value: "start" },
    { label: "Koniec trasy", value: "end" },
    { label: "Przejście graniczne", value: "border" },
    { label: "Punkt kontrolny", value: "point" },
    { label: "Tankowanie", value: "refueling" },
  ];
  const [warehouse, setWarehouse] = useState(props.data.warehouse);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");
  const [isLatLng, setIsLatLng] = useState(
    !!props?.data?.address?.latitude && !!props?.data?.address?.longitude
  );

  // const [country, setCountry] = useState(props.data.address ? props.data.address.country : '')
  // const [city, setCity] = useState(props.data.address ? props.data.address?.city : '')
  // const [street, setStreet] = useState(props.data.address?.street)
  // const [street_no, setStreet_no] = useState(props.data.address?.street_no)
  const [apartment_no, setApartment_no] = useState(
    props.data.address?.apartment_no
  );
  const [firm_name, setFirmName] = useState(props.data.address?.firm_name);
  // const [zipcode, setZipcode] = useState(props.data.address?.zipcode)

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDialog(false);
  };
  useEffect(() => {
    if (props.clearHooksState === true) {
      setWarehouse(props.data.warehouse);
    }
  }, [props.clearHooksState]);

  useEffect(() => {
    if (props.data.warehouse && props.data.warehouse?.id) {
      setWarehouse(props.data.warehouse);
    }
  }, [props.data.warehouse, warehouse]);

  useEffect(() => {
    if (props.data.type === "loading" || props.data.type === "unloading") {
      if (warehouse !== undefined && props.warehousesOptions.length) {
        const wareHouseDetail = props.warehousesOptions.find(
          (e) => e.id === warehouse?.id
        );
        if (!!wareHouseDetail) {
          props.handleTrace(
            {
              target: {
                name: "warehouse",
                value: wareHouseDetail,
              },
            },
            props.propKey
          );
        }
        setWarehouse(wareHouseDetail);
      }
    }
  }, [props.data, props.warehousesOptions]);

  useEffect(() => {
    if (!!props.data.subtype) {
      props.handleTrace(
        {
          target: {
            name: "subtype",
            value: props.data.subtype,
          },
        },
        props.propKey
      );
      setStopIcon(icon(props.data.subtype));
      setType(setLabelForSubtype(props.data.subtype));
    }
  }, [props.data.subtype]);

  const error = props.error;
  const ValueContainer = ({ children, selectProps, ...props }) => {
    return (
      <components.ValueContainer {...props} className={styles.ValueContainer}>
        <div className={styles.clientAddParent}>
          <IconButton
            disabled={props.user_s}
            className={`${styles.btnTraceIcon} ${
              props.user_s ? stylesVersion.onlyfull : ""
            }`}
            onClick={() => {
              selectProps.selectProps(
                selectProps.warehouses ? "Warehouse" : "Goods"
              );
            }}
          >
            <Plus className={styles.clientAdd} />
          </IconButton>
        </div>
        {children}
      </components.ValueContainer>
    );
  };

  const setLabelForSubtype = (val) => {
    switch (val) {
      case "parking":
        return { value: "parking", label: "Parking" };
      case "ferry":
        return { value: "ferry", label: "Prom" };
      case "start":
        return { value: "start", label: "Początek trasy" };
      case "end":
        return { value: "end", label: "Koniec trasy" };
      case "border":
        return { value: "start", label: "Przejście graniczne" };
      case "point":
        return { value: "point", label: "Punkt kontrolny" };
      case "refueling":
        return { value: "refueling", label: "Tankowanie" };
      default:
        return { value: "point", label: "Punkt kontrolny" };
    }
  };

  const icon = (type) => {
    switch (type) {
      case "loading":
        return (
          <Download
            className={`${styles.mainIco} ${
              props.user_s ? stylesVersion.onlyfull : ""
            }`}
          />
        );
      case "unloading":
        return (
          <Upload
            className={`${styles.mainIco} ${
              props.user_s ? stylesVersion.onlyfull : ""
            }`}
          />
        );

      case "parking":
        return (
          <LocalParkingOutlinedIcon
            className={`${styles.matMainIco} ${
              props.user_s ? stylesVersion.onlyfull : ""
            }`}
          />
        );

      case "ferry":
        return (
          <DirectionsBoatOutlinedIcon
            className={`${styles.matMainIco} ${
              props.user_s ? stylesVersion.onlyfull : ""
            }`}
          />
        );

      case "start":
        return (
          <ArrowForwardOutlinedIcon
            className={`${styles.matMainIco} ${
              props.user_s ? stylesVersion.onlyfull : ""
            }`}
          />
        );

      case "end":
        return (
          <KeyboardTabOutlinedIcon
            className={`${styles.matMainIco} ${
              props.user_s ? stylesVersion.onlyfull : ""
            }`}
          />
        );

      case "border":
        return (
          <FlagOutlinedIcon
            className={`${styles.matMainIco} ${
              props.user_s ? stylesVersion.onlyfull : ""
            }`}
          />
        );

      case "point":
        return (
          <MapPin
            className={`${styles.mainIco} ${
              props.user_s ? stylesVersion.onlyfull : ""
            }`}
          />
        );

      case "refueling":
        return (
          <IconFuel
            className={`${styles.mainIco} ${
              props.user_s ? stylesVersion.onlyfull : ""
            }`}
          />
        );

      default:
        return (
          <MapPin
            className={`${styles.mainIco} ${
              props.user_s ? stylesVersion.onlyfull : ""
            }`}
          />
        );
    }
  };

  let newDate = props.data.date ? new Date(props.data.date) : "";

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div
        className={`${styles.input} ${"datapicker_new"} ${
          props.error === true && error && !props.value && styles.error
        } ${props.user_s ? stylesVersion.onlyfull : ""}
		  		${
            props.isShowRequiredFields && props.value !== ""
              ? "isShowRequiredFieldsVal"
              : props.isShowRequiredFields && props.traceIndex === 0
              ? "isShowRequiredFields"
              : ""
          }`}
      >
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <Calendar onClick={props.onClick} />
      </div>
    );
  });

  useEffect(() => {
    if (props.data.manual_adress === true) {
      if (props.data.warehouse) {
        props.data.warehouse_temp = props.data.warehouse;
        delete props.data.warehouse;
      }
    }

    if (props.data.manual_adress === false) {
      if (props.data.address !== undefined) {
        delete props.data.address;
      }
      if (props.data.warehouse_temp) {
        props.data.warehouse = props.data.warehouse_temp;
        delete props.data.warehouse_temp;
      }
    }
  }, [props.data.manual_adress]);

  useEffect(() => {
    if (props.data.address?.apartment_no) {
      setApartment_no(props.data.address?.apartment_no);
    }
  }, [props.data.address]);

  useEffect(() => {
    if (props.data.address?.firm_name) {
      setFirmName(props.data.address?.firm_name);
    }
  }, [props.data.firm_name]);

  // console.log("isLatLng",isLatLng)
  // console.log("props?.data?.address.latitude && props?.data?.address.longitude",props?.data?.address?.latitude, props?.data?.address?.longitude)

  if (props.type === "stop") {
    return (
      <form className={`${styles.traceBox} ${styles.sm}`}>
        <div className={styles.moveBox}></div>
        <Move className={styles.moveIcon} />
        <div className={`${props.user_s ? stylesVersion.onlyfull : ""}`}>
          {stopIcon}
        </div>
        <Grid container spacing={2} className={styles.tBox}>
          <Grid item xs={1}>
            <Tooltip
              title={props.user_s ? "Dostępne tylko w wersji pełnej" : ""}
            >
              <div className={styles.inp}>
                <label
                  className={`${props.user_s ? stylesVersion.onlyfull : ""}`}
                >
                  Typ
                </label>
                <Select
                  isDisabled={props.user_s}
                  className={`select ${
                    props.user_s ? stylesVersion.onlyfull : ""
                  }`}
                  value={type}
                  options={typeOptions}
                  classNamePrefix="selectPrefix"
                  onChange={(e) => {
                    props.handleTrace(
                      {
                        target: {
                          name: "subtype",
                          value: e.value,
                        },
                      },
                      props.propKey
                    );
                    setStopIcon(icon(e.value));
                    setType(setLabelForSubtype(e.value));
                  }}
                  name="subtype"
                  placeholder="Wybierz typ"
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      border: "1px solid #142f42",
                      boxShadow: "none",
                      "&:hover": {
                        border: "1px solid #142f42",
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                    }),
                  }}
                />
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <Tooltip
              title={props.user_s ? "Dostępne tylko w wersji pełnej" : ""}
            >
              <div className={styles.inp}>
                <label
                  className={`${props.user_s ? stylesVersion.onlyfull : ""}`}
                >
                  Data
                </label>
                <DatePicker
                  disabled={props.user_s}
                  dateFormat="dd.MM.yyyy HH:mm"
                  selected={newDate}
                  onChange={(e) => {
                    // setStartDate(e)
                    let newD = moment(e).valueOf();
                    let newDS = moment(newD).format("yyyy-MM-DDTHH:mm");
                    props.handleTraceNew(newDS, props.propKey, "date");
                  }}
                  showTimeSelect
                  timeIntervals={10}
                  locale={plLocale}
                  name="date"
                  customInput={<CustomInput error={false} />}
                  placeholderText={"dd.mm.rrrr gg:mm"}
                />
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <Tooltip
              title={props.user_s ? "Dostępne tylko w wersji pełnej" : ""}
            >
              <div className={styles.inp}>
                <label
                  className={`${props.user_s ? stylesVersion.onlyfull : ""}`}
                >
                  {type.value === "refueling"
                    ? "Numer karty paliwowej"
                    : "Komentarz"}
                </label>
                <input
                  className={`${styles.input} ${
                    props.user_s ? stylesVersion.onlyfull : ""
                  }`}
                  disabled={props.user_s}
                  value={props.data.comment || ""}
                  onChange={(e) => {
                    props.handleTrace(e, props.propKey);
                  }}
                  name="comment"
                />
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={4}>
            <Tooltip
              title={props.user_s ? "Dostępne tylko w wersji pełnej" : ""}
            >
              <div className={styles.inp}>
                {/* {console.log('>>>>',props.data.address.address_display_name || null)} */}
                <AddressSearch
                  user_s={props.user_s}
                  token={props.user.csrf_token}
                  setAddress={(e) =>
                    props.handleTrace(e, props.propKey, false, false, true)
                  }
                  dense
                  value={
                    (props.data.address &&
                      props.data.address.address_display_name) ||
                    null
                  }
                  // value={props.data.address ? `${props.data.address.street && props.data.address.street + ' '} ${props.data.address.street_no && props.data.address.street_no + ', '}${props.data.address.zipcode && props.data.address.zipcode + ' '}${props.data.address.city && props.data.address.city + ' '}${props.data.address.district && props.data.address.district + ' '}${props.data.address.country && props.data.address.country + ' '}` : null}
                  error={error}
                  // 3, Gustawa Morcinka, Górce, Bemowo, Warszawa, województwo mazowieckie, 01-496, Polska
                />
              </div>
            </Tooltip>
          </Grid>
        </Grid>
        <Tooltip title={props.user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={styles.corner}>
            <IconButton
              disabled={props.user_s}
              className={`${styles.btnIcon} ${
                props.user_s ? stylesVersion.onlyfull : ""
              }`}
              value={props.propKey || ""}
              onClick={() => {
                props.moveTrace(props.propKey, "up");
              }}
            >
              <ArrowUpCircle className={styles.primary} size={18} />
            </IconButton>
            <IconButton
              disabled={props.user_s}
              className={`${styles.btnIcon} ${
                props.user_s ? stylesVersion.onlyfull : ""
              }`}
              value={props.propKey || ""}
              onClick={() => {
                props.moveTrace(props.propKey, "down");
              }}
            >
              <ArrowDownCircle className={styles.primary} size={18} />
            </IconButton>
            <IconButton
              disabled={props.user_s}
              className={`${styles.btnIcon} ${
                props.user_s ? stylesVersion.onlyfull : ""
              }`}
              value={props.propKey || ""}
              onClick={() => {
                props.copyTrace(props.propKey);
              }}
            >
              <Copy className={styles.primary} size={18} />
            </IconButton>
            <IconButton
              disabled={props.user_s}
              className={`${styles.btnIcon} ${
                props.user_s ? stylesVersion.onlyfull : ""
              }`}
              value={props.propKey || ""}
              onClick={() => {
                props.removeTrace(props.propKey);
              }}
            >
              <XCircle className={styles.remove} size={18} />
            </IconButton>
          </div>
        </Tooltip>
        {/* <Tooltip title={props.user_s ? "Dostępne tylko w wersji pełnej" : ""}>
					<div className={styles.lowerCorner}>
						<IconButton disabled={props.user_s} className={`${styles.btnIcon} ${props.user_s ? stylesVersion.onlyfull : ''}`} value={props.propKey || ""} onClick={() => { props.moveTrace(props.propKey, "up") }}>
							<ArrowUpCircle className={styles.primary} size ={18}/>
						</IconButton>
						<IconButton disabled={props.user_s} className={`${styles.btnIcon} ${props.user_s ? stylesVersion.onlyfull : ''}`} value={props.propKey || ""} onClick={() => { props.moveTrace(props.propKey, "down") }}>
							<ArrowDownCircle className={styles.primary} size ={18}/>
						</IconButton>
						<IconButton disabled={props.user_s} className={`${styles.btnIcon} ${props.user_s ? stylesVersion.onlyfull : ''}`} value={props.propKey || ""} onClick={() => { props.copyTrace(props.propKey) }}>
							<Copy className={styles.primary} size ={18}/>
						</IconButton>
					</div>
				</Tooltip> */}
      </form>
    );
  } else {
    return (
      <form className={styles.traceBox}>
        <div className={styles.moveBox}></div>
        <Move className={styles.moveIcon} />

        <Grid container spacing={2} className={styles.tBox}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <div className={styles.newRow}>
                  <div>{icon(props.type)}</div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={2}>
                            <Tooltip
                              title={
                                props.user_s
                                  ? "Dostępne tylko w wersji pełnej"
                                  : ""
                              }
                            >
                              <div className={styles.inp}>
                                <label
                                  className={`${
                                    props.user_s ? stylesVersion.onlyfull : ""
                                  }`}
                                >
                                  Data
                                </label>
                                <DatePicker
                                  disabled={props.user_s}
                                  dateFormat="dd.MM.yyyy HH:mm"
                                  selected={newDate}
                                  onChange={(e) => {
                                    // setStartDate(e)
                                    let newD = moment(e).valueOf();
                                    let newDS =
                                      moment(newD).format("yyyy-MM-DDTHH:mm");
                                    props.handleTraceNew(
                                      newDS,
                                      props.propKey,
                                      "date"
                                    );
                                  }}
                                  style={{ backgroundColor: "red !important" }}
                                  showTimeSelect
                                  timeIntervals={10}
                                  locale={plLocale}
                                  name="date"
                                  placeholderText={"dd.mm.rrrr gg:mm"}
                                  customInput={
                                    <CustomInput
                                      error={props.user_s ? false : true}
                                      isShowRequiredFields={
                                        props.isShowRequiredFields
                                      }
                                      traceIndex={props.index}
                                    />
                                  }
                                  // customInput={
                                  // 	<MaskedInput mask="11.11.1111 11:11" placeholder="dd.MM.YYYY HH:mm" />
                                  //   }
                                />
                              </div>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={2}>
                            <Tooltip
                              title={
                                props.user_s
                                  ? "Dostępne tylko w wersji pełnej"
                                  : ""
                              }
                            >
                              <div className={styles.inp}>
                                <label
                                  className={`${
                                    props.user_s ? stylesVersion.onlyfull : ""
                                  }`}
                                >
                                  Komentarz
                                </label>
                                <input
                                  disabled={props.user_s}
                                  value={props.data.comment || ""}
                                  className={`${styles.input} ${
                                    props.user_s ? stylesVersion.onlyfull : ""
                                  }`}
                                  onChange={(e) => {
                                    props.handleTrace(e, props.propKey);
                                  }}
                                  name="comment"
                                />
                              </div>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={2}>
                            <div className={styles.manualAdress}>
                              <input
                                type="checkbox"
                                disabled={props.user_s}
                                className={`${styles.check} ${
                                  props.user_s ? stylesVersion.onlyfull : ""
                                }`}
                                onChange={(e) => {
                                  if (props.propKey === 0) {
                                    if (e.target.checked) {
                                      props.setaccess_kilometers("");
                                      props.setcargo_kilometers("");
                                      props.setsum_kilometers("");
                                      props.setCost("");
                                    }
                                  }
                                  props.handleTrace(
                                    e,
                                    props.propKey,
                                    props.cargoIndex,
                                    true
                                  );
                                }}
                                name="manual_adress"
                                checked={props.data.manual_adress || false}
                              />
                              <label
                                className={`${
                                  props.user_s ? stylesVersion.onlyfull : ""
                                }`}
                              >
                                Ustaw adres ręcznie
                              </label>
                            </div>
                          </Grid>
                          <Grid item xs={2}>
                            <Tooltip
                              title={
                                props.user_s
                                  ? "Dostępne tylko w wersji pełnej"
                                  : ""
                              }
                            >
                              <div className={styles.inp}>
                                <label
                                  className={`${
                                    props.user_s ? stylesVersion.onlyfull : ""
                                  }`}
                                >
                                  Magazyn
                                </label>
                                <Select
                                  isDisabled={
                                    props.user_s ||
                                    props.data.manual_adress === true
                                  }
                                  value={warehouse || null}
                                  className={`select ${
                                    props.user_s ? stylesVersion.onlyfull : ""
                                  }`}
                                  classNamePrefix={
                                    !props.data.manual_adress &&
                                    error &&
                                    !warehouse?.address
                                      ? `error selectPrefix`
                                      : "selectPrefix"
                                  }
                                  options={props.warehousesOptions}
                                  name="warehouse"
                                  isOptionDisabled={(option) =>
                                    option.isdisabled
                                  }
                                  warehouses
                                  placeholder="Wybierz..."
                                  components={{ ValueContainer }}
                                  selectProps={props.setOpenModalNewWarehouse}
                                  saveTab={props.saveTab}
                                  styles={{
                                    control: (base, state) => ({
                                      ...base,
                                      border: "1px solid #142f42",
                                      backgroundColor:
                                        props.index === 0 &&
                                        props.isShowRequiredFields &&
                                        !props.toCostValues &&
                                        warehouse?.address === undefined
                                          ? "#e1f3d3 !important"
                                          : state.isDisabled === true
                                          ? "#eee"
                                          : "#fff",
                                      borderColor:
                                        props.index === 0 &&
                                        props.isShowRequiredFields &&
                                        !props.toCostValues &&
                                        "#82cd47 !important",
                                      boxShadow: "none",
                                      "&:hover": {
                                        border: "1px solid #142f42",
                                      },
                                      // '& > div:first-child > div:nth-child(2)': {
                                      // 	marginTop: '-1px'
                                      // }
                                    }),
                                    option: (provided, state) => ({
                                      ...provided,
                                      backgroundColor:
                                        state.isFocused && "#142f42",
                                      color: state.isFocused && "#fff",
                                    }),
                                  }}
                                  onChange={(e) => {
                                    // props.handleSelect(e, props.propKey, "name")

                                    props.setaccess_kilometers();
                                    props.setcargo_kilometers();
                                    props.setsum_kilometers();
                                    props.setCost();

                                    setWarehouse(e);
                                    props.handleTrace(
                                      {
                                        target: {
                                          name: "warehouse",
                                          value: e,
                                        },
                                      },
                                      props.propKey
                                    );
                                    props.checkStatus();
                                  }}
                                />
                              </div>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={3} className={styles.traceTxtParent}>
                            <Tooltip
                              title={
                                props.user_s
                                  ? "Dostępne tylko w wersji pełnej"
                                  : ""
                              }
                            >
                              <p
                                className={`${styles.traceTxt} ${styles.flexRow}`}
                              >
                                <strong
                                  className={`${
                                    props.user_s ? stylesVersion.onlyfull : ""
                                  }`}
                                >
                                  Adres:{" "}
                                </strong>
                                <span>
                                  {warehouse?.address?.country || ""}
                                  {warehouse?.address?.country && ", "}
                                  {warehouse?.address?.city || ""}
                                  {warehouse?.address?.city && ", "}
                                  {warehouse?.address?.street || ""}{" "}
                                  {warehouse?.address?.street_no || ""}
                                </span>
                                <IconButton
                                  disabled={props.user_s}
                                  className={`${styles.btnCopyTxt} ${
                                    props.user_s ? stylesVersion.onlyfull : ""
                                  }`}
                                  onClick={() => {
                                    const el =
                                      document.createElement("textarea");
                                    el.value = `${
                                      warehouse?.address?.country || ""
                                    }, ${warehouse?.address?.city || ""}, ${
                                      warehouse?.address?.street || ""
                                    } ${warehouse?.address?.street_no || ""}`;
                                    document.body.appendChild(el);
                                    el.select();
                                    document.execCommand("copy");
                                    document.body.removeChild(el);
                                    setOpenDialog(true);
                                    setDialogContent("Skopiowano adres");
                                    setDialogColor("success");
                                  }}
                                  size="small"
                                >
                                  <Copy
                                    disabled={props.user_s}
                                    className={styles.primary}
                                  />
                                </IconButton>
                              </p>
                            </Tooltip>

                            <p className={styles.traceTxt}>
                              <strong
                                className={`${
                                  props.user_s ? stylesVersion.onlyfull : ""
                                }`}
                              >
                                Kontakt:{" "}
                              </strong>
                              {warehouse?.contacts?.[0]?.fullname || ""}
                              <strong
                                className={`${
                                  props.user_s ? stylesVersion.onlyfull : ""
                                }`}
                              >
                                tel:{" "}
                              </strong>
                              {warehouse?.contacts?.[0]?.phone || ""}
                              <strong
                                className={`${
                                  props.user_s ? stylesVersion.onlyfull : ""
                                }`}
                              >
                                email:{" "}
                              </strong>
                              {warehouse?.contacts?.[0]?.email || ""}
                            </p>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            className={styles.absoluteCheckboxGrid}
                          >
                            <Tooltip
                              title={
                                props.user_s
                                  ? "Dostępne tylko w wersji pełnej"
                                  : ""
                              }
                            >
                              <div className={styles.checkPar}>
                                <input
                                  disabled={props.user_s}
                                  type="checkbox"
                                  className={`${styles.check} ${
                                    props.user_s ? stylesVersion.onlyfull : ""
                                  }`}
                                  value={
                                    props.data.access_via_transport_docs ||
                                    false
                                  }
                                  onChange={(e) => {
                                    props.handleTrace(
                                      e,
                                      props.propKey,
                                      undefined,
                                      true
                                    );
                                  }}
                                  name="access_via_transport_docs"
                                  checked={
                                    props.data.access_via_transport_docs ||
                                    false
                                  }
                                />
                                <label
                                  className={`${styles.longLabel} ${
                                    props.user_s ? stylesVersion.onlyfull : ""
                                  }`}
                                >
                                  Dojazd wg dokumentów przewozu
                                </label>
                              </div>
                            </Tooltip>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            className={`${styles.btnCargoBox} ${styles.dense} `}
                          ></Grid>
                        </Grid>
                      </Grid>

                      {props.data.manual_adress !== undefined &&
                        props.data.manual_adress === true && (
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={2}>
                                <div className={styles.inp}>
                                  <label>Nazwa firmy</label>
                                  <input
                                    type="text"
                                    value={props.data.address?.firm_name || ""}
                                    onChange={(e) => {
                                      setFirmName(e.target.value);
                                      const newFirmName = props.trace.map(
                                        (o, i) => {
                                          if (i === props.propKey) {
                                            if (o.manual_adress === true) {
                                              if (o.address === undefined) {
                                                o.address = {};
                                                o.address.firm_name =
                                                  e.target.value;
                                              } else {
                                                o.address.firm_name =
                                                  e.target.value;
                                              }
                                            }
                                          }
                                          return o;
                                        }
                                      );

                                      props.setTrace(newFirmName);
                                    }}
                                    className={styles.input}
                                    name="firm_name"
                                    // disabled
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={2}>
                                <Tooltip
                                  title={
                                    props.user_s
                                      ? "Dostępne tylko w wersji pełnej"
                                      : ""
                                  }
                                >
                                  <div className={styles.inp}>
                                    <AddressSearch
                                      user_s={props.user_s}
                                      token={props.user.csrf_token}
                                      setAddress={(e) => {
                                        props.setaccess_kilometers();
                                        props.setcargo_kilometers();
                                        props.setsum_kilometers();
                                        props.setCost();
                                        setApartment_no();
                                        // props.setShowPointAddress(e)

                                        props.handleTrace(
                                          e,
                                          props.propKey,
                                          false,
                                          false,
                                          true
                                        );
                                        if (firm_name !== "") {
                                          const newFirmName = props.trace.map(
                                            (o, i) => {
                                              if (i === props.propKey) {
                                                if (o.manual_adress === true) {
                                                  o.address.firm_name =
                                                    firm_name;
                                                }
                                              }
                                              return o;
                                            }
                                          );

                                          props.setTrace(newFirmName);
                                        }
                                        if (apartment_no !== "") {
                                          const newFirmName = props.trace.map(
                                            (o, i) => {
                                              if (i === props.propKey) {
                                                if (o.manual_adress === true) {
                                                  o.address.apartment_no =
                                                    apartment_no;
                                                }
                                              }
                                              return o;
                                            }
                                          );

                                          props.setTrace(newFirmName);
                                        }
                                      }}
                                      dense
                                      value={
                                        props?.data?.address
                                          ?.address_display_name
                                      }
                                      error={error}
                                    />
                                  </div>
                                </Tooltip>
                              </Grid>

                              <Grid item xs={1}>
                                <div className={styles.inp}>
                                  <label>Kraj</label>
                                  <input
                                    type="text"
                                    value={props.data.address?.country || ""}
                                    className={styles.input}
                                    onChange={(e) => {
                                      const tmp = props.trace.map((o, i) => {
                                        if (i === props.propKey) {
                                          if (o.manual_adress === true) {
                                            if (o.address === undefined) {
                                              o.address = {};
                                              o.address.country =
                                                e.target.value;
                                            } else {
                                              o.address.country =
                                                e.target.value;
                                            }
                                          }
                                        }
                                        return o;
                                      });

                                      props.setTrace(tmp);
                                    }}
                                    name="country"
                                    // disabled
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={1}>
                                <div className={styles.inp}>
                                  <label>Miasto</label>
                                  <input
                                    type="text"
                                    value={props.data.address?.city || ""}
                                    className={styles.input}
                                    onChange={(e) => {
                                      const tmp = props.trace.map((o, i) => {
                                        if (i === props.propKey) {
                                          if (o.manual_adress === true) {
                                            if (o.address === undefined) {
                                              o.address = {};
                                              o.address.city = e.target.value;
                                            } else {
                                              o.address.city = e.target.value;
                                            }
                                          }
                                        }
                                        return o;
                                      });

                                      props.setTrace(tmp);
                                    }}
                                    name="city"
                                    // disabled
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={1}>
                                <div className={styles.inp}>
                                  <label>Kod pocztowy</label>
                                  <input
                                    type="text"
                                    value={props.data.address?.zipcode || ""}
                                    className={styles.input}
                                    onChange={(e) => {
                                      const tmp = props.trace.map((o, i) => {
                                        if (i === props.propKey) {
                                          if (o.manual_adress === true) {
                                            if (o.address === undefined) {
                                              o.address = {};
                                              o.address.zipcode =
                                                e.target.value;
                                            } else {
                                              o.address.zipcode =
                                                e.target.value;
                                            }
                                          }
                                        }
                                        return o;
                                      });

                                      props.setTrace(tmp);
                                    }}
                                    name="zipcode"
                                    // disabled
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={2}>
                                <div className={styles.inp}>
                                  <label>Ulica</label>
                                  <input
                                    type="text"
                                    value={props.data.address?.street || ""}
                                    className={styles.input}
                                    onChange={(e) => {
                                      const tmp = props.trace.map((o, i) => {
                                        if (i === props.propKey) {
                                          if (o.manual_adress === true) {
                                            if (o.address === undefined) {
                                              o.address = {};
                                              o.address.street = e.target.value;
                                            } else {
                                              o.address.street = e.target.value;
                                            }
                                          }
                                        }
                                        return o;
                                      });

                                      props.setTrace(tmp);
                                    }}
                                    name="street"
                                    // disabled
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={1} style={{ maxWidth: "70px" }}>
                                <div className={styles.inp}>
                                  <label>Numer</label>
                                  <input
                                    type="text"
                                    value={props.data.address?.street_no || ""}
                                    className={styles.input}
                                    name="street_no"
                                    onChange={(e) => {
                                      const newStreetNo = props.trace.map(
                                        (o, i) => {
                                          if (i === props.propKey) {
                                            o.address.street_no =
                                              e.target.value;
                                          }
                                          return o;
                                        }
                                      );

                                      props.setTrace(newStreetNo);
                                    }}
                                    // disabled
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={1} style={{ maxWidth: "70px" }}>
                                <div className={styles.inp}>
                                  <label>Lokal</label>
                                  <input
                                    type="text"
                                    value={
                                      props.data.address?.apartment_no || ""
                                    }
                                    onChange={(e) => {
                                      setApartment_no(e.target.value);
                                      const newApartmentNo = props.trace.map(
                                        (o, i) => {
                                          if (i === props.propKey) {
                                            if (o.manual_adress === true) {
                                              if (o.address === undefined) {
                                                o.address = {};
                                                o.address.apartment_no =
                                                  e.target.value;
                                              } else {
                                                o.address.apartment_no =
                                                  e.target.value;
                                              }
                                            }
                                          }
                                          return o;
                                        }
                                      );
                                      props.setTrace(newApartmentNo);
                                    }}
                                    className={styles.input}
                                    name="apartment_no"
                                    // disabled
                                  />
                                </div>
                              </Grid>

                              {props?.data?.address?.address_display_name !==
                                undefined && (
                                <Grid item xs={"auto"}>
                                  <div className={styles.inp}>
                                    <Tooltip
                                      title={"Koryguj współrzędne punktu"}
                                    >
                                      <Button
                                        className={styles.traceBtn}
                                        onClick={(e) => {
                                          props.setTraceIndex(props.index);
                                          props.setTraceData(props.data);
                                          props.setOpenModalShowPoint(true);
                                        }}
                                      >
                                        <MapPin
                                          style={{
                                            minWidth: "16px",
                                            maxWidth: "16px",
                                          }}
                                        />
                                        Koryguj współrzędne
                                      </Button>
                                    </Tooltip>
                                  </div>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        )}
                    </Grid>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ padding: 0 }}>
            <hr
              style={{ width: "97%", borderTop: "0", borderColor: "#e5e5e5" }}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item className={styles.dense} xs={3}>
                <div className={`${styles.inp} ${styles.withIcon}`}>
                  <label
                    className={`${props.user_s ? stylesVersion.onlyfull : ""}`}
                  >
                    Nazwa towaru
                  </label>
                </div>
              </Grid>
              <Grid item className={styles.dense} xs={1}>
                <div className={styles.inp}>
                  <label
                    className={`${props.user_s ? stylesVersion.onlyfull : ""}`}
                  >
                    Ilość
                  </label>
                </div>
              </Grid>
              <Grid item className={styles.dense} xs={1}>
                <div className={styles.inp}>
                  <label
                    className={`${props.user_s ? stylesVersion.onlyfull : ""}`}
                  >
                    Jednostka
                  </label>
                </div>
              </Grid>
              <Grid item className={styles.dense} xs={1}>
                <div className={styles.inp}>
                  <label
                    className={`${props.user_s ? stylesVersion.onlyfull : ""}`}
                  >
                    Wymiana palet
                  </label>
                </div>
              </Grid>
              <Grid item className={styles.dense} xs={1}>
                <div className={styles.inp}>
                  <label
                    className={`${props.user_s ? stylesVersion.onlyfull : ""}`}
                  >
                    Rozmiar LDM
                  </label>
                </div>
              </Grid>
              <Grid item className={styles.dense} xs={1}>
                <div className={styles.inp}>
                  <label
                    className={`${props.user_s ? stylesVersion.onlyfull : ""}`}
                  >
                    Opis Towaru
                  </label>
                </div>
              </Grid>
              <Grid item className={styles.dense} xs={1}>
                <div className={styles.inp}>
                  <label
                    className={`${props.user_s ? stylesVersion.onlyfull : ""}`}
                  >
                    ADR
                  </label>
                </div>
              </Grid>
              <Grid item className={styles.dense} xs={1}>
                <div className={styles.inp}>
                  <label
                    className={`${props.user_s ? stylesVersion.onlyfull : ""}`}
                  >
                    Klasa ADR
                  </label>
                </div>
              </Grid>
              <Grid item className={styles.dense} xs={1}>
                <div className={styles.inp}>
                  <label
                    className={`${props.user_s ? stylesVersion.onlyfull : ""}`}
                  >
                    Numer ref
                  </label>
                </div>
              </Grid>
              <Grid
                item
                xs={1}
                className={`${styles.btnCargoBox} ${styles.dense} `}
              ></Grid>
              {/* {!props.data.cargo[0]?.name && <span>jjjjjjjj</span>} */}
              {props.data.cargo &&
                props.data.cargo.map((e, i) => {
                  // console.table(
                  // 		[props.user_s,
                  // 		// props.addTab,
                  // 		// props.saveTab,
                  // 		props.propKey,
                  // 		props.goodsOptions,
                  // 		// props.checkStatus,
                  // 		e,
                  // 		// props.handleTrace,
                  // 		// props.handleSelect,
                  // 		// props.addTraceCargo,
                  // 		// props.removeTraceCargo,
                  // 		i,
                  // 		props.error,
                  // 		props.checkedGoods]
                  // 	)

                  return (
                    <Cargo
                      user_s={props.user_s}
                      addTab={props.addTab}
                      saveTab={props.saveTab}
                      propKey={props.propKey}
                      goodsOptions={props.goodsOptions}
                      checkStatus={props.checkStatus}
                      data={e}
                      handleTrace={props.handleTrace}
                      handleSelect={props.handleSelect}
                      addTraceCargo={props.addTraceCargo}
                      removeTraceCargo={props.removeTraceCargo}
                      key={i}
                      cargoIndex={i}
                      error={props.error}
                      checkedGoods={props.checkedGoods}
                      traceCargo={true}
                    />
                  );
                })}
            </Grid>
          </Grid>
        </Grid>

        <Tooltip title={props.user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={styles.corner}>
            <IconButton
              disabled={props.user_s}
              className={`${styles.btnIcon} ${
                props.user_s ? stylesVersion.onlyfull : ""
              }`}
              value={props.propKey}
              onClick={() => {
                props.moveTrace(props.propKey, "up");
              }}
            >
              <ArrowUpCircle className={styles.primary} size={18} />
            </IconButton>
            <IconButton
              disabled={props.user_s}
              className={`${styles.btnIcon} ${
                props.user_s ? stylesVersion.onlyfull : ""
              }`}
              value={props.propKey}
              onClick={() => {
                props.moveTrace(props.propKey, "down");
              }}
            >
              <ArrowDownCircle className={styles.primary} size={18} />
            </IconButton>
            <IconButton
              disabled={props.user_s}
              className={`${styles.btnIcon} ${
                props.user_s ? stylesVersion.onlyfull : ""
              }`}
              value={props.propKey}
              onClick={() => {
                props.copyTrace(props.propKey);
              }}
            >
              <Copy className={styles.primary} size={18} />
            </IconButton>
            <IconButton
              disabled={props.user_s}
              className={`${styles.btnIcon} ${
                props.user_s ? stylesVersion.onlyfull : ""
              }`}
              value={props.propKey}
              onClick={() => {
                props.removeTrace(props.propKey);
              }}
            >
              <XCircle className={styles.remove} size={18} />
            </IconButton>
          </div>
        </Tooltip>

        {/* <Tooltip title={props.user_s ? "Dostępne tylko w wersji pełnej" : ""}>
					<div className={styles.lowerCorner}>
						<IconButton disabled={props.user_s} className={`${styles.btnIcon} ${props.user_s ? stylesVersion.onlyfull : ''}`} value={props.propKey} onClick={() => { props.moveTrace(props.propKey, "up") }}>
							<ArrowUpCircle className={styles.primary} size ={18}/>
						</IconButton>
						<IconButton disabled={props.user_s} className={`${styles.btnIcon} ${props.user_s ? stylesVersion.onlyfull : ''}`} value={props.propKey} onClick={() => { props.moveTrace(props.propKey, "down") }}>
							<ArrowDownCircle className={styles.primary} size ={18}/>
						</IconButton>
						<IconButton disabled={props.user_s} className={`${styles.btnIcon} ${props.user_s ? stylesVersion.onlyfull : ''}`} value={props.propKey} onClick={() => {
							props.copyTrace(props.propKey) }}>
							<Copy className={styles.primary} size ={18}/>
						</IconButton>
					</div>
				</Tooltip> */}

        <Snackbar
          open={openDialog}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={dialogColor}>
            {dialogContent}
          </Alert>
        </Snackbar>
      </form>
    );
  }
}
