import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

type Props = {
  width?: number;
  height?: number;
};

const TmsLoadingSpinner = ({ width, height }: Props) => {
  const widthOverride = width ? width + "px" : "30px";
  const heightOverride = height ? height + "px" : "30px";

  return (
    <ClipLoader
      color="#152f42"
      loading={true}
      speedMultiplier={1}
      cssOverride={{
        width: widthOverride,
        height: heightOverride,
      }}
    />
  );
};

export default TmsLoadingSpinner;
