import React, { useState, useEffect, useRef, useContext } from "react";
import { User, Power } from "react-feather";
import { Icon } from "@iconify/react";
import { Lock } from "react-feather";
import iosContrast from "@iconify/icons-ion/ios-contrast";
import UserChangePassword from "./UserPasswordChange";
import { Link } from "react-router-dom";
import NewsBar from "./NewsBar";
import { UserContext } from "contexts/userContext";

import styles from "./topbar.module.css";

export default function TopBar(props) {
  const [maxWidthNews, setMaxWidthNews] = useState(0);
  const [rootStyle, setRootStyle] = useState(1);
  const [openPasswordChange, setOpenPasswordChange] = useState(false);
  const topBarRef = useRef(null);
  const topBarBoxRef = useRef(null);
  const [showNewsBar, setShowNewsBar] = useState(false);

  const { logoutApp } = useContext(UserContext);

  useEffect(() => {}, []);
  const setDarkTheme = () => {
    document.documentElement.style.setProperty("--sidebar-bg-col", "#0e1e28");
    document.documentElement.style.setProperty("--sidebar-open", "#33333388");
    document.documentElement.style.setProperty("--sidebar-hover", "#000");
    document.documentElement.style.setProperty("--main-col", "#ED3C22");
    document.documentElement.style.setProperty("--main-white", "#fff");
    document.documentElement.style.setProperty("--main-col-op", "#050c10");
    document.documentElement.style.setProperty("--main-to-op", "#ed3c22");
    document.documentElement.style.setProperty("--main-disabled", "#cccccc");
    document.documentElement.style.setProperty("--highlight", "#a3adff22");
    document.documentElement.style.setProperty("--text-col", "#bdc6cb");
    document.documentElement.style.setProperty("--third-col", "#000");
    document.documentElement.style.setProperty("--err-col", "#E22E2E");
    document.documentElement.style.setProperty("--err-col-op", "#E22E2E88");
    document.documentElement.style.setProperty("--err-col-bg", "#ffb9b9");
    document.documentElement.style.setProperty("--cor-col", "#129865");
    document.documentElement.style.setProperty("--cor-col-bg", "#A8FFCB");
    document.documentElement.style.setProperty("--topbar-col", "#182f3d");
    document.documentElement.style.setProperty("--headtxt-col", "#EFF1FF");
    document.documentElement.style.setProperty("--white", "#fff");
    document.documentElement.style.setProperty("--white-op", "#152631");
    document.documentElement.style.setProperty("--input-bor", "#19303f");
    document.documentElement.style.setProperty("--input-bg", "#0f161a");
    document.documentElement.style.setProperty("--input-dis", "#152631");
    document.documentElement.style.setProperty("--light", "#26495f");
    document.documentElement.style.setProperty("--yel", "#E2C42E");
    document.documentElement.style.setProperty("--yel-bg", "#F9FFA8");
    document.documentElement.style.setProperty("--lgray", "#333");
    document.documentElement.style.setProperty("--bg", "#0e1e28");
    document.documentElement.style.setProperty("--table-border", "#182f3d");
    document.documentElement.style.setProperty(
      "--gradientNewsYellow",
      "linear-gradient(90deg, rgba(247, 223, 9, 1) 0%, rgba(247, 222, 9, 0) 50%)"
    );
    document.documentElement.style.setProperty(
      "--gradientNewsGreen",
      "linear-gradient(90deg, rgba(18, 152, 101, 1) 0%, rgba(18, 152, 101, 0) 50%)"
    );
    document.documentElement.style.setProperty(
      "--gradientNewsBlue",
      "linear-gradient(90deg, rgba(88, 139, 221, 1) 0%, rgba(88, 207, 221, 0) 50%)"
    );
    document.documentElement.style.setProperty("--newsColor", "#ffffff");
    document.documentElement.style.setProperty("--newsDefaultBtnBg", "#26495f");
    document.documentElement.style.setProperty("--bg_plus", "#142f42");
    document.documentElement.style.setProperty("--bg_green", "#1cb97d");
    document.documentElement.classList.remove("lightTheme");
    document.documentElement.classList.add("darkTheme");
  };

  const setLightTheme = () => {
    document.documentElement.style.setProperty("--sidebar-bg-col", "#142F42");
    document.documentElement.style.setProperty("--sidebar-open", "#142f4280");
    document.documentElement.style.setProperty("--sidebar-hover", "#142F42");
    document.documentElement.style.setProperty("--main-disabled", "#cccccc");
    document.documentElement.style.setProperty("--main-col", "#ED3C22");
    document.documentElement.style.setProperty("--main-white", "#152F42");
    document.documentElement.style.setProperty("--main-col-op", "#142F42");
    document.documentElement.style.setProperty("--main-to-op", "#ED3C22");
    document.documentElement.style.setProperty("--highlight", "#ED3C22");
    document.documentElement.style.setProperty("--text-col", "#666");
    document.documentElement.style.setProperty("--third-col", "#58CFDD");
    document.documentElement.style.setProperty("--err-col", "#E22E2E");
    document.documentElement.style.setProperty("--err-col-op", "#E22E2E88");
    document.documentElement.style.setProperty("--err-col-bg", "#ffb9b9");
    document.documentElement.style.setProperty("--cor-col", "#129865");
    document.documentElement.style.setProperty("--cor-col-bg", "#A8FFCB");
    document.documentElement.style.setProperty("--topbar-col", "#f1f3f4");
    document.documentElement.style.setProperty("--headtxt-col", "#1F2538");
    document.documentElement.style.setProperty("--white", "#ffffff");
    document.documentElement.style.setProperty("--white-op", "#FFFFFF99");
    document.documentElement.style.setProperty("--input-bor", "#aaaaaa");
    document.documentElement.style.setProperty("--input-bg", "#fbfbfb");
    document.documentElement.style.setProperty("--input-dis", "#eee");
    document.documentElement.style.setProperty("--light", "#ccc");
    document.documentElement.style.setProperty("--yel", "#E2C42E");
    document.documentElement.style.setProperty("--yel-bg", "#F9FFA8");
    document.documentElement.style.setProperty("--lgray", "#eeeeee");
    document.documentElement.style.setProperty("--bg", "#FFFFFF");
    document.documentElement.style.setProperty("--table-border", "#e0e0e0");
    document.documentElement.style.setProperty(
      "--gradientNewsYellow",
      "linear-gradient(90deg, rgba(247, 223, 9, 0.47) 0%, rgba(247, 222, 9, 0) 50%)"
    );
    document.documentElement.style.setProperty(
      "--gradientNewsGreen",
      "linear-gradient(90deg, rgba(18, 152, 101, 0.25) 0%, rgba(18, 152, 101, 0) 50%)"
    );
    document.documentElement.style.setProperty(
      "--gradientNewsBlue",
      "linear-gradient(90deg, rgba(88, 139, 221, 0.229) 0%, rgba(88, 207, 221, 0) 50%)"
    );
    document.documentElement.style.setProperty("--newsColor", "#142F42");
    document.documentElement.style.setProperty("--newsDefaultBtnBg", "#142F42");
    document.documentElement.style.setProperty("--bg_plus", "#ffffff");
    document.documentElement.style.setProperty("--bg_green", "#129865");
    document.documentElement.classList.remove("darkTheme");
    document.documentElement.classList.add("lightTheme");
  };

  const setTheme = () => {
    if (rootStyle === 0) {
      setRootStyle(1);
      setLightTheme();
    } else if (rootStyle === 1) {
      setRootStyle(0);
      setDarkTheme();
    }
  };

  const handleWindowResize = () => {
    const topBarDiv = topBarBoxRef.current;
    const elements = topBarDiv.getElementsByClassName("width-element");
    const widths = Array.from(elements)
      .slice(0, 4)
      .map((element) => element.offsetWidth);

    let szerokoscClient = document.documentElement.clientWidth;

    let sum = widths[0] + widths[1] + widths[2];

    let tempMaxWidthNews = szerokoscClient - sum - 200;
    setMaxWidthNews(tempMaxWidthNews);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowNewsBar(true);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    const topBarDiv = topBarRef.current;
    let width = topBarDiv?.offsetWidth;
    setMaxWidthNews(width);

    return () => {
      setMaxWidthNews(0);
    };
  }, [showNewsBar]);

  return (
    <div className={`${styles.topbar} top-bar`} ref={topBarBoxRef}>
      {showNewsBar && typeof props.user !== "string" && props.isLoggedIn && (
        <div
          id={"myDiv"}
          className={`${styles.newsBar}`}
          ref={topBarRef}
          style={{ maxWidth: `${maxWidthNews}px` }}
        >
          <NewsBar
            user={props.user}
            loading={props.loading}
            addTab={props.addTab}
            changeTab={props.changeTab}
            tabs={props.tabs}
            isLoading={props.isLoading}
            isLoggedIn={props.isLoggedIn}
          />
        </div>
      )}

      <div className={`${styles.email} width-element`}> {props.user.email}</div>
      <Link to="/user-settings">
        <div className={`width-element`}>
          <User className={`${styles.userIco}`} />
        </div>
      </Link>
      <div className={`${styles.optionBar} buttons-top-bar width-element`}>
        <div className={styles.linkIco} onClick={setTheme}>
          <Icon icon={iosContrast} className={styles.ico} />
        </div>
        <div
          className={styles.linkIco}
          onClick={() => {
            setOpenPasswordChange(true);
          }}
        >
          <Lock className={styles.ico} />
        </div>
        <Link to="/" className={styles.linkIco} onClick={() => logoutApp()}>
          <Power className={styles.ico} />
        </Link>
      </div>
      <UserChangePassword
        open={openPasswordChange}
        setOpen={setOpenPasswordChange}
        logout={() => logoutApp()}
        user={props.user}
        setDialog={props.setDialog}
      />
    </div>
  );
}
