import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { DBurl } from "../../appConfig";
import Input from "../../components/input";
import Title from "../../components/title";
import styles from "../table.module.css";
import RowsPerPage from "../../components/RowsPerPage";
import Pagination from "../../components/pagination";
import TmsLoading from "components/TmsLoading";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Snackbar,
  Checkbox,
} from "@material-ui/core";
import { getWorkingTime } from "api/endpoints";

function descendingComparator(a, b, orderBy) {
  if (
    (b[orderBy] === null || !b[orderBy] ? "-" : b[orderBy]) <
    (a[orderBy] === null || !a[orderBy] ? "-" : a[orderBy])
  ) {
    return -1;
  }
  if (
    (b[orderBy] === null || !b[orderBy] ? "-" : b[orderBy]) >
    (a[orderBy] === null || !a[orderBy] ? "-" : a[orderBy])
  ) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  let result;
  result =
    order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);

  return result;
}

function stableSort(array, comparator) {
  if (array?.length > 0) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
}

const headCells = [
  { id: "driver", alignRight: false, disablePadding: true, label: "Kierowca" },
  { id: "car", alignRight: false, disablePadding: false, label: "Pojazd" },
  {
    id: "last_ts",
    alignRight: false,
    disablePadding: false,
    label: "Data dla pojazdu",
  },
  {
    id: "tacho_drive_day",
    alignRight: false,
    disablePadding: false,
    label: "Jazda",
  },
  {
    id: "tacho_work_day",
    alignRight: false,
    disablePadding: false,
    label: "Praca",
  },
  {
    id: "tacho_break_day",
    alignRight: false,
    disablePadding: false,
    label: "Przerwa",
  },
  {
    id: "tacho_rest_day",
    alignRight: false,
    disablePadding: false,
    label: "Odpoczynek",
  },
  {
    id: "tacho_work_day_sum",
    alignRight: false,
    disablePadding: false,
    label: "Razem praca",
  },
  {
    id: "tacho_drive_week",
    alignRight: false,
    disablePadding: false,
    label: "Jazda",
  },
  {
    id: "tacho_work_week",
    alignRight: false,
    disablePadding: false,
    label: "Praca",
  },
  {
    id: "tacho_break_week",
    alignRight: false,
    disablePadding: false,
    label: "Przerwa",
  },
  {
    id: "tacho_rest_week",
    alignRight: false,
    disablePadding: false,
    label: "Odpoczynek",
  },
  {
    id: "tacho_work_week_sum",
    alignRight: false,
    disablePadding: false,
    label: "Razem praca",
  },
];

function EnhancedTableHead(props) {
  const {
    styles,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    setSearchVehicle,
    setSearchDriver,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead className={styles.tabHead}>
        <TableRow>
          <TableCell className={styles.checkbox}>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all id" }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              className={
                headCell.id === "tacho_drive_day" ||
                headCell.id === "tacho_break_day" ||
                headCell.id === "tacho_rest_day" ||
                headCell.id === "tacho_work_day" ||
                headCell.id === "tacho_work_day_sum"
                  ? styles.timeToday
                  : headCell.id === "tacho_drive_week" ||
                    headCell.id === "tacho_work_week" ||
                    headCell.id === "tacho_break_week" ||
                    headCell.id === "tacho_rest_week" ||
                    headCell.id === "tacho_work_week_sum"
                  ? styles.timeWeek
                  : styles.cellWtDivider
              }
              style={
                headCell.id === "driver" ||
                headCell.id === "car" ||
                headCell.id === "last_ts"
                  ? { width: "12%" }
                  : null
              }
              key={headCell.id}
              align={headCell.alignRight ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "default"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={styles.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableHead className={styles.tabHead}>
        <TableRow className={styles.secondStickyRow}>
          <TableCell></TableCell>
          {setSearchDriver}
          {setSearchVehicle}
          <TableCell className={styles.headCellWtDivider}></TableCell>
          <TableCell className={styles.headCellWtDivider}></TableCell>
          <TableCell className={styles.headCellWtDivider}></TableCell>
          <TableCell className={styles.headCellWtDivider}></TableCell>
          <TableCell className={styles.headCellWtDivider}></TableCell>
          <TableCell className={styles.headCellWtDivider}></TableCell>
          <TableCell className={styles.headCellWtDivider}></TableCell>
          <TableCell className={styles.headCellWtDivider}></TableCell>
          <TableCell className={styles.headCellWtDivider}></TableCell>
          <TableCell className={styles.headCellWtDivider}></TableCell>
          <TableCell className={styles.headCellWtDivider}></TableCell>
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  styles: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function useInput({ type }) {
  const [value, setValue] = useState("");
  const input = (
    <TableCell className={styles.headCellWtDivider}>
      <Input
        value={value}
        handleChange={(e) => {
          setValue(e.target.value);
        }}
        type={type}
        search={true}
      />
    </TableCell>
  );
  return [value, input];
}

export default function WorkTime(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("car");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [table, setTable] = useState([]);
  const [searchVehicle, setSearchVehicle] = useInput({ type: "text" });
  const [searchDriver, setSearchDriver] = useInput({ type: "text" });

  const { isLoading, isFetching } = useQuery("getWorkingTime", getWorkingTime, {
    onSuccess: (data) => {
      setTable(data);
    },
    onError: (error) => {
      props.setDialog({
        isOpen: true,
        type: "error",
        content: `${error.response}`,
      });
    },
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = table.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const search = (tbl) => {
    if (tbl?.length > 0) {
      return tbl.filter((row) => {
        return (
          row.car
            ?.toString()
            .toLowerCase()
            .indexOf(searchVehicle.toLowerCase()) > -1 &&
          row.driver
            ?.toString()
            .toLowerCase()
            .indexOf(searchDriver.toLowerCase()) > -1
        );
      });
    }
  };

  const paginate = (pageNumber) => setPage(pageNumber - 1);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, table.length - page * rowsPerPage);

  const exportToXLS = () => {
    const url = `${DBurl}/printWorkingTimeXLS`;
    window.open(url, "_blank");
  };

  if (isLoading || isFetching) {
    return <TmsLoading />;
  }

  return (
    <div className={styles.root}>
      <Paper className={styles.paper}>
        <Title
          title={props.state.tabName}
          btnBox
          btnExport
          exportFunc={() => {
            exportToXLS();
          }}
        />
        <TableContainer className={styles.stickyTable}>
          <Table
            stickyHeader
            className={styles.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              styles={styles}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={table.length}
              setSearchVehicle={setSearchVehicle}
              setSearchDriver={setSearchDriver}
            />
            <TableBody className={styles.tabBody}>
              {stableSort(search(table), getComparator(order, orderBy))
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell className={styles.checkbox}>
                        <Checkbox
                          checked={isItemSelected}
                          color="primary"
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell className={styles.cellWtDivider} align="left">
                        {row.driver}
                      </TableCell>
                      <TableCell className={styles.cellWtDivider} align="left">
                        {row.car}
                      </TableCell>
                      <TableCell className={styles.cellWtDivider} align="left">
                        {row.last_ts}
                      </TableCell>
                      <TableCell className={styles.cellWtDivider} align="left">
                        {row.tacho_drive_day}
                      </TableCell>
                      <TableCell className={styles.cellWtDivider} align="left">
                        {row.tacho_work_day}
                      </TableCell>
                      <TableCell className={styles.cellWtDivider} align="left">
                        {row.tacho_break_day}
                      </TableCell>
                      <TableCell className={styles.cellWtDivider} align="left">
                        {row.tacho_rest_day}
                      </TableCell>
                      <TableCell className={styles.cellWtDivider} align="left">
                        {row.tacho_work_day_sum}
                      </TableCell>
                      <TableCell className={styles.cellWtDivider} align="left">
                        {row.tacho_drive_week}
                      </TableCell>
                      <TableCell className={styles.cellWtDivider} align="left">
                        {row.tacho_work_week}
                      </TableCell>
                      <TableCell className={styles.cellWtDivider} align="left">
                        {row.tacho_break_week}
                      </TableCell>
                      <TableCell className={styles.cellWtDivider} align="left">
                        {row.tacho_rest_week}
                      </TableCell>
                      <TableCell align="left">
                        {row.tacho_work_week_sum}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={styles.tableBottom}>
          <RowsPerPage
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setPage={setPage}
            tableLength={table?.length}
          />
          <div>
            <Pagination
              postsPerPage={rowsPerPage}
              totalPosts={search(table)?.length}
              paginate={paginate}
              page={page}
            />
          </div>
        </div>
      </Paper>
    </div>
  );
}
