import React, { useState, useEffect, useRef, SyntheticEvent } from "react";
import Title from "../../components/title";
import styles from "../table.module.css";
import { DBurl } from "../../appConfig";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import docsStyles from "../../styles/documents.module.css";
import ReportList from "./reportList";
import { useInput } from "components/hooks";
import {
  DriversActivityTableType,
  DriverActivityTabsType,
  StatusType,
} from "typeDefinitions";
import { useQuery } from "react-query";
import { getOrderDriverActivityRaportData } from "api/endpoints";
import { AxiosError } from "axios";
import { State, Tab, TabProps } from "types";

type Props = TabProps & {
  state: DriverActivityTabsType & State;
  tabs: Tab[] & DriverActivityTabsType[];
};

const DriversActivity = ({
  loading,
  setDialog,
  setTabs,
  state,
  tabID,
  tabs,
  user,
}: Props) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("car");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [table, setTable] = useState(state.table || []);
  const [selected, setSelected] = useState<number[]>([]);
  const [openModalList, setOpenModalList] = useState(false);
  const [what, setWhat] = useState(state.what || "");
  const [filterData, setFilterData] = useState(state.filterData || []);
  const [dateFrom, setDateFrom] = useInput(
    "datetime-local",
    "Od",
    state.dateFrom ?? "",
    styles.searchInputSmall
  );
  const [dateTo, setDateTo] = useInput(
    "datetime-local",
    "Do",
    state.dateTo ?? "",
    styles.searchInputSmall
  );
  const [filterWhat, setFilterWhat] = useState(state.filterWhat || "");

  const [ss, setSS] = useState(state.ss || false);

  const savedTabId = tabID;

  const saveTab = () => {
    const tempTab = { ...tabs[savedTabId] };

    tempTab.table = table;
    tempTab.what = what;
    tempTab.filterData = filterData;
    tempTab.dateFrom = dateFrom;
    tempTab.dateTo = dateTo;
    tempTab.filterWhat = filterWhat;
    tempTab.ss = ss;

    return tempTab;
  };

  useEffect(() => {
    if (setTabs) {
      setTabs(() => {
        const tempTab = [...tabs];
        tempTab[tabID] = saveTab();
        return tempTab;
      });
    }
  }, [table, what, filterData, filterWhat, dateFrom, dateTo]);

  const headCells = [
    {
      id: "orderNum",
      numeric: true,
      disablePadding: false,
      label: "Kierowca",
      class: docsStyles.headCell,
    },
    {
      id: "contractor",
      numeric: true,
      disablePadding: false,
      label: "Zlecenie",
    },
    { id: "driver", numeric: true, disablePadding: false, label: "Status" },
    {
      id: "car",
      numeric: true,
      disablePadding: false,
      label: "Czas rozpoczęcia",
    },
    {
      id: "plannedKm",
      numeric: true,
      disablePadding: false,
      label: "Czas trwania",
    },
  ];

  function EnhancedTableHead(props: any) {
    const {
      styles,
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;

    return (
      <TableHead className={styles.tabHead}>
        <TableRow>
          {headCells.map((headCell) => {
            return (
              <TableCell
                key={headCell.id}
                align="left"
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={headCell.class}
              >
                {headCell.label}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }

  const { refetch: fetchRaport } = useQuery(
    ["getOrderDriverActivityRaportData", dateFrom, dateTo, selected],
    () =>
      getOrderDriverActivityRaportData({
        from: dateFrom,
        to: dateTo,
        ids_drivers: selected,
      }),
    {
      enabled: false,
      onSuccess: (data: DriversActivityTableType[]) => {
        if (data.length > 0) {
          setTable(data?.filter((rec) => rec.statuses.length));
        } else {
          setDialog({
            isOpen: true,
            type: "warning",
            content: "Brak danych dla tego wyszukiwania",
          });
        }
      },
      onError: (error: AxiosError) => {
        setDialog({
          isOpen: true,
          type: "error",
          content: `Nie można pobrać raportu / ${error.message}`,
        });
      },
    }
  );

  const printOrdersPDF = () => {
    const url = `${DBurl}/getOrderDriverActivityRaportPdf?from=${dateFrom.replace(
      "T",
      " "
    )}:00&to=${dateTo.replace(
      "T",
      " "
    )}:00&ids_drivers[]=${selected.toString()}`;
    window.open(url, "_blank");
  };

  const handleRequestSort = (_event: unknown, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isSelected = (name: number) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, table.length - page * rowsPerPage);

  const handleActivity = (id_status: number) => {
    if (id_status === 1) return "Jazda";
    if (id_status === 2) return "Odpoczynek";
    if (id_status === 3) return "Długi odpoczynek";
    if (id_status === 4) return "Dojazd";
    if (id_status === 5) return "Podjęcie zlecenia";
    if (id_status === 6) return "Oczekiwanie na załadunek";
    if (id_status === 7) return "Załadunek";
    if (id_status === 8) return "Oczekiwanie na rozładunek";
    if (id_status === 9) return "Rozładunek";
    if (id_status === 10) return "Korek";
    if (id_status === 11) return "Awaria pojazdu";
    if (id_status === 12) return "Prom";
    if (id_status === 13) return "Zakończenie zlecenia";
    if (id_status === 14) return "Przepinka";
    if (id_status === 15) return "Przesiadka";
    if (id_status === 16) return "Przeładunek";
    if (id_status === 17) return "Zjazd na bazę";
    if (id_status === 18) return "Myjnia";
    if (id_status === 19) return "Kontrola drogowa";
    if (id_status === 20) return "Wymiana palet";
    if (id_status === 21) return "Pobieranie palet";
    if (id_status === 22) return "Zrzutka palet";
    if (id_status === 23) return "Zmiana zestawu";
    if (id_status === 24) return "Odprawa celna";
  };

  const sorterForStatuses = (a: StatusType, b: StatusType) => {
    const aDate = new Date(a.ts_status_start as string);
    const bDate = new Date(b.ts_status_start as string);

    return bDate.getTime() - aDate.getTime();
  };

  const sorterForRows = (
    a: DriversActivityTableType,
    b: DriversActivityTableType
  ) => {
    const aDate = new Date(a.ts_activity_start);
    const bDate = new Date(b.ts_activity_start as string);

    return bDate.getTime() - aDate.getTime();
  };

  const getTimeDifference = (
    fullRowsArr: DriversActivityTableType[],
    timeStart: string | undefined,
    allRowsIndex: number,
    statusesIndex: number,
    allStatuses: StatusType[],
    thisRow: DriversActivityTableType
  ) => {
    if (!timeStart) return "";
    let stopTime: Date;
    const startDate = new Date(timeStart);

    if (statusesIndex !== 0) {
      const smallerArrayStatusStart =
        allStatuses[statusesIndex - 1]?.ts_status_start;
      if (!smallerArrayStatusStart) return "";
      stopTime = new Date(smallerArrayStatusStart);
    }

    if (statusesIndex === 0) {
      if (allRowsIndex === 0) {
        return "Wciąż trwa";
      }
      const laterRow = fullRowsArr[allRowsIndex - 1];
      if (!laterRow) return "";
      const laterRowOldestStatus =
        laterRow.statuses[laterRow.statuses.length - 1]?.ts_status_start;
      if (!laterRowOldestStatus) return "";
      stopTime = new Date(laterRowOldestStatus);
    }

    //@ts-ignore
    const hours = Math.abs(stopTime - startDate) / 36e5;

    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    const text = rhours + " godzin i " + rminutes + " minut";
    return text;
  };

  return (
    <>
      <div className={styles.root}>
        <Paper className={styles.paper}>
          <Title
            title={state.tabName}
            btnBox
            btnGenerate
            generateFunc={() => {
              if (!selected || !dateFrom || !dateTo) {
                setDialog({
                  isOpen: true,
                  type: "error",
                  content: "Proszę uzupełnić formularz",
                });
              } else {
                fetchRaport();
                setSS(what);
              }
            }}
            btnDrivers
            btnDriversFunc={() => {
              setOpenModalList(true);
              setWhat("drivers");
            }}
            btnExport
            exportFileType="PDF"
            exportFunc={() => {
              if (!selected || !dateFrom || !dateTo) {
                setDialog({
                  isOpen: true,
                  type: "error",
                  content: "Proszę uzupełnić formularz",
                });
              } else printOrdersPDF();
            }}
            cornersNew
            rightCorner1={setDateFrom}
            rightCorner2={setDateTo}
          />
          <TableContainer className={styles.stickyTable}>
            <Table
              className={styles.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                styles={styles}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={table.length}
              />
              <TableBody className={styles.tabBody}>
                {table
                  ?.sort((a, b) => sorterForRows(a, b))
                  ?.map((row, allRowsIndex, fullRowsArr) => {
                    const timeStop = row?.ts_activity_stop?.split(".")[0];
                    const statusIDs = row.statuses.map((st) => {
                      return st.id_status;
                    });
                    const isItemSelected = isSelected(row.id as number);
                    const labelId = `enhanced-table-checkbox-${allRowsIndex}`;
                    return (
                      <>
                        <TableRow
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell className={docsStyles.headCell}>
                            {row?.driver[0]?.firstname}{" "}
                            {row?.driver[0]?.surname}
                          </TableCell>
                          <TableCell align="left">
                            {row?.order
                              ? row?.order[0]?.order_no
                              : "Brak zlecenia"}
                          </TableCell>
                          <TableCell align="left">
                            {statusIDs.map((id) => (
                              <div>{handleActivity(id)}</div>
                            ))}
                          </TableCell>
                          <TableCell align="left">
                            {row.statuses
                              .sort((a, b) => sorterForStatuses(a, b))
                              .map((st) => (
                                <div>{st.ts_status_start?.split(".")[0]}</div>
                              ))}
                          </TableCell>
                          <TableCell align="left">
                            {row.statuses.map(
                              (st, statusesIndex, allStatuses) => {
                                const timeStart =
                                  st.ts_status_start?.split(".")[0];
                                return (
                                  <div>
                                    {getTimeDifference(
                                      fullRowsArr,
                                      timeStart,
                                      allRowsIndex,
                                      statusesIndex,
                                      allStatuses,
                                      row
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 33 * emptyRows }}>
                    <TableCell colSpan={5} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={styles.tableBottom}></div>
        </Paper>
      </div>
      <ReportList
        token={user.csrf_token}
        data={table}
        open={openModalList}
        setOpen={setOpenModalList}
        what={what}
        setWhat={setWhat}
        selected={selected}
        setSelected={setSelected}
        loading={loading}
      />
    </>
  );
};

export default DriversActivity;
