import React, { useState } from "react";
import { TableCell, TableRow, Checkbox, IconButton } from '@material-ui/core'
import styles from 'containers/table.module.css'
import { ArrowLeftCircle, ArrowRightCircle } from 'react-feather';
import { toggleShowAllTags, getAllTags, getBarColor } from '../helpers/table.helpers'
import Bar from '../../components/Bar'
import Status from '../../components/StatusDriver'
import IcoBtn from '../../components/buttons/icoBtn';
import FleetStateSelector from './FleetStateSelector'


const FleetStateTableRow = ({ handleClick, rowConfig, rowSetters, rowGetters, handleRefs }) => {

    const { isItemSelected, labelId, row, images } = rowConfig;
    const { addTab, getOpenSelectorsData } = rowGetters

    const { setOpenOrder, setRowData, setOrderData, setOpenOrderStatus, setOrderStatusData, setOpenSelectorsModal, setOpenSelectorsData } = rowSetters
    const { active_order, progress_to_destination, id,
        name, driver, tacho_drive_today, tacho_rest_today,
        tacho_break_today, tacho_work_today, position_address, position, active_order_last_status: ao, next_orders } = row
    const allTags = getAllTags(active_order?.tags || [])
    const [tagsToShow, setTagsToShow] = useState(allTags.slice(0, 1))
    const [showAllTags, setShowAllTags] = useState(false);
    const active_order_last_status = (ao !== null) ? ao[0].id_status : null;

    const [warningOrange, warningRed] = ['warningOrange', 'warningRed']

    const handleTachoClick = () => {
        setOpenSelectorsModal(true)
        setOpenSelectorsData(getOpenSelectorsData(row))
    }

    const tachoConf = [
        { tachoValue: tacho_drive_today, title: "Jazda", icon: "IcoDrive" },
        { tachoValue: tacho_break_today, title: "Odpoczynek", icon: "IcoStop" },
        { tachoValue: tacho_rest_today, title: "Dyspozycyjność", icon: "IcoAvailability" },
        { tachoValue: tacho_work_today, title: "Praca", icon: "IcoWork" },
    ]

    const renderToggleSymbol = () => {
        if (allTags.length >= 2) return showAllTags ?
            <IconButton className={styles.mediumIcons}>
                <ArrowLeftCircle color={'orange'} />
            </IconButton>
            :
            <IconButton className={styles.mediumIcons}>
                <ArrowRightCircle color={'green'} />
            </IconButton>
            ;
        return <></>
    }

    const getClassName = () => {
        if (!row.active_order.exists) return
        if (row?.active_order_last_status[0]?.name !== "Załadunek" && row?.active_order_last_status[0]?.name !== "Rozładunek") return
        const now = new Date()
        const activeOrderLocation = row?.active_order?.order_locations?.find(o => o.id_location === row?.active_order_last_status[0]?.id)
        const newDate = new Date(activeOrderLocation.ts.replace(" ", "T"))
        if (newDate.valueOf() < now.valueOf()) return styles[warningRed]
        now.setHours(now.getHours() + 1)
        if (newDate.valueOf() < now.valueOf()) return styles[warningOrange]
        return ''
    }


    const renderTags = (tags) => {
        return (
            <div>
                <span> {tags.join(", ")} </span>
                <span onClick={(e) => toggleShowAllTags(e, !showAllTags, setShowAllTags, setTagsToShow, allTags)}>{renderToggleSymbol()} </span>
            </div>
        )
    }
    const uniqueRefs = Array.from(new Set(handleRefs(row)))

    return (
        <TableRow
            hover
            onClick={(event) => handleClick(event, id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={id}
            selected={isItemSelected}
            className={getClassName()}
        >
            <TableCell className={styles.checkbox}>
                <Checkbox
                    checked={isItemSelected}
                    color="primary"
                    inputProps={{ 'aria-labelledby': labelId }}
                />
            </TableCell>
            <TableCell align="left">{name}</TableCell>
            <TableCell align="right">
                <div className={styles.tableCenter}>
                    <span className={styles.mr10}>{active_order.exists ? active_order.order_no : "Brak aktywnego zlecenia"}</span>
                    {active_order.exists && <IcoBtn icon="Search" tooltip="Podgląd" value={row} onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setOpenOrder(true)
                        setRowData(row)
                        setOrderData(active_order)
                    }} />}

                    {active_order.exists && <IcoBtn icon="Info" tooltip="Historia statusów" value={row} onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setOpenOrderStatus(true)
                        setRowData(row)
                        setOrderStatusData(active_order)
                    }} />}
                </div>
            </TableCell>

            <TableCell align={progress_to_destination !== null ? "left" : "right"}>
                {progress_to_destination !== null ?
                    <Bar
                        value={progress_to_destination !== null ? progress_to_destination.percentage_progress : 0}
                        color={getBarColor(active_order_last_status)} /> :
                    "Brak danych"}

            </TableCell>
            <TableCell align="right" className={styles.status_order}>
                <Status num={active_order_last_status} />
            </TableCell>
            <TableCell align="right">
                {handleRefs(row) !== undefined && uniqueRefs.join(', ')}
            </TableCell>
            <TableCell align="center" className={styles.status_order}>
                {/* {active_order.exists ? <span>{next_orders[0]?.order_no}</span> : <div>Brak zaplanowanego zlecenia</div>} */}
                <div className={styles.tableCenter}>
                    <span className={styles.mr10}>
                        {active_order.exists && next_orders[0] ? next_orders[0]?.order_no : "Brak zaplanowanego zlecenia"}</span>
                    {active_order.exists && next_orders[0] ? <IcoBtn icon="Search" tooltip="Podgląd" value={row} onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setOpenOrder(true)
                        setRowData(row)
                        setOrderData(next_orders[0])
                    }} /> : <></>}</div>
            </TableCell>
            <TableCell align="right">
                <div className={styles.tableCenter}>
                    <span className={styles.mr10}>{driver?.name}</span>
                    {driver?.allow_mobile && <IcoBtn icon="Chat" tooltip="Komunikator" value={row} onClick={(e) => {
                        addTab("Communication", driver.id)
                    }} />}
                </div>

            </TableCell>
            <TableCell align="right" >
                <div className={styles.tachoiconsParent}>
                    {tachoConf.map(({ tachoValue, title, icon }, index) => <FleetStateSelector key={index} tachoValue={tachoValue} title={title} icon={icon} handleClick={handleTachoClick} />)}
                </div>
            </TableCell>
            <TableCell align="right">{progress_to_destination !== null ? `${progress_to_destination.km_to_destination} km` : "brak zlecenia"}</TableCell>
            <TableCell align="right">
                <div className={styles.tableCenter}>
                    {/* TODO: dopasować flagi w zależności od współrzędnych  */}

                    <span className={styles.positionFlag}>
                        <img src={images[`${position_address?.address?.country_code}.gif`]} />{position ? `${position?.lat}, ${position?.lng}` : "brak danych"}</span>
                    {position && <IcoBtn icon="Pin" tooltip="Wskaż na mapie" value={row} onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        addTab("Map", position)
                    }} />}
                </div>
            </TableCell>
            <TableCell align="left">
                <div className={styles.tableCenter}>
                    {renderTags(tagsToShow)}
                </div>

            </TableCell>
        </TableRow>
    )


}

export default FleetStateTableRow;