import React, { useState, useEffect } from "react";
import styles from "./topbar.module.css";
import {
  Check,
  Search,
  Mail,
  ChevronLeft,
  ChevronRight,
  X,
} from "react-feather";
import { DBurl } from "../appConfig";
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Snackbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import stylesMod from "../styles/newOrEdit.module.css";
import BTNstyles from "../styles/button.module.css";
import TABLEstyles from "../containers/table.module.css";
import IcoBtn from "./buttons/icoBtn";
import PropTypes from "prop-types";
import moment from "moment";
import RowsPerPage from "./RowsPerPage";
import Pagination from "./pagination";
import sound_comunicator from "../img/news_sound_comunicator.wav";
import sound_load_unload from "../img/news_sound_load_unload.wav";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const headCells = [
  { id: "what", right: false, disablePadding: true, label: "Typ wiadomości" },
  {
    id: "from_driver_id",
    right: false,
    disablePadding: true,
    label: "Kierowcy",
  },
  { id: "ts", right: false, disablePadding: true, label: "Data wyslania" },
  {
    id: "ts_readed",
    right: false,
    disablePadding: true,
    label: "Data odczytania",
  },
  { id: "user_id", right: false, disablePadding: true, label: "Wysłane do" },
  { id: "is_readed", right: false, disablePadding: true, label: "Status" },
  { id: "mail", right: true, disablePadding: true, label: "Wiadomość" },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] === a[orderBy]) return 0;
  return b[orderBy] < a[orderBy] ? -1 : 1;
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={TABLEstyles.tabHead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.right ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={`${headCell.class} ${TABLEstyles.cell_modalTable}`}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={TABLEstyles.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {/* <TableCell className={styles.buttonBoxParent} /> */}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  styles: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function NewsBar(props) {
  const [currentNewsIndex, setCurrentNewsIndex] = useState(0);
  const [data, setData] = useState([]);
  const [news, setNews] = useState([]);
  const [mouseOverNews, setMouseOverNews] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");
  const [newsHistoryDialog, setNewsHistoryDialog] = useState(false);
  const [newsContentDialog, setNewsContentDialog] = useState(false);
  const [newsContent, setNewsContent] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("short");
  const [page, setPage] = useState(0);
  const [rowsPerPageModalTab, setRowsPerPageModalTab] = useState(15);
  const [pageModalTab, setPageModalTab] = useState(0);
  const [drivers, setDrivers] = useState([]);
  const [users, setUsers] = useState([]);
  const [counter, setCounter] = useState(0);
  // const paginateModalTab = pageNumber => setPageModalTab(pageNumber - 1);

  const fetchGetNews = async function (newsList) {
    const response = await fetch(`${DBurl}/getNews/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      if (json?.data?.length > 0) {
        const tempData = [...json.data];

        let newsToRead = tempData.filter((o) => o.is_readed === false);
        setData(tempData);
        let tempNews = hours24Ago(sortObjectsByTsDesc(newsToRead));

        if (news.length > 0) {
          if (news.length < tempNews.length) {
            if (tempNews[0].what === "new_message") {
              console.log("dingding...");
              dingding_new_massage();
            } else {
              if (
                tempNews[0].what === "loading" ||
                tempNews[0].what === "unloading"
              ) {
                console.log("dingding...");
                dingding_load_unload();
              }
            }
          } else {
            // console.log('brak nowych newsów...')
          }
        } else {
          // console.log('brak newsów...')
        }

        setNews(tempNews);
        setCounter(hours24Ago(newsToRead).length);
        if (newsList !== undefined) {
          if (newsList.length < tempNews.length) {
            setCurrentNewsIndex(0);
          }
        }

        return json;
      }
    }
  };
  const fetchSetNewsAsReaded = async function (id) {
    props.loading(true);
    const response = await fetch(`${DBurl}/setNewsAsReaded`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "update",
        data: {
          id: id,
        },
      }),
    });
    const json = await response.json();
    if (json.success) {
      setOpenDialog(true);
      setDialogContent(`Wiadomość została oznaczona jako przeczytana.`);
      setDialogColor("success");
      fetchGetNews();
      props.loading(false);
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };
  const fetchDrivers = async function () {
    // console.log("2--->",props.user.csrf_token)
    const response = await fetch(`${DBurl}/getDrivers/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setDrivers(json.data);
      return json;
    }
  };
  const fetchUsers = async () => {
    // console.log("3--->",props.user.csrf_token)
    const response = await fetch(`${DBurl}/getUsers`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setUsers(json.data);
    }
  };

  const handleReadClick = (index, item) => {
    let tempList = [...hours24Ago(news)];
    let tempData = [...data];

    let oldTempListLength = tempList.length;

    for (let i = 0; tempList.length > i; i++) {
      if (tempList[i].id === item.id) {
        if (tempList[i].is_readed === false) {
          tempList[i].is_readed = true;
        }
      }
    }

    tempList = news.filter((o) => o.is_readed === false);

    if (oldTempListLength === index + 1) {
      const prevIndex = tempList.length - 1;
      setCurrentNewsIndex(prevIndex);
    } else if (tempList.length === 0) {
      setCurrentNewsIndex(0);
    } else {
      const nextIndex = currentNewsIndex;
      setCurrentNewsIndex(nextIndex);
    }

    for (let i = 0; tempData.length > i; i++) {
      if (tempData[i].id === item.id) {
        if (tempData[i].is_readed === false) {
          tempData[i].is_readed = true;
        }
      }
    }

    setCounter(tempList.length);
    setNews(tempList);
    setData(tempData);
    fetchSetNewsAsReaded(item.id);
  };

  const handleClickActions = (name, added) => {
    // console.log(props.tabs)

    if (props.tabs.find((p) => p.componentName === name) === undefined) {
      if (added) {
        props.addTab(added);
      } else {
        props.addTab(name);
      }
    } else {
      let tab = props.tabs.filter((o) => o.componentName === name);
      let tabId = tab[0].tabID;
      props.changeTab(tabId);
    }
  };

  const handleNext = () => {
    let next = currentNewsIndex + 1;
    if (next === hours24Ago(news).length) {
      setCurrentNewsIndex(0);
    } else {
      setCurrentNewsIndex(next);
    }
  };

  const handlePrev = () => {
    let prev = currentNewsIndex - 1;
    if (prev < 0) {
      setCurrentNewsIndex(hours24Ago(news).length - 1);
    } else {
      setCurrentNewsIndex(prev);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDialog(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function hours24Ago(obj) {
    const currentTime = new Date();
    let hours24Ago = currentTime - 24 * 60 * 60 * 1000;

    const filteredObj = obj.filter((item) => {
      let itemTime = moment(item.ts).valueOf();
      return itemTime > hours24Ago;
    });

    return filteredObj;
  }

  function sortObjectsByTsDesc(objects) {
    objects.sort((a, b) => new Date(b.ts) - new Date(a.ts));
    return objects;
  }

  const dingding_new_massage = () => {
    const audio = new Audio(sound_comunicator);
    audio.volume = 0.7;
    audio.play();
  };

  const dingding_load_unload = () => {
    const audio = new Audio(sound_load_unload);
    audio.volume = 0.7;
    audio.play();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      let tempList = hours24Ago(news).filter((o) => o.is_readed === false);

      if (tempList.length > 0 && !mouseOverNews) {
        setCurrentNewsIndex((currentIndex) => {
          const nextIndex = currentIndex + 1;
          if (nextIndex >= tempList.length) {
            return 0;
          } else {
            return nextIndex;
          }
        });
      }
    }, 15000);
    return () => clearInterval(interval);
  }, [news, data, mouseOverNews]);

  useEffect(() => {
    fetchGetNews();
    fetchDrivers();
    fetchUsers();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("check_news...");
      fetchGetNews(news);
    }, 60000);
    return () => clearInterval(interval);
  }, [news]);

  const bg_color = (type) =>
    type === "new_message"
      ? "comunicator"
      : type === "loading"
      ? "load"
      : type === "unloading"
      ? "unload"
      : "";
  const paginate = (pageNumber) => setPage(pageNumber - 1);

  return (
    <>
      <div
        className={styles.news_slider}
        onMouseEnter={(e) => setMouseOverNews(true)}
        onMouseLeave={(e) => setMouseOverNews(false)}
      >
        {hours24Ago(sortObjectsByTsDesc(news)).length > 0 ? (
          <>
            <div className={styles.news_navi}>
              <button onClick={(e) => handlePrev()}>
                <ChevronLeft />
              </button>
              <div className={styles.counter}>
                {currentNewsIndex + 1} / {counter}
              </div>
              <button onClick={(e) => handleNext()}>
                <ChevronRight />
              </button>
              <button
                className={styles.history24}
                onClick={() => setNewsHistoryDialog(true)}
              >
                24h
              </button>
            </div>
            {/* <div className={styles.count}>{news.length}</div> */}
            <div
              className={styles.news_slider_content}
              style={{ transform: `translateX(${-currentNewsIndex * 100}%)` }}
            >
              {hours24Ago(news)
                .filter((o) => o.is_readed === false)
                .map((item, index) => {
                  return (
                    <div
                      className={`${styles.news_item} ${bg_color(item.what)}`}
                      key={index}
                    >
                      <div className={styles.news_btns}>
                        <button
                          className={styles.check}
                          onClick={() => handleReadClick(index, item)}
                        >
                          <Check strokeWidth="4" width="14" />
                        </button>
                        <button
                          className={styles.more}
                          onClick={(e) =>
                            handleClickActions(
                              item.what === "new_message"
                                ? "Communication"
                                : "FleetState"
                            )
                          }
                        >
                          <Search strokeWidth="3" width="14" />
                        </button>
                      </div>
                      <div className={styles.news_text}>
                        <h2>
                          {item.what === "new_message"
                            ? "Komunikator"
                            : item.what === "unloading"
                            ? "Rozładunek"
                            : "Załadunek"}
                        </h2>
                        <p>{item.content}</p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        ) : (
          <>
            <div className={styles.news_navi}>
              <button
                className={styles.history24}
                onClick={() => setNewsHistoryDialog(true)}
              >
                24h
              </button>
            </div>
            <div
              className={`${styles.news_slider_content} ${styles.news_empty}`}
            >
              <Mail /> <p>Brak nowych wiadomosci</p>
            </div>
          </>
        )}
      </div>

      <Dialog
        open={newsHistoryDialog}
        TransitionComponent={Transition}
        onClose={() => {
          setNewsHistoryDialog(false);
        }}
        fullWidth
        maxWidth={"lg"}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Lista wiadomości z ostatnich 24 godzin
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              setNewsHistoryDialog(false);
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div className={TABLEstyles.tableBoxInColumnA_full}>
            <TableContainer>
              <Table
                className={TABLEstyles.modalTable}
                aria-labelledby="tableTitle"
              >
                <EnhancedTableHead
                  styles={TABLEstyles}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={data.length}
                />

                <TableBody className={TABLEstyles.tabBody}>
                  {stableSort(hours24Ago(data), getComparator(order, orderBy))
                    .slice(
                      page * rowsPerPageModalTab,
                      page * rowsPerPageModalTab + rowsPerPageModalTab
                    )
                    .map((row, index) => {
                      let driver_full_name =
                        drivers.length > 0 &&
                        `${
                          drivers.find((o) => o.id === row?.from_driver_id)
                            ?.firstname || "-"
                        } ${
                          drivers.find((o) => o.id === row?.from_driver_id)
                            ?.surname || "-"
                        }`;
                      let user_full_name = ``;

                      if (users.length > 0) {
                        if (
                          users.find((o) => o.id === row.user_id) !== undefined
                        ) {
                          user_full_name = users.find(
                            (o) => o.id === row.user_id
                          ).login;
                        } else {
                          user_full_name = "-";
                        }
                      }

                      // console.log(users.length > 0 && row.user_id !== null && row.user_id && users.find(o => o.id === row.user_id).firstname)

                      // (${users.find(o => o.id === row.user_id).firstname} ${users.find(o => o.id === row.user_id).firstname})

                      return (
                        <TableRow role="checkbox" tabIndex={-1} key={index}>
                          <TableCell className={TABLEstyles.cell_modalTable}>
                            {row.what === "new_message" ? (
                              <div className={styles.flex_label}>
                                <span>Komunikator</span>
                              </div>
                            ) : row.what === "unloading" ? (
                              <div className={styles.flex_label}>
                                <span>Rozladunek</span>
                              </div>
                            ) : (
                              <div className={styles.flex_label}>
                                <span>Zaladunek</span>
                              </div>
                            )}
                          </TableCell>
                          <TableCell className={TABLEstyles.cell_modalTable}>
                            {driver_full_name || "-"}
                          </TableCell>
                          <TableCell className={TABLEstyles.cell_modalTable}>
                            {moment(row.ts).format("DD-MM-YYYY HH:mm:ss")}
                          </TableCell>
                          <TableCell className={TABLEstyles.cell_modalTable}>
                            {row.ts_readed ? (
                              moment(row.ts_readed).format(
                                "DD-MM-YYYY HH:mm:ss"
                              )
                            ) : (
                              <span className={styles.noreaded_tab_label}>
                                nieodczytane
                              </span>
                            )}
                          </TableCell>
                          <TableCell className={TABLEstyles.cell_modalTable}>
                            {user_full_name}
                          </TableCell>
                          <TableCell className={TABLEstyles.cell_modalTable}>
                            {row.is_readed ? (
                              <span className={styles.readed_tab_label}>
                                odebrane
                              </span>
                            ) : (
                              <span className={styles.noreaded_tab_label}>
                                nieodebrane
                              </span>
                            )}
                          </TableCell>
                          <TableCell
                            className={styles.cell_modalTable}
                            align="right"
                            style={{ padding: 0 }}
                          >
                            <div className={styles.btns}>
                              <IcoBtn
                                icon="File"
                                onClick={() => {
                                  setNewsContentDialog(true);
                                  setNewsContent({
                                    title:
                                      row.what === "new_message"
                                        ? "Komunikator"
                                        : row.what === "unloading"
                                        ? "Rozladunek"
                                        : "Zaladunek",
                                    content: row.content,
                                  });
                                }}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>

            <div className={TABLEstyles.tableBottom}>
              <RowsPerPage
                rowsPerPage={rowsPerPageModalTab}
                setRowsPerPage={setRowsPerPageModalTab}
                setPage={setPage}
                tableLength={hours24Ago(data).length}
              />
              <div>
                <Pagination
                  postsPerPage={rowsPerPageModalTab}
                  totalPosts={hours24Ago(data).length}
                  paginate={paginate}
                  page={page}
                />
              </div>
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={BTNstyles.btn}
            onClick={(e) => {
              setNewsHistoryDialog(false);
            }}
          >
            Zamknij
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={newsContentDialog}
        TransitionComponent={Transition}
        onClose={() => {
          setNewsContentDialog(false);
          setNewsContent({
            title: "",
            content: "",
          });
        }}
        fullWidth
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          {newsContent.title}
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              setNewsContentDialog(false);
              setNewsContent({
                title: "",
                content: "",
              });
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <p>{newsContent.content}</p>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={BTNstyles.btn}
            onClick={(e) => {
              setNewsContentDialog(false);
              setNewsContent({
                title: "",
                content: "",
              });
            }}
          >
            Zamknij
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={dialogColor}>
          {dialogContent}
        </Alert>
      </Snackbar>
    </>
  );
}
