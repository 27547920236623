import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { getFuelCards } from "api/endpoints";
import ImportModal from "./ImportModal";
import TmsLoading from "components/TmsLoading";
import { perPageButtons } from "containers/order/utilities/constants";
import OrderPerPageButton from "containers/order/OrderPerPageButton";
import Pagination from "components/pagination";
import TmsDialog from "components/TmsDialog";
import { FuelCard, TmsDialogState } from "types";
import styles from "containers/table.module.css";

import "./styles.sass";

const CostsImport = () => {
  const [importModal, setImportModal] = useState<{ isOpen: boolean }>({
    isOpen: false,
  });
  const [rowsPerPage, setRowsPerPage] = useState<number>(15);
  const [page, setPage] = useState<number>(0);
  const [dialog, setDialog] = useState<TmsDialogState>({ isOpen: false });

  const paginate = (pageNumber: number) => setPage(pageNumber - 1);

  const {
    data: fuelCards,
    isLoading,
    refetch: refetchFuelCards,
  } = useQuery("getFuelCards", getFuelCards);

  if (isLoading) {
    return <TmsLoading />;
  }

  return (
    <>
      <div className="costs-import-header">
        <div className="costs-import-header--left">
          <h1 className="costs-import-header__title">
            Koszty - Karty Paliwowe
          </h1>
          <div className="costs-import-header__buttons">
            <button
              onClick={() => {
                setImportModal({ isOpen: true });
              }}
              className="costs-import-header__button"
            >
              Importuj
            </button>
          </div>
        </div>
        <div className="costs-import-header--right"></div>
      </div>
      <TableContainer className="costs-import-table">
        <Table stickyHeader>
          <TableHead className="costs-import-table__header">
            <TableRow>
              <TableCell size="medium" padding="normal">
                <label className="costs-import-table__cell">ID</label>
              </TableCell>
              <TableCell size="medium" padding="normal">
                <label className="costs-import-table__cell">Data importu</label>
              </TableCell>
              <TableCell size="medium" padding="normal">
                <label className="costs-import-table__cell">Nazwa</label>
              </TableCell>
              <TableCell size="medium" padding="normal">
                <label className="costs-import-table__cell">Rejestracja</label>
              </TableCell>
              <TableCell size="medium" padding="normal">
                <label className="costs-import-table__cell">ID Pojazdu</label>
              </TableCell>
              <TableCell size="medium" padding="normal">
                <label className="costs-import-table__cell">Provider</label>
              </TableCell>
              <TableCell size="medium" padding="normal">
                <label className="costs-import-table__cell">Cena</label>
              </TableCell>
              <TableCell size="medium" padding="normal">
                <label className="costs-import-table__cell">Ilość paliwa</label>
              </TableCell>
              <TableCell size="medium" padding="normal">
                <label className="costs-import-table__cell">Dystans</label>
              </TableCell>
              <TableCell size="medium" padding="normal">
                <label className="costs-import-table__cell">
                  Czas transakacji
                </label>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fuelCards
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((fuelCard: FuelCard) => (
                <TableRow key={fuelCard.id}>
                  <TableCell>{fuelCard.id_card}</TableCell>
                  <TableCell>{fuelCard.created_at}</TableCell>
                  <TableCell>{fuelCard.product_name}</TableCell>
                  <TableCell>{fuelCard.license_plate_number}</TableCell>
                  <TableCell>{fuelCard.id_car}</TableCell>
                  <TableCell>{fuelCard.card_provider}</TableCell>
                  <TableCell>
                    {fuelCard.unit_price} {fuelCard.unit_price_currency}
                  </TableCell>
                  <TableCell>{fuelCard.fuel}</TableCell>
                  <TableCell>{fuelCard.total_distance}km</TableCell>
                  <TableCell>{fuelCard.transaction_time}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <div
          className={styles.tableBottom}
          style={{ width: "calc(100vw - 200px)", position: "fixed", bottom: 0 }}
        >
          <div>
            {perPageButtons.map(({ value, minNumber }) => (
              <OrderPerPageButton
                key={value}
                tableLength={fuelCards ? fuelCards.length : 0}
                minNumber={minNumber}
                value={value}
                templatesLength={0}
                setRowsPerPage={setRowsPerPage}
                rowsPerPage={rowsPerPage}
              />
            ))}
          </div>
          <div>
            <Pagination
              postsPerPage={rowsPerPage}
              totalPosts={fuelCards?.length}
              paginate={paginate}
              page={page}
            />
          </div>
        </div>
      </TableContainer>
      <ImportModal
        importModal={importModal}
        setImportModal={setImportModal}
        refetchFuelCards={refetchFuelCards}
        setDialog={setDialog}
      />
      <TmsDialog dialog={dialog} setDialog={setDialog} />
    </>
  );
};

export default CostsImport;
