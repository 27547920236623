export const countryPLToCode = (country) => {
  switch (country) {
    case "Afganistan": return "AF"
    case "Albania": return "AL"
    case "Algieria": return "DZ"
    case "Andora": return "AD"
    case "Angola": return "AO"
    case "Anguilla": return "AI"
    case "Antarktyka": return "AQ"
    case "Antigua i Barbuda": return "AG"
    case "Arabia Saudyjska": return "SA"
    case "Argentyna": return "AR"
    case "Armenia": return "AM"
    case "Aruba": return "AW"
    case "Australia": return "AU"
    case "Austria": return "AT"
    case "Anglia": return "GB"
    case "Azerbejdżan": return "AZ"
    case "Bahamy": return "BS"
    case "Bahrajn": return "BH"
    case "Bangladesz": return "BD"
    case "Barbados": return "BB"
    case "Belgia": return "BE"
    case "Belize": return "BZ"
    case "Benin": return "BJ"
    case "Bermudy": return "BM"
    case "Bhutan": return "BT"
    case "Białoruś": return "BY"
    case "Boliwia": return "BO"
    case "Bonaire, Sint Eustatius i Saba": return "BQ"
    case "Bośnia i Hercegowina": return "BA"
    case "Botswana": return "BW"
    case "Brazylia": return "BR"
    case "Brunei": return "BN"
    case "Brytyjskie Terytorium Oceanu Indyjskiego": return "IO"
    case "Brytyjskie Wyspy Dziewicze": return "VG"
    case "Bułgaria": return "BG"
    case "Burkina Faso": return "BF"
    case "Burundi": return "BI"
    case "Chile": return "CL"
    case "Chiny": return "CN"
    case "Chorwacja": return "HR"
    case "Curaçao": return "CW"
    case "Cypr": return "CY"
    case "Czad": return "TD"
    case "Czarnogóra": return "ME"
    case "Czechy": return "CZ"
    case "Dalekie Wyspy Mniejsze Stanów Zjednoczonych": return "UM"
    case "Dania": return "DK"
    case "Demokratyczna Republika Konga": return "CD"
    case "Dominika": return "DM"
    case "Dominikana": return "DO"
    case "Dżibuti": return "DJ"
    case "Egipt": return "EG"
    case "Ekwador": return "EC"
    case "Erytrea": return "ER"
    case "Estonia": return "EE"
    case "Eswatini": return "SZ"
    case "Etiopia": return "ET"
    case "Falklandy": return "FK"
    case "Fidżi": return "FJ"
    case "Filipiny": return "PH"
    case "Finlandia": return "FI"
    case "Francja": return "FR"
    case "Francuskie Terytoria Południowe i Antarktyczne": return "TF"
    case "Gabon": return "GA"
    case "Gambia": return "GM"
    case "Georgia Południowa i Sandwich Południowy": return "GS"
    case "Ghana": return "GH"
    case "Gibraltar": return "GI"
    case "Grecja": return "GR"
    case "Grenada": return "GD"
    case "Grenlandia": return "GL"
    case "Gruzja": return "GE"
    case "Guam": return "GU"
    case "Guernsey": return "GG"
    case "Gujana Francuska": return "GF"
    case "Gujana": return "GY"
    case "Gwadelupa": return "GP"
    case "Gwatemala": return "GT"
    case "Gwinea Bissau": return "GW"
    case "Gwinea Równikowa": return "GQ"
    case "Gwinea": return "GN"
    case "Haiti": return "HT"
    case "Hiszpania": return "ES"
    case "Holandia": return "NL"
    case "Honduras": return "HN"
    case "Hongkong": return "HK"
    case "Indie": return "IN"
    case "Indonezja": return "ID"
    case "Irak": return "IQ"
    case "Iran": return "IR"
    case "Irlandia": return "IE"
    case "Islandia": return "IS"
    case "Izrael": return "IL"
    case "Jamajka": return "JM"
    case "Japonia": return "JP"
    case "Jemen": return "YE"
    case "Jersey": return "JE"
    case "Jordania": return "JO"
    case "Kajmany": return "KY"
    case "Kambodża": return "KH"
    case "Kamerun": return "CM"
    case "Kanada": return "CA"
    case "Katar": return "QA"
    case "Kazachstan": return "KZ"
    case "Kenia": return "KE"
    case "Kirgistan": return "KG"
    case "Kiribati": return "KI"
    case "Kolumbia": return "CO"
    case "Komory": return "KM"
    case "Kongo": return "CG"
    case "Korea Południowa": return "KR"
    case "Korea Północna": return "KP"
    case "Kostaryka": return "CR"
    case "Kuba": return "CU"
    case "Kuwejt": return "KW"
    case "Laos": return "LA"
    case "Lesotho": return "LS"
    case "Liban": return "LB"
    case "Liberia": return "LR"
    case "Libia": return "LY"
    case "Liechtenstein": return "LI"
    case "Litwa": return "LT"
    case "Luksemburg": return "LU"
    case "Łotwa": return "LV"
    case "Macedonia Północna": return "MK"
    case "Madagaskar": return "MG"
    case "Majotta": return "YT"
    case "Makau": return "MO"
    case "Malawi": return "MW"
    case "Malediwy": return "MV"
    case "Malezja": return "MY"
    case "Mali": return "ML"
    case "Malta": return "MT"
    case "Mariany Północne": return "MP"
    case "Maroko": return "MA"
    case "Martynika": return "MQ"
    case "Mauretania": return "MR"
    case "Mauritius": return "MU"
    case "Meksyk": return "MX"
    case "Mikronezja": return "FM"
    case "Mjanma": return "MM"
    case "Mołdawia": return "MD"
    case "Monako": return "MC"
    case "Mongolia": return "MN"
    case "Montserrat": return "MS"
    case "Mozambik": return "MZ"
    case "Namibia": return "NA"
    case "Nauru": return "NR"
    case "Nepal": return "NP"
    case "Niemcy": return "DE"
    case "Niger": return "NE"
    case "Nigeria": return "NG"
    case "Nikaragua": return "NI"
    case "Niue": return "NU"
    case "Norfolk": return "NF"
    case "Norwegia": return "NO"
    case "Nowa Kaledonia": return "NC"
    case "Nowa Zelandia": return "NZ"
    case "Oman": return "OM"
    case "Pakistan": return "PK"
    case "Palau": return "PW"
    case "Palestyna": return "PS"
    case "Panama": return "PA"
    case "Papua-Nowa Gwinea": return "PG"
    case "Paragwaj": return "PY"
    case "Peru": return "PE"
    case "Pitcairn": return "PN"
    case "Polinezja Francuska": return "PF"
    case "Polska": return "PL"
    case "Portoryko": return "PR"
    case "Portugalia": return "PT"
    case "Południowa Afryka": return "ZA"
    case "Republika Środkowoafrykańska": return "CF"
    case "Republika Zielonego Przylądka": return "CV"
    case "Reunion": return "RE"
    case "Rosja": return "RU"
    case "Rumunia": return "RO"
    case "Rwanda": return "RW"
    case "Sahara Zachodnia": return "EH"
    case "Saint Kitts i Nevis": return "KN"
    case "Saint Lucia": return "LC"
    case "Saint Vincent i Grenadyny": return "VC"
    case "Saint-Barthélemy": return "BL"
    case "Saint-Martin": return "MF"
    case "Saint-Pierre i Miquelon": return "PM"
    case "Salwador": return "SV"
    case "Samoa Amerykańskie": return "AS"
    case "Samoa": return "WS"
    case "San Marino": return "SM"
    case "Senegal": return "SN"
    case "Serbia": return "RS"
    case "Seszele": return "SC"
    case "Sierra Leone": return "SL"
    case "Singapur": return "SG"
    case "Sint Maarten": return "SX"
    case "Słowacja": return "SK"
    case "Słowenia": return "SI"
    case "Somalia": return "SO"
    case "Sri Lanka": return "LK"
    case "Stany Zjednoczone": return "US"
    case "Sudan": return "SD"
    case "Sudan Południowy": return "SS"
    case "Surinam": return "SR"
    case "Svalbard i Jan Mayen": return "SJ"
    case "Syria": return "SY"
    case "Szwajcaria": return "CH"
    case "Szwecja": return "SE"
    case "Tadżykistan": return "TJ"
    case "Tajlandia": return "TH"
    case "Tajwan": return "TW"
    case "Tanzania": return "TZ"
    case "Timor Wschodni": return "TL"
    case "Togo": return "TG"
    case "Tokelau": return "TK"
    case "Tonga": return "TO"
    case "Trynidad i Tobago": return "TT"
    case "Tunezja": return "TN"
    case "Turcja": return "TR"
    case "Turkmenistan": return "TM"
    case "Turks i Caicos": return "TC"
    case "Tuvalu": return "TV"
    case "Uganda": return "UG"
    case "Ukraina": return "UA"
    case "Urugwaj": return "UY"
    case "Uzbekistan": return "UZ"
    case "Vanuatu": return "VU"
    case "Wallis i Futuna": return "WF"
    case "Watykan": return "VA"
    case "Wenezuela": return "VE"
    case "Węgry": return "HU"
    case "Wielka Brytania": return "GB"
    case "Wietnam": return "VN"
    case "Włochy": return "IT"
    case "Wybrzeże Kości Słoniowej": return "CI"
    case "Wyspa Bouveta": return "BV"
    case "Wyspa Bożego Narodzenia": return "CX"
    case "Wyspa Man": return "IM"
    case "Wyspa Świętej Heleny, Wyspa Wniebowstąpienia i Tristan da Cunha": return "SH"
    case "Wyspy Alandzkie": return "AX"
    case "Wyspy Cooka": return "CK"
    case "Wyspy Dziewicze Stanów Zjednoczonych": return "VI"
    case "Wyspy Heard i McDonalda": return "HM"
    case "Wyspy Kokosowe": return "CC"
    case "Wyspy Marshalla": return "MH"
    case "Wyspy Owcze": return "FO"
    case "Wyspy Salomona": return "SB"
    case "Wyspy Świętego Tomasza i Książęca": return "ST"
    case "Zambia": return "ZM"
    case "Zimbabwe": return "ZW"
    case "Zjednoczone Emiraty Arabskie": return "AE"
  }

}