const example = {
  version: "nowa wersja",
  logs: [
    {
      scope: "widok / moduł / feature (nieobowiązkowe)",
      changes: ["zmiana 1", "zmiana 2"],
    },
  ],
  author: "you",
};

export const versions = [
  {
    version: "0.13.271",
    logs: [
      {
        scope: "Aplikacja",
        changes: [
          "Wylogowanie apki po logowaniu w nowej karcie lub wygaśnieciu tokenu usera",
          "Czyszczenie cache ReactQuery po wylogowaniu",
          "Przeniesienie całej logiki związanej z wylogowywaniem i trzymaniem sesji do contextu usera",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.270",
    logs: [
      {
        scope: "Aplikacja",
        changes: [
          "Naprawiono wiele błędów",
          "Wprowadzono liczne poprawki poprawiające działanie aplikacji",
          "Envy zostały zaktualizowane i działają poprawnie",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.263",
    logs: [
      {
        scope:
          "Naprawa krytycznych błędów wynikających z braku danych w widokach",
        changes: [
          "Baza klientów -> Towary",
          "Księgowość -> Tabela kursów walut",
          "Księgowość -> Lista faktur",
          "Księgowość -> Ustawienia księgowe",
          "Księgowość -> Windykacja",
          "Księgowość -> Kontrahenci",
          "Zlecenia -> Zlecenie spedycyjne",
          "Flota -> Stan floty",
          "Flota -> Eksploatacja",
          "Konfiguracja -> Kierowcy",
          "Konfiguracja -> Pojazdy",
          "Konfiguracja -> Naczepy",
          "Konfiguracja -> Użytkownicy",
          "Konfiguracja -> Firmy",
          "Konfiguracja -> Operatorzy",
        ],
      },
      {
        scope: "Aplikacja",
        changes: [
          "Dodanie do 'readme' globalnych zmiennych dla bety oraz dema",
        ],
      },
      {
        scope: "Sidebar",
        changes: [
          "Wyświetlanie przycisku 'Zobacz Wideoinstrukcję TMS' zależne od wersji aplikacji",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.262",
    logs: [
      {
        scope: "Nowe zlecenie / edycja zlecenia / szablony zleceń",
        changes: [
          "Usunięcie błędu który uniemożliwiał utworzenie pierwszego szablonu notatki z widoku formularza zlecenia",
          "Wyświetlanie poprawnie w modalu szablonów notatek dolnej belki z ilością stron oraz szablonów",
          "Poszerzenie tabelki w modalu z listą szablonów notatek",
          "Podczas próby zapisu zlecenia z zajętym już numerem zlecenia pojawia sie informacja 'Numer zlecenia jest już zajęty, wygenerowano nowy numer zlecenia' i automatycznie generuje się nowy numer zlecenia umożliwiając zapisanie zlecenia",
          "Przy adresach ręcznych w punktach trasy i próbie dodania zlecenia bez wpisania adresu pojawia się komunikat o niewypełnionych adresach podświetlając pole adresu uniemożliwiając stworzenie / edycję zlecenia",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.261",
    logs: [
      {
        scope: "News bar",
        changes: ["Poprawienie wyświetlania komunikatu o odprawie celnej"],
      },
    ],
    author: "Marcin G",
  },
  {
    version: "0.13.260",
    logs: [
      {
        scope: "Nowe zlecenie / edycja zlecenia / szablony zleceń",
        changes: [
          "Notatka dla kierowcy zapisuje się w zleceniu podczas tworzenia / edycji zlecenia",
          "Przy zaznaczonym checkboxie 'Notatka dla kierowcy' walidujemy polę notatki podczas tworzenia / edycji zlecenia",
          "Przycisk 'Szablony notatek' otwiera modal z listą szablonów z możliwością wyboru, edycji oraz usuwania",
          "Przycisk 'Utwórz jako szablon' otwiera modal tworząc nowy szablon",
        ],
      },
      {
        scope: "Notatki dla kierowcy",
        changes: [
          "Dodanie nowego widoku z listą szablonów notatek dla kierowcy z możliwością dodawania nowych oraz edycji, usuwania aktualnych",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.259",
    logs: [
      {
        scope: "Wystaw fakturę",
        changes: [
          "Dodanie usuwania wszystkich znaków specjalnych oraz spacji z numeru NIP przy zapisie faktury",
          "Poprawienie błędu powodującego nie ładowanie się danych nabywcy przy edycji faktury",
        ],
      },
      {
        scope: "Raporty",
        changes: [
          "Dodanie statusu 'Kontrola celna' do raportów realizacji zleceń oraz aktywności kierowców",
        ],
      },
    ],
    author: "Marcin G",
  },
  {
    version: "0.13.258",
    logs: [
      {
        scope: "Nowe zlecenie / edycja zlecenia / szablony zleceń",
        changes: [
          "Optymalizacja walidacji ładunku / rozładunku towaru",
          "Przeniesienie notatki dla kierowcy do sekcji 'Ładunek'",
          "Powiększenie pola tekstowego treści notatki",
          "Dodanie przycisków do edycji oraz stworzenia szablonu notatki",
          "Podczas ładowania tras domyślnych do selecta wyświetlanie animacji ładowania w miejscu wyboru trasy domyślnej zamiast na środku ekranu",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.257",
    logs: [
      {
        scope: "Nowe zlecenie / edycja zlecenia / szablony zleceń",
        changes: [
          "Dodanie waluty przy stawce za kilometr",
          "Zmiana kursora na 'pointer' po najechaniu na ikonę generowania nowego numer zlecenia",
          "Dodanie notatki dla kierowcy z przykładowymi szablonami (bez zapisu na backend)",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.256",
    logs: [
      {
        scope: "Nowe zlecenie",
        changes: [
          "Dodanie przycisku do ponownego wygenerowania numeru zlecenia oraz zmiana treści komunikatu w przypadku tworzenia zlecenia z zajętym już numerem.",
        ],
      },
    ],
    author: "Marcin G",
  },
  {
    version: "0.13.255",
    logs: [
      {
        scope: "Konfiguracja / Pojazdy",
        changes: [
          "Zmiana zapisywania dancyh pojazdu, tak aby jego nazwą był tylko numer rejestracyjny bez marki i modelu",
        ],
      },
      {
        scope: "AutoDeploy",
        changes: ["Dostosowanie aplikacji do autodeploy-a"],
      },
    ],
    author: "Marcin G feat. Radek Komorowski",
  },
  {
    version: "0.13.254",
    logs: [
      {
        scope: "Raporty EKD",
        changes: [
          "Dodanie protokołów wyjazdowych oraz przycisku do ich pobrania do listy",
          "Dodanie handlera wywołującego komunikat w przypadku próby wygenerowania raportu bez wypełnionego formularza",
          "Poprawienie zakresu dat 'do' na ostatni dzień wybranego miesiąca",
          "Dodanie sortowania po dacie",
        ],
      },
    ],
    author: "Marcin G",
  },
  {
    version: "0.13.253",
    logs: [
      {
        scope: "Zlecenia",
        changes: [
          "Podczas kopiowania zlecenia nie kopiujemy ilości towarów w ładunku oraz punktach trasy",
          "Przy próbie zapisu / edycji zlecenia bez klienta wyświetlamy informację 'Wybierz klienta' i podświetlamy jego pole",
        ],
      },
      {
        scope: "Użytkownicy",
        changes: [
          "Po edycji użytkownika formularz edycji wyświetla jego dane zamiast pustego formularza",
          "Dane nowego użytkownika nie wypełniają się już edytowanym wcześniej użytkownikiem",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.252",
    logs: [
      {
        scope: "Zlecenie spedycyjne",
        changes: [
          "Poprawienie błędu powodującego wyświetlenie białej strony po wejściu w zlecenia spedycyjne",
        ],
      },
      {
        scope: "Wystaw fakturę",
        changes: [
          "Wybranie klienta bez przypisanego domyślnego konta nie powoduje wyczyszczenia danych formularza zwiazanych z kontami bankowymi",
          "Dodanie wizualnego zaznaczenia braku wybranego nabywcy w przypadku próby zapisu formularza",
        ],
      },
      {
        scope: "Raport aktywności kierowców",
        changes: ["Dodanie brakujących statusów"],
      },
      {
        scope: "Lista zleceń / Zestawy transportowe",
        changes: ["Ukrycie checkboxów oraz przycisku do usuwania wielu wpisów"],
      },
    ],
    author: "Marcin G",
  },
  {
    version: "0.13.251",
    logs: [
      {
        scope: "Podgląd zlecenia",
        changes: ["Podgląd zlecenia nie generuje nowego numeru zlecenia"],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.250",
    logs: [
      {
        scope: "Harmonogram",
        changes: ["Po kliknięciu na zlecenie nie zmieniamy jego tła"],
      },
      {
        scope: "Stwórz zlecenie",
        changes: [
          "Po utworzeniu nowego zlecenia aktualizowanie trasy w HereMaps o id zlecenia",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.249",
    logs: [
      {
        scope: "Harmonogram",
        changes: [
          "Wyświetlanie wszystkich punktów trasy",
          "W zleceniu które zostało rozpoczęte i posiada zrealizowane punkty trasy, niezrealizowane punkty posiadają czerwone tło",
        ],
      },
      {
        scope: "Lista zleceń",
        changes: [
          "Zoptymalizowanie pobierania listy zleceń i dodanie informacji do loadingu",
          "Naprawienie nie wyświetlania animacji ładowania po powrocie do zakładki 'Lista zleceń'",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.248",
    logs: [
      {
        scope: "Lista zleceń",
        changes: ["Poprawienie wyświetlania tagów na liście"],
      },
      {
        scope: "Lista faktur",
        changes: [
          "Dodanie modala z możliwością potwierdzenia / wpisania adresu email dla odbiorcy faktury",
        ],
      },
      {
        scope: "Konfiguracja -> Pojazdy",
        changes: [
          "Poprawienie błędu powodującego dodanie słowa 'null' do nazwy pojazdu w przypadku braku nazwy marki",
        ],
      },
      {
        scope: "Edycja operatora / firmy",
        changes: [
          "Zmiana etykiety inoutu z 'Nadawca' na 'Adres email nadawcy'",
        ],
      },
    ],
    author: "Marcin G",
  },
  {
    version: "0.13.247",
    logs: [
      {
        scope: "Aplikacja",
        changes: [
          "Dodanie globalnego dialogu (dolnego powiadomienia), które jest dostępne we wszystkich widokach po użyciu z propsów funkcji setDialog, przykład: 'setDialog(isOpen: true | false, type: 'success | error', content: 'wiadomość')",
          "Zaimplementowanie globalnej funkcji która przechodzi do wybranej zakładki i zamyka aktualną, jest ona dostępna we wszystkich widokach z propsów: tabCloseAndChangeTo('nazwa komponentu zakładki do której przechodzimy po zamknięciu aktualnej')",
          "Zmiana pierwszego widoku na 'Lista zleceń', ułatwia poruszanie się po aplikacji",
        ],
      },
      {
        scope: "Nowe zlecenie / edycja zlecenia",
        changes: [
          "W edycji zlecenia zmiana pojazdu lub kierowcy resetuje zestaw i wymaga ponownego kliknięcia 'Sprawdź zestaw transportowy'",
          "Po zapisaniu edycji zlecenia zamyka otwartą kartę oraz wraca do listy zleceń",
          "Umożliwienie zmiany kierowcy oraz pojazdu w edycji zlecenia",
          "Używanie nowej logiki odpowiedzialnej za zliczanie ilości towarów z punktów trasy podczas ładowania edycji zlecenia, która waliduje poprawność ilości ładunku",
          "Tworzenie nowej trasy domyślnej nie resetuje już towarów oraz dat w punktach trasy otwartego formularza zlecenia",
        ],
      },
      {
        scope: "Harmonogram",
        changes: [
          "Zmiana kolorów w harmonogramie na zaproponowane przez klienta",
          "Po kliknięciu w kafelkę otwieramy modal z pełnym podglądem zlecenia z możliwością przejścia do jego edycji",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.246",
    logs: [
      {
        scope: "Dodaj klienta",
        changes: [
          "Poprawienie błędu uniemożliwiającego zapis magazynów i departamentów w trybie edycji",
        ],
      },
    ],
    author: "Marcin G",
  },
  {
    version: "0.13.245",
    logs: [
      {
        scope: "Stawka za kilometr",
        changes: [
          "Dodanie możliwości zapisu waluty przy stawce za kilometr dla kontrahenta",
        ],
      },
      {
        scope: "Lista kontrahentów",
        changes: [
          "Dodanie waluty za stawkę przy kilometr w tabeli",
          "Poprawienie strony wizualnej, przeniesienie guzików na pasek tytułowy",
        ],
      },
      {
        scope: "Towary",
        changes: ["Poprawienie wyświetlania listy jednostek"],
      },
      {
        scope: "Mapa / Sidebar",
        changes: [
          "Ukrycie elementów związanych z E100 gdy jest ono nieaktywne na zalogowanym koncie",
        ],
      },
      {
        scope: "Overall",
        changes: [
          "Poprawienie etykiet jednostek na 'km' w kilku miejscach, poprawienie wielkich/małych liter w etykietach",
        ],
      },
    ],
    author: "Marcin G",
  },
  {
    version: "0.13.244",
    logs: [
      {
        scope: "Komunikator",
        changes: [
          "Blokowanie wysyłania pustych wiadomości z komunikatem 'Nie możesz wysłać pustej wiadomości'",
          "W oczekiwaniu na wysłanie wiadomości i odświeżenie czatu wyświetlanie animacji ładowania z informacją 'Wysyłanie wiadomości'",
          "Po wejściu na komunikator ustawianie aktywnej ostatniej konwersacji",
        ],
      },
      {
        scope: "Harmonogram",
        changes: [
          "Zmiana kolorów tła zlecenia na podstawie bieżącej realizacji: żółty - zlecenie w trakcie, zielony - zakończone, czerwony - przeterminowane / niezrealizowane / anulowane",
          "Podzielenie zlecenia na podstawie punktów trasy oraz ustawiania ich tła na podstawie: zielony - zrealizowany, zółty - niezrealizowany",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.243",
    logs: [
      {
        scope: "Nowe zlecenie / edycja zlecenia / szablony zleceń",
        changes: [
          "Naprawienie zliczania wszystkich towarów w zleceniu z wyświetleniem poprawnej ilości ładunku podczas ładowania zlecenia do edycji",
          "Poprawa logiki odpowiedzialnej za automatyczne licznie ilości towaru do dodania w punktach trasy po wyborze towaru",
          "Naprawienie wszystkich konsolowych errorów",
          "Wyszarzone ikonki załadunku / rozładunku przy towarze gdy nie rozładowaliśmy lub załadowaliśmy żadnej ilości",
        ],
      },
      {
        scope: "Trasy domyślne",
        changes: [
          "Naprawa buga który powodował białą stronę po wejściu na edycję trasy domyślnej",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.242",
    logs: [
      {
        scope: "Nowe zlecenie / edycja zlecenia / szablony zleceń",
        changes: [
          "Blokowanie wybrania godziny w punktach trasy przed wybraniem daty w celu rozwiązania problemu z niezablokowanymi godzinami bez wcześniejszego wyboru daty",
          "Na podstawie adresu załadunku / rozładunku w punkcie trasy wyświetlanie miniaturki flagi oraz skrótu kraju",
          "Napisanie nowej walidacji dla ilości towarów która aktualizuje ilość załadowanych / rozładowanych towarów po każdej zmianie w punktach trasy",
          "Po dodaniu nowego załadunku / rozładunku i wybraniu towaru automatycznie liczy potrzebną różnicę ilości towaru w stosunku załadunku do rozładunku i ustawia ją w ilości wybranego towaru w punkcie trasy",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.241",
    logs: [
      {
        scope: "Konfiguracja -> Operatorzy / Firma",
        changes: ["Dodanie możliwości konfiguracji ustawień skrzynki email"],
      },
      {
        scope: "Lista faktur",
        changes: ["Dodanie przycisku do wysyłki faktury na email"],
      },
    ],
    author: "Marcin G",
  },
  {
    version: "0.13.240",
    logs: [
      {
        scope: "Nowe zlecenie / edycja zlecenia / szablony zleceń",
        changes: [
          "Załatanie buga który uniemożliwiał załadowanie zestawu transportowego po edycji zlecenia",
          "W sekcji 'Ładunek' przeniesienie ilości załadowanych oraz rozładowanych zaraz obok ilości ładunku dla lepszej czytelności",
          "Dodanie do dat w punktach trasy dokładną walidację (od - do) biorąc pod uwagę również godziny z minutami",
          "Rozbudowanie walidatora ilości towarów biorąc pod uwagę wszystkie możliwe scenariusze błędów i uruchamianie go podczas zapisywania / edytowania zlecenia",
          "Dodanie ikonek załadunku / rozładunku przy ilościach towaru w punktach trasy dla jeszcze lepszej czytelności",
          "Dodanie do sekcji 'Ładunek' checkboxa 'Nie weryfikuj ilości towarów', który omija walidatora ilości przy tworzeniu / edycji zlecenia",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.239",
    logs: [
      {
        scope: "Raport realizacji zleceń",
        changes: [
          "Dodanie podglądu statusów zleceń w przypadku gdy takowe istnieją",
        ],
      },
    ],
    author: "Marcin G",
  },
  {
    version: "0.13.238",
    logs: [
      {
        scope: "Lista zleceń spedycyjnych",
        changes: [
          "Dodanie możliwosci zakończenia zlecenia spedycyjnego ręcznie wraz z kolumną 'Status'",
        ],
      },
      {
        scope: "Raport realizacji zleceń",
        changes: [
          "Dodanie kolumny 'Numer zlecenia klienta'",
          "Dodanie kolumny 'Nr ref.'",
          "Dodanie kolumny 'Tagi'",
          "Dodanie kolumny 'Kwota za zlecenie'",
          "Dodanie możliwości przeszukiwania tabeli po numerze zlecenia, numerze zlecenia klienta, kliencie, kierowcy, samochodzie, numerach referencyjnych lub tagach",
        ],
      },
    ],
    author: "Marcin G",
  },
  {
    version: "0.13.237",
    logs: [
      {
        scope: "Nowe zlecenie / edycja zlecenia / szablony zleceń",
        changes: [
          "Automatyczne dodawanie domyślnego numeru ref do nowych punktów trasy",
          "Po zapisie edytowanego zlecenia pobieranie nowych danych i aktualizowanie formularza",
          "Gdy niezrealizowany punkt trasy znajduje się przed zrealizowanym umożliwienie przeniesienia go za zrealizowany punkt trasy",
          "Naprawa czyszczenia adresów w punktach trasy po stworzeniu lub anulowaniu zlecenia",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.236",
    logs: [
      {
        scope: "Nowe zlecenie / edycja zlecenia / szablony zleceń",
        changes: [
          "Naprawienie logiki odpowiedzialnej za dodawanie zakładek do session storage, po odświeżeniu aplikacji nie wyświetla już białej strony z napisem 'Wczytuję...', tylko ładuje odpowiednią zakładkę",
          "Nie blokowanie załadunku / rozładunku, które znajdują się przed zrealizowanym punktem trasy",
          "Uniemożliwienie w punkcie trasy wybranie daty późniejszej niż ustawiona data w kolejnym punkcie trasy",
          "Gdy mamy tylko jeden ładunek lub towar w punkcie trasy nie wyświetlamy przycisku usuwania uniemożliwiając jego usunięcie",
          "Uniemożliwienie wybranie tego samego towaru więcej niż raz w załadunku / rozładunku",
          "Jeśli nie mamy do wybrania żadnego ładunku w załadunku / rozładunku wyświetlamy informację w select: 'Brak ładunku do wybrania'",
          "Po zmianie kolejności punktów trasy zamiast czyszczenia wszystkich dat ustawiamy daty w tej samej kolejności",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.235",
    logs: [
      {
        scope: "Lista zleceń / lista zleceń spedycyjnych",
        changes: [
          "Dodanie kolumny Nr ref. wraz z możliwością wyszukiwania po nich",
          "Dodanie kolumny Nr zlecenia (klient). wraz z możliwością wyszukiwania po nim",
          "Dodanie kolumny 'Przewoźnik' w widoku listy zleceń spedycyjnych wraz z możliwością wyszukiwania",
          "Dodanie kolumny 'Tagi' w widoku listy zleceń spedycyjnych wraz z możliwością wyszukiwania",
          "Wyszukiwanie + sortowanie w kolumnie 'Data'",
        ],
      },
      {
        scope: "Stan floty",
        changes: [
          "Dodanie wyświetlania tagów z aktywnego zlecenia wraz z możliwością wyszukiwania po nich",
        ],
      },
    ],
    author: "Marcin G",
  },
  {
    version: "0.13.234",
    logs: [
      {
        scope: "Edycja zlecenia i zlecenia spedycyjnego",
        changes: [
          "Podczas edycji zleceń i zleceń spedycyjnych wysyłamy 'id' locations",
        ],
      },
      {
        scope: "Komunikator",
        changes: [
          "Możliwość wysyłki plików do kierowcy",
          "Wyświetlanie listy kierowców od ostatniej wysłanej / odebranej wiadomości, która odświeża się automatycznie",
          "Usunięcie czerwonej ikonki czatu która wyświetlała się obok danych kierowcy i po kliknięciu otwierała nam jego czat, teraz wystarczy kliknąć na kierowcę",
          "Podświetlanie na jasnozielony kolor kierowcy, którego aktualnie czat jest otwarty",
          "Dodanie możliwości wysyłania wiadomości po kliknięciu przycisku 'Enter' oraz dodanie fiszki / checkboxa który umożliwia włączenie lub wyłaczenie tej funkjonalności",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.233",
    logs: [
      {
        scope: "Nowe zlecenie / edycja zlecenia / szablony zleceń",
        changes: [
          "Uniemożliwienie zmiany kolejności punktów trasy w zakończonym zleceniu z wystawianą fakturą",
          "Podczas kopiowania zlecenia z towarem czyścimy pola 'Numer ref' w ładunku oraz towarach w punktach zleceń, jeśli towaru nie ma wtedy ustawiamy 'Numer ref' w punktach trasy na domyślną wartość 'ref_1'",
          "Jeśli w ładunku zmienimy ilość towaru na mniejszą niż mamy w załadunku i rozładunku pojawia się błąd 'Zbyt mała ilość towaru w ładunku aby zrealizować załadunek / rozładunek' umożliwając nam edycję lub stworzenie zlecenia",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.232",
    logs: [
      {
        scope: "Nowe zlecenie / edycja zlecenia / szablony zleceń",
        changes: [
          "Uniemożliwienie zmiany kolejności zablokowanego / zrealizowanego punktu zlecenia z informacją 'Nie możesz przenieść zrealizowanego zlecenia'",
          "Odblokowywanie pola 'Numer Ref' w punktach trasy podczas edycji zlecenia bez towaru i dodania do niego nowego",
          "Naprawienie błędu, który uniemożliwiał wysyłanie 'good_id' po dodaniu nowego towaru do edytowanego zlecenia i jego zapisu",
          "Podczas przeciągnięcia punktu trasy bez zmiany jego kolejności nie resetujmy dat w punktach zlecenia",
          "Umożliwienie dodania nowych punktów z towarem podczas edycji do zlecenia w którym nie było towaru i zostały już zablokowane punkty trasy",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.231",
    logs: [
      {
        scope: "Nowe zlecenie / edycja zlecenia / szablony zleceń",
        changes: [
          "Zablokowanie pola do wyszukiwania adresu w zrealizowanych punktach trasy",
          "Możliwość modyfikacji numeru referencyjnego bezpośrednio w towarach znajdujących się w punktach trasy",
          "Blokowanie edycji punktów trasy przed zrealizowanymi punktami",
          "Podczas zmiany kolejności punktów trasy nie resetujemy dat zablokowanych punktów",
          "Uniemożliwienie przesunięcia / zmiany kolejności punktu trasy przed zrealizowane punkty z informacją 'Nie możesz umieścić punktu trasy przed zrealizowanymi punktami'",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.230",
    logs: [
      {
        scope: "Nowe zlecenie / edycja zlecenia / szablony zleceń",
        changes: [
          "Umożliwienie edycji kopiowanego zlecenia, które zostało skopiowane ze zlecenia z wystawioną fakturą",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.229",
    logs: [
      {
        scope: "Lista faktur",
        changes: [
          "Korekty do faktur wyświetlają się jako rozwijalne pozycje pod korygowanymi fakturami",
        ],
      },
    ],
    author: "Marcin G",
  },
  {
    version: "0.13.228",
    logs: [
      {
        scope: "Nowe zlecenie / edycja zlecenia / szablony zleceń",
        changes: [
          "Poszerzenie głównego widoku do maksymalnego rozmiaru okna",
          "Naprawienie błędu który powodował rozjeżdzanie się layoutu podczas dodawania więcej ładunków do punktu trasy",
          "Nie wyświetlanie statusów kierowców pod zestawem transportowym",
          "Blokowanie punktu trasy oraz zmiany jego kolejności, który posiada zrealizowany status od kierowcy zmieniając jego tło na zielony oraz dodając informację o jego zrealizowaniu wraz z datą realizacji",
          "Naprawienie błędu który podczas usuwania jednego ładunku w punkcie trasy, usuwał wszystkie",
          "Przeniesienie pola opis towaru do sekcji 'Ładunek' i zablokowanie go w ładunkach punktów trasy",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.227",
    logs: [
      {
        scope: "Nowe zlecenie / edycja zlecenia / szablony zleceń",
        changes: [
          "Checkboxy w sekcji 'Ładunek' ustawione poziomo z większą czcionką oraz kursorem typu pointer",
          "Dodanie numeru referencyjnego do punktów zleceń bez towaru",
          "Usunięcie buga, który czyścił km dojazdowe",
          "Jeśli edytowane zlecenie posiada statusy kierowców powiązane z punktami załadunku lub rozładunku blokowanie ich edycji oraz zestawu transportowego, dodatkowo wyświetlanie powiązanych statusów pod zestawem transportowym",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.226",
    logs: [
      {
        scope: "Nowe zlecenie / edycja zlecenia / szablony zleceń",
        changes: [
          "Po zmianie kolejności punktów trasy czyścimy wszystkie ustawione w nich daty",
          "Dodanie możliwości zmiany typu punktu trasy w rozładunku oraz załadunku",
          "Zablokowanie pól towaru w punktach załadunku i rozładunku",
          "Refaktor kolejności pól w punktach trasy",
          "Przeniesienie kosztów do nowej sekcji",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.225",
    logs: [
      {
        scope: "Invoice list",
        changes: [
          "Poprawienie wyświetlanej wartości netto dla faktur korygujących",
        ],
      },
      {
        scope: "Dodaj fakturę",
        changes: ["Poprawienie parametru 'value_brutto'przy zapisie faktury"],
      },
    ],
    author: "Marcin G",
  },
  {
    version: "0.13.224",
    logs: [
      {
        scope: "Nowe zlecenie / edycja zlecenia / szablony zleceń",
        changes: [
          "Naprawienie buga który uniemożliwiał stworzenie zlecenia z wpisanymi ręcznie greckimi adresami w punktach trasy",
          "Podmienienie ikon załadunku i rozładunku na 'nowe oraz bardziej czytelne' w punktach trasy",
          "Zmiana koloru 'paska' oraz obramowania punktu trasy w zależności od typu 'załadunek / rozładunek'",
          "Dodanie kontekstu do animacji ładowania np. Pobieranie danych, Zapisywanie danych, Ładowanie mapy etc.",
          "Dane w szablonach nie zawierają tych samych danych co zlecenia kopiowane (zestaw transportowy, numer zlecenia klienta, numery referencyjne, daty w punktach trasy, kwota całkowita)",
          "Przeniesienie towaru do nowej sekcji 'Ładunek'",
          "Poszerzenie sekcji z punktami trasy aby wyrównać ją do reszty formularza",
          "Refaktor designu danych podstawowych",
          "Rozszerzenie towaru w zleceniu o wszystkie pola z punktów trasy",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.223",
    logs: [
      {
        scope: "Lista faktur",
        changes: [
          "Dodanie sortowania faktur tak, aby korekta ustawiała się pod fakturą korygowaną",
          "Faktury skorygowane mają status 'Skorygowana'",
        ],
      },
      {
        scope: "Konfiguracja",
        changes: ["Zmiana etykiet przycisków ze 'Zmień dane' na 'Zapisz'"],
      },
      {
        scope: "Zakupy",
        changes: [
          "Poprawienie błedu powodującego wyświetlenie białej strony po wejściu w widok",
        ],
      },
    ],
    author: "Marcin G",
  },
  {
    version: "0.13.222",
    logs: [
      {
        scope: "Edycja szablonów",
        changes: [
          "Naprawienie błędu, który podczas zapisywania szablonu wysyłał zlecenie do kierowcy, usuwał szablon z listy oraz czyścił formularz szablonu",
          "Podczas edycji szablonu zamiast tytułu 'Edycja zlecenia' wyświetlanie 'Edycja szablonu' i na przyciskach zamiast 'Zapisz zlecenie' wyświetlanie 'Zapisz szablon'",
          "Umożliwienie tworzenia nowego szablonu z widoku edycji szablonu po kliknięciu 'Utwórz szablon'",
        ],
      },
      {
        scope: "Nowe zlecenie",
        changes: [
          "Zablokowanie selectów wyboru magazynów w punktach trasy bez wcześniej wybranego klienta / kontraktora",
        ],
      },
      {
        scope: "Zlecenia / szablony",
        changes: [
          "Podczas pobierania danych wyświetlanie tylko animacji wczytywania oraz podczas ich zapisywania blokowanie wszystkich pól aby uniemożliwić zmianę wartości formularza do momentu zakończenia procesu",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.221",
    logs: [
      {
        scope: "Rozliczenia kierowców",
        changes: ["Dodanie linii pionowych siatki do tabel"],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.220",
    logs: [
      {
        scope: "Nowe zlecenie",
        changes: [
          "Naprawienie błędu w którym przy tworzeniu zlecenia bez wcześniejszego zapisu trasy nie był dodawany routing_id oraz nie aktualizował się wpis trasy dodając do niego utworzony order_id co skutkowało brakiem relacji pomiędzy trasą a nowym zleceniem, które jest potrzebne by można było wysłać trasę do tabletu",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.219",
    logs: [
      {
        scope: "Lista faktur",
        changes: [
          "Poprawienie błędu powodującego crash aplikacji przy próbuje usunięcia kilku faktur",
        ],
      },
      {
        scope:
          "Konfiguracja -> Pojazdy / Naczepy / Kierowcy oraz widok Zestawów transportowych",
        changes: ["Dodanie w tabelach kolumny z liczbą porządkową"],
      },
    ],
    author: "Marcin G",
  },
  {
    version: "0.13.218",
    logs: [
      {
        scope: "Lista zleceń / podgląd zlecenia",
        changes: [
          "Jeśli faktura dla zlecenia została wystawiona nie wyświetla się już możliwość edycji na liście zleceń, tylko ikona lupy jako 'podgląd zlecenia', który blokuję edycję wszystkich pól w zleceniu",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.217",
    logs: [
      {
        scope: "Nowe zlecenie spedycyjne",
        changes: [
          "Poprawienie powiadomienia o brakach w formularzu (szczegóły towaru) podczas próby zapisania zlecenia",
        ],
      },
      {
        scope: "Zestawy transportowe",
        changes: [
          "Dodanie kolumny 'ID' w tabelach pojazdów, naczep oraz kierowców wraz z możliwością wyszukiwania po tych numerach",
        ],
      },
    ],
    author: "Marcin G",
  },
  {
    version: "0.13.216",
    logs: [
      {
        scope: "Stan floty",
        changes: [
          "Naprawienie buga, który uniemożliwiał załadowanie zlecenia do edycji po kliknięciu na podgląd przy zleceniu -> 'edytuj'",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.215",
    logs: [
      {
        scope: "Kopiowanie zlecenia",
        changes: [
          "Nie kopiowanie kierowców, pojazdów, numeru zlecenia klienta oraz numerów referencyjnych podczas kopiowania zlecenia",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.214",
    logs: [
      {
        scope: "Lista faktur",
        changes: ["Poprawienie spinnera podczas wczytywania danych"],
      },
      {
        scope: "Lista zleceń",
        changes: [
          "Poprawienie spinnera podczas wczytywania danych",
          "Podświetlenie wierszy zafakturowanych zleceń na zielono",
          "Poprawienie wyświetlania oraz logiki liczenia zysków ze zleceń spedycyjnych zgodnie z wymaganiami klienta",
        ],
      },
    ],
    author: "Marcin G",
  },
  {
    version: "0.13.213",
    logs: [
      {
        scope: "Koszty",
        changes: ["Dodanie rozliczenia kierowców"],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.212",
    logs: [
      {
        scope: "Koszty utrzymania floty",
        changes: [
          "Koszty utrzymania floty",
          "Koszty utrzymania floty - raporty",
          "Koszty utrzymania floty - raporty zatwierdzone",
        ],
      },
    ],
    author: "Marcin G",
  },
  {
    version: "0.13.211",
    logs: [
      {
        scope: "Dodaj fakturę",
        changes: [
          "Poprawienie błędu powodującego nie zapisywanie nabywcy przy tworzeniu zlecenia z zamówienia",
          "Poprawienie listy nabywców tak aby zawierała zarówno klientów jak i oddziały klientów",
        ],
      },
    ],
    author: "Marcin G",
  },
  {
    version: "0.13.210",
    logs: [
      {
        scope: "Edytuj profil pojazdu",
        changes: [
          "Naprawienie buga, który zapisywał puste wartości podczas edycji profilu pojazdu co skutkowało pustą stroną podczas tworzenia zlecenia wykorzystując pojazd z pustymi wartościami w profilu",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.209",
    logs: [
      {
        scope: "Nowe zlecenie",
        changes: [
          "Naprawienie buga, który triggerował pustą stronę podczas tworzenia zlecenia",
          "Optymalizacja pól formularza w danych podstawowych oraz punktach trasy",
          "Usunięcie podświetlania pól, które nie są wymagane do stworzenia lub edycji zlecenia",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.208",
    logs: [
      {
        scope: "Nowe zlecenie",
        changes: [
          "Ograniczenie do 60 znaków pola 'Numer Ref'",
          "Nie wyświetla się już 'NaN' przy statusie towaru kiedy nie wpiszemy ilości",
          "Zablokowanie wpisania ilości towaru w punktach trasy przed wpisaniem jej w szczegółach towaru",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.207",
    logs: [
      {
        scope: "Nowe zlecenie",
        changes: [
          "Naprawa błędu który losowo blokował stworzenie zlecania po wyborze trasy domyślnej i jej edycji",
          "Powiększenie i ograniczenie pola opis towaru do 50 znaków wraz z wyeliminowaniem 'jak by były lagi'",
          "Zmiana kolejności pól towaru w punktach trasy aby zrobić więcej miejsca dla opisu towaru",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.206",
    logs: [
      {
        scope: "Lista faktur",
        changes: ["Na liście faktur pokazują się teraz wszystkie faktury"],
      },
      {
        scope: "Wystaw fakturę",
        changes: [
          "Po powrocie do zakładki 'Dodaj fakturę' nie dublują się pozycje w usługach i numer faktury",
          "Usunięcie błędnego formatu dat które wysypywały aplikację po odświeżeniu strony na widoku dodania nowej faktury",
        ],
      },
      {
        scope: "Kopiowanie zlecenia",
        changes: [
          "Km dojazdowe ustawiamy na 1, nie kopiujemy kwoty całkowitej",
        ],
      },
      {
        scope: "Dodaj klienta",
        changes: [
          "Po znalezieniu danych po NIP czyszczenie pola wcześniej wpisanego adresu",
          "Dodanie komunikatu po pomyślnym dodaniu oraz edycji klienta",
          "Podczas anulowania formularza czyszczą się wszystkie jego pola",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.205",
    logs: [
      {
        scope: "fix/TMS-308",
        changes: ["domyslna data eksploatacji"],
      },
      {
        scope: "TMS-318",
        changes: [
          "W widoku Ustawienia->Kierowcy dodajemy edycję parametru active (0,1)",
        ],
      },
    ],
    author: "Radek K",
  },
  {
    version: "0.13.204",
    logs: [
      {
        scope: "fix/TMS-308",
        changes: ["ładunek do endpointa getExploitation"],
      },
    ],
    author: "Radek K",
  },
  {
    version: "0.13.203",
    logs: [
      {
        scope: "fix/slideBar",
        changes: ["scroll, font"],
      },
    ],
    author: "Radek K",
  },
  {
    version: "0.13.202",
    logs: [
      {
        scope: "Mapa/warstwa HERE",
        changes: ["Migracja warstwy mapy HERE do wersji v3"],
      },
    ],
    author: "Artur Z",
  },
  {
    version: "0.13.201",
    logs: [
      {
        scope: "Przepinki/Przesiaki",
        changes: ["Fix"],
      },
    ],
    author: "Radek K",
  },
  {
    version: "0.13.200",
    logs: [
      {
        scope: "Operatorzy / Edycja firmy",
        changes: [
          "Umożliwienie wgrania logo na fakturę w rozszerzeniach innych niż '.jpg'",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.199",
    logs: [
      {
        scope: "Nowe zlecenie",
        changes: [
          "Domyślna wartość kilometrów dojazdowych w kosztach ustawia się na 1",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.198",
    logs: [
      {
        scope: "Przepinka/Przesiadka",
        changes: ["Fixes"],
      },
    ],
    author: "Radek K",
  },
  {
    version: "0.13.197",
    logs: [
      {
        scope: "Nowe zlecenie",
        changes: [
          "Umożliwienie stworzenia zlecenia po poprawnym sprofilowaniu pojazdu",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.196",
    logs: [
      {
        scope: "Nowe zlecenie",
        changes: [
          "Po skopiowaniu zlecenia nie uzupełniają się daty w punktach trasy oraz suma kilometrów w kosztach",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.195",
    logs: [
      {
        scope: "Nowe zlecenie",
        changes: [
          "Dla ilości towaru w zleceniu dodanie pełnej walidacji w czasie rzeczywistym oraz przy poglądzie trasy czy tworzeniu zlecenia",
          "Przepisanie logiki walidowania formularza nowego zlecenia dodając do niego komunikaty, które wyświetlają się w zależności od rodzaju błędu",
          "Dodanie ilości załadowanego oraz rozładowanego towaru obok ikonek statusów w szczegółach towaru",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.194",
    logs: [
      {
        scope: "Dodaj klienta",
        changes: ["Dodanie możliwości poprawienia adresu klienta ręcznie"],
      },
      {
        scope: "Raporty cykliczne",
        changes: ["Poprawienie checkboxa raportów częściowych w trybie edycji"],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.193",
    logs: [
      {
        scope: "Nowe zlecenie",
        changes: [
          "Uniemożliwienie w punktach załadunku i rozładunku wybór daty wcześniejszej niż dzisiejsza oraz wybrana w poprzednich punktach trasy",
          "Usunięcie przycisku odpowiedzialnego za dodawanie nowego towaru, który z ikonką plusa znajdował się obok selecta wyboru towaru w punktach załadunku i rozładunku",
          "Zablokowanie możliwości zmiany ilości towaru w punktach załadunku i rozładunku na inną wartość niż zdefiniowana w szczegółach towaru",
          "Poprawienie logiki odpowiedzialnej o walidację poprawności współrzędnych w puntach trasy podczas otwierania mapy oraz zapisu zlecenia",
          "Po wyznaczeniu trasy z wpisanymi manualnie adresami magazynów, a potem wybraniu trasy domyślnej nie dochodzi już do 'sieczki danych'",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.192",
    logs: [
      {
        scope: "Nowe zlecenie",
        changes: [
          "Po zapisie oraz wyborze trasy domyślnej nie zapisuje / przywraca daty oraz cargo w punktach załadunku i rozładunku",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.191",
    logs: [
      {
        scope: "Nowe zlecenie",
        changes: [
          "Naprawienie logiki odpowiedzialnej za czyszczenie magazynów po kilkukrotnym anulowaniu trasy domyślnej",
          "Po 'anulowaniu' / wyczyszczeniu całego formularza generujemy ponownie nowy numer zlecenia",
          "Umożliwienie wyliczania km dojazdowych wraz z usunięciem błędu w przypadku użycia punktu typu 'początek trasy' z manualnie wpisanym adresem",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.190",
    logs: [
      {
        scope: "Podgląd trasy / Nowe zlecenie",
        changes: [
          "Umożliwienie zliczania kilometrów ładownych w 'Parametry routingu trasy' w podglądzie trasy oraz nowym zleceniu",
        ],
      },
      {
        scope: "Nowe zlecenie",
        changes: [
          "Dodanie informacji 'Brak dostępnych tras dla wybranego klienta' w select wyboru tras domyślnych gdy wybrany klient ich nie posiada",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.189",
    logs: [
      {
        scope: "Dodaj klienta",
        changes: ["Zmiana etykiety dla domyślnych kont"],
      },
    ],
    author: "Marcin G",
  },
  {
    version: "0.13.188",
    logs: [
      {
        scope: "Dodaj fakturę",
        changes: [
          "Dodanie spinnera w trakcie sprawdzania numeru NIP",
          "Poprawienie sprawdzania formularza przed zapisem",
          "Dodanie komunikatu na wypadek braku możliwości sprawdzenia numeru NIP",
        ],
      },
    ],
    author: "Marcin G",
  },
  {
    version: "0.13.187",
    logs: [
      {
        scope: "Dodanie magazynu z widoku nowego zlecenia",
        changes: [
          "Naprawianie błędu, który uniemożliwia zamknięcie modala po dodaniu magazynu",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.186",
    logs: [
      {
        scope: "Dodaj fakturę",
        changes: [
          "Naprawienie błędu powodującego nie wyświetlanie komunikatu o źle uzupełnionym formularzu po sprawdzeniu numeru NIP",
        ],
      },
      {
        scope: "Lista zleceń",
        changes: [
          "Naprawienie błędu powodującego dublowanie się pozycji w FV przy towrzeniu jej na podstawie zlecenia",
        ],
      },
      {
        scope: "Stan floty",
        changes: [
          "Poprawienie wyświetlania danych w podglądzie zlecenia w przypadku przepinki / przesiadki",
        ],
      },
    ],
    author: "Marcin G",
  },
  {
    version: "0.13.185",
    logs: [
      {
        scope: "Dodanie profilu pojazdu",
        changes: [
          "Naprawianie błędu, który blokuje zamknięcie modala po dodaniu profilu",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.184",
    logs: [
      {
        scope: "Import kosztów",
        changes: ["Dodanie importu kart paliwowych"],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.183",
    logs: [
      {
        scope: "Stwórz zlecenie",
        changes: [
          "Usunięcie buga który blokował pole 'Stawka' w szczegółach towaru",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.182",
    logs: [
      {
        scope: "Dodaj klienta",
        changes: [
          "Poprawienie błędu ze znikającymi danymi po kliknięciu przycisku Anuluj",
        ],
      },
    ],
    author: "Marcin G",
  },
  {
    version: "0.13.181",
    logs: [
      {
        scope: "Zlecenia",
        changes: [
          "Przy kopiowaniu zlecenia generujemy jego nowy numer",
          "Naprawienie błędu blokującego renderowanie mapy podczas kopiowania zlecenia",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.180",
    logs: [
      {
        scope: "Stwórz zlecenie",
        changes: [
          "Został dodany przycisk do anulowania trasy domyślnej, który przed resetem punktów załadunku i rozładunku pyta o potwierdzenie",
        ],
      },
      {
        scope: "Renderowanie trasy na mapie",
        changes: [
          "Edytowanie punktów głównych trasy nie powoduje już usunięcia naniesionych wcześniej punktów na mapie",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.179",
    logs: [
      {
        scope: "Lista klientów",
        changes: ["Poprawienie filtrowania listy klientów / przewoźników"],
      },
      {
        scope: "Kierowcy",
        changes: [
          "Wyświetlanie komunikatu o zduplikowanym loginie w bazie danych przy ustawianiu konta mobilnego",
          "Poprawienie komunikatu o zajętym numerze telefonu w przypadku tworzenia nowego kierowcy",
        ],
      },
    ],
    author: "Marcin G",
  },
  {
    version: "0.13.178",
    logs: [
      {
        scope: "Dodaj klienta",
        changes: [
          "Dodanie powiązanego oddziału operatora do oddziału klienta",
          "Dodanie kont oddziału księgowego do drop down listy jako opcje konta klienta",
        ],
      },
      {
        scope: "Wystaw fakturę",
        changes: [
          "Załadowanie domyślnych kont jako konta sprzedawcy przy wyborze nabywcy",
        ],
      },
    ],
    author: "Marcin G",
  },
  {
    version: "0.13.177",
    logs: [
      {
        scope: "Edycja profilu pojazdu",
        changes: [
          "Dodanie walidacji wymaganych pól w formularzu",
          "Dodanie zawsze widocznych przycisków akcji na dole formularza",
          "Podświetlanie nieuzupełnionych wymaganych pól",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.176",
    logs: [
      {
        scope: "Nowe zlecenie",
        changes: [
          "Podgląd trasy sprawdza wszystkie wymagane pola i podświetla niewypełnione",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.175",
    logs: [
      {
        scope: "Nowe zlecenie",
        changes: [
          "Rozszerzenie sprawdzania i wyświetlania wymaganych pól",
          "Naprawienie logiki odpowiedzialnej za wyświetlanie błędu po dodaniu towaru do zlecenia",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.174",
    logs: [
      {
        scope: "Dodaj klienta",
        changes: [
          "Dodanie możliwości dodawania kont bankowych do oddziałów klienta",
          "Możliwość zapisania dwóch kont jako domyślne",
        ],
      },
      {
        scope: "Dodaj fakturę",
        changes: [
          "Dodanie możliwości zapisu dwóch kont bankowych nabywcy",
          "Konta ustawiają się automatycznie jeżeli są ustawione jako domyślne dla danego nabywcy",
          "Poprawienie błędnego komunikatu przy sprawdzaniu numeru NIP",
        ],
      },
    ],
    author: "Marcin G.",
  },
  {
    version: "0.13.173",
    logs: [
      {
        scope: "Edycja zlecenia",
        changes: [
          "Zablokowanie możliwości edycji stawki w zleceniu gdy faktura jest juz wystawiona",
        ],
      },
      {
        scope: "Nowe zlecenie",
        changes: [
          "Zmiana automatycznej numeracji zleceń, dodanie numeracji per firma",
          "Dodanie okienka z podstawowymi informacjiami klienta",
        ],
      },
      {
        scope: "Ustawienia operatora",
        changes: ["Dodanie flagi numeracji zleceń per firma"],
      },
      {
        scope: "Wystaw fakturę",
        changes: [
          "Dodanie tagów do tworzenia faktury",
          "Dodanie nowego sposobu zapłaty - przelew dwuwalutowy",
          "Przelew dwuwalutowy ustawiany automatycznie gdy odbiorca faktury jest spoza Polski",
          "Sprawdzenie czy numer NIP jest aktywny przed wystawieniem FV",
        ],
      },
      {
        scope: "Tagi do faktur",
        changes: [
          "Dodanie widoku umożliwiającego dodawanie oraz edycję tagów do faktur",
        ],
      },
      {
        scope: "Lista faktur",
        changes: ["Dodanie filtrowania listy po tagach"],
      },
    ],
    author: "Marcin G.",
  },
  {
    version: "0.13.172",
    logs: [
      {
        scope: "Stwórz zlecenie",
        changes: [
          "Naprawienie logiki odpowiedzialnej za sprawdzanie zestawu transportowego",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.171",
    logs: [
      {
        scope: "Stwórz zlecenie",
        changes: [
          "Modyfikacja fiszki 'Wymagane podanie wagi' na 'Wymaga podania wagi ładunku' i przeniesienie jej nad szczegóły towaru w sekcji 'Dane podstawowe'",
          "Poprawki do styli",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.170",
    logs: [
      {
        scope: "Stwórz zlecenie",
        changes: ["Dodana fiszka 'Wymagane podanie wagi'"],
      },
      {
        scope: "Mobile - status załadunek / rozładunek",
        changes: [
          "Jeśli zlecenie wymaga podania wagi to po wybraniu statusu załadunek / rozładunek pojawia się dodatkowe pole do wpisania wagi (w kg)",
        ],
      },
      {
        scope: "Newsbar",
        changes: [
          "Jeśli waga zostanie wpisana podczas wybierania statusu załadunek / rozładunek (na mobile), przy statusie pojawia się waga",
        ],
      },
      {
        scope: "Historia statusów",
        changes: [
          "Jeśli waga została dodana w statusie wyświetla się w tabeli",
        ],
      },
      {
        scope: "Raport realizacji zleceń",
        changes: ["Przy miejscu załadunku / rozładunku wyświetlanie wagi"],
      },
      {
        scope: "Stan floty",
        changes: [
          "Zmiana domyślnego sortowania stanów floty na sortowanie po najnowszej dacie",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.169",
    logs: [
      {
        scope: "Stwórz zlecenie",
        changes: [
          "Poprawka do ładowania selecta 'Wybierz trase', nie wyświetla się już 'no options' po powrocie do otwartej zakładki z nowym lub edytowanym zleceniem",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.168",
    logs: [
      {
        scope: "Stwórz zlecenie",
        changes: [
          "Tworzenie nowej trasy z wybranej wcześniej trasy domyślnej nie nadpisuje jej, tylko tworzy nową trasę",
          "Podczas ładowania tras domyślnych do selecta 'Wybierz trasę' nie wyświetla już 'no options', select pojawia się dopiero po zakończeniu animacji ładowania komponentu",
          "Po zapisaniu trasy domyślnej po kliknięciu 'Pogląd trasy' ładuje waypointy zapisanej trasy",
        ],
      },
      {
        scope: "Trasy domyślne",
        changes: ["Dodanie paginacji"],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.167",
    logs: [
      {
        scope: "Komunikacja",
        changes: [
          "Dodanie plików przesłanych bez aktywnego zlecenia do listy",
          "Posortowanie listy plików względem czasu ich dodania",
        ],
      },
    ],
    author: "Marcin G",
  },
  {
    version: "0.13.166",
    logs: [
      {
        scope: "Lista zleceń",
        changes: [
          "Po skopiowaniu zlecenia tworzymy nowe zamiast edycji skopiowanego zlecenia",
        ],
      },
      {
        scope: "Stwórz zlecenie / edycja trasy",
        changes: ["Naprawa renderowania custom waypoints w poglądzie mapy"],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.165",
    logs: [
      {
        scope: "Stwórz zlecenie",
        changes: [
          "Usunięcie błędu wyświetlania 'NaN' na początku numeru zlecenia zamiast odpowiedniej wartości",
          "Usunięcie błędu podczas rysowania mapy w poglądzie trasy",
        ],
      },
      {
        scope: "Stwórz zlecenie / edycja trasy",
        changes: [
          "Po dodaniu punktu trasy jako 'Przejście graniczne' zawsze wyświetla selecty do wyboru krajów",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.164",
    logs: [
      {
        scope: "Raport aktywności kierowców",
        changes: [
          "Dodanie generowania raportu w pliku PDF",
          "Poprawienie generowania listy ",
        ],
      },
    ],
    author: "Marcin G",
  },
  {
    version: "0.13.163",
    logs: [
      {
        scope: "Raport aktywności kierowców",
        changes: ["Poprawienie funkcji liczącej czas trwania aktywności"],
      },
    ],
    author: "Marcin G",
  },
  {
    version: "0.13.162",
    logs: [
      {
        scope: "stwórz zlecenie / edycja trasy",
        changes: [
          "naprawa nie rysowania trasy podczas tworzenia zlecenia z adresów wpisywanych ręcznie w punkty załadunku lub rozładunku",
          "podczas wpisywania ręcznych adresów trasy i dodaniu przejścia granicznego automatycznie ustawia graniczące ze sobą kraje wraz z domyślnym przejściem granicznym",
        ],
      },
      {
        scope: "trasy",
        changes: ["dodanie kosztów drogowych"],
      },
      {
        scope: "ekran logowania",
        changes: [
          "usunięcie wyświetlania modala z historami zmian po kliknięciu na wersję aplikacji",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.161",
    logs: [
      {
        scope: "panel boczny",
        changes: [
          "na becie oraz środkowisku lokalnym po kliknięciu na wersję aplikacji wyświetalnie modala z historią zmian",
          "wyświetlana wersja aplikacji jest ostatnią wersją dodaną do pliku 'versions.ts'",
        ],
      },
      {
        scope: "stwórz zlecenie / edycja trasy",
        changes: [
          "po wybraniu typu punktu trasy 'Przejście graniczne' wyświetlanie selectów z wyborem krajów, które pozwalają na ustawienie innego przejścia granicznego niż domyślne ustawiające się automatycznie na podstawie wybranej trasy",
          "usunięty komunikat 'nie udało się załadować przejść' gdy nie znajdziemy domyślnego przejścia granicznego dla wybranych krajów",
        ],
      },
      {
        scope: "ekran logowania",
        changes: [
          "wyświetlanie wersji aplikacji na ekranie logowania, po kliknięciu modal z historią zmian (tylko na beta i localhost)",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.160",
    logs: [
      {
        scope: "historia statusów bez zlecenia",
        changes: ["nowy widok historia statusów bez zlecenia"],
      },
    ],
  },
  {
    version: "0.13.159",
    logs: [
      {
        scope: "raport aktywności kierowcy",
        changes: ["nowy raport aktywności kierowcy"],
      },
    ],
  },
  {
    version: "0.13.158",
    logs: [
      {
        scope: "stwórz zlecenie",
        changes: [
          "po usunięciu wszystkich punktów trasy nie wyświetla się już biała strona",
          "blokowanie przycisku 'Podgląd mapy' bez wypełnionych wszystkich adresów w punktach trasy",
        ],
      },
    ],
    author: "Adam J",
  },
  {
    version: "0.13.157",
    logs: [
      {
        scope: "edycja trasy",
        changes: [
          "zmiana kontrahenta czyści wcześniej wybrane magazyny w punktach trasy",
          "naprawa nie wczytywania na mapie waypointów z HereMaps podczas pierwszego renderu mapy",
          "po usunięciu wszystkich punktów trasy nie wyświetla się już biała strona",
          "blokowanie przycisku 'Podgląd mapy' bez odpowieniej ilości punktów na trasie oraz wypełnionych wszystkich adresów",
        ],
      },
      {
        scope: "stwórz zlecenie",
        changes: [
          "razem z resztą wartości formularza zapisywanie do sessionStorage wybranej trasy domyślnej",
          "po utworzeniu nowej trasy aktualizacja listy tras domyślnych wyświetlanej w select 'Wybierz trase' oraz ustawienie w nim dodanej trasy",
          "podczas załadowania trasy domyślnej w widoku stwórz zlecenie i potrzeby zrobienia z niej nowej trasy, tworzy nową trasę z nowym id zamiast edytowania załadowanej wcześniej trasy",
        ],
      },
      {
        scope: "domyślne trasy",
        changes: ["poprawki w TypeScript"],
      },
    ],
    author: "Adam J",
  },
];
