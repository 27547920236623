/* global google */
/* eslint-disable no-undef */
import React from 'react';
import { DirectionsRenderer, Polyline } from '@react-google-maps/api';

export class MyDirectionsRenderer extends React.Component {

    /**
    * constructor()
    *
    * Funkcja 
    *
    */
    constructor(props) {
        super(props);
        this.state = {
            polylinePath: null,
            directionsResponse: this.props.directionsResponse,
            polylinePath: null
        }
    }

    /**
    * componentDidMount()
    *
    * Funkcja 
    *
    */
    componentDidMount = () => {

        console.log('Mount MyDirectionsRenderer');

        if (this.props.renderMode === 'polyline') {

            if (this.props.pathEncoded !== null) {
                console.log('FROM DB');
                this.setState({
                    polylinePath: google.maps.geometry.encoding.decodePath(this.props.pathEncoded)
                });
                this.props.boundsToRouteCallback();
            }
            else {
                console.log('FROM services');
                this.setState({
                    polylinePath: this.state.directionsResponse.routes[0].overview_path
                });
                this.props.boundsToRouteCallback();
            }

        }

    }

    /**
    * componentDidUpdate(prevProps)
    *
    * Funkcja 
    *
    */
    componentDidUpdate(prevProps, prevState) {

        if (this.props.updateDirectionsRenderer) {
            if (this.props.pathEncoded !== null) {
                console.log('FROM DB update');
                this.setState({
                    polylinePath: google.maps.geometry.encoding.decodePath(this.props.pathEncoded)
                });
                this.props.boundsToRouteCallback();
            }
            else {
                console.log('FROM services update');
                this.setState({
                    polylinePath: this.state.directionsResponse.routes[0].overview_path
                });
                this.props.boundsToRouteCallback();
            }

            this.props.setUpdateDirectionsRendererCallback(false);
        }

    }

    /**
    * shouldComponentUpdate()
    *
    * Funkcja 
    *
    */
    shouldComponentUpdate = () => {
        if (this.props.renderMode === 'directions') {
            return false;
        } else if (this.props.renderMode === 'polyline') {
            return true;
        }
    }

    /**
    * onLoadPolyline()
    *
    * Funkcja 
    *
    */
    onLoadPolyline = (polyline) => {
        console.log('onLoadPolyline:');
        console.log(polyline);
    }



    /**
    * render()
    *
    * Funkcja 
    *
    */
    render() {
        // console.log('MyDirectionsRenderer');
        // console.log(this.props.renderMode);
        // console.log(this.state.polylinePath);
        return (
            <>
                {this.props.renderMode === 'directions' &&
                    <DirectionsRenderer
                        options={{
                            directions: this.state.directionsResponse,
                            suppressMarkers: this.props.suppressMarkers
                        }}

                        onLoad={() => this.props.onLoadDirectionsResponse}
                        onDirectionsChanged={() => this.props.onDirectionsChangedDirectionsResponse}
                    />
                }

                {this.props.renderMode === 'polyline' && this.state.polylinePath !== null &&
                    <Polyline
                        path={this.state.polylinePath}
                        options={optionsPolyline}
                        onLoad={this.props.onLoadDirectionsResponse}
                    />
                }
            </>
        )
    }

}


const lineSymbol = {
    path: 'M 1.5 1 L 1 0 L 1 2 M 0.5 1 L 1 0',
    fillColor: '#1891DE',
    strokeColor: '#1891DE',
    strokeWeight: 2,
    strokeOpacity: 1
};


const optionsPolyline = {
    geodesic: true,
    strokeColor: '#1891DE',
    strokeOpacity: 0.7,
    strokeWeight: 5,
    icons: [{
        icon: lineSymbol,
        offset: '25px',
        repeat: '100px'
    }]
};

