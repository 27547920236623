import React, { useState, useEffect } from "react";
import moment from "moment";
import Select from "react-select";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import stylesMod from "../../styles/newOrEdit.module.css";
import Input from "../../components/input";
import { DBurl } from "../../appConfig";
import BTNstyles from "../../styles/button.module.css";
import { Add } from "@material-ui/icons";
import { X } from "react-feather";
import { IconButton } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddVehicles(props) {
  const [model, setModel] = useState(props.data.model || null);
  const [nr_rejestracyjny, setNr_rejestracyjny] = useState(
    props.data.nr_rejestracyjny || null
  );
  const [vin, setVin] = useState(props.data.vin || null);
  const [rok_produkcji, setRok_produkcji] = useState(
    props.data.rok_produkcji || null
  );
  const [type, setType] = useState(props.data.type || null);
  const [axle_netto, setAxle_netto] = useState(props.data.axle_netto || null);
  const [term_oc, setTerm_oc] = useState(props.data.term_oc || null);
  const [term_ac, setTerm_ac] = useState(props.data.term_ac || null);
  const [inspection_valid_until, setInspection_valid_until] = useState(
    props.data.inspection_valid_until || null
  );
  const [extinguisher_valid_to, setExtinguisher_valid_to] = useState(
    props.data.extinguisher_valid_to || null
  );
  const [comment, setComment] = useState(props.data.description || null);
  const [error, setError] = useState(false);
  const [emissionType, setEmissionType] = useState(
    props.data.emissions || null
  );

  const emissionTypeOptions = [
    { value: null, label: "Wybierz..." },
    { value: 1, label: "Euro 1" },
    { value: 2, label: "Euro 2" },
    { value: 3, label: "Euro 3" },
    { value: 4, label: "Euro 4" },
    { value: 5, label: "Euro 5" },
    { value: 6, label: "Euro 6" },
  ];

  const carTypeOptions = [
    { label: "Wybierz...", value: null },
    { label: "do 3,5t", value: 1 },
    { label: "Solówka", value: 2 },
    { label: "Ciągnik siodłowy", value: 3 },
  ];

  useEffect(() => {
    let tmpTrailerType = props.trailer
      ? props.data.trailer_data?.length &&
        props.typeOptions.filter(
          (e) => e.value === props.data.trailer_data[0].id
        )[0]
      : "";

    setModel(props.data.model);
    setNr_rejestracyjny(props.data.nr_rejestracyjny);
    setVin(props.data.vin);
    setRok_produkcji(props.data.rok_produkcji);
    props.trailer && setType(tmpTrailerType);
    props.data.axle_netto
      ? setAxle_netto(props.data.axle_netto)
      : setAxle_netto(18500);
    setTerm_oc(props.data.term_oc?.slice(0, 10));
    setTerm_ac(props.data.term_ac?.slice(0, 10));
    setInspection_valid_until(props.data.inspection_valid_until?.slice(0, 10));
    setExtinguisher_valid_to(
      props.data.extinguisher_valid_to
        ? moment(props.data.extinguisher_valid_to).format("YYYY-MM-DD")
        : ""
    );
    setComment(props.data.description);
    props.car && setEmissionType(props.data.emissions);
    props.car && setType(props.data.type);
  }, []);

  const isError = (value) => {
    if (error) {
      if (!value) {
        return true;
      }

      if (value === "Wybierz...") {
        return true;
      }
    }

    return false;
  };

  const setCars = () => {
    const fetchUrl = `${DBurl}/setCars/`;

    const data = {
      id: props.edit ? props.data.id : null,
      id_device: null || props.data.id_device,
      id_firmy: props.user.id_firm,
      customer_id: props.user.customer_id,
      name: nr_rejestracyjny,
      nr_rejestracyjny: nr_rejestracyjny,
      model: model,
      rok_produkcji: rok_produkcji,
      car_icon_id: props.trailer
        ? 5
        : !!type
        ? type && carTypeOptions.filter((o) => o.label === type)[0].value
        : 1,
      vin: vin || null,
      axle_netto: axle_netto || null,
      description: comment || null,
      term_oc: term_oc ? `${term_oc} 00:00:00` : null,
      term_ac: term_ac ? `${term_ac} 00:00:00` : null,
      inspection_valid_until: inspection_valid_until
        ? `${inspection_valid_until} 00:00:00`
        : null,
      extinguisher_valid_to: !!extinguisher_valid_to
        ? `${extinguisher_valid_to} 00:00:00`
        : null,
      emissions: props.car ? (emissionType ? emissionType : null) : null,
      id_trailer_type: props.trailer ? type.value : null,
    };

    fetch(fetchUrl, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: props.edit ? "update" : "insert",
        data: data,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          props.setDialog({
            isOpen: true,
            type: "success",
            content: props.edit ? "Zapisano zmiany" : "Pojazd dodany",
          });
          props.refresh();
          setError(false);
          props.setOpen(false);
        }
      });
  };

  const checkError = () => {
    if (props.trailer) {
      if (!nr_rejestracyjny || !model || !type || !axle_netto) {
        return true;
      }

      return false;
    } else {
      if (
        !nr_rejestracyjny ||
        !model ||
        !type ||
        !emissionType ||
        !axle_netto
      ) {
        return true;
      }
      return false;
    }
  };

  const handleSave = () => {
    if (checkError()) {
      setError(true);
      props.setDialog({
        isOpen: true,
        type: "error",
        content: "Uzupełnij wymagane pola",
      });
    } else {
      setError(false);
      setCars();
    }
  };

  return (
    <Dialog
      maxWidth="lg"
      open={props.open}
      TransitionComponent={Transition}
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        className={stylesMod.dialogHead}
      >
        Dodaj pojazd
        <IconButton
          aria-label="close"
          className={stylesMod.closeButton}
          onClick={() => {
            props.setOpen(false);
          }}
        >
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent className={stylesMod.noOverflow}>
        <Grid container spacing={2}>
          {!!props.trailer && (
            <Grid item xs={2}>
              <div className={stylesMod.inp}>
                <label>Wybierz typ*</label>
                <Select
                  value={type}
                  className={`${stylesMod.select} ${
                    error && !type && stylesMod.error
                  }`}
                  options={props.typeOptions}
                  name="typ"
                  placeholder="Wybierz..."
                  onChange={(e) => {
                    setType(e);
                  }}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    control: (base, state) => ({
                      ...base,
                      border: "1px solid #142f42",
                      boxShadow: "none",
                      "&:hover": {
                        border: "1px solid #142f42",
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                      fontSize: "11px",
                    }),
                  }}
                />
              </div>
            </Grid>
          )}
          <Grid item xs={2}>
            <div className={stylesMod.inp}>
              <label>Nr rejestracyjny*</label>
              <Input
                value={nr_rejestracyjny}
                error={error && !nr_rejestracyjny}
                handleChange={(e) => {
                  setNr_rejestracyjny(e.target.value);
                }}
                name="nr_rejestracyjny"
                disabled={!!props.data.id_device && !!nr_rejestracyjny}
              />
            </div>
          </Grid>

          <Grid item xs={2}>
            <div className={stylesMod.inp}>
              <label>Marka model*</label>
              <Input
                value={model}
                error={error && !model}
                handleChange={(e) => {
                  setModel(e.target.value);
                }}
                name="model"
                disabled={!!props.data.id_device && !!model}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={stylesMod.inp}>
              <label>VIN</label>
              <Input
                value={vin}
                handleChange={(e) => {
                  setVin(e.target.value);
                }}
                name="vin"
                disabled={!!props.data.id_device && !!vin}
              />
            </div>
          </Grid>

          <Grid item xs={2}>
            <div className={stylesMod.inp}>
              <label>Rok produkcji</label>
              <Input
                value={rok_produkcji}
                handleChange={(e) => {
                  setRok_produkcji(e.target.value);
                }}
                name="rok_produkcji"
                disabled={!!props.data.id_device && !!rok_produkcji}
              />
            </div>
          </Grid>

          {props.car && (
            <Grid item xs={2}>
              <div className={stylesMod.inp}>
                <label>Typ pojazdu*</label>
                <Select
                  className={`${stylesMod.select} ${
                    isError(type) && stylesMod.error
                  }`}
                  value={
                    type
                      ? carTypeOptions.filter((o) => o.label === type)
                      : carTypeOptions[0]
                  }
                  options={carTypeOptions}
                  name="type"
                  placeholder="Wybierz..."
                  onChange={(e) => {
                    setType(e.label);
                  }}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    control: (base, state) => ({
                      ...base,
                      border: "1px solid #142f42",
                      boxShadow: "none",
                      "&:hover": {
                        border: "1px solid #142f42",
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                      fontSize: "11px",
                    }),
                  }}
                />
              </div>
            </Grid>
          )}

          {props.car && (
            <Grid item xs={2}>
              <div className={stylesMod.inp}>
                <label>Norma emisji spalin*</label>
                <Select
                  className={`${stylesMod.select} ${
                    isError(emissionType) && stylesMod.error
                  }`}
                  options={emissionTypeOptions}
                  value={
                    emissionType
                      ? emissionTypeOptions.filter(
                          (o) => o.value === emissionType
                        )
                      : emissionTypeOptions[0]
                  }
                  name="emissionType"
                  placeholder="Wybierz..."
                  onChange={(e) => {
                    setEmissionType(e.value);
                  }}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    control: (base, state) => ({
                      ...base,
                      border: "1px solid #142f42",
                      boxShadow: "none",
                      "&:hover": {
                        border: "1px solid #142f42",
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                      fontSize: "11px",
                    }),
                  }}
                />
              </div>
            </Grid>
          )}

          <Grid item xs={2}>
            <div className={stylesMod.inp}>
              <label>Masa własna (kg)*</label>
              <Input
                value={axle_netto}
                error={error && !axle_netto}
                type="number"
                handleChange={(e) => {
                  setAxle_netto(e.target.value);
                }}
                name="axle_netto"
                disabled={!!props.data.id_device && !!axle_netto}
              />
            </div>
          </Grid>

          <Grid item xs={2}>
            <div className={stylesMod.inp}>
              <label>Termin OC</label>
              <Input
                value={term_oc}
                type="date"
                handleChange={(e) => {
                  setTerm_oc(e.target.value);
                }}
                name="term_oc"
              />
            </div>
          </Grid>

          <Grid item xs={2}>
            <div className={`${stylesMod.inp} ${stylesMod.start} `}>
              <label>Termin AC</label>
              <Input
                value={term_ac}
                type="date"
                handleChange={(e) => {
                  setTerm_ac(e.target.value);
                }}
                name="term_ac"
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={`${stylesMod.inp} ${stylesMod.start}`}>
              <label>Przegląd ważny do:</label>
              <Input
                value={inspection_valid_until}
                type="date"
                handleChange={(e) => {
                  setInspection_valid_until(e.target.value);
                }}
                name="inspection_valid_until"
              />
            </div>
          </Grid>

          {props.car && (
            <Grid item xs={2}>
              <div className={`${stylesMod.inp} ${stylesMod.start}`}>
                <label>Gaśnica ważna do:</label>
                <Input
                  value={extinguisher_valid_to}
                  type="date"
                  handleChange={(e) => {
                    setExtinguisher_valid_to(e.target.value);
                  }}
                  name="extinguisher_valid_to"
                />
              </div>
            </Grid>
          )}

          <Grid item xs={2}>
            <div className={`${stylesMod.inp} ${stylesMod.start}`}>
              <label>Komentarz</label>
              <Input
                value={comment}
                handleChange={(e) => {
                  setComment(e.target.value);
                }}
                name="comment"
              />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="large"
          color="primary"
          className={`${BTNstyles.btn} ${BTNstyles.err}`}
          onClick={(e) => {
            props.setOpen(false);
          }}
        >
          Zamknij
        </Button>
        <Button
          variant="contained"
          size="large"
          className={`${BTNstyles.btn} ${BTNstyles.grn}`}
          onClick={handleSave}
          color="primary"
        >
          {props.edit ? "Zapisz" : "Dodaj"}{" "}
          <Add className={BTNstyles.downBtnIco} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
