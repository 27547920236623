import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { AddUnit, RemoveUnit } from "./unitAddRemove";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "./input";
import { CopyBtn, DeleteBtn, EditBtn } from "./buttons";
import { DBurl } from "../appConfig";
import Title from "./title";
import styles from "../containers/table.module.css";
import Button from "@material-ui/core/Button";
import Pagination from "./pagination";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import TmsLoadingSpinner from "./TmsLoadingSpinner/TmsLoadingSpinner";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (array?.length > 0) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
}

const headCells = [
  { id: "unit", numeric: false, disablePadding: true, label: "Jednostki" },
];

function EnhancedTableHead(props) {
  const {
    styles,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={styles.tabHead}>
      <TableRow className={styles.w100child}>
        <TableCell className={styles.checkbox}>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all id" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={headCell.class}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={styles.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell className={styles.buttonBoxParent} />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  styles: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function useInput({ type }) {
  const [value, setValue] = useState("");
  const input = (
    <TableCell>
      <Input
        value={value}
        handleChange={(e) => {
          setValue(e.target.value);
        }}
        type={type}
        search={true}
      />
    </TableCell>
  );
  return [value, input];
}

export default function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("short");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchName, setSearchName] = useInput({ type: "text" });
  const [name, setName] = useState("");
  const [unitType, setUnitType] = useState("insert");
  const [unit, setUnit] = useState("");
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [modalDeleteData, setModalDeleteData] = useState([]);
  const [openModalAdd, setOpenModalAdd] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDialog(false);
  };

  const search = (tbl) => {
    if (tbl?.length > 0) {
      return tbl?.filter((row) => {
        return (
          row.name.toString().toLowerCase().indexOf(searchName.toLowerCase()) >
          -1
        );
      });
    }
  };

  const handleClick = (event, name, data) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    setName(data.name);

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (props?.table?.length > 0) {
      if (event.target.checked) {
        const newSelecteds = props?.table.map((n) => n.id);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    } else {
      props.setDialog({
        isOpen: true,
        type: "warning",
        content: "Brak danych do zaznaczenia",
      });
    }
  };

  const paginate = (pageNumber) => setPage(pageNumber - 1);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div className={styles.rootFlex}>
      <div className={styles.tableBox}>
        <Paper className={styles.paper}>
          <Title
            title="Jednostki"
            btnBox
            btnDelete
            btnAdd
            btnAddTxt="Dodaj"
            addFunc={(e) => {
              setUnitType("insert");
              setOpenModalAdd(true);
            }}
            deleteFunc={() => {
              const deleteArray = [];
              selected
                .sort((a, b) => {
                  return b - a;
                })
                .forEach((val) => {
                  props.table.forEach((el, i) => {
                    if (el.id === val) {
                      deleteArray.push(el);
                    }
                  });
                });
              setOpenModalDelete(true);
              setModalDeleteData({
                state: deleteArray,
              });
            }}
          />
          <TableContainer className={styles.unitsTableContainer}>
            <Table
              className={styles.tableSm}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                styles={styles}
                numSelected={selected?.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={props?.table?.length > 0 ? props?.table?.length : 0}
              />
              <TableBody className={styles.tabBody}>
                <TableRow className={styles.w100child}>
                  <TableCell></TableCell>
                  {setSearchName}
                  <TableCell></TableCell>
                </TableRow>
                {stableSort(search(props?.table), getComparator(order, orderBy))
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        className={styles.w100child}
                        hover
                        onClick={(event) => handleClick(event, row.id, row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            color="primary"
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.name}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={styles.buttonBoxParent}
                        >
                          <div className={styles.buttonBox}>
                            <EditBtn
                              title="Edit"
                              handleChange={(e) => {
                                setUnitType("update");
                                setOpenModalAdd(true);
                                setUnit(row);
                              }}
                            />
                            <DeleteBtn
                              handleChange={(e) => {
                                const deleteArray = [{ ...row }];

                                setOpenModalDelete(true);
                                setModalDeleteData({
                                  state: deleteArray,
                                });
                              }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={`${styles.tableBottom} ${styles.fe}`}>
            <div>
              <Pagination
                postsPerPage={rowsPerPage}
                totalPosts={search(props.table)?.length}
                paginate={paginate}
                page={page}
              />
            </div>
          </div>
        </Paper>
      </div>
      <Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={dialogColor}>
          {dialogContent}
        </Alert>
      </Snackbar>
      <AddUnit
        open={openModalAdd}
        setOpen={setOpenModalAdd}
        setSnack={setOpenDialog}
        setSnackContent={setDialogContent}
        setSnackColor={setDialogColor}
        token={props.user.csrf_token}
        fetchUnits={props.fetchUnits}
        type={unitType}
        unit={unit}
      />
      <RemoveUnit
        open={openModalDelete}
        setOpen={setOpenModalDelete}
        setSnack={setOpenDialog}
        setSnackContent={setDialogContent}
        setSnackColor={setDialogColor}
        list={modalDeleteData}
        fetchUnits={props.fetchUnits}
        token={props.user.csrf_token}
      />
    </div>
  );
}
