import React, { useState, useEffect, useContext } from "react";
import { DBurl } from "appConfig";
import { X, ArrowRight, Download, Upload, Plus } from "react-feather";
import {
  Snackbar,
  Button,
  IconButton,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Checkbox,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import stylesMod from "styles/newOrEdit.module.css";
import styles from "containers/table.module.css";
import BTNstyles from "styles/button.module.css";

const TransportFilesModal = ({
  openModalFiles,
  setOpenModalFiles,
  filesArr,
  selectedFilesArr,
  transport,
  setSelectedFilesArr,
  setFiles,
}) => {
  const handleSelectAllClick = (event, table, setSelected) => {
    if (event.target.checked) {
      const newSelecteds = table.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name, selected, setSelected, type) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    if (type === "car") {
      setSelected((prevState) => ({
        ...prevState,
        files_car: newSelected,
      }));
    }
    if (type === "driver1") {
      setSelected((prevState) => ({
        ...prevState,
        files_driver1: newSelected,
      }));
    }
    if (type === "driver2") {
      setSelected((prevState) => ({
        ...prevState,
        files_driver2: newSelected,
      }));
    }
    if (type === "trailer") {
      setSelected((prevState) => ({
        ...prevState,
        files_trailer: newSelected,
      }));
    }
  };

  const isSelected = (name, selected) => selected?.indexOf(name) !== -1;

  const addFiles = () => {
    const addToFiles = (selectedFiles, filesArr, kind) => {
      if (selectedFiles.length > 0) {
        let tempCarSelectedFiles = selectedFiles;
        let tmsFilesCar = filesArr;
        for (let i = 0; tempCarSelectedFiles.length > i; i++) {
          let isAdded = transport.files.find(
            (obj) =>
              obj.id === tempCarSelectedFiles[i] ||
              obj.ref_file_id === tempCarSelectedFiles[i]
          );
          if (!!isAdded === false) {
            let tmp = tmsFilesCar.filter((o) => {
              return o.id === tempCarSelectedFiles[i];
            });
            let tmpToSend = tmp.map((o) => {
              return {
                name: o.filename,
                kind: kind,
                type: o.type,
                ref_file_id: o.id,
                col_name: o.col_name,
              };
            });
            let allFiles = [];
            allFiles.push(...tmpToSend);
            setFiles((files) => [...files, tmpToSend[0]]);
          }
        }
      }
    };

    selectedFilesArr.files_car &&
      addToFiles(selectedFilesArr.files_car, filesArr.files_car, "car");
    selectedFilesArr.files_driver1 &&
      addToFiles(
        selectedFilesArr.files_driver1,
        filesArr.files_driver1,
        "driver1"
      );
    selectedFilesArr.files_driver2 &&
      addToFiles(
        selectedFilesArr.files_driver2,
        filesArr.files_driver2,
        "driver2"
      );
    selectedFilesArr.files_trailer &&
      addToFiles(
        selectedFilesArr.files_trailer,
        filesArr.files_trailer,
        "trailer"
      );
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xl"
      open={openModalFiles}
      onClose={() => {
        setOpenModalFiles(false);
        // cleanFiles()
      }}
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        className={stylesMod.dialogHead}
      >
        Podepnij dokumenty z bazy TMS
        <IconButton
          aria-label="close"
          className={stylesMod.closeButton}
          onClick={() => {
            setOpenModalFiles(false);
            // cleanFiles()
          }}
        >
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={3} className={styles.tableBox_border}>
            <h5>Dokumenty pojazdu</h5>
            {filesArr.files_car &&
            filesArr.files_car !== null &&
            filesArr.files_car.length > 0 ? (
              <TableContainer className={styles.stickyTable}>
                <Table
                  className={styles.modalTable}
                  stickyHeader
                  aria-labelledby="Dokumenty pojazdu"
                  size="small"
                  aria-label="enhanced table"
                >
                  <TableHead className={styles.tabHead}>
                    <TableRow>
                      <TableCell
                        className={`${styles.doc_checkbox} ${styles.cell_modalTable}`}
                      >
                        <Checkbox
                          indeterminate={
                            selectedFilesArr.files_car?.length > 0 &&
                            selectedFilesArr.files_car?.length <
                              filesArr.files_car?.length
                          }
                          checked={
                            filesArr.files_car?.length > 0 &&
                            selectedFilesArr.files_car?.length ===
                              filesArr.files_car?.length
                          }
                          onChange={(e) =>
                            handleSelectAllClick(
                              e,
                              filesArr?.files_car,
                              setSelectedFilesArr
                            )
                          }
                          inputProps={{ "aria-label": "select all id" }}
                        />
                      </TableCell>

                      <TableCell
                        className={styles.cell_modalTable}
                        style={{ padding: "0" }}
                      >
                        Nazwa pliku
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filesArr.files_car.map((row, i) => {
                      const isItemSelected = isSelected(
                        row.id,
                        selectedFilesArr.files_car
                      );
                      const labelId = `enhanced-table-checkbox-${i}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) =>
                            handleClick(
                              event,
                              row.id,
                              selectedFilesArr.files_car,
                              setSelectedFilesArr,
                              "car"
                            )
                          }
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell className={styles.doc_checkbox}>
                            <Checkbox
                              checked={isItemSelected}
                              color="primary"
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                          <TableCell
                            component="td"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.col_name}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div className={styles.noData}>Brak plików..</div>
            )}
          </Grid>

          <Grid item xs={3} className={styles.tableBox_border}>
            <h5>Dokumenty kierowcy 1</h5>
            {filesArr.files_driver1 &&
            filesArr.files_driver1 !== null &&
            filesArr.files_driver1.length > 0 ? (
              <TableContainer className={styles.stickyTable}>
                <Table
                  className={styles.modalTable}
                  stickyHeader
                  aria-labelledby="Dokumenty pojazdu"
                  size="small"
                  aria-label="enhanced table"
                >
                  <TableHead className={styles.tabHead}>
                    <TableRow>
                      <TableCell
                        className={`${styles.doc_checkbox} ${styles.cell_modalTable}`}
                      >
                        <Checkbox
                          indeterminate={
                            selectedFilesArr?.files_driver1.length > 0 &&
                            selectedFilesArr?.files_driver1.length <
                              filesArr?.files_driver1.length
                          }
                          checked={
                            filesArr?.files_driver1.length > 0 &&
                            selectedFilesArr.files_driver1.length ===
                              filesArr.files_driver1.length
                          }
                          onChange={(e) =>
                            handleSelectAllClick(
                              e,
                              filesArr.files_driver1,
                              setSelectedFilesArr
                            )
                          }
                          inputProps={{ "aria-label": "select all id" }}
                        />
                      </TableCell>

                      <TableCell
                        className={styles.cell_modalTable}
                        style={{ padding: "0" }}
                      >
                        Nazwa pliku
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filesArr.files_driver1.map((row, i) => {
                      const isItemSelected = isSelected(
                        row.id,
                        selectedFilesArr.files_driver1
                      );
                      const labelId = `enhanced-table-checkbox-${i}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) =>
                            handleClick(
                              event,
                              row.id,
                              selectedFilesArr.files_driver1,
                              setSelectedFilesArr,
                              "driver1"
                            )
                          }
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell className={styles.doc_checkbox}>
                            <Checkbox
                              checked={isItemSelected}
                              color="primary"
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                          <TableCell
                            component="td"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.col_name}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div className={styles.noData}>Brak plików..</div>
            )}
          </Grid>

          <Grid item xs={3} className={styles.tableBox_border}>
            <h5>Dokumenty kierowcy 2</h5>
            {filesArr.files_driver2 &&
            filesArr.files_driver2 !== null &&
            filesArr.files_driver2.length > 0 ? (
              <TableContainer className={styles.stickyTable}>
                <Table
                  className={styles.modalTable}
                  stickyHeader
                  aria-labelledby="Dokumenty pojazdu"
                  size="small"
                  aria-label="enhanced table"
                >
                  <TableHead className={styles.tabHead}>
                    <TableRow>
                      <TableCell
                        className={`${styles.doc_checkbox} ${styles.cell_modalTable}`}
                      >
                        <Checkbox
                          indeterminate={
                            selectedFilesArr.files_driver2.length > 0 &&
                            selectedFilesArr.files_driver2.length <
                              filesArr.files_driver2.length
                          }
                          checked={
                            filesArr.files_driver2.length > 0 &&
                            selectedFilesArr.files_driver2.length ===
                              filesArr.files_driver2.length
                          }
                          onChange={(e) =>
                            handleSelectAllClick(
                              e,
                              filesArr.files_driver2,
                              setSelectedFilesArr
                            )
                          }
                          inputProps={{ "aria-label": "select all id" }}
                        />
                      </TableCell>

                      <TableCell
                        className={styles.cell_modalTable}
                        style={{ padding: "0" }}
                      >
                        Nazwa pliku
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filesArr.files_driver2.map((row, i) => {
                      const isItemSelected = isSelected(
                        row.id,
                        selectedFilesArr.files_driver1
                      );
                      const labelId = `enhanced-table-checkbox-${i}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) =>
                            handleClick(
                              event,
                              row.id,
                              selectedFilesArr.files_driver2,
                              setSelectedFilesArr,
                              "driver2"
                            )
                          }
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell className={styles.doc_checkbox}>
                            <Checkbox
                              checked={isItemSelected}
                              color="primary"
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                          <TableCell
                            component="td"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.col_name}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div className={styles.noData}>Brak plików..</div>
            )}
          </Grid>

          <Grid item xs={3} className={styles.tableBox_border}>
            <h5>Dokumenty naczepy</h5>
            {filesArr.files_trailer &&
            filesArr.files_trailer !== null &&
            filesArr.files_trailer.length > 0 ? (
              <TableContainer className={styles.stickyTable}>
                <Table
                  className={styles.modalTable}
                  stickyHeader
                  aria-labelledby="Dokumenty pojazdu"
                  size="small"
                  aria-label="enhanced table"
                >
                  <TableHead className={styles.tabHead}>
                    <TableRow>
                      <TableCell
                        className={`${styles.doc_checkbox} ${styles.cell_modalTable}`}
                      >
                        <Checkbox
                          indeterminate={
                            selectedFilesArr.files_trailer.length > 0 &&
                            selectedFilesArr.files_trailer.length <
                              filesArr.files_trailer.length
                          }
                          checked={
                            filesArr.files_trailer.length > 0 &&
                            selectedFilesArr.files_trailer.length ===
                              filesArr.files_trailer.length
                          }
                          onChange={(e) =>
                            handleSelectAllClick(
                              e,
                              filesArr.files_trailer,
                              setSelectedFilesArr
                            )
                          }
                          inputProps={{ "aria-label": "select all id" }}
                        />
                      </TableCell>

                      <TableCell
                        className={styles.cell_modalTable}
                        style={{ padding: "0" }}
                      >
                        Nazwa pliku
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filesArr.files_trailer.map((row, i) => {
                      const isItemSelected = isSelected(
                        row.id,
                        selectedFilesArr.files_trailer
                      );
                      const labelId = `enhanced-table-checkbox-${i}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) =>
                            handleClick(
                              event,
                              row.id,
                              selectedFilesArr.files_trailer,
                              setSelectedFilesArr,
                              "trailer"
                            )
                          }
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell className={styles.doc_checkbox}>
                            <Checkbox
                              checked={isItemSelected}
                              color="primary"
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                          <TableCell
                            component="td"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.col_name}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div className={styles.noData}>Brak plików..</div>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="large"
          color="primary"
          className={`${BTNstyles.btn} ${BTNstyles.err}`}
          onClick={(e) => {
            setOpenModalFiles(false);
          }}
        >
          Anuluj <X className={BTNstyles.downBtnIco} />
        </Button>
        <Button
          variant="contained"
          size="large"
          color="primary"
          className={BTNstyles.btn}
          onClick={(e) => {
            setOpenModalFiles(false);
            addFiles();
          }}
        >
          Dodaj pliki <Upload className={BTNstyles.downBtnIco} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransportFilesModal;
