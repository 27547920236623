import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import stylesMod from "../../styles/newOrEdit.module.css";
import Input from "../../components/input";
import { Switch, IconButton, FormControlLabel } from "@material-ui/core";
import Select from "react-select";
import { DBurl } from "../../appConfig";
import BTNstyles from "../../styles/button.module.css";
import { Add } from "@material-ui/icons";
import { X } from "react-feather";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let options_permissions = [];

export default function AddUser(props) {
  const [error, setError] = useState(false);

  const [firm, setFirm] = useState({});
  const [customer, setCustomer] = useState({});

  const [login, setLogin] = useState(props.data?.login || "");
  const [password, setPassword] = useState(props.data?.password || "");
  const [firstname, setFirstname] = useState(props.data?.firstname || "");
  const [email, setEmail] = useState(props.data?.email || "");
  const [surname, setSurname] = useState(props.data?.surname);
  const [adm, setAdm] = useState(props.data?.adm || "");
  const [permissions, setPermissions] = useState([
    2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
    23, 24, 25, 26, 27, 28,
  ]);
  const [newOrderAutoNum, setNewOrderAutoNum] = useState(
    props.data?.is_automatic_order_number || true
  );
  const [newOrderSufix, setNewOrderSufix] = useState(
    props.data?.sufix_order_name || ""
  );

  useEffect(() => {
    if (props.data?.login) {
      setPassword(props.data.name);
      setLogin(props.data.login);
      setFirstname(props.data.firstname);
      setEmail(props.data.email);
      setSurname(props.data.surname);
      setAdm({ label: props.checkAdm(props.data.adm), value: props.data.adm });
      setFirm(props.checkFirm(props.data.id_firm));
      setCustomer(props.checkCustomer(props.data.id_customer));
      getPermissions(props.data?.id);
      setNewOrderAutoNum(props.data?.is_automatic_order_number);
      setNewOrderSufix(props.data?.sufix_order_name);
    }
  }, [props.data.id]);

  const clear = () => {
    setPassword("");
    setLogin("");
    setFirstname("");
    setEmail("");
    setSurname("");
    setAdm("");
    setFirm({});
    setCustomer({});
    setNewOrderAutoNum(true);
  };

  const fetchSetPermissions = async (id) => {
    const response = await fetch(`${DBurl}/setUsersPermissions`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "insert",
        data: {
          id_user: id,
          permission_ids: permissions.sort((a, b) => a - b),
        },
      }),
    });
    const json = await response.json();
    if (json.success) {
      getPermissions(id);
    }
  };

  const getPermissions = async (id) => {
    const response = await fetch(`${DBurl}/getUsersPermissions?id_user=${id}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();

    if (json.success) {
      setPermissions(json.data.map((e) => e.id));
    } else {
      props.setOpenSnack(true);
      props.setSnackContent(
        `Błąd podczas pobierania danych o uprawnieniach: Błąd ${json.response} - ${json.responseDescription}`
      );
      props.setSnackColor("error");
    }
  };

  //ustawienie odpowiednich opcji selecta - "Uprawnienia" względem uprawnień zalogowanego uzytkownika
  switch (props.user.adm) {
    case 999:
      options_permissions = [
        {
          label: "Użytkownik",
          value: null,
        },
        {
          label: "Admin Operatora",
          value: 1,
        },
        {
          label: "Admin Firmy",
          value: 9,
        },
        {
          label: "Super Admin",
          value: 999,
        },
      ];
      break;
    case 9:
      options_permissions = [
        {
          label: "Użytkownik",
          value: null,
        },
        {
          label: "Admin Operatora",
          value: 1,
        },
      ];
      break;
    case 1:
      options_permissions = [
        {
          label: "Użytkownik",
          value: null,
        },
      ];
      break;
    default:
      options_permissions = [];
  }

  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      open={props.open}
      // className={stylesMod.noOverflowGrand}
      TransitionComponent={Transition}
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        className={stylesMod.dialogHead}
      >
        {props.edit ? "Edytuj użytkownika" : "Dodaj użytkownika"}{" "}
        <IconButton
          aria-label="close"
          className={stylesMod.closeButton}
          onClick={() => {
            props.setOpen(false);
          }}
        >
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent className={stylesMod.noOverflow}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <div className={stylesMod.inp}>
              <label>Firma obsługująca</label>
              <Select
                value={firm}
                className={`${stylesMod.select} ${
                  error && !firm && stylesMod.error
                }`}
                options={props.firms}
                onChange={(e) => {
                  setFirm(e);
                  setCustomer({ label: "Wybierz firmę..." });
                }}
                name="firm"
                placeholder="Wybierz firmę..."
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused && "#142f42",
                    color: state.isFocused && "#fff",
                  }),
                }}
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={stylesMod.inp}>
              <label>Operator</label>
              <Select
                value={customer}
                className={`${stylesMod.select} ${
                  error && !firm && stylesMod.error
                }`}
                options={props.customers.filter((e) => e.id_firm === firm?.id)}
                onChange={(e) => {
                  setCustomer(e);
                }}
                name="firm"
                placeholder="Wybierz firmę..."
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused && "#142f42",
                    color: state.isFocused && "#fff",
                  }),
                }}
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={`${stylesMod.inp}`}>
              <label>Login</label>
              <Input
                value={login}
                error={!login && error}
                handleChange={(e) => {
                  setLogin(e.target.value);
                }}
                name="login"
              />
            </div>
          </Grid>
          {!props.edit && (
            <Grid item xs={3}>
              <div className={`${stylesMod.inp}`}>
                <label>Hasło</label>
                <Input
                  value={password}
                  error={!password && error}
                  handleChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  name="password"
                />
              </div>
            </Grid>
          )}
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={3}>
            <div className={`${stylesMod.inp}`}>
              <label>Imię</label>
              <Input
                value={firstname}
                error={!firstname && error}
                handleChange={(e) => {
                  setFirstname(e.target.value);
                }}
                name="firstname"
              />
            </div>
          </Grid>

          <Grid item xs={3}>
            <div className={`${stylesMod.inp}`}>
              <label>Nazwisko</label>
              <Input
                value={surname}
                error={!surname && error}
                handleChange={(e) => {
                  setSurname(e.target.value);
                }}
                name="surname"
              />
            </div>
          </Grid>

          <Grid item xs={3}>
            <div className={`${stylesMod.inp}`}>
              <label>Email</label>
              <Input
                value={email}
                error={!email && error}
                handleChange={(e) => {
                  setEmail(e.target.value);
                }}
                name="email"
              />
            </div>
          </Grid>

          <Grid item xs={3}>
            <div className={stylesMod.inp}>
              <label>Uprawnienia</label>
              <Select
                value={adm}
                className={`${stylesMod.select} ${
                  error && !firm && stylesMod.error
                }`}
                options={options_permissions}
                onChange={(e) => {
                  setAdm(e);
                }}
                name="adm"
                placeholder="Poziom uprawnień"
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused && "#142f42",
                    color: state.isFocused && "#fff",
                  }),
                }}
              />
            </div>
          </Grid>
          <Grid item xs={3} className={stylesMod.columnCenter}>
            <div className={stylesMod.spacer}></div>
            <FormControlLabel
              className={stylesMod.smallerFont}
              control={
                <Switch
                  checked={newOrderAutoNum}
                  onChange={(e) => {
                    setNewOrderAutoNum(e.target.checked);
                  }}
                />
              }
              label="Automatyczna numeracja zleceń"
            />
          </Grid>
          {newOrderAutoNum && (
            <Grid item xs={3}>
              <div className={`${stylesMod.inp}`}>
                <label>Znacznik do numeracji zleceń</label>
                <Input
                  value={newOrderSufix}
                  error={newOrderAutoNum && !newOrderSufix && error}
                  handleChange={(e) => {
                    setNewOrderSufix(e.target.value);
                  }}
                  name="newOrderSufix"
                />
              </div>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} className={stylesMod.mt20}>
            <h2 className={stylesMod.title}>Dostęp do zakładek:</h2>
          </Grid>
          <Grid item xs={3} className={stylesMod.column}>
            <h6 className={stylesMod.mt10}>Baza klientów</h6>
            <FormControlLabel
              className={stylesMod.smallerFont}
              control={
                <Switch
                  checked={permissions.includes(2)}
                  onChange={(e) => {
                    setPermissions(
                      permissions.includes(2)
                        ? permissions.filter((el) => el !== 2)
                        : [...permissions, 2]
                    );
                  }}
                />
              }
              label="Lista klientów"
            />

            <FormControlLabel
              className={stylesMod.smallerFont}
              control={
                <Switch
                  checked={permissions.includes(3)}
                  onChange={(e) => {
                    setPermissions(
                      permissions.includes(3)
                        ? permissions.filter((el) => el !== 3)
                        : [...permissions, 3]
                    );
                  }}
                />
              }
              label="Dodaj klienta"
            />

            <FormControlLabel
              className={stylesMod.smallerFont}
              control={
                <Switch
                  checked={permissions.includes(4)}
                  onChange={(e) => {
                    setPermissions(
                      permissions.includes(4)
                        ? permissions.filter((el) => el !== 4)
                        : [...permissions, 4]
                    );
                  }}
                />
              }
              label="Magazyny"
            />

            <FormControlLabel
              className={stylesMod.smallerFont}
              control={
                <Switch
                  checked={permissions.includes(5)}
                  onChange={(e) => {
                    setPermissions(
                      permissions.includes(5)
                        ? permissions.filter((el) => el !== 5)
                        : [...permissions, 5]
                    );
                  }}
                />
              }
              label="Towary"
            />
            <h6 className={stylesMod.mt10}>Zlecenia</h6>
            <FormControlLabel
              className={stylesMod.smallerFont}
              control={
                <Switch
                  checked={permissions.includes(6)}
                  onChange={(e) => {
                    setPermissions(
                      permissions.includes(6)
                        ? permissions.filter((el) => el !== 6)
                        : [...permissions, 6]
                    );
                  }}
                />
              }
              label="Lista zleceń"
            />

            <FormControlLabel
              className={stylesMod.smallerFont}
              control={
                <Switch
                  checked={permissions.includes(7)}
                  onChange={(e) => {
                    setPermissions(
                      permissions.includes(7)
                        ? permissions.filter((el) => el !== 7)
                        : [...permissions, 7]
                    );
                  }}
                />
              }
              label="Stwórz zlecenie"
            />

            <FormControlLabel
              className={stylesMod.smallerFont}
              control={
                <Switch
                  checked={permissions.includes(8)}
                  onChange={(e) => {
                    setPermissions(
                      permissions.includes(8)
                        ? permissions.filter((el) => el !== 8)
                        : [...permissions, 8]
                    );
                  }}
                />
              }
              label="Harmonogram"
            />
          </Grid>

          <Grid item xs={3} className={stylesMod.column}>
            <h6 className={stylesMod.mt10}>Flota</h6>
            <FormControlLabel
              className={stylesMod.smallerFont}
              control={
                <Switch
                  checked={permissions.includes(9)}
                  onChange={(e) => {
                    setPermissions(
                      permissions.includes(9)
                        ? permissions.filter((el) => el !== 9)
                        : [...permissions, 9]
                    );
                  }}
                />
              }
              label="Mapa"
            />

            <FormControlLabel
              className={stylesMod.smallerFont}
              control={
                <Switch
                  checked={permissions.includes(10)}
                  onChange={(e) => {
                    setPermissions(
                      permissions.includes(10)
                        ? permissions.filter((el) => el !== 10)
                        : [...permissions, 10]
                    );
                  }}
                />
              }
              label="Eksploatacja"
            />

            <FormControlLabel
              className={stylesMod.smallerFont}
              control={
                <Switch
                  checked={permissions.includes(11)}
                  onChange={(e) => {
                    setPermissions(
                      permissions.includes(11)
                        ? permissions.filter((el) => el !== 11)
                        : [...permissions, 11]
                    );
                  }}
                />
              }
              label="Stan Floty"
            />

            <FormControlLabel
              className={stylesMod.smallerFont}
              control={
                <Switch
                  checked={permissions.includes(12)}
                  onChange={(e) => {
                    setPermissions(
                      permissions.includes(12)
                        ? permissions.filter((el) => el !== 12)
                        : [...permissions, 12]
                    );
                  }}
                />
              }
              label="Zestaw transportowy"
            />

            <FormControlLabel
              className={stylesMod.smallerFont}
              control={
                <Switch
                  checked={permissions.includes(13)}
                  onChange={(e) => {
                    setPermissions(
                      permissions.includes(13)
                        ? permissions.filter((el) => el !== 13)
                        : [...permissions, 13]
                    );
                  }}
                />
              }
              label="Czas pracy"
            />
          </Grid>
          <Grid item xs={3} className={stylesMod.column}>
            <h6 className={stylesMod.mt10}>Księgowość</h6>
            <FormControlLabel
              className={stylesMod.smallerFont}
              control={
                <Switch
                  checked={permissions.includes(14)}
                  onChange={(e) => {
                    setPermissions(
                      permissions.includes(14)
                        ? permissions.filter((el) => el !== 14)
                        : [...permissions, 14]
                    );
                  }}
                />
              }
              label="Wystaw fakturę"
            />

            <FormControlLabel
              className={stylesMod.smallerFont}
              control={
                <Switch
                  checked={permissions.includes(15)}
                  onChange={(e) => {
                    setPermissions(
                      permissions.includes(15)
                        ? permissions.filter((el) => el !== 15)
                        : [...permissions, 15]
                    );
                  }}
                />
              }
              label="Lista faktur"
            />

            <FormControlLabel
              className={stylesMod.smallerFont}
              control={
                <Switch
                  checked={permissions.includes(16)}
                  onChange={(e) => {
                    setPermissions(
                      permissions.includes(16)
                        ? permissions.filter((el) => el !== 16)
                        : [...permissions, 16]
                    );
                  }}
                />
              }
              label="Kontrahent"
            />

            <FormControlLabel
              className={stylesMod.smallerFont}
              control={
                <Switch
                  checked={permissions.includes(17)}
                  onChange={(e) => {
                    setPermissions(
                      permissions.includes(17)
                        ? permissions.filter((el) => el !== 17)
                        : [...permissions, 17]
                    );
                  }}
                />
              }
              label="Koszty"
            />
            <FormControlLabel
              className={stylesMod.smallerFont}
              control={
                <Switch
                  checked={permissions.includes(18)}
                  onChange={(e) => {
                    setPermissions(
                      permissions.includes(18)
                        ? permissions.filter((el) => el !== 18)
                        : [...permissions, 18]
                    );
                  }}
                />
              }
              label="Zakupy"
            />
            <FormControlLabel
              className={stylesMod.smallerFont}
              control={
                <Switch
                  checked={permissions.includes(19)}
                  onChange={(e) => {
                    setPermissions(
                      permissions.includes(19)
                        ? permissions.filter((el) => el !== 19)
                        : [...permissions, 19]
                    );
                  }}
                />
              }
              label="Oddziały"
            />
            <FormControlLabel
              className={stylesMod.smallerFont}
              control={
                <Switch
                  checked={permissions.includes(20)}
                  onChange={(e) => {
                    setPermissions(
                      permissions.includes(20)
                        ? permissions.filter((el) => el !== 20)
                        : [...permissions, 20]
                    );
                  }}
                />
              }
              label="Ustawienia księgowe"
            />

            <h6 className={stylesMod.mt10}>Raporty</h6>
            <FormControlLabel
              className={stylesMod.smallerFont}
              control={
                <Switch
                  checked={permissions.includes(21)}
                  onChange={(e) => {
                    setPermissions(
                      permissions.includes(21)
                        ? permissions.filter((el) => el !== 21)
                        : [...permissions, 21]
                    );
                  }}
                />
              }
              label="Raporty"
            />
          </Grid>

          <Grid item xs={3} className={stylesMod.column}>
            <h6 className={stylesMod.mt10}>Komunikacja</h6>
            <FormControlLabel
              className={stylesMod.smallerFont}
              control={
                <Switch
                  checked={permissions.includes(22)}
                  onChange={(e) => {
                    setPermissions(
                      permissions.includes(22)
                        ? permissions.filter((el) => el !== 22)
                        : [...permissions, 22]
                    );
                  }}
                />
              }
              label="Komunikacja"
            />
            <h6 className={stylesMod.mt10}>Konfiguracja</h6>
            <FormControlLabel
              className={stylesMod.smallerFont}
              control={
                <Switch
                  checked={permissions.includes(23)}
                  onChange={(e) => {
                    setPermissions(
                      permissions.includes(23)
                        ? permissions.filter((el) => el !== 23)
                        : [...permissions, 23]
                    );
                  }}
                />
              }
              label="Kierowcy"
            />

            <FormControlLabel
              className={stylesMod.smallerFont}
              control={
                <Switch
                  checked={permissions.includes(24)}
                  onChange={(e) => {
                    setPermissions(
                      permissions.includes(24)
                        ? permissions.filter((el) => el !== 24)
                        : [...permissions, 24]
                    );
                  }}
                />
              }
              label="Pojazdy"
            />

            <FormControlLabel
              className={stylesMod.smallerFont}
              control={
                <Switch
                  checked={permissions.includes(25)}
                  onChange={(e) => {
                    setPermissions(
                      permissions.includes(25)
                        ? permissions.filter((el) => el !== 25)
                        : [...permissions, 25]
                    );
                  }}
                />
              }
              label="Naczepy"
            />

            <FormControlLabel
              className={stylesMod.smallerFont}
              control={
                <Switch
                  checked={permissions.includes(26)}
                  onChange={(e) => {
                    setPermissions(
                      permissions.includes(26)
                        ? permissions.filter((el) => el !== 26)
                        : [...permissions, 26]
                    );
                  }}
                />
              }
              label="Użytkownicy"
            />

            <FormControlLabel
              className={stylesMod.smallerFont}
              control={
                <Switch
                  checked={permissions.includes(27)}
                  onChange={(e) => {
                    setPermissions(
                      permissions.includes(27)
                        ? permissions.filter((el) => el !== 27)
                        : [...permissions, 27]
                    );
                  }}
                />
              }
              label="Firmy"
            />

            <FormControlLabel
              className={stylesMod.smallerFont}
              control={
                <Switch
                  checked={permissions.includes(28)}
                  onChange={(e) => {
                    setPermissions(
                      permissions.includes(28)
                        ? permissions.filter((el) => el !== 28)
                        : [...permissions, 28]
                    );
                  }}
                />
              }
              label="Operatorzy"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="large"
          color="primary"
          className={`${BTNstyles.btn} ${BTNstyles.err}`}
          onClick={() => {
            clear();
            props.setOpen(false);
          }}
        >
          Anuluj
        </Button>
        <Button
          variant="contained"
          size="large"
          className={`${BTNstyles.btn} ${BTNstyles.grn}`}
          onClick={(e) => {
            if (!!firstname && !!surname && !!adm && !!email && !!login) {
              const fetchUrl = `${DBurl}/setUsers`;
              const data = {
                password: password,
                login: login,
                email: email,
                firstname: firstname,
                surname: surname,
                adm: adm.value,
                id_firm: firm?.value,
                id_customer: customer?.value,
                id: props.edit ? props.data.id : null,
                permissions_ids: permissions,
                status: 0, // nieużywane
                is_automatic_order_number: newOrderAutoNum,
                sufix_order_name: newOrderAutoNum ? newOrderSufix : "",
              };
              fetch(fetchUrl, {
                method: "post",
                credentials: "include",
                headers: {
                  "Content-Type": "application/json",
                  "X-CSRF-Token": props.user.csrf_token,
                },
                body: JSON.stringify({
                  mode: props.edit ? "update" : "insert",
                  data: data,
                }),
              })
                .then((res) => res.json())
                .then((data) => {
                  if (data.success) {
                    if (
                      data.data.return === "The same email/login exists in db"
                    ) {
                      props.setOpenSnack(true);
                      props.setSnackColor("error");
                      props.setSnackContent("Email lub Login już istnieje");
                    } else {
                      props.setOpenSnack(true);
                      props.setSnackColor("success");
                      props.setSnackContent(
                        props.edit ? "Zapisano zmiany" : "Użytkownik dodany"
                      );
                      fetchSetPermissions(
                        props.edit ? props.data.id : data.data.new_id
                      );
                      props.refresh();
                      clear();
                      props.setOpen(false);
                    }
                  } else if (data.response === "401") {
                    props.setOpenSnack(true);
                    props.setSnackColor("error");
                    props.setSnackContent(
                      "Brak uprawnień. Skontatkuj się z administratorem"
                    );
                  } else {
                    props.setOpenSnack(true);
                    props.setSnackColor("error");
                    props.setSnackContent(`Błąd: ${data.responseDescription}`);
                  }
                });
            } else {
              props.setSnackContent("Uzupełnij wymagane pola");
              props.setSnackColor("error");
              props.setOpenSnack(true);
              setError(true);
            }
          }}
          color="primary"
        >
          {props.edit ? "Zapisz" : "Dodaj"}{" "}
          <Add className={BTNstyles.downBtnIco} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
