import React, { useState, useEffect, useRef } from 'react';
import Input from '../../components/input'
import Title from '../../components/title'
import styles from '../table.module.css'
import RowsPerPage from '../../components/RowsPerPage'
import Pagination from '../../components/pagination'
import { DBurl } from '../../appConfig'
import { Button, Table, TableBody, TableCell, TableContainer, IconButton, TableHead, TableRow, TableSortLabel, Paper, Checkbox, Slide, Snackbar, Dialog, 
	DialogActions, DialogContent, DialogContentText, DialogTitl, DialogTitle, Tooltip, Grid, Switch, FormControlLabel } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import docsStyles from '../../styles/documents.module.css'
import { handleRenderAction, handleRenderColumn, handleRenderCategory, handleRenderValues } from './changesHistoryHelpers'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const headCells = [
	{ id: 'changeType', numeric: true, disablePadding: false, label: 'Akcja' , class: docsStyles.headCell},
	{ id: 'changeLoc', numeric: true, disablePadding: false, label: 'Widok'},
	{ id: 'changeName', numeric: true, disablePadding: false, label: 'Dotyczy'},
	{ id: 'changeCol', numeric: true, disablePadding: false, label: 'Kolumna', class:docsStyles.historyCell},
	{ id: 'changeVal', numeric: true, disablePadding: false, label: 'Wartość / Plik' },
	{ id: 'userName', numeric: true, disablePadding: false, label: 'Użytkownik' },
	{ id: 'userCompany', numeric: true, disablePadding: false, label: 'Firma' },
	{ id: 'changeTime', numeric: true, disablePadding: false, label: 'Kiedy' },
];

function useInput({ type }) {
	const [value, setValue] = useState("")
	const input = <Input value={value} handleChange={e => {
		setValue(e.target.value)
	}
	} type={type}
		search={true}
		key={'searchRegNum'}
		autocomplete='off' />;
	return [value, input];
}

const ChangesHistory = (props) => {
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('car');
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(15);
	const [table, setTable] = useState([])

	const [searchRegNum, setSearchRegNum] = useInput({ type: "text" });
	const [searchLogin, setSearchLogin] = useInput({ type: "text" });
	const [searchDate, setSearchDate] = useInput({ type: "text" });

	const [openDialog, setOpenDialog] = useState(false)
	const [dialogContent, setDialogContent] = useState("")
	const [dialogColor, setDialogColor] = useState("error")


    function EnhancedTableHead(props) {
        const { styles, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    
        return (
            <TableHead className={styles.tabHead}>
                <TableRow>
                    {headCells.map((headCell) => {
						return (
                        <TableCell
                            key={headCell.id}
                            align="left"
                            padding={headCell.disablePadding ? 'none' : 'default'}
                            sortDirection={orderBy === headCell.id ? order : false}
                            className={headCell.class}
                        >
                                {headCell.label}
                        </TableCell>
                    )})}
                </TableRow>
            </TableHead>
        );
    }

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenDialog(false)
	}

	const fetchHistory = async (token) => {
		props.loading(true)
        const response = await fetch(`${DBurl}/getDocumentChangeHistory`, {
            method: "GET",
            credentials: 'include',
            headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": token
            }
            });
		const json = await response.json()
		if (json.success) {
			setTable(json.data)
			props.loading(false)
		}
	}

	useEffect(() => {
		fetchHistory(props.user.csrf_token)
	}, [])

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};	

	// const search = (tbl) => {
	// 	const ret =  tbl.filter((row) => {
	// 		return (
	// 			row.what?.toLowerCase().includes(searchRegNum.toLowerCase())
	// 		)
	// 	})
	// 	return ret
	// }

	const search = (tbl) => {
		const ret =  tbl.filter((row) => {
			return (
				handleRenderAction(row)?.toLowerCase().includes(searchRegNum.toLowerCase()) &&
				row.login.toLowerCase().includes(searchLogin.toLowerCase()) &&
				row.ts.toLowerCase().includes(searchDate.toLowerCase())
			)
		})
		return ret
	}

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = table.map((n) => n.id);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		setSelected(newSelected);
	};
	const paginate = pageNumber => setPage(pageNumber - 1);
	const isSelected = (name) => selected.indexOf(name) !== -1;


	const emptyRows = rowsPerPage - Math.min(rowsPerPage, table.length - page * rowsPerPage);

	return (
		<>
			<div className={styles.root}>
				<Paper className={styles.paper}>
					<Title title={props.state.tabName}/>
					<TableContainer className={styles.stickyTable}>
						<Table
							className={styles.table}
							aria-labelledby="tableTitle"
							size="small"
							aria-label="enhanced table"
						>

							<EnhancedTableHead
								styles={styles}
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onSelectAllClick={handleSelectAllClick}
								onRequestSort={handleRequestSort}
								rowCount={table.length}
							/>
							<TableBody className={styles.tabBody}>
								<TableRow>
									<TableCell className={docsStyles.headCell}>{setSearchRegNum}</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>{setSearchLogin}</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>{setSearchDate}</TableCell>
								</TableRow>
								{search(table).sort((a,b) => b.id - a.id).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => {
										const isItemSelected = isSelected(row.id);
										const labelId = `enhanced-table-checkbox-${index}`;
										return (<>
											<TableRow
												hover
												onClick={(event) => handleClick(event, row.id)}
												role="checkbox"
												aria-checked={isItemSelected}
												tabIndex={-1}
												key={row.id}
												selected={isItemSelected}
												style={{ backgroundColor: 'inherit',
                                                            height: 51 }}
											>
												<TableCell align="left" className={docsStyles.headCell}>{handleRenderAction(row)}</TableCell>
												<TableCell align="left">{handleRenderCategory(row)}</TableCell>
												<TableCell align="left">{row.target_name}</TableCell>
												<TableCell align="left" className={docsStyles.historyCell}>{handleRenderColumn(row).length > 0 ? handleRenderColumn(row) : row.col_name}</TableCell>
												<TableCell align="left">{handleRenderValues(row)}</TableCell>
												<TableCell align="left">{row.login}</TableCell>
												<TableCell align="left">{row.firm_name}</TableCell>
												<TableCell align="left">{row.ts}</TableCell>
											</TableRow>
											</>
										);
									})}
								{emptyRows > 0 && (
									<TableRow style={{ height: 33 * emptyRows }}>
										<TableCell colSpan={8} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<div className={styles.tableBottom} >
						<RowsPerPage
							rowsPerPage={rowsPerPage}
							setRowsPerPage={setRowsPerPage}
							setPage={setPage}
							tableLength={table.length}
						/>

						<div>
							<Pagination
								postsPerPage={rowsPerPage}
								totalPosts={search(table).length}
								paginate={paginate}
								page={page}
							/>
						</div>
					</div>

				</Paper>

			</div>
			<Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
				<Alert onClose={handleClose} severity={dialogColor}>
					{dialogContent}
				</Alert>
			</Snackbar>

		</>
	);
}


export default ChangesHistory;