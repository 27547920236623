import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import Select, { components } from "react-select";
import Title from "../../components/title";
import stylesMod from "../../styles/newOrEdit.module.css";
import BTNstyles from "../../styles/button.module.css";
import stylesVersion from "../../styles/version_s.module.css";
import Input from "../../components/input";
import Cargo from "../../components/Cargo";
import Drivers from "../../components/Drivers";
import { DBurl } from "../../appConfig";
import {
  X,
  ArrowRight,
  Download,
  Upload,
  MapPin,
  Map,
  Plus,
  UploadCloud,
  XCircle,
  DownloadCloud,
  DollarSign,
  Search,
} from "react-feather";
import Trace from "./trace";
import NewWarehouse from "../client/NewWarehouse";
import { countryPLToCode } from "../../components/flags/countryToCode";
import {
  Snackbar,
  Button,
  IconButton,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TextField,
  Checkbox,
} from "@material-ui/core";
import styles from "../table.module.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MuiAlert from "@material-ui/lab/Alert";
import RatesPerKmModal from "../../components/RatesPerKmModal";
import AdvanceItem from "../../components/AdvanceItem";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import ShowRouteMapDraggable from "./ShowRouteMapDraggable";
import { CompactPicker } from "react-color";
import {
  GoogleMap,
  LoadScript,
  Circle,
  OverlayView,
  DirectionsService,
  DistanceMatrixService,
  MarkerClusterer,
  Polyline,
  Autocomplete,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import { Loader } from "@googlemaps/js-api-loader";
import CreateTransportSet from "./createTransportSet";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddProfile from "../settings/addProfile";
import ShowPointOnMap from "./ShowPointOnMap";
import { getNewOrderNumber } from "./newOrder.helpers";
import NewOrderInput from "./newOrderInput";

// import { DistanceMatrixService } from '@react-google-maps/api';

function capitalize(s) {
  return s && s[0].toUpperCase() + s.slice(1);
}
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function DialModal(props) {
  return (
    <Dialog
      open={props.open}
      TransitionComponent={props.TransitionComponent}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        className={stylesMod.dialogHead}
      >
        {" "}
        {props.title}{" "}
        <IconButton
          aria-label="close"
          className={stylesMod.closeButton}
          onClick={props.onClose}
        >
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {props.text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="large"
          color="primary"
          className={BTNstyles.btn}
          onClick={props.closeClick}
        >
          Anuluj
        </Button>
        <Button
          variant="contained"
          size="large"
          className={props.btn1Class}
          onClick={props.btn1Click}
          color="primary"
        >
          {props.btn1Text} {props.btn1Ico}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default function NewOrder(props) {
  const [order_no, setOrder_no] = useState(props.state.order_no || "");
  const [customer_order_number, setCustomer_order_number] = useState(
    props.state.customer_order_number || ""
  );
  const [order_title, setOrder_title] = useState(props.state.order_title || "");
  const [order_text, setOrder_text] = useState(props.state.order_text || "");
  const [contractor, setContractor] = useState(props.state.contractor || "");
  const [contractorOption, setContractorOption] = useState([]);
  const [price, setPrice] = useState(props.state.order_price || "");
  const [currency, setCurrency] = useState(props.state.currency || "");
  const [error, setError] = useState(false);
  const currencyOption = [
    { label: "PLN", value: "PLN" },
    { label: "EURO", value: "EUR" },
    { label: "CHF", value: "CHF" },
    { label: "FUNT", value: "GBP" },
    { label: "KORONA", value: "CZK" },
    { label: "RUBEL", value: "RUB" },
    { label: "HRYWNA", value: "UAH" },
  ];
  const [cargo, setCargo] = useState(
    props.state.cargo || [
      {
        name: "",
        quantity: "",
        unit: "",
        price: "",
        currency: "",
        currencyOption: currencyOption,
        statusLoad: true,
        statusUnload: false,
        details: "",
      },
    ]
  );
  const [unitOption, setUnitOption] = useState({});
  const [traceGoodsOptions, setTraceGoodsOptions] = useState([
    { label: "Brak ładunku", isdisabled: true },
  ]);
  const [access_kilometers, setaccess_kilometers] = useState(
    props.state.access_kilometers || 0
  );
  const [cargo_kilometers, setcargo_kilometers] = useState(
    props.state.cargo_kilometers || 0
  );
  const [sum_kilometers, setsum_kilometers] = useState(
    props.state.sum_kilometers || 0
  );
  const [cost, setCost] = useState(props.state.cost || 0);
  const [transport_set, settransport_set] = useState(
    props.state.transport_set || ""
  );
  const [transport_setOption, settransport_setOption] = useState([]);
  const [vehicle, setVehicle] = useState(
    props.state.car || props.state.vehicle || ""
  );
  const [semiTrailer, setSemiTrailer] = useState(props.state.semiTrailer || "");
  const [driver, setDriver] = useState(props.state.driver || "");
  const [driverPhone, setDriverPhone] = useState(props.state.driverPhone || "");
  const [driverTwo, setDriverTwo] = useState(props.state.driverTwo || "");
  const [driverTwoPhone, setDriverTwoPhone] = useState(
    props.state.driverTwoPhone || ""
  );
  const [isCurrentlyUsed, setIsCurrentlyUsed] = useState(
    props.state.isCurrentlyUsed || ""
  );
  const [isDedicated, setIsDedicated] = useState(props.state.isDedicated || "");
  const [trace, setTrace] = useState(
    props.state.trace || [
      {
        id: "trace0",
        type: "loading",
        date: "",
        time: "",
        comment: "",
        access_via_transport_docs: false,
        cargo: [
          {
            name: "",
            quantity: "",
            unit: "",
            palletExchange: false,
            ldmSize: "",
            details: "",
            ADR: false,
            ADRClass: "",
            NumRef: "",
          },
        ],
        warehouse: {},
      },
      {
        id: "trace2",
        type: "unloading",
        date: "",
        time: "",
        comment: "",
        access_via_transport_docs: false,
        cargo: [
          {
            name: "",
            quantity: "",
            unit: "",
            palletExchange: false,
            ldmSize: "",
            details: "",
            ADR: false,
            ADRClass: "",
            NumRef: "",
          },
        ],
        warehouse: {},
      },
    ]
  );
  const [advance, setAdvance] = useState(
    props.state.advance_payments?.length > 0
      ? props.state.advance_payments.map((e) => {
          return {
            value: e.value,
            currency: { label: e.currency.label, value: e.currency.value },
          };
        })
      : [{ value: 0, currency: { label: "PLN", value: "PLN" } }]
  );
  // const [cancelModal, setCancelModal] = useState(false)
  const [openModalCancel, setOpenModalCancel] = useState(false);
  const [openModalShowRoute, setOpenModalShowRoute] = useState(false);
  const [goodsOptions, setGoodsOptions] = useState([]);
  const [warehousesOptions, setWarehousesOptions] = useState([]);
  const [tsId, setTsId] = useState(null);
  const [carUsedModal, setCarUsedModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");
  const [dedicatedModal, setDedicatedModal] = useState(false);
  const [dedicated, setDedicated] = useState(props.state.isDedicated || {});
  const [files, setFiles] = useState(props.state.files || []);
  const [isEdit, setIsEdit] = useState(props.state.isEdit || false);
  const [openModalNewWarehouse, setOpenModalNewWarehouse] = useState(false);
  const [ratesPerKm, setRatesPerKm] = useState("Wybierz kontrahenta");
  const [id_ratesPerKm, set_id_ratesPerKm] = useState(null);
  const [openModalRatesPerKm, setOpenModalRatesPerKm] = useState(false);
  const [order_start_temp, setDateFrom_temp] = useState(
    props.state?.order_start?.replace(/ /g, "T") || ""
  );
  const [order_stop_temp, setDateTo_temp] = useState(
    props.state?.order_stop?.replace(/ /g, "T") || ""
  );
  const [order_start, setDateFrom] = useInput(
    { type: "datetime-local" },
    "Od",
    order_start_temp
  );
  const [order_stop, setDateTo] = useInput(
    { type: "datetime-local" },
    "Do",
    order_stop_temp
  );
  const [user_s, setUser_s] = useState(
    props.user.tms_version_s === true ? props.user.tms_version_s : false
  );
  const [saveRoute, setSaveRoute] = useState(false);
  const [saveRouteAndSaveOrder, setSaveRouteAndSaveOrder] = useState(false);
  const [id_routing, setIdRouting] = useState(null);
  const [id_routing2, setIdRouting2] = useState(null);
  const [mainRouteChange, setMainRouteChange] = useState(false);
  const [route_request, setRoute_request] = useState(null);
  const [route_response, setRoute_response] = useState(null);
  const [route_originObj, setRoute_originObj] = useState(null);
  const [route_destinationObj, setRoute_destinationObj] = useState(null);
  const [route_waypointsObj, setRoute_waypointsObj] = useState(null);

  // tagi
  const [tags_list, setTags_list] = useState([]);
  const [selected_tags, setSelected_tags] = useState(props.state.tags || []);
  const [add_tag, setAdd_tag] = useState("");

  // const [selected_tags_temp, setSelected_tags_temp] = useState('');

  //   transportowe
  const [car, setCar] = useState(props.state.car || { label: "", value: null });
  const [trailer, setTrailer] = useState(
    props.state.trailer || { label: "", value: null }
  );
  const [vehiclesData, setVehiclesData] = useState([
    { label: "", value: null },
  ]);
  const [trailerData, setTrailerData] = useState([{ label: "", value: null }]);
  const [driversData, setDriversData] = useState([{ label: "", value: null }]);
  const [transportSetName, setTransportSetName] = useState("");
  const [openModalCreateTransportSet, setOpenModalCreateTransportSet] =
    useState(false);
  const [newTransportSetID, setNewTransportSetID] = useState(undefined);
  const [newTransportSetFromEdit, setNewTransportSetFromEdit] = useState(null);
  const [checkedGoods, setCheckedGoods] = useState(false);
  const isLoading = useRef(0);

  // załaczniki do zlecenia
  const [isMounted, setIsMounted] = useState(false);
  const [openModalFiles, setOpenModalFiles] = useState(false);
  const [filesArr, setFilesArr] = useState([]);
  const [selectedFilesArr, setSelectedFilesArr] = useState({
    files_car: [],
    files_driver1: [],
    files_driver2: [],
    files_trailer: [],
  });
  const [openAddModalProfile, setOpenAddModalProfile] = useState(false);
  const [openAddModalProfileData, setOpenAddModalProfileData] = useState({});
  const [editProfile, setEditProfile] = useState(false);
  const [openModalProfilQuestion, setOpenModalProfilQuestion] = useState(false);
  const [exchangeRates, setExchangeRates] = useState([]);

  const [openModalShowPoint, setOpenModalShowPoint] = useState(false);
  // const [showPointAddress, setShowPointAddress] = useState('')
  const [updateItem, setUpdateItem] = useState("");
  const [traceIndex, setTraceIndex] = useState(null);
  const [traceData, setTraceData] = useState(null);
  const [newTraceAddress, setNewTraceAddress] = useState(false);

  useEffect(() => {
    if (newTransportSetID !== undefined) {
      getSets();
    }
  }, [newTransportSetID]);

  useEffect(() => {
    const newTransportSet = transport_setOption?.find(
      (el) => el?.id == newTransportSetID
    );
    if (newTransportSet) {
      setNewTransportSetFromEdit(newTransportSet);
      settingTransportSet(newTransportSet);
      settransport_set(newTransportSet);
      setTrailer({
        label: newTransportSet?.trailer_name,
        value: newTransportSet?.id_trailer,
      });
      setDriver({
        label: newTransportSet?.driver1_name,
        value: newTransportSet?.id_driver1,
      });
      setDriverTwo({
        label: newTransportSet?.driver2_name,
        value: newTransportSet?.id_driver2,
      });
    }
  }, [transport_setOption.length]);

  const fetchCars = async function () {
    const response = await fetch(`${DBurl}/getCars/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      const cars = json.data.map((car) => {
        return {
          label: car.name,
          value: car.id,
        };
      });
      setVehiclesData([...cars]);
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
    }
    isLoading.current -= 1;
  };

  const fetchTrailers = async function () {
    const response = await fetch(`${DBurl}/getTrailers/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      const trailers = json.data.map((trailer) => {
        return {
          label: trailer.name,
          value: trailer.id,
        };
      });
      setTrailerData([...trailers]);
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
    }
    isLoading.current -= 1;
  };

  const fetchDrivers = async function () {
    const response = await fetch(`${DBurl}/getDriversNoSlaves/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      let tempData = json.data.map((v) => ({
        ...v,
        label: `${v.firstname} ${v.surname}`,
        value: v.id,
      }));
      setDriversData(tempData);
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
    }
    isLoading.current -= 1;
  };

  const checkTransportSet = async () => {
    const getURL = () => {
      if (trailer.value && !driverTwo.value)
        return `${DBurl}/checkTransportSets?id_car=${car.value}&id_driver1=${driver.value}&id_trailer=${trailer.value}`;
      if (trailer.value && driverTwo.value)
        return `${DBurl}/checkTransportSets?id_car=${car.value}&id_driver1=${driver.value}&id_driver2=${driverTwo.value}&id_trailer=${trailer.value}`;
      if (!trailer.value && !driverTwo.value)
        return `${DBurl}/checkTransportSets?id_car=${car.value}&id_driver1=${driver.value}`;
      if (!trailer.value && driverTwo.value)
        return `${DBurl}/checkTransportSets?id_car=${car.value}&id_driver1=${driver.value}&id_driver2=${driverTwo.value}`;
      if (!trailer.value && !driverTwo.value && !driver)
        return `${DBurl}/checkTransportSets?id_car=0&id_driver1=0`;
      if (!trailer.value && !driverTwo.value && !driver.value && !car.value)
        return `${DBurl}/checkTransportSets?id_car=0&id_driver1=0`;
    };
    const response = await fetch(getURL(), {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json?.data?.length > 0)
      settransport_set(
        transport_setOption.find((set) => set.id === json.data[0].id)
      );
    if (json?.data?.length === 0) setOpenModalCreateTransportSet(true);
    isLoading.current -= 1;
  };

  useEffect(() => {
    fetchCars();
    fetchTrailers();
    fetchDrivers();
  }, []);

  useEffect(() => {
    if (props.state.order_type_S === true) {
      setUser_s(true);
    }
  }, [props.state.order_type_S]);

  const savedTabId = props.tabID;
  const ValueContainer = ({ children, selectProps, ...props }) => (
    <components.ValueContainer {...props}>
      <div className={stylesMod.clientAddParent}>
        <IconButton
          className={stylesMod.btnIcon}
          onClick={() => {
            selectProps.selectProps(
              selectProps.link ? selectProps.link : "AddClient",
              false,
              selectProps.saveTab()
            );
          }}
        >
          <Plus className={stylesMod.clientAdd} />
        </IconButton>
      </div>
      {children}
    </components.ValueContainer>
  );

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  function useInput({ type }, placeholder, initialValue) {
    const [value, setValue] = useState(() => initialValue);
    const input = (
      <Input
        value={value}
        handleChange={(e) => {
          setValue(e.target.value);
        }}
        type={type}
        placeholder={placeholder}
        className={styles.searchInputSmall}
        error={error && value.length < 1}
        search={type === "text" ? true : false}
      />
    );

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return [value, input];
  }

  const [clearHooksState, setClearHooksState] = useState(false);

  const clearHooks = () => {
    setToCostValues(false);
    setDisplayColorPicker(false);
    setColorPicker("");
    setSelected_tags([]);
    setAdd_tag("");
    setOrder_no("");
    setCustomer_order_number("");
    setOrder_title("");
    setOrder_text("");
    setDateFrom_temp("");
    setDateTo_temp("");
    setContractor("");
    setPrice("");
    setCurrency("");
    setCargo([
      {
        name: "",
        quantity: "",
        unit: "",
        price: "",
        currency: "",
        currencyOption: currencyOption,
        statusLoad: true,
        statusUnload: false,
        details: "",
      },
    ]);
    setaccess_kilometers("");
    setcargo_kilometers("");
    setsum_kilometers("");
    setCost("");
    settransport_set("");
    setVehicle("");
    setSemiTrailer("");
    setDriver("");
    setDriverPhone("");
    setDriverTwo("");
    setDriverTwoPhone("");
    setTrace([
      {
        id: "trace0",
        type: "loading",
        date: "",
        time: "",
        comment: "",
        access_via_transport_docs: false,
        cargo: [
          {
            name: "",
            quantity: "",
            unit: "",
            palletExchange: false,
            ldmSize: "",
            details: "",
            ADR: false,
            ADRClass: "",
            NumRef: "",
          },
        ],
        warehouse: {},
      },
      {
        id: "trace2",
        type: "unloading",
        date: "",
        time: "",
        comment: "",
        access_via_transport_docs: false,
        cargo: [
          {
            name: "",
            quantity: "",
            unit: "",
            palletExchange: false,
            ldmSize: "",
            details: "",
            ADR: false,
            ADRClass: "",
            NumRef: "",
          },
        ],
      },
    ]);
    setFiles([]);
    setFilesArr([]);
    setSelectedFilesArr({
      files_car: [],
      files_driver1: [],
      files_driver2: [],
      files_trailer: [],
    });
  };

  const handleCargo = (e, i) => {
    const cargoTemp = [...cargo];
    if (e.target) {
      cargoTemp[i][e.target.name] = e.target.value;
    } else {
      cargoTemp[i][e.name] = e.value;
    }
    setCargo(cargoTemp);
  };

  const handleSelect = (e, i, n) => {
    if (e) {
      const cargoTemp = [...cargo];
      cargoTemp[i][n] = e.value;
      setCargo(cargoTemp);
    }
  };

  const removeCargo = (e) => {
    var cargoTemp2 = [...cargo];
    if (cargoTemp2.length !== 1) {
      cargoTemp2.splice(e, 1);
      setCargo(cargoTemp2);

      // setCargo(cargoTemp.splice(e, 1))
    } else {
      cargoTemp2 = [
        {
          access_via_transport_docs: false,
          id: "trace0",
          type: "loading",
          date: "",
          time: "",
          comment: "",
          companyShort: "",
          kod: "",
          street: "",
          street_no: "",
          city: "",
          position: "",
          name: "",
          quantity: "",
          unit: "",
          palletExchange: false,
          ldmSize: "",
          details: "",
          ADR: false,
          ADRClass: "",
          NumRef: "",
        },
      ];
      // setCargo(cargoTemp)
    }

    // await setCargo(cargoTemp)
    checkStatus(cargoTemp2);
  };

  const addCargo = (e) => {
    const cargoTemp = [
      ...cargo,
      {
        currency: "PLN",
        name: "",
        price: "",
        quantity: null,
        statusLoad: false,
        statusUnload: false,
        unit: "",
        id: "",
        details: "",
      },
    ];
    setCargo(cargoTemp);
  };

  const handleTrace = (e, i, c, check, address) => {
    // console.log('handleTrace', e, i, c, check, address)
    const traceTemp = [...trace];
    if (address) {
      traceTemp[i].address = e;
    } else if (check) {
      if (c || c === 0) {
        traceTemp[i].cargo[c][e.target.name] = e.target.checked;
      } else {
        traceTemp[i][e.target.name] = e.target.checked;
      }
    } else if (c || c === 0) {
      traceTemp[i].cargo[c][e.target.name] = e.target.value;
    } else {
      traceTemp[i][e.target.name] = e.target.value;
    }

    setTrace(traceTemp);
  };

  const handleTraceNew = (e, i, name) => {
    const traceTemp = [...trace];
    traceTemp[i][name] = e;
    setTrace(traceTemp);
  };

  const handleSelectTrace = (e, i, name, cargoIdx) => {
    const traceTemp = [...trace];
    if (traceTemp && traceTemp[i] && e) {
      if (cargoIdx || cargoIdx === 0) {
        if (traceTemp[i].cargo[cargoIdx]) {
          traceTemp[i].cargo[cargoIdx][name] = e[name];
        }
      } else {
        traceTemp[i][name] = e.value;
      }
      setTrace(traceTemp);
    }
  };

  const addTrace = (type) => {
    // Trigger map change for HERE popup
    setMainRouteChange(true);

    const ids = trace.map((e) => {
      return Number(e.id.split("trace")[1]);
    });

    const maxId = ids.reduce(function (
      previousLargestNumber,
      currentLargestNumber
    ) {
      return currentLargestNumber > previousLargestNumber
        ? currentLargestNumber
        : previousLargestNumber;
    },
    0);

    if (type === "loading" || type === "unloading") {
      const traceTemp = [
        ...trace,
        {
          id: `trace${maxId + 1}`,
          type: type,
          date: "",
          time: "",
          comment: "",
          cargo: [
            {
              name: "",
              quantity: "",
              unit: "",
              palletExchange: false,
              ldmSize: "",
              details: "",
              ADR: false,
              ADRClass: "",
              NumRef: "",
            },
          ],
          warehouse: {},
        },
      ];
      setTrace(traceTemp);
    } else {
      const traceTemp = [
        ...trace,
        {
          id: `trace${maxId + 1}`,
          type: "stop",
          subtype: "point",
          date: "",
          time: "",
          comment: "",
          place: "",
        },
      ];
      setTrace(traceTemp);
    }
  };

  const addTraceCargo = (iTrace) => {
    const tmpTrace = [...trace];
    tmpTrace[iTrace].cargo[tmpTrace[iTrace].cargo.length] = {
      name: "",
      quantity: "",
      unit: "",
      palletExchange: false,
      ldmSize: "",
      details: "",
      ADR: false,
      ADRClass: "",
      NumRef: "",
    };
    setTrace(tmpTrace);
  };

  const removeTraceCargo = (iTrace, iCargo) => {
    const tmpTrace = [...trace];
    tmpTrace[iTrace].cargo.splice(iCargo, 1);
    setTrace(tmpTrace);
    checkStatus();
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const triggerMainRouteChange = () => {
    // Trigger map change for HERE popup
    setMainRouteChange(true);
    // console.log('triggerMainRouteChange!!!');
  };

  const onDragEnd = (result) => {
    // Trigger map change for HERE popup

    setMainRouteChange(true);

    if (!result.destination) {
      return;
    }

    const items = reorder(trace, result.source.index, result.destination.index);

    setTrace(items);
  };

  const fetchRates = async () => {
    const response = await fetch(`${DBurl}/getExchangeRates`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setExchangeRates(json.data);
    }
    isLoading.current -= 1;
  };

  const getSets = async function () {
    const response = await fetch(`${DBurl}/getTransportSets/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      const tempSets = json.data.map((e) => {
        let temp = { ...e };
        temp.value = e.id;
        return temp;
      });
      settransport_setOption(tempSets);
      if (transport_set?.id !== null || transport_set?.id !== "") {
        let updateSet = tempSets.find((o) => {
          return o.id === transport_set.id;
        });
        updateSet !== undefined && settransport_set(updateSet);
      }
    } else {
      setOpenDialog(true);
      setDialogColor("error");
      setDialogContent(
        `Błąd pobierania danych zestawów: ${json.response}: ${response.responseDescription}`
      );
    }
    isLoading.current -= 1;
  };

  const fetchUnits = async function () {
    const response = await fetch(`${DBurl}/getUnits/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setUnitOption(
        json.data.map((e) => {
          return { value: e.id, label: e.name };
        })
      );
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
    }
    isLoading.current -= 1;
  };

  const getContractors = async function () {
    const response = await fetch(`${DBurl}/getContractors/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      let contractorArray = [];
      json.data.forEach((el) => {
        contractorArray.push({
          value: el.id,
          label: el.name,
          name: "contractor",
        });
      });
      setContractorOption(contractorArray);
    } else {
    }
    isLoading.current -= 1;
  };

  const fetchOrderWithFiles = async (type, data) => {
    props.loading(true);
    const response = await fetch(`${DBurl}/setOrders`, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: type,
        data: data,
      }),
    });
    const json = await response.json();
    if (json.success) {
      setOpenDialog(true);
      setDialogContent(
        type == "insert" ? "Zlecenie stworzone" : "Edycja zapisana pomyślnie"
      );
      setDialogColor("success");
      getOrders();
      // Zapis tras do routingu dla tabletów Sygic
      // Tylko wersja pełna
      if (!user_s) {
        let id_order = null;
        if (data.id_routing != null) {
          if (type == "insert") {
            id_order = json.data.new_id;
            fetchSetHeremapsRoutingUPDATE(id_order, data.id_routing);
            // console.log('****UPDATE id_order dla id_route: ', data.id_routing);
            // console.log('****UPDATE id_order: ', id_order);
          }
        }
      }
      clearHooks();
      props.loading(false);
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      getOrders();
      props.loading(false);
    }
  };

  const fetchFile = async (file) => {
    try {
      const formData = new FormData();
      await formData.append("file", await file, file.name);
      const response = await fetch(`${DBurl}/uploadFile`, {
        method: "POST",
        credentials: "include",
        headers: {
          "X-CSRF-Token": props.user.csrf_token,
        },
        body: formData,
      });
      const json = await response.json();
      if (json.success) {
        return await {
          name: json.data?.filename,
          msg: file.name,
          user_filename: file.user_filename,
        };
      }
    } catch (err) {
      // console.log(err)
      return {};
    }
  };

  const fetchOrder = async (type, data) => {
    const tempData = { ...data };

    tempData.orders_files = tempData.orders_files.filter((e) => !!e.id && e);

    if (data.orders_files.length > 0) {
      for (const file of data.orders_files) {
        if (!file.id && !file.ref_file_id) {
          const tempFile = await fetchFile(file);
          tempData.orders_files = [...tempData.orders_files, tempFile];
        }
        if (!file.id && file.ref_file_id) {
          tempData.orders_files = [...tempData.orders_files, file];
        }
      }
      // console.log(type, tempData)
      fetchOrderWithFiles(type, tempData);
    } else {
      // console.log(type, data)
      fetchOrderWithFiles(type, data);
    }
  };

  const getGoods = async function () {
    const response = await fetch(`${DBurl}/getGoods/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      const goodsArray = json.data.map((e) => {
        return {
          ...e,
          value: e.id,
          label: e.name,
        };
      });
      setGoodsOptions(goodsArray);
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
    }
    isLoading.current -= 1;
  };

  const getWarehouses = async function (id) {
    if (!!id) {
      const response = await fetch(
        `${DBurl}/getWarehouses?id_contractor=${id}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": props.user.csrf_token,
          },
        }
      );
      const json = await response.json();
      if (json.success) {
        const warehousesArray = json.data.map((e) => {
          return {
            ...e,
            value: e.id,
            label: e.name,
          };
        });
        await setWarehousesOptions(warehousesArray);
        return json;
      } else {
        setOpenDialog(true);
        setDialogContent(`${json.responseDescription} // ${json.response}  `);
        setDialogColor("error");
      }
    } else {
      setWarehousesOptions([
        { label: "Wybierz kontrahenta", isdisabled: true },
      ]);
    }
    isLoading.current -= 1;
  };

  const saveTab = () => {
    const tempTab = { ...props.tabs[savedTabId] };

    tempTab.save = isEdit;
    tempTab.access_kilometers = access_kilometers;
    tempTab.cargo = cargo;
    tempTab.cargo_kilometers = cargo_kilometers;
    tempTab.contractor = contractor;
    tempTab.currency = currency;
    tempTab.order_no = order_no;
    tempTab.customer_order_number = customer_order_number;
    tempTab.order_title = order_title;
    tempTab.order_text = order_text;
    tempTab.order_start = order_start;
    tempTab.order_stop = order_stop;
    tempTab.vehicle = vehicle;
    tempTab.driver = driver;
    tempTab.driverTwo = driverTwo;
    tempTab.order_price = price;
    tempTab.sum_kilometers = sum_kilometers;
    tempTab.trace = trace;
    tempTab.transport_set = transport_set;
    tempTab.cost = cost;
    tempTab.advance_payments = advance;
    tempTab.tags = selected_tags;
    tempTab.access_kilometers = access_kilometers;
    tempTab.cargo_kilometers = cargo_kilometers;
    tempTab.sum_kilometers = sum_kilometers;
    tempTab.cost = cost;
    tempTab.semiTrailer = semiTrailer;
    tempTab.car = car;
    tempTab.trailer = trailer;
    tempTab.files = files;

    return tempTab;
  };

  useEffect(() => {
    props.setTabs(() => {
      const tempTab = [...props.tabs];
      tempTab[props.tabID] = saveTab();
      return tempTab;
    });
  }, [
    access_kilometers,
    cargo,
    cargo_kilometers,
    contractor,
    currency,
    order_no,
    customer_order_number,
    order_title,
    order_text,
    order_start,
    order_stop,
    vehicle,
    driver,
    price,
    sum_kilometers,
    trace,
    transport_set,
    cost,
    advance,
    selected_tags,
    access_kilometers,
    cargo_kilometers,
    sum_kilometers,
    cost,
    semiTrailer,
    driver,
    driverTwo,
    car,
    trailer,
    files,
  ]);

  useEffect(() => {
    if (transport_setOption.length && tsId) {
      checkTsId(tsId, transport_setOption);
    }
  }, [transport_setOption, tsId]);

  useEffect(() => {
    getContractors();
    getSets();
    getGoods();
    fetchRates();
    fetchUnits();

    if (props.state.order_currency) {
      setCurrency({
        label: props.state.order_currency,
        name: props.state.currency,
      });
    }
  }, []);

  useEffect(() => {
    getSets();
  }, [newTransportSetID]);

  useEffect(() => {
    getWarehouses(contractor.value);
  }, [contractor.value]);

  const getNameFromId = (num) => {
    if (
      goodsOptions.length > 0 &&
      goodsOptions.find((e) => Number(e.id) === num)
    ) {
      return goodsOptions.find((e) => Number(e.id) === num).name;
    }
  };

  useEffect(() => {
    if (cargo.length > 0 && !(cargo.length === 1 && cargo[0].name === "")) {
      const result = cargo.map((e) => {
        if (e.name) {
          let detail = goodsOptions.filter((o) => {
            return o.id === e.name;
          });
          return {
            id: e.name,
            name: getNameFromId(e.name),
            details: detail[0]?.details,
            unit: e.unit,
            label: getNameFromId(e.name),
            value: e.name,
            quantity: e.quantity,
          };
        }
      });
      setTraceGoodsOptions(result);
    }
  }, [cargo]);

  const changeDateFormat = (date) => {
    if (date !== null) {
      const arrayDate = date.split("-");
      let time = arrayDate[2].split(" ")[1];
      time = time.substring(0, 5);
      arrayDate[2] = arrayDate[2].substring(0, 2);
      return `${arrayDate[0]}-${arrayDate[1]}-${arrayDate[2]}T${time}`;
    }
  };

  const checkWarehouse = (data) => {
    let result;
    result = warehousesOptions.find((e) => e.value == data);
    return result;
  };

  const checkTraceCargo = (data) => {
    let tempCargo = [];
    data.forEach((e) => {
      if (checkedGoods || e.id_good) {
        tempCargo.push({
          name: e.name,
          id: e.id_good,
          quantity: e.goods_location_quantity,
          unit: e.unit,
          palletExchange: e.is_pallet_exchange,
          ldmSize: e.ldm_size,
          ADR: e.is_adr,
          ADRClass: e.adr_class,
          NumRef: e.reference_no,
          details: e.details,
        });
      }
    });
    if (tempCargo.length === 0) {
      tempCargo.push({
        name: "",
        quantity: "",
        unit: "",
        price: "",
        currency: "",
        currencyOption: currencyOption,
        statusLoad: true,
        statusUnload: false,
        details: "",
      });
    }
    return tempCargo;
  };

  const checkTsId = (id, array) => {
    if (id && array.length > 0) {
      const set = array.find((e) => e.id == id);
      settingTransportSet(set);
    }
  };

  const getOrder = async (id) => {
    if (props.state.order_type_S === true) {
      //Orders S
      const response = await fetch(`${DBurl}/getOrdersS?id=${id}`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
      });
      const json = await response.json();
      if (json.success) {
        const order = { ...json.data[0] };
        setOrder_no(order.order_no);
        setOrder_title(order.order_name);
        setOrder_text(order.order_description);
        setDateFrom_temp(
          order.order_start ? order.order_start.replace(/ /g, "T") : ""
        );
        setDateTo_temp(
          order.order_stop ? order.order_stop.replace(/ /g, "T") : ""
        );
        setTsId(order.transport_set.id_transport_set);
      }
    } else if (props.state.order_type_S === false) {
      //Orders full options
      const response = await fetch(`${DBurl}/getOrders?id=${id}`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
      });
      const json = await response.json();
      if (json.success) {
        const order = { ...json.data[0] };

        let tmpSelectedFilesArr = {
          files_car: [],
          files_driver1: [],
          files_driver2: [],
          files_trailer: [],
        };

        for (let file of order.files) {
          if (file.kind === "car") {
            tmpSelectedFilesArr.files_car.push(file.ref_file_id);
          }
          if (file.kind === "driver1") {
            tmpSelectedFilesArr.files_driver1.push(file.ref_file_id);
          }
          if (file.kind === "driver2") {
            tmpSelectedFilesArr.files_driver2.push(file.ref_file_id);
          }
          if (file.kind === "trailer") {
            tmpSelectedFilesArr.files_trailer.push(file.ref_file_id);
          }
        }
        setSelectedFilesArr(tmpSelectedFilesArr);
        // const [selectedFilesArr, setSelectedFilesArr] = useState({
        //   files_car: [],
        //   files_driver1: [],
        //   files_driver2: [],
        //   files_trailer: [],
        // });

        setOrder_no(order.order_no);
        setCustomer_order_number(order.customer_order_number);
        setContractor(
          order.contractor
            ? {
                label: order.contractor.name,
                name: order.contractor.name,
                value: order.id_contractor,
              }
            : { label: "", name: "", value: "" }
        );
        setPrice(order.order_price);
        setCurrency({
          value: order.order_currency,
          label: order.order_currency,
        });
        let tempCargo = [];
        order.id &&
          order.locations.map((e) => {
            e.goods.map((el) => {
              var found = false;
              for (var i = 0; i < tempCargo.length; i++) {
                if (tempCargo[i].name == el.name) {
                  found = true;
                  break;
                }
              }
              if (!found) {
                tempCargo.push({
                  name: el.name,
                  id: el.id_good,
                  details: el.details,
                  quantity: el.goods_order_quantity,
                  unit: el.unit,
                  price: el.goods_price,
                  currency:
                    el.goods_currency === undefined ? null : el.goods_currency,
                  currencyOption: currencyOption,
                  statusLoad: true,
                  statusUnload: true,
                });
              }
            });
          });
        if (tempCargo.length > 0) {
          setCargo(tempCargo);
        } else {
          setCargo([{}]);
        }
        setaccess_kilometers(order.access_kilometers);
        setcargo_kilometers(order.cargo_kilometers);
        setsum_kilometers(order.sum_kilometers);
        setFiles(order.files);
        setAdvance(
          order.advance_payments.length > 0
            ? order.advance_payments.map((e) => {
                return {
                  value: e.value,
                  currency: { label: e.currency, value: e.currency },
                };
              })
            : [{ value: 0, currency: { label: "PLN", value: "PLN" } }]
        );
        setCost(order.cost);
        setTsId(order.transport_set.id_transport_set);
        let tempTrace = [];
        order.locations.forEach((e) => {
          if (e.type === "loading" || e.type === "unloading") {
            if (e.id_warehouse === null) {
              tempTrace.push({
                id: `trace-${e.index_order}`,
                type: e.type,
                date: changeDateFormat(e.ts),
                comment: e.comment,
                address: {
                  city: e.city,
                  country: e.country,
                  country_code: e.country_code,
                  district: e.district,
                  latitude: e.latitude,
                  longitude: e.longitude,
                  street: e.street,
                  street_no: e.street_no,
                  apartment_no: e.apartment_no,
                  firm_name: e.firm_name,
                  zipcode: e.zipcode,
                  address_display_name: e.address_display_name,
                },
                cargo: checkTraceCargo(e.goods),
                manual_adress: true,
              });
            } else {
              tempTrace.push({
                id: `trace-${e.index_order}`,
                type: e.type,
                date: changeDateFormat(e.ts),
                comment: e.comment,
                warehouse: { id: e.id_warehouse },
                cargo: checkTraceCargo(e.goods),
              });
            }
          } else {
            tempTrace.push({
              id: `trace-${e.index_order}`,
              type: e.type,
              subtype: e.subtype,
              date: changeDateFormat(e.ts),
              comment: e.comment,
              address: {
                city: e.city,
                country: e.country,
                country_code: e.country_code,
                district: e.district,
                latitude: e.latitude,
                longitude: e.longitude,
                street: e.street,
                street_no: e.street_no,
                zipcode: e.zipcode,
                address_display_name: e.address_display_name,
              },
            });
          }
        });
        setTrace(tempTrace);
        setSelected_tags(
          order &&
            order.tags.map((o) => {
              return o.id;
            })
        );
      }
    }
    isLoading.current -= 1;
  };

  useEffect(() => {
    getSets();
    if (props.state.fetchOrderId) {
      !props.state.save && getOrder(props.state.fetchOrderId);
    }

    if (props.state.isEdit) {
      setIsEdit(props.state.isEdit);
    }
  }, [props.state.fetchOrderId]);

  const checkStatus = (cargoData) => {
    const cargoTemp = cargoData || [...cargo];
    cargoTemp.forEach((elCargo, i) => {
      cargoTemp[i].statusLoad = false;
      cargoTemp[i].statusUnload = false;

      trace.forEach((elTrace) => {
        if (elTrace.cargo) {
          elTrace.cargo.forEach((elCargoTrace) => {
            const elCargoName = elCargo.name
              ? String(elCargoTrace.name).toLowerCase()
              : undefined;
            const elTraceName = elCargoTrace.name
              ? String(elCargoTrace.name).toLowerCase()
              : null;
            const elCargoUnit = elCargo.unit
              ? String(elCargoTrace.unit).toLowerCase()
              : undefined;
            const elTraceUnit = elCargoTrace.unit
              ? String(elCargoTrace.unit).toLowerCase()
              : null;
            const elCargoQuantity = elCargo.quantity
              ? elCargo.quantity
              : undefined;
            const elTraceQuantity = elCargoTrace.quantity
              ? elCargoTrace.quantity
              : null;

            // if (elCargoName === elTraceName && elCargoUnit === elTraceUnit && elCargoQuantity === elTraceQuantity) {
            // if (elCargoName === elTraceName && elCargoUnit === elTraceUnit) {

            if (elCargoName === elTraceName) {
              if (elTrace.type === "loading") {
                cargoTemp[i].statusLoad = true;
              }
              if (elTrace.type === "unloading") {
                cargoTemp[i].statusUnload = true;
              }
            }

            // console.log(cargoTemp[i].statusLoad,cargoTemp[i].statusUnload)
          });
        }
      });
    });
    setCargo(cargoTemp);
  };

  const cargoModule = cargo.map((e, i) => {
    // console.log(e,i)
    return (
      <Cargo
        cargo={e}
        key={i}
        propKey={i}
        removeCargo={removeCargo}
        addCargo={addCargo}
        handleCargo={handleCargo}
        handleSelect={handleSelect}
        goodsOptions={goodsOptions}
        checkStatus={checkStatus}
        addTab={props.addTab}
        saveTab={saveTab}
        error={error}
        unitOption={unitOption}
        user_s={user_s}
        checkedGoods={checkedGoods}
      />
    );
  });

  const moveTrace = (i, dir) => {
    if (
      (i !== 0 || dir !== "up") &&
      (i !== trace.length - 1 || dir !== "down")
    ) {
      const traceTemp = [...trace];
      var moveElement = traceTemp.splice(i, 1)[0];
      if (dir === "up") {
        traceTemp.splice(i - 1, 0, moveElement);
      }
      if (dir === "down") {
        traceTemp.splice(i + 1, 0, moveElement);
      }
      setTrace(traceTemp);
    }
  };

  const removeTrace = (i) => {
    const traceTemp = [...trace];
    traceTemp.splice(i, 1);
    setTrace(traceTemp);
    checkStatus();
  };

  const copyTrace = (i) => {
    const traceTemp = [...trace];
    const copyTraceEl = { ...trace[i] };
    copyTraceEl.id = Math.random().toString(36).substring(7);
    traceTemp.splice(i + 1, 0, copyTraceEl);
    setTrace(traceTemp);
  };

  const checkError = () => {
    if (user_s === true) {
      if (
        !!order_no &&
        !!order_title &&
        !!order_text &&
        !!order_start &&
        !!order_stop &&
        !!transport_set
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      const checkCargo = () => {
        let result = true;
        cargo.forEach((e) => {
          // console.log('e.name',e.name, 'e.currency',e.currency, 'e.statusLoad',e.statusLoad, 'e.statusUnload', e.statusUnload)
          if (checkedGoods) {
            if (
              !e.name ||
              // !e.quantity ||
              // !e.unit ||
              // !e.price ||
              // !e.currency ||
              !e.statusLoad ||
              !e.statusUnload
            ) {
              result = false;
            }
          }
        });
        return result;
      };
      const checkTraceCargo = (data) => {
        let result = true;
        data.forEach((e) => {
          // console.log('e.NumRef,e.name',e.NumRef,e.name)
          if (checkedGoods === true) {
            if (
              !e.NumRef ||
              !e.name
              // !e.quantity
              // !e.unit
            ) {
              result = false;
            }
          }
        });
        return result;
      };
      const checkTrace = () => {
        let result = true;
        trace.forEach((e) => {
          if (e.type === "loading" || e.type === "unloading") {
            if (e.manual_adress === true) {
              if (
                !e.date ||
                !e.address.latitude ||
                !e.address.longitude ||
                !checkTraceCargo(e.cargo)
              ) {
                result = false;
              }
            } else {
              if (
                !e.date ||
                !e.warehouse ||
                !e.warehouse.address ||
                !checkTraceCargo(e.cargo)
              ) {
                result = false;
              }
            }
          } else {
            if (
              // !e.date ||
              !e.address
            ) {
              result = false;
            }
          }
        });
        return result;
      };
      if (
        !!order_no &&
        !!contractor &&
        // !!price &&
        // !!currency &&
        // !!access_kilometers &&
        // !!cargo_kilometers &&
        // !!sum_kilometers &&
        // !!cost &&
        !!transport_set &&
        !!vehicle &&
        checkCargo() &&
        checkTrace()
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  const makeOrder = (
    type,
    is_reservation,
    is_edit,
    is_template,
    id_routing = null
  ) => {
    const dataForm = (date) => {
      const newDate = moment(date).format("yyyy-MM-DD HH:mm:ss");
      return newDate;
    };
    if (!transport_set || transport_set === undefined) {
      checkTransportSet();
    }
    if (user_s === true) {
      if (checkError()) {
        setError(true);
        setOpenDialog(true);
        setDialogContent("Uzupełnij brakujące pola");
        setDialogColor("error");
      } else {
        setError(false);
        const data = {
          id: is_edit ? props.state.fetchOrderId : null,
          id_contractor: contractor.value ? contractor.value : null,
          id_car: transport_set.id_car ? transport_set.id_car : null,
          id_transport_set: transport_set.id ? transport_set.id : null,
          // id_trailer: transport_set.id_trailer ? transport_set.id_trailer : null,
          id_trailer: null,
          id_driver1: transport_set.id_driver1
            ? transport_set.id_driver1
            : null,
          id_driver2: transport_set.id_driver2
            ? transport_set.id_driver2
            : null,
          is_dedicated_car: transport_set.is_dedicated,
          is_double_cast_of_drivers: transport_set.is_double_cast_of_drivers,
          // is_reservation: is_reservation ? is_reservation : null,
          order_no: order_no ? order_no : null,
          order_price: price ? price : null,
          order_currency: currency.value ? currency.value : null,
          // is_active: true,
          orders_advance_payments: !!advance
            ? advance.map((e) => {
                return { value: e.value, currency: e.currency.value };
              })
            : [{}],
          access_kilometers:
            access_kilometers === ""
              ? 0
              : access_kilometers
              ? access_kilometers
              : null,
          cargo_kilometers: cargo_kilometers ? cargo_kilometers : null,
          sum_kilometers: sum_kilometers ? sum_kilometers : null,
          cost: cost === "" ? null : cost ? cost : null,
          orders_files: files ? files : null,
          status: is_reservation ? 4 : 1,
          // template_name: is_template ? order_no : null,
          // is_template: !!is_template,
          is_edited_transport_set: false,
          advance_payment: null, //???
          orders_locations: null,
          order_name: order_title ? order_title : null,
          order_description: order_text ? order_text : null,
          order_start: order_start ? dataForm(order_start) : null,
          order_stop: order_stop ? dataForm(order_stop) : null,
        };
        fetchOrder(type, data);
      }
    } else {
      setError(false);

      if (isDedicated) {
        setDedicatedModal(true);
      } else if (isCurrentlyUsed) {
        setCarUsedModal(true);
      } else {
        const gprice = (id, name) => {
          const result = cargo.find((e) => e.name == id);
          if (result !== undefined) {
            if (name === "price") return result.price;
            else if (name === "currency") return result.currency;
          }
        };
        const order_locations = trace.map((e, i) => {
          const tmpCargo = e.cargo
            ? e.cargo.map((el, idx) => {
                return {
                  id_good: checkedGoods === false ? 0 : el.id,
                  goods_order_quantity: el.quantity === "" ? null : el.quantity,
                  goods_location_quantity:
                    el.quantity === "" ? null : el.quantity,
                  goods_price: gprice(el.id, "price")
                    ? gprice(el.id, "price")
                    : null,
                  goods_currency:
                    gprice(el.id, "currency") === undefined
                      ? null
                      : gprice(el.id, "currency"),
                  is_pallet_exchange: el.palletExchange,
                  ldm_size: !!el.ldmSize ? el.ldmSize : null,
                  is_adr: el.ADR,
                  adr_class: !!el.ADRClass ? el.ADRClass : null,
                  reference_no: el.NumRef,
                  details: el.details,
                };
              })
            : null;

          // console.log('tmpCargo',tmpCargo)

          const address =
            (e.type === "loading" || e.type === "unloading") &&
            e.manual_adress !== undefined &&
            e.manual_adress === true &&
            e.address !== undefined
              ? {
                  address_display_name: e.address.address_display_name,
                  country: e.address.country,
                  country_code: countryPLToCode(capitalize(e.address.country)),
                  street: e.address.street,
                  city: e.address.city,
                  street_no: e.address.street_no,
                  // address_code: e.warehouse && e.warehouse.address && e.warehouse.address.address_code,
                  apartment_no: e.address.apartment_no,
                  firm_name: e.address.firm_name,
                  longitude: e.address.longitude,
                  latitude: e.address.latitude,
                  district: e.address.district,
                  zipcode: e.address.zipcode,
                  id_warehouse: null,
                }
              : (e.type === "loading" || e.type === "unloading") &&
                (e.manual_adress === undefined || e.manual_adress === false)
              ? {
                  country:
                    e.warehouse &&
                    e.warehouse.address &&
                    e.warehouse.address.country,
                  country_code: countryPLToCode(
                    capitalize(
                      e.warehouse &&
                        e.warehouse.address &&
                        e.warehouse.address.country
                    )
                  ),
                  street:
                    e.warehouse &&
                    e.warehouse.address &&
                    e.warehouse.address.street,
                  city:
                    e.warehouse &&
                    e.warehouse.address &&
                    e.warehouse.address.city,
                  street_no:
                    e.warehouse &&
                    e.warehouse.address &&
                    e.warehouse.address.street_no,
                  address_code:
                    e.warehouse &&
                    e.warehouse.address &&
                    e.warehouse.address.address_code,
                  apartment_no:
                    e.warehouse &&
                    e.warehouse.address &&
                    e.warehouse.address.apartment_no,
                  longitude:
                    e.warehouse &&
                    e.warehouse.address &&
                    e.warehouse.address.longitude,
                  latitude:
                    e.warehouse &&
                    e.warehouse.address &&
                    e.warehouse.address.latitude,
                  district:
                    e.warehouse &&
                    e.warehouse.address &&
                    e.warehouse.address.district,
                  zipcode:
                    e.warehouse &&
                    e.warehouse.address &&
                    e.warehouse.address.zip_code,
                  id_warehouse: e.warehouse && e.warehouse.id,
                }
              : {
                  ...e.address,
                };

          return {
            type: e.type,
            subtype: e.subtype,
            index_order: i,
            access_via_transport_docs: e.access_via_transport_docs || false,
            comment: e.comment,
            location_ts: e.date ? e.date : e.date === "" ? null : e.date,
            locations_goods: tmpCargo,
            ...address,
          };
        });

        const data = {
          id: is_edit ? props.state.fetchOrderId : null,
          id_contractor: contractor.value,
          id_car: transport_set.id_car,
          id_transport_set: transport_set.id,
          id_trailer: transport_set.id_trailer,
          id_driver1: transport_set.id_driver1,
          id_driver2: transport_set.id_driver2,
          is_dedicated_car: transport_set.is_dedicated,
          is_double_cast_of_drivers: transport_set.is_double_cast_of_drivers,
          is_reservation: is_reservation,
          order_no: order_no,
          order_price: price ? price : price === "" ? null : price,
          order_currency: currency.value,
          is_active: true,
          orders_advance_payments: !!advance
            ? advance.map((e) => {
                return { value: e.value, currency: e.currency.value };
              })
            : [],
          access_kilometers:
            access_kilometers === ""
              ? 0
              : access_kilometers
              ? access_kilometers
              : null,
          cargo_kilometers: cargo_kilometers,
          sum_kilometers: sum_kilometers,
          cost: cost === "" ? null : cost ? cost : null,
          orders_files: files,
          orders_locations: order_locations,
          status: is_reservation ? 4 : 1,
          template_name: is_template ? order_no : null,
          is_template: !!is_template,
          is_edited_transport_set: false,
          order_title: order_title ? order_title : null,
          order_text: order_text ? order_text : null,
          id_routing: id_routing,
          orders_tags: selected_tags,
          customer_order_number: customer_order_number || "",
        };
        fetchOrder(type, data);
        // console.log(type, data)
      }
    }
  };

  const saveRouteCallback = (
    request,
    response,
    originObj,
    destinationObj,
    waypointsObj,
    routing_id
  ) => {
    setRoute_request(request);
    setRoute_response(response);
    setRoute_originObj(originObj);
    setRoute_destinationObj(destinationObj);
    setRoute_waypointsObj(waypointsObj);

    // Tylko dla pełnej wersji
    // W trybie edycji leci id_order
    // W trybie nowego zlecenia leci id_order = null
    if (!user_s) {
      let id_order = null;
      if (isEdit) {
        id_order = props.state.fetchOrderId;
      }
      fetchSetHeremapsRouting2(
        id_order,
        request,
        response,
        originObj,
        destinationObj,
        waypointsObj,
        routing_id
      );
    }
  };

  const fetchSetHeremapsRouting2 = async (
    order_id,
    request,
    response_param,
    originObj,
    destinationObj,
    waypointsObj,
    routing_id
  ) => {
    // console.log('fetchSetHeremapsRouting CALL!!!');
    // console.log('route_request', request);
    // console.log('route_response', response_param);
    // console.log('route_originObj', originObj);
    // console.log('route_destinationObj', destinationObj);
    // console.log('route_waypointsObj', waypointsObj);
    // console.log('routing_id', routing_id);
    if (
      request != null &&
      response_param != null &&
      originObj != null &&
      destinationObj != null &&
      waypointsObj != null
    ) {
      // console.log('fetchSetHeremapsRouting SA DANE OK - leci zapis');
      const response = await fetch(`${DBurl}/setHeremapsRouting`, {
        method: "post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
        body: JSON.stringify({
          mode: "insert",
          data: {
            id_order: order_id,
            id_routing: routing_id,
            request: JSON.stringify(request),
            response: JSON.stringify(response_param),
            origin: JSON.stringify(originObj),
            destination: JSON.stringify(destinationObj),
            waypoints: JSON.stringify(waypointsObj),
          },
        }),
      });
      setSaveRoute(false);
      const json = await response.json();
      if (json.success) {
        // console.log('log ok');

        // console.log('%%%%%ZAPIS routing_id!!!');
        // console.log('%%%%%json.data.routing_id:', json.data.routing_id);
        // console.log('%%%%%ZMIENNA routing_id:', routing_id);
        // console.log('%%%%%SATE id_routing:', id_routing);

        //if (id_routing === null) {
        // console.log('Zapis routing_id: ', json.data.routing_id);
        setIdRouting(json.data.routing_id);
        //}

        // Jeżeli zapisujemy na końcu z zamówieniem
        if (saveRouteAndSaveOrder) {
          // console.log('id_routing SAVE NOWY: ', json.data.routing_id);
          makeOrder(
            isEdit ? "update" : "insert",
            false,
            isEdit,
            false,
            json.data.routing_id
          );
          if (isEdit) {
            // console.log("przeładowanie do listy...")
          }
        }
      } else {
        // console.log('error');
      }
    } else {
      // console.log('fetchSetHeremapsRouting BRAK DANYCH - NIE leci zapis');
    }
  };

  const fetchSetHeremapsRoutingUPDATE = async (id_order, id_routing) => {
    // console.log('fetchSetHeremapsRoutingUPDATE CALL!!!');
    // console.log('id_order', id_order);
    // console.log('id_routing', id_routing);
    if (id_order != null && id_routing != null) {
      // console.log('fetchSetHeremapsRoutingUPDATE SA DANE OK - leci zapis');
      const response = await fetch(`${DBurl}/setHeremapsRouting`, {
        method: "post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
        body: JSON.stringify({
          mode: "update",
          data: {
            id_order: id_order,
            id_routing: id_routing,
          },
        }),
      });
      setSaveRoute(false);
      const json = await response.json();
      if (json.success) {
        // console.log('log ok');
      } else {
        // console.log('error');
      }
    } else {
      // console.log('fetchSetHeremapsRoutingUPDATE BRAK DANYCH - NIE leci zapis');
    }
  };

  const fetchSetHeremapsRouting = async (order_id) => {
    // console.log('fetchSetHeremapsRouting CALL!!!');
    // console.log('route_request', route_request);
    // console.log('route_response', route_response);
    // console.log('route_originObj', route_originObj);
    // console.log('route_destinationObj', route_destinationObj);
    // console.log('route_waypointsObj', route_waypointsObj);
    // if (route_request != null && route_response != null && route_originObj != null && route_destinationObj != null && route_waypointsObj != null) {
    // 	console.log('fetchSetHeremapsRouting SA DANE OK - leci zapis');
    // 	const response = await fetch(`${DBurl}/setHeremapsRouting`, {
    // 		method: "post",
    // 		credentials: 'include',
    // 		headers: {
    // 			"Content-Type": "application/json",
    // 			"X-CSRF-Token": props.user.csrf_token
    // 		},
    // 		body: JSON.stringify({
    // 			mode: 'insert',
    // 			data: {
    // 				id_order: order_id,
    // 				request: JSON.stringify(route_request),
    // 				response: JSON.stringify(route_response),
    // 				origin: JSON.stringify(route_originObj),
    // 				destination: JSON.stringify(route_destinationObj),
    // 				waypoints: JSON.stringify(route_waypointsObj)
    // 			}
    // 		})
    // 	})
    // 	setSaveRoute(false);
    // 	const json = await response.json()
    // 	if (json.success) {
    // 		// console.log('log ok');
    // 		setIdRouting(json.data.routing_id);
    // 	} else {
    // 		// console.log('error');
    // 	}
    // }
    // else {
    // 	console.log('fetchSetHeremapsRouting BRAK DANYCH - NIE leci zapis');
    // }
  };

  const fetchSetHeremapsRoutingDelete = async (order_id) => {
    const response = await fetch(`${DBurl}/setHeremapsRouting`, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "delete",
        data: {
          id_order: order_id,
        },
      }),
    });
    const json = await response.json();
    if (json.success) {
      // console.log('log ok');
    } else {
      // console.log('error');
    }
  };

  const settingTransportSet = (data) => {
    const src = newTransportSetFromEdit
      ? { ...newTransportSetFromEdit }
      : { ...data };
    if (src) {
      settransport_set(src);
      setVehicle(src.car_name);
      if (!user_s) {
        setSemiTrailer(src.trailer_name);
      }

      setDriver({ label: src?.driver1_name, value: src?.id_driver1 });
      setDriverTwo({ label: src?.driver2_name, value: src?.id_driver2 });
      setCar({ label: src?.car_name, value: src?.id_car });
      setTrailer({ label: src?.trailer_name, value: src?.id_trailer });
      // setDriverPhone(src.phone)
      setIsDedicated(src.isDedicated);
      setDedicated({
        company: src.dedicatedTo,
        timeStart: src.dedicatedTimeStart,
        timeEnd: src.dedicatedTimeEnd,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDialog(false);
  };

  useEffect(() => {
    if (Number(price) < 0) {
      setPrice(0);
    }
  }, [price]);

  useEffect(() => {
    if (Number(cost) < 0) {
      setCost(0);
    }
  }, [cost]);

  // useEffect(() => {
  // 	fetchSetHeremapsRoutingDelete(props.state.fetchOrderId);
  // }, [trace])

  useEffect(() => {
    if (!!Number(sum_kilometers) && !!Number(ratesPerKm)) {
      setCost((Number(sum_kilometers) * Number(ratesPerKm)).toFixed(2));
    }
  }, [sum_kilometers, ratesPerKm]);

  const getRatesPerKm = async function (id) {
    const response = await fetch(`${DBurl}/getRatesPerKm?id_contractor=${id}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setRatesPerKm(Number(json.data?.[0]?.rate_netto));
      set_id_ratesPerKm(json.data?.[0]?.id);
    } else {
      setOpenDialog(true);
      setDialogColor("error");
      setDialogContent(
        `Błąd pobierania stawki za kilometr: ${json.response}: ${response.responseDescription}`
      );
    }
    isLoading.current -= 1;
  };

  const showMessageToUser = (message, type) => {
    setOpenDialog(true);
    setDialogContent(message);
    setDialogColor(type);
  };

  const checkTraceError = () => {
    let is_error = false;
    trace.map((item, index) => {
      if (item.manual_adress === true) {
        if (item.type === "unloading") {
          if (item.address === undefined) {
            is_error = true;
          }
          if (item.address?.latitude === null) {
            is_error = true;
          }
          if (item.address?.longitude === null) {
            is_error = true;
          }
        } else if (item.type === "loading") {
          if (item.address === undefined) {
            is_error = true;
          }
          if (item.address?.latitude === null) {
            is_error = true;
          }
          if (item.address?.longitude === null) {
            is_error = true;
          }
        }
      } else {
        if (item.type === "unloading") {
          if (item.warehouse?.address === undefined) {
            is_error = true;
          }
          if (item.warehouse?.address?.latitude === null) {
            is_error = true;
          }
          if (item.warehouse?.address?.longitude === null) {
            is_error = true;
          }
        } else if (item.type === "loading") {
          if (item.warehouse?.address === undefined) {
            is_error = true;
          }
          if (item.warehouse?.address?.latitude === null) {
            is_error = true;
          }
          if (item.warehouse?.address?.longitude === null) {
            is_error = true;
          }
        }
      }

      if (item.type !== "unloading" && item.type !== "loading") {
        if (item.address === undefined) {
          is_error = true;
        }
      }
    });
    return is_error;
  };

  useEffect(() => {
    if (!!contractor.value) {
      getRatesPerKm(contractor.value);
    }
  }, [contractor.value]);

  useEffect(() => {
    if (Number(cargo_kilometers) < 0) {
      setcargo_kilometers(0);
    } else if (Number(access_kilometers) < 0) {
      setaccess_kilometers(0);
    } else if (
      Number(access_kilometers) < 0 ||
      cargo_kilometers === undefined
    ) {
      setsum_kilometers(0 + Number(access_kilometers));
    } else {
      setsum_kilometers(Number(cargo_kilometers) + Number(access_kilometers));
    }
  }, [cargo_kilometers, access_kilometers, trace]);

  const fetchTags = async function () {
    const response = await fetch(`${DBurl}/getTags`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setTags_list(
        json.data.map((e) => {
          return { value: e.id, label: e.name, color: e.color };
        })
      );
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
    }
    isLoading.current -= 1;
  };

  const fetchAddTags = async function (text, hex_color) {
    let newTagTmp = {
      name: text,
      color: hex_color,
    };
    const response = await fetch(`${DBurl}/setTags`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "insert",
        data: newTagTmp,
      }),
    });
    const json = await response.json();
    if (json.success) {
      if (json.data.new_id) {
        setOpenDialog(true);
        setDialogContent(`Nowy "${text}" tag został dodany do bazy tagów`);
        setDialogColor("success");
        setAdd_tag("");
        setColorPicker("");
        fetchTags();
        selected_tags.push(json.data.new_id);
      } else if (json.data.return === "duplicate") {
        setAdd_tag("");
        fetchTags();
        setColorPicker("");
        selected_tags.push(json.data.duplicate_id);
      }
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
    }
  };

  const fetchUpdateTags = async function (id, text, hex_color) {
    let upTagTmp = {
      id: id,
      name: text,
      color: hex_color,
    };
    const response = await fetch(`${DBurl}/setTags`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "update",
        data: upTagTmp,
      }),
    });
    const json = await response.json();
    if (json.success) {
      setOpenDialog(true);
      setDialogContent(`Zaktualizowano tag "${text}"`);
      setDialogColor("success");
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
    }
  };

  const fetchRemoveTags = async function (id) {
    const response = await fetch(`${DBurl}/setTags`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "delete",
        data: {
          ids: [id],
        },
      }),
    });
    const json = await response.json();
    if (json.success) {
      setOpenDialog(true);
      setDialogContent(`Tag usunięty`);
      setDialogColor("success");
      setAdd_tag("");
      fetchTags();
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
    }
  };

  const handleTagsChange = (e) => {
    setSelected_tags(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const handleTagAdd = (e) => {
    if (e !== "") {
      fetchAddTags(e, colorPicker);
    } else {
      setOpenDialog(true);
      setDialogContent(`Proszę wpisać Tag`);
      setDialogColor("error");
    }
  };

  const removeTag = (i, e) => {
    const newTempTags = selected_tags.filter((o) => {
      return o !== e;
    });
    setSelected_tags(() => newTempTags);
  };

  const getTrailersTypes = async function () {
    const response = await fetch(`${DBurl}/getTrailersTypes/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(
        `Błąd podczas pobierania listy typów przyczep // ${json.responseDescription} // ${json.response}  `
      );
      setDialogColor("error");
    }
    isLoading.current -= 1;
  };

  useEffect(() => {
    getTrailersTypes();
  }, []);

  useEffect(() => {
    fetchTags();
    return () => {
      setTags_list();
    };
  }, []);

  useEffect(() => {
    setSelected_tags(selected_tags);
    return () => {
      setSelected_tags();
    };
  }, [selected_tags]);

  const popover = {
    position: "absolute",
    zIndex: "99999",
    top: "60px",
    left: "2px",
  };
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };

  const colors = [
    "#33691e",
    "#2e7d32",
    "#43a047",
    "#588f27",
    "#45bf55",
    "#bedb39",
    "#00305a",
    "#004b8d",
    "#1510f0",
    "#04bfbf",
    "#35478c",
    "#0288d1",
    "#440505",
    "#4c1b1b",
    "#d50000",
    "#ff2d00",
    "#f44336",
    "#d23600",
    "#fa5b0f",
    "#d95100",
    "#fd7400",
    "#f2b705",
    "#fa9600",
    "#0f8299",
    "#36175e",
    "#4c1273",
    "#4a148c",
    "#512da8",
    "#7b1fa2",
    "#9c27b0",
    "#2a2c2b",
    "#374140",
    "#546e7a",
    "#292a2b",
    "#1c1d21",
    "#44464a",
  ];

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [colorPicker, setColorPicker] = useState("");

  const handleColorClick = (e) => {
    setDisplayColorPicker(true);
  };
  const handleCleanColorClick = (e) => {
    setColorPicker("");
  };

  const handleColorClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChangeComplete = (e) => {
    setColorPicker(e.hex);
  };

  const colorContrast = (rgb) => {
    rgb = rgb.split(/\(([^)]+)\)/)[1].replace(/ /g, "");
    var r = parseInt(rgb.split(",")[0], 10),
      g = parseInt(rgb.split(",")[1], 10),
      b = parseInt(rgb.split(",")[2], 10),
      a;

    if (rgb.split(",")[3] !== null) {
      a = parseInt(rgb.split(",")[3], 10);
    }

    var contrast =
      (Math.round(r * 299) + Math.round(g * 587) + Math.round(b * 114)) / 1000;
    return contrast >= 170 ? "black" : "white";
  };

  // lista typów naczep do tagów
  const ts_tlailer_type_tag_new = ["Chłodnia", "Plandeka"];

  // useEffect(() => {
  //   // if(!isEdit){
  //   // 	access_kilometers && setaccess_kilometers()
  //   // 	cargo_kilometers && setcargo_kilometers()
  //   // 	sum_kilometers && setsum_kilometers()
  //   // 	cost && setCost()
  //   // }

  //   if (transport_set !== undefined) {
  //     if (transport_set.trailer_type !== null) {
  //       if (transport_set?.trailer_type?.name !== undefined) {
  //         let tmpTransport_setTrailer_typeName =
  //           transport_set.trailer_type.name.replace(/ *\([^)]*\) */g, "");

  //         //czy jest na wymaganej liści
  //         const isTypeInTags = ts_tlailer_type_tag_new.some((o) => {
  //           if (o === tmpTransport_setTrailer_typeName) {
  //             return true;
  //           }
  //           return false;
  //         });

  //         //czy istnieje tag
  //         const isTypeTagExist = tags_list.some((o) => {
  //           if (o.label === tmpTransport_setTrailer_typeName) {
  //             return true;
  //           }
  //           return false;
  //         });

  //         if (isTypeInTags) {
  //           if (!isTypeTagExist) {
  //             let randomTagColor = `#${Math.floor(
  //               Math.random() * 16777215
  //             ).toString(16)}`;
  //             let tagName = tmpTransport_setTrailer_typeName;
  //             fetchAddTags(tagName, randomTagColor);
  //           } else {
  //             let tempTag = tags_list.filter((o) => {
  //               return o.label === tmpTransport_setTrailer_typeName;
  //             });
  //             if (selected_tags.includes(tempTag[0].value) === false) {
  //               selected_tags.push(tempTag[0].value);
  //             }
  //           }
  //         }
  //       }
  //     }
  //     // else{
  //     // 	console.log("brak zdefiniowanego typu TRANSPORT_SET")
  //     // }
  //   }
  // }, [transport_set, contractor, trace[0].warehouse]);

  // useEffect(() => {

  // 	console.log('trace.......')

  // }, [trace[0].warehouse])

  //sprawdzanie dubli na liscie wybranych tagów
  var selected_tags_duble = [];
  selected_tags.forEach(function (item) {
    if (selected_tags_duble.indexOf(item) < 0) {
      selected_tags_duble.push(item);
    }
  });

  // const [isMapApiLoaded, setIsMapApiLoaded] = useState(false)
  const [toCostValues, setToCostValues] = useState(false);
  const [prevOrders, setPrevOrders] = useState();
  const [isShowRequiredFields, setIsShowRequiredFields] = useState(false);
  // const [distanseLoading, setDistanseLoading] = useState(0)

  // getPrevOrders?ts=2022-11-14%2000:00:00&id_car=1529&id_driver=3870
  // getPrevOrders?ts=2022-11-17%2013:20:00&id_car=1529&id_driver=3870

  // const loader = new Loader({})
  // loader.load().then(() => setIsMapApiLoaded(true))

  const fetchPrevOrders = async function (id_driver, id_car, ts) {
    let tmpTS = moment(ts).valueOf();
    let tmpTSFormat = moment(tmpTS).format("YYYY-MM-DD HH:mm:ss");

    const response = await fetch(
      `${DBurl}/getPrevOrders?ts=${tmpTSFormat}&id_car=${id_car}&id_driver=${id_driver}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      if (json.data.length > 0) {
        // console.log('fetchPrev...', json.data[0])
        setPrevOrders(json.data[0]);

        if (toCostValues && json.data[0].order_stop_lattitude) {
          if (
            transport_set.id_driver1 === null &&
            transport_set.id_car === null
          ) {
            setOpenDialog(true);
            setDialogContent(
              "Zestaw transportowy nie posiada kierowcy lub pojazdu."
            );
            setDialogColor("error");
          } else {
            costCalculate("distance-access", trace, json.data[0]);
            // costCalculate('distance-loaded', trace, prevOrders)
          }
        } else {
          setOpenDialog(true);
          setDialogContent("Brak ostatniej lokalizacja zestawu.");
          setDialogColor("error");
        }
      } else {
        setOpenDialog(true);
        setDialogContent(
          "Brak ostatniego zlecenia potrzebnego do wyliczenia km dojazdowych, uzupełnij pole ręcznie bądź pozostaw puste.."
        );
        setDialogColor("error");
      }
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
    }
    isLoading.current -= 1;
  };

  const costCalculate = (type, trace, prev) => {
    const google = window.google;

    let origin_lat;
    let origin_lng;
    let destination_lat;
    let destination_lng;

    if (type === "distance-access") {
      origin_lat = Number(prev.order_stop_lattitude);
      origin_lng = Number(prev.order_stop_longitude);
      destination_lat = trace[0].manual_adress
        ? Number(trace[0].address.latitude)
        : Number(trace[0].warehouse.address.latitude);
      destination_lng = trace[0].manual_adress
        ? Number(trace[0].address.longitude)
        : Number(trace[0].warehouse.address.longitude);
    } else if (type === "distance-loaded") {
      // console.log('trace',trace,trace.length)

      origin_lat = Number(trace[0].warehouse.address.latitude);
      origin_lng = Number(trace[0].warehouse.address.longitude);
      destination_lat = Number(
        trace[trace.length - 1].warehouse.address.latitude
      );
      destination_lng = Number(
        trace[trace.length - 1].warehouse.address.longitude
      );
    }

    // console.log(type,origin_lat,origin_lng,'-',destination_lat,destination_lng)

    var origin = new google.maps.LatLng(origin_lat, origin_lng);
    var destination = new google.maps.LatLng(destination_lat, destination_lng);

    var service = new google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [destination],
        travelMode: "DRIVING",
        unitSystem: google.maps.UnitSystem.METRIC,
      },
      callback
    );

    function callback(response, status) {
      // console.log("WYNIK_______",response, status)

      if (type === "distance-access") {
        if (status === "OK") {
          if (response.rows[0].elements[0].status !== "ZERO_RESULTS") {
            setaccess_kilometers(
              Math.round(response.rows[0].elements[0].distance.value / 1000)
            );
          } else {
            setOpenDialog(true);
            setDialogContent(`Nie można obliczyć trasy dojazdowej.`);
            setDialogColor("error");
          }
        }
      } else if (type === "distance-loaded") {
        if (status === "OK") {
          if (response.rows[0].elements[0].status !== "ZERO_RESULTS") {
            setcargo_kilometers(
              Math.round(response.rows[0].elements[0].distance.value / 1000)
            );
          } else {
            setOpenDialog(true);
            setDialogContent(`Nie można obliczyć trasy załadunkowej.`);
            setDialogColor("error");
          }
        }
      }
    }
  };
  useEffect(() => {
    if (transport_set !== undefined) {
      if (transport_set.id_driver1 !== null && transport_set.id_car !== null) {
        let id_driver = transport_set.id_driver1;
        let id_car = transport_set.id_car;
        let ts = trace[0].date;
        // let nowDate = moment(new Date()).valueOf()

        if (
          id_driver !== undefined &&
          id_car !== undefined &&
          ts !== "" &&
          // ts >= nowDate &&
          ((trace[0].warehouse !== undefined &&
            trace[0].warehouse.address !== undefined) ||
            trace[0].address !== undefined)
        ) {
          setToCostValues(true);
        } else {
          setToCostValues(false);
          setPrevOrders();
        }
      } else {
        setToCostValues(false);
        setPrevOrders();
      }
    } else {
      setToCostValues(false);
      setPrevOrders();
    }
  }, [transport_set, trace]);

  // useEffect(() => {

  // 	// if(toCostValues === true){
  // 	// 	if(transport_set.id_driver1 !== null && transport_set.id_car !== null){
  // 	// 		fetchPrevOrders(transport_set.id_driver1, transport_set.id_car, trace[0].date)
  // 	// 	}
  // 	// }

  // }, [transport_set, trace, toCostValues])

  useEffect(() => {
    if (clearHooksState === true) {
      setClearHooksState(false);
    }
  }, [clearHooksState]);

  function round(num) {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return (Math.round(m) / 100) * Math.sign(num);
  }

  // console.log('cargo', cargo)

  function checkForId(obj) {
    return obj.hasOwnProperty("id");
  }

  // console.log('checkedGoods',checkedGoods)

  useEffect(() => {
    const isCargoId = props.state?.cargo?.some((obiekt) => obiekt.id);
    // !isCargoId && cargo[0].name === '' ? setCheckedGoods(isCargoId) : setCheckedGoods(true)

    if (cargo[0].name === undefined && isCargoId === false) {
      setCheckedGoods(false);
    }
    if (
      cargo[0].name !== undefined &&
      cargo[0].name !== "" &&
      isCargoId === true
    ) {
      setCheckedGoods(true);
    }
  }, [props.state]);

  // załączniki do zlecenia

  const handleSelectAllClick = (event, table, setSelected) => {
    if (event.target.checked) {
      const newSelecteds = table.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name, selected, setSelected, type) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    if (type === "car") {
      setSelected((prevState) => ({
        ...prevState,
        files_car: newSelected,
      }));
    }
    if (type === "driver1") {
      setSelected((prevState) => ({
        ...prevState,
        files_driver1: newSelected,
      }));
    }
    if (type === "driver2") {
      setSelected((prevState) => ({
        ...prevState,
        files_driver2: newSelected,
      }));
    }
    if (type === "trailer") {
      setSelected((prevState) => ({
        ...prevState,
        files_trailer: newSelected,
      }));
    }
  };

  const isSelected = (name, selected) => selected.indexOf(name) !== -1;

  const filteredByColumny = (data) =>
    Object.values(
      data.reduce((a, c) => {
        if (!a[c.col_name] || c.id > a[c.col_name].id) {
          a[c.col_name] = c;
        }
        return a;
      }, {})
    );

  const fetchGetTransportSetFiles = async function (transport_set) {
    const params = [];
    if (transport_set?.id_car) params.push(`id_car=${transport_set?.id_car}`);
    if (transport_set?.id_trailer)
      params.push(`id_trailer=${transport_set?.id_trailer}`);
    if (transport_set?.id_driver1)
      params.push(`id_driver1=${transport_set?.id_driver1}`);
    if (transport_set?.id_driver2)
      params.push(`id_driver2=${transport_set?.id_driver2}`);

    const paramToString = params.join("&");

    const response = await fetch(
      `${DBurl}/getTransportSetFiles?${paramToString}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      let tempFilesArr = {
        files_car: null,
        files_driver1: null,
        files_driver2: null,
        files_trailer: null,
      };

      if (json.data.files_car.length > 0) {
        tempFilesArr.files_car = filteredByColumny(json.data.files_car);
      }
      if (json.data.files_driver1.length > 0) {
        tempFilesArr.files_driver1 = filteredByColumny(json.data.files_driver1);
      }
      if (json.data.files_driver2.length > 0) {
        tempFilesArr.files_driver2 = filteredByColumny(json.data.files_driver2);
      }
      if (json.data.files_trailer.length > 0) {
        tempFilesArr.files_trailer = filteredByColumny(json.data.files_trailer);
      }

      setFilesArr(tempFilesArr);

      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
    }
    isLoading.current -= 1;
  };

  const addFiles = () => {
    const addToFiles = (selectedFiles, filesArr, kind) => {
      if (selectedFiles.length > 0) {
        let tempCarSelectedFiles = selectedFiles;
        let tmsFilesCar = filesArr;
        for (let i = 0; tempCarSelectedFiles.length > i; i++) {
          let isAdded = files.find(
            (obj) =>
              obj.id === tempCarSelectedFiles[i] ||
              obj.ref_file_id === tempCarSelectedFiles[i]
          );
          if (!!isAdded === false) {
            let tmp = tmsFilesCar.filter((o) => {
              return o.id === tempCarSelectedFiles[i];
            });
            let tmpToSend = tmp.map((o) => {
              return {
                name: o.filename,
                kind: kind,
                type: o.type,
                ref_file_id: o.id,
                col_name: o.col_name,
              };
            });
            setFiles((files) => [...files, tmpToSend[0]]);
          }
        }
      }
    };

    selectedFilesArr.files_car &&
      addToFiles(selectedFilesArr.files_car, filesArr.files_car, "car");
    selectedFilesArr.files_driver1 &&
      addToFiles(
        selectedFilesArr.files_driver1,
        filesArr.files_driver1,
        "driver1"
      );
    selectedFilesArr.files_driver2 &&
      addToFiles(
        selectedFilesArr.files_driver2,
        filesArr.files_driver2,
        "driver2"
      );
    selectedFilesArr.files_trailer &&
      addToFiles(
        selectedFilesArr.files_trailer,
        filesArr.files_trailer,
        "trailer"
      );
  };

  const updateCargoKilometers = (kilometers) => {
    setcargo_kilometers(kilometers);
  };

  useEffect(() => {
    if (transport_set !== undefined) {
      fetchGetTransportSetFiles(transport_set);
    }
  }, [transport_set]);

  useEffect(() => {
    let tmpCars =
      selectedFilesArr?.files_car.length > 0
        ? [...selectedFilesArr.files_car]
        : [];
    let tmpDriver1 =
      selectedFilesArr?.files_driver1.length > 0
        ? [...selectedFilesArr.files_driver1]
        : [];
    let tmpDriver2 =
      selectedFilesArr?.files_driver2.length > 0
        ? [...selectedFilesArr.files_driver2]
        : [];
    let tmpTrailer =
      selectedFilesArr?.files_trailer.length > 0
        ? [...selectedFilesArr.files_trailer]
        : [];

    function filterObjectsById(objects, car, d1, d2, trailer) {
      const filteredObjects = [];
      objects.forEach((obj) => {
        if (obj.kind === "car") {
          if (obj.ref_file_id === null || car.includes(obj.ref_file_id)) {
            filteredObjects.push(obj);
          }
        } else if (obj.kind === "driver1") {
          if (obj.ref_file_id === null || d1.includes(obj.ref_file_id)) {
            filteredObjects.push(obj);
          }
        } else if (obj.kind === "driver2") {
          if (obj.ref_file_id === null || d2.includes(obj.ref_file_id)) {
            filteredObjects.push(obj);
          }
        } else if (obj.kind === "trailer") {
          if (obj.ref_file_id === null || trailer.includes(obj.ref_file_id)) {
            filteredObjects.push(obj);
          }
        } else {
          if (obj.ref_file_id === null || trailer.includes(obj.ref_file_id)) {
            filteredObjects.push(obj);
          }
        }
      });
      return filteredObjects;
    }

    let tempFiles = filterObjectsById(
      files,
      tmpCars,
      tmpDriver1,
      tmpDriver2,
      tmpTrailer
    );

    if (isMounted && files.length !== tempFiles.length) {
      // setFiles(tempFiles)
    }

    return () => {
      setIsMounted(true);
    };
  }, [selectedFilesArr, files]);

  // -------------------
  // -------------------

  useEffect(() => {
    if (isLoading.current <= 0) {
      props.loading(false);
    }
  }, [isLoading.current]);

  useEffect(() => {
    isLoading.current = isEdit ? 7 : 4;
    props.loading(true);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      props.loading(false);
    }, 8000);
    return () => clearTimeout(timer);
  }, [props]);

  // -------------------
  // -------------------

  // numeracja

  const [orders, setOrders] = useState([]);

  const getOrders = async function () {
    const response = await fetch(`${DBurl}/getOrders/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      const tempData = [...json.data];
      setOrders(tempData);
      return json;
    }
  };

  useEffect(() => {
    getOrders();
  }, []);
  useEffect(() => {
    orders.length &&
      !props.state.isEdit &&
      props.user.is_automatic_order_number &&
      setOrder_no(
        getNewOrderNumber(orders, new Date(), props.user.sufix_order_name)
      );
  }, [orders]);

  const [tempFiles, setTempFiles] = useState([]);
  useEffect(() => {
    const newFiles = files.map((file) => {
      const newName = { user_filename: "" };
      for (const key in file) {
        newName[key] = file[key];
      }
      return newName;
    });
    setTempFiles(newFiles);
  }, [files.length]);

  const updateFileNames = (newName, index) => {
    const newFiles = [...tempFiles];
    newFiles[index].user_filename = `${newName}`;
    setTempFiles(newFiles);
    const arr = [...files];
    arr[index].user_filename = `${newName}`;
    setFiles(arr);
  };
  return (
    <>
      <Title title={props.state.tabName} />

      <Grid container spacing={5} className={stylesMod.cont}>
        <Grid item xs={8} className={stylesMod.br}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={stylesMod.inp}>
                <h6>Dane podstawowe </h6>
              </div>
            </Grid>

            <Grid item xs={12} className={stylesMod.labels}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <div
                    className={stylesMod.inp}
                    style={{ position: "relative" }}
                  >
                    <label>Dodaj tag</label>
                    <Input
                      className={stylesMod.inputText}
                      value={add_tag}
                      handleChange={(e) => setAdd_tag(e.target.value)}
                      name="Dodaj tag"
                      placeholder="Dodaj nowy..."
                      // error={error && add_tag.length < 1}
                    />
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <div
                    className={stylesMod.inp}
                    style={{ position: "relative" }}
                  >
                    <button
                      onClick={handleColorClick}
                      style={{
                        backgroundColor: colorPicker,
                        border: `2px solid ${colorPicker || "rgb(21, 47, 66)"}`,
                      }}
                      className={BTNstyles.BTNColor}
                    >
                      {colorPicker !== ""
                        ? "Zmień kolor taga"
                        : "Wybierz kolor taga"}
                    </button>
                    {displayColorPicker ? (
                      <div style={popover}>
                        <div style={cover} onClick={handleColorClose} />
                        <CompactPicker
                          disableAlpha={true}
                          color={colorPicker}
                          colors={colors}
                          onChangeComplete={handleChangeComplete}
                          placement="topLeft"
                        />
                      </div>
                    ) : null}
                    {/*
											<div className={BTNstyles.BTNCleanColorBox} style={colorPicker !== '' ? {display: 'block'} : {display: 'none'}}>
												<button
													onClick={ handleCleanColorClick }
													className={BTNstyles.BTNCleanColor}
												>
													<X className={stylesMod.clientAdd} />
												</button>
											</div>
										*/}
                  </div>
                </Grid>
                <Grid item md={1}>
                  <Button
                    color="primary"
                    className={`${BTNstyles.primary} ${BTNstyles.addTag}`}
                    component="button"
                    variant="contained"
                    disabled={
                      add_tag !== "" && colorPicker !== "" ? false : true
                    }
                    onClick={() => handleTagAdd(add_tag)}
                    // className={`${stylesMod.BtnIconTag}`}
                  >
                    <Plus className={stylesMod.clientAdd} />
                  </Button>
                </Grid>

                <Grid item xs={4}>
                  <div className={`${stylesMod.inp} multi_selector`}>
                    <label>Wybierz tag</label>
                    <Select
                      isMulti
                      value={tags_list.filter((obj) =>
                        selected_tags.includes(obj.value)
                      )}
                      className={`${stylesMod.select}`}
                      options={tags_list || []}
                      onChange={handleTagsChange}
                      name="tags_list"
                      placeholder="Wybierz tag..."
                      getOptionLabel={(e) => (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {e.label}
                          <span
                            className={BTNstyles.selectedColor_option}
                            style={{
                              backgroundColor: e.color,
                              borderColor: "#aaaaaa",
                            }}
                          ></span>
                        </div>
                      )}
                      getOptionValue={(option) => `${option.label}`}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          border: "1px solid #142f42",
                          boxShadow: "none",
                          "&:hover": {
                            border: "1px solid #142f42",
                          },
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused && "#142f42",
                          color: state.isFocused && "#fff",
                        }),
                      }}
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {selected_tags_duble &&
                    tags_list &&
                    selected_tags_duble !== null &&
                    selected_tags_duble.map((o, i) => {
                      let name = tags_list.filter((x) => {
                        return x.value === o;
                      });

                      let colorTmp =
                        name.length > 0 ? name[0].color : "#ffffff";
                      let colorBorderTmp =
                        name.length > 0 ? name[0].color : "#ffffff";

                      if (colorTmp === "#ffffff") {
                        colorBorderTmp = "#dfdfdf";
                      }

                      let colorTmpToRBG = colorTmp.substring(1);
                      let aRgbHex = colorTmpToRBG.match(/.{1,2}/g);
                      let aRgb = [
                        parseInt(aRgbHex[0], 16),
                        parseInt(aRgbHex[1], 16),
                        parseInt(aRgbHex[2], 16),
                      ];

                      let colorText = colorContrast(
                        String(`rgb(${aRgb[0]},${aRgb[1]},${aRgb[2]})`)
                      );

                      if (
                        tags_list.length > 0 &&
                        name[0]?.label !== undefined
                      ) {
                        return (
                          <div
                            key={i}
                            className={stylesMod.tagItem}
                            style={{
                              position: "relative",
                              color: colorText,
                              border: `2px solid ${colorBorderTmp}`,
                              backgroundColor: `rgb(${aRgb[0]} ${aRgb[1]} ${aRgb[2]} / 90%)`,
                            }}
                          >
                            {name.length > 0 && name[0].label}
                            <Button
                              color="primary"
                              component="div"
                              onClick={(e) => removeTag(i, o)}
                              className={`${stylesMod.BtnInInput_x}`}
                            >
                              <X
                                className={stylesMod.tagOut}
                                style={{ color: colorText }}
                              />
                            </Button>
                          </div>
                        );
                      }
                    })}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={2}>
              <div className={stylesMod.inp}>
                <label>Nr zlecenia</label>
                <Input
                  value={order_no}
                  handleChange={(e) => setOrder_no(e.target.value)}
                  name="order_no"
                  error={error && order_no.length < 1}
                />
              </div>
            </Grid>

            <Grid item xs={2}>
              <div className={stylesMod.inp}>
                <h6 className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                  Dane Klienta
                </h6>
                <label>Nr zlecenia od klienta</label>
                <Input
                  value={customer_order_number}
                  handleChange={(e) => setCustomer_order_number(e.target.value)}
                  name="customer_order_number"
                  error={error && customer_order_number.length < 1}
                />
              </div>
            </Grid>

            <Grid item xs={4} className={user_s ? "user_s" : "user_full"}>
              <div className={stylesMod.inp}>
                <label>Nazwa zlecenia</label>
                <Input
                  value={order_title}
                  handleChange={(e) => setOrder_title(e.target.value)}
                  name="order_title"
                  error={error && order_title.length < 1}
                />
              </div>
            </Grid>

            <Grid item xs={3} className={user_s ? "user_s" : "user_full"}>
              <div className={stylesMod.inp}>
                <label>Początek:</label>
                {setDateFrom}
              </div>
            </Grid>

            <Grid item xs={3} className={user_s ? "user_s" : "user_full"}>
              <div className={stylesMod.inp}>
                <label>Koniec:</label>
                {setDateTo}
              </div>
            </Grid>

            <Grid item xs={12} className={user_s ? "user_s" : "user_full"}>
              <div className={`${stylesMod.inp} ${"discription_textarea"}`}>
                <label>Treść zlecenia</label>
                {/* <Input value={order_text} handleChange={e => setOrder_text(e.target.value)} name="order_text" error={error && order_text.length < 1} /> */}
                <TextField
                  multiline
                  rows={4}
                  // defaultValue={order_text ? order_text : ''}
                  value={order_text ? order_text : ""}
                  error={error && order_text === ""}
                  name="order_text"
                  onChange={(e) => setOrder_text(e.target.value)}
                />
              </div>
            </Grid>

            <Grid item xs={3}>
              <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
                <div className={stylesMod.inp}>
                  <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                    Klient
                  </label>
                  <Select
                    isDisabled={user_s}
                    value={contractor}
                    className={`${stylesMod.select} ${
                      error && !contractor && stylesMod.error
                    } ${user_s ? stylesVersion.onlyfull : ""}`}
                    options={contractorOption}
                    onChange={(e) => {
                      setContractor(e);
                      setTrace([
                        {
                          id: "trace0",
                          type: "loading",
                          date: "",
                          time: "",
                          comment: "",
                          access_via_transport_docs: false,
                          cargo: [
                            {
                              name: "",
                              quantity: "",
                              unit: "",
                              palletExchange: false,
                              ldmSize: "",
                              details: "",
                              ADR: false,
                              ADRClass: "",
                              NumRef: "",
                            },
                          ],
                          warehouse: {},
                        },
                        {
                          id: "trace2",
                          type: "unloading",
                          date: "",
                          time: "",
                          comment: "",
                          access_via_transport_docs: false,
                          cargo: [
                            {
                              name: "",
                              quantity: "",
                              unit: "",
                              palletExchange: false,
                              ldmSize: "",
                              details: "",
                              ADR: false,
                              ADRClass: "",
                              NumRef: "",
                            },
                          ],
                        },
                      ]);
                      setToCostValues(false);
                    }}
                    name="contractor"
                    placeholder="Wybierz..."
                    components={{ ValueContainer }}
                    selectProps={props.addTab}
                    saveTab={saveTab}
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        border: "1px solid #142f42",
                        backgroundColor:
                          isShowRequiredFields &&
                          !toCostValues &&
                          !contractor &&
                          "#e1f3d3 !important",
                        borderColor:
                          isShowRequiredFields &&
                          !toCostValues &&
                          "#82cd47 !important",
                        boxShadow: "none",
                        "&:hover": {
                          border: "1px solid #142f42",
                        },
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused && "#142f42",
                        color: state.isFocused && "#fff",
                      }),
                    }}
                  />
                </div>
              </Tooltip>
            </Grid>

            <Grid
              item
              xs={2}
              style={{ maxWidth: "12.4999985%", flexBasis: "12.4999985%" }}
            >
              <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
                <div className={stylesMod.inp}>
                  <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                    Kwota całkowita
                  </label>
                  <Input
                    className={`${user_s ? stylesVersion.onlyfull : ""}`}
                    disabled={user_s}
                    value={price}
                    handleChange={(e) => {
                      setPrice(e.target.value);
                    }}
                    name="price"
                    type="number"
                    // error={error && (price === null || price === undefined || price === "")}
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid
              item
              xs={1}
              style={{ maxWidth: "12.4999985%", flexBasis: "12.4999985%" }}
            >
              <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
                <div className={stylesMod.inp}>
                  <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                    Waluta
                  </label>
                  <Select
                    value={currency}
                    isDisabled={user_s}
                    // className={`${stylesMod.select} ${(error && !contractor) && stylesMod.error} ${user_s ? stylesVersion.onlyfull : ''}`}
                    className={`${stylesMod.select} ${
                      user_s ? stylesVersion.onlyfull : ""
                    }`}
                    options={currencyOption}
                    onChange={(e) => {
                      setCurrency(e);
                    }}
                    name="currency"
                    placeholder="Wybierz..."
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        border: "1px solid #142f42",
                        boxShadow: "none",
                        "&:hover": {
                          border: "1px solid #142f42",
                        },
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused && "#142f42",
                        color: state.isFocused && "#fff",
                      }),
                    }}
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={12} className={stylesMod.labels}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <div className={stylesMod.inp}>
                    <label
                      className={`${user_s ? stylesVersion.onlyfull : ""}`}
                      style={{
                        position: "relative",
                        top: "-5px",
                        display: "flex",
                      }}
                    >
                      <input
                        type="checkbox"
                        className={`${styles.check2}`}
                        value={checkedGoods}
                        onChange={(e) => {
                          setCheckedGoods(e.target.checked);
                        }}
                        name="checkedGoods"
                        checked={checkedGoods}
                      />
                      Podaj szczegóły towaru
                    </label>
                    {/* <h5>Towary</h5> */}
                    <label
                      className={`${user_s ? stylesVersion.onlyfull : ""}`}
                    >
                      Nazwa towaru
                    </label>
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <div className={stylesMod.inp}>
                    <label
                      className={`${user_s ? stylesVersion.onlyfull : ""}`}
                    >
                      Ilość
                    </label>
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <div className={stylesMod.inp}>
                    <label
                      className={`${user_s ? stylesVersion.onlyfull : ""}`}
                    >
                      Jednostka
                    </label>
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <div className={stylesMod.inp}>
                    <label
                      className={`${user_s ? stylesVersion.onlyfull : ""}`}
                    >
                      Stawka
                    </label>
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <div className={stylesMod.inp}>
                    <label
                      className={`${user_s ? stylesVersion.onlyfull : ""}`}
                    >
                      Waluta
                    </label>
                  </div>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1} className={stylesMod.statusGrid}>
                  <div className={stylesMod.inp}>
                    <label
                      className={`${user_s ? stylesVersion.onlyfull : ""}`}
                    >
                      Status
                    </label>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            {cargoModule}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
                <div className={stylesMod.inp} style={{ position: "relative" }}>
                  <h6 className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                    Koszty
                  </h6>
                  <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                    Km dojazdowe
                  </label>
                  <Input
                    className={`${
                      user_s
                        ? stylesVersion.onlyfull
                        : isShowRequiredFields
                        ? "ActiveFields"
                        : ""
                    }`}
                    style={{ boxShadow: "2px 0 0 0 #000" }}
                    disabled={user_s}
                    // value={distanceAccess !== undefined ? distanceAccess[0]?.distance : access_kilometers}
                    value={
                      access_kilometers === 0 || access_kilometers === undefined
                        ? ""
                        : round(access_kilometers)
                    }
                    handleChange={(e) => {
                      setaccess_kilometers(e.target.value);
                    }}
                    name="access_kilometers"
                    type="number"
                    // error={error && (access_kilometers === null || access_kilometers === undefined || access_kilometers === "")}
                  />
                  {/* {distanceAccess !== undefined && <span className={stylesMod.distanceAccessDuration}>Czas: {distanceAccess[0]?.duration}</span>} */}
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={2}>
              <Tooltip
                title={
                  user_s
                    ? "Dostępne tylko w wersji pełnej"
                    : toCostValues === true && cargo_kilometers === ""
                    ? 'Aby wyliczyć "Km ładowne", sprawdź "Podgląd trasy"'
                    : ""
                }
              >
                <div className={stylesMod.inp} style={{ position: "relative" }}>
                  <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                    Km ładowne
                  </label>
                  <Input
                    className={`${user_s ? stylesVersion.onlyfull : ""}`}
                    disabled={true}
                    // value={distanceLoaded !== undefined ? distanceLoaded[0]?.distance : cargo_kilometers}
                    value={round(cargo_kilometers)}
                    handleChange={(e) => {
                      setcargo_kilometers(e.target.value);
                    }}
                    name="cargo_kilometers"
                    type="number"
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={2}>
              <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
                <div className={stylesMod.inp}>
                  <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                    Suma kilometrów
                  </label>
                  <Input
                    className={`${user_s ? stylesVersion.onlyfull : ""}`}
                    disabled={user_s}
                    value={round(sum_kilometers)}
                    handleChange={(e) => {
                      setsum_kilometers(e.target.value);
                    }}
                    name="sum_kilometers"
                    type="number"
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={2}>
              <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
                <div className={stylesMod.inp}>
                  <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                    Stawka za km
                  </label>
                  <Input
                    className={`${user_s ? stylesVersion.onlyfull : ""}`}
                    disabled={user_s}
                    value={ratesPerKm}
                    handleChange={(e) => {}}
                    name="ratesPerKm"
                    plus={!!contractor.value && !Number(ratesPerKm)}
                    plusFunc={() => {
                      setOpenModalRatesPerKm(true);
                    }}
                    edit={!!contractor.value && !!Number(ratesPerKm)}
                    editFunc={() => {
                      setOpenModalRatesPerKm(true);
                    }}
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={2}>
              <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
                <div className={stylesMod.inp}>
                  <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                    Koszt
                  </label>
                  <Input
                    className={`${user_s ? stylesVersion.onlyfull : ""}`}
                    disabled={user_s}
                    value={round(cost)}
                    handleChange={(e) => {}}
                    name="cost"
                    type="number"
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={2}>
              <Tooltip
                title={
                  user_s
                    ? "Dostępne tylko w wersji pełnej"
                    : toCostValues === false
                    ? "Uzupełnij wymagane pola do wyliczenia km dojazdowych"
                    : ""
                }
              >
                <div
                  className={BTNstyles.BOXNoLabelPosition}
                  onMouseOver={() =>
                    toCostValues === false && setIsShowRequiredFields(true)
                  }
                  onMouseLeave={() =>
                    toCostValues === false && setIsShowRequiredFields(false)
                  }
                >
                  <Button
                    color="primary"
                    className={`${BTNstyles.primary} ${BTNstyles.costCalculate}`}
                    component="button"
                    variant="contained"
                    disabled={!toCostValues}
                    onClick={() => {
                      if (toCostValues === true) {
                        if (
                          transport_set.id_driver1 !== null &&
                          transport_set.id_car !== null
                        ) {
                          fetchPrevOrders(
                            transport_set.id_driver1,
                            transport_set.id_car,
                            trace[0].date
                          );
                        }
                      }
                    }}
                  >
                    Wylicz km dojazdowe
                  </Button>
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <div className={stylesMod.inp}>
                <h6>Zestaw transportowy</h6>
                <Select
                  value={transport_set}
                  options={transport_setOption}
                  className={`${stylesMod.select} ${
                    error &&
                    !transport_set &&
                    stylesMod.error &&
                    stylesMod.error
                  }`}
                  onChange={(e) => {
                    settingTransportSet(e);
                    setCar({ label: e.car_name, value: e.id_car });
                    setDriver({ label: e.driver1_name, value: e.id_driver1 });
                    e.id_trailer !== null
                      ? setTrailer({
                          label: e.trailer_name,
                          value: e.id_trailer,
                        })
                      : setTrailer({ label: "", value: "" });
                    e.is_double_cast_of_drivers
                      ? setDriverTwo({
                          label: e.driver2_name,
                          value: e.id_driver2,
                        })
                      : setDriverTwo({ label: "", value: "" });
                  }}
                  name="transport_set"
                  components={{ ValueContainer }}
                  selectProps={props.addTab}
                  link="TransportSet"
                  saveTab={saveTab}
                  placeholder="Wybierz..."
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      border: "1px solid #142f42",
                      backgroundColor:
                        isShowRequiredFields &&
                        !toCostValues &&
                        !transport_set &&
                        "#e1f3d3 !important",
                      borderColor:
                        isShowRequiredFields &&
                        !toCostValues &&
                        "#82cd47 !important",
                      boxShadow: "none",
                      "&:hover": {
                        border: "1px solid #142f42",
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                    }),
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={5}>
              <div className={stylesMod.inp}>
                <label>Pojazd</label>
                <Select
                  value={car}
                  options={vehiclesData}
                  className={`${stylesMod.select} ${
                    error &&
                    !transport_set &&
                    stylesMod.error &&
                    stylesMod.error
                  }`}
                  onChange={(e) => {
                    setCar(e);
                    settransport_set("");
                  }}
                  name="car"
                  saveTab={saveTab}
                  placeholder="Wybierz..."
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      border: "1px solid #142f42",
                      backgroundColor:
                        isShowRequiredFields &&
                        !toCostValues &&
                        !transport_set &&
                        "#e1f3d3 !important",
                      borderColor:
                        isShowRequiredFields &&
                        !toCostValues &&
                        "#82cd47 !important",
                      boxShadow: "none",
                      "&:hover": {
                        border: "1px solid #142f42",
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                    }),
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={5}>
              <div className={stylesMod.inp}>
                <label>Naczepa</label>

                <Select
                  value={trailer}
                  options={trailerData}
                  className={`${stylesMod.select} ${
                    error &&
                    !transport_set &&
                    stylesMod.error &&
                    stylesMod.error
                  }`}
                  onChange={(e) => {
                    setTrailer(e);
                    settransport_set("");
                  }}
                  name="trailer"
                  saveTab={saveTab}
                  placeholder="Wybierz..."
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      border: "1px solid #142f42",
                      backgroundColor:
                        isShowRequiredFields &&
                        !toCostValues &&
                        !transport_set &&
                        "#e1f3d3 !important",
                      borderColor:
                        isShowRequiredFields &&
                        !toCostValues &&
                        "#82cd47 !important",
                      boxShadow: "none",
                      "&:hover": {
                        border: "1px solid #142f42",
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                    }),
                  }}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <div className={stylesMod.inp}>
                <label>Kierowca 1</label>

                <Select
                  value={driver}
                  options={driversData.filter(
                    (driv) => driv.id !== driverTwo?.value
                  )}
                  className={`${stylesMod.select} ${
                    error &&
                    !transport_set &&
                    stylesMod.error &&
                    stylesMod.error
                  }`}
                  onChange={(e) => {
                    setDriver({
                      label: `${e.firstname} ${e.surname}`,
                      value: e.id,
                    });
                    settransport_set("");
                  }}
                  name="driver"
                  saveTab={saveTab}
                  placeholder=""
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      border: "1px solid #142f42",
                      backgroundColor:
                        isShowRequiredFields &&
                        !toCostValues &&
                        !transport_set &&
                        "#e1f3d3 !important",
                      borderColor:
                        isShowRequiredFields &&
                        !toCostValues &&
                        "#82cd47 !important",
                      boxShadow: "none",
                      "&:hover": {
                        border: "1px solid #142f42",
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                    }),
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={5}>
              <div className={stylesMod.inp}>
                <label>Kierowca 2</label>

                <Select
                  value={driverTwo}
                  options={driversData.filter(
                    (driv) => driv.id !== driver?.value
                  )}
                  className={`${stylesMod.select} ${
                    error &&
                    !transport_set &&
                    stylesMod.error &&
                    stylesMod.error
                  }`}
                  onChange={(e) => {
                    setDriverTwo({
                      label: `${e.firstname} ${e.surname}`,
                      value: e.id,
                    });
                    settransport_set("");
                  }}
                  name="driverTwo"
                  saveTab={saveTab}
                  placeholder=""
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      border: "1px solid #142f42",
                      backgroundColor:
                        isShowRequiredFields &&
                        !toCostValues &&
                        !transport_set &&
                        "#e1f3d3 !important",
                      borderColor:
                        isShowRequiredFields &&
                        !toCostValues &&
                        "#82cd47 !important",
                      boxShadow: "none",
                      "&:hover": {
                        border: "1px solid #142f42",
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                    }),
                  }}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {(Array.isArray(advance) ? advance : [{}]).map((item, index) => (
              <>
                <AdvanceItem
                  user_s={user_s}
                  data={item}
                  key={index}
                  propkey={index}
                  addAdvance={() => {
                    setAdvance([...advance, { value: 0, currency: "" }]);
                  }}
                  removeAdvance={(id) => {
                    setAdvance(() => {
                      const tempAdvance = [...advance];
                      tempAdvance.splice(id, 1);
                      return tempAdvance.length > 0 ? tempAdvance : [{}];
                    });
                  }}
                  setCurrency={(value, index) => {
                    setAdvance(() => {
                      const tempAdvance = [...advance];
                      tempAdvance[index].currency = value;
                      return tempAdvance;
                    });
                  }}
                  setAdvance={(value, index) => {
                    setAdvance(() => {
                      const tempAdvance = [...advance];
                      tempAdvance[index].value = value;
                      return tempAdvance;
                    });
                  }}
                  currencyOption={currencyOption}
                />
              </>
            ))}
            <Grid item xs={12}>
              <Button
                disabled={user_s || transport_set}
                variant="outlined"
                color="primary"
                className={`${stylesMod.outBtn} ${
                  user_s ? stylesVersion.onlyfull : ""
                }`}
                onClick={() => {
                  checkTransportSet();
                }}
              >
                Sprawdź zestaw transportowy
                <Search />
              </Button>
            </Grid>
            <Grid item xs={10} className={stylesMod.gridButtonParent}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <input
                    disabled={user_s}
                    accept="*"
                    style={{ display: "none" }}
                    id="raised-button-file"
                    multiple
                    type="file"
                    onChange={(e) => {
                      const selectedFiles = Array.from(e.target.files);
                      setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
                      e.target.value = null; // Clear the selected files from the input
                    }}
                  />
                  <Tooltip
                    title={user_s ? "Dostępne tylko w wersji pełnej" : ""}
                  >
                    <label htmlFor="raised-button-file">
                      <Button
                        disabled={user_s}
                        variant="outlined"
                        color="primary"
                        component="span"
                        className={`${stylesMod.outBtn} ${
                          stylesMod.outBtnRed
                        } ${user_s ? stylesVersion.onlyfull : ""}`}
                      >
                        Podepnij dokumenty
                        <UploadCloud />
                      </Button>
                    </label>
                  </Tooltip>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    disabled={user_s || transport_set === undefined}
                    variant="outlined"
                    color="primary"
                    component="span"
                    className={`${stylesMod.outBtn} ${stylesMod.outBtnGreen}`}
                    onClick={(e) => {
                      setOpenModalFiles(true);
                      fetchGetTransportSetFiles(transport_set);
                    }}
                  >
                    Pliki z TMS
                    <UploadCloud />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              {/* <Button
								disabled={user_s}
								variant="outlined"
								color="primary"
								className={`${stylesMod.outBtn} ${user_s ? stylesVersion.onlyfull : ''}`}
								onClick={() => { checkTransportSet() }}>
									Sprawdź zestaw transportowy
									<Search />
							</Button> */}
            </Grid>
            <Grid item xs={12}>
              <div className={stylesMod.filesList}>
                {files.map((e, i) => {
                  if (e) {
                    return (
                      <>
                        <p className={stylesMod.filesItem}>
                          <NewOrderInput
                            key={e.user_filename & i}
                            index={i}
                            file={e}
                            handleChange={updateFileNames}
                          />

                          <div className={stylesMod.filesIconBox}>
                            {!!e.id &&
                              (e.type === "image/jpeg" ||
                                e.type === "image/png" ||
                                e.type === "application/pdf" ||
                                e.type === "application/vnd.ms-excel" ||
                                e.type ===
                                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                e.type === "text/csv") && (
                                <IconButton
                                  className={stylesMod.btnIcon2}
                                  onClick={() => {
                                    window.open(
                                      `${DBurl}/getFile?filename=${e.name}`,
                                      "_blank"
                                    );
                                  }}
                                  value={props.propKey}
                                >
                                  <DownloadCloud className={stylesMod.add} />
                                </IconButton>
                              )}
                            <IconButton
                              className={stylesMod.btnIcon2}
                              onClick={() => {
                                const tempFiles = [...files];
                                tempFiles.splice(i, 1);
                                setFiles(tempFiles);

                                if (e.kind === "car") {
                                  const index =
                                    selectedFilesArr.files_car.indexOf(
                                      e.ref_file_id
                                    );
                                  if (index !== -1) {
                                    selectedFilesArr.files_car.splice(index, 1);
                                  }
                                }
                                if (e.kind === "driver1") {
                                  const index =
                                    selectedFilesArr.files_driver1.indexOf(
                                      e.ref_file_id
                                    );
                                  if (index !== -1) {
                                    selectedFilesArr.files_driver1.splice(
                                      index,
                                      1
                                    );
                                  }
                                }
                                if (e.kind === "driver2") {
                                  const index =
                                    selectedFilesArr.files_driver2.indexOf(
                                      e.ref_file_id
                                    );
                                  if (index !== -1) {
                                    selectedFilesArr.files_driver2.splice(
                                      index,
                                      1
                                    );
                                  }
                                }
                                if (e.kind === "trailer") {
                                  const index =
                                    selectedFilesArr.files_trailer.indexOf(
                                      e.ref_file_id
                                    );
                                  if (index !== -1) {
                                    selectedFilesArr.files_trailer.splice(
                                      index,
                                      1
                                    );
                                  }
                                }
                              }}
                              value={props.propKey}
                            >
                              <XCircle className={stylesMod.remove} />
                            </IconButton>
                          </div>
                        </p>
                      </>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Title title="Punkty załadunku i rozładunku" />
      <div className={stylesMod.orderBox}>
        <div className={stylesMod.clearRow}>
          <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
            <div>
              <Button
                disabled={user_s}
                variant="outlined"
                color="primary"
                className={stylesMod.outBtn}
                onClick={() => {
                  addTrace("loading");
                }}
              >
                Dodaj załadunek <Download />
              </Button>
              <Button
                disabled={user_s}
                variant="outlined"
                color="primary"
                className={stylesMod.outBtn}
                onClick={() => {
                  addTrace("unloading");
                }}
              >
                Dodaj rozładunek <Upload />
              </Button>
              <Button
                disabled={user_s}
                variant="outlined"
                color="primary"
                className={stylesMod.outBtn}
                onClick={() => {
                  addTrace("stop");
                }}
              >
                Dodaj miejsce <MapPin />
              </Button>
              <Button
                disabled={user_s}
                variant="contained"
                color="primary"
                className={stylesMod.outBtn}
                style={{ backgroundColor: "rgba(20, 47, 66, 1)" }}
                onClick={() => {
                  if (
                    transport_set !== undefined &&
                    transport_set.car_profile !== undefined &&
                    transport_set.car_profile !== null
                  ) {
                    if (checkError()) {
                      setError(true);
                      setOpenDialog(true);
                      setDialogColor("error");
                      setDialogContent(
                        "Uzupełnij pola i sprawdź ilości towarów"
                      );
                    } else {
                      if (
                        transport_set !== undefined &&
                        transport_set.car_profile != undefined &&
                        transport_set.car_profile !== null
                      ) {
                        if (checkTraceError()) {
                          showMessageToUser(
                            "Niepoprawne punkty zlecenia! Aby wyznaczyć trasę prosimy o zdefiniowanie poprawnych punktów zlecenia.",
                            "error"
                          );
                        }

                        if (
                          transport_set.car_profile.ntruckweighttotal !==
                            null &&
                          transport_set.car_profile.ntruckweightaxle !== null &&
                          transport_set.car_profile.ntruckheight !== null &&
                          transport_set.car_profile.ntruckwidth !== null &&
                          transport_set.car_profile.ntrucklenght !== null &&
                          !checkTraceError() &&
                          transport_set.car_profile.h_type !== null &&
                          transport_set.car_profile.h_axle_count !== null &&
                          transport_set.car_profile.h_trailer_count !== null &&
                          transport_set.car_profile.h_tunel_category !== null &&
                          transport_set.car_profile
                            .h_shipped_hazardous_goods !== null
                        ) {
                          setOpenModalShowRoute(true);
                        }
                      }
                      // Brak profilu - sam zapis zlecenia
                      else {
                        // makeOrder(isEdit ? 'update' : 'insert', false, isEdit, false)
                        if (transport_set?.label !== undefined) {
                          setOpenModalProfilQuestion(true);
                          setOpenAddModalProfileData(transport_set);
                        } else {
                          showMessageToUser(
                            "Wybierz zestaw transportowy.",
                            "error"
                          );
                        }
                        // if (isEdit) {
                        // 	console.log("przeładowanie do listy...")
                        // }
                      }
                    }
                  } else {
                    if (transport_set?.label !== undefined) {
                      setOpenModalProfilQuestion(true);
                      setOpenAddModalProfileData(transport_set);
                    } else {
                      showMessageToUser(
                        "Wybierz zestaw transportowy.",
                        "error"
                      );
                    }
                    // {console.log('transport_set',transport_set)}
                    // showMessageToUser(
                    //   "Brak profilu pojazdu! Aby wyznaczyć trasę prosimy o zdefiniowanie profilu pojazdu w zakładcę konfiguracja pojazdu.",
                    //   "error"
                    // );
                  }
                }}
              >
                Podgląd trasy <Map />
              </Button>
            </div>
          </Tooltip>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {trace.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Trace
                          clearHooksState={clearHooksState}
                          index={index}
                          isShowRequiredFields={isShowRequiredFields}
                          toCostValues={toCostValues}
                          user_s={user_s}
                          key={index}
                          type={item.type}
                          subtype={item.subtype}
                          data={item}
                          propKey={index}
                          handleTrace={handleTrace}
                          handleTraceNew={handleTraceNew}
                          handleSelect={handleSelectTrace}
                          checkStatus={checkStatus}
                          copyTrace={copyTrace}
                          moveTrace={moveTrace}
                          removeTrace={removeTrace}
                          addTraceCargo={addTraceCargo}
                          removeTraceCargo={removeTraceCargo}
                          goodsOptions={traceGoodsOptions}
                          addTab={props.addTab}
                          saveTab={saveTab}
                          warehousesOptions={warehousesOptions}
                          user={props.user}
                          error={error}
                          setOpenModalNewWarehouse={setOpenModalNewWarehouse}
                          triggerMainRouteChange={triggerMainRouteChange}
                          setaccess_kilometers={setaccess_kilometers}
                          setcargo_kilometers={setcargo_kilometers}
                          setsum_kilometers={setsum_kilometers}
                          setCost={setCost}
                          setTrace={setTrace}
                          trace={trace}
                          checkedGoods={checkedGoods}
                          setOpenModalShowPoint={setOpenModalShowPoint}
                          updateItem={updateItem}
                          setUpdateItem={setUpdateItem}
                          setTraceIndex={setTraceIndex}
                          setTraceData={setTraceData}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <div className={BTNstyles.btnRow} style={{ paddingBottom: "5px" }}>
          <Button
            variant="contained"
            size="large"
            className={`${BTNstyles.btn} ${BTNstyles.err}`}
            onClick={() => {
              setOpenModalCancel(true);
            }}
          >
            Anuluj <X className={BTNstyles.downBtnIco} />
          </Button>

          <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
            <div>
              <Button
                disabled={user_s ? true : false}
                variant="contained"
                color="primary"
                size="large"
                className={BTNstyles.btn}
                onClick={() => {
                  makeOrder(isEdit ? "update" : "insert", true, isEdit, true);
                }}
              >
                Utwórz szablon
                <Upload className={BTNstyles.downBtnIco} />
              </Button>
            </div>
          </Tooltip>

          <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
            <div>
              <Button
                tooltip={"asdasdadsswdad"}
                disabled={user_s ? true : false}
                variant="contained"
                color="primary"
                size="large"
                className={BTNstyles.btn}
                onClick={() => {
                  makeOrder(isEdit ? "update" : "insert", true, isEdit, false);
                }}
              >
                Utwórz rezerwacje
                <Upload className={BTNstyles.downBtnIco} />
              </Button>
            </div>
          </Tooltip>

          <Button
            variant="contained"
            color="primary"
            size="large"
            className={BTNstyles.btn}
            onClick={() => {
              // Okno z routingiem HERE
              // Tylko wersja pełna
              if (!user_s) {
                checkTransportSet();
                if (checkError()) {
                  setError(true);
                  setOpenDialog(true);
                  setDialogColor("error");
                  setDialogContent("Uzupełnij pola i sprawdź ilości towarów");
                } else {
                  if (
                    transport_set !== undefined &&
                    transport_set.car_profile != undefined &&
                    transport_set.car_profile !== null
                  ) {
                    if (checkTraceError()) {
                      showMessageToUser(
                        "Niepoprawne punkty zlecenia! Aby wyznaczyć trasę prosimy o zdefiniowanie poprawnych punktów zlecenia.",
                        "error"
                      );
                    }

                    if (
                      transport_set.car_profile.ntruckweighttotal !== null &&
                      transport_set.car_profile.ntruckweightaxle !== null &&
                      transport_set.car_profile.ntruckheight !== null &&
                      transport_set.car_profile.ntruckwidth !== null &&
                      transport_set.car_profile.ntrucklenght !== null &&
                      !checkTraceError() &&
                      transport_set.car_profile.h_type !== null &&
                      transport_set.car_profile.h_axle_count !== null &&
                      transport_set.car_profile.h_trailer_count !== null &&
                      transport_set.car_profile.h_tunel_category !== null &&
                      transport_set.car_profile.h_shipped_hazardous_goods !==
                        null
                    ) {
                      setOpenModalShowRoute(true);
                      setSaveRouteAndSaveOrder(true);
                    }
                  }
                  // Brak profilu - sam zapis zlecenia
                  else {
                    // makeOrder(isEdit ? 'update' : 'insert', false, isEdit, false)
                    if (transport_set?.label !== undefined) {
                      setOpenModalProfilQuestion(true);
                      setOpenAddModalProfileData(transport_set);
                    } else {
                      showMessageToUser(
                        "Wybierz zestaw transportowy.",
                        "error"
                      );
                    }
                    // if (isEdit) {
                    // 	console.log("przeładowanie do listy...")
                    // }
                  }
                }
              }
              // Wersja S
              else {
                makeOrder(isEdit ? "update" : "insert", false, isEdit, false);
                if (isEdit) {
                  // console.log("przeładowanie do listy...")
                }
              }

              // makeOrder(isEdit ? 'update' : 'insert', false, isEdit, false)
              // if (isEdit) {
              // 	console.log("przeładowanie do listy...")
              // }
            }}
          >
            {isEdit ? "Zapisz zmiany" : "Utwórz zlecenie"}{" "}
            <ArrowRight className={BTNstyles.downBtnIco} />
          </Button>
        </div>
      </div>

      <AddProfile
        open={openAddModalProfile}
        setOpen={setOpenAddModalProfile}
        data={openAddModalProfileData}
        user={props.user}
        editProfile={editProfile}
        setEditProfile={setEditProfile}
        refresh={getSets}
        setOpenSnack={setOpenDialog}
        setSnackContent={setDialogContent}
        setSnackColor={setDialogColor}
        newOrder={true}
      />

      <Dialog
        open={openModalProfilQuestion}
        onClose={() => {
          setOpenModalProfilQuestion(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Profil pojazdu?{" "}
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              setOpenModalProfilQuestion(false);
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Grid container>
              <Grid item xs={12}>
                Pojazd (
                <b style={{ color: "#129865" }}>{transport_set?.car_name}</b>) w
                wybranym zestawie transportowym nie ma zdefiniowanego "Profilu
                pojazdu", który jest wymaganego do wyznaczenia trasy.
                <br />
                <br />
              </Grid>
              {/* <Grid item xs={12}><b style={{color: '#129865', fontSize: '18px'}}>{transport_set?.label}</b></Grid> */}
              <Grid item xs={12}>
                Czy chcesz teraz zdefiniować profil danego pojazdu?
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={BTNstyles.btn}
            onClick={(e) => {
              setOpenModalProfilQuestion(false);
            }}
          >
            Nie
          </Button>
          <Button
            variant="contained"
            size="large"
            className={`${BTNstyles.btn} ${BTNstyles.grn}`}
            onClick={(e) => {
              setOpenModalProfilQuestion(false);
              setOpenAddModalProfile(true);
            }}
            color="primary"
          >
            Tak, zdefiniuj profil
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="xl"
        open={openModalFiles}
        onClose={() => {
          setOpenModalFiles(false);
          // cleanFiles()
        }}
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Podepnij dokumenty z bazy TMS
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              setOpenModalFiles(false);
              // cleanFiles()
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={3} className={styles.tableBox_border}>
              <h5>Dokumenty pojazdu</h5>
              {filesArr.files_car &&
              filesArr.files_car !== null &&
              filesArr.files_car.length > 0 ? (
                <TableContainer className={styles.stickyTable}>
                  <Table
                    className={styles.modalTable}
                    stickyHeader
                    aria-labelledby="Dokumenty pojazdu"
                    size="small"
                    aria-label="enhanced table"
                  >
                    <TableHead className={styles.tabHead}>
                      <TableRow>
                        <TableCell
                          className={`${styles.doc_checkbox} ${styles.cell_modalTable}`}
                        >
                          <Checkbox
                            indeterminate={
                              selectedFilesArr.files_car.length > 0 &&
                              selectedFilesArr.files_car.length <
                                filesArr.files_car.length
                            }
                            checked={
                              filesArr.files_car.length > 0 &&
                              selectedFilesArr.files_car.length ===
                                filesArr.files_car.length
                            }
                            onChange={(e) =>
                              handleSelectAllClick(
                                e,
                                filesArr.files_car,
                                setSelectedFilesArr
                              )
                            }
                            inputProps={{ "aria-label": "select all id" }}
                          />
                        </TableCell>

                        <TableCell
                          className={styles.cell_modalTable}
                          style={{ padding: "0" }}
                        >
                          Nazwa pliku
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filesArr.files_car.map((row, i) => {
                        const isItemSelected = isSelected(
                          row.id,
                          selectedFilesArr.files_car
                        );
                        const labelId = `enhanced-table-checkbox-${i}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              handleClick(
                                event,
                                row.id,
                                selectedFilesArr.files_car,
                                setSelectedFilesArr,
                                "car"
                              )
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                          >
                            <TableCell className={styles.doc_checkbox}>
                              <Checkbox
                                checked={isItemSelected}
                                color="primary"
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </TableCell>
                            <TableCell
                              component="td"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row.col_name}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className={styles.noData}>Brak plików..</div>
              )}
            </Grid>

            <Grid item xs={3} className={styles.tableBox_border}>
              <h5>Dokumenty kierowcy 1</h5>
              {filesArr.files_driver1 &&
              filesArr.files_driver1 !== null &&
              filesArr.files_driver1.length > 0 ? (
                <TableContainer className={styles.stickyTable}>
                  <Table
                    className={styles.modalTable}
                    stickyHeader
                    aria-labelledby="Dokumenty pojazdu"
                    size="small"
                    aria-label="enhanced table"
                  >
                    <TableHead className={styles.tabHead}>
                      <TableRow>
                        <TableCell
                          className={`${styles.doc_checkbox} ${styles.cell_modalTable}`}
                        >
                          <Checkbox
                            indeterminate={
                              selectedFilesArr.files_driver1.length > 0 &&
                              selectedFilesArr.files_driver1.length <
                                filesArr.files_driver1.length
                            }
                            checked={
                              filesArr.files_driver1.length > 0 &&
                              selectedFilesArr.files_driver1.length ===
                                filesArr.files_driver1.length
                            }
                            onChange={(e) =>
                              handleSelectAllClick(
                                e,
                                filesArr.files_driver1,
                                setSelectedFilesArr
                              )
                            }
                            inputProps={{ "aria-label": "select all id" }}
                          />
                        </TableCell>

                        <TableCell
                          className={styles.cell_modalTable}
                          style={{ padding: "0" }}
                        >
                          Nazwa pliku
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filesArr.files_driver1.map((row, i) => {
                        const isItemSelected = isSelected(
                          row.id,
                          selectedFilesArr.files_driver1
                        );
                        const labelId = `enhanced-table-checkbox-${i}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              handleClick(
                                event,
                                row.id,
                                selectedFilesArr.files_driver1,
                                setSelectedFilesArr,
                                "driver1"
                              )
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                          >
                            <TableCell className={styles.doc_checkbox}>
                              <Checkbox
                                checked={isItemSelected}
                                color="primary"
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </TableCell>
                            <TableCell
                              component="td"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row.col_name}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className={styles.noData}>Brak plików..</div>
              )}
            </Grid>

            <Grid item xs={3} className={styles.tableBox_border}>
              <h5>Dokumenty kierowcy 2</h5>
              {filesArr.files_driver2 &&
              filesArr.files_driver2 !== null &&
              filesArr.files_driver2.length > 0 ? (
                <TableContainer className={styles.stickyTable}>
                  <Table
                    className={styles.modalTable}
                    stickyHeader
                    aria-labelledby="Dokumenty pojazdu"
                    size="small"
                    aria-label="enhanced table"
                  >
                    <TableHead className={styles.tabHead}>
                      <TableRow>
                        <TableCell
                          className={`${styles.doc_checkbox} ${styles.cell_modalTable}`}
                        >
                          <Checkbox
                            indeterminate={
                              selectedFilesArr.files_driver2.length > 0 &&
                              selectedFilesArr.files_driver2.length <
                                filesArr.files_driver2.length
                            }
                            checked={
                              filesArr.files_driver2.length > 0 &&
                              selectedFilesArr.files_driver2.length ===
                                filesArr.files_driver2.length
                            }
                            onChange={(e) =>
                              handleSelectAllClick(
                                e,
                                filesArr.files_driver2,
                                setSelectedFilesArr
                              )
                            }
                            inputProps={{ "aria-label": "select all id" }}
                          />
                        </TableCell>

                        <TableCell
                          className={styles.cell_modalTable}
                          style={{ padding: "0" }}
                        >
                          Nazwa pliku
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filesArr.files_driver2.map((row, i) => {
                        const isItemSelected = isSelected(
                          row.id,
                          selectedFilesArr.files_driver1
                        );
                        const labelId = `enhanced-table-checkbox-${i}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              handleClick(
                                event,
                                row.id,
                                selectedFilesArr.files_driver2,
                                setSelectedFilesArr,
                                "driver2"
                              )
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                          >
                            <TableCell className={styles.doc_checkbox}>
                              <Checkbox
                                checked={isItemSelected}
                                color="primary"
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </TableCell>
                            <TableCell
                              component="td"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row.col_name}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className={styles.noData}>Brak plików..</div>
              )}
            </Grid>

            <Grid item xs={3} className={styles.tableBox_border}>
              <h5>Dokumenty naczepy</h5>
              {filesArr.files_trailer &&
              filesArr.files_trailer !== null &&
              filesArr.files_trailer.length > 0 ? (
                <TableContainer className={styles.stickyTable}>
                  <Table
                    className={styles.modalTable}
                    stickyHeader
                    aria-labelledby="Dokumenty pojazdu"
                    size="small"
                    aria-label="enhanced table"
                  >
                    <TableHead className={styles.tabHead}>
                      <TableRow>
                        <TableCell
                          className={`${styles.doc_checkbox} ${styles.cell_modalTable}`}
                        >
                          <Checkbox
                            indeterminate={
                              selectedFilesArr.files_trailer.length > 0 &&
                              selectedFilesArr.files_trailer.length <
                                filesArr.files_trailer.length
                            }
                            checked={
                              filesArr.files_trailer.length > 0 &&
                              selectedFilesArr.files_trailer.length ===
                                filesArr.files_trailer.length
                            }
                            onChange={(e) =>
                              handleSelectAllClick(
                                e,
                                filesArr.files_trailer,
                                setSelectedFilesArr
                              )
                            }
                            inputProps={{ "aria-label": "select all id" }}
                          />
                        </TableCell>

                        <TableCell
                          className={styles.cell_modalTable}
                          style={{ padding: "0" }}
                        >
                          Nazwa pliku
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filesArr.files_trailer.map((row, i) => {
                        const isItemSelected = isSelected(
                          row.id,
                          selectedFilesArr.files_trailer
                        );
                        const labelId = `enhanced-table-checkbox-${i}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              handleClick(
                                event,
                                row.id,
                                selectedFilesArr.files_trailer,
                                setSelectedFilesArr,
                                "trailer"
                              )
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                          >
                            <TableCell className={styles.doc_checkbox}>
                              <Checkbox
                                checked={isItemSelected}
                                color="primary"
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </TableCell>
                            <TableCell
                              component="td"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row.col_name}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className={styles.noData}>Brak plików..</div>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={`${BTNstyles.btn} ${BTNstyles.err}`}
            onClick={(e) => {
              setOpenModalFiles(false);
            }}
          >
            Anuluj <X className={BTNstyles.downBtnIco} />
          </Button>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={BTNstyles.btn}
            onClick={(e) => {
              setOpenModalFiles(false);
              addFiles();
            }}
          >
            Dodaj pliki <Upload className={BTNstyles.downBtnIco} />
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="xl"
        style={{ height: "100%" }}
        open={openModalShowRoute}
        onClose={() => {
          setOpenModalShowRoute(false);
        }}
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Podgląd trasy
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              setOpenModalShowRoute(false);
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ShowRouteMapDraggable
            trace={trace}
            setTrace={setTrace}
            updateCargoKilometers={updateCargoKilometers}
            transport_set={transport_set}
            order_no={order_no}
            order_id={isEdit ? props.state.fetchOrderId : null}
            routing_id={id_routing}
            saveRoute={saveRoute}
            csrf_token={props.user.csrf_token}
            saveRouteCallback={saveRouteCallback}
            isEdit={isEdit}
            mainRouteChange={mainRouteChange}
            cargo_kilometers={cargo_kilometers}
            setcargo_kilometers={setcargo_kilometers}
            cost={cost}
            ratesPerKm={ratesPerKm}
            round={round}
            exchangeRates={exchangeRates}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={`${BTNstyles.btn} ${BTNstyles.err}`}
            onClick={(e) => {
              setOpenModalShowRoute(false);
              setSaveRoute(false);
              // Wyzeruj trigger zmiany trasy
              setMainRouteChange(false);
            }}
          >
            Anuluj <X className={BTNstyles.downBtnIco} />
          </Button>
          {!saveRouteAndSaveOrder && (
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={BTNstyles.btn}
              onClick={(e) => {
                setSaveRoute(true);
                setOpenModalShowRoute(false);
                // Wyzeruj trigger zmiany trasy
                setMainRouteChange(false);
              }}
            >
              Zapisz trasę <Upload className={BTNstyles.downBtnIco} />
            </Button>
          )}
          {saveRouteAndSaveOrder && (
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={BTNstyles.btn}
              onClick={(e) => {
                setSaveRoute(true);
                setOpenModalShowRoute(false);
                // Wyzeruj trigger zmiany trasy
                setMainRouteChange(false);

                // console.log('id_routing SAVE: ', id_routing);

                // setTimeout(() => {
                // 	console.log('makeOrder CALL!!!');
                // 	makeOrder(isEdit ? 'update' : 'insert', false, isEdit, false)
                // 	if (isEdit) {
                // 		console.log("przeładowanie do listy...")
                // 	}
                // }, 500);
              }}
            >
              Zatwierdź trasę i zapisz zamówienie
              <Upload className={BTNstyles.downBtnIco} />
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="xl"
        style={{ height: "100%" }}
        open={openModalShowPoint}
        onClose={() => {
          setOpenModalShowPoint(false);
        }}
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Podgląd trasy
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              setOpenModalShowPoint(false);
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ShowPointOnMap
            csrf_token={props.user.csrf_token}
            propKey={traceIndex}
            setTrace={setTrace}
            handleTrace={handleTrace}
            traceData={traceData}
            newTraceAddress={newTraceAddress}
            setNewTraceAddress={setNewTraceAddress}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={`${BTNstyles.btn} ${BTNstyles.err}`}
            onClick={(e) => {
              setOpenModalShowPoint(false);
            }}
          >
            Anuluj <X className={BTNstyles.downBtnIco} />
          </Button>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={BTNstyles.btn}
            onClick={(e) => {
              setOpenModalShowPoint(false);
              setNewTraceAddress(true);
            }}
          >
            Zapisz punkt <Download className={BTNstyles.downBtnIco} />
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openModalCancel}
        onClose={() => {
          setOpenModalCancel(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Czy napewno chcesz anulować?{" "}
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              setOpenModalCancel(false);
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Czy napewno chcesz anulować?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={BTNstyles.btn}
            onClick={(e) => {
              setOpenModalCancel(false);
            }}
          >
            Anuluj
          </Button>
          <Button
            variant="contained"
            size="large"
            className={`${BTNstyles.btn} ${BTNstyles.err}`}
            onClick={(e) => {
              setOpenModalCancel(false);
              clearHooks();
              setClearHooksState(true);
            }}
            color="primary"
          >
            Usuń <X className={BTNstyles.downBtnIco} />
          </Button>
        </DialogActions>
      </Dialog>

      <DialModal
        open={carUsedModal}
        TransitionComponent={Transition}
        onClose={() => {
          setCarUsedModal(false);
        }}
        title="Auto w użyciu"
        text="Auto obecnie ma już zaplanowaną trasę. Czy chcesz dodać zlecenie pomimo to?"
        closeClick={(e) => {
          setCarUsedModal(false);
        }}
        btn1Click={(e) => {
          setCarUsedModal(false);
          setOpenDialog(true);
          setDialogContent("Zlecenie stworzone");
          setDialogColor("success");
        }}
        btn1Text="Tak"
        btn1Class={`${BTNstyles.btn} ${BTNstyles.grn}`}
        btn1Ico={<ArrowRight className={BTNstyles.downBtnIco} />}
      />

      <DialModal
        open={dedicatedModal}
        TransitionComponent={Transition}
        onClose={() => {
          setDedicatedModal(false);
        }}
        title="Auto w użyciu"
        text={`Auto jest dedykowane dla firmy ${dedicated.company} od ${dedicated.timeStart} do ${dedicated.timeEnd}. Czy chcesz go użyć pomimo to?`}
        closeClick={(e) => {
          setDedicatedModal(false);
        }}
        btn1Click={(e) => {
          setDedicatedModal(false);
          setOpenDialog(true);
          setDialogContent("Zlecenie stworzone");
          setDialogColor("success");
        }}
        btn1Text="Tak"
        btn1Class={`${BTNstyles.btn} ${BTNstyles.grn}`}
        btn1Ico={<ArrowRight className={BTNstyles.downBtnIco} />}
      />

      <Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={dialogColor}>
          {dialogContent}
        </Alert>
      </Snackbar>

      <NewWarehouse
        open={openModalNewWarehouse}
        setOpen={setOpenModalNewWarehouse}
        // data={modalNewWarehouseData}
        edit={false}
        setSnackContent={setDialogContent}
        setOpenSnack={setOpenDialog}
        user={props.user}
        fetchWarehouse={() => {
          getWarehouses(contractor.value);
        }}
      />
      <RatesPerKmModal
        open={openModalRatesPerKm}
        setOpen={setOpenModalRatesPerKm}
        setSnack={setOpenDialog}
        setSnackContent={setDialogContent}
        setSnackColor={setDialogColor}
        contractor={contractor}
        token={props.user.csrf_token}
        refresh={() => getRatesPerKm(contractor.value)}
        ratesPerKm={ratesPerKm}
        id_ratesPerKm={id_ratesPerKm}
        setDialog={props.setDialog}
      />
      <CreateTransportSet
        token={props.user.csrf_token}
        car={car}
        trailer={trailer}
        driver={driver}
        driverTwo={driverTwo}
        transport_setOption={transport_setOption}
        settingTransportSet={settingTransportSet}
        open={openModalCreateTransportSet}
        setOpen={setOpenModalCreateTransportSet}
        settransport_set={settransport_set}
        setNewTransportSetID={setNewTransportSetID}
        setSnack={setOpenDialog}
        setSnackContent={setDialogContent}
        setSnackColor={setDialogColor}
      />
    </>
  );
}
