import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useQuery } from "react-query";
import { AxiosError } from "axios";
import { ValueType } from "react-select";

import TmsSelect from "components/TmsSelect";
import TmsDialog from "components/TmsDialog";
import TmsLoading from "components/TmsLoading";
import CrossingModal from "./CrossingModal";

import { getBorderCrossingsFavorites } from "api/endpoints";
import { parseSelectOptionsWithoutDuplicates } from "components/TmsSelect/utils";
import { filterCrossings, translateCountries } from "./utils";
import {
  BorderCrossing,
  FavoriteBorderCrossing,
  SelectOption,
  SelectOptionState,
  TmsDialogState,
} from "types";

import "./styles.sass";

const BorderCrossings = () => {
  const [origin, setOrigin] = useState<SelectOptionState>();
  const [destination, setDestination] = useState<SelectOptionState>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [dialog, setDialog] = useState<TmsDialogState>({ isOpen: false });

  const {
    data: favoritesCrossingsData,
    isLoading: favoritesCrossingsLoading,
    refetch: refetchFavoriteCrossings,
  } = useQuery<FavoriteBorderCrossing[], AxiosError>(
    ["favoritesCrossingsData"],
    () => getBorderCrossingsFavorites(),
    {
      onError: (error) =>
        setDialog({ isOpen: true, type: "error", content: error.message }),
    }
  );

  const favoritesCrossings = favoritesCrossingsData?.map(
    (favoriteCrossing: FavoriteBorderCrossing) =>
      favoriteCrossing.border_crossing
  );

  const translatedFavoritesCrossings =
    favoritesCrossings && translateCountries(favoritesCrossings);

  const filtredFavoritesCrossings =
    translatedFavoritesCrossings &&
    filterCrossings({
      crossings: translatedFavoritesCrossings,
      origin,
      destination,
    });

  const crossingsOriginSelectOptions =
    filtredFavoritesCrossings &&
    parseSelectOptionsWithoutDuplicates({
      data: filtredFavoritesCrossings,
      label: "origin",
      value: "origin",
      image: "origin_iso2",
    });

  const crossingsDestinationSelectOptions =
    filtredFavoritesCrossings &&
    parseSelectOptionsWithoutDuplicates({
      data: filtredFavoritesCrossings,
      label: "destination",
      value: "destination",
      image: "destination_iso2",
    });

  const showDialogAndRefetch = (type: "success" | "error", content: string) => {
    setDialog({ isOpen: true, type, content });
    if (type === "success") refetchFavoriteCrossings();
  };

  if (favoritesCrossingsLoading) {
    return <TmsLoading />;
  }

  return (
    <>
      <div className="crossings-header">
        <div className="crossings-header--left">
          <h1 className="crossings-header__title">Przejścia graniczne</h1>
          <div className="crossings-header__buttons">
            <button
              onClick={() => setIsModalOpen(true)}
              className="crossings-header__button"
            >
              Ustaw przejście domyślne
            </button>
          </div>
        </div>
        <div className="crossings-header--right">
          <div className="crossings-header__filter">
            <label>Przejście z:</label>
            <TmsSelect
              className="crossings-header__select"
              options={crossingsOriginSelectOptions}
              name="filter"
              onChange={(option: ValueType<SelectOption, false>) =>
                setOrigin(option)
              }
              value={origin}
              image={{ path: "img/flags", extension: "gif" }}
              isClearable
            />
          </div>
          <div className="crossings-header__filter">
            <label>Do:</label>
            <TmsSelect
              className="crossings-header__select"
              options={crossingsDestinationSelectOptions}
              name="filter"
              onChange={(option: ValueType<SelectOption, false>) =>
                setDestination(option)
              }
              value={destination}
              image={{ path: "img/flags", extension: "gif" }}
              isClearable
            />
          </div>
        </div>
      </div>
      <TableContainer className="crossings-table">
        <Table stickyHeader>
          <TableHead className="crossings-table__header">
            <TableRow>
              <TableCell size="medium" padding="default">
                <label className="crossings-table__cell">
                  Nazwa przejścia granicznego
                </label>
              </TableCell>
              <TableCell size="medium" padding="default">
                <label className="crossings-table__cell">Przejście z</label>
              </TableCell>
              <TableCell size="medium" padding="default">
                <label className="crossings-table__cell">Przejście do</label>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {filtredFavoritesCrossings?.map(
              (borderCrossing: BorderCrossing) => (
                <TableRow>
                  <TableCell>{borderCrossing.name}</TableCell>
                  <TableCell>{borderCrossing.origin}</TableCell>
                  <TableCell>{borderCrossing.destination}</TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CrossingModal
        isOpen={isModalOpen}
        setOpen={setIsModalOpen}
        showDialogAndRefetch={showDialogAndRefetch}
        headerOrigin={origin}
        headerDestination={destination}
        setHeaderOrigin={setOrigin}
        setHeaderDestination={setDestination}
        favoritesCrossings={translatedFavoritesCrossings}
      />
      <TmsDialog dialog={dialog} setDialog={setDialog} />
    </>
  );
};

export default BorderCrossings;
