import React from "react";
import Alert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";
import { TmsDialogState } from "types";

type Props = {
  dialog: TmsDialogState;
  setDialog: (dialog: TmsDialogState) => void;
};

const TmsDialog = ({ dialog, setDialog }: Props) => {
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setDialog({ ...dialog, isOpen: false });
  };

  return (
    <Snackbar
      open={dialog.isOpen}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert color={dialog.type} severity={dialog.type} onClose={handleClose}>
        {dialog.content}
      </Alert>
    </Snackbar>
  );
};

export default TmsDialog;
