import React, { Component } from 'react'

export default class Reports extends Component {

	render() {
		return (
			<div>
				Reports - Raporty
			</div>
		)
	}
}
