import React, { useState, useEffect, forwardRef } from 'react'
import { Snackbar, Button, IconButton, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, TextField } from '@material-ui/core';
import { X, Calendar } from 'react-feather';
import { nationOptions } from './nationOptions'
import { DBurl } from 'appConfig'
import stylesMod from '../../styles/newOrEdit.module.css'
import BTNstyles from '../../styles/button.module.css'
import docsStyles from '../../styles/documents.module.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import plLocale from 'date-fns/locale/pl';
import moment from 'moment';
import Select from 'react-select'


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const EditDocs = ({ token, open, setOpen, setSnack, setSnackContent, setSnackColor, headCells, columns, cars, driverData, handleSetDateChanges, reload }) => {


    const [allColumns, setAllColumns] = useState([]);
    const [alwaysColChanged, setAlwaysColChanged] = useState(false)
    const [nationality, setNationality] = useState({value: ``, label: ``})

    const setDocsOwnDefVal = async (col) => {
        const response = await fetch(`${DBurl}/setDocumentsOwnDefValues`, {
            method: "POST",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": token
                },
                body: JSON.stringify({
                mode: col.own_id ? 'update' : 'insert',
                data: {
                    id: col.own_id,
                    document_child_id: driverData.id, // car_id, driver_id
                    document_own_def_id: col.id, // id kolumny z definicji
                    is_file: false, // Czy plik czy parametr
                    value: col.fullDate, // Wypełniamy -> Jeżeli is_file = false
                    filename: null // Wypełniamy -> Jeżeli is_file = true
            }})});
            const json = await response.json()
            if (json.success) {
                reload(token)
            }
    }

    const setCarDocVals = async (allColumns) => {
        const doc_qualification_certificate_date = allColumns.find(col => col.id === "doc_qualification_certificate_date").fullDate
        const doc_form_a1_date = allColumns.find(col => col.id === "doc_form_a1_date").fullDate
        const doc_contract_of_employment_date =  allColumns.find(col => col.id === "doc_contract_of_employment_date").fullDate
        const doc_imi_notif_at = allColumns.find(col => col.id === "doc_imi_notif_at").fullDate
        const doc_imi_notif_be = allColumns.find(col => col.id === "doc_imi_notif_be").fullDate
        const doc_imi_notif_cz = allColumns.find(col => col.id === "doc_imi_notif_cz").fullDate
        const doc_imi_notif_de = allColumns.find(col => col.id === "doc_imi_notif_de").fullDate
        const doc_imi_notif_es = allColumns.find(col => col.id === "doc_imi_notif_es").fullDate
        const doc_imi_notif_fr = allColumns.find(col => col.id === "doc_imi_notif_fr").fullDate
        const doc_imi_notif_it = allColumns.find(col => col.id === "doc_imi_notif_it").fullDate
        const doc_imi_notif_nl = allColumns.find(col => col.id === "doc_imi_notif_nl").fullDate
        const doc_visa_date = allColumns.find(col => col.id === "doc_visa_date").fullDate
        const doc_statement_on_entrusting_work_date = allColumns.find(col => col.id === "doc_statement_on_entrusting_work_date").fullDate
        const nation = nationality.value
        const response = await fetch(`${DBurl}/setDocumentsDrivers`, {
            method: "POST",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": token
                },
                body: JSON.stringify({
                mode: 'update',
                data: {
                    driver_id: driverData.id, doc_qualification_certificate_date, doc_form_a1_date, doc_contract_of_employment_date, doc_imi_notif_at, doc_imi_notif_be, doc_imi_notif_cz,
                    doc_imi_notif_de, doc_imi_notif_es, doc_imi_notif_fr, doc_imi_notif_it, doc_imi_notif_nl, doc_visa_date, doc_statement_on_entrusting_work_date, nation

            }})});
            const json = await response.json()
            if (json.success) {
                reload(token)
            }
    }

    const CustomInput = forwardRef((props, ref) => {
		return (
			<div className={`${'datapicker_new'} ${docsStyles.dataPicker}` }>        
				<label onClick={props.onClick} ref={ref}>         
				{props.value || props.placeholder} 
				</label>
				<Calendar onClick={props.onClick} />
			</div>
		);
	});

    const select_styles = {
		control: (base, state) => ({
			...base,
			border: '1px solid #142f42',	
			boxShadow: 'none',
			'&:hover': {
				border: '1px solid #142f42',
			}
		}),
		option: (provided, state) => ({
			...provided,
			backgroundColor: state.isFocused && "#142f42",
			color: state.isFocused && "#fff",
		}),
		menuPortal: base => ({ ...base, zIndex: 99999999 })
	}

    const pickersToHide = ["registrationNum", "nation", "driverid"]

    const getStateDate = (value) => {
        const date = new Date(value)
        const day = date.getDate()
        const month = date.getMonth() +1
        const year = date.getFullYear()
        return `${year}-${month}-${day}`
    }

    const getInitialState = () => {
        const alwaysColumns = headCells.map(({id, label}) => {
            const initialValue = driverData[id]
            return {fullDate: initialValue, id, col_name: label, value: new Date(initialValue), isAlways: true, isChanged: false}
        }).filter(val => val.fullDate !== undefined)

        const additionalColsValues = driverData.own_def_vales
        const additionalColumns = columns.map(({id, col_name}) => {
            const initialValue = additionalColsValues.find(addCol => addCol?.col_id === id && !addCol?.is_file);
            if (initialValue) {
                return {fullDate: initialValue.value, id, col_name, value: new Date(initialValue.value), isAlways: false, isChanged: false, own_id: initialValue.id}
            }
            return {fullDate: null, id, col_name, value: null, isAlways: false, isChanged: false, own_id: null}
        })
        return [...alwaysColumns, ...additionalColumns]
    }

    useEffect(() => {
        setAllColumns(getInitialState())
    }, [driverData])

    useEffect(() => {
        if(driverData.nation) {setNationality({value: `${driverData.nation}`, label: `${driverData.nation}`})}
            else{setNationality({value: ``, label: ``})}
    }, [driverData, open])

    const handleSave = () => {
        for (const col of allColumns) {
            if (col.isChanged && !col.isAlways) {
                setDocsOwnDefVal(col)
            }
        }
        alwaysColChanged && setCarDocVals(allColumns)
    }

    const handleAdditionalColsChange = (value, col_name, id) => {
        const fullDate = getStateDate(value)
        const existingEntry = allColumns.find(entry => entry.id === id) 
        if (!alwaysColChanged && existingEntry.isAlways) setAlwaysColChanged(true)
        // const newEntryValue = existingEntry?.id ? {...existingEntry, value, fullDate} : {id, col_name, value, fullDate}
        const newEntryValue =  {...existingEntry, value, fullDate, isChanged: true}
        setAllColumns(prevValues => [...prevValues.filter(val => val.id !== id), newEntryValue])
    }

    return (
    <>
        <Dialog
            maxWidth='sm'
            open={open}
            TransitionComponent={Transition}
            onClose={() => {
            setOpen(false)
            }}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >

        <DialogTitle id="alert-dialog-slide-title" className={stylesMod.dialogHead}>Edytuj dane 		
            <IconButton aria-label="close" className={stylesMod.closeButton} onClick={() => {
				setOpen(false)
                setNationality('')
			}}>
          <X />
        </IconButton>
			</DialogTitle >
            <DialogContent>
                <Grid container spacing ={2}>
                    <Grid item xs={6}>
                        <div className={docsStyles.carDetails}>
                            <label>{`${driverData?.surname} ${driverData?.firstname}`}</label>
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid item xs={5}>
                        <div >
                            <label htmlFor={"numb"}>Narodowość</label>
                                <Select 
                                    value={nationality}
                                    className={`${docsStyles.select}`}
                                    options={nationOptions}
                                    onChange={e => {
                                        setNationality(e)
                                        setAlwaysColChanged(true)
                                    }}
                                    name="numb"
                                    placeholder="Wybierz..."
                                    getOptionValue={option => `${option.label}`}  
                                    styles={select_styles} 
                                    menuPortalTarget={document.body}
                                />
                        </div>
                        </Grid>
                    </Grid>
                    {[...headCells.filter(headCell => !pickersToHide.includes(headCell.id)), ...columns].map( col => 
                        <>
                        <Grid key={col.id} item xs = {6}>
                        <div className={docsStyles.datePicker}>
                                <label htmlFor={col.label}>{col.label}</label>
                                <div className={docsStyles.date}><DatePicker 
                                    dateFormat="yyyy.MM.dd"
                                    minDate={new Date()}
                                    startDate={new Date()}
                                    selected={allColumns.find(entry => entry.id === col.id)?.value > 0 ? allColumns.find(entry => entry.id === col.id)?.value : null} 
                                    onChange={(e) => {
                                        let newD = moment(e).valueOf()
                                        let newDS = moment(newD).format('yyyy-MM-DD')
                                        newDS = new Date(e >= new Date() ? e : new Date())
                                        handleAdditionalColsChange(newDS, col.col_name, col.id)
                                        handleSetDateChanges()
                                    }} 
                                    locale={plLocale}
                                    name={col.label}
                                    customInput={<CustomInput error={false}/>}
                                /></div>
                            </div>
                        </Grid>
                        </>
                        )}
                </Grid>
            </DialogContent>
        <DialogActions>
            <Button variant="contained" size="large" color="primary" className={BTNstyles.btn} onClick={(e) => {
                    setOpen(false)
                    setNationality('')
                    }}>
                    Anuluj
                </Button>
                <Button variant="contained" size="large" className={`${BTNstyles.btn} ${BTNstyles.grn}`} onClick={() => {
                        handleSave()
                        setOpen(false)
                        setNationality('')
                    }}
                    color="primary">
                Zapisz
            </Button>
        </DialogActions>

      </Dialog>
    </>
    )
}

export default EditDocs;