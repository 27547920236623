import React from "react";
import Button from "@material-ui/core/Button";

const Pagination = ({ postsPerPage, totalPosts, paginate, page }) => {
  let pageNumbers = [];
  let showStartDots = false;
  let showEndDots = false;

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
    if (i > 5) {
      showEndDots = true;
      showStartDots = true;
    }
  }
  let tempPageNumber;
  if (pageNumbers.length > 5 && (!!page || page === 0)) {
    tempPageNumber = [
      page - 2 < 0 ? page + 4 : page - 1,
      page - 1 < 0 ? page + 5 : page,
      page + 1,
      page + 1 > pageNumbers.length ? page - 3 : page + 2,
      page + 2 > pageNumbers.length ? page - 2 : page + 3,
    ];
    tempPageNumber = tempPageNumber.sort((a, b) => a - b);
  } else {
    tempPageNumber = pageNumbers;
    showStartDots = false;
    showEndDots = false;
  }

  return (
    <>
      {showStartDots && page > 2 && pageNumbers.length > 5 && (
        <Button size="small" variant="outlined" key="start">
          ...
        </Button>
      )}
      {tempPageNumber.map((number) => (
        <Button
          state={number === page + 1 ? "on" : "off"}
          size="small"
          variant={number === page + 1 ? "contained" : "outlined"}
          color={number === page + 1 ? "secondary" : "primary"}
          key={number}
          onClick={() => paginate(number)}
        >
          {number}
        </Button>
      ))}
      {showEndDots &&
        page < pageNumbers.length - 3 &&
        pageNumbers.length > 5 && (
          <Button size="small" variant="outlined" key="end">
            ...
          </Button>
        )}
    </>
  );
};

export default Pagination;
