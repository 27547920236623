import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import stylesMod from '../../styles/newOrEdit.module.css'
import { IconButton } from '@material-ui/core';
import { DBurl } from '../../appConfig'
import BTNstyles from '../../styles/button.module.css'
import { X } from 'react-feather'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress/';
import Box from '@material-ui/core/Box';
import styles from '../table.module.css';
import MergeDriverList from './mergeDriverList';
import {getData, postData, deleteData} from '../helpers';

const findInDriversArray = (driversToFilter, driver) => !driversToFilter.some(singleDriver => singleDriver.id === driver.id)

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MergeDriver({data, user, open, setOpen, slaveFunctions}) {

  const [name, setName] = useState(data.name || "")
  const [openMergeListModal, setOpenMergeListModal] = useState(false)
  const [mergeList, setMergeList] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [dialogContent, setDialogContent] = useState("")
  const [dialogColor, setDialogColor] = useState("error")
  const [driversForMaster, setDriversForMaster] = useState([])
  const [driversForMasterToSelect, setDriversForMasterToSelect] = useState([])
  const [pending, setPending] = useState(false)
  const [circleLoader, setCircleLoader] = useState("none")
  
  const postDriversToMaster = async(id_driver) => {
    setPending(true)
    setOpenMergeListModal(false)
    const response = await postData(`${DBurl}/setMasterForDriver`,user.csrf_token, { id_master: data.id, id_driver })
    if (response) {
        await getData(`${DBurl}/getDriversForMaster?id_master=${data.id}`, setDriversForMaster, user.csrf_token)
        setPending(false)
    }
  }

  const getDriversForMaster = async () => {
    setCircleLoader("open")
    const response = await fetch(`${DBurl}/getDriversForMaster?id_master=${data.id}`, {
      method: "GET",
      credentials: 'include',
      headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": user.csrf_token
      }
      })
      const json = await response.json()
      if (json.success) {
        setDriversForMaster(json.data)
        setCircleLoader("none")};
  }

  const handleDeleteDriver = async (id_driver) => {
    setPending(true)
    const response = await deleteData(`${DBurl}/setMasterForDriver`,user.csrf_token,{id_driver})
    if (response) {
        await getData(`${DBurl}/getDriversForMaster?id_master=${data.id}`, setDriversForMaster, user.csrf_token)
        slaveFunctions.handleDeleteSlaveDrivers(id_driver)
        slaveFunctions.handleAddToNotSlaveDrivers(id_driver)
        setPending(false)
    }
  }

  const getButtonStatus = (linked_to_transport_set_num) => {
    return (!linked_to_transport_set_num === 0) || pending;
  }

    useEffect(()=>{
        setName(data.name)
        if (driversForMasterToSelect.length === 0) getData(`${DBurl}/getDriversForMasterToSelect`, setDriversForMasterToSelect, user.csrf_token)
        if (data.id) getDriversForMaster()
    },[driversForMasterToSelect.length, data.name])
    
  return (
    <Dialog
        maxWidth='md'
        fullWidth={true}
        open={open}
        TransitionComponent={Transition}
        onClose={() =>  setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        >
      <DialogTitle id="alert-dialog-slide-title" className={stylesMod.dialogHead}>Połącz profile kierowców<IconButton aria-label="close" className={stylesMod.closeButton} onClick={() => {
        setOpen(false)
      }}>
        <X />
      </IconButton>
      </DialogTitle >
      <DialogContent className={stylesMod.noOverflow}>
        <Grid container spacing={2} >
            <Grid item xs={8}>
                <h1>{`Kierowcy przypisani do ${name}`}</h1>
            </Grid>
            <Grid item xs={4}>
            <Tooltip title={"Połącz profile kierowców"}>
              <div>
                <Button
                  tooltip={''}
                  disabled={pending || driversForMasterToSelect.length === 0}
                  variant="contained"
                  color="primary"
                  size="small"
                  className={BTNstyles.btn}
                  onClick={() => {
                          setMergeList(true)
                          setOpenMergeListModal(true)
                  }}
                >
                + Przypisz kierowcę</Button>
              </div>
            </Tooltip>
            </Grid>
        </Grid>
        {circleLoader === "open" ? 
        <div className={styles.circleLoaderContainer}><Box className={circleLoader}>
            <CircularProgress color="success" className={BTNstyles.loaderBig}/>
          </Box></div>:
        <TableContainer>
            <Table className={styles.tableMerge}>
                <TableHead>
                    <TableRow>
                        <TableCell>Kierowca</TableCell>
                        <TableCell>Numer</TableCell>
                        <TableCell>Akcja</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {driversForMaster.map(driver => 
                        <TableRow key={driver.id}>
                            <TableCell>{driver.fullname}</TableCell>
                            <TableCell>{driver.driverid}</TableCell>
                            <TableCell>
                                <Button variant="contained" color="primary" size="large" className={BTNstyles.titleBtn}
                                    disabled={getButtonStatus(driver.linked_to_transport_set_num)}
                                    onClick={() => handleDeleteDriver(driver.id)}
                                    >
                                    Usuń <X className={`${BTNstyles.errStroke} ${BTNstyles.downBtnIco} ${BTNstyles.BTNdelete}`} />
                                </Button>
                            </TableCell>
                        </TableRow>
                    )}

                </TableBody>
            </Table>
        </TableContainer>}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="large" color="primary" className={BTNstyles.btn} onClick={() => setOpen(false)}>
            Ok
        </Button>
    </DialogActions>
        {<MergeDriverList
                    drivers={driversForMasterToSelect.filter(singleDriver => findInDriversArray(driversForMaster, singleDriver))}
                    postDriversToMaster={postDriversToMaster}
                    open={openMergeListModal}
                    slaveFunctions={slaveFunctions}
                    setOpen={setOpenMergeListModal}
                    user={user}
                    data={data}
                    setOpenSnack={setOpenDialog}
                    setSnackContent={setDialogContent}
                    setSnackColor={setDialogColor}
                    mergeList={mergeList}
                />}	
    </Dialog >
  )
}
