import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import stylesMod from "../../styles/newOrEdit.module.css";
import Input from "../../components/input";
import Select from "react-select";
import { DBurl } from "../../appConfig";
import BTNstyles from "../../styles/button.module.css";
import { Add } from "@material-ui/icons";
import { X } from "react-feather";
import IconButton from "@material-ui/core/IconButton";
import { Switch, FormControlLabel } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import UploadFilePreview from "../../components/UploadFilePreview";

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#129865",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#129865",
  },
  "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
    backgroundColor: "#129865",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddClient(props) {
  const [error, setError] = useState(false);
  const [firm, setFirm] = useState(props.data?.id_firm || "");
  const [shortname, setShortname] = useState(props.data?.shortname || "");
  const [name, setName] = useState(props.data?.name || "");
  const [street, setStreet] = useState(props.data?.street || "");
  const [street_no, setStreet_no] = useState(props.data?.street_no || "");
  const [city, setCity] = useState(props.data?.city);
  const [zipcode, setZipCode] = useState(props.data?.zipcode || "");
  const [contact_name, setContact_name] = useState(props.data?.contact_name);
  const [contact_email, setContact_email] = useState(props.data?.contact_email);
  const [contact_tel, setContact_tel] = useState(props.data?.contact_tel);
  const [tms_version_s, setTms_version_s] = useState(
    props.data?.tms_version_s || ""
  );
  const [ekd, setEkd] = useState(props.data.ekd);
  const [traffic_enabled, setTraffic_enabled] = useState(
    props.data.traffic_enabled
  );
  const [atlas_auto_add, setAtlas_auto_add] = useState(
    props.data.atlas_auto_add || false
  );
  const [fileLogo, setFileLogo] = useState(null);
  const [EKDEmail, setEKDEmail] = useState(props.data.ekd_email || "");
  const isFocused = props.edit;
  const [trader, setTrader] = useState(props.data.trader_id || "");
  const [e100, setE100] = useState(props.data.e100 || false);
  const [e100Distance, setE100Distance] = useState(
    props.data.e100_distance_to_station || 50
  );
  const [e100Username, setE100Username] = useState(
    props.data.e100_username || ""
  );
  const [e100Password, setE100Password] = useState(
    props.data.e100_password || ""
  );
  const [numPerOperator, setNumPerOperator] = useState(false);

  const [emailUsername, setEmailUsername] = useState("");
  const [emailPassword, setEmailPassword] = useState();
  const [emailPort, setEmailPort] = useState();
  const [emailHost, setEmailHost] = useState();
  const [emailSender, setEmailSender] = useState();

  const getEmailConfig = async (id) => {
    const response = await fetch(`${DBurl}/getEmailInboxCustomer?id=${id}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setEmailUsername(json.data.mail_username);
      setEmailPassword(json.data.mail_password);
      setEmailHost(json.data.mail_smtp_host);
      setEmailPort(json.data.mail_smtp_port);
      setEmailSender(json.data.mail_sender);
    }
  };

  const changeEmailSettings = async (id) => {
    const response = await fetch(`${DBurl}/setEmailInboxCustomer`, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: props.edit ? "update" : "insert",
        data: {
          id: props.edit ? props.data.id : id,
          mail_smtp_host: emailHost,
          mail_username: emailUsername,
          mail_password: emailPassword,
          mail_smtp_port: emailPort,
          mail_sender: emailSender,
        },
      }),
    });
    const json = await response.json();

    if (json.success) {
      props.setDialog({
        isOpen: true,
        type: "success",
        content: props.edit ? "Zapisano zmiany" : "Firma dodana",
      });
      props.refresh();
      props.setOpen(false);
      clear();
      return json;
    }
  };
  useEffect(() => {
    props.data.id && getEmailConfig(props.data.id);
  }, [props.open === true && props.edit === true, props.data.id]);

  useEffect(() => {
    setName("");
    setStreet("");
    setStreet_no("");
    setCity("");
    setZipCode("");
    setContact_name("");
    setContact_email("");
    setContact_tel("");
    setEKDEmail("");
    setError(false);
    setEmailUsername("");
    setEmailPassword("");
    setEmailHost("");
    setEmailPort(0);
    setEmailSender("");
  }, [props.open === false]);

  useEffect(() => {
    if (props.data?.name) {
      if (props.edit === true) {
        setFirm(props.data.id_firm);
        setName(props.data.name);
        setShortname(props.data.shortname);
        setStreet(props.data.street);
        setStreet_no(props.data.street_no);
        setCity(props.data.city);
        setZipCode(props.data.zipcode);
        setContact_name(props.data.contact_name);
        setContact_email(props.data.contact_email);
        setContact_tel(props.data.contact_tel);
        setFirm(props.checkFirm(props.data.id_firm));
        setTms_version_s(props.data.tms_version_s);
        setEkd(props.data.ekd);
        setTraffic_enabled(props.data.traffic_enabled);
        setAtlas_auto_add(props.data.atlas_auto_add);
        setEKDEmail(props.data.ekd_email);
        setTrader(
          props.data.trader_id &&
            props.traders.filter((o) => {
              return o.id === props.data.trader_id;
            })
        );
        setE100(props.data.e100);
        setE100Distance(props.data.e100_distance_to_station);
        setE100Username(props.data.e100_username);
        setNumPerOperator(props.data.order_no_per_operator);
      }
    }
    if (props.edit === false) {
      setFirm("");
      setName("");
      setShortname("");
      setStreet("");
      setStreet_no("");
      setCity("");
      setZipCode("");
      setContact_name("");
      setContact_email("");
      setContact_tel("");
      setFirm({});
      setTms_version_s(false);
      setEkd(false);
      setTraffic_enabled(false);
      setAtlas_auto_add(false);
      setError(false);
      setEKDEmail("");
      setTrader();
      setE100(false);
      setE100Distance(50);
      setE100Username("");
      setNumPerOperator(false);
      setEmailUsername("");
      setEmailPassword("");
      setEmailHost("");
      setEmailPort(0);
      setEmailSender("");
    }
  }, [props.data.name, isFocused, props]);

  const clear = () => {
    setName("");
    setShortname("");
    setStreet("");
    setStreet_no("");
    setCity("");
    setZipCode("");
    setContact_name("");
    setContact_email("");
    setContact_tel("");
    setError(false);
    setTms_version_s(false);
    setTraffic_enabled(false);
    setEkd(false);
    setFirm({});
    setFileLogo();
    setEKDEmail("");
    setTrader();
    setE100(false);
    setE100Distance(50);
    setE100Username("");
    setE100Password("");
    setNumPerOperator(false);
    setEmailUsername();
    setEmailPassword();
    setEmailHost();
    setEmailPort();
    setEmailSender();
  };

  return (
    <>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={props.open}
        TransitionComponent={Transition}
        onClose={() => {
          props.setOpen(false);
          clear();
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          {props.edit ? "Edytuj firmę" : "Dodaj firmę"}
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              props.setOpen(false);
              clear();
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>

        <DialogContent className={stylesMod.noOverflow}>
          <Grid container spacing={2} style={{ marginBottom: "15px" }}>
            <Grid item xs={12}>
              <div className={stylesMod.inp}>
                <label>Firma obsługująca</label>
                <Select
                  value={firm}
                  className={`${stylesMod.select} ${
                    error && !firm && stylesMod.error
                  }`}
                  options={props.firms}
                  onChange={(e) => {
                    setFirm(e);
                  }}
                  name="firm"
                  placeholder="Wybierz firmę..."
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      border: "1px solid #142f42",
                      boxShadow: "none",
                      "&:hover": {
                        border: "1px solid #142f42",
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                    }),
                  }}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginBottom: "15px" }}>
            <Grid item xs={9}>
              <Grid container spacing={2}>
                <Grid item xs={7}>
                  <div className={`${stylesMod.inp}`}>
                    <h6>Dane firmy</h6>
                    <label>Nazwa</label>
                    <Input
                      value={name}
                      error={!name && error}
                      handleChange={(e) => {
                        setName(e.target.value);
                      }}
                      name="name"
                    />
                  </div>
                </Grid>

                <Grid item xs={5}>
                  <div className={`${stylesMod.inp}`}>
                    <label>Nazwa skrócona</label>
                    <Input
                      value={shortname}
                      error={!shortname && error}
                      handleChange={(e) => {
                        setShortname(e.target.value);
                      }}
                      name="shortname"
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className={`${stylesMod.inp}`}>
                    <label>Ulica</label>
                    <Input
                      value={street}
                      error={!street && error}
                      handleChange={(e) => {
                        setStreet(e.target.value);
                      }}
                      name="street"
                    />
                  </div>
                </Grid>

                <Grid item xs={2}>
                  <div className={`${stylesMod.inp}`}>
                    <label>Numer</label>
                    <Input
                      value={street_no}
                      error={!street_no && error}
                      handleChange={(e) => {
                        setStreet_no(e.target.value);
                      }}
                      name="street_no"
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className={`${stylesMod.inp}`}>
                    <label>Miasto</label>
                    <Input
                      value={city}
                      error={!city && error}
                      handleChange={(e) => {
                        setCity(e.target.value);
                      }}
                      name="city"
                    />
                  </div>
                </Grid>

                <Grid item xs={2}>
                  <div className={`${stylesMod.inp}`}>
                    <label>Kod pocztowy</label>
                    <Input
                      value={zipcode}
                      error={!zipcode && error}
                      handleChange={(e) => {
                        setZipCode(e.target.value);
                      }}
                      name="zipcode"
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid item xs={12}>
                <div className={stylesMod.inp}>
                  <label style={{ marginTop: "21px" }}>
                    Dodaj logo na fakture
                  </label>
                  <UploadFilePreview
                    {...props}
                    width={200}
                    height={109}
                    setFileLogo={setFileLogo}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className={`${stylesMod.inp}`}>
                <h6>Dane osoby do kontaktu</h6>
                <label>Imię i nazwisko</label>
                <Input
                  value={contact_name}
                  error={!contact_name && error}
                  handleChange={(e) => {
                    setContact_name(e.target.value);
                  }}
                  name="contact_name"
                />
              </div>
            </Grid>

            <Grid item xs={3}>
              <div className={`${stylesMod.inp}`}>
                <label>Email</label>
                <Input
                  value={contact_email}
                  error={!contact_email && error}
                  handleChange={(e) => {
                    setContact_email(e.target.value);
                  }}
                  name="contact_email"
                />
              </div>
            </Grid>

            <Grid item xs={3}>
              <div className={`${stylesMod.inp}`}>
                <label>nr telefonu</label>
                <Input
                  value={contact_tel}
                  error={!contact_tel && error}
                  handleChange={(e) => {
                    setContact_tel(e.target.value);
                  }}
                  name="contact_tel"
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={`${stylesMod.inp}`}>
                <label>Opiekun</label>
                <Select
                  value={trader}
                  className={`${stylesMod.select}`}
                  isDisabled={
                    props.user.adm === 999 || props.user.adm === 9
                      ? false
                      : true
                  }
                  options={props.traders}
                  onChange={(e) => {
                    setTrader(e);
                  }}
                  name="trader"
                  placeholder="Wybierz opiekuna..."
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      border: "1px solid #142f42",
                      boxShadow: "none",
                      "&:hover": {
                        border: "1px solid #142f42",
                      },
                      ...(props.user.adm === 999 || props.user.adm === 9
                        ? {}
                        : { opacity: 0.4, cursor: "not-allowed" }),
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                    }),
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={`${stylesMod.inp}`}>
                <h6>Konfiguracja skrzynki email</h6>
                <label>Nazwa użytkownika</label>
                <Input
                  value={emailUsername}
                  handleChange={(e) => {
                    setEmailUsername(e.target.value);
                  }}
                  name="emailUsername"
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={`${stylesMod.inp}`}>
                <label>Hasło</label>
                <Input
                  value={emailPassword}
                  handleChange={(e) => {
                    setEmailPassword(e.target.value);
                  }}
                  name="emailPassword"
                  type="password"
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={`${stylesMod.inp}`}>
                <label>Host SMTP</label>
                <Input
                  value={emailHost}
                  error={!EKDEmail && error}
                  handleChange={(e) => {
                    setEmailHost(e.target.value);
                  }}
                  name="smtpHost"
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={`${stylesMod.inp}`}>
                <label>Port SMTP</label>
                <Input
                  value={emailPort}
                  handleChange={(e) => {
                    setEmailPort(e.target.value);
                  }}
                  name="smtpPort"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={`${stylesMod.inp}`}>
                <label>Adres e-mail nadawcy</label>
                <Input
                  value={emailSender}
                  handleChange={(e) => {
                    setEmailSender(e.target.value);
                  }}
                  name="emailSender"
                />
              </div>
            </Grid>
            <Grid item xs={6}></Grid>

            <Grid item xs={6}>
              <div className={`${stylesMod.inp}`}>
                <h6>EKD</h6>
                <label>EKD Email</label>
                <Input
                  value={EKDEmail}
                  error={!EKDEmail && error}
                  handleChange={(e) => {
                    setEKDEmail(e.target.value);
                  }}
                  name="EKDEmail"
                />
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              className={
                props.user.adm === 9 || props.user.adm === 999
                  ? "set_user_s_on"
                  : "set_user_s_off"
              }
            >
              <div className={`${stylesMod.inp}`}>
                <FormControlLabel
                  className={stylesMod.smallerFont}
                  control={
                    <GreenSwitch
                      checked={tms_version_s}
                      color="primary"
                      onChange={(e) => {
                        setTms_version_s(e.target.checked);
                      }}
                    />
                  }
                  label={"włącz/wyłącz wersję S"}
                />
              </div>
            </Grid>

            {props.user.adm === 9 || props.user.adm === 999 ? (
              <Grid item xs={12}>
                <div className={`${stylesMod.inp}`}>
                  <FormControlLabel
                    className={stylesMod.smallerFont}
                    control={
                      <GreenSwitch
                        checked={ekd}
                        onChange={(e) => setEkd(e.target.checked)}
                      />
                    }
                    label={"EKD"}
                  />
                </div>
              </Grid>
            ) : (
              ""
            )}

            {props.user.adm === 9 || props.user.adm === 999 ? (
              <Grid item xs={12}>
                <div className={`${stylesMod.inp}`}>
                  <FormControlLabel
                    className={stylesMod.smallerFont}
                    control={
                      <GreenSwitch
                        checked={traffic_enabled}
                        onChange={(e) => setTraffic_enabled(e.target.checked)}
                      />
                    }
                    label={"Natężenie ruchu"}
                  />
                </div>
              </Grid>
            ) : (
              ""
            )}

            <Grid item xs={12}>
              <div className={`${stylesMod.inp}`}>
                <FormControlLabel
                  className={stylesMod.smallerFont}
                  control={
                    <GreenSwitch
                      checked={atlas_auto_add}
                      onChange={(e) => setAtlas_auto_add(e.target.checked)}
                    />
                  }
                  label={"Atlas - automatyczna synchronizacja"}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              {props.user.adm >= 1 ? (
                <div className={`${stylesMod.inp}`}>
                  <FormControlLabel
                    className={stylesMod.smallerFont}
                    control={
                      <GreenSwitch
                        checked={e100}
                        onChange={(e) => setE100(e.target.checked)}
                      />
                    }
                    label={"E100"}
                  />
                </div>
              ) : (
                ""
              )}
            </Grid>

            <Grid item xs={12}>
              <div className={`${stylesMod.inp}`}>
                <FormControlLabel
                  className={stylesMod.smallerFont}
                  control={
                    <GreenSwitch
                      checked={numPerOperator}
                      onChange={(e) => setNumPerOperator(e.target.checked)}
                    />
                  }
                  label={"Numeracja zleceń względem całej firmy"}
                />
              </div>
            </Grid>

            <Grid item xs={4}>
              {e100 && props.user.adm && (
                <div className={`${stylesMod.inp}`}>
                  <label>E100 - nazwa użytkownika</label>
                  <Input
                    value={e100Username}
                    // error={e100 && !e100Username && error}
                    handleChange={(e) => {
                      setE100Username(e.target.value);
                    }}
                    name="E100Username"
                  />
                </div>
              )}
            </Grid>

            {e100 && props.user.adm && (
              <Grid item xs={4}>
                <div className={`${stylesMod.inp}`}>
                  <label>E100 - hasło</label>
                  <Input
                    value={e100Password}
                    type="password"
                    autocomplete="off"
                    error={!props.edit && e100 && !e100Password && error}
                    handleChange={(e) => {
                      setE100Password(e.target.value);
                    }}
                    name="E100Password"
                  />
                </div>
              </Grid>
            )}

            <Grid item xs={3}>
              {e100 && props.user.adm && (
                <div className={`${stylesMod.inp}`}>
                  <label>E100 - dystans do stacji w km</label>
                  <Input
                    value={e100Distance}
                    type="number"
                    // error={e100 && !e100Distance && error}
                    handleChange={(e) => {
                      setE100Distance(e.target.value);
                    }}
                    name="E100Distance"
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={`${BTNstyles.btn} ${BTNstyles.err}`}
            onClick={() => {
              props.setOpen(false);
              clear();
            }}
          >
            Anuluj
          </Button>
          <Button
            variant="contained"
            size="large"
            className={`${BTNstyles.btn} ${BTNstyles.grn}`}
            onClick={(e) => {
              if (
                !!name &&
                !!street &&
                !!city &&
                !!zipcode &&
                !!street_no &&
                !!contact_name &&
                !!contact_email &&
                !!contact_tel &&
                !!shortname
              ) {
                const fetchUrl = `${DBurl}/setCustomers`;
                const data = {
                  id_firm: props.edit ? props.data.id_firm : firm.value,
                  id: props.edit ? props.data.id : null,
                  name: name,
                  shortname: shortname,
                  street: street,
                  city: city,
                  zipcode: zipcode,
                  street_no: street_no,
                  contact_name: contact_name,
                  contact_email: contact_email,
                  contact_tel: contact_tel,
                  tms_version_s: tms_version_s ? tms_version_s : false,
                  atlas_endpoint: props.data.atlas_endpoint || "",
                  atlas_username: props.data.atlas_username || "",
                  atlas_password: props.data.atlas_password || "",
                  atlas_auto_add: atlas_auto_add || false,
                  ekd: ekd ? ekd : false,
                  traffic_enabled: traffic_enabled ? traffic_enabled : false,
                  logo_file_name: fileLogo || "",
                  ekd_email: EKDEmail || "",
                  trader_id: trader?.id || null,
                  e100: e100,
                  e100_distance_to_station: e100 ? e100Distance : null,
                  e100_username: e100 ? e100Username : null,
                  e100_password: e100 ? e100Password : null,
                  order_no_per_operator: numPerOperator,
                };

                fetch(fetchUrl, {
                  method: "post",
                  credentials: "include",
                  headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-Token": props.user.csrf_token,
                  },
                  body: JSON.stringify({
                    mode: props.edit ? "update" : "insert",
                    data: data,
                  }),
                })
                  .then((res) => res.json())
                  .then((data) => {
                    if (data.success) {
                      changeEmailSettings(data.data.new_id);
                    } else if (data.response === "401") {
                      props.setDialog({
                        isOpen: true,
                        type: "error",
                        content:
                          "Brak uprawnień. Skontatkuj się z administratorem",
                      });
                    } else {
                      props.setDialog({
                        isOpen: true,
                        type: "error",
                        content: `Błąd: ${data.responseDescription}`,
                      });
                    }
                  });
              } else {
                props.setDialog({
                  isOpen: true,
                  type: "error",
                  content: "Uzupełnij wymagane pola",
                });
                setError(true);
              }
            }}
            color="primary"
          >
            {props.edit ? "Zapisz" : "Dodaj"}{" "}
            <Add className={BTNstyles.downBtnIco} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
