import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import { XCircle, PlusCircle } from "react-feather";
import { Grid } from "@material-ui/core/";
import IconButton from "@material-ui/core/IconButton";
import stylesVersion from "styles/version_s.module.css";
import Tooltip from "@material-ui/core/Tooltip";

import styles from "../../trace.module.css";
import "react-datepicker/dist/react-datepicker.css";
import "../../trace.css";

const CargoTrace = ({
  updateValue,
  trace,
  user_s,
  addTab,
  saveTab,
  traceIndex,
  cargo,
  goodsOptions,
  handleTrace,
  addTraceCargo,
  removeTraceCargo,
  goodsOptionsWithoutSelected,
  cargoLength,
  cargoIndex,
  error,
  checkedGoods,
  setDialog,
  isPreview,
}) => {
  const [numberRef, setNumberRef] = useState();
  const [cargoState, setCargoState] = useState({});

  const updateCargoValue = (name, value) => {
    updateValue(`trace[${traceIndex}].cargo[${cargoIndex}].${name}`, value);
  };

  const updateCargoValues = (values) => {
    updateValue(`trace[${traceIndex}].cargo[${cargoIndex}]`, values);
  };

  useEffect(() => {
    setCargoState(cargo);
  }, [cargo]);

  const updateCargo = (name, value) => {
    setCargoState({ ...cargoState, [name]: value });
  };

  const updateOnBlur = () => {
    updateCargoValues(cargoState);
  };

  useEffect(() => {
    if (cargo?.NumRef !== "" && cargo?.NumRef !== undefined) {
      setNumberRef(cargo?.NumRef);
    } else if (cargo?.id) {
      let tempNum = goodsOptions?.findIndex((o) => {
        return o.id === cargo?.id;
      });

      tempNum = tempNum + 1;

      handleTrace(
        {
          target: {
            name: "NumRef",
            value: "ref_" + tempNum,
          },
        },
        traceIndex,
        cargoIndex
      );

      setNumberRef("ref_" + tempNum);
    }

    if (checkedGoods === false) {
      handleTrace(
        {
          target: {
            name: "NumRef",
            value: "ref_1",
          },
        },
        traceIndex,
        cargoIndex
      );

      setNumberRef("ref_1");
    }
  }, [cargo?.NumRef, cargo?.id]);

  const ValueContainer = ({ children, selectProps, ...props }) => {
    return (
      <components.ValueContainer {...props} className={styles.ValueContainer}>
        {children}
      </components.ValueContainer>
    );
  };

  const orderCargo = goodsOptions.find((good) => good.id === cargo.id);

  const updateOrderCargo = (cargoId, updatedValues) => {
    const updatedCargo = goodsOptions.map((item) =>
      item.id === cargoId ? { ...item, ...updatedValues } : item
    );

    updateValue("cargo", updatedCargo);
  };

  const selectCargo = (selectedCargo) => {
    if (trace.type === "loading") {
      let newQuantity =
        Number(selectedCargo.maxQuantity) - selectedCargo?.quantityLoad;

      if (!newQuantity) {
        newQuantity = selectedCargo.maxQuantity;
      }

      const quantity = newQuantity > 0 ? newQuantity : 0;

      updateCargoValues({
        ...selectedCargo,
        quantity,
        id: selectedCargo.id,
      });
      updateOrderCargo(selectedCargo.id, {
        statusLoad: true,
      });
    }

    if (trace.type === "unloading") {
      let newQuantity =
        Number(selectedCargo.maxQuantity) - selectedCargo?.quantityUnload;

      if (!newQuantity) {
        newQuantity = selectedCargo.maxQuantity;
      }

      const quantity = newQuantity > 0 ? newQuantity : 0;

      updateCargoValues({
        ...selectedCargo,
        quantity,
        id: selectedCargo.id,
      });
      updateOrderCargo(selectedCargo.id, {
        statusUnload: true,
      });
    }
  };

  useEffect(() => {
    if (orderCargo?.id) {
      updateCargoValues({
        ...cargo,
        ...orderCargo,
        quantity: cargo.quantity,
        NumRef: cargo.NumRef ? cargo.NumRef : orderCargo.NumRef,
      });
    }
  }, [
    orderCargo?.NumRef,
    orderCargo?.currency,
    orderCargo?.label,
    orderCargo?.maxQuantity,
    orderCargo?.price,
    orderCargo?.unit,
    orderCargo?.details,
    orderCargo?.palletExchange,
    orderCargo?.ADR,
    orderCargo?.ldmSize,
    orderCargo?.ADRClass,
  ]);

  return (
    <>
      <Grid item className={styles.dense} xs={3}>
        <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={`${styles.btnCargoBox} ${styles.dense}`}>
            <IconButton
              disabled={user_s || checkedGoods === false || isPreview}
              className={`${styles.btnIcon} ${
                user_s || checkedGoods === false ? stylesVersion.onlyfull : ""
              }`}
              style={{ marginLeft: cargoLength > 1 ? 0 : 30 }}
              onClick={(e) => {
                addTraceCargo(traceIndex);
              }}
              value={traceIndex}
            >
              <PlusCircle className={styles.add} />
            </IconButton>
            {cargoLength > 1 && (
              <IconButton
                disabled={user_s || checkedGoods === false || isPreview}
                className={`${styles.btnIcon} ${
                  user_s || checkedGoods === false ? stylesVersion.onlyfull : ""
                }`}
                onClick={() => {
                  removeTraceCargo(traceIndex, cargo.id, trace.id, cargoIndex);
                }}
                value={traceIndex}
              >
                <XCircle className={styles.remove} />
              </IconButton>
            )}
          </div>
        </Tooltip>
        <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={`${styles.inp} ${styles.withIcon}`}>
            <Select
              isDisabled={
                user_s ||
                checkedGoods === false ||
                !goodsOptions[0].id ||
                isPreview
              }
              className={`select ${
                user_s || checkedGoods === false ? stylesVersion.onlyfull : ""
              }`}
              classNamePrefix={
                error && !cargo?.name ? `error selectPrefix` : "selectPrefix"
              }
              options={goodsOptionsWithoutSelected}
              isOptionDisabled={(option) => option.isdisabled}
              name="name"
              placeholder="Wybierz..."
              value={{ value: cargo?.id, label: cargo?.name }}
              components={{ ValueContainer }}
              selectProps={addTab}
              noOptionsMessage={() => "Brak ładunku do wybrania"}
              saveTab={saveTab}
              styles={{
                control: (base, state) => ({
                  ...base,
                  border: "1px solid #142f42",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #142f42",
                  },
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused && "#142f42",
                  color: state.isFocused && "#fff",
                }),
              }}
              onChange={(cargo) => {
                selectCargo(cargo);
              }}
            />
          </div>
        </Tooltip>
      </Grid>
      <Grid item className={styles.dense} xs={1}>
        <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={styles.inp}>
            <input
              disabled={
                user_s ||
                checkedGoods === false ||
                !cargo.id ||
                !orderCargo?.maxQuantity ||
                isPreview
              }
              className={`${styles.input} ${
                user_s || checkedGoods === false ? stylesVersion.onlyfull : ""
              }`}
              value={cargoState.quantity || ""}
              type="number"
              onChange={(e) => {
                updateCargo("quantity", Number(e.target.value) || undefined);
              }}
              onBlur={updateOnBlur}
              name="quantity"
            />
          </div>
        </Tooltip>
      </Grid>
      <Grid item className={styles.dense} xs={1}>
        <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={styles.inp}>
            <input
              disabled={true}
              className={`${styles.input} ${
                user_s || checkedGoods === false ? stylesVersion.onlyfull : ""
              }`}
              value={cargo?.unit?.label || ""}
              onChange={(e) => {
                updateCargoValue("unit", {
                  label: e.target.value,
                  value: e.target.value,
                });
              }}
              name="unit"
            />
          </div>
        </Tooltip>
      </Grid>
      <Grid item className={styles.dense} xs={2}>
        <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={styles.inp}>
            <input
              disabled={true}
              className={`${styles.input} ${
                user_s || checkedGoods === false ? stylesVersion.onlyfull : ""
              }`}
              value={cargoState?.details || ""}
              onChange={(e) => {
                updateCargo("details", e.target.value);
              }}
              name="details"
              type="textarea"
              maxLength="50"
              onBlur={updateOnBlur}
            />
          </div>
        </Tooltip>
      </Grid>
      <Grid item className={styles.dense} xs={1}>
        <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={styles.inp}>
            <input
              disabled={true}
              className={`${styles.input} ${
                user_s || checkedGoods === false ? stylesVersion.onlyfull : ""
              }`}
              value={cargoState?.ldmSize || ""}
              onChange={(e) => {
                if (e.target.value < 0) {
                  updateCargo("ldmSize", 0);
                } else {
                  updateCargo("ldmSize", e.target.value);
                }
              }}
              type="number"
              name="ldmSize"
              onBlur={updateOnBlur}
            />
          </div>
        </Tooltip>
      </Grid>
      <Grid item className={styles.dense} xs={1}>
        <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={styles.inp}>
            <input
              disabled={true}
              className={`${styles.input} ${
                user_s || checkedGoods === false ? stylesVersion.onlyfull : ""
              }`}
              value={cargoState?.ADRClass || ""}
              onChange={(e) => {
                updateCargo("ADRClass", e.target.value);
              }}
              name="ADRClass"
              maxLength="40"
              type="number"
              onBlur={updateOnBlur}
            />
          </div>
        </Tooltip>
      </Grid>
      <Grid item className={styles.dense} xs={1}>
        <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
          <div className={styles.inp}>
            <input
              disabled={
                user_s || checkedGoods === false || !cargo.id || isPreview
              }
              className={`${styles.input} ${
                error && !numberRef && styles.error
              } ${
                user_s || checkedGoods === false ? stylesVersion.onlyfull : ""
              }`}
              value={cargoState.NumRef || ""}
              onChange={(e) => {
                updateCargo("NumRef", e.target.value);
              }}
              maxLength="60"
              name="NumRef"
              onBlur={updateOnBlur}
            />
          </div>
        </Tooltip>
      </Grid>

      <Grid item className={styles.dense} xs={2}>
        <div style={{ display: "flex", gap: 25 }}>
          <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
            <input
              disabled={true}
              type="checkbox"
              className={`${styles.check} ${
                user_s || checkedGoods === false ? stylesVersion.onlyfull : ""
              }`}
              value={cargo?.ADR || false}
              onChange={(e) => {
                updateCargoValue("ADR", e.target.checked);
              }}
              name="ADR"
              maxLength="40"
              checked={cargo?.ADR || false}
            />
          </Tooltip>
          <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
            <input
              type="checkbox"
              disabled={true}
              className={`${styles.check} ${
                user_s || checkedGoods === false ? stylesVersion.onlyfull : ""
              }`}
              value={cargo?.palletExchange || false}
              onChange={(e) => {
                updateCargoValue("palletExchange", e.target.checked);
              }}
              name="palletExchange"
              checked={cargo?.palletExchange || false}
            />
          </Tooltip>
        </div>
      </Grid>
    </>
  );
};

export default CargoTrace;
