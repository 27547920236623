import React, { useState, useEffect, useRef, SyntheticEvent } from "react";
import Title from "../../components/title";
import styles from "../table.module.css";
import { DBurl } from "../../appConfig";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Slide,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import docsStyles from "../../styles/documents.module.css";
import { CommonUserType } from "typeDefinitions/common/Common.types";
import { MONTHS } from "./AddFleet.consts";
import IcoBtn from "components/buttons/icoBtn";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export type FleetCostsReportsConfirmedStateType = {
  table: any;
  date: Date;
  tabName: string;
};

export type FleetCostsReportsConfirmedPropsType = {
  tabID: number;
  loading: (newState: boolean) => void;
  name?: string;
  setTabs: any;
  user: CommonUserType;
  state: FleetCostsReportsConfirmedStateType;
  tabs: any;
};

const FleetCostsReportsConfirmed = (
  props: FleetCostsReportsConfirmedPropsType
) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("car");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [table, setTable] = useState(props.state.table || []);
  const [selected, setSelected] = useState<number[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState<
    "error" | "warning" | "success" | "info"
  >("error");
  const [date, setDate] = useState(new Date());
  const [newlyGenerated, setNewlyGenerated] = useState<boolean>();

  const savedTabId = props.tabID;
  const saveTab = () => {
    const tempTab = { ...props.tabs[savedTabId] };

    tempTab.table = table;
    tempTab.date = date;
    tempTab.newlyGenerated = newlyGenerated;

    return tempTab;
  };

  useEffect(() => {
    props.setTabs(() => {
      const tempTab = [...props.tabs];
      tempTab[props.tabID] = saveTab();
      return tempTab;
    });
  }, [table, date]);

  const headCells = [
    {
      id: "month",
      numeric: true,
      disablePadding: false,
      label: "Raport na",
      class: docsStyles.headCell,
    },
    {
      id: "created",
      numeric: true,
      disablePadding: false,
      label: "Data utworzenia",
    },
    { id: "button", numeric: true, disablePadding: false },
  ];

  function EnhancedTableHead(props: any) {
    const {
      styles,
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;

    return (
      <TableHead className={styles.tabHead}>
        <TableRow>
          {headCells.map((headCell) => {
            return (
              <TableCell
                key={headCell.id}
                align="left"
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={headCell.class}
              >
                {headCell.label}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }

  const handleClose = (_event: SyntheticEvent<Element, Event>) => {
    setOpenDialog(false);
  };

  const fetchData = async () => {
    const selectedMonth = date
      ? date.getMonth() + 1
      : new Date().getMonth() + 1;
    const selectedYear = date ? date.getFullYear() : new Date().getFullYear();
    props.loading(true);
    const response = await fetch(`${DBurl}/getFleetCostsReports`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      //@ts-ignore
      setTable(json.data);
      setNewlyGenerated(true);
      props.loading(false);
    }
  };

  const deleteReport = async (id: number) => {
    props.loading(true);

    const response = await fetch(`${DBurl}/setFleetCostsReports`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "delete",
        data: {
          ids: [id],
        },
      }),
    });
    const json = await response.json();
    if (json.success) {
      fetchData();
      setOpenDialog(true);
      setDialogContent(`Wybrany raport został usunięty.`);
      setDialogColor("success");

      props.loading(false);
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  const printPDF = (month: number, year: number) => {
    const url = `${DBurl}/getFleetCostsReportXLS?month=${month}&year=${year}`;
    window.open(url, "_blank");
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRequestSort = (_event: unknown, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isSelected = (name: number) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, table.length - page * rowsPerPage);

  return (
    <>
      <div className={styles.root}>
        <Paper className={styles.paper}>
          <Title title={props.state.tabName} />
          <TableContainer className={styles.stickyTable}>
            <Table
              className={styles.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                styles={styles}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={table.length}
              />
              <TableBody className={styles.tabBody}>
                {table.map((row: any) => {
                  const issueMonth = new Date(row.issue_date).getMonth() + 1;
                  const issueYear = new Date(row.issue_date).getFullYear();
                  return (
                    <>
                      <TableRow className="fleet-costs-reports-confirmed__table-row">
                        <TableCell className="fleet-costs-reports-confirmed__table-cell--first">
                          {MONTHS.find((m) => m.value === issueMonth)?.label}{" "}
                          {issueYear}
                        </TableCell>
                        <TableCell className="fleet-costs-reports-confirmed__table-cell">
                          {row.created_at}
                        </TableCell>
                        <TableCell className="fleet-costs-reports-confirmed__icon-container">
                          <IcoBtn
                            icon="FileText"
                            tooltip="PDF"
                            onClick={() => {
                              printPDF(issueMonth, issueYear);
                            }}
                          />

                          <IcoBtn
                            icon="X"
                            tooltip="Usuń"
                            className="btn-delete"
                            onClick={() => {
                              deleteReport(row.id);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 33 * emptyRows }}>
                    <TableCell colSpan={3} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={styles.tableBottom}></div>
        </Paper>
      </div>
      <Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={dialogColor}>
          {dialogContent}
        </Alert>
      </Snackbar>
    </>
  );
};

export default FleetCostsReportsConfirmed;
