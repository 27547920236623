export const deleteData = async(url, csrf_token, data) => {
    const response = await fetch(url, {
            method: "POST",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": csrf_token
            },
                body: JSON.stringify({
                mode: 'delete',
                data
            })
        });
        const json = await response.json().then(data => data).catch(error => console.log(error.message))
        return json.data;
}