import React, { useState, useEffect, forwardRef } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import stylesMod from '../../styles/addAccount.module.css'
import { IconButton } from '@material-ui/core';
import { DBurl } from '../../appConfig'
import BTNstyles from '../../styles/button.module.css'
import { X } from 'react-feather'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import styles from '../table.module.css';
import IcoBtn from '../../components/buttons/icoBtn';
import AddAccount from './AddAccount'
import EditAccount from './EditAccount'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  

const DepartmentsAccList = ({ token, department, setOpen }) => {

    const [departmentAccounts, setDepartmentAccounts] = useState([])
    const [openModalAddAccount, setOpenModalAddAccount] = useState(false);
    const [openModalEditAccount, setOpenModalEditAccount] = useState(false)
    const [loading, setLoading] = useState(false)
    const [accData, setAccData] = useState([])

    const getDepartmentAccounts = async () => {
        const response = await fetch(`${DBurl}/getCustomersDepartmentsAccounts?customers_departament_id=${department.id}`, {
          method: "GET",
          credentials: 'include',
          headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token
          }
          })
          const json = await response.json()
          if (json.success) {
            setDepartmentAccounts(json.data)};
      }

    const handleDeleteAccount = async(id) => {
        setLoading(true)
        const response = await fetch(`${DBurl}/setCustomersDepartmentsAccounts`, {
            method: "POST",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": token
                },
            body: JSON.stringify({
            mode: 'delete',
            data: {
            ids: [id]
                }
            })
        });
        setLoading(false)
    }

      useEffect(()=>{
        getDepartmentAccounts()
      },[loading])
    
    return(<div>
        <Grid container spacing={2} >
        <Grid item xs={10} className={stylesMod.header}>
            <h1>Powiązane konta</h1>
        </Grid>
        <Grid item xs={2}>
        <Tooltip title={"Dodaj nowe konto"}>
          <div>
            <Button
              tooltip={''}
              disabled={false}
              variant="contained"
              color="primary"
              size="small"
              className={BTNstyles.btnGreen}
              onClick={(e) => {
                setOpenModalAddAccount(true)
              }}
            >
            + Dodaj nowe konto</Button>
          </div>
        </Tooltip>
        </Grid>
    </Grid>
    <TableContainer>
        <Table className={styles.tableMerge}>
            <TableHead>
                <TableRow>
                    <TableCell className={stylesMod.row}>Nazwa konta</TableCell>
                    <TableCell className={stylesMod.row}>Nazwa banku</TableCell>
                    <TableCell className={stylesMod.row}>Numer konta</TableCell>
                    <TableCell className={stylesMod.row}>Numer konta VAT</TableCell>
                    <TableCell className={stylesMod.row}>Numer SWIFT / BIC</TableCell>
                    <TableCell className={stylesMod.rowShort}>Domyślna waluta</TableCell>
                    <TableCell className={stylesMod.rowShort}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                 {departmentAccounts.map(row => { 
                    return(              
                      <TableRow key={row.id}>
                        <TableCell>{row.account_name}</TableCell>
                        <TableCell>{row.bank_name}</TableCell>
                        <TableCell>{row.bank_account_no}</TableCell>
                        <TableCell>{row.bank_vat_account_no}</TableCell>
                        <TableCell>{row.swift_bic_no}</TableCell>
                        <TableCell>{row.currency}</TableCell>
                        <TableCell>
                            <div className={stylesMod.buttonBox}>
                                <IcoBtn icon="Edit" tooltip="Edytuj" value={1} onClick={(e) => {
                                    setAccData(row)
                                    setOpenModalEditAccount(true)
                                }} />
                                <IcoBtn icon="X" tooltip="Usuń" className="btn-delete" value={2} onClick={() => {
                                    handleDeleteAccount(row.id)
                                }} />
                            </div>
                        </TableCell>
                      </TableRow>)})}
            </TableBody>
        </Table>
    </TableContainer>
    <DialogActions>
            <Button variant="contained" size="large" color="primary" className={BTNstyles.btn} onClick={() => setOpen(false)}>
                Ok
            </Button>                 
    </DialogActions>

    <Dialog
          open={openModalAddAccount}
          TransitionComponent={Transition}
          maxWidth="md"
          onClose={() => {
            setOpenModalAddAccount(false)
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title" className={stylesMod.dialogHead}>{"Dodaj konto bankowe"}			
          
          <IconButton aria-label="close" className={stylesMod.closeButton} onClick={() => {
            setOpenModalAddAccount(false)
          }}>
            <X />
          </IconButton>
          </DialogTitle >
          <DialogContent>
            <Grid container spacing={1}>
              <AddAccount
                department={department}
                setOpen={setOpenModalAddAccount}
                token={token}
                setLoading={setLoading}
              />
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openModalEditAccount}
          TransitionComponent={Transition}
          maxWidth="md"
          onClose={() => {
            setOpenModalEditAccount(false)
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title" className={stylesMod.dialogHead}>{"Dodaj konto bankowe"}			
          
          <IconButton aria-label="close" className={stylesMod.closeButton} onClick={() => {
            setOpenModalEditAccount(false)
          }}>
            <X />
          </IconButton>
          </DialogTitle >
          <DialogContent>
            <Grid container spacing={1}>
              <EditAccount
                setOpen={setOpenModalEditAccount}
                token={token}
                setLoading={setLoading}
                accData={accData}
              />
            </Grid>
          </DialogContent>
        </Dialog>
    </div>
    )
}

export default DepartmentsAccList;