import React, { SetStateAction, useEffect, useState } from "react";
import { AddFleetCostsContainerSelect, AddFleetCostsGenericModal, MODALS, MODALS_NAMES_TYPE, SelectOptionType, getCarsOptions, AddFleetCostsContainerInput, CURRENCY_OPTIONS, handleDatePickerLabel, AllMonthsDifferenceType, addFleetCostsApiCall, getNumberOfMonthDifference } from "..";
import { Car } from "types";
import AddFleetCostsContainerDatePicker from "../AddFleetCosts/AddFleetCostsDatePicker";


export type InsuranceModalPropsType = {
    modalID?: MODALS_NAMES_TYPE;
    handleClose: () => void;
    cars: Car[];
    token: string;
    setRefetch: (value: SetStateAction<boolean>) => void;
    carData: Car
    setSnack: React.Dispatch<React.SetStateAction<boolean>>;
    setSnackContent: React.Dispatch<React.SetStateAction<string>>;
    setSnackColor: React.Dispatch<React.SetStateAction<string>>;
}

const Insurance = ({ modalID, handleClose, cars, token, setRefetch, carData, setSnack, setSnackContent, setSnackColor }: InsuranceModalPropsType) => {
    const [selectedCar, setSelectedCar] = useState<SelectOptionType>({ value: "", label: "" })
    const [price, setPrice] = useState<number>(0)
    const [date, setDate] = useState<Date>(new Date())
    const [endDate, setEndDate] = useState<Date>(new Date)
    const [currency, setCurrency] = useState<SelectOptionType>({ value: "", label: "" })
    const [allMonthsDifference, setAllMonthsDifference] = useState<AllMonthsDifferenceType[]>([])

    useEffect(() => {
        carData && setSelectedCar({value: carData.id, label: carData.nr_rejestracyjny})
    },[carData])

    const closeAll = () => {
        handleClose()
        setSelectedCar({ value: "", label: "" })
        setPrice(0)
        setDate(new Date())
        setEndDate(new Date())
        setAllMonthsDifference([])
        setCurrency({ value: "", label: "" })
        setRefetch(true)
        setSnack(true)
        setSnackColor("success")
        setSnackContent("Dane poprawnie zapisane.")
    }

    const handleFail = () => {
        setSnack(true)
        setSnackColor("error")
        setSnackContent("Nie udało się zapisać danych.")
    }

    const handleDateChange = (newDate: Date, name: "end" | "start") => {
        const isStart = name === "start"
        isStart ? setDate(newDate) : setEndDate(newDate)

        const newStartDate = isStart ? newDate : date || new Date()
        const newEndDate = !isStart ? newDate : endDate || new Date()
        setAllMonthsDifference(getNumberOfMonthDifference(newEndDate, newStartDate))
    }

    const handleConfirm = async () => {
        const totalMonths = allMonthsDifference.length;
        let counter = 0;
    
        for (const monthDiff of allMonthsDifference) {
            const data = {
                id_fleet_cost_type: 6,
                price: (price / allMonthsDifference.length).toFixed(2),
                start_date: monthDiff.date,
                id_car: selectedCar.value,
                currency: currency.value,
                is_fixed_cost: false
            };
            await addFleetCostsApiCall("setFleetCosts", token, data, () => {
                counter++;
                if (counter === totalMonths) {
                    closeAll()
                }
            }, handleFail);
        }
    };


    return (<AddFleetCostsGenericModal open={MODALS.INSURANCE === modalID} modalID={modalID} handleClose={handleClose} handleConfirm={handleConfirm}>
        <AddFleetCostsContainerSelect label={"Pojazd"} value={selectedCar} onChangeHandler={setSelectedCar} options={getCarsOptions(cars)} />
        <AddFleetCostsContainerInput label={"Składka"} value={price} type='number' onChangeHandler={setPrice} />
        <AddFleetCostsContainerSelect label={"Waluta"} value={currency} onChangeHandler={setCurrency} options={CURRENCY_OPTIONS} />
        <AddFleetCostsContainerDatePicker label={"Ubezpieczenie od"} value={date} onChangeHandler={(d: Date) => handleDateChange(d, "start")} showMonthYearPicker={false} format={"dd-MM-yyyy"} />
        <AddFleetCostsContainerDatePicker label={"Ubezpieczenie do"} value={endDate} onChangeHandler={(d: Date) => handleDateChange(d, "end")} showMonthYearPicker={false} format={"dd-MM-yyyy"} />
    </AddFleetCostsGenericModal >)
}

export default Insurance;