import React, { useState, useEffect } from "react";
import Input from "./input";
import styles from "../containers/table.module.css";
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Snackbar,
} from "@material-ui/core";
import { DBurl } from "../appConfig";
import stylesMod from "../styles/newOrEdit.module.css";
import BTNstyles from "../styles/button.module.css";
import CiclesRepStyles from "./ciclesStyles.module.css";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import { XCircle, PlusCircle } from "react-feather";
import Button from "@material-ui/core/Button";
import { Add, Edit } from "@material-ui/icons";
import { X } from "react-feather";
import Alert from "@material-ui/lab/Alert";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CiclicDialog(props) {
  const [ciclicRange, setCiclicRange] = useState(props.data?.zakres || "");
  const [ciclicType, setCiclicType] = useState(props.data?.file_type || "");
  const [cyclicalMail, setCyclicalMail] = useState([{}]);
  const [checkPartialReport, setCheckPartialReport] = useState(
    props.data?.is_append || false
  );
  const [emails_to_edit, setEmails_to_edit] = useState();

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");

  const [errorEmail, setErrorEmail] = useState("");
  const [errorType, setErrorType] = useState("");
  const [errorRange, setErrorRange] = useState("");
  const [errorDeadline, setErrorDeadline] = useState("");

  const [days_after_deadline, set_days_after_deadline] = useState("");

  const clear = () => {
    setCiclicRange();
    setCiclicType();
    setCyclicalMail([{}]);
    setCheckPartialReport();
    setEmails_to_edit([{}]);
    set_days_after_deadline();
  };

  const cyclicRangeOptions = [
    { label: "Dzień", value: "1" },
    { label: "Tydzień", value: "3" },
    { label: "Miesiąc", value: "5" },
  ];
  const cyclicTypeOptions = [{ label: "xls", value: "xls" }];

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDialog(false);
  };

  const handleChange = (index, event) => {
    const values = [...emails_to_edit];
    values[index] = event.target.value;
    setEmails_to_edit(values);
  };

  const handleAddFields = () => {
    const values = [...emails_to_edit];
    values.push({});
    setEmails_to_edit(values);
  };

  const handleRemoveFields = () => {
    const values = [...emails_to_edit];
    if (values.length > 1) values.pop();
    setEmails_to_edit(values);
  };

  async function setCiclesReport(data) {
    await fetch(`${DBurl}/setCycleReports`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: props.isEdit ? "update" : "insert",
        data: {
          ...data,
        },
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setOpenDialog(true);
          setDialogContent(
            `Raport cykliczny ${props.isEdit ? "zedytowany" : "dodany"}`
          );
          setDialogColor("success");
          clear();
          if (props.isEdit) {
            props.refresh();
          }
        } else {
          setOpenDialog(true);
          setDialogContent(
            `${data.responseDescription} // ${DataTransferItemList.response}  `
          );
          setDialogColor("error");
        }
      });
  }

  useEffect(() => {
    if (props.data?.id) {
      setCiclicRange(props.data.zakres);
      setCiclicType(props.data.file_type);
      setCyclicalMail([props.data?.emails]);
      setCheckPartialReport(props.data.is_append);
      setEmails_to_edit(props.data?.emails);
      set_days_after_deadline(props.data.ignoruj);
    } else {
      setEmails_to_edit([{}]);
    }
  }, [props.openModalCiclic]);

  return (
    <>
      <Dialog
        open={props.openModalCiclic}
        TransitionComponent={Transition}
        className={"CiclicDialogSetting"}
        onClose={() => {
          props.setOpenModalCiclic(false);
          // clear()
          if (props.isEdit) {
            props.refresh();
          }
          clear();
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Ustaw raport cykliczny
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              props.setOpenModalCiclic(false);
              // clear()
              if (props.isEdit) {
                props.refresh();
              }
              clear();
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>

        <DialogContent style={{ minHeight: "250px" }}>
          {props.reportType !== "invoices" &&
          props.data?.report_type !== "REPORT_LATE_INVOICES" ? (
            <div className={`${CiclesRepStyles.title}`}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className={stylesMod.inp}>
                    <label>Kierowca</label>
                    <h3>
                      {props.selectedDriverFullName ||
                        props.data?.driver_name ||
                        ""}
                    </h3>
                  </div>
                </Grid>
              </Grid>
            </div>
          ) : (
            ""
          )}

          {props.reportType === "invoices" ||
          props.data?.report_type === "REPORT_LATE_INVOICES" ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className={`${stylesMod.inp}`}>
                  <label>Ilość dni po terminie</label>
                  <Input
                    className={errorDeadline}
                    value={days_after_deadline}
                    handleChange={(e) =>
                      set_days_after_deadline(e.target.value)
                    }
                    name="days_after_deadline"
                    type="number"
                    placeholder="Wpisz ilość dni.."
                  />
                </div>
              </Grid>
            </Grid>
          ) : (
            ""
          )}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={stylesMod.inp}>
                <label>Wybierz zakres</label>
                <Select
                  value={
                    props.isEdit
                      ? cyclicRangeOptions.find(
                          ({ value }) => value === String(ciclicRange)
                        )
                      : ciclicRange
                  }
                  className={`${stylesMod.select} ${errorRange}`}
                  options={cyclicRangeOptions}
                  onChange={(e) => {
                    setCiclicRange(e);
                  }}
                  name="contractor"
                  placeholder="Wybierz..."
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      border: "1px solid #142f42",
                      boxShadow: "none",
                      "&:hover": {
                        border: "1px solid #142f42",
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                    }),
                  }}
                />
              </div>
            </Grid>
          </Grid>

          {((ciclicRange && ciclicRange.value === "3") ||
            (ciclicRange && ciclicRange.value === "5") ||
            (props.isEdit && String(ciclicRange) === "3") ||
            (props.isEdit && String(ciclicRange) === "5")) &&
          props.reportType !== "invoices" &&
          props.data?.report_type !== "REPORT_LATE_INVOICES" ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className={styles.checkPar}>
                  <input
                    type="checkbox"
                    className={`${stylesMod.check} ${CiclesRepStyles.check}`}
                    checked={checkPartialReport}
                    onChange={(e) => {
                      setCheckPartialReport(e.target.checked);
                    }}
                    name="checkPartialReport"
                  />

                  <label className={`${CiclesRepStyles.label}`}>
                    Wysyłaj raport częściowy (codziennie)
                  </label>
                </div>
              </Grid>
            </Grid>
          ) : (
            ""
          )}

          {props.reportType !== "invoices" &&
          props.data?.report_type !== "REPORT_LATE_INVOICES" ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className={stylesMod.inp}>
                  <label>Wybierz typ raportu</label>
                  <Select
                    value={
                      !props.isEdit
                        ? ciclicType
                        : cyclicTypeOptions.find(
                            ({ value }) => value === String(ciclicType)
                          )
                    }
                    className={`${stylesMod.select} ${errorType}`}
                    options={cyclicTypeOptions}
                    onChange={(e) => {
                      setCiclicType(e);
                    }}
                    name="contractor"
                    placeholder="wybierz..."
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        border: "1px solid #142f42",
                        boxShadow: "none",
                        "&:hover": {
                          border: "1px solid #142f42",
                        },
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused && "#142f42",
                        color: state.isFocused && "#fff",
                      }),
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          ) : (
            ""
          )}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={stylesMod.inp}>
                <label>Adres e-mail odbiorców</label>

                {emails_to_edit &&
                  emails_to_edit.map((data, i) => {
                    return (
                      <Grid
                        container
                        spacing={2}
                        key={i}
                        style={{ paddingBottom: "5px" }}
                      >
                        <Grid item xs={8}>
                          <div className={stylesMod.inp}>
                            <Input
                              className={errorEmail}
                              value={JSON.stringify(data) === "{}" ? "" : data}
                              handleChange={(e) => handleChange(i, e)}
                              name="email"
                              type="text"
                              placeholder="Adres e-mail"
                            />
                          </div>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            style={{ margin: "5px" }}
                            className={stylesMod.btnIcon2}
                            onClick={handleAddFields}
                          >
                            <PlusCircle
                              className={styles.add}
                              style={{
                                color: "var(--cor-col)",
                                stroke: "var(--cor-col)",
                              }}
                            />
                          </IconButton>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            style={{ margin: "5px 0px 5px 10px" }}
                            className={stylesMod.btnIcon2}
                            onClick={handleRemoveFields}
                          >
                            <XCircle
                              className={styles.remove}
                              style={{
                                color: "var(--main-col)",
                                stroke: "var(--main-col)",
                              }}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  })}
              </div>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={BTNstyles.btn}
            onClick={(e) => {
              props.setOpenModalCiclic(false);
              clear();
              if (props.isEdit) {
                props.refresh();
              }
              clear();
            }}
          >
            Anuluj
          </Button>
          <Button
            variant="contained"
            size="large"
            className={`${BTNstyles.btn} ${BTNstyles.grn}`}
            onClick={() => {
              let report_type_name;

              switch (props.state.componentName) {
                case "LoadingUnloading":
                  report_type_name = "REPORT_LOAD_UNLOAD";
                  break;
                case "LoadingUnloading_S":
                  report_type_name = "REPORT_LOAD_UNLOAD_S";
                  break;
                case "ForeignEpisodes":
                  report_type_name = "REPORT_FOREIGN_EPISODES";
                  break;
                case "Vindication":
                  report_type_name = "REPORT_LATE_INVOICES";
                  break;
                default:
                  break;
              }

              const emails = [];
              for (const i of cyclicalMail) {
                if (i.email !== undefined) {
                  emails.push(i.email);
                }
              }

              const data = {
                emails: emails_to_edit,
                file_type:
                  ciclicType && ciclicType.value ? ciclicType.value : null,
                id_car: null,
                id_customer: props.user.customer_id,
                id_driver: props.selectedDriver,
                id_firm: props.user.id_firm,
                id_user: props.user.id,
                ignoruj: null,
                is_append: checkPartialReport, // jezei miesiąc dodac chceckbox czesciowy
                is_enabled: true,
                report_type: report_type_name,
                zakres:
                  ciclicRange && ciclicRange.value ? ciclicRange.value : null,
              };

              const data_edit = {
                id: props.data?.id,
                emails: emails_to_edit.map((x) =>
                  typeof x === "object" ? String(x) : x
                ),
                file_type:
                  typeof ciclicType === "object"
                    ? ciclicType.value
                    : ciclicType,
                ignoruj: null,
                is_append: checkPartialReport, // jezei miesiąc dodac chceckbox czesciowy
                is_enabled: true,
                zakres:
                  typeof ciclicRange === "object"
                    ? ciclicRange.value
                    : ciclicRange,
              };

              const dataInvoises = {
                report_type: report_type_name,
                zakres:
                  typeof ciclicRange === "object"
                    ? ciclicRange.value
                    : ciclicRange,
                id_car: null,
                id_driver: null,
                ignoruj: days_after_deadline,
                is_enabled: true,
                is_append: false,
                file_type: "xls",
                emails: props.isEdit
                  ? emails_to_edit.map((x) =>
                      typeof x === "object" ? String(x) : x
                    )
                  : emails_to_edit.filter((x) => typeof x === "string"),
              };

              let term = data.emails;
              let term_edit = data_edit.emails;
              let re = new RegExp(
                "^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$"
              );

              const checkCiclicVindication = () => {
                if (
                  dataInvoises.ignoruj === "" ||
                  dataInvoises.ignoruj === undefined ||
                  dataInvoises.ignoruj === null ||
                  dataInvoises.zakres === undefined ||
                  dataInvoises.zakres === null ||
                  dataInvoises.zakres === "" ||
                  typeof dataInvoises.emails[0] !== "string"
                ) {
                  if (
                    dataInvoises.ignoruj === "" ||
                    dataInvoises.ignoruj === undefined ||
                    dataInvoises.ignoruj === null
                  ) {
                    setErrorDeadline("error");
                  } else {
                    setErrorDeadline("");
                  }

                  if (
                    dataInvoises.zakres === undefined ||
                    dataInvoises.zakres === "" ||
                    dataInvoises.zakres === null
                  ) {
                    setErrorRange("error");
                  } else {
                    setErrorRange("");
                  }

                  if (
                    typeof dataInvoises.emails[0] !== "string" ||
                    dataInvoises.emails.length === 0
                  ) {
                    setErrorEmail("error");
                  } else {
                    setErrorEmail("");
                  }

                  return false;
                } else {
                  setErrorDeadline("");
                  setErrorRange("");
                  setErrorEmail("");

                  return true;
                }
              };

              if (
                props.reportType === "invoices" ||
                props.data?.report_type === "REPORT_LATE_INVOICES"
              ) {
                if (props.isEdit) {
                  dataInvoises.id = props.data?.id;
                  if (checkCiclicVindication()) {
                    setCiclesReport(dataInvoises).then(() => {
                      props.setOpenModalCiclic(false);
                      if (props.isEdit) {
                        props.refresh();
                      }
                      clear();
                    });
                  } else {
                    setOpenDialog(true);
                    setDialogContent(`Wypełnij wszystkie pola..`);
                    setDialogColor("error");
                  }
                } else {
                  if (checkCiclicVindication()) {
                    setCiclesReport(dataInvoises).then(() => {
                      props.setOpenModalCiclic(false);
                      if (props.isEdit) {
                        props.refresh();
                      }
                      clear();
                    });
                  } else {
                    setOpenDialog(true);
                    setDialogContent(`Wypełnij wszystkie pola..`);
                    setDialogColor("error");
                  }
                }
              } else {
                if (props.isEdit) {
                  if (
                    data_edit.zakres === null ||
                    data_edit.file_type === null ||
                    data_edit.emails.length === 0 ||
                    re.test(term_edit) === false
                  ) {
                    if (data_edit.zakres === null) {
                      setErrorRange("error");
                    } else {
                      setErrorRange("");
                    }

                    if (data_edit.file_type === null) {
                      setErrorType("error");
                    } else {
                      setErrorType("");
                    }

                    if (
                      data_edit.emails.length === 0 ||
                      re.test(term) === false
                    ) {
                      setErrorEmail("error");
                    } else {
                      setErrorEmail("");
                    }

                    setOpenDialog(true);
                    setDialogContent(`Wypełnij wszystkie pola..`);
                    setDialogColor("error");
                  } else if (
                    data_edit.zakres !== null &&
                    data_edit.file_type !== null &&
                    re.test(term_edit) !== false
                  ) {
                    setErrorRange("");
                    setErrorType("");
                    setErrorEmail("");

                    props.setOpenModalCiclic(false);

                    setCiclesReport(data_edit);
                  }
                } else {
                  if (
                    data.zakres === null ||
                    data.file_type === null ||
                    data.emails.length === 0 ||
                    re.test(term) === false
                  ) {
                    if (data.zakres === null) {
                      setErrorRange("error");
                    } else {
                      setErrorRange("");
                    }

                    if (data.file_type === null) {
                      setErrorType("error");
                    } else {
                      setErrorType("");
                    }

                    if (data.emails.length === 0 || re.test(term) === false) {
                      setErrorEmail("error");
                    } else {
                      setErrorEmail("");
                    }

                    setOpenDialog(true);
                    setDialogContent(`Wypełnij wszystkie pola..`);
                    setDialogColor("error");
                  } else if (
                    data.zakres !== null &&
                    data.file_type !== null &&
                    re.test(term) !== false
                  ) {
                    setErrorRange("");
                    setErrorType("");
                    setErrorEmail("");

                    props.setOpenModalCiclic(false);

                    setCiclesReport(data);
                    clear();
                  }
                }
              }
            }}
            color="primary"
          >
            {props.isEdit ? "Edytuj" : "Dodaj"}
            {props.isEdit ? (
              <Edit
                className={BTNstyles.downBtnIco}
                style={props.isEdit ? { fontSize: "17px" } : ""}
              />
            ) : (
              <Add className={BTNstyles.downBtnIco} />
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={dialogColor}>
          {dialogContent}
        </Alert>
      </Snackbar>
    </>
  );
}
