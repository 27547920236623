import React, { useState, useEffect, forwardRef } from 'react'
import { Snackbar, Button, IconButton, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, TextField } from '@material-ui/core';
import { X, Calendar } from 'react-feather';
import Input from '../../components/input'
import { DBurl } from 'appConfig'
import stylesMod from '../../styles/newOrEdit.module.css'
import BTNstyles from '../../styles/button.module.css'
import docsStyles from '../../styles/documents.module.css'
import "react-datepicker/dist/react-datepicker.css";
import IcoBtn from 'components/buttons/icoBtn'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const DefineCols = ({token, open, setOpen, setSnack, setSnackContent, setSnackColor, data, colType, reload}) => {

    const setDocsOwnDef = async () => {
        const response = await fetch(`${DBurl}/setDocumentsOwnDef`, {
            method: "POST",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": token
                },
                body: JSON.stringify({
                mode: 'insert',
                data: {
					type: colType, // car, trailer, driver, firm
					col_name: newCol // nazwa kolumny
            }})});
            const json = await response.json()
            if (json.success) {
                reload(token)
            }
	}

    const delDocsOwnDef = async (id) => {
        const response = await fetch(`${DBurl}/setDocumentsOwnDef`, {
            method: "POST",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": token
                },
                body: JSON.stringify({
                mode: 'delete',
                data: {
                    ids:[id]
            }})});
            const json = await response.json()
            if (json.success) {
                reload(token)
            }
	}

    const [newCol, setNewCol] = useState('')

    return (
        <>
            <Dialog
                maxWidth='sm'
                fullWidth
                open={open}
                TransitionComponent={Transition}
                onClose={() => {
                    setOpen(false)
                    setNewCol('')
                }}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >

            <DialogTitle id="alert-dialog-slide-title" className={stylesMod.dialogHead}>Definiuj dodatkowe kolumny		
                <IconButton aria-label="close" className={stylesMod.closeButton} onClick={() => {
                    setOpen(false)
                    }}>
                    <X />
                </IconButton>
            </DialogTitle >
            <DialogContent>
                <Grid container spacing={2}>
                    {data.map((col) => <>
                        <Grid item xs={12}>
                            <div className={docsStyles.additionalCols}>
                                {col.col_name}
                                <IcoBtn icon="X" tooltip="Usuń" value={col} className={`${BTNstyles.btn} btn-delete`} onClick={(e) => {
                                    delDocsOwnDef(col.id)
                                }}/>
                            </div>
                        </Grid>
                    </>)}
                    <Grid item xs={12}>
                        <div className={docsStyles.additionalColsAdd}>
                        <Grid item xs={6}>
                            <label>Dodaj nową:</label>
                            <Input 						
						    value={newCol} 
						    handleChange={(e) => { setNewCol(e.target.value) }} 
						    name="newCol">
                            </Input>
                        </Grid>
                        <IcoBtn icon="Plus" tooltip="Dodaj" value={0} className={`${BTNstyles.btn} ${BTNstyles.grn}`}
                            onClick={(e) => {
                                setDocsOwnDef()
                                setNewCol('')
                        }} />
                        </div>
                    </Grid>
                </Grid >
            </DialogContent>

            <DialogActions>
                <Button variant="contained" size="large" color="primary" className={BTNstyles.btn} onClick={(e) => {
                    setOpen(false)
                    setNewCol('')
                    }}>
                    Ok
                </Button>
            </DialogActions>

            </Dialog>
            </>
            )
}

export default DefineCols;