import React, { useState, useRef, useEffect } from "react";
import { DBurl } from "../../appConfig";
import styles from "../../styles/chat.module.css";
import { IconButton, Tooltip } from "@material-ui/core";
import { Send, Upload } from "react-feather";

const ChatInput = ({
  chatID,
  token,
  refresh,
  messages,
  refetchDrivers,
  setDialog,
  setShowLoading,
}) => {
  const [text, setText] = useState("");
  const [isEnterSend, setIsEnterSend] = useState(true);

  const messagesEndRef = useRef(null);

  const sendMessageToDriver = async () => {
    const response = await fetch(`${DBurl}/sendMessageToDriver/`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify({
        data: {
          id_car: null,
          id_driver: chatID,
          msg: text,
        },
      }),
    });
    const json = await response.json();
    if (json.success) {
      refresh();
      setText("");
      refetchDrivers();
      scrollToBottom();
    }
  };

  const send = async () => {
    if (text.length > 1) {
      setShowLoading(true);
      await sendMessageToDriver();
      setShowLoading(false);
    } else {
      setDialog({
        isOpen: true,
        type: "error",
        content: "Nie możesz wysłać pustej wiadomości",
      });
    }
  };

  const sendFileToDriver = async (filename) => {
    const response = await fetch(`${DBurl}/sendFileToDriver`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify({
        data: {
          id_driver: chatID,
          filename: filename,
          msg: text,
        },
      }),
    });
    const json = await response.json();
    if (json.success) {
      refresh();
      scrollToBottom();
    }
  };

  const fetchFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", await file, file.name);
      const response = await fetch(`${DBurl}/uploadFile`, {
        method: "POST",
        credentials: "include",
        headers: {
          "X-CSRF-Token": token,
        },
        body: formData,
      });
      const json = await response.json();
      if (json.success) {
        sendFileToDriver(json.data?.filename);
      }
    } catch (err) {
      return;
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView();
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      send();
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div>
      <div
        onKeyDown={(e) => isEnterSend && handleEnter(e)}
        className={styles.chatInputParent}
        ref={messagesEndRef}
      >
        <span className={styles.sendText}>Napisz wiadomość:</span>

        <textarea
          onChange={(e) => setText(e.target.value)}
          value={text}
          rows={0}
          autoFocus
        />
        <input
          style={{ display: "none" }}
          accept="*"
          id="raised-button-file"
          type="file"
          onChange={(e) => {
            const fileList = Array.from(e.target.files);

            if (fileList.length > 0) {
              fetchFile(fileList[0]);
            }
          }}
        />
        <Tooltip title="Wyślij plik">
          <label htmlFor="raised-button-file">
            <IconButton component="span" value={text} className={styles.send}>
              <Upload />
            </IconButton>
          </label>
        </Tooltip>
        <IconButton onClick={send} value={text} className={styles.send}>
          <Send />
        </IconButton>
      </div>
      <label className={styles.enterCheckbox}>
        <input
          type="checkbox"
          checked={isEnterSend}
          onChange={(e) => setIsEnterSend(e.target.checked)}
        />
        Enter wysyła wiadomość
      </label>
    </div>
  );
};
export default ChatInput;
