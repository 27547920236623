import React, { useState, useEffect, useRef } from "react";
import Input from "../../components/input";
import Title from "../../components/title";
import styles from "../table.module.css";
import RowsPerPage from "../../components/RowsPerPage";
import Pagination from "../../components/pagination";
import IcoBtn from "components/buttons/icoBtn";
import { DBurl } from "../../appConfig";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Slide,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useQuery } from "react-query";
import { getCars } from "api/endpoints";
import {
  Leasing,
  Insurance,
  Service,
  Parts,
  Equipment,
  Sell,
  Tires,
  Taxes,
  Office,
  Employees,
  Other,
} from "./";
import "./styles.scss";
import docsStyles from "../../styles/documents.module.css";
import { headCells } from "./";
import DeleteRecords from "./DeleteRecords";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FleetCosts = (props) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("car");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [table, setTable] = useState([]);
  const [costs, setCosts] = useState([]);
  const [searchRegNum, setSearchRegNum] = useInput({ type: "text" });

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");
  const [openDialogAddCosts, setOpenDialogAddCosts] = useState();
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [deleteDialogData, setDeleteDialogData] = useState([]);
  const [date, setDate] = useState(new Date());
  const [refetch, setRefetch] = useState(false);
  const [carData, setCarData] = useState({});

  const handleOpen = (headCell) => {
    setOpenDialogAddCosts(headCell);
  };

  const handleCloseAddCosts = () => {
    setOpenDialogAddCosts(undefined);
  };

  const {
    data: cars,
    isLoading,
    refetch: refetchCars,
  } = useQuery("getCars", getCars);

  useEffect(() => {
    cars && setTable(cars);
  }, [cars]);

  const fetchCosts = async () => {
    props.loading(true);
    const response = await fetch(`${DBurl}/getFleetCosts`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      props.loading(false);
      setCosts(json.data);
      setRefetch(false);
    }
  };

  useEffect(() => {
    fetchCosts();
  }, [refetch === true]);

  const handleSplitCosts = (id) => {
    return costs
      .filter((c) => c.id_fleet_cost_type === id)
      .filter((c) => !c.id_car)
      .map((rec) => {
        return { ...rec, price: (rec.price / cars?.length).toFixed(2) };
      });
  };

  const handleSplitCostsForSummary = (id) => {
    return costs
      .filter((c) => c.id_fleet_cost_type === id)
      .filter((c) => !c.id_car);
  };

  const handleCosts = (id_car, arr) => {
    const allFixedCosts = costs
      .filter((r) => r.is_fixed_cost)
      .filter((r) => !r.id_car);
    const costsForCar = costs.filter((r) => r.id_car === id_car);
    const costsByType = costsForCar.filter((r) =>
      arr.includes(r.id_fleet_cost_type)
    );
    const fixedCostsByType = allFixedCosts.filter((r) =>
      arr.includes(r.id_fleet_cost_type)
    );
    const allCosts = fixedCostsByType.concat(costsByType);
    return allCosts;
  };

  const filterByMonthAndYear = (array) => {
    const selectedMonth = date.getMonth() + 1;
    const selectedYear = date.getFullYear();

    return array.filter((obj) => {
      const timeStart = new Date(obj.start_date);

      return (
        timeStart.getMonth() + 1 === selectedMonth &&
        timeStart.getFullYear() === selectedYear
      );
    });
  };

  const sumPricesByCurrency = (array) => {
    const sumByCurrency = array.reduce((acc, obj) => {
      const { price, currency } = obj;
      acc[currency] = (acc[currency] || 0) + Number(price);
      return acc;
    }, {});

    const resultArray = Object.entries(sumByCurrency).map(([currency, sum]) => {
      return `${sum.toFixed(2)} ${currency}`;
    });
    return resultArray.map((rec) => {
      return <div>{rec}</div>;
    });
  };

  const handleDeleteDataNoSplit = (arr, id) => {
    const selectedMonth = date.getMonth() + 1;
    const selectedYear = date.getFullYear();
    const filteredByType = costs
      .filter((r) => arr.includes(r.id_fleet_cost_type))
      .filter((r) => r.id_car === id)
      .filter((obj) => {
        const timeStart = new Date(obj.start_date);
        return (
          timeStart.getMonth() + 1 === selectedMonth &&
          timeStart.getFullYear() === selectedYear
        );
      });
    setDeleteDialogData(filteredByType);
  };

  const headCellsWithButtons = ["office", "empolyees", "other"];

  function EnhancedTableHead(props) {
    const {
      styles,
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;

    return (
      <TableHead className={styles.tabHead}>
        <TableRow>
          {headCells.map((headCell) => {
            return (
              <TableCell
                key={headCell.id}
                align="left"
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={headCell.class}
              >
                <div
                  className={
                    headCell.id === "car"
                      ? "fleet-costs__table-label--car"
                      : "fleet-costs__table-label"
                  }
                  onClick={() => {
                    if (!headCellsWithButtons.includes(headCell.id)) {
                      handleOpen(headCell.id);
                      setCarData({});
                    }
                  }}
                >
                  {headCell.label}
                  {headCellsWithButtons.includes(headCell.id) && (
                    <div className="fleet-costs__table-cell--icon-container">
                      <IcoBtn
                        icon="Plus"
                        tooltip="Dodaj"
                        onClick={() => {
                          handleOpen(headCell.id);
                          setCarData({});
                        }}
                        className="fleet-costs__table-icon"
                      />
                      <IcoBtn
                        icon="FileText"
                        tooltip="Podsumowanie"
                        onClick={() => {
                          if (headCell.id === "office") {
                            setDeleteDialogData(handleSplitCostsForSummary(12));
                          }
                          if (headCell.id === "empolyees") {
                            setDeleteDialogData(handleSplitCostsForSummary(13));
                          }
                          if (headCell.id === "other") {
                            setDeleteDialogData(handleSplitCostsForSummary(14));
                          }
                          setOpenDialogDelete(true);
                        }}
                        className="fleet-costs__table-icon"
                      />
                    </div>
                  )}
                </div>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDialog(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const search = (tbl) => {
    const ret = tbl.filter((row) => {
      return row.nr_rejestracyjny
        ?.toLowerCase()
        .includes(searchRegNum.toLowerCase());
    });
    return ret;
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = table.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const paginate = (pageNumber) => setPage(pageNumber - 1);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, table.length - page * rowsPerPage);

  function useInput({ type }) {
    const [searchValue, setSearchValue] = useState("");
    const input = (
      <TableCell className={docsStyles.headCell}>
        <Input
          value={searchValue}
          handleChange={(e) => {
            setSearchValue(e.target.value);
          }}
          type={type}
          search={true}
          id={"search"}
          autocomplete={false}
        />
      </TableCell>
    );
    return [searchValue, input];
  }

  return (
    <>
      <div className={styles.root}>
        <Paper className={styles.paper}>
          <Title
            title={props.state.tabName}
            btnBox
            cornersNew
            datapicker_month={setDate}
            date={date}
          />
          <TableContainer className={styles.stickyTable}>
            <Table
              className={styles.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                styles={styles}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={table.length}
              />
              <TableBody className={styles.tabBody}>
                {search(table)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <>
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          style={{
                            backgroundColor: "inherit",
                            height: 51,
                          }}
                        >
                          <TableCell className="fleet-costs__table-cell--vertical-border">
                            {row.nr_rejestracyjny}
                          </TableCell>
                          <TableCell className="fleet-costs__table-cell--vertical-border">
                            <div className="fleet-costs__table-cell">
                              <div className="fleet-costs__table-cell--value-container">
                                {sumPricesByCurrency(
                                  filterByMonthAndYear(
                                    handleCosts(row.id, [2, 3, 4, 5])
                                  )
                                )}
                              </div>
                              <div className="fleet-costs__table-cell--icon-container">
                                <IcoBtn
                                  icon="Plus"
                                  tooltip="Dodaj"
                                  onClick={() => {
                                    handleOpen("leasing");
                                    setCarData(row);
                                  }}
                                  className="fleet-costs__table-icon"
                                />
                                <IcoBtn
                                  icon="FileText"
                                  tooltip="Podsumowanie"
                                  onClick={() => {
                                    setDeleteDialogData(
                                      filterByMonthAndYear(
                                        handleCosts(row.id, [2, 3, 4, 5])
                                      )
                                    );
                                    setOpenDialogDelete(true);
                                  }}
                                  className="fleet-costs__table-icon"
                                />
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className="fleet-costs__table-cell--vertical-border">
                            <div className="fleet-costs__table-cell">
                              <div className="fleet-costs__table-cell--value-container">
                                {sumPricesByCurrency(
                                  filterByMonthAndYear(handleCosts(row.id, [6]))
                                )}
                              </div>
                              <IcoBtn
                                icon="Plus"
                                tooltip="Dodaj"
                                onClick={() => {
                                  handleOpen("insurance");
                                  setCarData(row);
                                }}
                                className="fleet-costs__table-icon"
                              />
                              <IcoBtn
                                icon="FileText"
                                tooltip="Podsumowanie"
                                onClick={() => {
                                  setDeleteDialogData(
                                    filterByMonthAndYear(
                                      handleCosts(row.id, [6])
                                    )
                                  );
                                  setOpenDialogDelete(true);
                                }}
                                className="fleet-costs__table-icon"
                              />
                            </div>
                          </TableCell>
                          <TableCell className="fleet-costs__table-cell--vertical-border">
                            <div className="fleet-costs__table-cell">
                              <div className="fleet-costs__table-cell--value-container">
                                {sumPricesByCurrency(
                                  filterByMonthAndYear(
                                    handleCosts(row.id, [0, 1])
                                  ).concat(handleSplitCosts(1))
                                )}
                              </div>
                              <IcoBtn
                                icon="Plus"
                                tooltip="Dodaj"
                                onClick={() => {
                                  handleOpen("service");
                                  setCarData(row);
                                }}
                                className="fleet-costs__table-icon"
                              />
                              <IcoBtn
                                icon="FileText"
                                tooltip="Podsumowanie"
                                onClick={() => {
                                  setDeleteDialogData(
                                    filterByMonthAndYear(
                                      handleCosts(row.id, [0, 1])
                                    ).concat(handleSplitCosts(1))
                                  );
                                  setOpenDialogDelete(true);
                                }}
                                className="fleet-costs__table-icon"
                              />
                            </div>
                          </TableCell>
                          <TableCell className="fleet-costs__table-cell--vertical-border">
                            <div className="fleet-costs__table-cell">
                              <div className="fleet-costs__table-cell--value-container">
                                {sumPricesByCurrency(
                                  filterByMonthAndYear(handleCosts(row.id, [7]))
                                )}
                              </div>
                              <IcoBtn
                                icon="Plus"
                                tooltip="Dodaj"
                                onClick={() => {
                                  handleOpen("parts");
                                  setCarData(row);
                                }}
                                className="fleet-costs__table-icon"
                              />
                              <IcoBtn
                                icon="FileText"
                                tooltip="Podsumowanie"
                                onClick={() => {
                                  setDeleteDialogData(
                                    filterByMonthAndYear(
                                      handleCosts(row.id, [7])
                                    )
                                  );
                                  setOpenDialogDelete(true);
                                }}
                                className="fleet-costs__table-icon"
                              />
                            </div>
                          </TableCell>
                          <TableCell className="fleet-costs__table-cell--vertical-border">
                            <div className="fleet-costs__table-cell">
                              <div className="fleet-costs__table-cell--value-container">
                                {sumPricesByCurrency(
                                  filterByMonthAndYear(handleCosts(row.id, [8]))
                                )}
                              </div>
                              <IcoBtn
                                icon="Plus"
                                tooltip="Dodaj"
                                onClick={() => {
                                  handleOpen("equipment");
                                  setCarData(row);
                                }}
                                className="fleet-costs__table-icon"
                              />
                              <IcoBtn
                                icon="FileText"
                                tooltip="Podsumowanie"
                                onClick={() => {
                                  setDeleteDialogData(
                                    filterByMonthAndYear(
                                      handleCosts(row.id, [8])
                                    )
                                  );
                                  setOpenDialogDelete(true);
                                }}
                                className="fleet-costs__table-icon"
                              />
                            </div>
                          </TableCell>
                          <TableCell className="fleet-costs__table-cell--vertical-border">
                            <div className="fleet-costs__table-cell">
                              <div className="fleet-costs__table-cell--value-container">
                                {sumPricesByCurrency(
                                  filterByMonthAndYear(handleCosts(row.id, [9]))
                                )}
                              </div>
                              <IcoBtn
                                icon="Plus"
                                tooltip="Dodaj"
                                onClick={() => {
                                  handleOpen("sell");
                                  setCarData(row);
                                }}
                                className="fleet-costs__table-icon"
                              />
                              <IcoBtn
                                icon="FileText"
                                tooltip="Podsumowanie"
                                onClick={() => {
                                  setDeleteDialogData(
                                    filterByMonthAndYear(
                                      handleCosts(row.id, [9])
                                    )
                                  );
                                  setOpenDialogDelete(true);
                                }}
                                className="fleet-costs__table-icon"
                              />
                            </div>
                          </TableCell>
                          <TableCell className="fleet-costs__table-cell--vertical-border">
                            <div className="fleet-costs__table-cell">
                              <div className="fleet-costs__table-cell--value-container">
                                {sumPricesByCurrency(
                                  filterByMonthAndYear(
                                    handleCosts(row.id, [10])
                                  )
                                )}
                              </div>
                              <IcoBtn
                                icon="Plus"
                                tooltip="Dodaj"
                                onClick={() => {
                                  handleOpen("tires");
                                  setCarData(row);
                                }}
                                className="fleet-costs__table-icon"
                              />
                              <IcoBtn
                                icon="FileText"
                                tooltip="Podsumowanie"
                                onClick={() => {
                                  setDeleteDialogData(
                                    filterByMonthAndYear(
                                      handleCosts(row.id, [10])
                                    )
                                  );
                                  setOpenDialogDelete(true);
                                }}
                                className="fleet-costs__table-icon"
                              />
                            </div>
                          </TableCell>
                          <TableCell className="fleet-costs__table-cell--vertical-border">
                            <div className="fleet-costs__table-cell">
                              <div className="fleet-costs__table-cell--value-container">
                                {sumPricesByCurrency(
                                  filterByMonthAndYear(
                                    handleCosts(row.id, [11])
                                  )
                                )}
                              </div>
                              <IcoBtn
                                icon="Plus"
                                tooltip="Dodaj"
                                onClick={() => {
                                  handleOpen("taxes");
                                  setCarData(row);
                                }}
                                className="fleet-costs__table-icon"
                              />
                              <IcoBtn
                                icon="FileText"
                                tooltip="Podsumowanie"
                                onClick={() => {
                                  setDeleteDialogData(
                                    filterByMonthAndYear(
                                      handleCosts(row.id, [11])
                                    )
                                  );
                                  setOpenDialogDelete(true);
                                }}
                                className="fleet-costs__table-icon"
                              />
                            </div>
                          </TableCell>
                          <TableCell className="fleet-costs__table-cell--vertical-border">
                            <div
                              onClick={() => {
                                setDeleteDialogData(handleSplitCosts(12));
                                setOpenDialogDelete(true);
                              }}
                            >
                              {sumPricesByCurrency(
                                filterByMonthAndYear(handleSplitCosts(12))
                              )}
                            </div>
                          </TableCell>
                          <TableCell className="fleet-costs__table-cell--vertical-border">
                            <div
                              onClick={() => {
                                setDeleteDialogData(handleSplitCosts(13));
                                setOpenDialogDelete(true);
                              }}
                            >
                              {sumPricesByCurrency(
                                filterByMonthAndYear(handleSplitCosts(13))
                              )}
                            </div>
                          </TableCell>
                          <TableCell className="fleet-costs__table-cell--vertical-border">
                            <div
                              onClick={() => {
                                setDeleteDialogData(handleSplitCosts(14));
                                setOpenDialogDelete(true);
                              }}
                            >
                              {sumPricesByCurrency(
                                filterByMonthAndYear(handleSplitCosts(14))
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 33 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={styles.tableBottom}>
            <RowsPerPage
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              setPage={setPage}
              tableLength={table.length}
            />

            <div>
              <Pagination
                postsPerPage={rowsPerPage}
                totalPosts={search(table).length}
                paginate={paginate}
                page={page}
              />
            </div>
          </div>
        </Paper>
      </div>
      <Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={dialogColor}>
          {dialogContent}
        </Alert>
      </Snackbar>
      <div>
        <Leasing
          modalID={openDialogAddCosts}
          handleClose={handleCloseAddCosts}
          cars={cars}
          token={props.user.csrf_token}
          setRefetch={setRefetch}
          carData={carData}
          setSnack={setOpenDialog}
          setSnackContent={setDialogContent}
          setSnackColor={setDialogColor}
        />
        <Insurance
          modalID={openDialogAddCosts}
          handleClose={handleCloseAddCosts}
          cars={cars}
          token={props.user.csrf_token}
          setRefetch={setRefetch}
          carData={carData}
          setSnack={setOpenDialog}
          setSnackContent={setDialogContent}
          setSnackColor={setDialogColor}
        />
        <Service
          modalID={openDialogAddCosts}
          handleClose={handleCloseAddCosts}
          cars={cars}
          token={props.user.csrf_token}
          setRefetch={setRefetch}
          carData={carData}
          setSnack={setOpenDialog}
          setSnackContent={setDialogContent}
          setSnackColor={setDialogColor}
        />
        <Parts
          modalID={openDialogAddCosts}
          handleClose={handleCloseAddCosts}
          cars={cars}
          token={props.user.csrf_token}
          setRefetch={setRefetch}
          carData={carData}
          setSnack={setOpenDialog}
          setSnackContent={setDialogContent}
          setSnackColor={setDialogColor}
        />
        <Equipment
          modalID={openDialogAddCosts}
          handleClose={handleCloseAddCosts}
          cars={cars}
          token={props.user.csrf_token}
          setRefetch={setRefetch}
          carData={carData}
          setSnack={setOpenDialog}
          setSnackContent={setDialogContent}
          setSnackColor={setDialogColor}
        />
        <Sell
          modalID={openDialogAddCosts}
          handleClose={handleCloseAddCosts}
          cars={cars}
          token={props.user.csrf_token}
          setRefetch={setRefetch}
          carData={carData}
          setSnack={setOpenDialog}
          setSnackContent={setDialogContent}
          setSnackColor={setDialogColor}
        />
        <Tires
          modalID={openDialogAddCosts}
          handleClose={handleCloseAddCosts}
          cars={cars}
          token={props.user.csrf_token}
          setRefetch={setRefetch}
          carData={carData}
          setSnack={setOpenDialog}
          setSnackContent={setDialogContent}
          setSnackColor={setDialogColor}
        />
        <Taxes
          modalID={openDialogAddCosts}
          handleClose={handleCloseAddCosts}
          cars={cars}
          token={props.user.csrf_token}
          setRefetch={setRefetch}
          carData={carData}
          setSnack={setOpenDialog}
          setSnackContent={setDialogContent}
          setSnackColor={setDialogColor}
        />
        <Office
          modalID={openDialogAddCosts}
          handleClose={handleCloseAddCosts}
          cars={cars}
          token={props.user.csrf_token}
          setRefetch={setRefetch}
          setSnack={setOpenDialog}
          setSnackContent={setDialogContent}
          setSnackColor={setDialogColor}
        />
        <Employees
          modalID={openDialogAddCosts}
          handleClose={handleCloseAddCosts}
          cars={cars}
          token={props.user.csrf_token}
          setRefetch={setRefetch}
          setSnack={setOpenDialog}
          setSnackContent={setDialogContent}
          setSnackColor={setDialogColor}
        />
        <Other
          modalID={openDialogAddCosts}
          handleClose={handleCloseAddCosts}
          cars={cars}
          token={props.user.csrf_token}
          setRefetch={setRefetch}
          setSnack={setOpenDialog}
          setSnackContent={setDialogContent}
          setSnackColor={setDialogColor}
        />
        <DeleteRecords
          token={props.user.csrf_token}
          setRefetch={setRefetch}
          open={openDialogDelete}
          setOpen={setOpenDialogDelete}
          setSnack={setOpenDialog}
          setSnackContent={setDialogContent}
          setSnackColor={setDialogColor}
          data={deleteDialogData}
          setData={setDeleteDialogData}
        />
      </div>
    </>
  );
};

export default FleetCosts;
