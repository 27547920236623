import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import stylesMod from "../../styles/newOrEdit.module.css";
import styles from "../table.module.css";
import Button from "@material-ui/core/Button";
import NewOrder from "./newOrder/newOrder";
import { X } from "react-feather";
import IconButton from "@material-ui/core/IconButton";
import { DBurl } from "../../appConfig";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { TableHead } from "@material-ui/core";
import moment from "moment";
import {
  toggleShowAllTags,
  getAllTags,
  getBarColor,
} from "../helpers/table.helpers";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function OrderDialog(props) {
  const [driver, setDriver] = useState(
    (props.data.transport_set && props.data.transport_set.driver1_name) || ""
  );
  const [secondDriver, setSecondDriver] = useState(
    (props.data.transport_set && props.data.transport_set.driver2_name) || ""
  );
  const [trailer, setTrailer] = useState(
    (props.data.transport_set && props.data.transport_set.trailer_name) || ""
  );
  const [sum_kilometers, setSumKilometers] = useState(
    props.data.sum_kilometers || ""
  );
  const [start_time, setStart_time] = useState(props.data.start_time || "");
  const [locations, setLocations] = useState(props.data.locations || []);
  const [end_time, setEnd_time] = useState(props.data.end_time || "");
  const [id, setID] = useState(props.data.id);
  const [order_no, setOrder_no] = useState("");
  const [order_name, setOrder_name] = useState("");
  const [order_description, setOrder_description] = useState("");

  const [ppp, setPPP] = useState(props.data.hand_over_type || "");
  const [adress_ppp, setAdress_ppp] = useState(props.data.hand_over_type || "");
  const [locationPPP, setLocationPPP] = useState(
    props.data.hand_over_type || ""
  );
  const allTags = getAllTags(props.data?.tags || []);

  const location = (type) => {
    if (locations.length > 0) {
      const tempLocation = locations.find((e) => e.type === type);
      return tempLocation
        ? `${tempLocation.country ? tempLocation.country : ""}, ${
            tempLocation.zipcode ? tempLocation.zipcode : ""
          } ${tempLocation.city ? tempLocation.city : ""}, ${
            tempLocation.street ? tempLocation.street : ""
          } ${tempLocation.street_no ? tempLocation.street_no : ""}`
        : "brak danych adresowych";
    } else {
      return "b.d";
    }
  };

  const getOrder = async (id) => {
    const response = await fetch(`${DBurl}/getOrders?id=${id}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      const data = json.data[0];
      if (json.data.length > 0) {
        setDriver(
          data.current_changing_transport_set_id !== null
            ? data.changing_transport_sets[0].transport_set.driver1_name
            : data.transport_set.driver1_name
        );
        setSecondDriver(
          data.current_changing_transport_set_id !== null
            ? data.changing_transport_sets[0].transport_set.driver2_name
            : data.transport_set.driver2_name
        );
        setTrailer(
          data.current_changing_transport_set_id !== null
            ? data.changing_transport_sets[0].transport_set.trailer_name
            : data.transport_set.trailer_name
        );
        setSumKilometers(data.sum_kilometers);
        setStart_time(data.locations[0].ts);
        setEnd_time(data.locations[data.locations.length - 1].ts);
        setLocations(data.locations);
        setID(data.id);
      }
    }
  };

  const getOrderS = async function (id) {
    const response = await fetch(`${DBurl}/getOrdersS?id=${id}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();

    if (json.success) {
      const data = json.data[0];
      if (json.data.length > 0) {
        setDriver(data.transport_set.driver1_name);
        setSecondDriver(data.transport_set.driver2_name);
        setTrailer(data.transport_set.trailer_name);
        setID(data.id);
        setOrder_description(data.order_description);
        setOrder_name(data.order_name);
        setOrder_no(data.order_no);
      }
    }
  };

  useEffect(() => {
    if (props.tms_version_s || props.order_type_S) {
      getOrderS(props.data.id);
    } else {
      getOrder(props.data.id_order);
    }
  }, []);

  console.log("data", props.data);

  const setLabelForSubtype = (val) => {
    switch (val) {
      case "parking":
        return { value: "parking", label: "Parking" };
      case "ferry":
        return { value: "ferry", label: "Prom" };
      case "start":
        return { value: "start", label: "Początek trasy" };
      case "end":
        return { value: "end", label: "Koniec trasy" };
      case "borderCrossing":
        return { value: "borderCrossing", label: "Przejście graniczne" };
      case "point":
        return { value: "point", label: "Punkt kontrolny" };
      case "refueling":
        return { value: "refueling", label: "Tankowanie" };
      case "loading":
        return { value: "loading", label: "Załadunek" };
      case "unloading":
        return { value: "unloading", label: "Rozładunek" };
      default:
        return { value: "point", label: "Punkt kontrolny" };
    }
  };

  const locationName = (location) => {
    return location.subtype
      ? setLabelForSubtype(location.subtype).label
      : location.type === "loading"
      ? "Załadunek"
      : "Rozładunek";
  };

  return (
    <Dialog
      maxWidth="md"
      open={props.open}
      TransitionComponent={Transition}
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        className={stylesMod.dialogHead}
      >
        {props.orderStatus === true
          ? `Historia statusów`
          : `Zlecenie ${props.data.order_no}`}
        <IconButton
          aria-label="close"
          className={stylesMod.closeButton}
          onClick={() => {
            props.setOpen(false);
          }}
        >
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent id="alert-dialog-slide-description">
        {
          props.order_type_S ? (
            //wersja S informacji o zleceniu

            <Grid container spacing={1} className={stylesMod.cont_dialog_order}>
              <Grid item xs={4}>
                <h6 className={stylesMod.cenHead}>Numer zlecenia: </h6>
              </Grid>
              <Grid item xs={8}>
                <p>{props.data.order_no || "b.d."} </p>
              </Grid>

              <Grid item xs={4}>
                <h6 className={stylesMod.cenHead}>Nazwa zlecenia: </h6>
              </Grid>
              <Grid item xs={8}>
                <p>{props.data.order_name || "b.d."} </p>
              </Grid>

              <Grid item xs={4}>
                <h6 className={stylesMod.cenHead}>Opis zlecenia: </h6>
              </Grid>
              <Grid item xs={8}>
                <p>{props.data.order_description || "b.d."} </p>
              </Grid>

              <Grid item xs={4}>
                <h6 className={stylesMod.cenHead}>Start: </h6>
              </Grid>
              <Grid item xs={8}>
                <p>{props.data.order_start || "b.d."} </p>
              </Grid>

              <Grid item xs={4}>
                <h6 className={stylesMod.cenHead}>Stop: </h6>
              </Grid>
              <Grid item xs={8}>
                <p>{props.data.order_stop || "b.d."} </p>
              </Grid>

              <Grid item xs={4}>
                <h6 className={stylesMod.cenHead}>Kierowca: </h6>
              </Grid>
              <Grid item xs={8}>
                <p>{props?.data?.transport_set?.driver1_name || "b.d."} </p>
              </Grid>
            </Grid>
          ) : props.orderStatus !== true ? (
            <Grid container spacing={2} className={stylesMod.cont}>
              <Grid item xs={4}>
                <h6 className={stylesMod.cenHead}>Kierowca: </h6>
              </Grid>
              <Grid item xs={8}>
                <p>{driver === "" ? "b.d" : driver} </p>
                {!!secondDriver && <p>{secondDriver}</p>}
              </Grid>

              <Grid item xs={4}>
                <h6 className={stylesMod.cenHead}>Naczepa: </h6>
              </Grid>
              <Grid item xs={8}>
                <p>{trailer === "" ? "b.d" : trailer} </p>
              </Grid>

              <Grid item xs={4}>
                <h6 className={stylesMod.cenHead}>Dystans: </h6>
              </Grid>
              <Grid item xs={8}>
                <p>{sum_kilometers === "" ? "b.d" : sum_kilometers + " km"}</p>
              </Grid>

              <Grid item xs={4}>
                <h6 className={stylesMod.cenHead}>Data załadunku: </h6>
              </Grid>
              <Grid item xs={8}>
                <p>
                  {start_time === ""
                    ? "b.d"
                    : moment(start_time).format("YYYY-MM-DD HH:mm:ss")}{" "}
                </p>
              </Grid>

              <Grid item xs={4}>
                <h6 className={stylesMod.cenHead}>Miejsce załadunku: </h6>
              </Grid>
              <Grid item xs={8}>
                <p>{location("loading")} </p>
              </Grid>

              <Grid item xs={4}>
                <h6 className={stylesMod.cenHead}>Data rozładunku: </h6>
              </Grid>
              <Grid item xs={8}>
                <p>
                  {end_time === ""
                    ? "b.d"
                    : moment(end_time).format("YYYY-MM-DD HH:mm:ss")}{" "}
                </p>
              </Grid>

              <Grid item xs={4}>
                <h6 className={stylesMod.cenHead}>Miejsce rozładunku: </h6>
              </Grid>
              <Grid item xs={8}>
                <p>{location("unloading")} </p>
              </Grid>

              <Grid item xs={4}>
                <h6 className={stylesMod.cenHead}>Tagi: </h6>
              </Grid>
              <Grid item xs={8}>
                {allTags.length > 0 ? (
                  allTags.join(", ")
                ) : (
                  <div>-brak tagów dla tego zlecenia-</div>
                )}
              </Grid>
              {props?.data?.locations?.length > 1 && (
                <>
                  <Grid item xs={4}>
                    <h6 className={stylesMod.cenHead}>Trasa:</h6>
                  </Grid>
                  <Grid item xs={8}>
                    <p>
                      <ul style={{ paddingLeft: "20px" }}>
                        {props?.data?.locations?.map((location) => (
                          <li>{locationName(location)}</li>
                        ))}
                      </ul>
                    </p>
                  </Grid>
                </>
              )}

              {ppp !== "" ? (
                <>
                  <Grid item xs={4}>
                    <h6 className={stylesMod.cenHead}>
                      Przekazanie zlecenia:{" "}
                    </h6>
                  </Grid>
                  <Grid item xs={8}>
                    <p>
                      {ppp === 0
                        ? "Przepinka"
                        : ppp === 1
                        ? "Przesiadka"
                        : "Przeładunek"}{" "}
                    </p>
                  </Grid>
                  {/* <Grid item xs={4}>
								<h6 className={stylesMod.cenHead}>Adres {ppp === 0 ? "Przepinki" : (ppp === 1 ? "Przesiadki" : "Przeładunku")}: </h6>
							</Grid>
							<Grid item xs={8}>
								<p>{props.data.changing_transport_sets[0].address_name || 'b.d'} </p>
							</Grid> */}
                </>
              ) : (
                ""
              )}
            </Grid>
          ) : (
            <Grid
              container
              spacing={2}
              className={`${stylesMod.orderStatus} status-information-grid`}
            >
              <div className="status-information">
                <div>
                  <h6>Nazwa Zlecenia</h6>
                </div>
                <div>
                  <p>{props.data.order_no}</p>
                </div>
                <div className="seperator"></div>
                <div>
                  <h6>Zestaw transportowy</h6>
                </div>
                <div>
                  <p>
                    {props?.mainData?.transport_set !== null
                      ? props?.mainData?.transport_set?.label
                      : "Brak zestawu transportowego"}
                  </p>
                </div>
              </div>

              <Grid item xs={12} className="status-history">
                <TableContainer>
                  <Table stickyHeader size="small" aria-label="enhanced table">
                    <TableHead className={styles.tabHead}>
                      <TableRow>
                        <TableCell align="left">Data</TableCell>
                        <TableCell align="left">Nazwa czynności</TableCell>
                        <TableCell align="left">Waga</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody className={styles.tabBody}>
                      {props?.mainData?.active_order_last_status.map(
                        (row, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell align="left">
                                {row.ts_status_start.split(".")[0]}
                              </TableCell>
                              <TableCell align="left">{row.name}</TableCell>
                              <TableCell align="left">
                                {row.weight && row.weight + "kg"}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          )
          //pełna wersja informacji o zleceniu
        }
      </DialogContent>
      <DialogActions>
        {props.orderStatus === true ? (
          <Button
            autoFocus
            onClick={() => {
              props.setOpen(false);
            }}
            color="primary"
          >
            Zamknij
          </Button>
        ) : (
          <Button
            autoFocus
            onClick={() => {
              let tempData = {
                permission_id: 7,
                tabName: "Edytuj zlecenie",
                isEdit: true,
                isCopy: false,
                tabID: null,
                component: NewOrder,
                fetchOrderId: Number(String(id).replace("---double", "")),
                order_type_S: false,
                order_start: props.data.order_start,
                order_stop: props.data.order_stop,
              };
              props.addTab("newOrder", tempData);
            }}
            color="primary"
          >
            Edytuj
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
