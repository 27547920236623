import React, { SetStateAction, useState } from "react";
import { AddFleetCostsContainerSelect, AddFleetCostsGenericModal, MODALS, MODALS_NAMES_TYPE, SelectOptionType, getCarsOptions, AddFleetCostsContainerInput, CURRENCY_OPTIONS, handleDatePickerLabel, addFleetCostsApiCall } from "..";
import { Car } from "types";
import AddFleetCostsContainerDatePicker from "../AddFleetCosts/AddFleetCostsDatePicker";
import './styles.scss'


export type OtherModalPropsType = {
    modalID?: MODALS_NAMES_TYPE;
    handleClose: () => void;
    cars: Car[];
    token: string;
    setRefetch: (value: SetStateAction<boolean>) => void;
    setSnack: React.Dispatch<React.SetStateAction<boolean>>;
    setSnackContent: React.Dispatch<React.SetStateAction<string>>;
    setSnackColor: React.Dispatch<React.SetStateAction<string>>;
}

const Other = ({ modalID, handleClose, cars, token, setRefetch, setSnack, setSnackContent, setSnackColor }: OtherModalPropsType) => {
    const [price, setPrice] = useState<number>(0)
    const [date, setDate] = useState<Date>(new Date)
    const [currency, setCurrency] = useState<SelectOptionType>({ value: "", label: "" })
    const [description, setDescription] = useState<number>(0)
    const [fixedCosts, setFixedCosts] = useState<boolean>(false)

    const closeAll = () => {
        handleClose()
        setPrice(0)
        setDate(new Date)
        setCurrency({ value: "", label: "" })
        setDescription(0)
        setRefetch(true)
        setSnack(true)
        setSnackColor("success")
        setSnackContent("Dane poprawnie zapisane.")
    }

    const handleFail = () => {
        setSnack(true)
        setSnackColor("error")
        setSnackContent("Nie udało się zapisać danych.")
    }

    const handleConfirm = async () => {
        {
            const data = {
                id_fleet_cost_type: 14,
                price: price,
                start_date: date || new Date(),
                currency: currency.value,
                description: description || null,
                is_fixed_cost: fixedCosts,
                end_date: fixedCosts ? null : date
            };
            await addFleetCostsApiCall("setFleetCosts", token, data, closeAll, handleFail);
        }
    };


    return (
        <AddFleetCostsGenericModal open={MODALS.OTHER === modalID} modalID={modalID} handleClose={handleClose} handleConfirm={handleConfirm}>
            <div className="fleet-costs-service-modal">
                <div className="fleet-costs-service-modal__checkbox-container">
                    <input type="checkbox"
                        className=""
                        checked={fixedCosts}
                        onChange={(e) => {
                            setFixedCosts(e.target.checked)
                        }}
                        name="fixedCosts"
                    />
                    <label className="" >Koszty stałe</label>
                </div>
                <div className="fleet-costs-service-modal__elements-container">
                    <AddFleetCostsContainerInput label={"Koszt"} value={price} type='number' onChangeHandler={setPrice} />
                    <AddFleetCostsContainerSelect label={"Waluta"} value={currency} onChangeHandler={setCurrency} options={CURRENCY_OPTIONS} />
                    <AddFleetCostsContainerInput label={"Koszt per pojazd"} value={price ? (price / cars.length).toFixed(2) : 0} disabled type='number' onChangeHandler={setPrice} />
                    <AddFleetCostsContainerInput label={"Opis"} value={description} type='text' onChangeHandler={setDescription} />
                    <AddFleetCostsContainerDatePicker label={"Miesiąc"} value={date} onChangeHandler={setDate} showMonthYearPicker={true} format={"MM-yyyy"} />
                </div>
            </div>
        </AddFleetCostsGenericModal >
    )
}

export default Other;