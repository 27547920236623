import React, { SetStateAction, useEffect, useState } from "react";
import { AddFleetCostsContainerSelect, AddFleetCostsGenericModal, MODALS, MODALS_NAMES_TYPE, SelectOptionType, getCarsOptions, AddFleetCostsContainerInput, CURRENCY_OPTIONS, handleDatePickerLabel, addFleetCostsApiCall } from "..";
import { Car } from "types";
import AddFleetCostsContainerDatePicker from "../AddFleetCosts/AddFleetCostsDatePicker";
import './styles.scss'

export type InsuranceModalPropsType = {
    modalID?: MODALS_NAMES_TYPE;
    handleClose: () => void;
    cars: Car[];
    token: string;
    setRefetch: (value: SetStateAction<boolean>) => void;
    carData: Car
    setSnack: React.Dispatch<React.SetStateAction<boolean>>;
    setSnackContent: React.Dispatch<React.SetStateAction<string>>;
    setSnackColor: React.Dispatch<React.SetStateAction<string>>;
}

const Equipment = ({ modalID, handleClose, cars, token, setRefetch, carData, setSnack, setSnackContent, setSnackColor }: InsuranceModalPropsType) => {
    const [selectedCar, setSelectedCar] = useState<SelectOptionType>({ value: "", label: "" })
    const [price, setPrice] = useState<number>(0)
    const [date, setDate] = useState<Date>(new Date)
    const [currency, setCurrency] = useState<SelectOptionType>({ value: "", label: "" })
    const [description, setDescription] = useState<string>("")

    useEffect(() => {
        carData && setSelectedCar({value: carData.id, label: carData.nr_rejestracyjny})
    },[carData])

    const closeAll = () => {
        handleClose()
        setSelectedCar({ value: "", label: "" })
        setPrice(0)
        setDate(new Date)
        setCurrency({ value: "", label: "" })
        setDescription("")
        setRefetch(true)
        setSnack(true)
        setSnackColor("success")
        setSnackContent("Dane poprawnie zapisane.")
    }

    const handleFail = () => {
        setSnack(true)
        setSnackColor("error")
        setSnackContent("Nie udało się zapisać danych.")
    }

    const handleConfirm = async () => {
        {
            const data = {
                id_fleet_cost_type: 8,
                price: price,
                start_date: date || new Date(),
                currency: currency.value,
                description: description,
                id_car: selectedCar.value,
                is_fixed_cost: false
            };
            await addFleetCostsApiCall("setFleetCosts", token, data, closeAll, handleFail);
        }
    };


    return (
    <AddFleetCostsGenericModal open={MODALS.EQUIPMENT === modalID} modalID={modalID} handleClose={handleClose} handleConfirm={handleConfirm}>
        <div className="fleet-costs-service-modal">
            <div className="fleet-costs-service-modal__elements-container">
            <AddFleetCostsContainerSelect label={"Pojazd"} value={selectedCar} onChangeHandler={setSelectedCar} options={getCarsOptions(cars)} />
            <AddFleetCostsContainerInput label={"Koszt"} value={price} type='number' onChangeHandler={setPrice} />
            <AddFleetCostsContainerSelect label={"Waluta"} value={currency} onChangeHandler={setCurrency} options={CURRENCY_OPTIONS} />
            <AddFleetCostsContainerInput label={"Opis"} value={description} type='text' onChangeHandler={setDescription} />
            <AddFleetCostsContainerDatePicker label={"Miesiąc"} value={date} onChangeHandler={setDate} showMonthYearPicker={true} format={"MM-yyyy"} />
            </div>
        </div>
    </AddFleetCostsGenericModal >
    )
}

export default Equipment;