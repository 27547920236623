import React, { useState } from "react";
import { TableCell, TableRow, IconButton } from "@material-ui/core";
import styles from "containers/table.module.css";
import IcoBtn from "components/buttons/icoBtn";
import { ArrowLeftCircle, ArrowRightCircle } from "react-feather";
import { FileBtn } from "../../components/buttons";
import { DBurl } from "../../appConfig";

const ForwardingOrderTableRow = ({
  row,
  isItemSelected,
  labelId,
  openNewOrder,
  setDeleteData,
  setOpenModalDelete,
  addInvoice,
  finishOrderManually,
  printOrdersPDF,
  setOpenModalOrderFiles,
  setOrderData,
  exchangeRatesList,
  handleRefs,
}) => {
  const {
    tags,
    ts,
    contractor,
    locations,
    order_price,
    order_currency,
    status,
    id,
    reservation,
    order_no,
    fo_freight_rate,
    fo_freight_currency,
    id_invoice,
    customer_order_number,
    carrier,
  } = row;
  const allTags = tags.map((tag) => (tag?.name ? tag.name : ""));
  const [tagsToShow, setTagsToShow] = useState(allTags.slice(0, 1));
  const [showAllTags, setShowAllTags] = useState(false);
  const toggleShowAllTags = (event, newShowAllTags) => {
    event.preventDefault();
    event.stopPropagation();
    setShowAllTags(newShowAllTags);
    setTagsToShow(newShowAllTags ? [...allTags] : allTags.slice(0, 1));
  };

  const dateString = (type, row) => {
    let tl = row?.locations.find((e) => e.type === type);
    let streetAndApart = tl?.apartment_no
      ? `${tl.street_no}/${tl.apartment_no}`
      : tl?.street_no
      ? tl.street_no
      : "";
    return `${tl?.street ? tl.street : ""} ${
      !!streetAndApart ? streetAndApart : ""
    }, ${tl?.zipcode ? tl.zipcode : ""} ${tl?.city ? tl.city : ""}, ${
      tl?.district ? tl.district : ""
    }`;
  };

  const renderToggleSymbol = () => {
    if (allTags.length >= 2)
      return showAllTags ? (
        <IconButton className={styles.mediumIcons}>
          <ArrowLeftCircle color={"orange"} />
        </IconButton>
      ) : (
        <IconButton className={styles.mediumIcons}>
          <ArrowRightCircle color={"green"} />
        </IconButton>
      );
    return <></>;
  };

  const calcProfit = (row) => {
    let orderCurrency = row.order_currency;
    let freightOrderCurrency = row.fo_freight_currency;
    let exchangeRateFO = exchangeRatesList.find(
      (curr) => curr.code.toUpperCase() === freightOrderCurrency
    );
    let exchangeRateO = exchangeRatesList.find(
      (curr) => curr.code.toUpperCase() === orderCurrency
    );

    if (orderCurrency === freightOrderCurrency) {
      const calculatedValue =
        (parseFloat(row.order_price) || 0) -
        (parseFloat(row.fo_freight_rate) || 0);
      return `${calculatedValue.toFixed(2)} ${
        orderCurrency ? orderCurrency : ""
      }`;
    }

    if (
      orderCurrency &&
      orderCurrency !== "PLN" &&
      orderCurrency !== freightOrderCurrency &&
      freightOrderCurrency !== "PLN"
    ) {
      const calculatedValue =
        ((parseFloat(row.order_price) || 0) *
          parseFloat(exchangeRateFO?.value) || 0) -
        ((parseFloat(row.fo_freight_rate) || 0) *
          parseFloat(exchangeRateO?.value) || 0);
      return `${calculatedValue.toFixed(2)} PLN`;
    }

    if (
      orderCurrency &&
      orderCurrency !== "PLN" &&
      orderCurrency !== freightOrderCurrency &&
      freightOrderCurrency === "PLN"
    ) {
      const calculatedValue =
        (parseFloat(row.order_price) || 0) -
        ((parseFloat(row.fo_freight_rate) || 0) *
          parseFloat(exchangeRateO?.value) || 0);
      return `${calculatedValue.toFixed(2)} PLN`;
    }

    if (orderCurrency === "PLN") {
      const calculatedValue =
        ((parseFloat(row.order_price) || 0) *
          parseFloat(exchangeRateFO?.value) || 0) -
        (parseFloat(row.fo_freight_rate) || 0);
      return `${calculatedValue.toFixed(2)} ${
        orderCurrency ? orderCurrency : ""
      }`;
    } else {
      const calculatedValue =
        (parseFloat(row.order_price) || 0) -
        (parseFloat(row.fo_freight_rate) || 0);
      return `${calculatedValue.toFixed(2)} ${
        orderCurrency ? orderCurrency : ""
      }`;
    }
  };

  const renderTags = (tags) => {
    return (
      <div className={styles.tagGrid}>
        <span> {tags.join(", ")} </span>
        <span onClick={(e) => toggleShowAllTags(e, !showAllTags)}>
          {renderToggleSymbol()}
        </span>
      </div>
    );
  };

  const printPDF = (id) => {
    const url = `${DBurl}/printInvoice2PDF?id_invoice=${id}&lang=PL`;
    window.open(url, "_blank");
  };

  const handleRowClass = () => {
    if (reservation) return styles.reservation;
    if (id_invoice) return styles.invoicedOrder;
    return "";
  };

  return (
    <TableRow
      hover={!id_invoice}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={id}
      selected={isItemSelected}
      className={handleRowClass()}
    >
      <TableCell component="th" id={labelId} align="right" scope="row">
        {order_no}
      </TableCell>
      <TableCell component="th" id={labelId} align="right" scope="row">
        {customer_order_number}
      </TableCell>
      <TableCell align="right">{ts}</TableCell>
      <TableCell align="right">
        {contractor.shortname ? contractor.shortname : "b.d."}
      </TableCell>
      <TableCell align="right">
        {carrier.shortname ? carrier.shortname : "b.d."}
      </TableCell>
      <TableCell align="right">{dateString("loading", row)}</TableCell>
      <TableCell align="right">{dateString("unloading", row)}</TableCell>
      <TableCell align="right">
        {locations.find((e) => e.type === "loading")?.goods[0]?.name}
      </TableCell>
      <TableCell align="right">
        {
          new Set(
            handleRefs(row)
              .map((str, index) => {
                const uniqueSet = new Set();
                if (!uniqueSet.has(str)) {
                  uniqueSet.add(str);
                  return index === handleRefs(row).length - 1
                    ? str
                    : str + ", ";
                }
              })
              .join("")
          )
        }
      </TableCell>
      <TableCell align="right">
        {order_price} {order_currency}
      </TableCell>
      <TableCell align="right">
        {fo_freight_rate} {fo_freight_currency}
      </TableCell>
      <TableCell align="right">{calcProfit(row)}</TableCell>
      <TableCell align="right">{status}</TableCell>
      <TableCell align="right">{renderTags(tagsToShow)}</TableCell>
      <TableCell align="right">
        <div className={styles.buttonBox_orderList}>
          {status.props.children[1] !== " Zakończone" && (
            <div>
              <IcoBtn
                icon="Pause"
                tooltip={"Zakończ ręcznie"}
                value={row}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  finishOrderManually(row.id);
                }}
              />
            </div>
          )}
          <div>
            <FileBtn
              tooltip="PDF dla przewoźnika"
              handleChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                printOrdersPDF(row.id, "carrier");
              }}
            />
          </div>
          <div>
            <FileBtn
              tooltip="PDF dla operatora"
              handleChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                printOrdersPDF(row.id, "operator");
              }}
            />
          </div>
          <div>
            <IcoBtn
              icon="FileText"
              tooltip="Pliki"
              fontSize="small"
              handleChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOrderData(row);
                setOpenModalOrderFiles(true);
              }}
            />
          </div>
          <div>
            {!row.id_invoice ? (
              <FileBtn
                tooltip="Wystaw fakturę dla klienta"
                color={"var(--cor-col)"}
                handleChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  addInvoice(row, false);
                }}
              />
            ) : (
              <IcoBtn
                icon="Download"
                tooltip="Pobierz fakturę klienta"
                fontSize="small"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  printPDF(row.id_invoice);
                }}
              />
            )}
          </div>
          <div>
            {row.contractor.shortname && (
              <IcoBtn
                icon="Copy"
                tooltip={"Kopiuj"}
                value={row}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  openNewOrder(row, "Nowe zlecenie spedycyjne");
                }}
              />
            )}
          </div>
          <div>
            {row.contractor.shortname && (
              <IcoBtn
                icon="Edit"
                tooltip="Edytuj"
                value={row}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  openNewOrder(row, "Edytuj zlecenie spedycyjne");
                }}
              />
            )}
          </div>
          <div>
            <IcoBtn
              icon="X"
              tooltip="Usuń"
              className="btn-delete"
              value={row}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setDeleteData([id]);
                setOpenModalDelete(true);
              }}
            />
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default ForwardingOrderTableRow;
