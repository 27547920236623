import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import Title from "../../components/title";
import { X } from "react-feather";
import styles from "../table.module.css";
import stylesMod from "../../styles/newOrEdit.module.css";
import BTNstyles from "../../styles/button.module.css";

import RowsPerPage from "../../components/RowsPerPage";
import Pagination from "../../components/pagination";
import TmsLoading from "components/TmsLoading";
import Button from "@material-ui/core/Button";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
  Slide,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";

import AddClient from "./AddClient";
import AtlasIntegrationModal from "./AtlasIntegrationModal";
import Input from "../../components/input";
import IcoBtn from "../../components/buttons/icoBtn";
import EditColumns from "./editColumnsModal";
import {
  getCustomers,
  getTraders,
  getFirm,
  deleteCustomers,
} from "api/endpoints";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (array?.length > 0) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
}

const headCells = [
  { id: "name", right: true, disablePadding: false, label: "Nazwa" },
  { id: "firm", right: true, disablePadding: false, label: "Firma" },
  { id: "street", right: true, disablePadding: false, label: "Ulica" },
  { id: "city", right: true, disablePadding: false, label: "Miasto" },
  { id: "zipcode", right: true, disablePadding: false, label: "Kod pocztowy" },
  {
    id: "contact_name",
    right: true,
    disablePadding: false,
    label: "Osoba do kontaktu",
  },
  {
    id: "contact_email",
    right: true,
    disablePadding: false,
    label: "Mail do kontaktu",
  },
  {
    id: "contact_tel",
    right: true,
    disablePadding: false,
    label: "Tel do kontaktu",
  },
  {
    id: "tms_version_s",
    right: true,
    disablePadding: false,
    label: "TMS version S",
  },
];

function EnhancedTableHead(props) {
  const {
    styles,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={styles.tabHead}>
      <TableRow>
        <TableCell className={styles.checkbox}>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all id" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.right ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ width: `${headCell.width || "auto"}` }}
          >
            {!headCell.noSort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={styles.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

function useInput({ type, size }) {
  const [value, setValue] = useState("");
  const input = (
    <TableCell className={styles.inputCell}>
      <Input
        value={value}
        handleChange={(e) => {
          setValue(e.target.value);
        }}
        type={type}
        search={true}
        className={size}
      />
    </TableCell>
  );
  return [value, input];
}

export default function CompanyList(props) {
  const [table, setTable] = useState([]);
  const [firms, setFirms] = useState([]);
  const [edit, setEdit] = useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("short");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [searchName, setSearchName] = useInput({ type: "text" });
  const [searchStreet, setSearchStreet] = useInput({ type: "text" });
  const [searchCity, setSearchCity] = useInput({ type: "text" });
  const [searchZipcode, setSearchZipcode] = useInput({ type: "text" });
  const [searchContact_name, setSearchContact_name] = useInput({
    type: "text",
  });
  const [searchContact_email, setSearchContact_email] = useInput({
    type: "text",
  });
  const [searchContact_tel, setSearchContact_tel] = useInput({ type: "text" });
  const [searchFirm, setSearchFirm] = useInput({ type: "text" });
  const [searchTMS_version_s, setSearchTMS_version_s] = useInput({
    type: "text",
  });

  const [deleteData, setDeleteData] = React.useState([]);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);

  const [openAddClientModal, setOpenAddClientModal] = useState(false);
  const [dataAddClientModal, setDataAddClientModal] = useState(false);

  const [openAtlasIntegrationModal, setOpenAtlasIntegrationModal] =
    useState(false);
  const [dataAtlasIntegrationModal, setDataAtlasIntegrationModal] =
    useState(false);

  const [openModalPassword, setOpenModalPassword] = useState(false);
  const [traders, setTraders] = useState();

  const { refetch: refetchCustomers, isLoading: isCustomersLoading } = useQuery(
    "getCustomers",
    getCustomers,
    {
      onSuccess: (data) => {
        setTable(data);
      },
      onError: (error) => {
        props.setDialog({
          isOpen: true,
          type: "error",
          content: `Nie można pobrać klientów / ${error.message}`,
        });
      },
    }
  );

  const { isLoading: isTradersLoading } = useQuery("getTraders", getTraders, {
    onSuccess: (data) => {
      setTraders(
        data.map((e) => {
          return { ...e, label: e.name, value: e.id };
        })
      );
    },
    onError: (error) => {
      props.setDialog({
        isOpen: true,
        type: "error",
        content: `Nie można pobrać klientów / ${error.message}`,
      });
    },
  });

  const { isLoading: isFirmLoading } = useQuery("getFirm", getFirm, {
    onSuccess: (data) => {
      setFirms(
        data.map((e) => {
          return { ...e, label: e.name, value: e.id };
        })
      );
    },
    onError: (error) => {
      props.setDialog({
        isOpen: true,
        type: "error",
        content: `Nie można pobrać firm / ${error.message}`,
      });
    },
  });

  const checkFirm = (id) => {
    return firms.find((e) => e.id === id);
  };

  const { mutate: delCustomers } = useMutation(
    "deleteCustomers",
    deleteCustomers,
    {
      onSuccess: () => {
        refetchCustomers();
        props.setDialog({
          isOpen: true,
          type: "success",
          content: "Firma została usunięta",
        });
      },
      onError: (error) => {
        props.setDialog({
          isOpen: true,
          type: "error",
          content: `Nie można usunąć klienta / ${error.message}`,
        });
      },
    }
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const search = (tbl) => {
    if (tbl?.length > 0) {
      return tbl?.filter((row) => {
        return (
          String(row.city).toLowerCase().indexOf(searchCity.toLowerCase()) >
            -1 &&
          String(row.name).toLowerCase().indexOf(searchName.toLowerCase()) >
            -1 &&
          String(row.street).toLowerCase().indexOf(searchStreet.toLowerCase()) >
            -1 &&
          String(row.zipcode)
            .toLowerCase()
            .indexOf(searchZipcode.toLowerCase()) > -1 &&
          String(row.contact_name)
            .toLowerCase()
            .indexOf(searchContact_name.toLowerCase()) > -1 &&
          String(row.contact_tel)
            .toLowerCase()
            .indexOf(searchContact_tel.toLowerCase()) > -1 &&
          String(row.contact_email)
            .toLowerCase()
            .indexOf(searchContact_email.toLowerCase()) > -1 &&
          String(checkFirm(row.id_firm)?.name)
            .toLowerCase()
            .indexOf(searchFirm.toLowerCase()) > -1 &&
          String(row.tms_version_s ? "tak" : "nie")
            .toLowerCase()
            .indexOf(searchTMS_version_s.toLowerCase()) > -1
        );
      });
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = table.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const paginate = (pageNumber) => setPage(pageNumber - 1);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, table.length - page * rowsPerPage);

  const isLoading = isCustomersLoading || isTradersLoading || isFirmLoading;

  return (
    <>
      <Paper className={styles.paper}>
        <Title
          title={props.state.tabName}
          btnBox
          btnAdd
          addFunc={(e) => {
            setDataAddClientModal(false);
            setEdit(false);
            setOpenAddClientModal(true);
          }}
          btnDelete={selected.length > 0 ? true : "disabled"}
          deleteFunc={(e) => setOpenModalDelete(true)}
        />
      </Paper>
      <TableContainer className={styles.stickyTable}>
        <Table
          stickyHeader={true}
          className={styles.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
          size={"small"}
        >
          <EnhancedTableHead
            styles={styles}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={table.length}
          />
          <TableBody className={styles.tabBody}>
            <TableRow>
              <TableCell></TableCell>
              {setSearchName}
              {setSearchFirm}
              {setSearchStreet}
              {setSearchCity}
              {setSearchZipcode}
              {setSearchContact_name}
              {setSearchContact_email}
              {setSearchContact_tel}
              {setSearchTMS_version_s}
              <TableCell></TableCell>
            </TableRow>

            {stableSort(search(table), getComparator(order, orderBy))
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    className={row.reservation ? styles.reservation : ""}
                  >
                    <TableCell className={styles.checkbox}>
                      <Checkbox
                        checked={isItemSelected}
                        color="primary"
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </TableCell>

                    <TableCell
                      component="th"
                      id={labelId}
                      align="right"
                      scope="row"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="right">
                      {checkFirm(row.id_firm)?.name}
                    </TableCell>
                    <TableCell align="right">{row.street}</TableCell>
                    <TableCell align="right">{row.city} </TableCell>
                    <TableCell align="right">{row.zipcode} </TableCell>
                    <TableCell align="right">{row.contact_name}</TableCell>
                    <TableCell align="right">{row.contact_email}</TableCell>
                    <TableCell align="right">{row.contact_tel}</TableCell>
                    <TableCell align="right">
                      {row.tms_version_s ? "tak" : "nie"}
                    </TableCell>
                    <TableCell align="right">
                      <div className={styles.buttonBox}>
                        <IcoBtn
                          icon="Link"
                          tooltip="Przeprowadź integracje z systemem Atlas"
                          value={row}
                          onClick={(e) => {
                            setDataAtlasIntegrationModal(row);
                            setOpenAtlasIntegrationModal(true);
                          }}
                        />
                        <IcoBtn
                          icon="Edit"
                          tooltip="Edytuj"
                          value={row}
                          onClick={(e) => {
                            setDataAddClientModal(row);
                            setEdit(true);
                            setOpenAddClientModal(true);
                          }}
                        />
                        <IcoBtn
                          icon="Tool"
                          tooltip="Ustaw hasło do edycji danych dokumentów"
                          value={row}
                          onClick={(e) => {
                            setOpenModalPassword(true);
                          }}
                        />
                        <IcoBtn
                          icon="X"
                          tooltip="Usuń"
                          className="btn-delete"
                          value={row}
                          onClick={(e) => {
                            setDeleteData([row.id]);
                            setOpenModalDelete(true);
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 33 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <div className={styles.tableBottom}>
        <RowsPerPage
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          tableLength={table?.length}
        />
        <div>
          <Pagination
            postsPerPage={rowsPerPage}
            totalPosts={table?.length}
            paginate={paginate}
            page={page}
          />
        </div>
      </div>

      <Dialog
        open={openModalDelete}
        TransitionComponent={Transition}
        onClose={() => {
          setDeleteData([]);
          setOpenModalDelete(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Czy napewno chcesz usunąć firmę?{" "}
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              setDeleteData([]);
              setOpenModalDelete(false);
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {deleteData.length > 0 ? (
            <DialogContentText id="alert-dialog-slide-description">
              Czy napewno chcesz usunąć firmy:{" "}
              {deleteData.map((e) => {
                const delOrder = table.find((el) => el.id === e);
                return (
                  <>
                    <br />
                    {delOrder.name}{" "}
                  </>
                );
              })}
            </DialogContentText>
          ) : (
            <DialogContentText id="alert-dialog-slide-description">
              Czy napewno chcesz usunąć firmę:{" "}
              {selected.map((e) => {
                const delOrder = table.find((el) => el.id === e);
                return (
                  <>
                    <br />
                    {delOrder.name}{" "}
                  </>
                );
              })}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={BTNstyles.btn}
            onClick={(e) => {
              setOpenModalDelete(false);
            }}
          >
            Anuluj
          </Button>
          <Button
            variant="contained"
            size="large"
            className={`${BTNstyles.btn} ${BTNstyles.err}`}
            onClick={() => {
              delCustomers(deleteData.length > 0 ? deleteData : selected);
              setSelected([]);
              setOpenModalDelete(false);
              setDeleteData([]);
            }}
            color="primary"
          >
            Usuń <X className={BTNstyles.downBtnIco} />
          </Button>
        </DialogActions>
      </Dialog>
      <EditColumns
        open={openModalPassword}
        setOpen={setOpenModalPassword}
        token={props.user.csrf_token}
        user={props.user}
        setDialog={props.setDialog}
      />
      <AddClient
        open={openAddClientModal}
        setOpen={setOpenAddClientModal}
        data={dataAddClientModal}
        edit={edit}
        user={props.user}
        firms={firms}
        refresh={refetchCustomers}
        checkFirm={checkFirm}
        traders={traders}
        setTraders={setTraders}
        setDialog={props.setDialog}
      />
      <AtlasIntegrationModal
        open={openAtlasIntegrationModal}
        setOpen={setOpenAtlasIntegrationModal}
        data={dataAtlasIntegrationModal}
        refresh={refetchCustomers}
        user={props.user}
        setDialog={props.setDialog}
      />
      {isLoading && <TmsLoading status="Ładowanie klientów" />}
    </>
  );
}
