import React, { useState, useEffect, forwardRef } from 'react'
import { Snackbar, Button, IconButton, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, TextField } from '@material-ui/core';
import { X, Calendar } from 'react-feather';
import Input from '../../components/input'
import { DBurl } from 'appConfig'
import stylesMod from '../../styles/newOrEdit.module.css'
import BTNstyles from '../../styles/button.module.css'
import docsStyles from '../../styles/documents.module.css'
import styles from '../table.module.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import plLocale from 'date-fns/locale/pl';
import moment from 'moment';
import { useMemo } from 'react';
import IcoBtn from 'components/buttons/icoBtn'


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const AllFiles = ({token, open, setOpen, setSnack, setSnackContent, setSnackColor, data, colName, reload}) => {

    const [filesToShow, setFilesToShow] = useState([]);
    useEffect(() => {
        if (Array.isArray(data?.files)) {
            const ownColsFiles = data?.own_def_vales?.filter(file => file?.is_file);
            setFilesToShow([...data?.files, ...ownColsFiles])
        }
    },[data])

    const delFile = async (id) => {
        

        const response = await fetch(`${DBurl}/setDocumentsCarsFiles`, {
            method: "POST",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": token
                },
                body: JSON.stringify({
                mode: 'delete',
                data: {
                    ids:[id]
            }})});
            const json = await response.json()

            if (json.success) {
                reload(token)
                setFilesToShow(prevState =>  prevState.filter(file => file.id !==id))
                setSnack(open)
                setSnackContent('Plik usunięty poprawnie.')
                setSnackColor('success')
            }
    }

    const delOwnFile = async (id) => {
        const response = await fetch(`${DBurl}/setDocumentsOwnDefValues`, {
            method: "POST",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": token
                },
                body: JSON.stringify({
                mode: 'delete',
                data: {
                    ids:[id]
            }})});
            const json = await response.json()

            if (json.success) {
                reload(token)
                setFilesToShow(prevState =>  prevState.filter(file => file.id !==id))
            }
    }

    return (
        <>
            <Dialog
                maxWidth='sm'
                fullWidth
                open={open}
                TransitionComponent={Transition}
                onClose={() => {
                setOpen(false)
                }}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >

            <DialogTitle id="alert-dialog-slide-title" className={stylesMod.dialogHead}>Wszystkie pliki		
                <IconButton aria-label="close" className={stylesMod.closeButton} onClick={() => {
                    setOpen(false)
                    }}>
                    <X />
                </IconButton>
            </DialogTitle >
            <DialogContent>
                    <Grid container spacing={2} >
                        {Array.isArray(filesToShow) && filesToShow.filter(file => file?.type === colName || file.col_name === colName || file.col_id === colName).map(file => 
                        <Grid key={file.id} item xs={12} className={docsStyles.allFiles}>
                            <div onClick={()=>{
                                    window.open(`${DBurl}/getFile?filename=${file.filename.split("---")[0]}`, '_blank')}}>{file.filename.split("---")[1]} </div>
                            <div className={docsStyles.allFilesButtonContainer}>
                                <IcoBtn icon="Download" tooltip="Pobierz" value={file} className={`${BTNstyles.btn} ${docsStyles.downloadBtn}`} onClick={() => {
                                    fetch(`${DBurl}/getFile?filename=${file.filename.split("---")[0]}`)
                                    .then(response => response.blob())
                                    .then(blob => {
                                        const link = document.createElement('a');
                                        link.download = file.filename.split("---")[1];
                                        link.href = URL.createObjectURL(blob);
                                        document.body.appendChild(link);
                                        link.click();
                                        URL.revokeObjectURL(link.href);
                                    });
                                }}
                                />
                                <IcoBtn icon="X" tooltip="Usuń" value={file} className={`${BTNstyles.btn} btn-delete`} onClick={(e) => {
                                        file?.is_file ? delOwnFile(file.id) : delFile(file.id);
                                    }}/>
                            </div>
                        </Grid>)}
                    </Grid>
            </DialogContent>

            <DialogActions>
                <Button variant="contained" size="large" color="primary" className={BTNstyles.btn} onClick={(e) => {
                    setOpen(false)
                    }}>
                    OK
                </Button>
            </DialogActions>

            </Dialog>
            </>
    )
}

export default AllFiles;