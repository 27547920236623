import React, { useState, useEffect } from "react";
import { Button, Grid } from "@material-ui/core";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import Input from "components/input";
import TmsDialog from "components/TmsDialog";
import { Select } from "@material-ui/core";

import { getPrevOrders } from "api/endpoints";
import { round } from "../newOrder.helpers";
import Title from "components/title";
import stylesVersion from "styles/version_s.module.css";
import stylesMod from "styles/newOrEdit.module.css";
import BTNstyles from "styles/button.module.css";

const OrderCosts = ({
  values,
  updateValue,
  user_s,
  toCostValues,
  setToCostValues,
  isShowRequiredFields,
  setIsShowRequiredFields,
  setOpenModalRatesPerKm,
  isLoading,
  isPreview,
}) => {
  const [dialog, setDialog] = useState(false);

  const costCalculate = (type, trace, prev) => {
    const google = window.google;

    let origin_lat;
    let origin_lng;
    let destination_lat;
    let destination_lng;

    if (type === "distance-access") {
      origin_lat = Number(prev.order_stop_lattitude);
      origin_lng = Number(prev.order_stop_longitude);
      destination_lat = trace[0].address
        ? Number(trace[0].address.latitude)
        : Number(trace[0].warehouse.address.latitude);
      destination_lng = trace[0].address
        ? Number(trace[0].address.longitude)
        : Number(trace[0].warehouse.address.longitude);
    }

    let origin = new google.maps.LatLng(origin_lat, origin_lng);
    let destination = new google.maps.LatLng(destination_lat, destination_lng);

    let service = new google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [destination],
        travelMode: "DRIVING",
        unitSystem: google.maps.UnitSystem.METRIC,
      },
      callback
    );

    function callback(response, status) {
      if (type === "distance-access") {
        if (status === "OK") {
          if (response.rows[0].elements[0].status !== "ZERO_RESULTS") {
            updateValue(
              "kilometers.access",
              Math.round(response.rows[0].elements[0].distance.value / 1000)
            );
          } else {
            setDialog({
              isOpen: true,
              type: "error",
              content: "Nie można obliczyć trasy dojazdowej",
            });
          }
        }
      }
    }
  };

  const fetchPrevOrders = async function (id_driver, id_car, ts) {
    let tmpTS = moment(ts).valueOf();
    let tmpTSFormat = moment(tmpTS).format("YYYY-MM-DD HH:mm:ss");

    const prevOrders = await getPrevOrders(tmpTSFormat, id_car, id_driver);

    if (prevOrders) {
      if (prevOrders.length > 0) {
        const prevOrder = prevOrders?.[0];
        if (toCostValues && prevOrder.order_stop_lattitude) {
          if (
            values.transport.set.id_driver1 === null &&
            values.transport.set.id_car === null
          ) {
            setDialog({
              isOpen: true,
              type: "error",
              content: "Zestaw transportowy nie posiada kierowcy lub pojazdu.",
            });
          } else {
            costCalculate("distance-access", values.trace, prevOrder);
          }
        } else {
          setDialog({
            isOpen: true,
            type: "error",
            content: "Brak ostatniej lokalizacja zestawu.",
          });
        }
      } else {
        setDialog({
          isOpen: true,
          type: "error",
          content:
            "Brak ostatniego zlecenia potrzebnego do wyliczenia km dojazdowych, uzupełnij pole ręcznie bądź pozostaw puste..",
        });
      }
    } else {
      setDialog({
        isOpen: true,
        type: "error",
        content: "Brak ostatniej lokalizacja zestawu.",
      });
    }
    isLoading.current -= 1;
  };

  useEffect(() => {
    if (Number(values.price) < 0) {
      updateValue("price", 0);
    }
  }, [values.price]);

  useEffect(() => {
    if (values?.trace) {
      if (values.transport?.set !== undefined) {
        if (
          values.transport?.set?.id_driver1 !== null &&
          values.transport?.set?.id_car !== null
        ) {
          let id_driver = values.transport?.set?.id_driver1;
          let id_car = values.transport?.set?.id_car;
          let ts = values?.trace[0]?.date;
          if (
            id_driver !== undefined &&
            id_car !== undefined &&
            ts !== null &&
            (values.trace[0]?.address !== undefined ||
              (values.trace[0]?.warehouse !== undefined &&
                values.trace[0]?.warehouse?.address !== undefined))
          ) {
            setToCostValues(true);
          } else {
            setToCostValues(false);
          }
        } else {
          setToCostValues(false);
        }
      } else {
        setToCostValues(false);
      }
    }
  }, [values?.transport?.set, values?.trace]);

  return (
    <>
      <Title title="Koszty" />
      <div style={{ padding: "15px 20px 30px 20px" }}>
        <Grid item xs={12} className={stylesMod.labels}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
                <div className={stylesMod.inp}>
                  <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                    Km dojazdowe
                  </label>
                  <Input
                    className={`${
                      user_s
                        ? stylesVersion.onlyfull
                        : isShowRequiredFields
                        ? "ActiveFields"
                        : ""
                    }`}
                    style={{ boxShadow: "2px 0 0 0 #000" }}
                    disabled={user_s | isPreview}
                    value={
                      values.kilometers.access === 0 ||
                      values.kilometers.access === undefined
                        ? ""
                        : round(values.kilometers.access)
                    }
                    handleChange={(e) => {
                      updateValue("kilometers.access", e.target.value);
                    }}
                    name="access_kilometers"
                    type="number"
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={2}>
              <Tooltip
                title={
                  user_s
                    ? "Dostępne tylko w wersji pełnej"
                    : toCostValues === true && values.cargo_kilometers === ""
                    ? 'Aby wyliczyć "Km ładowne", sprawdź "Podgląd trasy"'
                    : ""
                }
              >
                <div className={stylesMod.inp} style={{ position: "relative" }}>
                  <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                    Km ładowne
                  </label>
                  <Input
                    className={`${user_s ? stylesVersion.onlyfull : ""}`}
                    disabled
                    value={round(values.kilometers.cargo)}
                    handleChange={(e) => {
                      updateValue("kilometers.cargo", e.target.value);
                    }}
                    name="cargo_kilometers"
                    type="number"
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={2}>
              <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
                <div className={stylesMod.inp}>
                  <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                    Suma km
                  </label>
                  <Input
                    className={`${user_s ? stylesVersion.onlyfull : ""}`}
                    disabled={user_s || isPreview}
                    value={round(values.kilometers.sum)}
                    handleChange={(e) => {
                      updateValue("kilometers.sum", e.target.value);
                    }}
                    name="sum_kilometers"
                    type="number"
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={2}>
              <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
                <div className={stylesMod.inp}>
                  <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                    Stawka za km
                  </label>
                  <Input
                    className={`${user_s ? stylesVersion.onlyfull : ""}`}
                    disabled={user_s || isPreview}
                    value={
                      values.ratesPerKm.rate_netto &&
                      values.ratesPerKm.rate_netto +
                        ` ${values.ratesPerKm?.currency}`
                    }
                    handleChange={(e) => {}}
                    name="ratesPerKm"
                    plus={
                      !!values?.contractor.value && !Number(values.ratesPerKm)
                    }
                    plusFunc={() => {
                      setOpenModalRatesPerKm(true);
                    }}
                    edit={
                      !!values.contractor.value && !!Number(values.ratesPerKm)
                    }
                    editFunc={() => {
                      setOpenModalRatesPerKm(true);
                    }}
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={2}>
              <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
                <div className={stylesMod.inp}>
                  <label className={`${user_s ? stylesVersion.onlyfull : ""}`}>
                    Koszt
                  </label>
                  <Input
                    className={
                      `${user_s ? stylesVersion.onlyfull : ""}` || isPreview
                    }
                    disabled={user_s || isPreview}
                    value={
                      values.cost &&
                      round(values.cost) + ` ${values.ratesPerKm?.currency}`
                    }
                    handleChange={(e) => {}}
                    name="cost"
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={2}>
              <Tooltip
                title={
                  user_s
                    ? "Dostępne tylko w wersji pełnej"
                    : toCostValues === false
                    ? "Uzupełnij wymagane pola do wyliczenia km dojazdowych"
                    : ""
                }
              >
                <div
                  className={BTNstyles.BOXNoLabelPosition}
                  onMouseOver={() =>
                    toCostValues === false && setIsShowRequiredFields(true)
                  }
                  onMouseLeave={() =>
                    toCostValues === false && setIsShowRequiredFields(false)
                  }
                >
                  <Button
                    color="primary"
                    className={`${BTNstyles.primary} ${BTNstyles.costCalculate}`}
                    component="button"
                    variant="contained"
                    disabled={!toCostValues || isPreview}
                    onClick={() => {
                      if (toCostValues === true) {
                        if (
                          values.transport.set.id_driver1 !== null &&
                          values.transport.set.id_car !== null
                        ) {
                          fetchPrevOrders(
                            values.transport.set.id_driver1,
                            values.transport.set.id_car,
                            values.trace[0].date
                          );
                        }
                      }
                    }}
                  >
                    Wylicz km dojazdowe
                  </Button>
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <TmsDialog dialog={dialog} setDialog={setDialog} />
    </>
  );
};

export default OrderCosts;
