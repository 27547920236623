import React from "react";

import "./styles.sass";

type Props = {
  title: string;
  marginTop?: number;
  checkbox?: boolean;
  setCheckbox?: (value: boolean) => void;
};

const TmsTitleSmall = ({ title, marginTop, checkbox, setCheckbox }: Props) => {
  return (
    <div style={marginTop ? { marginTop } : {}} className="title-small">
      {setCheckbox ? (
        <label className="title-small__checkbox-label">
          <input
            type="checkbox"
            className="title-small__checkbox"
            onChange={(e) => setCheckbox(e.target.checked)}
            checked={checkbox}
          />
          <p>{title}</p>
        </label>
      ) : (
        <div className="title-small__wrapper">
          <p className="title-small__title">{title}</p>
        </div>
      )}
    </div>
  );
};

export default TmsTitleSmall;
