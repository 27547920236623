import React, { SetStateAction, useEffect, useState } from "react";
import { AddFleetCostsContainerSelect, AddFleetCostsGenericModal, MODALS, MODALS_NAMES_TYPE, SelectOptionType, getCarsOptions, LEASING_OPTIONS, AddFleetCostsContainerInput, CURRENCY_OPTIONS, handleDatePickerLabel, addFleetCostsApiCall, getNumberOfMonthDifference, AllMonthsDifferenceType } from "..";
import { Car } from "types";
import AddFleetCostsContainerDatePicker from "../AddFleetCosts/AddFleetCostsDatePicker";

export type LeasingModalPropsType = {
    modalID?: MODALS_NAMES_TYPE;
    handleClose: () => void;
    cars: Car[];
    token: string;
    setRefetch: (value: SetStateAction<boolean>) => void
    carData: Car
    setSnack: React.Dispatch<React.SetStateAction<boolean>>;
    setSnackContent: React.Dispatch<React.SetStateAction<string>>;
    setSnackColor: React.Dispatch<React.SetStateAction<string>>;
}

const Leasing = ({ modalID, handleClose, cars, token, setRefetch, carData, setSnack, setSnackContent, setSnackColor }: LeasingModalPropsType) => {
    const [selectedCar, setSelectedCar] = useState<SelectOptionType>({ value: "", label: "" })
    const [selectedType, setSelectedType] = useState<SelectOptionType>({ value: "", label: "" })
    const [price, setPrice] = useState<number>(0)
    const [date, setDate] = useState<Date>(new Date)
    const [endDate, setEndDate] = useState<Date>(new Date)
    const [currency, setCurrency] = useState<SelectOptionType>({ value: "", label: "" })
    const [onlyMonthYear, setOnlyMonthYear] = useState<boolean>(false)
    const [allMonthsDifference, setAllMonthsDifference] = useState<AllMonthsDifferenceType[]>([])

    useEffect(() => {
        carData && setSelectedCar({value: carData.id, label: carData.nr_rejestracyjny})
    },[carData])

    const closeAll = () => {
        setSelectedCar({ value: "", label: "" })
        setPrice(0)
        setDate(new Date)
        setSelectedType({ value: "", label: "" })
        setCurrency({ value: "", label: "" })
        setEndDate(new Date)
        setAllMonthsDifference([])
        setRefetch(true);
        handleClose()
        setSnack(true)
        setSnackColor("success")
        setSnackContent("Dane poprawnie zapisane.")
    }

    const handleFail = () => {
        setSnack(true)
        setSnackColor("error")
        setSnackContent("Nie udało się zapisać danych.")
    }

    const handleDateChange = (newDate: Date, name: "end" | "start") => {
        const isStart = name === "start"
        isStart ? setDate(newDate) : setEndDate(newDate)

        const newStartDate = isStart ? newDate : date || new Date()
        const newEndDate = !isStart ? newDate : endDate || new Date()
        setAllMonthsDifference(getNumberOfMonthDifference(newEndDate, newStartDate))
    }

    const showFromTo = () => {
        if (selectedType.value == 4 || selectedType.value == 5) return false
        else return true
    }

    const handleConfirm = async () => {
        const totalMonths = allMonthsDifference.length;
        let counter = 0;

        for (const monthDiff of allMonthsDifference) {
            const data = {
                id_fleet_cost_type: selectedType.value,
                price: selectedType.value === 2
                    ? (price / totalMonths).toFixed(2)
                    : (price / 12).toFixed(2),
                start_date: monthDiff.date,
                id_car: selectedCar.value,
                currency: currency.value,
                is_fixed_cost: false
            };
            await addFleetCostsApiCall("setFleetCosts", token, data, () => {
                counter++;
                if (counter === totalMonths) {
                    closeAll()
                }
            }, handleFail);
        }
    };

    const handleConfirmSingle = async () => {
        {
            const data = {
                id_fleet_cost_type: selectedType.value,
                price: price,
                start_date: date || new Date(),
                currency: currency.value,
                id_car: selectedCar.value
            };
            await addFleetCostsApiCall("setFleetCosts", token, data, closeAll, handleFail);
        }
    }

    const handleSelectedTypeChange = (value: SelectOptionType) => {
        setSelectedType(value)
        setDate(new Date)
        setEndDate(new Date)
        setOnlyMonthYear(value.value != "4")
        setPrice(0)
    }

    return (<AddFleetCostsGenericModal open={MODALS.LEASING === modalID} modalID={modalID} handleClose={handleClose} handleConfirm={showFromTo() ? handleConfirm : handleConfirmSingle}>
        <AddFleetCostsContainerSelect label={"Pojazd"} value={selectedCar} onChangeHandler={setSelectedCar} options={getCarsOptions(cars)} />
        <AddFleetCostsContainerSelect label={"Typ"} value={selectedType} onChangeHandler={handleSelectedTypeChange} options={LEASING_OPTIONS} />
        {showFromTo() && <AddFleetCostsContainerDatePicker label={"Leasing od"} value={date} onChangeHandler={(d: Date) => handleDateChange(d, "start")} showMonthYearPicker={false} format={"dd-MM-yyyy"} />}
        {showFromTo() && <AddFleetCostsContainerDatePicker label={"Leasing do"} value={endDate} onChangeHandler={(d: Date) => handleDateChange(d, "end")} showMonthYearPicker={false} format={"dd-MM-yyyy"} />}
        {!showFromTo() && <AddFleetCostsContainerDatePicker label={handleDatePickerLabel(selectedType)} value={date} onChangeHandler={setDate} showMonthYearPicker={true} format={"MM-yyyy"} />}
        <AddFleetCostsContainerInput label={"Koszt"} value={price} type='number' onChangeHandler={setPrice} />
        <AddFleetCostsContainerSelect label={"Waluta"} value={currency} onChangeHandler={setCurrency} options={CURRENCY_OPTIONS} />
    </AddFleetCostsGenericModal >)
}

export default Leasing;