import React from 'react'
import { Unlock } from 'react-feather';
import styles from './btnStyles.module.css'
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

export default function UnlockAccond (props) {
	return (
		<Tooltip title={props.tooltip}  disableHoverListener={!props.tooltip}>
			<IconButton onClick={props.handleChange} value={props.value} style={{backgroundColor: props.color}} className={`${styles.btn} ${styles.errDisabled}`}>
				< Unlock />
			</IconButton>
		</Tooltip>
	)
}
