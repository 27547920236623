import React from "react";
import { Dialog, DialogContent, DialogTitle, Button } from "@material-ui/core";
import { X, ArrowRight } from "react-feather";
import NewOrder from "containers/order/newOrder/newOrder";
import stylesMod from "styles/newOrEdit.module.css";
import BTNstyles from "styles/button.module.css";

import "./styles.sass";

const OrderPreviewModal = ({
  previewOrderModal,
  setPreviewOrderModal,
  orderId,
  orderTitle,
  addTab,
  user,
}) => {
  const closeModal = () => {
    setPreviewOrderModal({ isOpen: false });
  };

  const state = {
    active: true,
    fetchOrderId: previewOrderModal.orderId,
    isCopy: false,
    isEdit: true,
    isTemplate: false,
    previewComponent: true,
    order_type_S: false,
  };

  return (
    <Dialog
      maxWidth="xl"
      fullWidth
      open={previewOrderModal.isOpen}
      onClose={closeModal}
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        className={stylesMod.dialogHead}
      >
        Podgląd zlecenia {orderTitle}
      </DialogTitle>
      <DialogContent>
        {previewOrderModal.isOpen && (
          <NewOrder
            addTab={() => {}}
            propKey={undefined}
            setTabs={() => {}}
            tabs={[]}
            state={state}
            tabId={null}
            user={user}
          />
        )}
      </DialogContent>
      <div className="edit-modal__footer">
        <Button
          variant="contained"
          size="large"
          className={`${BTNstyles.btn} ${BTNstyles.err}`}
          onClick={() => {
            closeModal();
          }}
        >
          Anuluj <X className={BTNstyles.downBtnIco} />
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={BTNstyles.btn}
          onClick={() => {
            let tempData = {
              permission_id: 7,
              tabName: "Edytuj zlecenie",
              isEdit: true,
              isCopy: false,
              tabID: null,
              component: NewOrder,
              fetchOrderId: Number(String(orderId).replace("---double", "")),
              order_type_S: false,
            };
            addTab("newOrder", tempData);
            closeModal();
          }}
        >
          Edycja zlecenia
          <ArrowRight className={BTNstyles.downBtnIco} />
        </Button>
      </div>
    </Dialog>
  );
};

export default OrderPreviewModal;
