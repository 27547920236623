import React from "react";
import DialModal from "../DialModal";
import BTNstyles from "styles/button.module.css";
import { ArrowRight } from "react-feather";

const ModalCarUsed = ({
  carUsedModal,
  setCarUsedModal,
  setDialog,
  Transition,
}) => {
  return (
    <DialModal
      open={carUsedModal}
      TransitionComponent={Transition}
      onClose={() => {
        setCarUsedModal(false);
      }}
      title="Auto w użyciu"
      text="Auto obecnie ma już zaplanowaną trasę. Czy chcesz dodać zlecenie pomimo to?"
      closeClick={(e) => {
        setCarUsedModal(false);
      }}
      btn1Click={(e) => {
        setCarUsedModal(false);
        setDialog({
          isOpen: true,
          type: "success",
          content: "Zlecenie stworzone",
        });
      }}
      btn1Text="Tak"
      btn1Class={`${BTNstyles.btn} ${BTNstyles.grn}`}
      btn1Ico={<ArrowRight className={BTNstyles.downBtnIco} />}
    />
  );
};

export default ModalCarUsed;
