import React, { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  DialogActions,
  Button,
  DialogContentText,
} from "@material-ui/core";
import Input from "./input";
import { DBurl } from "../appConfig";
import { X, ArrowRight } from "react-feather";
import BTNstyles from "../styles/button.module.css";
import stylesMod from "../styles/newOrEdit.module.css";
import { IconButton } from "@material-ui/core";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function AddUnit(props) {
  const [unit, setUnit] = useState(props.unit.name || "");

  useEffect(() => {
    setUnit(props.unit.name);
  }, [props.unit.name]);

  const fetchUnit = async (type) => {
    let data =
      type === "insert"
        ? {
            name: unit,
          }
        : {
            name: unit,
            id: props.unit.id,
          };

    const response = await fetch(`${DBurl}/setUnits/`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.token,
      },
      body: JSON.stringify({
        mode: type,
        data: data,
      }),
    });
    const json = await response.json();
    if (json.success) {
      props.fetchUnits();

      props.setSnack(true);
      props.setSnackContent("Jednostka zapisana");
      props.setSnackColor("success");
      props.setOpen(false);
    } else {
      props.setSnack(true);
      props.setSnackContent("Błąd przy tworzeniu/edycji jednostki");
      props.setSnackColor("error");
    }
  };

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        className={stylesMod.dialogHead}
      >
        Wpisz nazwę jednostki:{" "}
        <IconButton
          aria-label="close"
          className={stylesMod.closeButton}
          onClick={() => {
            props.setOpen(false);
          }}
        >
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Input
          value={unit}
          handleChange={(e) => {
            setUnit(e.target.value);
          }}
          name="unit"
          type="text"
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="large"
          disabled={!unit}
          onClick={(e) => fetchUnit(props.type)}
        >
          Zapisz <ArrowRight className={`${BTNstyles.downBtnIco}`} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function RemoveUnit(props) {
  const deleteFunc = async (id) => {
    const response = await fetch(`${DBurl}/setUnits/`, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.token,
      },
      body: JSON.stringify({
        mode: "delete",
        data: {
          ids: id,
        },
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          props.setSnack(true);
          props.setSnackContent("Jednostka/i usunięte");
          props.setSnackColor("success");
          props.fetchUnits();
          props.setOpen(false);
        } else {
          props.setSnack(true);
          props.setSnackContent("Błąd podczas usuwania");
          props.setSnackColor("error");
        }
      });
  };
  return (
    <>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        onClose={() => {
          props.setOpen(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Czy napewno chcesz usunąć jednostki{" "}
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              props.setOpen(false);
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Czy napewno chcesz usunąć jednostki: <br />
            {props.list &&
              props.list.state &&
              props.list.state.map((e, i) => {
                return <p key={i}>{e.name}</p>;
              })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={BTNstyles.btn}
            onClick={(e) => {
              props.setOpen(false);
            }}
          >
            Anuluj
          </Button>
          <Button
            variant="contained"
            size="large"
            className={`${BTNstyles.btn} ${BTNstyles.err}`}
            onClick={(e) => {
              if (props.list && props.list.state) {
                deleteFunc(props.list.state.map((e) => e.id));
                props.setOpen(false);
              }
            }}
            color="primary"
          >
            Usuń <X className={BTNstyles.downBtnIco} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
