import React, { useState } from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "components/input";
import { CopyBtn, DeleteBtn, EditBtn } from "components/buttons";
import Title from "components/title";
import styles from "../table.module.css";
import RowsPerPage from "components/RowsPerPage";
import Pagination from "components/pagination";
import GoodsEdit from "components/goodsEdit";
import DeleteGoods from "./DeleteGoods";
import Units from "components/Units";
import { useMutation, useQuery } from "react-query";
import {
  deleteGoods,
  getGoods,
  getUnits,
  insertGoods,
  updateGoods,
} from "api/endpoints";
import TmsLoading from "components/TmsLoading";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (array?.length > 0) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
}

const headCells = [
  { id: "name", numeric: false, disablePadding: true, label: "Nazwa towaru" },
  { id: "unit", numeric: true, disablePadding: false, label: "Jednostka" },
  {
    id: "details",
    numeric: true,
    disablePadding: false,
    label: "Opis",
    class: styles.halfBox,
  },
];

function EnhancedTableHead(props) {
  const {
    styles,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={styles.tabHead}>
      <TableRow>
        <TableCell className={styles.checkbox}>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all id" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={headCell.class}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={styles.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell className={styles.buttonBoxParent} />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  styles: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function useInput({ type }) {
  const [value, setValue] = useState("");
  const input = (
    <TableCell>
      <Input
        value={value}
        handleChange={(e) => {
          setValue(e.target.value);
        }}
        type={type}
        search={true}
      />
    </TableCell>
  );
  return [value, input];
}

export default function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("short");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [table, setTable] = useState([]);
  const [searchName, setSearchName] = useInput({ type: "text" });
  const [name, setName] = useState("");
  const [unit, setUnit] = useState("");
  const [details, setDetails] = useState("");
  const [idx, setIdx] = useState("");
  const [btn, setBtn] = useState("Dodaj");
  const [goodsTitle, setGoodsTitle] = useState("Dodaj");
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [modalDeleteData, setModalDeleteData] = useState(false);
  const [unitTable, setUnitTable] = useState([]);

  const { refetch: fetchGoods, isLoading: isLoadingGoods } = useQuery(
    "getGoods",
    getGoods,
    {
      onSuccess: (data) => {
        setTable(data.sort((a, b) => b.id - a.id));
      },
      onError: (error) => {
        props.setDialog({
          isOpen: true,
          type: "error",
          content: `Nie można pobrać towarów / ${error.message}`,
        });
      },
    }
  );

  const { refetch: fetchUnits, isLoading: isLoadingUnits } = useQuery(
    "getUnits",
    getUnits,
    {
      onSuccess: (data) => {
        setUnitTable(data.sort((a, b) => b.id - a.id));
      },
      onError: (error) => {
        props.setDialog({
          isOpen: true,
          type: "error",
          content: `Nie można pobrać jednostek / ${error.message}`,
        });
      },
    }
  );

  const { mutate: goodsAdd } = useMutation("insertGoods", insertGoods, {
    onSuccess: () => {
      fetchGoods();
      props.setDialog({
        isOpen: true,
        type: "success",
        content: "Towar został dodany",
      });
    },
    onError: (error) => {
      props.setDialog({
        isOpen: true,
        type: "error",
        content: `Nie można dodać towaru / ${error.message}`,
      });
    },
  });

  const { mutate: goodsEdit } = useMutation("updateGoods", updateGoods, {
    onSuccess: () => {
      fetchGoods();
      props.setDialog({
        isOpen: true,
        type: "success",
        content: "Zapisano zmiany",
      });
    },
    onError: (error) => {
      props.setDialog({
        isOpen: true,
        type: "error",
        content: `Nie można zapisać towaru / ${error.message}`,
      });
    },
  });

  const { mutate: goodsDelete } = useMutation("deleteGoods", deleteGoods, {
    onSuccess: () => {
      fetchGoods();
      props.setDialog({
        isOpen: true,
        type: "success",
        content: "Towar został usunięty",
      });
    },
    onError: (error) => {
      props.setDialog({
        isOpen: true,
        type: "error",
        content: `Nie można usunąć towaru / ${error.message}`,
      });
    },
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const search = (tbl) => {
    if (tbl?.length > 0) {
      return tbl.filter((row) => {
        return (
          row.name.toString().toLowerCase().indexOf(searchName.toLowerCase()) >
          -1
        );
      });
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = table.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name, data) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    setName(data.name);
    setUnit(data.unit);
    setDetails(data.details);
    setIdx(data.id);
    setBtn("Zapisz");
    setGoodsTitle("Edytuj");

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const paginate = (pageNumber) => setPage(pageNumber - 1);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, table?.length - page * rowsPerPage);

  return (
    <>
      <div className={styles.rootFlex}>
        <div className={styles.tableBox}>
          <Paper className={styles.paper}>
            <Title
              title={props.state.tabName}
              btnBox
              btnDelete
              addFunc={(e) => {
                setGoodsTitle("Dodaj");
                setBtn("Dodaj");
                setName("");
                setUnit("");
                setDetails("");
              }}
              deleteFunc={() => {
                const deleteArray = [];
                selected
                  .sort((a, b) => {
                    return b - a;
                  })
                  .forEach((val) => {
                    table.forEach((el, i) => {
                      if (el.id === val) {
                        deleteArray.push(el);
                      }
                    });
                  });
                setOpenModalDelete(true);
                setModalDeleteData({
                  state: deleteArray,
                });
              }}
            />
            <TableContainer className={styles.stickyTable}>
              <Table
                className={styles.tableSm}
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  styles={styles}
                  numSelected={selected?.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={table?.length}
                />
                <TableBody className={styles.tabBody}>
                  <TableRow>
                    <TableCell></TableCell>
                    {setSearchName}
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  {stableSort(search(table), getComparator(order, orderBy))
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.id, row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              color="primary"
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.name}
                          </TableCell>
                          <TableCell align="right">{row.unit}</TableCell>
                          <TableCell align="right">{row.details}</TableCell>
                          <TableCell
                            align="right"
                            className={styles.buttonBoxParent}
                          >
                            <div className={styles.buttonBox}>
                              <CopyBtn
                                title="Dodaj"
                                handleChange={(e) => {
                                  setTimeout(() => {
                                    setBtn("Dodaj");
                                    setGoodsTitle("Nowy Towar");
                                  }, 100);
                                }}
                              />
                              <EditBtn
                                title="Edytuj"
                                handleChange={(e) => {
                                  setBtn("Zapisz");
                                  setGoodsTitle("Edytuj");
                                }}
                              />
                              <DeleteBtn
                                title="skasuj"
                                handleChange={(e) => {
                                  const deleteArray = [{ ...row }];

                                  setOpenModalDelete(true);
                                  setModalDeleteData({
                                    state: deleteArray,
                                  });
                                }}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div className={styles.tableBottom}>
              <RowsPerPage
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setPage={setPage}
                tableLength={table?.length}
              />
              <div>
                <Pagination
                  postsPerPage={rowsPerPage}
                  totalPosts={search(table)?.length}
                  paginate={paginate}
                  page={page}
                />
              </div>
            </div>
          </Paper>
        </div>
        <div className={styles.editBox}>
          <GoodsEdit
            name={name}
            unit={unit}
            unitOption={unitTable}
            details={details}
            idx={idx}
            btn={btn}
            title={goodsTitle}
            add={goodsAdd}
            edit={goodsEdit}
            id={idx}
            setDialog={props.setDialog}
          />
          <Units user={props.user} table={unitTable} fetchUnits={fetchUnits} />
        </div>
        {openModalDelete ? (
          <DeleteGoods
            open={openModalDelete}
            setOpen={setOpenModalDelete}
            data={modalDeleteData}
            deleteFunc={goodsDelete}
          />
        ) : null}
      </div>
      {isLoadingGoods && <TmsLoading status="Pobieranie towarów" />}
      {isLoadingUnits && <TmsLoading status="Pobieranie jednostek" />}
    </>
  );
}
