const getOrderNum = (filteredOrders) => {
  if (filteredOrders.length === 0) {
    return 1;
  }

  const onlyNumbers = [
    filteredOrders
      .map((order) => {
        const ordNumber = order.order_no.split("/")[0];
        return isNaN(ordNumber) ? undefined : parseInt(ordNumber);
      })
      .filter((num) => !isNaN(num)),
  ].sort((a, b) => a - b);

  for (let i = 0; i < onlyNumbers.length; i++) {
    if (i === 0) {
      if (onlyNumbers[i] !== 1) {
        return 1;
      }
      continue;
    }

    const prev = onlyNumbers[i - 1];
    const curr = onlyNumbers[i];

    if (curr !== prev + 1) {
      return prev + 1;
    }
  }

  return onlyNumbers[onlyNumbers.length - 1] + 1;
};

export const getNewOrderNumber = (orders, issueDate, special) => {
  const date = new Date(issueDate);
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const filteredOrders = orders.filter((ord) => {
    const issueDateTwo = new Date(ord.ts);
    const issueMonth = issueDateTwo.getMonth() + 1;
    const issueYear = issueDateTwo.getFullYear();
    return issueMonth === month && issueYear === year;
  });
  const orderName = `${getOrderNum(filteredOrders)}/${month}/${year}${
    special ? "/" + special : ""
  }`;
  return orderName;
};

export const filteredByColumny = (data) =>
  Object.values(
    data.reduce((a, c) => {
      if (!a[c.col_name] || c.id > a[c.col_name].id) {
        a[c.col_name] = c;
      }
      return a;
    }, {})
  );

export const round = (num) => {
  var m = Number((Math.abs(num) * 100).toPrecision(15));
  return (Math.round(m) / 100) * Math.sign(num);
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const capitalize = (s) => {
  return s && s[0].toUpperCase() + s.slice(1);
};
