import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { Snackbar, TextField, Button, Input, Grid } from "@material-ui/core";
import styles from "../containers/login/login.module.css";
import {
  DBurl,
  GPSurl,
  AppName,
  ContactEmail,
  ContactEmail2,
  ContactGuard,
} from "../appConfig";
import MuiAlert from "@material-ui/lab/Alert";
import AtlasIntegrationModal from "../containers/configuration/AtlasIntegrationModal";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";
import getFCMToken from "../firebase";
import SyncLoader from "react-spinners/SyncLoader";
import { useHistory } from "react-router-dom";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const override = {
  width: "50px",
  height: "30px",
  zIndex: "99999",
};

export default function Promotion(props) {
  const [dialogColor, setDialogColor] = useState("success");
  const [openDialog, setOpenDialog] = useState(true);
  const [dialogContent, setDialogContent] = useState(
    `W razie trudności z wypełnieniem formularza, prosimy o kontakt z Państwa Opiekunem Handlowym, lub Opiekunem produktu TMS: ${ContactGuard}`
  );

  const [company_name, set_company_name] = useState("");
  const [nip, set_nip] = useState("");
  const [city, set_city] = useState("");
  const [street, set_street] = useState("");
  const [street_no, set_street_no] = useState("");
  const [post_code, set_post_code] = useState("");
  const [phone, set_phone] = useState("");
  const [trader_name, set_trader_name] = useState("");
  const [trader_email, set_trader_email] = useState("");
  const [country, set_country] = useState("");

  const [login, set_login] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [firstname, set_firstname] = useState("");
  const [surname, set_surname] = useState("");
  const [email, set_email] = useState("");

  const [error, set_error] = useState(false);

  const [tab_1, set_tab_1] = useState(true);
  const [tab_2, set_tab_2] = useState(false);
  const [tab_3, set_tab_3] = useState(false);
  const [auto_login, set_auto_login] = useState(false);

  const [new_customer_id, set_new_customer_id] = useState();
  const [new_user_id, set_new_user_id] = useState();

  const [openAtlasIntegrationModal, setOpenAtlasIntegrationModal] =
    useState(false);
  const [dataAtlasIntegrationModal, setDataAtlasIntegrationModal] = useState({
    id: 3,
    id_firm: 1,
    name: "Klient Nowy edit",
    shortname: "Klient edit",
    street: "Wilanowska",
    street_no: "12",
    city: "Warszawa",
    zipcode: "01-000",
    contact_name: "Jan Nowak",
    contact_email: "jnkk@o.pl",
    contact_tel: "123456789",
    atlas_endpoint: `${GPSurl}/atlas/`,
    atlas_auto_add: true,
    tms_version_s: false,
    ekd: true,
    traffic_enabled: true,
  });
  const [table, setTable] = useState([]);

  let history = useHistory();

  const getCustomers = async () => {
    props.loading(true);
    const response = await fetch(`${DBurl}/getCustomers`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setTable(json.data);
      props.loading(false);
    }
  };

  const fetchSetPromoAccountStage1 = async function (data) {
    const response = await fetch(`${DBurl}/setPromoAccountStage1`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mode: "insert",
        data: {
          name: data[0].name,
          nip: data[0].nip,
          street: data[0].street,
          street_no: data[0].street_no,
          city: data[0].city,
          postal: data[0].postal,
          phone: data[0].phone || null,
          trader_name: data[0].trader_name || null,
          trader_email: data[0].trader_email || null,
          country: data[0].country,
        },
      }),
    });
    const json = await response.json();
    if (json.success) {
      console.log("Stage1_json", json);
      if (json.data.return === "duplicate") {
        setOpenDialog(true);
        setDialogContent(
          `Niestety, istnieje już konto z podanym numerem NIP..`
        );
        setDialogColor("error");
      } else {
        set_new_customer_id(json.data.new_id);

        set_tab_1(false);
        set_tab_2(true);
        set_tab_3(false);
      }
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
    }
  };

  const fetchSetPromoAccountStage2 = async function (id) {
    const response = await fetch(`${DBurl}/setPromoAccountStage2`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mode: "insert",
        data: {
          password: password,
          login: login,
          email: email,
          imie: firstname,
          nazwisko: surname,
          customer_id: id,
        },
      }),
    });
    const json = await response.json();
    if (json.success && json.data.return !== "duplicate") {
      set_new_user_id(json.data.new_id);
      set_tab_1(false);
      set_tab_2(false);
      set_tab_3(true);
      setOpenAtlasIntegrationModal(true);
    } else {
      if (json.data.return === "duplicate") {
        setOpenDialog(true);
        setDialogContent(
          `Podany login lub adres email już istnieje. Proszę podać inne dane lub skontaktować się z nami w celu weryfikacji istniejącego konta.`
        );
        setDialogColor("error");
      } else {
        setOpenDialog(true);
        setDialogContent(`${json.responseDescription} // ${json.response}  `);
        setDialogColor("error");
      }
    }
  };

  const fetchSetPromoAccountStage3 = async function () {
    const response = await fetch(
      `${DBurl}/setPromoAccountStage3?id_user=${new_user_id}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      autoLogin(login, password);
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDialog(false);
  };

  const hendleTab = () => {
    if (tab_1) {
      if (
        company_name !== "" &&
        nip !== "" &&
        city !== "" &&
        street !== "" &&
        street_no !== "" &&
        post_code !== "" &&
        phone !== "" &&
        // trader_email !== '' &&
        // trader_name !== '' &&
        country !== ""
      ) {
        const data = [
          {
            name: company_name,
            nip: nip,
            street: street,
            street_no: street_no,
            city: city,
            postal: post_code,
            phone: phone || null,
            trader_name: trader_name || null,
            trader_email: trader_email || null,
            country: country,
          },
        ];
        set_error(false);
        fetchSetPromoAccountStage1(data);
      } else {
        set_error(true);
        setOpenDialog(true);
        setDialogContent(`Proszę wypełnić wszystkie wymagane pola.`);
        setDialogColor("error");
        set_error(true);
      }
    } else if (tab_2) {
      if (
        login !== "" &&
        password !== "" &&
        repeatPassword !== "" &&
        firstname !== "" &&
        surname !== "" &&
        email !== ""
      ) {
        if (
          password === repeatPassword &&
          password.length >= 7 &&
          isEmail(email)
        ) {
          set_error(false);
          const data = [
            {
              password: password,
              login: login,
              email: email,
              imie: firstname,
              nazwisko: surname,
            },
          ];
          fetchSetPromoAccountStage2(new_customer_id, data);
        } else if (!isEmail(email)) {
          set_error(true);
          setOpenDialog(true);
          setDialogContent(
            `Adres e-mail nie ma właściwego formatu. Popraw adres e-mail.`
          );
          setDialogColor("error");
        } else {
          set_error(true);
          setOpenDialog(true);
          password !== repeatPassword &&
            setDialogContent(`Podane hasła nie są takie same.`);
          password.length < 7 &&
            setDialogContent(`Hasło musi posiadać minimum 7 znaków.`);
          password !== repeatPassword &&
            password.length < 7 &&
            setDialogContent(
              `Podane hasła nie są takie same i muszą posiadać minimum 7 znaków.`
            );
          setDialogColor("error");
        }
      } else {
        set_error(true);
        setOpenDialog(true);
        setDialogContent(`Proszę wypełnić wszystkie wymagane pola.`);
        setDialogColor("error");
      }
    }
  };

  const changePostCode = (event) => {
    set_post_code(event.target.value);
  };

  const changePhone = (event) => {
    set_phone(event.target.value);
  };

  const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  const sendTokenToBackend = async (csrf_token) => {
    const push_token = await localStorage.getItem("FCMToken");
    const response = await fetch(`${DBurl}/setTokenPushWWW`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf_token,
      },
      body: JSON.stringify({
        data: { push_token: push_token },
      }),
    });
  };

  const checkUser = async (login, password) => {
    const response = await fetch(`${DBurl}/auth/`, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ login: login, password: password }),
    });
    const json = await response.json();
    if (json.success) {
      sessionStorage.setItem("user", JSON.stringify(json.data?.[0]));
      sessionStorage.setItem("isLoggedIn", true);
      props.setIsLoggedIn(json.success);
      props.setUser(json.data && json.data[0]);
      sendTokenToBackend(json.data[0].csrf_token);

      set_auto_login(false);
      history.push("/");
      set_error(false);

      return json;
    } else {
      if (
        json.response === "400" &&
        json.responseDescription ===
          "Bad Request. One or more required parameters were missing or invalid"
      ) {
        setOpenDialog(true);
        setDialogContent("Błędny login");
        setDialogColor("error");
        set_error(true);
      }
      if (
        json.response === "401" &&
        json.responseDescription === "Forbidden."
      ) {
        setOpenDialog(true);
        setDialogContent("Brak dostępu - Błędny login lub hasło");
        setDialogColor("error");
        set_error(true);
      }
    }
  };

  const autoLogin = (login, password) => {
    set_auto_login(true);
    setTimeout(() => {
      checkUser(login, password);
    }, 1500);
  };

  const GoToLogin = () => {
    props.setPromotion(false);
    set_error(false);
    history.push("/");
  };

  const checkForBeta = (url) => {
    const index = url.indexOf("://");
    if (index !== -1) {
      const checkBeta = url.substring(index + 3, index + 7);
      return checkBeta;
    }
    return false;
  };

  useEffect(() => {
    if (props.dataFromURL) {
      set_company_name(props.dataFromURL.name || "");
      set_nip(props.dataFromURL.nip || "");
      set_street(props.dataFromURL.street || "");
      set_street_no(props.dataFromURL.street_no || "");
      set_post_code(props.dataFromURL.postal || "");
      set_trader_name(props.dataFromURL.trader_name || "");
      set_trader_email(props.dataFromURL.trader_email || "");
      set_phone(props.dataFromURL.phone || "");
      set_city(props.dataFromURL.city || "");
    }
  }, [props.dataFromURL]);

  useEffect(() => {
    getFCMToken();
  }, []);

  useEffect(() => {
    if (tab_3 === true) {
      setDialogContent(
        `Podaj login i hasło do GPS ${AppName} w celu zaimportowania pojazdów i kierowców. Login i hasło które stworzyłeś przed chwilą jest potrzebne tylko na ${ContactEmail2} i nie wpisuj go teraz.`
      );
    }
  }, [tab_3]);

  return (
    <>
      <div className={styles.loginPage}>
        <div className={styles.loginLink}>
          <span>
            {" "}
            Nie masz konta? <a href={`mailto:${ContactEmail}`}>Napisz do nas</a>
          </span>
        </div>
        <div
          className={styles.loginLoginLink}
          style={
            checkForBeta(DBurl) !== "beta" ? { top: "20px" } : { top: "40px" }
          }
        >
          <button onClick={(e) => GoToLogin()}> Logowanie</button>
        </div>

        {tab_1 === true ? (
          <div className={styles.promotion}>
            <h2>{AppName} TMS</h2>
            <h5>Zarejestruj bezpłatne konto próbne</h5>

            <div>
              <TextField
                error={error && company_name === "" ? true : false}
                variant="standard"
                margin="normal"
                required
                fullWidth
                name="company_name"
                label="Nazwa firmy"
                type="text"
                id="company_name"
                value={company_name}
                className={styles.input}
                onChange={(e) => set_company_name(e.target.value)}
              />
              <TextField
                error={error && nip === "" ? true : false}
                variant="standard"
                margin="normal"
                required
                fullWidth
                name="nip"
                label="NIP"
                type="number"
                id="nip"
                value={nip}
                className={styles.input}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    set_nip(e.target.value);
                  }
                }}
                maxLength={5}
              />

              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <TextField
                    error={error && street === "" ? true : false}
                    variant="standard"
                    margin="normal"
                    required
                    fullWidth
                    name="street"
                    label="Ulica"
                    type="text"
                    id="street"
                    value={street}
                    className={styles.input}
                    onChange={(e) => set_street(e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    error={error && street_no === "" ? true : false}
                    variant="standard"
                    margin="normal"
                    required
                    fullWidth
                    name="street_no"
                    label="Numer"
                    type="text"
                    id="street_no"
                    value={street_no}
                    className={styles.input}
                    onChange={(e) => set_street_no(e.target.value)}
                  />
                </Grid>
              </Grid>

              <TextField
                error={error && phone === "" ? true : false}
                variant="standard"
                margin="normal"
                required
                fullWidth
                name="phone"
                label="Numer telefonu"
                id="phone"
                className={styles.input}
                InputProps={{
                  inputComponent: IMaskInput,
                  inputProps: { mask: "000-000-000", guide: true },
                  value: phone,
                  onChange: changePhone,
                }}
              />

              <Grid container spacing={2}>
                <Grid item xs={7}>
                  <TextField
                    error={error && city === "" ? true : false}
                    variant="standard"
                    margin="normal"
                    required
                    fullWidth
                    name="city"
                    label="Miasto"
                    type="text"
                    id="city"
                    value={city}
                    pattern="[A-Za-z ]*"
                    className={styles.input}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (!/\d/.test(inputValue)) {
                        set_city(inputValue);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    error={error && post_code === "" ? true : false}
                    variant="standard"
                    margin="normal"
                    required
                    fullWidth
                    name="post_code"
                    label="Kod pocztowy"
                    id="post_code"
                    className={styles.input}
                    InputProps={{
                      inputComponent: IMaskInput,
                      inputProps: { mask: "00-000", guide: true },
                      value: post_code,
                      onChange: changePostCode,
                    }}
                  />
                </Grid>
              </Grid>

              <TextField
                error={error && country === "" ? true : false}
                variant="standard"
                margin="normal"
                required
                fullWidth
                name="country"
                label="Kraj"
                type="text"
                id="country"
                value={country}
                className={styles.input}
                onChange={(e) => set_country(e.target.value)}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                className={styles.logBtn}
                onClick={(e) => hendleTab()}
              >
                Konfiguracja konta
              </Button>
            </div>
          </div>
        ) : tab_2 === true ? (
          <div className={styles.promotion}>
            <h2>{AppName} TMS</h2>
            <h5>Konfiguracja konta</h5>

            <div>
              <TextField
                error={error && login === "" ? true : false}
                variant="standard"
                margin="normal"
                required
                fullWidth
                name="login"
                label="Login"
                type="text"
                id="login"
                value={login}
                className={styles.input}
                onChange={(e) => set_login(e.target.value)}
              />
              <TextField
                error={
                  error && (password === "" || password !== repeatPassword)
                    ? true
                    : false
                }
                variant="standard"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Hasło"
                type="password"
                id="password"
                value={password}
                className={styles.input}
                onChange={(e) => setPassword(e.target.value)}
              />
              <TextField
                error={
                  error &&
                  (repeatPassword === "" || password !== repeatPassword)
                    ? true
                    : false
                }
                variant="standard"
                margin="normal"
                required
                fullWidth
                name="repeatPassword"
                label="Powtórz hasło"
                type="password"
                id="repeatPassword"
                value={repeatPassword}
                className={styles.input}
                onChange={(e) => setRepeatPassword(e.target.value)}
              />
              <TextField
                error={error && firstname === "" ? true : false}
                variant="standard"
                margin="normal"
                required
                fullWidth
                name="firstname"
                label="Imię"
                type="text"
                id="firstname"
                value={firstname}
                className={styles.input}
                onChange={(e) => set_firstname(e.target.value)}
              />
              <TextField
                error={error && surname === "" ? true : false}
                variant="standard"
                margin="normal"
                required
                fullWidth
                name="surname"
                label="Nazwisko"
                type="text"
                id="surname"
                value={surname}
                className={styles.input}
                onChange={(e) => set_surname(e.target.value)}
              />
              <TextField
                error={
                  error && (email === "" || !isEmail(email)) ? true : false
                }
                variant="standard"
                margin="normal"
                required
                fullWidth
                name="email"
                label="E-mail"
                type="text"
                id="email"
                value={email}
                className={styles.input}
                onChange={(e) => {
                  set_email(e.target.value);
                }}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                className={styles.logBtn}
                onClick={(e) => hendleTab()}
              >
                Utwórz konto
              </Button>
            </div>
          </div>
        ) : tab_3 === true ? (
          <div className={styles.promotion}>
            {auto_login === true ? (
              <div className={styles.load}>
                {error ? (
                  <div className={styles.goToLogin}>
                    <h5>
                      Automatyczne logowanie zakończyło się niepowodzeniem.
                    </h5>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      size="large"
                      className={styles.logBtn}
                      onClick={(e) => GoToLogin()}
                    >
                      Powrót do logowania
                    </Button>
                  </div>
                ) : (
                  <>
                    <SyncLoader
                      color={"#ed3c22"}
                      loading={auto_login}
                      height={3}
                      width={"100%"}
                      cssOverride={override}
                    />
                    <h5>Automatyczne logowanie na konto promocyjne.</h5>
                  </>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          ""
        )}

        <div className={styles.loginLink}>
          <NavLink to="/login">Polityka prywatności</NavLink>
        </div>

        <AtlasIntegrationModal
          setOpenSnack={setOpenDialog}
          setSnackContent={setDialogContent}
          setSnackColor={setDialogColor}
          open={openAtlasIntegrationModal}
          setOpen={setOpenAtlasIntegrationModal}
          data={dataAtlasIntegrationModal}
          refresh={getCustomers}
          customerPromo={new_customer_id}
          userPromo={new_user_id}
          promotion={true}
          PromoUnlockAndLogin={fetchSetPromoAccountStage3}
          setPromotion={props.setPromotion}
        />
        <Snackbar
          open={openDialog}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleClose} severity={dialogColor}>
            {dialogContent}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}
