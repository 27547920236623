
const getOrderNum = (filteredOrders) => {
    if (filteredOrders.length === 0) {
        return 1;
    }

    const onlyNumbers = [... new Set(filteredOrders.map(ord => {
        const ordNumber = ord.order_no.split("/")[0];
        return isNaN(ordNumber) ? undefined : parseInt(ordNumber);
    }).filter(num => !isNaN(num)))].sort((a, b) => a - b);

    for (let i = 0; i < onlyNumbers.length; i++) {
        if (i === 0) {
            if (onlyNumbers[i] !== 1) {
                return 1;
            }
            continue;
        }

        const prev = onlyNumbers[i - 1];
        const curr = onlyNumbers[i];

        if (curr !== prev + 1) {
            return prev + 1;
        }
    }

    return onlyNumbers[onlyNumbers.length - 1] + 1;
};

export const getNewOrderNumber = (orders, issueDate, special) => {
    const date = new Date(issueDate)
    const month = date.getMonth() +1;
    const year = date.getFullYear();
    const filteredOrders = orders.filter(ord => {
        const issueDateTwo = new Date(ord.ts)
        const issueMonth = issueDateTwo.getMonth() +1
        const issueYear = issueDateTwo.getFullYear()
        return issueMonth === month && issueYear === year
})
    const orderName = `${getOrderNum(filteredOrders)}/${month}/${year}${special ? "/"+special : ""}`
    return orderName
}
