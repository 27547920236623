import React from 'react'
import { Grid, Dialog, DialogContent, DialogTitle, Slide } from '@material-ui/core';
import stylesMod from '../styles/newOrEdit.module.css'
import { X } from 'react-feather';
import {IconButton} from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});




export default function ViewDataModal(props) {

  // const title = () => {
  //   switch (props.title) {
  //     case "Pojazdy":{
  //      return "" 
  //     }
  //   }
  //   if (props.title ==="Pojazdy") {
  //     return 
  //   }
  // }

  const content = (data) => {
    switch (props.title) {
      case "Pojazdy": {
        return (
          <>
            <Grid item={3} >
              Nazwa: <br />
              VIN: <br />
              Rok produkcji: <br />
              Norma spalania:<br />
              Typ spalania <br />
              Waga: <br />
              Termin OC: <br />
              Termin AC: <br />
              Przegląd ważny do: <br />
              Termin gaśnicy: <br />
              Komentarz:
            </Grid>
            <Grid item={3} >
              {data.name} <br />
              {data.vin || "brak danych"}<br />
              {data.rok_produkcji}<br />
              {data.norma_spalania || "brak danych"} {data.norma_spalania && "l/100km"}<br />
              {data.type || "brak danych"}<br />
              {data.axle_netto || "brak danych"} {data.axle_netto && "kg"}<br />
              {data.term_oc || "brak danych"}<br />
              {data.term_ac || "brak danych"}<br />
              {data.inspection_valid_until || "brak danych"}<br />
              {data.extinguisherValidTo || "brak danych"}<br />
              {data.description}
            </Grid>

          </>
        )
      }
      case "Naczepy": {
        return (
          <>
            <Grid item={3} >
              Nazwa: <br />
              VIN: <br />
              Rok produkcji: <br />
              Norma spalania:<br />
              Typ spalania <br />
              Waga: <br />
              Termin OC: <br />
              Termin AC: <br />
              Przegląd ważny do: <br />
              Komentarz:
            </Grid>
            <Grid item={3} >
              {data.name} <br />
              {data.vin || "brak danych"}<br />
              {data.rok_produkcji}<br />
              {data.norma_spalania || "brak danych"} {data.norma_spalania && "l/100km"}<br />
              {data.type || "brak danych"}<br />
              {data.axle_netto || "brak danych"} {data.axle_netto && "kg"}<br />
              {data.term_oc || "brak danych"}<br />
              {data.term_ac || "brak danych"}<br />
              {data.inspection_valid_until || "brak danych"}<br />
              {data.description}
            </Grid>

          </>
        )
      }
      case "Kierowcy": {
        return (
          <>
            <Grid item={3} >
              Imię i nazwisko: <br />
              Nr telefonu: <br />
              E-mail: <br />
              Następne badanie:<br />
              Ważność karty: <br />
              Zarejestrowany: <br />
              Kategorie: <br />
              Pozwolenia: <br />
              Komentarz:
            </Grid>
            <Grid item={3} >
              {data.name} <br />
              {data.phone_number || "-"}<br />
              {data.email || "-"}<br />
              {data.next_medical_exam || "-"}<br />
              {data.expiry_drivers_card || "-"}<br />
              {data.registered || "-"}<br />
              {data.driving_license_categories && data.driving_license_categories.length > 0 ? data.driving_license_categories.toString() : "brak uprawnień"}<br />
              {data.drivers_permissions && data.drivers_permissions.length > 0 ? data.drivers_permissions.toString(): "brak uprawnień"}<br />
              {data.comment}
            </Grid>

          </>
        )

      }
      default: {
          return (<div>Brak danych</div>)
      }
    }
  }


  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      onClose={() => {
        props.setOpen(false)
        props.setModalContent([])
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title" className={stylesMod.dialogHead}>Informacje:			<IconButton aria-label="close" className={stylesMod.closeButton} onClick={() => {
				props.setOpen(false)
        props.setModalContent([])
			}}>
          <X />
        </IconButton>
			</DialogTitle >
      <DialogContent>
        <Grid container spacing={2} >
          {content(props.data)}
        </Grid>


      </DialogContent>
    </Dialog>
  )
}



