import React, { useState, useEffect, useRef } from "react";
import Input from "../../components/input";
import Title from "../../components/title";
import styles from "../table.module.css";
import { DBurl } from "../../appConfig";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Slide,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import docsStyles from "../../styles/documents.module.css";
import ReportList from "./reportList";
import IcoBtn from "components/buttons/icoBtn";
import ReportFilterList from "./reportFilterList";
import FleetStateOrderStatuses from "containers/fleet/FleetStateOrderStatuses";

function useInput({ type }, placeholder) {
  const [value, setValue] = useState("");
  const input = (
    <Input
      value={value}
      handleChange={(e) => {
        setValue(e.target.value);
      }}
      type={type}
      placeholder={placeholder}
      className={styles.searchInputSmall}
      search={type === "text" ? true : false}
    />
  );
  return [value, input];
}

const FinishedOrders = (props) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("car");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [table, setTable] = useState(props.state.table || []);
  const [selected, setSelected] = useState([]);
  const [openModalList, setOpenModalList] = useState(false);
  const [openModalFilter, setOpenModalFilter] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");
  const [what, setWhat] = useState(props.state.what || "");
  const [filterData, setFilterData] = useState(props.state.filterData || []);
  const [dateFrom, setDateFrom] = useInput(
    props.state.dateFrom || { type: "datetime-local" },
    "Od"
  );
  const [dateTo, setDateTo] = useInput(
    props.state.dateTo || { type: "datetime-local" },
    "Do"
  );
  const [filterWhat, setFilterWhat] = useState(props.state.filterWhat || "");
  const [selectedFilter, setSelectedFilter] = useState(
    props.state.selectedFilter || []
  );
  const [selectedFilter2, setSelectedFilter2] = useState(
    props.state.selectedFilter2 || {}
  );
  const filterListContractor = [
    ...new Set(table?.map((ord) => ord.contractor)),
  ];
  const filterListDriver = [...new Set(table?.map((ord) => ord.driver))];
  const filterListCar = [...new Set(table?.map((ord) => ord.car))];
  const dateFromToSave = dateFrom.split("T")[0];
  const dateToToSave = dateTo.split("T")[0];
  const [ss, setSS] = useState(props.state.ss || false);
  const [searchValue, setSearch] = useState("");

  const [statusModal, setStatusModal] = useState(false);
  const [statusModalData, setStatusModalData] = useState([]);

  const savedTabId = props.tabID;
  const saveTab = () => {
    const tempTab = { ...props.tabs[savedTabId] };

    tempTab.table = table;
    tempTab.what = what;
    tempTab.filterData = filterData;
    tempTab.filterWhat = filterWhat;
    tempTab.selectedFilter = selectedFilter;
    tempTab.selectedFilter2 = selectedFilter2;
    tempTab.ss = ss;

    return tempTab;
  };

  useEffect(() => {
    props.setTabs(() => {
      const tempTab = [...props.tabs];
      tempTab[props.tabID] = saveTab();
      return tempTab;
    });
  }, [table, what, filterData, filterWhat, selectedFilter, selectedFilter2]);

  const handleNewSelectedFilter = (groupName, value) => {
    const fixedGroupName = headCells.find(
      (group) => groupName === group.label
    ).id;
    setSelectedFilter2((prevFilter) => {
      if (!prevFilter.hasOwnProperty(fixedGroupName)) {
        return { ...prevFilter, [fixedGroupName]: [value] };
      }
      const newGroup = prevFilter[fixedGroupName].includes(value)
        ? prevFilter[fixedGroupName].filter((val) => val !== value)
        : [...prevFilter[fixedGroupName], value];
      return { ...prevFilter, [fixedGroupName]: newGroup };
    });
  };

  const headCells = [
    {
      id: "orderNum",
      numeric: true,
      disablePadding: false,
      label: "Numer zlecenia",
      class: docsStyles.headCell,
    },
    {
      id: "customerOrderNum",
      numeric: true,
      disablePadding: false,
      label: "Numer zlecenia klienta",
      class: docsStyles.headCell,
    },
    {
      id: "contractor",
      numeric: true,
      disablePadding: false,
      label: "Klient",
      filter: filterListContractor.length > 1 ? true : false,
    },
    {
      id: "driver",
      numeric: true,
      disablePadding: false,
      label: "Kierowca",
      filter: filterListDriver.length > 1 ? true : false,
    },
    {
      id: "car",
      numeric: true,
      disablePadding: false,
      label: "Auto",
      filter: filterListCar.length > 1 ? true : false,
    },
    {
      id: "reference_no",
      numeric: true,
      disablePadding: false,
      label: "Nr ref.",
    },
    {
      id: "tags",
      numeric: true,
      disablePadding: false,
      label: "Tagi",
    },
    {
      id: "plannedKm",
      numeric: true,
      disablePadding: false,
      label: "Planowane km",
    },
    {
      id: "drivenKm",
      numeric: true,
      disablePadding: false,
      label: "Przejechane km",
    },
    {
      id: "fuelConsumed",
      numeric: true,
      disablePadding: false,
      label: "Zużyte paliwo",
    },
    {
      id: "avgFuelCons",
      numeric: true,
      disablePadding: false,
      label: "Średnie zużycie",
    },
    {
      id: "orderPrice",
      numeric: true,
      disablePadding: false,
      label: "Kwota za zlecenie",
    },
  ];

  const handleFilterData = (headCell) => {
    if (headCell.label === "Klient") setFilterData(filterListContractor);
    if (headCell.label === "Kierowca") setFilterData(filterListDriver);
    if (headCell.label === "Auto") setFilterData(filterListCar);
  };

  function EnhancedTableHead(props) {
    const {
      styles,
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;

    return (
      <TableHead className={styles.tabHead}>
        <TableRow>
          {headCells.map((headCell) => {
            return (
              <TableCell
                key={headCell.id}
                align="left"
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={headCell.class}
              >
                <div className={styles.withFilter}>
                  {headCell.label}
                  {headCell.filter && (
                    <IcoBtn
                      icon="Filter"
                      tooltip="Filtruj"
                      onClick={(e) => {
                        setOpenModalFilter(true);
                        handleFilterData(headCell);
                        setFilterWhat(headCell.label);
                      }}
                    />
                  )}
                </div>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDialog(false);
  };

  const fetchData = async () => {
    props.loading(true);
    const response = await fetch(
      `${DBurl}/getExecutionOfOrders?type=${what}&ids=${selected.toString()}&dateFrom=${dateFrom.replace(
        "T",
        " "
      )}:00&dateTo=${dateTo.replace("T", " ")}:00`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      if (json.data?.data?.length === 0) {
        setOpenDialog(true);
        setDialogColor("warning");
        setDialogContent("Brak danych dla tego wyszukiwania");
        props.loading(false);
      } else {
        setTable(json.data?.data);
        props.loading(false);
      }
    }
  };

  const printOrdersPDF = () => {
    const url = `${DBurl}/printExecutionOfOrdersPDF?type=${what}&ids=${selected.toString()}&dateFrom=${dateFrom.replace(
      "T",
      " "
    )}:00&dateTo=${dateTo.replace(
      "T",
      " "
    )}:00&ids_orders=${currentOrderIDs.toString()}`;
    window.open(url, "_blank");
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleRenderAddress = (row) => {
    return row.address_display_name
      ? row.address_display_name
      : `${row.street}, ${row.zipcode ? row.zipcode : ""} ${row.city}, ${
          row.country
        } `;
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, table.length - page * rowsPerPage);

  const filteredTable = table.filter((ord) => {
    let result = true;
    for (const [key, value] of Object.entries(selectedFilter2)) {
      if (value.includes(ord[key])) {
        result = false;
      }
    }
    return result;
  });

  const plannedDays = filteredTable.map((days) => days.planned_working_days);

  const workingDays = filteredTable.map((days) => days.completed_working_days);

  const totalDistance = filteredTable.map((dist) => dist.total_distance);

  const plannedDistance = filteredTable.map((dist) => {
    if (dist.sum_kilometers) {
      return parseFloat(dist.sum_kilometers);
    }
    return 0;
  });

  const fuelConsumed = filteredTable.map((fuel) => {
    if (fuel.total_fuel) {
      return parseFloat(fuel.total_fuel);
    }
    return 0;
  });

  const avgFuelConsumed = filteredTable.map((fuel) => {
    if (fuel.fuel_avg) {
      return parseFloat(fuel.fuel_avg);
    }
    return 0;
  });

  const currentOrderIDs = filteredTable.map((ord) => parseInt(ord.id));

  const handleTags = (row) => {
    return row?.tags?.map((tag) => tag.name)?.join(", ");
  };

  const handleRefs = (row) => {
    const locationsRefs = row?.locations
      ?.map((loc) => loc.reference_no)
      ?.filter((rec) => rec !== null);
    const goodsRefs = row?.locations
      ?.map((loc) => loc?.goods?.map((g) => g.reference_no))
      ?.flat()
      ?.filter((rec) => rec !== null);
    if (locationsRefs.length) return locationsRefs;
    if (!locationsRefs.length) return goodsRefs;
    if (!locationsRefs && !goodsRefs) return [];
  };

  const search = (tbl) => {
    return tbl.filter((row) => {
      return (
        row?.order_no
          ?.toString()
          .toLowerCase()
          .includes(searchValue?.toLowerCase()) ||
        row?.customer_order_number
          ?.toString()
          .toLowerCase()
          .includes(searchValue?.toLowerCase()) ||
        row?.contractor
          ?.toString()
          .toLowerCase()
          .includes(searchValue?.toLowerCase()) ||
        row?.driver
          ?.toString()
          .toLowerCase()
          .includes(searchValue?.toLowerCase()) ||
        row?.car
          ?.toString()
          .toLowerCase()
          .includes(searchValue?.toLowerCase()) ||
        handleTags(row)?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        handleRefs(row)
          .join(", ")
          ?.toLowerCase()
          .includes(searchValue?.toLowerCase())
      );
    });
  };

  return (
    <>
      <div className={styles.root}>
        <Paper className={styles.paper}>
          <Title
            title={props.state.tabName}
            btnBox
            btnGenerate
            generateFunc={(e) => {
              if (!selected || !dateFrom || !dateTo) {
                props.setDialog({
                  isOpen: true,
                  type: "warning",
                  content: "Proszę uzupełnić formularz",
                });
              } else {
                fetchData();
                setSelectedFilter2({});
                setSS(what);
              }
            }}
            btnClients
            btnClientsFunc={(e) => {
              setOpenModalList(true);
              setWhat("contractors");
            }}
            btnClientsActive={ss === "contractors"}
            btnCars
            btnCarsFunc={(e) => {
              setOpenModalList(true);
              setWhat("cars");
            }}
            btnCarsActive={ss === "cars"}
            btnDrivers
            btnDriversFunc={(e) => {
              setOpenModalList(true);
              setWhat("drivers");
            }}
            btnDriversActive={ss === "drivers"}
            btnExport
            exportFileType="PDF"
            exportFunc={() => {
              if (!selected || !dateFrom || !dateTo) {
                setOpenDialog(true);
                setDialogColor("error");
                setDialogContent("Proszę uzupełnić formularz");
              } else printOrdersPDF();
            }}
            searchInput
            setSearchValue={setSearch}
            searchValue={searchValue}
            cornersNew
            rightCorner1={setDateFrom}
            rightCorner2={setDateTo}
          />
          <TableContainer className={styles.stickyTable}>
            <Table
              className={styles.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                styles={styles}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={table.length}
              />
              <TableBody className={styles.tabBody}>
                {search(filteredTable).map((row, index) => {
                  const uniqueRefs = [...new Set(handleRefs(row))];
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <>
                      {index > 0 && (
                        <TableRow>
                          {headCells.map((headCell) => {
                            return (
                              <TableCell
                                key={headCell.id}
                                align="left"
                                padding={
                                  headCell.disablePadding ? "none" : "default"
                                }
                                sortDirection={
                                  orderBy === headCell.id ? order : false
                                }
                                className={headCell.class}
                                style={{
                                  backgroundColor: "#ededed",
                                  height: 51,
                                  borderTop: "3px solid black",
                                }}
                              >
                                <div className={styles.withFilter}>
                                  <b>{headCell.label}</b>
                                </div>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      )}
                      <TableRow
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell className={docsStyles.headCell}>
                          <div className={docsStyles.statusHistoryBtnContainer}>
                            {row.order_no}
                            {row.driver_status.length && (
                              <IcoBtn
                                icon="Info"
                                tooltip="Historia statusów"
                                value={row}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setStatusModal(true);
                                  setStatusModalData(row);
                                }}
                              />
                            )}
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          {row.customer_order_number
                            ? row.customer_order_number
                            : "b.d."}
                        </TableCell>
                        <TableCell align="left">
                          {row.contractor ? row.contractor : "b.d."}
                        </TableCell>
                        <TableCell align="left">
                          {row.driver ? row.driver : "b.d."}
                        </TableCell>
                        <TableCell align="left">
                          {row.car ? row.car : "b.d."}
                        </TableCell>
                        <TableCell align="left">
                          {uniqueRefs.length
                            ? uniqueRefs
                                .map((ref) => {
                                  return ref;
                                })
                                .join(", ")
                            : "b.d."}
                        </TableCell>
                        <TableCell align="left">
                          {handleTags(row) ? handleTags(row) : "b.d."}
                        </TableCell>
                        <TableCell align="left">
                          {row.sum_kilometers ? row.sum_kilometers : "b.d."}
                        </TableCell>
                        <TableCell align="left">
                          {row.total_distance ? row.total_distance : "b.d."}
                        </TableCell>
                        <TableCell align="left">
                          {row.total_fuel ? row.total_fuel : "b.d."}
                        </TableCell>
                        <TableCell align="left">
                          {row.fuel_avg ? row.fuel_avg : "b.d."}
                        </TableCell>
                        <TableCell align="left">
                          {row.order_price
                            ? `${row.order_price} ${
                                row.order_currency ? row.order_currency : ""
                              }`
                            : "b.d."}
                        </TableCell>
                      </TableRow>
                      {row.locations
                        .filter((loc) => loc.type === "loading")
                        .map((row) => {
                          return (
                            <>
                              <TableRow
                                style={{
                                  backgroundColor: "#ededed",
                                  height: 51,
                                }}
                              >
                                <TableCell className={docsStyles.headCell}>
                                  <b>Miejsce załadunku</b>
                                </TableCell>
                                <TableCell>
                                  <b>Planowany czas</b>
                                </TableCell>
                                <TableCell>
                                  <b>Rzeczywisty czas</b>
                                </TableCell>
                                <TableCell>
                                  <b>Różnica</b>
                                </TableCell>
                                <TableCell>
                                  <b>Waga</b>
                                </TableCell>
                                <TableCell>
                                  <b>Nr ref.</b>
                                </TableCell>
                                {Array(6)
                                  .fill()
                                  .map((o) => {
                                    return <TableCell></TableCell>;
                                  })}
                              </TableRow>
                              <TableRow
                                style={{
                                  backgroundColor: "inherit",
                                  height: 51,
                                }}
                              >
                                <TableCell className={docsStyles.headCell}>
                                  {handleRenderAddress(row)}
                                </TableCell>
                                <TableCell>{row.ts}</TableCell>
                                <TableCell>
                                  {row.driver_status?.ts_status_start?.split(
                                    "."
                                  )[0]
                                    ? row.driver_status?.ts_status_start?.split(
                                        "."
                                      )[0]
                                    : "b.d."}
                                </TableCell>
                                <TableCell>
                                  {row.driver_status.time_loc_diff
                                    ? row.driver_status.time_loc_diff
                                    : "b.d."}
                                </TableCell>
                                <TableCell>
                                  {row.driver_status.weight &&
                                    row.driver_status.weight + "kg"}
                                </TableCell>
                                <TableCell>
                                  {row.reference_no
                                    ? row.reference_no
                                    : row.goods
                                        .map((g) => g.reference_no)
                                        .join(", ")}
                                </TableCell>
                                {Array(6)
                                  .fill()
                                  .map((o) => {
                                    return <TableCell></TableCell>;
                                  })}
                              </TableRow>
                            </>
                          );
                        })}
                      {row.locations
                        .filter((loc) => loc.type === "unloading")
                        .map((row) => {
                          return (
                            <>
                              <TableRow
                                style={{
                                  backgroundColor: "#ededed",
                                  height: 51,
                                }}
                              >
                                <TableCell className={docsStyles.headCell}>
                                  <b>Miejsce rozładunku</b>
                                </TableCell>
                                <TableCell>
                                  <b>Planowany czas</b>
                                </TableCell>
                                <TableCell>
                                  <b>Rzeczywisty czas</b>
                                </TableCell>
                                <TableCell>
                                  <b>Różnica</b>
                                </TableCell>
                                <TableCell>
                                  <b>Waga</b>
                                </TableCell>
                                <TableCell>
                                  <b>Nr ref.</b>
                                </TableCell>
                                {Array(6)
                                  .fill()
                                  .map((o) => {
                                    return <TableCell></TableCell>;
                                  })}
                              </TableRow>
                              <TableRow
                                style={{
                                  backgroundColor: "inherit",
                                  height: 51,
                                }}
                              >
                                <TableCell className={docsStyles.headCell}>
                                  {handleRenderAddress(row)}
                                </TableCell>
                                <TableCell>{row.ts}</TableCell>
                                <TableCell>
                                  {row.driver_status?.ts_status_start?.split(
                                    "."
                                  )[0]
                                    ? row.driver_status?.ts_status_start?.split(
                                        "."
                                      )[0]
                                    : "b.d."}
                                </TableCell>
                                <TableCell>
                                  {row.driver_status.time_loc_diff
                                    ? row.driver_status.time_loc_diff
                                    : "b.d."}
                                </TableCell>
                                <TableCell>
                                  {row.driver_status.weight &&
                                    row.driver_status.weight + "kg"}
                                </TableCell>
                                <TableCell>
                                  {row.reference_no
                                    ? row.reference_no
                                    : row.goods
                                        .map((g) => g.reference_no)
                                        .join(", ")}
                                </TableCell>
                                {Array(6)
                                  .fill()
                                  .map((o) => {
                                    return <TableCell></TableCell>;
                                  })}
                              </TableRow>
                            </>
                          );
                        })}
                    </>
                  );
                })}
                <TableRow
                  style={{
                    backgroundColor: "#ededed",
                    borderTop: "solid 3px black",
                  }}
                >
                  <TableCell className={docsStyles.headCell}>
                    <b>PODSUMOWANIE</b>
                  </TableCell>
                  {Array(11)
                    .fill()
                    .map((o) => {
                      return <TableCell></TableCell>;
                    })}
                </TableRow>
                <TableRow>
                  <TableCell className={docsStyles.headCell}>
                    <b>Łącznie zleceń</b>
                  </TableCell>
                  <TableCell>
                    {
                      table.filter((ord) => {
                        let result = true;
                        for (const [key, value] of Object.entries(
                          selectedFilter2
                        )) {
                          if (value.includes(ord[key])) {
                            result = false;
                          }
                        }
                        return result;
                      }).length
                    }
                  </TableCell>
                  {Array(10)
                    .fill()
                    .map((o) => {
                      return <TableCell></TableCell>;
                    })}
                </TableRow>
                <TableRow style={{ backgroundColor: "#ededed" }}>
                  <TableCell className={docsStyles.headCell}>
                    <b>Dni pracy</b>
                  </TableCell>
                  <TableCell>
                    <b>Zaplanowanych</b>
                  </TableCell>
                  <TableCell>
                    <b>Zrealizowanych</b>
                  </TableCell>
                  {Array(9)
                    .fill()
                    .map((o) => {
                      return <TableCell></TableCell>;
                    })}
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    {plannedDays.length > 0 &&
                      plannedDays.reduce((a, b) => a + b)}
                  </TableCell>
                  <TableCell>
                    {workingDays.length > 0 &&
                      workingDays.reduce((a, b) => a + b)}
                  </TableCell>
                  {Array(9)
                    .fill()
                    .map((o) => {
                      return <TableCell></TableCell>;
                    })}
                </TableRow>
                <TableRow style={{ backgroundColor: "#ededed" }}>
                  <TableCell className={docsStyles.headCell}>
                    <b>Przejechane KM</b>
                  </TableCell>
                  <TableCell>
                    <b>Zaplanowane</b>
                  </TableCell>
                  <TableCell>
                    <b>Przejechane z telematyki</b>
                  </TableCell>
                  {Array(9)
                    .fill()
                    .map((o) => {
                      return <TableCell></TableCell>;
                    })}
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    {plannedDistance.length > 0 &&
                      plannedDistance.reduce((a, b) => a + b)}
                  </TableCell>
                  <TableCell>
                    {totalDistance.length > 0 &&
                      totalDistance.reduce((a, b) => a + b)}
                  </TableCell>
                  {Array(9)
                    .fill()
                    .map((o) => {
                      return <TableCell></TableCell>;
                    })}
                </TableRow>
                <TableRow style={{ backgroundColor: "#ededed" }}>
                  <TableCell className={docsStyles.headCell}>
                    <b>Zużyte paliwo</b>
                  </TableCell>
                  <TableCell>
                    <b>Ilość litrów</b>
                  </TableCell>
                  <TableCell>
                    <b>Średnia l/100km</b>
                  </TableCell>
                  {Array(9)
                    .fill()
                    .map((o) => {
                      return <TableCell></TableCell>;
                    })}
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    {fuelConsumed.length > 0 &&
                      fuelConsumed.reduce((a, b) => a + b)}
                  </TableCell>
                  <TableCell>
                    {avgFuelConsumed.length > 0 &&
                      (
                        avgFuelConsumed.reduce(
                          (total, current) => total + current,
                          0
                        ) / avgFuelConsumed.length
                      ).toFixed(2)}
                  </TableCell>
                  {Array(9)
                    .fill()
                    .map((o) => {
                      return <TableCell></TableCell>;
                    })}
                </TableRow>
                {emptyRows > 0 && (
                  <TableRow style={{ height: 33 * emptyRows }}>
                    <TableCell colSpan={12} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={styles.tableBottom}></div>
        </Paper>
      </div>
      <Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={dialogColor}>
          {dialogContent}
        </Alert>
      </Snackbar>
      <ReportList
        token={props.user.csrf_token}
        data={table}
        open={openModalList}
        setOpen={setOpenModalList}
        what={what}
        setWhat={setWhat}
        selected={selected}
        setSelected={setSelected}
        loading={props.loading}
      />
      <ReportFilterList
        token={props.user.csrf_token}
        data={filterData}
        open={openModalFilter}
        setOpen={setOpenModalFilter}
        what={filterWhat}
        selected={selectedFilter}
        setSelected={setSelectedFilter}
        setSelected2={handleNewSelectedFilter}
      />
      <FleetStateOrderStatuses
        orderStatus={true}
        data={statusModalData}
        open={statusModal}
        setOpen={setStatusModal}
        user={props.user}
      />
    </>
  );
};

export default FinishedOrders;
