import React, { useState, useEffect } from 'react'
import Title from '../../components/title'
import { DBurl } from '../../appConfig'
import { X, Clock, Unlock} from 'react-feather';
import styles from '../table.module.css'
import stylesMod from '../../styles/newOrEdit.module.css'
import BTNstyles from '../../styles/button.module.css'

import RowsPerPage from '../../components/RowsPerPage'
import Pagination from '../../components/pagination'
import Button from '@material-ui/core/Button';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Paper, Checkbox, Slide, Snackbar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';

import AddClient from './AddClient'
import AtlasIntegrationModal from './AtlasIntegrationModal'
import Input from '../../components/input'
import IcoBtn from '../../components/buttons/icoBtn';
import UnlockAccond from '../../components/buttons/unlockAccound'
import AddClock from '../../components/buttons/clock'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function descendingComparator(a, b, orderBy) {
	const aValue = a[orderBy];
	const bValue = b[orderBy];

	console.log(aValue,bValue)

	if (typeof aValue === "boolean" && typeof bValue === "boolean") {
		if (aValue === bValue) {
			return 0;
		} else if (aValue) {
			return -1;
		} else {
			return 1;
		}
	}

	if (bValue == null || bValue === "") {
	  	return -1;
	}
	if (aValue == null || aValue === "") {
	  	return 1;
	}
  
	if (bValue < aValue) {
	  	return -1;
	}
	if (bValue > aValue) {
	  	return 1;
	}
	return 0;
  }
function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{ id: "name", right: true, disablePadding: false, label: "Nazwa" },
	{ id: "firm", right: true, disablePadding: false, label: "Firma", width: '150px'},
	{ id: "contact_name", right: true, disablePadding: false, label: "Osoba do kontaktu" },
	{ id: "contact_email", right: true, disablePadding: false, label: "Mail do kontaktu" },
	{ id: "contact_tel", right: true, disablePadding: false, label: "tel do kontaktu" },
	{ id: "trader_name", right: true, disablePadding: false, label: "Opiekun handlowy"},
	{ id: "sum_cars", right: true, disablePadding: false, label: "Ilość pojazdów", width: '120px'},
	{ id: "promo_account_expires_to", right: true, disablePadding: false, label: "Aktywne do"},
	{ id: "is_promo_account", right: true, disablePadding: false, label: "Typ Konta", width: '120px'},
	{ id: "tms_version_s", right: true, disablePadding: false, label: "TMS vS", width: '100px'},
];

function EnhancedTableHead(props) {
	const { styles, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead className={styles.tabHead}>
			<TableRow>
				<TableCell className={styles.checkbox}>
					<Checkbox
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{ 'aria-label': 'select all id' }}
					/>
				</TableCell>
				
				{headCells.map((headCell) => {
					return <TableCell
						key={headCell.id}
						align={headCell.right ? 'right' : 'left'}
						padding={headCell.disablePadding ? 'none' : 'default'}
						sortDirection={orderBy === headCell.id ? order : false}
						style={{ width: `${headCell.width || "auto"}` }}
					>
						{!headCell.noSort ? <TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={styles.visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</span>
							) : null}
						</TableSortLabel> : headCell.label}
					</TableCell>
				})}
				
				<TableCell />
			</TableRow>
		</TableHead>
	);
}

function useInput({ type, size }) {
	const [value, setValue] = useState("")
	const input = <TableCell className={styles.inputCell}><Input value={value} handleChange={e => {
		setValue(e.target.value)
	}
	} type={type}
		search={true}
		className={size} /></TableCell>;
	return [value, input];
}

export default function CompanyList(props) {

	const [table, setTable] = useState([])
	const [firms, setFirms] = useState([])
	const [edit, setEdit] = useState(false)
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('short');
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(15);
	const [searchName, setSearchName] = useInput({ type: "text" });
	const [searchStreet, setSearchStreet] = useInput({ type: "text" });
	const [searchCity, setSearchCity] = useInput({ type: "text" })
	const [searchZipcode, setSearchZipcode] = useInput({ type: "text" })
	const [searchContact_name, setSearchContact_name] = useInput({ type: "text" })
	const [searchContact_email, setSearchContact_email] = useInput({ type: "text" })
	const [searchContact_tel, setSearchContact_tel] = useInput({ type: "text" })
	const [searchFirm, setSearchFirm] = useInput({ type: "text" })
	const [searchTMS_version_s, setSearchTMS_version_s] = useInput({ type: "text" });
	const [searchIs_promo_account, setSearchIs_promo_account] = useInput({ type: "text" });
	const [searchIs_car_count, setSearchIs_car_count] = useInput({ type: "text" });
	const [searchIs_trader_name, setSearchIs_trader_name] = useInput({ type: "text" });
	const [deleteData, setDeleteData] = React.useState([])
	const [openModalDelete, setOpenModalDelete] = React.useState(false)
	const [openAddClientModal, setOpenAddClientModal] = useState(false)
	const [dataAddClientModal, setDataAddClientModal] = useState(false)
	const [openAtlasIntegrationModal, setOpenAtlasIntegrationModal] = useState(false)
	const [dataAtlasIntegrationModal, setDataAtlasIntegrationModal] = useState(false)
	const [openDialog, setOpenDialog] = useState(false)
	const [dialogContent, setDialogContent] = useState("")
	const [dialogColor, setDialogColor] = useState("error")
	const [openModalPromotionalPermanent, setOpenModalPromotionalPermanent] = useState(false)
	const [openModalPromotionalExtension, setOpenModalPromotionalExtension] = useState(false)
	const [selectedCustomerID, setSelectedCustomerID] = useState()
	const [selectedUserID, setSelectedUserID] = useState()
	const [traders, setTraders] = useState()


	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenDialog(false)
	}

	const getCustomers = async () => {
		props.loading(true)
		const response = await fetch(`${DBurl}/getCustomersSA`, {
			method: 'GET',
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": props.user.csrf_token
			}
		})
		const json = await response.json()
		if (json.success) {
			//wymagane przy sortowaniu
			const modifiedTable = json.data.map((row) => {
				const promoAccountExpiresTo = row.promo_users.length && row.promo_users[0].promo_account_expires_to ? row.promo_users[0].promo_account_expires_to : null;
				return { ...row, promo_account_expires_to: promoAccountExpiresTo };
			});
			setTable(modifiedTable)
			props.loading(false)
		}else{
			props.loading(false)
		}
	}

	const getTraders = async () => {
		props.loading(true)
		const response = await fetch(`${DBurl}/getTraders`, {
			method: 'GET',
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": props.user.csrf_token
			}
		})
		const json = await response.json()
		if (json.success) {
			setTraders(json.data.map(e => {
				return { ...e, label: e.name, value: e.id }
			}))
			props.loading(false)
		}
	}

	const getFirm = async () => {
		props.loading(true)
		const response = await fetch(`${DBurl}/getFirm`, {
			method: 'GET',
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": props.user.csrf_token
			}
		})
		const json = await response.json()
		if (json.success) {
			setFirms(json.data.map(e => {
				return { ...e, label: e.name, value: e.id }
			}))
			props.loading(false)
		}
	}

	const printXLS = () => {
		const url = `${DBurl}/printCustomersSA_XLS`;
		window.open(url, '_blank');
	}

	const checkFirm = (id) => {
		return firms.find(e => e.id === id)
	}

	const delCustomers = async function (arrayOfIds) {
		props.loading(true)
		const response = await fetch(`${DBurl}/setCustomers/`, {
			method: "post",
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": props.user.csrf_token
			},
			body: JSON.stringify({
				mode: 'delete',
				data: {
					ids: arrayOfIds
				}
			})
		})
		const json = await response.json()

		if (json.success) {
			setOpenDialog(true)
			setDialogContent(`Firma została usunięta `)
			setDialogColor("success")
			getCustomers()
			props.loading(false)
			return json
		} else {
			setOpenDialog(true)
			setDialogContent(`${json.responseDescription} // ${json.response}  `)
			setDialogColor("error")
			props.loading(false)
		}
	}

	const promoAccountToNormal = async function (id_customer) {
		props.loading(true)
		const response = await fetch(`${DBurl}/setPromoAccountToNormal`, {
			method: "post",
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": props.user.csrf_token
			},
			body: JSON.stringify({
				mode: 'update',
				data: {
					id_customer: id_customer, 
				}
			})
		})
		const json = await response.json()

		if (json.success) {
			setOpenDialog(true)
			setDialogContent(`Konto przepięte na stałe!`)
			setDialogColor("success")
			setSelectedCustomerID()
			getCustomers()
			props.loading(false)
			return json
		} else {
			setOpenDialog(true)
			setDialogContent(`${json.responseDescription} // ${json.response}  `)
			setDialogColor("error")
			setSelectedCustomerID()
			props.loading(false)
		}
	}

	const extendPromoAccount = async function (id_user) {
		props.loading(true)
		const response = await fetch(`${DBurl}/setExtendPromoAccount`, {
			method: "post",
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": props.user.csrf_token
			},
			body: JSON.stringify({
				mode: 'update',
				data: {
					id_user: id_user, // ID usera
				}
			})
		})
		const json = await response.json()

		if (json.success) {
			setOpenDialog(true)
			setDialogContent(`Konto zostało przedłuzone o 30 dni`)
			setDialogColor("succes")
			setSelectedUserID()
			getCustomers()
			props.loading(false)
			return json
		} else {
			setOpenDialog(true)
			setDialogContent(`${json.responseDescription} // ${json.response}  `)
			setDialogColor("error")
			setSelectedUserID()
			props.loading(false)
		}
	}

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const search = (tbl) => {

		return tbl?.filter((row) => {

			return String(row.city).toLowerCase().indexOf(searchCity.toLowerCase()) > -1 &&
				String(row.name).toLowerCase().indexOf(searchName.toLowerCase()) > -1 &&
				String(row.street).toLowerCase().indexOf(searchStreet.toLowerCase()) > -1 &&
				String(row.zipcode).toLowerCase().indexOf(searchZipcode.toLowerCase()) > -1 &&
				String(row.contact_name).toLowerCase().indexOf(searchContact_name.toLowerCase()) > -1 &&
				String(row.contact_tel).toLowerCase().indexOf(searchContact_tel.toLowerCase()) > -1 &&
				String(row.contact_email).toLowerCase().indexOf(searchContact_email.toLowerCase()) > -1 &&
				String(checkFirm(row.id_firm)?.name).toLowerCase().indexOf(searchFirm.toLowerCase()) > -1 &&
				String(row.tms_version_s ? 'tak' : 'nie').toLowerCase().indexOf(searchTMS_version_s.toLowerCase()) > -1 &&
				String(row.is_promo_account ? 'promocyjne' : 'stałe').toLowerCase().indexOf(searchIs_promo_account.toLowerCase()) > -1 &&
				String(row.sum_cars).toLowerCase().indexOf(searchIs_car_count.toLowerCase()) > -1 &&
				String(row.trader_name).toLowerCase().indexOf(searchIs_trader_name.toLowerCase()) > -1

		})
	}

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = table.map((n) => n.id);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}
		setSelected(newSelected);
	};

	const paginate = pageNumber => setPage(pageNumber - 1);
	const isSelected = (name) => selected.indexOf(name) !== -1;

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, table.length - page * rowsPerPage);

	useEffect(() => {
		getCustomers()
		getFirm()
		getTraders()
	}, [])



	return (
		<>
			<Paper className={styles.paper}>
				<Title
					title={props.state.tabName}
					btnBox
					btnAdd
					addFunc={e => {
						setDataAddClientModal(false)
						setEdit(false)
						setOpenAddClientModal(true)
					}}
					btnDelete={selected.length > 0 ? true : "disabled"}
					deleteFunc={e => setOpenModalDelete(true)}
					btnExport
					exportFunc={printXLS}
					exportFileType={'XLS'}
				/>
			</Paper>
			<TableContainer className={styles.stickyTable}>
				<Table
					stickyHeader={true}
					className={styles.table}
					aria-labelledby="tableTitle"
					aria-label="enhanced table"
					size={"small"}
				>
					<EnhancedTableHead
						styles={styles}
						numSelected={selected.length}
						order={order}
						orderBy={orderBy}
						onSelectAllClick={handleSelectAllClick}
						onRequestSort={handleRequestSort}
						rowCount={table.length}
					/>
					<TableBody className={styles.tabBody}>
						<TableRow>
							<TableCell></TableCell>
							{setSearchName}
							{setSearchFirm}
							{setSearchContact_name}
							{setSearchContact_email}
							{setSearchContact_tel}
							{setSearchIs_trader_name}
							{setSearchIs_car_count}
							<TableCell></TableCell>
							{setSearchIs_promo_account}
							{setSearchTMS_version_s}
							<TableCell></TableCell>
						</TableRow>
						{
							stableSort(search(table), getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => {
									const isItemSelected = isSelected(row.id);
									const labelId = `enhanced-table-checkbox-${index}`;

									// console.log('row.is_promo_account',index, row.is_promo_account)

									return (
										<TableRow
											hover
											onClick={(event) => handleClick(event, row.id)}
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row.id}
											selected={isItemSelected}
											className={row.reservation ? styles.reservation : ""}
										>
											<TableCell className={styles.checkbox} >
												<Checkbox
													checked={isItemSelected}
													color="primary"
													inputProps={{ 'aria-labelledby': labelId }}
												/>
											</TableCell>
											<TableCell component="th" id={labelId} align="right" scope="row" >{row.name}</TableCell>
											<TableCell align="right">{checkFirm(row.id_firm)?.name}</TableCell>
											<TableCell align="right">{row.contact_name}</TableCell>
											<TableCell align="right">
												<div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} tooltip={row.contact_email}>
													{row.contact_email}
												</div>
											</TableCell>
											<TableCell align="right">{row.contact_tel}</TableCell>
											<TableCell align="right">
												{row.trader_name}
											</TableCell>
											<TableCell align="right">
												{row.sum_cars}
											</TableCell>
											<TableCell align="right" flexDirection='row'>
												<div className={styles.columnCelFlex}>
													{row.promo_account_expires_to}{row.is_promo_account && row.promo_users[0]?.was_extended_promo_account === false && 
														<AddClock tooltip={`Przedłuż promocje`} value={row} color={'#129865'} handleChange={e => {
															e.preventDefault()
															e.stopPropagation()
															setOpenModalPromotionalExtension(true)
															setSelectedUserID(row.promo_users[0]?.id)
														}} />
													}
												</div>	
											</TableCell>
											<TableCell align="right">
												{/* {row.promotional_permanent} */}
												<div className={styles.columnCelFlex}>
													{row.is_promo_account ? 'Promocyjne' : 'Stałe' }
													{row.is_promo_account &&
														<UnlockAccond tooltip={`Aktywuj konto na stałe`} value={row} color={'#129865'} handleChange={e => {
															e.preventDefault()
															e.stopPropagation()
															setOpenModalPromotionalPermanent(true)
															setSelectedCustomerID(row.id)}} 
														/>
													}

												</div> 
											</TableCell>
											<TableCell align="right">{row.tms_version_s ? 'tak' : 'nie'}</TableCell>
											<TableCell align="right" >
												<div className={styles.buttonBox}>
													<IcoBtn icon="Link" tooltip="Przeprowadź integracje z systemem Atlas" value={row} onClick={(e) => {
														e.preventDefault()
														e.stopPropagation()
														setDataAtlasIntegrationModal(row)
														setOpenAtlasIntegrationModal(true)
													}} />
													<IcoBtn icon="Edit" tooltip="edytuj" value={row} onClick={(e) => {
														e.preventDefault()
														e.stopPropagation()
														setDataAddClientModal(row)
														setEdit(true)
														setOpenAddClientModal(true)
													}} />
													<IcoBtn icon="X" tooltip="Usuń" className="btn-delete" value={row} onClick={(e) => {
														e.preventDefault()
														e.stopPropagation()
														setDeleteData([row.id])
														setOpenModalDelete(true)
													}} />
												</div>
											</TableCell>
										</TableRow>
									);
								})}
						{emptyRows > 0 && (
							<TableRow style={{ height: 33 * emptyRows }}>
								<TableCell colSpan={6} />
							</TableRow>
						)}
					</TableBody>
				</Table>

			</TableContainer>

			<div className={styles.tableBottom} >
				<RowsPerPage
					rowsPerPage={rowsPerPage}
					setRowsPerPage={setRowsPerPage}
					setPage={setPage}
					tableLength={table.length}
				/>
				<div>
					<Pagination
						postsPerPage={rowsPerPage}
						totalPosts={table.length}
						paginate={paginate}
						page={page}
					/>
				</div>
			</div>


			<Dialog
				open={openModalDelete}
				TransitionComponent={Transition}
				onClose={() => {
					setDeleteData([])
					setOpenModalDelete(false)
				}}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-slide-title" className={stylesMod.dialogHead}>Czy napewno chcesz usunąć firmę?			<IconButton aria-label="close" className={stylesMod.closeButton} onClick={() => {
					setDeleteData([])
					setOpenModalDelete(false)
				}}>
					<X />
				</IconButton>
				</DialogTitle >
				<DialogContent>
					{deleteData.length > 0 ?
						<DialogContentText id="alert-dialog-slide-description">
							Czy napewno chcesz usunąć firmy: {
								deleteData.map(e => {
									const delOrder = table.find(el => el.id === e)
									return <><br />{delOrder.name} </>

								})}
						</DialogContentText> :
						<DialogContentText id="alert-dialog-slide-description">
							Czy napewno chcesz usunąć firmę: {
								selected.map(e => {
									const delOrder = table.find(el => el.id === e)
									return <><br />{delOrder.name} </>
								})}
						</DialogContentText>}
				</DialogContent>
				<DialogActions>
					<Button variant="contained" size="large" color="primary" className={BTNstyles.btn} onClick={(e) => {
						setOpenModalDelete(false)
					}}>
						Anuluj
					</Button>
					<Button variant="contained" size="large" className={`${BTNstyles.btn} ${BTNstyles.err}`} onClick={() => {
						delCustomers(deleteData.length > 0 ? deleteData : selected)
						setSelected([])
						setOpenModalDelete(false)
						setDeleteData([])
					}}
						color="primary">
						Usuń <X className={BTNstyles.downBtnIco} />
					</Button>
				</DialogActions>
			</Dialog>




			<Dialog
				open={openModalPromotionalPermanent}
				TransitionComponent={Transition}
				onClose={() => {
					setOpenModalPromotionalPermanent(false)
				}}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-slide-title" className={stylesMod.dialogHead}>Przepięcie konta na stałe	<IconButton aria-label="close" className={stylesMod.closeButton} onClick={() => {
					setOpenModalPromotionalPermanent(false)
				}}>
					<X />
				</IconButton>
				</DialogTitle >
				<DialogContent>
					Czy napewno chcesz przepiąć to konto na stałe?
				</DialogContent>
				<DialogActions>
					<Button variant="contained" size="large" color="primary" className={BTNstyles.btn} onClick={(e) => {
						setOpenModalPromotionalPermanent(false)
					}}>
						Anuluj
					</Button>
					<Button variant="contained" size="large" className={`${BTNstyles.btn} ${styles.green}`} onClick={() => {
						setOpenModalPromotionalPermanent(false)
						promoAccountToNormal(selectedCustomerID)
					}}
						color="primary">
						Odblokuj konto na stałe  <Unlock style={{marginLeft: '10px'}}/>
					</Button>
				</DialogActions>
			</Dialog>


			<Dialog
				open={openModalPromotionalExtension}
				TransitionComponent={Transition}
				onClose={() => {
					setOpenModalPromotionalExtension(false)
				}}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-slide-title" className={stylesMod.dialogHead}>Przedłuż okres promocyjny <IconButton aria-label="close" className={stylesMod.closeButton} onClick={() => {
					setOpenModalPromotionalExtension(false)
				}}>
					<X />
				</IconButton>
				</DialogTitle >
				<DialogContent>
					Czy na pewno chcesz wydłużyć okres promocyjny o 30 dni?
				</DialogContent>
				<DialogActions>
					<Button variant="contained" size="large" color="primary" className={BTNstyles.btn} onClick={(e) => {
						setOpenModalPromotionalExtension(false)
					}}>
						Anuluj
					</Button>
					<Button variant="contained" size="large" className={`${BTNstyles.btn} ${styles.green}`} onClick={() => {
						setOpenModalPromotionalExtension(false)
						extendPromoAccount(selectedUserID)
					}}
						color="primary">
						Przedłuż o 30 dni <Clock style={{marginLeft: '10px'}}/>
					</Button>
				</DialogActions>
			</Dialog>


			<AddClient
				open={openAddClientModal}
				setOpen={setOpenAddClientModal}
				data={dataAddClientModal}
				edit={edit}
				setOpenSnack={setOpenDialog}
				setSnackContent={setDialogContent}
				setSnackColor={setDialogColor}
				user={props.user}
				firms={firms}
				refresh={getCustomers}
				checkFirm={checkFirm}
				traders={traders}
				setTraders={setTraders}
			/>

			<AtlasIntegrationModal
				setOpenSnack={setOpenDialog}
				setSnackContent={setDialogContent}
				setSnackColor={setDialogColor}
				open={openAtlasIntegrationModal}
				setOpen={setOpenAtlasIntegrationModal}
				data={dataAtlasIntegrationModal}
				refresh={getCustomers}
				user={props.user}
			/>

			<Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
				<Alert onClose={handleClose} severity={dialogColor}>
					{dialogContent}
				</Alert>
			</Snackbar>
		</>
	)


}
