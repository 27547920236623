import React, { useState, useEffect } from 'react'
import { Grid, Dialog, DialogContent, DialogTitle, Slide, DialogActions, Button, DialogContentText } from '@material-ui/core';
import Input from './input'
import { DBurl } from '../appConfig'
import { X, ArrowRight } from 'react-feather'
import BTNstyles from '../styles/button.module.css'
import stylesMod from '../styles/newOrEdit.module.css'
import { IconButton } from '@material-ui/core';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export function AddVat(props) {
  const [rate, setRate] = useState(props.data?.rate || "")

  useEffect(() => {
    setRate(props.data?.rate)
  }, [props.data?.rate])



  const setVat = async (type) => {

    let data = type === "insert" ? {
      rate: rate
    } : {
      rate: rate,
      id: props.data?.id
    }

    const response = await fetch(`${DBurl}/setVatRates/`, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.token
      },
      body: JSON.stringify({
        mode: type,
        data: data
      })
    })
    const json = await response.json()
    if (json.success) {
      props.refresh()

      props.setSnack(true)
      props.setSnackContent("Stawka zapisana")
      props.setSnackColor("success")
      props.setOpen(false)
    } else {
      props.setSnack(true)
      props.setSnackContent("Błąd przy tworzeniu/edycji stawki VAT")
      props.setSnackColor("error")

    }
  }

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      onClose={() => {
        props.setOpen(false)
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title" className={stylesMod.dialogHead}>Wpisz stawkę VAT:			<IconButton aria-label="close" className={stylesMod.closeButton} onClick={() => {
        props.setOpen(false)
      }}>
        <X />
      </IconButton>
      </DialogTitle >
      <DialogContent>
        <Input
          value={rate}
          handleChange={e => {
            setRate(e.target.value)
          }}
          name="rate"
          type="number"
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="large"
          disabled={!rate}
          onClick={e => setVat(props.type)}>
          Zapisz <ArrowRight className={`${BTNstyles.downBtnIco}`} />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export function RemoveVat(props) {
  const deleteFunc = async (id) => {
    const response = await fetch(`${DBurl}/setVatRates/`, {
      method: "post",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.token
      },
      body: JSON.stringify({
        mode: "delete",
        data: {
          ids: [id]
        }
      })
    }).then(res => res.json())
      .then(

        data => {
          if (data.success) {
            props.setSnack(true)
            props.setSnackContent("Stawka usunięta")
            props.setSnackColor("success")
            props.refresh()
            props.setOpen(false)
          } else {
            props.setSnack(true)
            props.setSnackContent("Błąd podczas usuwania")
            props.setSnackColor("error")
          }
        }

      )
  }
  return (
    <>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        onClose={() => {
          props.setOpen(false)
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" className={stylesMod.dialogHead}>Czy napewno chcesz usunąć stawki VAT 			<IconButton aria-label="close" className={stylesMod.closeButton} onClick={() => {
          props.setOpen(false)
        }}>
          <X />
        </IconButton>
        </DialogTitle >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Czy napewno chcesz usunąć stawkę VAT:  <br />
            <p>{props.data?.rate} ?</p>


          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" size="large" color="primary" className={BTNstyles.btn} onClick={(e) => {
            props.setOpen(false)
          }}>
            Anuluj
              </Button>
          <Button variant="contained" size="large" className={`${BTNstyles.btn} ${BTNstyles.err}`} onClick={(e) => {
            deleteFunc(props.data?.id)
            props.setOpen(false)

          }}
            color="primary">
            Usuń <X className={BTNstyles.downBtnIco} />
          </Button>
        </DialogActions>
      </Dialog>

    </>
  )
}