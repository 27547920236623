import React, { useState, useEffect } from 'react';
import styles from './tachoIcons.module.css'

// <TachoIcon icon="IcoDrive"/>{row.order.drivingTime}
// <TachoIcon icon="IcoStop"/>{row.order.stopTime}
// <TachoIcon icon="IcoAvailability"/>{row.order.stopEngineOnTime}
// <TachoIcon icon="IcoWork"/>{row.order.workTime}
// }


export default function TachoIcons(props) {
	const [ico, setIco] = useState("")

	useEffect(() => {
		switch (props.icon) {
			case "IcoDrive":
				setIco((
					<svg viewBox="0 0 28.35 28.35">
						<circle className={styles.b} cx="14.17" cy="14.41" r="5.12" />
						<circle className={styles.a} cx="14.17" cy="14.41" r="9.26" />
					</svg>
				))
				break;
			case "IcoAvailability":
				setIco((
					<svg viewBox="0 0 28.35 28.35">
						<rect className={styles.a} x="4.92" y="4.92" width="18.51" height="18.51" />
						<line className={styles.a} x1="23.43" y1="4.92" x2="4.92" y2="23.43" />
					</svg>
				))
				break;
			case "IcoStop":
				setIco((
					<svg viewBox="0 0 28.35 28.35">
						<line className={styles.c} x1="6.45" y1="4.92" x2="6.45" y2="23.43" />
						<line className={styles.c} x1="6.45" y1="14.17" x2="21.9" y2="14.17" />
						<line className={styles.c} x1="21.9" y1="23.43" x2="21.9" y2="14.17" />
					</svg>
				))
				break;
			case "IcoWork":
				setIco((
					<svg viewBox="0 0 28.35 28.35">
						<line className={styles.c} x1="5.7" y1="23.43" x2="20.56" y2="8.57" />
						<line className={styles.c} x1="7.79" y1="8.57" x2="22.65" y2="23.43" />
						<rect className={styles.b} x="6.56" y="4.84" width="3.15" height="8.17" transform="translate(8.69 -3.14) rotate(45)" />
						<rect className={styles.b} x="18.63" y="4.84" width="3.15" height="8.17" transform="translate(40.8 0.95) rotate(135)" />
					</svg>
				))
				break;
			default:
				setIco((
					<svg viewBox="0 0 28.35 28.35"><rect className={styles.a} x="4.92" y="4.92" width="18.51" height="18.51" /><line className={styles.a} x1="23.43" y1="4.92" x2="4.92" y2="23.43" /></svg>
				))
		}

	},[props.icon])

	return (
		<div className={styles.parent}>
			{ico}
		</div>
	)
}