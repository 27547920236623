import React from 'react'
import Button from '@material-ui/core/Button';

export default function ItemsOnPage(props) {
	return (
		<div>
			<Button size="small" variant="outlined" value="20" onClick={() => {
				props.setRowsPerPage(20)
				props.setPage(0)
			}}>20</Button>
			{props.table.length > 20 ? <Button size="small" variant="outlined" value="50" onClick={() => {
				props.setRowsPerPage(props.table.length > 50 ? 50 : props.table.length)
				props.setPage(0)
			}}>{props.table.length > 50 ? 50 : props.table.length}</Button> : null}
			{props.table.length > 50 ? <Button size="small" variant="outlined" value="100" onClick={() => {
				props.setRowsPerPage(props.table.length > 100 ? 100 : props.table.length)
				props.setPage(0)
			}}>{props.table.length > 100 ? 100 : props.table.length}</Button> : null}
			{props.table.length > 100 ? <Button size="small" variant="outlined" value="200" onClick={() => {
				props.setRowsPerPage(props.table.length > 200 ? 200 : props.table.length)
				props.setPage(0)
			}}>{props.table.length > 200 ? 200 : props.table.length}</Button> : null}
			{props.table.length > 200 ? <Button size="small" variant="outlined" value="500" onClick={() => {
				props.setRowsPerPage(props.table.length > 500 ? 500 : props.table.length)
				props.setPage(0)
			}}>{props.table.length > 500 ? 500 : props.table.length}</Button> : null}
		</div>
	)
}
