import React from "react";
import { Modal, Box, Fade } from "@material-ui/core";
import { TmsDeleteModalState } from "types";
import { Trash2 } from "react-feather";

import "./styles.sass";

type Props = {
  deleteModal: TmsDeleteModalState;
  setDeleteModal: ({ isOpen }: { isOpen: boolean }) => void;
  deleteFunction: any;
};

const TmsDeleteModal = ({
  deleteModal,
  setDeleteModal,
  deleteFunction,
}: Props) => {
  const handleClose = () => {
    setDeleteModal({ isOpen: false });
  };

  const handleDelete = () => {
    deleteFunction();
    handleClose();
  };

  return (
    <Modal open={deleteModal.isOpen} onClose={handleClose}>
      <Fade in={deleteModal.isOpen}>
        <Box className="delete-modal">
          <div className="delete-modal__title">
            <label>
              Na pewno chcesz usunąć{" "}
              <span className="delete-modal__name">{deleteModal.name}</span>?
            </label>
          </div>
          <div className="delete-modal__buttons">
            <button className="delete-modal__button" onClick={handleClose}>
              Anuluj
            </button>
            <button
              onClick={handleDelete}
              className="delete-modal__button --delete"
            >
              Usuń <Trash2 />
            </button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default TmsDeleteModal;
