import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import stylesMod from "../../styles/newOrEdit.module.css";
import Input from "../../components/input";
import Select from "react-select";
import { DBurl } from "../../appConfig";
import BTNstyles from "../../styles/button.module.css";
import { Add } from "@material-ui/icons";
import { X } from "react-feather";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddCompany(props) {
  const [error, setError] = useState(false);

  const [name, setName] = useState(props.data?.name || "");
  const [street, setStreet] = useState(props.data?.street || "");
  const [street_no, setStreet_no] = useState(props.data?.street_no || "");
  const [city, setCity] = useState(props.data?.city);
  const [zipcode, setZipCode] = useState(props.data?.zipcode || "");

  const [contact_name, setContact_name] = useState(props.data?.contact_name);
  const [contact_email, setContact_email] = useState(props.data?.contact_email);
  const [contact_tel, setContact_tel] = useState(props.data?.contact_tel);
  const [EKDEmail, setEKDEmail] = useState(props.data?.ekd_email);

  const [emailUsername, setEmailUsername] = useState("");
  const [emailPassword, setEmailPassword] = useState();
  const [emailPort, setEmailPort] = useState();
  const [emailHost, setEmailHost] = useState();
  const [emailSender, setEmailSender] = useState();
  const getEmailConfig = async () => {
    const response = await fetch(
      `${DBurl}/getEmailInboxFirm?id=${props.data.id}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      setEmailUsername(json.data.mail_username);
      setEmailPassword(json.data.mail_password);
      setEmailHost(json.data.mail_smtp_host);
      setEmailPort(json.data.mail_smtp_port);
      setEmailSender(json.data.mail_sender);
    }
  };

  const changeEmailSettings = async (id) => {
    const response = await fetch(`${DBurl}/setEmailInboxFirm`, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: props.edit ? "update" : "insert",
        data: {
          id: props.edit ? props.data.id : id,
          mail_smtp_host: emailHost,
          mail_username: emailUsername,
          mail_password: emailPassword,
          mail_smtp_port: emailPort,
          mail_sender: emailSender,
        },
      }),
    });
    const json = await response.json();

    if (json.success) {
      props.setOpenSnack(true);
      props.setSnackColor("success");
      props.setSnackContent(props.edit ? "Zapisano zmiany" : "Firma dodana");
      props.refresh();
      props.setOpen(false);
      clear();
      return json;
    }
  };

  useEffect(() => {
    props.data.id && getEmailConfig();
  }, [
    props.data.id !== undefined,
    props.data.id,
    props.open === true && props.edit === true,
  ]);

  useEffect(() => {
    setName("");
    setStreet("");
    setStreet_no("");
    setCity("");
    setZipCode("");
    setContact_name("");
    setContact_email("");
    setContact_tel("");
    setEKDEmail("");
    setError(false);
    setEmailUsername("");
    setEmailPassword("");
    setEmailHost("");
    setEmailPort(0);
    setEmailSender("");
  }, [props.open === false]);

  useEffect(() => {
    if (props.data?.name) {
      setName(props.data.name);
      setStreet(props.data.street);
      setStreet_no(props.data.street_no);
      setCity(props.data.city);
      setZipCode(props.data.zipcode);
      setContact_name(props.data.contact_name);
      setContact_email(props.data.contact_email);
      setContact_tel(props.data.contact_tel);
      setEKDEmail(props.data.ekd_email);
    }
  }, [props.data.name]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // setOpenDialog(false)
  };

  const clear = () => {
    setName("");
    setStreet("");
    setStreet_no("");
    setCity("");
    setZipCode("");
    setContact_name("");
    setContact_email("");
    setContact_tel("");
    setEKDEmail("");
    setError(false);
    setEmailUsername("");
    setEmailPassword("");
    setEmailHost("");
    setEmailPort(0);
    setEmailSender("");
  };

  return (
    <Dialog
      maxWidth="lg"
      open={props.open}
      // className={stylesMod.noOverflowGrand}
      TransitionComponent={Transition}
      onClose={() => {
        props.setOpen(false);
        clear();
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        className={stylesMod.dialogHead}
      >
        Dodaj Firmę{" "}
        <IconButton
          aria-label="close"
          className={stylesMod.closeButton}
          onClick={() => {
            props.setOpen(false);
            clear();
          }}
        >
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent className={stylesMod.noOverflow}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className={`${stylesMod.inp}`}>
              <h6>Dane firmy</h6>
              <label>Nazwa</label>
              <Input
                value={name}
                error={!name && error}
                handleChange={(e) => {
                  setName(e.target.value);
                }}
                name="name"
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={`${stylesMod.inp}`}>
              <label>Ulica</label>
              <Input
                value={street}
                error={!street && error}
                handleChange={(e) => {
                  setStreet(e.target.value);
                }}
                name="street"
              />
            </div>
          </Grid>
          <Grid item xs={1}>
            <div className={`${stylesMod.inp}`}>
              <label>Numer</label>
              <Input
                value={street_no}
                error={!street_no && error}
                handleChange={(e) => {
                  setStreet_no(e.target.value);
                }}
                name="street_no"
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={`${stylesMod.inp}`}>
              <label>Miasto</label>
              <Input
                value={city}
                error={!city && error}
                handleChange={(e) => {
                  setCity(e.target.value);
                }}
                name="city"
              />
            </div>
          </Grid>

          <Grid item xs={2}>
            <div className={`${stylesMod.inp}`}>
              <label>Kod pocztowy</label>
              <Input
                value={zipcode}
                error={!zipcode && error}
                handleChange={(e) => {
                  setZipCode(e.target.value);
                }}
                name="zipcode"
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <div className={`${stylesMod.inp}`}>
              <h6>Dane osoby do kontaktu</h6>
              <label>Imię i nazwisko</label>
              <Input
                value={contact_name}
                error={!contact_name && error}
                handleChange={(e) => {
                  setContact_name(e.target.value);
                }}
                name="contact_name"
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={`${stylesMod.inp}`}>
              <label>Email</label>
              <Input
                value={contact_email}
                error={!contact_email && error}
                handleChange={(e) => {
                  setContact_email(e.target.value);
                }}
                name="contact_email"
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={`${stylesMod.inp}`}>
              <label>nr telefonu</label>
              <Input
                value={contact_tel}
                error={!contact_tel && error}
                handleChange={(e) => {
                  setContact_tel(e.target.value);
                }}
                name="contact_tel"
              />
            </div>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <div className={`${stylesMod.inp}`}>
              <h6>Konfiguracja skrzynki email</h6>
              <label>Nazwa użytkownika</label>
              <Input
                value={emailUsername}
                // error={!EKDEmail && error}
                handleChange={(e) => {
                  setEmailUsername(e.target.value);
                }}
                name="emailUsername"
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={`${stylesMod.inp}`}>
              <label>Hasło</label>
              <Input
                value={emailPassword}
                // error={!EKDEmail && error}
                handleChange={(e) => {
                  setEmailPassword(e.target.value);
                }}
                name="emailPassword"
                type="password"
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={`${stylesMod.inp}`}>
              <label>Host SMTP</label>
              <Input
                value={emailHost}
                error={!EKDEmail && error}
                handleChange={(e) => {
                  setEmailHost(e.target.value);
                }}
                name="smtpHost"
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={`${stylesMod.inp}`}>
              <label>Port SMTP</label>
              <Input
                value={emailPort}
                // error={!EKDEmail && error}
                handleChange={(e) => {
                  setEmailPort(e.target.value);
                }}
                name="smtpPort"
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={`${stylesMod.inp}`}>
              <label>Adres e-mail nadawcy</label>
              <Input
                value={emailSender}
                // error={!EKDEmail && error}
                handleChange={(e) => {
                  setEmailSender(e.target.value);
                }}
                name="emailSender"
              />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="large"
          color="primary"
          className={`${BTNstyles.btn} ${BTNstyles.err}`}
          onClick={() => {
            clear();
            props.setOpen(false);
          }}
        >
          Anuluj
        </Button>
        <Button
          variant="contained"
          size="large"
          className={`${BTNstyles.btn} ${BTNstyles.grn}`}
          onClick={(e) => {
            if (
              !!name &&
              !!street &&
              !!city &&
              !!zipcode &&
              !!street_no &&
              !!contact_name &&
              !!contact_email &&
              !!contact_tel
            ) {
              const fetchUrl = `${DBurl}/setFirm`;
              const data = {
                id: props.edit ? props.data.id : null,
                name: name,
                street: street,
                city: city,
                zipcode: zipcode,
                street_no: street_no,
                contact_name: contact_name,
                contact_email: contact_email,
                contact_tel: contact_tel,
              };
              fetch(fetchUrl, {
                method: "post",
                credentials: "include",
                headers: {
                  "Content-Type": "application/json",
                  "X-CSRF-Token": props.user.csrf_token,
                },
                body: JSON.stringify({
                  mode: props.edit ? "update" : "insert",
                  data: data,
                }),
              })
                .then((res) => res.json())
                .then((data) => {
                  if (data.success) {
                    changeEmailSettings(data.data.new_id);
                  } else if (data.response === "401") {
                    props.setOpenSnack(true);
                    props.setSnackColor("error");
                    props.setSnackContent(
                      "Brak uprawnień. Skontatkuj się z administratorem"
                    );
                  } else {
                    props.setOpenSnack(true);
                    props.setSnackColor("error");
                    props.setSnackContent(`Błąd: ${data.responseDescription}`);
                  }
                });
            } else {
              props.setSnackContent("Uzupełnij wymagane pola");
              props.setSnackColor("error");
              props.setOpenSnack(true);
              setError(true);
            }
          }}
          color="primary"
        >
          {props.edit ? "Zapisz" : "Dodaj"}{" "}
          <Add className={BTNstyles.downBtnIco} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
