import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { X } from "react-feather";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
  Slide,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";

import RowsPerPage from "components/RowsPerPage";
import Pagination from "components/pagination";
import Title from "components/title";
import Input from "components/input";
import IcoBtn from "components/buttons/icoBtn";
import TmsLoading from "components/TmsLoading";
import CostAdd from "./CostsAdd";

import {
  getCostsFromOrders,
  deleteCostsFromOrders,
  getOrders,
  getOrderDriverActivities,
} from "api/endpoints";

import styles from "../table.module.css";
import stylesMod from "styles/newOrEdit.module.css";
import BTNstyles from "styles/button.module.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (array?.length > 0) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
}

const headCells = [
  {
    id: "order_no",
    numeric: true,
    disablePadding: false,
    label: "nr Zlecenia",
  },
  { id: "ts", numeric: true, disablePadding: false, label: "Data" },
  { id: "contractor", numeric: true, disablePadding: false, label: "Klient" },
  { id: "type", numeric: true, disablePadding: false, label: "Typ" },
  {
    id: "cost_from",
    numeric: true,
    disablePadding: false,
    label: "Wystawiony przez",
  },
  { id: "cost_pln", numeric: true, disablePadding: false, label: "Koszt" },
  { id: "description", numeric: true, disablePadding: false, label: "Opis" },
];

function EnhancedTableHead(props) {
  const {
    styles,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={styles.tabHead}>
      <TableRow>
        <TableCell className={styles.checkbox}>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all id" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ width: `${headCell.width || "auto"}` }}
          >
            {!headCell.noSort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={styles.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

function useInput({ type, size }) {
  const [value, setValue] = useState("");
  const input = (
    <TableCell className={styles.inputCell}>
      <Input
        value={value}
        handleChange={(e) => {
          setValue(e.target.value);
        }}
        type={type}
        search={true}
        className={size}
      />
    </TableCell>
  );
  return [value, input];
}

export default function CostsList(props) {
  const [table, setTable] = useState([]);
  const [ordersData, setOrdersData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("short");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [searchOrder, setSearchOrder] = useInput({ type: "text" });
  const [searchClient, setSearchClient] = useInput({ type: "text" });
  const [searchType, setSearchType] = useInput({ type: "text" });
  const [searchDescription, setSearchDescription] = useInput({ type: "text" });
  const [deleteData, setDeleteData] = useState([]);
  const [noOrderTable, setNoOrderTable] = useState([]);
  const [dataAddModal, setDataAddModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const { refetch: refetchOrders } = useQuery("getOrders", getOrders, {
    onSuccess: (data) => {
      setOrdersData(data);
    },
    onError: (error) => {
      props.setDialog({
        isOpen: true,
        type: "error",
        content: `Nie udało się pobrać zleceń / ${error.message}`,
      });
    },
  });

  const { refetch: refetchCostsOrders, isLoading: isCostsLoading } = useQuery(
    "getCostsFromOrders",
    getCostsFromOrders,
    {
      onSuccess: (data) => {
        setTable(data);
      },
      onError: (error) => {
        props.setDialog({
          isOpen: true,
          type: "error",
          content: `Nie udało się pobrać kosztów / ${error.message}`,
        });
      },
    }
  );

  const { isLoading: isCostsNoOrderLoading } = useQuery(
    "getOrderDriverActivities",
    () =>
      getOrderDriverActivities({
        without_order: true,
        with_costs: true,
        with_refuelings: false,
        with_statuses: false,
      }),
    {
      onSuccess: (data) => {
        setNoOrderTable(
          data
            .filter((rec) => rec.costs[0]?.id !== undefined)
            .map((rec) => rec.costs)
            .flat()
            .map((rec) => {
              return { ...rec, cost_from: "mobile" };
            })
        );
      },
      onError: (error) => {
        props.setDialog({
          isOpen: true,
          type: "error",
          content: `Nie udało się pobrać zleceń / ${error.message}`,
        });
      },
    }
  );

  const { mutate: delOrders } = useMutation(
    "deleteCostsFromOrders",
    deleteCostsFromOrders,
    {
      onSuccess: () => {
        props.setDialog({
          isOpen: true,
          type: "success",
          content: "Koszt został usunięty",
        });
        refetchOrders();
        refetchCostsOrders();
      },
      onError: (error) => {
        props.setDialog({
          isOpen: true,
          type: "error",
          content: `Nie udało się usunąć kosztu / ${error.message}`,
        });
      },
    }
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const search = (tbl) => {
    if (tbl?.length > 0) {
      return tbl.filter((row) => {
        return (
          row.order_no?.toString().toLowerCase().indexOf(searchOrder) > -1 &&
          ordersData
            .find((e) => e.id === row.id_order)
            ?.contractor?.shortname?.toString()
            .toLowerCase()
            .indexOf(searchClient) > -1 &&
          row.type?.toString().toLowerCase().indexOf(searchType) > -1 &&
          row.description?.toString().toLowerCase().indexOf(searchDescription) >
            -1
        );
      });
    }
  };

  const handleSelectAllClick = (event) => {
    if (table?.length > 0) {
      if (event.target.checked) {
        const newSelecteds = table.map((n) => n.id);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    } else {
      props.setDialog({
        isOpen: true,
        type: "warning",
        content: "Brak danych do zaznaczenia",
      });
    }
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const paginate = (pageNumber) => setPage(pageNumber - 1);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const checkContractorName = (id) => {
    if (ordersData.length > 0) {
      return ordersData.find((e) => e.id === id)?.contractor?.shortname;
    }
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, table?.length - page * rowsPerPage);

  const isLoading = isCostsLoading || isCostsNoOrderLoading;

  return (
    <>
      <Paper className={styles.paper}>
        <Title
          title={props.state.tabName}
          btnBox
          btnAdd
          addFunc={(e) => {
            setDataAddModal(false);
            setEdit(false);
            setOpenAddModal(true);
          }}
          btnDelete={selected?.length > 0 ? true : "disabled"}
          deleteFunc={(e) => setOpenModalDelete(true)}
        />
      </Paper>
      <TableContainer className={styles.stickyTable}>
        <Table
          stickyHeader={true}
          className={styles.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
          size={"small"}
        >
          <EnhancedTableHead
            styles={styles}
            numSelected={selected?.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={table?.length}
          />
          <TableBody className={styles.tabBody}>
            <TableRow>
              <TableCell></TableCell>
              {setSearchOrder}
              <TableCell></TableCell>
              {setSearchClient}
              {setSearchType}
              <TableCell></TableCell>
              <TableCell></TableCell>
              {setSearchDescription}
              <TableCell></TableCell>
            </TableRow>

            {stableSort(
              table?.concat(noOrderTable),
              getComparator(order, orderBy)
            )
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    className={row.reservation ? styles.reservation : ""}
                  >
                    <TableCell className={styles.checkbox}>
                      <Checkbox
                        checked={isItemSelected}
                        color="primary"
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </TableCell>

                    <TableCell
                      component="th"
                      id={labelId}
                      align="right"
                      scope="row"
                    >
                      {row.order_no ? row.order_no : "Brak podjętego zlecenia"}
                    </TableCell>
                    <TableCell align="right">
                      {row.ts_cost.slice(0, 19)}
                    </TableCell>
                    <TableCell align="right">
                      {row.id_order
                        ? checkContractorName(row.id_order)
                        : "Brak podjętego zlecenia"}{" "}
                    </TableCell>
                    <TableCell align="right">{row.type}</TableCell>
                    <TableCell align="right">{row.cost_from}</TableCell>
                    <TableCell align="right">{`${row.cost} ${row.currency}`}</TableCell>
                    <TableCell align="right">{row.description}</TableCell>
                    <TableCell align="right">
                      <div className={styles.buttonBox}>
                        <IcoBtn
                          icon="X"
                          tooltip="Usuń"
                          className="btn-delete"
                          value={row}
                          onClick={(e) => {
                            setDeleteData([row.id]);
                            setOpenModalDelete(true);
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 33 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <div className={styles.tableBottom}>
        <RowsPerPage
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          tableLength={table?.length}
        />
        <div>
          <Pagination
            postsPerPage={rowsPerPage}
            totalPosts={table?.length}
            paginate={paginate}
            page={page}
          />
        </div>
      </div>

      <Dialog
        open={openModalDelete}
        TransitionComponent={Transition}
        onClose={() => {
          setDeleteData([]);
          setOpenModalDelete(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Czy napewno chcesz usunąć ?{" "}
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              setDeleteData([]);
              setOpenModalDelete(false);
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {deleteData.length > 0 ? (
            <DialogContentText id="alert-dialog-slide-description">
              Czy napewno chcesz usunąć zlecenia:{" "}
              {deleteData.map((e) => {
                const tempOrder = table.find((el) => el.id === e);
                return (
                  <>
                    <br />
                    Zlecenie nr {tempOrder ? tempOrder.order_no : null}
                  </>
                );
              })}
            </DialogContentText>
          ) : (
            <DialogContentText id="alert-dialog-slide-description">
              Czy napewno chcesz usunąć zlecenie:{" "}
              {selected.map((e) => {
                const tempOrder = table.find((el) => el.id === e);
                return (
                  <>
                    <br />
                    Zlecenie nr {tempOrder ? tempOrder.order_no : null}
                  </>
                );
              })}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={BTNstyles.btn}
            onClick={(e) => {
              setOpenModalDelete(false);
            }}
          >
            Anuluj
          </Button>
          <Button
            variant="contained"
            size="large"
            className={`${BTNstyles.btn} ${BTNstyles.err}`}
            onClick={() => {
              delOrders(deleteData.length > 0 ? deleteData : selected);
              setSelected([]);
              setOpenModalDelete(false);
              setDeleteData([]);
            }}
            color="primary"
          >
            Usuń <X className={BTNstyles.downBtnIco} />
          </Button>
        </DialogActions>
      </Dialog>
      {openAddModal && (
        <CostAdd
          open={openAddModal}
          setOpen={setOpenAddModal}
          data={dataAddModal}
          orderOptions={ordersData.map((e) => {
            return { label: e.order_no, value: e.id, ...e };
          })}
          refresh={refetchCostsOrders}
          edit={edit}
          user={props.user}
          setDialog={props.setDialog}
        />
      )}
      {isLoading && <TmsLoading status="Pobieranie kosztów" />}
    </>
  );
}
