import React, {useState} from "react"
import IcoBtn from 'components/buttons/icoBtn'
import stylesMod from "../../styles/newOrEdit.module.css";

const NewOrderInput = ({file, handleChange, index}) => {
    const [isInput, setIsInput] = useState(false)
    const handleValue = (file) => {
        if (file.user_filename) return file.user_filename
        if (!file.user_filename && file.col_name) return file.col_name
        else return file.name
    }
    return (
        <div className={stylesMod.newOrderInputContainer}>
            {!isInput && <div>{handleValue(file)}</div>}
            {isInput && <input className={stylesMod.newOrderInput} value={handleValue(file)} onChange={e => handleChange(e.target.value, index)}/> }
                <IcoBtn className={stylesMod.editIco} icon="Edit" tooltip={isInput ? "Zapisz" : "Zmień nazwę"} value={file} onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setIsInput(prev => !prev)
                }}
                />
        </div>
    )
}

export default NewOrderInput;