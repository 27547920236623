import React, { useEffect, useState } from "react";
import Title from "components/title";
import stylesMod from "styles/newOrEdit.module.css";
import BTNstyles from "styles/button.module.css";
import moment from "moment";
import { X, Download, Upload, MapPin, Map } from "react-feather";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Tooltip from "@material-ui/core/Tooltip";
import Trace from "./Trace";
import ShowPointOnMap from "../ShowPointOnMap";
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core";
import { reorder } from "../newOrder.helpers";
import TmsDialog from "components/TmsDialog";
import SelectFavoriteRoutes from "./FavouriteRoutes/SelectFavoriteRoutes";
import { parseAndUpdateCargo } from "../newOrder.functions";

const Locations = ({
  values,
  updateValue,
  user,
  addTab,
  user_s,
  checkError,
  setError,
  setOpenModalShowRoute,
  setOpenModalProfilQuestion,
  setOpenAddModalProfileData,
  clearHooksState,
  isShowRequiredFields,
  toCostValues,
  saveTab,
  warehousesOptions,
  error,
  setOpenModalNewWarehouse,
  setMainRouteChange,
  checkTraceError,
  editFavouritesRoutes,
  isPreview,
  previewComponent,
}) => {
  const [dialog, setDialog] = useState({ isOpen: false });
  const [updateItem, setUpdateItem] = useState("");
  const [traceIndex, setTraceIndex] = useState(null);
  const [traceData, setTraceData] = useState(null);
  const [openModalShowPoint, setOpenModalShowPoint] = useState(false);
  const [newTraceAddress, setNewTraceAddress] = useState(false);

  const getMinDate = (index) => {
    for (let i = index - 1; i >= 0; i--) {
      const currentDate = values.trace[i].date;

      if (
        currentDate !== null &&
        currentDate !== undefined &&
        currentDate !== ""
      ) {
        return new Date(currentDate);
      }
    }

    return new Date();
  };

  const getMaxDate = (index) => {
    for (let i = index + 1; i <= values.trace.length; i++) {
      const currentDate = values.trace[i]?.date;

      if (
        currentDate !== null &&
        currentDate !== undefined &&
        currentDate !== ""
      ) {
        return new Date(currentDate);
      }
    }

    return null;
  };

  const getMinTime = (newDate, index) => {
    if (!newDate) {
      return new Date();
    }

    const newDateDay = moment(newDate).format("yyyy-MM-DD");
    const minDateDay = moment(getMinDate(index)).format("yyyy-MM-DD");

    if (newDateDay === minDateDay) {
      return moment(getMinDate(index)).add(10, "m").toDate();
    }

    return new Date(`${newDateDay}T00:00`);
  };

  const getMaxTime = (newDate, index) => {
    const newDateDay = moment(newDate).format("yyyy-MM-DD");
    const maxDateDay = moment(getMaxDate(index)).format("yyyy-MM-DD");

    if (newDateDay === maxDateDay) {
      return moment(getMaxDate(index)).subtract(10, "m").toDate();
    }

    return new Date(`${newDateDay}T23:50`);
  };

  useEffect(() => {
    let lastItemWithDriverStatus;

    for (let i = 0; i <= values.trace.length; i++) {
      if (values.trace[i]?.isDriverStatus) {
        lastItemWithDriverStatus = i;
      }
    }

    if (lastItemWithDriverStatus) {
      const newTrace = values.trace.map((trace, index) => {
        if (index <= lastItemWithDriverStatus) {
          if (trace.type !== "loading" && trace.type !== "unloading") {
            return { ...trace, isBlocked: true };
          }
        }
        return trace;
      });

      updateValue("trace", newTrace);
    }
  }, []);

  const addTraceCargo = (index) => {
    const newCargo = {
      id: values.trace[index].cargo.length + 1,
      name: "",
      quantity: "",
      unit: "",
      palletExchange: false,
      ldmSize: "",
      details: "",
      ADR: false,
      ADRClass: "",
      NumRef: "",
    };

    updateValue(`trace[${index}.cargo]`, [
      ...values.trace[index].cargo,
      newCargo,
    ]);
  };

  const addTrace = (type) => {
    setMainRouteChange(true);

    const ids = values.trace.map((e) => {
      return Number(e.id.split("trace")[1]);
    });

    const maxId = ids.reduce(function (
      previousLargestNumber,
      currentLargestNumber
    ) {
      return currentLargestNumber > previousLargestNumber
        ? currentLargestNumber
        : previousLargestNumber;
    },
    0);

    if (type === "loading" || type === "unloading") {
      const traceTemp = {
        id: `trace${maxId + 1}`,
        type: type,
        reference_no: "ref_1",
        date: "",
        time: "",
        comment: "",
        cargo: [
          {
            name: "",
            quantity: "",
            unit: "",
            palletExchange: false,
            ldmSize: "",
            details: "",
            ADR: false,
            ADRClass: "",
            NumRef: "",
          },
        ],
        warehouse: {},
      };

      updateValue("trace", [...values.trace, traceTemp]);
    } else {
      const traceTemp = {
        id: `trace${maxId + 1}`,
        type: "stop",
        subtype: "point",
        date: "",
        time: "",
        comment: "",
        place: "",
      };

      updateValue("trace", [...values.trace, traceTemp]);
    }
  };

  const handleTraceNew = (e, i, name) => {
    const traceTemp = [...values.trace];
    traceTemp[i][name] = e;
    updateValue("trace", traceTemp);
  };

  const handleTrace = (e, i, c, check, address) => {
    const traceTemp = [...values.trace];
    if (address) {
      traceTemp[i].address = e;
    } else if (check) {
      if (c || c === 0) {
        traceTemp[i].cargo[c][e.target.name] = e.target.checked;
      } else {
        traceTemp[i][e.target.name] = e.target.checked;
      }
    } else if (c || c === 0) {
      traceTemp[i].cargo[c][e.target.name] = e.target.value;
    } else {
      traceTemp[i][e.target.name] = e.target.value;
    }

    updateValue("trace", traceTemp);
  };

  useEffect(() => {
    if (values?.trace?.length > 0 && values?.cargo?.length > 0) {
      const newCargo = parseAndUpdateCargo(values?.trace, values?.cargo);

      updateValue("cargo", newCargo);
    }
  }, [values?.trace]);

  const removeTraceCargo = (traceIndex, cargoId, traceId, cargoIndex) => {
    updateValue(
      `trace[${traceIndex}].cargo`,
      values.trace[traceIndex].cargo.filter(
        (item, index) => index !== cargoIndex
      )
    );
  };

  const removeTrace = async (traceIndex) => {
    const trace = values?.trace[traceIndex];

    updateValue(
      "trace",
      values.trace.filter((_, index) => index !== traceIndex)
    );
  };

  const moveTrace = (i, dir) => {
    if (
      (i !== 0 || dir !== "up") &&
      (i !== values.trace.length - 1 || dir !== "down")
    ) {
      const traceTemp = [...values.trace];
      let moveElement = traceTemp.splice(i, 1)[0];
      if (dir === "up") {
        traceTemp.splice(i - 1, 0, moveElement);

        let lastItemWithDriverStatus;

        for (let i = 0; i <= values.trace.length; i++) {
          if (values.trace[i]?.isDriverStatus) {
            lastItemWithDriverStatus = i;
          }
        }

        if (i - 1 <= lastItemWithDriverStatus) {
          setDialog({
            isOpen: true,
            type: "error",
            content:
              "Nie możesz umieścić punktu trasy przed zrealizowanymi punktami",
          });
          return;
        }
      }

      if (dir === "down") {
        traceTemp.splice(i + 1, 0, moveElement);
      }

      traceTemp.forEach((location) => {
        if (!location.isBlocked) {
          location.date = null;
        }
      });

      updateValue("trace", traceTemp);
    }
  };

  const onDragEnd = (result) => {
    setMainRouteChange(true);

    if (!result.destination) {
      return;
    }

    if (result.source.index === result.destination.index) {
      return;
    }

    let lastItemWithDriverStatus;

    for (let i = 0; i <= values.trace.length; i++) {
      if (values.trace[i]?.isDriverStatus) {
        lastItemWithDriverStatus = i;
      }
    }

    if (values.trace[result.source.index].isBlocked) {
      setDialog({
        isOpen: true,
        type: "error",
        content: "Nie możesz przenieść zrealizowanego zlecenia",
      });
      return;
    }

    if (result.source.index >= lastItemWithDriverStatus) {
      if (result.destination.index <= lastItemWithDriverStatus) {
        setDialog({
          isOpen: true,
          type: "error",
          content:
            "Nie możesz umieścić punktu trasy przed zrealizowanymi punktami",
        });
        return;
      }
    }

    const dates = values.trace.map((trace) => trace.date);

    const items = reorder(
      values.trace,
      result.source.index,
      result.destination.index
    );

    const itemsWithOldDates = items.map((item, index) => ({
      ...item,
      date: dates[index],
    }));

    updateValue("trace", itemsWithOldDates);
  };

  const copyTrace = (i) => {
    const traceTemp = [...values.trace];
    const copyTraceEl = { ...values.trace[i] };
    copyTraceEl.id = Math.random().toString(36).substring(7);
    traceTemp.splice(i + 1, 0, copyTraceEl);
    updateValue("trace", traceTemp);
  };

  const openMap = () => {
    const errorResult = checkError();

    if (
      values.transport.set !== undefined &&
      values.transport.set.car_profile !== undefined &&
      values.transport.set.car_profile !== null
    ) {
      if (!errorResult.isValid) {
        setDialog({
          isOpen: true,
          type: "error",
          content: errorResult.message,
        });
        setError(true);
      } else {
        if (
          values.transport.set !== undefined &&
          values.transport.set.car_profile != undefined &&
          values.transport.set.car_profile !== null
        ) {
          if (checkTraceError()) {
            setDialog({
              isOpen: true,
              type: "error",
              content:
                "Niepoprawne punkty zlecenia! Aby wyznaczyć trasę prosimy o zdefiniowanie poprawnych punktów zlecenia.",
            });
          }

          if (
            values.transport.set.car_profile.ntruckweighttotal !== null &&
            values.transport.set.car_profile.ntruckweightaxle !== null &&
            values.transport.set.car_profile.ntruckheight !== null &&
            values.transport.set.car_profile.ntruckwidth !== null &&
            values.transport.set.car_profile.ntrucklenght !== null &&
            !checkTraceError() &&
            values.transport.set.car_profile.h_type !== null &&
            values.transport.set.car_profile.h_axle_count !== null &&
            values.transport.set.car_profile.h_trailer_count !== null &&
            values.transport.set.car_profile.h_tunel_category !== null &&
            values.transport.set.car_profile.h_shipped_hazardous_goods !== null
          ) {
            setOpenModalShowRoute(true);
          }
        } else {
          if (values.transport.set?.label !== undefined) {
            setOpenModalProfilQuestion(true);
            setOpenAddModalProfileData(values.transport.set);
          } else {
            if (checkError()) {
              setError(true);
            }
            setDialog({
              isOpen: true,
              type: "error",
              content: "Wybierz zestaw transportowy.",
            });
          }
        }
      }
    } else {
      if (values.transport.set?.label !== undefined) {
        setOpenModalProfilQuestion(true);
        setOpenAddModalProfileData(values.transport.set);
      } else {
        if (checkError()) {
          setError(true);
        }
        setDialog({
          isOpen: true,
          type: "error",
          content: "Wybierz zestaw transportowy.",
        });
      }
    }
  };

  const checkAddresses = () => {
    if (values.trace.length > 1) {
      for (let i = 0; i < values.trace.length; i++) {
        const address = values.trace[i].warehouse || values.trace[i].address;

        if (!address) {
          return true;
        }
        if (Object.keys(address).length === 0) {
          return true;
        }
      }
      return false;
    }
    return true;
  };

  const handleOpenMap = () => {
    if (!checkAddresses()) {
      if (editFavouritesRoutes) {
        setOpenModalShowRoute(true);
      } else {
        openMap();
      }
    } else {
      setDialog({
        isOpen: true,
        type: "error",
        content: "Wybierz magazyny lub wypełnij adresy ręcznie",
      });
    }
  };

  return (
    <>
      <Title title="Trasa" />
      <div style={{ marginBottom: 20 }} className={stylesMod.orderBox}>
        {!previewComponent && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className={stylesMod.clearRow}>
              <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
                <div>
                  <Button
                    disabled={user_s || isPreview}
                    variant="outlined"
                    color="primary"
                    className={stylesMod.outBtn}
                    onClick={() => {
                      addTrace("loading");
                    }}
                  >
                    Dodaj załadunek <Download />
                  </Button>
                  <Button
                    disabled={user_s || isPreview}
                    variant="outlined"
                    color="primary"
                    className={stylesMod.outBtn}
                    onClick={() => {
                      addTrace("unloading");
                    }}
                  >
                    Dodaj rozładunek <Upload />
                  </Button>
                  <Button
                    disabled={user_s || isPreview}
                    variant="outlined"
                    color="primary"
                    className={stylesMod.outBtn}
                    onClick={() => {
                      addTrace("stop");
                    }}
                  >
                    Dodaj miejsce <MapPin />
                  </Button>
                  <Button
                    disabled={user_s || checkAddresses()}
                    variant="contained"
                    color="primary"
                    className={stylesMod.outBtn}
                    style={{ backgroundColor: "rgba(20, 47, 66, 1)" }}
                    onClick={() => handleOpenMap()}
                  >
                    Podgląd trasy <Map />
                  </Button>
                </div>
              </Tooltip>
            </div>
            {!editFavouritesRoutes && (
              <div className={stylesMod.clearRow}>
                <SelectFavoriteRoutes
                  values={values}
                  contractorId={values.contractor?.value}
                  updateValue={updateValue}
                  setDialog={setDialog}
                  isPreview={values.isLocationDriverStatus || isPreview}
                />
              </div>
            )}
          </div>
        )}

        <DragDropContext onDragEnd={!isPreview && onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {values.trace?.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Trace
                          updateValue={updateValue}
                          values={values}
                          clearHooksState={clearHooksState}
                          index={index}
                          isShowRequiredFields={isShowRequiredFields}
                          toCostValues={toCostValues}
                          user_s={user_s}
                          key={index}
                          itemType={item.type}
                          subtype={item.subtype}
                          data={item}
                          propKey={index}
                          handleTrace={handleTrace}
                          handleTraceNew={handleTraceNew}
                          copyTrace={copyTrace}
                          moveTrace={moveTrace}
                          removeTrace={removeTrace}
                          addTraceCargo={addTraceCargo}
                          removeTraceCargo={removeTraceCargo}
                          goodsOptions={values.cargo}
                          addTab={addTab}
                          saveTab={saveTab}
                          warehousesOptions={warehousesOptions}
                          user={user}
                          error={error}
                          setOpenModalNewWarehouse={setOpenModalNewWarehouse}
                          trace={values.trace}
                          checkedGoods={values.checkedGoods}
                          setOpenModalShowPoint={setOpenModalShowPoint}
                          updateItem={updateItem}
                          setUpdateItem={setUpdateItem}
                          setTraceIndex={setTraceIndex}
                          setTraceData={setTraceData}
                          setDialog={setDialog}
                          editFavouritesRoutes={editFavouritesRoutes}
                          getMinDate={getMinDate}
                          getMaxDate={getMaxDate}
                          getMinTime={getMinTime}
                          getMaxTime={getMaxTime}
                          isPreview={isPreview}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <Dialog
        fullWidth={true}
        maxWidth="xl"
        style={{ height: "100%" }}
        open={openModalShowPoint}
        onClose={() => {
          setOpenModalShowPoint(false);
        }}
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Podgląd trasy
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              setOpenModalShowPoint(false);
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ShowPointOnMap
            csrf_token={user.csrf_token}
            propKey={traceIndex}
            handleTrace={handleTrace}
            traceData={traceData}
            newTraceAddress={newTraceAddress}
            setNewTraceAddress={setNewTraceAddress}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            disabled={isPreview}
            className={`${BTNstyles.btn} ${BTNstyles.err}`}
            onClick={(e) => {
              setOpenModalShowPoint(false);
            }}
          >
            Anuluj <X className={BTNstyles.downBtnIco} />
          </Button>
          <Button
            variant="contained"
            disabled={isPreview}
            size="large"
            color="primary"
            className={BTNstyles.btn}
            onClick={(e) => {
              setOpenModalShowPoint(false);
              setNewTraceAddress(true);
            }}
          >
            Zapisz punkt <Download className={BTNstyles.downBtnIco} />
          </Button>
        </DialogActions>
      </Dialog>
      <TmsDialog dialog={dialog} setDialog={setDialog} />
    </>
  );
};

export default Locations;
