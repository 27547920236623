import React, { Component } from "react";
import styles from "./input.module.css";
import "./input.module.css";
import { Search, Mail, Plus, Edit } from "react-feather";
import { RxReload } from "react-icons/rx";

export default class Input extends Component {
  render() {
    return (
      <div
        className={`${styles.searchBox} ${
          this.props.className
            ? this.props.className === "small"
              ? styles.small
              : this.props.className
            : this.props.className
        } `}
      >
        <input
          className={`${this.props.error ? styles.error : ""} ${styles.input}`}
          key={this.props.key}
          value={this.props.value || ""}
          onChange={this.props.handleChange}
          name={this.props.name}
          onBlur={this.props.onBlur}
          type={this.props.type || "text"}
          ref={this.props.ref}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled ? true : false}
          autoComplete={this.props.autocomplete}
          id={this.props.id}
          style={this.props.style}
        />
        {this.props.search ? (
          <Search
            className={`${styles.searchIcon} ${
              this.props.serchFunc ? styles.clickAble : null
            }`}
            onClick={this.props.serchFunc}
          />
        ) : null}
        {this.props.reload ? (
          <RxReload
            className={`${styles.searchIcon} ${
              this.props.serchFunc ? styles.clickAble : null
            }`}
            onClick={this.props.reloadFunc}
          />
        ) : null}
        {this.props.plus ? (
          <Plus
            className={`${styles.searchIcon} ${
              this.props.plusFunc ? styles.clickAble : null
            }`}
            onClick={this.props.plusFunc}
          />
        ) : null}
        {this.props.edit ? (
          <Edit
            className={`${styles.searchIcon} ${
              this.props.editFunc ? styles.clickAble : null
            }`}
            onClick={this.props.editFunc}
          />
        ) : null}
        {this.props.mail ? (
          <Mail
            className={`${styles.searchIcon} ${
              this.props.mailFunc ? styles.clickAble : null
            }`}
            onClick={this.props.mailFunc}
          />
        ) : null}
      </div>
    );
  }
}
