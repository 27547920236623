import React, { useState, useContext, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import {
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Input,
} from "@material-ui/core";
import { AxiosError } from "axios";
import { ValueType } from "react-select";
import { useFormik, FormikValues } from "formik";
import { X, ArrowRight } from "react-feather";
import { UserContext } from "contexts/userContext";
import TmsSelect from "components/TmsSelect";
import Locations from "containers/order/newOrder/Locations";
import stylesMod from "styles/newOrEdit.module.css";
import ModalShowRoute from "containers/order/newOrder/ModalShowRoute";
import BTNstyles from "styles/button.module.css";
import { defualtTransportSet } from "./RouteEditModal.data";
import {
  editRoutesFavourites,
  getContractors,
  getWarehouses,
} from "api/endpoints";
import { EditRouteModal } from "../FavouriteRoutes.types";
import {
  Contractor,
  SelectOption,
  SelectOptionState,
  TmsDialogState,
  TransportSet,
  Warehouse,
} from "types";

import "./styles.sass";

interface RouteFormValues extends FormikValues {
  contractor: ValueType<SelectOption, false>;
  transport: {
    set: TransportSet;
  };
  kilometers: {
    cargo: number;
  };
  cost: number;
}

type Props = {
  editModal: EditRouteModal;
  setEditModal: ({ isOpen }: { isOpen: boolean }) => void;
  refetchFavoritesRoutes: () => void;
  setDialog: (dialog: TmsDialogState) => void;
};

const RouteEditModal = ({
  editModal,
  setEditModal,
  refetchFavoritesRoutes,
  setDialog,
}: Props) => {
  const [error, setError] = useState(null);
  const [isShowRequiredFields] = useState<boolean>(false);
  const [setOpenModalProfilQuestion] = useState<boolean>(false);
  const [openModalShowRoute, setOpenModalShowRoute] = useState<boolean>(false);
  const [setOpenAddModalProfileData] = useState<boolean>(false);
  const [setOpenModalNewWarehouse] = useState<boolean>(false);
  const [mainRouteChange, setMainRouteChange] = useState<boolean>(false);
  const [saveRoute, setSaveRoute] = useState<boolean>(false);
  const [saveRouteAndSaveOrder, setSaveRouteAndSaveOrder] =
    useState<boolean>(false);
  const [warehousesOptions, setWarehousesOptions] = useState<
    SelectOptionState[]
  >([]);
  const [contractorsOptions, setContractorsOptions] = useState<
    SelectOptionState[]
  >([]);

  const { user } = useContext(UserContext);

  const route = useFormik<RouteFormValues>({
    initialValues: {
      ...editModal.route,
      contractor: null,
      transport: {
        set: defualtTransportSet,
      },
      kilometers: {
        cargo: 0,
      },
      cost: 0,
    },
    onSubmit: () => {},
  });

  const values = route.values;

  const updateValue = (name: string, value: any) => {
    route.setFieldValue(name, value);
  };

  const closeEditModal = () => {
    setEditModal({ isOpen: false });
  };

  const changeContractor = (contractor: ValueType<SelectOption, false>) => {
    let trace = values.trace;

    for (let i = 0; i < values.trace.length; i++) {
      if (trace[i].warehouse) {
        delete trace[i].warehouse;
      }
    }

    route.setValues({ ...values, contractor, trace });
  };

  const { data: contractors } = useQuery<Contractor[], AxiosError>(
    "contractors",
    getContractors,
    {
      onSuccess: (contractors) => {
        if (contractors) {
          setContractorsOptions(
            contractors.map((contractor) => ({
              value: contractor.id.toString(),
              label: contractor.name,
            }))
          );
        } else {
          setDialog({
            isOpen: true,
            type: "error",
            content: "Nie mogę pobrać kontrahentów",
          });
        }
      },
    }
  );

  const { mutate: updateRoute } = useMutation(
    "editRoutesFavourites",
    editRoutesFavourites,
    {
      onSuccess: () => {
        setDialog({
          isOpen: true,
          type: "success",
          content: `Trasa "${values.name}" została zaktualizowana.`,
        });
        refetchFavoritesRoutes();
        closeEditModal();
      },
    }
  );

  useEffect(() => {
    const contractor = contractors?.filter(
      (contractor) => editModal.route?.id_contractor === contractor.id
    )[0];

    if (contractor) {
      updateValue("contractor", {
        value: contractor.id,
        label: contractor.name,
      });
    }
  }, [contractors, editModal.route?.id_contractor]);

  useQuery<Warehouse[], AxiosError>(
    ["warehouses", values.contractor?.value],
    () => getWarehouses(Number(values.contractor?.value)),
    {
      enabled: Boolean(values.contractor?.value),
      onSuccess: (warehouses) => {
        if (warehouses) {
          setWarehousesOptions(
            warehouses?.map((warehouse) => ({
              ...warehouse,
              value: warehouse.id.toString(),
              label: warehouse.name,
            }))
          );
        }
      },
      onError: (error) => {
        setDialog({ isOpen: true, type: "error", content: error.message });
      },
    }
  );

  const handleUpdate = () => {
    updateRoute({
      id: values.id,
      name: values.name,
      id_route: values.route.id,
      id_firm: user.id_firm,
      id_contractor: Number(values.contractor?.value),
      id_customer: user.customer_id,
      id_user: user.id,
      locations: values.trace,
    });
  };

  return (
    <Dialog
      maxWidth="xl"
      fullWidth
      open={editModal.isOpen}
      onClose={closeEditModal}
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        className={stylesMod.dialogHead}
      >
        Edycja trasy "{values.name}"
        <IconButton
          aria-label="close"
          className={stylesMod.closeButton}
          onClick={closeEditModal}
        >
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="edit-modal__header">
          <div className="edit-modal__input-wrapper">
            <label className="edit-modal__input-label">Nazwa trasy</label>
            <Input
              className="edit-modal__input"
              style={{ minWidth: "300px" }}
              value={values.name}
              onChange={(e) => {
                updateValue("name", e.target.value);
              }}
              name="name"
            />
          </div>
          <div className="edit-modal__input-wrapper">
            <label className="edit-modal__input-label">Kontrahent</label>
            <TmsSelect
              minWidth="300px"
              value={values.contractor}
              options={contractorsOptions}
              onChange={(option: ValueType<SelectOption, false>) => {
                changeContractor(option);
              }}
            />
          </div>
        </div>
        <Locations
          values={values}
          updateValue={updateValue}
          user={user}
          addTab={() => {}}
          user_s={false}
          checkError={() => false}
          setError={setError}
          setOpenModalShowRoute={setOpenModalShowRoute}
          setOpenModalProfilQuestion={setOpenModalProfilQuestion}
          setOpenAddModalProfileData={setOpenAddModalProfileData}
          clearHooksState={() => {}}
          isShowRequiredFields={isShowRequiredFields}
          toCostValues={() => {}}
          saveTab={() => {}}
          warehousesOptions={warehousesOptions}
          error={error}
          setOpenModalNewWarehouse={setOpenModalNewWarehouse}
          setMainRouteChange={setMainRouteChange}
          checkTraceError={() => {}}
          editFavouritesRoutes
          isPreview={false}
          previewComponent={false}
        />
        <ModalShowRoute
          values={values}
          updateValue={updateValue}
          openModalShowRoute={openModalShowRoute}
          setOpenModalShowRoute={setOpenModalShowRoute}
          setSaveRoute={setSaveRoute}
          setMainRouteChange={setMainRouteChange}
          setDialog={setDialog}
          isEdit={true}
          makeOrder={() => {}}
          saveRouteAndSaveOrder={saveRouteAndSaveOrder}
          user_s={false}
          saveRoute={saveRoute}
          mainRouteChange={mainRouteChange}
          fetchOrderId={values.route.id_order}
          showSaveFavouriteRoute={false}
          edit_id_routing={values.route.id}
          setSaveRouteAndSaveOrder={setSaveRouteAndSaveOrder}
          isPreview={false}
        />
      </DialogContent>
      <div className="edit-modal__footer">
        <Button
          variant="contained"
          size="large"
          className={`${BTNstyles.btn} ${BTNstyles.err}`}
          onClick={() => {
            closeEditModal();
          }}
        >
          Anuluj <X className={BTNstyles.downBtnIco} />
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={BTNstyles.btn}
          onClick={handleUpdate}
        >
          Zapisz zmiany
          <ArrowRight className={BTNstyles.downBtnIco} />
        </Button>
      </div>
    </Dialog>
  );
};

export default RouteEditModal;
