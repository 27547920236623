
import React, { useState, useEffect, useMemo } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Slide, Grid, Button, Tooltip } from '@material-ui/core'
import stylesMod from '../../styles/newOrEdit.module.css'
import BTNstyles from '../../styles/button.module.css'
import styles from '../table.module.css';
import Select, { StylesConfig } from 'react-select'
import { data } from 'jquery';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  export default function MergeDriverList(props) {
    const {drivers, postDriversToMaster, slaveFunctions, data} = props
    const [selectedDriver, setSelectedDriver] = useState('')
    const handleSave = () => {
        if (selectedDriver.linked_to_transport_set_num === 0){
            slaveFunctions.handleAddToSlaveDrivers(selectedDriver.id)
            slaveFunctions.handleDeleteNotSlaveDrivers(selectedDriver.id)
            postDriversToMaster(selectedDriver.id)
        }
        setSelectedDriver('')
    }

    const handleDriverSelected = (singleDriver) => {
        const newDriver = drivers.find(driver => driver.id === singleDriver.id)
        if(singleDriver?.linked_to_transport_set_num === 0) setSelectedDriver(newDriver)
    }

    
    return (
        <Dialog
            maxWidth='sm'
            fullWidth={true}
            open={props.open}
            TransitionComponent={Transition}
            onClose={() => {
                props.setOpen(false)
            }}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description">
            <DialogTitle id="alert-dialog-slide-title" className={stylesMod.dialogHead}>
                Połączeni kierowcy
            </DialogTitle>
            <DialogContent className={styles.mergePop}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <h3>Wybierz kierowcę</h3>
                    </Grid>
                    <Grid item xs={8}>
                        <div className={`${stylesMod.inp} multi_selector`}>
                            <Select 
                                isOptionDisabled={(option) => option.linked_to_transport_set_num > 0 || option.id === data.id}
                                className={`${stylesMod.select}`}
                                options={drivers || []}
                                onChange={(event) => handleDriverSelected(event)}
                                name="Drivers_list"
                                placeholder="Wybierz kierowcę..."
                                getOptionLabel={e => (
                                    <div key={e.id} value={`${e.fullname}---${e.id}`} 
                                    >
                                        {e.linked_to_transport_set_num === 0 ? <div >{e.fullname} [{e.driverid}]</div> : 
                                        <Tooltip title={
                                            `Kierowca połączony z ${e.linked_to_transport_set_num} zestawami transportowymi`}><div onClick={(e)=>{
                                            e.preventDefault()
                                            e.stopPropagation()
                                        }}> {e.fullname} [{e.driverid}]</div></Tooltip>}
                                    </div>
                                )}
                                getOptionValue={option => `${option.fullname}---${option?.id}`}  
                             />
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
        <Button variant="contained" size="large" className={`${BTNstyles.btn} ${BTNstyles.err}`} onClick={(e) => {
                props.setOpen(false)}}
                color="primary">
                Anuluj 
        </Button>
        <Button variant="contained" size="large" color="primary" className={BTNstyles.btn} onClick={() => { handleSave()}}>
            Zapisz
        </Button>
    </DialogActions>
        </Dialog>
    )

}