import React, { useState, useContext } from "react";
import { useMutation } from "react-query";
import { Button, Input, Modal, Box } from "@material-ui/core";
import { Map } from "react-feather";
import Tooltip from "@material-ui/core/Tooltip";

import { UserContext } from "contexts/userContext";
import stylesMod from "styles/newOrEdit.module.css";
import { setRoutesFavourites } from "api/endpoints";

import "./styles.sass";

const SaveFavoriteRoutes = ({
  updateValue,
  trace,
  contractorId,
  user_s,
  setSaveRoute,
  id_routing,
  setOpenModalShowRoute,
  setDialog,
}) => {
  const [routeName, setRouteName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { user } = useContext(UserContext);

  const { mutate: addNewRoute } = useMutation(
    "setRoutesFavourites",
    setRoutesFavourites,
    {
      onSuccess: (response) => {
        if (response) {
          updateValue("route_id", id_routing);
          updateValue("newFavoriteRoute", {
            value: response.id,
            label: routeName,
          });
          updateValue("updateFavouriteRoute", true);
        } else {
          setDialog({
            isOpen: true,
            type: "error",
            content: "Nie udało się zapisać trasy domyślnej.",
          });
        }
      },
    }
  );

  const handleSave = async () => {
    if (contractorId) {
      if (routeName !== "") {
        if (id_routing) {
          addNewRoute({
            name: routeName,
            id_route: id_routing,
            id_firm: user.id_firm,
            id_contractor: contractorId,
            id_customer: user.customer_id,
            id_user: user.id,
            locations: trace,
          });

          setIsModalOpen(false);
          setRouteName("");
          setOpenModalShowRoute(false);
          setDialog({
            isOpen: true,
            type: "success",
            content: `Trasa "${routeName}" została zapisana`,
          });
        } else {
          setDialog({
            isOpen: true,
            type: "error",
            content: "Nie można zapisać trasy do HERE Maps",
          });
        }
      } else {
        setDialog({
          isOpen: true,
          type: "error",
          content: "Podaj nazwę trasy.",
        });
      }
    } else {
      setDialog({
        isOpen: true,
        type: "error",
        content: "Brak wybranego klienta w formularzu zlecenia.",
      });
    }
  };

  const openModal = () => {
    updateValue("newRoute", true);
    setSaveRoute(true);
    setIsModalOpen(true);
  };

  return (
    <>
      <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
        <div className="favorite-routes">
          <Button
            disabled={user_s}
            variant="outlined"
            color="primary"
            className={stylesMod.outBtn}
            style={{ margin: 0 }}
            onClick={openModal}
          >
            Zapisz jako trasę domyślną <Map />
          </Button>
        </div>
      </Tooltip>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box className="favorite-routes__modal">
          <div className="favorite-routes__input-wrapper">
            <label className="favorite-routes__label">Nazwa trasy</label>
            <Input
              style={{ color: "#000" }}
              value={routeName}
              onChange={(e) => setRouteName(e.target.value)}
              name="route_name"
            />
          </div>
          <Button
            disabled={user_s}
            variant="outlined"
            color="primary"
            className={stylesMod.outBtn}
            style={{ width: "100%", marginTop: "20px" }}
            onClick={handleSave}
          >
            Zapisz
            <Map />
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default SaveFavoriteRoutes;
