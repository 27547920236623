import React, { useState, useEffect, useContext } from "react";
import { UploadCloud, XCircle, DownloadCloud, Search } from "react-feather";
import { DBurl } from "appConfig";
import { Button, IconButton, Grid } from "@material-ui/core";
import { UserContext } from "contexts/userContext";
import { filteredByColumny } from "../../newOrder.helpers";
import TransportFilesModal from "../TransportFilesModal";
import stylesMod from "styles/newOrEdit.module.css";

const FilesTms = ({
  transport,
  updateTransportValue,
  user_s,
  selectedFilesArr,
  setSelectedFilesArr,
  files,
  setFiles,
  isPreview,
}) => {
  const [openModalFiles, setOpenModalFiles] = useState(false);
  const [filesArr, setFilesArr] = useState([]);
  const [isMounted, setIsMounted] = useState(false);
  const [dialog, setDialog] = useState({ isOpen: false });

  const { user } = useContext(UserContext);

  const fetchGetTransportSetFiles = async function (transport_set) {
    const params = [];
    if (transport_set?.id_car) params.push(`id_car=${transport_set?.id_car}`);
    if (transport_set?.id_trailer)
      params.push(`id_trailer=${transport_set?.id_trailer}`);
    if (transport_set?.id_driver1)
      params.push(`id_driver1=${transport_set?.id_driver1}`);
    if (transport_set?.id_driver2)
      params.push(`id_driver2=${transport_set?.id_driver2}`);

    const paramToString = params.join("&");

    const response = await fetch(
      `${DBurl}/getTransportSetFiles?${paramToString}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": user.csrf_token,
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      let tempFilesArr = {
        files_car: null,
        files_driver1: null,
        files_driver2: null,
        files_trailer: null,
      };

      if (json.data.files_car.length > 0) {
        tempFilesArr.files_car = filteredByColumny(json.data.files_car);
      }
      if (json.data.files_driver1.length > 0) {
        tempFilesArr.files_driver1 = filteredByColumny(json.data.files_driver1);
      }
      if (json.data.files_driver2.length > 0) {
        tempFilesArr.files_driver2 = filteredByColumny(json.data.files_driver2);
      }
      if (json.data.files_trailer.length > 0) {
        tempFilesArr.files_trailer = filteredByColumny(json.data.files_trailer);
      }

      setFilesArr(tempFilesArr);

      return json;
    } else {
      setDialog({
        isOpen: true,
        type: "error",
        content: `${json.responseDescription} // ${json.response}  `,
      });
    }
  };

  useEffect(() => {
    if (transport.set !== undefined) {
      fetchGetTransportSetFiles(transport.set);
    }
  }, [transport.set]);

  useEffect(() => {
    let tmpCars =
      selectedFilesArr?.files_car.length > 0
        ? [...selectedFilesArr.files_car]
        : [];
    let tmpDriver1 =
      selectedFilesArr?.files_driver1.length > 0
        ? [...selectedFilesArr.files_driver1]
        : [];
    let tmpDriver2 =
      selectedFilesArr?.files_driver2.length > 0
        ? [...selectedFilesArr.files_driver2]
        : [];
    let tmpTrailer =
      selectedFilesArr?.files_trailer.length > 0
        ? [...selectedFilesArr.files_trailer]
        : [];

    function filterObjectsById(objects, car, d1, d2, trailer) {
      const filteredObjects = [];
      objects.forEach((obj) => {
        if (obj.kind === "car") {
          if (obj.ref_file_id === null || car.includes(obj.ref_file_id)) {
            filteredObjects.push(obj);
          }
        } else if (obj.kind === "driver1") {
          if (obj.ref_file_id === null || d1.includes(obj.ref_file_id)) {
            filteredObjects.push(obj);
          }
        } else if (obj.kind === "driver2") {
          if (obj.ref_file_id === null || d2.includes(obj.ref_file_id)) {
            filteredObjects.push(obj);
          }
        } else if (obj.kind === "trailer") {
          if (obj.ref_file_id === null || trailer.includes(obj.ref_file_id)) {
            filteredObjects.push(obj);
          }
        } else {
          if (obj.ref_file_id === null || trailer.includes(obj.ref_file_id)) {
            filteredObjects.push(obj);
          }
        }
      });
      return filteredObjects;
    }

    let tempFiles = filterObjectsById(
      transport.files,
      tmpCars,
      tmpDriver1,
      tmpDriver2,
      tmpTrailer
    );

    if (isMounted && transport.files.length !== tempFiles.length) {
      // setFiles(tempFiles)
      updateTransportValue("files", tempFiles);
    }

    return () => {
      setIsMounted(true);
    };
  }, [selectedFilesArr, transport.files]);

  return (
    <>
      <Grid item xs={6}>
        <Button
          disabled={user_s || transport.set === undefined || isPreview}
          variant="outlined"
          color="primary"
          component="span"
          className={`${stylesMod.outBtn} ${stylesMod.outBtnGreen}`}
          onClick={(e) => {
            setOpenModalFiles(true);
            fetchGetTransportSetFiles(transport.set);
          }}
        >
          Pliki z TMS
          <UploadCloud />
        </Button>
      </Grid>
      <TransportFilesModal
        openModalFiles={openModalFiles}
        setOpenModalFiles={setOpenModalFiles}
        filesArr={filesArr}
        selectedFilesArr={selectedFilesArr}
        transport={transport}
        setSelectedFilesArr={setSelectedFilesArr}
        updateTransportValue={updateTransportValue}
        files={files}
        setFiles={setFiles}
      />
    </>
  );
};

export default FilesTms;
