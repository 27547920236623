import React, { useState, useEffect, useRef } from "react";
import Input from "components/input";
import Title from "components/title";
import styles from "../table.module.css";
import RowsPerPage from "components/RowsPerPage";
import Pagination from "components/pagination";
import { DBurl } from "appConfig";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Slide,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import docsStyles from "styles/documents.module.css";
import { whatOptions, rangeOptions, renderCategory } from "./deadlinesHelpers";
import DeadlinesCiclic from "./deadlinesCiclic";
import { useQuery } from "react-query";
import { getDocReport } from "api/endpoints";
import TmsLoading from "components/TmsLoading";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const headCells = [
  {
    id: "changeType",
    numeric: true,
    disablePadding: false,
    label: "Kategoria",
    class: docsStyles.headCell,
  },
  { id: "changeLoc", numeric: true, disablePadding: false, label: "Dotyczy" },
  { id: "changeType", numeric: true, disablePadding: false, label: "Parametr" },
  { id: "changeVal", numeric: true, disablePadding: false, label: "Termin" },
  {
    id: "userName",
    numeric: true,
    disablePadding: false,
    label: "Pozostało dni",
  },
];

function useInput({ type }) {
  const [value, setValue] = useState("");
  const input = (
    <TableCell className={docsStyles.headCell}>
      <Input
        value={value}
        handleChange={(e) => {
          setValue(e.target.value);
        }}
        type={type}
        search={true}
        key={"searchRegNum"}
        autocomplete="off"
      />
    </TableCell>
  );
  return [value, input];
}

const DeadlinesDocs = (props) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("car");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [table, setTable] = useState([]);

  const [searchRegNum, setSearchRegNum] = useInput({ type: "text" });
  const [openModalCiclic, setOpenModalCiclic] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");
  const [what, setWhat] = useState(null);
  const [range, setRange] = useState("");

  function EnhancedTableHead(props) {
    const {
      styles,
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;

    return (
      <TableHead className={styles.tabHead}>
        <TableRow>
          {headCells.map((headCell) => {
            return (
              <TableCell
                key={headCell.id}
                align="left"
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={headCell.class}
              >
                {headCell.label}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDialog(false);
  };

  const { refetch: fetchData, isLoading: isGeneratingData } = useQuery(
    ["getDocReport", what],
    () => getDocReport({ what, range: 0 }),
    {
      enabled: false,
      onSuccess: (data) => {
        if (data.length > 0) {
          setTable(data);
        } else {
          setTable([]);
          props.setDialog({
            isOpen: true,
            type: "error",
            content: "Brak danych do wyświetlenia w tym zakresie.",
          });
        }
      },
      onError: (error) => {
        setTable([]);
        props.setDialog({
          isOpen: true,
          type: "error",
          content: `Nie udało się wygenerować danych / ${error.message}`,
        });
      },
    }
  );

  const exportToXLS = async () => {
    const url = `${DBurl}/printDocReportXLS?what=${what}&range=0`;
    props.loading(true);
    fetch(`${DBurl}/printDocReportXLS?what=${what}&range=0`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "X-CSRF-Token": props.user.csrf_token,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        if (blob.size > 286 || blob.type === "application/vnd.ms-excel") {
          window.open(url, "_blank");
          props.loading(false);
        } else {
          setOpenDialog(true);
          setDialogContent(`Brak danych, proszę wybrać właściwe parametry...`);
          setDialogColor("error");
          props.loading(false);
        }
      });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const search = (tbl) => {
    const ret = tbl.filter((row) => {
      return row.col_label?.toLowerCase().includes(searchRegNum.toLowerCase());
    });
    return ret;
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = table.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const paginate = (pageNumber) => setPage(pageNumber - 1);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, table.length - page * rowsPerPage);

  const handleGenerate = () => {
    if (!what) {
      props.setDialog({
        isOpen: true,
        type: "warning",
        content: "Wybierz kategorię",
      });
    } else {
      fetchData();
    }
  };

  return (
    <>
      <div className={styles.root}>
        <Paper className={styles.paper}>
          <Title
            title={props.state.tabName}
            btnBox
            btnGenerate
            btnExport
            btnCyclic
            cyclicFunc={() => {
              setOpenModalCiclic(true);
            }}
            exportFunc={() => {
              exportToXLS();
            }}
            generateFunc={handleGenerate}
            selectCategory={whatOptions}
            selectCategoryFunc={(e) => {
              setWhat(e.value);
            }}
          />
          <TableContainer className={styles.stickyTable}>
            <Table
              className={styles.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                styles={styles}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={table.length}
              />
              <TableBody className={styles.tabBody}>
                {Object.values(table)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <>
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          style={{ backgroundColor: "inherit", height: 51 }}
                        >
                          <TableCell className={docsStyles.headCell}>
                            {renderCategory(row)}
                          </TableCell>
                          <TableCell align="left">{row.name}</TableCell>
                          <TableCell align="left">{row.col_label}</TableCell>
                          <TableCell align="left">{row.value}</TableCell>
                          <TableCell align="left">{row.days_left}</TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 33 * emptyRows }}>
                    <TableCell colSpan={5} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={styles.tableBottom}>
            <RowsPerPage
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              setPage={setPage}
              tableLength={Object.values(table).length}
            />

            <div>
              <Pagination
                postsPerPage={rowsPerPage}
                totalPosts={Object.values(table).length}
                paginate={paginate}
                page={page}
              />
            </div>
          </div>
        </Paper>
      </div>
      <Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={dialogColor}>
          {dialogContent}
        </Alert>
      </Snackbar>

      <DeadlinesCiclic
        {...props}
        openModalCiclic={openModalCiclic}
        setOpenModalCiclic={setOpenModalCiclic}
      />
      {isGeneratingData && <TmsLoading status="Generowanie terminów" />}
    </>
  );
};

export default DeadlinesDocs;
