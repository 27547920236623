import React, { CSSProperties, useState } from "react";
import Select, { OptionProps, StylesConfig, ValueType } from "react-select";
import { SelectOption } from "types";

type Props = {
  options: SelectOption[] | undefined | any;
  defaultOption?: SelectOption;
  name?: string;
  className?: string;
  placeholder?: string;
  minWidth?: string;
  onChange?: (selectedOption: ValueType<SelectOption, false>) => void;
  value?: SelectOption | null;
  isDisabled?: boolean;
  image?: {
    path: string;
    extension: string;
    height?: string;
    margin?: string;
  };
  isClearable?: boolean;
  clearValue?: () => void;
  noOptionsMessage?: string;
};

const TmsSelect = ({
  options,
  defaultOption,
  name,
  className,
  placeholder,
  minWidth,
  onChange,
  value,
  isDisabled,
  image,
  isClearable,
  clearValue,
  noOptionsMessage,
}: Props) => {
  const [select, setSelect] =
    useState<ValueType<SelectOption, false>>(defaultOption);

  const styles: StylesConfig<SelectOption, false> = {
    container: (base: CSSProperties) => ({
      ...base,
      minWidth: minWidth || "200px",
      fontSize: "12px",
    }),
    control: (base: CSSProperties) => ({
      ...base,
      border: "1px solid #142f42",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #142f42",
      },
      color: "#142f42",
    }),
    // @ts-ignore
    menu: (base: CSSProperties) => ({
      ...base,
      zIndex: "9",
    }),
    option: (base: CSSProperties, state: OptionProps<SelectOption, false>) => ({
      ...base,
      backgroundColor: state.isFocused ? "#142f42" : "#fff",
      color: state.isFocused ? "#fff" : "#142f42",
      display: "flex",
    }),
  };

  const SingleValueWithImage = ({ data }: { data: SelectOption }) => (
    <>
      {image && data.image && (
        <img
          src={`${image.path}/${data.image.toLowerCase()}.${image.extension}`}
          alt={data.label}
          style={{
            height: "15px" || image.height,
            marginRight: "10px" || image.margin,
          }}
        />
      )}
      {data.label}
    </>
  );

  const handleSelectChange = (
    selectedOption: ValueType<SelectOption, false>
  ) => {
    setSelect(selectedOption as SelectOption);
  };

  return (
    <Select
      className={className}
      value={value || select}
      options={options}
      onChange={onChange || handleSelectChange}
      components={image && { SingleValue: SingleValueWithImage }}
      styles={styles}
      name={name || ""}
      placeholder={placeholder || ""}
      isClearable={isClearable && true}
      isDisabled={isDisabled || false}
      clearValue={clearValue}
      noOptionsMessage={() => noOptionsMessage || null}
    />
  );
};

export default TmsSelect;
