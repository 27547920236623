/* global google */
/* eslint-disable no-undef */
import React, { useRef, lazy, Suspense } from "react";
import { DBurl } from "../../appConfig";
import { MyMarker } from "./MyMarker";
import { MyMarkerE100 } from "./MyMarkerE100";
import { CarsList } from "./CarsList";
import { MyCustomMarker } from "./MyCustomMarker";
import { RoutePro } from "./RoutePro";
import { Route } from "./Route";
import { SearchAndPlan } from "./SearchAndPlan";
import { MyDirectionsRenderer } from "./MyDirectionsRenderer";
import {
  GoogleMap,
  LoadScript,
  Circle,
  OverlayView,
  DirectionsService,
  DistanceMatrixService,
  MarkerClusterer,
  Polyline,
  Autocomplete,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { Tooltip, Button } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import LayersIcon from "@material-ui/icons/Layers";
import DepartureBoardIcon from "@material-ui/icons/DepartureBoard";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import { AltRoute } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import styles from "../fleet/map.module.css";
import {
  AspectRatioSharp,
  CompassCalibrationOutlined,
  ContactSupportOutlined,
  TimeToLeave,
  Minimize,
  Maximize,
} from "@material-ui/icons";
import { decode as DecodeSygicPolyline } from "./FlexiblePolyline";
import IconFuel from "../../components/buttons/IconFuel";
import Grid from "@material-ui/core/Grid";
import E100 from "components/buttons/e100";
import stylesMod from "../../styles/newOrEdit.module.css";
import { X } from "react-feather";
import Select from "react-select";
import ClipLoader from "react-spinners/ClipLoader";
import { Snackbar, Checkbox, Zoom } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";
import HERE from "@here/maps-api-for-javascript";

export default class Map extends React.Component {
  /**
   * constructor()
   *
   * Funkcja
   *
   */

  constructor(props) {
    super(props);
    // console.log(props)
    const dataAC = [];
    for (let i = 1, len = 4; i < len; i++) {
      dataAC.push({
        title: `rows${i}`,
        text: "",
        place: null,
      });
    }

    const counter_sec = 120;
    const counter_eta_sec = 300; // 5 min

    const override = {
      width: "20px",
      height: "20px",
    };

    this.state = {
      csrf_token: props.user.csrf_token,
      clientOSM: false, // Dzielimy mapę na dwie możliwe opcje przypisywane per Klient: OPEN STREET MAP i MAPA PRO
      map: null,
      markers: [],
      infoWindows: [],
      markersData: [],
      showNewMarkers: false,
      intervalId: null,
      counterMax: counter_sec,
      counter: counter_sec,
      counter_etaMax: counter_eta_sec,
      counter_eta: counter_eta_sec,
      loadingStatus: true,
      tableRows: [],
      eta: [],
      optionsAC: [],
      myAutocompleteItem: dataAC,
      myAutocopleteTimeout: null,
      circleRadius: 60000,
      circleCenter: { lat: 52.24, lng: 22.51 },
      markerOverlayVisibility: false,
      markerOverlayPosition: { lat: 53.24, lng: 23.51 },
      selectedTableRowId: 0,
      trackingCar: false,
      trackingIdDevice: null,
      trackingTs: null,
      checkedTrace: true,
      showOneDayRoute: false,
      polylinePath: [],
      useDirections: false,
      destination: "",
      origin: "",
      waypoints: [],
      travelMode: "DRIVING",
      directionsResponse: null,
      showMyDirectionsRenderer: false,
      useDirectionServices: true,
      renderMode: "directions",
      pathEncoded: null,
      updateDirectionsRenderer: false,
      useMatrix: false,
      typeMatrix: 1,
      destinationsMatrix: [],
      originsMatrix: [],
      distanceMatrixResponse: null,
      carsListMatrix: [],
      pointsListMatrix: [],
      carNames: [],
      carOrigins: [],
      carDestination: null,
      pointNames: [],
      pointOrigins: [],
      pointDestination: null,
      autocomplete: null,
      showPlacesMarker: false,
      placesMarkerPosition: { lat: 53.24, lng: 23.51 },
      checkedRoutePro: false,
      showRoutePro: false,
      showPointRoutePro: false,
      pointsRoutePro: [],
      pathRoutePro: [],
      checkedOpenStreet: false,
      showRouteMode: false,
      routeDistance: "",
      routeDuration: "",
      routeACdata: null,
      initDoRoute: false,
      updateRoute: false,

      showSearchAndPlanMode: false,
      showSearchFuel: false,
      showActiveOrderRoute: false,
      showMyCustomMarker: false,
      myCustomMarkerPoints: [],
      myCustomInfoWindows: [],
      myCustomMarkers: [],
      idOrderSelected: null,
      orderLocations: [],
      showOrderSummary: false,
      orderSummary_km_to_destination: 0,
      orderSummary_access_kilometers: 0,
      orderSummary_cargo_kilometers: 0,
      orderSummary_sum_kilometers: 0,
      showOrderRouteProgress: false,
      polylinePathOrderRouteProgress: [],
      orderRouteProgressIdDevice: null,
      orderRouteProgressTs: null,
      showMyCustomMarkerRefuelings: false,
      myCustomMarkerPointsRefuelings: [],
      myCustomInfoWindowsRefuelings: [],
      myCustomMarkersRefuelings: [],
      suppressMarkers: false,
      id_order_selected: null,
      eta_waypoints: null,
      showHereRoute: false,
      polylinePathHere: [],
      showSygicRoute: false,
      polylinePathSygic: [],

      showMyCustomWaypointsHere: false,
      myCustomWaypointsHere: [],

      checkE100_1: true,
      checkE100_2: true,
      checkE100_3: true,
      checkE100_4: true,
      checkE100_all: true,
      searchRadius: "",
      stationsOnRoute: false,
      cheapestStation: false,
      carList: [],
      markersE100_const: [],
      markersE100: [],
      selectedFuelCard: null,
      nearestVehicles: [],
      fuelCardList: [
        {
          id: 0,
          id_fuel_card: "000122106553",
          id_car: 34,
          active: true,
        },
        {
          id: 1,
          id_fuel_card: "000122106554",
          id_car: 23,
          active: true,
        },
        {
          id: 2,
          id_fuel_card: "000122106555",
          id_car: 44,
          active: true,
        },
        {
          id: 3,
          id_fuel_card: "000122106556",
          id_car: 12,
          active: true,
        },
        {
          id: 4,
          id_fuel_card: "000122106557",
          id_car: 55,
          active: false,
        },
        {
          id: 5,
          id_fuel_card: "000122106558",
          id_car: 38,
          active: true,
        },
      ],
      centerMap: {
        lat: 52.14,
        lng: 19.51,
      },
      zoomMap: 8,
      markersDataE100: [],
      showE100Marker: false,
      e100MarkerPoints: [],
      e100InfoWindows: [],
      e100Markers: [],
      searchFuelE100: false,
      isAddedFuelE100: false,
      filteredMarkersE100: [],
      route_e100: [],
      loading: false,
      color: "#ed3c22",
      override: {
        width: "20px",
        height: "20px",
      },
      idsWindowE100: [],
      openModal: false,
      openDialog: false,
      dialogContent: "",
      dialogColor: "error",
      e100_list_distanse_sorted: [],
      selected_car: null,
      selected_point: null,
      radioOptionsE100: "",
      selected_country: null,
      country_selected: false,
      country: [
        {
          id: 16,
          label: "Armenia",
          code: "AM",
          eng_name: "Armenia",
          capital: "Yerevan",
          zoom: 7,
        },
        {
          id: 21,
          label: "Austria",
          code: "AT",
          eng_name: "Austria",
          capital: "Vienna",
          zoom: 6,
        },
        {
          id: 1,
          label: "Azerbejdżan",
          code: "AZ",
          eng_name: "Azerbaijan",
          capital: "Baku",
          zoom: 6,
        },
        {
          id: 22,
          label: "Belgia",
          code: "BE",
          eng_name: "Belgium",
          capital: "Brussels",
          zoom: 7,
        },
        {
          id: 4,
          label: "Białoruś",
          code: "BY",
          eng_name: "Belarus",
          capital: "Minsk",
          zoom: 6,
        },
        {
          id: 23,
          label: "Bułgaria",
          code: "BG",
          eng_name: "Bulgaria",
          capital: "Sofia",
          zoom: 6,
        },
        {
          id: 24,
          label: "Czechy",
          code: "CZ",
          eng_name: "Czech Republic",
          capital: "Prague",
          zoom: 6,
        },
        {
          id: 25,
          label: "Dania",
          code: "DK",
          eng_name: "Denmark",
          capital: "Copenhagen",
          zoom: 7,
        },
        {
          id: 26,
          label: "Estonia",
          code: "EE",
          eng_name: "Estonia",
          capital: "Tallinn",
          zoom: 7,
        },
        {
          id: 5,
          label: "Finlandia",
          code: "FI",
          eng_name: "Finland",
          capital: "Helsinki",
          zoom: 5,
        },
        {
          id: 19,
          label: "Francja",
          code: "FR",
          eng_name: "France",
          capital: "Paris",
          zoom: 5,
        },
        {
          id: 20,
          label: "Gruzja",
          code: "GE",
          eng_name: "Georgia",
          capital: "Tbilisi",
          zoom: 6,
        },
        {
          id: 27,
          label: "Hiszpania",
          code: "ES",
          eng_name: "Spain",
          capital: "Madrid",
          zoom: 5,
        },
        {
          id: 31,
          label: "Holandia",
          code: "NL",
          eng_name: "Netherlands",
          capital: "Amsterdam",
          zoom: 7,
        },
        {
          id: 7,
          label: "Irlandia",
          code: "IE",
          eng_name: "Ireland",
          capital: "Dublin",
          zoom: 7,
        },
        {
          id: 9,
          label: "Kazachstan",
          code: "KZ",
          eng_name: "Kazakhstan",
          capital: "Nur-Sultan",
          zoom: 6,
        },
        {
          id: 28,
          label: "Litwa",
          code: "LT",
          eng_name: "Lithuania",
          capital: "Vilnius",
          zoom: 6,
        },
        {
          id: 29,
          label: "Luksemburg",
          code: "LU",
          eng_name: "Luxembourg",
          capital: "Luxembourg City",
          zoom: 7,
        },
        {
          id: 30,
          label: "Łotwa",
          code: "LV",
          eng_name: "Latvia",
          capital: "Riga",
          zoom: 7,
        },
        {
          id: 10,
          label: "Mołdawia",
          code: "MD",
          eng_name: "Moldova",
          capital: "Chisinau",
          zoom: 6,
        },
        {
          id: 18,
          label: "Niemcy",
          code: "DE",
          eng_name: "Germany",
          capital: "Berlin",
          zoom: 5,
        },
        {
          id: 32,
          label: "Norwegia",
          code: "NO",
          eng_name: "Norway",
          capital: "Oslo",
          zoom: 5,
        },
        {
          id: 33,
          label: "Polska",
          code: "PL",
          eng_name: "Poland",
          capital: "Warsaw",
          zoom: 8,
        },
        {
          id: 11,
          label: "Portugalia",
          code: "PT",
          eng_name: "Portugal",
          capital: "Lisbon",
          zoom: 6,
        },
        {
          id: 17,
          label: "Rosja",
          code: "RU",
          eng_name: "Russia",
          capital: "Moscow",
          zoom: 4,
        },
        {
          id: 12,
          label: "Rumunia",
          code: "RO",
          eng_name: "Romania",
          capital: "Bucharest",
          zoom: 6,
        },
        {
          id: 34,
          label: "Szwecja",
          code: "SE",
          eng_name: "Sweden",
          capital: "Stockholm",
          zoom: 5,
        },
        {
          id: 35,
          label: "Słowacja",
          code: "SK",
          eng_name: "Slovakia",
          capital: "Bratislava",
          zoom: 7,
        },
        {
          id: 3,
          label: "Słowenia",
          code: "SI",
          eng_name: "Slovenia",
          capital: "Ljubljana",
          zoom: 7,
        },
        {
          id: 13,
          label: "Tadżykistan",
          code: "TJ",
          eng_name: "Tajikistan",
          capital: "Dushanbe",
          zoom: 5,
        },
        {
          id: 14,
          label: "Turcja",
          code: "TR",
          eng_name: "Turkey",
          capital: "Ankara",
          zoom: 5,
        },
        {
          id: 36,
          label: "Ukraina",
          code: "UA",
          eng_name: "Ukraine",
          capital: "Kyiv",
          zoom: 5,
        },
        {
          id: 15,
          label: "Uzbekistan",
          code: "UZ",
          eng_name: "Uzbekistan",
          capital: "Tashkent",
          zoom: 5,
        },
        {
          id: 6,
          label: "Wielka Brytania",
          code: "GB",
          eng_name: "United Kingdom",
          capital: "London",
          zoom: 6,
        },
        {
          id: 2,
          label: "Węgry",
          code: "HU",
          eng_name: "Hungary",
          capital: "Budapest",
          zoom: 6,
        },
        {
          id: 8,
          label: "Włochy",
          code: "IT",
          eng_name: "Italy",
          capital: "Rome",
          zoom: 6,
        },
      ],
      radius_km_checked: false,
      radius_km: null,
      on_road_checked: false,
      selected_list_e100: null,
      enterPressed: false,
      autoPressed: false,
      decodetAutocompleteAdress: [
        {
          name: null,
          lat: null,
          lng: null,
        },
      ],
      shouldReload: false,
      searchValue: "",
      ifShowResult: false,
      selected_order: null,
      selected_e100_station_price: null,
      e100_cards: null,
      e100_station_limit: null,
      e100_transactions: [],
      e100_limits: [],
      open_markerId: null,
      loadingLimits: false,
      loadingFulePrice: false,
      loadingTransaction: false,
      location_position: { lat: null, lng: null },
      circle_center: null,
      circle_radius: null,
      hoveredMarker: null,
    };

    this.platform = new HERE.service.Platform({
      apikey: process.env.REACT_APP_HERE_API_KEY,
    });

    this.infoWindowsRef = [];

    this.adressInput = React.createRef(null);
  }

  /**
   * componentDidMount()
   *
   * Funkcja
   *
   */
  componentDidMount = () => {
    // First load
    this.loadCarsToMap();
    // ETA
    this.loadETA();

    //E100
    this.getE100GasStations();

    let intervalId = setInterval(() => this.tick(), 1000);
    this.setState({ intervalId: intervalId });

    this.setInitRoutePoints();
  };

  /**
   * componentWillUnmount()
   *
   * Funkcja
   *
   */
  componentWillUnmount = () => {
    clearInterval(this.state.intervalId);

    // Usuń markery z mapy
    // this.state.markersE100.forEach(marker => marker.setMap(null));
  };

  /**
   * tick()
   *
   * Funkcja
   *
   */
  tick = () => {
    this.setState({
      counter:
        this.state.counter > 0 ? this.state.counter - 1 : this.state.counterMax,
      counter_eta:
        this.state.counter_eta > 0
          ? this.state.counter_eta - 1
          : this.state.counter_etaMax,
    });

    if (this.state.counter === 0) {
      this.updateCarsToMap();
      this.loadOrdersToMap();
    }

    if (this.state.counter_eta === 0) {
      this.loadETA();

      if (this.state.id_order_selected !== null) {
        let eta_waypoints = this.getEtaWaypointsForOrder(
          this.state.id_order_selected
        );
        this.setState({
          eta_waypoints: eta_waypoints,
        });
      }
    }
  };

  /**
   * loadETA()
   *
   * Funkcja
   *
   */
  loadETA = async () => {
    const response = await fetch(`${DBurl}/getETA`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.state.csrf_token,
      },
    });
    const json = await response.json();

    if (json.success) {
      this.setState({
        eta: json.data,
      });
    }
  };

  /**
   * loadOrdersToMap()
   *
   * Funkcja
   *
   */
  loadOrdersToMap = async () => {
    const response = await fetch(`${DBurl}/loadOrdersToMap`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.state.csrf_token,
      },
    });
    const json = await response.json();
    // console.log('loadOrdersToMap..', json.data)

    if (json.success) {
      let tableRowsTemp = [];
      let tableToCheck = [];

      // Update orders data (optimalization)
      json.data.forEach((order, index) => {
        // Set data source
        if (tableRowsTemp.length === 0) {
          tableToCheck = this.state.tableRows;
        } else {
          tableToCheck = tableRowsTemp;
        }

        // Update temp table
        tableRowsTemp = tableToCheck.map((row) => {
          if (row.id === order.id_car) {
            // Udate data
            return {
              ...row,
              activeOrderData: order.active_order,
              active_order: order.active_order,
              active_order_last_status: order.active_order_last_status,
            };
          }
          return row;
        });
      });

      // Update state once (optimalization)
      this.setState({ tableRows: tableRowsTemp });
    }
  };

  /**
   * loadCarsToMap()
   *
   * Funkcja
   *
   */
  loadCarsToMap = async () => {
    const response = await fetch(`${DBurl}/loadCarsToMap`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.state.csrf_token,
      },
    });
    const json = await response.json().data;

    if (json) {
      this.setState({
        markersData: [],
        infoWindows: [],
        e100InfoWindows: [],
        showNewMarkers: true,
        tableRows: [],
      });

      let tableRowsTemp = [];
      let markersDataTemp = [];

      const bounds = new google.maps.LatLngBounds();

      let tempCarsList = json?.map((o, i) => {
        return {
          id: o.id,
          name: o.name || null,
          position: o.position || null,
        };
      });

      if (tempCarsList?.length > 0) {
        this.setState({
          carList: tempCarsList,
        });
      }

      json.forEach((car, index) => {
        if (!car.no_frame_data) {
          bounds.extend(
            new google.maps.LatLng(car.position.lat, car.position.lng)
          );

          let iconMarker = {
            url: `${DBurl}/imgCars/${car.icon}`,
            size: new google.maps.Size(car.image_width_b, 140),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(car.anchorX_b, car.anchorY_b),
          };

          const popupData = {
            ts: car.ts,
            ts_local: car.ts_local,
            nr_rejestracyjny: car.nr_rejestracyjny,
            speed: car.speed,
            fuel: car.fuel,
            fuel_avg: car.fuel_avg,
            totaldistance: car.totaldistance,
            mileagegps: car.mileagegps,
            enginespeed: car.enginespeed,
            axleweight1: car.axleweight1,
            axleweight2: car.axleweight2,
            axleweight3: car.axleweight3,
            axleweight4: car.axleweight4,
            axle_brutto: car.axle_brutto,
            wiretemp1: car.wiretemp1,
            wiretemp2: car.wiretemp2,
            wiretemp3: car.wiretemp3,
            wiretemp4: car.wiretemp4,
            wiretemp5: car.wiretemp5,
            wiretemp6: car.wiretemp6,
            wiretemp1_msg: car.wiretemp1_msg,
            wiretemp2_msg: car.wiretemp2_msg,
            wiretemp3_msg: car.wiretemp3_msg,
            wiretemp4_msg: car.wiretemp4_msg,
            wiretemp5_msg: car.wiretemp5_msg,
            wiretemp6_msg: car.wiretemp6_msg,
            lastDateCarStart: car.lastDateCarStart,
            lastDateCarStop: car.lastDateCarStop,
            lastTimeCarStart: car.lastTimeCarStart,
            lastTimeCarStop: car.lastTimeCarStop,
            tacho_exists: car.tacho_exists,
            tacho_driver1: car.driver1,
            tacho_driver2: car.driver2,
            tacho_drive: car.tacho_drive,
            tacho_work: car.tacho_work,
            tacho_break: car.tacho_break,
            tacho_rest: car.tacho_rest,
          };

          // Insert data (optimalization)
          markersDataTemp.push({
            id: car.id,
            id_device: car.id_device,
            position: {
              lat: car.position.lat,
              lng: car.position.lng,
            },
            icon: iconMarker,
            name: car.name,
            ts: car.ts,
            ts_local: car.ts_local,
            nr_rejestracyjny: car.nr_rejestracyjny,
            speed: car.speed,
            fuel: car.fuel,
            fuel_avg: car.fuel_avg,
            totaldistance: car.totaldistance,
            mileagegps: car.mileagegps,
            enginespeed: car.enginespeed,
            axleweight1: car.axleweight1,
            axleweight2: car.axleweight2,
            axleweight3: car.axleweight3,
            axleweight4: car.axleweight4,
            axle_brutto: car.axle_brutto,
            wiretemp1: car.wiretemp1,
            wiretemp2: car.wiretemp2,
            wiretemp3: car.wiretemp3,
            wiretemp4: car.wiretemp4,
            wiretemp5: car.wiretemp5,
            wiretemp6: car.wiretemp6,
            wiretemp1_msg: car.wiretemp1_msg,
            wiretemp2_msg: car.wiretemp2_msg,
            wiretemp3_msg: car.wiretemp3_msg,
            wiretemp4_msg: car.wiretemp4_msg,
            wiretemp5_msg: car.wiretemp5_msg,
            wiretemp6_msg: car.wiretemp6_msg,
            lastDateCarStart: car.lastDateCarStart,
            lastDateCarStop: car.lastDateCarStop,
            lastTimeCarStart: car.lastTimeCarStart,
            lastTimeCarStop: car.lastTimeCarStop,
            tacho_exists: car.tacho_exists,
            tacho_driver1: car.driver1,
            tacho_driver2: car.driver2,
            tacho_drive: car.tacho_drive,
            tacho_work: car.tacho_work,
            tacho_break: car.tacho_break,
            tacho_rest: car.tacho_rest,
          });

          // Insert table rows data (optimalization)
          tableRowsTemp.push({
            is_telematics: true,
            id: car.id,
            id_device: car.id_device,
            name: car.name,
            nr_rejestracyjny: car.nr_rejestracyjny,
            params: car.position.lat + ", " + car.position.lng,
            driver: "3",
            lat: car.position.lat,
            lng: car.position.lng,
            activeOrderData: car.active_order,
            progress_to_destination: car.progress_to_destination,
            refuelingData: car.orders_drivers_fuels,
            active_order_last_status: car.active_order_last_status,
            popupData: popupData,
            fuel_percent: car.fuel_percent,
          });
        } else {
          // Insert table rows data (optimalization)
          tableRowsTemp.push({
            is_telematics: false,
            id: car.id,
            id_device: car.id_device,
            name: car.name,
            nr_rejestracyjny: car.nr_rejestracyjny,
            params: "",
            driver: "3",
            lat: null,
            lng: null,
            activeOrderData: car.active_order,
            progress_to_destination: car.progress_to_destination,
            refuelingData: car.orders_drivers_fuels,
            active_order_last_status: car.active_order_last_status,
            popupData: null,
            fuel_percent: car.fuel_percent,
          });
        }
      });

      // Update state once (optimalization)
      this.setState({
        tableRows: tableRowsTemp,
        markersData: markersDataTemp,
      });

      !this.state.showSearchFuel &&
        this.state.map &&
        this.state.map.fitBounds(bounds);
    }
  };

  /**
   * updateCarsToMap()
   *
   * Funkcja
   *
   */
  updateCarsToMap = async () => {
    const response = await fetch(`${DBurl}/updateCarsToMap`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.state.csrf_token,
      },
    });

    const json = await response.json();
    // console.log('updateCarsToMap..', json)

    const bounds = new google.maps.LatLngBounds();

    let tableRowsTemp = [];
    let tableToCheck = [];

    let markersDataTemp = [];
    let tableMarkersDataToCheck = [];

    // Update data
    json.forEach((car, index) => {
      // Set data source
      if (markersDataTemp.length === 0) {
        tableMarkersDataToCheck = this.state.markersData;
      } else {
        tableMarkersDataToCheck = markersDataTemp;
      }

      // Update temp table
      markersDataTemp = tableMarkersDataToCheck.map((marker) => {
        if (car.id === marker.id) {
          // Pan to car marker
          if (this.state.trackingCar && this.state.trackingCar === car.id) {
            this.setState({
              markerOverlayPosition: {
                lat: car.position.lat,
                lng: car.position.lng,
              },
              trackingIdDevice: car.id_device,
              trackingTs: car.ts,
            });

            var objPoint = new google.maps.LatLng(
              car.position.lat,
              car.position.lng
            );
            if (this.state.map) {
              this.state.map.panTo(objPoint);
            }

            if (this.state.checkedTrace) {
              // Update route tracking car
              this.loadOneDayRoute(car.id_device, car.ts);
            }
          }

          // Update data
          return {
            ...marker,
            name: car.name,
            ts: car.ts,
            ts_local: car.ts_local,
            nr_rejestracyjny: car.nr_rejestracyjny,
            speed: car.speed,
            fuel: car.fuel,
            fuel_avg: car.fuel_avg,
            totaldistance: car.totaldistance,
            mileagegps: car.mileagegps,
            enginespeed: car.enginespeed,
            axleweight1: car.axleweight1,
            axleweight2: car.axleweight2,
            axleweight3: car.axleweight3,
            axleweight4: car.axleweight4,
            axle_brutto: car.axle_brutto,
            wiretemp1: car.wiretemp1,
            wiretemp2: car.wiretemp2,
            wiretemp3: car.wiretemp3,
            wiretemp4: car.wiretemp4,
            wiretemp5: car.wiretemp5,
            wiretemp6: car.wiretemp6,
            wiretemp1_msg: car.wiretemp1_msg,
            wiretemp2_msg: car.wiretemp2_msg,
            wiretemp3_msg: car.wiretemp3_msg,
            wiretemp4_msg: car.wiretemp4_msg,
            wiretemp5_msg: car.wiretemp5_msg,
            wiretemp6_msg: car.wiretemp6_msg,
            lastDateCarStart: car.lastDateCarStart,
            lastDateCarStop: car.lastDateCarStop,
            lastTimeCarStart: car.lastTimeCarStart,
            lastTimeCarStop: car.lastTimeCarStop,
            tacho_exists: car.tacho_exists,
            tacho_driver1: car.driver1,
            tacho_driver2: car.driver2,
            tacho_drive: car.tacho_drive,
            tacho_work: car.tacho_work,
            tacho_break: car.tacho_break,
            tacho_rest: car.tacho_rest,
            position: car.position,
            icon: {
              url: `${DBurl}/imgCars/${car.icon}`,
              size: new google.maps.Size(car.image_width_b, 140),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(car.anchorX_b, car.anchorY_b),
            },
          };
        }

        return marker;
      });

      // Update tableRows (optymalization)
      // Set data source
      if (tableRowsTemp.length === 0) {
        tableToCheck = this.state.tableRows;
      } else {
        tableToCheck = tableRowsTemp;
      }

      // Update temp table
      tableRowsTemp = tableToCheck.map((row) => {
        if (car.id === row.id) {
          const popupData = {
            ts: car.ts,
            ts_local: car.ts_local,
            nr_rejestracyjny: car.nr_rejestracyjny,
            speed: car.speed,
            fuel: car.fuel,
            fuel_avg: car.fuel_avg,
            totaldistance: car.totaldistance,
            mileagegps: car.mileagegps,
            enginespeed: car.enginespeed,
            axleweight1: car.axleweight1,
            axleweight2: car.axleweight2,
            axleweight3: car.axleweight3,
            axleweight4: car.axleweight4,
            axle_brutto: car.axle_brutto,
            wiretemp1: car.wiretemp1,
            wiretemp2: car.wiretemp2,
            wiretemp3: car.wiretemp3,
            wiretemp4: car.wiretemp4,
            wiretemp5: car.wiretemp5,
            wiretemp6: car.wiretemp6,
            wiretemp1_msg: car.wiretemp1_msg,
            wiretemp2_msg: car.wiretemp2_msg,
            wiretemp3_msg: car.wiretemp3_msg,
            wiretemp4_msg: car.wiretemp4_msg,
            wiretemp5_msg: car.wiretemp5_msg,
            wiretemp6_msg: car.wiretemp6_msg,
            lastDateCarStart: car.lastDateCarStart,
            lastDateCarStop: car.lastDateCarStop,
            lastTimeCarStart: car.lastTimeCarStart,
            lastTimeCarStop: car.lastTimeCarStop,
            tacho_exists: car.tacho_exists,
            tacho_driver1: car.driver1,
            tacho_driver2: car.driver2,
            tacho_drive: car.tacho_drive,
            tacho_work: car.tacho_work,
            tacho_break: car.tacho_break,
            tacho_rest: car.tacho_rest,
          };

          // Udate data
          return {
            ...row,
            name: car.name,
            nr_rejestracyjny: car.nr_rejestracyjny,
            params: car.speed + " km/h",
            popupData: popupData,
          };
        }
        return row;
      });
    });

    // Update state once (optimalization)
    if (json.length > 0) {
      this.setState({
        markersData: markersDataTemp,
        tableRows: tableRowsTemp,
      });
    }

    // Update bounds
    this.state.markersData.forEach((marker, i) => {
      bounds.extend(
        new google.maps.LatLng(marker.position.lat, marker.position.lng)
      );
    });

    // Bounds all cars markers
    if (!this.state.trackingCar) {
      !this.state.showSearchFuel &&
        this.state.map &&
        this.state.map.fitBounds(bounds);
    }

    // Update order route progress
    if (this.state.showOrderRouteProgress) {
      this.showOrderRouteProgress(
        this.state.orderRouteProgressIdDevice,
        this.state.orderRouteProgressTs
      );
    }
  };

  /**
   * createData()
   *
   * Funkcja
   *
   */
  createData = (
    is_telematics,
    id,
    id_device,
    name,
    nr_rejestracyjny,
    params,
    driver,
    lat,
    lng,
    activeOrderData,
    progress_to_destination,
    refuelingData,
    active_order_last_status,
    popupData
  ) => {
    return {
      is_telematics,
      id,
      id_device,
      name,
      nr_rejestracyjny,
      params,
      driver,
      lat,
      lng,
      activeOrderData,
      progress_to_destination,
      refuelingData,
      active_order_last_status,
      popupData,
    };
  };

  /**
   * showMarkers()
   *
   * Funkcja
   *
   */
  showMarkers = () => {
    if (this.state.showNewMarkers) {
      return this.drawMarkers();
    }
  };

  /**
   * drawMarkers()
   *
   * Funkcja
   *
   */
  // drawMarkersE100 = () => {
  // 	return <MarkerClusterer options={optionsMC}>
  // 		{(clusterer) =>

  // 			this.state.markersDataE100.map((m, index) => {

  // 				return (
  // 					<MyMarkerE100
  // 						key={index}
  // 						id={m.id}
  // 						index={index}
  // 						position={m.position}
  // 						icon={m.icon}
  // 						clusterer={clusterer}

  // 						name={m.name}
  // 						type_e100={m.type_e100}
  // 						fuel={m.fuel}
  // 						fuel_card_limit={m.fuel_card_limit}
  // 						last_transaction={m.last_transaction}
  // 						fuelCardList={this.state.fuelCardList}
  // 						limits={m.limits}
  // 						carList={this.state.carList}
  // 						checkE100_1 = {this.state.checkE100_1}
  // 						checkE100_2 = {this.state.checkE100_2}
  // 						checkE100_3 = {this.state.checkE100_3}
  // 						checkE100_4 = {this.state.checkE100_4}

  // 						callbackOnLoadMarker={this.onLoadE100Marker}
  // 						callbackOnLoadInfoWindow={this.onLoadE100InfoWindow}
  // 						callbackOnDomReadyInfoWindow={this.onDomReadyInfoWindow}

  // 						callbackOnClickMarker={this.onClickMarkerE100}
  // 						callbackOnClose={this.onCloseInfoWindowE100}

  // 					/>
  // 				)
  // 			})
  // 		}
  // 	</MarkerClusterer>
  // }

  // drawMarkersE100 = () => {
  // 	return (
  // 		<MarkerClusterer options={optionsMC}>
  // 			{(clusterer) => {

  // 				// console.log('drawMarkersE100...')

  // 				return (
  // 					this.state.markersDataE100.filter((m,i) => {

  // 						if (m.type_e100 === 1 && this.state.checkE100_1) {
  // 							return true;
  // 						}
  // 						if (m.type_e100 === 2 && this.state.checkE100_2) {
  // 							return true;
  // 						}
  // 						if (m.type_e100 === 3 && this.state.checkE100_3) {
  // 							return true;
  // 						}
  // 						if (m.type_e100 === 4 && this.state.checkE100_4) {
  // 							return true;
  // 						}
  // 						return false;
  // 					})
  // 					.map((m, i) => {
  // 						return (
  // 							<MyMarkerE100
  // 								key={i}
  // 								id={m.id}
  // 								index={i}
  // 								position={m.position}
  // 								icon={m.icon}
  // 								clusterer={clusterer}
  // 								className={`marker_${m.type_e100}`}
  // 								name={m.name}
  // 								type_e100={m.type_e100}
  // 								fuel={m.fuel}
  // 								fuel_card_limit={m.fuel_card_limit}
  // 								last_transaction={m.last_transaction}
  // 								fuelCardList={this.state.fuelCardList}
  // 								limits={m.limits}
  // 								carList={this.state.carList}
  // 								checkE100_1 = {this.state.checkE100_1}
  // 								checkE100_2 = {this.state.checkE100_2}
  // 								checkE100_3 = {this.state.checkE100_3}
  // 								checkE100_4 = {this.state.checkE100_4}

  // 								callbackOnLoadMarker={this.onLoadE100Marker}
  // 								callbackOnLoadInfoWindow={this.onLoadE100InfoWindow}
  // 								callbackOnDomReadyInfoWindow={this.onDomReadyInfoWindow}

  // 								callbackOnClickMarker={this.onClickMarkerE100}
  // 								callbackOnClose={this.onCloseInfoWindowE100}

  // 							/>
  // 						)
  // 					})

  // 				)
  // 			}}
  // 		</MarkerClusterer>
  // 	)
  // }

  drawMarkers = () => {
    // console.log('drawMarkers...')
    return (
      <MarkerClusterer options={optionsMC}>
        {(clusterer) =>
          this.state.markersData.map((m, index) => {
            return (
              <MyMarker
                key={index}
                id={m.id}
                index={index}
                position={m.position}
                icon={m.icon}
                clusterer={clusterer}
                name={m.name}
                ts_local={m.ts_local}
                nr_rejestracyjny={m.nr_rejestracyjny}
                speed={m.speed}
                fuel={m.fuel}
                fuel_avg={m.fuel_avg}
                totaldistance={m.totaldistance}
                mileagegps={m.mileagegps}
                enginespeed={m.enginespeed}
                axleweight1={m.axleweight1}
                axleweight2={m.axleweight2}
                axleweight3={m.axleweight3}
                axleweight4={m.axleweight4}
                axle_brutto={m.axle_brutto}
                wiretemp1={m.wiretemp1}
                wiretemp2={m.wiretemp2}
                wiretemp3={m.wiretemp3}
                wiretemp4={m.wiretemp4}
                wiretemp5={m.wiretemp5}
                wiretemp6={m.wiretemp6}
                wiretemp1_msg={m.wiretemp1_msg}
                wiretemp2_msg={m.wiretemp2_msg}
                wiretemp3_msg={m.wiretemp3_msg}
                wiretemp4_msg={m.wiretemp4_msg}
                wiretemp5_msg={m.wiretemp5_msg}
                wiretemp6_msg={m.wiretemp6_msg}
                lastDateCarStart={m.lastDateCarStart}
                lastDateCarStop={m.lastDateCarStop}
                lastTimeCarStart={m.lastTimeCarStart}
                lastTimeCarStop={m.lastTimeCarStop}
                tacho_exists={m.tacho_exists}
                tacho_driver1={m.tacho_driver1}
                tacho_driver2={m.tacho_driver2}
                tacho_drive={m.tacho_drive}
                tacho_work={m.tacho_work}
                tacho_break={m.tacho_break}
                tacho_rest={m.tacho_rest}
                callbackOnLoadMarker={this.onLoadMarker}
                callbackOnLoadInfoWindow={this.onLoadInfoWindow}
                callbackOnDomReadyInfoWindow={this.onDomReadyInfoWindow}
                callbackOnClickMarker={this.onClickMarker}
                callbackOnMouseOverMarker={this.onMouseOverMarker}
                callbackOnMouseOutMarker={this.onMouseOutMarker}
              />
            );
          })
        }
      </MarkerClusterer>
    );
  };

  /**
   * drawMyMarkers()
   *
   * Funkcja
   *
   */
  drawMyMarkers = () =>
    this.state.markersDataLocal.map((m, index) => (
      <MyMarker
        id={m.id}
        index={index}
        position={m.position}
        icon={m.icon}
        name={m.name}
        callbackOnLoadMarker={this.onLoadMarker}
        callbackOnLoadInfoWindow={this.onLoadInfoWindow}
        callbackOnClickMarker={this.onClickMarker}
        callbackOnMouseOverMarker={this.onMouseOverMarker}
        callbackOnMouseOutMarker={this.onMouseOutMarker}
      />
    ));

  /**
   * drawPlaceRoutePro()
   *
   * Funkcja
   *
   */
  drawPlaceRoutePro = (data) => {
    // console.log('drawPlaceRoutePro');
    console.log(data);

    const bounds = new google.maps.LatLngBounds();

    let routePoints = data["routePoints"];
    routePoints.map((point, index) => {
      bounds.extend(new google.maps.LatLng(point.latitude, point.longitude));
    });

    let routeRepresentation = data["routeRepresentation"];
    let routeCoordinates = [];

    routeRepresentation.map((coord, index) => {
      let lon = coord.longitude;
      let lat = coord.latitude;
      routeCoordinates.push(new google.maps.LatLng(lat, lon));
    });

    this.setState({
      showRoutePro: true,
      pathRoutePro: routeCoordinates,
    });

    this.state.map && this.state.map.fitBounds(bounds);
  };

  /**
   * addPointRoutePro()
   *
   * Funkcja
   *
   */
  addPointRoutePro = (data) => {
    // console.log('addPointRoutePro');
    console.log(data);

    this.clearRoutePro();

    this.setState({
      showPointRoutePro: true,
      pointsRoutePro: data,
    });
  };

  /**
   * focusOnPointRoutePro()
   *
   * Funkcja
   *
   */
  focusOnPointRoutePro = (data) => {
    console.log(data);

    const bounds = new google.maps.LatLngBounds();
    bounds.extend(
      new google.maps.LatLng(data.place.location.lat, data.place.location.lng)
    );
    this.state.map && this.state.map.fitBounds(bounds);
  };

  /**
   * getMapOptions()
   *
   * Funkcja
   *
   */
  getMapOptions = () => {
    return {};
  };

  /**
   * onLoadMarker()
   *
   * Funkcja
   *
   */
  onLoadMarker = (marker) => {
    this.setState((state) => ({
      markers: [...state.markers, marker],
    }));
  };

  /**
   * onLoadInfoWindow()
   *
   * Funkcja
   *
   */
  onLoadInfoWindow = (infoWindow) => {
    infoWindow.close();

    this.setState((state) => ({
      infoWindows: [...state.infoWindows, infoWindow],
    }));
  };

  /**
   * onLoadMyCustomMarker()
   *
   * Funkcja
   *
   */
  onLoadMyCustomMarker = (marker) => {
    // console.log('onLoadMyCustomMarker!!!');
    this.setState((state) => ({
      myCustomMarkers: [...state.myCustomMarkers, marker],
    }));
  };

  /**
   * onLoadE100Marker()
   *
   * Funkcja
   *
   */
  onLoadE100Marker = (marker) => {
    // console.log('onLoadE100Marker!!!');
    this.setState((state) => ({
      e100Markers: [...state.e100Markers, marker],
    }));
  };

  /**
   * onLoadMyCustomMarkerRefuelings()
   *
   * Funkcja
   *
   */
  onLoadMyCustomMarkerRefuelings = (marker) => {
    // console.log('onLoadMyCustomMarkerRefuelings!!!');
    this.setState((state) => ({
      myCustomMarkersRefuelings: [...state.myCustomMarkersRefuelings, marker],
    }));
  };

  /**
   * onClickCloseRoutePro()
   *
   * Funkcja
   *
   */
  onClickCloseRoutePro = () => {
    this.setState({
      showRoutePro: false,
      checkedRoutePro: false,
    });

    this.clearRoutePro();
  };

  /**
   * onDomReadyInfoWindow()
   *
   * Funkcja
   *
   */
  onDomReadyInfoWindow = () => {
    // Change structure
    let elem = document.getElementsByClassName("gm-style-iw");

    //let elem2 = document.getElementsByClassName('gm-style-iw-t');
    //console.log(elem2);

    for (let i = 0; i < elem.length; i++) {
      // Remove shadow
      elem[i].style.boxShadow = "none";
      // Romove background
      elem[i].style.background = "none";
      //Remove border-radius
      elem[i].style.borderRadius = "0px";
      // Remove scrolls
      elem[i].childNodes[0].style.overflow = "auto";
      // Remove close btn
      elem[i].childNodes[1].style.display = "none";
      // Remove bottom arrow
      //elem2[i].classList.add("hide-after");
    }

    //let elemArrow = document.getElementsByClassName('gm-style-iw-t').classList.add("hide-after");
  };

  /**
   * onClickMarker()
   *
   * Funkcja
   *
   */
  onClickMarker = (id, index, e) => {
    // console.log('onClickMarker..........',id, index, e);

    let tmpSelectedCar = this.state.tableRows.filter((o) => o.id === id)[0];
    // console.log('onClickMarker..........tmpSelectedCar',tmpSelectedCar);
    this.selectCarById(id);
    this.setState({
      selectedTableRowId: id,
      selected_car: tmpSelectedCar,
    });
  };

  /**
   * onSelectAutocomlete(selected)
   *
   * Funkcja
   *
   */
  onSelectAutocomlete = (selected) => {
    console.log(selected);

    this.state.myAutocompleteItem.forEach((item, index) => {
      if (item.title === selected.title) {
        this.setState((state) => ({
          myAutocompleteItem: [
            ...state.myAutocompleteItem.slice(0, index),
            {
              ...state.myAutocompleteItem[index],
              text: selected.text,
              place: selected.place,
            },
            ...state.myAutocompleteItem.slice(index + 1),
          ],
        }));
      }
    });

    setTimeout(() => {
      console.log(this.state.myAutocompleteItem);
    }, 100);
  };

  /**
   * onDragEnd(fromIndex, toIndex)
   *
   * Funkcja
   *
   */
  onDragEnd = (fromIndex, toIndex) => {
    const data = [...this.state.myAutocompleteItem];
    const item = data.splice(fromIndex, 1)[0];
    data.splice(toIndex, 0, item);
    this.setState({ myAutocompleteItem: data });

    console.log(this.state.myAutocompleteItem);
  };

  /**
   * onClickChangeInput()
   *
   * Funkcja
   *
   */
  onClickChangeInput = () => {
    let selected = {
      title: "rows2",
      text: "edited",
      place: null,
    };

    this.state.myAutocompleteItem.forEach((item, index) => {
      if (item.title === selected.title) {
        this.setState((state) => ({
          myAutocompleteItem: [
            ...state.myAutocompleteItem.slice(0, index),
            {
              ...state.myAutocompleteItem[index],
              text: selected.text,
              place: selected.place,
            },
            ...state.myAutocompleteItem.slice(index + 1),
          ],
        }));
      }
    });
  };

  /**
   * selectCarById(id)
   *
   * Funkcja
   *
   */
  selectCarById = (id, onlySelect) => {
    this.clearDirections();

    this.state.markersData
      .filter((md) => md.id === id)
      .forEach((markerData) => {
        this.setState({
          trackingCar: markerData.id,
          markerOverlayVisibility: true,
          markerOverlayPosition: {
            lat: markerData.position.lat,
            lng: markerData.position.lng,
          },
        });

        var objPoint = new google.maps.LatLng(
          markerData.position.lat,
          markerData.position.lng
        );
        if (this.state.map) {
          this.state.map.panTo(objPoint);
          this.state.map.setZoom(11);
        }
        if (onlySelect === false || onlySelect === undefined) {
          if (this.state.checkedTrace) {
            this.loadOneDayRoute(markerData.id_device, markerData.ts);
          }
        }
      });
  };

  /**
   * loadOneDayRoute()
   *
   * Funkcja
   *
   */
  loadOneDayRoute = async (id_device, ts) => {
    const response = await fetch(
      `${DBurl}/loadOneDayRoute?id_device=` + id_device + "&ts=" + ts,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": this.state.csrf_token,
        },
      }
    );
    const json = await response.json();

    if (json) {
      // console.log('json', json)

      this.setState({
        polylinePath: [],
        showOneDayRoute: false,
      });

      let coordinates = new Array();

      json.forEach((row, index) => {
        coordinates.push({
          lat: parseFloat(row.lat),
          lng: parseFloat(row.lng),
        });
      });

      this.setState({
        polylinePath: coordinates,
        showOneDayRoute: true,
        trackingIdDevice: id_device,
        trackingTs: ts,
      });
    }
  };

  /**
   * showOrderRouteProgress()
   *
   * Funkcja
   *
   */
  showOrderRouteProgress = async (id_device, ts) => {
    const response = await fetch(
      `${DBurl}/loadOrderProgressRoute?id_device=` + id_device + "&ts=" + ts,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": this.state.csrf_token,
        },
      }
    );
    const json = await response.json();

    if (json) {
      console.log(json);

      this.setState({
        polylinePathOrderRouteProgress: [],
        showOrderRouteProgress: false,
        orderRouteProgressIdDevice: null,
        orderRouteProgressTs: null,
      });

      let coordinates = new Array();

      json.forEach((row, index) => {
        coordinates.push({
          lat: parseFloat(row.lat),
          lng: parseFloat(row.lng),
        });
      });

      this.setState({
        polylinePathOrderRouteProgress: coordinates,
        showOrderRouteProgress: true,
        orderRouteProgressIdDevice: id_device,
        orderRouteProgressTs: ts,
      });
    }
  };

  /**
   * showOrderRouteProgressNoTelemetry(active_order_last_status)
   *
   * Funkcja
   *
   */
  showOrderRouteProgressNoTelemetry = (active_order_last_status) => {
    let found = false;
    this.hideMarker();

    active_order_last_status.forEach((item, index) => {
      if (!found && item.longitude !== null && item.lattitude !== null) {
        found = true;

        let objPoint = new google.maps.LatLng(item.lattitude, item.longitude);
        this.showMarker(objPoint.lat(), objPoint.lng());

        if (this.state.map) {
          this.state.map.panTo(objPoint);
          this.state.map.setZoom(11);
        }
      }
    });
  };

  /**
   * onPlaceChangedAutocomplete()
   *
   * Funkcja
   *
   */
  onPlaceChangedAutocomplete = () => {
    if (this.state.autocomplete !== null) {
      let place = this.state.autocomplete.getPlace();
      console.log(place);

      this.showMarker(
        place.geometry.location.lat(),
        place.geometry.location.lng()
      );
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  /**
   * showMarker()
   *
   * Funkcja
   *
   */
  showMarker = (lat, lng) => {
    this.setState({
      showPlacesMarker: true,
      placesMarkerPosition: { lat: lat, lng: lng },
    });
  };

  /**
   * hideMarker()
   *
   * Funkcja
   *
   */
  hideMarker = (lat, lng) => {
    this.setState({
      showPlacesMarker: false,
      placesMarkerPosition: null,
    });
  };

  /**
   * clearHereSygicRoute()
   *
   * Funkcja
   *
   */
  clearHereSygicRoute = () => {
    this.setState({
      showHereRoute: false,
      showSygicRoute: false,
      showMyCustomWaypointsHere: false,
      polylinePathHere: [],
      polylinePathSygic: [],
      myCustomWaypointsHere: [],
    });
  };

  /**
   * onClickSetTheRoute()
   *
   * Funkcja
   *
   */
  onClickSetTheRoute = () => {
    const { idsWindowE100, markersE100 } = this.state;

    const updatedMarkersE100 = markersE100.map((o) => {
      if (idsWindowE100.includes(o.id)) {
        return {
          ...o,
          showInfo: false,
        };
      }
      return o;
    });

    this.setState({
      showSearchAndPlanMode: false,
      showSearchFuel: false,
      showRouteMode: true,
      markersE100: updatedMarkersE100,
      nearestVehicles: [],
      route_e100: [],
      idsWindowE100: [],
      // e100_list_distanse_sorted: []
    });

    this.clearDirections();
  };

  /**
   * onClickShowSearchAndPlan()
   *
   * Funkcja
   *
   */
  onClickShowSearchAndPlan = () => {
    const { idsWindowE100, markersE100 } = this.state;

    const updatedMarkersE100 = markersE100.map((o) => {
      if (idsWindowE100.includes(o.id)) {
        return {
          ...o,
          showInfo: false,
        };
      }
      return o;
    });

    this.setState({
      showSearchAndPlanMode: true,
      showSearchFuel: false,
      showRouteMode: false,
      markersE100: updatedMarkersE100,
      nearestVehicles: [],
      route_e100: [],
      idsWindowE100: [],
      // e100_list_distanse_sorted: [],
    });

    this.clearDirections();
  };

  onClickSetMapSearch = () => {
    const { idsWindowE100, markersE100 } = this.state;

    const updatedMarkersE100 = markersE100.map((o) => {
      if (idsWindowE100.includes(o.id)) {
        return {
          ...o,
          showInfo: false,
        };
      }
      return o;
    });

    this.setState({
      showRouteMode: false,
      showSearchAndPlanMode: false,
      showSearchFuel: false,
      markersE100: updatedMarkersE100,
      nearestVehicles: [],
      route_e100: [],
      idsWindowE100: [],
      // e100_list_distanse_sorted: [],
    });

    this.clearDirections();
  };

  /**
   * onLoadAutocomplete()
   *
   * Funkcja
   *
   */
  onLoadAutocomplete = (autocomplete) => {
    // console.log('autocomplete: ', autocomplete);
    this.setState({ autocomplete: autocomplete });
  };

  /**
   * onClickShowSearchAndPlan()
   *
   * Funkcja
   *
   */
  onClickShowSearchFuel = () => {
    this.setState({
      showSearchFuel: true,
      showSearchAndPlanMode: false,
      showRouteMode: false,
    });
    this.clearDirections();
  };

  /**
   * onCloseRoutePlanerCallback()
   *
   * Funkcja
   *
   */
  onCloseRoutePlanerCallback = () => {
    this.setState({
      showRouteMode: false,
    });

    this.clearDirections();
  };

  /**
   * onClickHideOrder()
   *
   * Funkcja
   *
   */
  onClickHideOrder = () => {
    this.clearDirections();

    this.setState({
      showOrderSummary: false,
    });
  };

  /**
   * onClickShowOrder()
   *
   * Funkcja
   *
   */
  onClickShowOrder = (value, row) => {
    // console.log('>>>>>>>>>',value, row)
    this.clearDirections();
    this.setState({
      showOrderSummary: value,
      orderSummary_km_to_destination:
        row.progress_to_destination !== null
          ? row.progress_to_destination.km_to_destination
          : 0,
      orderSummary_access_kilometers: row.activeOrderData.access_kilometers,
      orderSummary_cargo_kilometers: row.activeOrderData.cargo_kilometers,
      orderSummary_sum_kilometers: row.activeOrderData.sum_kilometers,
    });

    // console.log('onClickShowOrder');
    // console.log(row);
  };

  /**
   * onClickSelectOrder()
   *
   * Funkcja
   *
   */
  onClickSelectOrder = (id_order) => {
    let eta_waypoints = this.getEtaWaypointsForOrder(id_order);

    this.setState({
      id_order_selected: id_order,
      eta_waypoints: eta_waypoints,
    });
  };

  /**
   * getEtaWaypointsForOrder(id_order)
   *
   * Funkcja
   *
   */
  getEtaWaypointsForOrder = (id_order) => {
    let eta_waypoints = null;
    let eta_waypoints_temp = [];
    let eta_waypoints_out = null;

    for (let i = 0; i < this.state.eta.length; i++) {
      // Szukamy eta waypoints dla zlecenia
      if (id_order === this.state.eta[i][0].eta_id_order) {
        eta_waypoints = this.state.eta[i][0].eta.waypoints;
      }
    }

    // Pomijamy waypoint-y invisible (ręcznie ustawione przy routingu HERE)
    if (eta_waypoints !== null) {
      eta_waypoints.forEach((waypoint) => {
        if (waypoint.type !== "invisible") {
          eta_waypoints_temp.push(waypoint);
        }
      });

      console.log("eta_waypoints_temp:", eta_waypoints_temp);

      if (eta_waypoints_temp.length > 0) {
        eta_waypoints_out = eta_waypoints_temp;
      }
    }

    console.log("eta_waypoints_out:", eta_waypoints_out);

    return eta_waypoints_out;
  };

  /**
   * clearDirections()
   *
   * Funkcja
   *
   */
  clearRoutePro = () => {
    this.setState({
      pathRoutePro: [],
      pointsRoutePro: [],
      showPointRoutePro: false,
    });
  };

  /**
   * clearDirections()
   *
   * Funkcja
   *
   */
  clearDirections = () => {
    this.setState({
      directionsResponse: null,
      showMyDirectionsRenderer: false,
      useDirectionServices: true,
      destination: "",
      origin: "",
      routeDistance: "",
      routeDuration: "",
      idOrderSelected: null,
      orderLocations: [],

      showMyCustomMarkerRefuelings: false,
      myCustomMarkersRefuelings: [],
      myCustomInfoWindowsRefuelings: [],

      showMyCustomMarker: false,
      myCustomInfoWindows: [],
      myCustomMarkerPoints: [],
      myCustomMarkerPointsRefuelings: [],
      myCustomMarkers: [],
      showOrderRouteProgress: false,
      polylinePathOrderRouteProgress: [],
      orderRouteProgressIdDevice: null,
      orderRouteProgressTs: null,
      carsListMatrix: [],
      pointsListMatrix: [],

      showE100Marker: false,
      e100InfoWindows: [],
      e100Markers: [],
      nearestVehicles: [],
      route_e100: [],
    });

    this.hideMarker();

    this.setInitRoutePoints();

    this.clearHereSygicRoute();
  };

  /**
   * setInitRoutePoints()
   *
   * Funkcja
   *
   */
  setInitRoutePoints = () => {
    const routeACdata = [];
    for (let i = 1, len = 3; i < len; i++) {
      routeACdata.push({
        title: `rows${i}`,
        text: "",
        place: null,
      });
    }

    this.setState({
      routeACdata: routeACdata,
    });
  };

  /**
   * onChangeChbxTrace()
   *
   * Funkcja
   *
   */
  onChangeChbxTrace = (event, state) => {
    this.setState({
      checkedTrace: state,
    });

    if (state) {
      this.setState({
        showOneDayRoute: true,
      });
      this.loadOneDayRoute(this.state.trackingIdDevice, this.state.trackingTs);
    } else {
      this.setState({
        showOneDayRoute: false,
      });
    }
  };

  /**
   * onChangeChbxRoutePro()
   *
   * Funkcja
   *
   */
  onChangeChbxRoutePro = (event, state) => {
    this.setState({
      checkedRoutePro: state,
      markerOverlayVisibility: false,
      selectedTableRowId: 0,
      trackingCar: false,
      showOneDayRoute: false,
    });

    this.clearRoutePro();

    if (state) {
      this.setState({
        showRoutePro: true,
      });
    } else {
      this.setState({
        showRoutePro: false,
      });
    }
  };

  /**
   * onChangeChbxOpenStreet()
   *
   * Funkcja
   *
   */
  onChangeChbxOpenStreet = (event, state) => {
    this.setState({
      checkedOpenStreet: state,
    });

    !state
      ? this.state.map.setMapTypeId("roadmap")
      : this.state.map.setMapTypeId("osm");
  };

  /**
   * onClickMyAutocomplete(selected, title)
   *
   * Funkcja
   *
   */
  onClickMyAutocomplete = (selected, title) => {
    console.log(selected);

    this.state.myAutocompleteItem.forEach((item, index) => {
      if (item.title === title) {
        this.setState((state) => ({
          myAutocompleteItem: [
            ...state.myAutocompleteItem.slice(0, index),
            {
              ...state.myAutocompleteItem[index],
              title: title,
              text: selected.label,
              place: selected.place,
            },
            ...state.myAutocompleteItem.slice(index + 1),
          ],
        }));
      }
    });
  };

  /**
   * onChangeMyAutocomplete(event, title)
   *
   * Funkcja
   *
   */
  onChangeMyAutocomplete = async (event, title) => {
    let newInputValue = event.currentTarget.value;

    this.state.myAutocompleteItem.forEach((item, index) => {
      if (item.title === title) {
        this.setState((state) => ({
          myAutocompleteItem: [
            ...state.myAutocompleteItem.slice(0, index),
            {
              ...state.myAutocompleteItem[index],
              title: title,
              text: newInputValue,
              place: null,
            },
            ...state.myAutocompleteItem.slice(index + 1),
          ],
        }));
      }
    });

    if (newInputValue !== "" && newInputValue.length > 1) {
      // Debounce
      return await this.debounce(newInputValue);
    }
  };

  /**
   * debounce()
   *
   * Funkcja
   *
   */
  debounce = (newInputValue) => {
    const that = this;

    // test for coordinates
    const latLonRegexp =
      /^[ \t\r\n]*([0-9]+[.,][0-9]+)[^0-9]{1,3}([0-9]+[.,][0-9]+)[ \t\r\n]*$/;
    if (latLonRegexp.test(newInputValue)) {
      console.log("Współrzędne");
    } else {
      console.log("Fraza");
    }

    var promise = new Promise(function (resolve, reject) {
      if (that.state.myAutocopleteTimeout) {
        clearTimeout(that.state.myAutocopleteTimeout);
      }
      let timeout = setTimeout(function () {
        resolve(that.search(newInputValue));
      }, 1000);
      that.setState({ myAutocopleteTimeout: timeout });
    });

    return promise;
  };

  /**
   * search(term)
   *
   * Funkcja
   *
   */
  search = async (term) => {
    const response = await fetch(`${DBurl}/search?term=${term}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.state.csrf_token,
      },
    });

    this.setState({
      optionsAC: [],
    });

    if (response.status === 200) {
      const json = await response.json();

      if (json.success && json.data !== null) {
        json.data.forEach((item, index) => {
          let label = item.display_name;

          let place = {
            location: { lat: parseFloat(item.lat), lng: parseFloat(item.lon) },
            name: label,
          };

          this.setState({
            optionsAC: [
              ...this.state.optionsAC,
              {
                label: label,
                id: index,
                place: place,
              },
            ],
          });
        });

        return this.state.optionsAC;
      }
    }
  };

  /**
   * onChangeAutocomplete(event, selected)
   *
   * Funkcja
   *
   */
  onChangeAutocomplete = (event, selected) => {
    console.log("onChangeAutocomplete");
    console.log(event);
    console.log(selected);

    if (selected) {
      this.setState({ selectedTableRowId: selected.id });
      this.selectCarById(selected.id);
    }
  };

  /**
   * onInputChangeAutocomplete(event, newInputValue)
   *
   * Funkcja
   *
   */
  onInputChangeAutocomplete = async (event, newInputValue) => {
    console.log("onInputChangeAutocomplete");
    console.log(newInputValue);

    this.setState({ optionsAC: [] });

    if (newInputValue !== "") {
      const response = await fetch(`${DBurl}/loadCarsToMap`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": this.state.csrf_token,
        },
      });

      const json = await response.json();

      json.forEach((car, index) => {
        this.setState({
          optionsAC: [
            ...this.state.optionsAC,
            {
              label: car.name,
              id: car.id,
            },
          ],
        });
      });
    }
  };

  /**
   * directionsCallback(response)
   *
   * Funkcja
   *
   */
  directionsCallback = async (response) => {
    console.log("directionsCallback");
    console.log("response: ", response);

    this.setState({
      destination: "",
      origin: "",
    });

    if (response !== null) {
      if (response.status === "OK") {
        let routeDistance = 0;
        let routeDuration = 0;
        for (let i = 0; i < response.routes[0].legs.length; i++) {
          routeDistance += response.routes[0].legs[i].distance.value;
          routeDuration += response.routes[0].legs[i].duration.value;
        }

        console.log(response);

        if (this.state.idOrderSelected !== null) {
          await this.setOrderPath(
            this.state.idOrderSelected,
            response.routes[0].overview_polyline
          );
        }

        this.setState({
          directionsResponse: response,
          showMyDirectionsRenderer: true,
          routeDistance: (routeDistance / 1000).toFixed(2),
          routeDuration: this.toHHMMSS(routeDuration),
        });
      } else {
      }
    }
  };

  /**
   * setOrderPath(id_order, path_encoded)
   *
   * Funkcja
   *
   */
  setOrderPath = async (id_order, path_encoded) => {
    const response = await fetch(`${DBurl}/setOrderPath`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.state.csrf_token,
      },
      body: JSON.stringify({
        mode: "update",
        data: {
          id_order: id_order,
          path_encoded: path_encoded,
        },
      }),
    });

    if (response.status === 200) {
      const json = await response.json();
    }
  };

  /**
   * distanceMatrixCallback(response)
   *
   * Funkcja
   *
   */
  distanceMatrixCallback = (response) => {
    console.log("distanceMatrixCallback");
    console.log("response: ", response);

    this.setState({
      destinationsMatrix: [],
      originsMatrix: [],
      useMatrix: false,
      pointsListMatrix: [],
      carsListMatrix: [],
    });

    if (response !== null) {
      let carsListMatrix = [];
      let pointsListMatrix = [];

      // Vehicles
      if (this.state.typeMatrix === 1) {
        response.rows.forEach((row, i) => {
          if (row.elements[0].status === "OK") {
            carsListMatrix = [
              ...carsListMatrix,
              {
                carName: this.state.carNames[i],
                carOrigin: this.state.carOrigins[i],
                carDestination: this.state.carDestination,
                distance: row.elements[0].distance.value / 1000,
                distanceValue: row.elements[0].distance.value,
                time: this.toHHMMSS(row.elements[0].duration.value),
                timeValue: row.elements[0].duration.value,
                timeInTraffic: this.toHHMMSS(
                  row.elements[0].duration_in_traffic.value
                ),
              },
            ];
          }
        });

        carsListMatrix.sort((a, b) => {
          return parseInt(a.distanceValue, 10) - parseInt(b.distanceValue, 10);
        });
      }
      // Points
      else if (this.state.typeMatrix === 2) {
        response.rows.forEach((row, i) => {
          if (row.elements[0].status === "OK") {
            pointsListMatrix = [
              ...pointsListMatrix,
              {
                pointName: this.state.pointNames[i],
                pointOrigin: this.state.pointOrigins[i],
                pointDestination: this.state.pointDestination,
                distance: row.elements[0].distance.value / 1000,
                distanceValue: row.elements[0].distance.value,
                time: this.toHHMMSS(row.elements[0].duration.value),
                timeValue: row.elements[0].duration.value,
                timeInTraffic:
                  row.elements[0].duration_in_traffic !== undefined
                    ? this.toHHMMSS(row.elements[0].duration_in_traffic.value)
                    : "-",
              },
            ];
          }
        });

        pointsListMatrix.sort((a, b) => {
          return parseInt(a.timeValue, 10) - parseInt(b.timeValue, 10);
        });
      }

      this.setState({
        distanceMatrixResponse: response,
        carsListMatrix: carsListMatrix,
        pointsListMatrix: pointsListMatrix,
      });
    }
  };

  /**
   * onLoadInfoWindow()
   *
   * Funkcja
   *
   */
  onLoadMyCustomInfoWindow = (infoWindow) => {
    infoWindow.close();

    this.setState((state) => ({
      myCustomInfoWindows: [...state.myCustomInfoWindows, infoWindow],
    }));
  };

  /**
   * onLoadE100InfoWindow()
   *
   * Funkcja
   *
   */
  onLoadE100InfoWindow = (infoWindow) => {
    infoWindow.close();

    this.setState((state) => ({
      e100InfoWindows: [...state.e100InfoWindows, infoWindow],
    }));
  };

  /**
   * onLoadInfoWindowRefuelings()
   *
   * Funkcja
   *
   */
  onLoadMyCustomInfoWindowRefuelings = (infoWindow) => {
    console.log("onLoadMyCustomInfoWindowRefuelings..");
    infoWindow.close();

    this.setState((state) => ({
      myCustomInfoWindowsRefuelings: [
        ...state.myCustomInfoWindowsRefuelings,
        infoWindow,
      ],
    }));
  };

  /**
   * onZoomChangedMap()
   *
   * Funkcja
   *
   */
  onZoomChangedMap = () => {
    if (this.state.map != null) {
      console.log(this.state.map.getZoom());

      if (this.state.map.getZoom() === 6) {
        this.setState({ circleRadius: 60000 });
      } else if (this.state.map.getZoom() === 7) {
        this.setState({ circleRadius: 30000 });
      }
    }
  };

  /**
   * onDirectionsChangedDirectionsResponse()
   *
   * Funkcja
   *
   */
  onDirectionsChangedDirectionsResponse = () => {
    console.log("onDirectionsChangedDirectionsResponse");
  };

  /**
   * onLoadDirectionsResponse(directionsResponse)
   *
   * Funkcja
   *
   */
  onLoadDirectionsResponse = (directionsResponse) => {
    console.log("onLoadDirectionsResponse");
    console.log(directionsResponse);
  };

  /**
   * onMouseOverMarker()
   *
   * Funkcja
   *
   */
  onMouseOverMarker = (id, index, e) => {
    this.state.infoWindows[index].open(
      this.state.map,
      this.state.markers[index]
    );
  };

  /**
   * onMouseOutMarker()
   *
   * Funkcja
   *
   */
  onMouseOutMarker = (id, index, e) => {
    this.state.infoWindows[index].close();
  };

  /**
   * onMouseOverMarkerMyCustom()
   *
   * Funkcja
   *
   */
  onMouseOverMarkerMyCustom = (id, index, e) => {
    if (this.state.myCustomInfoWindows[index] !== undefined) {
      this.state.myCustomInfoWindows[index].open(
        this.state.map,
        this.state.myCustomMarkers[index]
      );
    }
  };

  /**
   * onMouseOutMarkerMyCustom()
   *
   * Funkcja
   *
   */
  onMouseOutMarkerMyCustom = (id, index, e) => {
    if (this.state.myCustomInfoWindows[index] !== undefined) {
      this.state.myCustomInfoWindows[index].close();
    }
  };

  /**
   * onClickMarkerE100()
   *
   * Funkcja
   *
   */
  onClickMarkerE100 = (id, index, e) => {
    console.log("onClickMarkerE100..", index);
    if (this.state.e100InfoWindows[index] !== undefined) {
      // console.log(this.state.e100InfoWindows)
      // console.log(this.state.e100InfoWindows[id],id)
      // console.log(this.state.e100InfoWindows[index],index)
      // console.log('---------------------------------')
      this.state.e100InfoWindows[index].open(
        this.state.map,
        this.state.e100Markers[index]
      );
    }
  };
  /**
   * onMClosetInfoWindowE100()
   *
   * Funkcja
   *
   */
  onCloseInfoWindowE100 = (id, index, e) => {
    console.log("onCloseInfoWindowE100..", index);
    if (this.state.e100InfoWindows[index] !== undefined) {
      this.state.e100InfoWindows[index].close();
      // this.setState({e100InfoWindows: []})
    }
  };
  /**
   * onMouseOverMarkerMyCustomRefuelings()
   *
   * Funkcja
   *
   */
  onMouseOverMarkerMyCustomRefuelings = (id, index, e) => {
    if (this.state.myCustomInfoWindowsRefuelings[index] !== undefined) {
      this.state.myCustomInfoWindowsRefuelings[index].open(
        this.state.map,
        this.state.myCustomMarkersRefuelings[index]
      );
    }
  };

  /**
   * onMouseOutMarkerMyCustomRefuelings()
   *
   * Funkcja
   *
   */
  onMouseOutMarkerMyCustomRefuelings = (id, index, e) => {
    if (this.state.myCustomInfoWindowsRefuelings[index] !== undefined) {
      this.state.myCustomInfoWindowsRefuelings[index].close();
    }
  };

  /**
   * onLoadMap()
   *
   * Funkcja
   *
   */
  onLoadMap = (map) => {
    this.setState({ map: map });

    map.mapTypes.set("osm", osmType);
    map.mapTypes.set("here", hereType);

    if (this.state.clientOSM) {
      map.setMapTypeId("osm");
      map.mapTypes.set("osm", osmType);
    } else {
      map.setMapTypeId("here");
      map.mapTypes.set("here", hereType);
    }
  };

  /**
   * setUpdateRoute(state)
   *
   * Funkcja
   *
   */
  setUpdateRoute = (state) => {
    this.setState({
      updateRoute: state,
    });
  };

  /**
   * setUpdateDirectionsRenderer(state)
   *
   * Funkcja
   *
   */
  setUpdateDirectionsRenderer = (state) => {
    this.setState({
      updateDirectionsRenderer: state,
    });
  };

  /**
   * onClickMap()
   *
   * Funkcja
   *
   */
  onClickMap = async (event) => {
    if (this.state.showRouteMode) {
      this.setState({
        updateRoute: true,
      });

      let index = null;

      for (let i = 0; i < this.state.routeACdata.length; i++) {
        if (this.state.routeACdata[i].place === null) {
          index = i;
          break;
        }
      }

      const lat = event.latLng.lat();
      const lng = event.latLng.lng();

      let reverse = await this.reverse(lat, lng);

      let text = reverse.data.display_name;

      let place = {
        location: { lat: parseFloat(lat), lng: parseFloat(lng) },
      };

      //Jeżeli nie ma null dla place to dodaj nowy na końcu tablicy
      if (index === null) {
        this.setState({
          routeACdata: [
            ...this.state.routeACdata,
            {
              title: `rows${this.state.routeACdata.length + 1}`,
              text: text,
              place: place,
            },
          ],
        });
      } else {
        let title = this.state.routeACdata[index].title;
        this.setState((state) => ({
          routeACdata: [
            ...state.routeACdata.slice(0, index),
            {
              ...state.routeACdata[index],
              title: title,
              text: text,
              place: place,
            },
            ...state.routeACdata.slice(index + 1),
          ],
        }));
      }
    }
  };

  /**
   * onClickSetMapType()
   *
   * Funkcja
   *
   */
  onClickSetMapType = (type) => {
    //'osm', 'roadmap', 'satellite', 'hybrid', 'terrain', 'styled_map'

    this.state.map.setMapTypeId(type);
  };

  /**
   * drawRouteFromHere(destination, origin, waypoints, routeACdata, renderMode, path_encoded, id_order)
   *
   * Funkcja
   *
   */
  drawRouteFromHere = async (
    destination,
    origin,
    waypoints = null,
    routeACdata = null,
    renderMode = "directions",
    path_encoded = null,
    id_order = null,
    locations = null,
    suppressMarkers = false
  ) => {
    // this.polyline2 = "BFoz5xJ67i1B1B7PzIhaxL7Y";
    // const decodePolylineHere = FlexiblePolyline.decode(this.polyline2);
    // console.log('DECODE:', decodePolylineHere);

    const routingRoutes = await this.getAllRoutingRoutesForOrder(id_order);

    // Jeżeli są trasy
    if (routingRoutes !== null) {
      // HERE custom waypoints
      let waypoint_data = [];
      routingRoutes.waypoints.forEach((waypoint) => {
        if (waypoint.type === "custom-waypoint") {
          waypoint_data.push({
            lat: parseFloat(waypoint.address.latitude),
            lng: parseFloat(waypoint.address.longitude),
            type: waypoint.type,
          });
        }
      });

      this.setState({
        showMyCustomWaypointsHere: true,
        myCustomWaypointsHere: waypoint_data,
        // showOneDayRoute: false
      });

      // HERE
      let coordinatesHere = [];
      //const boundsHere = new google.maps.LatLngBounds();
      if (routingRoutes.response.routes.length) {
        routingRoutes.response.routes[0].sections.forEach((section) => {
          const decodedSection = DecodeSygicPolyline(section.polyline);

          // console.log('decodedSection: ',decodedSection);
          decodedSection.polyline.forEach((coord) => {
            coordinatesHere.push({
              lat: parseFloat(coord[0]),
              lng: parseFloat(coord[1]),
            });
            //boundsHere.extend(new google.maps.LatLng(coord[0], coord[1]));
          });
        });

        this.setState({
          polylinePathHere: coordinatesHere,
          showHereRoute: true,
        });

        //this.state.map && this.state.map.fitBounds(boundsHere);
      }

      // Sygic
      let coordinatesSygic = [];
      let validRouteSygic = false;
      const boundsSygic = new google.maps.LatLngBounds();
      if (routingRoutes.sygic !== null) {
        routingRoutes.sygic.routeParts.forEach((routePart) => {
          if (routePart.points !== undefined) {
            validRouteSygic = true;
            routePart.points.forEach((point) => {
              let lat = parseFloat(point.lat / 100000);
              let lng = parseFloat(point.lon / 100000);

              coordinatesSygic.push({
                lat: lat,
                lng: lng,
              });
              boundsSygic.extend(new google.maps.LatLng(lat, lng));
            });
          }
        });

        if (validRouteSygic) {
          this.setState({
            polylinePathSygic: coordinatesSygic,
            showSygicRoute: true,
          });

          this.state.map && this.state.map.fitBounds(boundsSygic);
        }
      }

      // let coordinates = [];
      // const bounds = new google.maps.LatLngBounds();
      // decodePolylineHere.polyline.forEach((row, index) => {

      // 	console.log('row: ',row);
      // 	coordinates.push({
      // 		lat: parseFloat(row[0]),
      // 		lng: parseFloat(row[1])
      // 	});
      // 	bounds.extend(new google.maps.LatLng(row[0], row[1]));

      // });

      // this.setState({
      // 	polylinePathHere: coordinates,
      // 	showHereRoute: true
      // });

      // this.state.map && this.state.map.fitBounds(bounds);
    }
  };

  /**
   * getAllRoutingRoutesForOrder()
   *
   * Funkcja
   *
   */
  getAllRoutingRoutesForOrder = async (id_order) => {
    const response = await fetch(
      `${DBurl}/getAllRoutingRoutesForOrder?id_order=${id_order}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": this.state.csrf_token,
        },
      }
    );
    const json = await response.json();

    if (json.success) {
      // console.log('ok');
      return json.data;
    } else {
      // console.log('error');
      return false;
    }
  };

  /**
   * doRoute(destination, origin, waypoints, routeACdata, renderMode, path_encoded, id_order)
   *
   * Funkcja
   *
   */
  doRoute = (
    destination,
    origin,
    waypoints = null,
    routeACdata = null,
    renderMode = "directions",
    path_encoded = null,
    id_order = null,
    locations = null,
    suppressMarkers = false
  ) => {
    let useDirectionServices = true;
    let showMyDirectionsRenderer = false;

    // ####### Temp - use always directions #########
    renderMode = "directions";
    path_encoded = null;
    // ##############################################

    if (renderMode === "polyline") {
      this.setState({
        showRouteMode: false,
        updateDirectionsRenderer: true,
      });

      //this.clearDirections();
    }

    if (renderMode === "polyline" && path_encoded !== null) {
      useDirectionServices = false;
      showMyDirectionsRenderer = true;
    }

    this.setState({
      directionsResponse: null,
      destination: { lat: destination.lat, lng: destination.lng },
      origin: { lat: origin.lat, lng: origin.lng },
      waypoints: waypoints !== null ? waypoints : [],
      routeACdata: routeACdata !== null ? routeACdata : [],
      renderMode: renderMode,
      pathEncoded: path_encoded,
      showMyDirectionsRenderer: showMyDirectionsRenderer,
      useDirectionServices: useDirectionServices,
      idOrderSelected: id_order,
      orderLocations: locations,
      suppressMarkers: suppressMarkers,
    });
  };

  /**
   * addMyCustomMarkers(markersData)
   *
   * Funkcja
   *
   */
  addMyCustomMarkers = (markersData) => {
    console.log("addMyCustomMarkers:");
    console.log(markersData);
    this.setState({
      showMyCustomMarker: true,
      myCustomMarkerPoints: markersData,
    });
  };

  /**
   * addE100Markers(markersData)
   *
   * Funkcja
   *
   */
  addE100Markers = (markersData) => {
    console.log("addE100Markers..", markersData);
    this.setState({
      showE100Marker: true,
      e100MarkerPoints: markersData,
    });
  };

  /**
   * addMyCustomMarkersRefuelings(markersData)
   *
   * Funkcja
   *
   */
  addMyCustomMarkersRefuelings = (markersData) => {
    console.log("addMyCustomMarkerssRefuelings:");
    console.log(markersData);
    this.setState({
      showMyCustomMarkerRefuelings: true,
      myCustomMarkerPointsRefuelings: markersData,
    });

    const bounds = new google.maps.LatLngBounds();
    markersData.forEach((marker, index) => {
      bounds.extend(new google.maps.LatLng(marker.lat, marker.lng));
    });
    this.state.map && this.state.map.fitBounds(bounds);
  };

  /**
   * onClickTestDistanceMatrix()
   *
   * Funkcja
   *
   */
  onClickTestDistanceMatrix = () => {
    console.log(this.state.markersData);

    let originsMatrix = [];
    let carNames = [];
    let carOrigins = [];
    this.state.markersData.forEach((marker, i) => {
      originsMatrix = [
        ...originsMatrix,
        { lat: marker.position.lat, lng: marker.position.lng },
      ];
      carNames = [...carNames, marker.name];
      carOrigins = [
        ...carOrigins,
        { lat: marker.position.lat, lng: marker.position.lng },
      ];
    });

    let destinationMatrix = {
      lat: parseFloat(this.getRndInteger(50, 52) + ".14"),
      lng: parseFloat(this.getRndInteger(18, 20) + ".51"),
    };

    this.showMarker(destinationMatrix.lat, destinationMatrix.lng);

    this.setState({
      useMatrix: true,
      distanceMatrixResponse: null,
      destinationsMatrix: [
        { lat: destinationMatrix.lat, lng: destinationMatrix.lng },
      ],
      originsMatrix: originsMatrix,
      carNames: carNames,
      carOrigins: carOrigins,
      carDestination: {
        lat: destinationMatrix.lat,
        lng: destinationMatrix.lng,
      },
    });
  };

  /**
   * onClickFindClosestVehicle(lat, lng)
   *
   * Funkcja
   *
   */
  onClickFindClosestVehicle = (lat, lng) => {
    console.log(this.state.markersData);

    let originsMatrix = [];
    let carNames = [];
    let carOrigins = [];

    this.state.markersData.forEach((marker, i) => {
      originsMatrix = [
        ...originsMatrix,
        { lat: marker.position.lat, lng: marker.position.lng },
      ];
      carNames = [...carNames, marker.name];
      carOrigins = [
        ...carOrigins,
        { lat: marker.position.lat, lng: marker.position.lng },
      ];
    });

    let destinationMatrix = {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    };

    this.showMarker(destinationMatrix.lat, destinationMatrix.lng);

    this.setState({
      useMatrix: true,
      typeMatrix: 1,
      distanceMatrixResponse: null,
      destinationsMatrix: [
        { lat: destinationMatrix.lat, lng: destinationMatrix.lng },
      ],
      originsMatrix: originsMatrix,
      carNames: carNames,
      carOrigins: carOrigins,
      carDestination: {
        lat: destinationMatrix.lat,
        lng: destinationMatrix.lng,
      },
      showMyDirectionsRenderer: false,
    });
  };

  /**
   * onClickFindClosestPoint(lat, lng, datetime, dt_condition)
   *
   * Funkcja
   *
   */
  onClickFindClosestPoint = (lat, lng, datetime, dt_condition) => {
    const dt = datetime.replace("T", " ");
    const dt_time = Date.parse(dt);

    // console.log(dt);
    // console.log(dt_time);

    // console.log(dt_condition);

    let originsMatrix = [];
    let pointNames = [];
    let pointOrigins = [];

    this.setState({
      originsMatrix: originsMatrix,
      pointNames: pointNames,
      pointOrigins: pointOrigins,
      pointsListMatrix: [],
      carsListMatrix: [],
      showMyDirectionsRenderer: false,
    });

    this.state.tableRows.forEach((row, i) => {
      if (row.activeOrderData.exists) {
        const order_no = row.activeOrderData.order_no;

        row.activeOrderData.order_locations.forEach((location, i) => {
          if (location.lattitude !== null && location.longitude !== null) {
            // console.log(location.ts);
            // console.log(Date.parse(location.ts));

            const locationTs_time = Date.parse(location.ts);
            let type = "";

            switch (location.type) {
              case "loading":
                type = "Załadunek";
                break;
              case "unloading":
                type = "Rozładunek";
                break;
              case "stop":
                type = "Punkt";
                break;
            }

            let subtype = "";
            if (location.subtype !== null) {
              switch (location.subtype) {
                case "parking":
                  subtype = " (Parking)";
                  break;
                case "ferry":
                  subtype = " (Prom)";
                  break;
                case "start":
                  subtype = " (Początek trasy)";
                  break;
                case "end":
                  subtype = " (Koniec trasy)";
                  break;
                case "border":
                  subtype = " (Przejście graniczne)";
                  break;
                case "point":
                  subtype = " (Punkt kontrolny)";
                  break;
              }
            }

            // przed i po (domyślnie)
            if (dt_condition === "1") {
              // console.log("1!");
              originsMatrix = [
                ...originsMatrix,
                {
                  lat: parseFloat(location.lattitude),
                  lng: parseFloat(location.longitude),
                },
              ];
              pointNames = [
                ...pointNames,
                "[" + order_no + "] " + type + subtype,
              ];
              pointOrigins = [
                ...pointOrigins,
                {
                  lat: parseFloat(location.lattitude),
                  lng: parseFloat(location.longitude),
                },
              ];
            }
            // tylko przed
            else if (dt_condition === "2") {
              // console.log("2!");
              // console.log(dt_time + ' >');
              // console.log(locationTs_time);
              if (dt_time > locationTs_time) {
                // console.log("TAK");
                originsMatrix = [
                  ...originsMatrix,
                  {
                    lat: parseFloat(location.lattitude),
                    lng: parseFloat(location.longitude),
                  },
                ];
                pointNames = [
                  ...pointNames,
                  "[" + order_no + "] " + type + subtype,
                ];
                pointOrigins = [
                  ...pointOrigins,
                  {
                    lat: parseFloat(location.lattitude),
                    lng: parseFloat(location.longitude),
                  },
                ];
              } else {
                // console.log("NIE");
              }
            }
            // tylko po
            else if (dt_condition === "3") {
              // console.log("3!");
              // console.log(dt_time + ' <');
              // console.log(locationTs_time);
              if (dt_time < locationTs_time) {
                // console.log("TAK");
                originsMatrix = [
                  ...originsMatrix,
                  {
                    lat: parseFloat(location.lattitude),
                    lng: parseFloat(location.longitude),
                  },
                ];
                pointNames = [
                  ...pointNames,
                  "[" + order_no + "] " + type + subtype,
                ];
                pointOrigins = [
                  ...pointOrigins,
                  {
                    lat: parseFloat(location.lattitude),
                    lng: parseFloat(location.longitude),
                  },
                ];
              } else {
                // console.log("NIE");
              }
            }
          }
        });
      }
    });

    let destinationMatrix = {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    };

    this.showMarker(destinationMatrix.lat, destinationMatrix.lng);

    if (originsMatrix.length > 0) {
      this.setState({
        useMatrix: true,
        typeMatrix: 2,
        distanceMatrixResponse: null,
        destinationsMatrix: [
          { lat: destinationMatrix.lat, lng: destinationMatrix.lng },
        ],
        originsMatrix: originsMatrix,
        pointNames: pointNames,
        pointOrigins: pointOrigins,
        pointDestination: {
          lat: destinationMatrix.lat,
          lng: destinationMatrix.lng,
        },
      });
    }
  };

  /**
   * onClickCarMatrixDoRoute(item)
   *
   * Funkcja
   *
   */
  onClickCarMatrixDoRoute = (item) => {
    this.hideMarker();

    if (this.state.typeMatrix === 1) {
      this.doRoute(item.carDestination, item.carOrigin);
    } else if (this.state.typeMatrix === 2) {
      this.doRoute(item.pointDestination, item.pointOrigin);
    }
  };

  /**
   * onClickTableRow(row)
   *
   * Funkcja
   *
   */
  onClickTableRow = (row) => {
    console.log("onClickTableRow");
    console.log(row);

    this.setState({
      selectedTableRowId: row.id,
    });
    this.selectCarById(row.id);
  };

  /**
   * onClickTableSetTheRoute(row)
   *
   * Funkcja
   *
   */
  onClickTableSetTheRoute = async (row) => {
    this.setState({
      showRouteMode: false,
    });
    this.clearDirections();

    let reverse = await this.reverse(row.lat, row.lng);

    let title = this.state.routeACdata[0].title;
    let text = reverse.data.display_name;

    let place = {
      location: { lat: parseFloat(row.lat), lng: parseFloat(row.lng) },
    };

    const index = 0;

    this.setState((state) => ({
      routeACdata: [
        ...state.routeACdata.slice(0, index),
        {
          ...state.routeACdata[index],
          title: title,
          text: text,
          place: place,
        },
        ...state.routeACdata.slice(index + 1),
      ],
    }));

    setTimeout(() => {
      console.log(this.state.routeACdata);
      this.setState({
        showRouteMode: true,
        showSearchFuel: false,
        showSearchAndPlanMode: false,
      });
    }, 100);
  };

  /**
   * reverse(lat, lng)
   *
   * Funkcja
   *
   */
  reverse = async (lat, lng) => {
    const response = await fetch(`${DBurl}/reverse?lon=${lng}&lat=${lat}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.state.csrf_token,
      },
    });

    if (response.status === 200) {
      const json = await response.json();
      return json;
    }
  };

  /**
   * toHHMMSS()
   *
   * Funkcja
   *
   */
  toHHMMSS = (sec) => {
    var sec_num = parseInt(sec, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    var time = hours + ":" + minutes + ":" + seconds;

    return time;
  };

  /**
   * onClickShowAllCars()
   *
   * Funkcja
   *
   */
  onClickShowAllCars = () => {
    this.clearDirections();
    this.clearRoutePro();

    const bounds = new google.maps.LatLngBounds();
    this.state.markersData.forEach((marker, index) => {
      bounds.extend(
        new google.maps.LatLng(marker.position.lat, marker.position.lng)
      );
    });
    this.state.map && this.state.map.fitBounds(bounds);

    this.setState({
      markerOverlayVisibility: false,
      selectedTableRowId: 0,
      trackingCar: false,
      showOneDayRoute: false,
    });
  };

  /**
   * onClickShowRoutePro()
   *
   * Funkcja
   *
   */
  onClickShowRoutePro = () => {
    this.clearDirections();
    this.clearRoutePro();

    this.setState({
      showRoutePro: true,
    });
  };

  /**
   * showPOIOrder()
   *
   * Funkcja
   *
   */
  showPOIOrder = (event, location) => {
    event.stopPropagation();
    const bounds = new google.maps.LatLngBounds();
    bounds.extend(
      new google.maps.LatLng(location.lattitude, location.longitude)
    );
    this.state.map && this.state.map.fitBounds(bounds);
  };

  /**
   * onClickCenterOrderRoute()
   *
   * Funkcja
   *
   */
  onClickCenterOrderRoute = (event, locations) => {
    event.stopPropagation();

    if (this.state.showHereRoute) {
      const bounds = new google.maps.LatLngBounds();
      locations.forEach((location, index) => {
        bounds.extend(
          new google.maps.LatLng(location.lattitude, location.longitude)
        );
      });
      this.state.map && this.state.map.fitBounds(bounds);
    }
  };

  /**
   * onClickCenterOrderProgressRoute()
   *
   * Funkcja
   *
   */
  onClickCenterOrderProgressRoute = (event, location) => {
    event.stopPropagation();
    const bounds = new google.maps.LatLngBounds();

    if (this.state.showPlacesMarker) {
      bounds.extend(this.state.placesMarkerPosition);
      this.state.map && this.state.map.fitBounds(bounds);
    } else if (this.state.showOrderRouteProgress) {
      this.state.polylinePathOrderRouteProgress.forEach((location, index) => {
        bounds.extend(new google.maps.LatLng(location.lat, location.lng));
      });
      this.state.map && this.state.map.fitBounds(bounds);
    }
  };

  /**
   * boundsToRoute()
   *
   * Funkcja
   *
   */
  boundsToRoute = () => {
    const bounds = new google.maps.LatLngBounds();
    this.state.orderLocations.map((location, index) => {
      bounds.extend(
        new google.maps.LatLng(location.lattitude, location.longitude)
      );
    });
    this.state.map && this.state.map.fitBounds(bounds);
  };

  getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  degToRad = (deg) => {
    return deg * (Math.PI / 180);
  };

  arrowIconRight = () => {
    return (
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="1"
        viewBox="0 0 512 512"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M192 128l128 128-128 128z"></path>
      </svg>
    );
  };

  filtrujStacjeBenzynowe = (stacje) => {
    let tmpStationsOut = stacje.filter((o) => {
      return o.external_category_id === 1;
    });
    let tmpStationsIn = stacje.filter((o) => {
      return o.external_category_id !== 1;
    });

    const kategoriePierwszejTablicy = tmpStationsOut.map((o) => o.country);
    const kategorieDrugiejTablicy = tmpStationsIn.map((o) => o.country);

    let kategoriePierwszejTablicy_temp = kategoriePierwszejTablicy.filter(
      (value, index, self) => {
        return self.indexOf(value) === index;
      }
    );
    let kategorieDrugiejTablicy_temp = kategorieDrugiejTablicy.filter(
      (value, index, self) => {
        return self.indexOf(value) === index;
      }
    );

    const roznica = (t1, t2) => {
      let szukane = [];

      for (let el of t1) {
        if (!t2.includes(el) && !szukane.includes(el)) {
          szukane.push(el);
        }
      }

      for (let el of t2) {
        if (!t1.includes(el) && !szukane.includes(el)) {
          szukane.push(el);
        }
      }

      return szukane;
    };

    let doDodania = roznica(
      kategoriePierwszejTablicy_temp,
      kategorieDrugiejTablicy_temp
    );
    // let toOptionsCountry = doDodania.concat(kategorieDrugiejTablicy_temp)

    let tempArr = [];

    if (doDodania.length > 0) {
      for (let el of doDodania) {
        let tempItem = tmpStationsOut.filter((o) => {
          return o.country === el;
        });
        for (let ti of tempItem) {
          tempArr.push(ti);
        }
      }
    }

    const outArr =
      tempArr.length > 0 ? tempArr.concat(tmpStationsIn) : tmpStationsIn;
    return outArr;
  };

  showMarkersE100 = () => {
    if (this.state.showSearchFuel && this.state.markersE100.length > 0) {
      return this.displayMarkers();
    }
  };

  handleOptionsRadioE100Click = (event) => {
    this.setState({ radioOptionsE100: "" });
  };

  handleOptionsRadioE100 = (event) => {
    this.setState({ radioOptionsE100: event.target.value });
  };

  getE100GasStations = async () => {
    const response = await fetch(`${DBurl}/getE100GasStations`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.state.csrf_token,
      },
    });
    const json = await response.json();

    if (json.success === true) {
      let tmpArr = this.filtrujStacjeBenzynowe(json.data);

      // console.log('tmpArr',tmpArr.length, tmpArr);

      if (json.data.length > 0) {
        // tmpArr.length = 100
        // console.log('tmpArr',tmpArr)
      } else {
        console.log("brak_e100..");
      }

      const tmpE100 = tmpArr.map((o) => ({
        id: o.id,
        id_e100: o.id,
        country: o.country,
        type_e100: o.external_category_id,
        name: o.station + " - " + o.brand,
        station: o.station,
        position: {
          lat: Number(o.latitude),
          lng: Number(o.longitude),
        },
        address: o.address,
        selected: false,
        icon: {
          url: require(`../../img/icons/e100_${o.external_category_id}.png`),
          size: {
            width: 46,
            height: 46,
          },
          origin: {
            x: 0,
            y: 0,
          },
          anchor: {
            x: 23,
            y: 23,
          },
        },
        last_transaction: [
          {
            id: 0,
            fuel_card_number: "00001232231",
            name: "E97",
            type: 1,
            quantity: 33,
          },
          {
            id: 1,
            fuel_card_number: "00001232222",
            name: "E100",
            type: 1,
            quantity: 65,
          },
          {
            id: 2,
            fuel_card_number: "00001232113",
            name: "B7",
            type: 2,
            quantity: 130,
          },
          {
            id: 3,
            fuel_card_number: "00001232999",
            name: "B7",
            type: 2,
            quantity: 330,
          },
        ],
        fuel_card_limit: [
          {
            id: 1,
            card_id: "0000023400403",
            limits: [
              {
                id: 1,
                name: "E95",
                limit: 124,
              },
              {
                id: 1,
                name: "E97",
                limit: 3,
              },
              {
                id: 2,
                name: "E100",
                limit: 0,
              },
              {
                id: 3,
                name: "B7",
                limit: 5000,
              },
              {
                id: 4,
                name: "B10",
                limit: 1500,
              },
              {
                id: 5,
                name: "LPG",
                limit: 0,
              },
            ],
          },
          {
            id: 1,
            card_id: "0000023400404",
            limits: [
              {
                id: 1,
                name: "E95",
                limit: 1000,
              },
              {
                id: 1,
                name: "E97",
                limit: 2000,
              },
              {
                id: 2,
                name: "E100",
                limit: 0,
              },
              {
                id: 3,
                name: "B7",
                limit: 0,
              },
              {
                id: 4,
                name: "B10",
                limit: 0,
              },
              {
                id: 5,
                name: "LPG",
                limit: 60,
              },
            ],
          },
        ],
        fuel: [
          {
            id: 0,
            name: "E95",
            type: 1,
            price: 6.55,
          },
          {
            id: 1,
            name: "E97",
            type: 1,
            price: 7.32,
          },
          {
            id: 2,
            name: "E100",
            type: 1,
            price: 8.21,
          },
          {
            id: 3,
            name: "B7",
            type: 2,
            price: 7.76,
          },
          {
            id: 4,
            name: "B10",
            type: 2,
            price: 8.57,
          },
          {
            id: 5,
            name: "LPG",
            type: 3,
            price: 3.22,
          },
        ],
        limits: [
          {
            id: 0,
            name: "E95",
            type: 1,
            limit: 0,
          },
          {
            id: 1,
            name: "E97",
            type: 1,
            limit: 1230,
          },
          {
            id: 2,
            name: "E100",
            type: 1,
            limit: 20,
          },
          {
            id: 3,
            name: "B7",
            type: 2,
            limit: 70,
          },
          {
            id: 4,
            name: "B10",
            type: 2,
            limit: 1000,
          },
          {
            id: 5,
            name: "LPG",
            type: 3,
            limit: 0,
          },
        ],
      }));

      this.setState({
        markersE100: tmpE100,
        markersE100_const: tmpE100,
      });
    } else {
      console.log("Błąd komunikacji z API..");
    }
  };

  getE100FuelPrices = async (station_name) => {
    this.setState({ loadingFulePrice: true });
    const response = await fetch(
      `${DBurl}/getE100FuelPrices?station=${station_name}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": this.state.csrf_token,
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      if (json.data !== null) {
        // console.log("getE100FuelPrices",json.data)
        this.setState({ selected_e100_station_price: json.data });
        this.setState({ loadingFulePrice: false });
      }
    } else {
      this.setState({ loadingFulePrice: false });
    }
  };

  getE100Cards = async () => {
    const response = await fetch(`${DBurl}/getE100Cards`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.state.csrf_token,
      },
    });
    const json = await response.json();
    if (json.data?.length > 0) {
      // console.log("getE100Cards",json.data)
      let options = json.data.map((o) => {
        return {
          label: o.card,
          value: o.card,
        };
      });
      this.setState({ e100_cards: options });
    }
  };

  getE100Transactions = async (station_id) => {
    this.setState({ loadingTransaction: true });
    const response = await fetch(
      `${DBurl}/getE100Transactions?station_id=${station_id}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": this.state.csrf_token,
        },
      }
    );
    const json = await response.json();
    if (json.data) {
      this.setState({
        e100_transactions: json.data,
        loadingTransaction: false,
      });
    } else {
      this.setState({
        e100_transactions: [],
        loadingTransaction: false,
      });
    }
  };

  getE100Limits = async (station_name, card) => {
    this.setState({ loadingLimits: true });
    const response = await fetch(
      `${DBurl}/getE100Limits?station=${station_name}&card=${card}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": this.state.csrf_token,
        },
      }
    );
    const json = await response.json();
    if (json.success) {
      if (json.data === null) {
        this.setState({ loadingLimits: false });
      } else {
        this.setState({ e100_limits: json.data });
        this.setState({ loadingLimits: false });
      }
    } else {
      this.setState({ loadingLimits: false });
    }
  };

  routeE100 = async (point, array, type) => {
    this.setState({
      loading: true,
      polylinePath: [],
      route_e100: [],
    });

    const temp_array = [];

    const routeFunc = async (routingParameters) => {
      const routingService = this.platform.getRoutingService(null, 8);
      try {
        const result = await routingService.calculateRoute(routingParameters);
        if (result.routes.length > 0) {
          this.setState((prevState) => ({
            route_e100: [...prevState.route_e100, result.routes[0]],
          }));

          let decodedPolyline = DecodeSygicPolyline(
            result.routes[0].sections[0].polyline
          );

          const bounds = new google.maps.LatLngBounds();
          decodedPolyline.polyline.forEach((o, i) => {
            bounds.extend(new google.maps.LatLng(o[0], o[1] - 0.017));
          });

          this.state.map && this.state.map.fitBounds(bounds);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.setState({ loading: false });
      }
    };

    let loops = array.length < 3 ? array.length : 3;
    for (let i = 0; i < loops; i++) {
      temp_array.push(array[i]);
    }

    if (type === "3_E100") {
      for (let i = 0; temp_array.length > i; i++) {
        let routingParameters = {
          routingMode: "short",
          transportMode: "truck",
          "vehicle[grossWeight]": 40000,
          "vehicle[weightPerAxle]": 10000,
          "vehicle[height]": 400,
          "vehicle[width]": 260,
          "vehicle[length]": 1650,
          "vehicle[axleCount]": 3,
          "vehicle[type]": "straightTruck",
          "vehicle[trailerCount]": 1,
          lang: "pl-pl",
          destination: [temp_array[i].position.lat, temp_array[i].position.lng],
          origin: [point.lat, point.lng],
          currency: "Pln",
          return: "polyline,summary,travelSummary",
        };

        routeFunc(routingParameters);
      }
    } else if (type === "3_cars") {
      for (let i = 0; temp_array.length > i; i++) {
        let routingParameters = {
          routingMode: "short",
          transportMode: "truck",
          "vehicle[grossWeight]": 40000,
          "vehicle[weightPerAxle]": 10000,
          "vehicle[height]": 400,
          "vehicle[width]": 260,
          "vehicle[length]": 1650,
          "vehicle[axleCount]": 3,
          "vehicle[type]": "straightTruck",
          "vehicle[trailerCount]": 1,
          lang: "pl-pl",
          origin: [temp_array[i].position.lat, temp_array[i].position.lng],
          destination: [point.position.lat, point.position.lng],
          currency: "Pln",
          return: "polyline,summary,travelSummary",
        };

        routeFunc(routingParameters);
      }
    } else if (type === "pointToPoint") {
      let routingParameters = null;
      if (array?.id !== undefined) {
        let car = [array.lat, array.lng];
        let e100_station = [point.position.lat, point.position.lng];

        routingParameters = {
          routingMode: "short",
          transportMode: "truck",
          "vehicle[grossWeight]": 40000,
          "vehicle[weightPerAxle]": 10000,
          "vehicle[height]": 400,
          "vehicle[width]": 260,
          "vehicle[length]": 1650,
          "vehicle[axleCount]": 3,
          "vehicle[type]": "straightTruck",
          "vehicle[trailerCount]": 1,
          lang: "pl-pl",
          origin: car,
          destination: e100_station,
          currency: "Pln",
          return: "polyline,summary,travelSummary",
        };
        routeFunc(routingParameters);
      } else {
        let points = [array[0], array[1]];
        let e100_station = [point.position.lat, point.position.lng];

        routingParameters = {
          routingMode: "short",
          transportMode: "truck",
          "vehicle[grossWeight]": 40000,
          "vehicle[weightPerAxle]": 10000,
          "vehicle[height]": 400,
          "vehicle[width]": 260,
          "vehicle[length]": 1650,
          "vehicle[axleCount]": 3,
          "vehicle[type]": "straightTruck",
          "vehicle[trailerCount]": 1,
          lang: "pl-pl",
          origin: points,
          destination: e100_station,
          currency: "Pln",
          return: "polyline,summary,travelSummary",
        };

        routeFunc(routingParameters);
      }
    }
  };

  handleCheckboxCountry = (e) => {
    this.setState({ country_selected: e });
    // console.log("handleCheckboxCountry e",e)

    // if(e === false){
    // 	if(this.state.markersE100.length < this.state.markersE100_const.length){

    // 		this.setState({ shouldReload: true }, () => {

    // 			this.setState({
    // 				shouldReload: false,
    // 				markersE100: this.state.markersE100_const
    // 			});

    // 			const bounds = new google.maps.LatLngBounds();
    // 			this.state.markersE100_const.forEach((marker, i) => {
    // 				bounds.extend(new google.maps.LatLng(marker.position.lat, marker.position.lng));
    // 			});

    // 			this.state.map && this.state.map.fitBounds(bounds);

    // 		});

    // 	}
    // }
  };

  handleCheckboxRadiusKm = (e) => {
    this.setState({ radius_km_checked: e });
  };

  handleCheckboxOnRoad = (e) => {
    this.setState({ on_road_checked: e });
  };

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    this.setState({ [name]: checked });

    if (name === "checkE100_all") {
      if (this.state.checkE100_all === true) {
        this.setState({
          checkE100_1: false,
          checkE100_2: false,
          checkE100_3: false,
          checkE100_4: false,
          checkE100_all: false,
        });
      } else {
        this.setState({
          checkE100_1: true,
          checkE100_2: true,
          checkE100_3: true,
          checkE100_4: true,
          checkE100_all: true,
        });
      }
    }
  };

  handleCardLimit = (e) => {
    this.setState({
      selectedFuelCard: e,
      e100_limits: [],
    });
    let card_number = e.value;
    let station = this.state.markersE100.filter((o) => {
      return o.id === this.state.open_markerId;
    })[0].station;

    if (card_number !== "" && station !== "") {
      this.getE100Limits(station, card_number);
    }
  };

  findNearestVehicle = (latA, lgnA, latB, lgnB) => {
    const earthRadius = 6371; // promień Ziemi w km
    const degLat = this.degToRad(latB - latA);
    const degLng = this.degToRad(lgnB - lgnA);
    const a =
      Math.sin(degLat / 2) * Math.sin(degLat / 2) +
      Math.cos(this.degToRad(latA)) *
        Math.cos(this.degToRad(latB)) *
        Math.sin(degLng / 2) *
        Math.sin(degLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadius * c;

    return distance;
  };

  getClosestStations = (stations, path, vehicleLocation) => {
    // console.log('stations',stations)
    // console.log('path',path)
    // console.log('vehicleLocation',vehicleLocation)

    const maxDistance = 10; // maksymalna odległość od ścieżki
    const stationsOnRoad = stations.filter((station) => {
      for (let i = 0; i < path.length - 1; i++) {
        const distanceToPath = this.findNearestVehicle(
          station.position.lat,
          station.position.lng,
          path[i].lat,
          path[i].lng
        );
        if (distanceToPath <= maxDistance) {
          return true;
        }
      }
      return false;
    });

    // console.log('stationsOnRoad',stationsOnRoad)

    const distances = stationsOnRoad.map((station) => {
      const distanceToVehicle = this.findNearestVehicle(
        vehicleLocation.lat,
        vehicleLocation.lng,
        station.position.lat,
        station.position.lng
      );
      return { station, distanceToVehicle };
    });

    // console.log('distances',distances)

    distances.sort((a, b) => a.distanceToVehicle - b.distanceToVehicle);

    return distances.slice(0, 3).map(({ station }) => station);
    // return stationsOnRoad
  };

  handleMarkerHover = (marker) => {
    this.setState({ hoveredMarker: marker });
  };

  handleMarkerHoverLeave = () => {
    this.setState({ hoveredMarker: null });
  };

  displayMarkers = () => {
    // const fuleCardList = this.state.fuelCardList.map((o, i) => {
    // 	return { value: o.id_fuel_card, label: o.id_fuel_card }
    // }) || []

    let data = this.state.markersE100;

    if (data.length > 0) {
      let markers = (claster) =>
        data.map((o, i) => {
          return (
            <>
              <Marker
                key={i}
                id={o.id}
                position={o.position}
                name={o.name}
                icon={o.icon}
                clusterer={claster}
                onClick={() => this.handleMarkerClickE100(o.id)}
              >
                {o.showInfo && (
                  <InfoWindow
                    onCloseClick={() => this.handleInfoWindowCloseE100(o.id)}
                    onDomReady={(e) => this.onDomReadyInfoWindow(e)}
                    onLoad={(infoWindow) => {
                      this.infoWindowsRef[o.id] = infoWindow;
                    }}
                  >
                    <div className={styles.scrollFixGMAPE100}>
                      <Grid className={styles.modalE100Header} container>
                        <Grid item xs={true} style={{ flexGrow: 1 }}>
                          <Grid container>
                            <Grid item>
                              <img
                                className={styles.modalE100Logo}
                                src={require(`../../img/icons/e100_${o.type_e100}.png`)}
                                alt="icon"
                              ></img>
                            </Grid>
                            <Grid className={styles.modalE100Name} item>
                              {o.name}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={"auto"}>
                          <div className={styles.windowOptions}>
                            {o.minimized === undefined ||
                            o.minimized === false ? (
                              <button
                                className={styles.modalE100MinimizeButton}
                                onClick={() =>
                                  this.handleInfoWindowMinimizeE100(o.id)
                                }
                              >
                                <Minimize style={{ maxHeight: "20px" }} />
                              </button>
                            ) : (
                              <button
                                className={styles.modalE100MaximizeButton}
                                onClick={() =>
                                  this.handleInfoWindowMaximizeE100(o.id)
                                }
                              >
                                <Maximize
                                  style={{
                                    maxHeight: "20px",
                                    maxWidth: "16px",
                                    position: "relative",
                                    top: "2px",
                                  }}
                                />
                              </button>
                            )}

                            <button
                              className={styles.modalE100CloseButton}
                              onClick={() =>
                                this.handleInfoWindowCloseE100(o.id)
                              }
                            >
                              <X />
                            </button>
                          </div>
                        </Grid>
                      </Grid>

                      <Grid className={styles.modalE100Body} container>
                        <Grid item xs={12}>
                          <h3>Ceny paliw:</h3>
                        </Grid>

                        {this.state.loadingFulePrice ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <ClipLoader
                              color={"#969696"}
                              loading={this.state.loadingFulePrice}
                              width={"100%"}
                              speedMultiplier={1}
                              cssOverride={this.state.override}
                            />
                          </div>
                        ) : (
                          <Grid item xs={12}>
                            <div className={styles.fuelBox}>
                              {this.state.selected_e100_station_price.length > 0
                                ? this.state.selected_e100_station_price.map(
                                    (m, i) => {
                                      if (i < 3) {
                                        let price = Number(
                                          m.price_client
                                        ).toFixed(2);

                                        const getType = (name) => {
                                          if (
                                            name.includes("Petrol") ||
                                            name.includes("95") ||
                                            name.includes("98")
                                          ) {
                                            return "#e7eec0";
                                          } else if (name.includes("Diesel")) {
                                            return "#e8e8e8";
                                          } else if (name.includes("AdBlue")) {
                                            return "rgb(202 219 255 / 61%)";
                                          } else if (
                                            name.includes(
                                              "Liquefied hydrocarbon"
                                            ) ||
                                            name.includes("LPG")
                                          ) {
                                            return "rgb(179 226 229 / 65%)";
                                          } else {
                                            return "#e7ebee";
                                          }
                                        };

                                        return (
                                          <>
                                            <div className={styles.fuelItem}>
                                              <div className={styles.fuelName}>
                                                {m.service_nt_title}
                                              </div>
                                              <div
                                                className={styles.dotts}
                                              ></div>
                                              <div
                                                className={styles.circlePrice}
                                              >
                                                <p
                                                  style={{
                                                    background: getType(
                                                      m.service_nt_title
                                                    ),
                                                  }}
                                                >
                                                  {price}{" "}
                                                  <small>
                                                    {
                                                      this.state
                                                        .selected_e100_station_price[
                                                        i
                                                      ].currency_client
                                                    }
                                                  </small>
                                                </p>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      } else {
                                        return "";
                                      }
                                    }
                                  )
                                : "Brak cen.."}
                            </div>
                          </Grid>
                        )}

                        {/*
											{this.state.loadingFulePrice ? this.state.selected_e100_station_price.length > 0 ?
												<Grid item xs={12}>
													<div className={styles.fuelBox}>
														{this.state.selected_e100_station_price.map((m,i)=>{

															let price = Number(m.price_client).toFixed(2)

															const getType = (name) => {
																if(name.includes("Petrol") || name.includes("95") || name.includes("98")){
																	return "#e7eec0"
																}else if(name.includes("Diesel")){
																	return "#e8e8e8"
																}else if(name.includes("AdBlue")){
																	return "rgb(202 219 255 / 61%)"
																}else if(name.includes("Liquefied hydrocarbon") || name.includes("LPG")){
																	return "rgb(179 226 229 / 65%)"
																}else{
																	return '#e7ebee'
																}
															}


															return (

																	<div className={styles.fuelItem}>
																		<div className={styles.fuelName}>
																			{m.service_nt_title}
																		</div>
																		<div className={styles.dotts}></div>
																		<div className={styles.circlePrice}>
																			<p style={{background: getType(m.service_nt_title)}}>{price} <small>{this.state.selected_e100_station_price[i].currency_client}</small></p>
																		</div>
																	</div>

															)
														})}
													</div>
												</Grid>
												:
													this.state.loadingFulePrice
														?
															<div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
																<ClipLoader color={"#969696"} loading={this.state.loadingFulePrice} width={'100%'} speedMultiplier={1} cssOverride={this.state.override}/>
															</div>
														:
															this.state.e100_limits.length > 0 ? '' : 'Brak cen..'
												: ''} */}

                        <span className={styles.hr}></span>

                        <Grid item xs={12}>
                          <h3>Ostatnie transakcje:</h3>
                        </Grid>

                        {this.state.loadingTransaction ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <ClipLoader
                              color={"#969696"}
                              loading={this.state.loadingTransaction}
                              width={"100%"}
                              speedMultiplier={1}
                              cssOverride={this.state.override}
                            />
                          </div>
                        ) : (
                          <Grid item xs={12}>
                            <div className={styles.fuelBox}>
                              {this.state.e100_transactions.length > 0
                                ? this.state.e100_transactions.map((m, i) => {
                                    if (i < 3) {
                                      const getType = (name) => {
                                        if (
                                          name.includes("Petrol") ||
                                          name.includes("95") ||
                                          name.includes("98")
                                        ) {
                                          return "#e7eec0";
                                        } else if (name.includes("Diesel")) {
                                          return "#e8e8e8";
                                        } else if (name.includes("AdBlue")) {
                                          return "rgb(202 219 255 / 61%)";
                                        } else if (
                                          name.includes(
                                            "Liquefied hydrocarbon"
                                          ) ||
                                          name.includes("LPG")
                                        ) {
                                          return "rgb(179 226 229 / 65%)";
                                        } else {
                                          return "#e7ebee";
                                        }
                                      };

                                      return (
                                        <>
                                          <div className={styles.fuelItem2}>
                                            <div className={styles.fuelName}>
                                              {m.card}
                                            </div>
                                            <div className={styles.dotts}></div>
                                            <div className={styles.circlePrice}>
                                              <p
                                                style={{
                                                  background: getType(
                                                    m.service_name
                                                  ),
                                                }}
                                              >
                                                {Number(m.price).toFixed(2)}{" "}
                                                <small>{m.currency}</small>
                                              </p>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    } else {
                                      return "";
                                    }
                                  })
                                : "Brak transakcji.."}
                            </div>
                          </Grid>
                        )}

                        {/* <div style={{display: 'flex', justifyContent: 'center'}}>
												<ClipLoader color={"#969696"} loading={this.state.loadingTransaction} width={'100%'} speedMultiplier={1} cssOverride={this.state.override}/>
											</div> */}

                        <span className={styles.hr}></span>

                        <Grid item xs={12}>
                          <h3>Limit dla karty paliwowej (w litrach):</h3>
                        </Grid>
                        <Grid item xs={12}>
                          <Select
                            menuPortalTarget={document.body}
                            value={this.state.selectedFuelCard}
                            className={stylesMod.select}
                            options={this.state.e100_cards}
                            onChange={(e) => {
                              this.handleCardLimit(e);
                              this.setState({
                                selectedFuelCard: e,
                              });
                            }}
                            name="Car"
                            placeholder="Wybierz..."
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                border: "1px solid #142f42",
                                boxShadow: "none",
                                minHeight: "unset",
                                height: "30px",
                                "&:hover": {
                                  border: "1px solid #142f42",
                                },
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isFocused && "#142f42",
                                color: state.isFocused && "#fff",
                              }),
                              indicatorSeparator: (provided, state) => ({
                                ...provided,
                                marginRight: "5px",
                              }),
                              dropdownIndicator: (provided) => ({
                                ...provided,
                                "& div": {
                                  border: "1px solid #142f42",
                                },
                                padding: "0 5px 0 0",
                              }),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {this.state.loadingLimits ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                              }}
                            >
                              <ClipLoader
                                color={"#969696"}
                                loading={this.state.loadingLimits}
                                width={"100%"}
                                speedMultiplier={1}
                                cssOverride={this.state.override}
                              />
                            </div>
                          ) : this.state.selectedFuelCard ? (
                            <Grid item xs={12}>
                              <div className={styles.fuelBox}>
                                {this.state.e100_limits !== null &&
                                this.state.e100_limits.length > 0
                                  ? this.state.e100_limits.map((m, i) => {
                                      const getType = (name) => {
                                        if (
                                          name.includes("Petrol") ||
                                          name.includes("95") ||
                                          name.includes("98")
                                        ) {
                                          return "#e7eec0";
                                        } else if (name.includes("Diesel")) {
                                          return "#e8e8e8";
                                        } else if (name.includes("AdBlue")) {
                                          return "rgb(202 219 255 / 61%)";
                                        } else if (
                                          name.includes(
                                            "Liquefied hydrocarbon"
                                          ) ||
                                          name.includes("LPG")
                                        ) {
                                          return "rgb(179 226 229 / 65%)";
                                        } else {
                                          return "#e7ebee";
                                        }
                                      };

                                      return (
                                        <div className={styles.fuelItem}>
                                          <div className={styles.fuelName}>
                                            {m.title}
                                          </div>
                                          <div className={styles.dotts}></div>
                                          <div className={styles.circlePrice}>
                                            <p
                                              style={{
                                                background: getType(m.title),
                                              }}
                                            >
                                              {m.limit}
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    })
                                  : "Brak ostatnich transakcji na tej stacji."}
                              </div>
                            </Grid>
                          ) : (
                            ""
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          <button
                            variant="contained"
                            size="small"
                            className={styles.showResult}
                            style={{ marginTop: "5px" }}
                            onClick={(e) => {
                              this.setState({
                                route_e100: [],
                              });

                              let tempTable = [];
                              for (
                                let i = 0;
                                this.state.carList.length > i;
                                i++
                              ) {
                                if (this.state.carList[i].position !== null) {
                                  const newItem = {
                                    name: this.state.carList[i].name,
                                    id: this.state.carList[i].id,
                                    distance: this.findNearestVehicle(
                                      this.state.carList[i].position.lat,
                                      this.state.carList[i].position.lng,
                                      o.position.lat,
                                      o.position.lng
                                    ).toFixed(1),
                                    position: this.state.carList[i].position,
                                  };

                                  tempTable.push(newItem);

                                  tempTable.sort(
                                    (a, b) => a.distance - b.distance
                                  );
                                }
                              }

                              this.setState({
                                nearestVehicles: tempTable,
                              });

                              // this.routeE100(o, tempTable, "3_cars")
                            }}
                          >
                            Znajdź najbliższe pojazdy{" "}
                            <ClipLoader
                              color={this.state.color}
                              loading={this.state.loading}
                              width={"100%"}
                              speedMultiplier={1}
                              cssOverride={this.state.override}
                            />
                          </button>
                        </Grid>
                        {this.state.nearestVehicles.length > 0 && (
                          <Grid className={styles.distanceList} item xs={12}>
                            <div className={styles.stationsList}>
                              <div className={styles.distances}>
                                <h3>Najblizsze pojazdy:</h3>
                                <div className={styles.stationsListRowsBox}>
                                  {this.state.nearestVehicles.map((n, i) => {
                                    let distanceToCar =
                                      this.state.route_e100.length > 0 &&
                                      this.state.route_e100[0].sections[0]
                                        .travelSummary.length !== null
                                        ? `${(
                                            this.state.route_e100[0].sections[0]
                                              .travelSummary.length / 1000
                                          ).toFixed(2)} km`
                                        : "";
                                    let isThisSelected =
                                      this.state.route_e100[0]?.sections[0].departure.place.originalLocation.lat.toFixed(
                                        4
                                      ) === n.position.lat.toFixed(4);

                                    return (
                                      <div
                                        className={styles.stationsListRow2}
                                        style={{ gap: "5px" }}
                                      >
                                        <div>
                                          <TimeToLeave
                                            style={{
                                              maxHeight: "18px",
                                              color: "#152f42",
                                            }}
                                          />
                                          {/* <img className={styles.modalE100Logo} src={require(`../../img/icons/e100_${o.type_e100}.png`)} alt="icon"/> */}
                                        </div>
                                        <div>{n.name}</div>
                                        <div>
                                          {isThisSelected ? distanceToCar : ""}
                                        </div>
                                        <div>
                                          <Tooltip
                                            title="Wyznacz trase"
                                            TransitionComponent={Fade}
                                          >
                                            <button
                                              className={styles.searchRouteBtn}
                                              style={
                                                isThisSelected
                                                  ? { background: "#129865" }
                                                  : { background: "#142f42" }
                                              }
                                              onClick={(e) => {
                                                let tmpStation = o;
                                                let newCarPosition = {
                                                  lat: n.position.lat,
                                                  lng: n.position.lng,
                                                  id: n.id,
                                                  name: n.name,
                                                };

                                                this.routeE100(
                                                  tmpStation,
                                                  newCarPosition,
                                                  "pointToPoint",
                                                  i
                                                );
                                              }}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                enable-background="new 0 0 24 24"
                                                height="16"
                                                viewBox="0 0 24 24"
                                                width="24"
                                              >
                                                <g>
                                                  <rect
                                                    fill="none"
                                                    height="24"
                                                    width="24"
                                                  />
                                                  <path d="M9.78,11.16l-1.42,1.42c-0.68-0.69-1.34-1.58-1.79-2.94l1.94-0.49C8.83,10.04,9.28,10.65,9.78,11.16z M11,6L7,2L3,6h3.02 C6.04,6.81,6.1,7.54,6.21,8.17l1.94-0.49C8.08,7.2,8.03,6.63,8.02,6H11z M21,6l-4-4l-4,4h2.99c-0.1,3.68-1.28,4.75-2.54,5.88 c-0.5,0.44-1.01,0.92-1.45,1.55c-0.34-0.49-0.73-0.88-1.13-1.24L9.46,13.6C10.39,14.45,11,15.14,11,17c0,0,0,0,0,0h0v5h2v-5 c0,0,0,0,0,0c0-2.02,0.71-2.66,1.79-3.63c1.38-1.24,3.08-2.78,3.2-7.37H21z" />
                                                </g>
                                              </svg>
                                            </button>
                                          </Tooltip>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>

                            {/* {this.state.nearestVehicles.length > 0 ? this.state.nearestVehicles.map((o,i)=>{
														if(i < 3){
															return (
																<Grid key={i} container className={styles.listRow}>
																	<Grid item xs={2} className={styles.right}>
																		<div className={styles.flexRow}>
																			<b>
																				{o.distance}
																			</b>
																			<small>km</small>
																		</div>
																	</Grid>
																	<Grid item xs={1} className={styles.center}>
																		{this.arrowIconRight()}
																	</Grid>
																	<Grid item xs className={styles.left}>{o.name}</Grid>
																</Grid>
															)
														}else{
															return ''
														}

													}): ''} */}
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
              {/* {o.showInfo && (
									<OverlayView
										position={o.position}
										mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
										getPixelPositionOffset={(width, height) => ({
											x: -width / 2,
											y: -height / 2,
										})}
									>
										<div
											style={{
												width: '70px',
												height: '70px',
												opacity: 0.8,
												border: '4px solid #129865',
												borderRadius: '50%',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												boxShadow: 'inset 0 0 6px 2px rgb(18 152 101 / 65%)'
											}}
										>
										</div>
								</OverlayView>
							)} */}
            </>
          );
        });
      return (
        <MarkerClusterer
          options={optionsMC}
          averageCenter
          gridSize={100}
          maxZoom={24}
          minimumClusterSize={5}
          styles={[
            {
              url: require(`../../img/icons/e100MC1.png`),
              height: 53,
              lineHeight: 53,
              width: 53,
              textColor: "#fff",
              textSize: 15,
            },
            {
              url: require(`../../img/icons/e100MC2.png`),
              height: 56,
              lineHeight: 56,
              width: 56,
              textColor: "#fff",
              textSize: 15,
            },
            {
              url: require(`../../img/icons/e100MC3.png`),
              height: 66,
              lineHeight: 66,
              width: 66,
              textColor: "#fff",
              textSize: 15,
            },
            {
              url: require(`../../img/icons/e100MC4.png`),
              height: 78,
              lineHeight: 78,
              width: 78,
              textColor: "#fff",
              textSize: 14,
            },
            {
              url: require(`../../img/icons/e100MC5.png`),
              height: 90,
              lineHeight: 90,
              width: 90,
              textColor: "#fff",
              textSize: 13,
            },
          ]}
        >
          {(clusterer) => {
            return markers(clusterer);
          }}
        </MarkerClusterer>
      );
    } else {
      console.log("Brak stacji do wyświetlenia..");
    }
  };

  handleInfoWindowMinimizeE100 = (id) => {
    const infoWindow = this.infoWindowsRef[id];
    if (infoWindow) {
      const infoWindowContent = infoWindow.getContent();
      const infoWindowWrapper = infoWindowContent.parentNode.parentNode;
      const header = infoWindowWrapper.querySelector(
        `.${styles.modalE100Header}`
      );
      const body = infoWindowWrapper.querySelector(`.${styles.modalE100Body}`);

      infoWindowWrapper.style.height = `42px`;
      body.style.display = "none";

      header.addEventListener("click", this.handleInfoWindowMaximizeE100);
    }
    this.setState((prevState) => ({
      markersE100: prevState.markersE100.map((marker) => {
        if (marker.id === id) {
          return {
            ...marker,
            showInfo: true,
            minimized: true,
          };
        }
        return marker;
      }),
    }));
  };

  handleInfoWindowMaximizeE100 = (id) => {
    const infoWindow = this.infoWindowsRef[id];
    if (infoWindow) {
      const infoWindowContent = infoWindow.getContent();
      const infoWindowWrapper = infoWindowContent.parentNode.parentNode;
      const body = infoWindowWrapper.querySelector(`.${styles.modalE100Body}`);

      infoWindowWrapper.style.height = "";
      body.style.display = "";

      const header = infoWindowWrapper.querySelector(
        `.${styles.modalE100Header}`
      );
      header.removeEventListener("click", this.handleInfoWindowMaximizeE100);
    }

    this.setState((prevState) => ({
      markersE100: prevState.markersE100.map((marker) => {
        if (marker.id === id) {
          return {
            ...marker,
            minimized: false,
          };
        }
        return marker;
      }),
    }));
  };

  handleInfoWindowCloseE100 = (markerId) => {
    let marker = markerId;

    if (markerId === undefined) {
      marker = open_markerId;
    }

    this.setState({
      markersE100: this.state.markersE100.map((o) => {
        if (o.id === marker) {
          o.showInfo = false;
        }
        return o;
      }),
    });
    this.setState({
      nearestVehicles: [],
      route_e100: [],
      idsWindowE100: [],
      selected_e100_station_price: [],
      e100_limits: [],
      selectedFuelCard: null,
      open_markerId: null,
    });
  };

  handleMarkerClickE100 = (markerId) => {
    // console.log("CLICK!!!!!!!!",markerId)
    this.closeAllInfoWindows();

    this.setState({
      open_markerId: markerId,
      markersE100: this.state.markersE100.map((o) => {
        if (o.id === markerId) {
          this.getE100FuelPrices(o.station);
          this.getE100Cards();
          this.getE100Transactions(o.id);
          // this.getE100GasStationPrice('AM2')
          o.showInfo = !o.showInfo;
        }
        return o;
      }),
    });

    this.setState((prevState) => ({
      idsWindowE100: [...prevState.idsWindowE100, markerId],
    }));
  };

  geoDecodeAutocomplete = (address, callback) => {
    const cityName = address;
    this.setState({ searchValue: cityName });

    const geocoder = this.platform.getSearchService();
    const geocodingParams = {
      q: cityName,
    };

    geocoder.geocode(
      geocodingParams,
      (result) => {
        if (result.items.length > 0) {
          const location = result.items[0].position;
          const tempAddress = [location.lat, location.lng];
          callback(tempAddress);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  };

  onPlaceToCountry = (country) => {
    if (country !== null) {
      if (
        this.state.checkE100_2 ||
        this.state.checkE100_3 ||
        this.state.checkE100_4
      ) {
        const cityName = country.capital;
        const geocoder = this.platform.getSearchService();
        const geocodingParams = {
          q: cityName,
        };
        geocoder.geocode(
          geocodingParams,
          (result) => {
            if (result.items.length > 0) {
              // console.log('result',result)
              const location = result.items[0].position;
              let tempAdressLat = location.lat;
              let tempAdressLng = location.lng;
              this.setState({
                searchFuelE100: true,
                centerMap: {
                  lat: tempAdressLat,
                  lng: tempAdressLng,
                },
                zoomMap: country.zoom,
              });
            }
          },
          (error) => {
            console.error(error);
          }
        );
      } else {
        this.setState({
          openDialog: true,
          dialogContent: `Nie znaleziono żadnych stacji, wybierz typ stacji.`,
          dialogColor: "error",
        });
      }
    }
  };

  findNearestE100 = (car) => {
    if (this.adressInput.current && this.adressInput.current?.value !== "") {
      this.adressInput.current.value = "";
    }

    this.setState({
      route_e100: [],
      e100_list_distanse_sorted: [],
      selected_car: car,
    });

    this.setState({
      selectedTableRowId: car.id,
    });
    this.selectCarById(car.id, true);
  };

  resetFuelSearch = () => {
    // console.log('resetFuelSearch...')

    // const { idsWindowE100, markersE100 } = this.state;

    // const updatedMarkersE100 = markersE100.map((o) => {
    // 	if (idsWindowE100.includes(o.id)) {
    // 		return {
    // 			...o,
    // 			showInfo: false,
    // 		};
    // 	}
    // 	return o;
    // });

    this.closeAllInfoWindows();

    this.adressInput.current.value = null;

    // this.setState({
    // 	e100_list_distanse_sorted: [],
    // 	route_e100: [],
    // 	// checkE100_1: true,
    // 	checkE100_2: true,
    // 	checkE100_3: true,
    // 	checkE100_4: true,
    // 	checkE100_all: false,
    // 	on_road_checked: false,
    // 	radius_km_checked: false,
    // 	radius_km: '',
    // 	country_selected: false,
    // 	selected_country: null,
    // 	selectedTableRowId: 0,
    // 	selected_car: null,
    // 	ifShowResult: false,
    // 	nearestVehicles: [],
    // 	idsWindowE100: [],
    // 	selected_point: null,
    // 	polylinePathHere: [],
    // 	polylinePathSygic: [],
    // 	myCustomMarkerPoints: [],
    // })

    // this.state.map.setZoom(6);
    // this.state.map.setCenter({
    // 	lat: 52.5362,
    // 	lng: 14.5788
    // });

    this.setState({ shouldReload: true }, () => {
      // this.state.map.setZoom(6);
      // this.state.map.setCenter({
      // 	lat: 52.5362,
      // 	lng: 14.5788
      // });

      this.setState({
        markersE100: this.state.markersE100_const,
        shouldReload: false,
        e100_list_distanse_sorted: [],
        route_e100: [],
        checkE100_1: true,
        checkE100_2: true,
        checkE100_3: true,
        checkE100_4: true,
        checkE100_all: false,
        on_road_checked: false,
        radius_km_checked: false,
        radius_km: "",
        country_selected: false,
        selected_country: null,
        selectedTableRowId: 0,
        selected_car: null,
        ifShowResult: false,
        nearestVehicles: [],
        idsWindowE100: [],
        selected_point: null,
        polylinePathHere: [],
        polylinePathSygic: [],
        myCustomMarkerPoints: [],
        circle_center: null,
        circle_radius: null,
        polylinePath: [],
        trackingCar: false,
        markerOverlayVisibility: false,
      });

      const bounds = new google.maps.LatLngBounds();
      this.state.markersE100_const.forEach((marker, i) => {
        bounds.extend(
          new google.maps.LatLng(marker.position.lat, marker.position.lng)
        );
      });

      this.state.map && this.state.map.fitBounds(bounds);
      // this.state.map.setZoom(4);
    });

    // this.clearDirections();
  };

  resetCarSelection = () => {
    this.setState({ shouldReload: true }, () => {
      this.setState({
        shouldReload: false,
        e100_list_distanse_sorted: [],
        route_e100: [],
        selectedTableRowId: 0,
        selected_car: null,
        nearestVehicles: [],
        polylinePathHere: [],
        polylinePathSygic: [],
        myCustomMarkerPoints: [],
        circle_center: null,
        circle_radius: null,
        polylinePath: [],
        trackingCar: false,
        markerOverlayVisibility: false,
      });

      this.state.map.setZoom(6);
      this.state.map.setCenter({
        lat: 52.5362,
        lng: 14.5788,
      });
    });

    // this.clearDirections();
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openDialog: false,
    });
  };

  filterByCountry = async (country, data, point) => {
    if (point !== undefined) {
      const stations = data;
      let stationsByCountry = stations.filter((o) => {
        return o.country === country.code;
      });
      if (point.id !== undefined) {
        let tmpMarkersE100 = stationsByCountry;
        let tempTable = [];
        for (const station of tmpMarkersE100) {
          const newItem = {
            ...station,
            distance: parseFloat(
              this.findNearestVehicle(
                station.position.lat,
                station.position.lng,
                point.lat,
                point.lng
              ).toFixed(2)
            ),
          };
          tempTable.push(newItem);
        }

        // let sorted = tempTable.sort((a, b) => a.distance - b.distance);
        tempTable = tempTable.sort((a, b) => a.distance - b.distance);

        return tempTable.length ? tempTable : [];

        // Tu dodaj kod obsługujący przypadek point.id !== undefined
      } else if (point !== "") {
        // console.log('point------>',point)
        const geocoder = new window.google.maps.Geocoder();
        const geocodePromise = new Promise((resolve, reject) => {
          geocoder.geocode({ address: point }, (results, status) => {
            if (status === "OK") {
              this.adressInput.current.value = results[0].formatted_address;
              let tmpMarkersE100 = stationsByCountry;
              let tempTable = [];

              for (const station of tmpMarkersE100) {
                const newItem = {
                  ...station,
                  distance: parseFloat(
                    this.findNearestVehicle(
                      station.position.lat,
                      station.position.lng,
                      results[0].geometry.location.lat(),
                      results[0].geometry.location.lng()
                    ).toFixed(2)
                  ),
                };
                tempTable.push(newItem);
              }

              let sorted = tempTable.sort((a, b) => a.distance - b.distance);
              tempTable = sorted.slice(0, 3);
              resolve(tempTable.length ? tempTable : []);
            } else {
              // console.log('Nie udało się znaleźć lokalizacji');
              reject(new Error("Nie udało się znaleźć lokalizacji"));
            }
          });
        });

        try {
          return await geocodePromise;
        } catch (error) {
          // console.log('Wystąpił błąd:', error);
        }
      }
    } else {
      const stations = data;
      let stationsByCountry = stations.filter((o) => {
        return o.country === country.code;
      });
      return stationsByCountry.length ? stationsByCountry : [];
    }
  };

  fitBoundsPoints = (points_arr) => {
    const bounds = new window.google.maps.LatLngBounds();
    points_arr.forEach((o, i) => {
      let lat, lng;

      if (o.position && o.position.lat && o.position.lng) {
        lat = o.position.lat;
        lng = o.position.lng;
      }

      if (o.latitude && o.longitude) {
        lat = o.latitude;
        lng = o.longitude;
      }

      if (o.lat && o.lng) {
        lat = o.lat;
        lng = o.lng;
      }

      if (lat !== undefined && lng !== undefined) {
        bounds.extend(new window.google.maps.LatLng(lat, lng));
      }
    });

    const map = this.state.map;
    map && map.fitBounds(bounds);
  };

  searchByRadiusKm = async (data, point, type, radius) => {
    if (radius) {
      if (this.state.checkE100_3 === false) {
        this.setState({ checkE100_3: true });
      }
    }

    let point_lat = null;
    let point_lng = null;
    let nearStations = [];
    let tempTable = [];
    let tempMarkers = data;

    const getResult = (lat, lng) => {
      if (lat !== null && lng !== null) {
        for (let i = 0; tempMarkers.length > i; i++) {
          let tmpDistance = Number(
            this.findNearestVehicle(
              tempMarkers[i].position.lat,
              tempMarkers[i].position.lng,
              lat,
              lng
            ).toFixed(2)
          );
          if (tempMarkers[i].position !== null) {
            const newItem = {
              ...tempMarkers[i],
              distance: tmpDistance,
            };
            tempTable.push(newItem);
          }
        }
        tempTable.sort((a, b) => a.distance - b.distance);
        if (tempTable.length > 0) {
          if (radius !== null) {
            const filteredData = tempTable.filter((o) => {
              return o.distance <= Number(radius);
            });

            const filteredData_Power_Max = filteredData.filter((o) => {
              return o.external_category_id !== 4;
            });

            if (filteredData.length > 3) {
              nearStations = filteredData_Power_Max.slice(0, 3);
            } else if (
              filteredData_Power_Max.length < 3 &&
              filteredData_Power_Max.length > 0
            ) {
              nearStations = [...filteredData_Power_Max];
            } else {
              // wyszukaj 3 stacje jezeli tylko wpisany adres w szukaj
              nearStations = filteredData_Power_Max.slice(0, 3);
            }
          } else {
            nearStations = [...tempTable.slice(0, 3)];
          }
        }
        return nearStations;
      }
    };

    if (type === "point") {
      return new Promise((resolve) => {
        this.geoDecodeAutocomplete(point, (result) => {
          let value = result;
          point_lat = value[0];
          point_lng = value[1];
          nearStations = getResult(point_lat, point_lng);

          this.setState({
            selected_point: [point_lat, point_lng],
            circle_center: { lat: point_lat, lng: point_lng }, // przykładowe współrzędne Londynu
            circle_radius: radius * 1000, // przykładowy promień w metrach
          });

          resolve(nearStations);
        });
      });
    } else if (type === "car") {
      point_lat = point.lat;
      point_lng = point.lng;

      nearStations = getResult(point_lat, point_lng);

      return nearStations;
    }
  };

  filterGasStations = (data) => {
    const { checkE100_1, checkE100_2, checkE100_3, checkE100_4 } = this.state;

    const createArrayFromBooleans = (
      checkE100_1,
      checkE100_2,
      checkE100_3,
      checkE100_4
    ) => {
      const resultArray = [];
      if (checkE100_1) {
        resultArray.push(1);
      } else {
        const index = resultArray.indexOf(1);
        if (index !== -1) {
          resultArray.splice(index, 1);
        }
      }

      if (checkE100_2) {
        resultArray.push(2);
      } else {
        const index = resultArray.indexOf(2);
        if (index !== -1) {
          resultArray.splice(index, 1);
        }
      }

      if (checkE100_3) {
        resultArray.push(4);
      } else {
        const index = resultArray.indexOf(4);
        if (index !== -1) {
          resultArray.splice(index, 1);
        }
      }

      if (checkE100_4) {
        resultArray.push(5);
      } else {
        const index = resultArray.indexOf(5);
        if (index !== -1) {
          resultArray.splice(index, 1);
        }
      }
      // console.log('resultArray',resultArray)
      return resultArray;
    };
    const selectedTypes = createArrayFromBooleans(
      checkE100_1,
      checkE100_2,
      checkE100_3,
      checkE100_4
    );

    // console.log('selectedTypes',selectedTypes)

    let output = data.filter((o) => selectedTypes.includes(o.type_e100));

    // console.log('output',output.length)

    return output;
  };

  findNearestE100_new = (stations, point, type) => {
    let tempTable = [];
    for (let i = 0; stations.length > i; i++) {
      if (stations[i].position !== null) {
        const newItem = {
          name: stations[i].name,
          id: stations[i].id,
          distance: this.findNearestVehicle(
            stations[i].position.lat,
            stations[i].position.lng,
            o.position.lat,
            o.position.lng
          ).toFixed(1),
          position: stations[i].position,
        };

        tempTable.push(newItem);

        tempTable.sort((a, b) => a.distance - b.distance);
      }
    }
    tempTable.log();
  };

  getDataToDraw = async (data) => {
    let tmpData = this.filterGasStations(data);

    if (this.adressInput.current.value !== "") {
      this.setState({
        selectedTableRowId: 0,
        selected_car: null,
      });

      if (this.state.country_selected) {
        if (this.state.selected_country !== null) {
          let tmpOldData = tmpData;
          tmpData = await this.filterByCountry(
            this.state.selected_country,
            tmpOldData,
            this.adressInput.current.value
          );
          this.setState({
            e100_list_distanse_sorted: tmpData,
          });
        } else {
          this.setState({
            openDialog: true,
            dialogContent: `Wybierz kraj, po ktorym chces filtrować.`,
            dialogColor: "error",
          });
        }
      }

      if (this.state.radius_km_checked) {
        if (this.state.radius_km > 0) {
          let tmpOldData = tmpData;
          tmpData = await this.searchByRadiusKm(
            tmpOldData,
            this.adressInput.current.value,
            "point",
            this.state.radius_km
          );
          this.setState({
            e100_list_distanse_sorted: tmpData,
          });
        } else {
          this.setState({
            openDialog: true,
            dialogContent: `Podaj promień w kilometrach, w którym mają być filtrowane stacje.`,
            dialogColor: "error",
          });
        }
      }

      if (!this.state.country_selected && !this.state.radius_km_checked) {
        let tmpOldData = tmpData;
        tmpData = await this.searchByRadiusKm(
          tmpOldData,
          this.adressInput.current.value,
          "point",
          null
        );
        this.setState({
          e100_list_distanse_sorted: tmpData,
        });
      }
    } else if (
      (this.adressInput.current.value === "" || this.adressInput === null) &&
      this.state.selected_car !== null
    ) {
      if (this.state.country_selected) {
        if (this.state.selected_country !== null) {
          let tmpOldData = tmpData;
          tmpData = await this.filterByCountry(
            this.state.selected_country,
            tmpOldData,
            this.state.selected_car
          );
          if (!this.state.on_road_checked) {
            tmpData = tmpData.slice(0, 3);
            this.setState({
              e100_list_distanse_sorted: tmpData,
            });
          }
        } else {
          this.setState({
            openDialog: true,
            dialogContent: `Wybierz kraj, po ktorym chces filtrować.`,
            dialogColor: "error",
          });
        }
      }

      if (this.state.radius_km_checked) {
        if (this.state.radius_km > 0) {
          let tmpOldData = tmpData;
          tmpData = await this.searchByRadiusKm(
            tmpOldData,
            this.state.selected_car,
            "car",
            this.state.radius_km
          );
          this.setState({
            e100_list_distanse_sorted: tmpData,
          });
        } else {
          this.setState({
            openDialog: true,
            dialogContent: `Podaj promień w kilometrach, w którym mają być filtrowane stacje.`,
            dialogColor: "error",
          });
        }
      }

      if (this.state.on_road_checked) {
        // console.log('^^^filter_onRoad',tmpData)
        if (this.state.polylinePathHere.length > 0 && this.state.selected_car) {
          tmpData = this.getClosestStations(
            tmpData,
            this.state.polylinePathHere,
            this.state.selected_car
          );
          this.setState({
            e100_list_distanse_sorted: tmpData,
          });
        }
      }

      if (
        !this.state.country_selected &&
        !this.state.radius_km_checked &&
        !this.state.on_road_checked
      ) {
        // console.log('>> B >> PUSTY')
        let tmpOldData = tmpData;
        tmpData = await this.searchByRadiusKm(
          tmpOldData,
          this.state.selected_car,
          "car",
          null
        );
        this.setState({
          e100_list_distanse_sorted: tmpData,
        });
      }
    } else {
      if (this.state.country_selected) {
        // console.log('NULL >> country_selected')
        if (this.state.selected_country !== null) {
          let tmpOldData = tmpData;
          tmpData = this.filterByCountry(
            this.state.selected_country,
            tmpOldData
          );
        } else {
          this.setState({
            openDialog: true,
            dialogContent: `Wybierz kraj, po ktorym chces filtrować.`,
            dialogColor: "error",
          });
        }
      }
    }

    return tmpData;
  };

  showResults = async () => {
    let dataOutput = await this.getDataToDraw(this.state.markersE100_const);
    // console.log('dataOutput',dataOutput)

    if (dataOutput.length > 0) {
      this.setState({ shouldReload: true }, () => {
        this.setState({
          markersE100: dataOutput,
          shouldReload: false,
        });

        const bounds = new google.maps.LatLngBounds();
        dataOutput.forEach((marker, i) => {
          bounds.extend(
            new google.maps.LatLng(marker.position.lat, marker.position.lng)
          );
        });

        this.state.map && this.state.map.fitBounds(bounds);
      });

      // // First load
      // this.loadCarsToMap();
      // // ETA
      // this.loadETA();

      // if(dataOutput.length > 0){
      // 	// console.log('fitBoundsPoints..')
      // 	this.fitBoundsPoints(dataOutput)
      // }else{
      // 	// console.log('else..')
      // 	this.state.map.setZoom(6);
      // 	this.state.map.setCenter({
      // 		lat: 52.5362,
      // 		lng: 14.5788
      // 	});
      // }
    } else {
      this.setState({ shouldReload: true }, () => {
        this.setState({ markersE100: dataOutput, shouldReload: false });
      });
      // First load
      this.loadCarsToMap();
      // ETA
      this.loadETA();

      if (this.state.selected_country !== null) {
        this.onPlaceToCountry(this.state.selected_country);
      }

      this.setState({
        openDialog: true,
        dialogContent: `Brak stacji do wyświetlenia.`,
        dialogColor: "error",
      });
    }
  };

  setSelectedOrder = (order) => {
    this.setState({ selected_order: order });
  };

  closeAllInfoWindows = () => {
    this.infoWindowsRef.forEach((infoWindow) => {
      if (infoWindow) {
        infoWindow.close();
      }
    });

    this.setState({
      selected_e100_station_price: null,
      e100_cards: null,
      e100_station_limit: null,
      e100_limits: [],
      selectedFuelCard: null,
      markersE100: this.state.markersE100.map((o) => {
        o.showInfo = false;
        return o;
      }),
    });
  };

  functionDistansMatrixHere = () => {
    console.log("matrix...");
  };

  hiddenOneDayRoute = (state) => {
    this.setState({ showOneDayRoute: state });
  };

  render() {
    const {
      checkE100_1,
      checkE100_2,
      checkE100_3,
      checkE100_4,
      checkE100_all,
    } = this.state;

    let leftDistance = Math.round(
      this.state.orderSummary_sum_kilometers -
        this.state.orderSummary_km_to_destination
    ).toFixed(0);
    let passedDistance = Math.round(
      this.state.orderSummary_km_to_destination
    ).toFixed(0);
    let loadedDistance = Math.round(
      this.state.orderSummary_cargo_kilometers
    ).toFixed(0);
    let driveDistance = Math.round(
      this.state.orderSummary_access_kilometers
    ).toFixed(0);
    let totalkilometersDistance = Math.round(
      this.state.orderSummary_sum_kilometers
    ).toFixed(0);

    return (
      <div>
        <div className={styles.mapCounter}>
          <div>Licznik: {this.state.counter}</div>
          <div>
            <Tooltip title="Pokaż wszystkie pojazdy" TransitionComponent={Fade}>
              <Button className={styles.btn} onClick={this.onClickShowAllCars}>
                <GpsFixedIcon fontSize="small" className={styles.icon} />
              </Button>
            </Tooltip>
          </div>
        </div>

        {this.state.showOrderSummary && (
          <div className={styles.orderSummaryBox}>
            <table className={styles.tableOrderSummary}>
              <tbody>
                <tr>
                  <td className={styles.header}>Pozostało:</td>
                  <td>{leftDistance} km</td>
                </tr>
                <tr>
                  <td className={styles.header}>Przejechano:</td>
                  <td>{passedDistance} km</td>
                </tr>
                <tr>
                  <td className={styles.header}>Kilometry ładowne:</td>
                  <td>{loadedDistance} km</td>
                </tr>
                <tr>
                  <td className={styles.header}>Dojazd:</td>
                  <td>{driveDistance} km</td>
                </tr>
                <tr>
                  <td className={styles.header}>Kilometry łącznie:</td>
                  <td className={styles.header}>
                    {totalkilometersDistance} km
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        <div className={styles.parentContainer}>
          <CarsList
            tableRows={this.state.tableRows}
            eta={this.state.eta}
            eta_waypoints={this.state.eta_waypoints}
            selectedTableRowId={this.state.selectedTableRowId}
            onClickTableRowCallback={this.onClickTableRow}
            onClickTableSetTheRouteCallback={this.onClickTableSetTheRoute}
            // doRouteCallback={this.doRoute}
            doRouteCallback={this.drawRouteFromHere}
            addMarkersCallback={this.addMyCustomMarkers}
            addMarkersRefuelingsCallback={this.addMyCustomMarkersRefuelings}
            onClickTableShowPOIOrderCallback={this.showPOIOrder}
            onClickHideOrderCallback={this.onClickHideOrder}
            onClickShowOrderCallback={this.onClickShowOrder}
            onClickSelectOrderCallback={this.onClickSelectOrder}
            showOrderRouteProgressCallback={this.showOrderRouteProgress}
            showOrderRouteProgressNoTelemetryCallback={
              this.showOrderRouteProgressNoTelemetry
            }
            onClickCenterOrderRouteCallback={this.onClickCenterOrderRoute}
            onClickCenterOrderProgressRouteCallback={
              this.onClickCenterOrderProgressRoute
            }
            onClickShowSearchFuel={this.onClickShowSearchFuel}
            findNearestE100={this.findNearestE100}
            setSelectedOrder={this.setSelectedOrder}
            hiddenOneDayRoute={this.hiddenOneDayRoute}
            e100={this.props.user.e100}
          />
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={this.state.centerMap}
            zoom={this.state.zoomMap}
            options={optionsMap}
            onLoad={this.onLoadMap}
            onClick={this.onClickMap}
          >
            {this.state.markerOverlayVisibility && (
              <OverlayView
                position={this.state.markerOverlayPosition}
                mapPaneName={OverlayView.MAP_PANE}
                getPixelPositionOffset={getPixelPositionOffset}
              >
                <div className={styles.markerOverlay}></div>
              </OverlayView>
            )}

            {this.state.useMatrix &&
              this.state.destinationsMatrix.length > 0 &&
              this.state.originsMatrix.length > 0 && (
                <DistanceMatrixService
                  options={{
                    destinations: this.state.destinationsMatrix,
                    origins: this.state.originsMatrix,
                    travelMode: this.state.travelMode,
                    unitSystem: google.maps.UnitSystem.METRIC,
                    durationInTraffic: true,
                  }}
                  callback={this.distanceMatrixCallback}
                />
              )}

            {this.state.destination !== "" &&
              this.state.origin !== "" &&
              this.state.useDirectionServices && (
                <DirectionsService
                  options={{
                    destination: this.state.destination,
                    origin: this.state.origin,
                    waypoints: this.state.waypoints,
                    travelMode: this.state.travelMode,
                  }}
                  callback={this.directionsCallback}
                />
              )}

            {this.state.showMyDirectionsRenderer && (
              <MyDirectionsRenderer
                directionsResponse={this.state.directionsResponse}
                renderMode={this.state.renderMode}
                pathEncoded={this.state.pathEncoded}
                updateDirectionsRenderer={this.state.updateDirectionsRenderer}
                setUpdateDirectionsRendererCallback={
                  this.setUpdateDirectionsRenderer
                }
                boundsToRouteCallback={this.boundsToRoute}
                suppressMarkers={this.state.suppressMarkers}
                onLoadDirectionsResponse={this.onLoadDirectionsResponse}
                onDirectionsChanged={this.onDirectionsChangedDirectionsResponse}
              />
            )}

            {this.state.showMyCustomMarker &&
              this.state.myCustomMarkerPoints.map((marker, index) => {
                return (
                  <MyCustomMarker
                    id={index}
                    key={index}
                    index={index}
                    position={{
                      lat: marker.lat,
                      lng: marker.lng,
                    }}
                    type={marker.type}
                    marker={marker}
                    eta_waypoints={this.state.eta_waypoints}
                    callbackOnLoadMarker={this.onLoadMyCustomMarker}
                    callbackOnLoadInfoWindow={this.onLoadMyCustomInfoWindow}
                    callbackOnDomReadyInfoWindow={this.onDomReadyInfoWindow}
                    callbackOnMouseOverMarker={this.onMouseOverMarkerMyCustom}
                    callbackOnMouseOutMarker={this.onMouseOutMarkerMyCustom}
                  />
                );
              })}

            {this.state.showMyCustomWaypointsHere &&
              this.state.myCustomWaypointsHere.map((marker, index) => {
                return (
                  <MyCustomMarker
                    id={index}
                    key={index}
                    index={index}
                    position={{
                      lat: marker.lat,
                      lng: marker.lng,
                    }}
                    type={marker.type}
                    marker={marker}
                    callbackOnLoadMarker={this.onLoadMyCustomMarker}
                    callbackOnLoadInfoWindow={this.onLoadMyCustomInfoWindow}
                    callbackOnDomReadyInfoWindow={this.onDomReadyInfoWindow}
                    callbackOnMouseOverMarker={this.onMouseOverMarkerMyCustom}
                    callbackOnMouseOutMarker={this.onMouseOutMarkerMyCustom}
                  />
                );
              })}

            {this.state.showMyCustomMarkerRefuelings &&
              this.state.myCustomMarkerPointsRefuelings.map((marker, index) => {
                return (
                  <MyCustomMarker
                    id={index}
                    key={index}
                    index={index}
                    position={{
                      lat: marker.lat,
                      lng: marker.lng,
                    }}
                    type={marker.type}
                    marker={marker}
                    callbackOnLoadMarker={this.onLoadMyCustomMarkerRefuelings}
                    callbackOnLoadInfoWindow={
                      this.onLoadMyCustomInfoWindowRefuelings
                    }
                    callbackOnDomReadyInfoWindow={this.onDomReadyInfoWindow}
                    callbackOnMouseOverMarker={
                      this.onMouseOverMarkerMyCustomRefuelings
                    }
                    callbackOnMouseOutMarker={
                      this.onMouseOutMarkerMyCustomRefuelings
                    }
                  />
                );
              })}

            {this.showMarkers()}

            {this.state.showSearchFuel && this.state.shouldReload
              ? null
              : this.showMarkersE100()}

            {this.state.route_e100 &&
              this.state.route_e100.map((o, i) => {
                function convertCoordsToObjects(coords) {
                  return coords.map((coord) => ({
                    lat: coord[0],
                    lng: coord[1],
                  }));
                }

                let decodedPolyline = DecodeSygicPolyline(
                  o.sections[0].polyline
                );

                return (
                  <>
                    <Polyline
                      path={convertCoordsToObjects(decodedPolyline.polyline)}
                      options={optionsPolylineE100RouteProgress}
                    />
                  </>
                );
              })}

            {this.state.showSearchFuel &&
              this.state.selected_point !== null && (
                <Marker
                  position={{
                    lat: this.state.selected_point[0],
                    lng: this.state.selected_point[1],
                  }}
                />
              )}

            {this.state.circle_center !== null &&
              this.state.circle_radius !== null && (
                <Circle
                  center={this.state.circle_center}
                  radius={this.state.circle_radius}
                  options={{
                    strokeColor: "red",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "red",
                    fillOpacity: 0.15,
                  }}
                />
              )}

            {this.state.showOneDayRoute && (
              <Polyline
                path={this.state.polylinePath}
                options={optionsPolylineOneDay}
              />
            )}

            {this.state.showOrderRouteProgress && (
              <Polyline
                path={this.state.polylinePathOrderRouteProgress}
                options={optionsPolylineOrderRouteProgress}
              />
            )}

            {/* Niebieska - trasa wyznaczona w zleceniu */}
            {this.state.showHereRoute && (
              <Polyline
                path={this.state.polylinePathHere}
                options={optionsPolylineHere}
              />
            )}

            {/* Zielona - Sygic */}
            {this.state.showSygicRoute && (
              <Polyline
                path={this.state.polylinePathSygic}
                options={optionsPolylineSygic}
              />
            )}

            {this.state.showActiveOrderRoute && (
              <Polyline
                path={this.state.polylinePath}
                options={optionsPolyline}
              />
            )}

            {this.state.showRoutePro && (
              <Polyline
                path={this.state.pathRoutePro}
                options={optionsPolylineRoutePro}
              />
            )}

            {this.state.showRoutePro && (
              <RoutePro
                token={this.state.token}
                csrf_token={this.state.csrf_token}
                onClickCloseRoutePro={this.onClickCloseRoutePro}
                drawPlaceCallback={this.drawPlaceRoutePro}
                addPointCallback={this.addPointRoutePro}
                focusOnPointCallback={this.focusOnPointRoutePro}
              />
            )}

            {this.state.showPointRoutePro &&
              this.state.pointsRoutePro.map((item, index) => {
                return (
                  <Marker
                    key={item.title}
                    position={item.place.location}
                    icon={{
                      url: routeMarkers[`m-${index}.png`],
                      size: new google.maps.Size(35, 47),
                      origin: new google.maps.Point(0, 0),
                      anchor: new google.maps.Point(16, 42),
                    }}
                  />
                );
              })}

            {this.state.showPlacesMarker && (
              <Marker position={this.state.placesMarkerPosition} />
            )}

            <div className={styles.searchBox}>
              <div className={styles.searchBoxHead}>
                <div className={styles.searchBoxHead_mapType}>
                  <Tooltip title="Here" TransitionComponent={Fade}>
                    <Button
                      className={`${styles.btn} ${styles.mapHere} ${
                        this.state.map?.mapTypeId === "here" && styles.active
                      }`}
                      onClick={() => this.onClickSetMapType("here")}
                    >
                      <LayersIcon fontSize="small" className={styles.icon} />1
                    </Button>
                  </Tooltip>
                  <Tooltip title="OSM" TransitionComponent={Fade}>
                    <Button
                      className={`${styles.btn} ${styles.mapOsm} ${
                        this.state.map?.mapTypeId === "osm" && styles.active
                      }`}
                      onClick={() => this.onClickSetMapType("osm")}
                    >
                      <LayersIcon fontSize="small" className={styles.icon} />2
                    </Button>
                  </Tooltip>
                  <Tooltip title="Google" TransitionComponent={Fade}>
                    <Button
                      className={`${styles.btn} ${styles.mapRoadmap} ${
                        this.state.map?.mapTypeId === "roadmap" && styles.active
                      }`}
                      onClick={() => this.onClickSetMapType("roadmap")}
                    >
                      <LayersIcon fontSize="small" className={styles.icon} />3
                    </Button>
                  </Tooltip>
                  <Tooltip title="Hybryda" TransitionComponent={Fade}>
                    <Button
                      className={`${styles.btn} ${styles.mapHybrid} ${
                        this.state.map?.mapTypeId === "hybrid" && styles.active
                      }`}
                      onClick={() => this.onClickSetMapType("hybrid")}
                    >
                      <LayersIcon fontSize="small" className={styles.icon} />4
                    </Button>
                  </Tooltip>
                </div>
                <div className={styles.searchBoxHead_mapOptions}>
                  <Tooltip title="Wyszukaj na mapie" TransitionComponent={Fade}>
                    <Button
                      className={`${styles.btn} ${styles.showRouteMode} ${
                        !this.state.showRouteMode &&
                        !this.state.showSearchAndPlanMode &&
                        !this.state.showSearchFuel &&
                        styles.active
                      }`}
                      onClick={this.onClickSetMapSearch}
                    >
                      <SearchIcon fontSize="small" className={styles.icon} />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Wyznaczanie trasy" TransitionComponent={Fade}>
                    <Button
                      className={`${styles.btn} ${styles.showRouteMode} ${
                        this.state.showRouteMode && styles.active
                      }`}
                      onClick={this.onClickSetTheRoute}
                    >
                      <GpsFixedIcon fontSize="small" className={styles.icon} />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title="Wyszukiwarka punktów i planowanie"
                    TransitionComponent={Fade}
                  >
                    <Button
                      className={`${styles.btn} ${
                        styles.showSearchAndPlanMode
                      } ${this.state.showSearchAndPlanMode && styles.active}`}
                      onClick={this.onClickShowSearchAndPlan}
                    >
                      <DepartureBoardIcon
                        fontSize="small"
                        className={styles.icon}
                      />
                    </Button>
                  </Tooltip>
                  {this.props.user.e100 && (
                    <Tooltip title="Stacje E100" TransitionComponent={Fade}>
                      <Button
                        className={`${styles.btn} ${styles.fuelIcon} ${
                          this.state.showSearchFuel === true && styles.active
                        }`}
                        onClick={(e) => {
                          this.onClickShowSearchFuel();
                          this.state.map.setZoom(6);
                          this.state.map.setCenter({
                            lat: 52.5362,
                            lng: 14.5788,
                          });
                          this.setState({
                            centerMap: {
                              lat: 52.5362,
                              lng: 14.5788,
                            },
                            zoomMap: 6,
                          });
                          // console.log('this.state.markersE100',this.state.markersE100.length)
                        }}
                      >
                        <IconFuel fontSize="small" />
                      </Button>
                    </Tooltip>
                  )}
                </div>
              </div>
              {!this.state.showRouteMode &&
                !this.state.showSearchAndPlanMode &&
                !this.state.showSearchFuel && (
                  <Autocomplete
                    onLoad={this.onLoadAutocomplete}
                    onPlaceChanged={this.onPlaceChangedAutocomplete}
                  >
                    <input
                      type="text"
                      placeholder="Wyszukaj na mapie"
                      style={{
                        boxSizing: `border-box`,
                        border: `1px solid transparent`,
                        width: `330px`,
                        height: `32px`,
                        padding: `0 12px`,
                        borderRadius: `3px`,
                        fontSize: `13px`,
                        outline: `none`,
                        textOverflow: `ellipses`,
                        marginLeft: "-2px",
                        float: "left",
                        marginTop: "14px",
                        boxShadow: "rgb(0 0 0 / 30%) 0px 2px 6px",
                        // display: flex;
                        // flex-direction: column;
                      }}
                    />
                  </Autocomplete>
                )}
              {this.state.showRouteMode && (
                <Route
                  token={this.state.token}
                  csrf_token={this.state.csrf_token}
                  routeACdata={this.state.routeACdata}
                  doRouteCallback={this.doRoute}
                  routeDistance={this.state.routeDistance}
                  routeDuration={this.state.routeDuration}
                  onCloseRoutePlanerCallback={this.onCloseRoutePlanerCallback}
                  setUpdateRouteCallback={this.setUpdateRoute}
                  initDoRoute={this.state.initDoRoute}
                  updateRoute={this.state.updateRoute}
                />
              )}
              {this.state.showSearchAndPlanMode && (
                <SearchAndPlan
                  token={this.state.token}
                  csrf_token={this.state.csrf_token}
                  carsListMatrix={this.state.carsListMatrix}
                  pointsListMatrix={this.state.pointsListMatrix}
                  onClickFindClosestVehicleCallback={
                    this.onClickFindClosestVehicle
                  }
                  onClickFindClosestPointCallback={this.onClickFindClosestPoint}
                  onClickCarMatrixDoRouteCallback={this.onClickCarMatrixDoRoute}
                />
              )}
              {this.state.showSearchFuel && (
                <div className={styles.fuelSearchBox}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={"auto"}>
                          <Autocomplete
                            onLoad={(autocomplete) => {
                              this.setState({
                                autocomplete: autocomplete,
                                enterPressed: false,
                                autoPressed: true,
                              });
                            }}
                            onPlaceChanged={() => {
                              this.setState({
                                selected_car: null,
                                selectedTableRowId: 0,
                                e100_list_distanse_sorted: [],
                              });
                            }}
                          >
                            <div className={styles.searchBoxFlex}>
                              <input
                                type="text"
                                ref={this.adressInput}
                                placeholder="Wyszukaj stacje paliw..."
                                className={`${styles.searchInput} ${styles.searchInputE100}`}
                                onKeyDown={(e) => {
                                  !this.state.autoPressed &&
                                    this.state.enterPressed &&
                                    this.handleKeyDown(
                                      e,
                                      this.state.autocomplete
                                    );
                                  // this.setState({selected_car: null, selectedTableRowId: 0})
                                }}
                              />
                              {(this.adressInput.current?.value !== "" ||
                                this.state.selected_car ||
                                this.state.selected_country ||
                                this.state.on_road_checked ||
                                this.state.radius_km) && (
                                <button onClick={this.resetFuelSearch}>
                                  <X />
                                </button>
                              )}
                            </div>
                          </Autocomplete>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "15px" }}>
                      {this.state.selectedTableRowId ? (
                        <div className={styles.ifCarSelectedRow}>
                          <TimeToLeave />
                          {this.state.selectedTableRowId
                            ? this.state.carList.filter(
                                (car) =>
                                  car.id === this.state.selectedTableRowId
                              )[0].name
                            : this.state.selected_car !== null
                            ? this.state.selected_car
                            : ""}
                          <button
                            className={styles.reset}
                            onClick={(e) => {
                              this.setState({
                                markersE100: this.state.markersE100_const,
                              });
                              this.resetCarSelection();
                            }}
                          >
                            <X />
                          </button>
                        </div>
                      ) : null}

                      <h3>Kategorie stacji benzynowych:</h3>
                      <Grid container style={{ marginTop: "5px" }}>
                        <Grid item xs={2}>
                          <Grid container>
                            <Grid item xs={6}>
                              <Checkbox
                                style={{ padding: "0" }}
                                name="checkE100_3"
                                checked={checkE100_3}
                                onChange={this.handleCheckboxChange}
                              />
                            </Grid>
                            <Tooltip
                              title="Power Max"
                              TransitionComponent={Fade}
                            >
                              <Grid item xs={6}>
                                <div className={styles.e100Icon}>
                                  <E100
                                    fill={"#6d4141"}
                                    width={"24px"}
                                    height={"auto"}
                                  />
                                </div>
                              </Grid>
                            </Tooltip>
                          </Grid>
                        </Grid>

                        <Grid item xs={2}>
                          <Grid container>
                            <Grid item xs={6}>
                              <Checkbox
                                style={{ padding: "0" }}
                                name="checkE100_2"
                                checked={checkE100_2}
                                onChange={this.handleCheckboxChange}
                              />
                            </Grid>
                            <Tooltip
                              title="Discount+"
                              TransitionComponent={Fade}
                            >
                              <Grid item xs={6}>
                                <div className={styles.e100Icon}>
                                  <E100
                                    fill={"#ffc000"}
                                    width={"24px"}
                                    height={"auto"}
                                  />
                                </div>
                              </Grid>
                            </Tooltip>
                          </Grid>
                        </Grid>

                        <Grid item xs={2}>
                          <Grid container>
                            <Grid item xs={6}>
                              <Checkbox
                                style={{ padding: "0" }}
                                name="checkE100_4"
                                checked={checkE100_4}
                                onChange={this.handleCheckboxChange}
                              />
                            </Grid>
                            <Tooltip title="Economy" TransitionComponent={Fade}>
                              <Grid item xs={6}>
                                <div className={styles.e100Icon}>
                                  <E100
                                    fill={"#808080"}
                                    width={"24px"}
                                    height={"auto"}
                                  />
                                </div>
                              </Grid>
                            </Tooltip>
                          </Grid>
                        </Grid>

                        <Grid item xs={2}>
                          <Grid container>
                            <Grid item xs={6}>
                              <Checkbox
                                style={{ padding: "0" }}
                                name="checkE100_1"
                                checked={checkE100_1}
                                onChange={this.handleCheckboxChange}
                              />
                            </Grid>
                            <Tooltip
                              title="Ordinary"
                              TransitionComponent={Fade}
                            >
                              <Grid item xs={6}>
                                <div className={styles.e100Icon}>
                                  <E100
                                    fill={"#bcbcba"}
                                    width={"24px"}
                                    height={"auto"}
                                  />
                                </div>
                              </Grid>
                            </Tooltip>
                          </Grid>
                        </Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={3}>
                          <Grid container>
                            <Grid item xs={4}>
                              <Checkbox
                                style={{ padding: "0" }}
                                name="checkE100_all"
                                checked={checkE100_all}
                                onChange={this.handleCheckboxChange}
                              />
                            </Grid>
                            <Grid item xs={8}>
                              <small style={{ fontSize: "10px" }}>
                                {checkE100_all === false
                                  ? "wszystkie"
                                  : "żaden"}
                              </small>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <h3 style={{ marginTop: "5px" }}>Dodatkowe opcje:</h3>
                      {/* <hr/> */}

                      <Grid
                        container
                        className={styles.fuelSearchBoxOptions}
                        style={{ marginTop: "5px" }}
                      >
                        <FormControl
                          component="fieldset"
                          style={{ width: "100%" }}
                        >
                          <RadioGroup
                            aria-label="gender"
                            name="gender1"
                            value={this.state.radioOptionsE100}
                            onChange={this.handleOptionsRadioE100}
                          >
                            {this.state.selected_car !== null &&
                              this.state.selected_car.activeOrderData
                                .exists && (
                                <Grid item xs={12}>
                                  <Grid container alignItems="center">
                                    <Grid item xs={1}>
                                      <Checkbox
                                        style={{ padding: "0" }}
                                        name="checkboxOn_road"
                                        checked={this.state.on_road_checked}
                                        onChange={(checked) => {
                                          this.handleCheckboxOnRoad(
                                            checked.target.checked
                                          );
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={11}>
                                      Uwzględniaj tylko stacje na trasie
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )}

                            <Grid item xs={12} style={{ marginTop: "4px" }}>
                              <Grid
                                container
                                style={
                                  this.adressInput.current?.value === "" &&
                                  this.state.selected_car === null
                                    ? { opacity: 0.3 }
                                    : { opacity: 1 }
                                }
                              >
                                <Grid item xs={1}>
                                  <Checkbox
                                    style={{ padding: "0" }}
                                    disabled={
                                      this.adressInput.current?.value === "" &&
                                      this.state.selected_car === null
                                        ? true
                                        : false
                                    }
                                    name="checkboxRadius_km"
                                    checked={this.state.radius_km_checked}
                                    onChange={(checked) => {
                                      if (checked.target.checked === false) {
                                        this.setState({ radius_km: null });
                                      }
                                      // if(checked.target.checked === false){
                                      // 	this.setState({selected_country: null})
                                      // }
                                      // console.log('checked.target.checked',checked.target.checked)
                                      this.handleCheckboxRadiusKm(
                                        checked.target.checked
                                      );
                                      if (this.state.checkE100_3 === false) {
                                        this.setState({ checkE100_3: true });
                                      }
                                    }}
                                  />
                                  {/* <FormControlLabel value="2" control={<Radio />} onClick={this.handleOptionsRadioE100Click}/> */}
                                </Grid>
                                <Grid item xs={11}>
                                  <div className={styles.radioRow}>
                                    <div
                                      style={
                                        this.state.radius_km_checked
                                          ? { marginTop: "4px" }
                                          : { marginTop: "0px" }
                                      }
                                    >
                                      Stację z najwyższym rabatem w promieniu od
                                      punktu
                                    </div>

                                    {this.state.radius_km_checked ? (
                                      <div className={styles.flexRow}>
                                        <input
                                          className={styles.input}
                                          type={"number"}
                                          name={"radius_km"}
                                          value={this.state.radius_km || ""}
                                          onChange={(e) => {
                                            this.setState({
                                              radius_km: e.target.value,
                                            });
                                          }}
                                        />
                                        <small>km</small>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: "-4px" }}>
                              <Grid container>
                                <Grid item xs={1}>
                                  {/* <FormControlLabel value="3" control={<Radio />} onClick={this.handleOptionsRadioE100Click}/> */}
                                  <Checkbox
                                    style={{ padding: "0" }}
                                    name="checkboxCountry"
                                    checked={this.state.country_selected}
                                    onChange={(checked) => {
                                      if (checked.target.checked === false) {
                                        this.setState({
                                          selected_country: null,
                                        });
                                      }
                                      this.handleCheckboxCountry(
                                        checked.target.checked
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={11}>
                                  <div className={styles.radioRow}>
                                    <div
                                      style={
                                        this.state.country_selected
                                          ? { marginTop: "4px" }
                                          : { marginTop: "0px" }
                                      }
                                    >
                                      Wyszukaj po kraju
                                    </div>

                                    {this.state.country_selected && (
                                      <div style={{ width: "100%" }}>
                                        <Select
                                          value={this.state.selected_country}
                                          className={`${styles.select}`}
                                          options={this.state.country}
                                          onChange={(e) => {
                                            this.setState({
                                              selected_country: e,
                                            });
                                            // this.filterByCountry(e)
                                          }}
                                          name="transportSet"
                                          placeholder="Wybierz kraj..."
                                          styles={{
                                            control: (base, state) => ({
                                              ...base,
                                              border: "1px solid #142f42",
                                              boxShadow: "none",
                                              minHeight: "unset",
                                              height: "30px",
                                              "&:hover": {
                                                border: "1px solid #142f42",
                                              },
                                            }),
                                            option: (provided, state) => ({
                                              ...provided,
                                              backgroundColor:
                                                state.isFocused && "#142f42",
                                              color: state.isFocused && "#fff",
                                            }),
                                            indicatorSeparator: (
                                              provided,
                                              state
                                            ) => ({
                                              ...provided,
                                              marginRight: "5px",
                                            }),
                                            dropdownIndicator: (provided) => ({
                                              ...provided,
                                              "& div": {
                                                border: "1px solid #142f42",
                                              },
                                              padding: "0 5px 0 0",
                                            }),
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                    {this.state.e100_list_distanse_sorted.length ? (
                      // && this.state.ifShowResult
                      <Grid item xs={12} style={{ marginTop: "10px" }}>
                        <div className={styles.distances}>
                          <h3>
                            Najblizsze stacje:
                            <span className={styles.link}>
                              {this.state.selected_car !== null
                                ? this.state.selected_car.name
                                : this.adressInput.current?.value}
                            </span>
                            <Tooltip
                              title="Pokaż pojazd"
                              TransitionComponent={Fade}
                            >
                              <button
                                className={styles.searchRouteBtn}
                                style={
                                  this.state.selected_list_e100 === null
                                    ? { background: "#129865" }
                                    : { background: "#142f42" }
                                }
                                onClick={(e) => {
                                  this.state.map.setZoom(12);
                                  this.state.map.setCenter({
                                    lat:
                                      this.state.selected_car !== null
                                        ? this.state.selected_car.lat
                                        : this.state.selected_point[0],
                                    lng:
                                      this.state.selected_car !== null
                                        ? this.state.selected_car.lng
                                        : this.state.selected_point[1],
                                  });
                                  this.setState({
                                    centerMap: {
                                      lat:
                                        this.state.selected_car !== null
                                          ? this.state.selected_car.lat
                                          : this.state.selected_point[0],
                                      lng:
                                        this.state.selected_car !== null
                                          ? this.state.selected_car.lng
                                          : this.state.selected_point[1],
                                    },
                                    zoomMap: 12,
                                    selected_list_e100: null,
                                  });
                                }}
                              >
                                <SearchIcon />
                              </button>
                            </Tooltip>
                          </h3>
                          <Grid container>
                            <div className={styles.stationsList}>
                              {this.state.e100_list_distanse_sorted.map(
                                (o, i) => {
                                  return (
                                    <div className={styles.stationsListRow}>
                                      <div>{i + 1}</div>
                                      <div>
                                        <img
                                          className={styles.modalE100Logo}
                                          src={require(`../../img/icons/e100_${o.type_e100}.png`)}
                                          alt="icon"
                                        />
                                      </div>
                                      <div>{o.name}</div>
                                      <div>
                                        <Tooltip
                                          title="Pokaż stacje"
                                          TransitionComponent={Fade}
                                        >
                                          <button
                                            className={styles.searchRouteBtn}
                                            style={
                                              this.state.selected_list_e100 ===
                                              o.id_e100
                                                ? { background: "#129865" }
                                                : { background: "#142f42" }
                                            }
                                            onClick={(e) => {
                                              this.state.map.setZoom(12);
                                              this.state.map.setCenter({
                                                lat: o.position.lat,
                                                lng: o.position.lng,
                                              });
                                              this.setState({
                                                centerMap: {
                                                  lat: o.position.lat,
                                                  lng: o.position.lng,
                                                },
                                                zoomMap: 12,
                                                selected_list_e100: o.id_e100,
                                              });
                                            }}
                                          >
                                            <SearchIcon />
                                          </button>
                                        </Tooltip>
                                        <Tooltip
                                          title="Wyznacz trase"
                                          TransitionComponent={Fade}
                                        >
                                          <button
                                            className={styles.searchRouteBtn}
                                            style={
                                              this.state.route_e100[0]?.sections[0].arrival.place.originalLocation.lat.toFixed(
                                                4
                                              ) === o.position.lat.toFixed(4)
                                                ? { background: "#129865" }
                                                : { background: "#142f42" }
                                            }
                                            onClick={(e) => {
                                              // console.log('o',o, this.state.selected_car, 'pointToPoint', i)

                                              if (
                                                this.state.selected_car?.id !==
                                                undefined
                                              ) {
                                                // console.log("routing do pojazdu..", this.state.selected_car.id)
                                                this.routeE100(
                                                  o,
                                                  this.state.selected_car,
                                                  "pointToPoint",
                                                  i
                                                );
                                              } else if (
                                                this.state.selected_point
                                              ) {
                                                // console.log("routing do punktu..", this.state.selected_point)
                                                this.routeE100(
                                                  o,
                                                  this.state.selected_point,
                                                  "pointToPoint",
                                                  i
                                                );
                                              }
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              enableBackground="new 0 0 24 24"
                                              height="16"
                                              viewBox="0 0 24 24"
                                              width="24"
                                            >
                                              <g>
                                                <rect
                                                  fill="none"
                                                  height="24"
                                                  width="24"
                                                />
                                                <path d="M9.78,11.16l-1.42,1.42c-0.68-0.69-1.34-1.58-1.79-2.94l1.94-0.49C8.83,10.04,9.28,10.65,9.78,11.16z M11,6L7,2L3,6h3.02 C6.04,6.81,6.1,7.54,6.21,8.17l1.94-0.49C8.08,7.2,8.03,6.63,8.02,6H11z M21,6l-4-4l-4,4h2.99c-0.1,3.68-1.28,4.75-2.54,5.88 c-0.5,0.44-1.01,0.92-1.45,1.55c-0.34-0.49-0.73-0.88-1.13-1.24L9.46,13.6C10.39,14.45,11,15.14,11,17c0,0,0,0,0,0h0v5h2v-5 c0,0,0,0,0,0c0-2.02,0.71-2.66,1.79-3.63c1.38-1.24,3.08-2.78,3.2-7.37H21z" />
                                              </g>
                                            </svg>
                                          </button>
                                        </Tooltip>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </Grid>
                        </div>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>

                  <button
                    // disabled={
                    // 	this.adressInput.current?.value === '' &&
                    // 	this.state.selected_car === null &&
                    // 	this.state.selected_country === null
                    // 	? true : false}
                    className={styles.showResult}
                    onClick={(e) => {
                      this.showResults();
                      if (this.state.open_markerId !== null) {
                        this.handleInfoWindowCloseE100(
                          this.state.open_markerId
                        );
                      }
                    }}
                  >
                    {this.state.selected_car !== null
                      ? "Wyszukaj 3 najbliższe stacje do pojazdu"
                      : "Wyszukaj"}
                  </button>
                </div>
              )}
            </div>
          </GoogleMap>
          <Snackbar
            open={this.state.openDialog}
            autoHideDuration={4000}
            onClose={this.handleClose}
          >
            <Alert onClose={this.handleClose} severity={this.state.dialogColor}>
              {this.state.dialogContent}
            </Alert>
          </Snackbar>
        </div>
      </div>
    );
  }
}

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const routeMarkers = importAll(
  require.context("../../img/routemarkers", false, /\.png/)
);

const containerStyle = {
  width: "calc(100vw - 550px)",
  minHeight: "calc(100vh - 88px)",
};

const centerMap = {
  lat: 52.14,
  lng: 19.51,
};

const optionsMap = {
  zoomControlOptions: {
    position: google.maps.ControlPosition.RIGHT_CENTER, // 'right-center'
  },
  streetViewControl: false,
  mapTypeControl: false,
};

const osmType = new google.maps.ImageMapType({
  getTileUrl: function (coord, zoom) {
    let tilesPerGlobe = 1 << zoom;
    let x = coord.x % tilesPerGlobe;
    if (x < 0) {
      x = tilesPerGlobe + x;
    }

    return (
      "http://tile.openstreetmap.org/" + zoom + "/" + x + "/" + coord.y + ".png"
    );
  },
  tileSize: new google.maps.Size(256, 256),
  maxZoom: 18,
  minZoom: 0,
  radius: 1738000,
  name: "OpenStreetMap",
});

const hereType = new google.maps.ImageMapType({
  getTileUrl: function (coord, zoom) {
    let tilesPerGlobe = 1 << zoom;
    let x = coord.x % tilesPerGlobe;
    if (x < 0) {
      x = tilesPerGlobe + x;
    }

    return (
      "https://maps.hereapi.com/v3/base/mc/" +
      zoom +
      "/" +
      x +
      "/" +
      coord.y +
      `/png?features=vehicle_restrictions:active_and_inactive&style=logistics.day&apiKey=${process.env.REACT_APP_HERE_API_KEY}`
    );
  },
  tileSize: new google.maps.Size(256, 256),
  maxZoom: 18,
  minZoom: 0,
  radius: 1738000,
  name: "HERE maps",
});

const optionsMC = {
  imagePath:
    "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
};

const getPixelPositionOffset = (width, height) => ({
  x: -34,
  y: -30,
});

const lineSymbolRoutePro = {
  path: "M 0, -1 0, 1",
  strokeWeight: 3,
  strokeColor: "#bbf5bb",
  strokeOpacity: 0.8,
  scale: 3,
};
const optionsPolylineRoutePro = {
  geodesic: true,
  strokeColor: "#10c500",
  strokeOpacity: 0.8,
  strokeWeight: 3,
  icons: [
    {
      icon: lineSymbolRoutePro,
      repeat: "25px",
    },
  ],
};

const lineSymbol = {
  path: "M 1.5 1 L 1 0 L 1 2 M 0.5 1 L 1 0",
  fillColor: "#1891DE",
  strokeColor: "#1891DE",
  strokeWeight: 2,
  strokeOpacity: 1,
};

const lineSymbolSygic = {
  path: "M 1.5 1 L 1 0 L 1 2 M 0.5 1 L 1 0",
  fillColor: "#009900",
  strokeColor: "#009900",
  strokeWeight: 2,
  strokeOpacity: 1,
};

const optionsPolyline = {
  geodesic: true,
  strokeColor: "#1891DE",
  strokeOpacity: 0.7,
  strokeWeight: 5,
  icons: [
    {
      icon: lineSymbol,
      offset: "25px",
      repeat: "100px",
    },
  ],
};

const optionsPolylineHere = {
  geodesic: true,
  strokeColor: "#1891DE",
  strokeOpacity: 0.7,
  strokeWeight: 5,
  icons: [
    {
      icon: lineSymbol,
      offset: "25px",
      repeat: "100px",
    },
  ],
};

const optionsPolylineSygic = {
  geodesic: true,
  strokeColor: "#009900",
  strokeOpacity: 0.7,
  strokeWeight: 5,
  icons: [
    {
      icon: lineSymbolSygic,
      offset: "25px",
      repeat: "100px",
    },
  ],
};

const lineSymbolOrderRouteProgress = {
  path: "M 1.5 1 L 1 0 L 1 2 M 0.5 1 L 1 0",
  fillColor: "#FF0000",
  strokeColor: "#FF0000",
  strokeWeight: 2,
  strokeOpacity: 1,
};

const optionsPolylineOrderRouteProgress = {
  geodesic: true,
  strokeColor: "#FF0000",
  strokeOpacity: 0.7,
  strokeWeight: 5,
  icons: [
    {
      icon: lineSymbolOrderRouteProgress,
      offset: "25px",
      repeat: "100px",
    },
  ],
};

const lineSymbolE100RouteProgress = {
  path: "M 1.5 1 L 1 0 L 1 2 M 0.5 1 L 1 0",
  fillColor: "#0deaa9",
  strokeColor: "#0deaa9",
  strokeWeight: 2,
  strokeOpacity: 1,
};

const optionsPolylineE100RouteProgress = {
  geodesic: true,
  strokeColor: "#0deaa9",
  strokeOpacity: 0.9,
  strokeWeight: 5,
  icons: [
    {
      icon: lineSymbolE100RouteProgress,
      offset: "25px",
      repeat: "100px",
    },
  ],
};

const lineSymbolOneDay = {
  path: "M 1.5 1 L 1 0 L 1 2 M 0.5 1 L 1 0",
  fillColor: "#152c3c",
  strokeColor: "#152c3c",
  strokeWeight: 2,
  strokeOpacity: 1,
};
const optionsPolylineOneDay = {
  geodesic: true,
  strokeColor: "#0b0b0b",
  strokeOpacity: 0.8,
  strokeWeight: 5,
  icons: [
    {
      icon: lineSymbolOneDay,
      offset: "25px",
      repeat: "100px",
    },
  ],
};
