import React, { useState, useEffect, useRef } from 'react';
import Input from '../../components/input'
import Title from '../../components/title'
import styles from '../table.module.css'
import stylesMod from '../../styles/documents.module.css'
import BTNstyles from '../../styles/button.module.css'
import RowsPerPage from '../../components/RowsPerPage'
import Pagination from '../../components/pagination'
import { DBurl } from '../../appConfig'
import { X, Disc, Target, File, Check, UploadCloud, Download } from 'react-feather';
import {
	Button, Table, TableBody, TableCell, TableContainer, IconButton, TableHead, TableRow, TableSortLabel, Paper, Checkbox, Slide, Snackbar, Dialog,
	DialogActions, DialogContent, DialogContentText, DialogTitl, DialogTitle, Tooltip, Grid, Switch, FormControlLabel
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import IcoBtn from '../../components/buttons/icoBtn';
import { styled } from '@material-ui/styles';
import EditDocs from './companyDocsEdit'
import DefineCols from './defineCols';
import AllFiles from './allFiles';
import docsStyles from '../../styles/documents.module.css'

const GreenSwitch = styled(Switch)(({ theme }) => ({
	'& .MuiSwitch-switchBase.Mui-checked': {
		color: "#129865",
	},
	'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
		backgroundColor: "#129865",
	},
	'& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
		backgroundColor: "#129865",
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const headCells = [
	{ id: 'doc_cert_pro_competence_date', numeric: true, disablePadding: false, label: 'Certyfikat kompetencji zawodowych', class: docsStyles.headCell },
	{ id: 'doc_domestic_transport_license_date', numeric: true, disablePadding: false, label: 'Licencja na transport krajowy' },
	{ id: 'doc_international_transport_license_date', numeric: true, disablePadding: false, label: 'Licencja na transport międzynarodowy' },
];

const buttonsCell = [{ id: 'btns', numeric: true, disablePadding: false, label: 'Pobieranie plików i edycja danych', class: styles.statusShort }]

const CompanyDocs = (props) => {
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('car');
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(15);
	const [table, setTable] = useState([])

	const [openDialog, setOpenDialog] = useState(false)
	const [dialogContent, setDialogContent] = useState("")
	const [dialogColor, setDialogColor] = useState("error")

	const [openModalEdit, setOpenModalEdit] = useState()
	const [dateChanges, setDateChanges] = useState(false)


	const handleSetDateChanges = () => setDateChanges(true)

	const fetchFile = async (filename, type, firm_id, ownerFileName) => {
		const response = await fetch(`${DBurl}/setDocumentsFirmFiles`, {
			method: "POST",
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": props.user.csrf_token
			},
			body: JSON.stringify({
				mode: 'insert',
				data: {
					customer_id: firm_id, // ID customera
					type: type, // Nazwa kolumny do której wskazuje plik
					filename: `${filename}---${ownerFileName}` // Nazwa pliku z uploadFile
				}
			})
		});
		const json = await response.json()
		if (json.success) {
			fetchCompany(props.user.csrf_token)
		}
	}

	const sendFileForVehicle = async (file, type, firm_id, isUserDefined = false) => {
		try {
			const formData = new FormData();
			formData.append('file', file, file.name);
			const response = await fetch(`${DBurl}/uploadFile`, {
				method: "POST",
				credentials: 'include',
				headers: {
					"X-CSRF-Token": props.user.csrf_token,
				},
				body: formData
			});
			const json = await response.json();
			if (json.success) {
				!isUserDefined && fetchFile(json.data?.filename, type, firm_id, file.name)
				isUserDefined && fetchFileUserDefined(json.data?.filename, type, firm_id, file.name)
			}
		}
		catch (err) {
			return
		}

	}

	const fetchFileUserDefined = async (filename, type, firm_id, ownerFileName) => {
		props.loading(true)
		const response = await fetch(`${DBurl}/setDocumentsOwnDefValues`, {
			method: "POST",
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": props.user.csrf_token
			},
			body: JSON.stringify({
				mode: 'insert',
				data: {
					document_child_id: firm_id, // car_id, driver_id
					document_own_def_id: type, // id kolumny z definicji
					is_file: true, // Czy plik czy parametr
					value: null, // Wypełniamy -> Jeżeli is_file = false
					filename: `${filename}---${ownerFileName}` // Wypełniamy -> Jeżeli is_file = true
				}
			})
		});

		const json = await response.json()
		if (json.success) {
			fetchCompany(props.user.csrf_token)
			props.loading(false)
		}
	}


	function EnhancedTableHead(props) {
		const { styles, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

		return (
			<TableHead className={styles.tabHead}>
				<TableRow>
					{headCells.concat(columns).concat(buttonsCell).map((headCell) => {
						return (
							<TableCell
								key={headCell.id}
								align="left"
								padding={headCell.disablePadding ? 'none' : 'default'}
								sortDirection={orderBy === headCell.id ? order : false}
								className={headCell.class}
							>
								{headCell.label}
							</TableCell>
						)
					})}
				</TableRow>
			</TableHead>
		);
	}

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenDialog(false)
	}

	const fetchCompany = async (token) => {
		props.loading(true)
		const response = await fetch(`${DBurl}/getDocumentsFirm`, {
			method: "GET",
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": token
			}
		});
		const json = await response.json()
		if (json.success) {
			setTable(json.data)
			props.loading(false)
		}
	}

	const getDocumentsOwnDef = async () => {
		const response = await fetch(`${DBurl}/getDocumentsOwnDef`, {
			method: "GET",
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": props.user.csrf_token
			}
		});
		const json = await response.json()

		if (json.success) {

			const firmCols = json.data.filter(col => col.type === "firm")
			const colsToRender = firmCols.map(col => {
				return {
					...col,
					label: col.col_name,
				}
			})
			setColumns(colsToRender)
		}
	}

	const checkPassword = async () => {
		const response = await fetch(`${DBurl}/checkCustomerEditDataPassword?password=${password}`, {
			method: "GET",
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": props.user.csrf_token
			}
		});
		const json = await response.json()
		if (json.success) {
			if (json.data.correct_password) {
				setLoggedIn(true)
				setOpenModalCheckPassword(false)
				setPassword('')
			}
			else {
				setOpenDialog(true)
				setDialogContent("Błędne hasło. Spróbuj ponownie bądź skontaktuj się z administratorem.")
				setDialogColor('error')
			}
		}
	}

	useEffect(() => {
		fetchCompany(props.user.csrf_token)
		getDocumentsOwnDef()
	}, [])

	useEffect(() => {
		if (!openModalEdit && dateChanges) {
			fetchCompany(props.user.csrf_token)
		}
	}, [openModalEdit])

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = table.map((n) => n.id);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		setSelected(newSelected);
	};
	const paginate = pageNumber => setPage(pageNumber - 1);
	const isSelected = (name) => selected.indexOf(name) !== -1;


	////// DOKUMENTY - FIRMA  

	const [showDeadlines, setShowDeadlines] = useState(true)
	const [columns, setColumns] = useState([])
	const [showFiles, setShowFiles] = useState([])
	const [firmData, setFirmData] = useState([])
	const [openModalDefine, setOpenModalDefine] = useState()
	const [openModalAllFiles, setOpenModalAllFiles] = useState()
	const [openModalCheckPassword, setOpenModalCheckPassword] = useState()
	const [filesData, setFilesData] = useState([])
	const [colName, setColName] = useState('')
	const [loggedIn, setLoggedIn] = useState(false)
	const [password, setPassword] = useState('')

	function useInput({ type }) {
		const [searchValue, setSearchValue] = useState("")
		const input = <TableCell className={docsStyles.headCell}><Input value={searchValue} handleChange={(e) => {
			if (openModalCheckPassword) return
			else { setSearchValue(e.target.value) }
		}
		} type={type}
			search={true}
			id={'search'} autocomplete={false} /></TableCell>;
		return [searchValue, input];
	}

	const cols = ['doc_cert_pro_competence_date', 'doc_domestic_transport_license_date', 'doc_international_transport_license_date']


	const printDocTemplateXLS = () => {
		const url = `${DBurl}/printDocTemplateXLS?what=firm&output=I`;
		window.open(url, '_blank');
	}
	const saveDocTemplateXLS = async (fileName) => {
		props.loading(true)
		const response = await fetch(`${DBurl}/saveDocTemplateXLS?what=firm&filename=${fileName}`, {
			method: "GET",
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": props.user.csrf_token
			}
		});
		const json = await response.json()
		if (json.success) {
			setOpenDialog(true)
			setDialogContent(`Tabela została zaktualizowana o pozycje z zaimportowanego pliku.`)
			setDialogColor("success")

			fetchCompany(props.user.csrf_token)
			getDocumentsOwnDef()
			props.loading(false)
		}
	}
	const fetchFileXLS = async file => {
		props.loading(true)
		try {

			const formData = new FormData();
			formData.append('file', await file[0][0], file[0][0].name);
			const response = await fetch(`${DBurl}/uploadFile`, {
				method: "POST",
				credentials: 'include',
				headers: {
					"X-CSRF-Token": props.user.csrf_token,
				},
				body: formData
			});
			const json = await response.json();
			if (json.success) {
				props.loading(false)
				saveDocTemplateXLS(json.data?.filename)
				return {
					name: json.data?.filename,
					msg: file.name,
				}

			}
		}
		catch (err) {
			props.loading(false)
			setDialogContent(`Błąd przesyłania pliku.`)
			setDialogColor("error")
			return {}
		}

	}

	return (
		<>
			<div className={styles.root}>
				<Paper className={styles.paper}>
					<Title title={props.state.tabName}
						btnBox
						deadlines
						documents
						editCols
						editDates
						editDatesBtnTxt={!loggedIn ? "Edytuj daty" : "Zakończ edycję"}
						showDeadlines={showDeadlines}
						docsFunc={e => {
							setShowDeadlines(false)
						}}
						deadlinesFunc={e => {
							setShowDeadlines(true)
							setShowFiles([])
						}}
						editColsFunc={e => {
							setOpenModalDefine(true)
						}}
						editDatesFunc={e => {
							!loggedIn ? setOpenModalCheckPassword(true) : setLoggedIn(false) && setPassword('')
						}}

						btnDocExport
						btnDocExportFunc={e => printDocTemplateXLS()}

						btnDocImport
						fetchFileXLS={fetchFileXLS}
						class={loggedIn ? BTNstyles.titleBtn : docsStyles.btnDisabled}
						loggedIn={loggedIn}
					/>
					<TableContainer className={styles.stickyTable}>
						<Table
							className={styles.table}
							aria-labelledby="tableTitle"
							size="small"
							aria-label="enhanced table"
						>

							<EnhancedTableHead
								styles={styles}
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onSelectAllClick={handleSelectAllClick}
								onRequestSort={handleRequestSort}
								rowCount={table.length}
							/>
							<TableBody className={styles.tabBody}>
								{table
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => {
										const isItemSelected = isSelected(row.id);
										const labelId = `enhanced-table-checkbox-${index}`;
										return (<>
											<TableRow
												hover
												onClick={(event) => handleClick(event, row.id)}
												role="checkbox"
												aria-checked={isItemSelected}
												tabIndex={-1}
												key={row.id}
												selected={isItemSelected}
												style={{
													backgroundColor: 'inherit',
													height: 51
												}}
											>
												<TableCell className={docsStyles.headCell} align="left">{showDeadlines ? row.doc_cert_pro_competence_date : row.files.filter(type => type.type === "doc_cert_pro_competence_date").sort((a, b) => b.id - a.id)[0]?.filename.split("---")[1]}</TableCell>
												<TableCell align="left">{showDeadlines ? row.doc_domestic_transport_license_date : row.files.filter(type => type.type === "doc_domestic_transport_license_date").sort((a, b) => b.id - a.id)[0]?.filename.split("---")[1]}</TableCell>
												<TableCell align="left">{showDeadlines ? row.doc_international_transport_license_date : row.files.filter(type => type.type === "doc_international_transport_license_date").sort((a, b) => b.id - a.id)[0]?.filename.split("---")[1]}</TableCell>
												{columns.length > 0 && columns.map((col) => {
													return <TableCell align="left">{showDeadlines ? <>
														{row.own_def_vales
															.filter(xx => xx.col_id === col.id && !xx.is_file)
															.slice(-1)[0]?.value
															.slice(0, 10)}</>
														: row.own_def_vales.filter(file => file.is_file).filter(type => type.col_id === col.id).slice(-1)[0]?.filename.split("---")[1]}</TableCell>
												})}
												<TableCell className={styles.statusShort}>
													{!showDeadlines ?
														<div className={`${stylesMod.inp}`}>
															<FormControlLabel className={stylesMod.smallerFont}
																control={
																	<GreenSwitch
																		onChange={e => {
																			const newValue = e.target?.checked ? [...showFiles, index] : [...showFiles.filter(val => val !== index)]
																			setShowFiles(newValue)
																		}}
																	/>}
																label={""}
															/>
														</div> :
														<>{loggedIn && <IcoBtn icon="Edit" tooltip="Edytuj" value={row} onClick={(e) => {
															setFirmData(row)
															setOpenModalEdit(true)
														}}
														/>}</>}
												</TableCell>
											</TableRow>
											{showFiles.includes(index) && !showDeadlines &&
												<>
													<TableRow>
														<TableCell className={docsStyles.headCell}>
															<div>
																<div className={docsStyles.currentFileContainer}>
																	<div onClick={() => window.open(`${DBurl}/getFile?filename=${row.files.filter(type => type.type === 'doc_cert_pro_competence_date').sort((a, b) => b.id - a.id)[0]?.filename.split("---")[0]}`, '_blank')}>
																		{row.files.filter(type => type.type === 'doc_cert_pro_competence_date').sort((a, b) => b.id - a.id)[0]?.filename.split("---")[1]}
																	</div>
																	<div>
																		{row.files.filter(type => type.type === 'doc_cert_pro_competence_date').length > 0 &&
																			<Download className={docsStyles.download} onClick={() => {
																				fetch(`${DBurl}/getFile?filename=${row.files.filter(type => type.type === 'doc_cert_pro_competence_date').sort((a, b) => b.id - a.id)[0]?.filename.split("---")[0]}`)
																					.then(response => response.blob())
																					.then(blob => {
																						const link = document.createElement('a');
																						link.download = row.files.filter(type => type.type === 'doc_cert_pro_competence_date').sort((a, b) => b.id - a.id)[0]?.filename.split("---")[1];
																						link.href = URL.createObjectURL(blob);
																						document.body.appendChild(link);
																						link.click();
																						URL.revokeObjectURL(link.href);
																					});
																			}} />}
																	</div>
																</div>
																<div className={stylesMod.date}>{row.files.filter(type => type.type === 'doc_cert_pro_competence_date').sort((a, b) => b.id - a.id)[0]?.ts_add}</div>
															</div>
														</TableCell>
														<TableCell>
															<div>
																<div className={docsStyles.currentFileContainer}>
																	<div onClick={() => window.open(`${DBurl}/getFile?filename=${row.files.filter(type => type.type === 'doc_domestic_transport_license_date').sort((a, b) => b.id - a.id)[0]?.filename.split("---")[0]}`, '_blank')}>
																		{row.files.filter(type => type.type === 'doc_domestic_transport_license_date').sort((a, b) => b.id - a.id)[0]?.filename.split("---")[1]}
																	</div>
																	<div>
																		{row.files.filter(type => type.type === 'doc_domestic_transport_license_date').length > 0 &&
																			<Download className={docsStyles.download} onClick={() => {
																				fetch(`${DBurl}/getFile?filename=${row.files.filter(type => type.type === 'doc_domestic_transport_license_date').sort((a, b) => b.id - a.id)[0]?.filename.split("---")[0]}`)
																					.then(response => response.blob())
																					.then(blob => {
																						const link = document.createElement('a');
																						link.download = row.files.filter(type => type.type === 'doc_domestic_transport_license_date').sort((a, b) => b.id - a.id)[0]?.filename.split("---")[1];
																						link.href = URL.createObjectURL(blob);
																						document.body.appendChild(link);
																						link.click();
																						URL.revokeObjectURL(link.href);
																					});
																			}} />}
																	</div>
																</div>
																<div className={stylesMod.date}>{row.files.filter(type => type.type === 'doc_domestic_transport_license_date').sort((a, b) => b.id - a.id)[0]?.ts_add}</div>
															</div>
														</TableCell>
														<TableCell>
															<div>
																<div className={docsStyles.currentFileContainer}>
																	<div onClick={() => window.open(`${DBurl}/getFile?filename=${row.files.filter(type => type.type === 'doc_international_transport_license_date').sort((a, b) => b.id - a.id)[0]?.filename.split("---")[0]}`, '_blank')}>
																		{row.files.filter(type => type.type === 'doc_international_transport_license_date').sort((a, b) => b.id - a.id)[0]?.filename.split("---")[1]}
																	</div>
																	<div>
																		{row.files.filter(type => type.type === 'doc_international_transport_license_date').length > 0 &&
																			<Download className={docsStyles.download} onClick={() => {
																				fetch(`${DBurl}/getFile?filename=${row.files.filter(type => type.type === 'doc_international_transport_license_date').sort((a, b) => b.id - a.id)[0]?.filename.split("---")[0]}`)
																					.then(response => response.blob())
																					.then(blob => {
																						const link = document.createElement('a');
																						link.download = row.files.filter(type => type.type === 'doc_international_transport_license_date').sort((a, b) => b.id - a.id)[0]?.filename.split("---")[1];
																						link.href = URL.createObjectURL(blob);
																						document.body.appendChild(link);
																						link.click();
																						URL.revokeObjectURL(link.href);
																					});
																			}} />}
																	</div>
																</div>
																<div className={stylesMod.date}>{row.files.filter(type => type.type === 'doc_international_transport_license_date').sort((a, b) => b.id - a.id)[0]?.ts_add}</div>
															</div>
														</TableCell>
														{columns.length > 0 && columns.map((col) => {
															const fileName = row.own_def_vales.filter(file => file.is_file).filter(type => type.col_id === col.id).sort((a, b) => b.id - a.id)[0]?.filename.split("---")[1]
															return (
																<TableCell align="left">
																	<div >
																		<div className={docsStyles.currentFileContainer}>
																			<div onClick={() => window.open(`${DBurl}/getFile?filename=${row.own_def_vales.filter(file => file.is_file).filter(type => type.col_id === col.id).sort((a, b) => b.id - a.id)[0]?.filename.split("---")[0]}`, '_blank')}>
																				{row.own_def_vales.filter(file => file.is_file).filter(type => type.col_id === col.id).sort((a, b) => b.id - a.id)[0]?.filename.split("---")[1]}
																			</div>
																			<div>
																				{row.own_def_vales.filter(file => file.is_file).filter(type => type.col_id === col.id).length > 0 &&
																					<Download className={docsStyles.download} onClick={() => {
																						fetch(`${DBurl}/getFile?filename=${row.own_def_vales.filter(file => file.is_file).filter(type => type.col_id === col.id).sort((a, b) => b.id - a.id)[0]?.filename.split("---")[0]}`)
																							.then(response => response.blob())
																							.then(blob => {
																								const link = document.createElement('a');
																								link.download = fileName;
																								link.href = URL.createObjectURL(blob);
																								document.body.appendChild(link);
																								link.click();
																								URL.revokeObjectURL(link.href);
																							});
																					}} />}
																			</div>
																		</div>
																		<div className={stylesMod.date}>{row.own_def_vales.filter(file => file.is_file).filter(type => type.col_id === col.id).sort((a, b) => b.id - a.id)[0]?.ts_add}</div>
																	</div>
																</TableCell>
															)
														})}
														<TableCell className={styles.statusShort}></TableCell>
													</TableRow>
													<TableRow>
														{headCells.map(col =>
															<TableCell className={`${col.class}`} onClick={e => {
																setOpenModalAllFiles(true)
																setFilesData(row)
																setColName(col.id)
															}}>Pokaż wszystkie pliki
															</TableCell>
														)}
														{columns.length > 0 && columns.map((col) =>
															<TableCell onClick={e => {
																setOpenModalAllFiles(true)
																setFilesData(row)
																setColName(col.id)
															}}>Pokaż wszystkie pliki
															</TableCell>)}
														<TableCell className={styles.statusShort}></TableCell>
													</TableRow>
													<TableRow>
														{headCells.map(col =>
															<TableCell className={col.class}>
																<Grid item xs={12} className={stylesMod.gridButtonParent}>
																	<input
																		// disabled={user_s}
																		accept="*"
																		style={{ display: 'none' }}
																		id={`raised-button-file-${col.id}`}
																		multiple
																		type="file"
																		onChange={(e) => {
																			sendFileForVehicle(e.target.files[0], col.id, row.id)
																		}}
																	/>
																	<Tooltip >
																		<label htmlFor={`raised-button-file-${col.id}`}>
																			<Button
																				variant="outlined"
																				color="primary"
																				component="span"
																				className={stylesMod.button}

																			>
																				+ Dodaj plik
																			</Button>
																		</label>
																	</Tooltip>
																</Grid>
															</TableCell>

														)}
														{columns.map(col =>
															<TableCell>
																<Grid item xs={12} className={stylesMod.gridButtonParent}>
																	<input
																		// disabled={user_s}
																		accept="*"
																		style={{ display: 'none' }}
																		id={`raised-button-file-${col.id}`}
																		multiple
																		type="file"
																		onChange={(e) => {
																			sendFileForVehicle(e.target.files[0], col.id, row.id, true)
																		}}
																	/>
																	<Tooltip >
																		<label htmlFor={`raised-button-file-${col.id}`}>
																			<Button
																				variant="outlined"
																				color="primary"
																				component="span"
																				className={stylesMod.button}

																			>
																				+ Dodaj plik
																			</Button>
																		</label>
																	</Tooltip>
																</Grid>
															</TableCell>

														)}
														<TableCell className={styles.statusShort}></TableCell>
													</TableRow>
												</>}
										</>
										);
									})}
							</TableBody>
						</Table>
					</TableContainer>
					<div className={styles.tableBottom} >
						<RowsPerPage
							rowsPerPage={rowsPerPage}
							setRowsPerPage={setRowsPerPage}
							setPage={setPage}
							tableLength={table.length}
						/>

						<div>
							<Pagination
								postsPerPage={rowsPerPage}
								totalPosts={table.length}
								paginate={paginate}
								page={page}
							/>
						</div>
					</div>

				</Paper>

			</div>
			<Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
				<Alert onClose={handleClose} severity={dialogColor}>
					{dialogContent}
				</Alert>
			</Snackbar>
			<EditDocs
				token={props.user.csrf_token}
				open={openModalEdit}
				setOpen={setOpenModalEdit}
				setSnack={setOpenDialog}
				setSnackContent={setDialogContent}
				setSnackColor={setDialogColor}
				headCells={headCells}
				columns={columns}
				cars={table}
				firmData={firmData}
				handleSetDateChanges={handleSetDateChanges}
				reload={() => {
					fetchCompany(props.user.csrf_token)
					getDocumentsOwnDef()
				}
				}
			/>
			<DefineCols
				token={props.user.csrf_token}
				open={openModalDefine}
				setOpen={setOpenModalDefine}
				setSnack={setOpenDialog}
				setSnackContent={setDialogContent}
				setSnackColor={setDialogColor}
				data={columns}
				colType={'firm'}
				reload={() => {
					fetchCompany(props.user.csrf_token)
					getDocumentsOwnDef()
				}
				}
			/>
			<AllFiles
				token={props.user.csrf_token}
				open={openModalAllFiles}
				setOpen={setOpenModalAllFiles}
				setSnack={setOpenDialog}
				setSnackContent={setDialogContent}
				setSnackColor={setDialogColor}
				data={filesData}
				colName={colName}
				reload={fetchCompany}
			/>

			<Dialog
				maxWidth='md'
				open={openModalCheckPassword}
				TransitionComponent={Transition}
				onClose={() => {
					setOpenModalCheckPassword(false)
					setPassword('')
				}}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>

				<DialogTitle id="alert-dialog-slide-title" className={stylesMod.dialogHead}>Podaj hasło operatora
					<IconButton aria-label="close" className={stylesMod.closeButton} onClick={() => {
						setOpenModalCheckPassword(false)
						setPassword('')
					}}>
						<X />
					</IconButton>
				</DialogTitle >
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<input className={docsStyles.hiddenInput} />
							<Input type={'password'} value={password} handleChange={(e) => { setPassword(e.target.value) }} />
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" size="large" color="primary" className={BTNstyles.btn} onClick={(e) => {
						setOpenModalCheckPassword(false)
						setPassword('')
					}}>
						Anuluj
					</Button>
					<Button variant="contained" size="large" className={`${BTNstyles.btn} ${BTNstyles.grn}`} onClick={() => {
						checkPassword()
					}}
						color="primary">
						Zaloguj się
					</Button>
				</DialogActions>

			</Dialog>

		</>
	);
}


export default CompanyDocs;