import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import PropTypes from "prop-types";
import Input from "../../components/input";

import { DBurl } from "../../appConfig";
import Title from "../../components/title";
import styles from "../table.module.css";
import Button from "@material-ui/core/Button";
import BTNstyles from "../../styles/button.module.css";
import stylesMod from "../../styles/newOrEdit.module.css";

import RowsPerPage from "../../components/RowsPerPage";
import Pagination from "../../components/pagination";
import AddVehicles from "./addVehicles";
import AddProfile from "./addProfile";
import TmsLoading from "components/TmsLoading";

import { deleteCars, getCars } from "api/endpoints";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Slide,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";

import { X } from "react-feather";
import IcoBtn from "../../components/buttons/icoBtn";
import TmsDialog from "components/TmsDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (array?.length > 0) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
}

const headCells = [
  { id: "id", right: false, disablePadding: true, label: "LP." },
  { id: "name", right: false, disablePadding: true, label: "Pojazd" },
  { id: "vin", right: true, disablePadding: false, label: "VIN" },
  {
    id: "rok_produkcji",
    right: true,
    disablePadding: false,
    label: "Wyprodukowany",
  },
  {
    id: "norma_spalania",
    right: true,
    disablePadding: false,
    label: "Spalanie",
  },
  { id: "type", right: true, disablePadding: false, label: "Typ" },
  {
    id: "axle_netto",
    right: true,
    disablePadding: false,
    label: "Masa Własna",
  },
  { id: "term_oc", right: true, disablePadding: false, label: "Termin OC" },
  { id: "term_ac", right: true, disablePadding: false, label: "Termin AC" },
  {
    id: "inspection_valid_until",
    right: true,
    disablePadding: false,
    label: "Przegląd ważny do:",
  },
  {
    id: "extinguisher_valid_to",
    right: true,
    disablePadding: false,
    label: "Gaśnica ważna do:",
  },
  {
    id: "customer.name",
    right: true,
    disablePadding: false,
    label: "Operator",
  },
  { id: "firm.name", right: true, disablePadding: false, label: "Firma" },
  { id: "description", right: true, disablePadding: false, label: "Uwagi" },
];

function EnhancedTableHead(props) {
  const { styles, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={`${styles.tabHead} ${styles.smallText}`}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.right ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={headCell.class}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={styles.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  styles: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function useInput({ type }) {
  const [value, setValue] = useState("");
  const input = (
    <TableCell>
      <Input
        value={value}
        handleChange={(e) => {
          setValue(e.target.value);
        }}
        type={type}
        search={true}
      />
    </TableCell>
  );
  return [value, input];
}

export default function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("short");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [table, setTable] = useState([]);
  const [searchVehicle, setSearchVehicle] = useInput({ type: "text" });
  const [searchVIN, setSearchVIN] = useInput({ type: "text" });
  const [searchComment, setSearchComment] = useInput({ type: "text" });
  const [searchFirm, setSearchFirm] = useInput({ type: "text" });
  const [searchCustomer, setSearchCustomer] = useInput({ type: "text" });

  const [openSnack, setOpenSnack] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [deleteData, setDeleteData] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");

  const [openAddModal, setOpenAddModal] = useState(false);
  const [openAddModalData, setOpenAddModalData] = useState({});
  const [edit, setEdit] = useState(false);

  const [openAddModalProfile, setOpenAddModalProfile] = useState(false);
  const [openAddModalProfileData, setOpenAddModalProfileData] = useState({});
  const [editProfile, setEditProfile] = useState(false);
  const [dialog, setDialog] = useState({ isOpen: false });

  const { refetch: refetchCars, isLoading } = useQuery("getCars", getCars, {
    onSuccess: (data) => {
      setTable(data);
    },
    onError: (error) => {
      props.setDialog({
        isOpen: true,
        type: "error",
        content: `${error.response}`,
      });
    },
  });

  const { mutate: deleteVehicles } = useMutation("deleteCars", deleteCars, {
    onSuccess: () => {
      props.setDialog({
        isOpen: true,
        type: "success",
        content: `Pojazd został usunięty z bazy`,
      });
      refetchCars();
    },
    onError: (error) => {
      props.setDialog({
        isOpen: true,
        type: "error",
        content: `${error.response}`,
      });
    },
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const search = (tbl) => {
    if (tbl?.length > 0) {
      return tbl.filter((row) => {
        return (
          String(row.name).toLowerCase().indexOf(searchVehicle.toLowerCase()) >
            -1 &&
          String(row.vin).toLowerCase().indexOf(searchVIN.toLowerCase()) > -1 &&
          String(row.description)
            .toLowerCase()
            .indexOf(searchComment.toLowerCase()) > -1 &&
          (row.customer !== "No data"
            ? String(row.customer.name)
                .toLowerCase()
                .indexOf(searchCustomer.toLowerCase()) > -1
            : searchCustomer === "-") &&
          (row.firm !== "No data"
            ? String(row.firm.name)
                .toLowerCase()
                .indexOf(searchFirm.toLowerCase()) > -1
            : searchFirm === "-")
        );
      });
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = table.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const paginate = (pageNumber) => setPage(pageNumber - 1);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <>
      <div className={styles.rootFlex}>
        <div className={styles.tableBox}>
          <Paper className={styles.paper}>
            <Title
              title={props.state.tabName}
              btnBox
              btnAdd
              addFunc={() => {
                setOpenAddModalData({});
                setEdit(false);
                setOpenAddModal(true);
              }}
            />
            <TableContainer className={styles.stickyTable}>
              <Table
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
                className={styles.table}
              >
                <EnhancedTableHead
                  styles={styles}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={table.length}
                />

                <TableBody className={styles.tabBody}>
                  <TableRow>
                    <TableCell></TableCell>
                    {setSearchVehicle}
                    {setSearchVIN}
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    {setSearchCustomer}
                    {setSearchFirm}
                    {setSearchComment}
                    <TableCell></TableCell>
                  </TableRow>
                  {stableSort(search(table), getComparator(order, orderBy))
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <>
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            // selected={isItemSelected}
                          >
                            <TableCell>{index + 1}</TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row.name}
                            </TableCell>
                            <TableCell align="right">{row.vin}</TableCell>
                            <TableCell align="right">
                              {row.rok_produkcji}
                            </TableCell>
                            <TableCell align="right">
                              {row.norma_spalania}l/100km
                            </TableCell>
                            <TableCell align="right">{row.type}</TableCell>
                            <TableCell align="right">
                              {row.axle_netto} kg
                            </TableCell>
                            <TableCell align="right">
                              {row.term_oc?.slice(0, 10)}
                            </TableCell>
                            <TableCell align="right">
                              {row.term_ac?.slice(0, 10)}
                            </TableCell>
                            <TableCell align="right">
                              {row.inspection_valid_until?.slice(0, 10)}
                            </TableCell>
                            <TableCell align="right">
                              {row.extinguisher_valid_to?.slice(0, 10)}
                            </TableCell>
                            <TableCell align="right">
                              {row.customer !== "No data"
                                ? row.customer.name
                                : "-"}
                            </TableCell>
                            <TableCell align="right">
                              {row.firm !== "No data" ? row.firm.name : "-"}
                            </TableCell>
                            <TableCell align="right">
                              {row.description}
                            </TableCell>
                            <TableCell align="right">
                              <div className={styles.buttonBox}>
                                {props.user.adm !== null &&
                                  props.user.adm !== 0 && (
                                    <IcoBtn
                                      icon="Info"
                                      tooltip={
                                        row.car_profile !== null
                                          ? "Edytuj profil"
                                          : "Dodaj profil"
                                      }
                                      value={row}
                                      className={
                                        row.car_profile === null &&
                                        styles.mob_active_off
                                      }
                                      onClick={(e) => {
                                        setOpenAddModalProfile(true);
                                        setEditProfile(true);
                                        setOpenAddModalProfileData(row);
                                      }}
                                    />
                                  )}

                                <IcoBtn
                                  icon="Edit"
                                  tooltip="Edytuj pojazd"
                                  value={row}
                                  onClick={(e) => {
                                    setOpenAddModalData(row);
                                    setEdit(true);
                                    setOpenAddModal(true);
                                  }}
                                />

                                {props.user.adm !== null &&
                                  props.user.adm !== 0 && (
                                    <IcoBtn
                                      icon="X"
                                      tooltip="Usuń"
                                      value={row}
                                      className={`${
                                        !!row.id_device && styles.hidden
                                      } btn-delete`}
                                      onClick={(e) => {
                                        setDeleteData([row.id]);
                                        setOpenModalDelete(true);
                                      }}
                                    />
                                  )}
                              </div>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <div className={styles.tableBottom}>
              <RowsPerPage
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setPage={setPage}
                tableLength={table?.length}
              />
              <div>
                <Pagination
                  postsPerPage={rowsPerPage}
                  totalPosts={search(table)?.length}
                  paginate={paginate}
                  page={page}
                />
              </div>
            </div>
          </Paper>
        </div>

        {openAddModal && (
          <AddVehicles
            open={openAddModal}
            setOpen={setOpenAddModal}
            data={openAddModalData}
            user={props.user}
            edit={edit}
            refresh={refetchCars}
            car
            setDialog={props.setDialog}
          />
        )}

        {
          <AddProfile
            open={openAddModalProfile}
            setOpen={setOpenAddModalProfile}
            data={openAddModalProfileData}
            user={props.user}
            editProfile={editProfile}
            setEditProfile={setEditProfile}
            refresh={refetchCars}
            setDialog={props.setDialog}
          />
        }

        <Dialog
          open={openModalDelete}
          TransitionComponent={Transition}
          onClose={() => {
            setDeleteData([]);
            setOpenModalDelete(false);
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            className={stylesMod.dialogHead}
          >
            Czy napewno chcesz usunąć Pojazd?{" "}
            <IconButton
              aria-label="close"
              className={stylesMod.closeButton}
              onClick={() => {
                setDeleteData([]);
                setOpenModalDelete(false);
              }}
            >
              <X />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Czy napewno chcesz usunąć Pojazdy:{" "}
              {selected.map((e) => {
                const delOrder = table?.find((el) => el.id === e);
                return (
                  <>
                    <br />
                    {delOrder?.name}{" "}
                  </>
                );
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={BTNstyles.btn}
              onClick={(e) => {
                setOpenModalDelete(false);
              }}
            >
              Anuluj
            </Button>
            <Button
              variant="contained"
              size="large"
              className={`${BTNstyles.btn} ${BTNstyles.err}`}
              onClick={() => {
                deleteVehicles(deleteData);
                setSelected([]);
                setOpenModalDelete(false);
                setDeleteData([]);
              }}
              color="primary"
            >
              Usuń <X className={BTNstyles.downBtnIco} />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {isLoading && <TmsLoading status="Pobieranie pojazdów" />};
    </>
  );
}
