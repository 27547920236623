import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import stylesMod from "../../styles/newOrEdit.module.css";
import Input from "../../components/input";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import { DBurl, GPSurl, AppName } from "../../appConfig";
import BTNstyles from "../../styles/button.module.css";
import { X, LogIn, ArrowRight } from "react-feather";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import { Switch, FormControlLabel } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { useHistory } from "react-router-dom";

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#129865",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#129865",
  },
  "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
    backgroundColor: "#129865",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AtlasIntegrationModal(props) {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [atlas_endpoint, setAtlas_endpoint] = useState({
    value: `${GPSurl}/atlas/`,
    label: `${AppName}`,
  });

  const steps = ["Logowanie", "Wybór pojazdów", "Wybór kierowców"];
  const [activeStep, setActiveStep] = useState(0);
  const [vehicles, setVehicles] = useState([]);
  const [vehiclesAll, setVehiclesAll] = useState(false);
  const [checkedVehicles, setCheckedVehicles] = useState([]);

  const [drivers, setDrivers] = useState([]);
  const [driversAll, setDriversAll] = useState(false);
  const [checkedDrivers, setCheckedDrivers] = useState([]);
  const [atlas_auto_add, setAtlas_auto_add] = useState(
    props.data.atlas_auto_add || false
  );

  const atlas_endpointOptions = [
    { value: `${GPSurl}/atlas/`, label: `${AppName}` },
  ];
  let history = useHistory();

  const clear = () => {
    setPassword("");
    setLogin("");
    setError(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (props.open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
      setAtlas_auto_add(props.data.atlas_auto_add);
    }
  }, [props.data.atlas_auto_add, props.open]);

  const atlasLogin = async () => {
    let end_point_url = "atlasCheckConnectionByParams";
    let end_point_param;

    if (props.promotion) {
      end_point_url = "atlasCheckConnectionByParamsPROMO";
      end_point_param = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // mode: 'insert',
          atlas_endpoint: atlas_endpoint.value,
          atlas_username: login,
          atlas_password: password,
          id_customer: props.customerPromo,
        }),
      };
    } else {
      end_point_param = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
        body: JSON.stringify({
          // mode: 'insert',
          atlas_endpoint: atlas_endpoint.value,
          atlas_username: login,
          atlas_password: password,
        }),
      };
    }

    const response = await fetch(`${DBurl}/${end_point_url}`, end_point_param);
    const json = await response.json();
    if (json.success && json.data) {
      setErrorText("");
      setActiveStep(1);
      props.promotion ? atlasGetDevices(props.data.id) : setCustomer();
    } else {
      setErrorText("Błędny login lub hasła");
    }
  };

  const setCustomer = () => {
    const fetchUrl = `${DBurl}/setCustomers`;
    const data = {
      ...props.data,
      atlas_endpoint: atlas_endpoint.value,
      atlas_username: login,
      atlas_password: password,
      atlas_auto_add: atlas_auto_add,
    };
    fetch(fetchUrl, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "update",
        data: data,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          props.refresh();
          atlasGetDevices(props.data.id);
        }
      });
  };

  const atlasGetDevices = async (id) => {
    let end_point_url = "atlasGetDevices";
    let end_point_param;

    if (props.promotion) {
      end_point_url = "atlasGetDevicesPROMO";
      end_point_param = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
    } else {
      end_point_param = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
      };
    }

    const response = await fetch(
      `${DBurl}/${end_point_url}?id_customer=${
        props.promotion ? props.customerPromo : id
      }`,
      end_point_param
    );
    const json = await response.json();
    if (json.success) {
      setVehicles(json.data);
    } else {
      setErrorText("Błędny login lub hasła");
    }
  };

  const atlasSetDevices = async (id) => {
    let end_point_url = "atlasSetDevices";
    let end_point_param;

    if (props.promotion) {
      end_point_url = "atlasSetDevicesPROMO";
      end_point_param = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mode: "insert",
          data: {
            id_user: props.userPromo,
            id_customer: props.customerPromo,
            deviceIds: checkedVehicles,
          },
        }),
      };
    } else {
      end_point_param = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
        body: JSON.stringify({
          mode: "insert",
          data: {
            id_customer: id,
            deviceIds: checkedVehicles,
          },
        }),
      };
    }

    const response = await fetch(`${DBurl}/${end_point_url}`, end_point_param);
    const json = await response.json();
    if (json.success) {
      atlasGetDrivers(id);
      setActiveStep(2);
    } else {
      setErrorText("Błąd przy zapisywaniu urządzeń pojazdów");
    }
  };

  const atlasGetDrivers = async (id) => {
    let end_point_url = "atlasGetDrivers";
    let end_point_param;
    if (props.promotion) {
      end_point_url = "atlasGetDriversPROMO";
      end_point_param = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
    } else {
      end_point_param = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
      };
    }

    const response = await fetch(
      `${DBurl}/${end_point_url}?id_customer=${
        props.promotion ? props.customerPromo : id
      }`,
      end_point_param
    );
    const json = await response.json();
    if (json.success) {
      setDrivers(json.data?.filter((e) => e.isActiveDevice === true));
    } else {
      setErrorText("Błąd przy pobieraniu listy kierowców");
    }
  };

  const atlasSetDrivers = async (id) => {
    let end_point_url = "atlasSetDrivers";
    let end_point_param;

    if (props.promotion) {
      end_point_url = "atlasSetDriversPROMO";
      end_point_param = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mode: "insert",
          data: {
            id_customer: props.customerPromo,
            ids: checkedDrivers,
          },
        }),
      };
    } else {
      end_point_param = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
        body: JSON.stringify({
          mode: "insert",
          data: {
            id_customer: id,
            ids: checkedDrivers,
          },
        }),
      };
    }

    const response = await fetch(`${DBurl}/${end_point_url}`, end_point_param);
    const json = await response.json();
    if (json.success) {
      console.log("ok");
    } else {
      console.log(json);
    }
  };

  const handleToggle = (value, drivers) => () => {
    const currentIndex = drivers
      ? checkedDrivers.indexOf(value)
      : checkedVehicles.indexOf(value);
    const newChecked = drivers ? [...checkedDrivers] : [...checkedVehicles];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    drivers ? setCheckedDrivers(newChecked) : setCheckedVehicles(newChecked);
  };

  const handleToggleAll = () => {
    vehiclesAll
      ? setCheckedVehicles([])
      : setCheckedVehicles(vehicles?.map((e) => e.deviceId));
    setVehiclesAll(!vehiclesAll);
  };

  const handleToggleAllDrivers = () => {
    driversAll
      ? setCheckedDrivers([])
      : setCheckedDrivers(drivers?.map((e) => e.id));
    setDriversAll(!driversAll);
  };

  const GoToLogin = () => {
    props.setPromotion(false);
    history.push("/");
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      open={props.open}
      scroll="paper"
      TransitionComponent={Transition}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title" className={stylesMod.dialogHead}>
        Integracja
        {activeStep === 0 && props.promotion ? null : (
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              props.setOpen(false);
              props.promotion && GoToLogin();
            }}
          >
            <X />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent className={stylesMod.noOverflow} dividers={true}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          {activeStep === 0 && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <p className={stylesMod.center}>
                    Wpisz dane do logowania do systemu {AppName} GPS
                  </p>
                </Grid>
              </Grid>
              <Grid container spacing={2} className={stylesMod.centerWithM}>
                <Grid item xs={3}>
                  <div className={`${stylesMod.inp}`}>
                    <label>Login</label>
                    <Input
                      value={login}
                      error={!login && error}
                      handleChange={(e) => {
                        setLogin(e.target.value);
                      }}
                      name="login"
                      type="text"
                    />
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className={`${stylesMod.inp}`}>
                    <label>Hasło</label>
                    <Input
                      value={password}
                      error={!password && error}
                      handleChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      name="password"
                      type="password"
                    />
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className={`${stylesMod.inp}`}>
                    <label>Dostawca</label>
                    <Select
                      value={atlas_endpoint}
                      className={stylesMod.select}
                      onChange={(e) => {
                        setAtlas_endpoint(e);
                      }}
                      name="atlas_endpoint"
                      options={atlas_endpointOptions}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          border: "1px solid #142f42",
                          boxShadow: "none",
                          "&:hover": {
                            border: "1px solid #142f42",
                          },
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused && "#142f42",
                          color: state.isFocused && "#fff",
                        }),
                      }}
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={2} className={stylesMod.centerWithM}>
                <Grid item xs={9}>
                  <div className={`${stylesMod.inp}`}>
                    <label>Atlas - automatyczna synchronizacja</label>
                    <FormControlLabel
                      className={stylesMod.smallerFont}
                      control={
                        <GreenSwitch
                          checked={atlas_auto_add}
                          onChange={(e) => setAtlas_auto_add(e.target.checked)}
                        />
                      }
                      label={
                        atlas_auto_add === false ? "wyłączony" : "włączony"
                      }
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={2} className={stylesMod.centerWithM}>
                <Grid item xs={5}>
                  <p className={`${stylesMod.error} ${stylesMod.center}`}>
                    {errorText}
                  </p>
                </Grid>
              </Grid>
            </>
          )}
          {activeStep === 1 && (
            <>
              <Grid container spacing={2} className={stylesMod.centerWithM}>
                <Grid item xs={8}>
                  <List
                    dense
                    subheader={
                      <>
                        <ListSubheader onClick={(e) => handleToggleAll()}>
                          Zaznacz wszystko
                          <ListItemSecondaryAction>
                            <Checkbox
                              edge="end"
                              onChange={() => handleToggleAll()}
                              checked={vehiclesAll}
                            />
                          </ListItemSecondaryAction>
                        </ListSubheader>
                        <Divider />
                      </>
                    }
                  >
                    {typeof vehicles === "object" &&
                      vehicles?.map((el) => {
                        const labelId = `checkbox-list-secondary-label-${el.deviceId}`;
                        return (
                          <>
                            <ListItem key={el.deviceId} button>
                              <ListItemText
                                id={labelId}
                                primary={`Pojazd ${el.deviceName}`}
                              />
                              <ListItemSecondaryAction>
                                <Checkbox
                                  edge="end"
                                  onChange={handleToggle(el.deviceId)}
                                  checked={
                                    checkedVehicles.indexOf(el.deviceId) !== -1
                                  }
                                  inputProps={{ "aria-labelledby": labelId }}
                                />
                              </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                          </>
                        );
                      })}
                  </List>
                </Grid>
              </Grid>
            </>
          )}
          {activeStep === 2 && (
            <>
              <Grid container spacing={2} className={stylesMod.centerWithM}>
                <Grid item xs={8}>
                  <List
                    dense
                    subheader={
                      <>
                        <ListSubheader
                          onClick={(e) => handleToggleAllDrivers()}
                        >
                          Zaznacz wszystko
                          <ListItemSecondaryAction>
                            <Checkbox
                              edge="end"
                              onChange={() => handleToggleAllDrivers()}
                              checked={vehiclesAll}
                            />
                          </ListItemSecondaryAction>
                        </ListSubheader>
                        <Divider />
                      </>
                    }
                  >
                    {typeof drivers === "object" &&
                      drivers?.map((el) => {
                        const labelId = `checkbox-list-secondary-label-${el.id}`;
                        return (
                          <>
                            <ListItem key={el.id} button>
                              <ListItemText
                                id={labelId}
                                primary={`Kierowca ${
                                  el.firstname !== null ? el.firstname : ""
                                } ${el.lastname !== null ? el.lastname : ""} ${
                                  el.tachoid ? "[" + el.tachoid + "]" : ""
                                }`}
                              />
                              <ListItemSecondaryAction>
                                <Checkbox
                                  edge="end"
                                  onChange={handleToggle(el.id, true)}
                                  checked={checkedDrivers.indexOf(el.id) !== -1}
                                  inputProps={{ "aria-labelledby": labelId }}
                                />
                              </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                          </>
                        );
                      })}
                  </List>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {activeStep === 0 && props.promotion ? null : (
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={`${BTNstyles.btn} ${BTNstyles.err}`}
            onClick={() => {
              props.promotion && props.PromoUnlockAndLogin();
              clear();
              props.setOpen(false);
            }}
          >
            Anuluj
          </Button>
        )}

        {activeStep === 0 && (
          <>
            <Button
              variant="contained"
              size="large"
              className={`${BTNstyles.btn} ${BTNstyles.grn}`}
              onClick={(e) => {
                if (!!password) {
                  atlasLogin();
                } else {
                  setError(true);
                  props.setDialog({
                    isOpen: true,
                    type: "error",
                    content: "Wpisz nowe hasło",
                  });
                }
              }}
              color="primary"
            >
              Zaloguj <LogIn className={BTNstyles.downBtnIco} />
            </Button>
          </>
        )}
        {activeStep === 1 && (
          <Button
            variant="contained"
            size="large"
            className={`${BTNstyles.btn} ${BTNstyles.grn}`}
            onClick={(e) => {
              if (!!password) {
                atlasSetDevices(props.data.id);
              } else {
                setError(true);
                props.setDialog({
                  isOpen: true,
                  type: "error",
                  content: "Błąd przy zapisywaniu pojazdów",
                });
              }
            }}
            color="primary"
          >
            Zapisz <ArrowRight className={BTNstyles.downBtnIco} />
          </Button>
        )}
        {activeStep === 2 && (
          <Button
            variant="contained"
            size="large"
            className={`${BTNstyles.btn} ${BTNstyles.grn}`}
            onClick={(e) => {
              if (!!password) {
                atlasSetDrivers(props.data.id);
                props.setDialog({
                  isOpen: true,
                  type: "success",
                  content: "Integracja zakończona pomyślnie",
                });
                props.setOpen(false);
                props.promotion && props.PromoUnlockAndLogin();
              } else {
                setError(true);
                props.setDialog({
                  isOpen: true,
                  type: "error",
                  content: "Błąd przy zapisywaniu kierowców",
                });
              }
            }}
            color="primary"
          >
            Zapisz <ArrowRight className={BTNstyles.downBtnIco} />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
