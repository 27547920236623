import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Input from "../../components/input";
import Title from "../../components/title";
import styles from "../table.module.css";
import RowsPerPage from "../../components/RowsPerPage";
import Pagination from "../../components/pagination";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Snackbar,
  Checkbox,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { DBurl } from "../../appConfig";
import moment from "moment";
import { useQuery } from "react-query";
import { getExploitation } from "api/endpoints";
import TmsLoading from "components/TmsLoading";

function descendingComparator(a, b, orderBy) {
  if (orderBy === "refueling length") {
    return ((!!b.refuelings && !b.refuelings[0].brak && b.refuelings?.length) ||
      0) <
      ((!!a.refuelings && !a.refuelings[0].brak && a.refuelings?.length) || 0)
      ? -1
      : ((!!b.refuelings && !b.refuelings[0].brak && b.refuelings?.length) ||
          0) >
        ((!!a.refuelings && !a.refuelings[0].brak && a.refuelings?.length) || 0)
      ? 1
      : 0;
  }
  if (orderBy === "refueling add") {
    return (!!b.refuelings &&
      !b.refuelings[0].brak &&
      countFuel(b.refuelings)) ||
      0 <
        (!!a.refuelings && !a.refuelings[0].brak && countFuel(a.refuelings)) ||
      0
      ? -1
      : (!!b.refuelings && !b.refuelings[0].brak && countFuel(b.refuelings)) ||
        0 >
          (!!a.refuelings &&
            !a.refuelings[0].brak &&
            countFuel(a.refuelings)) ||
        0
      ? 1
      : (!!b.refuelings && !b.refuelings[0].brak && countFuel(b.refuelings)) ||
        0 ===
          (!!a.refuelings && !a.refuelings[0].brak && countFuel(a.refuelings)
            ? 0
            : -1);
  }

  let sortB;
  let sortA;
  if (!!b && typeof b[orderBy] === "string") {
    sortB = b[orderBy].toLowerCase();
  } else {
    sortB = b;
  }
  if (!!a && typeof a[orderBy] === "string") {
    sortA = a[orderBy].toLowerCase();
  } else {
    sortA = a;
  }
  if (sortB < sortA) {
    return -1;
  }
  if (sortB > sortA) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (array?.length > 0) {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
}

const countFuel = (rows) => {
  let result = 0;
  rows && rows.forEach((row) => (result += row.fuel));
  return result;
};

const headCells = [
  { id: "name", alignRight: false, disablePadding: false, label: "Pojazd" },
  {
    id: "distance_start",
    alignRight: false,
    disablePadding: false,
    label: "Stan licznika początek",
  },
  {
    id: "distance_end",
    alignRight: false,
    disablePadding: false,
    label: "Stan licznika koniec",
  },
  {
    id: "distance_can",
    alignRight: false,
    disablePadding: false,
    label: "Dystans z CAN",
  },
  {
    id: "distance_gps",
    alignRight: false,
    disablePadding: false,
    label: "Dystans z GPS",
  },
  {
    id: "fuel_avg",
    alignRight: false,
    disablePadding: false,
    label: "Śr. spalanie L/100KM",
  },
  {
    id: "refueling length",
    alignRight: false,
    disablePadding: false,
    label: "Liczba tankowań",
  },
  {
    id: "refueling add",
    alignRight: false,
    disablePadding: false,
    label: "Paliwo zatankowane",
  },
  {
    id: "fuel_used",
    alignRight: false,
    disablePadding: false,
    label: "Paliwo zużyte",
  },
  {
    id: "speed_max",
    alignRight: false,
    disablePadding: false,
    label: "Prędkość maksymalna (km/h)",
  },
  {
    id: "speed_avg",
    alignRight: false,
    disablePadding: false,
    label: "Prędkość średnia (km/h)",
  },
  {
    id: "fuelloss length",
    alignRight: false,
    disablePadding: false,
    label: "Liczba upustów",
  },
  {
    id: "fuelloss add",
    alignRight: false,
    disablePadding: false,
    label: "ubytek paliwa (L)",
  },
  { id: "webasto", alignRight: false, disablePadding: false, label: "WEBASTO" },
];

function EnhancedTableHead(props) {
  const {
    styles,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead className={styles.tabHead}>
        <TableRow>
          <TableCell className={styles.checkbox}>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all id" }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.alignRight ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "default"}
              sortDirection={orderBy === headCell.id ? order : false}
              className={styles.headCellSmall}
              style={headCell.id === "name" ? { width: "13%" } : null}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={styles.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  styles: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function useInput({ type, init }, placeholder) {
  const [value, setValue] = useState(init || "");
  const input = (
    <Input
      value={value}
      handleChange={(e) => {
        setValue(e.target.value);
      }}
      type={type}
      placeholder={placeholder}
      className={styles.searchInputSmall}
      search={type === "text" ? true : false}
    />
  );
  return [value, input];
}

export default function Exploitation(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [table, setTable] = useState([]);
  const [searchVehicle, setSearchVehicle] = useInput(
    { type: "text" },
    "Szukaj pojazdu"
  );
  const [dateFrom, setDateFrom] = useInput(
    {
      type: "datetime-local",
      init: moment().startOf("day").format("YYYY-MM-DDTHH:mm"),
    },
    "Do"
  );
  const [dateTo, setDateTo] = useInput(
    {
      type: "datetime-local",
      init: moment().format("YYYY-MM-DDTHH:mm"),
    },
    "Do"
  );

  const { isLoading } = useQuery(
    ["getExploitation", dateFrom, dateTo],
    () =>
      getExploitation({
        dateFrom: dateFrom?.replace("T", " ") + ":00",
        dateTo: dateTo?.replace("T", " ") + ":00",
      }),
    {
      enabled: !!dateFrom && !!dateTo,
      onSuccess: (data) => {
        setTable(data);
      },
      onError: (error) => {
        props.setDialog({
          isOpen: true,
          type: "error",
          content: error.response,
        });
      },
    }
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      if (table?.length > 0) {
        const newSelecteds = table.map((n) => n.id);
        setSelected(newSelecteds);
        return;
      } else {
        props.setDialog({
          isOpen: true,
          type: "error",
          content: "Brak danych do zaznaczenia",
        });
      }
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const search = (tbl) => {
    if (tbl?.length > 0) {
      return tbl.filter((row) => {
        return (
          row?.name
            .toString()
            .toLowerCase()
            .indexOf(searchVehicle.toLowerCase()) > -1
        );
      });
    }
  };

  const paginate = (pageNumber) => setPage(pageNumber - 1);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, table.length - page * rowsPerPage);
  const exportToXLS = async () => {
    const today = await new Date();
    const start =
      dateFrom.toString().replace("T", " ") ||
      `${today.getFullYear()}-${today.getMonth() + 1 < 10 ? "0" : ""}${
        today.getMonth() + 1
      }-${today.getDate() < 10 ? "0" : ""}${today.getDate()} 00:00:00`;
    const end =
      dateTo.replace("T", " ") ||
      `${today.getFullYear()}-${today.getMonth() + 1 < 10 ? "0" : ""}${
        today.getMonth() + 1
      }-${today.getDate() < 10 ? "0" : ""}${today.getDate()} 23:59:59`;
    const filename = `exploation_from_${start.replace(
      " ",
      "_"
    )}_to_${end.replace(" ", "_")}.xls`;
    const url = `${DBurl}/printExploitationXLS?dateFrom=${start}&dateTo=${end}&filename=${filename}`;
    window.open(url, "_blank");
  };

  return (
    <>
      <div className={styles.root}>
        <Paper className={styles.paper}>
          <Title
            title={props.state.tabName}
            btnBox
            btnExport
            exportFunc={() => {
              exportToXLS();
            }}
            search={setSearchVehicle}
            rightCorner1={setDateFrom}
            rightCorner2={setDateTo}
          />
          <TableContainer className={styles.stickyTable}>
            <Table
              stickyHeader
              className={styles.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                styles={styles}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={table.length}
              />
              <TableBody className={styles.tabBody}>
                {stableSort(search(table), getComparator(order, orderBy))
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell className={styles.checkbox}>
                          <Checkbox
                            checked={isItemSelected}
                            color="primary"
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>

                        <TableCell
                          className={styles.cellWtDivider}
                          align="left"
                        >
                          {row.name}
                        </TableCell>
                        <TableCell
                          className={styles.cellWtDivider}
                          align="left"
                        >
                          {row.distance_start}
                        </TableCell>
                        <TableCell
                          className={styles.cellWtDivider}
                          align="left"
                        >
                          {row.distance_end}
                        </TableCell>
                        <TableCell
                          className={styles.cellWtDivider}
                          align="left"
                        >
                          {row.distance_can}
                        </TableCell>
                        <TableCell
                          className={styles.cellWtDivider}
                          align="left"
                        >
                          {row.distance_gps}
                        </TableCell>
                        <TableCell
                          className={styles.cellWtDivider}
                          align="left"
                        >
                          {row.fuel_avg}
                        </TableCell>
                        <TableCell
                          className={styles.cellWtDivider}
                          align="left"
                        >
                          {row.refuelings && !row.refuelings[0].brak
                            ? row.refuelings?.length
                            : "0"}
                        </TableCell>
                        <TableCell
                          className={styles.cellWtDivider}
                          align="left"
                        >
                          {row.refuelings && !row.refuelings[0].brak
                            ? countFuel(row.refuelings)
                            : "-"}
                        </TableCell>
                        <TableCell
                          className={styles.cellWtDivider}
                          align="left"
                        >
                          {row.fuel_used}
                        </TableCell>
                        <TableCell
                          className={styles.cellWtDivider}
                          align="left"
                        >
                          {row.speed_max}
                        </TableCell>
                        <TableCell
                          className={styles.cellWtDivider}
                          align="left"
                        >
                          {row.speed_avg}
                        </TableCell>
                        <TableCell
                          className={styles.cellWtDivider}
                          align="left"
                        >
                          {row.fuelloss && !row.fuelloss[0].brak
                            ? row.fuelloss.length
                            : "0"}
                        </TableCell>
                        <TableCell
                          className={styles.cellWtDivider}
                          align="left"
                        >
                          {row.fuelloss && !row.fuelloss[0].brak
                            ? countFuel(row.fuelloss)
                            : "-"}
                        </TableCell>
                        <TableCell
                          className={styles.cellWtDivider}
                          align="left"
                        >
                          {row.webasto}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 33 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={styles.tableBottom}>
            <RowsPerPage
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              setPage={setPage}
              tableLength={table?.length}
            />
            <div>
              <Pagination
                postsPerPage={rowsPerPage}
                totalPosts={search(table)?.length}
                paginate={paginate}
                page={page}
              />
            </div>
          </div>
        </Paper>
      </div>
      {isLoading && <TmsLoading status="Pobieranie danych" />}
    </>
  );
}
