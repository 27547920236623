import React, { useState, useEffect } from 'react';

import Input from '../../components/input'
import { EditBtn, FileBtn, Edit2Btn } from '../../components/buttons';
import Title from '../../components/title'
import BTNstyles from '../../styles/button.module.css'
import styles from '../table.module.css'
import stylesMod from '../../styles/newOrEdit.module.css'
import { X } from 'react-feather';
import RowsPerPage from '../../components/RowsPerPage'
import Pagination from '../../components/pagination'
import { DBurl } from '../../appConfig'

import { Button, Table, TableBody, TableCell, TableContainer, IconButton, TableHead, TableRow, TableSortLabel, Paper, Checkbox, Slide, Snackbar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';

import StatusInvoice from '../../components/StatusInvoice';
import InvoiceAdd_S from './InvoiceAdd_S'
import IcoBtn from '../../components/buttons/icoBtn';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{ id: 'invoiceNr', numeric: false, disablePadding: true, label: 'Nr faktury' },
	{ id: 'type', numeric: false, disablePadding: false, label: 'Typ', class: styles.statusShort },
	{ id: 'order', numeric: true, disablePadding: false, label: 'Zlecenie' },
	{ id: 'company', numeric: true, disablePadding: false, label: 'Klient' },
	{ id: 'saleDate', numeric: true, disablePadding: false, label: 'Data Sprzedaży' },
	{ id: 'paidDate', numeric: true, disablePadding: false, label: 'Data wystawienia' },
	{ id: 'netto', numeric: true, disablePadding: false, label: 'Wartość netto' },
	{ id: 'value_brutto', numeric: true, disablePadding: false, label: 'Wartość brutto' },
	{ id: 'validDate', numeric: true, disablePadding: false, label: 'Termin płatności' },
	{ id: 'madeBy', numeric: true, disablePadding: false, label: 'Wystawiona przez' },
	{ id: 'status', numeric: true, disablePadding: false, label: 'Status' }
];

function EnhancedTableHead(props) {
	const { styles, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead className={styles.tabHead}>
			<TableRow>
				<TableCell className={styles.checkbox}>
					<Checkbox
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{ 'aria-label': 'select all id' }}
					/>
				</TableCell>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? 'right' : 'left'}
						padding={headCell.disablePadding ? 'none' : 'default'}
						sortDirection={orderBy === headCell.id ? order : false}
						className={headCell.class}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={styles.visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell className={styles.buttonBoxParentLg} />
			</TableRow>
		</TableHead>
	);
}

function useInput({ type }) {
	const [value, setValue] = useState("")
	const input = <TableCell ><Input value={value} handleChange={e => {
		setValue(e.target.value)
	}
	} type={type}
		search={true} /></TableCell>;
	return [value, input];
}

export default function EnhancedTable(props) {
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('car');
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(15);
	const [table, setTable] = useState([{
		invoice_no: "",
		orders: [],
		contractor: {},
		data_services: ""
	}])
	const [searchInvoiceNr, setSearchInvoiceNr] = useInput({ type: "text" });
	const [searchOrder, setSearchOrder] = useInput({ type: "text" });
	const [searchType, setSearchType] = useInput({ type: 'text' })
	const [searchClient, setSearchClient] = useInput({ type: "text" });
	const [searchMadeBy, setSearchMadeBy] = useInput({ type: "text" });

	const [openModalDelete, setOpenModalDelete] = React.useState(false);
	const [modalContent, setModalContent] = React.useState([]);

	const [openDialog, setOpenDialog] = useState(false)
	const [dialogContent, setDialogContent] = useState("")
	const [dialogColor, setDialogColor] = useState("error")

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenDialog(false)
	}

	const fetchInvoices = async (token) => {
		props.loading(true)
		const response = await fetch(`${DBurl}/getInvoicesS`, {
			method: "GET",
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": token
			}
		});
		const json = await response.json()
		if (json.success) {
			setTable(json.data)
			props.loading(false)
		}

	}

	const delInvoices = async function (arrayOfIds) {
		props.loading(true)
		const response = await fetch(`${DBurl}/setInvoices/`, {
			method: "post",
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": props.user.csrf_token
			},
			body: JSON.stringify({
				mode: 'delete',
				data: {
					ids: arrayOfIds
				}
			})
		})
		const json = await response.json()

		if (json.success) {
			setOpenDialog(true)
			setDialogContent(`Dane zostały usunięte `)
			setDialogColor("success")
			fetchInvoices(props.user.csrf_token)
			props.loading(false)
			return json
		} else {
			setOpenDialog(true)
			setDialogContent(`${json.responseDescription} // ${json.response}  `)
			setDialogColor("error")
			props.loading(false)
		}
	}

	useEffect(() => {
		fetchInvoices(props.user.csrf_token)
	}, [])

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const getType = (type) => {
		switch (type) {
			case 1: return "Faktura VAT S"
			case 2: return "Proforma S"
			case 3: return "Korekta S"
			case 4: return "Szablon S"
			default: return ""
		}
	}

	const search = (tbl) => {
		return tbl.filter((row) => {
			return row.invoice_no?.toString().toLowerCase().indexOf(searchInvoiceNr.toLowerCase()) > -1 &&
				getType(row.type).toLowerCase().indexOf(searchType.toLowerCase()) > -1 &&
				row.contractor?.shortname?.toString().toLowerCase().indexOf(searchClient.toLowerCase()) > -1 &&

				row.signature?.toString().toLowerCase().indexOf(searchMadeBy.toLowerCase()) > -1
		})
	}

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = table.map((n) => n.id);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		setSelected(newSelected);
	};
	const paginate = pageNumber => setPage(pageNumber - 1);
	const isSelected = (name) => selected.indexOf(name) !== -1;
	const getNetto = (arr, corArr) => {
		if (!!corArr) {
			const tempArr = [];
			arr.forEach((el,i) => {
				const correctionElement = corArr.find(el2 => el2.id === el.id)
				!!correctionElement ? 
				tempArr.push(correctionElement) :
				tempArr.push(el)
			});
			return tempArr.reduce((a, b) => Number(a) + (Number(b['value_brutto']) || 0), 0)
		} else {
			var result = 0
			arr.forEach(e => result += (Number(e.value_netto)))
			return result;
		}
	}

	const getBrutto = (arr) => {
		var result = 0
		arr.forEach(e => result += (Number(e.value_brutto)))
		return result;
	}

	const printPDF = (id) => {
		const url = `${DBurl}/printInvoicePDF?token=${props.user.csrf_token}&id_invoice=${id}`;
		window.open(url, '_blank');
	}

	const editInvoice = (id, type) => {
		const tempData = {
			permission_id: 14,
			tabName: getType(type) || 'Edytuj fakturę S',
			tabID: null,
			component: InvoiceAdd_S,
			active: true,
			fetchInvoiceId: id,
			typeInput: { label: getType(type), value: type }
		}
		props.addTab('InvoiceAdd_S', tempData)

	}

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, table.length - page * rowsPerPage);

	return (
		<>
			<div className={styles.root}>
				<Paper className={styles.paper}>
					<Title title={props.state.tabName}
						btnBox
						btnAdd
						addFunc={e => {
							props.addTab('InvoiceAdd_S')
						}}
						btnDelete={selected.length > 0 ? true : "disabled"}
						deleteFunc={e => {
							setModalContent(selected)

							///to do dokończyć delete


							setOpenModalDelete(true)
						}}
					/>
					<TableContainer className={styles.stickyTable}>
						<Table
							className={styles.table}
							aria-labelledby="tableTitle"
							size="small"
							aria-label="enhanced table"
						>

							<EnhancedTableHead
								styles={styles}
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onSelectAllClick={handleSelectAllClick}
								onRequestSort={handleRequestSort}
								rowCount={table.length}
							/>
							<TableBody className={styles.tabBody}>
								<TableRow>
									<TableCell></TableCell>
									{setSearchInvoiceNr}
									{setSearchType}
									{setSearchOrder}
									{setSearchClient}
									<TableCell></TableCell>
									<TableCell></TableCell>
									<TableCell></TableCell>
									<TableCell></TableCell>
									<TableCell></TableCell>
									{setSearchMadeBy}
									<TableCell></TableCell>
									<TableCell></TableCell>
								</TableRow>
								{stableSort(search(table), getComparator(order, orderBy))
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => {
										const isItemSelected = isSelected(row.id);
										const labelId = `enhanced-table-checkbox-${index}`;

										return (
											<TableRow
												hover
												onClick={(event) => handleClick(event, row.id)}
												role="checkbox"
												aria-checked={isItemSelected}
												tabIndex={-1}
												key={row.id}
												selected={isItemSelected}
											>
												<TableCell padding="checkbox">
													<Checkbox
														checked={isItemSelected}
														color="primary"
														inputProps={{ 'aria-labelledby': labelId }}
													/>
												</TableCell>
												<TableCell align="left">{row.correction_no ||row.invoice_no}</TableCell>
												<TableCell align="left"><div style={{minWidth: 100}}>{getType(row.type)}</div></TableCell>
												<TableCell align="right">{row.orders?.[0]?.order_no}</TableCell>
												<TableCell align="right">{row.contractor?.shortname}</TableCell>
												<TableCell align="right">{row.date_service?.substring(11, 0)}</TableCell>
												<TableCell align="right">{row.date_of_correction?.substring(11, 0) || row.date_of_issue?.substring(11, 0)}</TableCell>
												<TableCell align="right">{getNetto(row.services, row.correction_services)} {row.currency}</TableCell>
												<TableCell align="right">{!!row.value_brutto ? row.value_brutto : getBrutto(row.services)} {row.currency}</TableCell>
												<TableCell align="right">{row.payment_deadline?.substring(11, 0)}</TableCell>
												<TableCell align="right">{row.signature}</TableCell>
												<TableCell align="left"><StatusInvoice
													type = {row.type}
													deadline={row.payment_deadline}
													paid={Number(row.payment) + Number(row.advance)}
													value={row.value_brutto || getBrutto(row.services)}
												/></TableCell>
												<TableCell align="right">
													<div className={styles.buttonBox}>
														{row.type !== 3&& <>
															{  row.type!==4 &&<Edit2Btn tooltip="Wystaw Korekte" handleChange={e => {
																editInvoice(row.id, 3)
															}} />}
															<EditBtn tooltip="Edytuj" handleChange={e => {
																editInvoice(row.id, row.type)
															}} />
														</>}
														<FileBtn tooltip="PDF" handleChange={e => {
															printPDF(row.id)
														}} />
														<IcoBtn icon="X" tooltip="Usuń" className="btn-delete" value={row} onClick={(e) => {
															setModalContent([row])
															setOpenModalDelete(true)
														}} />

													</div>
												</TableCell>

											</TableRow>
										);
									})}
								{emptyRows > 0 && (
									<TableRow style={{ height: 33 * emptyRows }}>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<div className={styles.tableBottom} >
						<RowsPerPage
							rowsPerPage={rowsPerPage}
							setRowsPerPage={setRowsPerPage}
							setPage={setPage}
							tableLength={table.length}
						/>

						<div>
							<Pagination
								postsPerPage={rowsPerPage}
								totalPosts={search(table).length}
								paginate={paginate}
								page={page}
							/>
						</div>
					</div>

				</Paper>

				<Dialog
					open={openModalDelete}
					TransitionComponent={Transition}
					onClose={() => {
						setOpenModalDelete(false)
						setModalContent([])
					}}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle id="alert-dialog-slide-title" className={stylesMod.dialogHead}>Czy napewno chcesz usunąć {modalContent ? modalContent.name : null}			<IconButton aria-label="close" className={stylesMod.closeButton} onClick={() => {
						setOpenModalDelete(false)
						setModalContent([])
					}}>
						<X />
					</IconButton>
					</DialogTitle >
					<DialogContent>
						<DialogContentText id="alert-dialog-slide-description">
							Czy napewno chcesz usunąć Faktury
							{modalContent.map(e => {
								return <>
									<br /> {e.invoice_no}
								</>
							})
							}

						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button variant="contained" size="large" color="primary" className={BTNstyles.btn} onClick={(e) => {
							setOpenModalDelete(false)
							setModalContent([])
						}}>
							Anuluj
						</Button>
						<Button variant="contained" size="large" className={`${BTNstyles.btn} ${BTNstyles.err}`} onClick={(e) => {
							// setTable(table.filter((e) => { if (!selected.includes(e.id)) { return e } else { return null } }))
							delInvoices(modalContent.map(e => e.id))
							setModalContent([])
							setOpenModalDelete(false)
						}}
							color="primary">
							Usuń <X className={BTNstyles.downBtnIco} />
						</Button>
					</DialogActions>
				</Dialog>

			</div>
			<Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
				<Alert onClose={handleClose} severity={dialogColor}>
					{dialogContent}
				</Alert>
			</Snackbar>
		</>
	);
}
