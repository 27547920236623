
import React, {useEffect} from 'react'
import styles from '../../styles/newOrEdit.module.css'
import styles2 from '../table.module.css'
import IcoBtn from '../../components/buttons/icoBtn';

import Grid from '@material-ui/core/Grid';
import Input from '../../components/input'
import Select from 'react-select'

export default function invoiceProducts(props) {

	//// ustawić use Effect na select order
	// useEffect(()=>{
	// 	if (props.order.value) {
	// 		props.updateService(props.propKey, true)
	// 	}
	// },[props.order.value])

	return (
		<>
			<Grid item xs={1} className={styles.invoiceID}>
				{props.data.id}
			</Grid>
			<Grid item xs={2}>
				<div className={`${styles.inp} ${styles.invoice}`}>
					<Select
						value={props.data.name}
						className={styles2.selectClass}
						options={props.servicesOptions}
						onChange={props.updateService(props.propKey, true)}
						name="name"
						placeholder="Nazwa usługi"
						search 
						styles={{
							control: (base, state) => ({
								...base,
								border: '1px solid #142f42',
								boxShadow: 'none',
								'&:hover': {
									border: '1px solid #142f42',
								}
							}),
							option: (provided, state) => ({
								...provided,
								backgroundColor: state.isFocused && "#142f42",
								color: state.isFocused && "#fff",
							 })

						  }}/>
				</div>
				{/* <Input className={styles.invoiceMd} value={props.data.name} handleChange={props.updateService(props.propKey)} name="name" /> */}
			</Grid>
			<Grid item xs={2}>
				<div className={`${styles.inp} ${styles.invoice}`}>
					<Select
						value={props.data.order}
						className={styles2.selectClass}
						options={props.ordersOptions}
						onChange={props.updateService(props.propKey, true)}
						name="order"
						placeholder="-"
						search 
						styles={{
							control: (base, state) => ({
								...base,
								border: '1px solid #142f42',
								boxShadow: 'none',
								'&:hover': {
									border: '1px solid #142f42',
								}
							}),
							option: (provided, state) => ({
								...provided,
								backgroundColor: state.isFocused && "#142f42",
								color: state.isFocused && "#fff",
							 })

						  }}/>
				</div>
			</Grid>
			<Grid item xs={1}>
				<Input type="number" className={styles.invoiceSm} value={props.data.price_netto} handleChange={props.updateService(props.propKey)} name="price_netto" />
			</Grid>
			<Grid item xs={1}>
				<Input type="number" className={styles.invoiceSm} value={props.data.quantity} handleChange={props.updateService(props.propKey)} name="quantity" />
			</Grid>
			<Grid item xs={1}>
				<div className={`${styles.inp} ${styles.invoice}`}>
					<Select
						value={props.data.vat}
						className={styles2.selectClass}
						options={props.vatOptions}
						onChange={props.updateService(props.propKey, true)
						}
						name="vat"
						placeholder="Stawka VAT"
						search 
						styles={{
							control: (base, state) => ({
								...base,
								border: '1px solid #142f42',
								boxShadow: 'none',
								'&:hover': {
									border: '1px solid #142f42',
								}
							}),
							option: (provided, state) => ({
								...provided,
								backgroundColor: state.isFocused && "#142f42",
								color: state.isFocused && "#fff",
							 })

						  }}/>
				</div>
			</Grid>
			<Grid item xs={1}>
				<Input type="number" className={styles.invoiceSm} value={props.data.value_netto} handleChange={props.updateService(props.propKey)} name="value_netto" disabled />
			</Grid>
			<Grid item xs={2}>
				<Input type="number" className={styles.invoiceSm} value={props.data.value_brutto} handleChange={props.updateService(props.propKey)} name="value_brutto" disabled />
			</Grid>
			<Grid item xs={1} className={styles.buttonCont}>

				<IcoBtn icon="Copy" tooltip="Kopiuj" className={`${styles.mr5} ${styles.secondClass}`} onClick={() => {
					props.copyCargo(props.propKey)
				}} />
				<IcoBtn icon="X" tooltip="Usuń" className="btn-delete" onClick={() => {
					props.remove(props.propKey)
				}} />

			</Grid>
		</>
	)
}
