import React, { useState } from "react";
import Input from "../input";

export const useInput = (type, placeholder, initialState, className) => {
  const [value, setValue] = useState(initialState);
  const input = (
    <Input
      value={value}
      handleChange={(e) => {
        setValue(e.target.value);
      }}
      type={type}
      placeholder={placeholder}
      className={className}
      search={type === "text" ? true : false}
    />
  );
  return [value, input];
};
