import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import stylesMod from "../../styles/newOrEdit.module.css";
import styles from "../table.module.css";
import Button from "@material-ui/core/Button";
import { X } from "react-feather";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { TableHead } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FleetStateOrderStatuses(props) {
    return (
        <Dialog
            maxWidth="md"
            open={props.open}
            TransitionComponent={Transition}
            onClose={() => {
                props.setOpen(false);
            }}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle
                id="alert-dialog-slide-title"
                className={stylesMod.dialogHead}
            >
                Historia statusów
                <IconButton
                    aria-label="close"
                    className={stylesMod.closeButton}
                    onClick={() => {
                        props.setOpen(false);
                    }}
                >
                    <X />
                </IconButton>
            </DialogTitle>
            <DialogContent id="alert-dialog-slide-description">
                <Grid
                    container
                    spacing={2}
                    className={`${stylesMod.orderStatus} status-information-grid`}
                >
                    <div className="status-information">
                        <div>
                            <h6>Nazwa Zlecenia</h6>
                        </div>
                        <div>
                            <p>{props.data.order_no}</p>
                        </div>
                        <div className="seperator"></div>
                    </div>

                    <Grid item xs={12} className="status-history">
                        <TableContainer>
                            <Table stickyHeader size="small" aria-label="enhanced table">
                                <TableHead className={styles.tabHead}>
                                    <TableRow>
                                        <TableCell align="left">Data</TableCell>
                                        <TableCell align="left">Nazwa czynności</TableCell>
                                        <TableCell align="left">Czas trwania</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody className={styles.tabBody}>
                                    {props?.data?.driver_status?.map(
                                        (row, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell align="left">
                                                        {row.ts_status_start.split(".")[0]}
                                                    </TableCell>
                                                    <TableCell align="left">{row.status}</TableCell>
                                                    <TableCell align="left">
                                                        {row.time}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        }
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    onClick={() => {
                        props.setOpen(false);
                    }}
                    color="primary"
                >
                    Zamknij
                </Button>
            </DialogActions>
        </Dialog>
    );
}
