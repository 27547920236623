import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

import "./styles.sass";

type Props = {
  title?: string;
  status?: string;
};

const TmsLoading = ({ title, status }: Props) => (
  <div className="loader">
    {title && <p>{title}</p>}
    <ClipLoader
      color="#ed3c22"
      loading={true}
      speedMultiplier={1}
      cssOverride={{
        width: "50px",
        height: "50px",
      }}
    />
    <p>{status ? `${status}...` : "Ładowanie..."}</p>
  </div>
);

export default TmsLoading;
