import React , { useState, useEffect }from 'react'
import Input from '../../components/input'
import styles from '../table.module.css'
import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Snackbar } from '@material-ui/core';
import { DBurl } from '../../appConfig'
import stylesMod from '../../styles/newOrEdit.module.css'
import BTNstyles from '../../styles/button.module.css'
import CiclesRepStyles from '../../components/ciclesStyles.module.css'
import Grid from '@material-ui/core/Grid';
import Select from 'react-select'
import { XCircle, PlusCircle  } from 'react-feather';
import Button from '@material-ui/core/Button';
import { Add, Edit } from '@material-ui/icons';
import { X } from 'react-feather';
import Alert from '@material-ui/lab/Alert';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});


export default function DeadlinesCiclic(props) {
	const cyclicRangeOptions = [
		{ label: "Dzień", value: "1" },
		{ label: "Tydzień" , value: "3" },
		{ label: "Miesiąc", value: "5" }
	];

	const [ciclicRange, setCiclicRange] = useState({ label: "Tydzień" , value: "3" });
	const [ciclicType, setCiclicType] = useState("");
	const [checkPartialReport, setCheckPartialReport] = useState(props.data?.is_append || false)
	const [emails_to_edit, setEmails_to_edit] = useState([''])
    const [reportType, setReportType] = useState('')

	const [openDialog, setOpenDialog] = useState(false)
	const [dialogContent, setDialogContent] = useState("")
	const [dialogColor, setDialogColor] = useState("error")

	const [errorEmail, setErrorEmail] = useState(false)
	const [errorType, setErrorType] = useState("")
	const [errorRange, setErrorRange] = useState("")
	const [errorDeadline, setErrorDeadline] = useState("")
	const [error, setError] = useState(false)

	const validEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

	const cyclicTypeOptions = [
        {label: 'Do 31 dni', value: 1},
        {label: '31-60 dni', value: 2},
        {label: '61-90 dni', value: 3}
	];
    const reportTypeOptions = [
        {label:'Pojazdy', value:'REPORT_DOC_DEADLINES_CARS'},
        {label:'Naczepy', value:'REPORT_DOC_DEADLINES_TRAILERS'},
        {label:'Kierowcy', value:'REPORT_DOC_DEADLINES_DRIVERS'},
        {label:'Firma', value:'REPORT_DOC_DEADLINES_FIRM'}
    ]

	const reportTypeOptionsReverse = (props) => {
		if (props.data && props.data.report_type === 'Dokumenty - pojazdy') return {label:'Pojazdy', value:'REPORT_DOC_DEADLINES_CARS'}
		if (props.data && props.data.report_type === 'Dokumenty - naczepy') return {label:'Naczepy', value:'REPORT_DOC_DEADLINES_TRAILERS'}
		if (props.data && props.data.report_type === 'Dokumenty - kierowcy') return {label:'Kierowcy', value:'REPORT_DOC_DEADLINES_DRIVERS'}
		if (props.data && props.data.report_type === 'Dokumenty - firma') return {label:'Firma', value:'REPORT_DOC_DEADLINES_FIRM'}
	}

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenDialog(false)
	}

	const handleChange = (index, event) => {
		const values = [...emails_to_edit];
		values[index] = event.target.value;
		setEmails_to_edit(values);
	};
	
	const handleAddFields = () => {
		const values = [...emails_to_edit];
		values.push({});
		setEmails_to_edit(values);
		if (emails_to_edit?.some((item) => typeof item !== 'object') || emails_to_edit?.some((item) => item !== '')) {
			setError(false)
		}else{
			setError(true)
		}
	};

	const handleRemoveFields = () => {
		const values = [...emails_to_edit];
		if (values.length > 1) values.pop();
		setEmails_to_edit(values);
		if (emails_to_edit?.some((item) => typeof item !== 'object') || emails_to_edit?.some((item) => item !== '')) {
			setError(false)
		}else{
			setError(true)
		}
	};

    const clear = () => {
        setCiclicRange({ label: "Tydzień" , value: "3" })
        setCheckPartialReport(false)
        setEmails_to_edit([''])
        setReportType('')
    }

	const handleCheckError = () => {
		// !reportType ? setError(true) : setError(false)
		// emails_to_edit && emails_to_edit[0].match(validEmail) ? setError(false) : setError(true)
	}

	useEffect(() => {
		if (props.data && props.data.is_append) setCheckPartialReport(true)
	},[props.data])


	useEffect(()=>{
		handleCheckError()
	},[reportType, emails_to_edit])
 
	async function setCiclesReport() {
		await fetch(`${DBurl}/setCycleReports`, {
			method: "POST",
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": props.user.csrf_token
			},
			body: JSON.stringify({
				mode: props.isEdit ? 'update' : 'insert',
				data: {
					id: props.isEdit ? props.data.id : null,
                    report_type: reportType.value,
                    zakres: parseInt(ciclicRange.value),
                    id_car: null,
                    id_driver: null,
                    ignoruj: 0,
                    is_enabled: true,
                    is_append: ciclicRange.value !== '1' ? checkPartialReport : false,
                    file_type: 'xls',
                    emails: emails_to_edit,
				}
			})
		})
		.then(res => res.json())
			.then(
				data => {
					if (data.success) {
						if (props.isEdit) {
							setOpenDialog(true)
							setDialogContent(`Raport cykliczny zmieniony`)
							setDialogColor("success")
							clear()
							props.setOpenModalCiclic(false)
							props.refresh()
						}else {
							setOpenDialog(true)
							setDialogContent(`Raport cykliczny dodany`)
							setDialogColor("success")
							clear()
							props.setOpenModalCiclic(false)
						}

					} else {
						setOpenDialog(true)
						setDialogContent(`Proszę uzupełnić formularz.`)
						setDialogColor("error")
					}
				}
			)
	}

	const handleEdit = () => {
		setCiclicRange({label :cyclicRangeOptions.find(xx => xx.value == props.data?.zakres)?.label, value: props.data?.zakres})
		setEmails_to_edit(props.data?.emails.flat())
		setReportType(reportTypeOptionsReverse(props))
	}

	useEffect(()=>{
		props.isEdit && handleEdit()
	},[props])

	const checkEmails = emails_to_edit?.some((item) => {
			return typeof item === 'object'
		})

	// useEffect(() => {
	// 	if (emails_to_edit?.some((item) => typeof item === 'object') || emails_to_edit?.some((item) => item === '')) {
	// 		setError(true)
	// 	}else{
	// 		setError(false)
	// 	}
	// },[emails_to_edit])

	return (
		<>
			<Dialog
				open={props.openModalCiclic}
				TransitionComponent={Transition}
				className={'CiclicDialogSetting'}
				onClose={() => {
					props.setOpenModalCiclic(false)
                    clear()
				}}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-slide-title" className={stylesMod.dialogHead}>
					{!props.isEdit ? 'Ustaw raport cykliczny' : 'Edytuj raport cykliczny'}
					<IconButton aria-label="close" className={stylesMod.closeButton} onClick={() => {
						props.setOpenModalCiclic(false)
                        clear()
					}}>
						<X />
					</IconButton>
				</DialogTitle >

				<DialogContent style={{minHeight:'250px'}}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<div className={stylesMod.inp}>
								<label>Wybierz typ</label>
								<Select
										value={reportType}
										className={`${stylesMod.select} ${errorRange}`}
										options={reportTypeOptions}
										onChange={(e) => {
											setReportType(e)
										}}
										name="contractor"
										isDisabled={props.isEdit}
										placeholder="Wybierz..."
										styles={{
											control: (base, state) => ({
												...base,
												border: '1px solid #142f42',
												boxShadow: 'none',
												'&:hover': {
													border: '1px solid #142f42',
												}
											}),
											option: (provided, state) => ({
												...provided,
												backgroundColor: state.isFocused && "#142f42",
												color: state.isFocused && "#fff",
											})

										}}
									/>
							</div>
						</Grid>
						<Grid item xs={12}>
							<div className={stylesMod.inp}>
								<label>Częstotliwość generowania</label>
								<Select
										value={ciclicRange}
										className={`${stylesMod.select} ${errorRange}`}
										options={cyclicRangeOptions}
										onChange={(e) => {
											setCiclicRange(e)
										}}
										name="contractor"
										placeholder="Wybierz..."
										styles={{
											control: (base, state) => ({
												...base,
												border: '1px solid #142f42',
												boxShadow: 'none',
												'&:hover': {
													border: '1px solid #142f42',
												}
											}),
											option: (provided, state) => ({
												...provided,
												backgroundColor: state.isFocused && "#142f42",
												color: state.isFocused && "#fff",
											})

										}}
									/>
							</div>
						</Grid>
					</Grid>
						{ciclicRange.value !== '1' && <Grid container spacing={2}>
							<Grid item xs={12}>
								<div className={styles.checkPar}>
									<input type="checkbox" 
										className={`${stylesMod.check} ${CiclesRepStyles.check}`} 
										checked={checkPartialReport} 
										onChange={(e) => { 
											setCheckPartialReport(e.target.checked) 
										}} 
										name="checkPartialReport" 
									/>
									
									<label className={`${CiclesRepStyles.label}`} >Wysyłaj raport częściowy (codziennie)</label>
								</div>
							</Grid>
						</Grid>}
					
						<Grid container spacing={2}>
							{/* <Grid item xs={12}>
								<div className={stylesMod.inp}>
									<label>Wybierz zakres</label>
									<Select
											value={ciclicType}
											className={`${stylesMod.select} ${errorType}`}
											options={cyclicTypeOptions}
											onChange={(e) => {
												setCiclicType(e)
											}}
											name="contractor"
											placeholder="wybierz..."
											styles={{
												control: (base, state) => ({
													...base,
													border: '1px solid #142f42',
													boxShadow: 'none',
													'&:hover': {
														border: '1px solid #142f42',
													}
												}),
												option: (provided, state) => ({
													...provided,
													backgroundColor: state.isFocused && "#142f42",
													color: state.isFocused && "#fff",
												})

											}}
										/>
								</div>
							</Grid> */}
						</Grid> 
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<div className={stylesMod.inp}>
								<label>Adres e-mail odbiorców</label>	

								{emails_to_edit && emails_to_edit.map((data, i) => {
									return (
										<Grid container spacing={2} key={i} style={{paddingBottom: '5px'}}>
											<Grid item xs={8}>
												<div className={stylesMod.inp}>
													<Input
														error={error}
														value={JSON.stringify(data) === '{}' ? '' : data}
														handleChange={(e) => {
															handleChange(i, e)
															const isValid = validEmail.test(e.target.value)
															setError(!isValid)
															setErrorEmail(!isValid)
														}}
														name="email"
														type="email"
														placeholder="Adres e-mail"
													/>
												</div>
											</Grid>
											<Grid item xs={1}>
												<IconButton style={{margin: '5px' }}
													className={stylesMod.btnIcon2}
													onClick={handleAddFields}
												>
													<PlusCircle className={styles.add} style={{color: 'var(--cor-col)', stroke: 'var(--cor-col)'}}/>
												</IconButton>
											</Grid>
											<Grid item xs={1}>
												<IconButton style={{margin: '5px 0px 5px 10px' }}
													className={stylesMod.btnIcon2}
													onClick={handleRemoveFields}
												>
													<XCircle className={styles.remove} style={{color: 'var(--main-col)', stroke: 'var(--main-col)'}}/>
												</IconButton>
											</Grid>

										</Grid>
										
									);
								})}	

							</div>
						</Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}></Grid>
					</Grid>


				</DialogContent>

				<DialogActions>
					<Button variant="contained" size="large" color="primary" className={BTNstyles.btn} onClick={(e) => {
						props.setOpenModalCiclic(false)
                        clear()
					}}>
						Anuluj
					</Button>
					<Button variant="contained" size="large" className={`${BTNstyles.btn} ${BTNstyles.grn}`} onClick={() => {
						if (emails_to_edit.some((item) => typeof item === 'object') || emails_to_edit.some((item) => item === '')) {
							setOpenDialog(true)
							setDialogContent(`Proszę upewnić się czy podane adresy email są poprawne.`)
							setDialogColor("warning")
						}else {
							if (!error) {
							setCiclesReport()
						}
						if (error && !errorEmail) {
							setOpenDialog(true)
							setDialogContent(`Proszę uzupełnić formularz.`)
							setDialogColor("warning")
						}
						if (error && errorEmail) {
							setOpenDialog(true)
							setDialogContent(`Proszę upewnić się czy podane adresy email są poprawne.`)
							setDialogColor("warning")
						}
						}

					}
					}>
                        {props.isEdit ? 'Edytuj' : 'Zapisz'}
					</Button>
				</DialogActions>
			</Dialog>

			<Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
				<Alert onClose={handleClose} severity={dialogColor}>
					{dialogContent}
				</Alert>
			</Snackbar>

		</>
	)
}
