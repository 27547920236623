import React, { useState, useEffect, useRef, SyntheticEvent } from 'react';
import Title from '../../components/title'
import styles from '../table.module.css'
import { DBurl } from '../../appConfig'
import {
	Button, Table, TableBody, TableCell, TableContainer, IconButton, TableHead, TableRow, TableSortLabel, Paper, Checkbox, Slide, Snackbar, Dialog,
	DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip, Grid, Switch, FormControlLabel
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import docsStyles from '../../styles/documents.module.css'
import { useInput } from 'components/hooks';
import { DriversActivityPropsType, DriversActivityTableType, StatusType } from 'typeDefinitions';
import { CommonUserType } from 'typeDefinitions/common/Common.types';
import { confirmFleetCostsReport } from './AddFleetCosts.api';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export type fleetCostsReportStateType = {
	table: any
	date: Date
	tabName: string
}

export type innerCostType = {
	total_price_PLN: number
	total_price_USD: number
	total_price_EUR: number
	calculated_price: number
	description: string
	currency: string
	fleet_cost_type: string
	created_at: string
}

export type CostType = {
	total_price_PLN: number
	total_price_USD: number
	total_price_EUR: number
	calculated_price: number
	description: string
	currency: string
	fleet_cost_type: string
	created_at: string
	costs: innerCostType[]
}

export type FleetCostsReportPropsType = {
	tabID: number;
	loading: (newState: boolean) => void;
	name?: string;
	setTabs: any;
	user: CommonUserType;
	state: fleetCostsReportStateType;
	tabs: any
}

const FleetCostsReport = (props: FleetCostsReportPropsType) => {
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('car');
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [table, setTable] = useState(props.state.table || [])
	const [selected, setSelected] = useState<number[]>([])
	const [openDialog, setOpenDialog] = useState(false)
	const [dialogContent, setDialogContent] = useState("")
	const [dialogColor, setDialogColor] = useState<"error" | "warning" | "success" | "info">("error")
	const [date, setDate] = useState(new Date())
	const [newlyGenerated, setNewlyGenerated] = useState<boolean>()

	const savedTabId = props.tabID
	const saveTab = () => {

		const tempTab = { ...props.tabs[savedTabId] }

		tempTab.table = table
		tempTab.date = date
		tempTab.newlyGenerated = newlyGenerated

		return tempTab

	}

	useEffect(() => {
		props.setTabs(() => {
			const tempTab = [...props.tabs]
			tempTab[props.tabID] = saveTab()
			return tempTab
		})
	}, [table, date])

	const headCells = [
		{ id: 'orderNum', numeric: true, disablePadding: false, label: 'Pojazd', class: docsStyles.headCell },
		{ id: 'contractor', numeric: true, disablePadding: false, label: 'Koszt' },
		{ id: 'driver', numeric: true, disablePadding: false, label: 'Za co' },
		{ id: 'car', numeric: true, disablePadding: false, label: 'Data dodania' },
	];

	function EnhancedTableHead(props: any) {
		const { styles, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

		return (
			<TableHead className={styles.tabHead}>
				<TableRow>
					{headCells.map((headCell) => {
						return (
							<TableCell
								key={headCell.id}
								align="left"
								padding={headCell.disablePadding ? 'none' : 'default'}
								sortDirection={orderBy === headCell.id ? order : false}
								className={headCell.class}
							>
								{headCell.label}
							</TableCell>
						)
					})}
				</TableRow>
			</TableHead>
		);
	}

	const handleClose = (_event: SyntheticEvent<Element, Event>) => {
		setOpenDialog(false)
	}

	const fetchData = async () => {
		const selectedMonth = date ? date.getMonth() + 1 : new Date().getMonth() + 1
		const selectedYear = date ? date.getFullYear() : new Date().getFullYear()
		props.loading(true)
		const response = await fetch(`${DBurl}/getFleetCostsReportData?month=${selectedMonth}&year=${selectedYear}`, {
			method: "GET",
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": props.user.csrf_token
			}
		});
		const json = await response.json()
		if (json.success) {
			if (json.data.length === 0) {
				setOpenDialog(true)
				setDialogColor('warning')
				setDialogContent('Brak danych dla tego wyszukiwania')
				props.loading(false)
			} else {
				//@ts-ignore
				setTable(json.data)
				setNewlyGenerated(true)
				props.loading(false)
			}
		}
	}

	const handleSuccess = () => {
		setOpenDialog(true)
		setDialogColor("success")
		setDialogContent("Raport został zatwierdzony.")
		setNewlyGenerated(false)
	}

	const handleFail = () => {
		setOpenDialog(true)
		setDialogColor("error")
		setDialogContent("Już istnieje zatwierdzony raport na ten miesiąc.")
	}

	const handleConfirm = async () => {
		{
			const data = {
				issue_date: date,
				is_confirmed: true
			};
		await confirmFleetCostsReport(props.user.csrf_token, data, handleSuccess, handleFail)
	}
	}

	const handleRequestSort = (_event: unknown, property: string) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const isSelected = (name: number) => selected.indexOf(name) !== -1;

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, table.length - page * rowsPerPage);
	//@ts-ignore
	const carRelatedCosts: { key: string, [key: string]: any }[] = table.car_related_costs && Object.entries(table.car_related_costs).map(([key, value]) => ({ key, ...value }))

	const otherCosts: CostType[] = Array(table.other_costs)

	return (
		<>
			<div className={styles.root}>
				<Paper className={styles.paper}>
					<Title title={props.state.tabName}
						btnBox
						btnGenerate
						generateFunc={() => {
							if (!date) {
								setOpenDialog(true)
								setDialogColor('error')
								setDialogContent('Proszę wybrać datę')
							} else {
								fetchData()
							}
						}}
						btnConfirm
						disabled={!newlyGenerated}
						confirmFunc={() => {handleConfirm()}}
						cornersNew
						datapicker_month={(e:Date) => {
							setDate(e)
							setNewlyGenerated(false)
						}}
						date={date}
					/>
					<TableContainer className={styles.stickyTable}>
						<Table
							className={styles.table}
							aria-labelledby="tableTitle"
							size="small"
							aria-label="enhanced table"
						>

							<EnhancedTableHead
								styles={styles}
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onRequestSort={handleRequestSort}
								rowCount={table.length}
							/>
							<TableBody className={styles.tabBody}>
								<>
									{carRelatedCosts && carRelatedCosts.map((row: any) => {
										return (
											<>
												<TableRow>
													<TableCell><b>{row.key}</b></TableCell>
													<TableCell></TableCell>
													<TableCell></TableCell>
													<TableCell></TableCell>
												</TableRow>
												{row.costs.map((cost: any) => {
													return (
														<TableRow>
															<TableCell></TableCell>
															<TableCell>{cost.calculated_price.toFixed(2)} {cost.currency}</TableCell>
															<TableCell>{cost.fleet_cost_type} {cost.description && `(${cost.description})`}</TableCell>
															<TableCell>{cost.created_at}</TableCell>
														</TableRow>
													)
												})}
												{row.total_price_PLN && <TableRow className={!row?.total_price_EUR && !row?.total_price_USD ? `fleet-costs-report__table-row--last` : undefined}>
													<TableCell></TableCell>
													<TableCell></TableCell>
													<TableCell><b>Łączna kwota w PLN</b></TableCell>
													<TableCell><b>{row.total_price_PLN.toFixed(2)} PLN</b></TableCell>

												</TableRow>}
												{row.total_price_EUR && <TableRow className={!row?.total_price_USD ? `fleet-costs-report__table-row--last` : undefined}>
													<TableCell></TableCell>
													<TableCell></TableCell>
													<TableCell><b>Łączna kwota w EUR</b></TableCell>
													<TableCell><b>{row.total_price_EUR.toFixed(2)} EUR</b></TableCell>

												</TableRow>}
												{row.total_price_USD && <TableRow className={`fleet-costs-report__table-row--last`}>
													<TableCell></TableCell>
													<TableCell></TableCell>
													<TableCell><b>Łączna kwota w USD</b></TableCell>
													<TableCell><b>{row.total_price_USD.toFixed(2)} USD</b></TableCell>
	
												</TableRow>}
											</>
										)
									})}
									{otherCosts && otherCosts[0]?.costs?.map((cost: innerCostType) => {
										console.log(cost)
										return (
											<>
												<TableRow>
													<TableCell></TableCell>
													<TableCell>{cost.calculated_price.toFixed(2)} {cost.currency}</TableCell>
													<TableCell>{cost.fleet_cost_type} {cost.description && `(${cost.description})`}</TableCell>
													<TableCell>{cost.created_at}</TableCell>
												</TableRow>
											</>
										)
									})}
									{otherCosts[0]?.total_price_PLN && <TableRow className={!otherCosts[0]?.total_price_EUR && !otherCosts[0]?.total_price_USD ? `fleet-costs-report__table-row--last` : undefined}>
										<TableCell></TableCell>
										<TableCell></TableCell>
										<TableCell><b>Łączna kwota w PLN</b></TableCell>
										<TableCell><b>{otherCosts[0].total_price_PLN.toFixed(2)} PLN</b></TableCell>
									</TableRow>}
									{otherCosts[0]?.total_price_EUR && <TableRow className={!otherCosts[0]?.total_price_USD ? `fleet-costs-report__table-row--last` : undefined}>
										<TableCell></TableCell>
										<TableCell></TableCell>
										<TableCell><b>Łączna kwota w EUR</b></TableCell>
										<TableCell><b>{otherCosts[0].total_price_EUR.toFixed(2)} EUR</b></TableCell>

									</TableRow>}
									{otherCosts[0]?.total_price_USD && <TableRow className={`fleet-costs-report__table-row--last`}>
										<TableCell></TableCell>
										<TableCell></TableCell>
										<TableCell><b>Łączna kwota w USD</b></TableCell>
										<TableCell><b>{otherCosts[0].total_price_USD.toFixed(2)} USD</b></TableCell>

									</TableRow>}
								</>
								{emptyRows > 0 && (
									<TableRow style={{ height: 33 * emptyRows }}>
										<TableCell colSpan={4} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<div className={styles.tableBottom} >
					</div>

				</Paper>
			</div>
			<Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
				<Alert onClose={handleClose} severity={dialogColor}>
					{dialogContent}
				</Alert>
			</Snackbar>
		</>
	);
}

export default FleetCostsReport;