import React, { useEffect, useState, useRef } from "react";
import { useMutation, useQuery } from "react-query";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import moment from "moment";
import {
  getDrivers,
  getDriversCosts,
  setDriversCosts,
  setDriversCostsReports,
} from "api/endpoints";
import DatePicker from "react-datepicker";
import plLocale from "date-fns/locale/pl";
import TmsLoading from "components/TmsLoading";
import { perPageButtons } from "containers/order/utilities/constants";
import OrderPerPageButton from "containers/order/OrderPerPageButton";
import Pagination from "components/pagination";
import TmsSelect from "components/TmsSelect";
import IcoBtn from "components/buttons/icoBtn";

import styles from "containers/table.module.css";
import "./styles.sass";

const data = [
  { enum: 0, value: "MILEAGE_RATE" },
  { enum: 1, value: "DAILY_ALLOWANCE" },
  { enum: 2, value: "SALARY" },
  { enum: 3, value: "BONUS" },
  { enum: 4, value: "SUBSISTENCE_ALLOWANCE" },
  { enum: 5, value: "DEDUCTION" },
  { enum: 6, value: "ADVANCE" },
  { enum: 7, value: "FINE" },
];

const currencyOption = [
  { label: "PLN", value: "PLN" },
  { label: "EURO", value: "EUR" },
  { label: "CHF", value: "CHF" },
  { label: "FUNT", value: "GBP" },
  { label: "KORONA", value: "CZK" },
  { label: "RUBEL", value: "RUB" },
  { label: "HRYWNA", value: "UAH" },
];

const DriversCosts = ({
  handleChangeView,
  isEdit,
  editDate,
  editId,
  setDialog,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [page, setPage] = useState(0);
  const [filterDate, setFilterDate] = useState();
  const [costState, setCostState] = useState();
  const [inputValue, setInputValue] = useState(0);
  const [currencyState, setCurrencyState] = useState("PLN");
  const inputRef = useRef(null);

  const paginate = (pageNumber) => setPage(pageNumber - 1);

  const { data: driversData, isLoading } = useQuery("getDrivers", getDrivers);

  const { data: driversCosts, refetch: refetchDriversCosts } = useQuery(
    ["getDriversCosts", filterDate],
    () =>
      getDriversCosts({
        filter_year: filterDate?.year,
        filter_month: filterDate?.month,
      })
  );

  const { mutate: mutateCosts } = useMutation(
    "setDriversCosts",
    setDriversCosts,
    {
      onSuccess: () => {
        clearStates();
        refetchDriversCosts();
      },
      onError: (error) => {
        const errorMessage = error.response.data.responseDescription;

        if (errorMessage) {
          setDialog({
            isOpen: true,
            type: "error",
            content: error.response.data.responseDescription,
          });
        }
        clearStates();
      },
    }
  );

  const { mutate: mutateRaport } = useMutation(
    "setDriversCostsReports",
    setDriversCostsReports,
    {
      onSuccess: () => {
        setDialog({
          isOpen: true,
          type: "success",
          content: "Rozliczenie zostało zaaktualizowane",
        });
        handleChangeView("settlements");
      },
      onError: (error) => {
        const errorMessage = error.response.data.responseDescription;

        if (
          errorMessage === "Report for this month has been already created!"
        ) {
          setDialog({
            isOpen: true,
            type: "error",
            content: "Rozliczenie dla tego miesiąca zostało już utworzone!",
          });
          handleChangeView("settlements");
        }
      },
    }
  );

  const clearStates = () => {
    setCostState({});
    setInputValue(0);
    setCurrencyState("PLN");
  };

  const handleEdit = (id_driver, type, id_driver_cost_type, cost) => {
    if (!cost) {
      clearStates();
      setCostState({
        id_driver,
        type,
        id_driver_cost_type,
        currency: "PLN",
        value: 0,
      });
    } else {
      setCostState(cost);
      setInputValue(cost.value);
      setCurrencyState(cost.currency);
    }
  };

  const handleChangeDate = (date) => {
    let newD = moment(date).valueOf();
    let year = moment(newD).format("yyyy");
    let month = moment(newD).format("MM");

    setFilterDate({ year, month, date });
  };

  useEffect(() => {
    const date = isEdit ? new Date(editDate) : new Date();

    handleChangeDate(date);
  }, [isEdit, editDate]);

  const parseDate = (date) => {
    let newD = moment(date).valueOf();
    let year = moment(newD).format("yyyy");
    let month = moment(newD).format("MM");

    return `${year} - ${month}`;
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current, costState]);

  const handleUpdate = () => {
    let newD = moment(filterDate?.date).valueOf();
    let newDS = moment(newD).format("yyyy-MM-DDTHH:mm");

    if (inputValue < 1) {
      setDialog({
        isOpen: true,
        type: "error",
        content: "Wpisz poprawną wartość",
      });
      return;
    }

    const data = {
      id: costState.id ? costState.id : null,
      id_driver_cost_type: costState.id_driver_cost_type,
      id_driver: costState.id_driver,
      price: inputValue,
      currency: currencyState,
      start_date: newDS,
    };

    mutateCosts(data);
  };

  const handleMutateRaport = (id) => {
    let newD = moment(filterDate?.date).valueOf();
    let newDS = moment(newD).format("yyyy-MM-DD");

    if (isEdit) {
      mutateRaport({ issue_date: newDS, id: editId });
    } else {
      mutateRaport({ issue_date: newDS });
    }
  };

  const mergedData = driversData?.map((driver) => {
    const matchingCosts = driversCosts?.filter(
      (cost) => cost.id_driver === driver.id
    );

    if (matchingCosts?.length > 0) {
      const costs = matchingCosts?.map((matchingCost) => {
        const enumData = data.find(
          (item) => item.enum === matchingCost?.id_driver_cost_type
        );

        return {
          id: matchingCost.id,
          type: enumData?.value,
          value: Number(matchingCost?.price),
          start_date: matchingCost?.start_date,
          currency: matchingCost?.currency,
          id_driver_cost_type: matchingCost?.id_driver_cost_type,
          id_driver: matchingCost?.id_driver,
        };
      });

      return {
        ...driver,
        costs,
      };
    }

    return { ...driver };
  });

  const renderMonthContent = (month, shortMonth, longMonth, day) => {
    const tooltipText = `${longMonth}`;

    return <span title={tooltipText}>{shortMonth}</span>;
  };

  const handleEnterKeyPress = (
    e,
    isEdit,
    driver_id,
    item_value,
    item_enum,
    cost
  ) => {
    if (e.key === "Enter") {
      isEdit
        ? handleUpdate()
        : handleEdit(driver_id, item_value, item_enum, cost);
    }
  };

  if (isLoading) {
    return <TmsLoading />;
  }

  return (
    <div className="driver-costs">
      <div className="drivers-costs-header">
        <div className="drivers-costs-header--left">
          <h1 className="drivers-costs-header__title">
            {isEdit
              ? `Edycja rozliczenia kierowców za okres ${parseDate(editDate)}`
              : "Nowe rozliczenie kierowców za okres"}
          </h1>
          <div className="drivers-costs-header__buttons">
            {!isEdit && (
              <DatePicker
                selected={filterDate?.date}
                renderMonthContent={renderMonthContent}
                showMonthYearPicker
                dateFormat="MM/yyyy"
                onChange={(newDate) => {
                  handleChangeDate(newDate);
                }}
                style={{
                  backgroundColor: "red !important",
                  zIndex: 9999,
                }}
                locale={plLocale}
                name="date"
                calendarClassName="calendar"
                placeholderText={"MM/yyyy"}
              />
            )}
          </div>
        </div>
        <div className="drivers-costs-header--right">
          <button
            className="drivers-costs-header__button"
            onClick={handleMutateRaport}
          >
            {isEdit ? "Edytuj rozliczenie" : "Utwórz rozliczenie"}
          </button>
          <button
            className="drivers-costs-header__button"
            onClick={() => handleChangeView("settlements")}
          >
            Anuluj
          </button>
        </div>
      </div>
      <TableContainer className="drivers-costs-table">
        <Table stickyHeader>
          <TableHead className="drivers-costs-table__header">
            <TableRow>
              <TableCell
                className="drivers-costs-header__cell"
                size="medium"
                padding="normal"
              >
                <label>Kierowca</label>
              </TableCell>
              <TableCell
                className="drivers-costs-header__cell"
                size="medium"
                padding="normal"
              >
                <label>Stawki km</label>
              </TableCell>
              <TableCell
                className="drivers-costs-header__cell"
                size="medium"
                padding="normal"
              >
                <label>Dniówki</label>
              </TableCell>
              <TableCell
                className="drivers-costs-header__cell"
                size="medium"
                padding="normal"
              >
                <label>Pensja</label>
              </TableCell>
              <TableCell
                className="drivers-costs-header__cell"
                size="medium"
                padding="normal"
              >
                <label>Dodatek</label>
              </TableCell>
              <TableCell
                className="drivers-costs-header__cell"
                size="medium"
                padding="normal"
              >
                <label>Diety</label>
              </TableCell>
              <TableCell
                className="drivers-costs-header__cell"
                size="medium"
                padding="normal"
              >
                <label>Potrącenia</label>
              </TableCell>
              <TableCell
                className="drivers-costs-header__cell"
                size="medium"
                padding="normal"
              >
                <label>Zaliczki</label>
              </TableCell>
              <TableCell
                className="drivers-costs-header__cell"
                size="medium"
                padding="normal"
              >
                <label>Mandaty</label>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mergedData
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((driver) => (
                <TableRow key={driver.id}>
                  <TableCell className="drivers-costs-header__cell">
                    {driver.firstname} {driver.surname}
                  </TableCell>
                  {data.map((item) => {
                    const cost = driver?.costs?.find(
                      (cost) => cost.type === item.value
                    );

                    const value = cost ? cost.value : 0;
                    const isEdit =
                      costState?.id_driver === driver.id &&
                      costState.type === item.value;
                    const currency = cost ? cost.currency : "PLN";

                    return (
                      <TableCell
                        className="drivers-costs-header__cell"
                        key={item.enum}
                      >
                        <form
                          className="driver-costs-table__row-wrapper"
                          onKeyDown={(e) =>
                            handleEnterKeyPress(
                              e,
                              isEdit,
                              driver.id,
                              item.value,
                              item.enum,
                              cost
                            )
                          }
                        >
                          {isEdit ? (
                            <div className="driver-costs-table__input-wrapper">
                              <input
                                className="driver-costs-table__input"
                                type="number"
                                value={inputValue}
                                ref={inputRef}
                                onChange={(e) => setInputValue(e.target.value)}
                              />
                              <TmsSelect
                                options={currencyOption}
                                value={{
                                  label: currencyState,
                                  value: currencyState,
                                }}
                                onChange={(option) => {
                                  setCurrencyState(option.value);
                                }}
                                minWidth="80px"
                              />
                            </div>
                          ) : (
                            <div className="driver-costs-table__value-wrapper">
                              <span>{value}</span>
                              <span className="driver-costs-table__value-currency">
                                {currency}
                              </span>
                            </div>
                          )}
                          <IcoBtn
                            icon={isEdit ? "Save" : "Edit"}
                            tooltip={isEdit ? "Zapisz" : "Edytuj"}
                            className="driver-costs-table__button-edit"
                            type="Submit"
                            onClick={() => {
                              isEdit
                                ? handleUpdate()
                                : handleEdit(
                                    driver.id,
                                    item.value,
                                    item.enum,
                                    cost
                                  );
                            }}
                          />
                        </form>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <div
          className={styles.tableBottom}
          style={{
            width: "calc(100vw - 200px)",
            position: "fixed",
            bottom: 0,
            zIndex: 999,
          }}
        >
          <div>
            {perPageButtons?.map(({ value, minNumber }) => (
              <OrderPerPageButton
                key={value}
                tableLength={mergedData ? mergedData?.length : 0}
                minNumber={minNumber}
                value={value}
                templatesLength={0}
                setRowsPerPage={setRowsPerPage}
                rowsPerPage={rowsPerPage}
              />
            ))}
          </div>
          <div>
            <Pagination
              postsPerPage={rowsPerPage}
              totalPosts={mergedData?.length}
              paginate={paginate}
              page={page}
            />
          </div>
        </div>
      </TableContainer>
    </div>
  );
};

export default DriversCosts;
