import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Grid, TextField } from "@material-ui/core";
import { BsInfoCircle } from "react-icons/bs";
import Select from "react-select";
import Tooltip from "@material-ui/core/Tooltip";
import Input from "components/input";
import TmsTitleSmall from "components/TmsTitleSmall";
import ContractorDetailsModal from "components/ContractorDetailsModal";
import Tags from "./Tags";

import { getContractors } from "api/endpoints";

import stylesVersion from "styles/version_s.module.css";
import stylesMod from "styles/newOrEdit.module.css";

const BasicInformation = ({
  values,
  updateValue,
  updateValues,
  addTab,
  error,
  setDialog,
  user_s,
  setDateFrom,
  setDateTo,
  toCostValues,
  setToCostValues,
  saveTab,
  isShowRequiredFields,
  currencyOption,
  isPreview,
  getOrderName,
}) => {
  const [contractorOption, setContractorOption] = useState([]);
  const [openDialogClientDetails, setOpenDialogClientDetails] = useState(false);

  useQuery("getContractors", getContractors, {
    onSuccess: (contractors) => {
      setContractorOption(
        contractors?.map((contractor) => ({
          ...contractor,
          value: contractor.id,
          label: contractor.name,
        }))
      );
    },
    onError: (error) => {
      setDialog({ isOpen: true, type: "error", content: error?.message });
    },
  });

  useEffect(() => {
    if (Number(values.price) < 0) {
      updateValue("price", 0);
    }
  }, [values.price]);

  useEffect(() => {
    if (values?.trace) {
      if (values.transport?.set !== undefined) {
        if (
          values.transport?.set?.id_driver1 !== null &&
          values.transport?.set?.id_car !== null
        ) {
          let id_driver = values.transport?.set?.id_driver1;
          let id_car = values.transport?.set?.id_car;
          let ts = values?.trace[0]?.date;
          if (
            id_driver !== undefined &&
            id_car !== undefined &&
            ts !== null &&
            (values.trace[0]?.address !== undefined ||
              (values.trace[0]?.warehouse !== undefined &&
                values.trace[0]?.warehouse?.address !== undefined))
          ) {
            setToCostValues(true);
          } else {
            setToCostValues(false);
          }
        } else {
          setToCostValues(false);
        }
      } else {
        setToCostValues(false);
      }
    }
  }, [values?.transport.set, values?.trace]);

  const changeContractor = (contractor) => {
    let trace = values.trace;

    for (let i = 0; i < values.trace.length; i++) {
      if (trace[i].warehouse) {
        delete trace[i].warehouse;
      }
    }

    updateValues({ ...values, contractor, trace });
  };

  return (
    <>
      <Grid item xs={8} className={stylesMod.br}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <div className={stylesMod.inp}>
                  <label>Nr zlecenia*</label>
                  <Input
                    value={values.order_no}
                    handleChange={(e) =>
                      updateValue("order_no", e.target.value)
                    }
                    name="order_no"
                    error={error && values.order_no.length < 1}
                    reload={values.show_order_no_refresh_button}
                    reloadFunc={() => getOrderName()}
                    disabled={isPreview}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <TmsTitleSmall title="Dane klienta" />
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={4} className={user_s ? "user_s" : "user_full"}>
                <div className={stylesMod.inp}>
                  <label>Nazwa zlecenia</label>
                  <Input
                    value={values.order_title}
                    handleChange={(e) =>
                      updateValue("order_title", e.target.value)
                    }
                    name="order_title"
                    error={error && values.order_title.length < 1}
                    disabled={isPreview}
                  />
                </div>
              </Grid>
              <Grid item xs={3} className={user_s ? "user_s" : "user_full"}>
                <div className={stylesMod.inp}>
                  <label>Początek:</label>
                  {setDateFrom}
                </div>
              </Grid>
              <Grid item xs={3} className={user_s ? "user_s" : "user_full"}>
                <div className={stylesMod.inp}>
                  <label>Koniec:</label>
                  {setDateTo}
                </div>
              </Grid>
              <Grid item xs={12} className={user_s ? "user_s" : "user_full"}>
                <div className={`${stylesMod.inp} ${"discription_textarea"}`}>
                  <label>Treść zlecenia</label>
                  <TextField
                    multiline
                    minRows={4}
                    value={values.order_text}
                    error={error && values.order_text === ""}
                    name="order_text"
                    onChange={(e) => updateValue("order_text", e.target.value)}
                    disabled={isPreview}
                  />
                </div>
              </Grid>
              <Grid item xs={5}>
                <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
                  <div className={stylesMod.inp}>
                    <div className={stylesMod.labelContainer}>
                      <label
                        className={`${user_s ? stylesVersion.onlyfull : ""}`}
                      >
                        Klient*
                      </label>
                      {values.contractor.label && (
                        <BsInfoCircle
                          className={stylesMod.infoButton}
                          onClick={() => setOpenDialogClientDetails(true)}
                        />
                      )}
                    </div>
                    <Select
                      isDisabled={user_s || isPreview}
                      value={values.contractor}
                      className={`${stylesMod.select} ${
                        error && !values.contractor.value && stylesMod.error
                      } ${user_s ? stylesVersion.onlyfull : ""}`}
                      options={contractorOption}
                      onChange={(contractor) => {
                        changeContractor(contractor);
                        setToCostValues(false);
                      }}
                      name="contractor"
                      placeholder="Wybierz..."
                      // components={{ ValueContainer }}
                      selectProps={addTab}
                      saveTab={saveTab}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          border: "1px solid #142f42",
                          backgroundColor:
                            isShowRequiredFields &&
                            !toCostValues &&
                            !values.contractor &&
                            "#e1f3d3 !important",
                          borderColor:
                            isShowRequiredFields &&
                            !toCostValues &&
                            "#82cd47 !important",
                          boxShadow: "none",
                          "&:hover": {
                            border: "1px solid #142f42",
                          },
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused && "#142f42",
                          color: state.isFocused && "#fff",
                        }),
                      }}
                    />
                  </div>
                </Tooltip>
              </Grid>
              <Grid item xs={3}>
                <div className={stylesMod.inp}>
                  <label>Nr zlecenia od klienta</label>
                  <Input
                    value={values.customer_order_number}
                    handleChange={(e) =>
                      updateValue("customer_order_number", e.target.value)
                    }
                    name="customer_order_number"
                    disabled={isPreview}
                  />
                </div>
              </Grid>
              <Grid item xs={2}>
                <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
                  <div className={stylesMod.inp}>
                    <label
                      className={`${user_s ? stylesVersion.onlyfull : ""}`}
                    >
                      Kwota całkowita
                    </label>
                    <Input
                      className={`${user_s ? stylesVersion.onlyfull : ""}`}
                      disabled={user_s || isPreview}
                      value={values.price}
                      handleChange={(e) => {
                        updateValue("price", e.target.value);
                      }}
                      min={0}
                      name="price"
                      type="number"
                    />
                  </div>
                </Tooltip>
              </Grid>
              <Grid
                item
                xs={1}
                style={{
                  maxWidth: "12.4999985%",
                  flexBasis: "12.4999985%",
                }}
              >
                <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
                  <div className={stylesMod.inp}>
                    <label
                      className={`${user_s ? stylesVersion.onlyfull : ""}`}
                    >
                      Waluta
                    </label>
                    <Select
                      value={values.currency}
                      isDisabled={user_s || isPreview}
                      className={`${stylesMod.select} ${
                        user_s ? stylesVersion.onlyfull : ""
                      }`}
                      options={currencyOption}
                      onChange={(e) => {
                        updateValue("currency", e);
                      }}
                      name="currency"
                      placeholder="Wybierz..."
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          border: "1px solid #142f42",
                          boxShadow: "none",
                          "&:hover": {
                            border: "1px solid #142f42",
                          },
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused && "#142f42",
                          color: state.isFocused && "#fff",
                        }),
                      }}
                    />
                  </div>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Tags
          selected_tags={values.selected_tags}
          updateValue={updateValue}
          isPreview={isPreview}
        />
      </Grid>
      <ContractorDetailsModal
        openDialogClientDetails={openDialogClientDetails}
        setOpenDialogClientDetails={setOpenDialogClientDetails}
        contractor={values.contractor}
      />
    </>
  );
};

export default BasicInformation;
