/* global google */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import styles from './map.module.css';

export class MyCustomMarker extends Component {

    /**
    * constructor()
    *
    * Funkcja 
    *
    */
    constructor(props) {
        super(props);
        this.state = {
            eta_status: this.props.marker.eta_status,
            eta_distans: this.props.marker.eta_distans,
            eta_time: this.props.marker.eta_time,
            eta_etime: this.props.marker.eta_etime,
            eta_distans_upd: null,
        }
    }

    /**
    * componentDidMount()
    *
    * Funkcja 
    *
    */
    componentDidMount = () => {

    }

    /**
    * componentDidUpdate(prevProps)
    *
    * Funkcja 
    *
    */
    componentDidUpdate(prevProps, prevState) {

        if (prevProps.eta_waypoints !== this.props.eta_waypoints) {

            if (this.props.eta_waypoints[this.props.index + 1].realtimeStatus.distanceRemaining !== undefined) {
                let eta_status = (this.props.eta_waypoints[this.props.index + 1].realtimeStatus.status) === 'visited' ? 'Zrealizowane' : 'W trakcie';
                let eta_distans = (this.props.eta_waypoints[this.props.index + 1].realtimeStatus.distanceRemaining) > 0 ? this.props.eta_waypoints[this.props.index + 1].realtimeStatus.distanceRemaining / 1000 + ' km' : 0 + ' km';
                let eta_time = this.toHHMMSS(this.props.eta_waypoints[this.props.index + 1].realtimeStatus.timeRemaining);
                let eta_etime = this.convertDateToLocalFormat(this.props.eta_waypoints[this.props.index + 1].realtimeStatus.estimatedTimeArrival);

                this.setState({
                    eta_status: eta_status,
                    eta_distans: eta_distans,
                    eta_time: eta_time,
                    eta_etime: eta_etime
                });
            }


        }

    }

    /**
     * convertDateToLocalFormat(date)
     *
     * Funkcja 
     *
     */
    convertDateToLocalFormat = (date) => {

        let m = new Date(date);

        let year = m.getFullYear();
        let month = ((m.getMonth() + 1) > 9) ? (m.getMonth() + 1) : '0' + (m.getMonth() + 1);
        let day = (m.getDate() + 1 > 9) ? m.getDate() : '0' + m.getDate();

        let hours = (m.getHours() + 1 > 9) ? m.getHours() : '0' + m.getHours();
        let minutes = (m.getMinutes() + 1 > 9) ? m.getMinutes() : '0' + m.getMinutes();
        let seconds = (m.getSeconds() + 1 > 9) ? m.getSeconds() : '0' + m.getSeconds();

        var dateLocalString = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;

        return dateLocalString;
    }

    /**
    * toHHMMSS()
    *
    * Funkcja 
    *
    */
    toHHMMSS = (sec) => {
        var sec_num = parseInt(sec, 10); // don't forget the second param
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);
        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        if (seconds < 10) {
            seconds = "0" + seconds;
        }
        var time = hours + ':' + minutes + ':' + seconds;

        return time;
    }

    /**
     * render()
     *
     * Funkcja 
     *
     */
    render = () => {

        let icon_url = icons[`m-${this.props.index}.png`];
        let full_refueling = null;
        let is_aggregate = null;
        let type = '';
        let icon_out = {
            url: icon_url,
            size: new google.maps.Size(35, 47),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(16, 42)
        }

        switch (this.props.marker.type) {
            case 'loading':
                type = 'Załadunek';
                break;
            case 'unloading':
                type = 'Rozładunek';
                break;
            case 'stop':
                type = 'Punkt';
                break;
            case 'refuel':
                type = 'Tankowanie';
                icon_url = icons_refueling[`poi11.png`];
                icon_out = {
                    url: icon_url,
                    size: new google.maps.Size(35, 47),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(16, 42)
                }
                full_refueling = (this.props.marker.full_refueling) ? 'Tak' : 'Nie';
                is_aggregate = (this.props.marker.is_aggregate) ? 'Tak' : 'Nie';
                break;
            case 'custom-waypoint':
                let customWaypointIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#ffffff" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg>';
                icon_out = {
                    url: 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(customWaypointIcon),
                    scaledSize: new google.maps.Size(20, 20)
                }
                break;

        }

        let subtype = '';
        if (this.props.marker.subtype !== null) {
            switch (this.props.marker.subtype) {
                case 'parking':
                    subtype = '[Parking]';
                    break;
                case 'ferry':
                    subtype = '[Prom]';
                    break;
                case 'start':
                    subtype = '[Początek trasy]';
                    break;
                case 'end':
                    subtype = '[Koniec trasy]';
                    break;
                case 'border':
                    subtype = '[Przejście graniczne]';
                    break;
                case 'point':
                    subtype = '[Punkt kontrolny]';
                    break;
            }
        }

        let eta_status_visited = (this.state.eta_status === 'Zrealizowane') ? true : false;

        // let icon_out = {

        //     path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
        //     fillColor: '#FF0000',
        //     fillOpacity: .6,
        //     anchor: new google.maps.Point(0, 0),
        //     strokeWeight: 0,
        //     scale: 1
        // }

        return <>
            {this.props.marker.type !== 'custom-waypoint' && <Marker
                key={this.props.id}
                position={this.props.position}
                icon={icon_out}


                onLoad={(e) => this.props.callbackOnLoadMarker(e)}

                // onClick = {(e) => this.props.callbackOnClickMarker(this.props.id, this.props.index, e)}
                onMouseOver={(e) => this.props.callbackOnMouseOverMarker(this.props.id, this.props.index, e)}
                onMouseOut={(e) => this.props.callbackOnMouseOutMarker(this.props.id, this.props.index, e)}

            />
            }

            {this.props.marker.type === 'custom-waypoint' && <Marker
                key={this.props.id}
                position={this.props.position}
                icon={icon_out}


                onLoad={(e) => this.props.callbackOnLoadMarker(e)}

            // onClick = {(e) => this.props.callbackOnClickMarker(this.props.id, this.props.index, e)}
            //onMouseOver={(e) => this.props.callbackOnMouseOverMarker(this.props.id, this.props.index, e)}
            //onMouseOut={(e) => this.props.callbackOnMouseOutMarker(this.props.id, this.props.index, e)}

            />
            }

            <InfoWindow
                key={this.props.id + 1}
                position={this.props.position}

                onLoad={(e) => this.props.callbackOnLoadInfoWindow(e)}
                onDomReady={(e) => this.props.callbackOnDomReadyInfoWindow(e)}
            >
                <div className={styles.scrollFixGMAP}>
                    <table width='100%' className={styles.tablePopupMyCustomMarker}>
                        <tbody>
                            <tr><td colSpan={2} className={`${styles.pop_other_header}`}>{this.props.index + 1} | {type} {subtype}</td></tr>
                            {this.props.marker.ts !== undefined && <tr><td className={styles.pop_other_left}>Czas zdarzenia:</td><td className={styles.pop_other_right}>{this.props.marker.ts}</td></tr>}

                            {this.state.eta_etime !== undefined && this.state.eta_etime !== null && !eta_status_visited && <tr><td className={styles.pop_other_left}>ETA - Szacowany czas:</td><td className={styles.pop_other_right}>{this.state.eta_etime}</td></tr>}
                            {this.state.eta_status !== undefined && this.state.eta_status !== null && <tr><td className={styles.pop_other_left}>ETA - Status:</td><td className={styles.pop_other_right}>{this.state.eta_status}</td></tr>}
                            {this.state.eta_distans !== undefined && this.state.eta_distans !== null && !eta_status_visited && <tr><td className={styles.pop_other_left}>ETA - Dystans:</td><td className={styles.pop_other_right}>{this.state.eta_distans}</td></tr>}
                            {this.state.eta_time !== undefined && this.state.eta_time !== null && !eta_status_visited && <tr><td className={styles.pop_other_left}>ETA - Czas:</td><td className={styles.pop_other_right}>{this.state.eta_time}</td></tr>}

                            {this.props.marker.amount_of_fuel !== undefined && <tr><td className={styles.pop_other_left}>Ilość paliwa:</td><td className={styles.pop_other_right}>{this.props.marker.amount_of_fuel} l</td></tr>}
                            {this.props.marker.fuel_type !== undefined && <tr><td className={styles.pop_other_left}>Typ paliwa:</td><td className={styles.pop_other_right}>{this.props.marker.fuel_type}</td></tr>}
                            {this.props.marker.price_of_fuel !== undefined && <tr><td className={styles.pop_other_left}>Cena paliwa:</td><td className={styles.pop_other_right}>{this.props.marker.price_of_fuel} zł</td></tr>}
                            {this.props.marker.cost_of_fuel !== undefined && <tr><td className={styles.pop_other_left}>Wartość zatankowanego paliwa:</td><td className={styles.pop_other_right}>{this.props.marker.cost_of_fuel} zł</td></tr>}
                            {this.props.marker.payment_method !== undefined && this.state.marker.payment_method !== '' && <tr><td className={styles.pop_other_left}>Metoda płatności:</td><td className={styles.pop_other_right}>{this.props.marker.payment_method}</td></tr>}
                            {this.props.marker.full_refueling !== undefined && <tr><td className={styles.pop_other_left}>Pełne tankowanie:</td><td className={styles.pop_other_right}>{full_refueling}</td></tr>}
                            {this.props.marker.is_aggregate !== undefined && <tr><td className={styles.pop_other_left}>Agregat:</td><td className={styles.pop_other_right}>{is_aggregate}</td></tr>}
                            {this.props.marker.address_display_name !== undefined && <tr><td className={styles.pop_other_left}>Miejsce zdarzenia:</td><td className={styles.pop_other_right}>{this.props.marker.address_display_name}</td></tr>}
                            {/* <tr><td className={styles.pop_other_left}>access_via_transport_docs:</td><td className={styles.pop_other_right}>{this.props.marker.access_via_transport_docs}</td></tr> */}
                            {this.props.marker.comment !== undefined && <tr><td className={styles.pop_other_left}>Opis:</td><td className={styles.pop_other_right}>{this.props.marker.comment}</td></tr>}
                        </tbody>
                    </table>
                </div>
            </InfoWindow>
        </>

    }

}

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const icons_refueling = importAll(require.context('../../img/icons', false, /\.png/));
const icons = importAll(require.context('../../img/routemarkers', false, /\.png/));