export const defualtTransportSet = {
  id: 447,
  label: "TESTowy",
  id_car: 201,
  car_name: "a a",
  id_trailer: null,
  trailer_type: null,
  trailer_name: null,
  id_driver1: 220,
  driver1_name: "a a",
  id_driver2: null,
  driver2_name: null,
  is_double_cast_of_drivers: false,
  is_dedicated: false,
  dedicated_contractor: null,
  dedicated_contractor_id: null,
  dedicated_ts_start: null,
  dedicated_ts_stop: null,
  car_profile: {
    id: 2,
    id_car: 201,
    bsoundenabled: 1,
    boperaterighthanded: 0,
    nvolumemin: 5,
    nvolumemax: 10,
    distanceunit: 1,
    clockformat: 0,
    gpsunits: 0,
    keyboardtype: 1,
    navoidtollroads: 1,
    bavoiduturns: 1,
    nplanningsettings: 1,
    nplanningsettingslimitedspeed: 1,
    bavoidferries: 1,
    bdisablemainmenu: 0,
    bdisablerecompute: 1,
    netamaximumspeed: 1,
    netapercentagechange: 1,
    bradarswarnon: 1,
    bradarsvisible: 1,
    nradardistance: 2,
    nradardistanceincity: 1,
    nskin: 2,
    ntimezone: 1,
    nspeedexceedincity: 1,
    nspeedexceed: 1,
    nview: 0,
    nsignpostdirection: 0,
    nsignpostsize: 0,
    bsnaptoeveryroad: 0,
    bmaxspeedwarn: 0,
    bttsenabled: 1,
    nvisiblepointreachdistance: 0,
    ninvisiblepointreachdistance: 0,
    ballowclosedroads: 0,
    btruckinmap: 1,
    busetruckatt: 0,
    ntruckmaxspeed: 90,
    ntruckweighttotal: 40000,
    ntruckweightaxle: 10000,
    ntrucktandemweight: 0,
    ntrucktridemweight: 0,
    ntruckotherweight: 0,
    ntruckunladenweight: 16500,
    ntrucklenght: 16500,
    ntruckaxlelength: 0,
    ntrailerlength: 13500,
    ntractorlength: 3000,
    nkingpinlastaxle: 0,
    nkingpinlasttandem: 0,
    nkingpinendtrailer: 0,
    ntruckotherlength: 0,
    ntruckwidth: 2600,
    ntruckheight: 4000,
    nloadrestrictions: 0,
    ballowitineraryedit: 1,
    h_type: "straight",
    h_axle_count: 3,
    h_trailer_count: 1,
    h_tunel_category: "",
    h_shipped_hazardous_goods: "",
    h_shipped_hazardous_goods_arr: [""],
    silnik: 2,
    rok_produkcji: "2017",
    emissions: 6,
    gigaliner_trucks: false,
    btrafficenabled: true,
  },
  linked_to_orders_num: 104,
  value: 447,
};
