import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import stylesMod from "../../styles/newOrEdit.module.css";
import Input from "../../components/input";
import { IconButton } from "@material-ui/core";
import { DBurl } from "../../appConfig";
import BTNstyles from "../../styles/button.module.css";
import { X, Smartphone } from "react-feather";
import { Switch, FormControlLabel } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress/";
import Box from "@material-ui/core/Box";

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#129865",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#129865",
  },
  "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
    backgroundColor: "#129865",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SetMobileApp(props) {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [allow_mobile, setAllow_mobile] = useState("");
  const [error, setError] = useState(false);
  const [circleLoader, setCircleLoader] = useState("none");

  useEffect(() => {
    setAllow_mobile(props.data.allow_mobile);
    setPhone(props.data.phone);
    setPassword("");
  }, [props.data.phone]);

  const setUsersMobile = async () => {
    const response = await fetch(`${DBurl}/setUsersMobile`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "update",
        data: {
          id_driver: props.data.id,
          phone: phone,
          mobile_password: password,
          allow_mobile: allow_mobile,
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.data.return === "duplicate") {
          props.setOpenSnack(true);
          props.setSnackContent(
            `Konto istnieje w systemie. Prosimy o wybranie innego loginu.`
          );
          props.setSnackColor("error");
          return;
        } else {
          if (data.data.return !== "duplicate") {
            setCircleLoader("open");
            props
              .fetchDrivers()
              .then((json) => {
                if (json.success) {
                  setCircleLoader("none");
                  setPassword("");
                } else {
                  props.setDialog({
                    isOpen: true,
                    type: "error",
                    content: `${json.responseDescription} // ${json.response}`,
                  });
                }
              })
              .then(() => {
                props.setDialog({
                  isOpen: true,
                  type: "success",
                  content: `Dane zapisane pomyślnie`,
                });
              });
          }
        }
      });
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth={true}
      open={props.open}
      TransitionComponent={Transition}
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        className={stylesMod.dialogHead}
      >
        Utwórz konto mobile
        <IconButton
          aria-label="close"
          className={stylesMod.closeButton}
          onClick={() => {
            props.setOpen(false);
          }}
        >
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent className={stylesMod.noOverflow}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <p>
              Kierowcy: {props.data.firstname} {props.data.surname}
            </p>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={stylesMod.centerWithM}>
          <Grid item xs={6}>
            <div className={`${stylesMod.inp}`}>
              <label>Numer telefonu</label>
              <Input
                value={phone === null ? "" : phone}
                error={!phone && error}
                handleChange={(e) => {
                  setPhone(e.target.value);
                }}
                name="phone"
                type="text"
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={`${stylesMod.inp}`}>
              <label>Hasło</label>
              <Input
                value={password === null ? "" : password}
                error={!password && error}
                handleChange={(e) => {
                  setPassword(e.target.value);
                }}
                name="password"
                type="password"
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={`${stylesMod.inp}`}>
              <label>Dostęp do aplikacji mobilnej</label>
              <FormControlLabel
                className={stylesMod.smallerFont}
                control={
                  <GreenSwitch
                    checked={allow_mobile}
                    color="primary"
                    onChange={(e) => {
                      setAllow_mobile(e.target.checked);
                    }}
                  />
                }
                label={allow_mobile === false ? "wyłączony" : "włączony"}
              />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="large"
          color="primary"
          className={`${BTNstyles.btn} ${BTNstyles.err}`}
          onClick={() => {
            props.setOpen(false);
          }}
        >
          Anuluj
        </Button>
        <Button
          variant="contained"
          size="large"
          className={`${BTNstyles.btn} ${BTNstyles.grn}`}
          onClick={(e) => {
            if (!!password && !!phone) {
              setUsersMobile();
            } else {
              setError(true);
              props.setDialog({
                isOpen: true,
                type: "error",
                content: "Wpisz hasło",
              });
            }
          }}
          color="primary"
        >
          Zapisz
          <Box className={circleLoader}>
            <CircularProgress color="success" className={BTNstyles.loader} />
            <Smartphone
              className={`${BTNstyles.downBtnIco} ${"button-icon"}`}
            />
          </Box>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
