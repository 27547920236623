import React, { Component } from "react";
import { DBurl } from "../../appConfig";
import { MyAutocomplete } from "./MyAutocomplete";
import styles from "../fleet/searchAndPlan.module.css";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import PinDropIcon from "@material-ui/icons/PinDrop";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";

export class SearchAndPlan extends Component {
  /**
   * constructor()
   *
   * Funkcja
   *
   */
  constructor(props) {
    super(props);

    const now = new Date();
    const month =
      now.getMonth() + 1 < 10 ? `0${now.getMonth() + 1}` : now.getMonth() + 1;
    const day = now.getDate() < 10 ? `0${now.getDate()}` : now.getDate();
    const hours = now.getHours() < 10 ? `0${now.getHours()}` : now.getHours();
    const minutes =
      now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes();
    const seconds =
      now.getSeconds() < 10 ? `0${now.getSeconds()}` : now.getSeconds();
    const defaultValue = `${now.getFullYear()}-${month}-${day}T${hours}:${minutes}:${seconds}`;

    const myAutocompleteItemStart = [];
    for (let i = 1, len = 2; i < len; i++) {
      myAutocompleteItemStart.push({
        title: `rows${i}`,
        text: "",
        place: null,
      });
    }

    this.state = {
      myAutocompleteItem: myAutocompleteItemStart,
      myAutocopleteTimeout: null,
      optionsAC: [],
      showModes: false,
      carsListMatrix: [],
      pointsListMatrix: [],
      dt_value: defaultValue,
      dt_radioValue: "1",
    };
  }

  /**
   * componentDidUpdate(prevProps)
   *
   * Funkcja
   *
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevState.carsListMatrix !== this.props.carsListMatrix) {
      this.setState({
        carsListMatrix: this.props.carsListMatrix,
      });
    }

    if (prevState.pointsListMatrix !== this.props.pointsListMatrix) {
      this.setState({
        pointsListMatrix: this.props.pointsListMatrix,
      });
    }
  }

  /**
   * onChangeMyAutocomplete(event, title)
   *
   * Funkcja
   *
   */
  onChangeMyAutocomplete = async (event, title) => {
    let newInputValue = event.currentTarget.value;

    this.state.myAutocompleteItem.forEach((item, index) => {
      if (item.title === title) {
        this.setState((state) => ({
          myAutocompleteItem: [
            ...state.myAutocompleteItem.slice(0, index),
            {
              ...state.myAutocompleteItem[index],
              title: title,
              text: newInputValue,
              place: null,
            },
            ...state.myAutocompleteItem.slice(index + 1),
          ],
        }));
      }
    });

    if (newInputValue !== "" && newInputValue.length > 1) {
      // Debounce
      return await this.debounce(newInputValue);
    }
  };

  /**
   * debounce()
   *
   * Funkcja
   *
   */
  debounce = (newInputValue) => {
    const that = this;

    // test for coordinates
    const latLonRegexp =
      /^[ \t\r\n]*([0-9]+[.,][0-9]+)[^0-9]{1,3}([0-9]+[.,][0-9]+)[ \t\r\n]*$/;
    // if (latLonRegexp.test(newInputValue)) {
    //     console.log('Współrzędne');
    // }
    // else {
    //     console.log('Fraza');
    // }

    var promise = new Promise(function (resolve, reject) {
      if (that.state.myAutocopleteTimeout) {
        clearTimeout(that.state.myAutocopleteTimeout);
      }
      let timeout = setTimeout(function () {
        resolve(that.search(newInputValue));
      }, 1000);
      that.setState({ myAutocopleteTimeout: timeout });
    });

    return promise;
  };

  /**
   * search(term)
   *
   * Funkcja
   *
   */
  search = async (term) => {
    const response = await fetch(`${DBurl}/search?term=${term}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.props.csrf_token,
      },
    });

    this.setState({
      optionsAC: [],
    });

    if (response.status === 200) {
      const json = await response.json();

      if (json.success && json.data !== null) {
        json.data.forEach((item, index) => {
          let label = item.display_name;

          let place = {
            location: { lat: parseFloat(item.lat), lng: parseFloat(item.lon) },
          };

          this.setState({
            optionsAC: [
              ...this.state.optionsAC,
              {
                label: label,
                id: index,
                place: place,
              },
            ],
          });
        });

        return this.state.optionsAC;
      }
    }
  };

  /**
   * onClickMyAutocomplete(selected, title)
   *
   * Funkcja
   *
   */
  onClickMyAutocomplete = (selected, title) => {
    this.state.myAutocompleteItem.forEach((item, index) => {
      if (item.title === title) {
        this.setState((state) => ({
          myAutocompleteItem: [
            ...state.myAutocompleteItem.slice(0, index),
            {
              ...state.myAutocompleteItem[index],
              title: title,
              text: selected.label,
              place: selected.place,
            },
            ...state.myAutocompleteItem.slice(index + 1),
          ],
        }));
      }
    });

    this.setState({
      showModes: true,
    });
  };

  /**
   * onClickFindClosestVehicle()
   *
   * Funkcja
   *
   */
  onClickFindClosestVehicle = () => {
    this.setState({
      carsListMatrix: [],
    });

    const lat = this.state.myAutocompleteItem[0].place.location.lat;
    const lng = this.state.myAutocompleteItem[0].place.location.lng;
    this.props.onClickFindClosestVehicleCallback(lat, lng);
  };

  /**
   * onClickFindClosestPoint()
   *
   * Funkcja
   *
   */
  onClickFindClosestPoint = () => {
    this.setState({
      pointsListMatrix: [],
    });

    const lat = this.state.myAutocompleteItem[0].place.location.lat;
    const lng = this.state.myAutocompleteItem[0].place.location.lng;
    this.props.onClickFindClosestPointCallback(
      lat,
      lng,
      this.state.dt_value,
      this.state.dt_radioValue
    );
  };

  /**
   * onChangeDT(event)
   *
   * Funkcja
   *
   */
  onChangeDT = (event) => {
    this.setState({
      dt_value: event.target.value,
    });
  };

  /**
   * onChangeRadioDT(event)
   *
   * Funkcja
   *
   */
  onChangeRadioDT = (event) => {
    this.setState({
      dt_radioValue: event.target.value,
    });
  };

  /**
   * render()
   *
   * Funkcja
   *
   */
  render() {
    return (
      <div className={styles.searchAndPlanContainer}>
        {this.state.myAutocompleteItem.map((item, index) => {
          return (
            <MyAutocomplete
              key={index}
              inputClass={styles.inputClass}
              inputValue={item.text}
              title={item.title}
              onChange={this.onChangeMyAutocomplete}
              onClick={this.onClickMyAutocomplete}
              placeholder="Wyszukaj punkt docelowy..."
            />
          );
        })}
        {this.state.showModes && (
          <Button
            startIcon={<DriveEtaIcon />}
            variant="contained"
            color="primary"
            className={styles.btn}
            onClick={this.onClickFindClosestVehicle}
          >
            Znajdź najbliższy pojazd{" "}
          </Button>
        )}
        {this.state.showModes && (
          <Button
            startIcon={<PinDropIcon />}
            variant="contained"
            color="primary"
            className={styles.btn}
            onClick={this.onClickFindClosestPoint}
          >
            Znajdź najbliższy punkt{" "}
          </Button>
        )}
        {this.state.showModes && (
          <TextField
            id="datetime-local"
            // label="Next appointment"
            type="datetime-local"
            value={this.state.dt_value}
            // defaultValue={defaultValue}
            onChange={this.onChangeDT}
            //className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        {this.state.showModes && (
          <RadioGroup
            row
            aria-label="dt_condition"
            name="dt_condition"
            className={styles.radioGroup}
            defaultValue="1"
            onChange={this.onChangeRadioDT}
          >
            <FormControlLabel
              value="1"
              control={<Radio className={styles.radio} color="primary" />}
              label={
                <span className={styles.formControlLabel}>przed i po</span>
              }
            />
            <FormControlLabel
              value="2"
              control={<Radio className={styles.radio} color="primary" />}
              label={
                <span className={styles.formControlLabel}>tylko przed</span>
              }
            />
            <FormControlLabel
              value="3"
              control={<Radio className={styles.radio} color="primary" />}
              label={<span className={styles.formControlLabel}>tylko po</span>}
            />
          </RadioGroup>
        )}

        <div className={styles.closestVehicleContainer}>
          <table className={styles.tableMatrix}>
            <thead>
              {this.state.carsListMatrix.length > 0 && (
                <tr>
                  <td></td>
                  <td style={{ width: "100px" }} align="left">
                    Pojazd
                  </td>
                  <td>Odległość do celu</td>
                  <td>Czas do celu</td>
                  <td>Czas w korku do celu</td>
                </tr>
              )}

              {this.state.pointsListMatrix.length > 0 && (
                <tr>
                  <td></td>
                  <td style={{ width: "100px" }} align="left">
                    Punkt
                  </td>
                  <td>Czas do celu</td>
                  <td>Czas w korku do celu</td>
                  <td>Odległość do celu</td>
                </tr>
              )}
            </thead>
            <tbody>
              {this.state.carsListMatrix.length > 0 &&
                this.state.carsListMatrix.map((car, index) => (
                  <tr key={index}>
                    <td>
                      <Tooltip
                        title="Wyznacz trasę do celu"
                        TransitionComponent={Fade}
                      >
                        <GpsFixedIcon
                          fontSize="small"
                          className={styles.iconBtnShowPoint}
                          onClick={() =>
                            this.props.onClickCarMatrixDoRouteCallback(car)
                          }
                        />
                      </Tooltip>
                    </td>
                    <td>
                      <p className={styles.header}>{car.carName}</p>
                    </td>
                    <td>{car.distance.toFixed(2)} km</td>
                    <td>{car.time}</td>
                    <td>{car.timeInTraffic}</td>
                  </tr>
                ))}

              {this.state.pointsListMatrix.length > 0 &&
                this.state.pointsListMatrix.map((point, index) => (
                  <tr key={index}>
                    <td>
                      <Tooltip
                        title="Wyznacz trasę do celu"
                        TransitionComponent={Fade}
                      >
                        <GpsFixedIcon
                          fontSize="small"
                          className={styles.iconBtnShowPoint}
                          onClick={() =>
                            this.props.onClickCarMatrixDoRouteCallback(point)
                          }
                        />
                      </Tooltip>
                    </td>
                    <td>
                      <p className={styles.header}>{point.pointName}</p>
                    </td>
                    <td>{point.time}</td>
                    <td>{point.timeInTraffic}</td>
                    <td>{point.distance.toFixed(2)} km</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
