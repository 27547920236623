import {COLORS, COLORS_STATUS} from './consts.helpers'


export const toggleShowAllTags = (event, newShowAllTags, setShowAllTags, setTagsToShow, allTags) => {
    event.preventDefault()
    event.stopPropagation()
    setShowAllTags(newShowAllTags)
    setTagsToShow(newShowAllTags ? [...allTags] : allTags.slice(0,1))
}

export const getAllTags = (tags) => tags.map(tag => tag?.name ? tag.name : '')

export const getBarColor = (number) => {
    const {GREEN, YELLOW, RED} = COLORS
    if (COLORS_STATUS.GREEN_STATUS.includes(number)) return GREEN;
    if (COLORS_STATUS.YELLOW_STATUS.includes(number)) return YELLOW;
    return RED
}