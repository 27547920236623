import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import BTNstyles from "../styles/button.module.css";
import stylesMod from "../styles/newOrEdit.module.css";
import { Save } from "react-feather";
import Input from "./input";
import { DBurl } from "../appConfig";
import { X } from "react-feather";
import { IconButton, Grid } from "@material-ui/core";
import Select from "react-select";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RatesPerKmMOdal = ({
  open,
  setOpen,
  setDialog,
  contractor,
  token,
  refresh,
  ratesPerKm,
  setSnack,
  setSnackContent,
  setSnackColor,
}) => {
  const [ratesPerKmState, setRatesPerKmState] = useState(ratesPerKm);

  const currencies = [
    { label: "PLN", value: "PLN" },
    { label: "EURO", value: "EUR" },
    { label: "CHF", value: "CHF" },
    { label: "GBP", value: "GBP" },
    { label: "CZK", value: "CZK" },
    { label: "UAH", value: "UAH" },
  ];

  const fetchRatesPerKm = async (id, rate, rate_id, currency) => {
    const data = !!rate_id
      ? {
          id: rate_id,
          id_contractor: id,
          rate_netto: Number(rate).toFixed(2),
          currency,
        }
      : {
          id_contractor: id,
          rate_netto: Number(rate).toFixed(2),
          currency,
        };

    const response = await fetch(`${DBurl}/setRatesPerKm`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify({
        mode: !!rate_id ? "update" : "insert",
        data: data,
      }),
    });
    const json = await response.json();
    if (json.success) {
      setDialog({
        isOpen: true,
        type: "success",
        content: "Stawka zapisana pomyślnie",
      });
      refresh();
      setOpen(false);
    } else {
      setDialog({
        isOpen: true,
        type: "error",
        content: `Błąd przy zapisie stawki: ${json.responseDescription} // ${json.response}  `,
      });
    }
  };

  useEffect(() => {
    setRatesPerKmState(ratesPerKm);
  }, [ratesPerKm]);

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Stawka za kilometr
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              setOpen(false);
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Stawka za km dla kontrahenta:
            {contractor.label || contractor.shortname}
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <div>
                  <Input
                    value={ratesPerKmState?.rate_netto}
                    handleChange={(e) => {
                      setRatesPerKmState({
                        ...ratesPerKmState,
                        rate_netto: e.target.value,
                      });
                    }}
                    type="number"
                  />
                </div>
              </Grid>
              <Grid item xs={2}>
                <div>
                  <Select
                    value={{
                      label: ratesPerKmState?.currency,
                      value: ratesPerKmState?.currency,
                    }}
                    className={`${stylesMod.select}`}
                    options={currencies}
                    onChange={(e) => {
                      setRatesPerKmState({
                        ...ratesPerKmState,
                        currency: e.value,
                      });
                    }}
                    menuPortalTarget={document.body}
                    name="currency"
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        border: "1px solid #142f42",
                        boxShadow: "none",
                        "&:hover": {
                          border: "1px solid #142f42",
                        },
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused && "#142f42",
                        color: state.isFocused && "#fff",
                      }),
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={`${BTNstyles.btn} ${BTNstyles.err}`}
            onClick={(e) => {
              setOpen(false);
            }}
          >
            Anuluj
          </Button>
          <Button
            variant="contained"
            size="large"
            className={`${BTNstyles.btn}`}
            onClick={() => {
              fetchRatesPerKm(
                contractor.value || contractor.id,
                ratesPerKmState.rate_netto,
                ratesPerKmState.id,
                ratesPerKmState.currency
              );
            }}
            color="primary"
          >
            Zapisz <Save className={BTNstyles.downBtnIco} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RatesPerKmMOdal;
