import React, { FC, ReactNode, SetStateAction } from "react";
import {
    Button, IconButton, Slide, Dialog,
    DialogActions, DialogContent, DialogTitle, Grid, Table, TableRow, TableCell,
} from '@material-ui/core'
import { X } from 'react-feather';
import Input from 'components/input'
import stylesMod from 'styles/newOrEdit.module.css'
import BTNstyles from 'styles/button.module.css'
import { Car } from '../../types'
import IcoBtn from "components/buttons/icoBtn";
import { DBurl } from "appConfig";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export type DataType = {
    id: number,
    id_fleet_cost_type: number,
    id_customer: number,
    id_firm: number,
    id_car: number,
    price: number,
    description: string
    quantity: number | null,
    start_date: string,
    end_date: string | null,
    is_fixed_cost: boolean | null,
    created_at: string,
    created_by: number,
    updated_at: string | null,
    updated_by: number | null,
    car: Car,
    fleet_cost_type: string,
    currency: string
}

export type DeleteRecordsType = {
    token: string;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setSnack: React.Dispatch<React.SetStateAction<boolean>>;
    setSnackContent: React.Dispatch<React.SetStateAction<string>>;
    setSnackColor: React.Dispatch<React.SetStateAction<string>>;
    data: DataType[];
    setRefetch: (value: SetStateAction<boolean>) => void
    setDeleteData: () => void
}

const DeleteRecords = ({ token, open, setOpen, setSnack, setSnackContent, setSnackColor, data, setRefetch, setDeleteData }: DeleteRecordsType) => {
    const handleClose = () => {
        setOpen(false)
    }
    const handleDelete = async function (id: number, index: number) {
        const response = await fetch(`${DBurl}/setFleetCosts/`, {
            method: "post",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": token,
            },
            body: JSON.stringify({
                mode: "delete",
                data: {
                    ids: [id],
                },
            }),
        });
        const json = await response.json();

        if (json.success) {
            setSnack(true);
            setSnackContent(`Dane zostały usunięte `);
            setSnackColor("success");
            setRefetch(true);
            //@ts-ignore
            data.splice(index, 1)
        } else {
            setSnack(true);
            setSnackContent(`Wystąpił błąd.`);
            setSnackColor("error");
        }
    };

    return (
        <Dialog
            maxWidth='md'
            fullWidth
            open={open}
            // @ts-ignore
            TransitionComponent={Transition}
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >

            <DialogTitle id="alert-dialog-slide-title" className={stylesMod.dialogHead}>Podsumowanie
                <IconButton aria-label="close" className={stylesMod.closeButton} onClick={handleClose}>
                    <X />
                </IconButton>
            </DialogTitle >
            <DialogContent>
                <div className="fleet-costs__delete-data-modal--title">
                    <div className="fleet-costs__delete-data-modal-cell--big">Rodzaj kosztu</div>
                    <div className="fleet-costs__delete-data-modal-cell--mid">Koszt</div>
                    <div className="fleet-costs__delete-data-modal-cell--small"></div>
                </div>
                {!data.length && <div>Brak wpisów</div>}
                {data.map((row, index) => {
                    return (
                        <div className="fleet-costs__delete-data-modal--content">
                            <div className="fleet-costs__delete-data-modal-cell--big">{row.fleet_cost_type} {row.description && `(${row.description})`}</div>
                            <div className="fleet-costs__delete-data-modal-cell--mid">{row.price} {row.currency}</div>
                            <div className="fleet-costs__delete-data-modal-cell--small">
                                <IcoBtn
                                    icon="X"
                                    tooltip="Usuń"
                                    className="btn-delete"
                                    value={row}
                                    onClick={() => {
                                        handleDelete(row.id, index)
                                    }}
                                />
                            </div>
                        </div>
                    )
                })}

            </DialogContent>
            <DialogActions>
                <Button variant="contained" size="large" className={`${BTNstyles.btn} ${BTNstyles.grn}`} onClick={() => {
                    handleClose()
                }}
                    color="primary">
                    Ok
                </Button>
            </DialogActions>

        </Dialog>

    )
}

export default DeleteRecords;