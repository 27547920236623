import React from "react";
import { Modal, Box } from "@material-ui/core";

import "./styles.sass";

const SettlementConfirmModal = ({
  confirmModal,
  setConfirmModal,
  handleConfirm,
}) => {
  const handleClose = () => {
    setConfirmModal({ isOpen: false });
  };

  return (
    <Modal open={confirmModal.isOpen} onClose={handleClose}>
      <Box className="confirm-modal">
        <div className="confirm-modal__title">
          <label>
            Na pewno chcesz potwierdzić raport za okres {confirmModal.date}?
          </label>
        </div>
        <div className="confirm-modal__buttons">
          <button className="confirm-modal__button" onClick={handleClose}>
            Anuluj
          </button>
          <button
            onClick={handleConfirm}
            className="confirm-modal__button --confirm"
          >
            Potwierdź
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default SettlementConfirmModal;
