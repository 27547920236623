import React, { useState, useEffect } from "react";
import styles from "../components/promotion.module.css";
import { Button, Slide, Dialog, makeStyles } from "@material-ui/core";
import { X } from "react-feather";
import { PromoSide } from "../appConfig";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  newPosOfDialog: {
    bottom: "0",
    height: "auto",
    display: "flex",
    position: "fixed",
  },
});

export default function PromotionBaner(props) {
  const classes = useStyles();
  const [promo_banner, setPromo_banner] = useState(
    props.isPromotionUser.promo_banner || false
  );
  const handleCloseBaner = (e) => {
    props.setOpenBaner(false);
    props.isPromotionUser.promo_banner = false;
  };

  const goToPromoSide = () => {
    const url = PromoSide;
    window.open(url, "_blank");
  };

  useEffect(() => {
    props.setOpenBaner(true);
    return () => props.setOpenBaner(false);
  }, []);

  return (
    props.isLoggedIn &&
    promo_banner && (
      <Dialog
        className={styles.baner}
        open={props.openBaner}
        onClose={handleCloseBaner}
        fullWidth
        maxWidth={false}
        hideBackdrop={true}
        classes={{
          paper: classes.newPosOfDialog,
        }}
      >
        <img
          onClick={(e) => goToPromoSide()}
          alt={"./img/banner_tms.jpg"}
          src={"./img/banner_tms.jpg"}
        ></img>
        <Button className={styles.close} onClick={handleCloseBaner}>
          <X />
        </Button>
      </Dialog>
    )
  );
}
