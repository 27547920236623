import React, { useState, useEffect } from 'react';
import Input from '../../components/input'
import styles from '../table.module.css'
import RowsPerPage from '../../components/RowsPerPageAlt'
import Pagination from '../../components/paginationAlt'
import { DBurl } from '../../appConfig'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Slide, Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import fuelCardsStyles from '../../styles/fuelCards.module.css'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const headCells = [
	{ id: 'fuelCardNum', numeric: true, disablePadding: false, label: 'Numer karty paliwowej', class: fuelCardsStyles.headCell },
	{ id: 'fuelStation', numeric: true, disablePadding: false, label: 'Stacja paliw' },
	{ id: 'transaction', numeric: true, disablePadding: false, label: 'Transakcja' },
];

const FuelCardsTransactionHistory = (props) => {

    const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('car');
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [table, setTable] = useState([])
	const [searchCard, setSearchCard] = useInput({ type: "text" });
	const [searchAddress, setSearchAddress] = useInput({ type: "text" });

	const [openDialog, setOpenDialog] = useState(false)
	const [dialogContent, setDialogContent] = useState("")
	const [dialogColor, setDialogColor] = useState("error")

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, table.length - page * rowsPerPage);
	const [stations, setStations] = useState('')


	function EnhancedTableHead(props) {
		const { styles, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

		return (
			<TableHead className={styles.tabHead}>
				<TableRow>
					{headCells.map((headCell) => {
						return (
							<TableCell
								key={headCell.id}
								align={headCell.align ? `${headCell.align}` : "left"}
								padding={headCell.disablePadding ? 'none' : 'default'}
								sortDirection={orderBy === headCell.id ? order : false}
								className={headCell.class}
							>
								{headCell.label}
							</TableCell>
						)
					})}
				</TableRow>
			</TableHead>
		);
	}

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenDialog(false)
	}

	const fetchHistory = async (token) => {
		props.props.loading(true)
		const response = await fetch(`${DBurl}/getE100Transactions`, {
			method: "GET",
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": token
			}
		});
		const json = await response.json()
		if (json.success) {
			setTable(json.data)
			props.props.loading(false)
		}
	}

	const fetchStations = async (token) => {
		props.props.loading(true)
		const response = await fetch(`${DBurl}/getE100GasStations?external_category_ids=2`, {
			method: "GET",
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-Token": token
			}
		});
		const json = await response.json()
		if (json.success) {
			setStations(json.data)
			props.props.loading(false)
		}
	}


	useEffect(() => {
		fetchHistory(props.props.user.csrf_token)
		fetchStations(props.props.user.csrf_token)
	}, [])

	const paginate = pageNumber => setPage(pageNumber - 1);
	const isSelected = (name) => selected.indexOf(name) !== -1;

	function useInput({ type }) {
		const [searchValue, setSearchValue] = useState("")
		const input = <Input value={searchValue} handleChange={(e) => {
			setSearchValue(e.target.value)
		}
		} type={type}
			search={true}
			id={'search'} autocomplete={false} />;
		return [searchValue, input];
	}

	const search = (tbl) => {
		return tbl.filter((row) => {
			return String(row.card).toLowerCase().indexOf(searchCard.toLowerCase()) > -1 &&
				String(row.address).toLowerCase().indexOf(searchAddress.toLowerCase()) > -1
		})
	}

    return (
            <div className={fuelCardsStyles.transactionHistory}>
                <div className={fuelCardsStyles.header}>Historia transakcji</div>
                <TableContainer className={styles.stickyDoubleTableAlt}>
					<Table
						stickyHeader={true}
                        className={`${styles.table} ${fuelCardsStyles.narrow}`}
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            styles={styles}
                            order={order}
                            orderBy={orderBy}
                            rowCount={table.length}
                        />
                        <TableBody className={styles.tabBody}>
							<TableRow>
								<TableCell  className={fuelCardsStyles.headCell}>{setSearchCard}</TableCell>
								<TableCell>{setSearchAddress}</TableCell>
								<TableCell></TableCell>
							</TableRow>
                            {search(table)
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (<>
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.id}
                                        >
                                            <TableCell className={fuelCardsStyles.headCell} align="left">{row.card}</TableCell>
                                            <TableCell align="left">{row.address}</TableCell>
                                            <TableCell align="left">{Number(row?.sum)?.toFixed(2)} {row.currency}</TableCell>
                                        </TableRow>
                                    </>
                                    );
                                })}
                                {emptyRows > 0 && (
									<TableRow style={{ height: 21 * emptyRows }}>
										<TableCell colSpan={3} />
									</TableRow>
								)}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={styles.tableBottom} >
						<RowsPerPage
							rowsPerPage={rowsPerPage}
							setRowsPerPage={setRowsPerPage}
							setPage={setPage}
							tableLength={search(table).length}
						/>

						<div>
							<Pagination
								postsPerPage={rowsPerPage}
								totalPosts={search(table).length}
								paginate={paginate}
								page={page}
							/>
						</div>
                </div>
			<Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
				<Alert onClose={handleClose} severity={dialogColor}>
					{dialogContent}
				</Alert>
			</Snackbar>
        </div>
    )
}

export default FuelCardsTransactionHistory;