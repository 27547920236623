import React from "react";
import {
  LogIn,
  Copy,
  Edit2,
  Info,
  File,
  Printer,
  X,
  Search,
  Plus,
  MessageCircle,
  MapPin,
  AlertCircle,
  Lock,
  Link,
  Smartphone,
  User,
  Tool,
  Download,
  RefreshCcw,
  Mail,
  Settings,
  DollarSign,
  PauseCircle,
  FileText,
  Filter,
  Unlock,
  Check,
  Save,
  Trash2,
} from "react-feather";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import styles from "./btnStyles.module.css";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

export default function IcoBtn(props) {
  const icon = (() => {
    switch (props.icon) {
      case "Download":
        return <Download />;
      case "LogIn":
        return <LogIn />;
      case "Copy":
        return <Copy />;
      case "Edit":
        return <Edit2 />;
      case "File":
        return <File />;
      case "Printer":
        return <Printer />;
      case "Info":
        return <Info />;
      case "Search":
        return <Search />;
      case "X":
        return <X />;
      case "Chat":
        return <MessageCircle />;
      case "Pin":
        return <MapPin />;
      case "Plus":
        return <Plus />;
      case "Lock":
        return <Lock />;
      case "Link":
        return <Link />;
      case "Car":
        return <LocalShippingIcon />;
      case "Phone":
        return <Smartphone />;
      case "User":
        return <User />;
      case "Tool":
        return <Tool />;
      case "Refresh":
        return <RefreshCcw />;
      case "Mail":
        return <Mail />;
      case "Settings":
        return <Settings />;
      case "DollarSign":
        return <DollarSign />;
      case "Pause":
        return <PauseCircle />;
      case "FileText":
        return <FileText />;
      case "Filter":
        return <Filter />;
      case "Unlock":
        return <Unlock />;
      case "Check":
        return <Check />;
      case "Save":
        return <Save />;
      case "Trash":
        return <Trash2 />;
      default:
        return <AlertCircle />;
    }
  })();
  const iconStyle = (() => {
    switch (props.icon) {
      case "X":
        return `${styles.btn} ${styles.err} ${props.className}`;
      case "Trash":
        return `${styles.btn} ${styles.err} ${props.className}`;
      case "Car":
        return `${styles.btn} ${styles.car} ${props.className}`;
      case "Check":
        return `${styles.btn} ${styles.check} ${props.className}`;
      case "Save":
        return `${styles.btn} ${styles.check} ${props.className}`;
      default:
        return `${styles.btn} ${styles.primary} ${props.className}`;
    }
  })();

  return (
    <Tooltip title={props.tooltip} disableHoverListener={!props.tooltip}>
      <IconButton
        onClick={props.handleChange || props.onClick}
        value={props.value}
        className={iconStyle}
        disabled={props.disabled}
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
}
