import React from "react";
import styles from "../../styles/chat.module.css";
import { DBurl } from "../../appConfig";
import { User } from "react-feather";
import { IconButton } from "@material-ui/core";
import IcoBtn from "../../components/buttons/icoBtn";
import chatStyles from "../../styles/chat.module.css";

export default function Chat({ messages, driver }) {
  const chatBubbles = messages.map((obj, i = 0) => (
    <div
      className={`${styles.bubbleContainer} ${
        obj.send_from_mobile ? styles.left : styles.right
      }`}
      key={i}
    >
      <div className={styles.headContainer}>
        <IconButton
          onClick={(e) => {
            console.log(e);
          }}
          className={`${styles.icon} ${
            obj.send_from_mobile ? styles.me : styles.you
          }`}
        >
          <User />
        </IconButton>
        <div className={styles.headText}>
          {obj.send_from_mobile ? driver : obj.name_user}{" "}
          <span>{obj.ts?.slice(0, 19)}</span>
        </div>
      </div>

      <div key={i++} className={styles.bubble}>
        <div className={styles.chatText}>
          {obj.msg.startsWith("Wysłano nowy plik") && obj.msg.length > 30 ? (
            <span>
              Wysłano nowy plik.
              <IcoBtn
                icon="Download"
                className={chatStyles.listBtn}
                tooltip={"Pobierz"}
                onClick={() => {
                  window.open(
                    `${DBurl}/getFile?filename=${
                      obj.msg.split("=")[1].split("]")[0]
                    }`,
                    "_blank"
                  );
                }}
              />
            </span>
          ) : (
            <div>{obj.msg}</div>
          )}
        </div>
      </div>
    </div>
  ));
  return <div className={styles.container}>{chatBubbles}</div>;
}
