import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import Tooltip from "@material-ui/core/Tooltip";
import { XCircle, PlusCircle, Download, Upload, Plus } from "react-feather";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Input from "components/input";
import stylesVersion from "styles/version_s.module.css";
import stylesMod from "styles/newOrEdit.module.css";

const currencyOption = [
  { label: "PLN", value: "PLN" },
  { label: "EURO", value: "EUR" },
  { label: "CHF", value: "CHF" },
  { label: "FUNT", value: "GBP" },
  { label: "KORONA", value: "CZK" },
  { label: "RUBEL", value: "RUB" },
  { label: "HRYWNA", value: "UAH" },
];

const selectStyles = {
  control: (base, state) => ({
    ...base,
    border: "1px solid #142f42",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #142f42",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused && "#142f42",
    color: state.isFocused && "#fff",
  }),
};

const CargoItem = ({
  addTab,
  updateValue,
  cargoItem,
  addCargo,
  removeCargo,
  checkedGoods,
  error,
  goodsOptions,
  index,
  saveTab,
  unitOption,
  user_s,
  cargoLength,
  isPreview,
}) => {
  const [cargoState, setCargoState] = useState({});

  const updateCargoValue = (name, value) => {
    updateValue(`cargo[${index}].${name}`, value);
  };

  const updateCargoValues = (values) => {
    updateValue(`cargo[${index}]`, values);
  };

  const updateCargo = (values) => {
    setCargoState({ ...cargoState, ...values });
  };

  const updateOnBlur = () => {
    updateCargoValues(cargoState);
  };

  useEffect(() => {
    setCargoState(cargoItem);
  }, [cargoItem]);

  const ValueContainer = ({
    children,
    selectProps,
    secSelectProps,
    ...props
  }) => {
    return (
      <components.ValueContainer {...props}>
        {/* <div className={stylesMod.clientAddParent}>
          <IconButton
            className={stylesMod.btnIcon}
            onClick={async () => {
              selectProps.selectProps.addtab(
                "Goods",
                false,
                selectProps.selectProps.saveTab()
              );
            }}
          >
            <Plus className={stylesMod.clientAdd} />
          </IconButton>
        </div> */}
        {children}
      </components.ValueContainer>
    );
  };

  return (
    <>
      <Grid item xs={12} className={stylesMod.labels}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
              <div className={stylesMod.inp}>
                <Select
                  isDisabled={user_s || checkedGoods === false || isPreview}
                  value={cargoItem}
                  className={`${stylesMod.select} ${
                    error && !cargoItem.name && stylesMod.error
                  } ${
                    user_s || checkedGoods === false
                      ? stylesVersion.onlyfull
                      : ""
                  }`}
                  options={goodsOptions}
                  name="name"
                  noOptionsMessage={() => "Brak towaru do wyboru"}
                  placeholder="Wybierz..."
                  components={{ ValueContainer }}
                  selectProps={{ addtab: addTab, saveTab: saveTab }}
                  onChange={(e) => {
                    updateValue(`cargo[${index}]`, {
                      ...cargoItem,
                      ...e,
                      unit: unitOption?.filter(
                        (unit) => unit.label === e.unit
                      )[0],
                    });
                  }}
                  styles={selectStyles}
                />
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={1}>
            <div style={{ display: "flex", gap: "6px", alignItems: "center" }}>
              <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
                <div style={{ width: "60%" }} className={stylesMod.inp}>
                  <Input
                    className={`${
                      user_s || checkedGoods === false
                        ? stylesVersion.onlyfull
                        : ""
                    } `}
                    disabled={
                      user_s ||
                      checkedGoods === false ||
                      !cargoItem.id ||
                      isPreview
                    }
                    value={cargoState.maxQuantity}
                    handleChange={(e) => {
                      updateCargo({
                        maxQuantity: e.target.value,
                      });
                    }}
                    name="maxQuantity"
                    type="number"
                    onBlur={updateOnBlur}
                    error={error & !cargoItem.maxQuantity}
                  />
                </div>
              </Tooltip>
              <div className={stylesMod.iconsCol}>
                <div
                  style={{ gap: "4px", gridGap: "4px" }}
                  className={stylesMod.iconsRow}
                >
                  <div className={stylesMod.statusIcon}>
                    <Download
                      disabled={user_s || checkedGoods === false}
                      className={`${
                        cargoItem.statusLoad
                          ? stylesMod.doneLoading
                          : stylesMod.undone
                      }  ${
                        user_s || checkedGoods === false
                          ? stylesVersion.onlyfull
                          : ""
                      }`}
                      style={{ margin: 0, width: "16px" }}
                      size={18}
                    />
                    <div
                      style={{ fontSize: "11px" }}
                      className={stylesMod.statusNumber}
                    >
                      {cargoItem.quantityLoad && cargoItem.quantityLoad}
                    </div>
                  </div>
                  <div className={stylesMod.statusIcon}>
                    <Upload
                      disabled={user_s || checkedGoods === false}
                      className={`${
                        cargoItem.statusUnload
                          ? stylesMod.doneUnloading
                          : stylesMod.undone
                      }  ${
                        user_s || checkedGoods === false
                          ? stylesVersion.onlyfull
                          : ""
                      }`}
                      style={{ margin: 0, width: "16px" }}
                      size={18}
                    />
                    <div
                      style={{ fontSize: "11px" }}
                      className={stylesMod.statusNumber}
                    >
                      {cargoItem.quantityUnload && cargoItem.quantityUnload}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
              <div className={stylesMod.inp}>
                <Select
                  isDisabled={
                    user_s ||
                    checkedGoods === false ||
                    !cargoItem.id ||
                    true ||
                    isPreview
                  }
                  value={cargoItem.unit}
                  className={`${stylesMod.select} ${
                    user_s || checkedGoods === false
                      ? stylesVersion.onlyfull
                      : ""
                  }`}
                  options={unitOption}
                  name="unit"
                  placeholder="Wybierz..."
                  components={{ ValueContainer }}
                  selectProps={{ addtab: addTab, saveTab: saveTab }}
                  onChange={(e) => {
                    updateCargoValue("unit", e);
                  }}
                  styles={selectStyles}
                />
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
              <div className={stylesMod.inp}>
                <Input
                  className={`${
                    user_s || checkedGoods === false
                      ? stylesVersion.onlyfull
                      : ""
                  }`}
                  disabled={
                    user_s ||
                    checkedGoods === false ||
                    !cargoItem.id ||
                    isPreview
                  }
                  value={cargoState.price}
                  handleChange={(e) => {
                    updateCargo({ price: e.target.value });
                  }}
                  onBlur={updateOnBlur}
                  name="price"
                  type="number"
                />
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
              <div className={stylesMod.inp}>
                <Select
                  isDisabled={
                    user_s ||
                    checkedGoods === false ||
                    !cargoItem.id ||
                    isPreview
                  }
                  className={`${stylesMod.select} ${
                    user_s || checkedGoods === false
                      ? stylesVersion.onlyfull
                      : ""
                  }`}
                  options={currencyOption}
                  onChange={(e) => {
                    updateCargoValue("currency", e);
                  }}
                  name="currency"
                  placeholder="Wybierz..."
                  value={cargoItem.currency}
                  styles={selectStyles}
                />
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
              <div className={stylesMod.inp}>
                <Input
                  className={`${
                    user_s || checkedGoods === false
                      ? stylesVersion.onlyfull
                      : ""
                  }`}
                  disabled={
                    user_s ||
                    checkedGoods === false ||
                    !cargoItem.id ||
                    isPreview
                  }
                  value={cargoState.details}
                  handleChange={(e) => {
                    updateCargo({ details: e.target.value });
                  }}
                  onBlur={updateOnBlur}
                  name="details"
                />
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
              <div className={stylesMod.inp}>
                <Input
                  className={`${
                    user_s || checkedGoods === false
                      ? stylesVersion.onlyfull
                      : ""
                  }`}
                  disabled={
                    user_s ||
                    checkedGoods === false ||
                    !cargoItem.id ||
                    isPreview
                  }
                  value={cargoState.ldmSize}
                  handleChange={(e) => {
                    updateCargo({ ldmSize: e.target.value });
                  }}
                  onBlur={updateOnBlur}
                  name="ldmSize"
                  type="number"
                />
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
              <div className={stylesMod.inp}>
                <Input
                  className={`${
                    user_s || checkedGoods === false
                      ? stylesVersion.onlyfull
                      : ""
                  }`}
                  disabled={
                    user_s ||
                    checkedGoods === false ||
                    !cargoItem.id ||
                    isPreview
                  }
                  value={cargoState.ADRClass}
                  handleChange={(e) => {
                    updateCargo({ ADRClass: e.target.value });
                  }}
                  onBlur={updateOnBlur}
                  name="ADRClass"
                  type="number"
                />
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
              <div className={stylesMod.inp}>
                <Input
                  className={`${
                    user_s || checkedGoods === false
                      ? stylesVersion.onlyfull
                      : ""
                  }`}
                  disabled={
                    user_s ||
                    checkedGoods === false ||
                    !cargoItem.id ||
                    isPreview
                  }
                  value={cargoState.NumRef}
                  handleChange={(e) => {
                    updateCargo({ NumRef: e.target.value });
                  }}
                  onBlur={updateOnBlur}
                  maxlength="60"
                />
              </div>
            </Tooltip>
          </Grid>
          <Grid item className={stylesMod.dense} xs={1}>
            <div style={{ display: "flex", gap: 25 }}>
              <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
                <input
                  disabled={
                    user_s ||
                    checkedGoods === false ||
                    !cargoState.id ||
                    isPreview
                  }
                  type="checkbox"
                  className={`${stylesMod.check} ${
                    user_s || checkedGoods === false
                      ? stylesVersion.onlyfull
                      : ""
                  }`}
                  value={cargoItem?.ADR || false}
                  onChange={(e) => {
                    updateCargoValue("ADR", e.target.checked);
                  }}
                  name="ADR"
                  checked={cargoItem?.ADR || false}
                />
              </Tooltip>
              <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
                <input
                  type="checkbox"
                  disabled={
                    user_s ||
                    checkedGoods === false ||
                    !cargoState.id ||
                    isPreview
                  }
                  className={`${stylesMod.check} ${
                    user_s || checkedGoods === false
                      ? stylesVersion.onlyfull
                      : ""
                  }`}
                  value={cargoItem?.palletExchange || false}
                  onChange={(e) => {
                    updateCargoValue("palletExchange", e.target.checked);
                  }}
                  name="palletExchange"
                  checked={cargoItem?.palletExchange || false}
                />
              </Tooltip>
            </div>
          </Grid>
          <Grid item xs={1} className={stylesMod.flexC}>
            <Tooltip title={user_s ? "Dostępne tylko w wersji pełnej" : ""}>
              <div className={stylesMod.iconsBox}>
                <div>
                  <IconButton
                    disabled={
                      user_s ||
                      checkedGoods === false ||
                      isPreview ||
                      cargoLength < 1
                    }
                    className={`${stylesMod.btnIcon} ${
                      user_s || checkedGoods === false
                        ? stylesVersion.onlyfull
                        : ""
                    }`}
                    value={index}
                    onClick={() => {
                      removeCargo(index);
                    }}
                  >
                    {cargoLength > 1 && (
                      <XCircle className={stylesMod.remove} size={18} />
                    )}
                  </IconButton>

                  <IconButton
                    disabled={user_s || checkedGoods === false || isPreview}
                    className={`${stylesMod.btnIcon} ${
                      user_s || checkedGoods === false
                        ? stylesVersion.onlyfull
                        : ""
                    }`}
                    onClick={addCargo}
                    value={index}
                  >
                    <PlusCircle className={stylesMod.add} size={18} />
                  </IconButton>
                </div>
              </div>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CargoItem;
