import React from 'react';
import styles from './map.module.css';



export class MyAutocomplete extends React.Component {

    /**
    * constructor()
    *
    * Funkcja 
    *
    */
    constructor(props) {
        super(props);
        this.state = {
            filteredSuggestions: [],
            showSuggestions: false,
            activeSuggestion: 0
        }

    }

    /**
     * onKeyDown()
     *
     * Funkcja 
     *
     */
    onKeyDown = (e) => {
        const { activeSuggestion, filteredSuggestions } = this.state;

        // User pressed the enter key
        if (e.keyCode === 13) {
            this.setState({
                activeSuggestion: 0,
                showSuggestions: false,
                userInput: filteredSuggestions[activeSuggestion]
            });
        }
        // User pressed the up arrow
        else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }

            this.setState({ activeSuggestion: activeSuggestion - 1 });
        }
        // User pressed the down arrow
        else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }

            this.setState({ activeSuggestion: activeSuggestion + 1 });
        }
    };

    /**
     * onChange(e, title)
     *
     * Funkcja 
     *
     */
    onChange = async (e, title) => {
        const suggestion = await this.props.onChange(e, title);
        if(suggestion){
            this.setState({
                filteredSuggestions: suggestion,
                showSuggestions: true
            });
        }
    }

    /**
     * onClick(selected)
     *
     * Funkcja 
     *
     */
    onClick = (selected, title) => {
        this.setState({
            showSuggestions: false
        });
        
        if(this.props.onClick !== undefined){
            // Przysłonięcie metody ???
            this.props.onClick(selected, title);
        }
        else {
            // Domyślna metoda komponentu ???
        }
        
    }


    /**
     * render()
     *
     * Funkcja 
     *
     */
    render() {

        let suggestionsListComponent;

        if (this.state.showSuggestions && this.props.inputValue) {
            if (this.state.filteredSuggestions.length) {
                suggestionsListComponent = (
                    <ul className={styles.suggestions}>
                        {this.state.filteredSuggestions.map((suggestion, index) => {
                            let className;

                            //Flag the active suggestion with a class
                            if (index === this.state.activeSuggestion) {
                                className = styles.suggestionActive;
                            }

                            return (
                                <li className={className} key={index} onClick={() => this.onClick(suggestion, this.props.title)}>
                                    <div><div className={styles.pacIcon}></div>{suggestion.label}</div>
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {
                suggestionsListComponent = (
                    <div className={styles.noSuggestions}>
                        <em>Brak wyników</em>
                    </div>
                );
            }
        }

        return (
            <React.Fragment>
                <div>
                    <input
                        className={this.props.inputClass}
                        type="text"
                        onChange={(e) => this.onChange(e, this.props.title)}
                        onKeyDown={this.onKeyDown}
                        value={this.props.inputValue}
                        placeholder={this.props.placeholder}
                    />
                    {suggestionsListComponent}
                </div>
            </React.Fragment>
        );
    }

}