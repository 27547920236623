import React from 'react'

export const whatOptions = [
    {label: 'Pojazdy', value: 'cars'},
    {label: 'Naczepy', value: 'trailers'},
    {label: 'Kierowcy', value: 'drivers'},
    {label: 'Firma', value: 'firm'}
    ]

export const rangeOptions = [
    {label: 'Do 31 dni', value: 1},
    {label: '31-60 dni', value: 2},
    {label: '61-90 dni', value: 3}
    ]

export const renderCategory = (row) => {
    if (row.what === 'cars') return 'Pojazdy'
    if (row.what === 'trailers') return 'Naczepy'
    if (row.what === 'drivers') return 'Kierowcy'
    if (row.what === 'firm') return 'Firma'
    return <div><b>{row.what}</b></div>
}