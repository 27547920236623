import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import styles from '../containers/table.module.css'
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import TachoIcon from './tachoIcons';
import IconButton from '@material-ui/core/IconButton';
import stylesMod from '../styles/newOrEdit.module.css'
import { X } from 'react-feather';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function SelectorsDialog(props) {
	return (
		<Dialog
			maxWidth='lg'
			open={props.open}
			TransitionComponent={Transition}
			onClose={() => {
				props.setOpen(false)
			}}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
		>
			<DialogTitle id="alert-dialog-slide-title" className={stylesMod.dialogHead}>Podsumowanie 			<IconButton aria-label="close" className={stylesMod.closeButton} onClick={() => {
				props.setOpen(false)
			}}>
				<X />
			</IconButton>
			</DialogTitle >

			<DialogContent id="alert-dialog-slide-description" className={styles.dialog}>
				<Grid container spacing={2} className={styles.cont}>
					<Grid item xs={4}>
						<h3>Wczoraj</h3>
						<div className={styles.tachoiconsParent}>
							<div>
								<Tooltip disableHoverListener={false} title="Jazda">
									<IconButton className={styles.smallIcons}>
										<TachoIcon icon="IcoDrive" />
									</IconButton>
								</Tooltip>
								{props.data.yesterday.drivingTime}

							</div>
							<div>
								<Tooltip disableHoverListener={false} title="Odpoczynek">
									<IconButton className={styles.smallIcons}>
										<TachoIcon icon="IcoStop" />
									</IconButton>
								</Tooltip>
								{props.data.yesterday.stopTime}
							</div>
							<div>
								<Tooltip disableHoverListener={false} title="Dyspozycyjność">
									<IconButton className={styles.smallIcons}>
										<TachoIcon icon="IcoAvailability" />
									</IconButton>
								</Tooltip>
								{props.data.yesterday.stopEngineOnTime}
							</div>
							<div>
								<Tooltip disableHoverListener={false} title="Praca">
									<IconButton className={styles.smallIcons}>
										<TachoIcon icon="IcoWork" />
									</IconButton>
								</Tooltip>
								{props.data.yesterday.workTime}
							</div>
						</div>
					</Grid>

					<Grid item xs={4}>
						<h3>Dzisiaj</h3>
						<div className={styles.tachoiconsParent}>
							<div>
								<Tooltip disableHoverListener={false} title="Jazda">
									<IconButton className={styles.smallIcons}>
										<TachoIcon icon="IcoDrive" />
									</IconButton>
								</Tooltip>
								{props.data.today.drivingTime}

							</div>
							<div>
								<Tooltip disableHoverListener={false} title="Odpoczynek">
									<IconButton className={styles.smallIcons}>
										<TachoIcon icon="IcoStop" />
									</IconButton>
								</Tooltip>
								{props.data.today.stopTime}
							</div>
							<div>
								<Tooltip disableHoverListener={false} title="Dyspozycyjność">
									<IconButton className={styles.smallIcons}>
										<TachoIcon icon="IcoAvailability" />
									</IconButton>
								</Tooltip>
								{props.data.today.stopEngineOnTime}
							</div>
							<div>
								<Tooltip disableHoverListener={false} title="Praca">
									<IconButton className={styles.smallIcons}>
										<TachoIcon icon="IcoWork" />
									</IconButton>
								</Tooltip>
								{props.data.today.workTime}
							</div>
						</div>
					</Grid>

					<Grid item xs={4}>
						<h3>Suma</h3>
						<div className={styles.tachoiconsParent}>
							<div>
								<Tooltip disableHoverListener={false} title="Jazda">
									<IconButton className={styles.smallIcons}>
										<TachoIcon icon="IcoDrive" />
									</IconButton>
								</Tooltip>
								{props.data.summary.drivingTime}

							</div>
							<div>
								<Tooltip disableHoverListener={false} title="Odpoczynek">
									<IconButton className={styles.smallIcons}>
										<TachoIcon icon="IcoStop" />
									</IconButton>
								</Tooltip>
								{props.data.summary.stopTime}
							</div>
							<div>
								<Tooltip disableHoverListener={false} title="Dyspozycyjność">
									<IconButton className={styles.smallIcons}>
										<TachoIcon icon="IcoAvailability" />
									</IconButton>
								</Tooltip>
								{props.data.summary.stopEngineOnTime}
							</div>
							<div>
								<Tooltip disableHoverListener={false} title="Praca">
									<IconButton className={styles.smallIcons}>
										<TachoIcon icon="IcoWork" />
									</IconButton>
								</Tooltip>
								{props.data.summary.workTime}
							</div>
						</div>
					</Grid>
				</Grid>



			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={() => {
					props.setOpen(false)
				}} color="primary">
					Zamknij
				</Button>
			</DialogActions>

		</Dialog >
	)
}
