import React, { useState, useEffect } from "react";
import Title from "../../components/title";
import Input from "../../components/input";
import styles from "../table.module.css";
import Select from "react-select";
import InvoiceProducts from "./invoiceProducts";
import InvoiceProductsCorrection from "./invoiceProductsCorrection";
import InvoiceProductsText from "./invoiceProductsText";
import { DBurl } from "../../appConfig";

import BTNstyles from "../../styles/button.module.css";
import { Check, X } from "react-feather";
import Button from "@material-ui/core/Button";

import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

export default function InvoiceAdd(props) {
  const data = props.data || props.state || {};

  // Sprzedawca
  const [department, setDepartment] = useState(data.department || "");
  const [departmentOptions, setDepartmentOptions] = useState([{}]);

  // kontrahent
  const [client, setClient] = useState(
    data.client || { value: false, label: "Wybierz kontrahenta" }
  );
  const [clientOptions, setClientOptions] = useState([]);
  const [clientDepartment, setClientDepartment] = useState({});
  const [clientDepartmentOptions, setClientDepartmentOptions] = useState({});

  // Nagłówek
  const [type, setType] = useState(
    data.type || { label: "Faktura Vat", value: 1 }
  );
  const typeOptions = [
    { value: 1, label: "Faktura VAT" },
    { value: 2, label: "Faktura Proforma" },
    // {value:3, label:'Korekta Faktura'},
    { value: 4, label: "Szablon faktury" },
  ];

  const [date_of_issue, setDate_of_issue] = useState(data.date_of_issue || "");
  const [date_service, setDate_service] = useState(data.date_service || "");
  const [invoice_no, setInvoice_no] = useState(data.invoice_no || "");
  // Płatności

  const [payment_method, setPayment_method] = useState(
    data.payment_method || ""
  );
  const payment_methodOptions = [
    { value: "Przelew", label: "Przelew" },
    { value: "Gotówka", label: "Gotówka" },
    { value: "Karta", label: "Karta" },
  ];
  const [payment_deadline, setPayment_deadline] = useState(
    data.payment_deadline || ""
  );
  const [advance, setAdvance] = useState(data.advance || "");
  // Wydruki
  const [place_of_issue, setPlace_of_issue] = useState(
    data.place_of_issue || ""
  );
  const [description, setDescription] = useState(data.description || "");
  const [signature, setSignature] = useState(data.signature || "");
  // Usługi
  const [services, setServices] = useState(
    data.services || [
      {
        id: 1,
        name: "",
        order: null,
        quantity: 1,
        vat: 0,
        price_netto: 0,
        value_netto: 0,
        value_brutto: 0,
      },
    ]
  );
  const [currency, setCurrency] = useState(data.currency || "PLN");
  const [nettoSum, setNettoSum] = useState(data.nettoSum || 0);
  const [bruttoSum, setBruttoSum] = useState(data.bruttoSum || 0);

  const [openSnack, setOpenSnack] = useState(false);
  const [snackContent, setSnackContent] = useState("");

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");

  const [ordersOptions, setOrdersOptions] = useState([{ label: "-" }]);
  const [servicesOptions, setServicesOptions] = useState([]);
  const [vatOptions, setVatOptions] = useState([]);

  // Korekta
  const [date_of_correction, set_date_of_correction] = useState(null);
  const [correction_no, set_correction_no] = useState(null);
  const [correction_invoice_id, set_correction_invoice_id] = useState(null);
  const [correction_title, set_correction_title] = useState(null);
  const [correction_services, setCorrection_services] = useState([]);

  const getAllOrders = async () => {
    const response = await fetch(`${DBurl}/getOrders/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      const result = json.data.map((e) => {
        return {
          label: e.order_no,
          value: e.id,
          price_netto: e.order_price,
          selName: "order",

          ...e,
        };
      });
      return result;
    }
  };

  const getOrders = async () => {
    const response = await fetch(`${DBurl}/getOrders/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      if (!!client?.value) {
        await setOrdersOptions([
          { label: "-", value: false },
          ...json.data
            ?.filter((e) => client.value === e.id_contractor)
            ?.map((e) => {
              return {
                label: e.order_no,
                value: e.id,
                price_netto: e.order_price,
                selName: "order",
              };
            }),
        ]);
      } else {
        setOrdersOptions({ label: "Wybierz klienta" });
      }
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
    }
  };

  const getServices = async () => {
    const response = await fetch(`${DBurl}/getServices/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setServicesOptions(
        json.data?.map((e) => {
          return {
            label: e.name,
            value: e.id,
            selName: "name",
            ...e,
          };
        })
      );
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
    }
  };

  const getVat = async () => {
    const response = await fetch(`${DBurl}/getVatRates/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      // let tempData = json.data.map(v => ({ ...v, name: v.rate }))
      setVatOptions(
        json.data.map((e) => {
          return {
            value: e.id,
            label: e.rate,
            rate: e.rate,
            id: e.id,
            selName: "vat",
          };
        })
      );
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
    }
  };

  useEffect(() => {
    getVat();
    getServices();
    getOwnDepartments();
  }, []);

  useEffect(() => {
    let netto = 0;
    let brutto = 0;
    if (type.value === 3) {
      for (const el of services) {
        const same = correction_services.find((e) => e.id === el.id);
        const isCorrection = !!same;
        netto = isCorrection
          ? Number(netto) + (Number(same.value_netto) || 0)
          : Number(netto) + (Number(el.value_netto) || 0);
        brutto = isCorrection
          ? Number(brutto) + (Number(same.value_brutto) || 0)
          : Number(brutto) + (Number(el.value_brutto) || 0);
      }
    } else {
      services.forEach((e) => {
        netto = Number(netto) + (Number(e.value_netto) || 0);
        brutto = Number(brutto) + (Number(e.value_brutto) || 0);
      });
    }

    setNettoSum(netto);
    setBruttoSum(brutto);
  }, [JSON.stringify(services), JSON.stringify(correction_services)]);

  useEffect(() => {
    getInvoice(props.state.fetchInvoiceId);
  }, [props.state.fetchInvoiceId]);

  const clear = () => {
    setClient("");

    setType(1);
    setDate_of_issue("");
    setDate_service("");
    setInvoice_no("");

    setPayment_method("");
    setPayment_deadline("");
    setAdvance("");

    setPlace_of_issue("");
    setDescription("");
    setSignature("");

    setServices([
      {
        id: 1,
        name: "",
        order: null,
        quantity: 1,
        vat: 0,
        netto: 0,
        brutto: 0,
      },
    ]);
    setCurrency("PLN");
    setNettoSum(0);
    setBruttoSum(0);

    // setOpenSnack(false)
    // setSnackContent("")
  };

  const currencyOption = [
    { label: "PLN", value: "PLN" },
    { label: "EURO", value: "EUR" },
    { label: "CHF", value: "CHF" },
    { label: "FUNT", value: "GBP" },
    { label: "KORONA", value: "CZK" },
    { label: "RUBEL", value: "RUB" },
    { label: "HRYWNA", value: "UAH" },
  ];

  const updateService = (index, isSelect, isCorrection) => (e) => {
    let newArr = isCorrection ? [...correction_services] : [...services];
    if (isSelect) {
      if (e.selName === "name") {
        newArr[index][e.selName] = e;
        newArr[index].quantity = e.quantity;
        newArr[index].vat = { label: e.vat, rate: e.vat };
        newArr[index].price_netto = e.price_netto;
      } else if (e.selName === "order" && !newArr[index].price_netto) {
        newArr[index][e.selName] = e;
        newArr[index].price_netto = e.price_netto;
        newArr[index].name = {
          label: "Usługi transportowe",
          value: "Usługi transportowe",
        };
      } else {
        newArr[index][e.selName] = e;
      }
    } else {
      newArr[index][e.target.name] = e.target.value;
    }
    if (
      !!newArr[index].price_netto &&
      (!!newArr[index].vat?.rate || !!newArr[index].vat?.value) &&
      !!newArr[index].quantity
    ) {
      newArr[index].value_netto = (
        Number(newArr[index].price_netto) * Number(newArr[index].quantity)
      ).toFixed(2);
      newArr[index].value_brutto = (
        Number(newArr[index].value_netto) *
        ((newArr[index].vat.rate || newArr[index].vat.value) / 100 + 1)
      ).toFixed(2);
    }

    isCorrection ? setCorrection_services(newArr) : setServices(newArr);
  };

  const removeService = (i) => {
    const tempServices = [...services];
    tempServices.splice(i, 1);
    tempServices.forEach((e, i) => {
      e.id = i + 1;
    });
    setServices(tempServices);
    // updateSum()
  };

  const copyCargo = (i) => {
    const tempServices = [...services];
    // const choosen = []...services[i]
    tempServices.splice(i, 0, { ...services[i] });
    tempServices.forEach((e, i) => {
      e.id = i + 1;
    });
    setServices(tempServices);
    // updateSum()
  };

  const addService = () => {
    let newArr = [
      ...services,
      {
        name: "",
        order: null,
        quantity: 1,
        vat: 0,
        netto: 0,
        brutto: 0,
      },
    ];

    setServices(newArr);
    // updateSum()
  };

  const addServiceCorrection = (id) => {
    const oldData = services.find((e) => e.id === id);
    let newArr = [...correction_services, oldData];
    setCorrection_services(newArr);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDialog(false);
  };

  const getContractors = async function () {
    const response = await fetch(`${DBurl}/getContractors/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setClientOptions(
        json.data?.map((e) => {
          return { value: e.id, label: e.shortname };
        })
      );
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
    }
  };

  const getOwnDepartments = async function () {
    const response = await fetch(`${DBurl}/getCustomersDepartments/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      const tempOptions = json.data.map((e) => {
        return { ...e, label: e.name, value: e.id };
      });
      setDepartmentOptions(tempOptions);
    }
  };

  useEffect(() => {
    getContractors();
  }, []);

  const getDepartments = async function () {
    const response = await fetch(`${DBurl}/getDepartments/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      const tempOptionsBase = json.data?.filter(
        (e) => e.id_parent === client.value
      );

      if (tempOptionsBase.length > 0) {
        const tempOptions = tempOptionsBase.map((e) => {
          return { value: e.id, label: e.shortname };
        });
        setClientDepartmentOptions([
          { label: client.label, value: false },
          ...tempOptions,
        ]);
        setClientDepartment({ label: client.label, value: false });

        // setTable(json.data)
      } else {
        setClientDepartment({ value: false, label: "Brak oddziałów" });
        setClientDepartmentOptions([]);
      }

      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
    }
  };

  const savedTabId = props.tabID;
  const saveTab = () => {
    const tempTab = { ...props.tabs[savedTabId] };
    tempTab.department = department;
    tempTab.client = client;
    tempTab.departmentOptions = departmentOptions;
    tempTab.clientOptions = clientOptions;
    tempTab.clientDepartment = clientDepartment;
    tempTab.clientDepartmentOptions = clientDepartmentOptions;
    tempTab.type = type;
    tempTab.date_of_issue = date_of_issue;
    tempTab.date_service = date_service;
    tempTab.invoice_no = invoice_no;
    tempTab.payment_method = payment_method;
    tempTab.payment_deadline = payment_deadline;
    tempTab.advance = advance;
    tempTab.place_of_issue = place_of_issue;
    tempTab.description = description;
    tempTab.signature = signature;
    tempTab.services = services;
    tempTab.currency = currency;
    tempTab.nettoSum = nettoSum;
    tempTab.bruttoSum = bruttoSum;

    return tempTab;
  };

  useEffect(() => {
    props.setTabs(() => {
      const tempTab = [...props.tabs];
      tempTab[props.tabID] = saveTab();
      return tempTab;
    });
    // }, [])
  }, [
    client,
    type,
    date_of_issue,
    date_service,
    payment_method,
    payment_deadline,
    advance,
    place_of_issue,
    description,
    signature,
    services,
    currency,
    nettoSum,
    bruttoSum,
  ]);

  useEffect(() => {
    if (typeof client.value === "number") {
      getDepartments(client.value);
      getOrders();
    } else {
      setClientDepartment({});
    }
  }, [client]);

  const getInvoice = async (id) => {
    getAllOrders().then(async (res) => {
      const response = await fetch(`${DBurl}/getInvoices/?id=${id}`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.user.csrf_token,
        },
      });
      const json = await response.json();

      if (json.success) {
        const data = json.data[0];
        const isCorrection = data.type.value === 3;

        setDepartment({
          ...data.dealer,
          label: data.dealer?.shortname,
          value: data.dealer?.id,
        });
        setClient({
          ...data.contractor,
          label: data.contractor?.shortname,
          value: data.contractor.id,
        });
        setClientDepartment(
          data.contractor_department === "No data"
            ? { label: "-", value: false }
            : {
                ...data.contractor,
                label: data.contractor?.shortname,
                value: data.contractor?.id,
              }
        );
        setType(
          data.type.value === 3 || type.value === 3
            ? { label: "Korekta", value: 3 }
            : typeOptions.find((e) => e.value === data.type)
        );
        setDate_of_issue(data.date_of_issue.substring(10, 0));
        setDate_service(data.date_service.substring(10, 0));
        setInvoice_no(data.invoice_no);
        setPayment_method({
          label: data.payment_method,
          value: data.payment_method,
        });
        setPayment_deadline(data.payment_deadline.substring(10, 0));
        setAdvance(data.advance);
        setPlace_of_issue(data.place_of_issue);
        setDescription(data.description);
        setSignature(data.signature);
        setCurrency({ label: data.currency, value: data.currency });
        setServices(
          data.services.map((e, i) => {
            return {
              id: i + 1,
              name: { label: e.name, value: e.id },
              order: res.find((el) => el.id === e.id_order),
              order_id: e.id_order,
              quantity: e.quantity,
              vat: { label: e.vat, value: e.vat },
              price_netto: e.price_netto,
              value_netto: e.value_netto,
              value_brutto: e.value_brutto,
            };
          })
        );
        set_date_of_correction(
          isCorrection ? data.date_of_correction.substring(10, 0) : null
        );
        set_correction_no(isCorrection ? data.correction_no : null);
        set_correction_invoice_id(
          isCorrection ? data.correction_invoice_id : null
        );
        set_correction_title(isCorrection ? data.correction_title : null);
      }
    });
  };

  const setInvoices = async () => {
    var result = [];

    const fixSelects = (arr) => {
      return arr.map((e) => {
        const tmpobj = { ...e };
        tmpobj.vat = e.vat.value;
        tmpobj.name = e.name.label;
        tmpobj.id_service_reference = e.name.id || e.name.value;
        return tmpobj;
      });
    };

    const addCorrectionAndServicesBrutto = () => {
      const tempArr = [];
      services.forEach((el, i) => {
        const correctionElement = correction_services.find(
          (el2) => el2.id === el.id
        );
        !!correctionElement
          ? tempArr.push(correctionElement)
          : tempArr.push(el);
      });
      return tempArr.reduce(
        (a, b) => Number(a) + (Number(b["value_brutto"]) || 0),
        0
      );
    };

    const servicesWithOrder = services.filter((e) => !!e.order?.value);
    const servicesWithoutRepetition = servicesWithOrder.filter(
      (v, i, a) => a.findIndex((t) => t.order.value === v.order.value) === i
    );

    servicesWithoutRepetition.forEach((e) => {
      result.push(e.order.value);
    });

    const response = await fetch(`${DBurl}/setInvoices`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: !!data.fetchInvoiceId ? "update" : "insert",
        data: {
          id: type.value !== 3 ? data.fetchInvoiceId : null,
          id_dealer: department.id,
          id_contractor: client.value,
          id_contractor_department: !!clientDepartment?.value
            ? clientDepartment.value
            : null,
          type: type.value, // [1 - Nowa czyta faktura, 2 - ProForma, 3 - Korekta faktury]
          date_of_issue: date_of_issue,
          date_service: date_service,
          invoice_no: invoice_no,
          place_of_issue: place_of_issue,
          description: description,
          signature: signature,
          currency: currency.label,
          payment_method: payment_method.value,
          payment_deadline: payment_deadline,
          advance: !!advance ? Number(advance) : 0,
          services:
            type.value !== 3
              ? services.map((e) => {
                  return {
                    ...e,
                    vat: Number(e.vat.label),
                    name: e.name.label,
                    id_order: e.order?.value || null,
                  };
                })
              : null,
          correction_services:
            correction_services.length > 0
              ? fixSelects(correction_services)
              : null,
          orders: result,
          status: props.state.status || 1,
          value_brutto:
            type.value !== 3
              ? services.reduce(
                  (a, b) => Number(a) + (Number(b["value_brutto"]) || 0),
                  0
                )
              : addCorrectionAndServicesBrutto(),
          date_of_correction: date_of_issue,
          correction_no: correction_no,
          correction_invoice_id: props.state.fetchInvoiceId,
          correction_title: correction_title,
        },
      }),
    });
    const json = await response.json();
    if (json.success) {
      setOpenSnack(true);
      setSnackContent("Faktura dodana");
      clear();
      return json;
    } else {
      setOpenSnack(true);
      setSnackContent(`${json.responseDescription} // ${json.response}  `);
    }
  };

  const addRemoveCorrection = (element) => {
    const isInCorrection = correction_services.find((e) => e.id === element.id)
      ? true
      : false;
    isInCorrection
      ? setCorrection_services(
          correction_services.filter((e) => e.id != element.id)
        )
      : setCorrection_services([...correction_services, { ...element }]);
  };

  const correctServicesModule = (
    <>
      {services.map((e, i) => {
        return (
          <>
            <InvoiceProductsText
              data={e}
              key={i}
              propKey={i}
              updateService={updateService}
              remove={removeService}
              addRemoveCorrection={addRemoveCorrection}
              copyCargo={copyCargo}
              ordersOptions={ordersOptions}
              servicesOptions={servicesOptions}
              vatOptions={vatOptions}
              alignRight={type.value === 3}
              correction_services
              setCorrection_services
            />
          </>
        );
      })}

      <Grid item xs={12} className={styles.flexRow}>
        <div className={styles.line}>
          <h6>Jest</h6>
          <p className={styles.subText}>Wprowadż poprawki</p>
          <Select
            className={`${styles.selectCurrency} ${styles.selectClass}`}
            options={currencyOption}
            onChange={setCurrency}
            name="service"
            placeholder={"Waluta"}
            value={currency}
            styles={{
              control: (base, state) => ({
                ...base,
                border: "1px solid #142f42",
                boxShadow: "none",
                "&:hover": {
                  border: "1px solid #142f42",
                },
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused && "#142f42",
                color: state.isFocused && "#fff",
              }),
            }}
          />
        </div>
      </Grid>
      <Grid item xs={1} className={`${styles.invoiceHead} ${styles.jcfe}`}>
        <div className={styles.inp}>
          <label>Lp.</label>
        </div>
      </Grid>
      <Grid item xs={2} className={`${styles.invoiceHead} ${styles.jcfe}`}>
        <div className={styles.inp}>
          <label className={styles.tar}>Nazwa</label>
        </div>
      </Grid>
      <Grid item xs={2} className={`${styles.invoiceHead} ${styles.jcfe}`}>
        <div className={styles.inp}>
          <label className={styles.tar}>Zlecenie</label>
        </div>
      </Grid>
      <Grid item xs={1} className={`${styles.invoiceHead} ${styles.jcfe}`}>
        <div className={styles.inp}>
          <label className={styles.tar}>Kwota netto</label>
        </div>
      </Grid>
      <Grid item xs={1} className={`${styles.invoiceHead} ${styles.jcfe}`}>
        <div className={styles.inp}>
          <label className={styles.tar}>Ilość</label>
        </div>
      </Grid>
      <Grid item xs={1} className={`${styles.invoiceHead} ${styles.jcfe}`}>
        <div className={styles.inp}>
          <label className={styles.tar}>Stawka VAT(%)</label>
        </div>
      </Grid>
      <Grid item xs={1} className={`${styles.invoiceHead} ${styles.jcfe}`}>
        <div className={styles.inp}>
          <label className={styles.tar}>Wartość netto</label>
        </div>
      </Grid>
      <Grid item xs={2} className={`${styles.invoiceHead} ${styles.jcfe}`}>
        <div className={styles.inp}>
          <label className={styles.tar}>Wartość brutto</label>
        </div>
      </Grid>
      <Grid
        item
        xs={1}
        className={`${styles.invoiceHead} ${styles.jcfe}`}
      ></Grid>
      {correction_services.map((e, i) => {
        return (
          <>
            <InvoiceProductsCorrection
              data={e}
              key={i}
              propKey={i}
              updateService={updateService}
              remove={removeService}
              ordersOptions={ordersOptions}
              servicesOptions={servicesOptions}
              vatOptions={vatOptions}
              alignRight={type.value === 3}
              correction_services
              setCorrection_services
            />
          </>
        );
      })}
    </>
  );

  return (
    <>
      <Title title={props.state.tabName} />
      <Grid container spacing={5} className={styles.contInVoice}>
        <Grid item xs={6} className={styles.borRight}>
          <div className={`${styles.inp} ${styles.invoice}`}>
            <h6>Kontrahent</h6>
            <label>Kontrahent</label>
            <Select
              value={client}
              className={styles.selectClass}
              options={clientOptions}
              onChange={(e) => {
                setClient(e);
              }}
              name="client"
              placeholder="Wybierz kontrahenta"
              styles={{
                control: (base, state) => ({
                  ...base,
                  border: "1px solid #142f42",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #142f42",
                  },
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused && "#142f42",
                  color: state.isFocused && "#fff",
                }),
              }}
              search
            />
          </div>
          <div className={`${styles.inp} ${styles.invoice}`}>
            <label>Oddział</label>
            <Select
              value={clientDepartment}
              className={styles.selectClass}
              options={clientDepartmentOptions}
              onChange={(e) => {
                setClientDepartment(e);
              }}
              name="client"
              placeholder="Wybierz kontrahenta"
              search={!client.value}
              isDisabled={!client.value}
              styles={{
                control: (base, state) => ({
                  ...base,
                  border: "1px solid #142f42",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #142f42",
                  },
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused && "#142f42",
                  color: state.isFocused && "#fff",
                }),
              }}
            />
          </div>
        </Grid>

        <Grid item xs={6} className={styles.borRight}>
          <div className={`${styles.inp} ${styles.invoice}`}>
            <h6>Sprzedawca</h6>
            <label>Sprzedawca</label>
            <Select
              value={department}
              className={styles.selectClass}
              options={departmentOptions}
              onChange={(e) => {
                setDepartment(e);
              }}
              name="department"
              placeholder="Wybierz oddział"
              search
              styles={{
                control: (base, state) => ({
                  ...base,
                  border: "1px solid #142f42",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #142f42",
                  },
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused && "#142f42",
                  color: state.isFocused && "#fff",
                }),
              }}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={5} className={styles.contInVoice}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} className={styles.flexRow}>
              <div className={styles.line}>
                <h6>{type.value !== 3 ? "Usługi" : "Było"}</h6>
                {type.value === 3 && (
                  <p className={styles.subText}>Zaznacz pozycje do korekty</p>
                )}
                {/* <div className={styles.buttonRow}>
									<Button variant="outlined" color="primary" className={styles.outBtn} onClick={() => { addService("order") }}>Dodaj zlecenie </Button>
									<Button variant="outlined" color="primary" className={styles.outBtn} onClick={() => { addService('service') }} >Dodaj usługę dodatkową </Button>
								</div> */}
                <Select
                  className={`${styles.selectCurrency} ${styles.selectClass}`}
                  options={currencyOption}
                  onChange={setCurrency}
                  name="service"
                  placeholder={"Waluta"}
                  value={currency}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      border: "1px solid #142f42",
                      boxShadow: "none",
                      "&:hover": {
                        border: "1px solid #142f42",
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "#142f42",
                      color: state.isFocused && "#fff",
                    }),
                  }}
                />
              </div>
            </Grid>
            <Grid container spacing={2}>
              <Grid
                item
                xs={1}
                className={`${styles.invoiceHead} ${
                  type.value === 3 && styles.jcfe
                }`}
              >
                <div className={styles.inp}>
                  <label>Lp.</label>
                </div>
              </Grid>
              <Grid
                item
                xs={2}
                className={`${styles.invoiceHead} ${
                  type.value === 3 && styles.jcfe
                }`}
              >
                <div className={styles.inp}>
                  <label className={type.value === 3 ? styles.tar : undefined}>
                    Nazwa
                  </label>
                </div>
              </Grid>
              <Grid
                item
                xs={2}
                className={`${styles.invoiceHead} ${
                  type.value === 3 && styles.jcfe
                }`}
              >
                <div className={styles.inp}>
                  <label className={type.value === 3 ? styles.tar : undefined}>
                    Zlecenie
                  </label>
                </div>
              </Grid>
              <Grid
                item
                xs={1}
                className={`${styles.invoiceHead} ${
                  type.value === 3 && styles.jcfe
                }`}
              >
                <div className={styles.inp}>
                  <label className={type.value === 3 ? styles.tar : undefined}>
                    Kwota netto
                  </label>
                </div>
              </Grid>
              <Grid
                item
                xs={1}
                className={`${styles.invoiceHead} ${
                  type.value === 3 && styles.jcfe
                }`}
              >
                <div className={styles.inp}>
                  <label className={type.value === 3 ? styles.tar : undefined}>
                    Ilość
                  </label>
                </div>
              </Grid>
              <Grid
                item
                xs={1}
                className={`${styles.invoiceHead} ${
                  type.value === 3 && styles.jcfe
                }`}
              >
                <div className={styles.inp}>
                  <label className={type.value === 3 ? styles.tar : undefined}>
                    Stawka VAT(%)
                  </label>
                </div>
              </Grid>
              <Grid
                item
                xs={1}
                className={`${styles.invoiceHead} ${
                  type.value === 3 && styles.jcfe
                }`}
              >
                <div className={styles.inp}>
                  <label className={type.value === 3 ? styles.tar : undefined}>
                    Wartość netto
                  </label>
                </div>
              </Grid>
              <Grid
                item
                xs={2}
                className={`${styles.invoiceHead} ${
                  type.value === 3 && styles.jcfe
                }`}
              >
                <div className={styles.inp}>
                  <label className={type.value === 3 ? styles.tar : undefined}>
                    Wartość brutto
                  </label>
                </div>
              </Grid>
              <Grid
                item
                xs={1}
                className={`${styles.invoiceHead} ${
                  type.value === 3 && styles.jcfe
                }`}
              ></Grid>
              {type.value === 3
                ? correctServicesModule
                : services.map((e, i) => {
                    return (
                      <InvoiceProducts
                        data={e}
                        key={i}
                        propKey={i}
                        updateService={updateService}
                        remove={removeService}
                        copyCargo={copyCargo}
                        ordersOptions={ordersOptions}
                        servicesOptions={servicesOptions}
                        vatOptions={vatOptions}
                      />
                    );
                  })}
              <Grid item xs={12}>
                {type.value !== 3 && (
                  <Grid
                    container
                    className={styles.invoiceNew}
                    onClick={() => {
                      addService();
                    }}
                  >
                    <Grid item xs={1}>
                      {services.length + 1}
                    </Grid>
                    <Grid item xs={9}>
                      Dodaj...
                    </Grid>
                    <Grid item xs={3}></Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={7}></Grid>
                  <Grid
                    item
                    xs={1}
                    className={
                      type.value !== 3
                        ? styles.invoiceSym
                        : `${styles.invoiceSum} ${styles.tar}`
                    }
                  >
                    Razem:
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    className={
                      type.value !== 3
                        ? styles.invoiceSym
                        : `${styles.invoiceSum} ${styles.tar}`
                    }
                  >
                    {nettoSum}
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={
                      type.value !== 3
                        ? styles.invoiceSym
                        : `${styles.invoiceSum} ${styles.tar}`
                    }
                  >
                    {bruttoSum}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={5} className={styles.contInVoice}>
        <Grid item xs={4} className={styles.borRight}>
          <Grid item xs={12}>
            <div className={`${styles.inp} ${styles.invoice}`}>
              <h6>Nagłówek</h6>
              <label>Rodzaj:</label>
              <Select
                value={type}
                className={styles.selectClass}
                options={typeOptions}
                onChange={(e) => {
                  setType(e);
                }}
                name="typ"
                placeholder="Wybierz typ"
                disabled={type.value === 3}
                search
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused && "#142f42",
                    color: state.isFocused && "#fff",
                  }),
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={styles.inp}>
              <label>Data wystawienia:</label>
              <Input
                value={date_of_issue}
                handleChange={(e) => {
                  setDate_of_issue(e.target.value);
                }}
                name="date_of_issue"
                type="date"
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={styles.inp}>
              <label>Data wykonania usługi:</label>
              <Input
                value={date_service}
                handleChange={(e) => {
                  setDate_service(e.target.value);
                }}
                name="date_service"
                type="date"
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={styles.inp}>
              <label>Numer:</label>
              {type.value === 3 ? (
                <Input
                  value={correction_no}
                  handleChange={(e) => {
                    set_correction_no(e.target.value);
                  }}
                  name="correction_no"
                />
              ) : (
                <Input
                  value={invoice_no}
                  handleChange={(e) => {
                    setInvoice_no(e.target.value);
                  }}
                  name="invoice_no"
                />
              )}
            </div>
          </Grid>
          {type.value === 3 && (
            <Grid item xs={12}>
              <div className={styles.inp}>
                <label>Korekta tytułem:</label>
                <Input
                  value={correction_title}
                  handleChange={(e) => {
                    set_correction_title(e.target.value);
                  }}
                  name="correction_title"
                />
              </div>
            </Grid>
          )}
        </Grid>

        <Grid item xs={4} className={styles.borRight}>
          <Grid item xs={12}>
            <div className={`${styles.inp} ${styles.invoice}`}>
              <h6>Płatność</h6>
              <label>Sposób płatności:</label>
              <Select
                value={payment_method}
                className={styles.selectClass}
                options={payment_methodOptions}
                onChange={(e) => {
                  setPayment_method(e);
                }}
                name="client"
                placeholder="Wybierz kontrahenta"
                search
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused && "#142f42",
                    color: state.isFocused && "#fff",
                  }),
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={styles.inp}>
              <label>Termin:</label>
              <Input
                value={payment_deadline}
                handleChange={(e) => {
                  setPayment_deadline(e.target.value);
                }}
                name="payment_deadline"
                type="date"
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={styles.inp}>
              <label>Zapłacono:</label>
              <Input
                value={advance}
                handleChange={(e) => {
                  setAdvance(e.target.value);
                }}
                name="advance"
              />
            </div>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid item xs={12}>
            <div className={`${styles.inp} ${styles.inVoicePrint}`}>
              <h6>Wydruk</h6>
              <label>Miejsce wystawienia</label>
              <Input
                value={place_of_issue}
                handleChange={(e) => {
                  setPlace_of_issue(e.target.value);
                }}
                name="place_of_issue"
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={styles.inp}>
              <label>Opis Dokumentu</label>
              <Input
                value={description}
                handleChange={(e) => {
                  setDescription(e.target.value);
                }}
                name="description"
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={styles.inp}>
              <label>Podpis</label>
              <Input
                value={signature}
                handleChange={(e) => {
                  setSignature(e.target.value);
                }}
                name="signature"
              />
            </div>
          </Grid>
        </Grid>
      </Grid>

      <div className={`${BTNstyles.btnRow} ${styles.mt15}`}>
        <Button
          variant="contained"
          size="large"
          className={`${BTNstyles.btn} ${BTNstyles.err}`}
          onClick={() => {
            clear();
          }}
        >
          Anuluj <X className={BTNstyles.downBtnIco} />
        </Button>

        {!!props.state.fetchInvoiceId ? (
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={`${BTNstyles.btn} ${BTNstyles.grn}`}
            onClick={() => {
              setInvoices();
            }}
          >
            Zapisz <Check className={BTNstyles.downBtnIco} />
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={`${BTNstyles.btn} ${BTNstyles.grn}`}
            onClick={() => {
              setInvoices();
            }}
          >
            Utwórz <Check className={BTNstyles.downBtnIco} />
          </Button>
        )}
      </div>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={(e, r) => {
          if (r === "clickaway") {
            return;
          }
          setOpenSnack(false);
        }}
      >
        <Alert
          onClose={(e, r) => {
            if (r === "clickaway") {
              return;
            }
            setOpenSnack(false);
          }}
          severity="success"
        >
          {snackContent}
        </Alert>
      </Snackbar>
      <Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={dialogColor}>
          {dialogContent}
        </Alert>
      </Snackbar>
    </>
  );
}
