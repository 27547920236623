import { BorderCrossing, SelectOptionState } from "types";
import { countries } from "../../data/countries";

export const filterCrossings = ({
  crossings,
  origin,
  destination,
}: {
  crossings: BorderCrossing[];
  origin: SelectOptionState;
  destination: SelectOptionState;
}) => {
  let crossingsCache = crossings;

  const filterBy = (filter: "origin" | "destination", value: string) =>
    crossingsCache?.filter(
      (crossing: BorderCrossing) => crossing[filter] === value
    );

  if (origin) crossingsCache = filterBy("origin", origin.value);
  if (destination) crossingsCache = filterBy("destination", destination.value);

  return crossingsCache;
};

export const translateCountries = (crossings: BorderCrossing[]) => {
  const mapCountriesByEngName = (engName: string) => {
    const foundCountry = countries.find(
      (country) => country.eng_name === engName
    );

    return foundCountry ? foundCountry.label : engName;
  };

  return crossings.map((crossing) => ({
    ...crossing,
    origin: mapCountriesByEngName(crossing.origin),
    destination: mapCountriesByEngName(crossing.destination),
  }));
};
