import React, { useState } from 'react'
import Title from '../../components/title'
import styles from '../../styles/newOrEdit.module.css'
import Grid from '@material-ui/core/Grid';
import Input from '../../components/input';
import Button from '@material-ui/core/Button';
import BTNstyles from '../../styles/button.module.css'
import { ArrowRight } from 'react-feather';


export default function Settings(props) {
	const [password, setPassword] = useState("");
	// const [auth, setAuth] = useState(false)
	const [newPassword, setNewPassword] = useState("");
	const [newPasswordCheck, setNewPasswordCheck] = useState("");

	// TODO zmiana na hookie jak będzie fetch
	const auth = false


const passCheck = (pass) => {
	// TODO: Fetch sprawdzający poprawność hasła
}

	return (
		< >
			<Title title={props.state.tabName} />
			<Grid container className={styles.contTop}>
				<Grid item xs={12}>
					<Grid item xs={2}>
						<div className={styles.inp}>
							<h6>Wpisz hasło</h6>
							<label></label>
							<Input value={password} handleChange={e => setPassword(e.target.value)} name="password"
								type="password" />
						</div>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid item xs={4}>			<div className={styles.aprBtnParrent}>

						<Button
							variant="contained"
							color="primary"
							size="large"
							className={styles.outBtn}
							onClick={passCheck(password)}
							>
							Zatwierdź
								<ArrowRight className={BTNstyles.downBtnIco} />
						</Button>
						</div>
					</Grid>
				</Grid>


			</Grid>
			{auth ?

				<Grid container className={styles.cont}>
					<Grid item xs={12}>
						<Grid item xs={4}>
							<div className={styles.inp}>
								<h6>Wpisz nowe hasło</h6>
								<label></label>
								<Input value={newPassword} handleChange={e => setNewPassword(e.target.value)} name="newPassword"
									type="password" />
							</div>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid item xs={4}>
							<div className={styles.inp}>
								<h6>Wpisz ponownie nowe hasło</h6>
								<label></label>
								<Input value={newPasswordCheck} handleChange={e => setNewPasswordCheck(e.target.value)} name="newPasswordCheck"
									type="password" />
							</div>
						</Grid>
					</Grid>
				</Grid>

				: null
			}

		</>
	)
}

