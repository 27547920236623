import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import stylesMod from "../../styles/newOrEdit.module.css";
import Input from "../../components/input";
import Select from "react-select";
import { DBurl } from "../../appConfig";
import BTNstyles from "../../styles/button.module.css";
import { Add } from "@material-ui/icons";
import { X } from "react-feather";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PurchaseAdd(props) {
  const [error, setError] = useState(false);
  const [car, setCar] = useState(props.data?.car || "");
  const [name, setName] = useState(props.data?.name || "");
  const [type, setType] = useState(props.data?.type || "");
  const typeOptions = [
    { label: "Opłaty drogowe", value: "Opłaty drogowe" },
    { label: "Parking", value: "Parking" },
    { label: "Prom", value: "Prom" },
    { label: "Myjnia", value: "Myjnia" },
    { label: "Serwis", value: "Serwis" },
    { label: "Nocleg", value: "Nocleg" },
    { label: "Własne", value: "Własne" },
  ];
  const [cost, setCost] = useState(props.data?.cost);
  const [currency, setCurrency] = useState(props.data?.currency || "");
  const currencyOption = [
    { label: "PLN", value: "PLN" },
    { label: "EUR", value: "EUR" },
    { label: "CHF", value: "CHF" },
    { label: "FUNT", value: "FUNT" },
    { label: "RUBEL", value: "RUBEL" },
    { label: "HRYWNA", value: "HRYWNA" },
  ];
  const [description, setDescription] = useState(props.data?.description || "");
  const [department, setDepartment] = useState(props.data?.department || "");

  useEffect(() => {
    if (props.data?.name) {
      setCar({
        label: props.data.car.name,
        value: props.data.car.id,
        ...props.data.car,
      });
      setName(props.data.name);
      setType({ label: props.data.type, value: props.data.type });
      setCost(props.data.cost);
      setCurrency({ label: props.data.currency, value: props.data.currency });
      setDescription(props.data.description);
      setDepartment({
        label: props.data.customer_department?.shortname,
        value: props.data.customer_department?.id,
        ...props.data.customer_department,
      });
    }
  }, [props.data.name]);

  return (
    <Dialog
      maxWidth="lg"
      open={props.open}
      TransitionComponent={Transition}
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        className={stylesMod.dialogHead}
      >
        Dodaj zakup{" "}
        <IconButton
          aria-label="close"
          className={stylesMod.closeButton}
          onClick={() => {
            props.setOpen(false);
          }}
        >
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent className={stylesMod.noOverflow}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <div className={stylesMod.inp}>
              <label>Dotyczy pojazdu</label>
              <Select
                value={car}
                className={stylesMod.select}
                options={props.carsOptions}
                onChange={(e) => {
                  setCar(e);
                }}
                name="car"
                placeholder="Wybierz..."
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused && "#142f42",
                    color: state.isFocused && "#fff",
                  }),
                }}
              />
            </div>
          </Grid>

          <Grid item xs={2}>
            <div className={`${stylesMod.inp}`}>
              <label>Nazwa</label>
              <Input
                value={name}
                error={!name && error}
                handleChange={(e) => {
                  setName(e.target.value);
                }}
                name="name"
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={`${stylesMod.inp}`}>
              <label>Typ</label>
              <Select
                value={type}
                className={`${stylesMod.select} ${
                  error && !type.value && stylesMod.error
                }`}
                options={typeOptions}
                onChange={(e) => {
                  setType(e);
                }}
                name="type"
                placeholder="Wybierz..."
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused && "#142f42",
                    color: state.isFocused && "#fff",
                  }),
                }}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={`${stylesMod.inp} `}>
              <label>Kwota</label>
              <Input
                value={cost}
                error={!cost && error}
                handleChange={(e) => {
                  setCost(e.target.value);
                }}
                name="cost"
                type="number"
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={`${stylesMod.inp}`}>
              <label>Waluta</label>
              <Select
                value={currency}
                className={`${stylesMod.select} ${
                  error && !currency.value && stylesMod.error
                }`}
                options={currencyOption}
                onChange={(e) => {
                  setCurrency(e);
                }}
                name="currency"
                placeholder="Wybierz..."
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused && "#142f42",
                    color: state.isFocused && "#fff",
                  }),
                }}
              />
            </div>
          </Grid>
          <Grid item xs={5}>
            <div className={stylesMod.inp}>
              <label>Opis</label>
              <Input
                value={description}
                handleChange={(e) => {
                  setDescription(e.target.value);
                }}
                name="description"
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={stylesMod.inp}>
              <label>Dotyczy departamentu:</label>
              <Select
                value={department}
                className={`${stylesMod.select} ${
                  error && !department.value && stylesMod.error
                }`}
                options={props.departmentOptions}
                onChange={(e) => {
                  setDepartment(e);
                }}
                name="department"
                placeholder="Wybierz..."
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "1px solid #142f42",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #142f42",
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused && "#142f42",
                    color: state.isFocused && "#fff",
                  }),
                }}
              />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="large"
          color="primary"
          className={`${BTNstyles.btn} ${BTNstyles.err}`}
          onClick={(e) => {
            setCar("");
            setName("");
            setType("");
            setCost("");
            setCurrency("");
            setDescription("");
            setDepartment("");
            props.setOpen(false);
          }}
        >
          Anuluj
        </Button>
        <Button
          variant="contained"
          size="large"
          className={`${BTNstyles.btn} ${BTNstyles.grn}`}
          onClick={(e) => {
            if (!!department?.id && !!cost && !!name && !!currency) {
              const fetchUrl = `${DBurl}/setCostOther/`;
              const data = {
                id: props.data.id || null,
                id_customer_department: department?.id,
                cost: Number(cost),
                id_car: car.id || null, // opcjonalnie - moze być NULL
                currency: currency.label, // waluta (PLN, EUR, CHF, FUNT, KORONA, RUBEL, HRYWNA)
                type: type.label, // typ (Opłaty drogowe, Parking, Prom, Myjnia, Serwis, Nocleg, Własne)
                name: name,
                description: description || null,
              };
              fetch(fetchUrl, {
                method: "post",
                credentials: "include",
                headers: {
                  "Content-Type": "application/json",
                  "X-CSRF-Token": props.user.csrf_token,
                },
                body: JSON.stringify({
                  mode: props.edit ? "update" : "insert",
                  data: data,
                }),
              })
                .then((res) => res.json())
                .then((data) => {
                  if (data.success) {
                    props.setOpenSnack(true);
                    props.setSnackColor("success");
                    props.setSnackContent(
                      props.edit ? "Zapisano zmiany" : "Zakup został dodany"
                    );
                    props.getCosts();
                    props.setOpen(false);
                  }
                });
            } else {
              props.setSnackContent("Uzupełnij wymagane pola");
              props.setSnackColor("error");
              props.setOpenSnack(true);
              setError(true);
            }
          }}
          color="primary"
        >
          {props.edit ? "Zapisz" : "Dodaj"}{" "}
          <Add className={BTNstyles.downBtnIco} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
