import React, { useState, useEffect } from "react";

import styles from "../table.module.css";
import Title from "../../components/title";
import Input from "../../components/input";
import ShortList from "../../components/ShortList";
import { DBurl } from "../../appConfig";
import { DeleteBtn, EditBtn } from "../../components/buttons";
import Alert from "@material-ui/lab/Alert";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Snackbar,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Slide,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { AddVat, RemoveVat } from "../../components/VatModule";
import { AddServices, RemoveServices } from "../../components/ServicesModule";
import stylesMod from "../../styles/newOrEdit.module.css";
import BTNstyles from "../../styles/button.module.css";
import { X } from "react-feather";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (array?.length > 0) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
}

const headCells = [
  { id: "name", right: false, disablePadding: true, label: "Nazwa szablonu" },
  { id: "dealer", right: true, disablePadding: false, label: "Sprzedawca" },
  { id: "contractor", right: true, disablePadding: false, label: "Klient" },
  { id: "services", right: true, disablePadding: false, label: "Usługi" },
  { id: "value", right: true, disablePadding: false, label: "Kwota" },
  { id: "advance", right: true, disablePadding: false, label: "Zaliczka" },
  { id: "signature", right: true, disablePadding: false, label: "Wystawił" },
];

function EnhancedTableHead(props) {
  const { styles, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={styles.tabHead}>
      <TableRow>
        <TableCell className={styles.checkbox}></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.right ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={headCell.class}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={styles.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {/* <TableCell className={styles.buttonBoxParent} /> */}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  styles: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function useInput({ type }) {
  const [value, setValue] = useState("");
  const input = (
    <TableCell>
      <Input
        value={value}
        handleChange={(e) => {
          setValue(e.target.value);
        }}
        type={type}
        search={true}
      />
    </TableCell>
  );
  return [value, input];
}

export default function AccountSettings(props) {
  const [type, setType] = useState("insert");
  const [vatData, setVatData] = useState([]);
  const [openAddVat, setAddVat] = useState(false);
  const [openRemoveVat, setOpenRemoveVat] = useState(false);
  const [selectedVat, setSelectedVat] = useState();
  const [servicesData, setServicesData] = useState([]);
  const [openAddServices, setOpenAddServices] = useState(false);
  const [openRemoveServices, setOpenRemoveServices] = useState(false);
  const [selectedServices, setSelectedServices] = useState();
  const [templatesData, setTemplatesData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogColor, setDialogColor] = useState("error");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("short");
  const [selected, setSelected] = useState([]);
  const [table, setTable] = useState([]);
  const [table2, setTable2] = useState([]);
  const [searchName, setSearchName] = useInput({ type: "text" });
  const [searchDealer, setSearchDealer] = useInput({ type: "text" });
  const [searchContractor, setSearchContractor] = useInput({ type: "text" });
  const [searchSignature, setSearchSignature] = useInput({ type: "text" });
  const [modalContent, setModalContent] = useState([]);
  const [sufix, setSufix] = useState(false);
  const [openSufixModal, setOpenSufixModal] = useState(false);
  const [sufixValue, setSufixValue] = useState("");
  const [reload, setReload] = useState(false);

  const getVat = async function () {
    props.loading(true);
    const response = await fetch(`${DBurl}/getVatRates/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      let tempData = json.data.map((v) => ({ ...v, name: v.rate }));
      setVatData(tempData);
      props.loading(false);
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  const getUsers = async () => {
    props.loading(true);
    const response = await fetch(`${DBurl}/getUsers`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setTable2(json.data);
      props.loading(false);
    }
  };

  const handleSetSufix = async (user_id) => {
    setReload(true);
    const response = await fetch(`${DBurl}/setSufixInvoiceNameForUsers`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "update",
        data: {
          users: [
            {
              user_id: user_id,
              sufix_invoice_name: sufixValue,
            },
          ],
        },
      }),
    });
    setReload(false);
    setSufixValue("");
  };
  const handleDeleteSufix = async (user_id) => {
    setReload(true);
    const response = await fetch(`${DBurl}/setSufixInvoiceNameForUsers`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
      body: JSON.stringify({
        mode: "update",
        data: {
          users: [
            {
              user_id: user_id,
              sufix_invoice_name: "",
            },
          ],
        },
      }),
    });
    setReload(false);
    setSufixValue("");
  };

  const deleteVat = (id) => {
    const result = vatData.find((e) => e.id == id);
    setSelectedVat(result);
    setOpenRemoveVat(true);
  };

  const setVat = (type, id) => {
    setType(type);
    if (!!id) {
      const result = vatData.find((e) => e.id == id);
      setSelectedVat(result);
    }

    setAddVat(true);
  };

  const getServices = async function () {
    props.loading(true);
    const response = await fetch(`${DBurl}/getServices/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();
    if (json.success) {
      setServicesData(json.data);
      props.loading(false);
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  const deleteServices = (id) => {
    const result = servicesData.find((e) => e.id == id);
    setSelectedServices(result);
    setOpenRemoveServices(true);
  };

  const setServices = (type, id) => {
    setType(type);
    if (!!id) {
      const result = servicesData.find((e) => e.id == id);
      setSelectedServices(result);
    }

    setOpenAddServices(true);
  };

  const getTemplates = async function () {
    props.loading(true);
    const response = await fetch(`${DBurl}/getInvoicesTemplates/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.user.csrf_token,
      },
    });
    const json = await response.json();

    if (json.success) {
      setTemplatesData(json.data);
      props.loading(false);
      return json;
    } else {
      setOpenDialog(true);
      setDialogContent(`${json.responseDescription} // ${json.response}  `);
      setDialogColor("error");
      props.loading(false);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDialog(false);
  };

  useEffect(() => {
    getVat();
    getTemplates();
    getServices();
    getUsers();
  }, []);

  useEffect(() => {
    getUsers();
  }, [reload === true]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    let newSelected = [name];
    setSelected(newSelected);
  };

  const search = (tbl) => {
    if (tbl.length > 0) {
      return tbl.filter((row) => {
        return (
          String(row.template_name).toLowerCase().indexOf(searchName) > -1 &&
          String(row.dealer?.shortname).toLowerCase().indexOf(searchDealer) >
            -1 &&
          String(row.contractor?.shortname)
            .toLowerCase()
            .indexOf(searchContractor) > -1 &&
          String(row.signature).toLowerCase().indexOf(searchSignature) > -1
        );
      });
    }
  };

  const isSelected = (name) => selected?.indexOf(name) !== -1;

  return (
    <>
      <Grid container spacing={0}>
        <Grid item sm={12} md={4} style={{ paddingRight: "4px" }}>
          <ShortList
            user={props.user}
            data={vatData}
            title="Stawki VAT"
            delete
            deleteFunc={deleteVat}
            set
            setFunc={setVat}
            noCheckBox
          />
        </Grid>
        <Grid item sm={12} md={8}>
          <ShortList
            user={props.user}
            data={servicesData}
            title="Usługi"
            delete
            deleteFunc={deleteServices}
            set
            setFunc={setServices}
            noCheckBox
            services
          />
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid
          item
          xs={props.user.adm >= 1 ? 8 : 12}
          style={props.user.adm >= 1 ? { paddingRight: "4px" } : {}}
        >
          <Title
            title="Szablony faktur"
            btnBox
            btnDelete
            deleteFunc={() => {
              const selectedArray = table?.filter((e) => {
                if (selected.includes(e.id)) {
                  return e;
                } else {
                  return null;
                }
              });
              setModalContent(selectedArray);
            }}
          />
          <TableContainer className={styles.stickyDoubleTable}>
            <Table
              stickyHeader
              className={styles.tableWOmin}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                styles={styles}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={templatesData.length}
              />
              <TableBody className={styles.tabBody}>
                <TableRow>
                  <TableCell></TableCell>
                  {setSearchName}
                  {setSearchDealer}
                  {setSearchContractor}
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  {setSearchSignature}
                  {/* <TableCell></TableCell> */}
                </TableRow>
                {stableSort(
                  search(templatesData),
                  getComparator(order, orderBy)
                )?.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox"></TableCell>
                      <TableCell align="right">{row.template_name}</TableCell>
                      <TableCell align="right">
                        {row.dealer?.shortname}
                      </TableCell>
                      <TableCell align="right">
                        {row.contractor?.shortname}
                      </TableCell>
                      <TableCell align="right">
                        {row.services[0]?.name}
                      </TableCell>
                      <TableCell align="right">
                        {row.services[0]?.value_brutto}
                      </TableCell>
                      <TableCell align="right">
                        {row.advance} {row.currency}
                      </TableCell>
                      <TableCell align="right">{row.signature}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {props.user.adm >= 1 && (
          <Grid item xs={4}>
            <Title title="Sufix do numeracji faktur" />
            <TableContainer className={styles.stickyDoubleTable}>
              <Table
                className={styles.tableWOmin}
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
              >
                <TableHead className={styles.tabHead}>
                  <TableRow>
                    <TableCell className={styles.sufixTableCell}>
                      Imię i nazwisko
                    </TableCell>
                    <TableCell className={styles.sufixTableCell}>
                      Login
                    </TableCell>
                    <TableCell className={styles.sufixTableCellShort}>
                      Sufix
                    </TableCell>
                    <TableCell className={styles.sufixTableCellShort} />
                  </TableRow>
                </TableHead>
                <TableBody className={styles.tabBody}>
                  {stableSort(
                    search(table2),
                    getComparator(order, orderBy)
                  )?.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id, row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell align="left">
                          {row.firstname} {row.surname}
                        </TableCell>
                        <TableCell align="left">{row.login}</TableCell>
                        <TableCell align="left">
                          {row.sufix_invoice_name}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={styles.buttonBoxParent}
                        >
                          <div className={styles.buttonBox}>
                            <Tooltip title="Edytuj">
                              <div>
                                <EditBtn
                                  handleChange={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setModalContent([row]);
                                    setSufix(true);
                                    setOpenSufixModal(true);
                                  }}
                                />
                              </div>
                            </Tooltip>
                            <Tooltip title={"Usuń"}>
                              <div>
                                <DeleteBtn
                                  handleChange={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleDeleteSufix(row.id);
                                  }}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>

      <Snackbar open={openDialog} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={dialogColor}>
          {dialogContent}
        </Alert>
      </Snackbar>

      <AddVat
        open={openAddVat}
        setOpen={setAddVat}
        setSnack={setOpenDialog}
        setSnackContent={setDialogContent}
        setSnackColor={setDialogColor}
        token={props.user.csrf_token}
        refresh={getVat}
        type={type}
        data={selectedVat}
      />
      <RemoveVat
        open={openRemoveVat}
        setOpen={setOpenRemoveVat}
        setSnack={setOpenDialog}
        setSnackContent={setDialogContent}
        setSnackColor={setDialogColor}
        data={selectedVat}
        refresh={getVat}
        token={props.user.csrf_token}
      />
      <AddServices
        open={openAddServices}
        setOpen={setOpenAddServices}
        setSnack={setOpenDialog}
        setSnackContent={setDialogContent}
        setSnackColor={setDialogColor}
        token={props.user.csrf_token}
        refresh={getServices}
        type={type}
        data={selectedServices}
        vatOptions={vatData.map((e) => {
          return { label: e.rate, value: e.rate };
        })}
      />
      <RemoveServices
        open={openRemoveServices}
        setOpen={setOpenRemoveServices}
        setSnack={setOpenDialog}
        setSnackContent={setDialogContent}
        setSnackColor={setDialogColor}
        data={selectedServices}
        refresh={getServices}
        token={props.user.csrf_token}
      />
      <Dialog
        open={openSufixModal}
        TransitionComponent={Transition}
        onClose={() => {
          setOpenSufixModal(false);
          setModalContent([]);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={stylesMod.dialogHead}
        >
          Ustaw sufix dla użytkownika
          <IconButton
            aria-label="close"
            className={stylesMod.closeButton}
            onClick={() => {
              setOpenSufixModal(false);
              setModalContent([]);
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Sufix do numeracji faktur
            {modalContent.map((e) => {
              return (
                <>
                  <br />{" "}
                  <Input
                    value={sufixValue}
                    handleChange={(e) => {
                      setSufixValue(e.target.value);
                    }}
                    type="text"
                  />
                </>
              );
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={BTNstyles.btn}
            onClick={(e) => {
              setOpenSufixModal(false);
              setModalContent([]);
            }}
          >
            Anuluj
          </Button>
          <Button
            variant="contained"
            size="large"
            className={`${BTNstyles.btn} ${BTNstyles.grn}`}
            onClick={(e) => {
              handleSetSufix(modalContent[0]?.id);
              setModalContent([]);
              setOpenSufixModal(false);
            }}
            color="primary"
          >
            Ustaw
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
