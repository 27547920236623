import React, { Component } from "react";
import styles from "./input.module.css";
import "./input.module.css";

export default class InputNum extends Component {
  render() {
    return (
      <div
        className={`${styles.searchBox} ${
          this.props.className
            ? this.props.className === "small"
              ? styles.small
              : this.props.className
            : this.props.className
        } `}
      >
        <input
          className={`${this.props.error ? styles.error : ""} ${styles.input}`}
          key={this.props.key}
          value={String(this.props.value)}
          onChange={this.props.handleChange}
          name={this.props.name}
          onBlur={this.props.onBlur}
          type={this.props.type}
          ref={this.props.ref}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled ? true : false}
        />
      </div>
    );
  }
}
